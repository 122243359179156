import { IComponentOptions, IController, IOnChangesObject } from "angular";
import { Unsubscribe } from "redux";
import { ITraceRootScopeService } from "@gtmhub/core/tracing";
import { ISessionSelectorDataLoader } from "@gtmhub/customFields/components/session-selector/models";
import { QuerySessionsService } from "@gtmhub/customFields/services/query-sessions.service";
import { IIndicator } from "@gtmhub/error-handling";
import { localize } from "@gtmhub/localization";
import { PlanningSessionsActions } from "@gtmhub/sessions/redux/session-actions";
import { ISessionsState, ISessionsStoreState } from "@gtmhub/sessions/redux/session-reducer";
import { DynamicFilterType, dynamicFilterSessionTags, dynamicFilterTypeSet } from "@gtmhub/shared/components/generic-session-selector/dynamic-filters-presets";
import { IDynamicValue } from "@gtmhub/shared/components/grid-filtering-bar";
import { INgRedux } from "@gtmhub/state-management";
import { IdMap } from "@gtmhub/util";
import { ISessionTag } from "@webapp/custom-fields/models/custom-fields.models";
import { Session } from "@webapp/sessions/models/sessions.model";

const dynamicFilterTagColor = "#003A7B";
const dynamicValues: Partial<Record<DynamicFilterType, ISessionTag>> = Object.fromEntries(dynamicFilterSessionTags.map((tag) => [tag.id, tag]));

/**
 * An immutable state for @type {GenericSessionSelectorCtrl}
 */
export class GenericSessionSelectorState {
  readonly sessionIds: string[];
  /**
   * Selected dynamic filter types.
   */
  readonly dynamicValues: IDynamicValue[];
  readonly sessionTags: ISessionTag[];

  /**
   * Creates a new state from the given value and sessions.
   * @param value a comma separated list of session ids and dynamic filter types.
   * The sessions ids and selected dynamic filter types
   * are extracted from this value and set to the class fields.
   * @param sessionsMap a map where keys are the session ids and values are the very sessions.
   */
  constructor(
    value: string,
    private sessionsMap: IdMap<Session>
  ) {
    const values = value.split(",").filter((v) => v.length);
    this.sessionIds = values.filter((value) => !dynamicFilterTypeSet.has(value));
    this.dynamicValues = values
      .filter((value) => dynamicFilterTypeSet.has(value))
      .map(
        (value) =>
          ({
            type: value,
          }) as IDynamicValue
      );
    this.sessionTags = this.getSessionTags();
  }

  /**
   * Returns a new comma separated list where the given argument is added to the list
   * of session ids and dynamic filter types.
   * The order of the list in the original value is not preserved.
   * @param idOrDynamicFilter a session id or a dynamic filter type
   */
  getValueWith(idOrDynamicFilter: string): string {
    let dynamicValues = this.dynamicValues;
    let sessionIds = this.sessionIds;
    if (dynamicFilterTypeSet.has(idOrDynamicFilter)) {
      dynamicValues = dynamicValues.concat({
        type: idOrDynamicFilter,
      } as IDynamicValue);
    } else {
      sessionIds = sessionIds.concat(idOrDynamicFilter);
    }
    const idsAndDynamicFilters = this.getIdsAndDynamicFiltersSet(dynamicValues, sessionIds);
    return this.idsAndDynamicFiltersToValue(idsAndDynamicFilters);
  }

  /**
   * Returns a new comma separated list where the given argument is removed from the list
   * of session ids and dynamic filter types.
   * The order of the list in the original value is not preserved.
   * @param idOrDynamicFilter a session id or a dynamic filter type
   */
  getValueWithout(idOrDynamicFilter: string): string {
    let dynamicValues = this.dynamicValues;
    let sessionIds = this.sessionIds;
    if (dynamicFilterTypeSet.has(idOrDynamicFilter)) {
      dynamicValues = dynamicValues.filter((value) => value.type !== idOrDynamicFilter);
    } else {
      sessionIds = sessionIds.filter((id) => id !== idOrDynamicFilter);
    }
    const idsAndDynamicFilters = this.getIdsAndDynamicFiltersSet(dynamicValues, sessionIds);
    return this.idsAndDynamicFiltersToValue(idsAndDynamicFilters);
  }

  private getSessionTags(): ISessionTag[] {
    const sessionTags = this.dynamicValues.reduce((sessionTags: ISessionTag[], value: IDynamicValue) => {
      const dynamicValue: ISessionTag = dynamicValues[value.type as DynamicFilterType];
      if (dynamicValue) {
        const sessionTag: ISessionTag = {
          id: dynamicValue.id,
          name: localize(dynamicValue.name),
          color: dynamicFilterTagColor,
        };
        sessionTags.push(sessionTag);
      }
      return sessionTags;
    }, []);
    return this.sessionIds.reduce((sessionTags: ISessionTag[], sessionId) => {
      const session = this.sessionsMap[sessionId];
      if (session && session.id) {
        const sessionTag: ISessionTag = {
          id: session.id,
          name: session.title,
          color: session.color,
        };
        sessionTags.push(sessionTag);
      }
      return sessionTags;
    }, sessionTags);
  }

  private getIdsAndDynamicFiltersSet(dynamicValues: IDynamicValue[], sessionIds: string[]): Set<string> {
    return new Set(dynamicValues.map((value): string => value.type).concat(sessionIds));
  }

  private idsAndDynamicFiltersToValue(sessionIdsAndDynamicFilters: Set<string>): string {
    return Array.from(sessionIdsAndDynamicFilters).join(",");
  }
}

/**
 * Provides functionality for selecting sessions form a drop down list and a tag list.
 *
 * `value` a string containing a comma separated list of selected session ids and dynamic session filters.
 * `on-change` an event handler accepting the new value - a string containing a comma separated list.
 *
 * @example
 * <automation-session-selector
 *   value="$ctrl.value"
 *   on-change="$ctrl.valueChanged(value)"
 * </automation-session-selector>
 */
export class GenericSessionSelectorCtrl implements IController {
  // bindings
  value = "";
  onChange?: (args: { value: string }) => void;
  dynamicFiltersPreset?: string;

  maxUnfocusedSessionTags = 4;
  sessionSearchParams: ISessionSelectorDataLoader;
  indicators: {
    gettingSessions?: IIndicator;
  };
  private sessionsMap: IdMap<Session> = {};
  private state = new GenericSessionSelectorState(this.value, this.sessionsMap);
  private unsubscribe: Unsubscribe;

  get sessionIds(): string[] {
    return this.state.sessionIds;
  }

  get dynamicValues(): IDynamicValue[] {
    return this.state.dynamicValues;
  }

  get sessionTags(): ISessionTag[] {
    return this.state.sessionTags;
  }

  get preset(): string {
    return this.dynamicFiltersPreset || "all";
  }

  public static $inject = ["$rootScope", "QuerySessionsService", "$ngRedux", "PlanningSessionsActions"];

  constructor(
    private $rootScope: ITraceRootScopeService,
    private querySessionsService: QuerySessionsService,
    private $ngRedux: INgRedux,
    private planningSessionsActions: PlanningSessionsActions
  ) {
    this.indicators = {};
    this.unsubscribe = this.$ngRedux.connect((state: ISessionsStoreState): ISessionsState => {
      return state.sessions;
    })(this.bindStateToCtrl.bind(this));
  }

  private bindStateToCtrl(sessionsState: ISessionsState) {
    const sessionsStateToIndicator = (): IIndicator => (sessionsState.error ? { error: sessionsState.error } : sessionsState.isFetching ? { progress: true } : undefined);
    this.sessionsMap = sessionsState.map;
    this.state = new GenericSessionSelectorState(this.value, this.sessionsMap);
    this.indicators = {
      gettingSessions: sessionsStateToIndicator(),
    };
  }

  $onChanges(onChangesObj: IOnChangesObject): void {
    if (onChangesObj && onChangesObj.value && onChangesObj.value.currentValue !== onChangesObj.value.previousValue) {
      this.state = new GenericSessionSelectorState(this.value, this.sessionsMap);
    }
  }

  $onInit(): void {
    this.$ngRedux.dispatch(this.planningSessionsActions.getSessionsIfMissing());
    this.sessionSearchParams = this.createSessionSearchSessionTagsParams();
  }

  $onDestroy(): void {
    this.unsubscribe();
  }

  private createSessionSearchSessionTagsParams(): ISessionSelectorDataLoader {
    return {
      placeholder: localize("search_for_session"),
      query: (input: string): ISessionTag[] => {
        const sessionFilters: ISessionTag[] = dynamicFilterSessionTags
          .filter((tag) => {
            const name = localize(tag.name).toLocaleLowerCase();
            return name.includes(input.toLocaleLowerCase());
          })
          .map((tag) => ({
            ...tag,
            name: localize(tag.name),
            color: dynamicFilterTagColor,
          }));
        return sessionFilters.concat(this.querySessionsService.querySessions(input));
      },
    };
  }

  addIdOrDynamicFilter(idOrDynamicFilter: string): void {
    this.$rootScope.traceAction("add_session_to_automation_task_rule", () => {
      const newValue = this.state.getValueWith(idOrDynamicFilter);
      // We are replacing the state here and not waiting for the new state to come from the parent component
      // because otherwise in case of multiple on-selection/on-deselection are invoked
      // the new "value" comes from the parent after the invocations and thus
      // leaving us working with a stale state in this method.
      this.state = new GenericSessionSelectorState(newValue, this.sessionsMap);
      this.notifyOnChange(newValue);
    });
  }

  removeIdOrDynamicFilter(idOrDynamicFilter: string): void {
    this.$rootScope.traceAction("remove_session_from_automation_task_rule", () => {
      const newValue = this.state.getValueWithout(idOrDynamicFilter);
      // We are replacing the state here and not waiting for the new state to come from the parent component
      // because otherwise in case of multiple on-selection/on-deselection are invoked
      // the new "value" comes from the parent after the invocations and thus
      // leaving us working with a stale state in this method.
      this.state = new GenericSessionSelectorState(newValue, this.sessionsMap);
      this.notifyOnChange(newValue);
    });
  }

  private notifyOnChange(value: string) {
    if (this.onChange) {
      this.onChange({ value });
    }
  }
}

export const GenericSessionSelectorComponent: IComponentOptions = {
  controller: GenericSessionSelectorCtrl,
  template: require("./generic-session-selector.html"),
  bindings: {
    value: "<",
    onChange: "&",
    dynamicFiltersPreset: "@",
  },
};
