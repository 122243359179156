import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IScreen } from "@webapp/navigation/models/shared.models";

@Component({
  selector: "top-section-nav",
  templateUrl: "./top-section-nav.component.html",
  styleUrls: ["./top-section-nav.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopSectionNavComponent {
  @Input() public screens: IScreen[];
  @Input() public activeScreen: string;
}
