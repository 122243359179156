import { ChangeDetectionStrategy, Component, Input, TemplateRef } from "@angular/core";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";

@Component({
  selector: "div[ui-modal-title]",
  exportAs: "UiModalTitleBuiltin",
  templateUrl: "modal-title.component.html",
  styleUrls: ["modal-title.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "ant-modal-header",
  },
  standalone: true,
  imports: [NzOutletModule],
})
export class UiModalTitleComponent {
  @Input() public uiTitle: string | TemplateRef<unknown>;
}
