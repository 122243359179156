import { GridStackNode } from "gridstack";
import { BaseWidgetComponent } from "./components/base-widget.component";
import { UiDashboardItemComponent, UiGridItemHTMLElement } from "./components/dashboard-item/dashboard-item.component";
import { UiDashboardComponent, UiGridHTMLElement } from "./dashboard.component";
import { UiDashboardOptions, UiDashboardWidget } from "./dashboard.models";

function addWidgetComponentOfTypeGrid(host: UiGridHTMLElement, w: UiDashboardWidget | GridStackNode): HTMLElement | undefined {
  const container = (host.parentElement as UiGridItemHTMLElement)?.component?.container;
  const gridRef = container?.createComponent(UiDashboardComponent);
  const grid = gridRef?.instance;
  if (!grid) {
    return;
  }

  grid.ref = gridRef;
  grid.uiOptions = w as UiDashboardOptions;
  return grid.el;
}

function addWidgetComponent(args: { host: UiGridHTMLElement | HTMLElement; w: UiDashboardWidget | GridStackNode; isGrid: boolean }): HTMLElement | undefined {
  const { host, w, isGrid } = args;
  if (isGrid) {
    return addWidgetComponentOfTypeGrid(host, w);
  }

  const gridComp = (host as UiGridHTMLElement).component;
  const gridItemRef = gridComp?.container?.createComponent(UiDashboardItemComponent);
  const gridItem = gridItemRef?.instance;
  if (!gridItem) {
    return;
  }

  gridItem.ref = gridItemRef;

  const { selector } = w as UiDashboardWidget;
  const componentType = selector ? UiDashboardComponent.selectorToType[selector] : undefined;
  if (componentType) {
    try {
      const childWidget = gridItem.container.createComponent<BaseWidgetComponent>(componentType)?.instance;
      if (childWidget) {
        gridItem.childWidget = childWidget;
        childWidget.deserialize(w);
      }
    } catch (err) {
      console.error(err);
    }
  }

  return gridItem.el;
}

function removeWidgetComponent(args: { w: UiDashboardWidget | GridStackNode; isGrid: boolean }): void {
  const { w, isGrid } = args;
  const n = w as GridStackNode;
  if (isGrid) {
    const grid = (n.el as UiGridHTMLElement)?.component;
    if (grid?.ref) {
      grid.ref.destroy();
    } else {
      grid?.ngOnDestroy();
    }
  } else {
    const gridItem = (n.el as UiGridItemHTMLElement)?.component;
    if (gridItem?.ref) {
      gridItem.ref.destroy();
    } else {
      gridItem?.ngOnDestroy();
    }
  }
}

export function addRemoveHandler(
  host: UiGridHTMLElement | HTMLElement,
  w: UiDashboardWidget | GridStackNode,
  // eslint-disable-next-line @foxglove/no-boolean-parameters
  add: boolean,
  // eslint-disable-next-line @foxglove/no-boolean-parameters
  isGrid: boolean
): HTMLElement | undefined {
  if (add) {
    if (!host) {
      return;
    }

    return addWidgetComponent({ host, w, isGrid });
  } else {
    removeWidgetComponent({ w, isGrid });
  }
}

export function saveHandler(n: GridStackNode, w: UiDashboardWidget): void {
  const gridItem = (n.el as UiGridItemHTMLElement)?.component;
  if (gridItem) {
    const inputs = gridItem.childWidget?.serialize();
    if (inputs) {
      w.inputs = inputs;
    }
  }
}
