<ng-template>
  <people-selector
    [formControl]="formControl"
    [readonly]="readonly"
    [borderless]="borderless"
    [uiShowArrow]="!borderless"
    [a11yLabelledby]="labelId"
    [a11yRequired]="required"
    [request]="request"
    [allowClear]="allowClear"
    [mode]="mode"
    [uiId]="controlId"
    [atLeastOneValue]="atLeastOneValue"
    [allowViewOnlyUsersForSelection]="allowViewOnlyUsersForSelection"
    [placeholderKey]="placeholder"
    [preventInfiniteScrolling]="true"
    [attr.data-test-id]="e2eTestId"
    (focus)="inputFocus.emit()"
    (selectionChange)="selectionChange.emit($event)"
    (openChange)="openChange.emit($event)"></people-selector>
</ng-template>
