import { getLanguageCode } from "@gtmhub/localization";
import { IUnitFormatting } from "@gtmhub/shared/models";
import { IdMap } from "@gtmhub/util";
import { Comment } from "@webapp/comments";
import { KpiProjection } from "@webapp/kpis/models/kpi-projections.models";
import { KpiTimeFilter } from "@webapp/kpis/models/kpis.models";
import { MAX_KPI_FRACTION_SIZE } from "@webapp/kpis/utils";
import dayjs from "@webapp/shared/libs/dayjs";
import { ShortNumberPipe } from "@webapp/shared/pipes/short-number/short-number.pipe";
import { IKpiChartPoint, IKpiChartTooltipContext, IKpiTooltipParams } from "./kpi-chart.models";

export const CHART_POINT_DATE_FORMAT = "YYYY-MM-DD";
export const CHART_POINT_TOOLTIP_DATE_FORMAT = "DD MMM, YYYY";
export const CHART_NUMBER_OF_ACCESSIBLE_YEAR_IN_FUTURE = 5;
export const chartTimeFiltersMaxAndMinDateRanges = new Map<KpiTimeFilter, { min: number; max: number }>([
  ["week", { min: 5, max: 2 }],
  ["month", { min: 20, max: 10 }],
  ["three-months", { min: 60, max: 30 }],
  ["year", { min: 240, max: 125 }],
]);
const KPI_CHART_Y_AXIS_MAX_FRACTION_SIZE = 4;
const KPI_FRACTION_SIZE_RESPECTED_TO_VALUE = 10000;

export function kpiChartYAxisLabelFormatter(value: number, shortNumberPipe: ShortNumberPipe): string {
  const firstTwoDigitNumber = 10;
  if (value !== 0 && Math.abs(value) < firstTwoDigitNumber) {
    return value.toLocaleString(getLanguageCode(), { maximumFractionDigits: KPI_CHART_Y_AXIS_MAX_FRACTION_SIZE });
  }

  return shortNumberPipe.transform(value);
}

export function kpiChartYAxisMin(value: { min: number; max: number }): number {
  if (value.min !== value.max) {
    return value.min - (value.max - value.min) * 0.2;
  } else if (value.min === value.max && value.min === 0) {
    return -1;
  } else {
    return value.min - Math.abs(value.min) * 0.5;
  }
}

export function kpiChartYAxisMax(value: { min: number; max: number }): number {
  if (value.min !== value.max) {
    return value.max + (value.max - value.min) * 0.2;
  } else if (value.min === value.max && value.max === 0) {
    return 1;
  } else {
    return value.max + Math.abs(value.max) * 0.5;
  }
}

// used for the tooltip formatting of snapshot and projection points
export const kpiChartPointTooltipFormatter = (params, tooltipContext: IKpiChartTooltipContext, shortNumberPipe: ShortNumberPipe): string => {
  const snapshotPoint = params.find((param) => param.seriesId === "snapshotSeries" && param.value.snapshot)?.value;
  const projectionPoint = params.find((param) => param.seriesId === "projectionSeries" && param.value.projection)?.value;
  return kpiChartTooltipFormatter(
    {
      snapshotPoint: { date: snapshotPoint?.date, value: snapshotPoint?.snapshot },
      projectionPoint: { date: projectionPoint?.date, value: projectionPoint?.projection },
    },
    tooltipContext,
    shortNumberPipe
  );
};

const findClosestProjectionInTheFutureToSnapshot = (snapshotDateAsString: string, projections: KpiProjection[]): KpiProjection => {
  // the projections need to be sorted by date because the find operator would select the first found element satisfying the predicate
  projections.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));

  return projections.find((projection) => {
    const diff = dayjs(dayjs(projection.date)).diff(dayjs(snapshotDateAsString));
    return diff >= 0 && dayjs(dayjs(snapshotDateAsString)).isSameOrBefore(dayjs(projection.date));
  });
};

// used for the tooltip formatting of drag and drop graphic invisible points on top of projeciton points
export const kpiDragAndDropPointTooltipFormatter = (
  projectionChartPoint: IKpiChartPoint,
  tooltipContext: IKpiChartTooltipContext,
  chartData: IKpiChartPoint[],
  shortNumberPipe: ShortNumberPipe
): string => {
  // the snapshot value is not in projectionChartPoint since if we have snapshot and projection on the same date, we keep 2 data points entries - otherwise the chart is not drawn properly
  // that's why we need to explicitly search through the chartData to find if there is a snapshot entry on the same date as the projection chart point
  const snapshotOnTheSameDateAsProjection = chartData.find((item) => item.date === projectionChartPoint.date && item.snapshot != null);
  const snapshotPoint = { value: snapshotOnTheSameDateAsProjection?.snapshot, date: snapshotOnTheSameDateAsProjection?.date };
  const projectionPoint = { value: projectionChartPoint.projection, date: projectionChartPoint.date };
  return kpiChartTooltipFormatter(
    {
      snapshotPoint: snapshotPoint,
      projectionPoint: projectionPoint,
    },
    tooltipContext,
    shortNumberPipe
  );
};

const kpiChartTooltipFormatter = (params: IKpiTooltipParams, tooltipContext: IKpiChartTooltipContext, shortNumberPipe: ShortNumberPipe): string => {
  const projectionCircleTooltipColor = "#417AEA";
  const bigValueStyle = "font-size: 16px !important; color: #0D232F;";
  const dateStyle = "font-size: 12px !important; color: #445573;";
  const circleStyle = "display: inline-block; width: 8px; height: 8px; border-radius: 50%;  margin-right: 4px;";

  if (params.snapshotPoint?.value) {
    let tooltipHtml = "";
    // snapshot value
    const snapshotValueFormatted = formatSnapshotOrProjection(params.snapshotPoint.value, tooltipContext.kpi.formatting, shortNumberPipe);
    const snapshotCircleTooltipColor = "#603FA5";
    tooltipHtml = tooltipHtml.concat(`<div>
    <span style="${circleStyle} background-color: ${snapshotCircleTooltipColor};"></span>
    <span style="${bigValueStyle}">${snapshotValueFormatted}</span>
    </br>`);

    const closestProjection = findClosestProjectionInTheFutureToSnapshot(params.snapshotPoint.date, tooltipContext.projections);
    if (closestProjection) {
      // closest projection value
      const closestProjectionValueFormatted = formatSnapshotOrProjection(closestProjection.value, tooltipContext.kpi.formatting, shortNumberPipe);
      const smallValueStyle = "font-size: 14px !important; color: #0D232F;";
      tooltipHtml = tooltipHtml.concat(`<span style="${circleStyle} background-color: ${projectionCircleTooltipColor};"></span>
      <span style="${smallValueStyle}">${closestProjectionValueFormatted}</span>
      </br>`);
    }

    // snapshot date
    const snapshotDateFormatted = formatSnapshotOrProjectionDate(params.snapshotPoint.date);
    tooltipHtml = tooltipHtml.concat(`<span style="${dateStyle}">${snapshotDateFormatted}</span>
    </div>`);
    return tooltipHtml;
  } else if (params.projectionPoint?.value) {
    const projectionPointFormatted = formatSnapshotOrProjection(params.projectionPoint.value, tooltipContext.kpi.formatting, shortNumberPipe);
    const projectionDateFormatted = formatSnapshotOrProjectionDate(params.projectionPoint.date);
    return `<div>
    <span style="${circleStyle} background-color: ${projectionCircleTooltipColor};"></span>
    <span style="${bigValueStyle}">${projectionPointFormatted}</span>
    </br>
    <span style="${dateStyle}">${projectionDateFormatted}</span>
    </div>`;
  } else {
    return "";
  }
};

const formatSnapshotOrProjection = (value: number, formatting: IUnitFormatting, shortNumberPipe: ShortNumberPipe): string => {
  let roundedValue;
  const hasKpiFractionSizeSetting = formatting.fractionSize !== 0;
  if (value < KPI_FRACTION_SIZE_RESPECTED_TO_VALUE) {
    if (hasKpiFractionSizeSetting) {
      roundedValue = formatKpiValue(value, formatting.fractionSize);
    } else {
      roundedValue = value.toFixed(0);
    }
  } else {
    roundedValue = shortNumberPipe.transform(value) + (formatting.suffix ? " " : "");
  }

  // toLocaleString sometimes trims the decimal places if we use it on number, hence using toString()
  return formatting.prefix + roundedValue.toString().toLocaleString(getLanguageCode()) + formatting.suffix;
};

const formatSnapshotOrProjectionDate = (date: string): string => {
  return dayjs(date).format(CHART_POINT_TOOLTIP_DATE_FORMAT);
};

export function formatKpiValue(value: number, fractionSize: number): number {
  return parseFloat(value.toFixed(getKpiFractionSize(fractionSize)));
}

function getKpiFractionSize(fractionSize: number): number {
  if (isNaN(fractionSize) || fractionSize < 0) {
    return MAX_KPI_FRACTION_SIZE;
  }

  return fractionSize;
}

// params are of type LabelFormatterParams but it is not exported from echarts/types
export function kpiChartCrosshairFormatter(params, shortNumberPipe: ShortNumberPipe): string {
  if (params.axisDimension === "x") {
    return dayjs(params.value).format(CHART_POINT_DATE_FORMAT);
  } else {
    return shortNumberPipe.transform(params.value);
  }
}

export function isSnapshotPoint(chartPoint: IKpiChartPoint): boolean {
  return chartPoint.snapshot !== null;
}

export function isProjectionPoint(chartPoint: IKpiChartPoint): boolean {
  return chartPoint.projection !== null;
}

export function getLeadingComments(comments: Comment[]): { projectionsLeadingCommentsMap: IdMap<string>; snapshotsLeadingCommentsMap: IdMap<string> } {
  return { projectionsLeadingCommentsMap: getProjectionsLeadingCommentsMap(comments), snapshotsLeadingCommentsMap: getSnapshotsLeadingCommentsMap(comments) };
}

function getSnapshotsLeadingCommentsMap(comments: Comment[]): IdMap<string> {
  const snapshotComments = comments.filter((c) => c.targetType === "kpi_snapshot");
  const snapshotCommentsMap = groupCommentsByShortTragetDate(snapshotComments);
  return createLeadingCommentsMap(snapshotCommentsMap);
}

function getProjectionsLeadingCommentsMap(comments: Comment[]): IdMap<string> {
  const projectionComments = comments.filter((c) => c.targetType === "kpi_projection");
  const projectionCommentsMap = groupCommentsByShortTragetDate(projectionComments);
  return createLeadingCommentsMap(projectionCommentsMap);
}

function groupCommentsByShortTragetDate(comments: Comment[]): IdMap<Comment[]> {
  const commentsMap: IdMap<Comment[]> = {};
  for (const comment of comments) {
    const shortTargetDate = dayjs(comment.targetDate).format(CHART_POINT_DATE_FORMAT);
    if (!commentsMap[shortTargetDate]) {
      commentsMap[shortTargetDate] = [comment];
    } else {
      commentsMap[shortTargetDate].push(comment);
    }
  }

  return commentsMap;
}

function createLeadingCommentsMap(commentsMap: IdMap<Comment[]>): IdMap<string> {
  const targetDateCommentIdMap: IdMap<string> = {};
  Object.values(commentsMap).forEach((comments) => {
    const earliestComment = findEarliestComment(comments);
    const formttedTargetDate = dayjs(earliestComment.targetDate).format(CHART_POINT_DATE_FORMAT);
    targetDateCommentIdMap[formttedTargetDate] = earliestComment.id;
  });

  return targetDateCommentIdMap;
}

function findEarliestComment(comments: Comment[]): Comment {
  return comments.reduce((a, b) => (b.createdAt < a.createdAt ? b : a));
}
