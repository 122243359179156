import { Injectable } from "@angular/core";
import { CurrentUserRepository } from "@webapp/users";
import { IInsightTemplateSuggestionSetting, IInsightTemplateSuggestionSettings } from "../models";

@Injectable({
  providedIn: "root",
})
export class InsightTemplateSuggestionsService {
  private defaultSuggestionsToShow = 2;

  constructor(private currentUserRepository: CurrentUserRepository) {}

  public getSettings = (insightboardId: string): IInsightTemplateSuggestionSetting => {
    const defaultSuggestionSettings: IInsightTemplateSuggestionSettings = {
      dismissed: false,
      tooltipShown: false,
      insightboards: [],
    };

    const settings: IInsightTemplateSuggestionSettings = this.currentUserRepository.getUserSetting("insightTemplateSuggestions") || defaultSuggestionSettings;
    return settings.insightboards.find((settings) => settings.insightboardId === insightboardId);
  };

  public initializeWithDefaultSettings = (insightboardId: string): IInsightTemplateSuggestionSetting => {
    const defaultSuggestionSettings: IInsightTemplateSuggestionSettings = {
      dismissed: false,
      tooltipShown: false,
      insightboards: [],
    };
    const settings: IInsightTemplateSuggestionSettings = this.currentUserRepository.getUserSetting("insightTemplateSuggestions") || defaultSuggestionSettings;
    const emptySettings = {
      insightboardId: insightboardId,
      numberOfSuggestionsToShow: this.defaultSuggestionsToShow,
      linkToMarketplaceDismissed: false,
    };
    settings.insightboards = [...settings.insightboards, emptySettings];
    this.currentUserRepository.setUserSetting({ insightTemplateSuggestions: settings });
    return emptySettings;
  };

  public updateSettings = (currentSettings: IInsightTemplateSuggestionSetting): void => {
    const settings: IInsightTemplateSuggestionSettings = this.currentUserRepository.getUserSetting("insightTemplateSuggestions");

    if (!settings) {
      return;
    }

    const updatedSettingsList = settings.insightboards.filter((settings) => settings.insightboardId !== currentSettings.insightboardId);
    updatedSettingsList.push(currentSettings);
    settings.insightboards = updatedSettingsList;
    this.currentUserRepository.setUserSetting({ insightTemplateSuggestions: settings });
  };

  public removeSettings = (insightboardId: string): void => {
    const suggestionSettings: IInsightTemplateSuggestionSettings = this.currentUserRepository.getUserSetting("insightTemplateSuggestions");
    if (suggestionSettings && suggestionSettings.insightboards.length) {
      const insightboardSuggestionSettings = suggestionSettings.insightboards.find((settings) => settings.insightboardId === insightboardId);
      if (insightboardSuggestionSettings) {
        const filteredList = suggestionSettings.insightboards.filter((setting) => setting.insightboardId !== insightboardId);
        this.currentUserRepository.setUserSetting({ insightTemplateSuggestions: { ...suggestionSettings, insightboards: filteredList } });
      }
    }
  };

  public shouldShowAddInsightsTooltip = (): boolean => {
    const settings: IInsightTemplateSuggestionSettings = this.currentUserRepository.getUserSetting("insightTemplateSuggestions");
    const shouldShowTooltip = settings !== null ? !settings.tooltipShown : false;

    if (shouldShowTooltip) {
      settings.tooltipShown = true;
      this.currentUserRepository.setUserSetting({ insightTemplateSuggestions: settings });
    }
    return shouldShowTooltip;
  };
}
