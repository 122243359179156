<div class="video-modal-container" *ngIf="videoDialogOpen" (click)="closeVideoDialog()">
  <iframe [attr.src]="videoUrl" frameborder="0" allow="fullscreen;" title=""></iframe>
</div>
<div class="container" id="empty-state" [class.bg-light]="background === 'light'" [class.bg-dark]="background === 'dark'">
  <div class="content" [ngClass]="emptyState.customClasses">
    <div [uiGutter]="[30, 30]" ui-row>
      <div class="" [uiXl]="8" [uiMd]="24" ui-col>
        <section tabindex="0">
          <h2 class="heading">{{ emptyState.title }}</h2>
          <ng-content select="[subtext]"></ng-content>
        </section>
        <ng-content select="[action-button]"></ng-content>
      </div>
      <div *ngIf="!emptyState.image" [uiXl]="16" [uiMd]="24" ui-col>
        <ng-content select="[main-container]"></ng-content>
      </div>
      <div class="main-image-container" *ngIf="emptyState.image" [uiXl]="16" [uiMd]="24" ui-col>
        <img class="main-image" [attr.src]="emptyState.image" [ngClass]="{ 'with-shadow': !emptyState.withoutShadow }" alt="" />
      </div>
    </div>
    <empty-state-info-boxes [infoBoxesId]="infoBoxesId || emptyStateId" (videoUrl)="openVideoDialog($event)"></empty-state-info-boxes>
  </div>
</div>
