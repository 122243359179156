import { NgModule } from "@angular/core";
import { UiInputNumberGroupSlotComponent } from "@webapp/ui/input-number/input-number-group-slot/input-number-group-slot.component";
import { UiInputNumberGroupComponent } from "@webapp/ui/input-number/input-number-group/input-number-group.component";
import { UiInputNumberComponent } from "./input-number.component";

@NgModule({
  imports: [UiInputNumberComponent, UiInputNumberGroupComponent, UiInputNumberGroupSlotComponent],
  exports: [UiInputNumberComponent, UiInputNumberGroupComponent, UiInputNumberGroupSlotComponent],
})
export class UiInputNumberModule {}
