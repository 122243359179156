import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { HubSpotForm } from "./hubspot.models";

@Injectable({
  providedIn: "root",
})
export class HubSpotService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private httpClient: HttpClient
  ) {}

  public postForm(form: HubSpotForm): Observable<unknown> {
    if (!this.appConfig.hubSpot.enabled) return;

    const fields = Object.entries(form.fields).map(([name, value]) => ({ name, value }));

    const data = {
      fields,
      context: {
        pageName: `${form.name}`,
      },
      skipValidation: true,
    };
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    return this.httpClient.post(this.appConfig.hubSpot.formUrl[form.name], data, options);
  }
}
