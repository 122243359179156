import { IComponentOptions, IController } from "angular";
import { IPictureInfo, IUserIdentity } from "@gtmhub/users";
import { ICollection } from "@webapp/core/core.models";

type UserLike = {
  id: string;
  email: string;
  picture?: string;
  firstName: string;
  lastName: string;
  isPrimary?: boolean;
  isSSOBased?: boolean;
  isAzureIDPBased?: boolean;
  identities?: ICollection<IUserIdentity>;
  pictureInfo?: IPictureInfo;
};

class DisabledEditNamesCtrl implements IController {
  user?: UserLike;
  view: "user" | "admin" = "user";

  // whether this is viewed by a user in the user's profile
  isUserView = (): boolean => {
    return this.view === "user";
  };

  // whether this is viewed by an admin in the "Users" screen
  isAdminView = (): boolean => {
    return this.view === "admin";
  };

  public editable = (): boolean => {
    if (this.user != null) {
      return this.user.isPrimary && !this.user.isSSOBased;
    } else {
      return true;
    }
  };

  private hasSSOIdentity = (): boolean => {
    if (this.user != null) {
      return this.user.isSSOBased && this.user.identities != null;
    } else {
      return false;
    }
  };

  public isMicrosoftBased = (): boolean => {
    if (!this.hasSSOIdentity()) {
      return false;
    } else {
      return this.user.identities.items.some((identity) => identity.provider === "waad");
    }
  };

  public isGoogleBased = (): boolean => {
    if (!this.hasSSOIdentity()) {
      return false;
    } else {
      return this.user.identities.items.some((identity) => identity.provider === "google-apps");
    }
  };

  public isGenericSSOBased = (): boolean => {
    if (!this.hasSSOIdentity()) {
      return false;
    } else {
      return !(this.isMicrosoftBased() || this.isGoogleBased());
    }
  };
}

export const DisabledEditNamesComponent: IComponentOptions = {
  bindings: {
    user: "<",
    view: "@",
  },
  controller: DisabledEditNamesCtrl,
  controllerAs: "$ctrl",
  template: require("./disabled-edit-names.component.html"),
};
