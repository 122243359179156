import { UserAssignee } from "@webapp/assignees/models/assignee.models";
import { OKRLinkItem } from "../rich-text-editor.models";
import { Node } from "./utils/node";

type DeltaResponse = {
  embed: {
    ghMention(this: Node, attrs: { id: string }): void;
    embeddedEmoji(this: Node, attrs: { id: string }): void;
    okrLink(this: Node, attrs: { id: string }): void;
  };
  inline: {
    italic(): string[];
    bold(): string[];
    strike(): string[];
    link(url: string): string[];
  };
  block: {
    list: {
      group(this: Node): Node;
      line(this: Node, attrs: { list: string }, group: { count: number }): void;
    };
  };
};

export const deltaToMarkdownConverters = (): DeltaResponse => ({
  embed: {
    ghMention: function (this: Node, attrs: UserAssignee): void {
      this.append(`@[${attrs.name}:${attrs.email}:${attrs.id}]`);
    },
    embeddedEmoji: function (this: Node, attrs): void {
      this.append(attrs);
    },
    okrLink: function (this: Node, attrs: OKRLinkItem): void {
      this.append(`#[${attrs.type}:${attrs.title}:${attrs.id}]`);
    },
  },

  inline: {
    italic: (): string[] => {
      return ["_", "_"];
    },
    bold: (): string[] => {
      return ["**", "**"];
    },
    strike: (): string[] => {
      return ["~~", "~~"];
    },
    link: (url): string[] => {
      return ["[", "](" + url + ")"];
    },
  },

  block: {
    list: {
      group: function (this: Node): Node {
        return new Node(["", ""]);
      },
      line: function (this: Node, attrs, group): void {
        if (attrs.list === "bullet") {
          this.open = "- " + this.open;
        } else if (attrs.list === "checked") {
          this.open = "- [x] " + this.open;
        } else if (attrs.list === "unchecked") {
          this.open = "- [ ] " + this.open;
        } else if (attrs.list === "ordered") {
          group.count = group.count || 0;
          const count = ++group.count;

          this.open = count + ". " + this.open;
        }
      },
    },
  },
});
