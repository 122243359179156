import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "suggestion-drawer-bullet-point-card",
  templateUrl: "./suggestion-drawer-bullet-point-card.component.html",
  styleUrls: ["./suggestion-drawer-bullet-point-card.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionDrawerBulletPointCardComponent {
  @Input() public icon: string;
  @Input() public iconBackground = "none";
  @Input() public title: string;
  @Input() public description: string;

  public get iconWrapperClasses(): string[] {
    return ["bullet-icon-wrapper", `bullet-icon-wrapper-bg-${this.iconBackground}`];
  }
}
