import { IHttpInterceptor, IHttpInterceptorFactory, ILocationService, IPromise, IRequestConfig, auto } from "angular";
import { EnvironmentService } from "@gtmhub/env";

const stripTokensFromQueryString = (pathWithQueryString: string): string => {
  const [path, queryString] = pathWithQueryString.split("?");
  if (queryString && queryString.toLowerCase().includes("token")) {
    const searchParams = new URLSearchParams(queryString);
    const toDelete: string[] = [];

    for (const key of searchParams.keys()) {
      if (key.toLowerCase().includes("token")) {
        toDelete.push(key);
      }
    }

    for (const key of toDelete) {
      searchParams.delete(key);
    }

    pathWithQueryString = path + "?" + searchParams.toString();
  }

  return pathWithQueryString;
};

/**
 * Attaches a `gtmhub-request-origin` header to all API requests
 * to denote the current URL path
 */
export class RequestOriginInterceptor implements IHttpInterceptor {
  constructor(
    private $injector: auto.IInjectorService,
    private $location: ILocationService
  ) {}

  public request = (config: IRequestConfig): IRequestConfig | IPromise<IRequestConfig> => {
    const envService = this.$injector.get<EnvironmentService>("EnvironmentService");
    if (envService.isApiRequest(config.url)) {
      config.headers["gtmhub-request-origin"] = stripTokensFromQueryString(this.$location.path());
    }

    return config;
  };

  public static factory(): IHttpInterceptorFactory {
    const factory = ($injector: auto.IInjectorService, $location: ILocationService) => new RequestOriginInterceptor($injector, $location);
    factory.$inject = ["$injector", "$location"];
    return factory;
  }
}
