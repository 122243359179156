import { Directionality } from "@angular/cdk/bidi";
import { NgIf, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Optional, Output, TemplateRef, ViewEncapsulation } from "@angular/core";
import { UiI18nService, UiPaginationI18nInterface } from "@quantive/ui-kit/i18n";
import { NzConfigService, WithConfig } from "ng-zorro-antd/core/config";
import { NzBreakpointService } from "ng-zorro-antd/core/services";
import { InputBoolean, InputNumber } from "ng-zorro-antd/core/util";
import { NzPaginationComponent } from "ng-zorro-antd/pagination";
import { UiPaginationDefaultComponent } from "./components/pagination-default/pagination-default.component";
import { UiPaginationSimpleComponent } from "./components/pagination-simple/pagination-simple.component";
import { PaginationItemRenderContext } from "./pagination.models";

@Component({
  selector: "nav[ui-pagination]",
  exportAs: "uiPagination",
  preserveWhitespaces: false,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "pagination.component.html",
  host: {
    "[attr.aria-label]": `customLocale.title`,
    role: "navigation",
  },
  standalone: true,
  imports: [NgIf, UiPaginationSimpleComponent, UiPaginationDefaultComponent, NgTemplateOutlet],
})
export class UiPaginationComponent extends NzPaginationComponent {
  @Input("uiShowTotal") public nzShowTotal: TemplateRef<{ $implicit: number; range: [number, number] }> | null = null;
  @Input("uiItemRender") public nzItemRender: TemplateRef<PaginationItemRenderContext> | null = null;
  @Input("uiSize") @WithConfig() public nzSize: "default" | "small" = "default";
  @Input("uiPageSizeOptions") @WithConfig() public nzPageSizeOptions: number[] = [10, 20, 30, 40];
  @Input("uiShowSizeChanger") @WithConfig() @InputBoolean() public nzShowSizeChanger = false;
  @Input("uiShowQuickJumper") @WithConfig() @InputBoolean() public nzShowQuickJumper = false;
  @Input("uiSimple") @WithConfig() @InputBoolean() public nzSimple = false;
  @Input("uiDisabled") @InputBoolean() public nzDisabled = false;
  @Input("uiResponsive") @InputBoolean() public nzResponsive = false;
  @Input("uiHideOnSinglePage") @InputBoolean() public nzHideOnSinglePage = true;
  @Input("uiTotal") @InputNumber() public nzTotal = 0;
  @Input("uiPageIndex") @InputNumber() public nzPageIndex = 1;
  @Input("uiPageSize") @InputNumber() public nzPageSize = 10;

  @Output("uiPageSizeChange") public readonly nzPageSizeChange: EventEmitter<number> = new EventEmitter();
  @Output("uiPageIndexChange") public readonly nzPageIndexChange: EventEmitter<number> = new EventEmitter();

  constructor(
    i18n: UiI18nService,
    cdr: ChangeDetectorRef,
    breakpointService: NzBreakpointService,
    nzConfigService: NzConfigService,
    @Optional() directionality: Directionality
  ) {
    super(i18n, cdr, breakpointService, nzConfigService, directionality);
  }

  public get customLocale(): UiPaginationI18nInterface {
    return this.locale as UiPaginationI18nInterface;
  }
}
