<div class="nav-list" *ngIf="limitedCollection && limitedCollection.items.length">
  <div class="nav-list__header" [attr.aria-expanded]="expanded" (keydown.enter)="expanded = !expanded" (click)="expanded = !expanded" role="button" tabindex="0">
    <span>{{ config.sectionLabelKey | localize }}</span>
    <span>
      <i class="nav-list__header__icon" [uiType]="expanded ? 'chevron-down-small' : 'chevron-right-small'" uiSize="xs" ui-icon></i>
    </span>
  </div>
  <ng-container *ngIf="expanded">
    <div class="nav-list__item-container" (cdkDropListDropped)="drop($event)" cdkDropList>
      @for (item of limitedCollection.items; track item.id) {
        <nav-list-item
          [item]="item"
          [config]="config"
          [canMoveUp]="!$first"
          [canMoveDown]="!$last"
          [attr.data-test-id]="'home-section-sub-nav-' + config.sectionLabelKey"
          (moveUp)="moveItem($event.id, -1)"
          (moveDown)="moveItem($event.id, 1)"
          (markItemAsFavorite)="markItemAsFavorite.emit($event)"
          (unmarkItemAsFavorite)="unmarkItemAsFavorite.emit($event)"
          (removeFromSuggested)="removeFromSuggested.emit($event)" />
      }
    </div>
    <div class="nav-list__show" *ngIf="limitedCollection.totalCount - config.visibleItemsCount > 0" (keydown.enter)="toggleSeeMore()" (click)="toggleSeeMore()" role="button" tabindex="0">
      <ng-container *ngIf="!seeMore">{{ "show_more" | localize: { amount: limitedCollection.totalCount - config.visibleItemsCount } }}</ng-container>
      <ng-container *ngIf="seeMore">{{ "show_less" | localize }}</ng-container>
    </div>
  </ng-container>
</div>
