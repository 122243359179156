import { IUIError } from "@gtmhub/error-handling";
import { applySocketUpdatesToCollection, applySocketUpdatesToIdMap } from "@gtmhub/sockets/util";
import { toIdMap } from "@gtmhub/util";
import { byTextField } from "@gtmhub/util/sorting-utils";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { AssigneesAction } from "./models";

export interface IAssigneesStoreState {
  assignees: IAssigneesState;
}

export interface IAssigneesState {
  isFetched: boolean;
  isFetching: boolean;
  map: Record<string, Assignee>;
  items: Assignee[];
  error: IUIError;
}

export function assigneeReducer(
  state: IAssigneesState = { isFetched: false, isFetching: false, items: [], map: {}, error: null },
  action: AssigneesAction
): IAssigneesState {
  switch (action.type) {
    case "REQUEST_ASSIGNEES":
      return { ...state, isFetching: true, isFetched: false, error: null };
    case "RECEIVE_ASSIGNEES": {
      const items = action.items.sort(byTextField("name"));
      return { ...state, isFetched: true, isFetching: false, items, map: toIdMap(action.items) };
    }
    case "UPDATE_ASSIGNEES": {
      return state.isFetched
        ? {
            isFetched: true,
            isFetching: false,
            error: null,
            items: applySocketUpdatesToCollection(state.items, action.update),
            map: applySocketUpdatesToIdMap(state.map, action.update),
          }
        : state;
    }
    case "ERROR_RECEIVE_ASSIGNEES":
      return { ...state, error: action.error, isFetching: false, isFetched: false };
    default: {
      return state;
    }
  }
}
