import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseApiServiceV2 } from "@webapp/core/api/base-api-v2.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { ICollection } from "@webapp/core/core.models";
import { IPlanningSessionConflict, Session, SessionProgress, UserAction } from "../models/sessions.model";

@Injectable({
  providedIn: "root",
})
export class SessionsApiService extends BaseApiServiceV2<Session, RequestPaging> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig) {
    super("sessions", { ...new ApiVersionModel("v1"), getAll: "v2" }, httpClient, appConfig);
  }

  public checkForConflictsInSession$(
    session: Session,
    action: UserAction,
    config: RequestConfig = new RequestConfig()
  ): Observable<ICollection<IPlanningSessionConflict>> {
    const url = config?.url || `${this.getBasePath(HttpActions.post)}/${session.id}/conflicts`;
    const body = { session, action };
    return this.httpClient.post<ICollection<IPlanningSessionConflict>>(url, body, {
      params: this.encodeQueryParams(config),
      headers: config.headers,
      context: config.context,
    });
  }

  public getSessionsProgress$(
    activeSessionIds: string,
    employeeIdAndTeamIds: string,
    config: RequestConfig = new RequestConfig()
  ): Observable<ICollection<SessionProgress>> {
    const url = config?.url || `${this.getBasePath(HttpActions.get)}/${activeSessionIds}/assignees/${employeeIdAndTeamIds}/stats`;
    return this.httpClient.get<ICollection<SessionProgress>>(url, {
      params: this.encodeQueryParams(config),
      headers: config.headers,
      context: config.context,
    });
  }
}
