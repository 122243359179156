import { FocusMonitor } from "@angular/cdk/a11y";
import { Directionality } from "@angular/cdk/bidi";
import { NgIf, NgStyle } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Optional,
  Output,
  Renderer2,
  ViewChild,
  forwardRef,
} from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";
import { UiSizeLDSType } from "@quantive/ui-kit/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzFormNoStatusService, NzFormStatusService } from "ng-zorro-antd/core/form";
import { NzDestroyService } from "ng-zorro-antd/core/services";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzInputNumberComponent } from "ng-zorro-antd/input-number";
import { defaultFormatter, thousandsFormatter } from "@webapp/ui/input-number/input-number.utils";
import { addAttributeValue } from "../utils/dom.utils";

@Component({
  selector: "ui-input-number",
  exportAs: "uiInputNumber",
  templateUrl: "input-number.component.html",
  styleUrls: ["./input-number.component.less"],
  host: {
    "[class.ant-input-number-borderless]": `nzBorderless`,
    "[class.ant-input-number-readonly]": "nzReadOnly",
    "[class.ant-input-number-disabled]": "nzDisabled",
    "[class.clickable]": "uiClickable",
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiInputNumberComponent),
      multi: true,
    },
    NzDestroyService,
  ],
  standalone: true,
  imports: [NgStyle, FormsModule, NgIf, UiIconModule],
})
export class UiInputNumberComponent extends NzInputNumberComponent implements OnInit, AfterViewInit {
  @Input("uiSize")
  public nzSize: UiSizeLDSType = "default";
  @Input("uiMin")
  public nzMin = -Infinity;
  @Input("uiMax")
  public nzMax = Infinity;
  @Input("uiParser")
  public nzParser = (value: string | number): string => {
    if ((value || value === 0) && value !== "-") {
      value = value.toString();
      return value
        .trim()
        .replace(/。/g, ".")
        .replace(/[^\w.-]+/g, "");
    } else {
      return "";
    }
  };
  @Input("uiPrecision")
  public nzPrecision?: number;
  @Input("uiPrecisionMode")
  public nzPrecisionMode: "cut" | "toFixed" | ((value: number | string, precision?: number) => number) = "toFixed";
  @Input("uiPlaceHolder")
  public nzPlaceHolder = "";
  @Input("uiStep")
  public nzStep = 1;
  @Input("uiInputMode")
  public nzInputMode = "decimal";
  @Input("uiId")
  public nzId: string | null = null;
  @Input("uiDisabled")
  @InputBoolean()
  public nzDisabled = false;
  @Input("uiReadOnly")
  @InputBoolean()
  public nzReadOnly = false;
  @Input("uiBorderless")
  @InputBoolean()
  public nzBorderless = false;
  @Input("uiAutoFocus")
  @InputBoolean()
  public nzAutoFocus = false;
  @Input("uiFormatter")
  public nzFormatter: (value: number) => string | number = defaultFormatter;
  @Input()
  public a11yLabel?: string;
  @Input()
  public a11yLabelledby?: string;
  @Input()
  public a11yDescribedby?: string;
  @Input()
  public a11yDescription?: string;
  @Input()
  public a11yRequired?: boolean;
  @Input()
  public a11yDisabled?: boolean;
  @Input()
  public errorMessageId?: string;
  @Input()
  public e2eTestId?: string;
  @Input()
  public valueControlsHide? = false;
  @Input()
  public useThousandsFormatter = false;
  @Input()
  @InputBoolean()
  public uiClickable = false;

  @Output("uiBlur")
  public readonly nzBlur = new EventEmitter();
  @Output("uiFocus")
  public readonly nzFocus = new EventEmitter();

  @ViewChild("inputElement", { static: true })
  public inputElement!: ElementRef<HTMLInputElement>;
  private lastValidValue = "";

  constructor(
    ngZone: NgZone,
    elementRef: ElementRef<HTMLElement>,
    cdr: ChangeDetectorRef,
    focusMonitor: FocusMonitor,
    renderer: Renderer2,
    @Optional() directionality: Directionality,
    destroy$: NzDestroyService,
    @Optional() nzFormStatusService?: NzFormStatusService,
    @Optional() nzFormNoStatusService?: NzFormNoStatusService
  ) {
    super(ngZone, elementRef, cdr, focusMonitor, renderer, directionality, destroy$, nzFormStatusService, nzFormNoStatusService);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    if (this.useThousandsFormatter) {
      this.nzFormatter = thousandsFormatter;
    }
  }

  public ngAfterViewInit(): void {
    super.ngAfterViewInit();

    addAttributeValue({
      element: this.inputElement.nativeElement,
      attribute: "aria-describedby",
      value: this.a11yDescribedby || (this.nzId && this.a11yDescription ? this.nzId + "-description" : null),
    });
  }

  public onInput(event: InputEvent): void {
    this.forbidAlphabeticValue(event);

    if (this.useThousandsFormatter) {
      this.formatThousands(event);
    }
  }

  private formatThousands(event: InputEvent): void {
    const inputElement = event.target as HTMLInputElement;
    const formattedValue = thousandsFormatter(inputElement.value);

    if (formattedValue !== inputElement.value) {
      inputElement.value = formattedValue;
    }
  }

  private forbidAlphabeticValue(event: InputEvent): void {
    const input = event.target as HTMLInputElement;
    const cursorPosition = input.selectionStart;
    const previousCursorPosition = cursorPosition - 1;
    const initialCursorPosition = 0;
    const text = input.value || "";
    // we want to replace any values, exclude digits, dot and dash.
    const sanitizedValue = text.replace(/[^\d.-]/g, "");

    if (input.value !== sanitizedValue) {
      input.value = sanitizedValue;
      input.setSelectionRange(previousCursorPosition, previousCursorPosition);
    }

    // handle the dot input and will set the cursor to the correct position
    if (event.data === "." && this.lastValidValue.includes(".")) {
      input.value = this.lastValidValue;
      input.setSelectionRange(previousCursorPosition, previousCursorPosition);
    }

    // handle the case when the user adds negative value and restrict it to the start of the input
    if (event.data === "-") {
      if (cursorPosition === initialCursorPosition) {
        input.value = "-";
        // checking if we try to add dash on place different than the beginning and if we already have negative value
      } else if ((cursorPosition !== 1 && cursorPosition !== initialCursorPosition) || this.lastValidValue.includes("-") || this.displayValue.toString().includes("-")) {
        input.value = this.lastValidValue || this.displayValue.toString();
        input.setSelectionRange(previousCursorPosition, previousCursorPosition);
      }
    }

    this.lastValidValue = input.value;
  }

  public down(e: MouseEvent | KeyboardEvent, ratio?: number): void {
    if (!this.isFocused) {
      this.focus();
    }

    if (this.nzDisabled || this.nzReadOnly) {
      this.stop();
      e.preventDefault();
      return;
    }

    this.step("down", e, ratio);
  }

  public up(e: MouseEvent | KeyboardEvent, ratio?: number): void {
    if (!this.isFocused) {
      this.focus();
    }

    if (this.nzDisabled || this.nzReadOnly) {
      this.stop();
      e.preventDefault();
      return;
    }

    this.step("up", e, ratio);
  }
}
