import { Directionality } from "@angular/cdk/bidi";
import { NgFor, NgIf, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, ContentChild, Input, Optional, TemplateRef } from "@angular/core";
import { UiDirectionVHType, UiSizeLDSType } from "@quantive/ui-kit/core";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzListComponent } from "ng-zorro-antd/list";
import { UiGridModule } from "../grid/grid.module";
import { UiLoadingIndicatorModule } from "../loading-indicator/loading-indicator.module";
import { UiListEmptyComponent } from "./components/list-empty/list-empty.component";
import { UiListFooterComponent } from "./components/list-footer/list-footer.component";
import { UiListHeaderComponent } from "./components/list-header/list-header.component";
import { UiListPaginationComponent } from "./components/list-pagination/list-pagination.component";
import { UiListLoadMoreDirective } from "./directives/list-load-more.directive";
import { UiListGrid } from "./list-models";

@Component({
  selector: "ui-list, [ui-list]",
  exportAs: "uiList",
  templateUrl: "./list.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    NgTemplateOutlet,
    UiListHeaderComponent,
    UiLoadingIndicatorModule,
    UiGridModule,
    UiListEmptyComponent,
    UiListFooterComponent,
    UiListPaginationComponent,
    NzOutletModule,
  ],
})
export class UiListComponent extends NzListComponent {
  @Input("uiDataSource") public nzDataSource?: NzSafeAny[];
  @Input("uiBordered") @InputBoolean() public nzBordered = false;
  @Input("uiGrid") public nzGrid?: UiListGrid | "" = "";
  @Input("uiHeader") public nzHeader?: string | TemplateRef<void>;
  @Input("uiFooter") public nzFooter?: string | TemplateRef<void>;
  @Input("uiItemLayout") public nzItemLayout: UiDirectionVHType = "horizontal";
  @Input("uiRenderItem") public nzRenderItem: TemplateRef<{ $implicit: NzSafeAny; index: number }> | null = null;
  @Input("uiLoading") @InputBoolean() public nzLoading = false;
  @Input("uiLoadMore") public nzLoadMore: TemplateRef<void> | null = null;
  @Input("uiPagination") public nzPagination?: TemplateRef<void>;
  @Input("uiSize") public nzSize: UiSizeLDSType = "default";
  @Input("uiSplit") @InputBoolean() public nzSplit = true;
  @Input("uiNoResult") public nzNoResult?: string | TemplateRef<void>;

  @ContentChild(UiListFooterComponent) public nzListFooterComponent!: UiListFooterComponent;
  @ContentChild(UiListPaginationComponent) public nzListPaginationComponent!: UiListPaginationComponent;
  @ContentChild(UiListLoadMoreDirective) public nzListLoadMoreDirective!: UiListLoadMoreDirective;

  constructor(@Optional() directionality: Directionality) {
    super(directionality);
  }
}
