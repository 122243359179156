<ng-container *ngFor="let group of searchResults">
  <div class="search-results-container" [ngClass]="{ 'search-page-padding-container': searchMode === 'search-page' }" data-test-id="search-result-element">
    <div class="section-title-wrapper">
      <div class="section-title">{{ getLocalization(group.collectionName) }}</div>
    </div>
    <ul class="list" [attr.data-test-id]="group.collectionName">
      <li
        class="section-list item-row"
        *ngFor="let item of group.items | slice: 0 : maxItemsPerGroup; let first = first; let last = last"
        (click)="onSearchItemClick(item)"
        (keydown.enter)="onSearchItemClick(item)"
        (keydown.space)="onSearchItemClick(item)"
        (keydown.arrowDown)="moveFocusTo('next'); $event.preventDefault()"
        (keydown.arrowUp)="moveFocusTo('prev'); $event.preventDefault()"
        (focusout)="onItemFocusout($event, { last, first })"
        tabindex="0"
        aria-describedby="item-type">
        <search-item class="selection-list" [item]="item" [searchMode]="searchMode"></search-item>
      </li>
    </ul>
    <div class="result-actions" id="result-actions" *ngIf="group.totalCount > (itemsLimit$ | async)">
      <button
        *ngIf="currentFacet === 'everywhere' && searchMode === 'global'; else nonEverywhere"
        [attr.data-test-id]="'view-more-results-for-' + group.collectionName"
        (click)="viewMoreResults(group.collectionName, group.totalCount); $event.stopPropagation()"
        (keydown.arrowDown)="moveFocusTo('next'); $event.preventDefault()"
        (keydown.arrowUp)="moveFocusTo('prev'); $event.preventDefault()"
        (focusout)="onBtnFocusout($event)"
        ui-button
        uiType="link">
        {{ "view_more_results" | localize }} ({{ getTotalCount(group.totalCount) }})
      </button>
      <ng-template #nonEverywhere>
        <ng-container *ngIf="!isRecent">
          <button
            *ngIf="(currentFacet === 'everywhere' && searchMode === 'search-page') || (currentFacet !== 'everywhere' && searchMode === 'global')"
            [attr.data-test-id]="'view-all-results-for-' + group.collectionName"
            (keydown.arrowDown)="moveFocusTo('next'); $event.preventDefault()"
            (keydown.arrowUp)="moveFocusTo('prev'); $event.preventDefault()"
            (click)="viewAllResults(group.totalCount, { collectionName: group.collectionName, isSearchPage: searchMode === 'search-page' })"
            ui-button
            uiType="link">
            {{ "view_all_results" | localize }} ({{ group.totalCount }})
          </button>
          <button
            *ngIf="searchMode === 'global'"
            [attr.data-test-id]="'search-everywhere-from-' + group.collectionName"
            (keydown.arrowDown)="moveFocusTo('next'); $event.preventDefault()"
            (keydown.arrowUp)="moveFocusTo('prev'); $event.preventDefault()"
            (focusout)="onBtnFocusout($event)"
            (click)="changeFacetToEverywhere(); $event.stopPropagation()"
            ui-button
            uiType="link">
            {{ "or_search_everywhere" | localize }}
          </button>
        </ng-container>
      </ng-template>
    </div>
  </div>
  <ui-divider class="sp-mb-0 sp-mt-0" *ngIf="currentFacet === 'everywhere'" uiType="horizontal"></ui-divider>
</ng-container>
