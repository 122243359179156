import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import { FormItemBaseComponent } from "@webapp/shared/form/components/form-items/form-item-base.component";
import { FormModule } from "@webapp/shared/form/form.module";
import { TaskStatus } from "@webapp/tasks/models/tasks.models";
import { ExternalSystem } from "../../okr-tasks.models";
import { TaskStatusModule } from "../task-status/task-status.module";

@Component({
  selector: "task-status-form-item",
  templateUrl: "./task-status-form-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FormItemBaseComponent, useExisting: forwardRef(() => TaskStatusFormItemComponent) }],
  standalone: true,
  imports: [FormModule, TaskStatusModule],
})
// GVS-44106 specify the type to be only TaskStatus
export class TaskStatusFormItemComponent extends FormItemBaseComponent<string | TaskStatus> {
  @Input() public externalSystem: ExternalSystem;
  @Input() public archived: boolean;
}
