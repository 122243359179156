<ui-select-top-control
  #origin="cdkOverlayOrigin"
  [class.top-control-large]="nzSize === 'large'"
  [uiPlaceHolderTemplate]="uiPlaceHolderTemplate"
  [nzId]="nzId"
  [open]="nzOpen"
  [disabled]="nzDisabled"
  [readonly]="readonly"
  [deletable]="(focused || nzOpen) && !readonly"
  [mode]="nzMode"
  [maxTagPlaceholder]="nzMaxTagPlaceholder"
  [removeIcon]="nzRemoveIcon"
  [placeholder]="nzPlaceHolder"
  [maxTagCount]="nzMaxTagCount"
  [customTemplate]="nzCustomTemplate"
  [autoHideSearch]="nzAutoClearSearchValue"
  [class.select-content-only]="contentOnly"
  [visibleTemplateOverflow]="visibleTemplateOverflow"
  [tokenSeparators]="nzTokenSeparators"
  [hideSelectedTags]="hideSelectedTags"
  [showSearch]="nzShowSearch"
  [autofocus]="nzAutoFocus"
  [listOfTopItem]="listOfTopItem"
  [focusedValue]="topControlFocus.currentValue"
  [activatedValue]="activatedValue"
  [a11yLabel]="a11yLabel"
  [a11yRequired]="a11yRequired"
  [a11yLabelledby]="a11yLabelledby"
  [renderRemoveChipIcon]="renderRemoveChipIcon"
  [a11yDescribedby]="a11yDescribedby"
  [a11yDescription]="searchInputAriaDescription"
  [listboxId]="listboxId"
  [selectSearchInputTabIndex]="selectSearchInputTabIndex"
  [problematicSelectItems]="problematicSelectItems"
  [class.error-select-control]="errorOcurred"
  (inputValueChange)="onInputValueChange($event)"
  (tokenize)="onTokenSeparate($event)"
  (deleteItem)="onItemDelete($event)"
  (keydown)="onKeyDown($event)"
  cdkOverlayOrigin></ui-select-top-control>
<ui-select-error *ngIf="errorOcurred"></ui-select-error>
<ui-select-arrow *ngIf="nzShowArrow" [loading]="nzLoading" [search]="nzOpen && nzShowSearch" [suffixIcon]="nzSuffixIcon"></ui-select-arrow>
<ui-select-clear *ngIf="nzAllowClear && !nzDisabled && !readonly && listOfValue.length" [clearIcon]="nzClearIcon" (clear)="onClearSelection()"></ui-select-clear>
<ng-template
  [cdkConnectedOverlayHasBackdrop]="nzBackdrop"
  [cdkConnectedOverlayMinWidth]="$any(nzDropdownMatchSelectWidth ? null : triggerWidth)"
  [cdkConnectedOverlayWidth]="$any(nzDropdownMatchSelectWidth ? triggerWidth : null)"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayTransformOriginOn]="'.ant-select-dropdown'"
  [cdkConnectedOverlayPanelClass]="nzDropdownClassName!"
  [cdkConnectedOverlayOpen]="nzOpen"
  (overlayOutsideClick)="onClickOutside($event)"
  (detach)="setOpenState(false)"
  (positionChange)="onPositionChange($event)"
  cdkConnectedOverlay
  nzConnectedOverlay>
  @if (showOptions) {
    <ui-option-container
      [id]="listboxId"
      [loading]="nzLoading"
      [attr.aria-multiselectable]="nzMode === 'multiple' || nzMode === 'tags'"
      [attr.aria-labelledby]="a11yLabelledby"
      [attr.aria-label]="a11yLabel"
      [ngStyle]="nzDropdownStyle"
      [itemSize]="nzOptionHeightPx"
      [maxItemLength]="nzOptionOverflowSize"
      [matchWidth]="nzDropdownMatchSelectWidth"
      [class.ant-select-dropdown-placement-bottomLeft]="dropDownPosition === 'bottomLeft'"
      [class.ant-select-dropdown-placement-topLeft]="dropDownPosition === 'topLeft'"
      [style.display]="showEmptyDropdown || listOfContainerItem.length ? 'block' : 'none'"
      [listOfContainerItem]="listOfContainerItem"
      [menuItemSelectedIcon]="selectedItemIcon"
      [notFoundContent]="nzNotFoundContent"
      [activatedValue]="activatedValue"
      [listOfSelectedValue]="listOfValue"
      [dropdownRender]="nzDropdownRender"
      [compareWith]="compareWith"
      [mode]="nzMode"
      [keyboardActivatedValue]="keyboardActivatedValue"
      [reserveScrollbarSpace]="reserveScrollbarSpace"
      (keydown)="onKeyDown($event)"
      (itemClick)="onItemSelect($event)"
      (scrollToBottom)="nzScrollToBottom.emit()"
      role="listbox"
      tabindex="-1"></ui-option-container>
  }
</ng-template>

<ng-template #defaultSelectedItemIconTemplate>
  <i class="fs-16px" ui-icon uiType="check" uiTheme="outline"></i>
</ng-template>
