import { Injectable } from "@angular/core";
import { Observable, filter } from "rxjs";
import { Assignee } from "../models/assignee.models";
import { AssigneesCache } from "./assignees-cache";

// Assignees are populated in Redux by Angular.js,
// so for now we don't need an API service in Angular
@Injectable({ providedIn: "root" })
export class AssigneesRepository {
  constructor(private cache: AssigneesCache) {}

  public getMap$(): Observable<Map<string, Assignee>> {
    return this.cache.get$().pipe(filter(Boolean));
  }
}
