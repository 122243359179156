import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { SuggestedInsight, SuggestedInsights } from "@webapp/data-story/models/insights.models";
import { MarketplaceInsightTemplate } from "@webapp/data-story/models/marketplace-facade.models";

@Component({
  selector: "suggested-automation",
  templateUrl: "./suggested-automation.component.html",
  styleUrls: ["./suggested-automation.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestedAutomationComponent {
  @Input() public suggestedInsights: SuggestedInsights;

  @Output() public readonly selectSuggestedTemplate = new EventEmitter<MarketplaceInsightTemplate>();
  @Output() public readonly selectSuggestedInsight = new EventEmitter<SuggestedInsight>();
  @Output() public readonly openBrowseConnectionsModal = new EventEmitter<void>();

  public trackById<T extends { id: string }>(index: number, template: T): string {
    return template.id;
  }
}
