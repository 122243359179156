import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UiChipModule } from "@quantive/ui-kit/chip";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { IndicatorModule } from "@webapp/shared/indicator/indicator.module";
import { UiFormModule } from "@webapp/ui/form/form.module";
import { UiGridModule } from "@webapp/ui/grid/grid.module";
import { UiTreeSelectModule } from "@webapp/ui/tree-select/tree-select.module";
import { UiTypographyModule } from "@webapp/ui/typography/typography.module";
import { MultiSelectorComponent } from "./multi-selector.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AssigneesModule,
    LocalizationModule,
    UiIconModule,
    UiChipModule,
    IndicatorModule,
    UiTreeSelectModule,
    UiTypographyModule,
    UiFormModule,
    UiGridModule,
    UiTooltipModule,
  ],
  declarations: [MultiSelectorComponent],
  exports: [MultiSelectorComponent],
})
export class MultiSelectorModule {}
