import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AuthenticationResolverServiceProvider } from "@webapp/authentication/providers/authentication-resolver.provider";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";
import { CurrentUserRepository } from "@webapp/users";
import { HttpInterceptorsModule } from "./http/interceptors/http-interceptors.module";
import { RootScopeServiceProvider } from "./hybrid/root-scope-service.provider";
import { CoreRoutingModule } from "./routing/core-routing.module";
import { TracingService } from "./tracing/services/tracing.service";
import { ClipboardService } from "./util/services/clipboard.service";

@NgModule({
  imports: [CommonModule, HttpInterceptorsModule, CoreRoutingModule],
  providers: [AuthenticationResolverServiceProvider, UserProfileService, CurrentUserRepository, ClipboardService, RootScopeServiceProvider],
})
export class CoreModule {
  constructor(tracingService: TracingService) {
    tracingService.initSessionID();
  }
}
