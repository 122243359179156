export enum TagAnalyticsEvent {
  TagsAssigned = "Tags Assigned",
  TagsUnassigned = "Tags Unassigned",
}

export enum TagTargetType {
  KeyResult = "Key Result",
  Objective = "Objective",
  Insightboard = "Insightboard",
}
