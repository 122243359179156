import { module } from "angular";
import upgrade from "@gtmhub/core/upgrade/module";
import env from "@gtmhub/env/env.module";
import { BadgeAssignmentComponent } from "@webapp/badges/components/badge-assignment/badge-assignment.component";
import { BadgesActions } from "./redux/badges-actions";

const mod = module("badges-core", [upgrade, env]);
mod.service("BadgesActions", BadgesActions);
mod.downgradeComponent("ghBadgeAssignment", { component: BadgeAssignmentComponent, propagateDigest: false });

export default mod.name;
