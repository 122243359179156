const isString = (str): boolean => str != null && typeof str.valueOf() === "string";

const matchBullet = /[.-]$/; // matches . or - at the end of the string
const matchLinkTag = /^\[/; // matches [ at the beginning of the string

const extractElementFromCollection = (wrappers: string[], regex): string => {
  return wrappers.filter((wr) => regex.test(wr.trim())).join("");
};

const removeElementFromCollection = (wrappers: string[], regex): string[] => {
  return wrappers.filter((wr) => !regex.test(wr.trim()));
};

const createTags = (wrappers: string[]): { openTag: string; closeTag: string } => {
  const openTag = wrappers.join("");
  const closeTag = [...wrappers].reverse().join("");

  return { openTag, closeTag };
};

const markDownStringBuilder = (text: string, tags: { openTag: string; closeTag: string }, bullet: string | undefined = undefined, linkTags: string[] = []): string => {
  if (bullet) {
    if (linkTags.length) {
      return handleBlancSpaces(text, `${bullet}${linkTags[0]}${tags.openTag}${text.trim()}${tags.closeTag}${linkTags[1]}`);
    }
    return handleBlancSpaces(text, `${bullet}${tags.openTag}${text.trim()}${tags.closeTag}`);
  }

  if (linkTags.length) {
    return handleBlancSpaces(text, `${linkTags[0]}${tags.openTag}${text.trim()}${tags.closeTag}${linkTags[1]}`);
  }

  return handleBlancSpaces(text, `${tags.openTag}${text.trim()}${tags.closeTag}`);

  function handleBlancSpaces(text: string, markdownString: string): string {
    const isBlankSpace = text && !text.trim();
    const hasBlankSpaceBefore = text !== text.trimLeft();
    const hasBlankSpaceAfter = text !== text.trimRight();

    if (isBlankSpace) {
      return " ";
    }

    if (hasBlankSpaceBefore && hasBlankSpaceAfter) {
      return ` ${markdownString} `;
    }

    if (hasBlankSpaceBefore) {
      return ` ${markdownString}`;
    }

    if (hasBlankSpaceAfter) {
      return `${markdownString} `;
    }

    return markdownString;
  }
};

const pull = (arr, ...removeList): unknown => {
  const removeSet = new Set(removeList);

  return arr.filter((it) => !removeSet.has(it));
};

let id = 0;

export class Node {
  public id: number;
  public open: string;
  public close: string;
  public text: string;
  public children: Node[];
  public parent: Node;

  constructor(data?) {
    this.id = ++id;
    if (Array.isArray(data)) {
      this.open = data[0];
      this.close = data[1];
    } else if (isString(data)) {
      this.text = data;
    }
    this.children = [];
  }

  public append(e): void {
    if (!(e instanceof Node)) {
      e = new Node(e);
    }
    if (e.parent) {
      e.parent.children = pull(e.parent.children, e);
    }
    e.parent = this;
    this.children = this.children.concat(e);
  }

  public render(wrappers = []): string {
    let text = "";

    wrappers = this.updateWrappers(wrappers);

    // if we reach a node with text, we add its text value
    // to the string that the render() method is building
    if (this.text) {
      let bullet;
      const linkTags = [];

      // extracting bullet formatting from wrappers
      // because bullets are not symmetrical tags
      if (wrappers.find((wr) => matchBullet.test(wr.trim()))) {
        bullet = extractElementFromCollection(wrappers, matchBullet);
        wrappers = removeElementFromCollection(wrappers, matchBullet);
      }

      // extracting link formatting from wrappers
      // because link tags are not symmetrical tags
      if (wrappers.find((wr) => matchLinkTag.test(wr.trim()))) {
        const linkWrapper = extractElementFromCollection(wrappers, matchLinkTag);
        wrappers = removeElementFromCollection(wrappers, matchLinkTag);
        linkTags.push(linkWrapper[0]);
        linkTags.push(linkWrapper.substring(1));
      }

      const tags = createTags(wrappers);

      text += markDownStringBuilder(this.text, tags, bullet, [...linkTags]);
    }

    let isBulletInserted = false;
    for (let i = 0; i < this.children.length; i++) {
      // we are adding bullet tag only to the first sibling with text
      // otherwise we have multiple bullets on one line if we change styles (bold, italic, etc. - produces new sibling nodes)
      // ex. << 1. *firstWord* _secondWord_ >>
      if (!isBulletInserted) {
        if (this.children[i].text || this.children[i].children.length) {
          isBulletInserted = true;
        }
        text += this.children[i].render([...wrappers]);
      } else {
        text += this.children[i].render(removeElementFromCollection(wrappers, matchBullet));
      }
    }

    // closing tags are generally handled as symmetrical derivatives of opening tags inside markdownStringBuilder
    // here we are handling only end of lines
    if (this.close === "\n") {
      text += this.close;
    }

    return text;
  }

  private updateWrappers(wrappers: string[]): string[] {
    const newWrappers = [...wrappers];

    // we are collecting wrappers through the tree
    // not allowing to have repetition of opening wrapper tags
    if (this.open && newWrappers.indexOf(this.open) < 0) {
      // the link wrapper consists of open tag '[' and close tag '](link)'
      // we are collecting both sides and will split them later
      if (this.open === "[") {
        newWrappers.push(`${this.open}${this.close}`);
      } else {
        // the rest of the wrappers are symmetrical so we need only open tags
        // and later when we build the string we will simply reverse the closing tags
        newWrappers.push(this.open);
      }
    }

    return newWrappers;
  }
}
