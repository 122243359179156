import { HttpContext } from "@angular/common/http";
import { QueryParams } from "@webapp/core/abstracts/models/query-params.model";

export class RequestConfig {
  public url? = "";
  public queryParams?: QueryParams = {};
  public headers?: { [header: string]: string | string[] };
  public context?: HttpContext;
  public body?: unknown;
}
