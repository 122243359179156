import { Task } from "@webapp/tasks/models/tasks.models";

export function jiraIssueLink() {
  return function (task: Task | undefined) {
    if (!task || !task.externalId || !task.metadata) {
      return "";
    }

    const portalUrl = task.externalId.split("/rest")[0];
    return portalUrl + "/browse/" + task.metadata["issue-key"];
  };
}
