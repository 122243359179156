import { IUIError } from "@gtmhub/error-handling";
import { toIdMap } from "@gtmhub/util";
import { IBadge, IBadgesIdMap } from "..";
import { BadgesAction } from "./models";

export interface IBadgesStoreState {
  badges: IBadgesState;
}

interface IBadgesState {
  isFetched: boolean;
  isFetching: boolean;
  items: IBadge[];
  map: IBadgesIdMap;
  error: IUIError;
}

export function badgesReducer(state: IBadgesState = { isFetched: false, isFetching: false, items: [], map: {}, error: null }, action: BadgesAction): IBadgesState {
  switch (action.type) {
    case "REQUEST_BADGES":
      return { ...state, isFetching: true, isFetched: false, error: null };
    case "RECEIVE_BADGES": {
      return { ...state, isFetched: true, isFetching: false, items: action.items, map: toIdMap(action.items) };
    }
    case "ERROR_RECEIVE_BADGES":
      return { ...state, error: action.error, isFetching: false, isFetched: false };
    default: {
      return state;
    }
  }
}
