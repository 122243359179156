import { storage } from "@gtmhub/core/storage";
import { ITag } from "../models";

const screensSettingsStorageKey = "recentTags";
const RECENTLY_USED_TAGS_LIMIT = 20;
const storeRecentlyUsedTags = (tags: string[], limit = RECENTLY_USED_TAGS_LIMIT): string[] => {
  const filtered = tags.slice(0, limit);
  storage.set<string[]>(screensSettingsStorageKey, filtered);
  return filtered;
};
export const getRecentlyUsedTags = (): string[] => storage.get<string[]>(screensSettingsStorageKey) || [];
export const addRecentlyUsedTag = (tag: ITag): string[] => {
  const tags = getRecentlyUsedTags();
  const existingIndex = tags.indexOf(tag.title);
  if (existingIndex > -1) {
    tags.splice(existingIndex, 1);
  }
  tags.unshift(tag.title);
  return storeRecentlyUsedTags(tags);
};
