import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject } from "rxjs";
import { ParentType } from "@gtmhub/automation/controllers/action";
import { QuantivePlusSuggestions } from "@gtmhub/goals/models";
import { IQuantivePlusAcceptedTask, IQuantivePlusSuggestionStatus, minTasksCountNotToFetchSuggestions } from "@webapp/platform-intelligence/quantive-plus/models";
import { PiStateProcessorInstance } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.models";
import { PiStateProcessorService } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.service";
import { PiSuggestionSharedGoalStateService } from "@webapp/platform-intelligence/shared/services/pi-suggestion-shared-goal-state.service";
import { PiSuggestionSharedMetricStateService } from "@webapp/platform-intelligence/shared/services/pi-suggestion-shared-metric-state.service";
import { PiTrackingFlowNameEnum } from "@webapp/platform-intelligence/shared/utils/pi-tracking";
import { Task } from "@webapp/tasks/models/tasks.models";

@UntilDestroy()
@Component({
  selector: "suggestion-drawer-tasks[item][parentType][status]",
  templateUrl: "./tasks.component.html",
  styleUrls: ["./tasks.component.less"],
})
export class SuggestionDrawerTasksComponent implements OnInit, OnDestroy {
  @Input() public suggestions: QuantivePlusSuggestions;
  @Input() public taskList: Task[];

  @Input() public item;
  @Input() public parentType: ParentType;
  @Input() public status: IQuantivePlusSuggestionStatus;
  @Input() public flowName: PiTrackingFlowNameEnum.ObjectiveDetailsViewAllSuggestionSidePanel | PiTrackingFlowNameEnum.KeyResultDetailsViewAllSuggestionsSidePanel;

  public minTasksCountNotToFetchSuggestions = minTasksCountNotToFetchSuggestions;
  public tasksInstance: PiStateProcessorInstance;
  private flowId: string;
  public onDestroy$ = new Subject<void>();

  constructor(
    private piSuggestionSharedGoalStateService: PiSuggestionSharedGoalStateService,
    private piSuggestionSharedMetricStateService: PiSuggestionSharedMetricStateService,
    private piStateProcessorService: PiStateProcessorService
  ) {}

  public ngOnInit(): void {
    const parentType = this.parentType === "goal" ? "objective" : "KR";

    this.tasksInstance = this.piStateProcessorService.createNewInstance({
      type: this.parentType,
      subType: "task",
      isInline: false,
      iconType: "task",
      iconTheme: "fill",
      improveSuggestionContext: {
        entityId: this.item.id,
        parentId: parentType === "KR" ? this.item.id : this.item.goalId,
        parentType: parentType,
      },
      destroyOn$: this.onDestroy$,
    });

    if (this.parentType === "goal") {
      this.piSuggestionSharedGoalStateService
        .getQuantiveSuggestionsFlowId()
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (flowId) => {
            this.flowId = flowId;
            this.piStateProcessorService.assigneInstanceFlowProperties(this.tasksInstance.id, { flowId: this.flowId, flowName: this.flowName });
          },
        });
    }

    if (this.parentType === "metric") {
      this.piSuggestionSharedMetricStateService
        .getQuantiveSuggestionsFlowId()
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (flowId) => {
            this.flowId = flowId;
            this.piStateProcessorService.assigneInstanceFlowProperties(this.tasksInstance.id, { flowId: this.flowId, flowName: this.flowName });
          },
        });
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  public onTaskCreated(args: IQuantivePlusAcceptedTask): void {
    if (this.parentType === "goal") {
      this.piSuggestionSharedGoalStateService.setSingleTask(args);
    }

    if (this.parentType === "metric") {
      this.piSuggestionSharedMetricStateService.setSingleTask(args);
    }
  }
}
