import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiDropdownModule } from "@quantive/ui-kit/dropdown";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { GuidedOKRsModule } from "@webapp/platform-intelligence/guided-okrs/guided-okrs.module";
import { DefaultSessionResolverService } from "@webapp/sessions/services/default-session-resolver.service";
import { DropdownModule } from "@webapp/shared/dropdown/dropdown.module";
import { CreateOkrDropdownButtonComponent } from "./create-okr-dropdown-button.component";

@NgModule({
  imports: [CommonModule, LocalizationModule, DropdownModule, UiButtonModule, UiIconModule, GuidedOKRsModule, UiDropdownModule],
  providers: [DefaultSessionResolverService],
  declarations: [CreateOkrDropdownButtonComponent],
  exports: [CreateOkrDropdownButtonComponent],
})
export class CreateOkrDropdownButtonModule {}
