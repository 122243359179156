import { formatUnit } from "@gtmhub/feed/utils";
import { toFixedWithoutRounding } from "@gtmhub/shared/utils";
import { roundNumber } from "@webapp/shared/utils/number";

export interface MetricFormat {
  prefix: string;
  suffix: string;
}

export function getFormattedMetricValue(
  value: number,
  format: MetricFormat,
  options: { maxFractionSize?: number; withoutRounding?: boolean; withThousandsSeparator?: boolean } = {}
): string {
  if (!value && value !== 0) {
    return null;
  }

  if (!options || (!options.maxFractionSize && options.maxFractionSize !== 0)) {
    options = {
      maxFractionSize: 2,
    };
  }

  let resultValue: string | number;

  if (Math.floor(value) === value) {
    resultValue = roundNumber(value, 0);
  } else {
    resultValue = options.withoutRounding ? toFixedWithoutRounding(value, options.maxFractionSize) : roundNumber(value, options.maxFractionSize);
  }

  if (options.withThousandsSeparator) {
    resultValue = resultValue.toLocaleString();
  }

  const prefix: string = format && format.prefix ? formatUnit({ unit: format.prefix, isPrefix: true }) : "";
  const suffix: string = format && format.suffix ? formatUnit({ unit: format.suffix, isPrefix: false }) : "";

  return `${prefix}${resultValue}${suffix}`.trim();
}
