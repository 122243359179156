import { AccountType, IAccount } from "@gtmhub/core";
import { IAppConfig } from "@gtmhub/env";
import { AccountResolverService } from "@webapp/accounts";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { LoggingService } from "@webapp/core/logging/services/logging.service";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { IProfile } from "@webapp/user-profile/models/user-profile.models";

declare const delighted: Survey;

interface Survey {
  survey(surveyConfig: Partial<SurveyConfig>): void;
}
interface SurveyConfig {
  createdAt: string;
  email: string;
  properties: {
    userId: string;
    tags?: string[];
    customerSuccessManaged: string;
    locale: string;
    event?: string;
  };
  minTimeOnPage: number;
  recurringPeriod?: number;
  onRespond(surveyResult: SurveyResult): void;
  onShow(): void;
}

interface SurveyResult {
  score: number;
}

export class SurveyService {
  private userProfile: IProfile;
  private userId: string;
  private loggingService: LoggingService;

  public static $inject = ["appConfig", "AnalyticsService", "AccountResolverService", "FeatureTogglesFacade"];

  constructor(
    private appConfig: IAppConfig,
    private analyticsService: AnalyticsService,
    private accountResolverService: AccountResolverService,
    private featureToggleService: FeatureTogglesFacade
  ) {
    this.userProfile = JSON.parse(localStorage.getItem("profile"));
    this.userId = localStorage.getItem("userId");
    this.loggingService = new LoggingService(appConfig.logging, "spa");
  }

  public execDelightedSurvey(): void {
    const account = this.accountResolverService.getAccountData();

    this.featureToggleService.isFeatureAvailable(FeatureFlag.DisableDelightedSurveys).then((surveysDisabled) => {
      if (surveysDisabled) {
        return;
      }

      if (this.accountIsNotTrailOrInternal(account) && this.isProfileValid() && this.calcDaysDiff(this.userProfile) && this.isLoginsCountEnough()) {
        const customLocaleSuffix = "-x-gtmhub";
        const surveyConfig: Partial<SurveyConfig> = this.getDefaultSurveyConfig(customLocaleSuffix, account);
        surveyConfig.properties.tags = this.appConfig.delighted.tags;
        surveyConfig.onRespond = (surveyResult: SurveyResult) => this.trackResponse(surveyResult, "NPS", "");
        this.loggingService.logMessage("User sent to delighted survey", { level: "INFO" });
        delighted.survey(surveyConfig);
      }
    });
  }

  private trackResponse(surveyResult: SurveyResult, surveyType: string, eventName: string) {
    const account = this.accountResolverService.getAccountData();
    this.analyticsService.track("Survey Answered", {
      score: surveyResult.score,
      cs_managed: account.csManaged,
      type: surveyType,
      trigger_event: eventName,
      logins_count: Number(localStorage.getItem("loginsCount")),
    });
  }

  private getDefaultSurveyConfig(customLocaleSuffix: string, account: IAccount) {
    const customerSuccessManaged = account.csManaged ? "Yes" : "No";

    const userLocale = this.getLocaleSetting(this.userProfile, customLocaleSuffix);
    return {
      createdAt: this.userProfile.created,
      email: this.userProfile.email,
      properties: {
        userId: this.userId,
        customerSuccessManaged,
        locale: userLocale,
      },
      minTimeOnPage: 15,
    };
  }

  private isProfileValid(): boolean {
    return !!this.userId && !!this.userProfile && !!this.userProfile.email;
  }
  private getLocaleSetting(userProfile: IProfile, customLocaleSuffix: string): string {
    const userLanguage = userProfile.language ? userProfile.language.toLowerCase() : "";

    // this is delighted custom locale for our customized comment's placeholder text
    // delighted support can add texts on their end (not possible via their API)
    let userLocale = "en" + customLocaleSuffix;
    switch (userLanguage) {
      case "german":
        userLocale = "de" + customLocaleSuffix;
        break;
      case "bulgarian":
        userLocale = "bg" + customLocaleSuffix;
        break;
      case "chinese":
        userLocale = "zh-CN" + customLocaleSuffix;
        break;
      case "spanish":
        userLocale = "es" + customLocaleSuffix;
        break;
      case "french":
        userLocale = "fr" + customLocaleSuffix;
        break;
      case "portugese":
        userLocale = "pt" + customLocaleSuffix;
        break;
      default:
        userLocale = "en" + customLocaleSuffix;
        break;
    }
    return userLocale;
  }

  private accountIsNotTrailOrInternal(account: IAccount): boolean {
    return account && account.type !== AccountType.TrialAccount && account.type !== AccountType.InternalAccount;
  }

  private calcDaysDiff(userProfile: IProfile): boolean {
    // we want to show the survey after 30 days from the user create
    const userDateCreated = new Date(userProfile.created).getTime();
    const todaysDate = new Date().getTime();
    const daysDiff = (todaysDate - userDateCreated) / (1000 * 3600 * 24);
    const daysToShowSurveyAfterUserCreated = 30;

    return daysDiff > daysToShowSurveyAfterUserCreated;
  }

  private isLoginsCountEnough(): boolean {
    const loginsCount = Number(localStorage.getItem("loginsCount"));
    const previousLoginsRequired = 4;

    if (loginsCount) {
      return loginsCount > previousLoginsRequired;
    }

    // returning true if for some reason loginsCount is not set to localStorage to show the survey
    return true;
  }
}
