<div class="custom-unit-container" role="dialog">
  <div [attr.aria-label]="'all_required_fields_are_marked_with_an_asterisk' | localize" role="dialog">
    <p class="custom-unit-container-sub-heading">{{ "all_required_fields_are_marked_with_an_asterisk" | localize }}</p>
  </div>
  <div class="preview-custom-unit">
    <p>Preview</p>
    <p>
      <span class="prefix-unit" *ngIf="affix === 'prefix'">{{ unit }}</span>
      <span>1,000</span>
      <span class="suffix-unit" *ngIf="affix === 'suffix'">{{ unit }}</span>
    </p>
  </div>
  <div class="custom-unit-creator">
    <input [(ngModel)]="unit" ui-input type="text" placeholder="Enter unit" />
    <ui-radio-group [(ngModel)]="affix">
      <label ui-radio uiValue="suffix">{{ "display_after_number" | localize }}</label>
      <label ui-radio uiValue="prefix">{{ "display_before_number" | localize }}</label>
    </ui-radio-group>
  </div>
</div>
