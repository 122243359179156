import { IComponentOptions, IController, IOnChangesObject } from "angular";

export interface IFullNameEntity {
  id?: string;
  isActive?: boolean;
  firstName?: string;
  lastName?: string;
  names?: string;
  name?: string;
  fullName?: string;
}

export interface IFullNameSettings {
  multiline?: boolean;
  center?: boolean;
  maxLength?: number;
  deactivatedLabel?: boolean;
}

const defaultSettings: IFullNameSettings = {
  multiline: false,
  center: true,
  maxLength: Infinity,
  deactivatedLabel: false,
};

export class FullNameCtrl implements IController {
  get fullName(): string {
    return this.fullNameParsed;
  }
  private fullNameParsed = "";

  public isDeleted = false;
  public isDeactivated = false;

  // Bindings
  public entity: IFullNameEntity;
  public settings: IFullNameSettings;
  public searchTerm: string;

  public $onInit(): void {
    this.settings = { ...defaultSettings, ...this.settings };
    this.init();
  }

  public $onChanges({ entity, settings }: IOnChangesObject): void {
    if (entity.isFirstChange()) {
      return;
    }

    this.entity = entity.currentValue;
    if (settings && settings.currentValue) {
      this.settings = { ...defaultSettings, ...settings.currentValue };
    }
    this.init();
  }

  private getFullName(): string {
    if (!this.entity) {
      return "";
    }
    let name = this.entity.names || this.entity.name || this.entity.fullName || "";
    if (this.entity.firstName !== undefined && this.entity.lastName !== undefined) {
      name = `${this.entity.firstName} ${this.entity.lastName}`.trim();
    }
    if (name.length > this.settings.maxLength) {
      return `${name.slice(0, this.settings.maxLength)}...`;
    }

    return this.strip(name);
  }

  private init(): void {
    if (!this.entity) {
      this.isDeleted = true;
      return;
    }

    this.fullNameParsed = this.getFullName();
    this.isDeleted = !this.fullNameParsed && !this.entity.id;
    this.isDeactivated = this.entity.isActive === false;
  }

  private strip(htmlString: string): string {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  }
}

export const FullNameComponent: IComponentOptions = {
  controller: FullNameCtrl,
  template: require("./full-name.component.html"),
  bindings: {
    entity: "<",
    settings: "<?",
    searchTerm: "@?",
  },
};
