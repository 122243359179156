import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HubFilter, UpdatedFilterParams } from "@webapp/filters/models/hub-filter.models";
import { IReportFilter } from "@webapp/insightboards/models";
import { HubFiltersApiService } from "./hub-filters.api.service";

@Injectable({ providedIn: "root" })
export class HubFiltersRepository {
  constructor(private hubFiltersApiService: HubFiltersApiService) {}

  public createFilter$(filter: HubFilter | IReportFilter): Observable<HubFilter | IReportFilter> {
    return this.hubFiltersApiService.post$(filter);
  }

  public getFilters$(type: string): Observable<HubFilter[] | IReportFilter[]> {
    return this.hubFiltersApiService.get$("", { queryParams: { filterType: type } });
  }

  public getFilter$(filterId: string): Observable<HubFilter> {
    return this.hubFiltersApiService.get$(filterId);
  }

  public deleteFilter$(filterId: string): Observable<void> {
    return this.hubFiltersApiService.delete$(filterId);
  }

  public patchFilter$(id: string, updatedFilterParams: UpdatedFilterParams): Observable<void> {
    return this.hubFiltersApiService.patch$(id, updatedFilterParams);
  }
}
