import { IHttpService, IPromise } from "angular";
import { EnvironmentService } from "@gtmhub/env";

export class FeatureUsageService {
  public static $inject = ["$http", "EnvironmentService"];

  constructor(
    private $http: IHttpService,
    private env: EnvironmentService
  ) {}

  public getFeatureUsageForUserId(accountId: string, edition: string): IPromise<{ features: [] }> {
    const url = this.env.getApiEndpoint("/accounts/" + accountId + "/used-features/" + edition);

    return this.$http.get<{ features: [] }>(url).then((response) => response.data);
  }
}
