import { IAttributes, IAugmentedJQuery, ICompileService, IDirective, IDirectiveFactory, IScope } from "angular";

/**
 * @example <button gh-button size="large" kind="secondary" icon="plus" ui-sref="..." ng-disabled="...">My Button</button>
 * @example <button gh-button size="small" kind="destructive" ng-click="myDeleteMethod()">My Red Button</button>
 * @example <button gh-button kind="link" icon="plus" ng-click="addSomething()">Add Something</button>
 * @param size "large" | "small" | "tiny": size is not needed for link buttons
 * @param kind "primary" | "secondary" | "destructive" | "link"
 * @param icon "check" | "plus" | "export-excel" | full list of icons with their names available at link below
 */
export class Button implements IDirective {
  public restrict = "A";

  constructor(private $compile: ICompileService) {}

  public link(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes): void {
    const buttonHtml = element.html();
    element.html(null);

    const sizeClass: string = attrs.size || "";
    const buttonClasses = attrs.icon ? `${sizeClass} ${attrs.kind} iconed` : `${sizeClass} ${attrs.kind}`;
    element.addClass(buttonClasses);

    const leadingIconTemplate = attrs.icon ? `<div class="leading-icon gh-font-icon-${attrs.icon}"></div>` : "";
    const dropdownIconTemplate = "uibDropdownToggle" in attrs ? `<div class="gh-font-icon-arrow-down-small"></div>` : "";

    const template = `
      <div class="content-wrapper">
        ${leadingIconTemplate}
        <div class="text">${buttonHtml}</div>
        ${dropdownIconTemplate}
      </div>
    `;
    const html = this.$compile(template)(scope);
    element.append(html);
  }

  public static factory(): IDirectiveFactory {
    const directive = ($compile: ICompileService) => new Button($compile);
    directive.$inject = ["$compile"];
    return directive;
  }
}
