import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { MultiAccount } from "@webapp/accounts/models/multi-account/multi-account.models";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { MultiAccountDTO } from "../../models/multi-account/multi-account.models";
import { MultiAccountsState } from "./multi-account-state.service";

@Injectable({
  providedIn: "root",
})
export class MultiAccountsApiService extends BaseApiService<MultiAccount, MultiAccountDTO, MultiAccountsState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, multiAccountsState: MultiAccountsState) {
    super("users", { ...new ApiVersionModel("v1") }, httpClient, appConfig, multiAccountsState);
  }

  public getMultiAccountsEndpoint(clientId: string): string {
    return `${this.getBasePath(HttpActions.get)}/managed-accounts/${clientId}`;
  }

  public getAccountInvitationEndpoint(invitationId: string): string {
    return `${this.getBasePath(HttpActions.get)}/users/invite/${invitationId}`;
  }
}
