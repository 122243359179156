import { NgFor } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { NzMessageContainerComponent } from "ng-zorro-antd/message";
import { UiToastComponent } from "../../toast.component";
import { UiToastData } from "../../toast.models";

@Component({
  selector: "ui-toast-container",
  exportAs: "uiToastContainer",
  templateUrl: "toast-container.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiToastComponent, NgFor],
})
export class UiToastContainerComponent extends NzMessageContainerComponent {
  public instances: Array<Required<UiToastData>>;
}
