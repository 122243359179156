import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiSkeletonModule } from "@quantive/ui-kit/skeleton";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { DropdownModule } from "@webapp/shared/dropdown/dropdown.module";
import { UiGridModule } from "@webapp/ui/grid/grid.module";
import { UiInputModule } from "@webapp/ui/input/input.module";
import { UiRadioModule } from "@webapp/ui/radio/radio.module";
import { PiClickableErrorCardComponent } from "./components/pi-clickable-error-card/pi-clickable-error-card.component";
import { PiErrorCardComponent } from "./components/pi-error-card/pi-error-card.component";
import { piFeedbackCardReadonlyDeclarations } from "./components/pi-feedback-card-readonly";
import { piFeedbackCardDeclarations, piFeedbackCardProviders } from "./components/pi-feedback-card/pi-feedback-card.module";
import { PiFeedbackSuggestionsComponent } from "./components/pi-feedback-suggestions/pi-feedback-suggestions.component";
import { PiFeedbackComponent } from "./components/pi-feedback/pi-feedback.component";
import { PiLoadingCardV2Component } from "./components/pi-loading-card-v2/pi-loading-card-v2.component";
import { PiLoadingCardComponent } from "./components/pi-loading-card/pi-loading-card.component";
import { PiTextCardComponent } from "./components/pi-text-card/pi-text-card.component";
import { StrategicGuidedOkrFacade } from "./services/strategic-guided-okr/strategic-guided-okr-facade.service";

@NgModule({
  imports: [
    CommonModule,
    UiIconModule,
    UiButtonModule,
    UiGridModule,
    LocalizationModule,
    UiTooltipModule,
    UiSkeletonModule,
    DropdownModule,
    UiInputModule,
    UiRadioModule,
    FormsModule,
  ],
  declarations: [
    PiErrorCardComponent,
    PiTextCardComponent,
    PiLoadingCardComponent,
    PiClickableErrorCardComponent,
    PiLoadingCardV2Component,
    PiFeedbackComponent,
    PiFeedbackSuggestionsComponent,
    ...piFeedbackCardDeclarations,
    ...piFeedbackCardReadonlyDeclarations,
  ],
  exports: [
    PiErrorCardComponent,
    PiTextCardComponent,
    PiLoadingCardComponent,
    PiClickableErrorCardComponent,
    PiLoadingCardV2Component,
    PiFeedbackComponent,
    PiFeedbackSuggestionsComponent,
    ...piFeedbackCardDeclarations,
    ...piFeedbackCardReadonlyDeclarations,
  ],
  providers: [StrategicGuidedOkrFacade, ...piFeedbackCardProviders],
})
export class PlatformIntelligenceSharedModule {}
