import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { PIImproveSuggestionContext } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/improvement/models";
import { IQuantivePlusAssignees, IQuantivePlusMetric } from "@webapp/platform-intelligence/shared/models";
import { PiErrorData } from "@webapp/platform-intelligence/shared/models/pi-errors.models";
import { FlowName } from "@webapp/platform-intelligence/shared/utils/pi-tracking";
import { Task } from "@webapp/tasks/models/tasks.models";

export interface IQuantivePlusRefreshedArgs {
  suggestionType: "metric" | "task";
  suggestionToReplace: IQuantivePlusMetric | IQuantivePlusTask;
  suggestionToReplaceIndex: number;
}

export interface IQuantivePlusAcceptedMetric {
  metric: Metric;
  quantivePlusSuggestionIndex: number;
}
export interface IQuantivePlusAcceptedTask {
  task?: Task;
  sessionId: string;
  quantivePlusSuggestionIndex: number;
  flowName: FlowName;
  type?: "improving" | "replacing" | "backToSuggestion";
  improvement?: string;
  improveSuggestionContext?: PIImproveSuggestionContext;
  newSuggestionName?: string;
}

export type QuantivePlusSuggestionDataStatus = {
  isLoading?: boolean;
  isError?: boolean;
};

export interface IQuantivePlusSuggestionStatus {
  isLoading?: boolean;
  isError?: boolean;
  errorData?: PiErrorData;
}

export interface IQuantivePlusMetricsAndTasksSuggestionStatus {
  isLoading?: boolean;
  isError?: boolean;
  errorDataMetrics?: PiErrorData;
  errorDataTasks?: PiErrorData;
}

export type IQuantivePlusTask = {
  description: string;
  title: string;
  id?: string;
  isLoading?: boolean;
  isHidden?: boolean;
  isError?: boolean;
  errorData?: PiErrorData;
};

export interface IQuantivePlusKrTask {
  description: string;
  title: string;
  isLoading?: boolean;
}

export interface IQuantivePlusTag {
  title: string;
  id?: string;
}

export interface IQuantivePlusObjective {
  assignees: IQuantivePlusAssignees;
  description: string;
  title: string;
  id?: string;
}

export const minTasksCountNotToFetchSuggestions = 3;
export const maxKrsSuggestions = 3;
