<default-sub-navigation
  [options]="options"
  [itemsType]="'people'"
  [favorites]="favorites$ | async"
  [recent]="recents$ | async"
  [suggested]="suggested$ | async"
  [loading]="loading$ | async"
  (markItemAsFavorite)="markItemAsFavorite($event)"
  (unmarkItemAsFavorite)="unmarkItemAsFavorite($event)"
  (removeFromSuggested)="removeFromSuggested($event)">
  <nav-items-list
    [collection]="myTeams$ | async"
    [config]="{
      sectionLabelKey: 'my_teams',
      visibleItemsCount: 5,
      maxItemsCount: 10,
      itemsType: 'people',
      itemsGroupType: 'myteams',
      broadcastConfig: broadcastConfig,
    }"
    (markItemAsFavorite)="markItemAsFavorite($event)"
    (unmarkItemAsFavorite)="unmarkItemAsFavorite($event)"
    (removeFromSuggested)="removeFromSuggested($event)"
    before-suggested></nav-items-list>
</default-sub-navigation>
