import { module } from "angular";
import env from "@gtmhub/env/env.module";
import { ErrorCtrl } from "./controllers/error";
import { UIErrorHandlingService } from "./services/error-handling-service";
import { ErrorService } from "./services/error.service";

const mod = module("error-handling", [env]);

mod.service("UIErrorHandlingService", UIErrorHandlingService);
mod.service("ErrorService", ErrorService);

mod.controller("ErrorCtrl", ErrorCtrl);

export default mod.name;
