<div [uiGutter]="[40, 20]" ui-row>
  <ng-container *ngFor="let template of insightboardTemplatesModified">
    <div [uiLg]="12" ui-col>
      <ui-card
        class="featured-insightboards"
        [uiHoverable]="true"
        [uiCover]="cover"
        [uiRounded]="true"
        [uiActions]="[addBtn, previewBtn, indicator]"
        uiBorderless="true"
        data-test-id="featured-insightboard-card">
        <section tabindex="0">
          <ui-card-meta [uiTitle]="cardTitle" [uiDescription]="cardDescription"></ui-card-meta>
        </section>
      </ui-card>
    </div>
    <ng-template #cardTitle>
      <h5>{{ template.name }}</h5>
    </ng-template>
    <ng-template #cardDescription>
      <p>{{ template.description }}</p>
    </ng-template>
    <ng-template #cover>
      <img [attr.src]="template.teaserUrl" alt="" />
    </ng-template>
    <ng-template #addBtn
      ><button *ngIf="hasPermissionToAdd$ | async" (click)="addInsightboardTemplate(template)" ui-button uiShape="round" uiType="primary" data-test-id="addInsightboardTemplateCard">
        {{ "add" | localize }}
      </button></ng-template
    >
    <ng-template #previewBtn>
      <button (click)="openModal(template)" ui-button uiShape="round" uiType="default">
        {{ "preview" | localize }}
      </button>
    </ng-template>
    <ng-template #indicator>
      <ui-loading-indicator [uiSpinning]="template.loading" [uiTip]="'adding' | localize" uiSize="small"></ui-loading-indicator>
    </ng-template>
  </ng-container>
</div>

<ui-modal
  [(uiVisible)]="isVisible"
  [uiContent]="modalContent"
  [uiWidth]="830"
  [uiTitle]="modalTitle"
  [uiOkText]="'add' | localize"
  (uiOnCancel)="handleCancel()"
  (uiOnOk)="addInsightboardTemplate(currentPreviewTemplate)"></ui-modal>
<ng-template #modalTitle>
  <h4>{{ currentPreviewTemplate.name }}</h4>
</ng-template>
<ng-template #modalContent>
  <img class="image-modal-content" [attr.src]="currentPreviewTemplate.previewUrl" alt="" />
</ng-template>
