<ui-assignee-avatar *ngFor="let assignee of visibleAssignees | keyvalue" [fromAssigneeId]="assignee.key" [hasBorder]="true" [borderColor]="assignee.value" [uiShowTooltip]="true" uiSize="md" />

<ui-avatar
  *ngIf="hiddenAssignees.size > 0"
  class="view-more-avatars-element"
  [uiText]="'+' + hiddenAssignees.size"
  [hasBorder]="true"
  [uiContent]="hiddenAvatarsTemplate"
  [uiBackdrop]="true"
  uiSize="md"
  ui-popover
  [uiOverlayStyle]="{ 'margin-right': '-8px', 'margin-top': '2px' }"
  uiTrigger="click"
  uiPlacement="bottomRight" />

<ng-template #hiddenAvatarsTemplate>
  <div class="view-more-avatars-wrapper" [class.scroll]="hiddenAssignees.size >= 10">
    <div class="view-more-avatars-row" *ngFor="let assignee of hiddenAssignees | keyvalue">
      <ui-assignee-avatar class="sp-mr-6" [fromAssigneeId]="assignee.key" [hasBorder]="true" [borderColor]="assignee.value" uiSize="md" />
      <ui-assignee-name [fromAssigneeId]="assignee.key" />
    </div>
  </div>
</ng-template>
