import { IPromise } from "angular";
import { IModalScope } from "angular-ui-bootstrap";
import { IStateInit } from "@gtmhub/core/routing";
import { IIndicator } from "@gtmhub/error-handling";
import { IGtmhubRootScopeService } from "@gtmhub/models";
import { SearchService } from "@gtmhub/search/services/search.service";
import { Paginator } from "@gtmhub/teams";
import { SearchUser, buildUsersSearchRequest, searchItemsToUsers } from "@gtmhub/users/utils/search-utils";
import { ICollection } from "@webapp/core/core.models";
import { ISearchParams, SearchOperatorsEnum } from "@webapp/search/models/search-api.models";
import { Search } from "@webapp/search/models/search.models";
import { IAllowedUsersAndTeams } from "../models";

export interface IMultiUserSelectorScope extends IModalScope {
  users: ICollection<SearchUser>;
  selectedUsers: string[];
  usersToExclude: string[];
  allowedUsersAndTeams: IAllowedUsersAndTeams;
  countSelectedUsers: number;
  indicators: {
    loadingUsers?: IIndicator;
    search?: IIndicator;
  };
  paginator: Paginator;
  querySearch: string;

  toggleUserInSelection(id: string);
  finish();
  onPageChange(page: number): void;
  searchEmployees(employeeName: string): void;
}

export class MultiUserSelectorCtrl implements IStateInit {
  public static $inject = ["$rootScope", "$scope", "alreadySelectedUsers", "excludedUsers", "allowedUsersAndTeams", "SearchService"];
  constructor(
    private $rootScope: IGtmhubRootScopeService,
    private $scope: IMultiUserSelectorScope,
    private alreadySelectedUsers: string[],
    private excludedUsers: string[],
    private allowedUsersAndTeams: IAllowedUsersAndTeams,
    private searchService: SearchService
  ) {
    this.$scope.indicators = {};
    this.$scope.selectedUsers = [];
    this.$scope.countSelectedUsers = 0;
    this.addAlreadySelected(this.alreadySelectedUsers);
    this.$scope.toggleUserInSelection = this.toggleUserInSelection;
    this.$scope.finish = this.finish;
    this.$scope.onPageChange = this.onPageChange;
    this.$scope.searchEmployees = this.searchEmployees;
    this.$scope.usersToExclude = this.excludedUsers;
    this.$scope.allowedUsersAndTeams = this.allowedUsersAndTeams;
  }

  public stateInit(): IPromise<unknown> {
    this.setDefaultPaginator();
    return this.getEmployees();
  }

  private setDefaultPaginator = (): void => {
    this.$scope.paginator = {
      currentPage: 1,
      itemsPerPage: 10,
      paginatorLimit: 5,
    };
  };

  private onPageChange = (page: number): void => {
    this.$scope.paginator.currentPage = page;
    this.getEmployees("loadingUsers");
  };

  private searchEmployees = (querySearch: string): void => {
    this.$scope.querySearch = querySearch;
    this.setDefaultPaginator();
    this.getEmployees("search");
  };

  private getEmployees = (indicator: keyof IMultiUserSelectorScope["indicators"] = "loadingUsers"): IPromise<unknown> => {
    this.$scope.indicators[indicator] = { progress: true };

    const queryParams: ISearchParams = {
      skip: (this.$scope.paginator.currentPage - 1) * this.$scope.paginator.itemsPerPage,
      take: this.$scope.paginator.itemsPerPage,
    };

    const searchSettings = {
      activeUsersOnly: true,
      ...(this.excludedUsers?.length && { excludedUserIds: this.excludedUsers }),
      ...(this.allowedUsersAndTeams?.allowedUserIds?.length && { allowedUserIds: this.allowedUsersAndTeams.allowedUserIds }),
      ...(this.allowedUsersAndTeams?.allowedTeamIds?.length && { allowedTeamIds: this.allowedUsersAndTeams.allowedTeamIds }),
    };

    const searchBody = {
      searchTerm: this.$scope.querySearch,
      operator: this.$scope.querySearch ? SearchOperatorsEnum.matchPrefix : SearchOperatorsEnum.matchAny,
      searchRequests: [buildUsersSearchRequest(this.$scope.querySearch, searchSettings)],
    };

    return this.searchService.getSomeSearchData<Search<"users">>(searchBody, queryParams).then(
      (response) => {
        delete this.$scope.indicators[indicator];

        this.$scope.users = { totalCount: response.totalCount, items: searchItemsToUsers(response.items) };
        this.$scope.paginator.totalPages = Math.ceil(response.totalCount / this.$scope.paginator.itemsPerPage);
      },
      (error) => (this.$scope.indicators[indicator] = { error })
    );
  };

  private addAlreadySelected = (preSelected: string[]): void => {
    preSelected = preSelected ? preSelected : [];

    preSelected.forEach((userId) => {
      this.toggleUserInSelection(userId);
    });
  };

  private toggleUserInSelection = (id: string) => {
    if (this.$scope.selectedUsers.indexOf(id) === -1) {
      this.$scope.selectedUsers.push(id);
    } else {
      const index = this.$scope.selectedUsers.indexOf(id);
      this.$scope.selectedUsers.splice(index, 1);
    }
    this.$scope.countSelectedUsers = this.$scope.selectedUsers.length;
  };

  private finish = () => {
    this.$rootScope.$broadcast("usersSelected", this.$scope.selectedUsers);
    this.$scope.$close();
  };
}
