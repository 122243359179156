import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "date-view",
  templateUrl: "./date-view.component.html",
  styleUrls: ["./date-view.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateViewComponent {
  @Input() public date: string;
  @Output() public readonly dateClicked: EventEmitter<void> = new EventEmitter();
}
