import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CheckInSettings, CheckInTemplate } from "../models/checkins-models";
import { ReflectionPanel } from "../models/reflection-panel.models";

@Injectable({
  providedIn: "root",
})
export class CheckInsDataService {
  private checkInTemplateSettings = new BehaviorSubject<{ settings: CheckInSettings; template: CheckInTemplate }>(null);
  private currentCadanceReflectionEdit = new BehaviorSubject<ReflectionPanel>(null);
  private previousCadanceReflectionEdit = new BehaviorSubject<ReflectionPanel>(null);

  public setCurrentCadanceReflectionEdit(value: ReflectionPanel): void {
    this.currentCadanceReflectionEdit.next(value);
  }

  public currentCadanceReflectionEdit$(): Observable<ReflectionPanel> {
    return this.currentCadanceReflectionEdit.asObservable();
  }

  public setPreviousCadanceReflectionEdit(value: ReflectionPanel): void {
    this.previousCadanceReflectionEdit.next(value);
  }

  public previousCadanceReflectionEdit$(): Observable<ReflectionPanel> {
    return this.previousCadanceReflectionEdit.asObservable();
  }

  public setCheckInTemplateSettingsData(value: { settings: CheckInSettings; template: CheckInTemplate }): void {
    this.checkInTemplateSettings.next(value);
  }

  public getCheckInTemplateSettingsData$(): Observable<{ settings: CheckInSettings; template: CheckInTemplate }> {
    return this.checkInTemplateSettings.asObservable();
  }
}
