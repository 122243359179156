import { AccountType } from "@gtmhub/core";

export const getAccountTypeDisplayValue = function (accountType: AccountType): string {
  let result: string;
  switch (accountType) {
    case 0:
      result = "TrialAccount";
      break;
    case 1:
      result = "ClientAccount";
      break;
    case 2:
      result = "InternalAccount";
      break;
    case 3:
      result = "DemoAccount";
      break;
    case 4:
      result = "PartnerAccount";
      break;
    case 5:
      result = "FreeAccount";
      break;
    default:
      result = "InternalAccount";
  }

  return result;
};
