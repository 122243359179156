import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "custom-field-label",
  templateUrl: "./label.component.html",
  styleUrls: ["./label.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldLabelComponent {
  @Input()
  public required: boolean;
  @Input()
  public label: string;
  @Input()
  public tooltipText: string;
  @Input()
  public fieldId: string;
  @Input()
  public customFieldName: string;
  @Input()
  public formName: string;

  public focusBoundElement(): void {
    const wrapper = this.formName.split(" ").join(".");
    const focusableInput = document.querySelector<HTMLElement>("." + wrapper + " #" + this.customFieldName);
    if (focusableInput) {
      focusableInput.focus();
    }
  }
}
