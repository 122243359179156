import { IModalService } from "angular-ui-bootstrap";

export class ChangeUserPictureModalService {
  public static $inject = ["$uibModal"];

  constructor(private $uibModal: IModalService) {}

  public openChangeUserPictureModal(userId: string): void {
    this.$uibModal.open({
      template: require("../views/change-user-avatar.html"),
      controller: "ChangeUserAvatarCtrl",
      windowClass: "modal modal-small vertical-center",
      backdropClass: "dark-backdrop",
      resolve: {
        userId: () => userId,
      },
    });
  }
}
