import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import hash from "hash-it";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

const DEFAULT_MAX_RETRIES = 1;

interface HttpRetry {
  retries: number;
}

@Injectable()
export class RetryFailedGetRequestsInterceptor implements HttpInterceptor {
  private retryHash = new Map<number, HttpRetry>();
  private static maxRetries = DEFAULT_MAX_RETRIES;

  public static setMaxRetries(maxRetries: number): void {
    RetryFailedGetRequestsInterceptor.maxRetries = maxRetries;
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.method !== "GET" || RetryFailedGetRequestsInterceptor.maxRetries <= 0) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const shouldRetry = error.status >= 500;
        if (shouldRetry) {
          const requestHash = hash(request);

          if (!this.retryHash.has(requestHash)) {
            this.retryHash.set(requestHash, { retries: RetryFailedGetRequestsInterceptor.maxRetries });
          }

          const retryItem = this.retryHash.get(requestHash);
          const shouldRetry = retryItem.retries > 0;
          if (shouldRetry) {
            retryItem.retries--;

            return this.intercept(request, next);
          } else {
            this.retryHash.delete(requestHash);
          }
        }

        return throwError(() => error);
      })
    );
  }
}
