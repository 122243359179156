import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigurationState } from "@webapp/configuration/services/configuration-state.service";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { BaseState } from "@webapp/core/abstracts/services/base-state.service";
import { IntercomApiService } from "./intercom-api.service";

@Injectable({
  providedIn: "any",
})
export class IntercomFacade extends BaseFacade<{ userHash: string }, null, ConfigurationState, IntercomApiService> {
  public constructor(state: BaseState, api: IntercomApiService) {
    super(state, api);
  }

  public getIntercomUserHash$(): Observable<{ userHash: string }> {
    return this.get$(null, {
      ...new RequestConfig(),
      url: this.apiService.getIntercomUserHashEndpoint(),
    });
  }
}
