import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from "@angular/core";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { NzDividerComponent } from "ng-zorro-antd/divider";
import { UiDividerTextOrientation, UiDividerType } from "./divider.models";

@Component({
  templateUrl: "./divider.component.html",
  selector: "ui-divider",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NzOutletModule, NgIf],
})
export class UiDividerComponent extends NzDividerComponent {
  @Input("uiDashed")
  public nzDashed = false;

  @Input("uiType")
  public nzType: UiDividerType;

  @Input("uiText")
  public nzText: string | TemplateRef<void>;

  @Input("uiPlain")
  public nzPlain = false;

  @Input("uiTextOrientation")
  public nzOrientation: UiDividerTextOrientation;
}
