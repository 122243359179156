import { IHttpService, IPromise, IRequestShortcutConfig } from "angular";
import { EnvironmentService } from "@gtmhub/env";
import { ICollection } from "@webapp/core/core.models";
import { INotification, INotificationSettings, IPatchedNotificationSettings } from "../models/notifications";

export class NotificationsService {
  public static $inject = ["$http", "EnvironmentService"];

  constructor(
    private $http: IHttpService,
    private env: EnvironmentService
  ) {}

  public getNotifications(query: { seen?: boolean; skip: number; take: number; targets?: string } = { skip: 0, take: 5000 }): IPromise<ICollection<INotification>> {
    const url = this.env.getEbisuEndpoint("/");
    const config: IRequestShortcutConfig = {
      params: query,
    };

    return this.$http.get<ICollection<INotification>>(url, config).then((response) => response.data);
  }

  public getUserNotificationSettings(): IPromise<INotificationSettings> {
    const url = this.env.getEbisuEndpoint("/user-settings");

    return this.$http.get<INotificationSettings>(url).then((response) => response.data);
  }

  public getAccountNotificationSettings(): IPromise<INotificationSettings> {
    const url = this.env.getEbisuEndpoint("/account-settings");

    return this.$http.get<INotificationSettings>(url).then((response) => response.data);
  }

  public patchUserNotificationSettings(patchedNotificationSettings: IPatchedNotificationSettings): IPromise<INotificationSettings> {
    const url = this.env.getEbisuEndpoint("/user-settings");

    return this.$http.patch<INotificationSettings>(url, patchedNotificationSettings).then((response) => response.data);
  }

  public patchAccountNotificationSettings(patchedNotificationSettings: IPatchedNotificationSettings): IPromise<INotificationSettings> {
    const url = this.env.getEbisuEndpoint("/account-settings");

    return this.$http.patch<INotificationSettings>(url, patchedNotificationSettings).then((response) => response.data);
  }

  public markAsSeen(id: string): IPromise<INotification> {
    const url = this.env.getEbisuEndpoint("/" + id + "/see");

    return this.$http.post<INotification>(url, null).then((response) => response.data);
  }

  public markAllAsSeen(): IPromise<unknown> {
    const url = this.env.getEbisuEndpoint("/see-all");

    return this.$http.post<unknown>(url, null).then((response) => response.data);
  }
}
