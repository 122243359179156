import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { ItemNameWithIconAndOwnerComponent } from "./item-name-with-icon-and-owner.component";

@NgModule({
  imports: [CommonModule, UiIconModule, AssigneesModule],
  declarations: [ItemNameWithIconAndOwnerComponent],
  exports: [ItemNameWithIconAndOwnerComponent],
})
export class ItemNameWithIconAndOwnerModule {}
