const toTitleCase = (text: string): string => {
  return text.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};

interface ITitleCaseFilter {
  (key: string): string;
}

export function TitleCaseFilter(): ITitleCaseFilter {
  return function (text: string): string {
    return toTitleCase(text);
  };
}
