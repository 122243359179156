import { UIRouterGlobals } from "@uirouter/angular";
import { Injectable } from "@angular/core";
import { combineLatest, map, of } from "rxjs";
import { ISessionsStoreState } from "@gtmhub/sessions/redux/session-reducer";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { FeatureModuleService } from "@webapp/feature-toggles/services/feature-module.service";
import { GuidedOKRsComponent, GuidedOKRsModalData } from "@webapp/platform-intelligence/guided-okrs/guided-okrs.component";
import { PiFeatureVisibilityFacade } from "@webapp/platform-intelligence/shared/services/pi-feature-visibility-facade.service";
import { PiTrackingFlowNameEnum } from "@webapp/platform-intelligence/shared/utils/pi-tracking";
import { Session } from "@webapp/sessions/models/sessions.model";
import { DefaultSessionResolverService } from "@webapp/sessions/services/default-session-resolver.service";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { CreateOkrFormModalService } from "../components/create-okr-form/create-okr-form-modal.service";

@Injectable()
export class CreateOkrDropdownButtonService {
  constructor(
    private featureModuleService: FeatureModuleService,
    private routerGlobals: UIRouterGlobals,
    private uiModalService: UiModalService,
    private createOkrFormModalService: CreateOkrFormModalService,
    private defaultSessionResolverService: DefaultSessionResolverService,
    private piFeatureVisibilityFacade: PiFeatureVisibilityFacade,
    private editionFeatureService: EditionFeatureService
  ) {}

  public menuItems({ hideCreateOkr }: { hideCreateOkr?: boolean } = {}): DropdownMenuItem[] {
    const menuItemBuilder = new DropdownMenuItemBuilder();

    const suggestOKRs = menuItemBuilder
      .setKey("suggest_okrs")
      .setDescriptionKey("draft_okrs_personalized_suggestions")
      .setAction({
        handler: () => this.openQuantiveSuggestionsWizard(),
      })
      .setUiType({ uiType: "uiIcon", iconType: "artificial-intelligence", iconTheme: "outline" })
      .setClasses(["text-color-gray-1"])
      .setToBeEndOfSection()
      .setA11yDescriptionKey("this_links_leads_to_currently_inaccessible_content")
      .setIsVisibleCondition(this.piFeatureVisibilityFacade.isGuidedOKRsCreationVisible$())
      .setTrack("Button Clicked")
      .setTrackMetaData({ button_name: "suggest_okrs" })
      .build();

    const createOkr = menuItemBuilder
      .setKey("create_okr")
      .setDescriptionKey("directly_add_your_okrs")
      .setAction({
        handler: () => this.openCreateGoalForm(),
      })
      .setUiType({ uiType: "uiIcon", iconType: "goal", iconTheme: "outline" })
      .setToBeEndOfSection()
      .setIsVisibleCondition(of(!hideCreateOkr))
      .setTrack("Button Clicked")
      .setTrackMetaData({ button_name: "create_okr" })
      .build();

    const chooseFromTemplate = menuItemBuilder
      .setKey("choose_from_template")
      .setDescriptionKey("get_inspiration_marketplace")
      .setAction({
        href: "/marketplace/okrs-examples",
      })
      .setUiType({ uiType: "uiIcon", iconType: "add-from-marketplace", iconTheme: "outline" })
      .setToBeEndOfSection()
      .setExternalLink()
      .setA11yDescriptionKey("this_links_leads_to_currently_inaccessible_content")
      .setIsVisibleCondition(this.editionFeatureService.hasFeature$("marketplace"))
      .setTrack("Button Clicked")
      .setTrackMetaData({ button_name: "choose_from_template" })
      .build();

    const brainstormAndIdeate = menuItemBuilder
      .setKey("brainstorm_and_ideate")
      .setDescriptionKey("use_a_whiteboard_to_draft_okrs")
      .setAction({
        state: "gtmhub.whiteboards",
      })
      .setUiType({ uiType: "uiIcon", iconType: "whiteboards", iconTheme: "outline" })
      .setToBeEndOfSection()
      .setA11yDescriptionKey("this_links_leads_to_currently_inaccessible_content")
      .setIsVisibleCondition(
        combineLatest([this.editionFeatureService.hasFeature$("whiteboards"), this.featureModuleService.isWhiteboardModuleEnabled$()]).pipe(
          map(([hasWhiteboards, whiteboardsEnabled]) => hasWhiteboards && whiteboardsEnabled)
        )
      )
      .setTrack("Button Clicked")
      .setTrackMetaData({ button_name: "brainstorm_and_ideate" })
      .build();

    return [suggestOKRs, createOkr, chooseFromTemplate, brainstormAndIdeate];
  }

  private openCreateGoalForm(): void {
    this.createOkrFormModalService.openFormModal({
      sessionId: this.routerGlobals.params["planningSessionId"],
      flowName: PiTrackingFlowNameEnum.CreateObjectiveFormTopNav,
    });
  }

  private openQuantiveSuggestionsWizard(): void {
    const querySessionId = this.routerGlobals.params["planningSessionId"];
    const state = reduxStoreContainer.reduxStore.getState<ISessionsStoreState>();
    const sessions: Session[] = state.sessions.items;

    if (querySessionId) {
      this.uiModalService.create<GuidedOKRsComponent, GuidedOKRsModalData>({
        uiContent: GuidedOKRsComponent,
        uiWrapClassName: "guided-okrs-modal-wrapper",
        uiWidth: "100%",
        uiFooter: null,
        uiClosable: false,
        uiKeyboard: false,
        uiData: {
          flowName: PiTrackingFlowNameEnum.GuidedOkrsCreationCreateOkrTopNav,
          currentSession: sessions.find((s) => s.id === querySessionId),
          readOnlySession: true,
        },
      });
      return;
    }

    const defaultSession = this.defaultSessionResolverService.getDefaultSession(sessions);
    this.uiModalService.create<GuidedOKRsComponent, GuidedOKRsModalData>({
      uiContent: GuidedOKRsComponent,
      uiWrapClassName: "guided-okrs-modal-wrapper",
      uiWidth: "100%",
      uiFooter: null,
      uiClosable: false,
      uiKeyboard: false,
      uiData: {
        flowName: PiTrackingFlowNameEnum.GuidedOkrsCreationCreateOkrTopNav,
        currentSession: defaultSession,
      },
    });
  }
}
