// eslint-disable-next-line no-restricted-imports
import angular from "angular";
import { UrlService } from "@uirouter/angular";
import { registerLocaleData } from "@angular/common";
import { LOCALE_ID, NgModuleRef, StaticProvider, enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { downgradeModule, setAngularJSGlobal } from "@angular/upgrade/static";
import app from "@gtmhub/app.module";
import { resolveLocales } from "@webapp/localization/utils/i18n-utils";
import { kendoMessageServiceProvider } from "@webapp/shared/kendo/i18n/kendo-message.service";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
}

setAngularJSGlobal(angular);

const appConfig = webappConfig();

if (appConfig.cdnStorage.enabled) {
  __webpack_public_path__ = appConfig.cdnStorage.url + "/dist/";
}
angular.module(app).config(["$urlServiceProvider", ($urlService: UrlService): void => $urlService.deferIntercept()]);

const [htmlLang, localeFactory] = resolveLocales();
document.documentElement.setAttribute("lang", htmlLang);
const bootstrapFn = (extraProviders: StaticProvider[]): Promise<NgModuleRef<AppModule>> => {
  return localeFactory()
    .then(({ ngLocale, uiLocaleProviders, kendoMessages }) => {
      registerLocaleData(ngLocale);

      return platformBrowserDynamic([
        ...extraProviders,
        ...uiLocaleProviders,
        kendoMessageServiceProvider(kendoMessages),
        { provide: LOCALE_ID, useValue: htmlLang },
      ]).bootstrapModule(AppModule);
    })
    .catch((err) => {
      console.error(err);
      const defaultBootstrap = (extraProviders: StaticProvider[]): Promise<NgModuleRef<AppModule>> => platformBrowserDynamic(extraProviders).bootstrapModule(AppModule);
      return defaultBootstrap(extraProviders);
    });
};

angular.bootstrap(document.body, [app, downgradeModule(bootstrapFn)], { strictDi: true });
