import { IHttpService, IPromise, IRequestShortcutConfig, IRootScopeService } from "angular";
import equal from "fast-deep-equal";
import { IRestLayerRequest } from "@gtmhub/core";
import { EnvironmentService } from "@gtmhub/env";
import { ICheckinComment } from "@gtmhub/goals/models";
import { IInsightSelectorData } from "@gtmhub/insights";
import { OkrEvents } from "@gtmhub/okrs/events";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { ICollection } from "@webapp/core/core.models";
import { ICheckin, Metric, MetricFormEventType, MetricFormState, MetricMilestoneEventType, MetricType } from "@webapp/okrs/metrics/models/metric.models";
import { PluginGtmhubAdditionalParams } from "@webapp/plugins/plugins.models";

export class MetricService {
  private okrEvents: OkrEvents;
  private lastInsightData: IInsightSelectorData;

  public static $inject = ["$http", "$rootScope", "AnalyticsService", "EnvironmentService"];

  constructor(
    private $http: IHttpService,
    private $rootScope: IRootScopeService,
    private analyticsService: AnalyticsService,
    private env: EnvironmentService
  ) {
    this.okrEvents = new OkrEvents(this.$rootScope);
  }

  public deleteMetric(goalId: string, metricId: string, additionalGtmhubParams?: PluginGtmhubAdditionalParams): IPromise<void> {
    const url = this.env.getApiEndpoint(`/metrics/${metricId}`);

    const config: IRequestShortcutConfig = {
      params: additionalGtmhubParams,
    };

    return this.$http.delete<void>(url, config).then(() =>
      this.okrEvents.broadcastGoalsChanged("metricDeleted", {
        goalId,
        metricId,
      })
    );
  }

  public checkin(metric: Partial<Metric>, checkin: ICheckin, additionalGtmhubParams?: PluginGtmhubAdditionalParams): IPromise<ICheckin> {
    const url = this.env.getApiEndpoint(`/metrics/${metric.id}/checkin`);
    const gtmhubAdditionalParams: IRequestShortcutConfig = {
      params: {
        gtmhubAdditionalParams: {
          ...additionalGtmhubParams,
          hasGif: !!checkin.gif?.id,
        },
      },
    };
    return this.$http.post<ICheckin>(url, checkin, gtmhubAdditionalParams).then((response) => {
      const newCheckin = response.data;
      this.okrEvents.broadcastGoalsChanged("metricCheckin", {
        goalId: metric.goalId,
        metricId: metric.id,
        checkin: newCheckin,
      });
      return newCheckin;
    });
  }

  public patchMetric(metric: Partial<Metric>, additionalGtmhubParams?: PluginGtmhubAdditionalParams): IPromise<Metric> {
    const url = this.env.getApiEndpoint(`/metrics/${metric.id}`);

    const config: IRequestShortcutConfig = {
      params: additionalGtmhubParams,
    };

    return this.$http.patch<Metric>(url, metric, config).then((response) => {
      const patchedMetric = response.data;
      this.okrEvents.broadcastGoalsChanged("metricPatched", {
        goalId: patchedMetric.goalId,
        metric: patchedMetric,
        patch: metric,
      });
      return patchedMetric;
    });
  }

  public patchMetricComment(metricHistoryId: string, comment: ICheckinComment): IPromise<string> {
    const url = this.env.getApiEndpoint(`/metrics/checkin/${metricHistoryId}`);
    const gtmhubAdditionalParams: IRequestShortcutConfig = {
      params: {
        gtmhubAdditionalParams: {
          hasGif: !!comment.gif?.id,
        },
      },
    };

    return this.$http.patch<string>(url, comment, gtmhubAdditionalParams).then((response) => response.data);
  }

  public deleteSnapshot(goalId: string, metricId: string, snapshotId: string): IPromise<void> {
    const url = this.env.getApiEndpoint(`/metrics/checkin/${snapshotId}`);

    return this.$http.delete<void>(url).then(() =>
      this.okrEvents.broadcastGoalsChanged("metricSnapshotDeleted", {
        goalId,
        metricId,
        snapshotId,
      })
    );
  }

  public getMetric(metricId: string, additionalGtmhubParams?: PluginGtmhubAdditionalParams): IPromise<Metric> {
    const url = this.env.getApiEndpoint(`/metrics/${metricId}`);

    const config: IRequestShortcutConfig = {
      params: additionalGtmhubParams,
    };

    return this.$http.get<Metric>(url, config).then((response) => response.data);
  }

  public getMetricByCheckin(checkinId: string): IPromise<Metric> {
    const url = this.env.getApiEndpoint(`/metrics/checkin/${checkinId}`);

    return this.$http.get<Metric>(url).then((response) => response.data);
  }

  public getMetricsV2(params: IRestLayerRequest, gtmhubAdditionalParams?: PluginGtmhubAdditionalParams): IPromise<ICollection<Metric>> {
    const config: IRequestShortcutConfig = {
      params: {
        ...params,
        ...gtmhubAdditionalParams,
      },
    };

    const url = this.env.getApiEndpointV2("/metrics");
    return this.$http.get<ICollection<Metric>>(url, config).then((response) => response.data);
  }

  public trackMetricForm(event: MetricFormEventType, metric: Metric): void {
    const meta = {
      type: metric && metric.id ? MetricFormState.EDIT : MetricFormState.CREATE,
      metric_type: metric && metric.insightName !== "" ? MetricType.DYNAMIC : MetricType.MANUAL,
    };
    this.analyticsService.track(event, meta);
  }

  public trackMetricMilestone(event: MetricMilestoneEventType, args: { metric?: Metric; enabled?: boolean }): void {
    switch (event) {
      case MetricMilestoneEventType.KR_MILESTONES_ENABLED:
        this.analyticsService.track(event, { enabled: args.enabled });
        break;
      case MetricMilestoneEventType.KR_MILESTONES_CREATED:
        this.trackMetricMilestones(event, args.metric);
        break;
      case MetricMilestoneEventType.KR_MILESTONES_DELETED:
        this.trackMetricMilestones(event, args.metric);
        break;
    }
  }

  private trackMetricMilestones(event: MetricMilestoneEventType, metric: Metric) {
    this.analyticsService.track(event, {
      milestones_per_metric: metric.milestones?.length && 0,
    });
  }

  public updateMetricInsightData(metric: Metric, data: IInsightSelectorData, shouldPredict?: { [key: string]: boolean }): Metric {
    const result: Partial<Metric> = {};

    result.settings = angular.copy(data.settings);

    if (data.insightName && typeof data.initialValue === "number" && data.initialValue !== this.lastInsightData?.initialValue) {
      result.initialValue = data.initialValue;
      if (shouldPredict) {
        shouldPredict["initialValue"] = false;
      }
    }

    result.insightName = data.insightName;
    result.fieldName = data.fieldName;
    // automated KR manualType should be empty string
    result.manualType = result.insightName ? "" : "double";

    // set the actual of a new metric
    // because sometimes it may keep the value of insight added via IB and then changed
    if (!metric.id) {
      result.actual = data.initialValue;
    }

    if (data.insightName && data.formatting && !equal(data.formatting, this.lastInsightData?.formatting)) {
      // init metric format again because to prediction sets it to null when we dont have a prediction,
      // if we have prediction we should override it with the data from the insight
      result.format = {
        prefix: "",
        suffix: "",
        fractionSize: 0,
      };

      result.format.suffix = data.formatting.suffix ? data.formatting.unit : "";
      result.format.prefix = data.formatting.suffix ? "" : data.formatting.unit;
    }

    this.lastInsightData = data;

    return { ...metric, ...result };
  }

  public createMetric(metric: Partial<Metric>, additionalGtmhubParams?: PluginGtmhubAdditionalParams | Record<string, unknown>): IPromise<Metric> {
    const url = this.env.getApiEndpoint("/metrics");

    const config: IRequestShortcutConfig = {
      params: additionalGtmhubParams,
    };

    return this.$http.post<Metric>(url, metric, config).then((response) => {
      const newMetric = response.data;
      this.okrEvents.broadcastGoalsChanged("metricCreated", { goalId: newMetric.goalId, metric: newMetric });
      return newMetric;
    });
  }
}
