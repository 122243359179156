import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { CfMultiSelectUI, CustomFieldUpdate } from "@webapp/custom-fields/models/custom-fields.models";

@Component({
  selector: "custom-field-multi-select",
  templateUrl: "./multi-select.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldMultiSelectComponent implements OnChanges {
  @Input() public disabled: boolean;
  @Input() public readonly: boolean;
  @Input() public fieldName: string;
  @Input() public options: CfMultiSelectUI;
  @Input() public selectedValues: string[];
  @Input() public borderless: boolean;
  @Input() public a11yLabelledby: string;
  @Input() public a11yRequired = false;
  @Input() public uiId: string;

  @Output() public readonly optionSelect = new EventEmitter<CustomFieldUpdate>();

  @HostBinding("class.borderless") public get borderlessClass(): boolean {
    return this.borderless;
  }

  public selectedValuesSet: Set<string>;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedValues?.isFirstChange() && changes.selectedValues.currentValue) {
      this.selectedValuesSet = new Set(this.selectedValues);
    }

    if (changes.selectedValues?.currentValue?.length !== changes.selectedValues?.previousValue?.length) {
      this.selectedValuesSet = new Set(this.selectedValues);
    }
  }

  public onSelection(newValue: string[]): void {
    this.optionSelect.emit({ fieldName: this.fieldName, customField: newValue });
  }
}
