import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { IconWithLabelComponent } from "./icon-with-label.component";

@NgModule({
  imports: [CommonModule, UiIconModule, LocalizationModule],
  declarations: [IconWithLabelComponent],
  exports: [IconWithLabelComponent],
})
export class IconWithLabelModule {}
