import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiCardModule } from "@quantive/ui-kit/card";
import { ApmService } from "@gtmhub/core/tracing/apm.service";
import { localize } from "@gtmhub/localization";
import { HomeWidgetService } from "@webapp/home/services/home-widget.service";
import { BaseWidgetComponent } from "@webapp/ui/dashboard/components/base-widget.component";
import { UiDashboardWidget } from "@webapp/ui/dashboard/dashboard.models";
import { ReadOnlyUserMessageComponent } from "../../user-details/read-only-user-message/read-only-user-message.component";
import { WidgetActionsComponent } from "../widget-actions/widget-actions.component";

@UntilDestroy()
@Component({
  selector: "view-only-role-widget",
  templateUrl: "./view-only-role-widget.component.html",
  styleUrls: ["./view-only-role-widget.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiCardModule, ReadOnlyUserMessageComponent, WidgetActionsComponent],
})
export class ViewOnlyRoleWidgetComponent extends BaseWidgetComponent implements OnInit {
  private widget: UiDashboardWidget;

  public get title(): string {
    return localize("you_are_view_only_role");
  }

  public get a11yLabel(): string {
    return "You're in a view-only role";
  }

  constructor(
    private homeWidgetService: HomeWidgetService,
    private apmService: ApmService
  ) {
    super();
    this.apmService.startDataLoadSpan("view-only-role-widget-init");
  }

  public ngOnInit(): void {
    this.homeWidgetService
      .getDashboardWidgets$()
      .pipe(untilDestroyed(this))
      .subscribe((widgets) => {
        this.widget = widgets.find((w) => w.id === this.widgetId);
        this.apmService.endDataLoadSpan("view-only-role-widget-init");
      });
  }

  public remove(): void {
    if (!this.widget) {
      throw new Error(`Cannot find ${this.widgetId} widget`);
    }

    this.homeWidgetService.removeWidget(this.widgetId);
  }
}
