import { v4 as uuidv4 } from "uuid";
import { ITracingAction, ITracingScreen } from "@webapp/core/tracing/models/tracing.models";

// ts-unused-exports:disable-next-line
export function getOrInitSessionID(): string {
  let sessionID: string;
  if (!sessionStorage.getItem("webapp.sessionID")) {
    sessionID = uuidv4();
    sessionStorage.setItem("webapp.sessionID", sessionID);
  } else {
    sessionID = sessionStorage.getItem("webapp.sessionID");
  }
  return sessionID;
}

export class TracingService {
  private sessionID: string;
  private screen: ITracingScreen;

  public setTracingHeaders(headers: Record<string, string>, tracingData: { screen?: ITracingScreen; action?: ITracingAction } = {}): void {
    const sessionID = this.sessionID ? "sessionID=" + this.sessionID : null;
    const actionStr = tracingData.action ? `actionID=${tracingData.action.actionID}, actionName=${tracingData.action.actionName}` : null;
    headers["jaeger-baggage"] = [sessionID, actionStr].filter((x) => x != null).join(", ");

    const screen = tracingData.screen || this.getScreen();
    if (screen) {
      headers["jaeger-baggage-screen"] = `screenID=${screen.screenID}, screenName=${screen.screenName}`;
    }
  }

  public initSessionID(): void {
    this.sessionID = getOrInitSessionID();
  }

  public getSessionID(): string {
    return this.sessionID;
  }

  public initScreen(name: string, stateTrackingName: string | null): void {
    this.screen = this.createScreen(name, stateTrackingName);
  }

  public getScreen(): ITracingScreen {
    return this.screen;
  }

  public createAction(name: string): ITracingAction {
    return {
      actionID: uuidv4(),
      actionName: name,
    };
  }

  private createScreen(name: string, stateTrackingName: string | null): ITracingScreen {
    return {
      screenID: uuidv4(),
      screenName: name,
      stateTrackingName,
    };
  }
}
