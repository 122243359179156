import { IPromise } from "angular";
import { IModalScope, IModalService, IModalServiceInstance } from "angular-ui-bootstrap";
import { formatUnit } from "@gtmhub/feed/utils";
import { InsightFormatting } from "@webapp/data-story/models/insights.models";
import { IItemModel, IUnitFormatting } from "../models";

interface IUnitFormattingFormData {
  formatting?: IUnitFormatting;
  insightFormatting?: InsightFormatting;
  model?: IItemModel;
}

export interface IUnitFormattingForm {
  open(options: IUnitFormattingFormData): IPromise<IModalServiceInstance>;
}

export class UnitFormattingForm implements IUnitFormattingForm {
  public static $inject = ["$uibModal"];

  constructor(private $uibModal: IModalService) {}

  public open(options: IUnitFormattingFormData): IPromise<IModalServiceInstance> {
    return this.$uibModal.open({
      template: require("../views/unit-formatting.html"),
      windowClass: "medium-small",
      resolve: {
        formData: (): IUnitFormattingFormData => options,
      },
      controller: "UnitFormattingFormCtrl",
    }).result;
  }
}

interface IUnitFormattingFormScope extends IModalScope {
  selectSuffix: boolean;
  selectedRadioOption: string;
  formatText: string;
  changeFormatLocation(selectedRadioOption: string): void;
  save(): void;
  formatUnit({ unit, isPrefix }: { unit: string; isPrefix: boolean }): string;
}

export class UnitFormattingFormCtrl {
  public static $inject = ["$scope", "formData"];

  constructor(
    private $scope: IUnitFormattingFormScope,
    private formData: IUnitFormattingFormData
  ) {
    this.$scope.save = this.formData.formatting ? this.save : this.saveAutomaticUiInsight;
    this.$scope.changeFormatLocation = this.changeFormatLocation;
    this.$scope.formatUnit = formatUnit;

    if (this.formData.formatting) {
      [this.$scope.selectSuffix, this.$scope.formatText] = this.formData.formatting.prefix
        ? [false, this.formData.formatting.prefix]
        : [true, this.formData.formatting.suffix];

      this.$scope.selectedRadioOption = this.$scope.selectSuffix.toString();
    }

    if (this.formData.insightFormatting) {
      this.$scope.formatText = this.formData.insightFormatting.unit ? this.formData.insightFormatting.unit : "";

      if (this.formData.insightFormatting.suffix === undefined) {
        this.$scope.selectSuffix = true;
      } else {
        this.$scope.selectSuffix = this.formData.insightFormatting.suffix ? this.formData.insightFormatting.suffix : false;
      }
      this.$scope.selectedRadioOption = this.$scope.selectSuffix.toString();
    }
  }

  private changeFormatLocation = (selectedRadioOption: string): void => {
    this.$scope.selectSuffix = selectedRadioOption === "true";
  };

  private save = (): void => {
    const buildInUnits = {
      $: { suffix: true },
      "€": { suffix: true },
      "£": { suffix: true },
      "%": { suffix: true },
    };

    const isCustom = this.$scope.formatText !== "" && !Object.keys(buildInUnits).includes(this.$scope.formatText);

    [this.formData.formatting.prefix, this.formData.formatting.suffix] = this.$scope.selectSuffix ? ["", this.$scope.formatText] : [this.$scope.formatText, ""];
    this.formData.formatting.isCustomUnit = isCustom;

    if (this.formData.model) {
      [this.formData.model.formatting.unit, this.formData.model.formatting.isCustom] = [this.$scope.formatText, isCustom];
    }

    this.$scope.$close();
  };

  private saveAutomaticUiInsight = (): void => {
    this.formData.insightFormatting.unit = this.$scope.formatText;
    this.formData.insightFormatting.suffix = this.$scope.selectSuffix;
    this.formData.insightFormatting.isCustomUnit = true;

    this.$scope.$close();
  };
}
