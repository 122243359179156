import angular, { IModule, auto } from "angular";
import { NgZone, Type } from "@angular/core";
// eslint-disable-next-line no-restricted-imports
import { downgradeComponent, downgradeInjectable } from "@angular/upgrade/static";
import { getCurrentlyDowngradedModule } from "@webapp/core/routing/routing.utils";

const DEFAULT_DOWNGRADED_MODULE = "$$UpgradeModule.lazy1";
const DOWNGRADED_MODULE_COUNT_KEY = "$$angularDowngradedModuleCount";
const UPGRADE_APP_TYPE_KEY = "$$angularUpgradeAppType";
const UPGRADE_APP_TYPE_LITE = 3;

// Lite upgrade is used when manually downgrading modules. Static upgrade is used in unit tests
const isNgUpgradeLite = ($injector: auto.IInjectorService): boolean =>
  $injector.has(UPGRADE_APP_TYPE_KEY) && $injector.get(UPGRADE_APP_TYPE_KEY) === UPGRADE_APP_TYPE_LITE;

/**
 * The `downgradedModule` option is firstly evaluated when the service/component is initialized for the 1st time.
 * We need provide the `downgradedModule` at the time of the declaration of the downgraded injectable/component,
 * so this is a hack to put some logic (note that the original property is of type string).
 */
class DowngradedModuleStringer {
  constructor(
    private $injector: auto.IInjectorService,
    private ngDowngradedModule: string
  ) {}

  private get downgradedModuleCount(): number {
    return this.$injector.has(DOWNGRADED_MODULE_COUNT_KEY) ? this.$injector.get<number>(DOWNGRADED_MODULE_COUNT_KEY) : 0;
  }

  toString(): string {
    return this.downgradedModuleCount > 1 ? this.ngDowngradedModule || DEFAULT_DOWNGRADED_MODULE : "";
  }
}

const origModule = angular.module;

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
angular.module = function upgradeModule(moduleName: string, deps?: string[], config?: Function): IModule {
  const mod = origModule(moduleName, deps, config);

  mod.downgradeInjectable = function (name: string, token: Type<unknown>): IModule {
    const ngDowngradedModule = getCurrentlyDowngradedModule();

    /**
     * When using downgradeModule to bootstrap Angular we cannot just use
     * downgraded injectables in Angular.js, see
     * https://medium.com/the-road-to-angular/uncaught-error-trying-to-get-the-angular-injector-before-bootstrapping-an-angular-module-6c91859d3ac9
     * This helper method creates a proxy that wraps each needed injectable
     * and calls its method via $injector - because at that point Angular is for
     * sure bootstrapped.
     */
    const factory = function ($injector: auto.IInjectorService) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const downgradedModule: any = isNgUpgradeLite($injector) ? new DowngradedModuleStringer($injector, ngDowngradedModule) : undefined;

      const downgradedInjectable = downgradeInjectable(token, downgradedModule);
      let source: Record<string, unknown>;

      return new Proxy(
        {},
        {
          get(target: Record<string, unknown>, prop: string) {
            const fn = function () {
              /* empty */
            };

            return new Proxy(fn, {
              apply: (target, thisArg, args) => {
                if (!source) {
                  source = $injector.invoke(downgradedInjectable);
                }

                // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
                return (source[prop] as Function).apply(source, args);
              },
            });
          },
        }
      );
    };
    factory.$inject = ["$injector"];
    return this.service(name, factory);
  };

  mod.downgradeComponent = function (name: string, info: { component: Type<unknown>; propagateDigest?: boolean }): IModule {
    const ngDowngradedModule = getCurrentlyDowngradedModule();

    const factory = function ($injector: auto.IInjectorService) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const downgradedModule: any = isNgUpgradeLite($injector) ? new DowngradedModuleStringer($injector, ngDowngradedModule) : undefined;

      return $injector.invoke(downgradeComponent({ ...info, downgradedModule }));
    };
    factory.$inject = ["$injector"];
    return this.directive(name, factory);
  };

  return mod;
};

const mod = angular.module("hybrid", []);
mod.downgradeInjectable("ngZone", NgZone);

export default mod.name;
