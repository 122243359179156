import { IPromise } from "angular";
import { IModalService } from "angular-ui-bootstrap";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { ISelectAssigneeModalOptions } from "./controllers/select-assignee-modal-ctrl";

export class AssigneeSelectorModal {
  static $inject = ["$uibModal"];

  constructor(private $uibModal: IModalService) {}

  selectOne(options: ISelectAssigneeModalOptions = {}): IPromise<Assignee> {
    return this.$uibModal.open({
      windowClass: "medium-small assignees-modal",
      template: require("./views/select.html"),
      controller: "SelectAssigneeModalCtrl",
      resolve: {
        options: () => options,
      },
    }).result;
  }
}
