import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { IconComponent } from "./icon.component";

/**
 * This module is only needed in Angular.js. Do not use it in Angular.
 */
@NgModule({
  imports: [CommonModule, UiTooltipModule, UiIconModule],
  declarations: [IconComponent],
  exports: [IconComponent],
})
export class IconModule {}
