import { IAttributes, IAugmentedJQuery, IDirective, IDirectiveFactory, IScope } from "angular";
import { TRACE_ELEMENT_RENDERED } from "./apm.models";

export interface TraceElementRenderedData {
  name: string;
}

// one main goal - emit an event when the directive is linked so that the id collector can detect and pass on to the transaction
export class TraceRenderId implements IDirective {
  public link(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes) {
    const name = attrs.ghTraceRenderId;
    const ignoreRender = attrs.ghTraceRenderIgnore;
    if (ignoreRender !== undefined) {
      attrs.$observe("ghTraceRenderIgnore", (value) => {
        if (value === "false") {
          scope.$emit(TRACE_ELEMENT_RENDERED, {
            name,
          });
        }
      });
    } else {
      scope.$emit(TRACE_ELEMENT_RENDERED, {
        name,
      });
    }
  }

  public static factory(): IDirectiveFactory {
    const directive = () => new TraceRenderId();
    directive.$inject = [];
    return directive;
  }
}
