/* eslint-disable @typescript-eslint/no-unsafe-function-type */

/* eslint-disable prefer-rest-params */
import { IIntervalService, IModule, IPromise, IServiceProvider } from "angular";
import { Ng1Zone } from "../zone";
import { constructChain, splitChain } from "./util";

export function $intervalPatch(mod: IModule) {
  mod.config([
    "$intervalProvider",
    function ($intervalProvider: IServiceProvider) {
      const [dependencies, oldGet] = splitChain($intervalProvider.$get);
      $intervalProvider.$get = constructChain(dependencies, newGet);

      function newGet(this: IServiceProvider) {
        const oldInterval: IIntervalService = oldGet.apply(this, arguments);

        const newInterval = function zoneAwareInterval(this: IIntervalService, fn: Function) {
          const zone = Ng1Zone.current;

          const newFn = function zoneAwareCallback(this: unknown) {
            return zone.run<IPromise<unknown>>(fn, this, [...arguments]);
          };

          // when calling the original $interval, we have to replace the 1st argument
          // with our new function
          const args = [].slice.call(arguments);
          args[0] = newFn;
          return oldInterval.apply(this, args);
        };

        // copy other fields
        Object.keys(oldInterval).forEach((propertyName) => {
          newInterval[propertyName] = oldInterval[propertyName];
        });
        return newInterval;
      }
    },
  ]);
}
