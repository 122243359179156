import { HierarchyNode } from "d3-hierarchy";
import { IUIError } from "@gtmhub/error-handling";
import { addItemAndReturnCollection } from "@gtmhub/shared/utils";
import { toIdMap } from "@gtmhub/util";
import { Session } from "@webapp/sessions/models/sessions.model";
import { addChildSessionsToSession, createSessionTree } from "../controllers/utils";
import { SessionsAction } from "./models";

export interface ISessionsStoreState {
  sessions: ISessionsState;
}

export interface ISessionsState {
  isFetched: boolean;
  isFetching: boolean;
  map: Record<string, Session>;
  items: Session[];
  tree: HierarchyNode<Session>;
  error: IUIError;
}

export function sessionsReducer(
  state: ISessionsState = {
    isFetched: false,
    isFetching: false,
    items: [] as Session[],
    tree: null,
    map: {},
    error: null,
  },
  action: SessionsAction
): ISessionsState {
  switch (action.type) {
    case "REQUEST_SESSIONS":
      return { ...state, isFetching: true, isFetched: false, error: null };
    case "RECEIVE_SESSIONS": {
      const sessions = addChildSessionsToSession(action.items);
      return { ...state, isFetching: false, isFetched: true, items: action.items, tree: createSessionTree(action.items), map: toIdMap(sessions), error: null };
    }
    case "ERROR_RECEIVE_SESSIONS":
      return { ...state, isFetching: false, isFetched: false, error: action.error };
    case "ADD_SESSION":
      return {
        ...state,
        items: addItemAndReturnCollection(state.items, action.item),
        map: toIdMap(state.items),
        isFetching: false,
        isFetched: true,
        error: null,
      };
    default: {
      return state;
    }
  }
}
