import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { FeatureTogglesModule } from "@webapp/feature-toggles/feature-toggles.module";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { UiLoadingIndicatorModule } from "@webapp/ui/loading-indicator/loading-indicator.module";
import { ErrorViewModule } from "../error-view/error-view.module";
import { IndicatorComponent } from "./indicator.component";

@NgModule({
  imports: [CommonModule, LocalizationModule, UiIconModule, UiButtonModule, ErrorViewModule, UiLoadingIndicatorModule, FeatureTogglesModule],
  declarations: [IndicatorComponent],
  exports: [IndicatorComponent],
})
export class IndicatorModule {}
