import { module } from "angular";
import { MetricDrawerTagsComponent } from "@webapp/okrs/metrics/components/metric-drawer-tags/metric-drawer-tags.component";
import { ConfidencePreview } from "./directives/confidence-preview.directive";
import { metricValue } from "./filters/metric-value.filter";
import { metric } from "./filters/metric.filter";
import { MetricService } from "./services/metric.service";

const mod = module("metricsCore", []);

mod.service("MetricService", MetricService);

mod.directive("confidencePreview", ConfidencePreview.factory());

mod.filter("metric", metric);
mod.filter("metricValue", metricValue);

mod.downgradeComponent("metricDrawerTags", { component: MetricDrawerTagsComponent, propagateDigest: false });

export default mod.name;
