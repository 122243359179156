import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { IRecentsModel, RecentsDtoModel } from "./recents.models";
import { RecentsState } from "./recents.state";

@Injectable({
  providedIn: "root",
})
export class RecentsApiService extends BaseApiService<IRecentsModel, RecentsDtoModel, RecentsState> {
  public constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: RecentsState) {
    super("uxcustomization/viewhistory", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }
}
