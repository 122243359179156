<div class="sp-m-0" [uiGutter]="[24, 24]" ui-row>
  <div class="sp-p-0" [uiXs]="24" ui-col>
    <div class="sp-pt-8 sp-pb-10" ui-row>
      <suggestion-drawer-paragraph [text]="groupTitle" size="mini" contrast="light" weight="semibold"></suggestion-drawer-paragraph>
    </div>

    <ng-template #noSuggestionReason>
      <pi-text-card
        [borderColorOnHover]="'light'"
        [fontColor]="'light'"
        [isRefreshBtnAvailable]="false"
        [isRemoveBtnAvailable]="false"
        [isAddBtnAvailable]="false"
        [textToDisplay]="suggestionGroup.noSuggestionReason | localize"
        data-test-id="no-title-suggestion-reason-card">
      </pi-text-card>
    </ng-template>

    <div ui-row>
      <div [uiXs]="24" ui-col>
        <ng-container *ngIf="suggestionGroup.hasAllDependencies; else noSuggestionReason">
          <ng-container *ngIf="suggestionGroup.isLoading">
            <pi-loading-card-v2
              *ngFor="let suggestion of suggestionGroup.suggestions"
              [isIconAvailable]="!!iconType"
              [suggestion]="{ id: suggestion.id, text: suggestion.suggestionText }"
              [instance]="titleInstance"
              [isCustomIcon]="true"
              [iconType]="iconType"
              [iconTheme]="iconTheme">
            </pi-loading-card-v2>
          </ng-container>

          <pi-clickable-error-card *ngIf="suggestionGroup.hasError" [instance]="titleInstance" [isRemoveBtnAvailable]="false" [errorData]="suggestionGroup.errorData"> </pi-clickable-error-card>

          <ng-container *ngIf="!suggestionGroup.hasError && !suggestionGroup.isLoading">
            <pi-feedback-card
              *ngFor="let suggestion of suggestionGroup.suggestions"
              [goal]="parent"
              [suggestion]="{ id: suggestion.id, text: suggestion.suggestionText }"
              [instance]="titleInstance"
              [isIconAvailable]="!!iconType"
              [iconType]="iconType"
              [iconTheme]="iconTheme"
              [suggestionFeedbackType]="suggestionFeedbackType"
              [suggestionFeedbackSubType]="suggestionFeedbackSubType"
              [attr.data-test-id]="'okr-suggestion-item-' + suggestionFeedbackSubType">
            </pi-feedback-card>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
