<table class="ant-picker-content" [attr.aria-labelledby]="uiTableLabelledBy" role="grid" data-test-id="date-picker-content">
  @if(headRow && headRow.length > 0) {
  <thead>
    <tr>
      @if(showWeek) {
      <th></th>
      } @for(cell of headRow; track cell) {
      <th>{{ cell.title }}</th>
      }
    </tr>
  </thead>
  }
  <tbody>
    @for(row of bodyRows; track row.trackByIndex) {
    <tr [ngClass]="row.classMap!">
      @if(row.weekNum) {
      <td [class]="prefixCls + '-cell-week'">{{ row.weekNum }}</td>
      } @for(cell of row.dateCells; track cell.trackByIndex) {
      <td
        [ngClass]="cell.classMap!"
        [tabindex]="cell.isDisabled ? -1 : 0"
        [attr.data-row]="row.trackByIndex"
        [attr.data-col]="cell.trackByIndex"
        [attr.aria-selected]="cell.isSelected"
        [attr.aria-disabled]="cell.isDisabled"
        (click)="cell.isDisabled ? null : cell.onClick()"
        (keyup.space)="cell.isDisabled ? null : cell.onClick()"
        (keyup.enter)="cell.isDisabled ? null : cell.onClick()"
        (keyup.arrowup)="datePickerService.moveTableCellFocusTo({ rowIndex: row.trackByIndex - 1, cellIndex: cell.trackByIndex }, elementRef)"
        (keyup.arrowdown)="datePickerService.moveTableCellFocusTo({ rowIndex: row.trackByIndex + 1, cellIndex: cell.trackByIndex }, elementRef)"
        (keyup.arrowleft)="datePickerService.moveTableCellFocusTo({ rowIndex: row.trackByIndex, cellIndex: cell.trackByIndex - 1 }, elementRef)"
        (keyup.arrowright)="datePickerService.moveTableCellFocusTo({ rowIndex: row.trackByIndex, cellIndex: cell.trackByIndex + 1 }, elementRef)"
        (keyup.home)="datePickerService.moveTableCellFocusTo({ rowIndex: row.trackByIndex, cellIndex: 0 }, elementRef)"
        (keyup.end)="datePickerService.moveTableCellFocusTo({ rowIndex: row.trackByIndex, cellIndex: row.dateCells.length - 1 }, elementRef)"
        (mouseenter)="cell.onMouseEnter()">
        @switch (prefixCls) { @case('ant-picker') { @if(cell.isTemplateRef) {
        <!-- *ngSwitchCase not has type assertion support, the cellRender type here is TemplateRef -->
        <ng-container *ngTemplateOutlet="$any(cell.cellRender); context: { $implicit: cell.value }"></ng-container>
        }@else if(cell.isNonEmptyString) {
        <span [innerHTML]="cell.cellRender"></span>
        }@else {
        <div [class]="prefixCls + '-cell-inner'" [attr.aria-label]="cell.ariaLabel">{{ cell.content }}</div>
        } } @case('ant-picker-calendar') {
        <div [class]="prefixCls + '-date ant-picker-cell-inner'" [class.ant-picker-calendar-date-today]="cell.isToday">
          @if(cell.fullCellRender) {
          <ng-container *ngTemplateOutlet="$any(cell.fullCellRender); context: { $implicit: cell.value }"></ng-container>
          }@else() {
          <div [class]="prefixCls + '-date-value'">{{ cell.content }}</div>
          <div [class]="prefixCls + '-date-content'">
            <ng-container *ngTemplateOutlet="$any(cell.cellRender); context: { $implicit: cell.value }"></ng-container>
          </div>
          }
        </div>
        } }
      </td>
      }
    </tr>
    }
  </tbody>
</table>
