import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { ICollection } from "@webapp/core/core.models";
import { ISearchParams } from "../models/search-api.models";
import { IGlobalMultiSearchItems, Search, SearchCollection, SearchDTO, SearchMultiDTO } from "../models/search.models";
import { SearchApiService } from "./search-api.service";
import { SearchState } from "./search-state.service";

@Injectable({
  providedIn: "any",
})
export class SearchFacade extends BaseFacade<Search<null>, SearchDTO | SearchMultiDTO, SearchState, SearchApiService> {
  constructor(state: SearchState, api: SearchApiService) {
    super(state, api);
  }

  public searchData$<T extends SearchCollection>(searchBody: SearchDTO, params?: Partial<ISearchParams>): Observable<ICollection<Search<T>>> {
    const config = { ...new RequestConfig(), url: this.apiService.getMultiPath(HttpActions.post), queryParams: params };

    return this.post$<ICollection<Search<T>>>(searchBody, config);
  }

  public searchDataV2$(searchBody: SearchDTO | SearchMultiDTO, params?: Partial<ISearchParams>): Observable<IGlobalMultiSearchItems> {
    const config = { ...new RequestConfig(), url: this.apiService.getMultiPathV2(HttpActions.post), queryParams: params };

    return this.post$<IGlobalMultiSearchItems>(searchBody, config);
  }
}
