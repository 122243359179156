import { IHttpService, IPromise } from "angular";
import { EnvironmentService, IAppConfig } from "@gtmhub/env";
import { IUserMultiAccount } from "../models";

export class MultiAccountService {
  public static $inject = ["$http", "EnvironmentService", "appConfig"];

  constructor(
    private $http: IHttpService,
    private env: EnvironmentService,
    private appConfig: IAppConfig
  ) {}

  getUserMultiAccounts(clientId: string): IPromise<IUserMultiAccount[]> {
    const url = this.env.getApiEndpoint(`/users/managed-accounts/${clientId}`);

    return this.$http.get<IUserMultiAccount[]>(url).then((response) => response.data);
  }

  acceptMultiAccountInvitation(invitationId: string): IPromise<unknown> {
    const url = this.env.getApiEndpoint(`/users/invite/${invitationId}`);

    return this.$http.post<unknown>(url, null);
  }

  rejectMultiAccountInvitation(invitationId: string): IPromise<unknown> {
    const url = this.env.getApiEndpoint(`/users/invite/${invitationId}`);

    return this.$http.delete<unknown>(url);
  }

  switchUserAccount(account: IUserMultiAccount, currentAccountId: string, currentUserEmail: string): void {
    if (account.accountId === currentAccountId) {
      return;
    }

    let queryString: string;
    if (account.userIsPrimary) {
      queryString = `email=${currentUserEmail}`;
    } else {
      queryString = "isSecondary=true";
    }

    if (this.appConfig.env.bypassDomainCheck) {
      const gtmhubDomain = this.env.constructGtmhubDomain(account.accountDomain, { preserveDomainForLocalhost: true, overrideDomainToQuantive: true });
      queryString += `&originalDomain=${gtmhubDomain}`;
    }

    const domain = this.env.constructGtmhubDomain(account.accountDomain);

    // go to login => auto-resolve user using the auth0 cookie
    window.open(`${location.protocol}//${domain}/login?${queryString}`, "_self");
  }
}
