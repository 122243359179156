import { NgModule } from "@angular/core";
import { race, take } from "rxjs";
import { AccountResolverService } from "@webapp/accounts";
import { AccountServiceProvider } from "@webapp/accounts/providers/account.provider";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { FeatureTogglesFacadeProvider } from "./providers/feature-toggles-facade.provider";
import { FeatureTogglesApiService } from "./services/feature-toggles-api.service";
import { FeatureTogglesFacade } from "./services/feature-toggles-facade.service";
import { FeatureTogglesState } from "./services/feature-toggles-state.service";

@NgModule({
  providers: [FeatureTogglesApiService, FeatureTogglesState, AccountServiceProvider, FeatureTogglesFacadeProvider],
})
export class FeatureTogglesFacadeModule {
  constructor(accountResolverService: AccountResolverService, featureTogglesFacade: FeatureTogglesFacade, broadcastService: BroadcastService) {
    const account = accountResolverService.getAccountData();
    if (account) {
      featureTogglesFacade.initialize();
    } else {
      race([broadcastService.on("accountCreated"), broadcastService.on("authorizationProcessed"), broadcastService.on("accountDataInitialized")])
        .pipe(take(1))
        .subscribe(() => {
          featureTogglesFacade.initialize();
        });
    }
  }
}
