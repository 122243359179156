import { IAttributes, ICompileService, IDirective, IDirectiveFactory, IScope } from "angular";

/**
 * A directive which is used to compile HTML string and also included ng directives
 * @example "localization_key": "<p>Click on this <span ng-click='fn()')>link</span> to do something.</p>"
 * @example .description(gh-compile="'localization_key' | localize")
 * @param ghCompile: string that includes HTML structure
 */

export class GhCompileDirective implements IDirective {
  constructor(private $compile: ICompileService) {}

  public link(scope: IScope, element: JQLite, attrs: IAttributes): void {
    scope.$watch(
      (scope) => {
        return scope.$eval(attrs.ghCompile);
      },
      (value) => {
        element.html(value);
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        this.$compile(element.contents() as unknown as any)(scope);
      }
    );
  }

  public static factory(): IDirectiveFactory {
    const directive = (compile: ICompileService) => new GhCompileDirective(compile);

    directive.$inject = ["$compile"];
    return directive;
  }
}
