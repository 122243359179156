import { Injectable } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { map } from "rxjs";
import { ICollection } from "@webapp/core/core.models";
import { CustomViewsRepository } from "@webapp/navigation/components/navigation/sub-navigation/tasks-sub-navigation/services/custom-views.repository";
import { INavItem } from "@webapp/navigation/models/nav-items-list.models";

@UntilDestroy()
@Injectable()
export class TasksNavItemsService {
  public navItems$ = this.customViewsRepository.get$().pipe(untilDestroyed(this), map(this.toNavItemsCollection));

  constructor(private customViewsRepository: CustomViewsRepository) {}

  private toNavItemsCollection(filters): ICollection<INavItem> {
    return {
      items: filters.map((hubFilter) => ({
        id: hubFilter.id,
        uxcType: "task",
        title: hubFilter.name,
      })),
      totalCount: filters.length,
    };
  }
}
