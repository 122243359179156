import { IAttributes, IAugmentedJQuery, IDirective, IDirectiveFactory, IScope } from "angular";

export class TabAction implements IDirective {
  public restrict = "A";

  public link(scope: IScope, elm: IAugmentedJQuery, attrs: IAttributes): void {
    if (!attrs.tabindex) {
      return;
    }

    elm.on("keydown", function (evt: JQueryEventObject) {
      const isEnter = evt.keyCode === 13;
      const isSpace = evt.keyCode === 32;

      if (isEnter || isSpace) {
        elm[0].click();
        evt.preventDefault();
      }
    });
  }

  public static factory(): IDirectiveFactory {
    return () => new TabAction();
  }
}
