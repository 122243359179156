import { Pipe, PipeTransform } from "@angular/core";
import { UiI18nService } from "@quantive/ui-kit/i18n";
import dayjs, { Dayjs } from "@webapp/shared/libs/dayjs";

type LocalOrUtcTimeZone = "utc" | "local";

@Pipe({ name: "dateDiffFromNow", standalone: true })
export class DateDiffFromNowPipe implements PipeTransform {
  constructor(private i18n: UiI18nService) {}

  public transform(input: string, timezone?: LocalOrUtcTimeZone, currentDate?: Dayjs): string {
    const timezoneDate = timezone === "utc" ? dayjs.utc : dayjs;
    const now = currentDate ? currentDate : timezone === "utc" ? dayjs.utc() : dayjs();
    const locale = this.i18n.getLocaleData("DatePicker");

    return timezoneDate(input).calendar(now, {
      lastDay: `[${locale.lang.yesterday}]`,
      sameDay: `[${locale.lang.today}]`,
      nextDay: `[${locale.lang.tomorrow}]`,
      lastWeek: "",
      nextWeek: "",
      sameElse: "",
    });
  }
}
