import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { CreateReactionDTO, Reaction } from "../models/reactions.models";
import { ReactionsState } from "./reactions-state.service";

@Injectable({
  providedIn: "root",
})
export class ReactionsApiService extends BaseApiService<Reaction, CreateReactionDTO, ReactionsState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: ReactionsState) {
    super("reactions", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getReactionsEndpoint(): string {
    return `${this.getBasePath(HttpActions.get)}`;
  }

  public getDeleteReactionEndpoint(reactionName: string, targetId: string): string {
    return `${this.getBasePath(HttpActions.delete)}/${reactionName}/${targetId}`;
  }
}
