import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DateViewComponent } from "@webapp/shared/date-view/date-view.component";
import { DateDiffFromNowPipe } from "@webapp/shared/pipes/date-diff-from-now.pipe";

@NgModule({
  declarations: [DateViewComponent],
  exports: [DateViewComponent],
  imports: [CommonModule, DateDiffFromNowPipe],
})
export class DateViewModule {}
