import { NgModule } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { PdfViewerModule } from "@webapp/shared/pdf-viewer/pdf-viewer.module";
import { PrivacyNoticeComponent } from "./privacy-notice.component";

@NgModule({
  imports: [LocalizationModule, UiButtonModule, PdfViewerModule],
  declarations: [PrivacyNoticeComponent],
  exports: [PrivacyNoticeComponent],
})
export class PrivacyNoticeModule {}
