import { NgClass, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, Host, Input, Optional, TemplateRef } from "@angular/core";
import { AbstractControl, FormControlDirective, FormControlName, NgControl, NgModel } from "@angular/forms";
import { UiI18nService } from "@quantive/ui-kit/i18n";
import { NzFormStatusService } from "ng-zorro-antd/core/form";
import { NzStringTemplateOutletDirective } from "ng-zorro-antd/core/outlet";
import { NzFormControlComponent, NzFormDirective } from "ng-zorro-antd/form";
import { UiFormItemComponent } from "../form-item/form-item.component";

@Component({
  selector: "ui-form-control",
  exportAs: "uiFormControl",
  templateUrl: "form-control.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NzFormStatusService],
  standalone: true,
  imports: [NgIf, NgClass, NzStringTemplateOutletDirective],
})
export class UiFormControlComponent extends NzFormControlComponent {
  @ContentChild(NgControl, { static: false }) public defaultValidateControl?: FormControlName | FormControlDirective;
  @Input("uiSuccessTip") public nzSuccessTip?: string | TemplateRef<{ $implicit: AbstractControl | NgModel }>;
  @Input("uiWarningTip") public nzWarningTip?: string | TemplateRef<{ $implicit: AbstractControl | NgModel }>;
  @Input("uiErrorTip") public nzErrorTip?: string | TemplateRef<{ $implicit: AbstractControl | NgModel }>;
  @Input("uiValidatingTip") public nzValidatingTip?: string | TemplateRef<{ $implicit: AbstractControl | NgModel }>;
  @Input("uiExtra") public nzExtra?: string | TemplateRef<void>;
  @Input("uiAutoTips") public nzAutoTips: Record<string, Record<string, string>> = {};
  @Input("uiDisableAutoTips") public nzDisableAutoTips = false;

  @Input("formHasFeedback")
  set formHasFeedback(value: boolean) {
    this.nzHasFeedback = value;
  }

  get formHasFeedback(): boolean {
    return this.nzHasFeedback;
  }

  @Input("formValidateStatus")
  set formValidateStatus(value: string | AbstractControl | FormControlName | NgModel) {
    this.nzValidateStatus = value;
  }

  constructor(
    @Optional() @Host() nzFormItemComponent: UiFormItemComponent,
    cdr: ChangeDetectorRef,
    i18n: UiI18nService,
    @Optional() nzFormDirective: NzFormDirective,
    nzFormStatusService: NzFormStatusService
  ) {
    super(nzFormItemComponent, cdr, i18n, nzFormDirective, nzFormStatusService);
  }
}
