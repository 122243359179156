<div
  class="simple-global-search-actions"
  [uiVisible]="shouldShowPopover"
  [uiContent]="resultTemplate"
  [uiTrigger]="null"
  (keydown.escape)="onEsc($event)"
  ui-popover
  uiOverlayClassName="without-arrow"
  uiPlacement="bottomLeft">
  <input
    class="search-field"
    id="search-input"
    #searchInput
    autocomplete="off"
    [(ngModel)]="searchTerm"
    [placeholder]="searchPlaceholder"
    [modelChangeDebounce]="500"
    (ngModelChange)="onSearchTermChange()"
    (keydown.arrowDown)="focusSearchItems()" />
</div>
<ng-template #resultTemplate>
  <div class="search-results" #resultsPopover>
    <search-results [maxItems]="totalNumberOfItems" [isCustomClickHandlerAvailable]="true" (escKeydown)="onEsc($event)" searchMode="simple"></search-results>
  </div>
</ng-template>
