import { UIRouterModule } from "@uirouter/angular";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiCardModule } from "@quantive/ui-kit/card";
import { take } from "rxjs";
import { ApmService } from "@gtmhub/core/tracing/apm.service";
import { localize } from "@gtmhub/localization";
import { VIEW_FEED_WIDGET_ID } from "@webapp/home/models/home-widgets.models";
import { HomeWidgetConfigurator } from "@webapp/home/services/home-widget-configuration.service";
import { HomeWidgetService } from "@webapp/home/services/home-widget.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { DefaultSessionResolverService } from "@webapp/sessions/services/default-session-resolver.service";
import { SessionsRepository } from "@webapp/sessions/services/sessions-repository.service";
import { BaseWidgetComponent } from "@webapp/ui/dashboard/components/base-widget.component";
import { UiDashboardWidget } from "@webapp/ui/dashboard/dashboard.models";
import { UiToastService } from "@webapp/ui/toast/services/toast.service";
import { WidgetActionsComponent } from "../widget-actions/widget-actions.component";

@UntilDestroy()
@Component({
  selector: "view-alignment-widget",
  templateUrl: "./view-alignment-widget.component.html",
  styleUrls: ["./view-alignment-widget.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiButtonModule, UiCardModule, UIRouterModule, CommonModule, LocalizationModule, WidgetActionsComponent],
})
export class ViewAlignmentWidgetComponent extends BaseWidgetComponent implements OnInit {
  public defaultSessionId: string;
  private widget: UiDashboardWidget;
  private viewFeedWidget: UiDashboardWidget;
  private widgetConfigurator: HomeWidgetConfigurator;

  public get title(): string {
    return localize("explore_alignment");
  }

  public get a11yLabel(): string {
    return "Explore Alignment";
  }

  constructor(
    private defaultSessionResolverService: DefaultSessionResolverService,
    private sessionsRepository: SessionsRepository,
    private cdr: ChangeDetectorRef,
    private homeWidgetService: HomeWidgetService,
    private toastService: UiToastService,
    private apmService: ApmService
  ) {
    super();
    this.apmService.startDataLoadSpan("view-alignment-widget-init");
  }

  public ngOnInit(): void {
    this.sessionsRepository
      .getMap$()
      .pipe(take(1))
      .subscribe((sessionsMap) => {
        const session = this.defaultSessionResolverService.getDefaultSession([...sessionsMap.values()]);
        this.defaultSessionId = session?.id;
        this.cdr.markForCheck();
        this.apmService.endDataLoadSpan("view-alignment-widget-init");
      });

    this.homeWidgetService
      .getDashboardWidgets$()
      .pipe(untilDestroyed(this))
      .subscribe((widgets) => {
        this.widget = widgets.find((w) => w.id === this.widgetId);
        this.viewFeedWidget = widgets.find((w) => w.id === VIEW_FEED_WIDGET_ID);
        this.widgetConfigurator = new HomeWidgetConfigurator(this.homeWidgetService, widgets);
      });
  }

  public remove(): void {
    if (!this.widget) {
      throw new Error(`Cannot find ${this.widgetId} widget`);
    }

    const viewAlignmentWidget = this.widget;
    this.homeWidgetService.removeWidget(this.widgetId);

    if (this.viewFeedWidget) {
      this.widgetConfigurator.toggleFullWidth(this.viewFeedWidget.id);
    }

    const message = localize("you_can_find_alignment_in_navigation");
    this.toastService.info(message, {
      uiShouldTimeout: true,
      uiDuration: 5000,
      uiPrimaryCTA: localize("undo"),
      uiOnPrimaryAction: () => {
        if (this.viewFeedWidget) {
          this.widgetConfigurator.toggleFullWidth(this.viewFeedWidget.id);
          this.widgetConfigurator.moveRight(this.viewFeedWidget.id);
        }

        this.homeWidgetService.addWidget(viewAlignmentWidget);
      },
    });
  }
}
