import { AfterViewInit, Directive, ElementRef, EventEmitter, Host, Input, OnDestroy, Output } from "@angular/core";

export type IsInViewportBoundingBoxMargins = {
  mt: number;
  mr: number;
  mb: number;
  ml: number;
};

export type IsInViewportObserveUntil = "firstValue" | "visible" | "destroyed";

/**
 * @description - This directive would emit whenever the DOM element it is applied to enters/leaves the viewport.
 * @example - <div (visibilityChange)="visibilityChangeHandler($event)" isInViewport></div>
 */
@Directive({
  selector: "[isInViewport]",
  standalone: true,
})
export class IsInViewportDirective implements AfterViewInit, OnDestroy {
  /**
   * @param rootMarginData
   * @description - Can have values similar to the CSS margin property.
   * This set of values serves to grow or shrink each side of the root element's bounding box before computing intersections.
   *
   * @see - https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#rootmargin
   * @see - https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/rootMargin
   */
  @Input() public rootMarginData: Partial<IsInViewportBoundingBoxMargins>;

  /**
   * @param threshold
   * @description - Either a single number or an array of numbers which indicate at what percentage of the target's visibility the observer's callback should be executed.
   * If you want the callback to run every time visibility passes another 25%, you would specify the array [0, 0.25, 0.5, 0.75, 1].
   * The default is 0 (meaning as soon as even one pixel is visible, the callback will be run).
   * A value of 1.0 means that the threshold isn't considered passed until every pixel is visible.
   *
   * @see - https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#threshold
   */
  @Input() public threshold: number | number[];

  /**
   * @param observeUntil
   * @description Controls when to stop observing.
   * "firstValue" - stop observing (disconnect) after the first emitted value;
   * "visible" - stop observing (disconnect) the first time the DOM element becomes visible;
   * "destroyed" - emit every time the DOM element enters/leaves the viewport. Disconnect logic is executed in ngOnDestroy.
   */
  @Input({ required: true }) public observeUntil: IsInViewportObserveUntil;

  @Output() public readonly visibilityChange = new EventEmitter<boolean>();

  private observer: IntersectionObserver;

  constructor(@Host() private elementRef: ElementRef) {}

  public ngAfterViewInit(): void {
    const observerOptions: IntersectionObserverInit = { root: null, rootMargin: this.buildRootMargin(), threshold: this.threshold || 0 };
    this.observer = new IntersectionObserver(this.observerCallback, observerOptions);
    this.observer.observe(this.elementRef.nativeElement);
  }

  public ngOnDestroy(): void {
    this.observer.disconnect();
  }

  private buildRootMargin = (): string => {
    if (!this.rootMarginData) {
      return "0px";
    }

    const mt = this.rootMarginData.mt || 0;
    const mr = this.rootMarginData.mr || 0;
    const mb = this.rootMarginData.mb || 0;
    const ml = this.rootMarginData.ml || 0;

    return `${mt}px ${mr}px ${mb}px ${ml}px`;
  };

  private observerCallback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver): void => {
    entries.forEach((entry) => {
      this.visibilityChange.emit(entry.isIntersecting);

      const shouldStopObserving = this.observeUntil === "firstValue" || (this.observeUntil === "visible" && entry.isIntersecting);

      if (shouldStopObserving) {
        observer.disconnect();
      }
    });
  };
}
