export interface IUserMultiAccount {
  accountId: string;
  accountName: string;
  accountDomain: string;
  userId: string;
  userIsPrimary: boolean;
  invitation?: IUserMultiAccountInvitation;
}

interface IUserMultiAccountInvitation {
  id: string;
  invitedByEmail: string;
  invitedByNames: string;
  invitedOn: string;
  // 0 = pending
  // 1 = approved
  // 2 = rejected
  status: UserMultiAccountInvitationType;
}

type UserMultiAccountInvitationType = 0 | 1 | 2;

export enum UserMultiAccountInvitationTypes {
  pending = 0,
  approved = 1,
  rejected = 2,
}
