import { IComponentOptions, IController, IPromise, IQService, IScope } from "angular";
import { IAssigneesStoreState } from "@gtmhub/assignees";
import { GtmhubController } from "@gtmhub/core";
import { IModalComponent } from "@gtmhub/core/routing";
import { IIndicator } from "@gtmhub/error-handling";
import { IRole, IRolesStoreState, RolesActions, filterOutTeamRoles } from "@gtmhub/roles";
import { IExtendedRootScopeService } from "@gtmhub/shared/ng-extensions";
import { INgRedux } from "@gtmhub/state-management";
import { ITeam, ITeamsStoreState, TeamsActions, addAssigneeDataToTeams, addAssigneeDataToTeamsMap } from "@gtmhub/teams";
import { IUser, UserService } from "@gtmhub/users";

const bindStateToCtrl = function (state: ITeamsStoreState & IRolesStoreState & IAssigneesStoreState) {
  return {
    teams: addAssigneeDataToTeams(state.teams.items, state.assignees.map),
    teamsMap: addAssigneeDataToTeamsMap(state.teams.map, state.assignees.map),
    roles: filterOutTeamRoles(state),
    reduxItemsFetched: state.teams.isFetched && state.assignees.isFetched && state.roles.isFetched,
    reduxError: state.teams.error || state.assignees.error || state.roles.error,
  };
};

export type IncludedInPermissionBindings = {
  principalId: string;
  principalKind: "team" | "role";
};

export interface IncludedInPermissionComponentCtrl extends IModalComponent<IncludedInPermissionBindings>, ReturnType<typeof bindStateToCtrl> {}
export class IncludedInPermissionComponentCtrl extends GtmhubController implements IController {
  loading: IIndicator;
  users: IUser[];
  principal: IRole | ITeam;

  public static $inject = ["$scope", "$q", "UserService", "$ngRedux", "TeamsActions", "RolesActions"];

  constructor(
    private $scope: IScope & IExtendedRootScopeService,
    private $q: IQService,
    private userService: UserService,
    private $ngRedux: INgRedux,
    private teamsActions: TeamsActions,
    private rolesActions: RolesActions
  ) {
    super();
  }

  $onInit(): void {
    this.onDestroy(this.$ngRedux.connect(bindStateToCtrl)(this));
    this.$ngRedux.dispatch(this.teamsActions.fetchTeamsIfMissing());
    this.$ngRedux.dispatch(this.rolesActions.getRolesIfMissing());

    this.loading = { progress: true };
    this.$q
      .all([this.fetchReduxItems(), this.fetchUsersInRole()])
      .then(() => delete this.loading)
      .catch((error) => (this.loading = { error }));
  }

  private fetchReduxItems = (): IPromise<void> => {
    return this.$scope
      .watchUntilAndRejectOnError(
        () => this.reduxItemsFetched,
        () => this.reduxError
      )
      .then(() => {
        this.principal = this.findPrincipal();
      });
  };

  private fetchUsersInRole = (): IPromise<void> => {
    return this.userService.getAllUsers({ filter: `roles:${this.resolve.principalId}`, includeShadowedRoles: true }).then((usersCollection) => {
      this.users = angular.copy(usersCollection.items);
      if (this.users?.length > 0) {
        this.users.sort((user1, user2) => {
          if (user1.isActive === user2.isActive) {
            return 0;
          } else {
            return user1.isActive ? -1 : 1;
          }
        });
      }
    });
  };

  private findPrincipal = (): IRole | ITeam => {
    const { principalId, principalKind } = this.resolve;
    switch (principalKind) {
      case "team":
        return this.teamsMap[principalId];
      case "role":
        return this.roles.find((role) => role.id === principalId);
      default:
        return null;
    }
  };
}

export const IncludedInPermissionComponent: IComponentOptions = {
  template: require("./included-in-permission.html"),
  controller: IncludedInPermissionComponentCtrl,
  bindings: {
    resolve: "<",
    close: "&",
    dismiss: "&",
  },
};
