import { Injectable, Injector } from "@angular/core";
import { Observable, map, take } from "rxjs";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { IQuantivePlusWhiteboardSuggestionsFacade } from "@webapp/platform-intelligence/shared/services/quantive-plus-whiteboard/quantive-plus-whiteboard-facade.interface";
import { QuantivePlusWhiteboardFacadeV1 } from "@webapp/platform-intelligence/shared/services/quantive-plus-whiteboard/v1/quantive-plus-whiteboard-facade-v1.service";
import { QuantivePlusWhiteboardFacadeV2 } from "@webapp/platform-intelligence/shared/services/quantive-plus-whiteboard/v2/quantive-plus-whiteboard-facade-v2.service";

type QuantivePlusSuggestionType = "whiteboard";

@Injectable({
  providedIn: "root",
})
export class QuantivePlusWhiteboardSuggestionsProviderFactory {
  constructor(
    private injector: Injector,
    private featureTogglesFacade: FeatureTogglesFacade
  ) {}

  public getInstance(suggestionType: QuantivePlusSuggestionType): Observable<IQuantivePlusWhiteboardSuggestionsFacade> {
    const getProviderBySuggestionType: {
      [key in QuantivePlusSuggestionType]: () => Observable<IQuantivePlusWhiteboardSuggestionsFacade>;
    } = {
      whiteboard: this.getWhiteboardSuggestionsProvider.bind(this),
    };
    return getProviderBySuggestionType[suggestionType]();
  }

  private getWhiteboardSuggestionsProvider(): Observable<IQuantivePlusWhiteboardSuggestionsFacade> {
    return this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.WhiteboardObjectiveKRSuggestions).pipe(
      take(1),
      map((isV2SuggestionsAvailable) => {
        if (isV2SuggestionsAvailable) {
          return this.injector.get(QuantivePlusWhiteboardFacadeV2);
        }
        return this.injector.get(QuantivePlusWhiteboardFacadeV1);
      })
    );
  }
}
