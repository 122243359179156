// See more examples at http://youmightnotneedjquery.com
import { IAugmentedJQuery } from "angular";
import { localize } from "@gtmhub/localization";
import { defaultCopyURLNotificationEl } from "./utils/dom-utils";

export const offset = (el: HTMLElement): { top: number; left: number } => {
  if (!el) {
    return;
  }

  const rect = el.getBoundingClientRect();
  return {
    top: rect.top + document.body.scrollTop,
    left: rect.left + document.body.scrollLeft,
  };
};

export const triggerFileDownload = (blob: Blob, filename: string): void => {
  const downloadableEl = document.createElement("a");
  downloadableEl.href = URL.createObjectURL(blob);
  downloadableEl.download = filename;
  downloadableEl.click();
};

export const copyToClipboard = (
  text: string,
  options: { container: HTMLDivElement; duration: number } = { container: defaultCopyURLNotificationEl(), duration: 900 }
): void => {
  const notificationContainer = options.container;
  const notificationText = document.createTextNode(localize("copied_to_clipboard"));
  notificationContainer.appendChild(notificationText);
  document.body.appendChild(notificationContainer);
  window.setTimeoutOutsideAngular(() => notificationContainer.remove(), options.duration);

  const clipEl = document.createElement("input");
  clipEl.id = "clip";
  document.body.appendChild(clipEl);

  clipEl.value = text;
  clipEl.select();
  document.execCommand("copy");
  clipEl.remove();
};

export const toggleClass = (el: HTMLElement, className: string): void => {
  if (el.classList.contains(className)) {
    el.classList.remove(className);
  } else {
    el.classList.add(className);
  }
};

export const indexOfSameClassSiblings = (el: Element, className: string): number => {
  let i = -1;
  do {
    if (el.classList.contains(className)) {
      i++;
    }
  } while ((el = el.previousElementSibling));

  return i;
};

export const documentReady = (callback: () => void): void => {
  if (document.readyState != "loading") {
    callback();
  } else {
    document.addEventListener("DOMContentLoaded", callback);
  }
};

export const blurFocusedElement = (): void => {
  const el = document.querySelector<HTMLElement>(":focus");
  if (el) {
    el.blur();
  }
};

export const clickOnElement = (selector: string): void => {
  const el = document.querySelector<HTMLElement>(selector);
  if (el) el.dispatchEvent(new MouseEvent("click"));
};

export const hasParentElement = (element: IAugmentedJQuery, parentSelector: string): boolean => {
  const parent = document.querySelector<HTMLElement>(parentSelector);
  if (!element || !parent) return;

  return parent.contains(element[0]);
};

export const hasClass = (element: HTMLElement, classNames: string | string[]): boolean => {
  const elementClassList = Array.from(element.classList);

  if (typeof classNames === "string") {
    return elementClassList.includes(classNames);
  }

  return classNames.map((className) => elementClassList.includes(className)).some((c) => c);
};
