/**
 * Gets all items for the target selector, takes the width of the largest one, and applies it to each other element of the same selector.
 */
export const emulateColumnWidthToElements = (container: Element, targetSelector: string): void => {
  const targetElements = container?.querySelectorAll<HTMLElement>(targetSelector);
  if (!targetElements?.length) {
    return;
  }

  const [largestElement] = [...targetElements].sort((a, b) => {
    return parseFloat(getComputedStyle(b).width) - parseFloat(getComputedStyle(a).width);
  });

  const largetElementWidth = getComputedStyle(largestElement).width;

  targetElements.forEach((element) => (element.style.width = largetElementWidth));
};
