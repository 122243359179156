import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

export type SaveFilterChangesContent = {
  text: string;
  ctaCb?(): void;
}[];

@Component({
  selector: "save-filter-changes",
  templateUrl: "./save-filter-changes.component.html",
  styleUrls: ["./save-filter-changes.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveFilterChangesComponent {
  @Input() public content: SaveFilterChangesContent;
}
