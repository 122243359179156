import { Injectable } from "@angular/core";
import { AccountService } from "@gtmhub/accounts/accounts.service";
import { IConfidenceMapping, IConfidenceSettings } from "@gtmhub/core";
import { ConfidenceScale, ConfidenceScaleEnum, ConfidenceType } from "@webapp/configuration/models/configuration.model";
import { localize } from "@webapp/localization/utils/localization.utils";
import { ConfidenceSettingsService } from "@webapp/okrs/metrics/services/confidence-settings.service";
import { getConfidenceFloatNumber } from "@webapp/okrs/utils/confidence-level.utils";

export interface ExctractedConfidenceValues {
  confidenceName: string;
  color: string;
  emojiClass: string;
  confidenceType: ConfidenceType;
  confidenceLevelTooltip: string;
}

@Injectable()
export class ConfidenceExtractorService {
  private confidenceValue: string;
  private confidenceValueOriginal: string;
  private confidenceType: ConfidenceType;
  private confidenceName: string;
  private confidenceSettings: IConfidenceSettings;
  private confidenceLevelTooltip: string;
  private mappings: IConfidenceMapping[];
  private color: string;
  private emojiClass: string;

  constructor(
    private accountService: AccountService,
    private confidenceService: ConfidenceSettingsService
  ) {}

  public extractConfidenceValues(confidenceValue: string): ExctractedConfidenceValues {
    if (this.confidenceValueOriginal !== confidenceValue) {
      this.initialize(confidenceValue);
    }

    return {
      confidenceName: this.confidenceName,
      color: this.color,
      emojiClass: this.emojiClass,
      confidenceType: this.confidenceType,
      confidenceLevelTooltip: this.confidenceLevelTooltip,
    };
  }

  private initialize(confidenceValue: string): void {
    this.confidenceValueOriginal = confidenceValue;
    this.confidenceSettings = this.confidenceService.getConfidenceSettings();
    this.confidenceType = this.confidenceSettings?.confidenceType ?? "numeric";

    const scaleFactor = 10;
    this.confidenceValue = getConfidenceFloatNumber(parseFloat(confidenceValue), scaleFactor).toString(); // Convert back to string

    const confidenceMappingCopy = this.confidenceSettings.confidenceMapping.map((it) => Object.assign({}, it));

    this.mappings = this.getFloatedMappings(
      confidenceMappingCopy.sort((a: IConfidenceMapping, b: IConfidenceMapping) => a.range.to - b.range.to),
      scaleFactor
    );

    const mapping = this.findRange(parseFloat(this.confidenceValue), this.mappings);
    this.setViewVariables(mapping, parseFloat(this.confidenceValue));
  }

  private getFloatedMappings(mappings: IConfidenceMapping[], scaleFactor: number): IConfidenceMapping[] {
    const clonedMappings = mappings.map((m) => ({ ...m }));
    const biggestMappingRange = Math.max(...clonedMappings.map((m) => m.range.to));

    if (biggestMappingRange > 1) {
      clonedMappings.forEach((m) => {
        m.range.to = m.range.to / scaleFactor;
        m.range.from = m.range.from / scaleFactor;
      });
    }

    return clonedMappings;
  }

  private isScaleMinor(confidenceScale: ConfidenceScale): boolean {
    return confidenceScale === ConfidenceScaleEnum.Minor;
  }

  private findRange(value: number, mappings: IConfidenceMapping[]): IConfidenceMapping {
    return mappings.find((m) => m.range.from <= value && value <= m.range.to);
  }

  private setViewVariables(mapping: IConfidenceMapping, confidenceValue: number): void {
    if (!mapping) {
      this.confidenceName = null;
      this.color = null;
      this.emojiClass = null;
      return;
    }

    const confidenceScale = this.accountService.getConfidenceScale();
    const scaleFactor = 10;
    const confidenceTooltipValue = this.isScaleMinor(confidenceScale) ? confidenceValue.toFixed(1) : Math.round(confidenceValue * scaleFactor);

    switch (this.confidenceType) {
      case "numeric":
        this.confidenceName = this.isScaleMinor(confidenceScale) ? confidenceValue.toFixed(1) : Math.round(confidenceValue * scaleFactor) + "/10";
        this.color = mapping.color;
        this.confidenceLevelTooltip = localize("confidence_level_cap");
        break;

      case "text":
        this.confidenceName = mapping.name;
        this.color = mapping.color;
        this.confidenceLevelTooltip = `${localize("confidence_level_cap")} ${confidenceTooltipValue} ${mapping.name}`;
        break;

      case "emoji":
        this.emojiClass = "twa twa-lg twa-" + mapping.name;
        this.color = null;
        this.confidenceLevelTooltip = `${localize("confidence_level_cap")} ${confidenceTooltipValue}`;
        break;

      default:
        this.confidenceName = null;
        this.color = null;
        this.emojiClass = null;
        break;
    }
  }
}
