import { ICompileService, IRootScopeService, IScope } from "angular";
import { UiAvatarSize } from "@quantive/ui-kit/avatar";

interface IDynamicAssigneeTooltipOptions {
  left: number;
  top: number;
  avatarSize?: UiAvatarSize;
}

export interface IDynamicAssigneeTooltipService {
  show(assigneeIds: string[], options: IDynamicAssigneeTooltipOptions): void;
  hide(): void;
}

export class DynamicAssigneeTooltipService implements IDynamicAssigneeTooltipService {
  private $scope: IScope;

  static $inject = ["$compile", "$rootScope"];

  constructor(
    private $compile: ICompileService,
    private $rootScope: IRootScopeService
  ) {}

  show(assigneeIds: string[], options: IDynamicAssigneeTooltipOptions): void {
    if (this.$scope) {
      this.hide();
    }

    this.$scope = this.$rootScope.$new();
    this.$scope["assigneeIds"] = assigneeIds;
    this.$scope["avatarSize"] = options.avatarSize || "xs";

    const html = `<dynamic-assignee-tooltip id="dynamic-assignee-tooltip" assignee-ids="assigneeIds" avatar-size="avatarSize"></dynamic-assignee-tooltip>`;
    const el = this.$compile(html)(this.$scope)[0];

    const arrowLeftOffset = 22; // the position contains the coordinates where the arrow should point, so we need to move a bit to the left the entire element
    el.style.left = `${options.left - arrowLeftOffset}px`;
    el.style.top = `${options.top}px`;

    document.body.appendChild(el);
    this.$scope.$evalAsync();
  }

  hide(): void {
    if (this.$scope) {
      this.$scope.$destroy();
      this.$scope = null;
    }

    const el = document.getElementById("dynamic-assignee-tooltip");
    if (el) {
      el.remove();
    }
  }
}
