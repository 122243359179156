import { UIRouterGlobals } from "@uirouter/angular";
import { Injectable } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, filter, first, of, skip } from "rxjs";
import { byDateFieldDesc, byNumericFieldDesc } from "@gtmhub/util/sorting-utils";
import { IViewHistoryItemsGroupedReqParams, ViewHistoryItemGrouped, ViewHistoryService } from "@gtmhub/view-history";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { TracingService } from "@webapp/core/tracing/services/tracing.service";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import {
  CurrentPageInfo,
  GlobalSearchItem,
  GlobalSearchResult,
  ITEMS_LIMIT_IF_EVERYWHERE,
  ITEMS_LIMIT_IF_NOT_EVERYWHERE,
  RecentSearchFacetsOptions,
  SearchCollection,
  SearchConditionSettings,
  SearchFacetsAccessMap,
  SearchFacetsOptions,
  SearchFacetsOptionsEnum,
  SearchFacetsOptionsMap,
  SearchIndicators,
  SearchItemsLimit,
  SearchQueryParams,
  SearchTracingName,
  SearchTracingNames,
  searchCollectionTypeToUXCustomizationType,
  viewHistoryItemCollectionByRecentItemsFacetMap,
} from "@webapp/search/models/search.models";
import { GlobalSearchTrackingService } from "@webapp/search/services/global-search-tracking.service";
import { SearchFacade } from "@webapp/search/services/search-facade.service";
import {
  convertRecentResponseToOrderedArray,
  convertSearchResponseToOrderedArray,
  getFacetsAndQueryParams,
  getItemsLimit,
  getRecentItemsMongoQueries,
  getSearchBodyV2,
} from "@webapp/search/utils/global-search.utils";

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class GlobalSearchMediatorService {
  private searchResults = new BehaviorSubject<GlobalSearchResult[]>(null);
  private currentFacet = new BehaviorSubject<SearchFacetsOptions>(SearchFacetsOptionsEnum.Everywhere);
  private facetToggleOptions = new BehaviorSubject<SearchFacetsOptions[]>([]);
  private searchTerm = new BehaviorSubject<string>(null);
  private indicators = new BehaviorSubject<SearchIndicators>({});
  private recentItemsSubjects = new Map<RecentSearchFacetsOptions, ReplaySubject<GlobalSearchResult[]>>();
  private itemsLimit = new BehaviorSubject<SearchItemsLimit>(ITEMS_LIMIT_IF_EVERYWHERE);
  private hideSearchResults = new Subject<void>();
  private customItemClickHandler = new Subject<GlobalSearchItem<SearchCollection>>();

  // The below subject is used to keep track of the current page number and selected facet for the search results in the below scenarios:
  // 1. When the user changes the page number or refreshes the page.
  // 2. When the user changes the selected facet.
  // This was introduced as a fix for this issue: https://quantive-inc.atlassian.net/browse/GVS-55328
  private currentPageInfo = new BehaviorSubject<CurrentPageInfo>({ pageNumber: 1, selectedFacet: SearchFacetsOptionsEnum.Everywhere });

  private searchSettings: SearchConditionSettings = {};
  private readonly facetOptionsMap: SearchFacetsOptionsMap = {} as SearchFacetsOptionsMap;
  private requestsCount: number = 0;
  private recentItems: GlobalSearchResult[] = [];
  private isOtherItems: boolean;
  private skipLoading: boolean = false;

  // Used for simple search.
  private staticFacetsOptions: SearchFacetsOptions[];
  // Used for simple search. Items limit should be static no matter of the facet!
  private staticItemsLimit: SearchItemsLimit;
  // Paging
  private queryParams: SearchQueryParams = { skip: 0, take: ITEMS_LIMIT_IF_NOT_EVERYWHERE };

  constructor(
    private searchFacade: SearchFacade,
    private permissionsFacade: PermissionsFacade,
    private featureToggleFacade: FeatureTogglesFacade,
    private editionFacade: EditionFeatureService,
    private viewHistoryService: ViewHistoryService,
    private routerGlobals: UIRouterGlobals,
    private tracingService: TracingService,
    private globalSearchTrackingService: GlobalSearchTrackingService
  ) {
    this.setFacetsOptions();

    this.searchTerm$
      .pipe(
        filter((f) => f !== null),
        untilDestroyed(this)
      )
      .subscribe(() => this.loadSearchData());
    this.currentFacet$.pipe(skip(1), untilDestroyed(this)).subscribe(() => this.loadSearchData());
  }

  public get currentFacet$(): Observable<SearchFacetsOptions> {
    return this.currentFacet.asObservable();
  }

  public get currentPageInfo$(): Observable<CurrentPageInfo> {
    return this.currentPageInfo.asObservable();
  }

  public get searchResults$(): Observable<GlobalSearchResult[]> {
    return this.searchResults.asObservable();
  }

  public get facetToggleOptions$(): Observable<SearchFacetsOptions[]> {
    return this.facetToggleOptions.asObservable();
  }

  public get searchTerm$(): Observable<string> {
    return this.searchTerm.asObservable();
  }

  public get indicators$(): Observable<SearchIndicators> {
    return this.indicators.asObservable();
  }

  public get itemsLimit$(): Observable<SearchItemsLimit> {
    return this.itemsLimit.asObservable();
  }

  public get hideSearchResults$(): Observable<void> {
    return this.hideSearchResults.asObservable();
  }

  public get customItemClickHandler$(): Observable<GlobalSearchItem<SearchCollection>> {
    return this.customItemClickHandler.asObservable();
  }

  public setCurrentPageInfo(info: CurrentPageInfo): void {
    this.currentPageInfo.next(info);
  }

  public setStaticFacetOptions(options: SearchFacetsOptions[]): void {
    this.staticFacetsOptions = options;
    // Bypassing permissions checks for the static facets options.
    // https://quantive-inc.atlassian.net/browse/GVS-58203
    options.forEach((option) => {
      this.facetOptionsMap[option] = option.toLowerCase() as SearchFacetsOptionsEnum;
    });
  }

  public setStaticItemsLimit(itemsLimit: SearchItemsLimit): void {
    this.staticItemsLimit = itemsLimit;
  }

  public setSearchSettings(settings: SearchConditionSettings): void {
    this.searchSettings = settings;
  }

  public onItemClicked(item: GlobalSearchItem<SearchCollection>): void {
    this.hideResults();
    this.customItemClickHandler.next(item);
  }

  public setCurrentFacet(currentFacet: RecentSearchFacetsOptions, param: { skipLoading: boolean } = { skipLoading: false }): void {
    this.skipLoading = param.skipLoading;
    if (currentFacet !== "otherItems") {
      this.tracingService.traceAction("gs__changed_facet", () => {});
      this.currentFacet.next(currentFacet);
      this.isOtherItems = false;
    } else {
      // We don't want to change current facet when click on view more results for other items! We just want to load them!
      this.isOtherItems = true;
      this.loadRecentItems({ forceReload: true }, "otherItems");
    }
    this.itemsLimit.next(this.staticItemsLimit || getItemsLimit(currentFacet));
  }

  public setSearchTerm(searchTerm: string, tracingName: SearchTracingNames = SearchTracingName.GS__CHANGED_SEARCH_TERM): void {
    this.tracingService.traceAction(tracingName, () => {});
    this.searchResults.next(null);
    this.searchTerm.next(searchTerm);
  }

  public loadRecentItems(params: { forceReload: boolean } = { forceReload: false }, currentFacet: RecentSearchFacetsOptions = this.currentFacet.getValue()): void {
    if (params.forceReload || (!this.recentItemsSubjects.has(currentFacet) && !this.isOtherItems)) {
      this.indicators.next({ gettingSearchData: { progress: true } });

      const filters = getRecentItemsMongoQueries(currentFacet, this.routerGlobals);
      this.requestsCount = filters.length;
      this.globalSearchTrackingService.trackRecent("Recent Item Searched", currentFacet);

      filters.forEach((f) => {
        this.recentItemsSubjects.set(currentFacet, new ReplaySubject<GlobalSearchResult[]>());
        this.setRecentItems(f.title, f.query);
      });
    } else {
      const facet = this.isOtherItems ? "otherItems" : currentFacet;
      this.recentItemsSubjects
        .get(facet)
        .pipe(first(), untilDestroyed(this))
        .subscribe((recentItems) => {
          // There is no need of sending tracking when load recent items from cache!
          this.searchResults.next(recentItems);
        });
    }
  }

  public addItemToRecentItems(id: string, collectionName: SearchCollection): void {
    this.viewHistoryService.addItemToViewHistory(
      this.viewHistoryService.payloadBuilder.withItem({ id }).forType(searchCollectionTypeToUXCustomizationType[collectionName]).forSource("search").generate()
    );
  }

  public hideResults(): void {
    this.hideSearchResults.next();
  }

  public setQueryParams(params: SearchQueryParams, shouldLoad: { load: boolean } = { load: true }): void {
    this.queryParams = params;
    if (shouldLoad.load) this.loadSearchData();
  }

  private setSearchResults(searchResults: GlobalSearchResult[]): void {
    this.searchResults.next(searchResults);
    this.indicators.next({ gettingSearchData: null });
  }

  private loadSearchData(): void {
    const { facets, queryParams } = getFacetsAndQueryParams(this.staticFacetsOptions || [this.currentFacet.getValue()], this.facetOptionsMap, this.queryParams);

    // When initialising global search component, we don't want to load search data!
    if (this.skipLoading) {
      this.skipLoading = false;
      return;
    }

    if (!this.searchTerm.getValue()) {
      this.indicators.next({ gettingSearchData: null });
      this.loadRecentItems({ forceReload: true });
      return;
    }

    this.indicators.next({ gettingSearchData: { progress: true } });
    this.globalSearchTrackingService.trackSearch("Item searched", this.currentFacet.getValue());

    this.searchFacade
      .searchDataV2$(getSearchBodyV2(this.searchTerm.getValue(), facets, this.searchSettings), queryParams)
      .pipe(first())
      .subscribe((searchData) => {
        this.setSearchResults(convertSearchResponseToOrderedArray(searchData));
        this.globalSearchTrackingService.trackSearch("Results shown", this.currentFacet.getValue());
      });
  }

  private setRecentItems(recentItemsTitle: RecentSearchFacetsOptions, recentItemsMongoQuery: IViewHistoryItemsGroupedReqParams): void {
    this.viewHistoryService.getViewHistoryItems(recentItemsMongoQuery).then((recentSearchItems) => {
      this.requestsCount--;

      let recentItemsCollection: ViewHistoryItemGrouped[] = [];
      viewHistoryItemCollectionByRecentItemsFacetMap[recentItemsTitle].forEach((collection: Exclude<RecentSearchFacetsOptions, "everywhere">) => {
        recentItemsCollection = recentItemsCollection.concat(recentSearchItems[collection] || []);
      });

      if (recentItemsCollection.length) {
        const sortedRecentItems = recentItemsCollection.sort(byDateFieldDesc("lastVisited"));
        this.recentItems = this.recentItems.concat(convertRecentResponseToOrderedArray(sortedRecentItems, recentItemsTitle, recentSearchItems.totalCount));
      }

      if (this.requestsCount === 0) {
        this.recentItemsSubjects.get(this.currentFacet.getValue()).next(this.recentItems);
        this.setSearchResults(this.recentItems.sort(byNumericFieldDesc("maxScore")));

        this.recentItems = [];

        this.globalSearchTrackingService.trackRecent("Recent Item Results Shown", this.currentFacet.getValue());
      }
    });
  }

  private setFacetsOptions(): void {
    const optionsArr: SearchFacetsOptions[] = [];
    Object.values(SearchFacetsOptionsEnum).forEach((val) => {
      const accessMap = SearchFacetsAccessMap[val];
      combineLatest({
        hasPermissions: accessMap.permissions ? this.permissionsFacade.hasMultiplePermissions$(accessMap.permissions) : of(true),
        isFeatureEnabled: accessMap.featureFlag ? this.featureToggleFacade.isFeatureAvailable$(accessMap.featureFlag) : of(true),
        isEditionEnabled: accessMap.editionFeature ? this.editionFacade.hasFeature$(accessMap.editionFeature) : of(true),
      })
        .pipe(first(), untilDestroyed(this))
        .subscribe(({ hasPermissions, isFeatureEnabled, isEditionEnabled }) => {
          if (hasPermissions && isFeatureEnabled && isEditionEnabled) {
            this.facetOptionsMap[val] = val.toLowerCase() as SearchFacetsOptionsEnum;
            optionsArr.push(val);
            this.facetToggleOptions.next(optionsArr);
          }
        });
    });
  }
}
