import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NzPaginationI18nInterface } from "ng-zorro-antd/i18n";
import { NzPaginationOptionsComponent } from "ng-zorro-antd/pagination";
import { UiSelectModule } from "@webapp/ui/select/select.module";

@Component({
  selector: "div[ui-pagination-options]",
  preserveWhitespaces: false,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "pagination-options.component.html",
  standalone: true,
  imports: [UiSelectModule, FormsModule],
})
export class UiPaginationOptionsComponent extends NzPaginationOptionsComponent {
  @Input("uiSize") public nzSize: "default" | "small" = "default";
  @Input() public disabled = false;
  @Input() public showSizeChanger = false;
  @Input() public showQuickJumper = false;
  @Input() public locale!: NzPaginationI18nInterface;
  @Input() public total = 0;
  @Input() public pageIndex = 1;
  @Input() public pageSize = 10;
  @Input() public pageSizeOptions: number[] = [];
  @Output() public readonly pageIndexChange = new EventEmitter<number>();
  @Output() public readonly pageSizeChange = new EventEmitter<number>();
}
