import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { UiFormModule } from "@webapp/ui/form/form.module";
import { UiInputModule } from "@webapp/ui/input/input.module";
import { UiRadioModule } from "@webapp/ui/radio/radio.module";
import { UiSelectModule } from "@webapp/ui/select/select.module";
import { CustomUnitComponent } from "./custom-unit-modal/custom-unit.component";
import { UnitSelectorComponent } from "./unit-selector.component";

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, LocalizationModule, UiSelectModule, UiFormModule, UiRadioModule, UiInputModule],
  declarations: [UnitSelectorComponent, CustomUnitComponent],
  exports: [UnitSelectorComponent],
})
export class UnitSelectorModule {}
