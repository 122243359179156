import { Component } from "@angular/core";
import { ICollection } from "@webapp/core/core.models";
import { INavItem } from "@webapp/navigation/models/nav-items-list.models";
import { SubNavOption } from "@webapp/navigation/models/sub-nav-options.models";
import NavigationItemsMediator from "@webapp/navigation/services/uxcustomization/navigation-items.mediator.service";
import { BaseSubNav } from "../services/base-sub-nav";

@Component({
  selector: "whiteboards-sub-navigation",
  templateUrl: "./whiteboards-sub-navigation.component.html",
})
export class WhiteboardsSubNavigationComponent extends BaseSubNav {
  public options: SubNavOption[] = [{ label: "all_whiteboards", state: "gtmhub.whiteboards" }];
  public shared: ICollection<INavItem>;
  public favorites: ICollection<INavItem>;

  public constructor(protected navigationItemsMediator: NavigationItemsMediator) {
    super();
    this.setupEntityTypes(["whiteboard"], "whiteboards");
  }
}
