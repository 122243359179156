import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { EnvironmentServiceProvider } from "@webapp/env/providers/environment.provider";
import { RecentsApiService } from "./recents.api.service";
import { RecentsFacade } from "./recents.facade.service";

@NgModule({
  exports: [],
  imports: [],
  providers: [EnvironmentServiceProvider, RecentsFacade, RecentsApiService, provideHttpClient(withInterceptorsFromDi())],
})
export class RecentsModule {}
