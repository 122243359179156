import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ISettingParameters, NotificationSettingType } from "../../models/notification-settings.model";

@Component({
  selector: "notification-settings",
  templateUrl: "./notification-settings.component.html",
  styleUrls: ["./notification-settings.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationSettingsComponent {
  @Input()
  public settingKey: string;
  @Input()
  public titleKey: string;
  @Input()
  public inboxValue: boolean;
  @Input()
  public emailValue: boolean;
  @Output()
  public readonly statusChange = new EventEmitter<ISettingParameters>();

  public patchSettings({ value }: { value: boolean }, type: NotificationSettingType): void {
    this.statusChange.emit({ patchedSetting: this.generatePatch(type, { value }), settingKey: this.settingKey, notificationType: type });
  }

  private generatePatch(
    type: NotificationSettingType,
    { value }: { value: boolean }
  ): {
    [key: string]: {
      embedded: {
        [key: string]: boolean;
      };
    };
  } {
    return {
      [type]: {
        embedded: {
          [this.settingKey]: value,
        },
      },
    };
  }
}
