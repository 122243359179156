export enum RichTextEditorIconEnum {
  BOLD = "bold",
  ITALIC = "italic",
  STRIKE = "strike",
  UNDERLINE = "underline",
  ORDERED = "ordered",
  BULLET = "bullet",
  LINK = "link",
  GIF = "gif",
  MENTION = "mention",
  EMOJI = "emoji",
  POST_COMMENT = "postComment",
  LOADING = "loading",
  OKR = "okr",
}
