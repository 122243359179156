import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { localize, localizeToCase } from "@gtmhub/localization";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { ConfirmDeleteWithInputComponent, ConfirmDeleteWithInputModalData } from "./components/confirm-delete-with-input/confirm-delete-with-input.component";
import { cancelButton, dangerButton } from "./modal.utils";

type ConfirmDeleteItemOptions = { itemKey: string; itemToCase?: boolean; description?: string; action?(): Promise<unknown> };

type ConfirmDeleteOptions = { title: string; description?: string; action?(): Promise<unknown> };

@Injectable()
export class ConfirmDeleteService {
  constructor(private modalService: UiModalService) {}

  public confirmDeleteItem(options: ConfirmDeleteItemOptions): Observable<void> {
    return this.confirmDelete({
      title: localize("delete_x", { data: (options.itemToCase ? localizeToCase : localize)(options.itemKey) }),
      description: options.description,
      action: options.action,
    });
  }

  public confirmDelete(options: ConfirmDeleteOptions): Observable<void> {
    const subject = new Subject<void>();
    this.modalService.confirm({
      uiTitle: options.title,
      uiContent: options.description || localize("once_delete_can_not_be_undone"),
      uiIconType: null,
      uiOkText: localize("delete"),
      uiOkDanger: true,
      uiOnOk: () => {
        const complete = (): void => {
          subject.next();
          subject.complete();
        };

        if (options.action) {
          return options.action().then(complete);
        } else {
          complete();
        }
      },
      uiCancelText: localize("cancel"),
      uiOnCancel: () => {
        subject.complete();
      },
    });
    return subject;
  }

  public confirmDeleteWithInput(options: ConfirmDeleteWithInputModalData & { title: string; action(): Promise<void> }): Observable<void> {
    const { title, description, inputPlaceholder, itemName, toDeleteItemText, action } = options;
    const subject = new Subject<void>();
    this.modalService.create<ConfirmDeleteWithInputComponent, ConfirmDeleteWithInputModalData>({
      uiTitle: title,
      uiContent: ConfirmDeleteWithInputComponent,
      uiNoScrollableContent: true,
      uiData: {
        description,
        inputPlaceholder,
        itemName,
        toDeleteItemText,
      },
      uiFooter: [
        dangerButton(localize("delete"), {
          disabled: (component) => !component.canDelete,
          onClick: () => {
            return action().then(() => {
              subject.next();
              subject.complete();
            });
          },
        }),
        cancelButton({
          onClick: () => {
            subject.complete();
          },
        }),
      ],
    });
    return subject;
  }
}
