import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { ISelectorAssignee } from "@webapp/assignees/models/assignee.models";
import { IPeopleFilterChain, PeopleSelectorRequestInternal } from "../models/models";

@Injectable()
export class NameFilterChain implements IPeopleFilterChain {
  private nextChain: IPeopleFilterChain;

  public setNextChain(chain: IPeopleFilterChain): void {
    this.nextChain = chain;
  }
  public handle(request: PeopleSelectorRequestInternal, assignees$: Observable<ISelectorAssignee[]>): Observable<ISelectorAssignee[]> {
    if (!request.nameQuery) {
      return this.nextChain ? this.nextChain.handle(request, assignees$) : assignees$;
    }

    const filterAssigneesByName = (assignees: ISelectorAssignee[]): ISelectorAssignee[] => {
      const matches = assignees.reduce(
        (matches: { top: ISelectorAssignee[]; secondary: ISelectorAssignee[] }, a) => {
          const nameStartsWithQuery = a.name.toLowerCase().startsWith(request.nameQuery.toLowerCase());
          const nameContainsQuery = a.name.toLowerCase().includes(request.nameQuery.toLowerCase());

          if (nameStartsWithQuery) {
            matches.top.push(a);
          } else if (nameContainsQuery) {
            matches.secondary.push(a);
          }

          return matches;
        },
        { top: [], secondary: [] }
      );

      return matches.top.concat(matches.secondary);
    };

    const filteredAssignees$ = assignees$.pipe(map(filterAssigneesByName));

    return this.nextChain ? this.nextChain.handle(request, filteredAssignees$) : filteredAssignees$;
  }
}
