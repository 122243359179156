<div class="data-container">
  <div class="input-info" *ngIf="isRestricted; else notRestricted">
    <div class="selected-insight">
      <div class="insight-name-wrapper">
        <button class="header" [ngClass]="{ 'not-editable': !isEditable, 'disabled-style': disabledStyle }">
          <div class="name">
            <img class="logo" src="https://cdn.quantivestatic.com/dist/img/icons/emails-v3/insight.png" alt="icon-insight" />
            {{ "restricted_insight" | localize }}
          </div>
        </button>
      </div>
    </div>
  </div>
  <ng-template #notRestricted>
    <div class="input-info">
      <gh-indicator [ghValue]="indicators.loading" [ghMessage]="'getting' | localize"></gh-indicator>
      <div class="selected-insight" *ngIf="!indicators.loading && confirmedInsight && dataSourceType">
        <div class="insight-name-wrapper">
          <button
            class="header"
            [ngClass]="{ clickable: !confirmedInsight.isShadow && isEditable, 'not-editable': !isEditable, 'disabled-style': disabledStyle }"
            (click)="onInsightClick()"
            tabindex="-1">
            <img
              class="logo"
              *ngIf="data.datasourceBlueprintName"
              [src]="data.datasourceBlueprintLogoUrl | getDataSourceBlueprintLogo: data.datasourceBlueprintName : 'png'"
              [alt]="confirmedInsight.title" />
            <div class="name" [uiTooltipTitle]="confirmedInsight.title" ui-tooltip uiTooltipPlacement="bottom">
              {{ confirmedInsight.title }}
            </div>
          </button>
          <div class="filters" *ngIf="data.settings && data.settings.length > 0" [ngClass]="{ editable: !readonly && isEditable }">
            <gh-indicator [ghValue]="indicators.loadingParams" [ghMessage]="'getting' | localize"></gh-indicator>
            <div *ngIf="!indicators.loadingParams">
              <button class="filter-item" *ngFor="let filter of data.settings" [ngClass]="{ 'disabled-style': disabledStyle }" (click)="onFilterClick()" tabindex="-1">
                {{ filter | insightFilter }}
              </button>
            </div>
          </div>
        </div>
        <button class="delete-btn" *ngIf="!readonly" (click)="removeItem.emit()" tabindex="-1" ui-button>
          <i ui-icon uiType="trash-bin"></i>
        </button>
      </div>
      <div class="selected-kpi" *ngIf="!indicators.loading && kpiData">
        <div class="kpi-name-wrapper">
          <button class="header clickable not-editable" tabindex="-1">
            <div class="icon-wrapper">
              <i class="fs-16px sp-mr-5" ui-icon uiType="kpi" uiTheme="fill"></i>
            </div>
            <div class="name" [uiTooltipTitle]="kpiData.name" ui-tooltip uiTooltipPlacement="bottom">
              {{ kpiData.name }}
            </div>
          </button>
        </div>
        <button class="delete-btn" *ngIf="!readonly" (click)="removeItem.emit()" tabindex="-1" ui-button>
          <i ui-icon uiType="trash-bin"></i>
        </button>
      </div>
    </div>
  </ng-template>
</div>
