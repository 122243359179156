import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { KpiProjection, KpiProjectionDTO } from "@webapp/kpis/models/kpi-projections.models";
import { KpiProjectionsState } from "./kpi-projections-state.service";

@Injectable({
  providedIn: "root",
})
export class KpiProjectionsApiService extends BaseApiService<KpiProjection, KpiProjectionDTO, KpiProjectionsState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: KpiProjectionsState) {
    super("kpis", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getProjectionsForKpiEndpoint(kpiId: string): string {
    return `${this.getBasePath(HttpActions.get)}/${kpiId}/projections`;
  }

  public getCreateEndpoint(kpiId: string): string {
    return `${this.getBasePath(HttpActions.post)}/${kpiId}/projections`;
  }

  public getPatchEndpoint(kpiId: string, projectionId: string): string {
    return `${this.getBasePath(HttpActions.patch)}/${kpiId}/projections/${projectionId}`;
  }

  public getDeleteEndpoint(kpiId: string, projectionId: string): string {
    return `${this.getBasePath(HttpActions.get)}/${kpiId}/projections/${projectionId}`;
  }
}
