import { PlanningSessionsActions } from "@gtmhub/sessions/redux/session-actions";
import { PlanningSessionService } from "@gtmhub/sessions/services/planning-session.service";
import { providerUpgradeFactory } from "@webapp/core/provider-upgrade/factories/provider-upgrade.factory";

export const PlanningSessionsServiceProvider = {
  provide: PlanningSessionService,
  useFactory: providerUpgradeFactory("PlanningSessionService"),
  deps: ["$injector"],
};

export const PlanningSessionsActionsProvider = {
  provide: PlanningSessionsActions,
  useFactory: providerUpgradeFactory("PlanningSessionsActions"),
  deps: ["$injector"],
};
