import { ChangeDetectionStrategy, Component } from "@angular/core";
import { IMetricMilestoneStatusNotification } from "@gtmhub/notifications/models/notifications";
import { InboxTemplateComponent } from "../inbox-template.component";

@Component({
  selector: "metric-milestone-status-inbox-template",
  templateUrl: "./metric-milestone-status-inbox-template.component.html",
  styleUrls: ["../inbox-template.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetricMilestoneStatusInboxTemplateComponent extends InboxTemplateComponent<IMetricMilestoneStatusNotification> {}
