import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, concatMap, map, of, tap } from "rxjs";
import { UIErrorHandlingService } from "@gtmhub/error-handling";
import { FavoritesFactory } from "@gtmhub/favorites/favorites-factory";
import { IFavoritesItemsGroupedResponse } from "@gtmhub/favorites/models";
import { IUXCustomizationItemResponse, UXCustomizationType } from "@gtmhub/uxcustomization/models";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { ICollection } from "@webapp/core/core.models";
import { INavItem, NavItemsType, uxcResponseNavItemsMap } from "@webapp/navigation/models/nav-items-list.models";
import { FavoritesPayloadBuilder } from "@webapp/navigation/utils/favorites-payload.builder";
import {
  NavItemsUXCTypesMap,
  SubNavItemsModel,
  buildNavItemFromModel,
  favoritesGroupedReponseToNavItems,
  getNavItemsType,
} from "@webapp/navigation/utils/nav-items.util";
import { FavoritesApiService } from "./favorites.api.service";
import { FavoritesDtoModel, IFavoritesModel } from "./favorites.models";
import { FavoritesState } from "./favorites.state";

@Injectable({
  providedIn: "root",
})
export class FavoritesFacade extends BaseFacade<IFavoritesModel, FavoritesDtoModel, FavoritesState, FavoritesApiService> {
  private reorderRequests$ = new Subject<() => Observable<unknown>>();
  private favorites$: BehaviorSubject<{ [key in NavItemsType]?: ICollection<INavItem> }> = new BehaviorSubject({});

  public markItemAsFavorite(
    type: UXCustomizationType,
    id: string,
    navItem: INavItem,
    navItemsType: NavItemsType
  ): Observable<IUXCustomizationItemResponse<UXCustomizationType>> {
    const payload = this.favoritesPayloadBuilder.forType(type).withItem({ id }).generate();
    return this.api.post$<IUXCustomizationItemResponse<UXCustomizationType>>(payload).pipe(
      tap((response: IUXCustomizationItemResponse<UXCustomizationType>) => {
        const targetedCollection = this.favorites$.value[navItemsType];
        if (!targetedCollection) return;

        this.favorites$.next({
          ...this.favorites$.value,
          [navItemsType]: {
            ...targetedCollection,
            items: [{ ...navItem, id: response.itemId, favoriteId: response.id }, ...targetedCollection.items],
            totalCount: targetedCollection.totalCount + 1,
          },
        });
      })
    );
  }

  public unmarkItemAsFavorite(id: string, navItem: INavItem, navItemsType: NavItemsType): Observable<IFavoritesModel> {
    return this.api.delete$(id).pipe(
      tap(() => {
        const targetedCollection = this.favorites$.value[navItemsType];
        if (!targetedCollection) return;

        const collectionItem = targetedCollection.items.find((item) => item.favoriteId === navItem.favoriteId);
        if (collectionItem) {
          this.favorites$.next({
            ...this.favorites$.value,
            [navItemsType]: {
              ...targetedCollection,
              items: targetedCollection.items.filter((item) => item.favoriteId !== navItem.favoriteId),
              totalCount: targetedCollection.totalCount - 1,
            },
          });
        }
      })
    );
  }

  public constructor(
    state: FavoritesState,
    private api: FavoritesApiService,
    private favoritesFactory: FavoritesFactory,
    private favoritesPayloadBuilder: FavoritesPayloadBuilder,
    private uiErrorHandlingService: UIErrorHandlingService
  ) {
    super(state, api);
    this.reorderRequests$
      .pipe(
        concatMap((r) => {
          return r();
        })
      )
      .subscribe({
        error: (err) => {
          this.uiErrorHandlingService.handleModal(err);
        },
      });
  }

  public getFavoritesOfType(entityNavType: NavItemsType): Observable<ICollection<INavItem>> {
    return this.favorites$.asObservable().pipe(map((value) => value[entityNavType]));
  }

  public loadFavorites(navItemTypes: NavItemsType[], navItemsUXCTypesMap: NavItemsUXCTypesMap): Observable<IFavoritesItemsGroupedResponse> {
    const pendingRequestNavTypes = navItemTypes.filter((navItemType) => !this.favorites$.value[navItemType]);
    if (pendingRequestNavTypes.length === 0) {
      return of(null);
    }

    const pendingRequestUxcTypes = pendingRequestNavTypes.flatMap((navKey) => navItemsUXCTypesMap[navKey]);
    const favoriteItemsRequestParams = this.favoritesFactory.buildNavigationFavoritesRequestPaging(pendingRequestUxcTypes);

    return this.api.getAll$<IFavoritesItemsGroupedResponse>(favoriteItemsRequestParams).pipe(
      tap((response: IFavoritesItemsGroupedResponse) => {
        // creates an object with grouped NavItemsType favorites { sessions: { items: [...], totalCount: ... }, people: { items: [...], totalCount: ... }, ... }
        const navItemGroups = pendingRequestNavTypes.reduce((groups, navItemType: NavItemsType) => {
          // combines related response items into a single object { teamItems: [...], userItems: [...], totalCount: ... }
          const responseGroup = Object.keys(response)
            // picks related items to the current NavItemsType section - e.g. `users` and `teams` are grouped in a single `people` nav section
            .filter((responseKey) => uxcResponseNavItemsMap[responseKey] === navItemType)
            .reduce<IFavoritesItemsGroupedResponse>((group, key) => ({ ...group, [key]: response[key], totalCount: group.totalCount + response[key]?.length || 0 }), {
              totalCount: 0,
            });

          // sort and decorate with nav-related props in the response group
          groups[navItemType] = favoritesGroupedReponseToNavItems(responseGroup, navItemType);

          return groups;
        }, {} as IFavoritesItemsGroupedResponse);

        this.favorites$.next({
          ...this.favorites$.value,
          ...navItemGroups,
        });
      })
    );
  }

  public reorderFavoriteItemSequentially(favoriteId: string, newOrder: number, uxcType: UXCustomizationType | null = null): void {
    const navItemType = getNavItemsType(uxcType);
    if (navItemType && this.favorites$.value[navItemType].totalCount <= 1) return;
    this.reorderRequests$.next(() => this.api.reorderFavoriteItem(favoriteId, newOrder));
  }

  public updateItem(uxcType: UXCustomizationType, item: SubNavItemsModel): void {
    const navItemType = getNavItemsType(uxcType);
    if (!this.favorites$.value[navItemType]) return;

    const newNavItem = buildNavItemFromModel(uxcType, item);
    if (newNavItem !== null) {
      this.favorites$.next({
        ...this.favorites$.value,
        [navItemType]: {
          ...this.favorites$.value[navItemType],
          items: this.favorites$.value[navItemType].items.map((oldValue) => {
            return oldValue.id === item.id ? { ...oldValue, ...newNavItem } : oldValue;
          }),
        },
      });
    }
  }

  public deleteItem(uxcType: UXCustomizationType, item: SubNavItemsModel): void {
    const navItemType = getNavItemsType(uxcType);
    if (!this.favorites$.value[navItemType]) return;

    const newNavItem = buildNavItemFromModel(uxcType, item);
    const itemToBeDeleted = this.favorites$.value[navItemType].items.find((favoriteItem) => favoriteItem.id === item.id);
    if (newNavItem !== null && itemToBeDeleted) {
      this.favorites$.next({
        ...this.favorites$.value,
        [navItemType]: {
          ...this.favorites$.value[navItemType],
          items: this.favorites$.value[navItemType].items.filter((oldItem) => oldItem.id !== item.id),
          totalCount: this.favorites$.value[navItemType].totalCount - 1,
        },
      });
    }
  }
}
