<suggestion-drawer-content-card>
  <pi-loading-card-v2 *ngIf="isLoading" [suggestion]="descriptionV2" [instance]="descriptionInstance" [isIconAvailable]="false"></pi-loading-card-v2>

  <pi-clickable-error-card *ngIf="isError" [suggestion]="descriptionV2" [instance]="descriptionInstance" [isRemoveBtnAvailable]="false" [errorData]="errorData"> </pi-clickable-error-card>

  <pi-feedback-card
    *ngIf="!isError && !isLoading"
    [goal]="goal"
    [suggestion]="descriptionV2"
    [instance]="descriptionInstance"
    suggestionFeedbackType="objective"
    suggestionFeedbackSubType="description"
    data-test-id="okr-suggestion-item-description">
  </pi-feedback-card>
</suggestion-drawer-content-card>
