<div *ngIf="notification.operationType === 'created'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>

  <div *ngIf="notification.notificationData.item.targetType === 'insight'">
    <div *ngIf="notification.notificationData.item.isMention">
      <div class="title">
        {{ "user_mentioned_you_in_comment_insight" | localize: { names: notification.notificationData.actor.names } }}
      </div>
    </div>
    <div *ngIf="!notification.notificationData.item.isMention">
      <div class="title">
        {{ "user_commented_on_insight" | localize: { names: notification.notificationData.actor.names } }}
      </div>
    </div>
    <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
    <div class="summary">
      <div class="message-icon">
        <i class="fs-20px" [uiType]="icon" [uiTooltipTitle]="notification.targetType | localize" ui-tooltip ui-icon uiTheme="fill"></i>
      </div>
      <span class="item">{{ notification.notificationData.item.targetTitle }}</span>
    </div>
    <div class="full">
      <div class="inbox-box">
        <div class="inbox-section">
          <div class="heading inline-notifications">
            {{ "comment" | localize }}
          </div>
          :<rich-text-viewer [text]="notification.notificationData.item.text"></rich-text-viewer>
        </div>
        <div class="inbox-section">
          <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_insight" | localize }}</a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="notification.notificationData.item.targetType === 'team'">
    <div *ngIf="notification.notificationData.item.isMention">
      <div class="title">
        {{ "user_mentioned_you_in_comment_team" | localize: { names: notification.notificationData.actor.names, targetTitle: notification.notificationData.item.targetTitle } }}
      </div>
    </div>
    <div *ngIf="!notification.notificationData.item.isMention">
      <div class="title">
        {{ "user_commented_on_team" | localize: { names: notification.notificationData.actor.names, targetTitle: notification.notificationData.item.targetTitle } }}
      </div>
    </div>
    <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
    <div class="summary">
      <div class="message-icon">
        <i class="fs-20px" [uiType]="icon" [uiTooltipTitle]="notification.targetType | localize" ui-tooltip ui-icon uiTheme="fill"></i>
      </div>
      <span class="item">{{ notification.notificationData.item.targetTitle }}</span>
    </div>
    <div class="full">
      <div class="inbox-box">
        <div class="inbox-section">
          <div class="heading inline-notifications">
            {{ "comment" | localize }}
          </div>
          :<rich-text-viewer [text]="notification.notificationData.item.text"></rich-text-viewer>
        </div>
        <div class="inbox-section">
          <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_comment" | localize }}</a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="notification.notificationData.item.targetType === 'employee'">
    <div *ngIf="notification.notificationData.item.isMention">
      <div class="title">
        {{ "user_mentioned_you_in_comment_employee" | localize: { names: notification.notificationData.actor.names, targetTitle: notification.notificationData.item.targetTitle } }}
      </div>
      <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
      <div class="summary">
        <div class="message-icon">
          <i class="fs-20px" [uiType]="icon" [uiTooltipTitle]="notification.targetType | localize" ui-tooltip ui-icon uiTheme="fill"></i>
        </div>
        <span class="item">{{ notification.notificationData.item.targetTitle }}</span>
      </div>
      <div class="full">
        <div class="inbox-box">
          <div class="inbox-section">
            <div class="heading inline-notifications">
              {{ "comment" | localize }}
            </div>
            :<rich-text-viewer [text]="notification.notificationData.item.text"></rich-text-viewer>
          </div>
          <div class="inbox-section">
            <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_comment" | localize }}</a>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!notification.notificationData.item.isMention">
      <div class="title">
        {{ "user_followed_up" | localize: { names: notification.notificationData.actor.names, targetTitle: notification.notificationData.item.targetTitle } }}
      </div>
      <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
      <div class="summary">
        <div class="message-icon">
          <i class="fs-20px" [uiType]="icon" [uiTooltipTitle]="notification.targetType | localize" ui-tooltip ui-icon uiTheme="fill"></i>
        </div>
        <span class="item">{{ notification.notificationData.item.targetTitle }}</span>
      </div>
      <div class="full">
        <div class="inbox-box">
          <rich-text-viewer [text]="notification.notificationData.item.text"></rich-text-viewer>
          <div class="inbox-section">
            <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "go_to_home_page" | localize }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="notification.notificationData.item.targetType === 'conversation'">
    <div *ngIf="notification.notificationData.item.isMention">
      <div class="title">
        {{ "user_mentioned_you_in_comment_conversation" | localize: { names: notification.notificationData.actor.names } }}
      </div>
    </div>
    <div *ngIf="!notification.notificationData.item.isMention">
      <div class="title">
        {{ "user_commented_on_conversation" | localize: { names: notification.notificationData.actor.names } }}
      </div>
    </div>
    <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
    <div class="summary">
      <div class="message-icon">
        <i class="fs-20px" [uiType]="icon" [uiTooltipTitle]="notification.targetType | localize" ui-tooltip ui-icon uiTheme="fill"></i>
      </div>
      <span class="item">{{ notification.notificationData.item.targetTitle }}</span>
    </div>
    <div class="full">
      <div class="inbox-box">
        <div class="inbox-section">
          <div class="heading inline-notifications">
            {{ "comment" | localize }}
          </div>
          :<rich-text-viewer [text]="notification.notificationData.item.text"></rich-text-viewer>
        </div>
        <div class="inbox-section">
          <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_conversation" | localize }}</a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="notification.notificationData.item.targetType === 'feed'">
    <div *ngIf="notification.notificationData.item.isMention">
      <div class="title">
        {{ "user_mentioned_you_in_comment_badge_announcement" | localize: { names: notification.notificationData.actor.names, targetTitle: notification.notificationData.item.targetTitle } }}
      </div>
    </div>
    <div *ngIf="!notification.notificationData.item.isMention">
      <div class="title">
        {{ "user_commented_on_badge_announcement" | localize: { names: notification.notificationData.actor.names, targetTitle: notification.notificationData.item.targetTitle } }}
      </div>
    </div>
    <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
    <div class="summary">
      <div class="message-icon">
        <i class="fs-20px" [uiType]="icon" [uiTooltipTitle]="notification.targetType | localize" ui-tooltip ui-icon uiTheme="fill"></i>
      </div>
      <span class="item">{{ notification.notificationData.item.targetTitle }}</span>
    </div>
    <div class="full">
      <div class="inbox-box">
        <div class="inbox-section">
          <div class="heading inline-notifications">
            {{ "comment" | localize }}
          </div>
          :<rich-text-viewer [text]="notification.notificationData.item.text"></rich-text-viewer>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="
      notification.notificationData.item.targetType === 'metric_snapshot' ||
      notification.notificationData.item.targetType === 'checkinMention' ||
      notification.notificationData.item.targetType === 'checkin'
    ">
    <div *ngIf="notification.notificationData.item.isMention">
      <div class="title">
        {{ "user_mentioned_you_in_kr_update" | localize: { names: notification.notificationData.actor.names } }}
      </div>
    </div>
    <div *ngIf="!notification.notificationData.item.isMention">
      <div class="title">
        {{ "user_commented_on_kr_update" | localize: { names: notification.notificationData.actor.names } }}
      </div>
    </div>
    <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
    <div class="summary">
      <div class="message-icon">
        <i class="fs-20px" [uiType]="icon" [uiTooltipTitle]="notification.targetType | localize" ui-tooltip ui-icon uiTheme="fill"></i>
      </div>
      <span class="item">{{ notification.notificationData.item.targetTitle }}</span>
    </div>
    <div class="full">
      <div class="inbox-box">
        <div class="inbox-section">
          <div class="heading inline-notifications">
            {{ "comment" | localize }}
          </div>
          :<rich-text-viewer [text]="notification.notificationData.item.text"></rich-text-viewer>
        </div>
        <div class="inbox-section">
          <div class="heading">{{ "the_update" | localize }}:</div>
          <div>
            <div class="list-item">{{ "new_value" | localize }}</div>
            <span *ngIf="notification.notificationData.item.targetItem.targetInfo.manualType === 'boolean'"
              >: {{ notification.notificationData.item.targetItem.updatedValue.target | metricValue: notification.notificationData.item.targetItem.updatedValue.format }}</span
            ><span *ngIf="notification.notificationData.item.targetItem.targetInfo.manualType !== 'boolean'"
              >: {{ notification.notificationData.item.targetItem.updatedValue.target | metricValue: notification.notificationData.item.targetItem.updatedValue.format }} (
              <div class="list-item">{{ "target" | localize }}</div>
              :&nbsp;<span *ngIf="notification.notificationData.item.targetItem.targetOperator === 'should_stay_above'"
                >{{ "should_stay_above_target_item_target_info_value" | localize }}
                {{ notification.notificationData.item.targetItem.targetInfo.target | metricValue: notification.notificationData.item.targetItem.targetInfo.format
                }}<span *ngIf="notification.notificationData.item.targetItem.targetInfo.critical"
                  >{{ "and_absolutely_must_be_above_target_item_critical_value" | localize }}
                  {{ notification.notificationData.item.targetItem.targetInfo.critical | metricValue: notification.notificationData.item.targetItem.targetInfo.format }}</span
                ></span
              ><span *ngIf="notification.notificationData.item.targetItem.targetOperator === 'should_stay_below'"
                >{{ "should_stay_below_target_item_target_info_value" | localize }}
                {{ notification.notificationData.item.targetItem.targetInfo.target | metricValue: notification.notificationData.item.targetItem.targetInfo.format
                }}<span *ngIf="notification.notificationData.item.targetItem.targetInfo.critical"
                  >{{ "and_absolutely_must_be_below_target_item_critical_value" | localize }}
                  {{ notification.notificationData.item.targetItem.targetInfo.critical | metricValue: notification.notificationData.item.targetItem.targetInfo.format }}</span
                ></span
              ><span *ngIf="notification.notificationData.item.targetItem.targetOperator === 'at_least'"
                >{{ "should_increase_to_target_item_target_info_value" | localize }}
                {{ notification.notificationData.item.targetItem.targetInfo.target | metricValue: notification.notificationData.item.targetItem.targetInfo.format
                }}<span *ngIf="notification.notificationData.item.targetItem.targetInfo.critical"
                  >{{ "and_absolutely_must_be_above_target_item_critical_value" | localize }}
                  {{ notification.notificationData.item.targetItem.targetInfo.critical | metricValue: notification.notificationData.item.targetItem.targetInfo.format }}</span
                ></span
              ><span *ngIf="notification.notificationData.item.targetItem.targetOperator === 'at_most'"
                >{{ "should_decrease_to_target_item_target_info_value" | localize }}
                {{ notification.notificationData.item.targetItem.targetInfo.target | metricValue: notification.notificationData.item.targetItem.targetInfo.format
                }}<span *ngIf="notification.notificationData.item.targetItem.targetInfo.critical"
                  >{{ "and_absolutely_must_be_below_target_item_critical_value" | localize }}
                  {{ notification.notificationData.item.targetItem.targetInfo.critical | metricValue: notification.notificationData.item.targetItem.targetInfo.format }}</span
                ></span
              >)
            </span>
          </div>
          <div>
            <div class="list-item">{{ "note" | localize }}</div>
            : <rich-text-viewer [text]="notification.notificationData.item.targetItem.updateNote"></rich-text-viewer>
          </div>
        </div>
        <div class="inbox-section">
          <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_update" | localize }}</a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="notification.notificationData.item.targetType === 'goal'">
    <div *ngIf="notification.notificationData.item.isMention">
      <div class="title">
        {{ "user_mentioned_you_in_comment_objective" | localize: { names: notification.notificationData.actor.names } }}
      </div>
    </div>
    <div *ngIf="!notification.notificationData.item.isMention">
      <div class="title">
        {{ "user_commented_on_objective" | localize: { names: notification.notificationData.actor.names } }}
      </div>
    </div>
    <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
    <div class="summary">
      <div class="message-icon">
        <i class="fs-20px" [uiType]="icon" [uiTooltipTitle]="notification.targetType | localize" ui-tooltip ui-icon uiTheme="fill"></i>
      </div>
      <span class="item">{{ notification.notificationData.item.targetTitle }}</span>
    </div>
    <div class="full">
      <div class="inbox-box">
        <div class="inbox-section">
          <div class="heading inline-notifications">
            {{ "comment" | localize }}
          </div>
          :<rich-text-viewer [text]="notification.notificationData.item.text"></rich-text-viewer>
          <gif-view *ngIf="notification.notificationData.item?.gif?.id" [gifSize]="sizeOfGif.Small" [gif]="notification.notificationData.item.gif"></gif-view>
        </div>
        <div class="inbox-section">
          <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_objective" | localize }}</a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="notification.notificationData.item.targetType === 'metric'">
    <div *ngIf="notification.notificationData.item.isMention">
      <div class="title">{{ "user_x_mentioned_you_in_a_comment_on_the_key_result" | localize: { userName: notification.notificationData.actor.names } }}</div>
    </div>
    <div *ngIf="!notification.notificationData.item.isMention">
      <div class="title">{{ "user_x_comment_on_the_key_result" | localize: { userName: notification.notificationData.actor.names } }}</div>
    </div>
    <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
    <div class="summary">
      <div class="message-icon">
        <i class="fs-20px" [uiType]="icon" [uiTooltipTitle]="notification.targetType | localize" ui-tooltip ui-icon uiTheme="fill"></i>
      </div>
      <span class="item">{{ notification.notificationData.item.targetTitle }}</span>
    </div>
    <div class="full">
      <div class="inbox-box">
        <div class="inbox-section">
          <div class="heading inline-notifications">
            {{ "comment" | localize }}
          </div>
          :<rich-text-viewer [text]="notification.notificationData.item.text"></rich-text-viewer>
          <gif-view *ngIf="notification.notificationData.item?.gif?.id" [gifSize]="sizeOfGif.Small" [gif]="notification.notificationData.item.gif"></gif-view>
        </div>
        <div class="inbox-section">
          <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_key_result" | localize }}</a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="notification.notificationData.item.targetType === 'kpi_projection'">
    <div *ngIf="notification.notificationData.item.isMention">
      <div class="title">{{ "user_x_mentioned_you_in_a_comment_on_the_kpi_projection" | localize: { userName: notification.notificationData.actor.names } }}</div>
    </div>
    <div *ngIf="!notification.notificationData.item.isMention">
      <div class="title">{{ "user_x_comment_on_the_kpi_projection" | localize: { userName: notification.notificationData.actor.names } }}</div>
    </div>
    <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
    <div class="summary">
      <div class="message-icon">
        <i class="fs-20px" [uiType]="icon" [uiTooltipTitle]="notification.targetType | localize" ui-tooltip ui-icon uiTheme="fill"></i>
      </div>
      <span class="item">{{ notification.notificationData.item.targetTitle }}</span>
    </div>
    <div class="full">
      <div class="inbox-box">
        <div class="inbox-section">
          <div class="heading inline-notifications">
            {{ "comment" | localize }}
          </div>
          :<rich-text-viewer [text]="notification.notificationData.item.text"></rich-text-viewer>
        </div>
        <div class="inbox-section">
          <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_comment" | localize }}</a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="notification.notificationData.item.targetType === 'kpi_snapshot'">
    <div *ngIf="notification.notificationData.item.isMention">
      <div class="title">{{ "user_x_mentioned_you_in_a_comment_on_the_kpi_snapshot" | localize: { userName: notification.notificationData.actor.names } }}</div>
    </div>
    <div *ngIf="!notification.notificationData.item.isMention">
      <div class="title">{{ "user_x_comment_on_the_kpi_snapshot" | localize: { userName: notification.notificationData.actor.names } }}</div>
    </div>
    <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
    <div class="summary">
      <div class="message-icon">
        <i class="fs-20px" [uiType]="icon" [uiTooltipTitle]="notification.targetType | localize" ui-tooltip ui-icon uiTheme="fill"></i>
      </div>
      <span class="item">{{ notification.notificationData.item.targetTitle }}</span>
    </div>
    <div class="full">
      <div class="inbox-box">
        <div class="inbox-section">
          <div class="heading inline-notifications">
            {{ "comment" | localize }}
          </div>
          :<rich-text-viewer [text]="notification.notificationData.item.text"></rich-text-viewer>
        </div>
        <div class="inbox-section">
          <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_comment" | localize }}</a>
        </div>
      </div>
    </div>
  </div>

  <!-- Check-in comments -->
  <div
    class="check-in-comment"
    *ngIf="checkInCommentLegacy.includes(notification.notificationData.item.targetType) && !notification.notificationData.item.isMention"
    [ngSwitch]="notification.notificationData.item.targetType">
    <div class="title" *ngSwitchCase="'check-in-comment-note' || 'checkin_blocker' || 'checkin_request' || 'checkin_takeaway' || 'checkin_win' || 'checkin_kudos'">
      {{ notification.notificationData.item.targetItem.type | lowercase | checkinNoteType: notification.targetType | localize: { user: notification.notificationData.actor.names } }}
    </div>
    <div class="title" *ngSwitchCase="'check-in-comment-answer' || 'checkin_answer'">
      {{ "checkins_comment_on_question" | localize: { user: notification.notificationData.actor.names } }}
    </div>
    <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" : "UTC" }}</div>
    <div class="summary">
      <div class="message-icon">
        <i class="fs-20px" [uiType]="'check-in'" [uiTooltipTitle]="'Check-in' | localize" ui-icon ui-tooltip uiTheme="fill"></i>
      </div>
      <span class="item" *ngIf="notification.notificationData.item.targetItem.teamName">{{
        "reflection_summary" | localize: { teamName: notification.notificationData.item.targetItem.teamName }
      }}</span>
    </div>
    <div class="full">
      <div class="inbox-box">
        <div class="inbox-section">
          <div class="inline-notifications cadence">
            {{ notification.notificationData.item.targetItem.cadenceStart | date: "dd MMM, yyyy" : "UTC" }} -
            {{ notification.notificationData.item.targetItem.cadenceEnd | date: "dd MMM, yyyy" : "UTC" }}
          </div>
        </div>
        <div class="inbox-section">
          <div class="heading inline-notifications comment">{{ "comment" | localize }}:</div>
          <rich-text-viewer [text]="notification.notificationData.item.text"></rich-text-viewer>
          <gif-view *ngIf="notification.notificationData.item?.gif?.id" [gifSize]="sizeOfGif.Small" [gif]="notification.notificationData.item.gif"></gif-view>
        </div>
        <div class="inbox-section">
          <a class="link classic" [attr.href]="notification.notificationData.item.url" (click)="goToComment($event, notification.notificationData.item.url)">{{ "view_comment" | localize }}</a>
        </div>
      </div>
    </div>
  </div>

  <!-- Check-in mentions -->
  <div class="check-in-comment-mention" *ngIf="checkInCommentLegacy.includes(notification.notificationData.item.targetType) && notification.notificationData.item.isMention">
    <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
    <div class="title">{{ "checkins_mention_on_comment" | localize: { user: notification.notificationData.actor.names } }}</div>
    <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" : "UTC" }}</div>
    <div class="summary">
      <div class="message-icon">
        <i class="fs-20px" [uiType]="'check-in'" [uiTooltipTitle]="'Check-in' | localize" ui-icon ui-tooltip uiTheme="fill"></i>
      </div>
      <span class="item" *ngIf="notification.notificationData.item.targetItem.teamName">{{
        "reflection_summary" | localize: { teamName: notification.notificationData.item.targetItem.teamName }
      }}</span>
    </div>
    <div class="full">
      <div class="inbox-box">
        <div class="inbox-section">
          <div class="inline-notifications cadence">
            {{ notification.notificationData.item.targetItem.cadenceStart | date: "dd MMM, yyyy" : "UTC" }} -
            {{ notification.notificationData.item.targetItem.cadenceEnd | date: "dd MMM, yyyy" : "UTC" }}
          </div>
        </div>
        <div class="inbox-section">
          <ng-container *ngIf="notification.targetType === 'reflection'" [ngSwitch]="notification.notificationData.item.targetItem.type | lowercase">
            <div class="inline-notifications" *ngSwitchCase="'default_question'">
              <div class="heading inline-notifications" *ngIf="notification.notificationData.item.targetItem.name === 'question_one'">
                {{ "checkins_default_question_one" | localize }}
              </div>
              <div class="heading inline-notifications" *ngIf="notification.notificationData.item.targetItem.name === 'question_two'">
                {{ "checkins_default_question_two" | localize }}
              </div>
              <div class="heading inline-notifications question-three" *ngIf="notification.notificationData.item.targetItem.name === 'question_three'">
                {{ "checkins_default_question_three" | localize }}
              </div>
            </div>
            <div class="heading inline-notifications custom-question" *ngSwitchCase="'custom_question'">
              {{ notification.notificationData.item.targetItem.name }}
            </div>
            <div class="heading inline-notifications blockers" *ngSwitchCase="'blockers'">
              {{ "reflections_overview_blockers" | localize }}
            </div>
            <div class="heading inline-notifications requests" *ngSwitchCase="'requests'">
              {{ "reflections_overview_requests" | localize }}
            </div>
            <div class="heading inline-notifications wins" *ngSwitchCase="'wins'">
              {{ "reflections_overview_wins" | localize }}
            </div>
            <div class="heading inline-notifications kudos" *ngSwitchCase="'kudos'">
              {{ "reflections_overview_kudos" | localize }}
            </div>
            <div class="heading inline-notifications takeaways" *ngSwitchCase="'takeaways'">
              {{ "reflections_overview_takeaways" | localize }}
            </div>
            <rich-text-viewer [text]="notification.notificationData.item.targetItem.text"></rich-text-viewer>
          </ng-container>
          <ng-container *ngIf="notification.targetType === 'comment'">
            <div class="heading inline-notifications comment">{{ "comment" | localize }}:</div>
            <rich-text-viewer [text]="notification.notificationData.item.text"></rich-text-viewer>
          </ng-container>
          <gif-view *ngIf="notification.notificationData.item?.gif?.id" [gifSize]="sizeOfGif.Small" [gif]="notification.notificationData.item.gif"></gif-view>
        </div>
        <div class="inbox-section">
          <a class="link classic" [attr.href]="notification.notificationData.item.url" (click)="goToComment($event, notification.notificationData.item.url)">{{ "view_check_in" | localize }}</a>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="
      notification.notificationData.item.targetType !== 'team' &&
      notification.notificationData.item.targetType !== 'employee' &&
      notification.notificationData.item.targetType !== 'feed' &&
      notification.notificationData.item.targetType !== 'conversation' &&
      notification.notificationData.item.targetType !== 'insight' &&
      notification.notificationData.item.targetType !== 'goal' &&
      notification.notificationData.item.targetType !== 'metric' &&
      notification.notificationData.item.targetType !== 'checkin' &&
      notification.notificationData.item.targetType !== 'checkinMention' &&
      notification.notificationData.item.targetType !== 'metric_snapshot' &&
      notification.notificationData.item.targetType !== 'kpi_snapshot' &&
      notification.notificationData.item.targetType !== 'kpi_projection' &&
      !checkInTagetTypesLegacy.includes(notification.notificationData.item.targetType)
    ">
    <div *ngIf="notification.notificationData.item.isMention">
      <div class="title">
        {{ "user_mentioned_you_in_comment" | localize: { names: notification.notificationData.actor.names } }}
      </div>
    </div>
    <div *ngIf="!notification.notificationData.item.isMention">
      <div class="title">
        {{ "user_commented_on_target" | localize: { names: notification.notificationData.actor.names, targetTitle: notification.notificationData.item.targetTitle } }}
      </div>
    </div>
    <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
    <div class="summary">
      <div class="message-icon">
        <i class="fs-20px" [uiType]="icon" [uiTooltipTitle]="notification.targetType | localize" ui-tooltip ui-icon uiTheme="fill"></i>
      </div>
      <span class="item">{{ notification.notificationData.item.targetTitle }}</span>
    </div>
    <div class="full">
      <div class="inbox-box">
        <div class="inbox-section">
          <div class="heading inline-notifications">{{ "comment" | localize }}:</div>
          <rich-text-viewer *ngIf="notification.notificationData.item.text" [text]="notification.notificationData.item.text"></rich-text-viewer>
          <gif-view *ngIf="notification.notificationData.item?.gif?.id" [gifSize]="sizeOfGif.Small" [gif]="notification.notificationData.item.gif"></gif-view>
        </div>
        <div class="inbox-section">
          <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_comment" | localize }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
