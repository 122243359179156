import { Injectable } from "@angular/core";
import { IFavoritesPayload } from "@gtmhub/favorites/models";
import { ITeam } from "@gtmhub/teams";
import { UXCustomizationType } from "@gtmhub/uxcustomization/models";
import { IWhiteboard } from "@gtmhub/whiteboards";
import { KpiGroup } from "@webapp/kpis/models/kpi-group.models";

type BasicViewHistoryEntity = { id: string };

@Injectable()
export class FavoritesPayloadBuilder {
  private itemId: string;
  private type: UXCustomizationType;
  private order: number;

  public withItem(item: BasicViewHistoryEntity): FavoritesPayloadBuilder {
    this.itemId = item.id;
    return this;
  }

  public withWhiteboard(whiteboard: IWhiteboard): FavoritesPayloadBuilder {
    return this.withItem(whiteboard).forType("whiteboard");
  }

  public withKpiGroup(kpiGroup: KpiGroup): FavoritesPayloadBuilder {
    return this.withItem(kpiGroup).forType("kpigroup");
  }

  public withSession(session: BasicViewHistoryEntity): FavoritesPayloadBuilder {
    return this.withItem(session).forType("session");
  }

  public withInsightboard(insightboard: BasicViewHistoryEntity): FavoritesPayloadBuilder {
    return this.withItem(insightboard).forType("dashboard");
  }

  public withTeam(team: ITeam): FavoritesPayloadBuilder {
    return this.withItem(team).forType("team");
  }

  public withTask(task: BasicViewHistoryEntity): FavoritesPayloadBuilder {
    return this.withItem(task).forType("task");
  }

  public withList(list: BasicViewHistoryEntity): FavoritesPayloadBuilder {
    return this.withItem(list).forType("list");
  }

  public withEmployee(employee: BasicViewHistoryEntity): FavoritesPayloadBuilder {
    return this.withItem(employee).forType("user");
  }

  public withFilter(filter: BasicViewHistoryEntity): FavoritesPayloadBuilder {
    return this.withItem(filter).forType("filter");
  }

  public forType(type: UXCustomizationType): FavoritesPayloadBuilder {
    this.type = type;
    return this;
  }

  public withOrder(order: number): FavoritesPayloadBuilder {
    this.order = order;
    return this;
  }

  public generate(): IFavoritesPayload {
    const product = {
      itemId: this.itemId,
      type: this.type,
      order: this.order,
    };
    this.itemId = undefined;
    this.type = undefined;
    this.order = undefined;
    return product;
  }
}
