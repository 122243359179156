import { IFeedEntryTypeEnum } from "@gtmhub/feed/models";

export const getTargetTypeForComments = (type: string) => (type === IFeedEntryTypeEnum.KR_UPDATE ? "checkin" : "feed");

export const formatUnit = ({ unit, isPrefix }: { unit: string; isPrefix: boolean }): string => {
  if (unit) {
    if (isPrefix) {
      return unit === "$" || unit === "£" || unit === "€" ? unit : `${unit} `;
    }

    return unit === "%" ? unit : ` ${unit}`;
  }

  return "";
};
