import { RawParams, StateOrName, TransitionOptions } from "@uirouter/angular";
import { OnboardingComponent } from "./onboarding.component";

export const NEW_NAVIGATION_ONBOARDING_USER_SETTINGS_KEY = "new-nav";
export const KPIS_USER_SETTINGS_KEY = "kpis-onboarding";
export const KPI_COMMENTS_USER_SETTINGS_KEY = "kpi-comments";

export const CHECKINS_HOME_USER_SETTINGS_KEY = "checkins-home-onboarding";
export const CHECKINS_TEAM_USER_SETTINGS_KEY = "checkins-team-onboarding";
export const SUGGESTION_STATE_INDICATOR_SETTINGS_KEY = "pi-suggestion-state-indicator-onboarding";
export const PI_CONTEXTUAL_SUGGESTIONS_SETTINGS_KEY = "pi-contextual-suggestions-onboarding";

type OnboardingJourneyStage = "INITIALIZING" | "DISABLED" | "PENDING_START" | "IN_TRANSITION" | "ONGOING" | "ENDED_BY_USER";

export type OnboardingBaseActions = "takeTheTour" | "next" | "back" | "close" | "gotIt";

export interface IOnboardingJourneyState {
  currentStep: number;
  totalSteps: number;
  stage: OnboardingJourneyStage;
  isCompleted: boolean;
  skipSteps?: number[];
}

export interface IOnboardingDelegate {
  shouldSeeOnboarding(): boolean;
  getRedirectStateForStep(step: number): null | { to: StateOrName; params?: RawParams; options?: TransitionOptions };
  getPrimaryActionForStep?(step: number, baseActions: Partial<Pick<OnboardingComponent, OnboardingBaseActions>>): { primaryCTA: string; primaryCTAHandler(): void };
  getSecondaryActionForStep?(step: number, baseActions: Partial<Pick<OnboardingComponent, OnboardingBaseActions>>): { secondaryCTA: string; secondaryCTAHandler(): void };
  journeyCompleted?();
  journeyClosed?();
  journeyStarted?(): void;
}

export interface IOnboardingDefinition {
  shouldShow: boolean;
  totalSteps: number;
  startingStep?: number;
  delegate?: IOnboardingDelegate;
  previewOverrides?: { totalSteps: number; stepsMap?: { [actualStep: number]: number } };
}
