import { IDirective, IDirectiveFactory, IScope } from "angular";
import { IFeedEntry, IFeedKrItem } from "@gtmhub/feed/models";
import { formatAttainmentProgressValue } from "@gtmhub/okrs/metrics/utils";
import { ColoringService } from "@webapp/configuration/services/coloring.service";
import { formatUnit } from "../../utils";

export interface IDrawProgressBarScope extends IScope {
  feedEntry: IFeedEntry;
  elementId: string;
  getProgressColor(attainment: number): string;
  setPositionCurrentValue(attainment: number): string;
  drawProgressBarChange(): void;
  checkAttainment(attainment: number, showNegative?: { showNegative: boolean }): number;
  formatUnit({ unit, isPrefix }: { unit: string; isPrefix: boolean }): string;
  formatAttainmentProgressValue(progress: number): number;
}

export class DrawProgressBar implements IDirective {
  private $scope: IDrawProgressBarScope = null;
  public scope = {
    feedEntry: "=",
  };
  public restrict = "E";
  public template = require("./draw-progress-bar.html");

  constructor(private coloringService: ColoringService) {}

  public link(scope: IDrawProgressBarScope): void {
    this.$scope = scope;

    scope.elementId = `draw-progress-bar-${scope.feedEntry?.id}`;

    scope.checkAttainment = (attainment: number, { showNegative } = { showNegative: false }): number => {
      if (attainment) {
        if (!showNegative) {
          return attainment < 0 ? 0 : attainment;
        }
        return attainment;
      }
      return 0;
    };

    scope.formatUnit = formatUnit;
    scope.formatAttainmentProgressValue = formatAttainmentProgressValue;

    scope.getProgressColor = (attainment: number): string => {
      return this.coloringService.getColorOrGeneric(scope.checkAttainment(attainment));
    };

    scope.setPositionCurrentValue = (attainment: number): string => {
      if (attainment > 1) {
        return "";
      }

      if (attainment >= 0.5) {
        return `right: ${100 - attainment * 100}%`;
      }
      return `left: ${scope.checkAttainment(attainment) * 100}%`;
    };

    scope.drawProgressBarChange = (): void => {
      const krItem = scope.feedEntry.item as IFeedKrItem;
      const cardElement = document.getElementById(`draw-progress-bar-${scope.feedEntry.id}`) as HTMLElement;

      if (cardElement) {
        const krProgressBar: Element = cardElement.children.namedItem("kr-progress-bar");
        const currentProgress: Element = krProgressBar.children.namedItem("current-progress");

        if (currentProgress && !scope.feedEntry.displayed) {
          scope.feedEntry.displayed = true; // to display the animation only on the first scroll

          if (scope.checkAttainment(krItem.attainment) <= 1) {
            this.animateProgressBarChange(krItem, currentProgress);
          }

          this.animateExceptionalProgressBarChange(scope.feedEntry.id, krProgressBar, cardElement);
        }
      }
    };

    angular.element(document.querySelector(`#${scope.elementId}`)).ready(() => {
      // On tab change this handle is called twice
      // And in some of those calls the element is NOT ready, so we need this extra check
      const element = document.querySelector(`#${scope.elementId}`);

      if (element) {
        const progressBarObserver = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting === true) {
              scope.drawProgressBarChange();

              progressBarObserver.unobserve(element);
            }
          },
          { threshold: [0] }
        );

        progressBarObserver.observe(element);
      }
    });
  }

  private animateProgressBarChange = (krItem: IFeedKrItem, currentProgress: Element): void => {
    const currentAttainment: number = this.$scope.checkAttainment(krItem.attainment);
    const attainmentChange: number = this.$scope.checkAttainment(krItem.attainmentChange);
    const previousAttainment: number = currentAttainment - attainmentChange > 0 ? currentAttainment - attainmentChange : 0;

    const fromKeyframe = previousAttainment * 100 < 100 ? previousAttainment * 100 : 100;
    const toKeyframe = currentAttainment * 100;
    if (fromKeyframe !== toKeyframe) {
      this.animate(currentProgress, {
        property: "width",
        fromKeyframe: `${fromKeyframe}%`,
        toKeyframe: `${toKeyframe}%`,
        timeDuration: 800,
      });

      if (krItem.attainment == 1) {
        this.createConfettiAnimation(this.$scope.feedEntry.id);
      }
    }
  };

  private animateExceptionalProgressBarChange = (itemId: string, krProgressBar: Element, cardElement: HTMLElement): void => {
    const exceptional: Element = krProgressBar.children.namedItem("exceptional");

    if (exceptional) {
      this.createConfettiAnimation(itemId);
      this.animate(exceptional, {
        property: "right",
        fromKeyframe: "0%",
        toKeyframe: "31%",
        timeDuration: 800,
      });
    }

    const krProgressBarValues: Element = cardElement.children.namedItem("kr-progress-bar-values");
    const progressBarTargetValue: Element = krProgressBarValues.children.namedItem("progress-bar-target-value");
    if (progressBarTargetValue) {
      this.animate(progressBarTargetValue, {
        property: "opacity",
        fromKeyframe: "0",
        toKeyframe: "1",
        timeDuration: 800,
      });
    }
  };

  private createConfettiAnimation = (itemId: string): void => {
    const celebrationElement: HTMLElement = document.getElementById(`celebration-${itemId}`) as HTMLElement;

    if (!celebrationElement) {
      return;
    }

    const celebrationFastElement: Element = celebrationElement.children.namedItem("confetti-fast");
    const celebrationSlowElement: Element = celebrationElement.children.namedItem("confetti-slow");

    if (!celebrationFastElement || !celebrationSlowElement) {
      return;
    }

    this.animateConfetti(
      celebrationFastElement,
      {
        property: "backgroundPosition",
        startKeyframe: "0 -150px",
        middleKeyframe: "0 -75px",
        endKeyframe: "0 0px",
      },
      {
        property: "opacity",
        startKeyframe: "0",
        middleKeyframe: "1",
        endKeyframe: "0",
      },
      1000
    );
    this.animateConfetti(
      celebrationSlowElement,
      {
        property: "backgroundPosition",
        startKeyframe: "0 -200px",
        middleKeyframe: "0 -150px",
        endKeyframe: "0 100px",
      },
      {
        property: "opacity",
        startKeyframe: "0",
        middleKeyframe: "0.3",
        endKeyframe: "0",
      },
      1500
    );
  };

  private animateConfetti = (
    element: Element,
    firstAnimation: { property: string; startKeyframe: string; middleKeyframe: string; endKeyframe: string },
    secondAnimation: { property: string; startKeyframe: string; middleKeyframe: string; endKeyframe: string },
    duration: number
  ): void => {
    element.animate(
      [
        {
          [firstAnimation.property]: firstAnimation.startKeyframe,
          [secondAnimation.property]: secondAnimation.startKeyframe,
        },
        {
          [firstAnimation.property]: firstAnimation.middleKeyframe,
          [secondAnimation.property]: secondAnimation.middleKeyframe,
        },
        {
          [firstAnimation.property]: firstAnimation.endKeyframe,
          [secondAnimation.property]: secondAnimation.endKeyframe,
        },
      ],
      duration // time duration in ms
    );
  };

  private animate = (element: Element, animation: { property: string; fromKeyframe: string; toKeyframe: string; timeDuration: number }): void => {
    element.animate(
      [
        {
          [animation.property]: animation.fromKeyframe,
        },
        {
          [animation.property]: animation.toKeyframe,
        },
      ],
      animation.timeDuration // time duration in ms
    );
  };

  public static factory(): IDirectiveFactory {
    const directive = (coloringService: ColoringService) => new DrawProgressBar(coloringService);
    directive.$inject = ["ColoringService"];
    return directive;
  }
}
