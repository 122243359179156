import { NgModule } from "@angular/core";
import { UiDrawerContentDirective } from "./directives/drawer-content.directive";
import { UiDrawerExtraDirective } from "./directives/drawer-extra.directive";
import { UiDrawerFooterDirective } from "./directives/drawer-footer.directive";
import { UiDrawerSidePanelDirective } from "./directives/drawer-side-panel.directive";
import { UiDrawerTitleDirective } from "./directives/drawer-title.directive";
import { UiDrawerComponent } from "./drawer.component";
import { UiDrawerService } from "./services/drawer.service";

@NgModule({
  imports: [UiDrawerComponent, UiDrawerContentDirective, UiDrawerTitleDirective, UiDrawerExtraDirective, UiDrawerSidePanelDirective, UiDrawerFooterDirective],
  exports: [UiDrawerComponent, UiDrawerContentDirective, UiDrawerTitleDirective, UiDrawerExtraDirective, UiDrawerSidePanelDirective, UiDrawerFooterDirective],
  providers: [UiDrawerService],
})
export class UiDrawerModule {}
