<ng-container *ngIf="isShowSwitchIcon">
  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <ng-container *nzStringTemplateOutlet="nzExpandedIcon; context: { $implicit: context, origin: context.origin }">
      <i class="ui-tree-node-switcher-arrow-custom" [class.ant-select-tree-switcher-icon]="nzSelectMode" [class.ant-tree-switcher-icon]="!nzSelectMode" ui-icon uiType="chevron-down-small"></i>
    </ng-container>
  </ng-container>
</ng-container>
<ng-container *ngIf="nzShowLine">
  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <ng-container *nzStringTemplateOutlet="nzExpandedIcon; context: { $implicit: context, origin: context.origin }">
      <i class="ant-tree-switcher-line-icon" *ngIf="isShowLineIcon" [uiType]="isSwitcherOpen ? 'ountline:minus-square' : 'ountline:plus-square'" ui-icon></i>
      <i class="ant-tree-switcher-line-icon" *ngIf="!isShowLineIcon" ui-icon uiType="file"></i>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #loadingTemplate>
  <i class="ant-tree-switcher-loading-icon" [uiSpin]="true" ui-icon uiType="loading"></i>
</ng-template>
