import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UiTooltipDirective } from "@quantive/ui-kit/tooltip";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";
import { PI_CARD_ACTION_BUTTON_TOOLTIP_CUSTOM_STYLE, PI_TEXT_CARD_FONT_COLOR_CLASSES, VERTICAL_MENU_DROPDOWN_MENU_ITEMS_MAP } from "../../../models/pi-card.models";
import { IPIFeedbackSuggestion } from "../models";
import {
  IMPROVE_SUGGESTION_COMMON_DROPDOWN_MENU_ITEMS_MAP,
  IMPROVE_SUGGESTION_SPECIFIC_DROPDOWN_MENU_ITEMS_MAP,
  ImproveSuggestionButtonSpecificDropdownMenuItemsMap,
  OR_MAKE_IT_ITEM,
  POST_IMPROVEMENT_INSTRUCTIONS_MAP,
  PostImprovementInstruction,
} from "../services/improvement/models";
import { PiStateProcessorInstance } from "../services/state-processor/pi-state-processor.models";

const copyBtnTooltipTitleResetDelay = 2000;

@Component({
  selector: "pi-suggestion-action-bar-main-actions",
  templateUrl: "./pi-suggestion-action-bar-main-actions.component.html",
  styleUrls: ["./pi-suggestion-action-bar-main-actions.component.less"],
})
export class PiSuggestionActionBarMainActionsComponent implements OnInit {
  @Input()
  public instance: PiStateProcessorInstance;
  @Input()
  public isAddBtnAvailable = true;
  @Input()
  public isImproveBtnAvailable = true;
  @Input()
  public isRefreshBtnAvailable = true;
  @Input()
  public isEditBtnAvailable = true;
  @Input()
  public isRemoveBtnAvailable = true;
  @Input()
  public isCopyBtnAvailable = true;
  @Input()
  public improveBtnSpecificDropdownItems: (keyof ImproveSuggestionButtonSpecificDropdownMenuItemsMap)[];
  @Input()
  public suggestion: IPIFeedbackSuggestion<string | string[]>;
  @Input()
  public primaryButtonText = "add";
  @Input()
  public copyBtnTooltipTitle = "copy_answer";

  @Output()
  public readonly suggest = new EventEmitter();
  @Output()
  public readonly suggestionRemove = new EventEmitter();
  @Output()
  public readonly suggestionAdd = new EventEmitter();
  @Output()
  public readonly askAiClick = new EventEmitter<void>();

  constructor(private cdr: ChangeDetectorRef) {}

  public shouldShowVerticalMenuBtn: boolean;

  public improveBtnDropdownItems: DropdownMenuItem[];
  public verticalMenuBtnDropdownItems: DropdownMenuItem[];

  public isImproveBtnDropdownOpened: boolean;
  public isVerticalMenuBtnDropdownOpened: boolean;

  public actionBtnBottomLeftTooltipCustomStyle = PI_CARD_ACTION_BUTTON_TOOLTIP_CUSTOM_STYLE.bottomLeftTooltip;

  private defaultCopyBtnTooltipTitle: string;
  private copyBtnTooltipTitleResetTimeout: NodeJS.Timeout = null;

  public ngOnInit(): void {
    this.shouldShowVerticalMenuBtn = this.isImproveBtnAvailable && this.isRefreshBtnAvailable && this.isEditBtnAvailable && this.isRemoveBtnAvailable;

    if (this.isImproveBtnAvailable) {
      this.setImproveBtnDropdownItems();
    }

    if (this.shouldShowVerticalMenuBtn) {
      this.setVerticalMenuBtnDropdownItems();
    }

    this.persistCopyBtnTooltipTitle();
  }

  public onImproveBtnDropdownToggle = (args: { isDropdownOpened: boolean; tooltipDirective: UiTooltipDirective }): void => {
    this.isImproveBtnDropdownOpened = args.isDropdownOpened;

    if (args.isDropdownOpened) {
      args.tooltipDirective.hide();
    }
  };

  public onVerticalMenuBtnDropdownToggle = (args: { isDropdownOpened: boolean; tooltipDirective: UiTooltipDirective }): void => {
    this.isVerticalMenuBtnDropdownOpened = args.isDropdownOpened;

    if (args.isDropdownOpened) {
      args.tooltipDirective.hide();
    }
  };

  public onSuggestAnotherClicked = (): void => {
    this.instance.subject.next({ suggestAnother: true, suggestion: this.suggestion });
    this.suggest.emit();
  };

  public onCopyClicked = (): void => {
    navigator.clipboard.writeText(this.suggestion.text as string).then(() => {
      this.copyBtnTooltipTitle = "copied_cap";
      this.cdr.markForCheck();

      this.resetCopyBtnTooltipTitle();
    });

    this.instance.subject.next({ makeCopy: true, suggestion: this.suggestion });
  };

  public onRemoveSuggestionClicked = (): void => {
    this.instance.subject.next({ removeSuggestion: true, suggestion: this.suggestion });
    this.suggestionRemove.emit();
  };

  public onAddSuggestionClicked = (): void => {
    this.instance.subject.next({ addSuggestion: true, suggestion: this.suggestion });
    this.suggestionAdd.emit();
  };

  private persistCopyBtnTooltipTitle = (): void => {
    this.defaultCopyBtnTooltipTitle = this.copyBtnTooltipTitle;
  };

  private resetCopyBtnTooltipTitle = (): void => {
    if (this.copyBtnTooltipTitleResetTimeout) {
      clearTimeout(this.copyBtnTooltipTitleResetTimeout);
    }

    this.copyBtnTooltipTitleResetTimeout = setTimeout(() => {
      this.copyBtnTooltipTitle = this.defaultCopyBtnTooltipTitle;
      this.cdr.markForCheck();
    }, copyBtnTooltipTitleResetDelay);
  };

  private setImproveBtnDropdownItems = (): void => {
    const menuItemBuilder = new DropdownMenuItemBuilder();

    const buildButton = (args: { key: string; improvement: PostImprovementInstruction }): DropdownMenuItem => {
      return menuItemBuilder
        .setKey(args.key)
        .setUiType({ uiType: "uiIcon", iconType: "platform-intelligence-default", iconTheme: "outline" })
        .setClasses(["fw-500", PI_TEXT_CARD_FONT_COLOR_CLASSES.dark])
        .setAction({
          handler: () => {
            if (args.improvement === POST_IMPROVEMENT_INSTRUCTIONS_MAP.ASK_AI_TO) {
              return this.askAiClick.emit();
            }

            return this.instance.improvementSubject.next({ improvement: args.improvement, suggestion: this.suggestion });
          },
        })
        .build();
    };

    const ASK_AI_TO = buildButton({ key: IMPROVE_SUGGESTION_COMMON_DROPDOWN_MENU_ITEMS_MAP.ASK_AI_TO, improvement: POST_IMPROVEMENT_INSTRUCTIONS_MAP.ASK_AI_TO });

    const OR_MAKE_IT = menuItemBuilder
      .setKey(OR_MAKE_IT_ITEM)
      .setClasses(["fs-12px", "fw-500", PI_TEXT_CARD_FONT_COLOR_CLASSES.medium])
      .setToBeInfoTextOnly()
      .setSkipFromTabOrder()
      .build();

    const commonDropdownItems = [ASK_AI_TO, OR_MAKE_IT];

    const suggestionSpecificDropdownItems = this.improveBtnSpecificDropdownItems.map((item) => {
      return buildButton({ key: IMPROVE_SUGGESTION_SPECIFIC_DROPDOWN_MENU_ITEMS_MAP[item], improvement: POST_IMPROVEMENT_INSTRUCTIONS_MAP[item] });
    });

    this.improveBtnDropdownItems = [...commonDropdownItems, ...suggestionSpecificDropdownItems];
  };

  private setVerticalMenuBtnDropdownItems = (): void => {
    const menuItemBuilder = new DropdownMenuItemBuilder();

    const EDIT = menuItemBuilder
      .setKey(VERTICAL_MENU_DROPDOWN_MENU_ITEMS_MAP.EDIT)
      .setUiType({ uiType: "uiIcon", iconType: "edit", iconTheme: "outline" })
      .setClasses(["fw-500", PI_TEXT_CARD_FONT_COLOR_CLASSES.dark])
      .build();

    const REMOVE = menuItemBuilder
      .setKey(VERTICAL_MENU_DROPDOWN_MENU_ITEMS_MAP.REMOVE)
      .setUiType({ uiType: "uiIcon", iconType: "close-big", iconTheme: "outline" })
      .setClasses(["fw-500", PI_TEXT_CARD_FONT_COLOR_CLASSES.dark])
      .build();

    this.verticalMenuBtnDropdownItems = [EDIT, REMOVE];
  };
}
