import { Injectable } from "@angular/core";
import { Observable, combineLatest, map, of, switchMap, tap } from "rxjs";
import { ListEventType } from "@gtmhub/lists/services/events";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { ICollection } from "@webapp/core/core.models";
import { IBaseRepository } from "@webapp/core/state-management/models/base-repository.model";
import { ModuleFlag } from "@webapp/feature-toggles/models/feature-module.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { IFilterByFeatureFlag, List, ListPropertyTypes, ListTargetType, ListType, PatchList } from "../models";
import { getDictionaryByListType } from "../utils";
import { ListApiService } from "./list-api.service";

@Injectable({ providedIn: "root" })
export class ListRepository implements Pick<IBaseRepository<List>, "getAll$" | "get$" | "post$" | "patch$" | "delete$"> {
  /** the `listType` field projection is related to the `type` property in the response model  */
  private readonly listsFields = [
    "access",
    "accountId",
    "color",
    "columns",
    "createdAt",
    "currentUserAllowedActions",
    "filter",
    "filters",
    "id",
    "itemsPerPage",
    "ownerId",
    "sort",
    "title",
    "listType",
    "view",
  ];

  constructor(
    private listApiService: ListApiService,
    private featureTogglesFacade: FeatureTogglesFacade,
    private broadcastService: BroadcastService
  ) {}

  public getAll$<RT = ICollection<List>>({ filters, config }: { filters?: RequestPaging; config?: RequestConfig } = {}): Observable<RT> {
    if (!filters) filters = new RequestPaging();

    filters.fields = this.listsFields;
    return this.listApiService.getAll$<RT>(filters, config);
  }

  public get$(id: string, config?: RequestConfig): Observable<List> {
    return this.listApiService.get$<List>(id, config).pipe(switchMap((list) => this.filterListColumnSortAndRuleBoundsByFeature$(list)));
  }

  public post$({ postModel, config }: { postModel: Partial<List>; config?: RequestConfig }): Observable<List> {
    return this.listApiService.post$<List>(postModel, config);
  }

  public cloneList$(list: { id: string }): Observable<List> {
    const url = this.listApiService.getBasePath(HttpActions.post) + `/${list.id}/clone`;

    return this.listApiService.post$<List>(list, { url: url });
  }

  public patch$({ id, patchModel, config }: { id: string; patchModel: PatchList; config?: RequestConfig }): Observable<List> {
    return this.listApiService.patch$<List>(id, patchModel, config).pipe(
      tap(() =>
        this.broadcastService.emit(ListEventType.LIST_UPDATED, {
          list: { id, ...patchModel },
        })
      )
    );
  }

  public delete$(params: { id: string; config?: RequestConfig }): Observable<void> {
    return this.listApiService.delete$<void>(params.id, params.config).pipe(
      tap(() =>
        this.broadcastService.emit(ListEventType.LIST_DELETED, {
          id: params.id,
        })
      )
    );
  }

  public getListPropertiesByType$(targetType: ListTargetType): Observable<ListPropertyTypes> {
    const url = this.listApiService.getBasePath(HttpActions.get) + `/${targetType}/schema`;

    return this.listApiService.getAll$<ListPropertyTypes>(null, { url }).pipe(
      switchMap((listPropertyTypes: ListPropertyTypes) => {
        const dictionary = getDictionaryByListType(targetType);

        return this.filterSchemaByFeature$(listPropertyTypes).pipe(
          map(() => {
            for (const field of listPropertyTypes.fields) {
              if (field.fieldName in dictionary) {
                field.trackingName = field.displayName;
                field.displayName = dictionary[field.fieldName];
              }

              if (field.isFilterable && field.dataType === "numeric") {
                field.supportedOperators = field.supportedOperators.filter((operator) => operator !== "isBetween" && operator !== "isNotBetween");
              }
            }

            return listPropertyTypes;
          })
        );
      })
    );
  }

  public getAllSupportedListTypes$(): Observable<ListType[]> {
    const url = this.listApiService.getBasePath(HttpActions.get) + "/supported-types";

    return this.listApiService.getAll$<{ supportedTypes: ListType[] }>(null, { url }).pipe(map((response) => response.supportedTypes));
  }

  private filterListColumnSortAndRuleBoundsByFeature$(list: List): Observable<List> {
    return this.getFieldsToSkip(list.type).pipe(
      map((fieldNamesToSkip) => {
        if (list.columns && list.columns.length) {
          list.columns = list.columns.filter((c) => !fieldNamesToSkip.includes(c.fieldName));
        }

        if (list.sort && list.sort.length) {
          list.sort = list.sort.filter((c) => !fieldNamesToSkip.includes(c.fieldName));
        }

        if (list.filters) {
          list.filters.forEach((filterGroup) => {
            if (filterGroup.ruleBounds) {
              filterGroup.ruleBounds = filterGroup.ruleBounds.filter((c) => !fieldNamesToSkip.includes(c.fieldName));
            }
          });
        }

        return list;
      })
    );
  }

  private filterSchemaByFeature$(listPropertyTypes: ListPropertyTypes): Observable<ListPropertyTypes> {
    return this.getFieldsToSkip(listPropertyTypes.type).pipe(
      map((fieldNamesToSkip) => {
        if (listPropertyTypes.fields && listPropertyTypes.fields.length) {
          listPropertyTypes.fields = listPropertyTypes.fields.filter((c) => !fieldNamesToSkip.includes(c.fieldName));
        }

        return listPropertyTypes;
      })
    );
  }

  private getFieldsToSkip(type: string): Observable<string[]> {
    const featureFlagColumnChecks: Record<string, IFilterByFeatureFlag> = {
      [ModuleFlag.Badges]: {
        fieldNamesToFilter: ["badges"],
        typesToFilterColumns: ["Team", "Employee"],
        typesToFilterSchema: ["team", "user"],
      },
      [ModuleFlag.Tags]: {
        fieldNamesToFilter: ["tags"],
        typesToFilterColumns: ["Objective", "Key Result"],
        typesToFilterSchema: ["goal", "metric"],
      },
    };

    const observables: Observable<boolean>[] = [];
    const fieldNamesToSkip: string[] = [];

    Object.keys(featureFlagColumnChecks).forEach((f) => {
      const featureSetting = featureFlagColumnChecks[f];
      if (featureSetting.typesToFilterColumns.includes(type) || featureSetting.typesToFilterSchema.includes(type)) {
        observables.push(
          this.featureTogglesFacade.isFeatureAvailable$(f).pipe(
            tap((enabled) => {
              if (!enabled) {
                fieldNamesToSkip.push(...featureSetting.fieldNamesToFilter);
              }
            })
          )
        );
      }
    });

    if (!observables.length) {
      return of([]);
    }

    return combineLatest(observables).pipe(map(() => fieldNamesToSkip));
  }
}
