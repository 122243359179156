<ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
<div class="title">{{ titleText }}</div>
<div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
<div class="summary" *ngIf="!isUserOrTeamTarget">
  <div class="message-icon">
    <i class="fs-20px" [uiType]="iconType" [uiTooltipTitle]="iconTooltipText" ui-tooltip ui-icon uiTheme="fill"></i>
  </div>
  <span class="item">{{ notification.notificationData.item.targetName }}</span>
</div>
<div class="full">
  <div class="inbox-box">
    <div class="inbox-section" *ngIf="isUserOrTeamTarget">
      <div class="heading sp-mb-10">{{ assigneeProfileTitleText }}:</div>
      <ui-assignee [fromAssigneeId]="notification.targetId" uiSize="xs"></ui-assignee>
    </div>
    <div class="inbox-section">
      <div class="heading sp-mb-10">{{ notification.notificationData.item.fieldName }}:</div>
      <div class="flex wrap">
        <ui-assignee class="sp-mr-5" *ngFor="let id of mentionIds" [fromAssigneeId]="id" uiSize="xs"></ui-assignee>
      </div>
    </div>
    <div class="inbox-section">
      <a class="link classic" [attr.href]="notification.notificationData.url">{{ buttonText }}</a>
    </div>
  </div>
</div>
