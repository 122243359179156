import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { QuantivePlusWhiteboardDTO, QuantivePlusWhiteboardPostDTO } from "../models";
import { QuantivePlusWhiteboardApiV2Service } from "./quantive-plus-whiteboard-api-v2.service";
import { QuantivePlusWhiteboardStateV2 } from "./quantive-plus-whiteboard-state-v2.service";

@Injectable({
  providedIn: "any",
})
export class QuantivePlusWhiteboardFacadeV2 extends BaseFacade<
  QuantivePlusWhiteboardDTO,
  QuantivePlusWhiteboardPostDTO,
  QuantivePlusWhiteboardStateV2,
  QuantivePlusWhiteboardApiV2Service
> {
  constructor(state: QuantivePlusWhiteboardStateV2, api: QuantivePlusWhiteboardApiV2Service) {
    super(state, api);
  }

  public getWhiteboardSuggestions(payload: unknown): Observable<QuantivePlusWhiteboardDTO> {
    return this.post$(payload, { ...new RequestConfig(), url: this.apiService.getObjectiveKrEndpoint() });
  }
}
