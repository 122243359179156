import { Pipe, PipeTransform } from "@angular/core";
import { SuggestedInsight } from "@webapp/data-story/models/insights.models";
import { localize } from "@webapp/localization/utils/localization.utils";

@Pipe({
  name: "insightUsageMessage",
})
export class InsightUsageMessagePipe implements PipeTransform {
  public transform(insight: SuggestedInsight): string {
    let msg = "";
    if (insight.krUsageCount) {
      msg += `${localize("usage_for")} ${insight.krUsageCount} `;
      msg += insight.krUsageCount > 1 ? localize("key_results") : localize("key_result");
    }
    if (insight.kpiUsageCount) {
      msg += msg.length ? ` ${localize("and")} ` : `${localize("usage_for")} `;
      msg += `${insight.kpiUsageCount} ${insight.kpiUsageCount > 1 ? localize("kpis") : localize("kpi")}`;
    }
    if (!insight.krUsageCount && !insight.kpiUsageCount) {
      msg = `${localize("insight_not_used_yet")}`;
    }
    return msg;
  }
}
