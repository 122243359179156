import { Directive, ElementRef, EventEmitter, Input, Output } from "@angular/core";
import { NgStyleInterface, NzTSType } from "ng-zorro-antd/core/types";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzPopoverDirective } from "ng-zorro-antd/popover";
import { NzTooltipTrigger } from "ng-zorro-antd/tooltip";

@Directive({
  selector: "[ui-popover]",
  exportAs: "uiPopover",
  host: {
    "[class.ant-popover-open]": "visible",
  },
  standalone: true,
})
export class UiPopoverDirective extends NzPopoverDirective {
  @Input("uiArrowPointAtCenter")
  @InputBoolean()
  public arrowPointAtCenter?: boolean;
  @Input("uiTitle")
  public title?: NzTSType;
  @Input("uiContent")
  public content?: NzTSType;
  @Input("ui-popover")
  public directiveTitle?: NzTSType | null;
  @Input("uiTrigger")
  public trigger?: NzTooltipTrigger = "hover";
  @Input("uiPlacement")
  public placement?: string | string[] = "top";
  @Input("uiOrigin")
  public origin?: ElementRef<HTMLElement>;
  @Input("uiVisible")
  public visible?: boolean;
  @Input("uiMouseEnterDelay")
  public mouseEnterDelay?: number;
  @Input("uiMouseLeaveDelay")
  public mouseLeaveDelay?: number;
  @Input("uiOverlayClassName")
  public overlayClassName?: string;
  @Input("uiOverlayStyle")
  public overlayStyle?: NgStyleInterface;
  @Input("uiBackdrop")
  public nzPopoverBackdrop?: boolean;

  @Output("uiVisibleChange") public readonly visibleChange = new EventEmitter<boolean>();

  constructor() {
    super();
  }
}
