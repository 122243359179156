import { IAssigneesStoreState } from "@gtmhub/assignees";
import { localize } from "@gtmhub/localization";
import { Assignee, AssigneeSelectorType, DeletedAssigneeType, MissingAssignee, UnknownAssignee } from "../models/assignee.models";

export const assigneeFromMap = (
  assignees: Map<string, Assignee> | undefined,
  assigneeId: string,
  options?: { hideDeleted: boolean },
  deletedAssigneeType?: DeletedAssigneeType
): Assignee | UnknownAssignee | undefined => {
  if (!assigneeId) {
    return undefined;
  }

  if (!assignees) {
    return { id: assigneeId, isUnknown: true, name: localize("loading"), avatar: "refresh", color: "#87a6bc" };
  }

  if (options?.hideDeleted) {
    return assignees.get(assigneeId) || undefined;
  } else {
    return assignees.get(assigneeId) || deletedAssignee(assigneeId, deletedAssigneeType);
  }
};

export const assigneeFromRedux = (
  state: IAssigneesStoreState,
  assigneeId: string,
  options?: { hideDeleted: boolean },
  deletedAssigneeType?: DeletedAssigneeType
): Assignee | UnknownAssignee => {
  if (!assigneeId) {
    return undefined;
  }

  if (!state.assignees.isFetched) {
    return { id: assigneeId, isUnknown: true, name: localize("loading"), avatar: "refresh", color: "#87a6bc" };
  }

  if (options?.hideDeleted) {
    return state.assignees.map[assigneeId] || undefined;
  } else {
    return state.assignees.map[assigneeId] || deletedAssignee(assigneeId, deletedAssigneeType);
  }
};

export const isUnknownAssignee = (assignee: Assignee | UnknownAssignee | MissingAssignee): assignee is UnknownAssignee => "isUnknown" in assignee && assignee.id !== "";
export const isMissingAssignee = (assignee: Assignee | UnknownAssignee | MissingAssignee): assignee is MissingAssignee => "isUnknown" in assignee && assignee.id === "";
const isDeactivatedAssignee = (assignee: Assignee | UnknownAssignee | MissingAssignee): boolean =>
  "isActive" in assignee && assignee && !isUnknownAssignee(assignee) && assignee.isActive === false;

export const deletedAssignee = (id: string, assigneeType: "user" | "assignee" = "assignee"): UnknownAssignee => ({
  id,
  isUnknown: true,
  name: assigneeType === "assignee" ? localize("deleted_assignee") : localize("deleted_user_cap"),
  avatar: "deleted-user",
  color: "#7485A2",
});

// unknown is someone that we do have an Id for, but we no longer have any reference to
// missing is we don't have an id for, so we don't know who he is
export const createMissingAssignee = (): MissingAssignee => ({
  id: "",
  isUnknown: true,
  name: localize("unknown"),
  avatar: "unknown-user",
  color: "#7485A2",
});

export const respectsAssignPermissions = (assignee: Assignee, assignPermissions: AssigneeSelectorType): boolean =>
  (assignPermissions !== "team" && assignee.type === "user") || (assignPermissions !== "user" && assignee.type === "team");

export const getAssigneeStatusRank = (assignee: Assignee | UnknownAssignee | MissingAssignee): number => {
  if (!assignee?.id || isMissingAssignee(assignee) || isUnknownAssignee(assignee)) {
    return 2;
  }
  if (assignee && !isMissingAssignee(assignee) && !isUnknownAssignee(assignee) && !isDeactivatedAssignee(assignee)) {
    return 0;
  }
  if (isDeactivatedAssignee(assignee)) {
    return 1;
  }
};

export const sortAssigneeIdsByActiveStatus = (assigneeIds: string[], assigneesMap: Record<string, Assignee>): string[] => {
  if (!assigneesMap) {
    return assigneeIds;
  }

  return [...(assigneeIds || [])].sort((firstAssigneeId: string, secondAssigneeId: string) => {
    return getAssigneeStatusRank(assigneesMap[firstAssigneeId]) - getAssigneeStatusRank(assigneesMap[secondAssigneeId]);
  });
};

export const sortAssigneesByActiveStatus = (assignees: (Assignee | UnknownAssignee)[]): (Assignee | UnknownAssignee)[] => {
  return [...assignees].sort((a: Assignee, b: Assignee) => {
    return getAssigneeStatusRank(a) - getAssigneeStatusRank(b);
  });
};
