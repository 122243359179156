import { IAttributes, IAugmentedJQuery, IDirective, IModule, INgModelController, IScope } from "angular";

/**
 * Converts the model of a checkbox (usually a string-to-boolean object) to an array.
 *
 * @example
 * <input type="checkbox" ng-model="myArrayOfStrings" ng-value="'val1'" array-selection />
 * <input type="checkbox" ng-model="myArrayOfStrings" ng-value="'val2'" array-selection />
 */
export class ArraySelectionDirective implements IDirective {
  restrict = "A";
  require = "ngModel";

  link(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes, ctrl: INgModelController): void {
    const evalValue = () => scope.$eval(attrs["ngValue"]);

    ctrl.$formatters.push((values: string[]) => {
      const value = evalValue();
      return values.indexOf(value) >= 0;
    });

    ctrl.$parsers.push((selected: boolean) => {
      const value = evalValue();
      const values: string[] = ctrl.$modelValue;

      if (selected) {
        return [...values, value];
      }

      return values.filter((v) => v !== value);
    });
  }

  static register(mod: IModule): void {
    const directive = () => new ArraySelectionDirective();
    mod.directive("arraySelection", directive);
  }
}
