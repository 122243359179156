import { SlicePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { IAssigneesStoreState } from "@gtmhub/assignees";
import { localize } from "@gtmhub/localization";
import { ISessionsStoreState } from "@gtmhub/sessions/redux/session-reducer";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { DynamicItemSettings } from "@webapp/data-story/models/insights.models";
import { ISessionsIdMap } from "@webapp/sessions/models/sessions.model";
import { toValueDisplayName } from "@webapp/shared/components/item-automation/item-automation.utils";
import { DayjsDateRangePipe } from "@webapp/shared/pipes/dayjs-date-range/dayjs-date-range.pipe";
import { DayjsDatePipe } from "@webapp/shared/pipes/dayjs-date/dayjs-date.pipe";

@Pipe({
  name: "insightFilter",
})
export class InsightFilterPipe implements PipeTransform {
  public assigneesIdMap: Record<string, Assignee>;
  public sessionsIdMap: ISessionsIdMap;

  constructor() {
    this.assigneesIdMap = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState>().assignees.map;
    this.sessionsIdMap = reduxStoreContainer.reduxStore.getState<ISessionsStoreState>().sessions.map;
  }

  public transform(filter: DynamicItemSettings, valueMaxLength?: number): string {
    if (!filter) return "";

    let displayValue: string;

    if (typeof filter.value !== "number" && !filter.value) {
      displayValue = localize("none_selected");
    } else {
      switch (filter.type) {
        case "daterange":
        case "systemdaterange": {
          const value = filter.value.toString();
          const isDateRange = value.split("|")[1];
          const dateRangeFilter = new DayjsDateRangePipe();
          displayValue = isDateRange ? dateRangeFilter.transform(value, "DD MMM, YYYY", true) : value;
          break;
        }

        case "datepicker": {
          const value = filter.value.toString();
          const dateFilter = new DayjsDatePipe();
          displayValue = dateFilter.transform(value, "DD MMM, YYYY", true);
          break;
        }

        default: {
          const value = toValueDisplayName({
            key: filter.name,
            ids: filter.value,
            assigneesIdMap: this.assigneesIdMap,
            sessionsIdMap: this.sessionsIdMap,
          });

          const slice = new SlicePipe();
          displayValue = slice.transform(value, 0, valueMaxLength) + (value.length > valueMaxLength ? "..." : "");
          break;
        }
      }
    }

    const firstPart = filter.title ? `${filter.title}: ` : "";

    return `${firstPart}${displayValue}`;
  }
}
