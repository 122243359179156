import { PiContextualSuggestionsOnboardingState } from "../services/pi-contextual-suggestions-onboarding.service";

export enum PiContextualSuggestionsOnboardingStepsEnum {
  contextualSuggestionButton = 1,
  suggestionStateIndicator = 2,
}

export const PI_CONTEXTUAL_SUGGESTIONS_ONBOARDING_TOTAL_STEPS = 2;
export const PI_CONTEXTUAL_SUGGESTIONS_ONBOARDING_MAX_VIEWS = 3;

export const PI_CONTEXTUAL_SUGGESTIONS_ONBOARDING_INITIAL_STATE: PiContextualSuggestionsOnboardingState = {
  currentStep: PiContextualSuggestionsOnboardingStepsEnum.contextualSuggestionButton,
  stage: "PENDING_START",
  isCompleted: false,
  parentContext: null,
};
