import { StateService } from "@uirouter/angular";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnChanges, OnInit, SimpleChanges, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EMPTY, Observable, combineLatest, mergeMap, noop, of, take, tap } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { IDataSource } from "@gtmhub/datasources/models/models";
import { IIndicator, UIErrorHandlingService } from "@gtmhub/error-handling";
import { getInsightSelectorData, isInsightCodeless } from "@gtmhub/insights";
import { byTextField } from "@gtmhub/util/sorting-utils";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { ICollection } from "@webapp/core/core.models";
import { DataSourceFilterParams } from "@webapp/data-story/models/data-source.models";
import { DataSourceBlueprint } from "@webapp/data-story/models/data-sources-facade.models";
import {
  BrowseConnectionsActionsParams,
  BrowseInsightsActionParams,
  BuildInsightActionParams,
  ConfigurePowerBIActionParams,
  ConnectionData,
  DynamicItemSettings,
  ExecuteInsightActionParams,
  Insight,
  InsightFeedbackType,
  InsightSelectorActionType,
  InsightSelectorData,
  OpenPowerBIInsightActionParams,
  SuggestedInsight,
  SuggestedInsights,
  UpdateInsightSelectorDataActionParams,
} from "@webapp/data-story/models/insights.models";
import { MarketplaceInsightTemplate, SuggestedMarketplaceInsightTemplate } from "@webapp/data-story/models/marketplace-facade.models";
import { Parameter } from "@webapp/data-story/models/parameters-facade.models";
import { AutoInsightsFacade } from "@webapp/data-story/services/auto-insights-facade/auto-insights-facade.service";
import { DataSourceBlueprintsFacade } from "@webapp/data-story/services/data-source-blueprints-facade/data-source-blueprints-facade.service";
import { DataSourcesFacade } from "@webapp/data-story/services/data-sources-facade/data-sources-facade.service";
import { InsightsFacade } from "@webapp/data-story/services/insights-facade/insights-facade.service";
import { InsightsModalsService } from "@webapp/data-story/services/insights-modals.service";
import { MarketplaceFacade } from "@webapp/data-story/services/marketplace-facade/marketplace-facade.service";
import { MissingDataSourceAccessModalService } from "@webapp/data-story/services/missing-data-source-access-modal.service";
import { ParametersFacade } from "@webapp/data-story/services/parameters-facade/parameters-facade.service";
import { getInsightUsedFilters } from "@webapp/data-story/utils/insights";
import { IDaxQueryResult } from "@webapp/integrations/models/powerbi-models";
import { ConfigurePowerBIModalService } from "@webapp/integrations/power-bi/components/configure-power-bi/configure-power-bi-modal.service";
import { ConfigurePowerBIModalData } from "@webapp/integrations/power-bi/components/configure-power-bi/configure-power-bi.component";
import {
  ScalarDataSourceModalComponent,
  ScalarDataSourceModalData,
} from "@webapp/integrations/power-bi/components/scalar-datasource-modal/scalar-datasource-modal.component";
import { KpiAutomationData } from "@webapp/kpis/models/kpis.models";
import { IAllowedActionsMap } from "@webapp/sessions/models/sessions.model";
import { EditInsightService } from "@webapp/shared/insights/edit-modal/edit-insight.service";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { CurrentUserRepository } from "@webapp/users";
import { formatInsightConnectionData, getDataSourceBlueprintName, getFilterFromItemSettings } from "./item-automation.utils";

type BasicAutomationLocalisationKeys = Record<"heading" | "description", string>;

const BASIC_AUTOMATION_LOCALISATION_KEYS: Record<InsightFeedbackType, BasicAutomationLocalisationKeys> = {
  kr: {
    heading: "automated_key_results",
    description: "track_progress_automatically",
  },
  kpi: {
    heading: "automated_kpis",
    description: "track_progress_automatically",
  },
};

@UntilDestroy()
@Component({
  selector: "item-automation",
  templateUrl: "./item-automation.component.html",
  styleUrls: ["./item-automation.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ItemAutomationComponent),
      multi: true,
    },
  ],
})
export class ItemAutomationComponent implements ControlValueAccessor, OnInit, OnChanges {
  // If ngModel is used within a form tag, either the 'name' attribute must be set or the form
  // control must be defined as 'standalone' in ngModelOptions.
  @Input() public name: string;
  @Input() public itemTitle: string;
  @Input() public itemType: InsightFeedbackType;
  @Input() public readonly: boolean;
  @Input() public hasPermissionToSeeAutomation: boolean;
  @Input() public isAutomated: boolean;
  @Input() public kpiData: KpiAutomationData;

  private insightFilters: Parameter[] = [];
  private isInsightFromMarketplace = false;
  private isInsightFromAccount = false;
  private insightTemplate: MarketplaceInsightTemplate | SuggestedInsight;
  private isMarketplaceEnabled: boolean;
  private dataSourceKey: string;
  private insightDataSourceAccess: IAllowedActionsMap = {};

  public indicators: { loading?: IIndicator; loadingParams?: IIndicator; loadingSuggestions?: IIndicator } = {};
  public showSuggestions: boolean;
  public insightTitle: string;
  public insight: Insight;
  public confirmedInsight: Insight;
  public suggestedInsights: SuggestedInsights;
  public basicAutomationLocalisationKeys: BasicAutomationLocalisationKeys;
  public dataSourceType = null;
  public isEditable: boolean;
  public showDataSourceAccessAlert = false;
  public disabledStyle: boolean;

  constructor(
    private dataSourcesFacade: DataSourcesFacade,
    private dataSourceBlueprintsFacade: DataSourceBlueprintsFacade,
    private insightsFacade: InsightsFacade,
    private parametersFacade: ParametersFacade,
    private marketplaceFacade: MarketplaceFacade,
    private uiErrorHandlingService: UIErrorHandlingService,
    private insightsModalsService: InsightsModalsService,
    private autoInsightsFacade: AutoInsightsFacade,
    private editInsightService: EditInsightService,
    private configurePowerBIModalService: ConfigurePowerBIModalService,
    private stateService: StateService,
    private uiModalService: UiModalService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private editionFeatureService: EditionFeatureService,
    private currentUserRepository: CurrentUserRepository,
    private missingDataSourceAccessModalService: MissingDataSourceAccessModalService
  ) {}

  public ngOnInit(): void {
    this.basicAutomationLocalisationKeys = BASIC_AUTOMATION_LOCALISATION_KEYS[this.itemType];

    this.resetSuggestedInsights();

    combineLatest({
      isMarketplaceEnabled: this.editionFeatureService.hasFeature$("marketplace"),
    })
      .pipe(take(1))
      .subscribe((result) => {
        this.isMarketplaceEnabled = result.isMarketplaceEnabled;
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemTitle && changes.itemTitle.currentValue !== changes.itemTitle.previousValue) {
      this.automate();
    }
  }

  private insightSelectorData: InsightSelectorData;

  get data(): InsightSelectorData {
    return this.insightSelectorData;
  }

  set data(value: InsightSelectorData) {
    this.insightSelectorData = value;
    this.propagateChange(value);
    this.cdr.markForCheck();
  }

  public writeValue(value: InsightSelectorData): void {
    this.data = value;

    if (this.data && this.data.insightName) {
      this.loadDataOnEdit();
    }
  }

  private propagateChange: (value: InsightSelectorData) => void = noop;
  public registerOnChange(fn: (value: InsightSelectorData) => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched = noop;

  public goToInsight = (): void => {
    if (!this.isEditable) {
      return;
    }

    if (this.confirmedInsight && !this.confirmedInsight.isShadow) {
      const options = { name: this.confirmedInsight.name };

      if (this.confirmedInsight.formatting && this.confirmedInsight.formatting.isCodeless) {
        this.stateService.go("gtmhub.insightCodelessEditor", options);
      } else {
        this.stateService.go("gtmhub.insight", options);
      }
    }
  };

  public openInsight = (): void => {
    if (!this.isEditable) {
      return;
    }

    this.insight = this.confirmedInsight;

    if (isInsightCodeless(this.insight)) {
      this.openBuildInsightModal(this.insight.connection);
    } else {
      this.stateService.go("gtmhub.insight", {
        name: this.insight.name,
      });
    }
  };

  // this will update filters in item settings in case insight used is different from codeless;
  // if some are removed from insight => they won't be displayed
  // if new ones are added to insight => they will be displayed with default values or no values
  private setItemFiltersForManual = (): void => {
    const formattedFilters: DynamicItemSettings[] = [];

    this.insightFilters.forEach((insightFilter) => {
      const formattedFilter: DynamicItemSettings = {
        name: insightFilter.key,
        title: insightFilter.title,
        value: insightFilter.defaultValue,
        type: insightFilter.selectorType,
      };

      const itemFilter = getFilterFromItemSettings(insightFilter.key, this.data?.settings);

      if (itemFilter) {
        formattedFilter.value = itemFilter.value;
      }
      formattedFilters.push(formattedFilter);
    });

    // sort so that filters are always in alphabetic order
    this.data = { ...this.data, settings: formattedFilters.sort(byTextField("title")) };
  };

  private setItemFiltersForCodeless = (): void => {
    // Codeless insights don't have filters
    // But insightboards can send filters for them as well - contained in `settings`
    // These need to be removed
    this.data = { ...this.data, settings: [] };
  };

  private loadConnectionData = (datasourceBlueprintName: string): void => {
    if (datasourceBlueprintName) {
      this.dataSourceBlueprintsFacade
        .getBlueprintByName$(datasourceBlueprintName)
        .pipe(take(1))
        .subscribe({
          next: (blueprint: DataSourceBlueprint): void => {
            this.insight.connection = formatInsightConnectionData(blueprint);
            this.setIsEditable();
          },
          error: (error: Error): void => {
            this.indicators.loading = { error };
          },
        });
    } else {
      delete this.indicators.loading;
      this.insight.connection = {
        name: null,
        title: null,
        logoUrl: null,
      };
    }
  };

  private loadDataOnEdit = (): void => {
    this.indicators.loading = { progress: true };

    // If edited insight in SQL editor saved as new - get the new insight
    const newInsightName: string = this.editInsightService.getNewInsightName();
    this.editInsightService.setNewInsightName("");

    if (newInsightName && newInsightName !== this.data?.insightName) {
      this.data = { ...this.data, insightName: newInsightName };

      // TODO: GVS-40754
      // Temporary fix: Set timeout to minimize the cases of not populated insight.usedEntities for newly created insight
      window.setTimeoutOutsideAngular(() => {
        this.getInsightData().subscribe(() => this.cdr.markForCheck());
      }, 1500);
    } else {
      this.getInsightData().subscribe();
    }
  };

  private getInsightData = (): Observable<Insight> => {
    return this.insightsFacade.getInsightSafe$(this.data?.insightName).pipe(
      untilDestroyed(this),
      tap((insight: Insight): void => {
        this.initFromInsight(insight);
      }),
      catchError((error: Error): Observable<never> => {
        this.indicators.loading = { error };
        return EMPTY;
      })
    );
  };

  private initFromInsight = (insight: Insight): void => {
    this.setInsight(insight);
    this.processInsightFilters(insight);
    this.getConnectionData(insight);
  };

  private setInsight = (insight: Insight): void => {
    this.insight = insight;
    this.confirmedInsight = insight;

    if (insight) {
      this.insightDataSourceAccess = this.processInsightAggregateAccess();
      this.showDataSourceAccessAlert = !this.insightDataSourceAccess.read;
      this.disabledStyle = !this.insightDataSourceAccess.read;
    }
  };

  private processInsightAggregateAccess = (): IAllowedActionsMap => {
    const insightEntitiesAccess = this.insight.aggregateAccess?.map((entity) => this.currentUserRepository.allowedActionsSync(entity.access));
    const dataSourceActions: (keyof IAllowedActionsMap)[] = ["read", "create", "update", "delete", "modifyPermissions"];

    return dataSourceActions.reduce((actionsMap: IAllowedActionsMap, action: keyof IAllowedActionsMap) => {
      actionsMap[action] = insightEntitiesAccess?.every((entityAccess) => entityAccess.includes(action)) ?? true;

      return actionsMap;
    }, {});
  };

  private processInsightFilters = (insight: Insight): void => {
    if (!isInsightCodeless(insight)) {
      delete this.indicators.loadingParams;

      this.parametersFacade.getParameters$().subscribe({
        next: (parameters: ICollection<Parameter>): void => {
          delete this.indicators.loadingParams;
          this.insightFilters = getInsightUsedFilters(this.insight, parameters.items);
          this.setItemFiltersForManual();
        },
        error: (error) => {
          this.indicators.loadingParams = { error };
        },
      });
    } else {
      this.setItemFiltersForCodeless();
    }
  };

  private getConnectionData = (insight: Insight): void => {
    this.dataSourceKey = insight.usedEntities && insight.usedEntities.length > 0 ? insight.usedEntities[0].name : null;
    this.data = { ...this.data, datasourceBlueprintName: getDataSourceBlueprintName(insight) };

    this.setIsEditable();

    this.dataSourceType = this.data.datasourceBlueprintName;
    delete this.indicators.loading;
    this.cdr.detectChanges();

    this.loadConnectionData(this.data.datasourceBlueprintName);
  };

  public editInsightFilters = (): void => {
    if (!this.isEditable) {
      return;
    }

    this.insight = this.confirmedInsight;

    if (isInsightCodeless(this.insight)) {
      this.openBuildInsightModal(this.insight.connection);
    } else {
      this.openExecuteInsightModal(this.insight);
    }
  };

  private openExecuteInsightModal = (insight: Insight): void => {
    this.ngZone.run(() => {
      this.insightsModalsService
        .openInsightSelectorModal({
          action: InsightSelectorActionType.EXECUTE_INSIGHT,
          insight,
          data: this.data,
        })
        .pipe(untilDestroyed(this))
        .subscribe((params) => {
          this.onModalClosed(params);
        });
    });
  };

  public openBrowseConnectionsModal = (): void => {
    this.ngZone.run(() => {
      this.insightsModalsService
        .openInsightSelectorModal()
        .pipe(untilDestroyed(this))
        .subscribe((params) => {
          this.onModalClosed(params);
        });
    });
  };

  private configurePowerBI = (connectionData: ConnectionData): void => {
    this.configurePowerBIModalService
      .open({ connectionData, type: this.itemType, isEdit: !!this.data?.insightName })
      .afterClose.pipe(take(1))
      .subscribe((params: BuildInsightActionParams | BrowseConnectionsActionsParams) => {
        this.onModalClosed(params);
      });
  };

  public openEditPowerBIConfiguration = (): void => {
    if (this.dataSourceKey) {
      const dataSourceFilterParams = { keys: [this.dataSourceKey], includeHidden: true };
      this.getDatasourceAndOpenEditPowerBIModal(dataSourceFilterParams);
    }
  };

  private setIsEditable(): void {
    let isEditable = !!this.data && !this.indicators.loading && this.insight && !!this.insight?.connection;

    isEditable = isEditable && this.insightDataSourceAccess.read;

    this.isEditable = isEditable;
  }

  private getDatasourceAndOpenEditPowerBIModal = (dataSourceFilterParams: DataSourceFilterParams): void => {
    const powerBiModalData: ConfigurePowerBIModalData = {
      connectionData: {
        ...this.insight.connection,
      },
      type: this.itemType,
      isEdit: !!this.data?.insightName,
    };

    // make it non-editable while loading datasource
    this.isEditable = false;

    this.dataSourcesFacade
      .getByQuery$(dataSourceFilterParams)
      .pipe(take(1))
      .subscribe({
        next: (dataSources: IDataSource[]) => {
          if (dataSources && dataSources.length === 1) {
            const dataSource = dataSources[0];
            powerBiModalData.connectionData.id = dataSource.connectorId;
            powerBiModalData.dataSourceDetails = {
              dataSourceId: dataSource.id,
              dataSetName: dataSource.settings.dataSetName,
              workspaceName: dataSource.settings.workspaceName,
              daxQuery: dataSource.virtualFields?.algorithm,
            };

            this.configurePowerBIModalService
              .open(powerBiModalData)
              .afterClose.pipe(take(1))
              .subscribe((params: OpenPowerBIInsightActionParams) => {
                this.onModalClosed(params);

                this.setIsEditable();
              });
          }
        },
        error: (error) => {
          this.indicators.loading = { error };
        },
      });
  };

  private openBuildInsightModal = (connectionData: ConnectionData, daxQueryResult?: IDaxQueryResult): void => {
    this.insightsModalsService
      .openInsightSelectorModal({
        action: InsightSelectorActionType.BUILD_INSIGHT,
        insight: this.confirmedInsight,
        connectionData,
        daxQueryResult,
        canGoBack: true,
      })
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        this.onModalClosed(params);
      });
  };

  private openPowerBiScalarResultModal = (params: OpenPowerBIInsightActionParams): void => {
    this.uiModalService
      .create<ScalarDataSourceModalComponent, ScalarDataSourceModalData>({
        uiContent: ScalarDataSourceModalComponent,
        uiWrapClassName: "",
        uiFooter: null,
        uiClosable: false,
        uiKeyboard: false,
        uiWidth: "700px",
        uiData: {
          connectionData: params.connectionData,
          daxQueryResult: params.daxQueryResult,
        },
      })
      .afterClose.subscribe((params: UpdateInsightSelectorDataActionParams) => {
        this.onModalClosed(params);
      });
  };

  private onModalClosed = (
    params:
      | BrowseConnectionsActionsParams
      | BrowseInsightsActionParams
      | BuildInsightActionParams
      | ExecuteInsightActionParams
      | UpdateInsightSelectorDataActionParams
      | ConfigurePowerBIActionParams
      | OpenPowerBIInsightActionParams
  ): void => {
    this.ngZone.run(() => {
      switch (params?.action) {
        case "browseConnections":
          this.openBrowseConnectionsModal();
          break;
        case "configurePowerBI":
          this.configurePowerBI(params.connectionData);
          break;
        case "buildInsight":
          this.openBuildInsightModal(params.connectionData, params.daxQueryResult);
          break;
        case "executeInsight":
          this.insight = params.insight as Insight;

          this.openExecuteInsightModal(params.insight as Insight);
          break;
        case "updateInsightSelectorData":
          this.updateSelectorData(params);
          break;
        case "openPowerBIInsight":
          this.openPowerBiScalarResultModal(params);
          break;
        default:
          break;
      }
    });
  };

  public removeItemAutomation = (): void => {
    if (this.readonly) {
      return;
    }

    if (!this.insightDataSourceAccess.read) {
      this.missingDataSourceAccessModalService.confirmRemoveItemAutomation({
        action: () => {
          this.removeInsightFromItem();
          this.showDataSourceAccessAlert = false;
        },
      });
    } else {
      this.removeInsightFromItem();
    }
  };

  private removeInsightFromItem = (): void => {
    const result: InsightSelectorData = {
      insightName: "",
      initialValue: null,
      settings: [],
      datasourceBlueprintName: null,
      fieldName: "",
    };

    this.insightTitle = null;

    /** uninstall an insight that has been installed through marketplace template */
    if (this.isInsightFromMarketplace) {
      this.indicators.loading = { progress: true };
      this.insightsFacade.deleteInsight$(this.insight.name).subscribe({
        next: () => {
          delete this.indicators.loading;
          delete this.isInsightFromMarketplace;

          this.setInsight(null);

          this.data = result;
        },
        error: (error) => {
          this.indicators.loading = { error };
        },
      });
      return;
    }

    this.setInsight(null);

    this.data = result;
  };

  private updateSelectorData = (params: UpdateInsightSelectorDataActionParams): void => {
    const result = getInsightSelectorData(params);

    result.insightTemplate = this.insightTemplate;
    result.marketplacePredictionId = this.data?.marketplacePredictionId;
    result.accountPredictionId = this.data?.accountPredictionId;
    result.type = this.itemType;

    result.suggestedInsights = this.data?.suggestedInsights;
    result.suggestedMarketplaceCount = this.suggestedInsights.marketplaceTemplates.length;
    result.suggestedAccountCount = this.suggestedInsights.accountInsights.length;

    if (this.isInsightFromMarketplace) {
      result.isInsightFromAccount = false;
    } else if (this.isInsightFromAccount) {
      result.isInsightFromAccount = true;
    }

    this.insightTitle = params.insight.title;

    this.setInsight(params.insight as Insight);

    this.data = result;
    this.dataSourceType = this.data.datasourceBlueprintName;

    this.setIsEditable();

    this.getInsightData().subscribe();
  };

  public onAutomate = (): void => {
    this.automate({ openConnectionsModal: true });
  };

  private automate = (opts?: { openConnectionsModal: boolean }): void => {
    this.resetSuggestedInsights();

    if (!this.itemTitle) {
      this.showSuggestions = false;

      if (opts && opts.openConnectionsModal) {
        this.openBrowseConnectionsModal();
      }

      return;
    }

    this.updateSuggestions(opts);
  };

  private updateSuggestions = (opts?: { openConnectionsModal: boolean }): void => {
    this.indicators.loadingSuggestions = { progress: true };

    combineLatest({
      marketplaceSuggestions: this.isMarketplaceEnabled
        ? this.autoInsightsFacade.getInsightRecommendations$(this.itemTitle, { fromAccount: false })
        : (of({ marketplaceTemplates: [] }) as Observable<SuggestedInsights>),
      accountSuggestions: this.autoInsightsFacade.getInsightRecommendations$(this.itemTitle, { fromAccount: true }),
    })
      .pipe(
        untilDestroyed(this),
        catchError((error) => {
          delete this.indicators.loading;

          this.showSuggestions = false;
          console.warn(error);
          if (opts && opts.openConnectionsModal) {
            this.openBrowseConnectionsModal();
          }

          return EMPTY;
        }),
        finalize(() => {
          delete this.indicators.loadingSuggestions;
          this.cdr.detectChanges();
        })
      )
      .subscribe({
        next: ({ marketplaceSuggestions, accountSuggestions }) => {
          this.filterSuggestions(accountSuggestions.accountInsights, marketplaceSuggestions.marketplaceTemplates);
          this.suggestedInsights = {
            accountInsights: accountSuggestions.accountInsights,
            marketplaceTemplates: marketplaceSuggestions.marketplaceTemplates,
          };

          this.showSuggestions = !!this.suggestedInsights.marketplaceTemplates.length || !!this.suggestedInsights.accountInsights.length;
          delete this.indicators.loading;

          const result: Partial<InsightSelectorData> = {};

          result.type = this.itemType;
          result.insightTemplate = this.insightTemplate;
          result.suggestedInsights = [...marketplaceSuggestions.marketplaceTemplates, ...accountSuggestions.accountInsights];

          result.accountPredictionId = accountSuggestions.predictionId;

          result.marketplacePredictionId = marketplaceSuggestions.predictionId;
          if (this.suggestedInsights.marketplaceTemplates.length === 0 && this.suggestedInsights.accountInsights.length === 0 && opts && opts.openConnectionsModal) {
            this.openBrowseConnectionsModal();
          }

          this.data = { ...this.data, ...result };

          this.setIsEditable();
        },
      });
  };

  public selectSuggestedTemplate = (insightTemplate: MarketplaceInsightTemplate): void => {
    this.marketplaceFacade
      .installMarketplaceInsightTemplates$([{ insightTemplateId: insightTemplate.id }])
      .pipe(
        take(1),
        mergeMap((marketplaceTemplate) => this.insightsFacade.getInsightsSafe$(marketplaceTemplate.installedInsightsIds.join(",")))
      )
      .subscribe({
        next: (insights) => {
          const insight = insights.items[0];
          if (insight) {
            this.isInsightFromMarketplace = true;
            this.insightTemplate = insightTemplate;
            this.initFromInsight(insight);
            this.openExecuteInsightModal(insight);
          }
        },
        error: (error) => {
          this.uiErrorHandlingService.handleModal(error);
        },
      });
  };

  public selectSuggestedInsight = (insightAccount: SuggestedInsight): void => {
    this.insightsFacade.getInsightsSafe$(insightAccount.id).subscribe({
      next: (insights) => {
        const insight = insights.items[0];
        if (insight) {
          this.isInsightFromAccount = true;
          this.insightTemplate = insightAccount;
          this.initFromInsight(insight);
          this.openExecuteInsightModal(insight);
        }
      },
      error: (error) => {
        this.uiErrorHandlingService.handleModal(error);
      },
    });
  };

  private resetSuggestedInsights(): void {
    this.suggestedInsights = {
      accountInsights: [],
      marketplaceTemplates: [],
    };
  }

  private filterSuggestions(accountInsights: SuggestedInsight[], marketplaceInsights: SuggestedMarketplaceInsightTemplate[]): void {
    const totalInsightsLength = marketplaceInsights.length + accountInsights.length;
    if (totalInsightsLength > 4) {
      accountInsights.splice(2, 1);
      marketplaceInsights.splice(2, 1);
    }
  }
}
