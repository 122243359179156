import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "checkinNoteType" })
export class CheckinNoteTypePipe implements PipeTransform {
  public transform(input: string, source: string): string {
    if (!input || !source) {
      return;
    }

    if (source === "comment") {
      return `checkins_comment_on_${input}`;
    }

    if (source === "reaction") {
      return `checkins_reaction_on_${input}`;
    }
  }
}
