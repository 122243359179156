import { IModule } from "angular";
import { RawParams, StateObject, StateOrName, StateService, TransitionOptions, TransitionPromise } from "@uirouter/angularjs";
import { firstValueFrom } from "rxjs";
import { UiDrawerService } from "@webapp/ui/drawer/services/drawer.service";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";

export function patchStateGo(module: IModule): void {
  module.run([
    "$state",
    "UiDrawerService",
    "UiModalService",
    function ($state: StateService, drawerService: UiDrawerService, modalService: UiModalService) {
      const originalStateGo = $state.go;

      $state.go = function (to: StateOrName, params?: RawParams, options?: TransitionOptions): TransitionPromise {
        return patchedStateGo({ originalStateGo, modalService, drawerService }, to, params, options) as TransitionPromise;
      };
    },
  ]);
}

async function patchedStateGo(
  { originalStateGo, drawerService, modalService }: { originalStateGo: typeof StateService.prototype.go; drawerService: UiDrawerService; modalService: UiModalService },
  to: StateOrName,
  params?: RawParams,
  options?: TransitionOptions
): Promise<StateObject> {
  const stateObject = await originalStateGo(to, params, options);

  if (stateObject?.data?.drawer === true) {
    const openDrawers = drawerService.getOpenDrawers();
    const drawerRef = openDrawers[openDrawers.length - 1];
    if (drawerRef) {
      await firstValueFrom(drawerRef.afterOpen);
    }
  }

  if (stateObject?.data?.modal === true) {
    const openModals = modalService.openModals;
    const modalRef = openModals[openModals.length - 1];
    if (modalRef) {
      await firstValueFrom(modalRef.afterOpen);
    }
  }

  return stateObject;
}
