import { DecimalPipe, NgFor, NgIf } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DebugElement,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzI18nPipe } from "ng-zorro-antd/i18n";
import { NzTimePickerPanelComponent } from "ng-zorro-antd/time-picker";
import { UiDateHelperService } from "@webapp/ui/date-picker/services/date-helper.service";

@Component({
  selector: "ui-time-picker-panel",
  exportAs: "uiTimePickerPanel",
  templateUrl: "time-picker-panel.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: UiTimePickerPanelComponent, multi: true }],
  standalone: true,
  imports: [NgIf, NgFor, DecimalPipe, NzI18nPipe],
})
export class UiTimePickerPanelComponent extends NzTimePickerPanelComponent {
  @Input("uiInDatePicker")
  public nzInDatePicker = false; // If inside a date-picker, more diff works need to be done
  @Input("uiAddOn")
  public nzAddOn?: TemplateRef<void>;
  @Input("uiHideDisabledOptions")
  public nzHideDisabledOptions = false;
  @Input("uiClearText")
  public nzClearText?: string;
  @Input("uiNowText")
  public nzNowText?: string;
  @Input("uiOkText")
  public nzOkText?: string;
  @Input("uiPlaceHolder")
  public nzPlaceHolder?: string | null;
  @Input("uiUse12Hours")
  @InputBoolean()
  public nzUse12Hours = false;
  @Input("uiDefaultOpenValue")
  public nzDefaultOpenValue?: Date;

  @Input()
  set uiAllowEmpty(value) {
    this.nzAllowEmpty = value;
  }

  @Input()
  set uiDisabledHours(value: undefined | (() => number[])) {
    this.nzDisabledHours = value;
  }

  @Input()
  set uiDisabledMinutes(value: undefined | ((hour: number) => number[])) {
    this.nzDisabledMinutes = value;
  }

  @Input() set uiDisabledSeconds(value: undefined | ((hour: number, minute: number) => number[])) {
    this.nzDisabledSeconds = value;
  }
  @Input()
  set uiFormat(value: string) {
    this.format = value;
  }
  @Input() set uiHourStep(value: number) {
    this.nzHourStep = value;
  }
  @Input() set uiMinuteStep(value: number) {
    this.nzMinuteStep = value;
  }
  @Input() set uiSecondStep(value: number) {
    this.nzSecondStep = value;
  }

  @Output("uiClosePanel")
  public readonly closePanel = new EventEmitter<void>();

  @ViewChild("hourListElement", { static: false })
  public hourListElement?: DebugElement;
  @ViewChild("minuteListElement", { static: false })
  public minuteListElement?: DebugElement;
  @ViewChild("secondListElement", { static: false })
  public secondListElement?: DebugElement;
  @ViewChild("use12HoursListElement", { static: false })
  public use12HoursListElement?: DebugElement;

  constructor(
    ngZone: NgZone,
    cdr: ChangeDetectorRef,
    public dateHelper: UiDateHelperService,
    elementRef: ElementRef<HTMLElement>
  ) {
    super(ngZone, cdr, dateHelper, elementRef);
  }
}
