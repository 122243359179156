import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { IIndicator } from "@gtmhub/error-handling";
import {
  BrowseConnectionsActionsParams,
  ConnectionData,
  InsightSelectorActionType,
  UpdateInsightSelectorDataActionParams,
} from "@webapp/data-story/models/insights.models";
import { IDaxQueryResult } from "@webapp/integrations/models/powerbi-models";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";
import { UI_MODAL_DATA } from "@webapp/ui/modal/modal.models";

export type ScalarDataSourceModalData = {
  connectionData: ConnectionData;
  daxQueryResult: IDaxQueryResult;
};

@Component({
  selector: "scalar-datasource-modal",
  templateUrl: "./scalar-datasource-modal.component.html",
  styleUrls: ["./scalar-datasource-modal.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScalarDataSourceModalComponent implements OnInit {
  public connectionData: ConnectionData;
  public daxQueryResult: IDaxQueryResult;
  public dataLoadingIndicator: IIndicator = { progress: true };

  constructor(
    private modalRef: UiModalRef,
    @Inject(UI_MODAL_DATA) modalData: ScalarDataSourceModalData
  ) {
    Object.assign(this, modalData);
  }

  public ngOnInit(): void {
    if (this.connectionData && this.daxQueryResult) delete this.dataLoadingIndicator;
  }

  public onConfirm(): void {
    const params: UpdateInsightSelectorDataActionParams = {
      action: InsightSelectorActionType.UPDATE_INSIGHT_SELECTOR_DATA,
      settings: [],
      insight: { ...this.daxQueryResult.insight, connection: this.connectionData },
      calculatedValue: this.daxQueryResult.scalarResult,
      fieldName: this.daxQueryResult.fieldName,
    };

    this.modalRef.destroy(params);
  }

  /**
   * Closes the modal and returns to the previous page.
   */
  public goBack(): void {
    const params: BrowseConnectionsActionsParams = {
      action: InsightSelectorActionType.BROWSE_CONNECTIONS,
    };

    this.modalRef.destroy(params);
  }
}
