import { Pipe, PipeTransform } from "@angular/core";
import dayjs from "@webapp/shared/libs/dayjs";

@Pipe({
  name: "timeAgo",
  standalone: true,
})
export class TimeAgoPipe implements PipeTransform {
  public transform(input: string, limitTo?: number): string {
    if (!input) {
      return;
    }

    const today = dayjs();
    const createdDate = dayjs(input);
    const diffInHours = today.diff(createdDate, "hours");

    if (limitTo !== undefined && (isNaN(diffInHours) || diffInHours >= limitTo)) {
      return input;
    }

    return createdDate.fromNow();
  }
}
