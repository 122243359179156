<ng-template #actionsTpl>
  <ul *ngIf="nzActions && nzActions.length > 0" [uiActions]="nzActions" ui-list-item-actions></ul>
  <ng-content select="ui-list-item-actions, [ui-list-item-actions]"></ng-content>
</ng-template>
<ng-template #contentTpl>
  <ng-content select="ui-list-item-meta, [ui-list-item-meta]"></ng-content>
  <ng-content></ng-content>
  <ng-container *ngIf="nzContent">
    <ng-container *nzStringTemplateOutlet="nzContent">{{ nzContent }}</ng-container>
  </ng-container>
</ng-template>
<ng-template #extraTpl>
  <ng-content select="ui-list-item-extra, [ui-list-item-extra]"></ng-content>
</ng-template>
<ng-template #simpleTpl>
  <ng-template [ngTemplateOutlet]="contentTpl"></ng-template>
  <ng-template [ngTemplateOutlet]="nzExtra"></ng-template>
  <ng-template [ngTemplateOutlet]="extraTpl"></ng-template>
  <ng-template [ngTemplateOutlet]="actionsTpl"></ng-template>
</ng-template>

<ng-container *ngIf="isVerticalAndExtra; else simpleTpl">
  <div class="ant-list-item-main">
    <ng-template [ngTemplateOutlet]="contentTpl"></ng-template>
    <ng-template [ngTemplateOutlet]="actionsTpl"></ng-template>
  </div>
  <ui-list-item-extra *ngIf="nzExtra">
    <ng-template [ngTemplateOutlet]="nzExtra"></ng-template>
  </ui-list-item-extra>
  <ng-template [ngTemplateOutlet]="extraTpl"></ng-template>
</ng-container>
