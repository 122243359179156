import { ComponentRef, EnvironmentInjector, Injectable } from "@angular/core";
import { EditableCustomFieldsInput, ICustomField } from "@webapp/custom-fields/models/custom-fields.models";
import { UiDatePickerComponent } from "@webapp/ui/date-picker/date-picker.component";
import { AbstractCustomFieldFactory } from "./abstract-custom-field.factory";

@Injectable()
export class DatepickerCustomFieldComponentFactory extends AbstractCustomFieldFactory<UiDatePickerComponent> {
  constructor(injector: EnvironmentInjector) {
    super(injector, UiDatePickerComponent);
  }

  protected setComponentInputs(component: ComponentRef<UiDatePickerComponent>, customField: ICustomField, editableCustomFieldInputs: EditableCustomFieldsInput): void {
    component.setInput("treatUtcAsLocal", true);
    component.setInput("uiBorderless", editableCustomFieldInputs.borderless ?? true);
    component.setInput("date", editableCustomFieldInputs.values[customField.name]);
    component.setInput("uiId", customField.name);
    component.setInput("a11yLabelledby", customField._id);
    component.setInput("a11yRequired", !editableCustomFieldInputs.disableRequired && customField.settings.validations.required);
    component.setInput("uiDisabled", editableCustomFieldInputs.disabled);
    component.setInput("readonly", editableCustomFieldInputs.readonly);
    component.setInput("uiAllowClear", true);
    component.location.nativeElement.setAttribute("data-test-id", "custom-date-picker");
    component.instance.dateChange.subscribe((updatedDate: string) => editableCustomFieldInputs.onChange({ fieldName: customField.name, customField: updatedDate }));
  }
}
