import { ChangeDetectionStrategy, Component } from "@angular/core";
import favoriteItemsEmptyUrl from "wwwroot/img/icons/favorite-items-empty.svg";

@Component({
  selector: "home-empty-sub-nav",
  templateUrl: "./home-empty-sub-nav.component.html",
  styleUrls: ["./home-empty-sub-nav.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeEmptySubNavigationComponent {
  public favoriteItemsIcon: string;

  public constructor() {
    this.favoriteItemsIcon = favoriteItemsEmptyUrl;
  }
}
