import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "suggestion-drawer-paragraph",
  templateUrl: "./suggestion-drawer-paragraph.component.html",
  styleUrls: ["./suggestion-drawer-paragraph.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionDrawerParagraphComponent {
  @Input() public text: string;
  @Input() public size: "mini" | "small" | "medium" | "large" = "medium";
  @Input() public contrast: "light" | "medium" | "dark" = "dark";
  @Input() public weight: "regular" | "semibold" = "regular";
}
