import { IPromise, IQService } from "angular";
import { IModalService, IModalServiceInstance } from "angular-ui-bootstrap";
import { getCurrentUserId } from "@gtmhub/users";
import { IAccess, IApiPermission } from "@webapp/sessions/models/sessions.model";
import { CurrentUserRepository } from "@webapp/users";
import { IAccessChecked } from "../models";

export interface ICheckAccessFactory {
  checkAccess(modifiedAccess: IAccess, originalAccess?: IAccess): IPromise<IAccessChecked>;
}

export class CheckAccess implements ICheckAccessFactory {
  public static $inject = ["$q", "$uibModal", "CurrentUserRepository"];

  constructor(
    private $q: IQService,
    private $uibModal: IModalService,
    private currentUserRepository: CurrentUserRepository
  ) {}

  public checkAccess = (modifiedAccess: IAccess, originalAccess: IAccess = null): IPromise<IAccessChecked> => {
    const deferred = this.$q.defer<IAccessChecked>();

    if (!modifiedAccess || !modifiedAccess.permissions) {
      deferred.resolve({
        access: originalAccess,
        canSave: true,
      });

      return deferred.promise;
    }

    if (modifiedAccess.permissions.length === 0) {
      deferred.resolve({
        access: originalAccess,
        canSave: true,
      });

      return deferred.promise;
    }

    if (!this.hasAtLeastOneModifyPermissions(modifiedAccess.permissions)) {
      this.openModifyPermissionsWarning();

      deferred.resolve({
        access: modifiedAccess,
        canSave: false,
      });

      return deferred.promise;
    }

    if (!originalAccess && !this.currentUserHasPermission(modifiedAccess.permissions)) {
      this.openCurrentUserWarning();

      this.currentUserModal.result.then(() => {
        deferred.resolve({
          access: modifiedAccess,
          canSave: this.canSave,
        });
      });
    }

    if (originalAccess || this.currentUserHasPermission(modifiedAccess.permissions)) {
      deferred.resolve({
        access: modifiedAccess,
        canSave: true,
      });

      return deferred.promise;
    }

    return deferred.promise;
  };

  private modifyPermissionsModal: IModalServiceInstance = null;
  private currentUserModal: IModalServiceInstance = null;
  private canSave: boolean;

  private hasAtLeastOneModifyPermissions = (permissions: IApiPermission[]): boolean => {
    for (const permission of permissions) {
      if (permission.grant.general.indexOf("modifyPermissions") !== -1) {
        return true;
      }
    }

    return false;
  };

  private currentUserHasPermission = (permissions: IApiPermission[]): boolean => {
    for (const permission of permissions) {
      if (permission.principalId === getCurrentUserId()) {
        return true;
      }
      if (this.currentUserRepository.userHasRole("admin")) {
        return true;
      }
    }

    return false;
  };

  private openModifyPermissionsWarning = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const ctrl = this;
    this.modifyPermissionsModal = this.$uibModal.open({
      template: require("../views/dialog-modify-permissions.html"),
      windowClass: "small",
      controller: [
        "$scope",
        function ($scope: { close(): void }) {
          $scope.close = (): void => {
            ctrl.modifyPermissionsModal.close();
          };
        },
      ],
    });
  };

  private openCurrentUserWarning = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const ctrl = this;
    this.currentUserModal = this.$uibModal.open({
      template: require("../views/confirm-dialog-permissions.html"),
      windowClass: "small",
      controller: [
        "$scope",
        function ($scope: { confirm(): void; close(): void }) {
          $scope.confirm = (): void => {
            ctrl.canSave = true;
            ctrl.currentUserModal.close();
          };

          $scope.close = (): void => {
            ctrl.canSave = false;
            ctrl.currentUserModal.close();
          };
        },
      ],
    });
  };

  public static factory() {
    const factory = ($q: IQService, $uibModal: IModalService, currentUserRepository: CurrentUserRepository) => new CheckAccess($q, $uibModal, currentUserRepository);
    factory.$inject = ["$q", "$uibModal", "CurrentUserRepository"];
    return factory;
  }
}
