import { StateOrName, StateService } from "@uirouter/angular";
import { ENTER, ESCAPE, TAB } from "@angular/cdk/keycodes";
import { Component, ElementRef, Input, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { UiIconDirective } from "@quantive/ui-kit/icon";
import { NzTooltipTrigger } from "ng-zorro-antd/tooltip";

@Component({
  selector: "icon-with-popover",
  templateUrl: "./icon-with-popover.component.html",
  styleUrls: ["./icon-with-popover.component.less"],
})
export class IconWithPopoverComponent {
  // Get the name for the icon in wwwroot/fe/fonts/gh-font/variables.less or print them by running scripts/gh-font-icons.js
  @Input("ghIconName") public iconName: string;

  @Input() public title?: string;
  @Input() public content?: string;
  @Input() public trigger?: NzTooltipTrigger = "hover";
  @Input() public placement?: string | string[] = "top";
  @Input() public linkText?: string;
  @Input() public linkLocation?: StateOrName;
  @Input() public linkParamKeys: string;
  @Input() public linkParamValue?: string;
  @Input() public a11yTabIndex = 0;
  @Input() public a11yLabel: string;

  @ViewChild(UiIconDirective, { static: true, read: ElementRef }) public host: ElementRef<HTMLElement>;
  @ViewChildren("contentElements") public contentElements: QueryList<ElementRef<HTMLElement>>;

  public isPopoverOpen: boolean;
  private activatedIndex = 0;

  constructor(private stateService: StateService) {}

  public linkInteract(): void {
    this.stateService.go(this.linkLocation, { [this.linkParamKeys]: this.linkParamValue });
  }

  public onIconKeydown(): void {
    this.isPopoverOpen = true;
    setTimeout(() => {
      this.contentElements.first.nativeElement.focus();
    }, 50);
  }

  private keydownEscClosePopover($event: KeyboardEvent): void {
    $event.stopPropagation();
    this.isPopoverOpen = false;
  }

  public onKeydownContent(evt: KeyboardEvent): void {
    const target = evt.target as HTMLElement;

    switch (evt.keyCode || evt.code) {
      case ESCAPE:
        this.keydownEscClosePopover(evt);
        this.host.nativeElement.focus();
        break;
      case ENTER:
        if (target.tagName === "A") {
          this.isPopoverOpen = false;
          this.linkInteract();
        }
        break;
      case TAB: {
        evt.preventDefault();
        const offset = evt.shiftKey ? -1 : 1;
        this.activatedIndex = (this.activatedIndex + this.contentElements.length + offset) % this.contentElements.length;
        this.contentElements.toArray()[this.activatedIndex].nativeElement.focus();
        break;
      }
    }
  }
}
