<button
  class="toggle-nav-btn"
  [uiTooltipTitle]="tooltipTitle"
  [id]="uiId"
  [attr.aria-label]="'toggle_subnavigation_heading' | localize: { activeScreen: activeScreen | localize }"
  (focus)="onFocus()"
  (click)="expandNav(action === 'expand')"
  ui-tooltip
  tooltipPosition="bottomLeft">
  <i class="icon" *ngIf="action === 'collapse'" [attr.data-test-id]="'sub-nav-opening-arrow'" ui-icon uiType="collapse-nav" uiTheme="outline"></i>
  <i class="icon" *ngIf="action === 'expand'" [attr.data-test-id]="'sub-nav-opening-arrow'" ui-icon uiType="parent-view" uiTheme="outline"></i>
</button>
