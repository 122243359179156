import { IHttpResponse, IHttpService, IPromise, IRequestShortcutConfig, IRootScopeService, angularFileUpload, noop } from "angular";
import { IRestLayerRequest } from "@gtmhub/core";
import { EnvironmentService } from "@gtmhub/env";
import { FileService } from "@gtmhub/files";
import { ICollection } from "@webapp/core/core.models";
import { WhiteboardsUserSettingsService } from "@webapp/whiteboards/services/whiteboards-user-settings.service";
import { WhiteboardEvents } from "./events";
import {
  IPatchUnsyncedStateChangesResponse,
  IPatchableWhiteboard,
  IRestPatchRequestParams,
  IWhiteboard,
  IWhiteboardAnalyticsParams,
  IWhiteboardWithState,
} from "./models";
import { IWhiteboardStateChange } from "./rendering/state/models";

const generalFields = "access,ownerId,name,picture,createdAt,createdById";
const whiteboardFields = "access,createdAt,createdById,currentUserAllowedActions,id,name,ownerId,picture,reactionSettings,state,currentUserAllowedActions";

export class WhiteboardsService {
  static $inject = ["Upload", "$http", "EnvironmentService", "FileService", "WhiteboardsUserSettingsService", "$rootScope"];

  private events: WhiteboardEvents;

  constructor(
    private $upload: angularFileUpload.IUploadService,
    private $http: IHttpService,
    private env: EnvironmentService,
    private fileService: FileService,
    private whiteboardsUserSettingsService: WhiteboardsUserSettingsService,
    private $rootScope: IRootScopeService
  ) {
    this.events = new WhiteboardEvents(this.$rootScope);
  }

  createNew(payload: Partial<IWhiteboardWithState>, params: IWhiteboardAnalyticsParams): IPromise<IWhiteboard> {
    const url = this.env.getWhiteboardsEndpoint();

    const gtmhubAdditionalParams: IRequestShortcutConfig = {
      params: {
        gtmhubAdditionalParams: params,
      },
    };

    return this.$http.post<IWhiteboard>(url, payload, gtmhubAdditionalParams).then((response) => response.data);
  }

  getById(id: string): IPromise<IWhiteboardWithState> {
    const url = this.env.getWhiteboardsEndpoint(id);
    const params: IRestLayerRequest = { fields: whiteboardFields };
    const config: IRequestShortcutConfig = { params };

    return this.$http.get<IWhiteboardWithState>(url, config).then((response) => {
      const whiteboard = response.data;
      for (const shape of whiteboard.state.shapes) {
        if ("fontFamily" in shape && shape.fontFamily === "Caveat") {
          shape.fontFamily = "Kalam";
        }
      }
      return whiteboard;
    });
  }

  getAll(): IPromise<IWhiteboard[]> {
    const url = this.env.getWhiteboardsEndpoint();
    const params: IRestLayerRequest = { fields: generalFields };
    const config: IRequestShortcutConfig = { params };

    return this.$http.get<ICollection<IWhiteboard>>(url, config).then((response) => response.data.items);
  }

  get(params: IRestLayerRequest): IPromise<ICollection<IWhiteboard>> {
    const url = this.env.getWhiteboardsEndpoint();
    const config: IRequestShortcutConfig = { params };

    return this.$http.get<ICollection<IWhiteboard>>(url, config).then((response) => {
      return response.data;
    });
  }

  delete(whiteboard: IWhiteboard): IPromise<unknown> {
    const url = this.env.getWhiteboardsEndpoint(whiteboard.id);

    return this.$http.delete(url).then(() => {
      this.events.broadcastWhiteboardDeleted(whiteboard);
      if (whiteboard.picture) {
        return this.fileService.deleteFile(whiteboard.picture).catch(noop);
      }

      this.whiteboardsUserSettingsService.remove(whiteboard.id);
    });
  }

  update(id: string, whiteboard: Partial<IPatchableWhiteboard>, params?: IRestPatchRequestParams): IPromise<IWhiteboardWithState> {
    const url = this.env.getWhiteboardsEndpoint(id);
    const successReponseHandler = (response: { data: IWhiteboardWithState }) => {
      if (response.data) {
        this.events.broadcastWhiteboardUpdated(id, whiteboard.name);
      }
      return response.data;
    };
    if (params && params.fields) {
      const query: IRequestShortcutConfig = { params };
      return this.$http.patch<IWhiteboardWithState>(url, whiteboard, query).then(successReponseHandler);
    } else {
      return this.$http.patch<IWhiteboardWithState>(url, whiteboard).then(successReponseHandler);
    }
  }

  applyStateChanges(id: string, whiteboardStateChanges: IWhiteboardStateChange[]): IPromise<IPatchUnsyncedStateChangesResponse> {
    const url = this.env.getWhiteboardsEndpoint(`${id}/state`);

    return this.$http.patch<IPatchUnsyncedStateChangesResponse>(url, whiteboardStateChanges).then((response) => response.data);
  }

  applyStateChangesV2(id: string, whiteboardStateChanges: IWhiteboardStateChange[]): IPromise<IPatchUnsyncedStateChangesResponse> {
    const url = this.env.getWhiteboardsV2Endpoint(`${id}`);

    return this.$http
      .patch<IPatchUnsyncedStateChangesResponse>(url, whiteboardStateChanges, { headers: { "Content-Type": "application/json-patch+json" } })
      .then((response) => response.data);
  }

  clone(id: string): IPromise<IWhiteboardWithState> {
    const url = this.env.getWhiteboardsEndpoint(`${id}/clone`);

    return this.$http.post<IWhiteboardWithState>(url, null).then((response) => response.data);
  }

  updatePicture(whiteboard: IWhiteboard, canvasDataUrl: string): IPromise<unknown> {
    const file = this.$upload.dataUrltoBlob(canvasDataUrl, `${whiteboard.id}.png`) as File;

    return this.fileService
      .uploadFile({
        targetId: whiteboard.id,
        targetType: "whiteboard",
        ref: "picture",
        file,
      })
      .then((uploadedFile) => {
        const picture = uploadedFile.previewUrl || uploadedFile.url;
        const params: IRestPatchRequestParams = {
          fields: "picture",
        };

        return this.update(whiteboard.id, { picture }, params).then(() => this.replacePicture(whiteboard, picture));
      });
  }

  private replacePicture(whiteboard: IWhiteboard, picture: string) {
    const previousPicture = whiteboard.picture;
    whiteboard.picture = picture;

    if (previousPicture) {
      this.tryDeletePreviousPicture(whiteboard.id, previousPicture);
    }
  }

  private tryDeletePreviousPicture(whiteboardId: string, picture: string) {
    this.fileService.deleteFile(picture).catch((resp: IHttpResponse<unknown>) => {
      if (resp.status !== 404) {
        console.warn("Error while deleting previous whiteboard picture", whiteboardId, picture, resp);
      }
    });
  }
}
