import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { IAssigneesStoreState } from "@gtmhub/assignees";
import { localize } from "@gtmhub/localization";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { ITeamsStoreState } from "@gtmhub/teams";
import { Goal } from "@webapp/okrs/goals/models/goal.models";
import { generateAssignees } from "@webapp/platform-intelligence/quantive-plus/utils/utils";
import { PI_CARD_ACTION_BUTTON_TOOLTIP_CUSTOM_STYLE } from "@webapp/platform-intelligence/shared/models/pi-card.models";
import { FeedbackType } from "@webapp/platform-intelligence/shared/models/pi-feedback.models";
import { cancelButton, primaryButton } from "@webapp/shared/modal/modal.utils";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { UiToastService } from "@webapp/ui/toast/services/toast.service";
import {
  CompareSuggestionsFeedbackOptions,
  FeedbackSuggestionResult,
  PiFeedbackSuggestionsComponent,
  PiFeedbackSuggestionsModalData,
} from "../../pi-feedback-suggestions/pi-feedback-suggestions.component";
import { PiFeedbackComponent } from "../../pi-feedback/pi-feedback.component";
import { IPostSqlFeedbackPayload, PIFeedbackSuggestionSubType, PIFeedbackSuggestionType, PIFeedbackType } from "../services/feedback/models";
import { PiFeedbackFacade } from "../services/feedback/pi-feedback-facade.service";
import { PiSqlFeedbackRepositoryService } from "../services/feedback/pi-sql-feedback-repository.service";
import { PiStateProcessorInstance } from "../services/state-processor/pi-state-processor.models";
import { PiStateProcessorService } from "../services/state-processor/pi-state-processor.service";

@UntilDestroy()
@Component({
  selector: "pi-action-bar-feedback-actions",
  templateUrl: "./pi-action-bar-feedback-actions.component.html",
  styleUrls: ["./pi-action-bar-feedback-actions.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiActionBarFeedbackActionsComponent {
  @Input() public instance: PiStateProcessorInstance;
  @Input() public suggestion: { id: string; text };
  @Input() public goal: Pick<Goal, "name" | "description" | "ownerIds" | "sessionId">;
  @Input() public entityId: string;
  @Input() public suggestionFeedbackType: PIFeedbackSuggestionType;
  @Input() public suggestionFeedbackSubType: PIFeedbackSuggestionSubType;

  public actionBtnBottomRightTooltipCustomStyle = PI_CARD_ACTION_BUTTON_TOOLTIP_CUSTOM_STYLE.bottomRightTooltip;

  private feedbackModalRef: UiModalRef<PiFeedbackComponent, string>;
  private compareSuggestionsModalRef: UiModalRef<PiFeedbackSuggestionsComponent, FeedbackSuggestionResult>;

  constructor(
    private modalService: UiModalService,
    private piStateProcessorService: PiStateProcessorService,
    private piFeedbackFacade: PiFeedbackFacade,
    private piSqlFeedbackRepositoryService: PiSqlFeedbackRepositoryService,
    private toastService: UiToastService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {}

  public shareFeedback = (args: { event: MouseEvent; feedbackType: FeedbackType }): void => {
    args.event.stopPropagation();
    this.openFeedbackModal(args.feedbackType);
  };

  private processFeedback(
    userFeedback: string,
    feedbackType: PIFeedbackType,
    compareSuggestionsFeedback?: CompareSuggestionsFeedbackOptions,
    newSuggestion?: {
      id: string;
      text: string;
    }
  ): void {
    this.notifyInstancesOnFeedbackSent(userFeedback, feedbackType, compareSuggestionsFeedback);
    this.displayFeedbackToastMessage();

    if (this.instance.subType === "sql") {
      this.postSqlFeedback(userFeedback, feedbackType);

      return;
    }

    this.postFeedback(userFeedback, feedbackType, compareSuggestionsFeedback, newSuggestion);
  }

  private openFeedbackModal = (feedbackType: FeedbackType): void => {
    this.ngZone.run(() => {
      this.feedbackModalRef = this.modalService.create<PiFeedbackComponent, undefined, string>({
        uiTitle: feedbackType === "positive" ? localize("your_feedback_matters") : localize("help_us_improve"),
        uiDescription: feedbackType === "positive" ? localize("sharing_helps_improve_future_suggestions") : localize("tell_us_so_we_can_improve_future_suggestions"),
        uiContent: PiFeedbackComponent,
        uiStyle: { top: "120px" },
        uiFooter: [
          primaryButton(localize("submit_feedback"), {
            onClick: (component) => component.userFeedback,
          }),
          cancelButton({ label: localize("close") }),
        ],
      });
    });

    this.subscribeToFeedbackModalClose(feedbackType);
  };

  private subscribeToFeedbackModalClose = (feedbackType: FeedbackType): void => {
    this.feedbackModalRef.afterClose.subscribe((userFeedback) => {
      if (this.instance.skipFeedbackSuggestion) {
        return this.processFeedback(userFeedback, feedbackType);
      }

      if (feedbackType === "positive" || !userFeedback) {
        return this.processFeedback(userFeedback, feedbackType);
      }

      this.openFeedbackSuggestionsModal(userFeedback);
    });
  };

  private openFeedbackSuggestionsModal = (userFeedback: string): void => {
    this.compareSuggestionsModalRef = this.modalService.create<PiFeedbackSuggestionsComponent, PiFeedbackSuggestionsModalData, FeedbackSuggestionResult>({
      uiTitle: localize("which_suggestion_is_better"),
      uiContent: PiFeedbackSuggestionsComponent,
      uiStyle: { top: "120px" },
      uiData: {
        suggestion: this.suggestion,
        instance: this.instance,
        userFeedback,
      },
      uiFooter: [
        primaryButton(localize("submit_feedback"), {
          disabled: (component) => !component.canSubmitFeedback,
          onClick: (component) => component.submitFeedback(),
        }),
        cancelButton({ label: localize("close") }),
      ],
    });

    this.cdr.detectChanges();
    this.subscribeToFeedbackSuggestionsModalClose(userFeedback);
  };

  private subscribeToFeedbackSuggestionsModalClose = (userFeedback: string): void => {
    this.compareSuggestionsModalRef.afterClose.subscribe((props) => {
      this.processFeedback(userFeedback, "negative", props?.compareSuggestionsFeedback, props?.newSuggestion);
    });
  };

  private displayFeedbackToastMessage = (): void => {
    this.toastService.info(localize("thank_you_for_your_feedback") + ".", {
      uiPlacement: "bottom",
      uiOffset: 100,
      uiCloseable: true,
      uiShouldTimeout: true,
      uiDuration: 2200,
    });
  };

  private notifyInstancesOnFeedbackSent = (userFeedback: string, feedbackType: PIFeedbackType, compareSuggestionsFeedback?: CompareSuggestionsFeedbackOptions): void => {
    const suggestion = { id: this.suggestion.id };

    const subjectPayload = {
      feedbackType,
      userFeedback,
      hasProvidedFeedback: true,
      suggestion,
      subType: this.instance.subType,
      ...(compareSuggestionsFeedback && { userSuggestionFeedback: compareSuggestionsFeedback }),
    };

    this.piStateProcessorService.notifyInstancesOnFeedbackSent(this.instance.subType, subjectPayload);
  };

  private postSqlFeedback = (userFeedback: string, feedbackType: PIFeedbackType): void => {
    const payload: IPostSqlFeedbackPayload = {
      questionId: this.suggestion.id,
      conversationId: this.entityId,
      feedback: feedbackType,
      ...(userFeedback && {
        feedbackText: userFeedback,
      }),
      parentType: "insight",
      parentId: this.instance.parentId,
    };

    this.piSqlFeedbackRepositoryService.postFeedback$({ postModel: payload }).pipe(untilDestroyed(this)).subscribe();
  };

  private postFeedback = (
    userFeedback: string,
    feedbackType: PIFeedbackType,
    compareSuggestionsFeedback?: CompareSuggestionsFeedbackOptions,
    newSuggestion?: {
      id: string;
      text: string;
    }
  ): void => {
    const state = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState & ITeamsStoreState>();
    const assignees = generateAssignees(this.goal.ownerIds, state.assignees.map, state.teams.items);

    const payload = {
      sessionId: this.goal.sessionId,
      feedback: feedbackType,
      flowId: this.instance.flowId,
      flowName: this.instance.flowName,
      suggestion: {
        suggestionId: this.suggestion.id,
        body: this.suggestion.text,
        type: this.suggestionFeedbackType,
        subType: this.suggestionFeedbackSubType,
      },
      requestBody: {
        title: this.goal.name,
        description: this.goal.description,
        nsuggestions: [],
        ...(this.instance.parentId && {
          parentId: this.instance.parentId,
          parentType: this.instance.parentType,
        }),
      },
      assignees,
      ...(userFeedback && { userFeedback: userFeedback }),
      ...(compareSuggestionsFeedback && {
        additionalFeedback: {
          original: this.suggestion.text,
          selected: compareSuggestionsFeedback,
          selectedId: newSuggestion.id,
          updated: newSuggestion.text,
        },
      }),
    };

    this.piFeedbackFacade
      .postFeedback(payload)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          // console.log("success");
        },
        error: () => {
          // console.log("error");
        },
      });
  };
}
