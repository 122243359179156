import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { storage } from "@gtmhub/core/storage";

@Injectable()
export class IsNavigationExpandedCache {
  private isNavExpanded$ = new ReplaySubject<boolean>(1);

  constructor() {
    this.setInitialValue();
  }

  public set(options: { value: boolean; persistValue: boolean }): void {
    if (options.persistValue) {
      storage.set("isNavExpanded", options.value);
    }
    this.isNavExpanded$.next(options.value);
  }

  public get$(): Observable<boolean> {
    return this.isNavExpanded$.asObservable();
  }

  public resetTempValue(): void {
    const isNavExpanded = storage.get("isNavExpanded");
    if (isNavExpanded) {
      this.isNavExpanded$.next(true);
    } else {
      this.isNavExpanded$.next(false);
    }
  }

  private setInitialValue(): void {
    const isNavExpanded = storage.get<boolean>("isNavExpanded");

    if (isNavExpanded === null) {
      storage.set("isNavExpanded", true);
      this.isNavExpanded$.next(true);
    } else {
      this.isNavExpanded$.next(isNavExpanded);
    }
  }
}
