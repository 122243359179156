import { IHttpService, IPromise } from "angular";
import { EnvironmentService } from "@gtmhub/env";
import { ICollection } from "@webapp/core/core.models";
import { Parameter } from "@webapp/data-story/models/parameters-facade.models";

export class ParameterService {
  public static $inject = ["$http", "EnvironmentService"];

  constructor(
    private $http: IHttpService,
    private env: EnvironmentService
  ) {}

  public getParameters(): IPromise<ICollection<Parameter>> {
    const url = this.env.getApiEndpoint("/dashboards/parameters");

    return this.$http.get<ICollection<Parameter>>(url).then((response) => response.data);
  }

  public defineParameter(parameter: Parameter): IPromise<Parameter> {
    const url = this.env.getApiEndpoint("/dashboards/parameters");

    return this.$http.post<Parameter>(url, parameter).then((response) => response.data);
  }

  public deleteParameter(id: string): IPromise<unknown> {
    const url = this.env.getApiEndpoint("/dashboards/parameters/" + id);

    return this.$http.delete(url).then(() => null);
  }

  public getParameter(id: string): IPromise<Parameter> {
    const url = this.env.getApiEndpoint("/dashboards/parameters/" + id);

    return this.$http.get<Parameter>(url).then((response) => response.data);
  }

  public updateParameter(parameter: Parameter): IPromise<Parameter> {
    const url = this.env.getApiEndpoint("/dashboards/parameters/" + parameter.id);

    return this.$http.put<Parameter>(url, parameter).then((response) => response.data);
  }
}
