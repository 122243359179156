import { RawParams, StateOrName, StateService, TransitionOptions } from "@uirouter/angular";
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from "@angular/core";
import { ICurrentEmployeeStoreState } from "@gtmhub/employees";
import { localize } from "@gtmhub/localization";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { Employee } from "@webapp/employees";
import dayjs from "@webapp/shared/libs/dayjs";
import { IOnboardingDelegate } from "./onboarding.models";

const navOnboardingKeys: Record<Section, string> = {
  kpis: "not-used",
  kpiComments: "not-used",
  suggestionStateIndicator: "not-used",
  okrViewsRegular: "not-used",
  okrViewsCreated: "not-used",
  okrViewsViewOnly: "not-used",
  okrViewsSharedRegular: "not-used",
  okrViewsSharedViewOnly: "not-used",
  okrViewsSaveAs: "not-used",
  piGoalContextualSuggestions: "not-used",
  piMetricContextualSuggestions: "not-used",
};
type Section = "kpis" | "kpiComments" | "suggestionStateIndicator" | OkrViewsSection | PiContextualSuggestionsSection;
export type OkrViewsSection = "okrViewsRegular" | "okrViewsCreated" | "okrViewsViewOnly" | "okrViewsSharedRegular" | "okrViewsSharedViewOnly" | "okrViewsSaveAs";
export type PiContextualSuggestionsSection = "piGoalContextualSuggestions" | "piMetricContextualSuggestions";
type SectionDetails = Array<{ heading: string; description: string }>;
const maxOnboardingCount = 3;

const onboardingReleaseDate = "2022-12-22T12:09:55.799Z";

const hasUserActiveNavControlOnboarding = (employee: Employee, section: Section): boolean => {
  const isOldEmployee = dayjs(employee.dateCreated).isSameOrBefore(onboardingReleaseDate);
  const hasSeenPointerOnboardingCount = JSON.parse(localStorage.getItem(navOnboardingKeys[section]));
  const hasPassedThreeDays = dayjs().diff(onboardingReleaseDate, "days") >= 3;

  // the onboarding appears up to three times before disabled
  if (hasSeenPointerOnboardingCount === maxOnboardingCount) {
    return false;
  }

  if (!isOldEmployee && !hasPassedThreeDays) {
    return false;
  }

  return true;
};

const setHasUserDismissedNavigationOnboarding = (section: Section): void => {
  const hasSeenPointerOnboardingCount = JSON.parse(localStorage.getItem(navOnboardingKeys[section]));
  const newValue = hasSeenPointerOnboardingCount ? hasSeenPointerOnboardingCount + 1 : 1;

  localStorage.setItem(navOnboardingKeys[section], JSON.stringify(newValue));
};

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "onboarding",
  templateUrl: "./onboarding.component.html",
  styleUrls: ["./onboarding.component.less"],
})
export class OnboardingComponent implements OnInit {
  @Input() public popoverTitle: string;
  @Input() public description: string;
  @Input() public steps: number;
  @Input() public startFromStep: number;
  @Input() public section: Section;
  @Input() public delegate?: IOnboardingDelegate;
  @Input() public customStyle?: { [klass: string]: string };
  @Input() public customStyleAppliedToStep = 1;
  @Input() public previewOverrides?: { totalSteps: number; stepsMap?: { [actualStep: number]: number } };
  @Input() public maxStepsDots = 5;
  @Input() public hidePrimaryButton? = false;
  @Input() public hideSecondaryButton? = false;
  @Input() public hideSteps? = false;
  @Input() public numericSteps? = false;

  @HostBinding("attr.section") public get hostSectionAttr(): Section {
    return this.section;
  }

  public showTour = false;
  public shouldSeeOnboarding = false;
  public step = 1;
  public contentHeading = "";
  public contentDescription = "";
  public range = [];
  public primaryCTA: string;
  public secondaryCTA: string;
  public primaryCTAHandler: () => void;
  public secondaryCTAHandler: () => void;

  constructor(private stateService: StateService) {}

  private kpis: SectionDetails = [
    {
      heading: localize("details_panel"),
      description: localize("details_panel_description"),
    },
    {
      heading: localize("filters"),
      description: localize("filters_description"),
    },
    {
      heading: localize("insights"),
      description: localize("insights_description"),
    },
    {
      heading: localize("direction"),
      description: localize("direction_description"),
    },
    {
      heading: localize("projections"),
      description: localize("projections_descriptions"),
    },
  ];

  private kpiComments: SectionDetails = [
    {
      heading: localize("kpi_comments"),
      description: localize("kpi_comments_description"),
    },
  ];

  private suggestionStateIndicator: SectionDetails = [
    {
      heading: localize("get_ai_suggestions_cap"),
      description: localize("find_custom_suggestions_cap"),
    },
  ];

  private okrViewsRegular: SectionDetails = [
    {
      heading: "",
      description: "",
    },
    {
      heading: "",
      description: "",
    },
  ];

  private okrViewsCreated: SectionDetails = [
    {
      heading: localize("favorite_cap"),
      description: localize("favorite_okr_view_description"),
    },
    {
      heading: localize("share_your_view"),
      description: localize("share_your_view_description"),
    },
    {
      heading: localize("access_your_views"),
      description: localize("access_your_views_description"),
    },
  ];

  private okrViewsViewOnly: SectionDetails = [
    {
      heading: "",
      description: "",
    },
  ];

  private okrViewsSharedRegular: SectionDetails = [
    {
      heading: localize("okr_view_cap"),
      description: localize("okr_view_item_regular_description"),
    },
    {
      heading: localize("favorite_cap"),
      description: localize("favorite_okr_view_description"),
    },
  ];

  private okrViewsSharedViewOnly: SectionDetails = [
    {
      heading: localize("okr_view_cap"),
      description: localize("okr_view_item_view_only_description"),
    },
    {
      heading: localize("favorite_cap"),
      description: localize("favorite_okr_view_description"),
    },
  ];

  private okrViewsSaveAs: SectionDetails = [
    {
      heading: localize("save_an_okr_view"),
      description: localize("save_an_okr_view_description"),
    },
  ];

  private piGoalContextualSuggestions: SectionDetails = [
    {
      heading: localize("make_objective_even_better"),
      description: localize("view_ai_suggestions_on_how_to_improve_objective"),
    },
    {
      heading: localize("suggestion_side_panel"),
      description: localize("suggestions_can_also_be_found_here_objective"),
    },
  ];

  private piMetricContextualSuggestions: SectionDetails = [
    {
      heading: localize("make_key_result_even_better"),
      description: localize("view_ai_suggestions_on_how_to_improve_key_result"),
    },
    {
      heading: localize("suggestion_side_panel"),
      description: localize("suggestions_can_also_be_found_here_key_result"),
    },
  ];

  public ngOnInit(): void {
    this.range = Array.from({ length: this.steps }, (index, value) => value + 1);
    this.updateContent();

    const employee = reduxStoreContainer.reduxStore.getState<ICurrentEmployeeStoreState>().currentEmployee.info;
    if (this.delegate) {
      this.shouldSeeOnboarding = this.delegate.shouldSeeOnboarding();
    } else {
      this.shouldSeeOnboarding = hasUserActiveNavControlOnboarding(employee, this.section);
    }
    if (this.shouldSeeOnboarding && this.startFromStep) {
      this.showTour = true;
      this.step = this.startFromStep;
      this.updateContent();
    }
    if (this.previewOverrides && !this.previewOverrides.stepsMap) {
      this.previewOverrides.stepsMap = {};
    }
  }

  public takeTheTour(): void {
    this.showTour = true;
    if (this.delegate && this.delegate.journeyStarted) {
      this.delegate.journeyStarted();
    }
  }

  public updateContent(): void {
    if (!this.section) return;

    const section: SectionDetails = this[this.section];
    const { heading, description } = section.find((val, index) => this.step === index + 1);

    this.contentHeading = heading;
    this.contentDescription = description;

    if (!this.delegate) return;

    // used to override the primary action
    // you can adjust the label + on click action
    if (this.delegate.getPrimaryActionForStep) {
      const { primaryCTA, primaryCTAHandler } = this.delegate.getPrimaryActionForStep(this.step, {
        takeTheTour: () => this.takeTheTour(),
        next: () => this.next(),
        back: () => this.back(),
        close: () => this.close(),
        gotIt: () => this.gotIt(),
      });
      this.primaryCTA = primaryCTA;
      this.primaryCTAHandler = primaryCTAHandler;
    }

    // used to override the secondary action
    // you can adjust the label + on click action
    if (this.delegate.getSecondaryActionForStep) {
      const { secondaryCTA, secondaryCTAHandler } = this.delegate.getSecondaryActionForStep(this.step, {
        takeTheTour: () => this.takeTheTour(),
        next: () => this.next(),
        back: () => this.back(),
        close: () => this.close(),
        gotIt: () => this.gotIt(),
      });
      this.secondaryCTA = secondaryCTA;
      this.secondaryCTAHandler = secondaryCTAHandler;
    }
  }

  public next(): void {
    let redirect: null | { to: StateOrName; params?: RawParams; options?: TransitionOptions } = null;
    if (this.delegate && this.delegate.getRedirectStateForStep) {
      redirect = this.delegate.getRedirectStateForStep(this.step + 1);
    }

    if (redirect) {
      this.stateService.go(redirect.to, redirect.params, redirect.options);
    } else {
      this.step += 1;
      this.updateContent();
    }
  }

  public back(): void {
    let redirect: null | { to: StateOrName; params?: RawParams; options?: TransitionOptions } = null;
    if (this.delegate && this.delegate.getRedirectStateForStep) {
      redirect = this.delegate.getRedirectStateForStep(this.step - 1);
    }
    if (redirect) {
      this.stateService.go(redirect.to, redirect.params, redirect.options);
    } else {
      this.step -= 1;
      this.updateContent();
    }
  }

  public close(): void {
    setHasUserDismissedNavigationOnboarding(this.section);
    this.shouldSeeOnboarding = false;

    if (this.delegate && this.delegate.journeyClosed) {
      this.delegate.journeyClosed();
    }
  }

  public gotIt(): void {
    localStorage.setItem(navOnboardingKeys[this.section], JSON.stringify(maxOnboardingCount));
    this.shouldSeeOnboarding = false;

    if (this.delegate && this.delegate.journeyCompleted) {
      this.delegate.journeyCompleted();
    }
  }
}
