import { localize } from "@gtmhub/localization";
import dayjs from "@webapp/shared/libs/dayjs";

export type LocalOrUtcTimeZone = "utc" | "local";

// Current date is used for testing purposes
export const dateDiffFromNow = function () {
  return function (input: string, timezone: LocalOrUtcTimeZone = "local", currentDate?: dayjs.Dayjs): string {
    const timezoneDate = timezone === "utc" ? dayjs.utc : dayjs;
    const now = currentDate ? currentDate : timezone === "utc" ? dayjs.utc() : dayjs();

    return timezoneDate(input).calendar(now, {
      lastDay: `[${localize("yesterday")}]`,
      sameDay: `[${localize("today")}]`,
      nextDay: `[${localize("tomorrow")}]`,
      lastWeek: "DD MMM YYYY",
      nextWeek: "dddd",
      sameElse: "DD MMM YYYY",
    });
  };
};

export const dateDiffFromNowConversational = function () {
  return function (input: string): string {
    const inputDate = dayjs(input).utc().clone();
    const today = dayjs.utc();

    const diffInDays = input && inputDate.diff(today, "days");
    const isMoreThan2WeeksAgo = diffInDays && diffInDays < -14;
    const isMoreThan3MonthsAgo = diffInDays && diffInDays < -84;

    if (input) {
      if (isMoreThan3MonthsAgo) {
        return "";
      }

      if (isMoreThan2WeeksAgo) {
        return inputDate.format("DD MMM YYYY");
      }
      return inputDate.from(today);
    }

    return "";
  };
};
