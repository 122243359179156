import { Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, of, switchMap } from "rxjs";
import { QuantivePlusSuggestions } from "@gtmhub/goals/models";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import {
  SuggestionDrawerAllSuggestionsTabStatePropagator,
  SuggestionDrawerEffectivenessTabStatePropagator,
  SuggestionDrawerIndicatorStatePropagator,
  SuggestionDrawerOverviewTabStatePropagator,
} from "@webapp/platform-intelligence/expandable-suggestions/suggestion-drawer/shared/utils/state-propagator";
import { IQuantivePlusSuggestionStatus } from "@webapp/platform-intelligence/quantive-plus/models";
import { PiFeatureVisibilityFacade } from "@webapp/platform-intelligence/shared/services/pi-feature-visibility-facade.service";
import { PiSuggestionSharedMetricStateService } from "@webapp/platform-intelligence/shared/services/pi-suggestion-shared-metric-state.service";
import { PiTrackingFlowNameEnum } from "@webapp/platform-intelligence/shared/utils/pi-tracking";
import { Task } from "@webapp/tasks/models/tasks.models";
import { QuantivePlusKeyResultsEffectiveness } from "../../shared/models/quantive-plus-effectiveness.models";
import { QunativePlusExplanation } from "../../shared/models/quantive-plus-explanation.models";
import { effectivenessScoreToLowToHighScale } from "../../shared/utils/quantive-plus-effectiveness-adapter.utils";
import {
  SuggestionDrawerSectionType,
  SuggestionDrawerSuggestionGroupType,
  SuggestionDrawerTabsVisibilityMapAsync,
  SuggestionDrawerTitleSuggestionParams,
} from "../shared/models/suggestion-drawer.models";

@UntilDestroy()
@Component({
  selector: "pi-update-kr-suggestion-drawer",
  templateUrl: "./update-kr-suggestion-drawer.component.html",
  styleUrls: ["./update-kr-suggestion-drawer.component.less"],
})
export class UpdateKrSuggestionDrawerComponent implements OnInit {
  @Input() public isOpen = false;
  @Input() public explanationSection: SuggestionDrawerSectionType<{ flowId: string; explanation: QunativePlusExplanation; refreshExplanation?(): void }>;
  @Input() public titleSection: SuggestionDrawerSectionType<{ title: SuggestionDrawerTitleSuggestionParams } & SuggestionDrawerSuggestionGroupType>;
  @Input() public effectivenessSection: SuggestionDrawerSectionType<{ effectiveness: QuantivePlusKeyResultsEffectiveness; refreshEffectiveness?(): void }>;
  @Input() public statePropagator: SuggestionDrawerIndicatorStatePropagator;
  @Input() public visibilityChange: (isVisible: boolean) => void;
  @Input() public flowId: string;

  public overviewTabStatePropagator = new SuggestionDrawerOverviewTabStatePropagator();
  public effectivenessTabStatePropagator = new SuggestionDrawerEffectivenessTabStatePropagator();
  public allSuggestionsTabStatePropagator = new SuggestionDrawerAllSuggestionsTabStatePropagator();

  public suggestions: QuantivePlusSuggestions;
  public taskList: Task[];
  public tasksSuggestionStatus: IQuantivePlusSuggestionStatus;
  public metric;
  public isQuantivePlusEnabled: boolean;
  public allSuggestionsflowName =
    PiTrackingFlowNameEnum.KeyResultDetailsViewAllSuggestionsSidePanel as PiTrackingFlowNameEnum.KeyResultDetailsViewAllSuggestionsSidePanel;
  public effectivenessFlowName: PiTrackingFlowNameEnum.KeyResultDetailsViewEffectivenessSidePanel = PiTrackingFlowNameEnum.KeyResultDetailsViewEffectivenessSidePanel;

  constructor(
    private piFeatureVisibilityFacade: PiFeatureVisibilityFacade,
    private piSuggestionSharedMetricStateService: PiSuggestionSharedMetricStateService,
    private featureToggleFacade: FeatureTogglesFacade
  ) {}

  public ngOnInit(): void {
    this.subscribeToStateChanges();

    this.featureToggleFacade
      .isFeatureAvailable$(FeatureFlag.QuantivePlusSuggestions)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (enabled) => (this.isQuantivePlusEnabled = enabled),
      });

    this.piSuggestionSharedMetricStateService
      .getTasks$()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (tasks) => {
          this.suggestions = tasks;
        },
      });

    this.piSuggestionSharedMetricStateService
      .getTaskList$()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (taskList) => {
          this.taskList = taskList;
        },
      });

    this.piSuggestionSharedMetricStateService
      .getTasksSuggestionStatus$()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (status) => {
          if (!status) {
            return;
          }

          this.allSuggestionsTabStatePropagator.emitStateChange({ isLoading: status.isLoading });
          this.tasksSuggestionStatus = status;
        },
      });

    this.piSuggestionSharedMetricStateService
      .getMetric$()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (metric) => {
          if (!metric) {
            return;
          }

          this.metric = metric;
        },
      });
  }

  private subscribeToStateChanges(): void {
    if (!this.statePropagator) return;

    combineLatest({
      overviewTab: this.tabsVisibility.overview.pipe(
        switchMap((isVisible) => {
          if (isVisible) {
            return this.overviewTabStatePropagator.subscribeToStateChanges$();
          }

          return of({
            isLoading: false,
          });
        })
      ),
      effectivenessTab: this.tabsVisibility.effectiveness.pipe(
        switchMap((isVisible) => {
          if (isVisible) {
            return this.effectivenessTabStatePropagator.subscribeToStateChanges$();
          }

          return of({
            isLoading: false,
            effectivenessScore: undefined,
          });
        })
      ),
    })
      .pipe(untilDestroyed(this))
      .subscribe(({ overviewTab, effectivenessTab }) => {
        const isLoading = overviewTab.isLoading || effectivenessTab.isLoading;
        const suggestionStateLevel = effectivenessScoreToLowToHighScale(effectivenessTab.effectivenessScore);
        const isSuggestionAvailable = true;
        const hasRequiredDependencies = true;

        this.statePropagator.emitStateChange({ isLoading, suggestionStateLevel, isSuggestionAvailable, hasRequiredDependencies });
      });
  }

  public tabsVisibility: SuggestionDrawerTabsVisibilityMapAsync = {
    overview: this.piFeatureVisibilityFacade.isOverviewVisible$(),
    effectiveness: this.piFeatureVisibilityFacade.isEffectivenessVisible$(),
    allSuggestions: this.piFeatureVisibilityFacade.isAllSuggestionsVisible$(),
  };
}
