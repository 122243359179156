import { UIRouterModule } from "@uirouter/angular";
import { NgModule } from "@angular/core";
import { states as ng1States } from "@gtmhub/home/home.states";

const states: unknown[] = [...ng1States];

@NgModule({
  imports: [UIRouterModule.forChild({ states })],
})
export class HomeRoutingModule {}
