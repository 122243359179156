import { IHttpProvider, jwt, module } from "angular";
import angularJwt from "angular-jwt";
import { getToken } from "@gtmhub/auth";
import employeesCore from "@gtmhub/employees/employees-core.module";
import { isLocalhost } from "@gtmhub/env";
import {
  AccountSuspendedResponseInterceptor,
  HandleUnauthorizedResponseInterceptor,
  ResponseErrorLoggerInterceptor,
  RetryFailedResponseInterceptor,
  RewriteUrlForCdnInterceptor,
  SetAccountIdRequestHeaderInterceptor,
  TrackDataInterceptor,
  UserDeactivatedInterceptor,
} from "./http-interceptor";
import { RequestOriginInterceptor } from "./request-origin.interceptor";
import { TokenRefreshInterceptor } from "./token-refresh-interceptor";

const mod = module("http", [angularJwt, employeesCore]);

mod.config([
  "jwtInterceptorProvider",
  "jwtOptionsProvider",
  function (jwtInterceptorProvider: jwt.IJwtInterceptor, jwtOptionsProvider: jwt.IJwtOptionsProvider) {
    jwtInterceptorProvider.tokenGetter = getToken;

    const whiteListedDomains: (string | RegExp)[] = [/^.+\.(?:gtmhub|quantive|quantii|okr\.tii)\.(com|online|ae)$/i];
    if (isLocalhost(window.location.hostname)) {
      whiteListedDomains.push(window.location.hostname);
    }
    jwtOptionsProvider.config({ whiteListedDomains });
  },
]);

mod.config([
  "$httpProvider",
  ($httpProvider: IHttpProvider) => {
    // Order of insertion is important:
    //   request: first -> last
    //   response: last -> first
    $httpProvider.interceptors.push(
      // request interceptors
      "jwtInterceptor",
      SetAccountIdRequestHeaderInterceptor.factory(),
      RewriteUrlForCdnInterceptor.factory(),
      TrackDataInterceptor.factory(),
      TokenRefreshInterceptor.factory(),
      RequestOriginInterceptor.factory(),

      // response interceptors
      ResponseErrorLoggerInterceptor.factory(),
      AccountSuspendedResponseInterceptor.factory(),
      HandleUnauthorizedResponseInterceptor.factory(),
      UserDeactivatedInterceptor.factory(), // Inject the user deactivated interceptor to hijack state redirection if user is deactivated BEFORE other 4xx interceptor checks.
      RetryFailedResponseInterceptor.factory() // this MUST be always last in list, it is going to be executed first in response interceptors
    );
  },
]);

export default mod.name;
