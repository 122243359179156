import { IHttpPromise, IHttpService, IPromise, IRequestShortcutConfig } from "angular";
import { encode as base64encode } from "js-base64";
import { IRestLayerRequest, RestLayerFilter } from "@gtmhub/core";
import { EnvironmentService } from "@gtmhub/env";

export interface IInsightBoardPdfQuery {
  sourceType: "dashboard" | "alignmentNestedView";
  properties: {
    insightboardId?: string;
    insightsCount?: number;
    totalGoalsCount?: number;
  };
  payload?: string;
  preferences?: IPdfPreferences;
  css?: string;
}

interface IPdfPreferences {
  margins?: number[];
  isLandscape?: boolean;
  paperSize?: string;
  scale: number;
  customPaperSize?: { width: number; height: number };
  pageRange?: { fromPage: number; toPage: number };
}

export interface IExportListToExcelQueryParams {
  listId: string;
  listType: string;
  listTitle: string;
  translatedFields?: Record<string, string>;
}

interface IExportGoalsGridToExcelData {
  hostSessionId: string;
  sort: string;
  filter: RestLayerFilter;
}

export class DocumentExporterService {
  public static $inject = ["$http", "EnvironmentService"];

  constructor(
    private $http: IHttpService,
    private env: EnvironmentService
  ) {}

  public exportPdf(query: IInsightBoardPdfQuery): IPromise<string> {
    const url = this.env.getDocumentExporterEndpoint(`/pdf`);

    return this.$http.post<string>(url, query, { responseType: "arraybuffer" }).then((response) => response.data);
  }

  public exportPdfV3(query: IInsightBoardPdfQuery): IPromise<string> {
    const url = this.env.getDocumentExporterEndpointV3("/pdf");

    return this.$http.post<string>(url, query, { responseType: "arraybuffer" }).then((response) => response.data);
  }

  public exportListToExcelWithParams(restLayerRequest: IRestLayerRequest, additionalParams: IExportListToExcelQueryParams): IHttpPromise<string> {
    const url = this.env.getDocumentExporterEndpoint(`/list/excel`);

    const config: IRequestShortcutConfig = {
      params: { ...restLayerRequest, ...additionalParams },
      responseType: "arraybuffer",
    };
    return this.$http.post<string>(url, {}, config);
  }

  public exportGoalsGridToExcel(data: IExportGoalsGridToExcelData): IHttpPromise<string> {
    const url = this.env.getDocumentExporterEndpoint(`/grid/excel`);

    return this.$http.post<string>(url, data, { responseType: "arraybuffer" });
  }

  public minifyAndEncodeHtml = (html: string): string => {
    return base64encode(
      html
        .replace(/\n\s+|\n/g, "") // removing all white spaces
        .replace(/<!--(.*?)-->/g, "") // removing all comments
        .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "") // removing all script tags and its content for security reasons);
        .replace(/<\s*div class=.gh-dropdown-wrapper[^>]+>(.*?)<\s*\/\s*div>/g, "") // removing all dropdowns with class gh-dropdown-wrapper
    );
  };
}
