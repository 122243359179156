import { IAugmentedJQuery, IDirective, IModule, IRootScopeService, auto, element, noop } from "angular";
import { IModalService, IModalServiceInstance, IModalStackService } from "angular-ui-bootstrap";
import { loadNgModule } from "@uirouter/angular";
import { StateParams, StateRegistry, StateService } from "@uirouter/angularjs";
import { UiDrawerRef } from "@webapp/ui/drawer/abstracts/drawer-ref";
import { UiDrawerOptions } from "@webapp/ui/drawer/drawer.models";
import { UiDrawerService } from "@webapp/ui/drawer/services/drawer.service";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";
import { UiModalOptions } from "@webapp/ui/modal/modal.models";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { IStateModalSettings } from "../util";

type CustomAnimation = boolean | { isStateModal: boolean };

type ICustomStateModalSettings = Omit<IStateModalSettings, "animation"> & {
  // The animation property is passed from `$uibModal.open` to `$uibModalStack.open` and we use it to pass custom data
  animation?: CustomAnimation;
};

type Modal = {
  component?: { name: string };
  content?: IAugmentedJQuery;
  animation?: CustomAnimation;
  backdrop?: boolean;
};

const SNAKE_CASE_REGEXP = /[A-Z]/g;
const toSnakeCase = (name: string): string => {
  const separator = "-";
  return name.replace(SNAKE_CASE_REGEXP, function (letter, pos) {
    return (pos ? separator : "") + letter.toLowerCase();
  });
};

function handleLazyLoad(options: ICustomStateModalSettings) {
  if (!options.resolve) {
    options.resolve = {};
  }

  Object.assign(options.resolve, {
    $lazyLoad: [
      "$state",
      "$stateRegistry",
      ($state: StateService, $stateRegistry: StateRegistry) => {
        const state = $stateRegistry.get(options.lazyLoadState);
        if (!state) {
          console.warn(`Modal is set to lazy-load state ${options.lazyLoadState}, but such state doesn't exist`);
          return;
        }

        // Angular states often use `loadChildren` instead of `lazyLoad`. Normally the conversion happens
        // when you navigate to the route, but here we need to do it manually
        if (state["loadChildren"]) {
          state.lazyLoad = loadNgModule(state["loadChildren"]);
          delete state["loadChildren"];
        }

        // if the state has already been loaded, the UIRouter will remove its `lazyLoad` property
        if (!state.lazyLoad) {
          return;
        }

        return $state.lazyLoad(options.lazyLoadState).then(noop);
      },
    ],
  });
}

const isDowngradedComponent = (name: string, $injector: auto.IInjectorService): boolean => {
  try {
    const directives = $injector.get<IDirective[]>(`${name}Directive`);
    const directive = directives[0];
    return Array.isArray(directive.require) && directive.require.includes("?^^$$angularInjector");
  } catch (e) {
    return false;
  }
};

const handleDowngradedComponentBindings = (modal: Modal, options: { isDowngradedComponent: boolean; additionalAttributes: Record<string, string> }) => {
  const content = element(document.createElement(toSnakeCase(modal.component.name)));
  const bind = options.isDowngradedComponent ? "bind-" : "";
  const on = options.isDowngradedComponent ? "on-" : "";

  content.attr({
    [`${bind}resolve`]: "$resolve",
    [`${bind}modal-instance`]: "$uibModalInstance",
    [`${on}close`]: "$close($value)",
    [`${on}dismiss`]: "$dismiss($value)",
    ...options.additionalAttributes,
  });

  modal.content = content;
  delete modal.component;
};

function handleHybridModalIndex($uibModalStack: IModalStackService, modalService: UiModalService, drawerService: UiDrawerService) {
  // bootstrap modals z-index starts from 1050 while Angular CDK overlay starts from 1100,
  // so when we have opened Angular modals, we need to compensate
  const pair = $uibModalStack.getTop();
  if (!pair) {
    return;
  }

  if (modalService.getOpenModals().length || drawerService.getOpenDrawers().length) {
    pair.value.modalScope["$$topModalIndexPrev"] = pair.value.modalScope["$$topModalIndex"];
    pair.value.modalScope["$$topModalIndex"] += 51;
  } else {
    const prev = pair.value.modalScope["$$topModalIndexPrev"];
    if (typeof prev !== "undefined") {
      pair.value.modalScope["$$topModalIndex"] = prev;
      delete pair.value.modalScope["$$topModalIndexPrev"];
    }
  }
}

function patchBackdropIndexWatcher($rootScope: IRootScopeService, backdropIndexPatched: () => number) {
  const backdropWatcherIndex = $rootScope.$$watchers.findIndex((w) => w.exp.toString().includes("backdrop"));
  const backdropWatcher = $rootScope.$$watchers[backdropWatcherIndex];
  backdropWatcher.exp = backdropIndexPatched;
  backdropWatcher.get = backdropIndexPatched;
}

type OpenedModal = { type: "ng1"; instance: IModalServiceInstance; backdrop?: boolean } | { type: "ng2"; modalRef: UiModalRef | UiDrawerRef; backdrop?: boolean };

function patchUiModalService($rootScope: IRootScopeService, openedModals: OpenedModal[]) {
  const create = UiModalService.prototype.create;
  UiModalService.prototype.create = function (config): UiModalRef {
    const modalRef = create.call(this, config);
    pushNg2OpenModal(openedModals, modalRef, config, $rootScope);
    return modalRef;
  };
}

function patchUiDrawerService($rootScope: IRootScopeService, openedModals: OpenedModal[]) {
  const create = UiDrawerService.prototype.create;
  UiDrawerService.prototype.create = function (options): UiDrawerRef {
    const drawerRef = create.call(this, options);
    pushNg2OpenModal(openedModals, drawerRef, options, $rootScope);
    return drawerRef;
  };
}

function pushNg2OpenModal(openedModals: OpenedModal[], modalRef: UiModalRef | UiDrawerRef, config: UiModalOptions | UiDrawerOptions, $rootScope: IRootScopeService) {
  openedModals.push({ type: "ng2", modalRef, backdrop: config.uiMask !== false });
  modalRef.afterClose.subscribe(() => {
    const indexToDelete = openedModals.findIndex((x) => x.type === "ng2" && x.modalRef === modalRef);
    if (indexToDelete >= 0) {
      openedModals.splice(indexToDelete, 1);
      $rootScope.$evalAsync();
    }
  });
}

function pushNg1OpenModal(openedModals: OpenedModal[], modalInstance: IModalServiceInstance, modal: Modal) {
  openedModals.push({ type: "ng1", instance: modalInstance, backdrop: modal.backdrop !== false });
  modalInstance.result.catch(noop).then(() => {
    const indexToDelete = openedModals.findIndex((x) => x.type === "ng1" && x.instance === modalInstance);
    if (indexToDelete >= 0) {
      openedModals.splice(indexToDelete, 1);
    }
  }, noop);
}

// Ng2 overlay has z-index 1100
// the formula ng1 backdrop z-index is: 1040 + (index && 1 || 0) + index*10
const MIN_NG1_BACKDROP_INDEX_TO_GO_OVER_NG2_OVERLAY = 6;

function decorateModalBackdropZIndex(mod: IModule) {
  const openedModals: OpenedModal[] = [];

  mod.run([
    "$rootScope",
    "$uibModalStack",
    "UiModalService",
    "UiDrawerService",
    function ($rootScope: IRootScopeService, $uibModalStack: IModalStackService, modalService: UiModalService, drawerService: UiDrawerService) {
      patchUiModalService($rootScope, openedModals);
      patchUiDrawerService($rootScope, openedModals);

      let isBackdropIndexPatched = false;

      const oldModalStackOpen = $uibModalStack.open;
      $uibModalStack.open = function (modalInstance: IModalServiceInstance, modal: Modal): void {
        const result = oldModalStackOpen.call(this, modalInstance, modal);
        handleHybridModalIndex($uibModalStack, modalService, drawerService);

        pushNg1OpenModal(openedModals, modalInstance, modal);

        if (!isBackdropIndexPatched) {
          drawerService.afterClose$().subscribe(() => {
            handleHybridModalIndex($uibModalStack, modalService, drawerService);
          });

          modalService.afterClose$().subscribe(() => {
            handleHybridModalIndex($uibModalStack, modalService, drawerService);
          });

          patchBackdropIndexWatcher($rootScope, function backdropIndexPatched() {
            if (!openedModals.length) {
              return -1;
            }

            const hasNg2Modal = openedModals.some((x) => x.type === "ng2");
            const topModal = openedModals[openedModals.length - 1];
            if (topModal.type === "ng2" || !hasNg2Modal) {
              return openedModals.findLastIndex((x) => x.type === "ng1" && x.backdrop);
            }

            return MIN_NG1_BACKDROP_INDEX_TO_GO_OVER_NG2_OVERLAY;
          });
          isBackdropIndexPatched = true;
        }
        return result;
      };
    },
  ]);
}

export function decorateModal(mod: IModule): void {
  decorateModalBackdropZIndex(mod);

  mod.run([
    "$uibModal",
    "$uibModalStack",
    "$injector",
    function ($uibModal: IModalService, $uibModalStack: IModalStackService, $injector: auto.IInjectorService) {
      const oldOpen = $uibModal.open;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ($uibModal as any).open = function (options: ICustomStateModalSettings): IModalServiceInstance {
        if (options.lazyLoadState) {
          handleLazyLoad(options);
        }

        if (options.isStateModal) {
          options.animation = {
            isStateModal: true,
          };
        }

        return oldOpen.apply(this, [options]);
      };

      const oldModalStackOpen = $uibModalStack.open;
      $uibModalStack.open = function (modalInstance: IModalServiceInstance, modal: Modal): void {
        if (modal.component) {
          let additionalAttributes: Record<string, string> = {};
          const isStateModal = typeof modal.animation === "object" && modal.animation?.isStateModal;
          if (isStateModal) {
            const $stateParams = $injector.get<StateParams>("$stateParams");
            additionalAttributes = Object.entries($stateParams).reduce((acc, [key, value]) => Object.assign(acc, { [toSnakeCase(key)]: value }), {});
          }

          handleDowngradedComponentBindings(modal, { isDowngradedComponent: isDowngradedComponent(modal.component.name, $injector), additionalAttributes });
        }

        if (typeof modal.animation === "object") {
          delete modal.animation;
        }

        return oldModalStackOpen.call(this, modalInstance, modal);
      };
    },
  ]);
}
