import { ChangeDetectionStrategy, Component } from "@angular/core";
import { IAiRecommendationsNotification } from "@gtmhub/notifications/models/notifications";
import { AiRecommendationRepository } from "@webapp/notifications/services/ai-recommendations/ai-recommendations-repository.service";
import { InboxTemplateComponent } from "../inbox-template.component";

@Component({
  selector: "ai-recommendations-inbox-template",
  templateUrl: "./ai-recommendations-inbox-template.component.html",
  styleUrls: ["./ai-recommendations-inbox-template.component.less", "../inbox-template.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiRecommendationsInboxTemplateComponent extends InboxTemplateComponent<IAiRecommendationsNotification> {
  constructor(private aiRecommendationsRepository: AiRecommendationRepository) {
    super();
  }

  public applyRecommendation(): void {
    const recommendationId = this.notification.notificationData.item.recommendationId;
    this.aiRecommendationsRepository
      .patch$({
        id: recommendationId,
        patchModel: { suggestionState: "accepted", actionType: "execute_recommendation" },
      })
      .subscribe();
  }

  public rejectRecommendation(): void {
    const recommendationId = this.notification.notificationData.item.recommendationId;
    this.aiRecommendationsRepository
      .patch$({
        id: recommendationId,
        patchModel: { suggestionState: "rejected", actionType: "execute_recommendation" },
      })
      .subscribe();
  }

  // Helper function to get the keys of the item changes
  public getChangeKeys(): string[] {
    const changes = this.notification.notificationData.item.itemChanges?.items || {};
    return Object.keys(changes);
  }

  // Helper function to get the old value of a specific change key, ensuring the output is always a string
  public getOldValue(changeKey: string): string {
    const change = this.notification.notificationData.item.itemChanges?.items[changeKey];
    const oldValue = change?.oldValue;

    // Convert number to string, or default to 'N/A' if null
    return oldValue !== null && oldValue !== undefined ? String(oldValue) : "N/A";
  }

  // Helper function to get the new value of a specific change key, ensuring the output is always a string
  public getNewValue(changeKey: string): string {
    const change = this.notification.notificationData.item.itemChanges?.items[changeKey];
    const newValue = change?.newValue;

    // Convert number to string, or default to 'N/A' if null
    return newValue !== null && newValue !== undefined ? String(newValue) : "N/A";
  }
}
