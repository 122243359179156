import { module } from "angular";
import env from "@gtmhub/env/env.module";
import { EmployeesRepository } from "@webapp/employees";
import { CurrentEmployeeActions } from "./redux/current-employee-actions";

const mod = module("employees-core", [env]);
mod.downgradeInjectable("EmployeesRepository", EmployeesRepository);
mod.service("CurrentEmployeeActions", CurrentEmployeeActions);

export default mod.name;
