export const checkOverflow = (id: string): boolean => {
  const element = document.getElementById(id);
  if (!element) return;
  return element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;
};

export const checkOverflowOfColumnWidth = (childId: string, parentColumn: string): boolean => {
  const column = document.querySelector(`[data-tooltip-trigger-id='${parentColumn}']`).clientWidth;
  const element = document.getElementById(childId).offsetWidth;
  return column < element;
};
