import { IRootScopeService, IScope } from "angular";
import { take } from "rxjs";
import languages from "wwwroot/json/languages.json";
import { IIndicator } from "@gtmhub/error-handling";
import { ILanguageMap, Language, setLanguageInLocalStorage } from "@gtmhub/localization";
import { SnackbarEvents } from "@gtmhub/shared/components/ui/snackbar/snackbar.events";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { IProfile } from "@webapp/user-profile/models/user-profile.models";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";
import { CurrentUserRepository } from "@webapp/users";
import { getCurrentUserId } from "../../users/current-user-storage";
import { UserService } from "../../users/user-service";

interface IUserLanguageScope extends IScope {
  userId: string;
  profile: IProfile;
  indicators: { saving?: IIndicator };
  supportedLanguages: ILanguageMap;
  initialLanguage: string;
  setLanguage(language: string): void;
}

export class UserLanguageCtrl {
  private snackbarEvents: SnackbarEvents;

  public static $inject = ["$rootScope", "$scope", "CurrentUserRepository", "UserService", "UserProfileService", "FeatureTogglesFacade"];

  constructor(
    private $rootScope: IRootScopeService,
    private $scope: IUserLanguageScope,
    private currentUserRepository: CurrentUserRepository,
    private userService: UserService,
    private profileService: UserProfileService,
    private featureToggleService: FeatureTogglesFacade
  ) {
    this.snackbarEvents = new SnackbarEvents($scope);
    this.init();
  }

  private init = () => {
    this.$scope.indicators = {};

    return this.featureToggleService
      .isFeatureAvailable$(FeatureFlag.Arabic)
      .pipe(take(1))
      .subscribe((isArabicEnabled) => {
        this.$scope.supportedLanguages = !isArabicEnabled ? languages.languageMap.filter((language) => language.id !== "ar") : languages.languageMap;

        this.$scope.setLanguage = this.setLanguage;

        this.loadUser();
        this.subscribeForProfileUpdate();
        this.setInitialLanguage(this.$scope.profile.language);
      });
  };

  private loadUser = () => {
    this.$scope.userId = getCurrentUserId();
    this.$scope.profile = this.profileService.getProfile();
  };

  private setInitialLanguage = (language: Language) => {
    this.$scope.initialLanguage = language;
  };

  private subscribeForProfileUpdate = () => {
    const removeUserProfileUpdatedListener = this.$rootScope.$on("userProfileUpdated", (evt, profile: IProfile) => {
      this.$scope.profile = profile;
    });

    this.$scope.$on("$destroy", removeUserProfileUpdatedListener);
  };

  private setLanguage = (language: Language) => {
    this.$scope.indicators.saving = { progress: true };

    const userSettings = {
      language: language,
    };

    this.userService.updateUserSettings(this.$scope.userId, userSettings).then(
      () => {
        delete this.$scope.indicators.saving;
        this.currentUserRepository.raiseProfileLanguageUpdated(language);
        setLanguageInLocalStorage(language);
        this.setInitialLanguage(userSettings.language);
        this.snackbarEvents.broadcastShowSnackbar("language_updated");
        location.reload();
      },
      (error) => (this.$scope.indicators.saving = { error })
    );
  };
}
