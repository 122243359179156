import { TargetState } from "@uirouter/angular";
import type { SpanOptions as APMSpanOptions, Span } from "@elastic/apm-rum";
import { afterFrame } from "@elastic/apm-rum-core";

export const DATA_LOAD_SPAN_TYPE = "data-load";
export const ROUTE_CHANGE_BLOCKING_SPAN_TYPE = "route-change-base";
export const RENDER_SPAN_TYPE = "render";
export const PENDING_RENDER_SPAN_TYPE = "pending-render";
export const DUPLICATED_SPAN_TYPE = "duplicated-span";
export const PENDING_DATA_LOAD_SPAN_TYPE = "pending-data-load";

export const ROUTE_CHANGE_TRANSACTION_TYPE = "route-change";
export const PAGE_LOAD_TRANSACTION_TYPE = "page-load";
export const ANGULAR_PAGE_LOAD_TRANSACTION_TYPE = "angular-page-load";
export const ANGULAR_ROUTE_CHANGE_TRANSACTION_TYPE = "angular-route-change";
export const BLOCKING_SPAN_NAME = "blocking-span";
export const TIMER_END_OFFSET = "timer-end-offset";

export const TRACE_ELEMENT_RENDERED = "TRACE_ELEMENT_RENDERED";
export const TRACE_ELEMENT_START_RENDER = "TRACE_ELEMENT_START_RENDER";
export const RENDER_ENDED_MARK_NAME = "PageLoadTimeCalculated";

export const BLACKLIST = {
  "apiffe.quantive.com": true,
  "apiff.quantive.com": true,
  "apipa.quantive.com": true,
};

export const SHORT_OFFSET = 25000;
const LONG_OFFSET = 60000;

// an interface for the Span class that's used by APM RUM and for some reason is not exported
interface ApmSpan extends Span {
  sync?: boolean;
}

export interface CoreApmSpan extends ApmSpan {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _start: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _end: number;
  subtype: string;
  context?: {
    destination?: {
      address: string;
    };
  };
}

// an interface for the Transaction class that's used by APM RUM and for some reason is not exported
export interface ApmTransaction extends Span {
  startSpan(name?: string | null, type?: string | null, options?: SpanOptions): ApmSpan | undefined;
  mark(key: string): void;
}

export interface CoreApmTransaction extends ApmTransaction {
  id: string;
  spans: ApmSpan[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _start: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _end: number;
  ended: boolean;
}

interface SpanOptions extends APMSpanOptions {
  labels?: Labels;
}

class ApmSpanProxy {
  private apmSpan: ApmSpan;

  public static newInstance(apmSpan: ApmSpan): ApmSpanProxy {
    const span = new ApmSpanProxy();
    span.apmSpan = apmSpan;
    return span;
  }

  public end(): void {
    const now = performance.now();
    const spanStart = (this.apmSpan as CoreApmSpan)._start;
    if (now - spanStart < 1) {
      this.apmSpan.addContext({ immediate: true });
      this.apmSpan.end(now + 1);
    } else {
      this.apmSpan.end();
    }
  }

  public get type(): string {
    return this.apmSpan.type;
  }

  public set type(type: string) {
    this.apmSpan.type = type;
  }
}

export class ApmTransactionProxy {
  private transaction: CoreApmTransaction;
  private spans: { string?: ApmSpanProxy } = {};
  private hasCustomSpans = false;
  private notFoundSpansTimeoutId: number;
  private useLongOffset: boolean;

  public static newInstance(apmTransaction: ApmTransaction): ApmTransactionProxy {
    const transaction = new ApmTransactionProxy();
    transaction.transaction = apmTransaction as unknown as CoreApmTransaction;
    return transaction;
  }

  public get type(): string {
    return this.transaction.type;
  }

  public get name(): string {
    return this.transaction.name;
  }

  public get id(): string {
    return this.transaction.id;
  }

  public get ended(): boolean {
    return this.transaction.ended;
  }

  public get customSpans(): boolean {
    return this.hasCustomSpans;
  }

  public set customSpans(value: boolean) {
    this.hasCustomSpans = value;
  }

  public mark(key: string): void {
    this.transaction.mark(key);
  }

  public addLabels(labels: Labels): void {
    this.transaction.addLabels(labels);
  }

  public end(): void {
    const blockingSpan: ApmSpanProxy = this.spans[BLOCKING_SPAN_NAME];
    if (blockingSpan) {
      blockingSpan.end();
    }

    afterFrame(() => {
      this.transaction.end();
    });
  }

  public endSpanByName(name: string, type: string): void {
    const span = this.spans[name];
    if (!span) {
      return;
    }

    span.type = type;
    span.end();

    delete this.spans[name];
    if (type === TIMER_END_OFFSET) {
      this.useLongOffset = false;
    }

    if (this.useLongOffset) return;

    this.resetTransactionEndTimer(SHORT_OFFSET);
  }

  public startSpanWithName(name: string, type: string, options?: SpanOptions): void {
    if (type === TIMER_END_OFFSET && this.useLongOffset) {
      console.warn(`You can start only one span of type ${TIMER_END_OFFSET}, span name: ${name}`);
      return;
    }

    const span = this.transaction.startSpan(name, type, options);

    if (!span) {
      console.warn(`Can't open span ${name}. Transaction with name ~${this.transaction.name}~ is already closed. TransactionId: ~${this.transaction.id}~ `);
      return;
    }

    if (options && options.labels) {
      span.addLabels(options.labels);
    }

    if (this.spans[name]) {
      this.spans[name].type = DUPLICATED_SPAN_TYPE;
    }

    this.spans[name] = ApmSpanProxy.newInstance(span);

    if (type === TIMER_END_OFFSET) {
      this.useLongOffset = true;
      this.resetTransactionEndTimer(LONG_OFFSET);
      return;
    }

    if (this.useLongOffset) return;

    this.resetTransactionEndTimer(SHORT_OFFSET);
  }

  public resetTransactionEndTimer(offset: number): void {
    this.clearNotFoundTimeout();
    this.notFoundSpansTimeoutId = window.setTimeoutOutsideAngular(() => {
      if (this.transaction) this.end();
    }, offset);
  }

  private clearNotFoundTimeout(): void {
    if (this.notFoundSpansTimeoutId) window.clearTimeoutOutsideAngular(this.notFoundSpansTimeoutId);
  }
}

export interface IApmService {
  captureError(error: string | Error): void;
  setUserContext(context: UserObject): void;
  addLabelsToCurrentTransactions(labels: Labels): void;
  addMarkToCurrentTransactions(key: string): void;
  handleTransitionStart(targetState: TargetState): void;
  starTimerEndOffsetSpan(name: string): void;
  endTimerEndOffsetSpan(name: string): void;
  startDataLoadSpan(name: string): void;
  startRenderSpan(name: string): void;
  endDataLoadSpan(name: string): void;
  endRenderSpan(name: string): void;
}
