import { IHttpInterceptor, IHttpInterceptorFactory, IPromise, IRequestConfig, auto } from "angular";
import { EnvironmentService } from "@gtmhub/env";
import { ITracingAction, ITracingScreen } from "@webapp/core/tracing/models/tracing.models";
import { TracingService } from "./tracing.service";
import { Ng1Zone } from "./zone";

export class TracingInterceptor implements IHttpInterceptor {
  constructor(private $injector: auto.IInjectorService) {}

  public request = (config: IRequestConfig): IRequestConfig | IPromise<IRequestConfig> => {
    const envService = this.$injector.get<EnvironmentService>("EnvironmentService");
    if (envService.isApiRequest(config.url)) {
      const tracingService = this.$injector.get<TracingService>("TracingService");
      const zoneScreen = Ng1Zone.current.get<ITracingScreen>("screen");
      const action = Ng1Zone.current.get<ITracingAction>("action");
      tracingService.setTracingHeaders(config.headers as Record<string, string>, { screen: zoneScreen, action });
    }

    return config;
  };

  public static factory(): IHttpInterceptorFactory {
    const factory = ($injector: auto.IInjectorService) => new TracingInterceptor($injector);
    factory.$inject = ["$injector"];
    return factory;
  }
}
