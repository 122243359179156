<div
  class="sp-py-6 sp-px-10 card-wrapper"
  [ngClass]="['border-' + borderStyle, borderColorOnHover + '-border-on-hover', !isToggleEnabled ? 'disabled' : '']"
  [attr.data-test-id]="'pi-card-section-is-clickable-' + isToggleEnabled"
  (mouseenter)="isHovered = true"
  (mouseleave)="isHovered = false"
  (click)="toggleCard()"
  ui-row>
  <ng-content select="[pi-subcard]"></ng-content>

  <!--
    Why using [hidden] instead of *ngIf:
    When the card is collapsed and the click which triggered the collapse was somewhere on the action bar, the mouseleave event is not being caught by
    the pi-card-wrapper component and thus "isHovered" remains true even though the mouse is no longer over the card. This behaviour is observed
    only if the action bar component is destroyed (*ngIf), while all hover effects work properly if the action bar is just [hidden].
  -->
  <div *ngIf="isReadonly">
    <ng-content select="[pi-feedback-card-content]"></ng-content>
  </div>

  <div class="action-bar-wrapper" [hidden]="!isSelected || isAskAiVisible || !isToggleEnabled">
    <ng-content select="pi-card-action-bar"></ng-content>
  </div>

  <div class="ask-ai-to" [hidden]="!isAskAiVisible">
    <ng-content select="[ask-ai-to]"></ng-content>
  </div>
</div>
