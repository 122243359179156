import { OkrMethodologySettings } from "@gtmhub/goals/models";
import { localize } from "@gtmhub/localization";
import { IViewHistoryGoal, IViewHistoryMetric } from "@gtmhub/view-history";
import { IColoringSetup } from "@webapp/configuration/models/configuration.model";
import { IRelatedItemsSelectorNode } from "@webapp/links/models/related-items.models";
import { ItemType } from "@webapp/okrs/alignment-tree/models";
import { IOkrClosedStatus } from "../goals/models/goal-status.models";
import { Metric, MetricTargetOperator, MetricTargetOperatorEnum } from "../metrics/models/metric.models";
import { ParentSelectorTrackingPayloadMeta } from "../models/parent-selector.models";

export const getProgressDescription = (colorSetup: IColoringSetup, attainment: number, color: string): string => {
  const localizedProgressString = localize("progress_cap");
  if (colorSetup?.strategy !== "colors") {
    return `${localizedProgressString} ${Math.round(attainment * 100)}%`;
  } else {
    const colorRangesSorted = colorSetup.ranges.sort((a, b) => a.lower - b.lower);
    const upperRange = colorRangesSorted[colorRangesSorted.length - 1];
    const goalRange = colorRangesSorted.find((x) => x.color === color);

    if (goalRange) {
      return `${localizedProgressString} ${localize("lower_than")} ${goalRange.lower}`;
    } else {
      return `${localizedProgressString} ${localize("higher_than")} ${upperRange.lower}`;
    }
  }
};

export const isOkrAbandoned = (closedStatus: Pick<IOkrClosedStatus, "status">): boolean => {
  return closedStatus && closedStatus.status === "abandoned";
};

export const convertObjKeysFromCamelcaseToUnderscoreCase = (payload: ParentSelectorTrackingPayloadMeta): { [key: string]: string | number } => {
  return Object.keys(payload).reduce((convertedKeys, key) => {
    const underscoredKey = key
      .split(/\.?(?=[A-Z])/)
      .join("_")
      .toLowerCase();
    return { ...convertedKeys, ...(payload[key] && { [underscoredKey]: payload[key] }) };
  }, {});
};

export function canCascadeMetric(metric: Metric, { isGoalAbandoned }: { isGoalAbandoned: boolean }): boolean {
  if (!metric) {
    return false;
  }

  const allowedMetricTargetOperators: MetricTargetOperator[] = [MetricTargetOperatorEnum.AT_LEAST, MetricTargetOperatorEnum.AT_MOST];
  const isTargetOperatorValid = allowedMetricTargetOperators.includes(metric.targetOperator);

  return !isGoalAbandoned && isTargetOperatorValid && metric.manualType !== "boolean";
}

export function getRecentMetricsGoalIdsNotFetchedWithRecentGoals(recentMetrics: IViewHistoryMetric[], recentGoals: IViewHistoryGoal[] = []): string[] {
  return recentMetrics.reduce((goalIds, recentMetric) => {
    const goalOfRecentMetric = recentGoals.find((goal) => goal.itemId === recentMetric.metric.goalId);

    if (!goalOfRecentMetric) {
      goalIds.push(recentMetric.metric.goalId);
    }

    return goalIds;
  }, []);
}

export function sortMetricsForEveryGoalByMostRecent(goals: IRelatedItemsSelectorNode[]): void {
  goals.forEach((goal) => {
    goal.children.sort((a, b) => {
      if (a.isRecentItem && b.isRecentItem) {
        return new Date(b.lastVisited).getTime() - new Date(a.lastVisited).getTime();
      }

      if (a.isRecentItem && !b.isRecentItem) {
        return -1;
      }

      if (!a.isRecentItem && b.isRecentItem) {
        return 1;
      }

      return 1;
    });
  });
}

export const metricProperties = [
  "orderId",
  "actual",
  "metricHistory",
  "goalClosedStatus",
  "goalAttainment",
  "assignees",
  "numberOfOwners",
  "progressStatus",
  "goalOwnerId",
  "assignee",
  "goalId",
  "insightName",
  "createdById",
  "obfuscated",
  "dynamic",
  "dateCreated",
  "ownerIds",
  "tasksCount",
  "access",
  "id",
  "targetOperator",
  "format",
  "cascadeType",
  "sessionId",
  "accountId",
  "target",
  "dueDate",
  "private",
  "fieldName",
  "reminderSchedule",
  "softDueDate",
  "goalOwnerIds",
  "url",
  "name",
  "settings",
  "trend",
  "ownerId",
  "critical",
  "goalDescription",
  "goalName",
  "initialValue",
  "attainment",
  "manualType",
  "description",
  "confidence",
  "customFields",
  "lastCheckInDate",
  "links",
  "sourceMetricId",
  "targetMetricIds",
  "currentUserAllowedActions",
  "milestones",
  "tags",
];

export const getDisplayableOwnerIds = (item: { ownerIds?: string[] }, type: ItemType, methodologySettings: OkrMethodologySettings): string[] => {
  const areMultipleOwnersDisabled = type === "metric" ? methodologySettings.areMultipleKrOwnersDisabled : methodologySettings.areMultipleObjectiveOwnersDisabled;
  return (item.ownerIds || []).slice(0, areMultipleOwnersDisabled ? 1 : undefined);
};
