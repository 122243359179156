import { localize } from "@gtmhub/localization";
import { ISessionsStoreState } from "@gtmhub/sessions/redux/session-reducer";
import { INgRedux } from "@gtmhub/state-management";
import { ISessionTag } from "@webapp/custom-fields/models/custom-fields.models";
import { Session } from "@webapp/sessions/models/sessions.model";
import dayjs from "@webapp/shared/libs/dayjs";

export const byEndDescAndStartAsc = (a: Session, b: Session): -1 | 0 | 1 => {
  // "-end,start"
  const startA = dayjs(a.start);
  const startB = dayjs(b.start);
  const endA = dayjs(a.end);
  const endB = dayjs(b.end);

  if (endA.isSame(endB)) {
    return startA.isBefore(startB) ? -1 : startA.isAfter(startB) ? 1 : 0;
  } else {
    return endB.isBefore(endA) ? -1 : 1;
  }
};

const sessionsFilterByIdAndSortByEndAndStart = (sessions: Session[]) => sessions.sort(byEndDescAndStartAsc);

export class QuerySessionsService {
  static $inject = ["$ngRedux"];

  constructor(private $ngRedux: INgRedux) {}

  private get sessions() {
    return this.$ngRedux.getState<ISessionsStoreState>().sessions.items;
  }

  private get sessionsMap() {
    return this.$ngRedux.getState<ISessionsStoreState>().sessions.map;
  }

  querySessionsByTitle(input: string): Session[] {
    const sessionsCopy = sessionsFilterByIdAndSortByEndAndStart(this.sessions);
    const filteredSessions: Session[] = [];
    for (let i = 0; i < sessionsCopy.length; i++) {
      const session = sessionsCopy[i];
      const titleMatched = input ? session.title.toLowerCase().includes(input.toLowerCase()) : true;

      if (titleMatched) {
        filteredSessions.push(session);
      }
    }
    return filteredSessions;
  }

  querySessions(input: string): ISessionTag[] {
    return this.getFilteredSessionsTags(input);
  }

  getFilteredSessionsTags(sessionTitle: string): ISessionTag[] {
    const sessionsCopy = sessionsFilterByIdAndSortByEndAndStart(this.sessions);
    const filteredSessions: ISessionTag[] = [];

    for (let i = 0; i < sessionsCopy.length; i++) {
      const session = sessionsCopy[i];
      const titleMatched = sessionTitle ? session.title.toLowerCase().includes(sessionTitle.toLowerCase()) : true;

      if (titleMatched) {
        filteredSessions.push(this.createSessionTag(session.id));
      }
    }

    return filteredSessions;
  }

  public createSessionTag(sessionId: string): ISessionTag {
    const session = this.sessionsMap[sessionId];
    const id = sessionId;
    return session
      ? {
          id,
          name: session.title,
          color: session.color,
        }
      : this.missingTag(id, "missing_session");
  }

  private missingTag(id: string, nameKey: string): ISessionTag {
    return {
      id,
      name: localize(nameKey),
      color: "#0055cc",
    };
  }
}
