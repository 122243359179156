import { TransitionService, UIRouterModule } from "@uirouter/angular";
import { NgModule } from "@angular/core";
import { UiDrawerModule } from "@webapp/ui/drawer/drawer.module";
import { AbstractDrawerViewContainerComponent } from "./utils/overlay-components/drawer/abstract-drawer-view-container.component";
import { AbstractModalViewContainerComponent } from "./utils/overlay-components/modal/abstract-modal-view-container.component";
import { overlayStateManager } from "./utils/overlay-components/overlay-component-state.manager";

@NgModule({
  imports: [UIRouterModule, UiDrawerModule],
  declarations: [AbstractDrawerViewContainerComponent, AbstractModalViewContainerComponent],
})
export class CoreRoutingModule {
  constructor(private transitionService: TransitionService) {
    this.executeOverlayComponentOpenerQueueOnTransitionSuccess();
  }

  private executeOverlayComponentOpenerQueueOnTransitionSuccess(): void {
    overlayStateManager.openerQueue.length = 0;

    this.transitionService.onSuccess({}, () => {
      if (overlayStateManager.openerQueue.length) {
        const queuePromise = overlayStateManager.openerQueue
          .slice(1)
          .reduce<Promise<unknown>>((promise, opener) => promise.then(opener), overlayStateManager.openerQueue[0]());
        queuePromise.catch((err) => console.error(err));
        overlayStateManager.openerQueue.length = 0;
      }
    });
  }
}
