import { Injectable } from "@angular/core";
import equal from "fast-deep-equal";
import dayjs from "@webapp/shared/libs/dayjs";
import { CurrentUserRepository } from "@webapp/users";
import { WhiteboardUserSettings, WhiteboardsUserSettings } from "../models/whiteboards-user-settings.models";

@Injectable({ providedIn: "root" })
export class WhiteboardsUserSettingsService {
  constructor(private currentUserRepository: CurrentUserRepository) {}

  public getOpened(): WhiteboardUserSettings[] {
    return this.userSettings().opened;
  }

  public update(whiteboardId: string, partialSettings: Partial<WhiteboardUserSettings>): WhiteboardUserSettings {
    const userSettings = this.userSettings();
    const existingWhiteboardSettings = userSettings.opened.find((w) => w.id === whiteboardId);
    if (!existingWhiteboardSettings) {
      const newWhiteboardSettings: WhiteboardUserSettings = {
        id: whiteboardId,
        openedAt: dayjs.utc().format(),
        zoom: 100,
        ...partialSettings,
      };
      userSettings.opened.push(newWhiteboardSettings);
      this.currentUserRepository.setUserSetting({ whiteboards: userSettings });
      return newWhiteboardSettings;
    } else {
      if (!equal({ ...existingWhiteboardSettings, ...partialSettings }, existingWhiteboardSettings)) {
        const newWhiteboardSettings = Object.assign(existingWhiteboardSettings, partialSettings);
        this.currentUserRepository.setUserSetting({ whiteboards: userSettings });
        return newWhiteboardSettings;
      }
      return existingWhiteboardSettings;
    }
  }

  public remove(whiteboardId: string): void {
    const userSettings = this.userSettings();
    const openedWhiteboardsCount = userSettings.opened.length;

    userSettings.opened = userSettings.opened.filter((w) => w.id !== whiteboardId);

    if (userSettings.opened.length !== openedWhiteboardsCount) {
      this.currentUserRepository.setUserSetting({ whiteboards: userSettings });
    }
  }

  private userSettings(): WhiteboardsUserSettings {
    return this.currentUserRepository.getUserSetting<WhiteboardsUserSettings>("whiteboards") || { opened: [], hasUserUnderstoodHowToPaste: false };
  }
}
