import { Injectable } from "@angular/core";
import { Subject, firstValueFrom } from "rxjs";
import { localize } from "@gtmhub/localization";
import { MetricService } from "@gtmhub/okrs/metrics/services/metric.service";
import { IInsightUsage } from "@webapp/data-story/models/insights.models";
import { InsightsFacade } from "@webapp/data-story/services/insights-facade/insights-facade.service";
import { KpisFacade } from "@webapp/kpis/services/kpis-facade.service";
import { cancelButton, primaryButton } from "@webapp/shared/modal/modal.utils";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { EditInsightModalComponent, EditInsightModalData } from "./modal/edit-insight-modal.component";
import { IEditInsightModalOptions } from "./models/edit-insight-models";

@Injectable({
  providedIn: "root",
})
export class EditInsightService {
  public newInsightName: string;
  public goalId: string;

  public constructor(
    private modalService: UiModalService,
    private metricService: MetricService,
    private kpisFacade: KpisFacade,
    private insightsFacade: InsightsFacade
  ) {}

  public setNewInsightName(newName: string): void {
    this.newInsightName = newName;
  }

  public getNewInsightName(): string {
    return this.newInsightName;
  }
  public openEditInsightModal(options: { relatedItems: IInsightUsage; insightTitle: string; saveAndLeave: boolean }): Subject<IEditInsightModalOptions> {
    const editModal = this.modalService.create<EditInsightModalComponent, EditInsightModalData>({
      uiContent: EditInsightModalComponent,
      uiClassName: "edit-insight-modal",
      uiData: {
        relatedKPIs: options.relatedItems.kpis,
        relatedKRs: options.relatedItems.metrics,
        relatedInsightboards: options.relatedItems.insightboards,
        relatedItemsCount: options.relatedItems.totalCount,
        insightTitle: options.insightTitle,
        itemsWithoutPermissionsCount: options.relatedItems.itemsWithoutPermissions,
      },
      uiWidth: 556,
      uiStyle: { top: "30px" },
      uiFooter: [
        primaryButton(options.saveAndLeave ? localize("save_and_leave") : localize("save"), {
          onClick(component) {
            return { saveAsNewInsight: component.editInsightMethod === "new", newInsightTitle: component.newInsightTitle };
          },
        }),
        cancelButton(),
      ],
    });

    return editModal.afterClose;
  }

  public getRelatedItems(id: string, insightName: string): Promise<IInsightUsage> {
    let allItems: IInsightUsage;

    return Promise.all([
      this.metricService.getMetricsV2({ filter: { insightName: insightName }, fields: "id,name,ownerIds" }),
      firstValueFrom(this.kpisFacade.getAll$({ filter: { "insightLink.insightName": insightName }, fields: ["_id", "name", "ownerIds"] })),
      firstValueFrom(this.insightsFacade.getInsightUsages$(id, "boards")),
      firstValueFrom(this.insightsFacade.getInsightUsages$(id, "count")),
    ]).then(([metrics, kpis, insightboards, totalUsageCount]) => {
      const totalCountWithPermissions = insightboards.length + kpis.totalCount + metrics.totalCount;
      allItems = {
        insightboards,
        kpis: kpis.items,
        metrics: metrics.items,
        totalCount: totalUsageCount.usageCount,
        itemsWithoutPermissions: totalUsageCount.usageCount - totalCountWithPermissions,
      };

      return allItems;
    });
  }

  public hasUsageInMultipleItems(relatedItems: IInsightUsage, metricId: string, kpiId: string, dashboardId: string): boolean {
    if (!relatedItems || relatedItems.totalCount < 1) return false;
    if (relatedItems.totalCount > 1) return true;

    if (metricId && relatedItems.metrics.some((metric) => metric.id === metricId)) {
      return false;
    }

    if (kpiId && relatedItems.kpis.some((kpi) => kpi.id === kpiId)) {
      return false;
    }

    if (dashboardId && relatedItems.insightboards.some((insightboard) => insightboard.id === dashboardId)) {
      return false;
    }

    if (!metricId && !kpiId && !dashboardId) {
      // Return true if no related item id presented in stateParams
      // Show modal even if has 1 related item if come from edit insight
      return true;
    }
    return false;
  }
}
