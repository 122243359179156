import { module } from "angular";
import upgrade from "@gtmhub/core/upgrade/module";
import { CommentComponent, CommentsComponent, CommentsOrderingComponent, ReactionsComponent, ShowCommentsButtonComponent } from "@webapp/comments";

const mod = module("comments", [upgrade]);

mod.downgradeComponent("showCommentsButton", { component: ShowCommentsButtonComponent, propagateDigest: false });
mod.downgradeComponent("reactions", { component: ReactionsComponent, propagateDigest: false });
mod.downgradeComponent("comments", { component: CommentsComponent, propagateDigest: false });
mod.downgradeComponent("comment", { component: CommentComponent, propagateDigest: false });
mod.downgradeComponent("commentsOrdering", { component: CommentsOrderingComponent, propagateDigest: false });

export default mod.name;
