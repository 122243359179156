import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { calculateSuggestionCardActionButtonsContainerWidth } from "../../../quantive-plus/utils/utils";
import { PiCardBorderStyle } from "../../models/pi-card.models";

@Component({
  selector: "pi-error-card",
  templateUrl: "./pi-error-card.component.html",
  styleUrls: ["./pi-error-card.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiErrorCardComponent implements OnChanges {
  public showActionButtons: boolean;
  public areActionButtonsAvailable = true;
  public actionButtonsContainerWidth: number;

  @Input()
  public borderStyle: PiCardBorderStyle = "solid";
  @Input()
  public isRefreshBtnAvailable = true;
  @Input()
  public isRemoveBtnAvailable = true;
  @Input()
  public refreshBtnTooltipTitle: string;
  @Input()
  public removeBtnTooltipTitle: string;
  @Input()
  public errorMessage: string;

  @Output()
  public readonly errorRefresh = new EventEmitter();
  @Output()
  public readonly errorRemove = new EventEmitter();

  public ngOnChanges(changes: SimpleChanges): void {
    this.isRefreshBtnAvailable = changes.isRefreshBtnAvailable ? changes.isRefreshBtnAvailable.currentValue : this.isRefreshBtnAvailable;
    this.isRemoveBtnAvailable = changes.isRemoveBtnAvailable ? changes.isRemoveBtnAvailable.currentValue : this.isRemoveBtnAvailable;

    const availableActionButtons = [this.isRefreshBtnAvailable, this.isRemoveBtnAvailable].filter(Boolean).length;

    if (!availableActionButtons) {
      this.areActionButtonsAvailable = false;

      return;
    }

    this.actionButtonsContainerWidth = calculateSuggestionCardActionButtonsContainerWidth({ availableActionButtons });
  }

  public onRefreshErrorClicked = (): void => {
    this.errorRefresh.emit();
  };

  public onRemoveErrorClicked = (): void => {
    this.errorRemove.emit();
  };
}
