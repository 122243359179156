import { thousandsFormatterRegExp } from "@webapp/shared/utils/regex";

export const defaultFormatter = (value: number): number => value;

export const thousandsFormatter = (value: string | number | undefined): string => {
  if (value === undefined || value === null) {
    return;
  }

  const strValue = `${value}`;
  const [integerPart, decimalPart] = strValue.split(".");

  if (integerPart.length < 4) {
    return strValue;
  }

  const formattedIntegerPart = integerPart.replace(thousandsFormatterRegExp, ",");

  if (decimalPart) {
    return `${formattedIntegerPart}.${decimalPart}`;
  } else if (strValue.endsWith(".")) {
    return `${formattedIntegerPart}.`;
  } else {
    return formattedIntegerPart;
  }
};
