import { IRootScopeService, IScope } from "angular";
import { List, PatchList } from "@webapp/lists/models";

export enum ListEventType {
  LIST_UPDATED = "LIST_UPDATED",
  LIST_DELETED = "LIST_DELETED",
  LIST_VISITED = "LIST_VISITED",
}

export class ListEvents {
  private $rootScope: IRootScopeService;

  constructor(private $scope: IScope) {
    this.$rootScope = this.$scope.$root;
  }

  public broadcastListUpdated(id: string, list: PatchList): void {
    this.$rootScope.$broadcast(ListEventType.LIST_UPDATED, {
      list: { id, ...list },
    });
  }

  public broadcastListDeleted(id: string): void {
    this.$rootScope.$broadcast(ListEventType.LIST_DELETED, {
      id,
    });
  }

  public broadcastListVisited(list: List) {
    this.$rootScope.$broadcast(ListEventType.LIST_VISITED, {
      list,
    });
  }
}
