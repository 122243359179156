<ui-card [uiBordered]="false" [uiHeadBorderless]="true" [uiRounded]="true" [uiExtra]="extraHeaderContentTemplate" uiTitle=" ">
  <ng-template #extraHeaderContentTemplate>
    <widget-actions [widgetId]="widgetId" [canRemove]="true" (remove)="remove()" />
  </ng-template>

  <img src="/dist/img/alignment_preview.png" alt="" />
  <div class="title">{{ title }}</div>
  <p>{{ "learn_how_teams_use_alignment" | localize }}</p>
  <a *ngIf="defaultSessionId" [uiParams]="{ planningSessionId: defaultSessionId }" ui-button uiSize="small" uiType="primary" uiShape="round" uiSref="gtmhub.goals.alignmentSidebar.tree">
    {{ "view_alignment_tree" | localize }}
  </a>
</ui-card>
