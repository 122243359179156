<span class="ant-input-wrapper ant-input-group" *ngIf="isAddOn; else noAddOnTemplate">
  <span class="add-on-before" *ngIf="nzAddOnBefore || nzAddOnBeforeIcon" [ngStyle]="uiAddonStyle" [uiIcon]="nzAddOnBeforeIcon" [uiTemplate]="nzAddOnBefore" ui-input-group-slot type="addon"></span>
  <span class="ant-input-affix-wrapper" *ngIf="isAffix; else contentTemplate" [class.ant-input-affix-wrapper-sm]="isSmall" [class.ant-input-affix-wrapper-lg]="isLarge">
    <ng-template [ngTemplateOutlet]="affixTemplate"></ng-template>
  </span>
  <span class="add-on-after" *ngIf="nzAddOnAfter || nzAddOnAfterIcon" [ngStyle]="uiAddonStyle" [uiIcon]="nzAddOnAfterIcon" [uiTemplate]="nzAddOnAfter" ui-input-group-slot type="addon"></span>
</span>
<ng-template #noAddOnTemplate>
  <ng-template [ngIf]="isAffix" [ngIfElse]="contentTemplate">
    <ng-template [ngTemplateOutlet]="affixTemplate"></ng-template>
  </ng-template>
</ng-template>
<ng-template #affixTemplate>
  <span *ngIf="nzPrefix || nzPrefixIcon" [uiIcon]="nzPrefixIcon" [uiTemplate]="nzPrefix" ui-input-group-slot type="prefix"></span>
  <ng-template [ngTemplateOutlet]="contentTemplate"></ng-template>
  <span *ngIf="nzSuffix || nzSuffixIcon" [uiIcon]="nzSuffixIcon" [uiTemplate]="nzSuffix" ui-input-group-slot type="suffix"></span>
</ng-template>
<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>
