import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import {
  IInsightboardNotification,
  IKpiNotification,
  IOkrViewNotification,
  IReportNotification,
  ISessionNotification,
  IWhiteboardNotification,
} from "@gtmhub/notifications/models/notifications";
import { AssigneesFacade } from "@webapp/assignees/services/assignees/assignees-facade.service";
import { InboxTemplateComponent } from "../inbox-template.component";

@Component({
  selector: "share-item-inbox-template",
  templateUrl: "./share-item-inbox-template.component.html",
  styleUrls: ["../inbox-template.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareItemInboxTemplateComponent
  extends InboxTemplateComponent<
    IWhiteboardNotification | IReportNotification | ISessionNotification | IInsightboardNotification | IKpiNotification | IOkrViewNotification
  >
  implements OnInit
{
  @Input() public notification!:
    | IWhiteboardNotification
    | IReportNotification
    | ISessionNotification
    | IInsightboardNotification
    | IKpiNotification
    | IOkrViewNotification;

  public teamName: string;

  public get iconType(): string {
    // maps the insightboard backend token 'dashboard' to 'insightboard' which is used as icon type and translation key in the frontend
    return this.notification?.targetType === "dashboard" ? "insightboard" : this.notification?.targetType;
  }

  constructor(private assignee: AssigneesFacade) {
    super();
  }
  public ngOnInit(): void {
    const teamId = this.notification.notificationData.item.teamId;
    this.teamName = this.assignee.getAssigneesIdMap()[teamId]?.name;
  }
}
