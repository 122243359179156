import { NgIf, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, HostBinding, Input, TemplateRef } from "@angular/core";
import { UiChipComponent } from "@quantive/ui-kit/chip";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { NzSelectItemComponent } from "ng-zorro-antd/select";

@Component({
  selector: "ui-select-item",
  templateUrl: "select-item.component.html",
  styleUrls: ["./select-item.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiIconModule, NgIf, NzOutletModule, NgTemplateOutlet, UiChipComponent],
})
export class UiSelectItemComponent extends NzSelectItemComponent {
  @Input("uiSelectItemTemplate") public selectItemTemplate: TemplateRef<NzSafeAny>;
  @Input("uiSelectItemContext") public selectItemContext: { $implicit: NzSafeAny; origin: NzSafeAny };
  @Input() public renderRemoveIcon = true;

  @HostBinding("class.gh-select-item-active") @Input() public isSelected: boolean;
}
