import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild, ViewEncapsulation } from "@angular/core";
import { UiInputDirective } from "@webapp/ui/input/input.directive";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";
import { UI_MODAL_DATA } from "@webapp/ui/modal/modal.models";
import { Affix } from "../models/unit-selector.models";

export type CustomUnitModalData = {
  unit: string;
  affix: Affix;
};

@Component({
  selector: "custom-unit",
  templateUrl: "./custom-unit.component.html",
  styleUrls: ["./custom-unit.component.less"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomUnitComponent implements AfterViewInit {
  @ViewChild(UiInputDirective, { static: true, read: ElementRef }) public input: ElementRef<HTMLInputElement>;

  public unit: string;
  public affix: Affix;

  constructor(
    private modalRef: UiModalRef,
    @Inject(UI_MODAL_DATA) modalData: CustomUnitModalData
  ) {
    Object.assign(this, modalData);
  }

  public ngAfterViewInit(): void {
    // Remove the setTimeout once GVS-29402 is handled
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 50);
  }

  public onCustomUnitCreated(): void {
    const isPrefix = this.affix === Affix.Prefix;
    this.modalRef.destroy({ unit: this.unit, prefix: isPrefix, suffix: !isPrefix });
  }

  public closeModal(): void {
    this.modalRef.destroy();
  }
}
