import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { localize } from "@gtmhub/localization";
import { PIStateProcessorInstanceSubType } from "../components/pi-feedback-card/services/state-processor/pi-state-processor.models";
import { PiErrorData, PiErrorTypes } from "../models/pi-errors.models";

const maliciousInstructionBackendFlag = "suspicious instruction detected";
const insufficientInformationBackendFlag = "insufficient information to retrieve suggestions";

const piErrorTypesDefaultValues: PiErrorTypes = {
  isThrottlingError: false,
  isMaliciousInstructionError: false,
  isInsufficientInformationError: false,
};

const piGenericErrorMessages = {
  throttling: "you_reached_the_daily_limit_for_suggestions",
  maliciousInstruction: "your_request_should_be_related_to_okrs",
};

const piSuggestionSpecificErrorMessages: { [key in PIStateProcessorInstanceSubType]: string } = {
  description: "unable_to_suggest_description",
  metric: "unable_to_suggest_key_result",
  metrics: "unable_to_suggest_key_results",
  objectives: "unable_to_suggest_objectives",
  tags: "unable_to_suggest_tags",
  task: "unable_to_suggest_task",
  tasks: "unable_to_suggest_tasks",
  title: "unable_to_suggest_title",
  explanation: "unable_to_load_overview",
  effectiveness: "unable_to_load_effectiveness",
  summary: "unable_to_load_overview", // on purpose, they share the same error message
  whyMatter: "unable_to_load_overview", // on purpose, they share the same error message
  keyTerms: "unable_to_load_overview", // on purpose, they share the same error message

  createObjectiveTitle: "unable_to_suggest_title", // on purpose, title and createObjectiveTitle share the same message
  createMetricTitle: "unable_to_suggest_title", // on purpose, title and createMetricTitle share the same message
  createMetricDescription: "unable_to_suggest_description", // on purpose, description and createMetricDescription share the same message
  metricsAndTasks: "unable_to_suggest_key_results", // on purpose, metricsAndTasks share the same error message for key results
  effectivenessTitle: "unable_to_load_effectiveness", // on purpose, metricsAndTasks share the same error message for key results
  overview: "unable_to_load_overview", // on purpose, metricsAndTasks share the same error message for key results
  sql: "unable_to_suggest_sql_please_try_again",
};

const piWhiteboardErrorMessages = {
  default: "unable_to_suggest_okrs",
  insufficientInformation: "include_more_context_like_notes_or_okrs",
};

const getPiErrorType = (httpError: HttpErrorResponse): PiErrorTypes => {
  if (httpError.status === HttpStatusCode.TooManyRequests) {
    return {
      ...piErrorTypesDefaultValues,
      isThrottlingError: true,
    };
  }

  if (httpError.error?.error_detail === maliciousInstructionBackendFlag) {
    return {
      ...piErrorTypesDefaultValues,
      isMaliciousInstructionError: true,
    };
  }

  if (httpError.error?.error_detail === insufficientInformationBackendFlag) {
    return {
      ...piErrorTypesDefaultValues,
      isInsufficientInformationError: true,
    };
  }

  return piErrorTypesDefaultValues;
};

export const getPiErrorData = (subType: PIStateProcessorInstanceSubType, httpError: HttpErrorResponse | null): PiErrorData => {
  if (!httpError) {
    return {
      errorMessage: localize(piSuggestionSpecificErrorMessages[subType]),
      ...piErrorTypesDefaultValues,
    };
  }

  const errorType = getPiErrorType(httpError);

  if (errorType.isThrottlingError) {
    return {
      errorMessage: localize(piGenericErrorMessages.throttling),
      ...errorType,
    };
  }

  if (errorType.isMaliciousInstructionError) {
    return {
      errorMessage: localize(piGenericErrorMessages.maliciousInstruction),
      ...errorType,
    };
  }

  return {
    errorMessage: localize(piSuggestionSpecificErrorMessages[subType]),
    ...piErrorTypesDefaultValues,
  };
};

export const getPiWhiteboardErrorData = (httpError: HttpErrorResponse): PiErrorData => {
  const errorType = getPiErrorType(httpError);

  if (errorType.isInsufficientInformationError) {
    return {
      errorMessage: localize(piWhiteboardErrorMessages.insufficientInformation),
      ...errorType,
    };
  }

  if (errorType.isThrottlingError) {
    return {
      errorMessage: localize(piGenericErrorMessages.throttling),
      ...errorType,
    };
  }

  return {
    errorMessage: localize(piWhiteboardErrorMessages.default) + ".",
    ...errorType,
  };
};
