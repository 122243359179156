import { IUIError } from "@gtmhub/error-handling";
import { IFeedEntry, IFeedEntryComment } from "@gtmhub/feed/models";
import { FeedActionsEnum } from "@gtmhub/feed/redux/feed-actions.enum";
import { FeedAction } from "./models";

export interface IFeedStoreState {
  feed: IFeedState;
}

export interface IFeedState {
  selectedFeedEntry: IFeedEntry;
  feed: IFeedEntry[];
  totalCount: number;
  isFetching: boolean;
  isFetched: boolean;
  error: IUIError;
}

export function feedReducer(
  state: IFeedState = { selectedFeedEntry: null, feed: [], totalCount: 0, isFetching: false, isFetched: false, error: null },
  action: FeedAction
): IFeedState {
  let feed: IFeedEntry[];

  switch (action?.type) {
    case FeedActionsEnum.REQUEST_FEED:
      return { ...state, isFetching: true, error: null };
    case FeedActionsEnum.RECEIVE_FEED:
      return { ...state, isFetching: false, isFetched: true, feed: mergeFeedEntryArrays(state.feed, action.feed.items), totalCount: action.feed.totalCount };
    case FeedActionsEnum.RECEIVE_FEED_ENTRY:
      feed = replaceInArray(state.feed, action.feedEntry);
      return { ...state, feed, selectedFeedEntry: action.feedEntry };
    case FeedActionsEnum.SET_SELECTED_FEED_ENTRY:
      return { ...state, selectedFeedEntry: action.feedEntry };
    case FeedActionsEnum.CLEAR_FETCHED_DATA:
      return { ...state, isFetching: false, isFetched: false, selectedFeedEntry: null, feed: [], totalCount: 0 };
    case FeedActionsEnum.CLEAR_SELECTED_FEED_ENTRY:
      return { ...state, selectedFeedEntry: null };
    case FeedActionsEnum.UPDATE_FEED_ENTRY_COMMENTS:
      return { ...state, feed: updateFeedEntryComments(state.feed, action.feedEntryId, action.comments) };
    case FeedActionsEnum.ERROR_REQUEST_FEED:
      return { ...state, isFetching: false, isFetched: false, error: action.error };
    default: {
      return state;
    }
  }
}

const updateFeedEntryComments = (items: IFeedEntry[], targetId: string, comments: IFeedEntryComment[]) => {
  const feedEntry = items.find((item) => item.id === targetId);

  if (feedEntry) {
    feedEntry.comments = comments || [];
  }

  return items;
};

const mergeFeedEntryArrays = (first: IFeedEntry[] = [], second: IFeedEntry[] = []) => {
  const idsToMerge = new Set(first?.map((d) => d.id));

  return [...first, ...(second || []).filter((d) => !idsToMerge.has(d.id))];
};

const replaceInArray = (arr: IFeedEntry[], feedEntry: IFeedEntry) =>
  arr.map((it) => {
    if (it.id === feedEntry.id) {
      return feedEntry;
    } else {
      return it;
    }
  });
