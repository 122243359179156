/* eslint-disable @typescript-eslint/naming-convention */
import { AutomationTriggerType, IAutomation } from "@gtmhub/automation";
import { IIndicator } from "@gtmhub/error-handling";
import { IGoal } from "@gtmhub/goals/models";
import { IRole } from "@gtmhub/roles";
import { ITeam } from "@gtmhub/teams";
import { TeamsSearchConditionSettings } from "@gtmhub/teams/utils/search-utils";
import { IUser } from "@gtmhub/users";
import { UsersSearchConditionSettings } from "@gtmhub/users/utils/search-utils";
import { UXCustomizationType } from "@gtmhub/uxcustomization/models";
import { IViewHistoryItemsGroupedResponse } from "@gtmhub/view-history";
import { IWhiteboard } from "@gtmhub/whiteboards";
import { EditionFeature } from "@webapp/accounts/models/edition.models";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { Insight } from "@webapp/data-story/models/insights.models";
import { ModuleFlag } from "@webapp/feature-toggles/models/feature-module.models";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { Insightboard } from "@webapp/insightboards/models";
import { Kpi } from "@webapp/kpis/models/kpis.models";
import { List } from "@webapp/lists/models";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { Permission } from "@webapp/permissions/models/permissions.model";
import { PlanningSessionStatus, Session } from "@webapp/sessions/models/sessions.model";
import { ShareableFilter } from "@webapp/shared/unified-filters/models/shareable-filters.models";
import { Task } from "@webapp/tasks/models/tasks.models";
import { ISearchRequestsBody, SearchOperatorsEnum } from "./search-api.models";

export class Search<T extends SearchCollection> {
  public id!: string;
  public collectionName: T;
  public fields: SearchFieldsCollection[T];
  public score: number;
}

export class SearchDTO {
  public searchTerm?: string;
  public operator?: SearchOperatorsEnum;
  public searchRequests: ISearchRequestsBody[];
}

export type SearchCollection =
  | "sessions"
  | "goals"
  | "metrics"
  | "tasks"
  | "users"
  | "teams"
  | "lists"
  | "kpis"
  | "insights"
  | "dashboards"
  | "automations"
  | "whiteboards"
  | "roles"
  | "filters";

type SearchFieldsCollection = {
  [CollectionType in SearchCollection]: IAllSearchFields[CollectionType];
};

interface IAllSearchFields {
  sessions: Partial<Session>;
  goals: Partial<IGoal> & Partial<ISearchGoalsFields>;
  metrics: Partial<Metric> & Partial<ISearchMetricsFields>;
  tasks: Partial<Task>;
  users: Partial<IUser> & Partial<ISearchUsersFields>;
  teams: Partial<ITeam>;
  lists: Partial<List> & { listType: number };
  kpis: Partial<Kpi> & { assignee?: Assignee };
  insights: Partial<Insight>;
  dashboards: Partial<Insightboard> & { description?: string };
  automations: Partial<IAutomation<AutomationTriggerType>>;
  whiteboards: Partial<IWhiteboard>;
  roles: Partial<IRole>;
  filters: Partial<ShareableFilter>;
}

interface ISearchGoalsFields {
  parent: ISearchGoalParentFields;
  session: {
    _id: string;
    status: PlanningSessionStatus;
    title: string;
  };
}

interface ISearchGoalParentFields {
  goalId: string;
  metricGoalId: string;
  metricId: string;
  sessionId: string;
}

interface ISearchMetricsFields {
  session: {
    _id: string;
    status: PlanningSessionStatus;
    title: string;
  };
}
interface ISearchUsersFields {
  fullName: string;
  auth0Cache: ISearchUsersAuth0CacheFields;
  teams: Pick<ITeam, "id" | "name">[];
  teamIds: string[];
  managedTeamIds: string[];
}

interface ISearchUsersAuth0CacheFields {
  appmetadata: { accountId: string };
  email: string;
  emailverified?: false;
  familyname: string;
  givenname: string;
  groups?: [];
  identities: [{ connection: string; provider: string; user_id: string }];
  name: string;
  picture: string;
  userid: string;
  usermetadata: { firstName: string; lastName: string };
  username?: string;
}

export class SearchMultiDTO {
  public searchTerm?: string;
  public operator?: SearchOperatorsEnum;
  public searchRequests?: Record<string, ISearchRequestsBody[]>;
}

export enum SearchFacetsOptionsEnum {
  Everywhere = "everywhere",
  OKRs = "okrs",
  Sessions = "sessions",
  OkrViews = "okr_views",
  Tasks = "tasks",
  Employees = "employees",
  Teams = "teams",
  Reports = "reports",
  Insights = "insights",
  Insightboards = "insightboards",
  KPIs = "kpis",
  Automations = "automations",
  Whiteboards = "whiteboards",
  Roles = "roles",
}

export type CurrentPageInfo = {
  pageNumber: number;
  selectedFacet: SearchFacetsOptions;
};

// Simple search needs roles, but it's not a part of the global search facet options
export type SearchFacetsOptions = `${SearchFacetsOptionsEnum}`;

export type RecentSearchFacetsOptions = SearchFacetsOptions | "otherItems";

export type SearchFacetsOptionsMap = {
  [key in `${SearchFacetsOptionsEnum}`]: SearchFacetsOptionsEnum;
};

export type RecentFacetLocalisationMap = {
  [key in RecentSearchFacetsOptions]: string;
};

/*
 * This map is translating the facet to collectionName, which BE needs for filtering
 *  */
export const searchCollectionsTranslationMap: Record<SearchFacetsOptionsEnum & "goals", SearchCollection[]> = {
  goals: ["goals"],
  okrs: ["goals", "metrics"],
  sessions: ["sessions"],
  tasks: ["tasks"],
  employees: ["users"],
  teams: ["teams"],
  reports: ["lists"],
  insights: ["insights"],
  insightboards: ["dashboards"],
  kpis: ["kpis"],
  automations: ["automations"],
  whiteboards: ["whiteboards"],
  okr_views: ["filters"],
  roles: ["roles"],
};

export const fieldsToSearchByMap = {
  goals: ["name", "description", "assignees.name"],
  goalsCompound: ["name", "description", "assignees.name"],
  metrics: ["name", "description", "assignees.name"],
  metricsCompound: ["name", "description", "assignees.name"],
  tasks: ["name", "description", "assignee.name"],
  sessions: ["title"],
  users: ["firstName", "lastName", "fullName", "auth0Cache.email", "teams.name"],
  usersCompound: ["fullName", "auth0Cache.email"],
  teams: ["name", "description"],
  teamsCompound: ["name"],
  insights: ["title", "description", "editor.name"],
  insightsCompound: ["title"],
  dashboards: ["name", "tag"],
  dashboardsCompound: ["name"],
  automations: ["name", "description"],
  kpis: ["name", "description", "assignee.name"],
  lists: ["title"],
  whiteboards: ["name"],
  roles: ["name"],
  filters: ["name"],
};

export const responseFieldsMap = {
  goals: ["name", "description", "ownerIds", "sessionId"],
  goalsCompound: ["name", "description", "ownerIds", "sessionId"],
  metrics: ["name", "description", "goalId", "ownerIds", "session._id"],
  metricsCompound: ["name", "description", "goalId", "ownerIds", "session._id"],
  tasks: ["name", "description", "ownerId"],
  sessions: ["title"],
  users: ["fullName", "teams._id", "teams.name", "isActive"],
  teams: ["name", "description"],
  insights: ["title", "description", "name"],
  dashboards: ["name"],
  automations: ["name", "description", "ownerId"],
  kpis: ["name", "description", "ownerId", "ownerIds"],
  lists: ["title", "listType", "ownerId"],
  whiteboards: ["name"],
  roles: ["name"],
  filters: ["name"],
};

export enum SearchFacetsIconTypes {
  Session = "session",
  Goal = "goal",
  Task = "task",
  List = "list",
  Automation = "automation",
  Team = "team",
  Employee = "employee",
  Kpi = "kpi",
  Insight = "insight",
  Insightboard = "insightboard",
  Whiteboard = "whiteboard",
  OkrViews = "filter-view",
  Filter = "filter",
}

export interface FacetIcon {
  facet: SearchFacetsOptionsEnum;
  type: SearchFacetsIconTypes;
  tooltipTitle: string;
}

export type GlobalSearchResult = IGlobalMultiSearchItem & { collectionName: RecentSearchFacetsOptions };

export interface SearchIndicators {
  gettingSearchData?: IIndicator;
  loadingMore?: IIndicator;
}

export const ITEMS_LIMIT_IF_EVERYWHERE = 4;
export const ITEMS_LIMIT_FOR_SIMPLE_SEARCH = 9;
export const ITEMS_LIMIT_IF_NOT_EVERYWHERE = 10;
export const ITEMS_LIMIT_SEARCH_PAGE = 20;

export type SearchItemsLimit =
  | typeof ITEMS_LIMIT_IF_EVERYWHERE
  | typeof ITEMS_LIMIT_FOR_SIMPLE_SEARCH
  | typeof ITEMS_LIMIT_IF_NOT_EVERYWHERE
  | typeof ITEMS_LIMIT_SEARCH_PAGE;

export interface SearchQueryParams {
  skip: number;
  take: SearchItemsLimit;
}

type ISearchFacetAccessMap = {
  [key in SearchFacetsOptions]: {
    permissions: Permission[];
    editionFeature: EditionFeature;
    featureFlag: FeatureFlag | ModuleFlag;
  };
};
export const SearchFacetsAccessMap: ISearchFacetAccessMap = {
  [SearchFacetsOptionsEnum.Sessions]: { editionFeature: "okrs", featureFlag: null, permissions: ["AccessGoals"] },
  [SearchFacetsOptionsEnum.OKRs]: { editionFeature: "okrs", featureFlag: null, permissions: ["AccessGoals"] },
  [SearchFacetsOptionsEnum.Tasks]: { editionFeature: "hub.tasks", featureFlag: ModuleFlag.Tasks, permissions: ["AccessGoals"] },
  [SearchFacetsOptionsEnum.Reports]: { editionFeature: null, featureFlag: null, permissions: null },
  [SearchFacetsOptionsEnum.Roles]: { editionFeature: null, featureFlag: null, permissions: null },
  [SearchFacetsOptionsEnum.Automations]: { editionFeature: "automation", featureFlag: null, permissions: ["ManageData"] },
  [SearchFacetsOptionsEnum.Teams]: { editionFeature: "people.teams", featureFlag: null, permissions: ["AccessPeople"] },
  [SearchFacetsOptionsEnum.Employees]: { editionFeature: "people.employees", featureFlag: null, permissions: ["AccessPeople"] },
  [SearchFacetsOptionsEnum.KPIs]: { editionFeature: "kpis", featureFlag: ModuleFlag.Kpis, permissions: ["AccessKPIs"] },
  [SearchFacetsOptionsEnum.Everywhere]: { editionFeature: null, featureFlag: null, permissions: null },
  [SearchFacetsOptionsEnum.Insightboards]: { editionFeature: "insightboards", featureFlag: null, permissions: ["AccessInsightboards"] },
  [SearchFacetsOptionsEnum.Insights]: { editionFeature: "insightboards", featureFlag: null, permissions: ["AccessInsightboards", "ManageData"] },
  [SearchFacetsOptionsEnum.Whiteboards]: { editionFeature: null, featureFlag: ModuleFlag.Whiteboards, permissions: null },
  [SearchFacetsOptionsEnum.OkrViews]: { editionFeature: "okrs", featureFlag: FeatureFlag.OkrViews, permissions: ["AccessGoals"] },
};

export const recentItemsTypes: Record<SearchFacetsOptions & "otherItems", UXCustomizationType[]> = {
  [SearchFacetsOptionsEnum.OKRs]: ["goal", "metric"],
  [SearchFacetsOptionsEnum.Sessions]: ["session"],
  [SearchFacetsOptionsEnum.OkrViews]: ["filter"],
  [SearchFacetsOptionsEnum.Tasks]: ["task"],
  [SearchFacetsOptionsEnum.Employees]: ["user"],
  [SearchFacetsOptionsEnum.Teams]: ["team"],
  [SearchFacetsOptionsEnum.Reports]: ["list"],
  [SearchFacetsOptionsEnum.Insights]: ["insight"],
  [SearchFacetsOptionsEnum.Insightboards]: ["dashboard"],
  [SearchFacetsOptionsEnum.KPIs]: ["kpi"],
  [SearchFacetsOptionsEnum.Automations]: ["automation"],
  [SearchFacetsOptionsEnum.Whiteboards]: ["whiteboard"],
  otherItems: ["session", "task", "list", "insight", "dashboard", "kpi", "automation", "whiteboard", "filter"],
};

export type GlobalSearchTrackingEvents =
  | "Results shown"
  | "Item searched"
  | "Recent Item Results Shown"
  | "Recent Item Searched"
  | "Recent Item Clicked"
  | "Item clicked"
  | "Additional Results Shown";

export const OpenSelectKeys = ["ArrowDown", "Enter"];

export type NextPrevItem = "next" | "prev";

export type FocusParams = {
  to: NextPrevItem;
  siblingSelectors: string;
  parentElementId: string;
};

export type SearchConditionSettings = UsersSearchConditionSettings & TeamsSearchConditionSettings;

export enum SearchTracingName {
  GS__CHANGED_SEARCH_TERM = "gs__changed_search_term",
  SS__CHANGED_SEARCH_TERM = "ss__changed_search_term",
}
export type SearchTracingNames = "gs__changed_search_term" | "ss__changed_search_term";

export type SearchMode = "simple" | "search-page" | "global";

type GlobalSearchItemInterface = {
  sessions: {
    title: string;
  };
  goals: {
    name: string;
    description: string;
    ownerIds: string[];
    sessionId: string;
  };
  metrics: {
    name: string;
    description: string;
    goalId: string;
    ownerIds: string[];
    session: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      _id: string;
    };
  };
  tasks: {
    name: string;
    description: string;
    ownerId: string;
  };
  users: {
    fullName: string;
    picture: string;
    auth0Cache: {
      email: string;
      picture: string;
    };
    teams: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      _id: string;
      name: string;
    }[];
    isActive?: boolean;
  };
  teams: {
    name: string;
    description: string;
    picture: string;
    avatar: string;
    color: string;
  };
  lists: {
    title: string;
    listType: number;
    ownerId: string;
  };
  kpis: {
    name: string;
    description: string;
    ownerId: string;
    ownerIds: string[];
  };
  insights: {
    name: string;
    title: string;
    description: string;
  };
  dashboards: {
    name: string;
  };
  automations: {
    name: string;
    description: string;
    ownerId: string;
  };
  whiteboards: {
    name: string;
  };
  roles: {
    name: string;
  };
  filters: {
    name: string;
    ownerId: string;
  };
};

type GlobalSearchItemCollectionTypeToInterface = {
  [CollectionType in SearchCollection]: GlobalSearchItemInterface[CollectionType];
};

export type GlobalSearchItem<T extends keyof GlobalSearchItemCollectionTypeToInterface> = {
  id: string;
  collectionName: T;
  fields: GlobalSearchItemCollectionTypeToInterface[T];
  score: number;
};

interface IGlobalMultiSearchItem<T extends keyof GlobalSearchItemCollectionTypeToInterface = SearchCollection> extends IGlobalSearchResult {
  items: GlobalSearchItem<T>[];
}

export interface IGlobalMultiSearchItems extends IGlobalSearchResult {
  items: Record<string, IGlobalMultiSearchItem>;
}

interface IGlobalSearchResult {
  totalCount: number;
  maxScore: number;
}
export type GlobalSearchItemReturnState = {
  state: string;
  stateParams: { [param: string]: number | string | boolean };
};

export const viewHistoryItemCollectionByRecentItemsFacetMap: Record<
  Exclude<RecentSearchFacetsOptions, "everywhere" | "roles">,
  Exclude<keyof IViewHistoryItemsGroupedResponse, "totalCount" | "kpiGroupsItems">[]
> = {
  okrs: ["metricItems", "goalItems"],
  sessions: ["sessionItems"],
  okr_views: ["filterItems"],
  tasks: ["taskItems"],
  employees: ["userItems"],
  teams: ["teamItems"],
  reports: ["listItems"],
  insights: ["insightItems"],
  insightboards: ["dashboardItems"],
  kpis: ["kpiItems"],
  automations: ["automationItems"],
  whiteboards: ["whiteboardItems"],
  otherItems: ["sessionItems", "taskItems", "listItems", "insightItems", "dashboardItems", "kpiItems", "automationItems", "whiteboardItems", "filterItems"],
};

type UXCustomizationTypeGlobalSearch = Exclude<UXCustomizationType, "kpigroup">;

export const searchCollectionTypeToUXCustomizationType: Record<Exclude<SearchCollection, "roles">, UXCustomizationTypeGlobalSearch> = {
  sessions: "session",
  goals: "goal",
  metrics: "metric",
  tasks: "task",
  lists: "list",
  users: "user",
  teams: "team",
  dashboards: "dashboard",
  insights: "insight",
  kpis: "kpi",
  automations: "automation",
  whiteboards: "whiteboard",
  filters: "filter",
};
