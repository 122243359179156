import { ChangeDetectionStrategy, Component } from "@angular/core";
import { NzListItemMetaDescriptionComponent } from "ng-zorro-antd/list";

@Component({
  selector: "ui-list-item-meta-description",
  exportAs: "uiListItemMetaDescription",
  templateUrl: "./list-item-meta-description.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class UiListItemMetaDescriptionComponent extends NzListItemMetaDescriptionComponent {}
