import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { AccountType } from "@gtmhub/core";
import { AccountResolverService } from "@webapp/accounts";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "demo-banner",
  templateUrl: "./demo-banner.component.html",
  styleUrls: ["./demo-banner.component.less"],
})
export class DemoBannerComponent implements OnInit {
  public isDemoAccount: boolean;

  constructor(private accountResolverService: AccountResolverService) {}

  public ngOnInit(): void {
    this.isDemoAccount = this.accountResolverService.getAccountData().type === AccountType.DemoAccount;
  }
}
