import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { InputSearchComponent } from "@webapp/shared/components/input-search/input-search.component";
import { IndicatorModule } from "@webapp/shared/indicator/indicator.module";
import { UiInputModule } from "@webapp/ui/input/input.module";

@NgModule({
  imports: [CommonModule, LocalizationModule, UiIconModule, UiButtonModule, UiInputModule, FormsModule, IndicatorModule],
  declarations: [InputSearchComponent],
  exports: [InputSearchComponent],
})
export class InputSearchModule {}
