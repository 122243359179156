import { FocusMonitor } from "@angular/cdk/a11y";
import { Directionality } from "@angular/cdk/bidi";
import { NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, NgZone, Optional, ViewChild, booleanAttribute, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { NzFormStatusService } from "ng-zorro-antd/core/form";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzRadioComponent } from "ng-zorro-antd/radio";
import { UiRadioService } from "./services/radio.service";

@Component({
  selector: "[ui-radio],[ui-radio-button]",
  exportAs: "uiRadio",
  templateUrl: "./radio.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiRadioComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgStyle],
})
export class UiRadioComponent extends NzRadioComponent {
  @Input("uiValue") public nzValue: NzSafeAny | null = null;
  @Input("uiDisabled") @InputBoolean() public nzDisabled = false;
  @Input("uiAutoFocus") @InputBoolean() public nzAutoFocus = false;
  @Input({ alias: "ui-radio-button", transform: booleanAttribute }) public isRadioButton = false;
  @Input() public hasCustomRadioContent = false;
  @ViewChild("inputElement", { static: false }) public inputElement: ElementRef;

  constructor(
    ngZone: NgZone,
    elementRef: ElementRef,
    cdr: ChangeDetectorRef,
    focusMonitor: FocusMonitor,
    @Optional() directionality: Directionality,
    @Optional() uiRadioService: UiRadioService,
    @Optional() nzFormStatusService?: NzFormStatusService
  ) {
    super(ngZone, elementRef, cdr, focusMonitor, directionality, uiRadioService, nzFormStatusService);
  }
}
