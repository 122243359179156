import { Injectable, OnDestroy } from "@angular/core";
import { receiveSessions } from "@gtmhub/sessions/redux/session-actions";
import { ISessionsStoreState } from "@gtmhub/sessions/redux/session-reducer";
import { ReduxCache } from "@webapp/core/state-management/redux-cache";
import { Session } from "../models/sessions.model";

@Injectable({ providedIn: "root" })
export class SessionsCache extends ReduxCache<ISessionsStoreState, Record<string, Session>, Map<string, Session>> implements OnDestroy {
  constructor() {
    super({
      selector: (state) => (state.sessions.isFetched ? state.sessions.map : undefined),
      setAction: (value) => receiveSessions([...value.values()]),
      storeToCache: (value) => (value != null ? new Map(Object.entries(value)) : undefined),
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe?.();
  }
}
