import { IRootScopeService, IScope } from "angular";
import { Insightboard } from "@webapp/insightboards/models";

export enum DashboardEventType {
  DASHBOARD_UPDATED = "DASHBOARD_UPDATED",
  DASHBOARD_DELETED = "DASHBOARD_DELETED",
  DASHBOARD_VISITED = "DASHBOARD_VISITED",
}

export class DashboardEvents {
  private $rootScope: IRootScopeService;

  constructor(private $scope: IScope) {
    this.$rootScope = this.$scope.$root;
  }

  public broadcastDashboardUpdated(insightboard: Insightboard): void {
    this.$rootScope.$broadcast(DashboardEventType.DASHBOARD_UPDATED, {
      insightboard,
    });
  }

  public broadcastDashboardDeleted(id: string): void {
    this.$rootScope.$broadcast(DashboardEventType.DASHBOARD_DELETED, {
      id,
    });
  }

  public broadcastDashboardVisited(insightboard: Insightboard): void {
    this.$rootScope.$broadcast(DashboardEventType.DASHBOARD_VISITED, {
      insightboard,
    });
  }
}
