import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { OKRsEventType } from "@gtmhub/okrs/events";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { TracingService } from "@webapp/core/tracing/services/tracing.service";
import { MetricsFacade } from "@webapp/okrs/metrics/services/metrics-facade.service";
import { TagSelectorComponent } from "@webapp/tags/components/tag-selector/tag-selector.component";
import { TagAnalyticsEvent, TagTargetType } from "@webapp/tags/models/tag-event.model";
import { Tag } from "@webapp/tags/models/tag.model";

@Component({
  selector: "metric-drawer-tags",
  templateUrl: "./metric-drawer-tags.component.html",
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MetricDrawerTagsComponent),
      multi: true,
    },
  ],
  imports: [TagSelectorComponent, ReactiveFormsModule],
})
export class MetricDrawerTagsComponent implements ControlValueAccessor {
  @Input() public metricId: string;
  @Input() public uiId: string;
  @Input() public disabled: boolean;
  @Input() public borderless: boolean;

  public tags: Tag[] = [];
  public notifyControlChange: (value: Tag[]) => void;

  constructor(
    private broadcastService: BroadcastService,
    private metricsFacade: MetricsFacade,
    private tracingService: TracingService,
    private analyticsService: AnalyticsService,
    private cdr: ChangeDetectorRef
  ) {}

  public registerOnChange(fn: (value: Tag[]) => void): void {
    this.notifyControlChange = fn;
  }

  public registerOnTouched(): void {
    // required by the ControlValueAccessor interface
  }

  public writeValue(tags: Tag[]): void {
    this.tags = tags || [];
  }

  public addTag(tag: Tag): void {
    this.tags = [...this.tags, tag];

    if (!this.metricId) {
      // if there is no metric id, this means that we edit the metric in draft mode
      this.notifyControlChange(this.tags);
      return;
    }

    this.tracingService.traceAction("add_tag_to_metric", () => {
      return this.metricsFacade.addTag$(this.metricId, tag.title).subscribe({
        next: () => {
          this.onSuccess(TagAnalyticsEvent.TagsAssigned);
        },
        error: () => {
          this.tags = this.tags.filter((t) => t.id !== tag.id);
        },
      });
    });
  }

  public removeTag(tag: Tag): void {
    this.tags = this.tags.filter((t) => t.id !== tag.id);

    if (!this.metricId) {
      // if there is no metric id, this means that we edit the metric in draft mode
      this.notifyControlChange(this.tags);
      return;
    }

    this.tracingService.traceAction("remove_tag_from_metric", () => {
      return this.metricsFacade.removeTag$(this.metricId, tag).subscribe({
        next: () => {
          this.onSuccess(TagAnalyticsEvent.TagsUnassigned);
        },
        error: () => {
          this.tags = [...this.tags, tag];
        },
      });
    });
  }

  private onSuccess(event: TagAnalyticsEvent): void {
    this.notifyControlChange(this.tags);
    this.broadcastMetricUpdate();

    this.analyticsService.track(event, {
      target_type: TagTargetType.KeyResult,
    });

    this.cdr.detectChanges();
  }

  private broadcastMetricUpdate(): void {
    this.broadcastService.emit("goalsChanged");
    this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, {
      reason: "goalChanged",
    });
  }
}
