import { module } from "angular";
import employeesCore from "@gtmhub/employees/employees-core.module";
import { DispatchCtrl } from "@gtmhub/notifications/controllers/dispatch";
import { InsightsFacade } from "@webapp/data-story/services/insights-facade/insights-facade.service";
import { InboxTemplateListComponent } from "@webapp/notifications/components/inbox-template-list/inbox-template-list.component";
import { PersonalNotificationComponent } from "@webapp/notifications/components/personal-notification/personal-notification.component";
import { UserNotificationSettingsTopNavBarComponent } from "@webapp/notifications/components/user-notification-settings-top-nav-bar/user-notification-settings-top-nav-bar.component";
import { NotificationsInboxCtrl } from "./controllers/notifications-inbox";
import notificationsCore from "./notifications-core.module";

const mod = module("notifications", [notificationsCore, employeesCore]);

mod.controller("NotificationsInboxCtrl", NotificationsInboxCtrl);
mod.controller("DispatchCtrl", DispatchCtrl);

mod.downgradeComponent("ghPersonalNotification", { component: PersonalNotificationComponent, propagateDigest: false });
mod.downgradeComponent("inboxTemplateList", { component: InboxTemplateListComponent, propagateDigest: false });
mod.downgradeComponent("userNotificationSettingsTopNavBar", { component: UserNotificationSettingsTopNavBarComponent, propagateDigest: false });

mod.downgradeInjectable("InsightsFacade", InsightsFacade);

export default mod.name;
