import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { AutoInsights, AutoInsightsDTO } from "@webapp/data-story/models/auto-insights-facade.models";
import { AutoInsightsState } from "@webapp/data-story/services/auto-insights-facade/auto-insights-state.service";

@Injectable()
export class AutoInsightsApiService extends BaseApiService<AutoInsights, AutoInsightsDTO, AutoInsightsState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: AutoInsightsState) {
    super("autoinsights", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getRecommendationsEndpoint(): string {
    return `${this.getBasePath(HttpActions.get)}/recommendation`;
  }

  public getAccountRecommendationsEndpoint(): string {
    return `${this.getBasePath(HttpActions.get)}/account/recommendation`;
  }

  public getInsightRecommendationFeedbackEndpoint(predictionId: string): string {
    return `${this.getBasePath(HttpActions.put)}/feedback/${predictionId}`;
  }
}
