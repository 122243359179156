import { IAttributes, IAugmentedJQuery, IDirective, IScope, auto } from "angular";
import "ng-tags-input";

const mod = angular.module("gh-ngTagsInput", ["ngTagsInput"]);

// The ability to add a completely custom template to the autoComplete directive.
// New method for displaying the input value.
// New method for clearing the input value (by extending the tagsInput directive) and closing the suggestion list.
// For all custom actions added, there is a binding so that the action could be passed back to the controller if triggered.
// There is a boolean binding indicating whether to show actions.

// These are partial scope/ctrl definitions. If you need to access more stuff,
// you have to look it up in ng-input-tags source
interface ITagsInputScope extends IScope {
  tagList: {
    remove(index: number): void;
  };
  canAddTag: boolean;
  disabled: boolean;
  options: Record<string, unknown>;
  newTag: {
    text(value?: string): string | void;
  };
}

interface ITagsInputCtrl {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  registerAutocomplete(): { getCurrentTagText(): string; clearInput(): void; getTags(): []; on(name: string, handler: Function): void };
  registerTagItem(): { getOptions(): Record<string, unknown>; removeTag(index: number): void };
}

interface IAutoCompleteScope extends IScope {
  suggestionList: {
    load(query: string, tags: []): void;
    reset(): void;
  };
  $getDisplayText(): string;
  $reset(): void;
}

mod.config([
  "$provide",
  function ($provide: auto.IProvideService) {
    $provide.decorator("tagsInputDirective", [
      "$delegate",
      function ($delegate: IDirective) {
        const directive: IDirective = $delegate[0];

        directive.scope = { ...(directive.scope as Record<string, string>), canAddTag: "=" };

        const oldCtrl = directive.controller[3];
        directive.controller = [
          "$scope",
          "$attrs",
          "$element",
          function (this: ITagsInputCtrl, $scope: ITagsInputScope, $attrs: IAttributes, $element: IAugmentedJQuery) {
            // eslint-disable-next-line prefer-rest-params
            oldCtrl.apply(this, arguments);

            $scope.$watch<boolean>("canAddTag", (canAddTag) => {
              if (canAddTag !== false) {
                $scope.disabled = $attrs.disabled === true;
                $element[0].classList.remove("cannot-add-tags");
              } else {
                $scope.disabled = true;
                $element[0].classList.add("cannot-add-tags");
              }
            });

            $scope.$watch<boolean>("disabled", (disabled) => {
              if (disabled === true) {
                $scope.options.placeholder = "";
              } else {
                $scope.options.placeholder = $attrs.placeholder;
              }
            });

            const registerAutocomplete = this.registerAutocomplete();

            this.registerAutocomplete = function () {
              return {
                ...registerAutocomplete,
                clearInput: function () {
                  $scope.newTag.text("");
                },
              };
            };

            this.registerTagItem = function () {
              return {
                getOptions: function () {
                  return $scope.options;
                },
                removeTag: function (index: number) {
                  if ($scope.disabled && $scope.canAddTag) {
                    return;
                  }
                  $scope.tagList.remove(index);
                },
              };
            };
          },
        ];

        return $delegate;
      },
    ]);
  },
]);

mod.config([
  "$provide",
  function ($provide: auto.IProvideService) {
    $provide.decorator("autoCompleteDirective", [
      "$delegate",
      function ($delegate: IDirective) {
        const directive = $delegate[0];

        const oldTemplateUrl = directive.templateUrl;

        directive.templateUrl = (element: IAugmentedJQuery, attrs: IAttributes) => attrs.customTemplate || oldTemplateUrl;

        directive.scope = { ...directive.scope, clickAction: "&", showActions: "<", hideSuggestionList: "<" };

        directive.compile = function () {
          return function (this: unknown, scope: IAutoCompleteScope, element: IAugmentedJQuery, attrs: IAttributes, tagsInputCtrl: ITagsInputCtrl) {
            // eslint-disable-next-line prefer-rest-params
            directive.link.apply(this, arguments);
            const tagsInput = tagsInputCtrl.registerAutocomplete();

            scope.$getDisplayText = function () {
              return tagsInput.getCurrentTagText();
            };

            scope.$reset = function () {
              tagsInput.clearInput();
              scope.suggestionList.reset();
            };

            tagsInput.on("tag-removed", function () {
              tagsInput.clearInput();
              scope.suggestionList.load(tagsInput.getCurrentTagText(), tagsInput.getTags());
            });

            tagsInput.on("input-blur", function () {
              scope.suggestionList.reset();
            });
          };
        };

        return $delegate;
      },
    ]);
  },
]);

export default mod.name;
