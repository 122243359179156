import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Ng1Zone } from "@gtmhub/core/tracing";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";
import { TracingService } from "@webapp/core/tracing/services/tracing.service";

@Injectable()
export class TracingInterceptor implements HttpInterceptor {
  constructor(
    private apiEndpointService: ApiEndpointService,
    private tracingService: TracingService
  ) {}

  public intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    return next.handle(this.attachHeaders(request));
  }

  private attachHeaders(request: HttpRequest<object>): HttpRequest<object> {
    if (!this.apiEndpointService.isApiRequest(request.url)) {
      return request;
    }

    const headers = {};
    this.tracingService.setTracingHeaders(headers, {
      action: Ng1Zone.current.get("action"),
      screen: Ng1Zone.current.get("screen"),
    });

    return request.clone({ setHeaders: headers });
  }
}
