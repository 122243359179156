import { IScope } from "angular";
import { StateService } from "@uirouter/angularjs";
import { AuthenticationResolverService } from "@gtmhub/auth";
import { UIErrorHandlingService } from "@gtmhub/error-handling";
import { SnackbarEvents } from "@gtmhub/shared/components/ui/snackbar/snackbar.events";
import { IProfile } from "@webapp/user-profile/models/user-profile.models";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";
import { ISendChangePasswordRequestRequest } from "../../login/models";
import { UserService } from "../../users/user-service";

interface ISendChangePasswordRequestScope extends IScope {
  auth0UserId: string;
  profile: IProfile;
  sendRequest(): void;
}

export class SendChangePasswordRequestCtrl {
  private snackbarEvents: SnackbarEvents;
  public static $inject = ["$state", "$scope", "UserService", "UserProfileService", "AuthenticationResolverService", "UIErrorHandlingService"];

  constructor(
    private $state: StateService,
    private $scope: ISendChangePasswordRequestScope,
    private userService: UserService,
    private profileService: UserProfileService,
    private authenticationResolverService: AuthenticationResolverService,
    private uiErrorHandlingService: UIErrorHandlingService
  ) {
    this.snackbarEvents = new SnackbarEvents($scope);
    this.init();
  }

  private init = () => {
    this.$scope.sendRequest = this.sendRequest;
    this.loadUser();
  };

  private loadUser = () => {
    this.$scope.profile = this.profileService.getProfile();
    this.$scope.auth0UserId = this.authenticationResolverService.getAuthUserId();
  };

  private sendRequest = () => {
    const email = this.$scope.profile.email;

    const data: ISendChangePasswordRequestRequest = {
      email: email,
    };

    this.userService.sendChangePasswordRequest(this.$scope.auth0UserId, data).then(
      () => {
        this.$state.go("gtmhub.userProfile");
        this.snackbarEvents.broadcastShowSnackbar("send_change_password_request");
      },
      (error) => {
        this.uiErrorHandlingService.handleModal(error);
      }
    );
  };
}
