import { HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { IBulkActionOnTeamsResponse, IReduxTeam, ITeamsStoreState } from "@gtmhub/teams";
import { TeamEventType } from "@gtmhub/teams/events";
import { IdMap } from "@gtmhub/util";
import { AccountResolverService } from "@webapp/accounts";
import { BaseListModel } from "@webapp/core/abstracts/models/base-list.model";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { GTMHUB_ADDITIONAL_PARAMS } from "@webapp/core/http/interceptors/track-data.interceptor";
import { GtmhubAdditionalParams } from "@webapp/core/http/models/http.models";
import { FileUploadedData, UploadImageResponse } from "@webapp/fileserver/models/fileserver.models";
import { FileserverFacade } from "@webapp/fileserver/services/fileserver-facade.service";
import { Team, TeamDTO } from "../models/teams.models";
import { TeamsApiService } from "./teams-api.service";
import { TeamsState } from "./teams-state.service";

@Injectable({
  providedIn: "any",
})
export class TeamsFacade extends BaseFacade<Team, TeamDTO, TeamsState, TeamsApiService> {
  public constructor(
    state: TeamsState,
    api: TeamsApiService,
    private broadcastService: BroadcastService,
    private fileFacade: FileserverFacade,
    private accountResolverService: AccountResolverService
  ) {
    super(state, api);
  }

  public getAllTeamsV2$(filter?: RequestPaging, config?: RequestConfig): Observable<BaseListModel<Team>> {
    return this.getAll$(filter, {
      ...new RequestConfig(),
      ...config,
      url: this.apiService.getAllTeamsV2Endpoint(),
    });
  }

  public getTeamsIdMap(): IdMap<IReduxTeam> {
    const state: ITeamsStoreState = reduxStoreContainer.reduxStore.getState();
    return state.teams.map;
  }

  public getTeamsItems(): IReduxTeam[] {
    const state: ITeamsStoreState = reduxStoreContainer.reduxStore.getState();
    return state.teams.items;
  }

  public createTeam$(team: Team, gtmhubAdditionalParams?: GtmhubAdditionalParams): Observable<Team> {
    const requestConfig = {
      ...new RequestConfig(),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, gtmhubAdditionalParams),
    };

    return this.apiService.post$(team, requestConfig);
  }

  public updateTeam$(team: Team, gtmhubAdditionalParams?: GtmhubAdditionalParams): Observable<Team> {
    const requestConfig = {
      ...new RequestConfig(),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, gtmhubAdditionalParams),
    };

    return this.apiService.patch$(team.id, team, requestConfig);
  }

  public uploadPicture$(pictureFile: File): Observable<UploadImageResponse> {
    const formData = new FormData();
    formData.append("file", pictureFile);

    return this.fileFacade.uploadImage$(formData);
  }

  public uploadPictureV2$(pictureFile: File): Observable<FileUploadedData> {
    const accountId: string = this.accountResolverService.getAccountId();

    const formData = new FormData();
    formData.append("file", pictureFile);
    formData.append("targetId", accountId); // Sending the accountId as the targetId since there can be a case where the team hasn't been created yet and we don't have the teamId
    formData.append("targetType", "team");
    formData.append("ref", "picture");

    return this.fileFacade.uploadMediaV2$(formData);
  }

  public activateTeam$(team: TeamDTO): Observable<IBulkActionOnTeamsResponse> {
    return this.post$([team.id], {
      ...new RequestConfig(),
      url: this.apiService.getActivateTeamEndpoint(),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, { id: team.id, action: "activate" }),
    });
  }

  public deactivateTeam$(team: TeamDTO): Observable<IBulkActionOnTeamsResponse> {
    return this.post$([team.id], {
      ...new RequestConfig(),
      url: this.apiService.getDeactivateTeamEndpoint(),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, { id: team.id, action: "deactivate" }),
    });
  }

  public deleteTeam$(team: TeamDTO): Observable<void> {
    return this.delete$<void>(team.id, {
      ...new RequestConfig(),
      url: this.apiService.getDeleteTeamEndpoint(team.id),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, { id: team.id, action: "delete" }),
    }).pipe(
      tap(() => {
        this.broadcastService.emit(TeamEventType.TEAM_DELETED, { team });
      })
    );
  }

  public deleteTeamById$(teamId: string, gtmhubAdditionalParams?: GtmhubAdditionalParams): Observable<void> {
    return this.delete$<void>(teamId, {
      ...new RequestConfig(),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, gtmhubAdditionalParams),
    }).pipe(
      tap(() => {
        this.broadcastService.emit(TeamEventType.TEAM_DELETED, { teamId: teamId });
      })
    );
  }

  public getTeam$(teamId: string): Observable<Team> {
    return this.get$(teamId, {
      ...new RequestConfig(),
      url: this.apiService.getTeamEndpoint(teamId),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, { id: teamId }),
    });
  }
}
