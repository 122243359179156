import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { IReflectionNotification } from "@gtmhub/notifications/models/notifications";
import { getCurrentAccountId, getCurrentUserId } from "@gtmhub/users";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { AssigneesFacade } from "@webapp/assignees/services/assignees/assignees-facade.service";
import { InboxTemplateComponent } from "../inbox-template.component";

@Component({
  selector: "reflection-inbox-template",
  templateUrl: "./reflection-inbox-template.component.html",
  styleUrls: ["../inbox-template.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReflectionInboxTemplateComponent extends InboxTemplateComponent<IReflectionNotification> implements OnInit {
  @Input() public notification!: IReflectionNotification;

  public teamName: string;

  constructor(
    private analyticsService: AnalyticsService,
    private assignee: AssigneesFacade
  ) {
    super();
  }

  public ngOnInit(): void {
    const teamId = this.notification.notificationData.item.teamId;
    this.teamName = this.assignee.getAssigneesIdMap()[teamId]?.name;
  }

  public trackNotificationClicked(type: string): void {
    this.analyticsService.track("Notification Seen", {
      account_id: getCurrentAccountId(),
      user_id: getCurrentUserId(),
      operation_type: type,
    });
  }
}
