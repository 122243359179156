import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  Type,
  ViewChildren,
  ViewContainerRef,
} from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { INotification } from "@gtmhub/notifications/models/notifications";
import { inboxTemplateComponentByOperationType, inboxTemplateComponentByTargetType } from "../inbox-templates";
import { InboxTemplateComponent } from "../inbox-templates/inbox-template.component";

@UntilDestroy()
@Component({
  selector: "inbox-template-list",
  templateUrl: "./inbox-template-list.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxTemplateListComponent implements OnDestroy {
  @Input()
  public notifications: INotification[] = [];

  @Output()
  public readonly seen = new EventEmitter<{ index: number; notification: INotification }>();

  @ViewChildren("notification", { read: ViewContainerRef })
  public set notificationContainers(list: QueryList<ViewContainerRef>) {
    this.createNotificationViews(list);
  }

  private expandedNotificationId: string;
  private componentRefs = new Map<string, ComponentRef<InboxTemplateComponent<INotification>>>();

  constructor(private cd: ChangeDetectorRef) {}

  public ngOnDestroy(): void {
    for (const componentRef of this.componentRefs.values()) {
      componentRef.destroy();
    }
    this.componentRefs.clear();
  }

  public trackByNotification(index: number, notification: INotification): string {
    return notification.id;
  }

  private createNotificationViews(containers: QueryList<ViewContainerRef>): void {
    containers.forEach((containerRef, index) => {
      const notification = this.notifications[index];
      if (this.componentRefs.has(notification.id)) {
        return;
      }

      const componentType = this.getNotificationComponentType(notification);
      const componentRef = containerRef.createComponent(componentType);
      componentRef.instance.notification = notification;
      componentRef.instance.expanded = this.expandedNotificationId === notification.id;
      componentRef.instance.toggle.pipe(untilDestroyed(this)).subscribe(() => this.toggleNotification(index, notification));
      this.componentRefs.set(notification.id, componentRef);
    });

    this.cd.detectChanges();
  }

  private getNotificationComponentType(notification: INotification): Type<InboxTemplateComponent<INotification>> {
    const componentType = inboxTemplateComponentByOperationType[notification.operationType] || inboxTemplateComponentByTargetType[notification.targetType];

    if (!componentType) {
      console.warn(`Cannot find inbox template component for notification for target type ${notification.targetType}`);
      return;
    }
    return componentType;
  }

  private toggleNotification(index: number, notification: INotification): void {
    if (notification.id === this.expandedNotificationId) {
      delete this.expandedNotificationId;
    } else {
      this.expandedNotificationId = notification.id;
    }

    for (const componentRef of this.componentRefs.values()) {
      const expanded = this.expandedNotificationId === componentRef.instance.notification.id;
      if (expanded !== componentRef.instance.expanded) {
        componentRef.instance.expanded = expanded;
        componentRef.changeDetectorRef.detectChanges();
      }
    }

    if (!notification.seen) {
      const componentRef = this.componentRefs.get(notification.id);
      notification.seen = true;
      componentRef.changeDetectorRef.detectChanges();

      this.seen.emit({ index, notification });
    }
  }
}
