import { Component } from "@angular/core";
import tasksEmptyUrl from "wwwroot/img/icons/tasks-empty.svg";
import { TaskFilterModalService } from "@gtmhub/tasks/services/task-filter-modal.service";

@Component({
  selector: "tasks-empty-sub-nav",
  templateUrl: "./tasks-empty-sub-nav.component.html",
  styleUrls: ["./tasks-empty-sub-nav.component.less"],
})
export class TasksEmptySubNavigationComponent {
  public tasksEmptyIcon: string;

  public constructor(private taskFilterModalService: TaskFilterModalService) {
    this.tasksEmptyIcon = tasksEmptyUrl;
  }

  public openFilterModal = (): void => {
    this.taskFilterModalService.open();
  };
}
