import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  forwardRef,
  isDevMode,
} from "@angular/core";
import { FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { Subject, takeUntil } from "rxjs";
import { v4 as uuid } from "uuid";
import { simpleClone } from "@webapp/shared/utils/utils";
import { FormItemBaseComponent } from "./components/form-items/form-item-base.component";
import { FormItemBase, FormOf, FormTypography, FormValueChange } from "./models/form.models";
import { hasDuplicateValues, isEmptyArray } from "./utils";

@UntilDestroy()
@Component({
  selector: "form-group",
  templateUrl: "./form-group.component.html",
  styleUrls: ["./form-group.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class FormGroupComponent<T = any> implements AfterContentInit, OnInit, OnDestroy {
  @ContentChildren(forwardRef(() => FormItemBaseComponent))
  public set setFormItemDescriptors(items: QueryList<FormItemBaseComponent>) {
    this.formItemDescriptors = items.toArray();
    this.handleFormDescriptorsChange({ formGroupUpdate: true });
  }

  @ViewChildren("descriptionSection", { read: ElementRef })
  public set a11yDescriptions(descriptions: QueryList<ElementRef<HTMLElement>>) {
    const renderedDescriptionBlockIds = (descriptions || []).map((description: ElementRef<HTMLElement>) => description.nativeElement.getAttribute("id"));
    this.a11yDescriptionsChange$.next(renderedDescriptionBlockIds);
  }

  /**
   * Controls whether the controls will be rendered without a border.
   * Set to `false` by default.
   */
  @Input() public borderless = false;

  /**
   * Attaches an ID value to the inner-lying form element. Used for composing each form control ID for label-input linking.
   * Uses a default guid-like value, so this should be provided only when you need to know the form or control IDs.
   */
  @Input() public formId = uuid();

  @Input()
  public typography: FormTypography;

  /**
   * Emits whenever any control's value has been updated.
   */
  @Output()
  public readonly valueChange: EventEmitter<FormValueChange<T>> = new EventEmitter();

  /**
   * Exposes the current form value.
   */
  public get value(): T {
    return <T>this.formGroup.value;
  }

  /**
   * Exposes whether the current form is valid.
   */
  public get valid(): boolean {
    return this.formGroup.valid;
  }

  /**
   * Exposes whether the current form is invalid.
   */
  public get invalid(): boolean {
    return this.formGroup.invalid;
  }

  /**
   * Exposes the current form dirty state.
   */
  public get dirty(): boolean {
    return this.formGroup.dirty;
  }

  /**
   * Exposes the current form pristine state.
   */
  public get pristine(): boolean {
    return this.formGroup.pristine;
  }

  /**
   * Emits a list of ID values of the currently rendered a11y description blocks.
   */
  public a11yDescriptionsChange$ = new Subject<string[]>();

  public formGroup: FormGroup<FormOf<T>>;
  public formHasIcons = false;
  public formItemDescriptors: FormItemBase[] = [];

  private detatchValueChangeListeners$ = new Subject<void>();

  constructor(private changeDetector: ChangeDetectorRef) {
    this.initializeForm();
  }

  public ngOnInit(): void {
    this.formGroup.statusChanges.pipe(untilDestroyed(this)).subscribe(() => {
      // update the form buttons disabled state according to status changes
      // the `input` runs outside the zone, so trigger cd manually if that's the case
      if (!NgZone.isInAngularZone()) {
        this.changeDetector.markForCheck();
      }
    });
  }

  public ngAfterContentInit(): void {
    this.handleFormDescriptorsChange();
  }

  public ngOnDestroy(): void {
    this.detatchValueChangeListeners$.next();
  }

  public formItemsTrackBy(index: number, item: FormItemBase): string {
    return item.controlName;
  }

  /**
   * Updates form group and the icon-related layout. Marks the component for check
   * to ensure updates to the configuration components are reflected in the UI.
   */
  public handleFormDescriptorsChange(options?: { formGroupUpdate: boolean; controlName?: string }): void {
    if (options?.formGroupUpdate) {
      this.ensureNoDuplicateControlNames();
      this.updateFormGroup({ controlName: options?.controlName });
    }

    this.formHasIcons = this.formItemDescriptors.some((item) => item.popoverIcon || item.clearValueIcon || item.tooltipIcon);

    this.changeDetector.markForCheck();
  }

  public handleClearValueIconClick(control: FormControl): void {
    // don't emit when the value is null, undefined, "", false or []
    if (control && (control.value || control.value === 0) && !isEmptyArray(control.value)) {
      control.reset();
      control.markAsDirty();
    }
  }

  public shouldShowClearValueIconOnHoverOnly(item: FormItemBase): boolean {
    if (!item?.clearValueIcon || typeof item.clearValueIcon === "boolean") {
      return false;
    }

    return item.clearValueIcon.showOnHover;
  }

  /**
   * Registers new form controls, deletes removed ones and updates existing ones according to the `items` config.
   */
  private updateFormGroup(options?: { controlName?: string }): void {
    const addedControlsCount = this.addNewFormControls();
    const removedControlsCount = this.removeDeletedFormControls();

    if (addedControlsCount > 0 || removedControlsCount > 0) {
      this.subscribeToValueChanges();
    }

    this.updateFormControls(options);
  }

  private createFormControl(item: FormItemBase): FormControl {
    return new FormControl({ value: simpleClone(item.value), disabled: item.disabled }, { validators: this.createValidators(item) });
  }

  private createValidators(item: FormItemBase): ValidatorFn[] {
    const validators: ValidatorFn[] = [];

    if (item.required) {
      validators.push(item.requiredValidator || Validators.required);
    }

    validators.push(...(item.validators || []));

    return validators;
  }

  /**
   * Updates the registered form control values, validators and disabled state
   * according to the data from the provided form items config.
   */
  private updateFormControls(options?: { controlName?: string }): void {
    Object.keys(this.formGroup.controls).forEach((name) => {
      if (!options || !options.controlName || options.controlName === name) {
        const itemDescriptor = this.formItemDescriptors.find((item) => item.controlName === name);
        const control = this.formGroup.controls[name];

        if (!equal(control.value, itemDescriptor.value)) {
          control.setValue(simpleClone(itemDescriptor.value), { emitEvent: false });
        }

        control.setValidators(this.createValidators(itemDescriptor));

        if (control.disabled && !itemDescriptor.disabled) {
          control.enable();
        } else if (!control.disabled && itemDescriptor.disabled) {
          control.disable();
        }
      }
    });

    this.formGroup.updateValueAndValidity({ emitEvent: false });
  }

  /**
   * Adds to the FormGroup new form controls corresponding to newly provided form items.
   * Returns the number of added controls.
   */
  private addNewFormControls(): number {
    const formControlNamesInForm = new Set(Object.keys(this.formGroup.controls));
    const addedFormItemDescriptors = this.formItemDescriptors.filter((item) => item.controlName && !formControlNamesInForm.has(item.controlName));

    addedFormItemDescriptors.forEach((item) => {
      item.formControl = this.createFormControl(item);
      this.formGroup.addControl(<string & keyof T>item.controlName, item.formControl);
    });

    return addedFormItemDescriptors.length;
  }

  /**
   * Removes from the FormGroup the form controls that are no longer present in the provided form items config.
   * Returns the number of deleted controls.
   */
  private removeDeletedFormControls(): number {
    const formControlNamesFromConfig = new Set(this.formItemDescriptors.filter((item) => item.controlName).map((item) => item.controlName));
    const formControlNamesInForm = Object.keys(this.formGroup.controls);

    const deletedFormControlNames = formControlNamesInForm.filter((name) => !formControlNamesFromConfig.has(name));
    deletedFormControlNames.forEach((name) => this.formGroup.removeControl(<string & keyof T>name));

    return deletedFormControlNames.length;
  }

  private subscribeToValueChanges(): void {
    this.detatchValueChangeListeners$.next();

    Object.keys(this.formGroup.controls).forEach((controlName) => {
      this.formGroup.controls[controlName].valueChanges.pipe(untilDestroyed(this), takeUntil(this.detatchValueChangeListeners$.asObservable())).subscribe((value) => {
        // the formGroup.value is still not recalculated - do so manually before emitting the valueChange event
        this.formGroup.updateValueAndValidity({ emitEvent: false });
        this.updateFormDescriptor(controlName, value);

        this.valueChange.emit({
          formValue: <T>this.formGroup.value,
          updatedControl: {
            name: controlName as keyof T,
            value: value,
          },
          isInZone: NgZone.isInAngularZone(),
        });
      });
    });
  }

  public initializeForm(): void {
    this.formGroup = new FormGroup<FormOf<T>>(<FormOf<T>>{});
  }

  private updateFormDescriptor(name: string, value: unknown): void {
    const descriptor = this.formItemDescriptors?.find((descriptor) => descriptor.controlName === name);
    if (descriptor) {
      descriptor.value = value;
      descriptor.valueChange.emit(value);
    }
  }

  private ensureNoDuplicateControlNames(): void {
    if (isDevMode() && hasDuplicateValues(this.formItemDescriptors, "controlName")) {
      throw new Error("The provided form items contain duplicate `controlName` identifiers. Make sure each provided form item has a unique `controlName` value.");
    }
  }
}
