import { Injectable } from "@angular/core";
import { byEndDescAndStartAsc } from "@gtmhub/shared/components/session-tree-selector/utils";
import { ISessionSelectorContext, ISessionTreeNode, TreeOptions } from "@webapp/sessions/models/session-tree.model";
import { Session } from "@webapp/sessions/models/sessions.model";

@Injectable({
  providedIn: "root",
})
export class SessionTreeDataTransformerService {
  public buildTree(sessions: Session[], context: ISessionSelectorContext): ISessionTreeNode[] {
    this.validateTreeParams(context.treeOptions);

    switch (context.treeOptions.type) {
      case "available-sessions":
        return this.buildFullTree(sessions);
      case "parent-to-child":
        return this.buildParentToChildSubTree(sessions, context);
      case "child-to-parent":
        return this.buildChildToParentSubTree(sessions, context);
    }
  }

  private validateTreeParams(treeOptions: TreeOptions): void {
    if (treeOptions.type !== "available-sessions" && !treeOptions.sourceSessionId) {
      throw new Error("Missing sourceSessionId in treeOptions");
    }
  }

  private buildFullTree(sessions: Session[]): ISessionTreeNode[] {
    sessions.sort(byEndDescAndStartAsc);
    const fetchedSessionsSet = new Set<string>(sessions.map(({ id }) => id));
    const rootSessions = sessions.filter(({ parentId }) => !parentId || !fetchedSessionsSet.has(parentId));

    return rootSessions.map(({ id, parentId }) => this.createSessionTreeNode(id, parentId, sessions));
  }

  private buildParentToChildSubTree(sessions: Session[], context: ISessionSelectorContext): ISessionTreeNode[] {
    const treeNodes = this.buildFullTree(sessions);
    const sourceNode = this.getNodeByKey(treeNodes, context.treeOptions.sourceSessionId);
    const children = context.hasGrandParentAlignmentFeature ? sourceNode.children : sourceNode.children.map((node) => ({ ...node, children: [] }));

    return [{ ...sourceNode, children }];
  }

  private buildChildToParentSubTree(sessions: Session[], context: ISessionSelectorContext): ISessionTreeNode[] {
    const sourceSession = this.getSessionById(sessions, context.treeOptions.sourceSessionId);
    const treeNodes = this.buildFullTree(sessions);
    const sourceNode = this.getNodeByKey(treeNodes, sourceSession.id);
    const parentNode = this.getNodeByKey(treeNodes, sourceNode.parentKey);
    sourceNode.children = [];

    return this.getParentNodes(context.hasGrandParentAlignmentFeature ? treeNodes : [parentNode], sourceNode);
  }

  private createSessionTreeNode(sessionId: string, parentId: string, sessions: Session[]): ISessionTreeNode {
    const session = this.getSessionById(sessions, sessionId);
    const children = sessions.filter(({ parentId }) => parentId === sessionId).map(({ id, parentId }) => this.createSessionTreeNode(id, parentId, sessions));

    return { key: sessionId, parentKey: parentId, title: session.title, color: session.color, disabled: !session.userHasPermissionToCreate, children };
  }

  private getNodeByKey(nodes: ISessionTreeNode[], key: string): ISessionTreeNode {
    return nodes?.reduce((foundNode, node) => foundNode || (node?.key === key ? node : this.getNodeByKey(node?.children, key)), null);
  }

  private getSessionById(sessions: Session[], sessionId: string): Session {
    return sessions.find(({ id }) => id === sessionId);
  }

  private getParentNodes(nodes: ISessionTreeNode[], node: ISessionTreeNode): ISessionTreeNode[] {
    const parentNode = this.getNodeByKey(nodes, node.parentKey);

    if (parentNode) {
      return this.getParentNodes(nodes, { ...parentNode, children: [node] });
    }

    return [node];
  }
}
