import { Injectable } from "@angular/core";
import { Observable, combineLatest, map, of, zip } from "rxjs";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { CurrentUserRepository } from "@webapp/users";

@Injectable()
export class AccountOptionsVisibilityService {
  constructor(
    private permissionsFacade: PermissionsFacade,
    private featureTogglesFacade: FeatureTogglesFacade,
    private currentUserRepository: CurrentUserRepository,
    private editionFeatureService: EditionFeatureService
  ) {}

  get anyOption$(): Observable<boolean> {
    return zip(this.accountAndDomain$, this.activityHistory$, this.billing$).pipe(
      map(([accountAndDomain, activityHistory, billing]) => {
        return accountAndDomain || activityHistory || billing;
      })
    );
  }

  get accountAndDomain$(): Observable<boolean> {
    return zip(of(this.currentUserRepository.userHasRole("admin")), this.isPlatformEnabled$()).pipe(
      map(([isAdmin, isPlatformEnabled]) => {
        if (isPlatformEnabled) {
          return false;
        }

        return isAdmin;
      })
    );
  }

  public get activityHistory$(): Observable<boolean> {
    return zip(
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ActivityHistory),
      this.permissionsFacade.hasPermission$("ManageActivityHistory"),
      this.isPlatformEnabled$()
    ).pipe(
      map(([featureAvailable, hasActivityHistoryPermission, isPlatformEnabled]) => {
        if (isPlatformEnabled) {
          return false;
        }

        return featureAvailable && hasActivityHistoryPermission;
      })
    );
  }

  get billing$(): Observable<boolean> {
    return zip(this.permissionsFacade.hasPermission$("ManageBilling"), this.isPlatformEnabled$()).pipe(
      map(([hasManageBillingPermission, isPlatformEnabled]) => {
        if (isPlatformEnabled) {
          return false;
        }

        return hasManageBillingPermission;
      })
    );
  }

  private isPlatformEnabled$(): Observable<boolean> {
    return combineLatest({
      isPlatformFeatureFlagEnabled: this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.PlatformLD),
      isPlatformEditionFeatureEnabled: this.editionFeatureService.hasFeature$("platform"),
    }).pipe(map(({ isPlatformFeatureFlagEnabled, isPlatformEditionFeatureEnabled }) => isPlatformFeatureFlagEnabled && isPlatformEditionFeatureEnabled));
  }
}
