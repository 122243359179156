import { Injectable } from "@angular/core";
import equal from "fast-deep-equal";
import { BehaviorSubject, Observable, OperatorFunction, distinctUntilChanged, map } from "rxjs";
import { ICustomFieldsState, ICustomFieldsStoreState } from "@gtmhub/customFields/redux/custom-field-reducer";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { CustomFieldTargetType, ICustomField } from "@webapp/custom-fields/models/custom-fields.models";
import { CustomFieldsState } from "./custom-fields-state";

@Injectable({
  providedIn: "root",
})
export class CustomFieldsFacade extends BaseFacade<ICustomField, ICustomField, CustomFieldsState, null> {
  private customFields$: BehaviorSubject<ICustomFieldsState> = new BehaviorSubject(reduxStoreContainer.reduxStore.getState<ICustomFieldsStoreState>().customFields);

  constructor(state: CustomFieldsState) {
    super(state, null);

    const { reduxStore } = reduxStoreContainer;
    reduxStore.subscribe(() => {
      const state: ICustomFieldsState = reduxStoreContainer.reduxStore.getState<ICustomFieldsStoreState>().customFields;
      this.customFields$.next(state);
    });
  }

  public getCustomFieldsByTargetType$(targetType: CustomFieldTargetType): Observable<ICustomFieldsState> {
    return this.customFields$.pipe(
      distinctUntilChanged((prev, curr) => {
        const oldValues = prev.map[targetType] ? prev.map[targetType] : [];
        const newValues = curr.map[targetType] ? prev.map[targetType] : [];

        return oldValues.length === newValues.length && equal([...oldValues], [...newValues]);
      })
    );
  }

  public getCustomFields$(): Observable<ICustomFieldsState> {
    return this.customFields$.pipe(
      distinctUntilChanged((prev, curr) => {
        const targetTypes = [...new Set([...Object.keys(prev.map), ...Object.keys(curr.map)])];
        for (const targetType of targetTypes) {
          const oldValues = prev.map[targetType] ? prev.map[targetType] : [];
          const newValues = curr.map[targetType] ? prev.map[targetType] : [];
          if (oldValues.length === newValues.length && equal([...oldValues], [...newValues])) {
            continue;
          }

          return false;
        }

        return true;
      })
    );
  }

  public removePrivateCustomFields(): OperatorFunction<ICustomFieldsState, ICustomFieldsState> {
    return map((customFieldsState) => {
      const map = { ...customFieldsState.map };
      for (const targetType of Object.keys(map)) {
        map[targetType] = map[targetType].filter((customField: ICustomField) => !customField.isPrivate);
      }

      return { ...customFieldsState, map };
    });
  }
}
