import { module } from "angular";
import env from "@gtmhub/env/env.module";
import { CurrentUserRolesActions } from "./redux/current-user-roles-actions";
import { RolesActions } from "./redux/roles-actions";
import { RoleService } from "./role-service";

const mod = module("rolesCore", [env]);

mod.service("RolesActions", RolesActions);
mod.service("RoleService", RoleService);
mod.service("CurrentUserRolesActions", CurrentUserRolesActions);

export default mod.name;
