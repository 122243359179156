import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { QuantivePlusSuggestions } from "@gtmhub/goals/models";
import { Goal } from "@webapp/okrs/goals/models/goal.models";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import {
  IQuantivePlusAcceptedMetric,
  IQuantivePlusAcceptedTask,
  IQuantivePlusRefreshedArgs,
  IQuantivePlusSuggestionStatus,
} from "@webapp/platform-intelligence/quantive-plus/models";
import { Task } from "@webapp/tasks/models/tasks.models";

type SingleMetricSubject = { type: "refreshed" | "accepted" } & {
  args: IQuantivePlusRefreshedArgs | IQuantivePlusAcceptedMetric;
};

type SingleTaskSubject = { type: "refreshed" | "accepted" } & {
  args: IQuantivePlusAcceptedTask;
};

@Injectable({
  providedIn: "root",
})
export class PiSuggestionSharedMetricStateService {
  private tasks$ = new Subject<QuantivePlusSuggestions>();
  private singleMetricTasks$ = new Subject<Partial<SingleTaskSubject>>();
  private goal$ = new BehaviorSubject<Partial<Goal>>(null);
  private metric$ = new BehaviorSubject<Partial<Metric>>(null);
  private singleMetric$ = new Subject<Partial<SingleMetricSubject>>();
  private singleTask$ = new Subject<Partial<IQuantivePlusAcceptedTask>>();
  private quantiveSuggestionsFlowId$ = new Subject<string>();

  private tasksSuggestionStatus$ = new BehaviorSubject<IQuantivePlusSuggestionStatus>(null);
  private taskList$ = new BehaviorSubject<Task[]>([]);

  public getSingleMetric$(): Observable<Partial<SingleMetricSubject>> {
    return this.singleMetric$.asObservable();
  }

  public setSingleMetric(args: SingleMetricSubject): void {
    this.singleMetric$.next(args);
  }

  public setTasks(tasks: QuantivePlusSuggestions): void {
    this.tasks$.next(tasks);
  }

  public setSingleMetricTasks(tasks: Partial<SingleTaskSubject>): void {
    this.singleMetricTasks$.next(tasks);
  }

  public getSingleMetricTasks$(): Observable<Partial<SingleTaskSubject>> {
    return this.singleMetricTasks$.asObservable();
  }

  public getTasks$(): Observable<QuantivePlusSuggestions> {
    return this.tasks$.asObservable();
  }

  public getSingleTask$(): Observable<Partial<IQuantivePlusAcceptedTask>> {
    return this.singleTask$.asObservable();
  }

  public setSingleTask(args: IQuantivePlusAcceptedTask): void {
    this.singleTask$.next(args);
  }

  public setGoal(goal: Partial<Goal>): void {
    this.goal$.next(goal);
  }

  public getGoal$(): Observable<Partial<Goal>> {
    return this.goal$.asObservable();
  }

  public setMetric(metric: Partial<Metric>): void {
    this.metric$.next(metric);
  }

  public getMetric$(): Observable<Partial<Metric>> {
    return this.metric$.asObservable();
  }

  public setTasksSuggestionStatus(status: IQuantivePlusSuggestionStatus): void {
    this.tasksSuggestionStatus$.next(status);
  }

  public getTasksSuggestionStatus$(): Observable<IQuantivePlusSuggestionStatus> {
    return this.tasksSuggestionStatus$.asObservable();
  }

  public setTaskList(tasks: Task[]): void {
    this.taskList$.next(tasks);
  }

  public getTaskList$(): Observable<Task[]> {
    return this.taskList$.asObservable();
  }

  public setQuantiveSuggestionsFlowId(flowId: string): void {
    this.quantiveSuggestionsFlowId$.next(flowId);
  }

  public getQuantiveSuggestionsFlowId(): Observable<string> {
    return this.quantiveSuggestionsFlowId$.asObservable();
  }
}
