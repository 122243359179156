import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Optional, SkipSelf } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { NzTSType } from "ng-zorro-antd/core/types";
import { InputBoolean, toBoolean } from "ng-zorro-antd/core/util";
import { NzFormLabelComponent } from "ng-zorro-antd/form";
import { UiFormTooltipIcon } from "@webapp/ui/form/form.models";
import { formToTooltipIcon } from "@webapp/ui/form/util/form.util";
import { UiFormDirective } from "../../form.directive";

@Component({
  selector: "ui-form-label",
  exportAs: "uiFormLabel",
  templateUrl: "form-label.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, UiTooltipModule, NzOutletModule, UiIconModule],
})
export class UiFormLabelComponent extends NzFormLabelComponent {
  @Input("uiFor") public nzFor?: string;
  @Input("uiRequired") @InputBoolean() public nzRequired = false;
  @Input("uiNoColon")
  set labelNoColon(value: boolean) {
    this.nzNoColon = toBoolean(value);
  }

  get labelNoColon(): boolean {
    return this.nzNoColon;
  }

  @Input("uiTooltipTitle") public nzTooltipTitle?: NzTSType;

  @Input("uiTooltipIcon")
  set labelTooltipIcon(value: string | UiFormTooltipIcon) {
    this.nzTooltipIcon = formToTooltipIcon(value);
  }

  constructor(cdr: ChangeDetectorRef, @Optional() @SkipSelf() uiFormDirective: UiFormDirective) {
    super(cdr, uiFormDirective);
  }
}
