import { StateService, TargetState, UIRouterModule } from "@uirouter/angular";
import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SimpleChangesOf } from "@quantive/ui-kit/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiSkeletonModule } from "@quantive/ui-kit/skeleton";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { Observable, combineLatest, fromEvent, map, mergeMap, of, throttleTime } from "rxjs";
import { CurrentEmployeeActions, ICurrentEmployeeStoreState } from "@gtmhub/employees";
import { formatAttainmentProgressValue, getNumberSign } from "@gtmhub/okrs/metrics/utils";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { getCurrentUserId } from "@gtmhub/users";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { AnalyticsModule } from "@webapp/analytics/analytics.module";
import { TrackingMetadata } from "@webapp/analytics/models/analytics.model";
import { ReduxStoreObserver } from "@webapp/core/state-management/redux-store-observer";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { hasAccessToSpecialReports } from "@webapp/navigation/components/navigation/sub-navigation/reports-sub-navigation/reports-sub-navigation.util";
import { NavigationReportsCacheService } from "@webapp/navigation/services/navigation-reports-cache.service";
import { MetricProgressBarComponent } from "@webapp/okrs/metrics/components/metric-progress-bar/metric-progress-bar.component";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { SessionsRepository } from "@webapp/sessions/services/sessions-repository.service";
import { getScrollParent } from "@webapp/shared/utils/dom";
import { TeamsRepository } from "@webapp/teams/services/teams-repository.service";
import { UiLoadingIndicatorModule } from "@webapp/ui/loading-indicator/loading-indicator.module";
import { UiProgressModule } from "@webapp/ui/progress/progress.module";

interface SummaryReportMetricProgress {
  attainment: number;
  attainmentDelta: number;
}

interface SummaryReportView {
  company: SummaryReportMetricProgress;
  my?: SummaryReportMetricProgress;
  myTeams?: SummaryReportMetricProgress;
  members?: SummaryReportMetricProgress;
  currentTeam?: SummaryReportMetricProgress;
}

@UntilDestroy()
@Component({
  selector: "session-summary-report",
  templateUrl: "./session-summary-report.component.html",
  styleUrls: ["./session-summary-report.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    LocalizationModule,
    UIRouterModule,
    UiProgressModule,
    MetricProgressBarComponent,
    UiIconModule,
    AnalyticsModule,
    UiSkeletonModule,
    UiLoadingIndicatorModule,
    UiTooltipModule,
  ],
})
export class SessionSummaryReportComponent implements OnChanges, AfterViewInit {
  @Input()
  public summaryReport: SummaryReportView;
  @Input()
  public expanded = false;
  @Input()
  public sessionId: string;
  @Input()
  public trackMeta: TrackingMetadata;
  @Input()
  public contextTeamId: string;
  @Input()
  public isDisabled: boolean;

  @Output()
  public readonly expandedChange = new EventEmitter<boolean>();

  @ViewChild("summary", { static: false, read: ElementRef<HTMLElement> }) public summaryEl: ElementRef<HTMLElement>;
  @ViewChild("report", { static: false, read: ElementRef<HTMLElement> }) public reportEl: ElementRef<HTMLElement>;

  public ariaRoleDescription: string;
  public reduxObserver: ReduxStoreObserver;

  public get reportState(): TargetState | null {
    const performanceReport = this.navigationReportsCache.get().find((report) => report.name.toLowerCase() === "performance report");
    if (!performanceReport) {
      return null;
    }

    return this.stateService.target("gtmhub.insightboard", { dashboardId: performanceReport.id, sessionId: this.sessionId });
  }

  public get hasReportPermissions(): Observable<boolean> {
    return combineLatest([
      this.permissionsFacade.hasPermission$("ManageData"),
      this.permissionsFacade.hasPermission$("AccessReportsAndDataSourceFilters"),
      this.permissionsFacade.hasPermission$("AccessInsightboards"),
      this.editionFeatureService.hasFeature$("setup.datasources"),
    ]).pipe(
      map(([canManageData, canAccessReportsAndDataSourceFilters, canAccessInsightboards, dataSourcesAvailable]) => {
        return hasAccessToSpecialReports({ canManageData, canAccessReportsAndDataSourceFilters, canAccessInsightboards, dataSourcesAvailable });
      })
    );
  }

  public currentUserId: string;
  private scrollParent: Element;

  constructor(
    private stateService: StateService,
    private sessionsRepository: SessionsRepository,
    private navigationReportsCache: NavigationReportsCacheService,
    private currentEmployeeActions: CurrentEmployeeActions,
    private editionFeatureService: EditionFeatureService,
    private permissionsFacade: PermissionsFacade,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef<HTMLElement>,
    private teamsRepository: TeamsRepository
  ) {
    const { reduxStore } = reduxStoreContainer;
    this.reduxObserver = new ReduxStoreObserver(reduxStore);
    reduxStore.dispatch(this.currentEmployeeActions.fetchCurrentEmployeeIfMissing());

    this.currentUserId = getCurrentUserId();
  }

  public ngOnChanges(changes: SimpleChangesOf<SessionSummaryReportComponent>): void {
    if (changes.expanded?.currentValue) {
      if (!this.scrollParent) {
        this.initScrollParent();
      }

      if (this.sessionId && !this.summaryReport) {
        if (this.contextTeamId) {
          this.getTeamSessionProgress(this.sessionId);
        } else {
          this.getSessionsProgress(this.sessionId);
        }
      }
    }
  }

  public ngAfterViewInit(): void {
    if (this.expanded) {
      this.initScrollParent();
    }
  }

  @HostListener("window:resize")
  public handleWindowResize(): void {
    this.onScroll();
  }

  private initScrollParent(): void {
    // run in promise to properly resolve the scroll parent
    Promise.resolve().then(() => {
      this.scrollParent = getScrollParent(this.elementRef.nativeElement);
      if (!this.scrollParent) {
        return;
      }

      fromEvent(this.scrollParent, "scroll")
        .pipe(throttleTime(10), untilDestroyed(this))
        .subscribe(() => {
          this.onScroll();
        });
    });
  }

  private onScroll(): void {
    if (!this.expanded || !this.reportEl || !this.summaryEl) {
      return;
    }

    const { top: scrollParentTop, height: scrollParentHeight } = this.scrollParent.getBoundingClientRect();

    requestAnimationFrame(() => {
      const summaryElStyles = window.getComputedStyle(this.summaryEl.nativeElement);
      const summaryElPadding = parseInt(summaryElStyles.paddingTop, 10) + parseInt(summaryElStyles.paddingBottom, 10);
      const { height: reportHeight } = this.reportEl.nativeElement.getBoundingClientRect();
      const minHeight = reportHeight + summaryElPadding;
      const { top: mainTop, bottom: mainBottom, height: mainHeight } = this.elementRef.nativeElement.getBoundingClientRect();

      const shouldSetMarginTop = mainBottom >= minHeight + scrollParentTop;
      if (shouldSetMarginTop) {
        this.summaryEl.nativeElement.style.marginTop = `${Math.max(scrollParentTop - mainTop, 0)}px`;
      }

      const marginBottom = mainBottom - (scrollParentHeight + scrollParentTop);
      const shouldSetMarginBottom = marginBottom + minHeight < mainHeight;
      if (shouldSetMarginBottom) {
        this.summaryEl.nativeElement.style.marginBottom = `${Math.max(marginBottom, 0)}px`;
      }
    });
  }

  public toggleExpanded(): void {
    this.expanded = !this.expanded;
    this.expandedChange.emit(this.expanded);
  }

  public getTrackingMetadata(): TrackingMetadata {
    return {
      button_name: "my_okrs_graph",
      expanded: this.expanded,
      session_id: this.sessionId,
      user_id: this.currentUserId,
      ...this.trackMeta,
    };
  }

  public formatAttainment(progress: number): number {
    return Math.round(progress * 100);
  }

  private getSessionsProgress(sessionId: string): void {
    this.reduxObserver
      .whenFetched$<ICurrentEmployeeStoreState>("currentEmployee")
      .pipe(
        mergeMap((state) => {
          const currentEmployee = state.currentEmployee.info;
          const assigneeIdsToLoad = [...new Set([currentEmployee.id, ...currentEmployee.teamIds, ...currentEmployee.managedTeamIds])].join(",");
          return combineLatest([of(currentEmployee), this.sessionsRepository.getSessionsProgress$(sessionId, assigneeIdsToLoad)]);
        })
      )
      .subscribe(([profile, stats]) => {
        if (stats.items.length) {
          const sessionProgress = stats.items[0];

          this.summaryReport = {
            company: {
              attainment: sessionProgress.sessionProgress,
              attainmentDelta: sessionProgress.sessionAttainmentDelta.delta,
            },
          };

          const myProgressStats = sessionProgress.assigneesStats?.find((item) => item.assigneeId === this.currentUserId);
          if (myProgressStats) {
            const myProgress = {
              attainment: myProgressStats.progress || 0,
              attainmentDelta: myProgressStats.attainmentDelta.delta || 0,
            };

            this.summaryReport.my = myProgress;
          }

          const myTeamsProgressStats = sessionProgress.assigneesStats?.filter(
            (item) => profile.teamIds.includes(item.assigneeId) || profile.managedTeamIds.includes(item.assigneeId)
          );
          if (myTeamsProgressStats?.length) {
            const myTeamsProgress = {
              attainment: myTeamsProgressStats.map((stat) => stat.progress).reduce((acc, item) => acc + item, 0) / myTeamsProgressStats.length,
              attainmentDelta: myTeamsProgressStats.map((stat) => stat.attainmentDelta.delta).reduce((acc, item) => acc + item, 0) / myTeamsProgressStats.length,
            };
            this.summaryReport.myTeams = myTeamsProgress;
          }
        }

        this.generateAriaRoleDescription();
        this.cdr.detectChanges();
        this.onScroll();
      });
  }

  private getTeamSessionProgress(sessionId: string): void {
    this.teamsRepository
      .getMap$()
      .pipe(
        mergeMap((teams) => {
          const team = teams.get(this.contextTeamId);
          const teamMembers = team.members || [];

          const assigneeIdsToLoad = [...new Set([team.id, ...teamMembers])].join(",");
          return combineLatest([of(teamMembers), this.sessionsRepository.getSessionsProgress$(sessionId, assigneeIdsToLoad)]);
        })
      )
      .subscribe(([teamMembersIds, stats]) => {
        const sessionProgress = stats.items[0];

        this.summaryReport = {
          company: {
            attainment: sessionProgress.sessionProgress,
            attainmentDelta: sessionProgress.sessionAttainmentDelta.delta,
          },
        };

        const membersProgressStats = sessionProgress.assigneesStats?.filter((item) => teamMembersIds.includes(item.assigneeId));
        if (membersProgressStats?.length) {
          const membersProgress = {
            attainment: membersProgressStats.map((stat) => stat.progress).reduce((acc, item) => acc + item, 0) / membersProgressStats.length,
            attainmentDelta: membersProgressStats.map((stat) => stat.attainmentDelta.delta).reduce((acc, item) => acc + item, 0) / membersProgressStats.length,
          };
          this.summaryReport.members = membersProgress;
        }

        const currentTeamProgressStats = sessionProgress.assigneesStats?.find((item) => item.assigneeId === this.contextTeamId);
        if (currentTeamProgressStats) {
          const currentTeamProgress = {
            attainment: currentTeamProgressStats.progress || 0,
            attainmentDelta: currentTeamProgressStats.attainmentDelta.delta || 0,
          };

          this.summaryReport.currentTeam = currentTeamProgress;
        }

        this.generateAriaRoleDescription();
        this.cdr.detectChanges();
        this.onScroll();
      });
  }

  private generateAriaRoleDescription(): void {
    let ariaRoleDescription = "Average session progress.";

    if (this.summaryReport.my) {
      const myProgressSign = getNumberSign(this.summaryReport.my.attainmentDelta, { lowSign: "down", topSign: "up" });
      const myProgressAttainment = formatAttainmentProgressValue(this.summaryReport.my.attainment);
      const myProgressAttainmentDelta = formatAttainmentProgressValue(this.summaryReport.my.attainmentDelta);

      const myProgressLabel = `Me, progress of my OKRs: ${myProgressAttainment}%. Progress of my OKRs compared to 2 weeks ago: ${myProgressSign} ${myProgressAttainmentDelta}%.`;
      ariaRoleDescription += "\n" + myProgressLabel;
    }

    if (this.summaryReport.members) {
      const membersProgressSign = getNumberSign(this.summaryReport.members.attainmentDelta, { lowSign: "down", topSign: "up" });
      const membersProgressAttainment = formatAttainmentProgressValue(this.summaryReport.members.attainment);
      const membersProgressAttainmentDelta = formatAttainmentProgressValue(this.summaryReport.members.attainmentDelta);
      const membersProgressLabel = `Members, progress of team members OKRs: ${membersProgressAttainment}%. Progress of team members OKRs compared to 2 weeks ago: ${membersProgressSign} ${membersProgressAttainmentDelta}%.`;
      ariaRoleDescription += "\n" + membersProgressLabel;
    }

    if (this.summaryReport.myTeams) {
      const teamsProgressSign = getNumberSign(this.summaryReport.myTeams.attainmentDelta, { lowSign: "down", topSign: "up" });
      const teamsProgressAttainment = formatAttainmentProgressValue(this.summaryReport.myTeams.attainment);
      const teamsProgressAttainmentDelta = formatAttainmentProgressValue(this.summaryReport.myTeams.attainmentDelta);
      const teamsProgressLabel = `My teams, progress of my teams owned OKRs: ${teamsProgressAttainment}%. Progress of my teams owned OKRs compared to 2 weeks ago: ${teamsProgressSign} ${teamsProgressAttainmentDelta}%.`;
      ariaRoleDescription += "\n" + teamsProgressLabel;
    }

    if (this.summaryReport.currentTeam) {
      const currentTeamProgressSign = getNumberSign(this.summaryReport.currentTeam.attainmentDelta, { lowSign: "down", topSign: "up" });
      const currentTeamProgressAttainment = formatAttainmentProgressValue(this.summaryReport.currentTeam.attainment);
      const currentTeamProgressAttainmentDelta = formatAttainmentProgressValue(this.summaryReport.currentTeam.attainmentDelta);
      const currentTeamProgressLabel = `Current team, progress of current team owned OKRs: ${currentTeamProgressAttainment}%. Progress of current team owned OKRs compared to 2 weeks ago: ${currentTeamProgressSign} ${currentTeamProgressAttainmentDelta}%.`;
      ariaRoleDescription += "\n" + currentTeamProgressLabel;
    }

    const companyProgressSign = getNumberSign(this.summaryReport.company.attainmentDelta, { lowSign: "down", topSign: "up" });
    const companyProgressAttainment = formatAttainmentProgressValue(this.summaryReport.company.attainment);
    const companyProgressAttainmentDelta = Math.abs(formatAttainmentProgressValue(this.summaryReport.company.attainmentDelta));
    const companyProgressLabel = `Company, progress of Company owned OKRs: ${companyProgressAttainment}%. Progress of Company owned OKRs compared to 2 weeks ago: ${companyProgressSign} ${companyProgressAttainmentDelta}%.`;
    ariaRoleDescription += "\n" + companyProgressLabel;

    this.ariaRoleDescription = ariaRoleDescription;
  }
}
