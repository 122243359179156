// This is defined in container.ejs in element #navigation-skeleton
export const removeMainNavSkeleton = (): void => {
  document.querySelector("#main-nav-skeleton")?.remove();
  removeFullNavSkeleton();
};

export const removeSubNavSkeleton = (): void => {
  document.querySelector("#sub-nav-skeleton")?.remove();
  removeFullNavSkeleton();
};

export const removeFullNavSkeleton = (args: { force?: boolean } = {}): void => {
  const baseNav = document.querySelector("#navigation-skeleton");

  if (baseNav) {
    if (args.force) {
      baseNav.remove();
      return;
    }

    const hasNoChildrenElements = !baseNav.childElementCount;
    const hasOnlyStyleElement = baseNav.childElementCount === 1 && baseNav.firstElementChild?.tagName === "STYLE";

    if (hasNoChildrenElements || hasOnlyStyleElement) {
      baseNav.remove();
    }
  }
};
