import { isPromise } from "@uirouter/angular";
import { humanError } from "@gtmhub/error-handling/util";
import { localize } from "@gtmhub/localization";
import { UiModalButtonOptions } from "@webapp/ui/modal/modal.models";

export const dangerButton = <T>(label: string, options: Omit<UiModalButtonOptions<T>, "label" | "type" | "danger"> = {}): UiModalButtonOptions<T> =>
  primaryButton<T>(label, {
    ...options,
    danger: true,
  });

/**
 * The `onClick` function can return any object, however there is certain logic behind the result:
 * - If the result is `false` or a promise that results in `false` - the modal won't be closed
 * - In all other cases, the modal wil be automatically closed with the given result
 */
export const primaryButton = <T>(label: string, options: Omit<UiModalButtonOptions<T>, "label" | "type"> = {}): UiModalButtonOptions<T> => {
  const { onClick, ...restOptions } = options;
  return {
    label,
    type: "primary",
    shape: "round",
    size: "large",
    ...restOptions,
    onClick(component, modalRef): unknown {
      if (!onClick) {
        modalRef.close();
        return;
      }

      const closeIfNotFalse = (result?: unknown): unknown => {
        if (result !== false) {
          modalRef.close(result);
        }
        return result;
      };

      const result = onClick.call(this, component, modalRef);
      if (isPromise(result)) {
        return result.then(
          (promiseResult) => closeIfNotFalse(promiseResult),
          (error) => {
            throw humanError(error);
          }
        );
      }

      return closeIfNotFalse(result);
    },
  };
};

export const cancelButton = <T>(options: Partial<Pick<UiModalButtonOptions<T>, "onClick" | "label" | "disabled" | "show">> = {}): UiModalButtonOptions<T> => {
  const { onClick, ...restOptions } = options;
  return {
    label: options.label || localize("cancel"),
    type: "link",
    shape: "round",
    size: "large",
    ...restOptions,
    onClick(component, modalRef): void {
      if (onClick) {
        onClick.call(this, component, modalRef);
      }
      modalRef.close();
    },
  };
};
