import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { NothingFoundSize, NothingFoundStyles } from "./nothing-found.models";
import { ghNothingFoundStyles } from "./nothing-found.utils";

const DEFAULT_SIZE = "default";

@Component({
  selector: "nothing-found",
  templateUrl: "./nothing-found.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NothingFoundComponent implements OnInit {
  @Input()
  public titleKey = "nothing_found";

  @Input()
  public infoKey = "";

  @Input()
  public uiSize: NothingFoundSize = DEFAULT_SIZE;

  public stylesMap: NothingFoundStyles;

  public ngOnInit(): void {
    this.stylesMap = ghNothingFoundStyles[this.uiSize] || ghNothingFoundStyles[DEFAULT_SIZE];
  }
}
