import { Injectable } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";

/**
 * @description
 * Defines the map of errors returned from failed textarea validation checks.
 */
type TextareaValidationErrors = {
  [key: string]: string;
};

/**
 * @description
 * A function that receives a control and synchronously returns a map of
 * textarea validation errors if present, otherwise null.
 */
export interface TextareaValidatorFn {
  (control: AbstractControl): TextareaValidationErrors | null;
}

@Injectable()
export class TextareaValidatorFactory {
  public maxLengthValidator(errorMessage: string, params: { maxLength: number }): TextareaValidatorFn {
    return (control: FormGroup): TextareaValidationErrors | null => {
      if (control.value?.length && control.value?.length > params.maxLength) {
        return { maxLength: errorMessage };
      }

      return null;
    };
  }

  public requiredValidator(errorMessage: string): TextareaValidatorFn {
    return (control: FormGroup): TextareaValidationErrors | null => {
      if (!control.value?.length || !control.value?.trim().length) {
        return { required: errorMessage };
      }

      return null;
    };
  }
}
