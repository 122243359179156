import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  public set<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }
  public get<T>(key: string): T {
    const saved = localStorage.getItem(key);
    let obj: T;
    try {
      if (typeof saved === "undefined" || saved === "undefined") {
        obj = undefined;
      } else {
        obj = JSON.parse(saved);
      }
    } catch (e) {
      localStorage.removeItem(key);
    }
    return obj;
  }
  public remove(key: string): void {
    localStorage.removeItem(key);
  }
}
