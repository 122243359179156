import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { getCurrentUserId } from "@gtmhub/users";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";
import { CurrentUserRepository } from "@webapp/users";

@Component({
  selector: "comment-options-dropdown",
  templateUrl: "./comment-options-dropdown.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentOptionsDropdownComponent implements OnInit {
  @Input()
  public commentAuthorId: string;

  @Input()
  public disabled: boolean;

  @Output()
  public readonly edit: EventEmitter<void> = new EventEmitter();

  @Output()
  public readonly delete: EventEmitter<void> = new EventEmitter();

  public menuItems: DropdownMenuItem[] = [];

  private get isCurrentUserCommentAuthor(): boolean {
    return this.commentAuthorId === getCurrentUserId();
  }

  private get isCurrentUserAdmin(): boolean {
    return this.currentUserRepository.userHasRole("admin");
  }

  private get isCurrentUserViewOnly(): boolean {
    return this.currentUserRepository.userHasRole("view-only");
  }

  public constructor(private currentUserRepository: CurrentUserRepository) {}

  public ngOnInit(): void {
    this.initializeMenuItems();
  }

  private initializeMenuItems(): void {
    if (this.isCurrentUserViewOnly || (!this.isCurrentUserAdmin && !this.isCurrentUserCommentAuthor)) {
      return;
    }

    const menuItemBuilder = new DropdownMenuItemBuilder();

    if (this.isCurrentUserCommentAuthor) {
      this.menuItems.push(
        menuItemBuilder
          .setKey("edit_comment")
          .setAction({ handler: (): void => this.edit.emit() })
          .setUiType({ uiType: "uiIcon", iconType: "edit", iconTheme: "outline" })
          .build()
      );
    }

    this.menuItems.push(
      menuItemBuilder
        .setKey("delete_comment")
        .setToBeDestructive()
        .setAction({ handler: (): void => this.delete.emit() })
        .setUiType({ uiType: "uiIcon", iconType: "trash-bin", iconTheme: "outline" })
        .build()
    );
  }
}
