/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import { isArray, isFunction } from "angular";

export const splitChain = (chain: unknown): [string[], Function] => {
  if (isArray(chain)) {
    return [chain.slice(0, chain.length - 1) as string[], chain[chain.length - 1] as Function];
  }
  if (isFunction(chain)) {
    return [[], chain];
  }
  throw new Error("Unexpected chain specified: " + chain);
};

export const constructChain = (dependencies: string[], fn: Function): unknown => (dependencies.length ? [...dependencies, fn] : fn);
