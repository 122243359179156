import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { IPIFeedbackDTO, IPIFeedbackItemModel } from "./models";
import { PiFeedbackServiceState } from "./pi-feedback-state.service";

@Injectable({
  providedIn: "root",
})
export class PiFeedbackApiService extends BaseApiService<IPIFeedbackItemModel, IPIFeedbackDTO, PiFeedbackServiceState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: PiFeedbackServiceState) {
    super("feedback", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getQuantivePlusFeedbackEndpoint(): string {
    return `${this.getQuantivePlusBasePath(HttpActions.post)}/`;
  }
}
