import { OverlayModule } from "@angular/cdk/overlay";
import { NgClass, NgIf, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UiTooltipComponent } from "@quantive/ui-kit/tooltip";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { NzOverlayModule } from "ng-zorro-antd/core/overlay";

@Component({
  selector: "ui-popover",
  exportAs: "uiPopoverComponent",
  preserveWhitespaces: false,
  templateUrl: "./popover.component.html",
  styleUrls: ["./popover.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [OverlayModule, NzOverlayModule, NgClass, NgStyle, NgIf, NzOutletModule],
})
export class UiPopoverComponent extends UiTooltipComponent {
  get hasBackdrop(): boolean {
    return this.nzTrigger === "click" ? this.nzBackdrop : false;
  }
}
