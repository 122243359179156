import { IInsightCalculationData } from "@gtmhub/sockets";
import { getScript } from "@gtmhub/util";
import { Insight, UpdateInsightSelectorDataActionParams } from "@webapp/data-story/models/insights.models";
import { IInsightSelectorData, ITimePeriod } from "./components/insight-selector";

export const getInsightSelectorData = (params: UpdateInsightSelectorDataActionParams): IInsightSelectorData => {
  // hack to take the fieldName of the insight
  let fieldName: string;

  if (params.insight.formatting && params.insight.formatting.metadata) {
    Object.keys(params.insight.formatting.metadata).forEach((key) => {
      fieldName = key;
    });
  }

  return {
    settings: params.settings,
    insightName: params.insight.name,
    fieldName: fieldName || params.fieldName,
    formatting: params.insight.formatting,
    initialValue: params.calculatedValue,
    datasourceBlueprintName: params.insight.connection?.name,
  };
};

// insight calculation response should contain a single numeric value or null value => isScalar = true
// Note: null value covers the case where no data corresponds to the sql query, but in the future such data may exist and the user wants to use such an insight in dynamic KR or KPI
export const isInsightScalar = (insightCalcResponse: IInsightCalculationData): boolean => {
  return (
    insightCalcResponse.data.default.length === 1 &&
    Object.keys(insightCalcResponse.data.default[0]).length === 1 &&
    (insightCalcResponse.format === "number" || Object.values(insightCalcResponse.data.default[0])[0] === null)
  );
};

export const isInsightCodeless = (insight: Insight): boolean => insight.formatting && insight.formatting.isCodeless;

export const isInsightGoogleChartDep = (insight: Insight): boolean => insight.view.indexOf("google.visualization") !== -1;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getGoogleCharts = (): Promise<any> =>
  getScript("https://www.gstatic.com/charts/loader.js", {
    id: "google-charts-loader",
  }).then(() => {
    const google = window["google"];

    // charts.load is async, when it loads the charts, return the google object
    return google.charts.load("current", { packages: ["corechart"] }).then(() => google);
  });

export const convertPeriodStringToObject = (periodString: string): ITimePeriod => {
  const [startDate, endDate] = periodString.split("|");

  return {
    startDate,
    endDate,
  };
};
export const convertDateRangeObjectToString = (dateObject: ITimePeriod): string => `${dateObject.startDate}|${dateObject.endDate}`;
