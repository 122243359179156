import { IHttpResponse, IHttpService, IPromise } from "angular";
import { IHasSlackIntegration, ISlackChannelInfo, ISlackChannels } from "@gtmhub/automation";
import { OidcDomain } from "@gtmhub/automation/models/automation";
import { storage } from "@gtmhub/core/storage";
import { EnvironmentService, IAppConfig, IAuth0AuthConfig } from "@gtmhub/env";
import { IConfigActivity, IPatchSSOConnection, IPostSSOConnection, ISSOConnection } from "@webapp/configuration/models/configuration.model";
import { ICollection } from "@webapp/core/core.models";

export class IntegrationService {
  public static $inject = ["appConfig", "$http", "EnvironmentService"];

  constructor(
    private appConfig: IAppConfig,
    private $http: IHttpService,
    private env: EnvironmentService
  ) {}

  public upsertConfigSection<C>(config: unknown, section: string): IPromise<C> {
    const url = this.env.getIntegrationsEndpoint("/config/" + section);

    return this.$http.put<C>(url, config).then((result) => result.data);
  }

  public updateConfig<C>(config: unknown, section: string): IPromise<C> {
    const url = this.env.getIntegrationsEndpoint("/config/" + section + "/activities");

    return this.$http.patch<C>(url, config).then((result) => result.data);
  }

  public getSlackButton(): IPromise<string> {
    const url = this.env.getApiEndpoint(`/automation/slack-button`);

    return this.$http.get<string>(url).then((response) => decodeURIComponent(response.data));
  }

  public getConfig<C>(integration: string): IPromise<C> {
    const url = this.env.getIntegrationsEndpoint("/config/" + integration);

    return this.$http.get<C>(url).then((response) => response.data);
  }

  // When working with Auth0 Connections, Auth0 returns us URLs with the "auth0.com" domain.
  // Which means that the user must follow a domain that is different than gtmhub.com.
  // We already have the "auth.gtmhub.com" domain configured in Auth0, so we want to use that domain.
  // Auth0 doesn't allow us to retrieve our custom domain from their Connections API, thus we're doing the dirty work here.
  private replaceIntegrationUrlWithCustomUrl = (data: ISSOConnection): ISSOConnection => {
    if (!data) return;

    if (this.appConfig.auth.type === "auth0") {
      const auth0Config = this.appConfig.auth as IAuth0AuthConfig;
      const dcConfig = auth0Config.dc[this.appConfig.env.dc];

      const modifiedURL = new URL(data.provisioning_ticket_url);
      modifiedURL.hostname = dcConfig.domain;

      return {
        ...data,
        provisioning_ticket_url: modifiedURL.toString(),
      };
    } else {
      return {
        ...data,
        provisioning_ticket_url: "",
      };
    }
  };

  public getIntegrations(): IPromise<ISSOConnection[]> {
    const accountName = storage.get("accountName");
    const url = this.env.getIntegrationsEndpoint(`/auth0/${accountName}/connections`);

    return this.$http.get<ISSOConnection[]>(url).then((response) => {
      if (response.data && response.data.length) {
        return response.data.map((integration) => this.replaceIntegrationUrlWithCustomUrl(integration));
      }

      return response.data;
    });
  }

  public addADConnection(adConnection: IPostSSOConnection): IPromise<ISSOConnection> {
    const accountName = storage.get("accountName");
    const url = this.env.getIntegrationsEndpoint("/auth0/" + accountName + "/connections");

    return this.$http.post<ISSOConnection>(url, adConnection).then((response) => this.replaceIntegrationUrlWithCustomUrl(response.data));
  }

  public patchADConnection(adConnection: Partial<IPostSSOConnection & { domain?: string }>): IPromise<ISSOConnection> {
    const accountName = storage.get("accountName");
    const url = this.env.getIntegrationsEndpoint("/auth0/" + accountName + "/connections");

    return this.$http.patch<ISSOConnection>(url, adConnection).then((response) => response.data);
  }

  public updateProfilePictureEditingPermission({ id, canEditPicture }: IPatchSSOConnection & { id: string }): IPromise<ISSOConnection> {
    const url = this.env.getIntegrationsEndpoint(`/auth0/connections/${id}`);

    return this.$http.patch<ISSOConnection>(url, { canEditPicture }).then((response) => this.replaceIntegrationUrlWithCustomUrl(response.data));
  }

  public deleteADConnection(): IPromise<void> {
    const accountName = storage.get("accountName");
    const url = this.env.getIntegrationsEndpoint("/auth0/" + accountName + "/connections");

    return this.$http.delete<void>(url).then((response) => response.data);
  }

  public getConfigActivities(): IPromise<ICollection<IConfigActivity>> {
    const url = this.env.getIntegrationsEndpoint("/config/all-activities");

    return this.$http.get<ICollection<IConfigActivity>>(url).then((collection) => collection.data);
  }

  public getSlackChannels(): IPromise<ISlackChannels> {
    const url = this.env.getApiEndpoint(`/automation/slack/channels`);

    return this.$http.get<ISlackChannels>(url).then((response) => response.data);
  }

  public getSlackChannelInfo(channelId: string): IPromise<IHttpResponse<ISlackChannelInfo>> {
    const channelIdEncoded = encodeURIComponent(channelId);
    const url = this.env.getApiEndpoint(encodeURI(`/automation/slack/channels/${channelIdEncoded}`));
    return this.$http.get<ISlackChannelInfo>(url);
  }

  public hasSlackIntegration(): IPromise<IHasSlackIntegration> {
    const url = this.env.getApiEndpoint(`/automation/slack/has-integration`);

    return this.$http.get<IHasSlackIntegration>(url).then((response) => response.data);
  }

  public getOIDCApplication<C>(): IPromise<C> {
    const url = this.env.getIntegrationsEndpoint(`/oidc/register`);

    return this.$http.get<C>(url, null).then((response) => response.data);
  }

  public updateOIDCDomain<C>(domain: OidcDomain): IPromise<C> {
    const url = this.env.getIntegrationsEndpoint("/oidc/register");

    return this.$http.put<C>(url, domain).then((result) => result.data);
  }

  public deleteOIDCDApplication(): IPromise<void> {
    const url = this.env.getIntegrationsEndpoint("/oidc/register");

    return this.$http.delete<void>(url).then((response) => response.data);
  }
}
