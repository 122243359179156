import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { UiLinkModule } from "@webapp/ui/link/link.module";

@Component({
  selector: "read-only-user-message",
  templateUrl: "./read-only-user-message.component.html",
  styleUrls: ["./read-only-user-message.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UiLinkModule, LocalizationModule, UiButtonModule, UiIconModule],
  host: {
    "class.widgetView": `widget-view`,
  },
})
export class ReadOnlyUserMessageComponent {
  @Input() public hideCloseButton: boolean = false;
  @Input() public widgetView: boolean = false;
  @Output() public readonly closeAction: EventEmitter<void> = new EventEmitter<void>();

  public emitCloseAction(): void {
    this.closeAction.emit();
  }
}
