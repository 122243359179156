import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiServiceV2 } from "@webapp/core/api/base-api-v2.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { IPostSqlFeedbackPayload } from "./models";

@Injectable({
  providedIn: "root",
})
export class PiSqlFeedbackApiService extends BaseApiServiceV2<IPostSqlFeedbackPayload> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig) {
    super("athena/feedback", new ApiVersionModel("v2"), httpClient, appConfig);
  }
}
