import { IModule, IRootScopeService, ISCEDelegateProvider } from "angular";
import { IAppConfig } from "@gtmhub/env";

export interface IRoutingRootScopeService extends IRootScopeService {
  cssUrl(relativePath: string): string;
}

export function configureCdn(mod: IModule): void {
  mod.config([
    "$sceDelegateProvider",
    "appConfig",
    ($sceDelegateProvider: ISCEDelegateProvider, appConfig: IAppConfig) => {
      const sceProviderList = ["self"];

      // Allow loading from our assets domain.  Notice the difference between * and **.
      if (appConfig.cdnStorage.enabled) {
        sceProviderList.push(`${appConfig.cdnStorage.url}/**`);
      }

      $sceDelegateProvider.trustedResourceUrlList(sceProviderList);
    },
  ]);

  mod.directive("img", [
    "appConfig",
    function (appConfig: IAppConfig) {
      const calculateNewSrcUsingCdn = (src: string) => {
        let newSrc = src.trim();

        // Replace image src attribute with the CDN URL, e.g.
        // /dist/img/hello.jpg -> https://cdn.quantivestatic.com/dist/img/hello.png
        // Ignore base64-encoded contents, e.g., `data:image/jpeg;base64,/9j/4AAQ`,
        // data blobs, e.g., `blob:http(s)://domain.com/path/image.jpg`
        // and absolute URLs in the src
        if (!src.startsWith("data:") && !src.startsWith("blob:") && !src.startsWith("//") && !src.startsWith("http")) {
          newSrc = appConfig.cdnStorage.url + src;
        }

        return newSrc;
      };

      return {
        restrict: "E",
        replace: false,
        link: function (scope, element, attr) {
          // detect the src change - we need to observe it in case it comes from a ng-src and gets compiled after we detect it
          if (appConfig.cdnStorage.enabled) {
            attr.$observe<string>("src", function (value) {
              if (!value) {
                attr.$set("src", null);
                return;
              }

              const newValue = calculateNewSrcUsingCdn(value);

              // we need that to break the recursion otherwise each change of the attribute triggers another recursion
              if (value != newValue) {
                // we set the attribute to the img tag - it will trigger another $observe
                attr.$set("src", newValue);
              }
            });
          }
        },
      };
    },
  ]);

  mod.run([
    "$rootScope",
    "appConfig",
    function ($rootScope: IRoutingRootScopeService, appConfig: IAppConfig) {
      $rootScope.cssUrl = function (relativePath: string) {
        let url = relativePath;

        if (appConfig.cdnStorage.enabled) {
          if (!relativePath.startsWith("/")) {
            relativePath = "/" + relativePath;
          }

          url = appConfig.cdnStorage.url + relativePath;
        }

        return `url('${url}')`;
      };
    },
  ]);
}
