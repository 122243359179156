import { StateService } from "@uirouter/angular";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { Screens } from "@webapp/navigation/models/shared.models";
import { BillingInfoService, UpgradeButtonState } from "@webapp/navigation/services/billing-info.service";
import { IsNavigationExpandedCache } from "@webapp/navigation/services/is-navigation-expanded.cache";
import { NavigationLifeCycleEvents } from "../../navigation.events";

@Component({
  selector: "sub-navigation",
  templateUrl: "./sub-navigation.component.html",
  styleUrls: ["./sub-navigation.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubNavigationComponent implements AfterViewInit, OnInit {
  @Input()
  public activeScreen: Screens | null;

  public accountIsInternal$: BehaviorSubject<boolean>;
  public showUpgrade$: Observable<boolean>;
  public isNavExpanded: boolean;
  public isTrial: boolean;
  public timeLeft: string;

  public constructor(
    private isNavigationExpandedCache: IsNavigationExpandedCache,
    private broadcastService: BroadcastService,
    private billingInfoService: BillingInfoService,
    private stateService: StateService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.isNavigationExpandedCache.get$().subscribe((value) => {
      this.isNavExpanded = value;
      this.cdr.markForCheck();
    });
    this.accountIsInternal$ = this.billingInfoService.getAccountIsInternal$();
    this.showUpgrade$ = this.billingInfoService.getUpgradeShouldBeShown$();
    this.isTrial = this.billingInfoService.getUpgradeButtonState() === UpgradeButtonState.TRIAL;
    this.timeLeft = this.billingInfoService.getTimeLeft();
  }

  public goToBilling(): void {
    this.stateService.go("gtmhub.editionPlanChange");
  }

  public ngAfterViewInit(): void {
    window.setTimeoutOutsideAngular(() => this.broadcastService.emit(NavigationLifeCycleEvents.SUB_NAV_LOADED));
  }
}
