import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { WidthResizeObservable } from "@quantive/ui-kit/core";
import { debounceTime, distinctUntilChanged } from "rxjs";

@UntilDestroy()
@Component({
  selector: "view-more",
  templateUrl: "view-more.component.html",
  styleUrls: ["view-more.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewMoreComponent implements AfterViewInit {
  public isExpanded = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef<HTMLElement>
  ) {}

  public ngAfterViewInit(): void {
    new WidthResizeObservable(document.body).pipe(debounceTime(200), distinctUntilChanged(), untilDestroyed(this)).subscribe(() => {
      if (!this.isExpanded) {
        this.checkOverflow();
      }
    });
  }

  private getContent(): { children: Element[]; toggleButton: Element } {
    const children = Array.from(this.elementRef.nativeElement.children);
    const [toggleButton] = children.splice(-1, 1);
    return { children, toggleButton };
  }

  private resetContentDisplay(): void {
    const { children } = this.getContent();
    for (const child of children) {
      child.classList.remove("hidden");
    }
  }

  private checkOverflow(): void {
    const { nativeElement: container } = this.elementRef;
    const { width: containerWidth } = container.getBoundingClientRect();
    const { children, toggleButton } = this.getContent();

    this.resetContentDisplay();

    toggleButton.classList.add("hidden");

    if (container.scrollWidth - containerWidth <= 1) {
      return;
    }

    toggleButton.classList.remove("hidden");

    let index = children.length - 1;
    while (container.scrollWidth - containerWidth > 1) {
      children[index--].classList.add("hidden");
    }
  }

  public toggleContent(): void {
    this.isExpanded = !this.isExpanded;
    this.cdr.detectChanges();

    if (this.isExpanded) {
      const { children } = this.getContent();
      const firstHiddenChild = <HTMLElement>children.find((child) => child.classList.contains("hidden"));

      this.elementRef.nativeElement.classList.add("expanded");
      this.resetContentDisplay();

      if (firstHiddenChild) {
        firstHiddenChild.focus();
      }
    } else {
      this.elementRef.nativeElement.classList.remove("expanded");
      this.checkOverflow();
    }
  }
}
