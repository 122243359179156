import { module } from "angular";
import upgrade from "@gtmhub/core/upgrade/module";
import { EditableCustomFieldsComponent } from "@webapp/custom-fields/components/editable-custom-fields/editable-custom-fields.component";
import { CustomFieldActions } from "./redux/custom-field-actions";
import { CustomFieldsService } from "./services/custom-fields.service";
import { QuerySessionsService } from "./services/query-sessions.service";

const mod = module("customFieldsCore", [upgrade]);

mod.service("CustomFieldActions", CustomFieldActions);
mod.service("CustomFieldsService", CustomFieldsService);
mod.service("QuerySessionsService", QuerySessionsService);

mod.downgradeComponent("editableCustomFields", { component: EditableCustomFieldsComponent, propagateDigest: false });
export default mod.name;
