<div class="rich-text-editor-icon" [ngClass]="{ outOfFocus: outOfFocus, disabled: disabled }">
  <svg *ngIf="icon === types.BOLD" width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.03708 2.4323L1.34396 2.4323V9.60206L1.03708 9.60205C0.533091 9.60204 0.12452 10.0106 0.124512 10.5146C0.124504 11.0186 0.533061 11.4271 1.03705 11.4272L5.54383 11.4272C7.2888 11.4272 8.70337 10.0126 8.70337 8.26764C8.70337 6.99103 7.94624 5.89126 6.85652 5.39286C7.10277 4.95642 7.24328 4.45238 7.24328 3.91553L7.24328 3.6179C7.24328 1.95512 5.89534 0.607178 4.23256 0.607178L1.03705 0.607197C0.533061 0.607205 0.124504 1.01578 0.124512 1.51977C0.12452 2.02376 0.533091 2.43231 1.03708 2.4323ZM3.16907 6.93321L3.16907 9.60207H5.54383C6.28082 9.60207 6.87826 9.00463 6.87826 8.26764C6.87826 7.53066 6.28082 6.93321 5.54383 6.93321L3.16907 6.93321ZM3.16907 5.10115L4.23256 5.10114C4.88736 5.10114 5.41817 4.57033 5.41817 3.91553V3.6179C5.41817 2.9631 4.88736 2.43229 4.23256 2.43229H3.16907L3.16907 5.10115Z" />
  </svg>

  <svg *ngIf="icon === types.ITALIC" width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.43026e-08 10.8488C3.77246e-08 10.5417 0.24892 10.2928 0.555979 10.2928L2.18422 10.2928L3.68661 1.78653L2.25477 1.78653C1.94771 1.78653 1.69879 1.53761 1.69879 1.23055C1.69879 0.923493 1.94771 0.674572 2.25477 0.674572L6.44402 0.674572C6.75108 0.674572 7 0.923493 7 1.23055C7 1.53761 6.75108 1.78653 6.44402 1.78653L4.81578 1.78653L3.31339 10.2928L4.74523 10.2928C5.05229 10.2928 5.30121 10.5417 5.30121 10.8488C5.30121 11.1559 5.05229 11.4048 4.74523 11.4048L0.555979 11.4048C0.24892 11.4048 1.08806e-08 11.1559 2.43026e-08 10.8488Z" />
  </svg>

  <svg *ngIf="icon === types.STRIKE" width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.1875 1.125C0.1875 0.81434 0.43934 0.5625 0.75 0.5625H11.25C11.5607 0.5625 11.8125 0.81434 11.8125 1.125V3.375C11.8125 3.68566 11.5607 3.9375 11.25 3.9375C10.9393 3.9375 10.6875 3.68566 10.6875 3.375V1.6875H6.5631V5.89511H11.2113C11.5433 5.89511 11.8125 6.14695 11.8125 6.45761C11.8125 6.76827 11.5433 7.02011 11.2113 7.02011L0.788838 7.02011C0.456754 7.02011 0.187545 6.76827 0.187545 6.45761C0.187545 6.14695 0.456754 5.89511 0.788839 5.89511H5.4381V1.6875H1.3125V3.375C1.3125 3.68566 1.06066 3.9375 0.75 3.9375C0.43934 3.9375 0.1875 3.68566 0.1875 3.375V1.125Z" />
    <path
      d="M5.43794 8.14502H6.56294V10.3129H8.25049C8.56115 10.3129 8.81299 10.5647 8.81299 10.8754C8.81299 11.1861 8.56115 11.4379 8.25049 11.4379H3.75049C3.43983 11.4379 3.18799 11.1861 3.18799 10.8754C3.18799 10.5647 3.43983 10.3129 3.75049 10.3129H5.43794V8.14502Z" />
  </svg>

  <svg *ngIf="icon === types.UNDERLINE" width="10" height="12" viewBox="0 0 10 12" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.67041 10.9106C8.67041 11.2472 8.50621 11.52 8.30366 11.52L1.94667 11.52C1.74412 11.52 1.57992 11.2472 1.57992 10.9106C1.57992 10.5741 1.74412 10.3013 1.94667 10.3013L8.30366 10.3013C8.50621 10.3013 8.67041 10.5741 8.67041 10.9106Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.36523 1.21547C9.36523 1.4995 9.13498 1.72976 8.85094 1.72976L8.11506 1.72976L8.11506 5.71642C8.11506 7.34055 6.79844 8.65717 5.17431 8.65717L4.83011 8.65717C3.20598 8.65717 1.88936 7.34055 1.88936 5.71642L1.88936 1.72976L1.1518 1.72976C0.867762 1.72976 0.637504 1.4995 0.637504 1.21547C0.637504 0.931429 0.867762 0.701172 1.1518 0.701172L3.6555 0.701172C3.93954 0.701172 4.1698 0.931429 4.1698 1.21547C4.1698 1.4995 3.93954 1.72976 3.6555 1.72976L2.91795 1.72976L2.91795 5.71642C2.91795 6.77248 3.77405 7.62858 4.83011 7.62858L5.17431 7.62858C6.23037 7.62858 7.08647 6.77248 7.08647 5.71642L7.08647 1.72976L6.34723 1.72976C6.0632 1.72976 5.83294 1.4995 5.83294 1.21547C5.83294 0.931429 6.0632 0.701172 6.34723 0.701172L8.85094 0.701172C9.13498 0.701172 9.36523 0.931429 9.36523 1.21547Z" />
  </svg>

  <svg *ngIf="icon === types.ORDERED" width="14" height="13" viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.32178 10.25C5.32178 9.83579 5.63781 9.5 6.02766 9.5H12.6159C13.0057 9.5 13.3218 9.83579 13.3218 10.25C13.3218 10.6642 13.0057 11 12.6159 11H6.02766C5.63781 11 5.32178 10.6642 5.32178 10.25Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.4134 0.318623C3.6795 0.44101 3.85001 0.707111 3.85001 1.00001V5.00001C3.85001 5.41422 3.51423 5.75001 3.10001 5.75001C2.6858 5.75001 2.35001 5.41422 2.35001 5.00001V2.63072L1.98812 2.94093C1.67363 3.21051 1.20016 3.17409 0.930582 2.85961C0.661008 2.54512 0.697419 2.07164 1.01191 1.80207L2.61191 0.430579C2.83429 0.239957 3.14729 0.196235 3.4134 0.318623Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.74552 8.75152C2.66493 8.74616 2.58526 8.75514 2.51305 8.77623C2.44078 8.79735 2.38284 8.82854 2.34042 8.86174C2.29822 8.89477 2.27503 8.92671 2.2632 8.95003C2.07584 9.31945 1.62449 9.46704 1.25507 9.27968C0.885652 9.09232 0.738066 8.64096 0.925426 8.27154C1.04391 8.03792 1.21409 7.83848 1.41589 7.68053C1.61747 7.52276 1.84875 7.40761 2.09237 7.33643C2.33606 7.26523 2.59194 7.23799 2.84503 7.25482C3.0981 7.27165 3.34831 7.33254 3.58008 7.43717C3.81185 7.5418 4.02474 7.69007 4.19997 7.8792C4.37561 8.06878 4.51017 8.2962 4.58489 8.54993C4.65984 8.80446 4.67046 9.07205 4.61487 9.33219C4.55943 9.59167 4.44139 9.82901 4.27821 10.0308C4.26998 10.0409 4.26148 10.0509 4.25273 10.0606L3.18315 11.25H3.90001C4.31422 11.25 4.65001 11.5858 4.65001 12C4.65001 12.4142 4.31422 12.75 3.90001 12.75H1.50001C1.20388 12.75 0.935479 12.5758 0.814949 12.3053C0.694419 12.0348 0.744333 11.7187 0.942347 11.4985L3.11993 9.07705C3.13789 9.05228 3.14514 9.03208 3.14798 9.01876C3.15108 9.00427 3.1508 8.99004 3.14598 8.97365C3.14092 8.95647 3.12858 8.92988 3.09965 8.89866C3.07031 8.867 3.02548 8.83256 2.96291 8.80432C2.90036 8.77608 2.82614 8.75688 2.74552 8.75152Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.32178 3.25C5.32178 2.83579 5.63781 2.5 6.02766 2.5H12.6159C13.0057 2.5 13.3218 2.83579 13.3218 3.25C13.3218 3.66421 13.0057 4 12.6159 4H6.02766C5.63781 4 5.32178 3.66421 5.32178 3.25Z" />
  </svg>

  <svg *ngIf="icon === types.BULLET" width="14" height="12" viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.75 6C4.75 5.58579 5.08579 5.25 5.5 5.25H12.5C12.9142 5.25 13.25 5.58579 13.25 6C13.25 6.41421 12.9142 6.75 12.5 6.75H5.5C5.08579 6.75 4.75 6.41421 4.75 6Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.75 10.5C4.75 10.0858 5.08579 9.75 5.5 9.75H12.5C12.9142 9.75 13.25 10.0858 13.25 10.5C13.25 10.9142 12.9142 11.25 12.5 11.25H5.5C5.08579 11.25 4.75 10.9142 4.75 10.5Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.75 1.5C4.75 1.08579 5.08579 0.75 5.5 0.75H12.5C12.9142 0.75 13.25 1.08579 13.25 1.5C13.25 1.91421 12.9142 2.25 12.5 2.25H5.5C5.08579 2.25 4.75 1.91421 4.75 1.5Z" />
    <path d="M3.25 1.5C3.25 2.19036 2.69036 2.75 2 2.75C1.30964 2.75 0.75 2.19036 0.75 1.5C0.75 0.809644 1.30964 0.25 2 0.25C2.69036 0.25 3.25 0.809644 3.25 1.5Z" />
    <path d="M3.25 10.5C3.25 11.1904 2.69036 11.75 2 11.75C1.30964 11.75 0.75 11.1904 0.75 10.5C0.75 9.80964 1.30964 9.25 2 9.25C2.69036 9.25 3.25 9.80964 3.25 10.5Z" />
    <path d="M3.25 6C3.25 6.69036 2.69036 7.25 2 7.25C1.30964 7.25 0.75 6.69036 0.75 6C0.75 5.30964 1.30964 4.75 2 4.75C2.69036 4.75 3.25 5.30964 3.25 6Z" />
  </svg>

  <svg *ngIf="icon === types.LINK" width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.87218 4.12782C9.14677 4.4024 9.14677 4.8476 8.87218 5.12218L5.12218 8.87218C4.8476 9.14677 4.4024 9.14677 4.12782 8.87218C3.85323 8.5976 3.85323 8.1524 4.12782 7.87782L7.87782 4.12782C8.1524 3.85323 8.5976 3.85323 8.87218 4.12782Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.18469 5.0653C4.45927 5.33989 4.45927 5.78508 4.18469 6.05967L2.30968 7.93467C1.54874 8.69562 1.54874 9.92935 2.30968 10.6903C3.07063 11.4512 4.30437 11.4512 5.06531 10.6903L6.94031 8.8153C7.2149 8.54071 7.6601 8.54071 7.93468 8.8153C8.20927 9.08989 8.20927 9.53508 7.93468 9.80967L6.05968 11.6847C4.74956 12.9948 2.62544 12.9948 1.31532 11.6847C0.00519198 10.3745 0.00519224 8.25042 1.31532 6.9403L3.19032 5.0653C3.4649 4.79071 3.9101 4.79071 4.18469 5.0653Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.81531 7.9347C8.54073 7.66011 8.54073 7.21492 8.81531 6.94033L10.6903 5.06533C11.4513 4.30438 11.4513 3.07064 10.6903 2.3097C9.92937 1.54875 8.69563 1.54875 7.93469 2.3097L6.05969 4.1847C5.7851 4.45929 5.3399 4.45929 5.06532 4.1847C4.79073 3.91011 4.79073 3.46492 5.06532 3.19033L6.94032 1.31533C8.25044 0.00520954 10.3746 0.00520987 11.6847 1.31533C12.9948 2.62545 12.9948 4.74958 11.6847 6.0597L9.80968 7.9347C9.5351 8.20929 9.0899 8.20929 8.81531 7.9347Z" />
  </svg>

  <svg *ngIf="icon === types.GIF" width="18" height="18" viewBox="0 0 16 9" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.39355 8.19061C5.30592 8.19061 6.43154 7.0738 6.51372 5.39276C6.51694 5.32688 6.51855 5.26014 6.51855 5.19256V4.75035C6.51855 4.26695 6.24512 3.98375 5.78125 3.98375H4.05762C3.70117 3.98375 3.4668 4.18394 3.4668 4.52086C3.4668 4.85777 3.70605 5.05797 4.05762 5.05797H5.08301L5.07812 5.50506C5.04883 6.37909 4.37988 6.96991 3.41797 6.96991C2.24609 6.96991 1.50391 6.03729 1.50391 4.50897C1.50391 4.44434 1.5052 4.38086 1.50778 4.31854C1.59307 2.25586 1.89 1.31836 3.36426 1.31836C4.2259 1.31836 4.57031 1.62598 4.91699 2.25586C5.09766 2.55371 5.31738 2.68555 5.62988 2.68555C6.01562 2.68555 6.27441 2.42676 6.27441 2.05078C6.27441 1.90918 6.25 1.76758 6.16699 1.59668C5.77148 0.732422 4.91699 0.0976562 3.35938 0.0976562C0.802792 0.0976562 0 1.76954 0.00380092 4.31854C0.00127222 4.38764 0 4.45763 0 4.5285C0 6.81854 1.28906 8.19061 3.39355 8.19061Z" />
    <path
      d="M8.73242 8.1662C9.19629 8.1662 9.46973 7.88299 9.46973 7.3996V0.771484C9.46973 0.283203 9.19629 0 8.73242 0C8.26855 0 7.99512 0.283203 7.99512 0.771484V7.3996C7.99512 7.88299 8.26855 8.1662 8.73242 8.1662Z" />
    <path
      d="M11.8789 8.1662C12.3428 8.1662 12.6162 7.88299 12.6162 7.3996V4.37012H14.9355C15.3213 4.37012 15.5654 4.15039 15.5654 3.78418C15.5654 3.42285 15.3164 3.20312 14.9355 3.20312H12.6162V1.31836H15.1748C15.5752 1.31836 15.8438 1.08887 15.8438 0.708008C15.8438 0.327148 15.5801 0.0976562 15.1748 0.0976562H11.8789C11.415 0.0976562 11.1416 0.380859 11.1416 0.864258V7.3996C11.1416 7.88299 11.415 8.1662 11.8789 8.1662Z" />
  </svg>

  <svg *ngIf="icon === types.MENTION" width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.72656 13.7031C8.55469 13.7031 9.35938 13.6016 9.96875 13.4141V12.1641C9.53125 12.3281 8.67969 12.4375 7.84375 12.4375C4.52344 12.4375 2.42188 10.4219 2.42188 7.24219V7.22656C2.42188 4.10156 4.52344 1.92188 7.54688 1.92188C10.5156 1.92188 12.4688 3.77344 12.4688 6.59375V6.60938C12.4688 8.21094 11.9609 9.24219 11.1719 9.24219C10.6953 9.24219 10.4219 8.91406 10.4219 8.35938V4.17969H8.84375V5.11719H8.70312C8.39062 4.42188 7.71094 4.00781 6.875 4.00781C5.3125 4.00781 4.25 5.30469 4.25 7.19531V7.21094C4.25 9.1875 5.32812 10.5078 6.95312 10.5078C7.85938 10.5078 8.5 10.0938 8.82812 9.30469H8.96875L8.97656 9.35156C9.15625 10.0781 9.86719 10.5312 10.8281 10.5312C12.7188 10.5312 13.8984 8.96875 13.8984 6.49219V6.47656C13.8984 3.03906 11.3359 0.679688 7.59375 0.679688C3.6875 0.679688 0.992188 3.33594 0.992188 7.1875V7.20312C0.992188 11.125 3.65625 13.7031 7.72656 13.7031ZM7.33594 9.07031C6.51562 9.07031 6.02344 8.38281 6.02344 7.25781V7.24219C6.02344 6.10938 6.51562 5.42969 7.34375 5.42969C8.17188 5.42969 8.69531 6.125 8.69531 7.24219V7.25781C8.69531 8.375 8.17188 9.07031 7.33594 9.07031Z" />
  </svg>

  <svg *ngIf="icon === types.EMOJI" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.3 6.5C1.3 3.62812 3.62812 1.3 6.5 1.3C6.6958 1.3 6.88883 1.31079 7.07858 1.33177C7.43539 1.37121 7.75662 1.11393 7.79606 0.757122C7.83551 0.400311 7.57823 0.0790827 7.22142 0.0396392C6.98433 0.0134307 6.74361 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13C10.0899 13 13 10.0899 13 6.5C13 6.25639 12.9866 6.01567 12.9604 5.77858C12.9209 5.42177 12.5997 5.16449 12.2429 5.20394C11.8861 5.24338 11.6288 5.56461 11.6682 5.92142C11.6892 6.11117 11.7 6.3042 11.7 6.5C11.7 9.37188 9.37188 11.7 6.5 11.7C3.62812 11.7 1.3 9.37188 1.3 6.5Z" />
    <path
      d="M9.64072 8.81073C9.83994 8.5121 9.75936 8.10851 9.46073 7.90928C9.1621 7.71006 8.75851 7.79064 8.55929 8.08927C8.36292 8.38361 8.07312 8.63883 7.71017 8.82031C7.34705 9.00187 6.92938 9.1 6.50001 9.1C6.07064 9.1 5.65297 9.00188 5.28985 8.82033C4.92689 8.63886 4.63708 8.38363 4.44071 8.0893C4.24148 7.79067 3.83789 7.7101 3.53926 7.90933C3.24064 8.10856 3.16006 8.51215 3.35929 8.81078C3.69037 9.30702 4.15871 9.70821 4.70849 9.98309C5.25811 10.2579 5.87564 10.4 6.50002 10.4C7.1244 10.4 7.74193 10.2579 8.29155 9.98307C8.84132 9.70818 9.30965 9.30698 9.64072 8.81073Z" />
    <path
      d="M4.225 6.6625C4.67373 6.6625 5.0375 6.29873 5.0375 5.85C5.0375 5.40127 4.67373 5.0375 4.225 5.0375C3.77627 5.0375 3.4125 5.40127 3.4125 5.85C3.4125 6.29873 3.77627 6.6625 4.225 6.6625Z" />
    <path d="M9.5875 5.85C9.5875 6.29873 9.22373 6.6625 8.775 6.6625C8.32627 6.6625 7.9625 6.29873 7.9625 5.85C7.9625 5.40127 8.32627 5.0375 8.775 5.0375C9.22373 5.0375 9.5875 5.40127 9.5875 5.85Z" />
    <path
      d="M8.45 2.275C8.45 1.91601 8.74102 1.625 9.1 1.625H10.075V0.65C10.075 0.291015 10.366 0 10.725 0C11.084 0 11.375 0.291015 11.375 0.65V1.625H12.35C12.709 1.625 13 1.91601 13 2.275C13 2.63399 12.709 2.925 12.35 2.925H11.375V3.9C11.375 4.25899 11.084 4.55 10.725 4.55C10.366 4.55 10.075 4.25899 10.075 3.9V2.925H9.1C8.74102 2.925 8.45 2.63399 8.45 2.275Z" />
  </svg>

  <svg class="post-comment-icon" *ngIf="icon === types.POST_COMMENT" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" />
    <path d="M8.3624 12L6.51668 18.0282L19.4916 12L6.51679 5.98967L8.3624 12ZM8.3624 12L12.072 12" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>

  <svg class="okr-link-selector-icon" *ngIf="icon === types.OKR" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0001 8.00003H4.00005M8.00007 12V3.99998M14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8Z"
      stroke="#617294"
      stroke-width="1.5" />
  </svg>

  <i *ngIf="icon === types.LOADING" [uiType]="types.LOADING" ui-icon></i>
</div>
