import { copy, equals } from "angular";
import md5 from "md5";
import deletedAvatarUrl from "wwwroot/img/avatars/unknown.svg";
import { MISSING_SESSION_ID } from "@gtmhub/goals/models";
import { pushOrReplace, unique } from "@gtmhub/util";
import {
  GridFilterEntry,
  GridFilterOperator,
  GridFilterValue,
  IArrayGridFilter,
  IDateRange,
  IDynamicValue,
  IGridFilter,
  IGridFilterDefinition,
  OrArrayFiltersGroup,
  OrFiltersGroup,
  TagAvatar,
  TeamsPlusMoreDynamicValues,
} from "./models";

export function isTeamsPlusMoreDynamicValue(v: IDynamicValue): v is TeamsPlusMoreDynamicValues {
  return v.type === "teamPlusMembers" || v.type === "teamOrg" || v.type === "teamOrgPlusMembers";
}

const serverComposedDynamicFilters: Set<string> = new Set(["sessionId_activeSessions"]);
export const isServerComposedFilter = (field: string): boolean => serverComposedDynamicFilters.has(field);

export const dynamicValueEquals = (a: IDynamicValue, b: IDynamicValue): boolean => {
  if (isTeamsPlusMoreDynamicValue(a) && isTeamsPlusMoreDynamicValue(b)) {
    return a.type === b.type && a.teamId === b.teamId;
  }

  return a.type === b.type;
};

export const cleanupDynamicValueEvaluated = (dynamicValue: IDynamicValue): IDynamicValue => {
  const { evaluated, ...cleanDynamicValue } = dynamicValue;
  return cleanDynamicValue;
};

export const isPositiveOperator = (operator: GridFilterOperator): boolean =>
  (<GridFilterOperator[]>["notEqual", "isNotOneOf", "doesNotContain", "isNotBetween"]).every((negativeOperator) => negativeOperator !== operator);

export const isRangeOperator = (operator: GridFilterOperator): boolean => operator === "isBetween" || operator === "isNotBetween";

export const isMissingOrEmptyOperator = (operator: GridFilterOperator): boolean => operator === "missingOrEmpty";

export const entryValue = (entry: GridFilterEntry): string | number | boolean => (typeof entry === "string" ? entry : entry.value);

export const entryDisplayName = (entry: GridFilterEntry): string => (typeof entry === "string" ? entry : entry.displayName);

export const expandFilterValue = (filter: IArrayGridFilter): string[] => {
  const evaluatedDynamicValues = (filter.dynamicValues || []).reduce<string[]>((values, dynamicValue) => [...values, ...(dynamicValue.evaluated || [])], []);
  return unique([...(filter.value || []), ...evaluatedDynamicValues]);
};

export const hasFilterValue = (filter: IGridFilter, options: { excludeMissingSessionFilter: boolean } = { excludeMissingSessionFilter: false }): boolean => {
  const hasDynamicValues = filter.dataType === "array" && (filter.dynamicValues || []).length > 0;
  if (hasDynamicValues || isServerComposedFilter(filter.fieldName) || filter.operator === "missingOrEmpty") {
    return true;
  }

  const value = filter.value;

  if (Array.isArray(value)) {
    if (options.excludeMissingSessionFilter && value.length === 1 && value[0] === MISSING_SESSION_ID) {
      return false;
    }
    return value.length > 0;
  }

  if (filter.dataType === "date") {
    return value && (typeof value !== "object" || !!(value as IDateRange).startDate);
  }

  return value === 0 || value === false || !!value;
};

export const hasInsightBoardFilterValue = (filter: IGridFilter, options: { excludeMissingSessionFilter: boolean } = { excludeMissingSessionFilter: false }): boolean => {
  const hasDynamicValues = "dynamicValues" in filter && filter.dynamicValues.length;
  if (hasDynamicValues || isServerComposedFilter(filter.fieldName) || filter.operator === "missingOrEmpty") {
    return true;
  }

  const value = filter.value;

  if (Array.isArray(value)) {
    if (options.excludeMissingSessionFilter && value.length === 1 && value[0] === MISSING_SESSION_ID) {
      return false;
    }
    return value.length > 0;
  }

  if (filter.fieldName === "date_range" && (filter.value as string[]).find((value) => value === "this quarter")) return true;

  return !!value;
};

export function hasFiltersGroupsWithValues(filters: OrFiltersGroup[]): boolean {
  return filters.some((filtersGroup) => hasFiltersGroupWithValues(filtersGroup));
}

export const hasFiltersGroupWithValues = (filtersGroup: OrFiltersGroup): boolean => {
  const filtersWithValues = filtersGroup.filter((filter) => hasFilterValue(filter));
  return filtersWithValues.length > 0;
};

export const hasSelectedFilterValue = (filter: IGridFilter, value: GridFilterValue): boolean => {
  if (filter.dataType !== "array") {
    return equals(filter.value, value);
  }
  if (hasValue(filter.value, value)) {
    return true;
  }
  return filter.dynamicValues?.some((values) => hasValue(values.evaluated, value));
};

function hasValue(values: string[] | undefined, value: GridFilterValue): boolean {
  return values?.some((v) => (Array.isArray(value) ? value.includes(v) : value === v));
}

export const createFilterDefaultValue = (definition: IGridFilterDefinition, operator?: GridFilterOperator): GridFilterValue => {
  if (!operator) {
    operator = definition.defaultOperator;
  }

  if (typeof definition.defaultValue !== "undefined") {
    return copy(definition.defaultValue);
  }

  if (definition.dataType === "array") {
    return [];
  }

  if (definition.dataType === "date" && isRangeOperator(operator)) {
    return { startDate: undefined, endDate: undefined };
  }

  if (definition.fieldName === "isActive") {
    return false;
  }

  return undefined;
};

export const createFilter = (definition: IGridFilterDefinition): IGridFilter => ({
  dataType: definition.dataType,
  fieldName: definition.fieldName,
  operator: definition.defaultOperator,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: createFilterDefaultValue(definition) as any,
});

/**
 * Removes a value from all grid filters, specified by fieldName, in every filters group and returns the modified filters groups
 * @param filters filters groups to be modified
 * @param args specified field name, value to be removed, create new filter method
 * @returns the modified filters groups with all their specified filters lacking the args value passed
 */
export function removeValueFromFiltersInEveryGroup(
  filters: OrFiltersGroup[],
  args: { fieldName: string; value: string; createFilter(value: string[]): IArrayGridFilter }
): OrFiltersGroup[] {
  return filters.map((filtersGroup) => {
    return filtersGroup.map((filter) => {
      if (filter.fieldName === args.fieldName) {
        const newFilterValue = expandFilterValue(<IArrayGridFilter>filter).filter((value) => value !== args.value);
        return args.createFilter(newFilterValue);
      }

      return filter;
    });
  });
}

/**
 * Adds a value to all grid filters, specified by fieldName, in every filters group, or creates a new filter with the fieldName given containing the value if the group has no such filters, and returns the modified filters groups
 * @param filters filters groups to be modified
 * @param args specified field name, value to be added, create new filter method
 * @returns the modified filters groups, containing at least 1 filter, specified by fieldName, with all their specified filters having the args value passed
 */
export function addValueToExistingFiltersOrCreateNewFilterInEveryGroup(
  filters: OrFiltersGroup[],
  args: { fieldName: string; value: string; createFilter(value: string[]): IArrayGridFilter }
): OrFiltersGroup[] {
  return filters.map((filtersGroup) => {
    if (!filtersGroup.some((filter) => filter.fieldName === args.fieldName)) {
      return pushOrReplace(filtersGroup, args.createFilter([args.value]));
    }

    return filtersGroup.map((filter) => {
      if (filter.fieldName === args.fieldName) {
        const newFilterValue = expandFilterValue(<IArrayGridFilter>filter);
        if (!newFilterValue.includes(args.value)) {
          newFilterValue.push(args.value);

          return args.createFilter(newFilterValue);
        }
      }

      return filter;
    });
  });
}

/**
 * Creates and returns a Set containing the identifiers of all values that are contained in all target filters in every filters group
 * @param filters filters groups to be iterated
 * @param args the field name of the filters we are checking for values
 * @returns a Set containing the identifiers of all values that are contained in all target filters in every filters group
 */
export function getCommonValuesUsedInAllFiltersInEveryGroup(filters: OrFiltersGroup[], args: { fieldName: string }): Set<string> {
  const selectionSet = new Set<string>();
  const hasTargetFiltersInAllGroups = filters.every((filtersGroup) => filtersGroup.some((filter) => filter.fieldName === args.fieldName));

  if (hasTargetFiltersInAllGroups) {
    const targetFiltersValues: string[][] = getTargetFilterValuesFromAllGroups(filters, args);

    const targetsUsageMap: Map<string, number> = targetFiltersValues.reduce((usageMap: Map<string, number>, targetValues: string[]) => {
      targetValues.forEach((targetId) => {
        const usageCount = usageMap.get(targetId) || 0;
        usageMap.set(targetId, usageCount + 1);
      });
      return usageMap;
    }, new Map<string, number>());

    const totalTargetFiltersCount = targetFiltersValues.length;
    targetsUsageMap.forEach((usageCount: number, targetId: string) => {
      if (usageCount === totalTargetFiltersCount) {
        selectionSet.add(targetId);
      }
    });
  }

  return selectionSet;
}

export const getTargetFilterValuesFromAllGroups = (filters: OrFiltersGroup[], args: { fieldName: string }): string[][] => {
  return filters.reduce((targetValuesArr: string[][], filtersGroup: OrFiltersGroup) => {
    const targetFiltersInGroup = <OrArrayFiltersGroup>filtersGroup.filter((filter) => filter.fieldName === args.fieldName);
    return [...targetValuesArr, ...targetFiltersInGroup.map((filter) => expandFilterValue(filter))];
  }, []);
};

export function removeFiltersByFieldNameAndEmptyGroups(filters: OrFiltersGroup[], fieldName: string): OrFiltersGroup[] {
  return removeEmptyGroupsFromFilters(removeFiltersByFieldNameFromAllGroups(filters, fieldName));
}

function removeFiltersByFieldNameFromAllGroups(filters: OrFiltersGroup[], fieldName: string): OrFiltersGroup[] {
  return filters.map((filtersGroup) => filtersGroup.filter((filter) => filter.fieldName !== fieldName));
}

function removeEmptyGroupsFromFilters(filters: OrFiltersGroup[]): OrFiltersGroup[] {
  return filters.filter((filtersGroup) => !!filtersGroup.length);
}

export const tagAvatar = (args: { picture?: string; email?: string; avatar?: string; color?: string; isUnknown?: boolean }): TagAvatar => {
  if (args.isUnknown) {
    return { picture: deletedAvatarUrl };
  }

  if (args.picture) {
    return { picture: args.picture };
  }

  if (args.avatar) {
    return { icon: args.avatar, color: args.color || "#87a6bc" };
  }

  if (args.email) {
    return { picture: `//www.gravatar.com/avatar/${md5(args.email)}?d=retro` };
  }

  return { picture: deletedAvatarUrl };
};
