import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { TopNavBarModule } from "@webapp/top-nav-bar/top-nav-bar.module";

@Component({
  selector: "user-notification-settings-top-nav-bar",
  templateUrl: "./user-notification-settings-top-nav-bar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TopNavBarModule, LocalizationModule],
})
export class UserNotificationSettingsTopNavBarComponent {}
