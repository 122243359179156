import circleCheckIconUrl from "wwwroot/img/icons/circle-check.svg";

export function getCopyURLNotificationEl(): HTMLDivElement {
  const notificationContainer = document.createElement("div");
  notificationContainer.style.cssText = `
      position: fixed;
      left: 50%;
      width: 192px;
      height: 46px;
      margin-left: -96px;
      top: 41px;
      background: #00A688;
      text-align: center;
      border-radius: 8px;
      padding: 12px;
      color: #fff;
      z-index: 9999;
    `;

  const icon = new Image();
  icon.src = circleCheckIconUrl;
  icon.style.cssText = `
      width: 22px;
      display: inline-block;
      margin-right: 9px;
    `;

  notificationContainer.appendChild(icon);

  return notificationContainer;
}

export function defaultCopyURLNotificationEl(): HTMLDivElement {
  const notificationContainer = document.createElement("div");
  notificationContainer.style.cssText = `
    position: fixed;
    left: 50%;
    width: 300px;
    margin-left: -150px;
    top: 50%;
    background: rgba(0, 0, 0, 0.7);
    text-align: center;
    border-radius: 30px;
    padding: 10px;
    color: #fff;
    z-index: 9999;
  `;

  return notificationContainer;
}

export function scrollToTop(querySelector: string, top = 0): void {
  document.querySelector(querySelector).scrollTo({ top });
}
