import { Directive, Optional, TemplateRef } from "@angular/core";
import { UiModalRef } from "../abstracts/modal-ref";

@Directive({
  selector: "[uiModalOptionsButton]",
  exportAs: "uiModalOptionsButton",
  standalone: true,
})
export class UiModalOptionsButtonDirective {
  constructor(templateRef: TemplateRef<object>, @Optional() modalRef?: UiModalRef) {
    if (modalRef) {
      // This creates a new micro-task and prevents the following error:
      // ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
      Promise.resolve().then(() => modalRef.updateConfig({ uiModalOptionsButton: templateRef }));
    }
  }
}
