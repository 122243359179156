import { Injectable } from "@angular/core";
import { Observable, switchMap } from "rxjs";
import { QuantivePlusWhiteboardDTO } from "@webapp/platform-intelligence/shared/services/quantive-plus-whiteboard/models";
import { IQuantivePlusWhiteboardSuggestionsFacade } from "@webapp/platform-intelligence/shared/services/quantive-plus-whiteboard/quantive-plus-whiteboard-facade.interface";
import { QuantivePlusWhiteboardSuggestionsProviderFactory } from "@webapp/platform-intelligence/shared/services/quantive-plus-whiteboard/quantive-plus-whiteboard-provider-factory.service";

@Injectable({
  providedIn: "any",
})
export class QuantivePlusWhiteboardFacade implements IQuantivePlusWhiteboardSuggestionsFacade {
  constructor(private quantivePlusWhiteboardProviderFactory: QuantivePlusWhiteboardSuggestionsProviderFactory) {}

  public getWhiteboardSuggestions(payload: unknown): Observable<QuantivePlusWhiteboardDTO> {
    return this.quantivePlusWhiteboardProviderFactory.getInstance("whiteboard").pipe(switchMap((provider) => provider.getWhiteboardSuggestions(payload)));
  }
}
