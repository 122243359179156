import { IHttpService, IPromise, noop } from "angular";
import { EnvironmentService } from "@gtmhub/env";
import { ICollection } from "@webapp/core/core.models";
import { IDataSource } from "../datasources/models/models";
import { IConnection, IConnectorParams, ICreateConnectorParams, IOAuthRequestParams, IPatchConnectorParams } from "./models";

export class ConnectorService {
  public static $inject = ["$http", "EnvironmentService"];

  constructor(
    private $http: IHttpService,
    private env: EnvironmentService
  ) {}

  public getConnections(connectorName: string): IPromise<IConnection[]> {
    const url = this.env.getApiEndpoint("/connectors/datasourceblueprint/" + connectorName);

    return this.$http.get<ICollection<IConnection>>(url).then((response) => response.data.items);
  }

  public getConnection(connectionId: string): IPromise<IConnection> {
    const url: string = this.env.getApiEndpoint(`/connectors/${connectionId}`);

    return this.$http.get<IConnection>(url).then((response) => response.data);
  }

  public getConnectionDataSources(id: string): IPromise<ICollection<IDataSource>> {
    const url: string = this.env.getApiEndpoint(`/connectors/${id}/datasources`);

    return this.$http.get<ICollection<IDataSource>>(url).then((response) => response.data);
  }

  public deleteConnection(id: string): IPromise<void> {
    const url: string = this.env.getApiEndpoint(`/connectors/${id}`);

    return this.$http.delete(url).then(noop);
  }

  public getOAuthURL(oAuthRequestParams: IOAuthRequestParams): IPromise<string> {
    let url = this.env.getApiEndpoint("/auth/" + oAuthRequestParams.dataSourceName + "/url");
    const returnUrlAbs = oAuthRequestParams.returnUrl;
    const redirectURI = this.env.getApiEndpoint("/auth/callback");

    let qs =
      "returnURL=" +
      encodeURIComponent(returnUrlAbs) +
      "&redirectURI=" +
      encodeURIComponent(redirectURI) +
      "&connectionParameters=" +
      encodeURIComponent(oAuthRequestParams.connectionParameters) +
      "&connectionName=" +
      encodeURIComponent(oAuthRequestParams.connectionName) +
      "&userId=" +
      encodeURIComponent(oAuthRequestParams.userId);

    if (oAuthRequestParams.connectionId) {
      qs += "&connectionId=" + encodeURIComponent(oAuthRequestParams.connectionId);
    }

    url = url + "?" + qs;

    return this.$http.get<string>(url).then((response) => response.data);
  }

  public postOAuthURL(oAuthRequestParams: IOAuthRequestParams): IPromise<string> {
    const url = this.env.getApiEndpoint("/auth/" + oAuthRequestParams.dataSourceName + "/url");
    const returnUrlAbs = oAuthRequestParams.returnUrl;
    const redirectURI = this.env.getApiEndpoint("/auth/callback");

    const data = {
      returnURL: returnUrlAbs,
      redirectURI: redirectURI,
      connectionParameters: oAuthRequestParams.connectionParameters,
      connectionName: oAuthRequestParams.connectionName,
      userId: oAuthRequestParams.userId,

      ...(oAuthRequestParams.connectionId && {
        connectionId: "oAuthRequestParams.connectionId",
      }),
    };

    return this.$http.post<string>(url, data).then((response) => response.data);
  }

  public createConnection(params: ICreateConnectorParams): IPromise<IConnection> {
    const url = this.env.getApiEndpoint("/connectors/datasourceblueprint/" + params.dataSourceBlueprintName);

    return this.$http.post<IConnection>(url, params).then((response) => response.data);
  }

  public editConnection(connectionId: string, params: IConnectorParams): IPromise<IConnection> {
    const url: string = this.env.getApiEndpoint(`/connectors/${connectionId}`);

    return this.$http.put<IConnection>(url, params).then((response) => response.data);
  }

  public patchConnection(connectionId: string, params: IPatchConnectorParams): IPromise<void> {
    const url: string = this.env.getApiEndpoint(`/connectors/${connectionId}`);

    return this.$http.patch<void>(url, params).then(() => undefined);
  }
}
