import { ChangeDetectionStrategy, Component } from "@angular/core";
import { NzListPaginationComponent } from "ng-zorro-antd/list";

@Component({
  selector: "ui-list-pagination",
  exportAs: "uiListPagination",
  templateUrl: "./list-pagination.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class UiListPaginationComponent extends NzListPaginationComponent {}
