import { IPromise, IQService } from "angular";
import { IModalScope } from "angular-ui-bootstrap";
import { firstValueFrom } from "rxjs";
import { IStateInit } from "@gtmhub/core/routing";
import { IUIError } from "@gtmhub/error-handling";
import { IFeedBadgeAssignItem, IFeedEntry, IFeedEntryTypeEnum } from "@gtmhub/feed/models";
import { FeedActions } from "@gtmhub/feed/redux/feed-actions";
import { IFeedStoreState } from "@gtmhub/feed/redux/feed-reducer";
import { getTargetTypeForComments } from "@gtmhub/feed/utils";
import { MetricService } from "@gtmhub/okrs/metrics/services/metric.service";
import { IExtendedRootScopeService } from "@gtmhub/shared/ng-extensions";
import { INgRedux } from "@gtmhub/state-management";
import { Comment } from "@webapp/comments";
import { CommentsOrdering } from "@webapp/comments/components/comments-ordering/comments-ordering.models";
import { GoalsFacade } from "@webapp/okrs/goals/services/goals-facade.service";

export interface IFeedCommentsScope extends IModalScope {
  params: IFeedCommentsStateParams;
  feedEntry: IFeedEntry;
  feedFetched: boolean;
  reduxItemsError: IUIError;
  getTargetTypeForComments(type: string): string;
  updateFeedEntryComments(comments: Comment[]): void;
  commentsSortingChanged($event: CommentsOrdering): void;
  commentsOrdering: CommentsOrdering;
}

interface IFeedCommentsStateParams {
  targetId: string;
}

const bindStateToScope = (state: IFeedStoreState) => {
  if (state.feed.selectedFeedEntry) {
    state.feed.selectedFeedEntry.displayed = true;
  }

  return {
    feedEntry: state.feed.selectedFeedEntry,
    feedFetched: state.feed.isFetched,
    reduxItemsError: state.feed.error,
  };
};

export class FeedCommentsThreadCtrl implements IStateInit {
  public static $inject = ["$scope", "$stateParams", "$ngRedux", "$q", "FeedActions", "MetricService", "GoalsFacade"];

  private entityOwnersSet: Set<string>;

  constructor(
    private $scope: IFeedCommentsScope & IExtendedRootScopeService,
    private $stateParams: IFeedCommentsStateParams,
    private $ngRedux: INgRedux,
    private $q: IQService,
    private feedActions: FeedActions,
    private metricService: MetricService,
    private goalsFacade: GoalsFacade
  ) {
    const unsubscribe = this.$ngRedux.connect(bindStateToScope)(this.$scope);

    this.entityOwnersSet = new Set<string>();

    this.$scope.params = { ...this.$stateParams };

    this.$scope.getTargetTypeForComments = getTargetTypeForComments;
    this.$scope.updateFeedEntryComments = this.updateFeedEntryComments;
    this.$scope.commentsSortingChanged = this.commentsSortingChanged;

    this.$scope.$on("$destroy", () => {
      unsubscribe();
      this.$ngRedux.dispatch(this.feedActions.clearSelectFeedEntry());
    });
  }

  public stateInit(): IPromise<unknown> {
    return this.$scope
      .watchUntilAndRejectOnError(
        () => this.$scope.feedFetched,
        () => this.$scope.reduxItemsError
      )
      .then(() => {
        this.$ngRedux.dispatch(this.feedActions.selectFeedEntryById(this.$scope.params.targetId));
        this.setEntityOwners();
      });
  }

  private setEntityOwners(): IPromise<void> {
    const feedEntry: IFeedEntry = this.$scope.feedEntry;

    this.entityOwnersSet.add(feedEntry.ownerId);

    if (feedEntry.type === IFeedEntryTypeEnum.KR_UPDATE) {
      return this.metricService.getMetric(feedEntry.item.id).then((metric) => {
        return firstValueFrom(this.goalsFacade.getGoal$(metric.goalId)).then((goal) => {
          metric.ownerIds.forEach((id) => this.entityOwnersSet.add(id));
          goal.ownerIds.forEach((id) => this.entityOwnersSet.add(id));
        });
      });
    } else if (feedEntry.type === IFeedEntryTypeEnum.BADGE_ASSIGN) {
      (feedEntry.item as IFeedBadgeAssignItem).assigneeIds.forEach((id) => this.entityOwnersSet.add(id));
      return this.$q.resolve();
    }
  }

  private updateFeedEntryComments = (comments: Comment[]): void => {
    // feed comments use as target ID the original comment ID; required by the reactions component for assigning reactions to the correct entity
    const feedComments = comments?.map((comment) => ({ ...comment, targetId: comment.id }));
    this.$ngRedux.dispatch(this.feedActions.updateFeedEntryComments(this.$scope.feedEntry.id, feedComments));
  };

  private commentsSortingChanged = ($event: CommentsOrdering): void => {
    this.$scope.commentsOrdering = $event;
    this.$scope.$evalAsync();
  };
}
