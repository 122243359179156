import { Pipe, PipeTransform } from "@angular/core";
import { EffectivenessLowToHighScaleColor, LowToHighScaleType } from "../models/quantive-plus-effectiveness.models";
import { lowToHighScaleEffectivenessToColor } from "../utils/quantive-plus-effectiveness-adapter.utils";

@Pipe({ name: "lowToHighScaleToColor" })
export class LowToHighScaleEffectivenessToColorPipe implements PipeTransform {
  public transform(
    effectivenessLevel: LowToHighScaleType,
    params?: { checkIfShouldSkipColor(effectivenessLevel: LowToHighScaleType): boolean }
  ): EffectivenessLowToHighScaleColor {
    return lowToHighScaleEffectivenessToColor(effectivenessLevel, params);
  }
}
