<div class="skip-link-wrapper">
  <ol class="skip-link-list">
    <li class="skip-link-list-item">
      <a (click)="skipToContent($event, 'nav')" (keydown)="skipToContent($event, 'nav')" tabindex="0" title="Skip to: Skip to top navigation">{{ "skip_to_top_nav" | localize }}</a>
    </li>
    <li class="skip-link-list-item">
      <a (click)="skipToContent($event, 'main')" (keydown)="skipToContent($event, 'main')" tabindex="0" title="Skip to: Skip to main content">{{ "skip_to_main_content" | localize }}</a>
    </li>
  </ol>
</div>
