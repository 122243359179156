import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { Comment, CreateCommentDTO } from "../models/comments.models";
import { CommentsState } from "./comments-state.service";

@Injectable({
  providedIn: "root",
})
export class CommentsApiService extends BaseApiService<Comment, CreateCommentDTO, CommentsState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: CommentsState) {
    super("comments", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getStatsEndpoint(): string {
    return `${this.getBasePath(HttpActions.get)}/stats`;
  }

  public getSeeEndpoint(commentId: string): string {
    return `${this.getBasePath(HttpActions.post)}/${commentId}/see`;
  }
}
