import { Pipe, PipeTransform } from "@angular/core";
import { ValidationErrors } from "@angular/forms";

/**
 * Provided an object of ValidatorErrors type and a descriptor object holding error message texts for the specified errors,
 * extracts the first error message key for which there's defined error message.
 */
@Pipe({
  name: "firstErrorMessage",
})
export class FirstErrorMessagePipe implements PipeTransform {
  public transform(errors: ValidationErrors, errorMessages: { [errorKey: string]: string }): string {
    const errorKeys = Object.keys(errors || {});
    const messageKeys = Object.keys(errorMessages || {});
    if (errorKeys.length === 0 || messageKeys.length === 0) {
      return null;
    }

    const targetErrorsKeys = new Set(messageKeys);

    return errorKeys.find((errorKey) => targetErrorsKeys.has(errorKey));
  }
}
