export type PiCardBorderStyle = "solid" | "dashed";
export type PiCardBorderColor = "light" | "dark";
export type PiTextCardFontColor = "light" | "medium" | "dark";
export type PiIconColor = "light" | "dark";

export const PI_TEXT_CARD_FONT_COLOR_CLASSES: PiTextCardFontClasses = {
  light: "text-color-gray-7",
  medium: "text-color-gray-9",
  dark: "text-color-gray-13",
};

export const PI_ICON_COLOR_CLASSES: PiIconColorClasses = {
  light: "text-color-gray-7",
  dark: "text-color-gray-13",
};

type PiTextCardFontClasses = {
  [key in PiTextCardFontColor]: string;
};

type PiIconColorClasses = {
  [key in PiIconColor]: string;
};

export const PI_CARD_ACTION_BUTTON_TOOLTIP_CUSTOM_STYLE = {
  bottomLeftTooltip: {
    left: "-7px",
    top: "-3px",
  },
  bottomRightTooltip: {
    right: "-7px",
    top: "-3px",
  },
};

type VerticalMenuDropdownMenuItems = "EDIT" | "REMOVE";

type VerticalMenuDropdownMenuItemsMap = {
  [key in VerticalMenuDropdownMenuItems]: string;
};

export const VERTICAL_MENU_DROPDOWN_MENU_ITEMS_MAP: VerticalMenuDropdownMenuItemsMap = {
  EDIT: "edit",
  REMOVE: "remove",
} as const;
