import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ConfigurationState } from "@webapp/configuration/services/configuration-state.service";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { BaseState } from "@webapp/core/abstracts/services/base-state.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";

@Injectable({
  providedIn: "root",
})
export class IntercomApiService extends BaseApiService<{ userHash: string }, null, BaseState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: ConfigurationState) {
    super("integrations", { ...new ApiVersionModel("v2") }, httpClient, appConfig, state);
  }

  public getIntercomUserHashEndpoint(): string {
    return `${this.getBasePath(HttpActions.get)}/intercom/hash`;
  }
}
