import { IRootScopeService, IScope } from "angular";
import { IModalScope } from "angular-ui-bootstrap";
import { Employee } from "@webapp/employees";

export enum EmployeeEventType {
  EMPLOYEE_VISITED = "EMPLOYEE_VISITED",
  EMPLOYEE_DELETED = "EMPLOYEE_DELETED",
  EMPLOYEE_UPDATED = "EMPLOYEE_UPDATED",
}

export interface IEmployeeUpdatedArgs {
  employee: Employee;
}

export class EmployeeEvents {
  private $rootScope: IRootScopeService;
  constructor(private $scope: IModalScope | IScope) {
    this.$rootScope = this.$scope.$root;
  }

  public broadcastEmployeeVisited(employee: Employee): void {
    this.$rootScope.$broadcast(EmployeeEventType.EMPLOYEE_VISITED, {
      employee,
    });
  }

  public broadcastEmployeeDeleted(id: string): void {
    this.$rootScope.$broadcast(EmployeeEventType.EMPLOYEE_DELETED, {
      id,
    });
  }

  public broadcastEmployeeUpdated(employee: Employee): void {
    this.$rootScope.$broadcast(EmployeeEventType.EMPLOYEE_UPDATED, {
      employee,
    });
  }
}
