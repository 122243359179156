import { Injectable } from "@angular/core";
import { Observable, combineLatest, map } from "rxjs";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureModuleService } from "@webapp/feature-toggles/services/feature-module.service";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { areAllPredicatesFulfilled, isAtLeastOnePredicateFulfilled } from "../utils/pi-visibility.utils";
import { PiFeaturePackagingFacade } from "./pi-feature-packaging-facade.service";

/**
 * This facade service is responsible for PI feature visibility evaluation.
 *
 * In order to have a PI Feature visible, the following conditions must be met:
 * 1. positive evaluation from pi-feature-packaging-facade (if the feature evaluation depends on feature packaging)
 * 2. platform intelligence module must be enabled
 * 3. LD flags must be enabled (if any)
 */
@Injectable({
  providedIn: "any",
})
export class PiFeatureVisibilityFacade {
  constructor(
    private featureTogglesFacade: FeatureTogglesFacade,
    private featureModuleService: FeatureModuleService,
    private piFeaturePackagingFacade: PiFeaturePackagingFacade
  ) {}

  /**
   * Determines if the Platform Intelligence feature is visible.
   */
  public isPlatformIntelligenceVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isPlatformIntelligenceIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC1: Suggesting Key Results to OKRs
   */
  public isSuggestingKRsToOKRsVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isSuggestingKRsToOKRsIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.QuantivePlusSuggestions),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC2: Suggesting Tasks to OKRs
   */
  public isSuggestingTasksToOKRsVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isSuggestingTasksToOKRsIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.QuantivePlusSuggestions),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC3: Suggesting Description to OKRs
   */
  public isSuggestingDescriptionToOKRsVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isSuggestingDescriptionToOKRsIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.QuantivePlusSuggestions),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC4: Suggesting Tags to OKRs
   */
  public isSuggestingTagsToOKRsVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isSuggestingTagsToOKRsIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.QuantivePlusSuggestions),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC1-4 Extension: All Suggestions
   */
  public isAllSuggestionsVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isAllSuggestionsIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.PiSuggestionDrawerAllSuggestionsTab),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC5: Suggest Key Result in the Create Key Result Form
   */
  public isSuggestingKRInCreateKRFormVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isSuggestingKRInCreateKRFormIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC6: Assigning The Right Person to an OKR/Task
   *
   * (to be implemented, Reminder for the LD flag)
   */
  public isAssigningTheRightPersonToOKRorTaskVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isAssigningTheRightPersonToOKRorTaskIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC7: Guided OKRs Creation (aka Quantive Coach)
   */
  public isGuidedOKRsCreationVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isGuidedOKRsCreationIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureModuleService.isWhiteboardModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.QuantivePlusSuggestions),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC7-v3:  Suggesting OKRs in Whiteboards
   */
  public isSuggestingOKRsInWhiteboardsVisible$(): Observable<boolean> {
    const generalFeatures$ = combineLatest([
      this.piFeaturePackagingFacade.isSuggestingOKRsInWhiteboardsIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.PiWhiteboardSuggestions),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.QuantivePlusSuggestions),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]);

    const planBasedFeatures$ = combineLatest([
      this.piFeaturePackagingFacade.isWhiteboardSuggestionsIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
    ]);

    return combineLatest({
      generalFeatures: generalFeatures$,
      planBasedFeatures: planBasedFeatures$,
    }).pipe(map((results) => areAllPredicatesFulfilled(results.generalFeatures) || areAllPredicatesFulfilled(results.planBasedFeatures)));
  }

  /**
   * UC8: Helping Users Write Better OKRs (Effectiveness)
   */
  public isEffectivenessVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isEffectivenessIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC10: Human Filters
   *
   * (to be implemented, Reminder for the LD flag)
   */
  public isHumanFiltersVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isHumanFiltersIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC13: KPI Suggestions
   *
   * (to be implemented, Reminder for the LD flag)
   */
  public isKPISuggestionsVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isKPISuggestionsIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC14: Improving KPI through an OKR
   *
   * (to be implemented, Reminder for the LD flag)
   */
  public isImprovingKPIThroughOKRVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isImprovingKPIThroughOKRIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC17: Suggest Task Creation Out Of Comment Thread
   *
   * (to be implemented, Reminder for the LD flag)
   */
  public isSuggestingTaskCreationOutOfCommentThreadVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isSuggestingTaskCreationOutOfCommentThreadIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC22 (beta): Text2SQL in the SQL editor - would act as a base for UC22: Next Gen InsightBoards
   */
  public isInsightsTextToSqlVisible$(): Observable<boolean> {
    const generalFeatures$ = combineLatest([
      this.piFeaturePackagingFacade.isTextToSqlIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.PiInsightsTextToSql),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]);

    const planBasedFeatures$ = combineLatest([
      this.piFeaturePackagingFacade.isInsightsTextToSQLIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
    ]);

    return combineLatest({
      generalFeatures: generalFeatures$,
      planBasedFeatures: planBasedFeatures$,
    }).pipe(map((results) => areAllPredicatesFulfilled(results.generalFeatures) || areAllPredicatesFulfilled(results.planBasedFeatures)));
  }

  /**
   * UC22: Next Gen InsightBoards
   *
   * (to be implemented, Reminder for the LD flag)
   */
  public isNextGenInsightBoardsVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isNextGenInsightBoardsIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC26: Explaining an Objective or KR like a 5yo (Overview)
   */
  public isOverviewVisible$(): Observable<boolean> {
    const generalFeatures$ = combineLatest([
      this.piFeaturePackagingFacade.isOverviewIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.PiSuggestionDrawerOverviewTab),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]);

    const planBasedFeatures$ = combineLatest([
      this.piFeaturePackagingFacade.isSuggestionDrawerOverviewTabIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
    ]);

    return combineLatest({ generalFeatures: generalFeatures$, planBasedFeatures: planBasedFeatures$ }).pipe(
      map((results) => areAllPredicatesFulfilled(results.generalFeatures) || areAllPredicatesFulfilled(results.planBasedFeatures))
    );
  }

  /**
   * UC27: Who Else Is Working On This
   *
   * (to be implemented, Reminder for the LD flag)
   */
  public isWhoElseIsWorkingOnThisVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isWhoElseIsWorkingOnThisIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC29: Automatically Mining Tasks
   *
   * (to be implemented, Reminder for the LD flag)
   */
  public isAutomaticallyMiningTasksVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isAutomaticallyMiningTasksIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC30 Explaining SQL in Plain English
   *
   * (to be implemented, Reminder for the LD flag)
   */
  public isExplainingSQLInPlainEnglishVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isExplainingSQLInPlainEnglishIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC31: AI Whiteboards
   *
   * (to be implemented, Reminder for the LD flag)
   */
  public isAIWhiteboardsVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isAIWhiteboardsIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC32: Suggesting Objective Title While Creating Objective
   */
  public isSuggestingOKRTitleWhileCreatingOKRVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isSuggestingOKRTitleWhileCreatingOKRIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.PiSuggestionDrawerWhenCreateObjective),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC33: Auto Alignment of OKRs
   *
   * (to be implemented, Reminder for the LD flag)
   */
  public isAutoAlignmentOfOKRsVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isAutoAlignmentOfOKRsIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * UC34: OKR Kill-ision - Duplicate Detection
   *
   * (to be implemented, Reminder for the LD flag)
   */
  public isDuplicateDetectionVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isDuplicateDetectionIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }

  /**
   * Responsible for the visibility of the unified Suggestions side panel (suggestion drawer) both on an objective and KR details view. The available tabs are:
   *
   * - Overview Tab (UC26)
   * - Effectiveness Tab (UC8)
   * - All Suggestions Tab (UC1-4 Extension)
   *
   * At least one of the features must be enabled for the side panel to be visible.
   */
  public isAtLeastOneTabVisible$(): Observable<boolean> {
    return combineLatest([this.isOverviewVisible$(), this.isEffectivenessVisible$(), this.isAllSuggestionsVisible$()]).pipe(
      map((results) => isAtLeastOnePredicateFulfilled(results))
    );
  }

  // feature visibility not related to pricing and packaging

  public isPlatformIntelligenceSettingsFeatureVisible$(): Observable<boolean> {
    return combineLatest([
      this.piFeaturePackagingFacade.isPlatformIntelligenceIncludedInEditionPlan$(),
      this.featureModuleService.isPlatformIntelligenceModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.PiSettings),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ResultsPIGlobal),
    ]).pipe(map((results) => areAllPredicatesFulfilled(results)));
  }
}
