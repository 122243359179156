import { StateService } from "@uirouter/angular";
import { ItemAction, ItemActionState } from "../models/action-handler.models";

export function actionHandlerGenerator(action: ItemAction, dependencies: { stateService: StateService; window: Window }): () => unknown {
  if (!action) {
    return angular.noop;
  }

  if ("handler" in action) {
    return action.handler;
  }

  if ("href" in action) {
    if ("target" in action) {
      return () => dependencies.window.open(action.href, action.target);
    }

    return () => (dependencies.window.location.href = action.href);
  }

  const { state, params, options } = action as ItemActionState;
  if (state) {
    return () => dependencies.stateService.go(state, params, options);
  }
}
