import { MessageConfig } from "ng-zorro-antd/core/config";
import { NzMessageData, NzMessageDataOptions, NzMessageType } from "ng-zorro-antd/message";
import { UiToastComponent } from "./toast.component";

export type UiToastType = Extract<NzMessageType, "info" | "success" | "error">;

type UiToastPlacement = "top" | "bottom";

export const renamedUiToastDataOptions = ["uiDuration", "uiAnimate", "uiPauseOnHover"] as const;

export type RenamedUiToastDataOptionsType = (typeof renamedUiToastDataOptions)[number];

type UiToastDataOptionsTypeMap = {
  /** Duration (milliseconds), does not disappear when set to 0 */
  uiDuration?: number;
  uiAnimate?: boolean;
  uiPauseOnHover?: boolean;
};

type RenamedUiToastDataOptions = {
  [RenamedUiToastDataOption in RenamedUiToastDataOptionsType]?: UiToastDataOptionsTypeMap[RenamedUiToastDataOption];
};

type UiToastInputOptions = {
  uiCloseable?: UiToastComponent["uiCloseable"];
  uiPrimaryCTA?: UiToastComponent["uiPrimaryCTA"];
  uiSecondaryCTA?: UiToastComponent["uiSecondaryCTA"];
  uiOnPrimaryAction?(): void;
  uiOnSecondaryAction?(): void;
  uiHideIcon?: boolean;
};

export type UiToastConfig = Pick<MessageConfig, "nzTop" | "nzMaxStack" | "nzDuration">;

export interface UiToastDataOptions extends NzMessageDataOptions, RenamedUiToastDataOptions, UiToastInputOptions {
  uiPlacement?: UiToastPlacement;
  uiOffset?: number;
  uiShouldTimeout?: boolean;
}

export type UiToastData = NzMessageData & {
  type?: UiToastType | string;
  options?: UiToastDataOptions;
};

export type UiToastRef = Pick<Required<UiToastData>, "onClose" | "messageId"> & UiToastData;
