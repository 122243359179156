import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { UiFormModule } from "@webapp/ui/form/form.module";
import { UiInputNumberModule } from "@webapp/ui/input-number/input-number.module";
import { UiInputModule } from "@webapp/ui/input/input.module";
import { UiSelectModule } from "@webapp/ui/select/select.module";
import { EditableCustomFieldsComponent } from "./components/editable-custom-fields/editable-custom-fields.component";
import { CustomFieldLabelComponent } from "./components/label/label.component";
import { CustomFieldMultiSelectComponent } from "./components/multi-select/multi-select.component";
import { CustomFieldNumberInputFieldComponent } from "./components/numeric-field/number-input-field.component";
import { CustomFieldSelectComponent } from "./components/select/select.component";
import { CustomFieldActionsProvider } from "./providers/custom-field-actions.provider";
import { CustomFieldComponentFactory } from "./services/factories/custom-field.factory";
import { DatepickerCustomFieldComponentFactory } from "./services/factories/datepicker-custom-field-component.factory";
import { CustomFieldLabelComponentFactory } from "./services/factories/label-custom-field-component.factory";
import { MultiSelectCustomFieldComponentFactory } from "./services/factories/multi-select-custom-field-component.factory";
import { NumericCustomFieldComponentFactory } from "./services/factories/numeric-custom-field-component.factory";
import { RichTextEditorCustomFieldComponentFactory } from "./services/factories/rich-text-area-custom-field-component.factory";
import { SelectAssigneeCustomFieldComponentFactory } from "./services/factories/select-assignee-custom-field-component.factory";
import { SelectCustomFieldComponentFactory } from "./services/factories/select-custom-field-component.factory";

@NgModule({
  declarations: [
    CustomFieldSelectComponent,
    CustomFieldMultiSelectComponent,
    EditableCustomFieldsComponent,
    CustomFieldLabelComponent,
    CustomFieldNumberInputFieldComponent,
  ],
  imports: [FormsModule, CommonModule, LocalizationModule, UiIconModule, UiSelectModule, UiTooltipModule, UiInputNumberModule, UiInputModule, UiFormModule],
  providers: [
    CustomFieldActionsProvider,
    CustomFieldComponentFactory,
    CustomFieldLabelComponentFactory,
    RichTextEditorCustomFieldComponentFactory,
    DatepickerCustomFieldComponentFactory,
    SelectCustomFieldComponentFactory,
    MultiSelectCustomFieldComponentFactory,
    SelectAssigneeCustomFieldComponentFactory,
    NumericCustomFieldComponentFactory,
  ],
  exports: [CustomFieldSelectComponent, CustomFieldMultiSelectComponent, EditableCustomFieldsComponent, CustomFieldLabelComponent, CustomFieldNumberInputFieldComponent],
})
export class CustomFieldsCoreModule {}
