import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import {
  PIKeyResultDescriptionSuggestion,
  PIKeyResultDescriptionSuggestionPayload,
  PIKrSuggestion,
  PIKrSuggestionPayload,
  PIKrTasksSuggestion,
  PIKrTasksSuggestionPayload,
  PIObjectiveTagsSuggestion,
  PIObjectiveTagsSuggestionPayload,
  QuantivePlusSuggestionDTO,
  QuantivePlusSuggestionPostDTO,
} from "@webapp/platform-intelligence/shared/models/quantive-plus-suggestions.models";
import { QuantivePlusSuggestionsApiV2Service } from "./quantive-plus-suggestions-api-v2.service";
import { QuantivePlusSuggestionsStateV2 } from "./quantive-plus-suggestions-state-v2.service";

@Injectable({
  providedIn: "any",
})
export class QuantivePlusSuggestionsFacadeV2 extends BaseFacade<
  QuantivePlusSuggestionDTO,
  QuantivePlusSuggestionPostDTO,
  QuantivePlusSuggestionsStateV2,
  QuantivePlusSuggestionsApiV2Service
> {
  constructor(state: QuantivePlusSuggestionsStateV2, api: QuantivePlusSuggestionsApiV2Service) {
    super(state, api);
  }

  public getQuantivePlusKrSuggestion(payload: PIKrSuggestionPayload): Observable<PIKrSuggestion> {
    return this.post$(payload, { ...new RequestConfig(), url: this.apiService.getKeyResultsEndpoint() });
  }

  public getQuantivePlusObjectiveTagsSuggestion(payload: PIObjectiveTagsSuggestionPayload): Observable<PIObjectiveTagsSuggestion> {
    return this.post$(payload, { ...new RequestConfig(), url: this.apiService.getTagsEndpoint() });
  }

  public getQuantivePlusKrTasksSuggestion(payload: PIKrTasksSuggestionPayload): Observable<PIKrTasksSuggestion> {
    return this.post$(payload, { ...new RequestConfig(), url: this.apiService.getKrTasksEndpoint() });
  }

  public getKeyResultDescriptionSuggestion(payload: PIKeyResultDescriptionSuggestionPayload): Observable<PIKeyResultDescriptionSuggestion> {
    return this.post$(payload, { ...new RequestConfig(), url: this.apiService.getKeyResultDescriptionEndpoint() });
  }
}
