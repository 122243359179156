export class PageTitleService {
  private notificationsCount: number;
  private currentTitle = "Quantive Results";

  private setTitle = (): void => {
    document.title = this.notificationsCount > 0 ? `(${this.notificationsCount}) ${this.currentTitle}` : `${this.currentTitle}`;
  };

  public setPageTitle(title: string): void {
    if (!title) {
      return;
    }

    this.currentTitle = title;
    this.setTitle();
  }

  public setNotificationsCount(count: number): void {
    if (count < 0) {
      return;
    }

    this.notificationsCount = count;
    this.setTitle();
  }
}
