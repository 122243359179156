import { IConfidenceSettings } from "@gtmhub/core";
import { IBaseGoal, IGoal } from "@gtmhub/goals/models";
import { IGridColumn, IGridSortingRule, OrFiltersGroup } from "@gtmhub/shared/components/grid-filtering-bar";
import { ITeam } from "@gtmhub/teams";
import { ConfidenceScale } from "@webapp/configuration/models/configuration.model";
import {
  CfMap,
  CustomFieldOperationalType,
  CustomFieldPredefinedValue,
  SelectAssigneeCustomFieldPredefinedValues,
} from "@webapp/custom-fields/models/custom-fields.models";
import { Employee } from "@webapp/employees";
import { GoalParentType } from "@webapp/okrs/goals/models/goal.models";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { SimplePermissionsAccessType } from "@webapp/permissions/models/permissions.model";
import { IAccess, UserAction } from "@webapp/sessions/models/sessions.model";

export type ListFilterBooleanOperator = "and";
type ListApiFiltersGroup = { ruleBounds: OrListFiltersGroup };
export type UpdateListFiltersGroup = ListApiFiltersGroup & { booleanOperator: ListFilterBooleanOperator };

export interface List {
  title: string;
  type: ListType;
  ownerId: string;
  filters: ListApiFiltersGroup[];
  id?: string;
  access?: IAccess;
  accountId?: string;
  createdAt?: string;
  uiAccess?: string;
  color?: string;
  sort?: IListSort[];
  columns?: IListColumn[];
  view?: ListView;
  chartSettings?: IListChartSettings;
  // UI
  typeKey?: string;
  currentUserAllowedActions?: UserAction[];
}

export interface IListChartSettings {
  chartCategory: string;
  chartValue: string;
  aggregation: Aggregation;
  color: string;
  visualization: ChartVisualization;
}

export interface ListPropertyTypes {
  // still do not know what the data will be here
  customFieldsProperties: [];
  customFieldsTotalCount: number;
  fields: IListProperty[];
  totalCount: number;
  type: string;
}

export interface IListProperty {
  trackingName?: string;
  displayName: string;
  fieldName: string;
  supportedOperators: string[];
  defaultOperator: string;
  dataType: string;
  operationalType?: CustomFieldOperationalType;
  targetIds?: string[];
  predefinedValues?: CustomFieldPredefinedValue[] | SelectAssigneeCustomFieldPredefinedValues;
  isSortable?: boolean;
  isSelectable?: boolean;
  isFilterable?: boolean;
  isCustom?: boolean;
}

export interface IChartPointOptions {
  fieldName: string; // can be typed as ListsPropertyNames, but will also have to change IListProperty
  dataType: string; // can be typed as ListFilterTypes, but will also have to change IListProperty
  isCustom?: boolean;
}

export interface ICreateDefaultList {
  type: ListType;
  color: string;
}

export interface PatchList {
  access?: IAccess;
  chartSettings?: IListChartSettings;
  color?: string;
  columns?: IListColumn[];
  filters?: UpdateListFiltersGroup[];
  sort?: IListSort[];
  title?: string;
  type?: ListType;
  view?: ListView;
  accessType?: SimplePermissionsAccessType;
}

export interface IListRules {
  chartSettings?: IListChartSettings;
  view?: ListView;
  columns?: IListColumn[];
  filters?: UpdateListFiltersGroup[];
  sort?: IListSort[];
  type: ListType;
}

export interface ICreateListFromExisting {
  chartSettings: IListChartSettings;
  color: string;
  filters: UpdateListFiltersGroup[];
  title: string;
  type: ListType;
  view: ListView;
}

export interface ILoadListV2 {
  listType: ListType;
  projections?: IGridColumn[];
  filters: OrFiltersGroup[];
  sorting?: IGridSortingRule[];
  skip?: number;
  limit?: number;
  includeRequiredColumns: boolean;
}

export interface IListFilter {
  fieldName: string;
  dataType: string;
  operator: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  viewValue?: any;
  dynamicFilters?: IDynamicFilter[];
  customField?: boolean;
}

export type OrListFiltersGroup = IListFilter[];

export interface IDynamicFilter {
  dynamicValueType: DynamicValueType;
  value?: string[];
}

type DynamicValueType =
  | "Owner ID Filter"
  | "Team and its members Filter"
  | "Team org members"
  | "Current Sessions Filter"
  | "Current user teams"
  | "Current user teams member"
  | "Current user teams manager";

export interface IListSort extends IGridSortingRule {
  sortOrder?: number;
}

export interface IListFiltersChange {
  listFilters: UpdateListFiltersGroup[];
  gridFilters: OrFiltersGroup[];
}

export interface IListColumn extends IGridColumn {
  type: string;
  width?: number;
  isNew?: boolean;
  isLatestSnapshotFieldColumn?: boolean;
  isCustomFieldColumn?: boolean;
}

export enum DynamicFilters {
  currentUser = "Owner ID Filter",
  teamPlusMembers = "Team and its members Filter",
  teamOrg = "Team org members",
  currentSessions = "Current Sessions Filter",
  currentUserTeams = "Current user teams",
  currentUserTeamsMember = "Current user teams member",
  currentUserTeamsManager = "Current user teams manager",
}

export enum ListOptions {
  filters = "filters",
  columns = "columns",
  sort = "sort",
  type = "type",
  view = "view",
  chartSettings = "chartSettings",
}

export enum ListEntityType {
  Objective = "goal",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "Key Result" = "metric",
  Task = "task",
  Employee = "user",
  Team = "team",
}

export const listPropertiesTypesMap: Record<ListType, ListTargetType> = {
  Objective: "goal",
  "Key Result": "metric",
  Task: "task",
  Employee: "employee",
  Team: "team",
};

export enum ListTypeKeysUI {
  Objective = "objective",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "Key Result" = "keyresult",
  Task = "task",
  Employee = "employee",
  Team = "team",
}

export type ListTypeKeys = "objective" | "keyresult" | "task" | "employee" | "team";

export type ListType = "Objective" | "Key Result" | "Task" | "Employee" | "Team";
export type ListTargetType = "goal" | "metric" | "task" | "employee" | "team";

export enum ListActions {
  addColumn = "addColumn",
  removeColumn = "removeColumn",
  reorderColumns = "reorderColumns",
  editFilter = "editFilter",
  editSorting = "editSorting",
  remove = "remove",
  changeType = "changeType",
  changeFilters = "changeFilters",
  changeColumns = "changeColumns",
  changeChartsSettings = "changeChartsSettings",
  changeView = "changeView",
}

export enum AccessTypes {
  public = "public",
  private = "private",
  restricted = "restricted",
  publicRead = "publicRead",
  publicUpdate = "publicUpdate",
}

export enum ListsPropertyNames {
  sessionId = "sessionId",
  name = "name",
  description = "description",
  ownerId = "ownerId",
  ownerIds = "ownerIds",
  dateCreated = "dateCreated",
  createdById = "createdById",
  modifiedById = "modifiedById",
  attainment = "attainment",
  progress = "progress",
  metricsCount = "metricsCount",
  tasksCount = "tasksCount",
  parentGoalSessionId = "parentGoalSessionId",
  goalId = "goalId",
  parentId = "parentId",
  tags = "tags",
  teamIds = "teamIds",
  picture = "picture",
  firstName = "firstName",
  lastName = "lastName",
  manager = "manager",
  members = "members",
  teamGoalCount = "teamGoalCount",
  membersGoalCount = "membersGoalCount",
  avatar = "avatar",
  confidenceValue = "confidence.value",
  lastCheckInDate = "lastCheckInDate",
  dueDate = "dueDate",
  status = "status",
  goalCount = "goalCount",
  manualMetricCount = "manualMetricCount",
  dynamicMetricCount = "dynamicMetricCount",
  goalOwnerId = "goalOwnerId",
  goalOwnerIds = "goalOwnerIds",
  goalAttainment = "goalAttainment",
  closedStatus = "closedStatus",
  goalClosedStatus = "goalClosedStatus",
  userRoles = "userRoles",
  badges = "badges",
  confidenceReason = "confidence.reason",
  workflowStatus = "workflow.status",
  targetOperator = "targetOperator",
  lastComment = "lastComment",
  roles = "roles",
  watchers = "watchers",
  isActive = "isActive",
}

export enum ListFilterTypes {
  alphabetic = "alphabetic",
  numeric = "numeric",
  dropdown = "dropdown",
  selectassignee = "selectassignee",
  bool = "bool",
  multiselect = "multiselect",
  date = "date",
  id = "id",
  array = "array",
  boolean = "boolean",
}

export enum SupportedOperators {
  equals = "equals",
  notEqual = "notEqual",
  isOneOf = "isOneOf",
  notOneOf = "notOneOf",
  contains = "contains",
  doesNotContain = "doesNotContain",
}

export interface IOKRFilterView extends IOKRBaseFilterView {
  type: string;
}

export interface IOKRBaseFilterView {
  id: string;
  name: string;
  closedStatus: { status: string };
}

export interface IConfidenceValueRange {
  from: number;
  to: number;
}

export type IConfidenceValue = number | IConfidenceValueRange;

export interface IListFilterToGridFilterContext {
  confidenceScale: ConfidenceScale;
  confidenceSettings: IConfidenceSettings;
}

export interface IListPropertyToGridFilterDefinitionContext extends IListFilterToGridFilterContext {
  listType: ListType;
}

export interface IGridFilterToListFilterContext {
  confidenceSettings: IConfidenceSettings;
}

export interface IFilterByFeatureFlag {
  fieldNamesToFilter: string[];
  typesToFilterColumns: string[];
  typesToFilterSchema: string[];
}

export interface IGoalList extends IBaseGoal {
  closedStatus?: { status: string };
  parentType?: GoalParentType;
}

export enum ListView {
  list = "List",
  pie = "Pie",
  bar = "Bar",
  line = "Line",
}

export const listViewDisplayNameMap: Map<ListView, string> = new Map([
  [ListView.list, "list_view"],
  [ListView.pie, "pie_view"],
  [ListView.bar, "bar_view"],
  [ListView.line, "line_view"],
]);

export const listViewIconMap: Map<ListView, string> = new Map([
  [ListView.list, "table"],
  [ListView.pie, "pie"],
  [ListView.bar, "consulting"],
  [ListView.line, "chart"],
]);

export const chartViewToChartTypeMap: Map<ListView, string> = new Map([
  [ListView.pie, "PieChart"],
  [ListView.bar, "ColumnChart"],
  [ListView.line, "LineChart"],
]);

export type ListItem = IGoalList | Metric | ITeam | Employee | Task;
export type ListItemView = (ListItem | IGoal) & {
  viewValue?: Record<string, string | string[] | boolean | unknown>;
  progress?: number;
  goalAttainment?: number;
  customFields?: CfMap;
  targetOperator?: string;
  manualType?: string;
  insightName?: string;
  sessionId?: string;
  parentGoalSessionId?: string;
};
export type Aggregation = (typeof aggregations)[number];
export type ChartVisualization = (typeof chartVisualizations)[number];
export const chartVisualizations = ["Pie", "Donut"] as const;
export const aggregations = ["Count", "Sum", "Average", "Min", "Max"] as const;
export type GoogleAggregation = "count" | "sum" | "avg" | "min" | "max";
export const aggregationSettingToGoogleAggregationMap: Map<Aggregation, GoogleAggregation> = new Map([
  ["Count", "count"],
  ["Sum", "sum"],
  ["Average", "avg"],
  ["Min", "min"],
  ["Max", "max"],
]);
export const DEFAULT_LIST_CHART_COLOR = "#4E7BE2";
const DEFAULT_LIST_CHART_VISUALIZATION = "Pie";
export const DEFAULT_LIST_COLOR = "#0A77E3";

export interface ListTypeChangeEventArgs {
  listType: ListType;
  listProperties: IListProperty[];
  filters: UpdateListFiltersGroup[];
  gridFilters: OrFiltersGroup[];
}

export const defaultChartSettingsMap: Map<ListView, Map<ListType, IListChartSettings>> = new Map([
  [
    ListView.pie,
    new Map<ListType, IListChartSettings>([
      [
        "Objective",
        { chartCategory: "name", chartValue: "ownerIds", aggregation: "Count", color: DEFAULT_LIST_CHART_COLOR, visualization: DEFAULT_LIST_CHART_VISUALIZATION },
      ],
      [
        "Key Result",
        { chartCategory: "name", chartValue: "ownerIds", aggregation: "Count", color: DEFAULT_LIST_CHART_COLOR, visualization: DEFAULT_LIST_CHART_VISUALIZATION },
      ],
      ["Task", { chartCategory: "name", chartValue: "status", aggregation: "Count", color: DEFAULT_LIST_CHART_COLOR, visualization: DEFAULT_LIST_CHART_VISUALIZATION }],
      [
        "Employee",
        { chartCategory: "lastName", chartValue: "goalCount", aggregation: "Sum", color: DEFAULT_LIST_CHART_COLOR, visualization: DEFAULT_LIST_CHART_VISUALIZATION },
      ],
      [
        "Team",
        { chartCategory: "name", chartValue: "teamGoalCount", aggregation: "Count", color: DEFAULT_LIST_CHART_COLOR, visualization: DEFAULT_LIST_CHART_VISUALIZATION },
      ],
    ]),
  ],
  [
    ListView.bar,
    new Map<ListType, IListChartSettings>([
      [
        "Objective",
        { chartCategory: "name", chartValue: "ownerIds", aggregation: "Count", color: DEFAULT_LIST_CHART_COLOR, visualization: DEFAULT_LIST_CHART_VISUALIZATION },
      ],
      [
        "Key Result",
        { chartCategory: "name", chartValue: "ownerIds", aggregation: "Count", color: DEFAULT_LIST_CHART_COLOR, visualization: DEFAULT_LIST_CHART_VISUALIZATION },
      ],
      ["Task", { chartCategory: "name", chartValue: "ownerId", aggregation: "Count", color: DEFAULT_LIST_CHART_COLOR, visualization: DEFAULT_LIST_CHART_VISUALIZATION }],
      [
        "Employee",
        { chartCategory: "lastName", chartValue: "goalCount", aggregation: "Sum", color: DEFAULT_LIST_CHART_COLOR, visualization: DEFAULT_LIST_CHART_VISUALIZATION },
      ],
      [
        "Team",
        { chartCategory: "name", chartValue: "teamGoalCount", aggregation: "Count", color: DEFAULT_LIST_CHART_COLOR, visualization: DEFAULT_LIST_CHART_VISUALIZATION },
      ],
    ]),
  ],
  [
    ListView.line,
    new Map<ListType, IListChartSettings>([
      [
        "Objective",
        { chartCategory: "name", chartValue: "ownerIds", aggregation: "Count", color: DEFAULT_LIST_CHART_COLOR, visualization: DEFAULT_LIST_CHART_VISUALIZATION },
      ],
      [
        "Key Result",
        { chartCategory: "name", chartValue: "ownerIds", aggregation: "Count", color: DEFAULT_LIST_CHART_COLOR, visualization: DEFAULT_LIST_CHART_VISUALIZATION },
      ],
      ["Task", { chartCategory: "name", chartValue: "ownerId", aggregation: "Count", color: DEFAULT_LIST_CHART_COLOR, visualization: DEFAULT_LIST_CHART_VISUALIZATION }],
      [
        "Employee",
        { chartCategory: "lastName", chartValue: "goalCount", aggregation: "Sum", color: DEFAULT_LIST_CHART_COLOR, visualization: DEFAULT_LIST_CHART_VISUALIZATION },
      ],
      [
        "Team",
        { chartCategory: "name", chartValue: "teamGoalCount", aggregation: "Count", color: DEFAULT_LIST_CHART_COLOR, visualization: DEFAULT_LIST_CHART_VISUALIZATION },
      ],
    ]),
  ],
]);

export const fieldNamesToBeFormattedAsPercentage = new Set<string>(["attainment", "goalAttainment", "progress"]);

export enum ListChangedEvent {
  Added = "ADDED",
  Deleted = "DELETED",
  Modified = "MODIFIED",
}
