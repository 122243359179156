import { HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { encode as base64encode } from "js-base64";
import { Observable, tap } from "rxjs";
import { storage } from "@gtmhub/core/storage";
import { EmployeeEventType } from "@gtmhub/employees/services/events";
import { IUserAccountRequest } from "@gtmhub/login/models";
import { IBulkActionOnUsersResponse, IUserSettings, getCurrentUserId } from "@gtmhub/users";
import { IInvitationRequest, IPatchedUser, IUser } from "@gtmhub/users/models";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { ICollection } from "@webapp/core/core.models";
import { GTMHUB_ADDITIONAL_PARAMS } from "@webapp/core/http/interceptors/track-data.interceptor";
import { GtmhubAdditionalParams } from "@webapp/core/http/models/http.models";
import { Snippet } from "@webapp/data-story/models/insights.models";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";
import { IUsersLimit, User, UsersDTO } from "../models/users.models";
import { UsersApiService } from "./users-api.service";
import { UsersState } from "./users-state.service";

@Injectable({
  providedIn: "any",
})
export class UsersFacade extends BaseFacade<User, UsersDTO, UsersState, UsersApiService> {
  public constructor(
    state: UsersState,
    api: UsersApiService,
    private broadcastService: BroadcastService,
    private userProfileService: UserProfileService
  ) {
    super(state, api);
  }

  public getUsersV2$<T = User>(
    filter?: RequestPaging,
    queryParams?: { includeShadowedRoles: boolean },
    additionalGtmhubParams?: GtmhubAdditionalParams
  ): Observable<ICollection<T>> {
    const context = additionalGtmhubParams ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) } : {};

    return this.apiService.getAll$<ICollection<T>>(filter, {
      ...new RequestConfig(),
      ...context,
      url: this.apiService.getUsersV2Endpoint(),
      ...(queryParams && { queryParams: { includeShadowedRoles: queryParams.includeShadowedRoles } }),
    });
  }

  public createUser$(invite: IInvitationRequest, options: { skipEmail?: boolean }, additionalGtmhubParams?: GtmhubAdditionalParams): Observable<User> {
    let queryParams = {};

    if (options.skipEmail) {
      queryParams = {
        skipEmail: true,
      };
    }

    // set first name to the email and last to empty space
    invite.firstName = invite.email.split("@")[0];
    invite.lastName = " ";

    const context = additionalGtmhubParams ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) } : {};

    return this.post$(invite, {
      ...new RequestConfig(),
      ...context,
      queryParams: queryParams,
    }).pipe(
      tap((user) => {
        this.broadcastService.emit("userCreated", { id: user.id });
      })
    );
  }

  public patchUser$(userId: string, patchedUser: IPatchedUser, additionalGtmhubParams?: GtmhubAdditionalParams): Observable<void> {
    const context = additionalGtmhubParams ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) } : {};

    return this.patch$<void>(userId, patchedUser, {
      ...new RequestConfig(),
      ...context,
      url: this.apiService.getUserPatchEndpoint(userId),
    }).pipe(
      tap(() => {
        if (userId === getCurrentUserId()) {
          this.userProfileService.updateProfileNames({
            firstName: patchedUser.firstName,
            lastName: patchedUser.lastName,
          });
        }
      })
    );
  }

  public deleteUserById$(userId: string, additionalGtmhubParams?: GtmhubAdditionalParams): Observable<void> {
    const context = additionalGtmhubParams ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) } : {};

    return this.delete$(userId, {
      ...new RequestConfig(),
      ...context,
      url: this.apiService.getUserDeleteEndpoint(userId),
    });
  }

  public activateUser$(user: Partial<User>): Observable<IBulkActionOnUsersResponse> {
    return this.post$([user.id], {
      ...new RequestConfig(),
      url: this.apiService.getActivateUsersEndpoint(),
    });
  }

  public deactivateUser$(user: Partial<User>): Observable<IBulkActionOnUsersResponse> {
    return this.post$([user.id], {
      ...new RequestConfig(),
      url: this.apiService.getDeactivateUsersEndpoint(),
    });
  }

  public deleteUser$(user: Partial<User>): Observable<IBulkActionOnUsersResponse> {
    return this.post$<IBulkActionOnUsersResponse>([user.id], {
      ...new RequestConfig(),
      url: this.apiService.getDeleteUsersEndpoint(),
    }).pipe(
      tap(() => {
        this.broadcastService.emit(EmployeeEventType.EMPLOYEE_DELETED, { id: user.id });
      })
    );
  }

  public getUsersLimit$(addingUsersCount: number): Observable<IUsersLimit> {
    return this.get$(null, {
      ...new RequestConfig(),
      url: this.apiService.getUsersLimitEndpoint(),
      queryParams: { addingUsersCount },
    });
  }

  public getUser$(
    id: string,
    queryParams: { includeShadowedRoles: boolean } = { includeShadowedRoles: true },
    additionalGtmhubParams?: GtmhubAdditionalParams
  ): Observable<User> {
    const context = additionalGtmhubParams ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) } : {};
    return this.get$(id, {
      ...new RequestConfig(),
      ...context,
      url: this.apiService.getUserEndpoint(id),
      queryParams: {
        includeShadowedRoles: queryParams.includeShadowedRoles,
      },
    });
  }

  public getUserSetting<T = unknown>(key: keyof IUserSettings): T {
    if (!key) {
      throw new Error("You must provide user setting key.");
    }

    const userSettings = storage.get<IUserSettings>("userSettings");
    return userSettings && key in userSettings ? (userSettings[key] as T) : null;
  }

  public setUserSetting$<T>(userSetting: IUserSettings): Observable<T> {
    const currentUserSettings = storage.get<IUserSettings>("userSettings");
    const userSettings: IUserSettings = {
      ...currentUserSettings,
      ...userSetting,
    };

    storage.set("userSettings", userSettings);

    if (userSetting.snippets) {
      userSetting.snippets = userSetting.snippets.map((snippet: Snippet) => {
        const code = base64encode(snippet.code || "");
        return {
          ...snippet,
          code,
        };
      });
    }

    const url = this.apiService.getUserSettingsEndpoint();

    return this.apiService.patch$(null, userSetting, {
      ...new RequestConfig(),
      url,
    });
  }

  public ensureUserAccount$(userAccountModel: IUserAccountRequest): Observable<IUser> {
    return this.apiService.post$(userAccountModel, {
      ...new RequestConfig(),
      url: this.apiService.getUsersAccountEndpoint(),
    });
  }
}
