import { IRootScopeService, IScope } from "angular";
import { FlowName } from "@webapp/platform-intelligence/shared/utils/pi-tracking";
import { Task } from "@webapp/tasks/models/tasks.models";

interface IBroadcastTaskChangesArgs {
  task: Task;
}

export interface ITaskCreatedArgs extends IBroadcastTaskChangesArgs {
  sessionId: string;
  isQuantivePlusOrigin?: boolean;
  quantivePlusSuggestionIndex?: number;
  flowName?: FlowName;
}

export interface ITaskDeletedArgs extends IBroadcastTaskChangesArgs {
  sessionId?: string;
}

export interface ITaskUpdatedArgs extends IBroadcastTaskChangesArgs {
  update: Partial<Task>;
  sessionId?: string;
}

export enum TaskEventType {
  TASK_PATCHED = "TASK_PATCHED",
  TASK_DELETED_REQUEST_SUCCEEDED = "TASK_DELETED_REQUEST_SUCCEEDED",
  TASK_VISITED = "TASK_VISITED",
}

export class TaskEvents {
  private $rootScope: IRootScopeService;
  constructor(private $scope: IScope) {
    this.$rootScope = this.$scope.$root;
  }

  public broadcastTaskCreated(options: ITaskCreatedArgs): void {
    this.$rootScope.$broadcast("taskCreated", options);
  }

  onTaskCreated(callback: (args: ITaskCreatedArgs) => unknown): void {
    this.$scope.$on("taskCreated", (evt, args: ITaskCreatedArgs) => callback(args));
  }

  public broadcastTaskDeleted(options: ITaskDeletedArgs): void {
    this.$rootScope.$broadcast("taskDeleted", options);
  }

  public broadcastTaskDeletedRequestSucceeded(options: ITaskDeletedArgs): void {
    this.$rootScope.$broadcast(TaskEventType.TASK_DELETED_REQUEST_SUCCEEDED, options);
  }

  onTaskDeleted(callback: (args: ITaskDeletedArgs) => unknown): void {
    this.$scope.$on("taskDeleted", (evt, args: ITaskDeletedArgs) => callback(args));
  }

  public broadcastTaskUpdated(options: ITaskUpdatedArgs): void {
    this.$rootScope.$broadcast("taskUpdated", options);
  }

  public broadcastTaskPatched(options: ITaskUpdatedArgs): void {
    this.$rootScope.$broadcast(TaskEventType.TASK_PATCHED, options);
  }

  onTaskUpdated(callback: (args: ITaskUpdatedArgs) => unknown): void {
    this.$scope.$on("taskUpdated", (evt, args: ITaskUpdatedArgs) => callback(args));
  }

  public broadcastTaskVisited(task: Task): void {
    this.$rootScope.$broadcast(TaskEventType.TASK_VISITED, {
      task,
    });
  }
}
