import { NgIf, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzHighlightPipe } from "ng-zorro-antd/core/highlight";
import { NzTreeNode, NzTreeNodeOptions } from "ng-zorro-antd/core/tree";
import { NzTreeNodeTitleComponent } from "ng-zorro-antd/tree";
import { TreeSize } from "../../tree.models";
import { UiTreeDropIndicatorComponent } from "../tree-drop-indicator/tree-drop-indicator.component";

@Component({
  selector: "ui-tree-node-title",
  templateUrl: "tree-node-title.component.html",
  styleUrls: ["tree-node-title.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    "[class.tree-node-title-medium]": `!uiSelectMode && treeSize === 'medium'`,
  },
  standalone: true,
  imports: [UiTreeDropIndicatorComponent, NgIf, UiIconModule, NzHighlightPipe, NgTemplateOutlet],
})
export class UiTreeNodeTitleComponent extends NzTreeNodeTitleComponent {
  @Input() public searchValue!: string;
  @Input() public treeTemplate: TemplateRef<{ $implicit: NzTreeNode; origin: NzTreeNodeOptions }> | null = null;
  @Input() public draggable!: boolean;
  @Input() public showIcon!: boolean;
  @Input() public selectMode = false;
  @Input() public context!: NzTreeNode;
  @Input() public icon!: string;
  @Input() public title!: string;
  @Input() public isLoading!: boolean;
  @Input() public isSelected!: boolean;
  @Input() public isDisabled!: boolean;
  @Input() public isMatched!: boolean;
  @Input() public isExpanded!: boolean;
  @Input() public isLeaf!: boolean;
  // Drag indicator
  @Input() public showIndicator = true;
  @Input() public dragPosition?: number;
  @Input() public treeSize?: TreeSize;
}
