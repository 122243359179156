import { IHttpService, IPromise, IQService, IRequestShortcutConfig, IRootScopeService } from "angular";
import { IRestLayerRequest } from "@gtmhub/core";
import { EnvironmentService } from "@gtmhub/env";
import { escapeString } from "@gtmhub/util/utils";
import { ICollection } from "@webapp/core/core.models";
import { ITag, ITagChangeStatusAction, ITagCollection, ITagsQueryParams } from "./models";

export class TagService {
  public static $inject = ["$http", "$q", "$rootScope", "EnvironmentService"];

  constructor(
    private $http: IHttpService,
    private $q: IQService,
    private $rootScope: IRootScopeService,
    private env: EnvironmentService
  ) {}

  public createTag(tag: ITag): IPromise<ITag> {
    const url = this.env.getApiEndpoint("/tags");
    const deferred = this.$q.defer<ITag>();
    this.$http.post<ITag>(url, tag).then(
      (result) => {
        this.$rootScope.$broadcast("tagsChanged");
        deferred.resolve(result.data);
      },
      (response) => {
        deferred.reject(response);
      }
    );
    return deferred.promise;
  }

  public changeTagsActivationStatus(tagIds: string[], action: ITagChangeStatusAction): IPromise<void> {
    const filtered = tagIds.filter((id) => Boolean(id));
    const deferred = this.$q.defer<void>();
    if (filtered.length === 0) {
      deferred.reject("invalid tagIds");

      return deferred.promise;
    }

    const url = this.env.getApiEndpoint(`/tags/${action}`);
    this.$http.post<void>(url, { tagIds: filtered }).then(
      () => {
        this.$rootScope.$broadcast("tagsChanged");
        deferred.resolve();
      },
      (error) => deferred.reject(error)
    );

    return deferred.promise;
  }

  public renameTag(id: string, title: string): IPromise<void> {
    const deferred = this.$q.defer<void>();
    if (!id || !title) {
      deferred.reject("missing id or title");

      return deferred.promise;
    }

    const url = this.env.getApiEndpoint(`/tags/${id}`);
    this.$http.patch<void>(url, { title }).then(
      () => {
        this.$rootScope.$broadcast("tagsChanged");
        deferred.resolve();
      },
      (error) => deferred.reject(error)
    );

    return deferred.promise;
  }

  public getTags(params: ITagsQueryParams = null): IPromise<ITagCollection> {
    const query: IRequestShortcutConfig = {
      params: {
        name: escapeString(params?.name),
        title: escapeString(params?.title),
      },
    };

    const url = this.env.getApiEndpoint("/tags");
    const deferred = this.$q.defer<ITagCollection>();

    this.$http.get<ITagCollection>(url, query).then(
      (result) => {
        deferred.resolve(result.data);
      },
      (response) => {
        deferred.reject(response);
      }
    );
    return deferred.promise;
  }

  public getTagsV2(params: IRestLayerRequest): IPromise<ICollection<ITag>> {
    const url = this.env.getApiEndpointV2("/tags");

    return this.$http.get<ICollection<ITag>>(url, { params }).then((response) => response.data);
  }

  public deleteTags(tagIds: string[]): IPromise<void> {
    const deferred = this.$q.defer<void>();
    const filtered = tagIds.filter((id) => Boolean(id));
    if (filtered.length === 0) {
      deferred.reject("invalid tagIds");

      return deferred.promise;
    }

    const url = this.env.getApiEndpoint("/tags");
    this.$http.delete<void>(url, { data: filtered }).then(
      () => {
        this.$rootScope.$broadcast("tagsChanged");

        deferred.resolve();
      },
      (error) => deferred.reject(error)
    );

    return deferred.promise;
  }

  public mergeTags(tagIds: string[], name: string): IPromise<void> {
    const url = this.env.getApiEndpoint("/tags/merge");

    return this.$http
      .post<void>(url, {
        mergeFrom: tagIds,
        mergeInto: name,
      })
      .then(() => {
        this.$rootScope.$broadcast("tagsChanged");
      });
  }
}
