import { Directive, OnInit } from "@angular/core";
import { Observable, tap } from "rxjs";
import { UXCustomizationType } from "@gtmhub/uxcustomization/models";
import { ICollection } from "@webapp/core/core.models";
import { INavItem, NavItemsType, SubNavItemsConfig } from "@webapp/navigation/models/nav-items-list.models";
import NavigationItemsMediator from "@webapp/navigation/services/uxcustomization/navigation-items.mediator.service";

@Directive()
export abstract class BaseSubNav implements OnInit {
  public suggested$: Observable<ICollection<INavItem>>;
  public favorites$: Observable<ICollection<INavItem>>;
  public recents$: Observable<ICollection<INavItem>>;
  public loading$: Observable<boolean>;
  protected navigationItemsMediator: NavigationItemsMediator;
  protected favoritesCollection: ICollection<INavItem>;
  private entityNavType: NavItemsType;
  private subNavItemsConfig: SubNavItemsConfig;

  public ngOnInit(): void {
    if (this.subNavItemsConfig.hasSuggested) {
      this.suggested$ = this.getSuggested$();
    }
    if (this.subNavItemsConfig.hasFavorites) {
      this.navigationItemsMediator.loadFavorites(this.entityNavType).subscribe();
      this.favorites$ = this.getFavorites$();
    }
    if (this.subNavItemsConfig.hasRecents) {
      this.navigationItemsMediator.loadRecents(this.entityNavType);
      this.recents$ = this.getRecent$();
    }
    this.loading$ = this.isLoading$();
  }

  public markItemAsFavorite(item: INavItem): void {
    this.navigationItemsMediator.markItemAsFavorite(item.id, item.uxcType, item, this.entityNavType);
  }

  public unmarkItemAsFavorite(item: INavItem): void {
    this.navigationItemsMediator.unmarkItemAsFavorite(item.favoriteId, item.uxcType, item, this.entityNavType);
  }

  public removeFromSuggested(item: INavItem): void {
    this.navigationItemsMediator.removeFromSuggested(item.id, item.uxcType);
  }

  protected setupEntityTypes(entityTypes: UXCustomizationType[], entityNavType: NavItemsType, subNavItemsConfig?: SubNavItemsConfig): void {
    this.entityNavType = entityNavType;
    this.subNavItemsConfig = subNavItemsConfig ?? { hasFavorites: true, hasSuggested: true, hasRecents: true };
  }

  private getRecent$(): Observable<ICollection<INavItem>> {
    return this.navigationItemsMediator.getRecentItems$(this.entityNavType);
  }

  private getFavorites$(): Observable<ICollection<INavItem>> {
    return this.navigationItemsMediator.getFavoritesItems$(this.entityNavType).pipe(
      tap((favorites) => {
        this.favoritesCollection = favorites;
        this.navigationItemsMediator.enrichRecents(this.favoritesCollection, this.entityNavType);
      })
    );
  }

  private getSuggested$(): Observable<ICollection<INavItem>> {
    return this.navigationItemsMediator.getSuggestedItems$(this.entityNavType).pipe(
      tap(() => {
        if (this.favoritesCollection) {
          this.navigationItemsMediator.enrichRecents(this.favoritesCollection, this.entityNavType);
        }
      })
    );
  }

  private isLoading$(): Observable<boolean> {
    return this.navigationItemsMediator.isLoading$();
  }
}
