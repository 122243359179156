import { PiActionBarFeedbackActionsComponent } from "./pi-action-bar-feedback-actions/pi-action-bar-feedback-actions.component";
import { PiCardActionBarComponent } from "./pi-card-action-bar/pi-card-action-bar.component";
import { PiCardIconComponent } from "./pi-card-icon/pi-card-icon.component";
import { PiCardWrapperComponent } from "./pi-card-wrapper/pi-card-wrapper.component";
import { PiErrorActionBarMainActionsComponent } from "./pi-error-action-bar-main-actions/pi-error-action-bar-main-actions.component";
import { PiFeedbackCardComponent } from "./pi-feedback-card.component";
import { PiSuggestionActionBarMainActionsComponent } from "./pi-suggestion-action-bar-main-actions/pi-suggestion-action-bar-main-actions.component";
import { PiTextSubcardComponent } from "./pi-text-subcard/pi-text-subcard.component";
import { PiFeedbackFacade } from "./services/feedback/pi-feedback-facade.service";
import { PiImprovementFacade } from "./services/improvement/pi-improvement-facade.service";
import { PiStateProcessorService } from "./services/state-processor/pi-state-processor.service";

export const piFeedbackCardDeclarations = [
  PiCardWrapperComponent,
  PiTextSubcardComponent,
  PiCardIconComponent,
  PiCardActionBarComponent,
  PiSuggestionActionBarMainActionsComponent,
  PiActionBarFeedbackActionsComponent,
  PiFeedbackCardComponent,
  PiErrorActionBarMainActionsComponent,
];

export const piFeedbackCardProviders = [PiStateProcessorService, PiFeedbackFacade, PiImprovementFacade];
