<ng-template #defaultTemplate>
  <div
    class="ant-spin"
    [class.spin-linear]="uiType === 'linear'"
    [class.ant-spin-rtl]="dir === 'rtl'"
    [class.ant-spin-spinning]="isLoading"
    [class.ant-spin-lg]="nzSize === 'large'"
    [class.ant-spin-sm]="nzSize === 'small'"
    [class.ant-spin-show-text]="nzTip">
    <div [class]="'loader-container loader-container-' + uiTipPosition">
      <ng-container *ngIf="uiType === 'circular'">
        <i class="busy-indicator-progress-color" ui-icon uiType="loading" uiSize="lg"></i>
      </ng-container>
      <ng-container *ngIf="uiType === 'linear'">
        <div class="linear-container">
          <div class="linear-loading">
            <div class="bar"></div>
          </div>
        </div>
      </ng-container>
      <div class="ant-spin-text" *ngIf="nzTip">{{ nzTip }}</div>
    </div>
  </div>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<ng-container *ngIf="isLoading; else content">
  <ng-template [ngTemplateOutlet]="nzIndicator || defaultTemplate"></ng-template>
</ng-container>
