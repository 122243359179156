import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AccountType } from "@webapp/accounts/models/accounts.models";
import { CurrentAccountRepository } from "@webapp/accounts/services/current-account-repository.service";

@UntilDestroy()
@Component({
  selector: "feature-banner",
  templateUrl: "./feature-banner.component.html",
  styleUrls: ["./feature-banner.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureBannerComponent implements OnInit {
  @Input() public hideScale: string;
  @Input() public cssClasses: string;

  public isTrialAccount: boolean;

  constructor(
    private currentAccountRepository: CurrentAccountRepository,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.currentAccountRepository
      .get$()
      .pipe(untilDestroyed(this))
      .subscribe((account) => {
        this.isTrialAccount = account.type === AccountType.TrialAccount;
        this.cdr.detectChanges();
      });
  }
}
