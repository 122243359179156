import { Injectable, OnDestroy } from "@angular/core";
import { ITeamsStoreState, receiveTeams } from "@gtmhub/teams";
import { ReduxCache } from "@webapp/core/state-management/redux-cache";
import { TeamBase } from "../models/teams.models";

@Injectable({ providedIn: "root" })
export class TeamsCache extends ReduxCache<ITeamsStoreState, Record<string, TeamBase>, Map<string, TeamBase>> implements OnDestroy {
  constructor() {
    super({
      selector: (state) => (state.teams.isFetched ? state.teams.map : undefined),
      setAction: (value) => receiveTeams(Array.from(value.values())),
      storeToCache: (value) => (value != null ? new Map(Object.entries(value)) : undefined),
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe?.();
  }
}
