import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { IIndicator } from "@gtmhub/error-handling";
import { FeatureModuleService } from "@webapp/feature-toggles/services/feature-module.service";
import { Gif, GifType, SizeOfGif } from "../gif.models";
import { TenorGifService } from "../tenor-gif.service";

@UntilDestroy()
@Component({
  selector: "gif-view",
  templateUrl: "./gif-view.component.html",
  styleUrls: ["./gif-view.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GifViewComponent implements OnInit, OnChanges {
  @Input() public gifSize: SizeOfGif;
  @Input() public gif: Gif;
  @Input() public showClearBtn: boolean;
  @Input() public isResponsive: boolean;

  public indicators: {
    loading?: IIndicator;
  } = {};
  public showGif = false;
  public isModuleGifsEnabled: boolean;
  public gifURL: string;

  constructor(
    private gifService: TenorGifService,
    private featureModuleService: FeatureModuleService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.featureModuleService
      .isGifsModuleEnabled$()
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.isModuleGifsEnabled = response;

        this.applyChangesBasedOnFeatureToggle();
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.gif) {
      const { currentValue, previousValue } = changes.gif;

      if (!currentValue) {
        this.clearGif();
      } else if (currentValue !== previousValue) {
        this.applyChangesBasedOnFeatureToggle();
      }
    }
  }

  public clearGif(): void {
    this.gifURL = "";
    this.gif = null;
    this.showGif = false;
  }

  private applyChangesBasedOnFeatureToggle(): void {
    if (!this.gif?.id) {
      return;
    }

    if (this.isModuleGifsEnabled) {
      const gifType: GifType = "gifs";

      this.gifService
        .getGifData({ gifType, gifSize: this.gifSize, gifId: this.gif.id })
        .pipe(untilDestroyed(this))
        .subscribe((response) => {
          this.gifURL = response[0].media?.url;
          this.showGif = true;
          this.cdr.detectChanges();
        });
    }
  }
}
