import { Directive, TemplateRef } from "@angular/core";
import { NzSafeAny } from "ng-zorro-antd/core/types";

@Directive({
  selector: "[uiDrawerContent]",
  exportAs: "uiDrawerContent",
  standalone: true,
})
export class UiDrawerContentDirective {
  constructor(public templateRef: TemplateRef<NzSafeAny>) {}
}
