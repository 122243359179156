import { IHttpService, IPromise, IQService, IRequestShortcutConfig } from "angular";
import { ICollection } from "@webapp/core/core.models";

export function getFullCollectionInBatches<T>(url: string, config: IRequestShortcutConfig, $http: IHttpService, $q: IQService): IPromise<ICollection<T>> {
  return $http.get<ICollection<T>>(url, config).then((firstBatchResponse) => {
    const { items, totalCount } = firstBatchResponse.data;
    let take: number;
    if ("take" in config.params) {
      take = config.params.take as number;
    } else if ("limit" in config.params) {
      take = config.params.limit as number;
    }

    if (totalCount > take) {
      const stepsPerformed = 1;
      const additionalStepsCount = Math.ceil(totalCount / take) - stepsPerformed;
      const promiseArr = Array.from({ length: additionalStepsCount }, (num, index) => {
        const nextStepOrder = index + 1;
        const skip = nextStepOrder * take;
        return $http.get<ICollection<T>>(url, { ...config, params: { ...config.params, skip } });
      });

      return $q.all(promiseArr).then((resolvedPromiseArr) => {
        const fullCollection = resolvedPromiseArr.reduce((collection, currentResponse) => {
          if (currentResponse && currentResponse.data && Array.isArray(currentResponse.data.items)) {
            collection.push(...currentResponse.data.items);
          }
          return collection;
        }, items);

        return { items: fullCollection, totalCount };
      });
    }

    return $q.resolve(firstBatchResponse.data);
  });
}
