<ui-card [uiBordered]="false" [uiHeadBorderless]="true" [uiRounded]="true" [uiExtra]="extraHeaderContentTemplate" uiTitle=" ">
  <ng-template #extraHeaderContentTemplate>
    <widget-actions [widgetId]="widgetId" [canRemove]="true" (remove)="remove()" />
  </ng-template>

  <img src="/dist/img/feed_preview.png" alt="" />
  <div class="title">{{ title }}</div>
  <p>{{ "get_realtime_updates" | localize }}</p>
  <a ui-button uiType="primary" uiShape="round" uiSize="small" uiSref="gtmhub.home.feed">{{ "view_feed" | localize }}</a>
</ui-card>
