import { UrlService } from "@uirouter/angular";
import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "app-bootstrap",
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor() {
    // resume URL service once we have bootstrapped Angular
    const injector = angular.element(document.body).injector();
    const urlService = injector.get<UrlService>("$urlService");
    urlService.listen();
    urlService.sync();
  }
}
