import { UserInvitationModalService } from "@gtmhub/users/components/user-invitation-form/user-invitation-modal.service";
import { PermissionsActions } from "@gtmhub/users/redux";
import { providerUpgradeFactory } from "@webapp/core/provider-upgrade/factories/provider-upgrade.factory";

export const UserInvitationModalServiceProvider = {
  provide: UserInvitationModalService,
  useFactory: providerUpgradeFactory("UserInvitationModalService"),
  deps: ["$injector"],
};

export const PermissionsActionsProvider = {
  provide: PermissionsActions,
  useFactory: providerUpgradeFactory("PermissionsActions"),
  deps: ["$injector"],
};
