import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from "@angular/core";
import { UiTSType } from "@quantive/ui-kit/core";
import { UiI18nService } from "@quantive/ui-kit/i18n";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { NzDestroyService } from "ng-zorro-antd/core/services";
import { NzTextEditComponent } from "ng-zorro-antd/typography";
import { UiAutosizeDirective } from "@webapp/ui/input/directives/autosize.directive";

@Component({
  selector: "ui-text-edit",
  exportAs: "uiTextEdit",
  templateUrl: "text-edit.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NzDestroyService],
  standalone: true,
  imports: [UiTooltipModule, UiIconModule, NgIf],
})
export class UiTextEditComponent extends NzTextEditComponent {
  @Input("text") public text?: string;
  @Input("icon") public icon: UiTSType = "edit";
  @Input("tooltip") public tooltip?: null | UiTSType;
  @Output("startEditing") public readonly startEditing = new EventEmitter<void>();
  @Output("endEditing") public readonly endEditing = new EventEmitter<string>(true);
  @ViewChild(UiAutosizeDirective, { static: false }) public autosizeDirective!: UiAutosizeDirective;

  constructor(zone: NgZone, host: ElementRef, cdr: ChangeDetectorRef, i18n: UiI18nService, destroy$: NzDestroyService) {
    super(zone, host, cdr, i18n, destroy$);
  }
}
