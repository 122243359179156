import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";
import { LoggingService } from "@webapp/core/logging/services/logging.service";

@Injectable()
export class LogFailedRequestsInterceptor implements HttpInterceptor {
  private noLogForResponseWithStatusCodes = [404, 409];
  private loggingService: LoggingService;

  constructor(
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private apiEndpointService: ApiEndpointService
  ) {
    this.loggingService = new LoggingService(this.appConfig.logging, "spa");
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error("An error occurred:", error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
        }

        const shouldLogError =
          error.url !== this.appConfig.logging.endpoint &&
          (!this.apiEndpointService.isApiRequest(error.url) || this.noLogForResponseWithStatusCodes.indexOf(error.status) < 0);

        if (shouldLogError) {
          this.loggingService.logHttpError(error, request);
        }

        const requestHeaders = {};
        if (request.headers.has("transaction-id")) {
          requestHeaders["transaction-id"] = request.headers.get("transaction-id");
        }

        const errorWithHeaders: HttpErrorResponse = new HttpErrorResponse({
          ...error,
          headers: { ...error.headers, ...requestHeaders } as HttpHeaders,
        });

        return throwError(() => errorWithHeaders);
      })
    );
  }
}
