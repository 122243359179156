import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";

@Injectable({
  providedIn: "any",
})
export abstract class BaseState {
  private loading$: BehaviorSubject<boolean>[] = [];
  private error$: BehaviorSubject<HttpErrorResponse | null>[] = [];
  private success$: BehaviorSubject<boolean>[] = [];

  constructor() {
    for (const httpActionsKey in HttpActions) {
      this.loading$[httpActionsKey] = new BehaviorSubject<boolean>(false);
      this.error$[httpActionsKey] = new BehaviorSubject<HttpErrorResponse | null>(null);
      this.success$[httpActionsKey] = new BehaviorSubject<boolean>(false);
    }
  }
  public isLoading$(method: HttpActions): Observable<boolean> {
    return this.loading$[method].asObservable();
  }

  // eslint-disable-next-line @foxglove/no-boolean-parameters
  public setIsLoading(method: HttpActions, isLoading: boolean): void {
    this.loading$[method].next(isLoading);
  }

  public isError$(method: HttpActions): Observable<HttpErrorResponse | null> {
    return this.error$[method].asObservable();
  }

  public setIsError(method: HttpActions, response: HttpErrorResponse | null): void {
    this.error$[method].next(response);
  }

  public isSuccess$(method: HttpActions): Observable<boolean> {
    return this.success$[method].asObservable();
  }

  // eslint-disable-next-line @foxglove/no-boolean-parameters
  public setIsSuccess(method: HttpActions, isSuccess: boolean): void {
    this.success$[method].next(isSuccess);
  }
}
