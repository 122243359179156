<button
  ui-button
  uiType="link"
  class="centered-vert hov-cursor-p justify-cont-c"
  [attr.aria-label]="isFavorite ? 'Remove from favorites' : 'Add to favorites'"
  [attr.aria-pressed]="isFavorite"
  (click)="toggleFavorite()"
  tabindex="0">
  <i class="fs-16px" *ngIf="!isFavorite" ui-icon uiType="favorite" uiTheme="outline"></i>
  <i class="fs-16px" *ngIf="isFavorite" ui-icon uiType="favorite" uiTheme="fill"></i>
</button>
