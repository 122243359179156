import {
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  forwardRef,
} from "@angular/core";
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { map } from "rxjs";
import { CustomFieldLabelComponent } from "@webapp/custom-fields/components/label/label.component";
import { CustomFieldsFacade } from "@webapp/custom-fields/services/custom-fields-facade.service";
import { CustomFieldValidatorService } from "@webapp/custom-fields/services/custom-fields-validator.sevice";
import { CustomFieldComponentFactory } from "@webapp/custom-fields/services/factories/custom-field.factory";
import { identity } from "@webapp/shared/utils/identity";
import { CfMap, CustomFieldDynamicComponent, CustomFieldTargetType, CustomFieldUpdate, EditableCustomFieldsInput, ICustomField } from "../../models/custom-fields.models";

const CUSTOM_FIELDS_VALUE_ACCESSOR = { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => EditableCustomFieldsComponent), multi: true };
const CUSTOM_FIELDS_VALIDATORS = { provide: NG_VALIDATORS, useExisting: forwardRef(() => EditableCustomFieldsComponent), multi: true };

@UntilDestroy()
@Component({
  selector: "editable-custom-fields",
  template: `<div [class]="formName"></div>`,
  providers: [CUSTOM_FIELDS_VALUE_ACCESSOR, CUSTOM_FIELDS_VALIDATORS],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./editable-custom-fields.less"],
})
export class EditableCustomFieldsComponent implements OnChanges, OnDestroy, ControlValueAccessor, Validator {
  @Input()
  public formName: string;
  @Input()
  public targetType: CustomFieldTargetType;
  @Input()
  public customFieldValues: CfMap;
  @Input()
  public disableRequired = false;
  @Input()
  public updateOnBlur: boolean;
  @Input()
  public filterEmptyValues: boolean;
  @Input()
  public borderless = true;
  @Input()
  public editorWidth: number;
  @Input()
  public customFieldsFilter: (customFields: ICustomField[]) => ICustomField[] = identity;
  @Input()
  public isFieldDisabled: (fieldName: string) => boolean = () => false;
  @Input()
  public isFieldReadonly: (fieldName: string) => boolean = () => false;

  @Output() public readonly fieldsChange = new EventEmitter<CfMap>();
  @Output() public readonly checkRequired = new EventEmitter<{ areFilled: boolean }>();
  @Output() public readonly customFieldsLoaded = new EventEmitter<ICustomField[]>();

  private customFields: ICustomField[];
  private customFieldLabel: ComponentRef<CustomFieldLabelComponent>[] = [];
  private customFieldBody: ComponentRef<CustomFieldDynamicComponent>[] = [];
  private customFieldRow: HTMLElement[];

  private notifyControlChange: (value: CfMap) => void;

  constructor(
    private appRef: ApplicationRef,
    private customFieldComponentFactory: CustomFieldComponentFactory,
    private customFieldsFacade: CustomFieldsFacade,
    private customFieldValidatorService: CustomFieldValidatorService,
    private elementRef: ElementRef<HTMLElement>
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.customFieldValues) {
      this.customFieldValues = {};
    }

    if (!equal(changes.customFieldValues?.currentValue, changes.customFieldValues?.previousValue) || changes.targetType || changes.customFieldsFilter) {
      this.getCustomFields();
    }
  }

  public writeValue(value: CfMap): void {
    const normalizedValue = value ?? {};
    if (!equal(this.customFieldValues, normalizedValue)) {
      this.customFieldValues = normalizedValue;
      this.getCustomFields();
    }

    this.checkRequired.emit({ areFilled: this.customFieldValidatorService.checkIfAllRequiredNotEmpty(this.customFields, this.customFieldValues) });
  }

  public registerOnChange(fn: (value: CfMap) => void): void {
    this.notifyControlChange = fn;
  }

  public registerOnTouched(): void {
    // required by the ControlValueAccessor interface
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value ?? {};

    if (this.customFields && !this.customFieldValidatorService.checkIfAllRequiredNotEmpty(this.customFields, value)) {
      return { requiredCfEmpty: true };
    }

    return null;
  }

  private getCustomFields(): void {
    this.customFieldsFacade
      .getCustomFieldsByTargetType$(this.targetType)
      .pipe(
        map((customFields) => {
          if (this.filterEmptyValues) {
            return customFields.map[this.targetType].filter((customField) => customField.name in this.customFieldValues);
          }

          return customFields.map[this.targetType];
        }),
        untilDestroyed(this)
      )
      .subscribe((customFields: ICustomField[]) => {
        this.customFields = customFields ? customFields : [];
        this.customFields.sort((a, b) => a.orderId - b.orderId);
        this.customFields = this.customFieldsFilter(this.customFields);
        this.customFieldsLoaded.emit(this.customFields);

        if (this.customFieldLabel.length || this.customFieldBody.length) {
          this.detachCustomFieldsFromView();
        }

        this.checkRequired.emit({ areFilled: this.customFieldValidatorService.checkIfAllRequiredNotEmpty(this.customFields, this.customFieldValues) });

        this.createCustomFieldsComponent();
      });
  }

  private detachCustomFieldsFromView(): void {
    this.customFieldLabel.forEach((label) => {
      this.appRef.detachView(label.hostView);
      label.hostView.destroy();
    });

    this.customFieldBody.forEach((body) => {
      this.appRef.detachView(body.hostView);
      body.hostView.destroy();
    });

    this.customFieldRow.forEach((row) => {
      row.remove();
    });
  }

  private generateCustomFieldsInputValues(customFieldName: string): EditableCustomFieldsInput {
    return {
      values: this.customFieldValues,
      disabled: this.isFieldDisabled(customFieldName),
      readonly: this.isFieldReadonly(customFieldName),
      borderless: this.borderless,
      disableRequired: this.disableRequired,
      updateOnBlur: this.updateOnBlur,
      formName: this.formName,
      onChange: (updated: CustomFieldUpdate): void => {
        this.customFieldValues[updated.fieldName] = updated.customField;

        this.checkRequired.emit({ areFilled: this.customFieldValidatorService.checkIfAllRequiredNotEmpty(this.customFields, this.customFieldValues) });
        this.fieldsChange.emit(this.customFieldValues);
        this.notifyControlChange?.(this.customFieldValues);
      },
    };
  }

  private createCustomFieldsComponent(): void {
    this.customFieldLabel = [];
    this.customFieldBody = [];
    this.customFieldRow = [];

    this.customFields.forEach((customField, i) => {
      const customFieldsInputValues: EditableCustomFieldsInput = this.generateCustomFieldsInputValues(customField.name);
      const { customFieldLabel, customFieldBody } = this.customFieldComponentFactory.build(customField.visualizationKind, customField, customFieldsInputValues);

      this.customFieldLabel.push(customFieldLabel);
      this.customFieldBody.push(customFieldBody);

      this.createCustomFieldRow(i, customFieldLabel, customFieldBody, customField);

      this.appRef.attachView(customFieldLabel.hostView);
      this.appRef.attachView(customFieldBody.hostView);
    });
  }

  private createCustomFieldRow(
    index: number,
    customFieldLabel: ComponentRef<CustomFieldLabelComponent>,
    customFieldBody: ComponentRef<unknown>,
    customField: ICustomField
  ): void {
    const row: HTMLElement = document.createElement("div");
    row.setAttribute("data-test-id", `custom-field-row-${customField.label}`);
    row.className = "custom-field-row";
    this.customFieldRow.push(row);
    this.elementRef.nativeElement.firstChild.appendChild(row);

    this.elementRef.nativeElement.firstChild.childNodes[index].appendChild(customFieldLabel.location.nativeElement);
    this.elementRef.nativeElement.firstChild.childNodes[index].appendChild(customFieldBody.location.nativeElement);

    if (this.editorWidth) {
      customFieldBody.location.nativeElement.style.maxWidth = `${this.editorWidth}px`;
    }
  }

  public ngOnDestroy(): void {
    if (this.customFieldLabel.length || this.customFieldBody.length) {
      this.detachCustomFieldsFromView();
    }
  }
}
