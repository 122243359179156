<div [formGroup]="formGroup" ui-col uiFlex="1">
  <div
    class="objective sp-mt-12"
    *ngFor="let objective of objectives.controls; let index = index"
    (mouseenter)="!isEditing && !suggestingKeyResults && (objective.value.showActions = true)"
    (mouseleave)="!objective.value.isEdit && (objective.value.showActions = false)"
    formArrayName="objectives">
    <div [formGroupName]="index">
      <div uiAlign="top" ui-row>
        <div [ngClass]="objective.value.isEdit ? 'sp-mt-6' : 'sp-mt-3'" ui-col>
          <i class="fs-16px" ui-icon uiTheme="fill" uiType="goal"></i>
        </div>

        <div class="sp-ml-12" *ngIf="!objective.value.isEdit" [uiFlex]="calculateObjectiveDataContainerWidth(objective)" ui-col>
          <div class="fs-16px title text-color-gray-13" ui-row data-test-id="suggest-okrs-modal-objective-suggestion">{{ objective.value.title }}</div>
          <div class="sp-mt-6 text-color-gray-9" *ngIf="!objective.value.isFetchingDescription && !objective.value.isFetchingDescriptionError" ui-row>{{ objective.value.description }}</div>

          <ui-skeleton
            class="sp-py-12"
            *ngIf="objective.value.isFetchingDescription"
            [uiParagraph]="{ rows: 1, width: '100%' }"
            [uiTitle]="false"
            [uiActive]="true"
            [uiSectionHeight]="{ title: 'h-lg', paragraph: 'h-sm' }"
            ui-row>
          </ui-skeleton>

          <div class="sp-py-2 description-error" *ngIf="objective.value.isFetchingDescriptionError" ui-row uiAlign="middle">
            <i class="fs-16px text-color-gray-1 sp-ml-1" ui-icon uiType="quantive-plus-caution" ui-col></i>
            <span ui-col uiFlex="auto">{{ "unable_to_suggest_description" | localize }}</span>
          </div>

          <div class="assignees" [ngClass]="objective.value.isFetchingDescriptionError ? 'sp-mt-6' : 'sp-mt-12'" ui-row>
            <ui-assignee *ngFor="let id of objective.value.assignees" [uiAssignee]="assigneeIdMap[id]" uiSize="sm"></ui-assignee>
          </div>
        </div>

        <div class="sp-ml-12" *ngIf="objective.value.isEdit" ui-col uiFlex="1">
          <input class="sp-mb-12" [uiFocus]="true" formControlName="title" ui-input ui-row type="text" />

          <ui-textarea class="sp-mb-12" *ngIf="!objective.value.isManual" ui-row>
            <textarea formControlName="description" rows="4" ui-input></textarea>
          </ui-textarea>

          <people-selector
            class="sp-mt-6"
            [a11yRequired]="true"
            [atLeastOneValue]="true"
            [selectedIds]="getAssignees(objective)"
            [placeholderKey]="'add_users' | localize"
            (selectionChange)="setAssignees(objective, $event)">
          </people-selector>
        </div>

        <div class="sp-ml-12" *ngIf="(objective.value.showActions || objective.value.isManual) && !objective.value.isFetchingDescription && !isKeyResultsStage" ui-col>
          <div class="btns" *ngIf="!objective.value.isEdit" ui-row uiJustify="space-between">
            <button
              class="sp-p-8"
              *ngIf="objective.value.isFetchingDescriptionError"
              [uiTooltipTitle]="'suggest_again' | localize"
              (click)="refreshDescription(objective, index)"
              ui-button
              uiSize="default"
              uiType="link"
              ui-tooltip
              uiTooltipPlacement="topRight">
              <i class="fs-16px" ui-icon uiType="suggestion-card-refresh"></i>
            </button>

            <button class="sp-p-8" [uiTooltipTitle]="'edit_suggestion' | localize" (click)="openEditMode(objective)" ui-button uiType="link" ui-tooltip uiTooltipPlacement="topRight">
              <i class="fs-16px" ui-icon uiType="edit"></i>
            </button>

            <button class="sp-p-8" [uiTooltipTitle]="'remove_suggestion' | localize" (click)="removeObjective.emit(index)" ui-button uiType="link" ui-tooltip uiTooltipPlacement="topRight">
              <i class="fs-16px" ui-icon uiType="close"></i>
            </button>
          </div>

          <div class="confirm-btns" *ngIf="objective.value.isEdit" ui-row uiJustify="space-between" uiAlign="middle">
            <button class="sp-p-8 sp-mr-12" [uiTooltipTitle]="'cancel' | localize" (click)="cancel(objective, index)" ui-button uiType="link" ui-tooltip uiTooltipPlacement="topRight">
              <i class="text-color-gray-6 fs-16px remove-btn" ui-icon uiType="close"></i>
            </button>

            <button
              [disabled]="objective.invalid"
              [uiTooltipTitle]="'confirm_or_enter' | localize"
              (click)="confirm(objective, index)"
              ui-button
              uiType="primary"
              uiShape="circle"
              uiSize="small"
              ui-tooltip
              uiTooltipPlacement="topRight">
              <i class="text-color-gray-1 fs-16px" ui-icon uiType="check"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="sp-mt-12" *ngIf="isKeyResultsStage && (objective.value.isFetchedKeyResultSuggestions || objective.value.isFetchingKeyResultSuggestionsError || objective.value.keyResults.length)">
      <div class="sp-pl-32" *ngIf="!objective.value.isFetchingKeyResultSuggestionsError || objective.value.keyResults.length">
        <key-result
          [isEditing]="isEditing"
          [objective]="objective"
          [formGroup]="formGroup"
          [showKeyResultActions]="objective.value.showActions"
          [flowId]="flowId"
          [flowName]="flowName"
          (removeKeyResult)="removeKeyResult($event, index)"></key-result>
      </div>

      <div class="sp-pl-32" *ngIf="objective.value.isFetchingKeyResultSuggestionsError" [ngClass]="!objective.value.keyResults.length && 'sp-mt-14'">
        <quantive-plus-wizard-card-error-inline
          [errorMessage]="'unable_to_suggest_key_results' | localize"
          [showActionButtons]="objective.value.showActions"
          [isRemoveBtnAvailable]="false"
          (errorRefresh)="refreshKeyResultsError(index)"></quantive-plus-wizard-card-error-inline>
      </div>
    </div>

    <div class="sp-pl-32 sp-mt-12" *ngIf="isKeyResultsStage && !objective.value.isFetchingKeyResultSuggestions && this.objectiveKeyResults(objective).length < 10" ui-row>
      <a class="sp-mr-12" [disabled]="isEditingObjectiveKeyResults(objective)" (click)="suggestMoreKeyResults(index, objective)" ui-button uiShape="round" uiType="link" uiSize="default">
        <i ui-icon uiTheme="fill" uiType="loader_refresh"></i>
        <span class="sp-ml-12">{{ "suggest_more" | localize }}</span>
      </a>

      <a [disabled]="isEditingObjectiveKeyResults(objective)" (click)="addKeyResult(index, objective)" ui-button uiShape="round" uiType="link" uiSize="default">
        <i ui-icon uiType="plus"></i>
        <span class="sp-ml-12">{{ "add_manually" | localize }}</span>
      </a>
    </div>

    <div class="sp-ml-32 sp-mt-12" *ngIf="objective.value.isFetchingKeyResultSuggestions">
      <div class="sp-mt-12" *ngFor="let i of [1, 2, 3]" ui-row>
        <div ui-col>
          <i class="fs-16px" ui-icon uiTheme="fill" uiType="metric"></i>
        </div>

        <div class="sp-ml-12" ui-col uiFlex="528px">
          <ui-skeleton [uiParagraph]="{ rows: 1, width: '100%' }" [uiTitle]="false" [uiActive]="true" [uiSectionHeight]="{ title: 'h-lg', paragraph: 'h-sm' }" ui-row></ui-skeleton>
        </div>
      </div>
    </div>
  </div>
</div>
