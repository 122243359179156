import { ChangeDetectionStrategy, Component, ElementRef, Input } from "@angular/core";
import { UiTSType } from "@quantive/ui-kit/core";
import { UiIconSize, UiThemeType } from "@quantive/ui-kit/icon";
import { UiTooltipPlacement } from "@quantive/ui-kit/tooltip";

/**
 * Use this component only in Angular.js
 */
@Component({
  // eslint-disable-next-line webapp/no-component-selector-prefix
  selector: "gh-icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["icon.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    alt: "icon",
  },
})
export class IconComponent {
  @Input()
  public uiSpin: boolean;

  @Input()
  public uiType: string;

  @Input()
  public uiTheme: UiThemeType;

  @Input()
  public uiTwotoneColor: string;

  @Input()
  public uiSize: UiIconSize;

  @Input()
  public uiIconfont: string;

  @Input()
  public uiRotate: number;

  @Input("uiAlt")
  public alt?: string;

  @Input()
  public tooltipAppendToBody = false;

  @Input("uiTooltipTitle")
  public tooltipTitle?: UiTSType;

  @Input("uiTooltipPlacement")
  public tooltipPosition?: UiTooltipPlacement = "top";

  @Input("uiTooltipOverlayClassName")
  public uiTooltipOverlayClassName?: string;

  @Input("uiTooltipOrigin")
  public tooltipOrigin?: ElementRef<HTMLElement>;
}
