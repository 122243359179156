import { Observable, Subject } from "rxjs";
import { SuggestionDrawerIndicatorStatePropagatorParams } from "../models/suggestion-drawer.models";

abstract class SuggestionDrawerStatePropagator<T> {
  protected stateChange$: Subject<T> = new Subject<T>();

  public subscribeToStateChanges$(): Observable<T> {
    return this.stateChange$.asObservable();
  }

  public abstract emitStateChange(change: T): void;
}

export class SuggestionDrawerIndicatorStatePropagator extends SuggestionDrawerStatePropagator<SuggestionDrawerIndicatorStatePropagatorParams> {
  private drawerState: SuggestionDrawerIndicatorStatePropagatorParams;

  public emitStateChange(params: SuggestionDrawerIndicatorStatePropagatorParams): void {
    if (!this.drawerState) {
      this.drawerState = params;
      this.stateChange$.next(this.drawerState);
    }

    const hasDrawerStateChanged =
      this.drawerState.isLoading !== params.isLoading ||
      this.drawerState.suggestionStateLevel !== params.suggestionStateLevel ||
      this.drawerState.hasRequiredDependencies !== params.hasRequiredDependencies ||
      this.drawerState.isSuggestionAvailable !== params.isSuggestionAvailable;
    if (!hasDrawerStateChanged) return;

    this.drawerState = params;
    this.stateChange$.next(this.drawerState);
  }
}

export class SuggestionDrawerOverviewTabStatePropagator extends SuggestionDrawerStatePropagator<{ isLoading: boolean }> {
  private overviewTabState: { isLoading: boolean };

  public emitStateChange(params: { isLoading: boolean }): void {
    if (!this.overviewTabState) {
      this.overviewTabState = params;
      this.stateChange$.next(this.overviewTabState);
    }

    const hasOverviewTabChanged = this.overviewTabState.isLoading !== params.isLoading;
    if (!hasOverviewTabChanged) return;

    this.overviewTabState = params;
    this.stateChange$.next(this.overviewTabState);
  }
}

export class SuggestionDrawerEffectivenessTabStatePropagator extends SuggestionDrawerStatePropagator<{
  isLoading: boolean;
  effectivenessScore: number;
}> {
  private effectivenessTabState: { isLoading: boolean; effectivenessScore: number };

  public emitStateChange(params: { isLoading: boolean; effectivenessScore: number }): void {
    if (!this.effectivenessTabState) {
      this.effectivenessTabState = params;
      this.stateChange$.next(this.effectivenessTabState);
    }

    const hasOverviewTabChanged =
      this.effectivenessTabState.isLoading !== params.isLoading || this.effectivenessTabState.effectivenessScore !== params.effectivenessScore;
    if (!hasOverviewTabChanged) return;

    this.effectivenessTabState = params;
    this.stateChange$.next(this.effectivenessTabState);
  }
}
export class SuggestionDrawerAllSuggestionsTabStatePropagator extends SuggestionDrawerStatePropagator<{
  isLoading: boolean;
}> {
  private allSuggestionsTabState: { isLoading: boolean };

  public emitStateChange(params: { isLoading: boolean }): void {
    if (!this.allSuggestionsTabState) {
      this.allSuggestionsTabState = params;
      this.stateChange$.next(this.allSuggestionsTabState);
    }

    const hasAllSuggestionsTabChanged = this.allSuggestionsTabState.isLoading !== params.isLoading;
    if (!hasAllSuggestionsTabChanged) return;

    this.allSuggestionsTabState = params;
    this.stateChange$.next(this.allSuggestionsTabState);
  }
}
