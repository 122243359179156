import { IComponentOptions } from "angular";

export class DndListItemComponentCtrl<P> {
  item: P;
}

export const GhDndListItemComponent: IComponentOptions = {
  require: { ghDndList: "^" },
  controller: DndListItemComponentCtrl,
  bindings: {
    item: "<",
  },
};
