import { Injectable } from "@angular/core";
import { emptyStatesMap, getEmptyStateInfoBoxes } from "./empty-state-utils";
import { EmptyState, InfoBox } from "./models";

@Injectable({
  providedIn: "root",
})
export class EmptyStateService {
  public getEmptyStateInfoBoxes(id: string): InfoBox[] {
    const emptyStateInfoBoxes = getEmptyStateInfoBoxes()[id];

    if (!emptyStateInfoBoxes) {
      console.error(`Empty State info boxes with id: ${id}, not found.`);
    }

    return emptyStateInfoBoxes || [];
  }

  public getEmptyState(id: string): EmptyState {
    const emptyState = emptyStatesMap()[id];

    if (!emptyState) {
      console.error(`Empty State with id: ${id}, not found.`);
    }

    return emptyState;
  }
}
