import { CurrentUserRolesAction, ICurrentUserRolesState } from "./models";

export function currentUserRolesReducer(
  state: ICurrentUserRolesState = {
    isFetched: false,
    fetching: false,
    nonShadowRoles: [],
    shadowRoles: [],
    error: null,
  },
  action: CurrentUserRolesAction
): ICurrentUserRolesState {
  switch (action.type) {
    case "REQUEST_CURRENT_USER_ROLES":
      return { ...state, fetching: true };
    case "RECEIVE_CURRENT_USER_ROLES":
      return { ...state, fetching: false, isFetched: true, nonShadowRoles: action.nonShadowRoles, shadowRoles: action.shadowRoles };
    case "RECEIVE_CURRENT_USER_ROLES_ERROR":
      return { ...state, fetching: false, error: action.error };

    default: {
      return state;
    }
  }
}
