import { Injector } from "@angular/core";
import { BehaviorSubject, Observable, combineLatest, map, of, switchMap, take } from "rxjs";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { SuggestedInsights } from "@webapp/data-story/models/insights.models";
import { AutoInsightsFacade } from "@webapp/data-story/services/auto-insights-facade/auto-insights-facade.service";
import {
  ItemAutomationSelectorDataType,
  ItemAutomationSupportedSuggestionModeType,
  ItemAutomationTargetType,
} from "@webapp/shared/components/item-automation-selector//models/item-automation-selector.models";
import { ItemAutomationSuggestion } from "@webapp/shared/components/item-automation-selector//models/item-automation-suggestions.models";
import { SupportedItemAutomationSuggestionTypeService } from "@webapp/shared/components/item-automation-selector//providers/supported-automation-suggestion-type.service";
import { ExtendWithWriteValueOriginCheck } from "../components/item-automation-selector/item-automation-selector.component";
import {
  filterSuggestions,
  suggestedInsightToItemAutomationSuggestion,
  suggestedMarketplaceInsightTemplateToItemAutomationSuggestion,
} from "./item-automation-suggestions.utils";

export class ItemAutomationSuggestionsManager {
  private supportedItemAutomationSuggestionTypeService: SupportedItemAutomationSuggestionTypeService;
  private editionFeatureService: EditionFeatureService;
  private autoInsightsFacade: AutoInsightsFacade;

  constructor(
    private injector: Injector,
    private selectorData$: BehaviorSubject<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"insight"> | ItemAutomationSelectorDataType<"kpi">>>
  ) {
    this.supportedItemAutomationSuggestionTypeService = this.injector.get(SupportedItemAutomationSuggestionTypeService);
    this.editionFeatureService = this.injector.get(EditionFeatureService);
    this.autoInsightsFacade = this.injector.get(AutoInsightsFacade);
  }

  public setSuggestionToEmptyListForItemType$({
    itemType,
  }: {
    itemType: ItemAutomationTargetType;
  }): Observable<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"insight"> | ItemAutomationSelectorDataType<"kpi">>> {
    const supportedTypeCheckMap: {
      [ItemType in ItemAutomationTargetType]: () => ReturnType<
        ItemAutomationSuggestionsManager[Extract<keyof ItemAutomationSuggestionsManager, "setSuggestionToEmptyListForKr$" | "setSuggestionToEmptyListForKpi$">]
      >;
    } = {
      kr: () => this.setSuggestionToEmptyListForKr$.bind(this)(),
      kpi: () => this.setSuggestionToEmptyListForKpi$.bind(this)(),
    };

    return supportedTypeCheckMap[itemType]();
  }

  public setSuggestionToEmptyListForKr$(): Observable<
    ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"insight"> | ItemAutomationSelectorDataType<"kpi">>
  > {
    return this.supportedItemAutomationSuggestionTypeService.getSupportedAutomationSuggestionTypeForItemType$("kr").pipe(
      switchMap((suggestionType) => {
        return this.setSuggestionToEmptyListForSuggestionType$({ suggestionType });
      })
    );
  }

  public setSuggestionToEmptyListForKpi$(): Observable<
    ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"insight"> | ItemAutomationSelectorDataType<"kpi">>
  > {
    return this.supportedItemAutomationSuggestionTypeService.getSupportedAutomationSuggestionTypeForItemType$("kpi").pipe(
      switchMap((suggestionType) => {
        return this.setSuggestionToEmptyListForSuggestionType$({ suggestionType });
      })
    );
  }

  private setSuggestionToEmptyListForSuggestionType$({
    suggestionType,
  }: {
    suggestionType: ItemAutomationSupportedSuggestionModeType;
  }): Observable<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"insight"> | ItemAutomationSelectorDataType<"kpi">>> {
    const supportedSuggestionTypeCheckMap: {
      [SuggestionType in ItemAutomationSupportedSuggestionModeType]: ItemAutomationSuggestionsManager[Extract<
        keyof ItemAutomationSuggestionsManager,
        "setSuggestionToEmptyListForSuggestionTypeInsight$"
      >];
    } = {
      insight: this.setSuggestionToEmptyListForSuggestionTypeInsight$.bind(this),
    };

    return supportedSuggestionTypeCheckMap[suggestionType]();
  }

  public setSuggestionToEmptyListForSuggestionTypeInsight$(): Observable<
    ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"insight"> | ItemAutomationSelectorDataType<"kpi">>
  > {
    return of({
      ...this.selectorData$.value,
      data: {
        ...this.selectorData$.value?.data,
        suggestedInsights: [],
        suggestionSource: null,
        suggestedMarketplaceCount: null,
        suggestedAccountCount: null,
        marketplacePredictionId: null,
        accountPredictionId: null,
        insightTemplate: null,
      },
    });
  }

  public getAutomationSuggestionsForItemType$({
    itemType,
    itemTitle,
  }: {
    itemType: ItemAutomationTargetType;
    itemTitle: string;
  }): Observable<ItemAutomationSuggestion[]> {
    const supportedTypeCheckMap: {
      [ItemType in ItemAutomationTargetType]: () => ReturnType<
        ItemAutomationSuggestionsManager[Extract<keyof ItemAutomationSuggestionsManager, "getAutomationSuggestionsForKr$" | "getAutomationSuggestionsForKpi$">]
      >;
    } = {
      kr: () => this.getAutomationSuggestionsForKr$.bind(this)(itemTitle),
      kpi: () => this.getAutomationSuggestionsForKpi$.bind(this)(itemTitle),
    };

    return supportedTypeCheckMap[itemType]();
  }

  public getAutomationSuggestionsForKr$(itemTitle: string): Observable<ItemAutomationSuggestion[]> {
    return this.supportedItemAutomationSuggestionTypeService.getSupportedAutomationSuggestionTypeForItemType$("kr").pipe(
      switchMap((suggestionType) => {
        return this.getSuggestionsForSuggestionType$({ suggestionType, itemTitle });
      })
    );
  }

  public getAutomationSuggestionsForKpi$(itemTitle: string): Observable<ItemAutomationSuggestion[]> {
    return this.supportedItemAutomationSuggestionTypeService.getSupportedAutomationSuggestionTypeForItemType$("kpi").pipe(
      switchMap((suggestionType) => {
        return this.getSuggestionsForSuggestionType$({ suggestionType, itemTitle });
      })
    );
  }

  private getSuggestionsForSuggestionType$({
    suggestionType,
    itemTitle,
  }: {
    suggestionType: ItemAutomationSupportedSuggestionModeType;
    itemTitle: string;
  }): Observable<ItemAutomationSuggestion[]> {
    const supportedSuggestionTypeCheckMap: {
      [SuggestionType in ItemAutomationSupportedSuggestionModeType]: ItemAutomationSuggestionsManager[Extract<
        keyof ItemAutomationSuggestionsManager,
        "getSuggestionsOfTypeInsight$"
      >];
    } = {
      insight: this.getSuggestionsOfTypeInsight$.bind(this),
    };

    return supportedSuggestionTypeCheckMap[suggestionType](itemTitle);
  }

  public getSuggestionsOfTypeInsight$(itemTitle: string): Observable<ItemAutomationSuggestion[]> {
    return this.editionFeatureService.hasFeature$("marketplace").pipe(
      take(1),
      switchMap((isMarketplaceEnabled) => {
        return combineLatest({
          marketplaceSuggestions: isMarketplaceEnabled
            ? this.autoInsightsFacade.getInsightRecommendations$(itemTitle, { fromAccount: false })
            : (of({ marketplaceTemplates: [] }) as Observable<SuggestedInsights>),
          accountSuggestions: this.autoInsightsFacade.getInsightRecommendations$(itemTitle, { fromAccount: true }),
        });
      }),
      map(({ marketplaceSuggestions, accountSuggestions }) => {
        const originalList = [
          marketplaceSuggestions.marketplaceTemplates.map((suggestion) => suggestedMarketplaceInsightTemplateToItemAutomationSuggestion(suggestion)),
          accountSuggestions.accountInsights.map((suggestion) => suggestedInsightToItemAutomationSuggestion(suggestion)),
        ].flat();

        filterSuggestions(accountSuggestions.accountInsights, marketplaceSuggestions.marketplaceTemplates);
        const filteredList = [
          marketplaceSuggestions.marketplaceTemplates.map((suggestion) => suggestedMarketplaceInsightTemplateToItemAutomationSuggestion(suggestion)),
          accountSuggestions.accountInsights.map((suggestion) => suggestedInsightToItemAutomationSuggestion(suggestion)),
        ].flat();

        const updatedSelectorData = {
          ...this.selectorData$.value.data,
          suggestedInsights: [...originalList],
          suggestedMarketplaceCount: marketplaceSuggestions.marketplaceTemplates.length,
          suggestedAccountCount: accountSuggestions.accountInsights.length,
          accountPredictionId: accountSuggestions.predictionId,
          marketplacePredictionId: marketplaceSuggestions.predictionId,
        };

        this.selectorData$.next({
          ...this.selectorData$.value,
          data: updatedSelectorData,
        });

        return filteredList;
      })
    );
  }
}
