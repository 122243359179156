import { IPromise } from "angular";
import { Subject } from "rxjs";
import { IRestLayerRequest } from "@gtmhub/core";
import { EnvironmentService } from "@gtmhub/env";
import { UXCustomizationType } from "@gtmhub/uxcustomization/models";
import { INavItem } from "@webapp/navigation/models/nav-items-list.models";
import { ViewHistoryPayloadBuilder } from "@webapp/navigation/utils/view-history-payload.builder";
import { IViewHistoryItemResponse, IViewHistoryItemsGroupedResponse, IViewHistoryPayload, ViewHistoryItemSource } from "./models";

export interface IViewHistoryItemsGroupedReqParams extends IRestLayerRequest {
  filter:
    | {
        type: {
          $in: UXCustomizationType[];
        };
      }
    | {
        $and: [
          {
            type: {
              $in: UXCustomizationType[];
            };
          },
          {
            source: {
              $in: ViewHistoryItemSource[];
            };
          },
        ];
      };
  // used to exclude particular items from response
  rejectedIds?: string[];
}

interface IViewHistoryChange {
  type: UXCustomizationType;
  source: ViewHistoryItemSource;
  item: INavItem;
}

export class ViewHistoryService {
  public static $inject = ["$http", "EnvironmentService", "ViewHistoryPayloadBuilder"];
  public viewHistoryChangeSubject: Subject<IViewHistoryChange>;
  public viewHistoryPatchSubject: Subject<IViewHistoryChange>;

  constructor(
    private $http: ng.IHttpService,
    private env: EnvironmentService,
    public payloadBuilder: ViewHistoryPayloadBuilder
  ) {
    this.viewHistoryChangeSubject = new Subject();
    this.viewHistoryPatchSubject = new Subject();
  }

  public getViewHistoryItems(params: IViewHistoryItemsGroupedReqParams): IPromise<IViewHistoryItemsGroupedResponse> {
    const url = this.env.getUXCustomizationEndpoint(`/viewhistory`);

    return this.$http.get<IViewHistoryItemsGroupedResponse>(url, { params }).then((response) => response.data);
  }

  public addItemToViewHistory<T extends UXCustomizationType>(payload: IViewHistoryPayload, item?: INavItem): IPromise<IViewHistoryItemResponse<T>> {
    const url = this.env.getUXCustomizationEndpoint(`/viewhistory`);

    return this.$http.post<IViewHistoryItemResponse<T>>(url, payload).then((response) => {
      if (item) {
        this.viewHistoryChangeSubject.next({
          type: item.uxcType,
          source: payload.source,
          item,
        });
      }
      return response.data;
    });
  }

  public update(updatedNavItem: INavItem): void {
    this.viewHistoryPatchSubject.next({
      type: updatedNavItem.uxcType,
      source: "navigation",
      item: updatedNavItem,
    });
  }
}
