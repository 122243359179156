import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IInsightSelectorData } from "@gtmhub/insights";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { AutoInsights, AutoInsightsDTO } from "@webapp/data-story/models/auto-insights-facade.models";
import { InsightFeedbackType, SuggestedInsights } from "@webapp/data-story/models/insights.models";
import { AutoInsightsApiService } from "@webapp/data-story/services/auto-insights-facade/auto-insights-api.service";
import { AutoInsightsState } from "@webapp/data-story/services/auto-insights-facade/auto-insights-state.service";

interface InsightFieldsChoice {
  selectedInsightId?: string;
  type?: InsightFeedbackType;
}

@Injectable()
export class AutoInsightsFacade extends BaseFacade<AutoInsights, AutoInsightsDTO, AutoInsightsState, AutoInsightsApiService> {
  constructor(state: AutoInsightsState, api: AutoInsightsApiService) {
    super(state, api);
  }

  public getInsightRecommendations$(title: string, { fromAccount = false }): Observable<SuggestedInsights> {
    const url = fromAccount ? this.apiService.getAccountRecommendationsEndpoint() : this.apiService.getRecommendationsEndpoint();

    return this.get$<SuggestedInsights>(null, {
      ...new RequestConfig(),
      queryParams: { kr: title },
      url: url,
    });
  }

  private sendInsightRecommendationFeedback$(predictionId: string, data: InsightFieldsChoice): Observable<void> {
    return this.put$<void>(null, data, {
      ...new RequestConfig(),
      url: this.apiService.getInsightRecommendationFeedbackEndpoint(predictionId),
    });
  }

  private getRecommendedInsightAnalyticsData(data: IInsightSelectorData): { marketplaceBody: InsightFieldsChoice; accountBody: InsightFieldsChoice } {
    const marketplaceBody: InsightFieldsChoice = {
        type: data.type,
      },
      accountBody: InsightFieldsChoice = {
        type: data.type,
      };

    /** selectedInsight is required and must be a valid ObjectID, otherwise validation kicks in */
    if (data.insightTemplate) {
      if (data.isInsightFromAccount) {
        accountBody.selectedInsightId = data.insightTemplate.id;
      } else {
        marketplaceBody.selectedInsightId = data.insightTemplate.id;
      }
    }

    return { marketplaceBody, accountBody };
  }

  public sendPredictionAnalyticsFeedback(data: IInsightSelectorData): void {
    const { marketplaceBody, accountBody } = this.getRecommendedInsightAnalyticsData(data);

    this.sendInsightRecommendationFeedback$(data.marketplacePredictionId, marketplaceBody);
    this.sendInsightRecommendationFeedback$(data.accountPredictionId, accountBody);
  }
}
