import { Store } from "redux";
import { Observable, of } from "rxjs";

type IsFetched = { isFetched: boolean };

type IsFetchedKeys<S extends object> = {
  [K in keyof S]: S[K] extends IsFetched ? K : never;
}[keyof S];

export class ReduxStoreObserver {
  constructor(private store: Store) {}

  public whenFetched$<S extends object>(...keys: IsFetchedKeys<S>[]): Observable<S> {
    const state: S = this.store.getState();
    const allKeysFetched = !keys.length || keys.every((key) => (state[key] as IsFetched).isFetched);
    if (allKeysFetched) {
      return of(state);
    }

    return new Observable<S>((observer) => {
      const unsubscribe = this.store.subscribe(() => {
        observer.add(unsubscribe);

        const state: S = this.store.getState();
        const allKeysFetched = keys.every((key) => (state[key] as IsFetched).isFetched);
        if (allKeysFetched) {
          observer.next(state);
          observer.complete();
        }
      });
    });
  }
}
