import languages from "wwwroot/json/languages.json";
import { Language } from "./models";

const defaultLanguage = "english";

export const removeLanguageFromLocalStorage = (): void => {
  localStorage.removeItem("language");
};

export const setLanguageInLocalStorage = (language: Language): void => {
  localStorage.setItem("language", JSON.stringify(language));
};

export const getLanguageFromLocalStorage = (): Language => {
  const language: Language = JSON.parse(localStorage.getItem("language"));
  return language || defaultLanguage;
};

export const getLanguageCode = (): string => {
  const language = getLanguageFromLocalStorage();
  const entry = languages.languageMap.find((x) => x.backend_label === language);
  if (!entry) {
    console.warn(`Unknown language found in localStorage: '${language}'. Defaulting to English`);
    return "en";
  }
  return entry.id;
};

export const isDefaultLanguage = (language: Language): boolean => language === defaultLanguage;
