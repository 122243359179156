import { UXCustomizationType } from "@gtmhub/uxcustomization/models";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";

export class ViewHistoryFactory {
  public buildNavigationViewHistoryQueryParams(types: UXCustomizationType[]): RequestPaging {
    const requestPaging: RequestPaging = {
      ...new RequestPaging(),
      filter: {
        $and: [
          {
            type: {
              $in: types,
            },
          },
          {
            source: {
              $in: ["navigation"],
            },
          },
        ],
      },
    };
    return requestPaging;
  }
}
