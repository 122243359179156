import { encode as base64encode } from "js-base64";
import { IField } from "@gtmhub/entities/models/models";
import { localize } from "@gtmhub/localization";
import {
  Insight,
  InsightDTO,
  InsightEntityRequiredField,
  InsightQueryCondition,
  InsightQueryLayout,
  InsightUsedEntity,
  SubmitInsightEmailContent,
  SupportedDataType,
  SupportedOperators,
} from "@webapp/data-story/models/insights.models";
import { Parameter } from "@webapp/data-story/models/parameters-facade.models";
import dayjs from "@webapp/shared/libs/dayjs";
import { IProfile } from "@webapp/user-profile/models/user-profile.models";

export const POWER_BI_CONNECTION_NAME = "powerbi";

export const SQL_CONDITION_STRING_ARRAY_SEPARATOR = ", ";

export const getInsightUsedFilters = (insight: Insight, parameters: Parameter[]): Parameter[] => {
  return parameters.filter((parameter) => insight.usedFilters.includes(parameter.key));
};

export const convertPeriodStringToDateArray = (periodString: string): Date[] => {
  const [startDate, endDate] = periodString.split("|");

  return [dayjs(startDate).toDate(), dayjs(endDate).toDate()];
};

export const convertDateRangeObjectToString = ([startDate, endDate]: Date[]): string => `${dayjs(startDate).toISOString()}|${dayjs(endDate).toISOString()}`;

const supportedOperatorTranslationKeys: { [key in SupportedOperators]?: string } = {
  contains: "contains",
  doesNotContain: "does_not_contain",
  overlaps: "overlaps",
  doesNotOverlap: "does_not_overlap",
  equals: "equals_to",
  notEqual: "is_not_equal_to",
  hasLengthOf: "has_length_of",
  isContainedBy: "is_contained_by",
  isNotContainedBy: "is_not_contained_by",
  shorter: "is_shorter_than",
  longer: "is_longer_than",
};

const getInsightOperatorTranslationKey = (operator: SupportedOperators, dataType: SupportedDataType): string => {
  if (operator === SupportedOperators.Greater) {
    return dataType === SupportedDataType.Timestamp ||
      dataType === SupportedDataType.Date ||
      dataType === SupportedDataType.DateTime ||
      dataType === SupportedDataType.UnixTime
      ? "is_after"
      : "is_greater_than";
  } else if (operator === SupportedOperators.Less) {
    return dataType === SupportedDataType.Timestamp ||
      dataType === SupportedDataType.Date ||
      dataType === SupportedDataType.DateTime ||
      dataType === SupportedDataType.UnixTime
      ? "is_before"
      : "is_less_than";
  }

  return supportedOperatorTranslationKeys[operator] || "equals";
};

export const localizeInsightOperator = (operator: SupportedOperators, dataType: SupportedDataType): string =>
  localize(getInsightOperatorTranslationKey(operator, dataType));

export const compareFieldsByName = (a: IField, b: IField): boolean => a?.name === b?.name;

// Insight can include functions, which are not copyable with structuredClone
export const cloneInsight = (insight: Insight): Insight => {
  if (!insight) {
    return null;
  }

  const result: Partial<Insight> = {};

  // Copy everything except queryLayout
  Object.keys(insight).forEach((key) => {
    if (key === "queryLayout") {
      return;
    }

    result[key] = structuredClone(insight[key]);
  });

  const queryLayout: Partial<InsightQueryLayout> = {};

  // Copy queryLayout, but use the same templateFunc in conditions
  Object.keys(insight.queryLayout ?? {}).forEach((key) => {
    if (key === "conditions") {
      const resultConditions: Partial<InsightQueryCondition> = {};

      const { conditions } = insight.queryLayout;

      Object.keys(conditions ?? {}).forEach((conditionKey) => {
        if (conditionKey === "templateFunc") {
          resultConditions[conditionKey] = conditions[conditionKey];
        } else {
          resultConditions[conditionKey] = structuredClone(conditions[conditionKey]);
        }
      });

      queryLayout.conditions = resultConditions as InsightQueryCondition;
    } else {
      queryLayout[key] = structuredClone(insight.queryLayout[key]);
    }
  });

  result.queryLayout = queryLayout as InsightQueryLayout;

  return result as Insight;
};

export const getInsightFieldsToUpdate = (newInsight: InsightDTO, oldInsight: Insight): Partial<InsightDTO> => {
  const insightFieldsToUpdate = setKeysToInsightFieldsForUpdate(newInsight, oldInsight);

  if (insightFieldsToUpdate.algorithm) {
    insightFieldsToUpdate.algorithm = base64encode(insightFieldsToUpdate.algorithm);
  }

  if (insightFieldsToUpdate.view) {
    insightFieldsToUpdate.view = base64encode(insightFieldsToUpdate.view);
  }

  return insightFieldsToUpdate;
};

const setKeysToInsightFieldsForUpdate = (newInsight: InsightDTO, oldInsight: Insight): Partial<InsightDTO> => {
  const insightFieldsToUpdate: Partial<InsightDTO> = {
    id: newInsight.id,
  };

  Object.keys(newInsight).forEach((key) => {
    if (typeof newInsight[key] !== "object") {
      if (newInsight[key] !== oldInsight[key]) {
        insightFieldsToUpdate[key] = newInsight[key];
      }
    } else {
      for (const subKey in newInsight[key]) {
        if (!oldInsight[key] || newInsight[key][subKey] !== oldInsight[key][subKey]) {
          insightFieldsToUpdate[key] = newInsight[key];
          break;
        }
      }
    }
  });

  return insightFieldsToUpdate;
};

export const generateSubmitInsightEmail = (insight: Insight, currentUserProfile: IProfile): SubmitInsightEmailContent => {
  const mailto = "marketplace@gtmhub.com";
  const subject = `Submit insight "${insight.title}" to Quantive marketplace`;

  const usedDataSources: string = generateUsedDataSourcesText(insight);

  const usedFilters: string = generateUsedFiltersText(insight.usedFilters);

  const body: string = generateEmailBody(insight, currentUserProfile, usedDataSources, usedFilters);

  return {
    body,
    mailto,
    subject,
  };
};

const generateUsedDataSourcesText = (insight: Insight): string => {
  if (insight.usedEntities) {
    return insight.usedEntities.reduce((usedSources: string, currentUsedEntity: InsightUsedEntity): string => {
      const requiredFields: string = generateRequiredFieldsText(currentUsedEntity.requiredFields);

      usedSources += `
          %0D%09* Entity name: ${currentUsedEntity.name}%0D
          %09* Data source blueprint name: ${currentUsedEntity.dataSourceBlueprintName}%0D
          %09* Remote entity name: ${currentUsedEntity.remoteEntityName}%0D
          %09* Required fields: ${requiredFields}%0D
        `;

      return usedSources;
    }, "");
  }

  return "-";
};

const generateRequiredFieldsText = (fields: InsightEntityRequiredField[]): string => {
  if (Array.isArray(fields) && fields.length > 0) {
    return fields.reduce((reqFields: string, currentReqField: InsightEntityRequiredField): string => {
      reqFields += `${currentReqField.name}; `;

      return reqFields;
    }, "");
  }

  return "-";
};

const generateUsedFiltersText = (filters: string[]): string => {
  if (Array.isArray(filters) && filters.length > 0) {
    return filters.reduce((filtersUsed: string, currentFilterName: string): string => {
      filtersUsed += `${currentFilterName}; `;

      return filtersUsed;
    }, "");
  }

  return "-";
};

const generateEmailBody = (insight: Insight, currentUserProfile: IProfile, usedDataSources: string, usedFilters: string): string => {
  return `
      Title: ${insight.title}%0D
      Description: ${insight.description}%0D%0D
      Algorithm: %0D%09${insight.algorithm.replace(/\n/gi, "%0D%09")}%0D%0D
      View: %0D%09${insight.view.replace(/\n/gi, "%0D%09")}%0D%0D
      Used data sources: ${usedDataSources}%0D
      Filters used: ${usedFilters}%0D%0D
      Formatting: editor: ${insight.formatting?.editor ? insight.formatting.editor : "-"}%0D%0D
      Created by: ${insight.createdBy ? insight.createdBy.name : "Unknown author"}%0D
      Submitted by: ${currentUserProfile.firstName} ${currentUserProfile.lastName}%0D
    `;
};
