import { A11yModule } from "@angular/cdk/a11y";
import { Directionality } from "@angular/cdk/bidi";
import { CdkConnectedOverlay, OverlayModule } from "@angular/cdk/overlay";
import { Platform } from "@angular/cdk/platform";
import { DOCUMENT, NgIf, NgStyle, NgTemplateOutlet } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Host,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  forwardRef,
} from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SimpleChangesOf, UiOutletModule, UiSizeLDSType, uiToNz } from "@quantive/ui-kit/core";
import { UiI18nModule, UiI18nService } from "@quantive/ui-kit/i18n";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { format, parseISO } from "date-fns";
import { NzResizeObserver } from "ng-zorro-antd/cdk/resize-observer";
import { NzConfigService, WithConfig } from "ng-zorro-antd/core/config";
import { NzFormNoStatusService, NzFormPatchModule, NzFormStatusService } from "ng-zorro-antd/core/form";
import { NzNoAnimationDirective, NzNoAnimationModule } from "ng-zorro-antd/core/no-animation";
import { NzOverlayModule } from "ng-zorro-antd/core/overlay";
import { NzDestroyService } from "ng-zorro-antd/core/services";
import { CandyDate } from "ng-zorro-antd/core/time";
import { FunctionProp, NzSafeAny } from "ng-zorro-antd/core/types";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzDatePickerComponent } from "ng-zorro-antd/date-picker";
import { RangePartType } from "ng-zorro-antd/date-picker/standard-types";
import { DateHelperService, NzDatePickerI18nInterface } from "ng-zorro-antd/i18n";
import { Observable, Subscription, take } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { ENTER, ESCAPE, SPACE } from "@webapp/shared/utils/keys";
import { UiCompatibleDate, UiDateMode, UiDisabledTimeFn, UiPresetRanges, UiSupportTimeOptions } from "@webapp/ui/date-picker/date-picker.models";
import { addAttributeValue } from "../utils/dom.utils";
import { UiDateRangePopupComponent } from "./components/date-range-popup/date-range-popup.component";
import { UiDatePickerService } from "./services/date-picker.service";

const POPUP_STYLE_PATCH = { position: "relative" }; // Aim to override antd's style to support overlay's position strategy (position:absolute will cause it not working because the overlay can't get the height/width of it's content)

@Component({
  selector: "ui-date-picker,ui-week-picker,ui-month-picker,ui-year-picker,ui-range-picker",
  exportAs: "uiDatePicker",
  templateUrl: "date-picker.component.html",
  styleUrls: ["./date-picker.component.less"],
  providers: [
    UiDatePickerService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => UiDatePickerComponent),
    },
    NzDestroyService,
  ],
  host: {
    "[class.ui-date-picker]": `!isRange`,
    "[class.ui-range-picker]": `isRange`,
  },
  standalone: true,
  imports: [
    FormsModule,
    NgTemplateOutlet,
    UiOutletModule,
    UiIconModule,
    NgStyle,
    NzFormPatchModule,
    UiDateRangePopupComponent,
    OverlayModule,
    NzOverlayModule,
    NzNoAnimationModule,
    NgIf,
    A11yModule,
    UiI18nModule,
  ],
})
export class UiDatePickerComponent extends NzDatePickerComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input()
  public date: Date | string;
  /** an optional input if we want our DP input to be described by an external element */
  @Input()
  public uiAriaDescribedBy = "";
  // --- Common API
  @Input("uiAllowClear")
  @InputBoolean()
  public nzAllowClear = false;
  @Input("uiAutoFocus")
  @InputBoolean()
  public nzAutoFocus = false;
  @Input("uiBorderless")
  @InputBoolean()
  public nzBorderless = false;
  @Input("uiDisabled")
  @InputBoolean()
  public nzDisabled = false;

  @Input()
  @InputBoolean()
  set readonly(value: boolean) {
    this.uiReadonly = value;
    this.nzInputReadOnly = value;
  }
  get readonly(): boolean {
    return this.uiReadonly;
  }
  private uiReadonly = false;
  @Input("uiInputReadOnly")
  @InputBoolean()
  public nzInputReadOnly = false;
  @Input("uiInline")
  @InputBoolean()
  public nzInline = false;
  @Input("uiOpen")
  @InputBoolean()
  public nzOpen?: boolean;
  @Input() @InputBoolean() public treatUtcAsLocal = false;
  @Output("uiDateChange") public readonly dateChange = new EventEmitter<string>();
  @Input("uiDisabledDate")
  public nzDisabledDate?: (d: Date) => boolean;
  @Input("uiLocale")
  public nzLocale!: NzDatePickerI18nInterface;
  @Input("uiPlaceHolder")
  public nzPlaceHolder: string | string[] = "";
  @Input("uiPopupStyle")
  public nzPopupStyle: object = POPUP_STYLE_PATCH;
  @Input("uiDropdownClassName")
  public nzDropdownClassName = "ui-date-picker-dropdown";
  @Input("uiSize")
  public nzSize: UiSizeLDSType = "large";
  @Input("uiFormat")
  public nzFormat!: string;
  @Input("uiDateRender")
  public nzDateRender?: TemplateRef<NzSafeAny> | string | FunctionProp<TemplateRef<Date> | string>;
  @Input("uiDisabledTime")
  public nzDisabledTime?: UiDisabledTimeFn;
  @Input("uiRenderExtraFooter")
  public nzRenderExtraFooter?: TemplateRef<NzSafeAny> | string | FunctionProp<TemplateRef<NzSafeAny> | string>;
  @Input("uiShowToday")
  @InputBoolean()
  public nzShowToday = false;
  @Input("uiMode")
  public nzMode: UiDateMode = "date";
  @Input("uiShowNow")
  @InputBoolean()
  public nzShowNow = true;
  @Input("uiRanges")
  public nzRanges?: UiPresetRanges;
  @Input("uiDefaultPickerValue")
  public nzDefaultPickerValue: UiCompatibleDate | null = null;
  @Input("uiBackdrop")
  @WithConfig()
  public nzBackdrop = false;
  @Input("uiId")
  public nzId: string | null = null;
  @Input()
  @InputBoolean()
  public hideSuffix = false;
  @Input() get uiShowTime(): UiSupportTimeOptions | boolean {
    return this.nzShowTime;
  }

  set uiShowTime(value: UiSupportTimeOptions | boolean) {
    this.nzShowTime = value;
  }

  @Input() public a11yLabelledby: string;
  @Input() public a11yRequired = false;

  @Output("uiOnPanelChange")
  public readonly nzOnPanelChange = new EventEmitter<UiDateMode | UiDateMode[] | string | string[]>();
  @Output("uiOnCalendarChange")
  public readonly nzOnCalendarChange = new EventEmitter<Array<Date | null>>();
  @Output("uiOnOk")
  public readonly nzOnOk = new EventEmitter<UiCompatibleDate | null>();
  @Output("uiOnOpenChange")
  public readonly nzOnOpenChange = new EventEmitter<boolean>();

  // ------------------------------------------------------------------------
  // Input API Start
  // ------------------------------------------------------------------------
  /** a reference to the connected overlay when it is opened and existing (no overlay in inline mode) */
  @ViewChild(CdkConnectedOverlay, { static: false })
  public cdkConnectedOverlay?: CdkConnectedOverlay;
  /** a reference to date-range-popup component that is always available if the popup is opened  */
  @ViewChild(UiDateRangePopupComponent, { static: false })
  public panel!: UiDateRangePopupComponent;
  @ViewChild("pickerInput", { static: false })
  public pickerInput?: ElementRef<HTMLInputElement>;
  @ViewChildren("rangePickerInput")
  public rangePickerInputs?: QueryList<ElementRef<HTMLInputElement>>;

  // ------------------------------------------------------------------------
  // Input API End
  // ------------------------------------------------------------------------

  /** unique id used in the accessibility attributes */
  public pickerId: string = uuidv4();
  private backdropClickSub: Subscription;
  private dateValueSubscription: Subscription;

  constructor(
    public uiConfigService: NzConfigService,
    public datePickerService: UiDatePickerService,
    protected i18n: UiI18nService,
    protected cdr: ChangeDetectorRef,
    renderer: Renderer2,
    ngZone: NgZone,
    elementRef: ElementRef,
    dateHelper: DateHelperService,
    uiResizeObserver: NzResizeObserver,
    platform: Platform,
    destroy$: NzDestroyService,
    @Inject(DOCUMENT) doc: NzSafeAny,
    @Optional() directionality: Directionality,
    @Host() @Optional() noAnimation?: NzNoAnimationDirective,
    @Optional() nzFormStatusService?: NzFormStatusService,
    @Optional() nzFormNoStatusService?: NzFormNoStatusService
  ) {
    super(
      uiConfigService,
      datePickerService,
      i18n,
      cdr,
      renderer,
      ngZone,
      elementRef,
      dateHelper,
      uiResizeObserver,
      platform,
      destroy$,
      doc,
      directionality,
      noAnimation,
      nzFormStatusService,
      nzFormNoStatusService
    );
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.updateDate();

    this.dateValueSubscription = this.datePickerService.emitValue$.subscribe(() => {
      if (Array.isArray(this.datePickerService.value)) {
        this.dateChange.emit(JSON.stringify([this.datePickerService.value[0]?.nativeDate, this.datePickerService.value[1]?.nativeDate]));
      } else {
        this.dateChange.emit(this.formatDate(this.datePickerService.value?.nativeDate));
      }
    });
  }

  public ngOnChanges(changes: SimpleChangesOf<UiDatePickerComponent>): void {
    super.ngOnChanges(uiToNz(changes));

    if (changes.date && !changes.date.isFirstChange()) {
      this.updateDate();
    }
  }

  public ngAfterViewInit(): void {
    super.ngAfterViewInit();

    addAttributeValue({
      element: this.pickerInput?.nativeElement,
      attribute: "aria-describedby",
      value: this.uiAriaDescribedBy || this.pickerId + "-description",
    });
  }

  public ngOnDestroy(): void {
    this.dateValueSubscription?.unsubscribe();
  }

  public onKeyUpArrowDown(): void {
    if (this.nzDisabled || this.readonly || !this.isKeyboardAccessible()) {
      return;
    }

    this.openAndSubscribeToBackdrop();

    this.setCellFocus();
  }

  public onClickInputBox(event: MouseEvent): void {
    if (this.readonly || this.nzDisabled) {
      return;
    }

    super.onClickInputBox(event);

    if (!this.nzDisabled && !this.readonly && this.isKeyboardAccessible()) {
      this.openAndSubscribeToBackdrop();
    }
  }

  // eslint-disable-next-line @foxglove/no-boolean-parameters, @typescript-eslint/no-inferrable-types
  public onInputChange(value: string, isEnter: boolean = false): void {
    super.onInputChange(value, isEnter);

    if (!this.isKeyboardAccessible()) {
      return;
    }

    if (!this.realOpenState) {
      this.openAndSubscribeToBackdrop();

      return;
    }

    if (isEnter && this.realOpenState && !!this.checkIfSelectedDateIsValid(value)) {
      this.closeAndRemoveBackdropSub();
    }
  }

  public onKeyupEnter($event: KeyboardEvent): void {
    if (this.isKeyboardAccessible() && !($event.target as HTMLInputElement).value) {
      return;
    }

    super.onKeyupEnter($event);
  }

  public onFocusout(event: FocusEvent): void {
    // if the focus is moved to the popup -> prevent the original implementation that closes the popup
    if (this.isKeyboardAccessible() && this.cdkConnectedOverlay?.overlayRef?.overlayElement?.contains(<Node>event.relatedTarget)) {
      return;
    }

    if (this.isKeyboardAccessible() && this.realOpenState) {
      if (event.relatedTarget) {
        // when Tab is pressed
        this.closeAndRemoveBackdropSub();
      } else {
        // when the user clicks outside (backdrop actually)
        // the backdrop sub will kick in and try to focus the element that is below the backdrop at the same coordinates of where the pointer was
        return;
      }
    }

    super.onFocusout(event);
  }

  // ActiveInput is used to determine which input is currently focused(range picker).
  // Used to set focus on correct element when the popup is opened with arrow down.
  public onFocusIn(activeInput: RangePartType): void {
    this.datePickerService.activeInput = activeInput;
  }

  public onOverlayKeydown(event: KeyboardEvent): void {
    if (!this.isKeyboardAccessible()) {
      return;
    }

    // Date Picker Dialog Esc => Closes the dialog and moves focus to the combobox
    if (event.key === ESCAPE) {
      this.closeAndRemoveBackdropSub();
      super.focus();
      return;
    }

    if (event.key !== SPACE && event.key !== ENTER) {
      return;
    }

    // when a date in the calendar is selected by pressing Space
    if (event.key === SPACE && this.panel.panelMode === "date" && this.isTableCellButton(event.target)) {
      this.emitValueOnceObservable().subscribe(() => (<HTMLTableCellElement>event.target).focus());
      return;
    }

    // when a date in the calendar is selected by pressing Enter
    if (event.key === ENTER && this.panel.panelMode === "date" && this.isTableCellButton(event.target)) {
      this.emitValueOnceObservable().subscribe(() => this.closeAndRemoveBackdropSub());
      return;
    }

    // when a month, year or decade is selected by pressing Space or Enter
    if (this.panel.panelMode !== "date" && this.isTableCellButton(event.target)) {
      this.panelModeChangeOnceObservable().subscribe(() => this.focusFirstMonthOrYearHeaderButtonInsidePopup());
      return;
    }

    // when a month or year button is selected by pressing Space or Enter
    if (this.isMonthOrYearHeaderButtonInsidePopup(<HTMLButtonElement>event.target)) {
      this.panelModeChangeOnceObservable().subscribe(() => this.focusFirstMonthOrYearHeaderButtonInsidePopup());
    }
  }

  public onPopupMouseDown(event: MouseEvent): void {
    if (!this.isKeyboardAccessible()) {
      return;
    }

    if (this.panel.panelMode === "date" && this.isTableCellOrItsInnerEl(event.target)) {
      this.emitValueOnceObservable().subscribe(() => this.closeAndRemoveBackdropSub());
    }
  }

  public onPopupKeyUpPageUp(event: KeyboardEvent): void {
    this.panel.elementRef.nativeElement.querySelector<HTMLButtonElement>("button.ant-btn.ant-picker-header-prev-btn")?.click();
    this.focusHeaderMonthOrYearButtonIfFocusIsLost(event);
  }

  public onPopupKeyUpShiftPageUp(event: KeyboardEvent): void {
    this.panel.elementRef.nativeElement.querySelector<HTMLButtonElement>("button.ant-btn.ant-picker-header-super-prev-btn")?.click();
    this.focusHeaderMonthOrYearButtonIfFocusIsLost(event);
  }

  public onPopupKeyUpPageDown(event: KeyboardEvent): void {
    this.panel.elementRef.nativeElement.querySelector<HTMLButtonElement>("button.ant-btn.ant-picker-header-next-btn")?.click();
    this.focusHeaderMonthOrYearButtonIfFocusIsLost(event);
  }

  public onPopupKeyUpShiftPageDown(event: KeyboardEvent): void {
    this.panel.elementRef.nativeElement.querySelector<HTMLButtonElement>("button.ant-btn.ant-picker-header-super-next-btn")?.click();
    this.focusHeaderMonthOrYearButtonIfFocusIsLost(event);
  }

  public setModeAndFormat(): void {
    const dateLocale = this.i18n.getDateLocale();
    const inputFormats: { [key in UiDateMode]?: string } = {
      year: "yyyy",
      month: "yyyy-MM",
      week: "YYYY-ww",
      date: this.nzShowTime ? "yyyy-MM-dd HH:mm:ss" : (dateLocale?.formatLong?.date({ width: "short" }) ?? "yyyy-MM-dd"),
    };

    if (!this.nzMode) {
      this.nzMode = "date";
    }

    this.panelMode = this.isRange ? [this.nzMode, this.nzMode] : this.nzMode;

    // Default format when it's empty
    const isCustomFormat: boolean = this["isCustomFormat"];
    if (!isCustomFormat) {
      this.nzFormat = inputFormats[this.nzMode]!;
    }

    this.inputSize = Math.max(10, this.nzFormat.length) + 2;
    this.updateInputValue();
  }

  private focusHeaderMonthOrYearButtonIfFocusIsLost(event: KeyboardEvent): void {
    this.cdr.detectChanges();
    if (!this.panel.elementRef.nativeElement.querySelector("[tabindex='0']:focus")) {
      const isMonthButton = (<HTMLElement>event.target).classList.contains("ant-picker-header-month-btn");
      const headerDateButtons = this.panel.elementRef.nativeElement.querySelectorAll<HTMLButtonElement>(".ant-picker-header-view button");
      const buttonToBeFocused = isMonthButton ? headerDateButtons[headerDateButtons.length - 1] : headerDateButtons[0];
      buttonToBeFocused?.focus();
    }
  }

  private panelModeChangeOnceObservable(): Observable<UiDateMode | UiDateMode[]> {
    return this.panel.panelModeChange.asObservable().pipe(take(1));
  }

  private emitValueOnceObservable(): Observable<void> {
    return this.datePickerService.emitValue$.asObservable().pipe(take(1));
  }

  private focusFirstMonthOrYearHeaderButtonInsidePopup(): void {
    this.cdr.detectChanges();
    const popupEl = this.panel.elementRef.nativeElement;
    popupEl.querySelector<HTMLButtonElement>(".ant-picker-header-view button")?.focus();
  }

  private isMonthOrYearHeaderButtonInsidePopup(eventTarget: EventTarget): boolean {
    const popupEl = this.panel.elementRef.nativeElement;
    return eventTarget === popupEl.querySelector("button.ant-picker-header-year-btn") || eventTarget === popupEl.querySelector("button.ant-picker-header-month-btn");
  }

  private isTableCellButton(eventTarget: EventTarget): boolean {
    return (<HTMLElement>eventTarget).classList.contains("ant-picker-cell");
  }

  private isTableCellOrItsInnerEl(eventTarget: EventTarget): boolean {
    return this.isTableCellButton(eventTarget) || (<HTMLElement>eventTarget).classList.contains("ant-picker-cell-inner");
  }

  private checkIfSelectedDateIsValid(value: string): CandyDate | null {
    // the checkValidDate method is private in ng-zorro source code but is needed here(to read/invoke it only) because of KB interactions
    // https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/date-picker/date-picker.component.ts#L558
    return this["checkValidDate"](value);
  }

  private openAndSubscribeToBackdrop(): void {
    if (this.nzOpen) {
      return;
    }

    this.nzOpen = true;
    this.nzOnOpenChange.emit(this.nzOpen);
    this.cdr.detectChanges();
    this.backdropClickSub = this.cdkConnectedOverlay.overlayRef
      .backdropClick()
      .pipe(take(1))
      .subscribe((event: MouseEvent) => {
        this.nzOpen = false;
        this.nzOnOpenChange.emit(this.nzOpen);
        this.cdr.detectChanges();
        const clickTargetEl = <HTMLElement>this.document.elementFromPoint(event.x, event.y);

        if (clickTargetEl) {
          clickTargetEl.focus();
        } else {
          super.focus();
        }
      });
  }

  private closeAndRemoveBackdropSub(): void {
    this.nzOpen = false;

    if (this.backdropClickSub) {
      this.backdropClickSub.unsubscribe();
      this.backdropClickSub = null;
    }
  }

  private isKeyboardAccessible(): boolean {
    return this.nzBackdrop && super.isOpenHandledByUser();
  }

  private formatDate(date?: Date): string | null {
    if (date) {
      return this.treatUtcAsLocal ? format(date, "yyyy-MM-dd") + "T00:00:00.000Z" : date.toISOString();
    }
    return null;
  }

  private setCellFocus(): void {
    const popupEl = this.panel.elementRef.nativeElement;

    let selector = ".ant-picker-cell-selected[tabindex='0']";

    if (this.isRange) {
      if (this.datePickerService.activeInput === "left") {
        selector = ".ant-picker-cell-range-start[tabindex='0']";
      } else {
        selector = ".ant-picker-cell-range-end[tabindex='0']";
      }
    }

    const elToFocus =
      popupEl.querySelector<HTMLTableCellElement>(selector) ||
      popupEl.querySelector<HTMLTableCellElement>(".ant-picker-cell-today[tabindex='0']") ||
      popupEl.querySelector<HTMLTableCellElement>(".ant-picker-cell[tabindex='0']");

    elToFocus?.focus();
  }

  private updateDate(): void {
    let date: Date;
    if (this.date) {
      if (typeof this.date === "string") {
        date = this.treatUtcAsLocal ? parseISO(this.date.substring(0, 10)) : parseISO(this.date);
      } else {
        date = this.treatUtcAsLocal ? parseISO(this.date.toISOString().substring(0, 10)) : this.date;
      }
    }
    this.writeValue(date);
  }

  public get showClear(): boolean {
    return !this.readonly && super.showClear;
  }

  public getPlaceholder(partType?: RangePartType): string {
    if (this.nzDisabled || this.readonly) {
      return "";
    }

    return super.getPlaceholder(partType);
  }
}
