import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { UiGridModule } from "@webapp/ui/grid/grid.module";
import { OnboardingComponent } from "./onboarding.component";

@NgModule({
  imports: [CommonModule, UiButtonModule, UiGridModule, UiIconModule, LocalizationModule],
  declarations: [OnboardingComponent],
  exports: [OnboardingComponent],
})
export class OnboardingModule {}
