<ui-tag
  *ngIf="displayValues.confidenceType !== 'emoji'"
  [uiColor]="displayValues.color"
  [attr.data-test-id]="'confidence-label'"
  [uiTooltipTitle]="isPreview ? displayValues.confidenceLevelTooltip : ''"
  [uiText]="displayValues.confidenceName"
  [disableTooltip]="isPreview"
  ui-tooltip
  [isTabbable]="isTabbable" />

<div *ngIf="displayValues.confidenceType === 'emoji'" [class]="displayValues.emojiClass" [uiTooltipTitle]="displayValues.confidenceLevelTooltip" ui-tooltip></div>
