import { Directionality } from "@angular/cdk/bidi";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, Optional, Renderer2 } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonComponent, UiButtonModule } from "@quantive/ui-kit/button";
import { NzConfigService } from "ng-zorro-antd/core/config";
import { fromEvent } from "rxjs";

const ACTION_KEYS = [" ", "Enter"];

/**
 * This should only be used in Angular.js
 */
@UntilDestroy()
@Component({
  selector: "ui-button",
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<ng-content></ng-content>`,
  preserveWhitespaces: false,
  standalone: true,
  imports: [UiButtonModule],
  host: {
    role: "button",
  },
})
export class ButtonComponent extends UiButtonComponent implements OnInit {
  constructor(
    private thisNgZone: NgZone,
    elementRef: ElementRef<HTMLButtonElement>,
    cdr: ChangeDetectorRef,
    thisRenderer: Renderer2,
    nzConfigService: NzConfigService,
    @Optional() directionality: Directionality
  ) {
    super(thisNgZone, elementRef, cdr, thisRenderer, nzConfigService, directionality);
  }

  public get iconOnly(): boolean {
    const listOfNode = Array.from((this.thisElementRef?.nativeElement as HTMLButtonElement)?.childNodes || []);
    const noText = listOfNode.every((node) => node.nodeName !== "#text");
    // ignore icon and comment
    const noSpan =
      listOfNode.filter((node) => {
        return !(
          node.nodeName === "#comment" ||
          !!(node as HTMLElement)?.attributes?.getNamedItem("ui-icon") ||
          !!(node as HTMLElement)?.attributes?.getNamedItem("gh-icon")
        );
      }).length == 0;
    return !!this.nzIconDirectiveElement && noSpan && noText;
  }

  public ngOnInit(): void {
    super.ngOnInit();

    const { nativeElement } = this.thisElementRef;

    this.thisNgZone.runOutsideAngular(() => {
      fromEvent<MouseEvent>(nativeElement, "click", { capture: true })
        .pipe(untilDestroyed(this))
        .subscribe((event) => {
          if (this.disabled) {
            event.preventDefault();
            event.stopImmediatePropagation();
          }
        });

      fromEvent<KeyboardEvent>(nativeElement, "keypress", { capture: true })
        .pipe(untilDestroyed(this))
        .subscribe((event) => {
          if (ACTION_KEYS.includes(event.key)) {
            event.preventDefault();
            this.thisNgZone.run(() => nativeElement.click());
          }
        });
    });
  }
}
