import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { Whiteboard, WhiteboardDto } from "@webapp/whiteboards/models/whiteboard.models";
import { WhiteboardsState } from "@webapp/whiteboards/services/whiteboards-state.service";

@Injectable({
  providedIn: "root",
})
export class WhiteboardsApiService extends BaseApiService<Whiteboard, WhiteboardDto, WhiteboardsState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: WhiteboardsState) {
    super("whiteboards", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public postWhiteboardEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}/`;
  }

  public whiteboardTemplateEndpoint(whiteboardId: string): string {
    return `${this.getBasePath(HttpActions.post)}/${whiteboardId}/clone`;
  }
}
