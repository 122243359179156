import { Directive, ElementRef, Inject, OnInit, Renderer2 } from "@angular/core";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";

@Directive({
  selector: "img[src]",
})
export class CdnImgDirective implements OnInit {
  private cdnUrl: string;
  private cdnEnabled: boolean;
  public isQuantivestaticCdnEnabled: boolean;

  constructor(
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    this.cdnEnabled = this.appConfig.cdnStorage.enabled;
    this.cdnUrl = this.appConfig.cdnStorage.url;
    this.modifyImageSrcAttribute();
  }

  private modifyImageSrcAttribute(): void {
    if (this.cdnUrl && this.cdnEnabled) {
      const img = this.el.nativeElement;
      const src = img.getAttribute("src").trim();

      const newSrc = this.calculateNewSrcUsingCdn(src);

      if (src !== newSrc) {
        this.renderer.setAttribute(img, "src", newSrc);
      }
    }
  }

  private calculateNewSrcUsingCdn(src: string): string {
    let newSrc = src;

    // Replace image src attribute with the CDN URL, e.g.
    // /dist/img/hello.jpg -> https://cdn.quantivestatic.com/dist/img/hello.png
    // Ignore base64-encoded contents, e.g., `data:image/jpeg;base64,/9j/4AAQ`,
    // data blobs, e.g., `blob:http(s)://domain.com/path/image.jpg`
    // and absolute URLs in the src
    if (src && !src.startsWith("data:") && !src.startsWith("blob:") && !src.startsWith("//") && !src.startsWith("http")) {
      if (!newSrc.startsWith("/")) {
        newSrc = "/" + newSrc;
      }
      newSrc = this.cdnUrl + newSrc;
    }

    return newSrc;
  }
}
