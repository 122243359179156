import { module } from "angular";
import { HowToStartModalOpenerService } from "@gtmhub/shared/services/how-to-start-modal-opener.service";
import { OnboardingService } from "./onboarding.service";

const mod = module("onboarding-core", []);

mod.service("OnboardingService", OnboardingService);
mod.service("HowToStartModalOpenerService", HowToStartModalOpenerService);

export default mod.name;
