export enum FocusTarget {
  First = 0,
  Next = 1,
  Previous = 2,
}

export const getNextFocusableItemIndex = (currentIndex: number, focusableItemsMap: boolean[]): number => {
  const searchStart = currentIndex + 1;
  const nextFocusableIndex = focusableItemsMap.indexOf(true, searchStart);
  if (nextFocusableIndex !== -1) {
    return nextFocusableIndex;
  }

  return focusableItemsMap.indexOf(true);
};

export const getPreviousFocusableItemIndex = (currentIndex: number, focusableItemsMap: boolean[]): number => {
  const searchStart = currentIndex - 1;
  const previousFocusableIndex = focusableItemsMap.lastIndexOf(true, searchStart);
  if (previousFocusableIndex !== -1) {
    return previousFocusableIndex;
  }

  return focusableItemsMap.lastIndexOf(true);
};

export const getFocusTargetIndex = (currentIndex: number, focusTarget: FocusTarget, itemsFocusAvailabilityMap: boolean[]): number => {
  if (!Array.isArray(itemsFocusAvailabilityMap) || itemsFocusAvailabilityMap.length === 0) {
    return -1;
  }

  switch (focusTarget) {
    case FocusTarget.First:
      return itemsFocusAvailabilityMap.indexOf(true);
    case FocusTarget.Next:
      return getNextFocusableItemIndex(currentIndex, itemsFocusAvailabilityMap);
    case FocusTarget.Previous:
      return getPreviousFocusableItemIndex(currentIndex, itemsFocusAvailabilityMap);
  }
};
