import { ChangeDetectionStrategy, Component, Input, OnChanges } from "@angular/core";
import { SuggestionDrawerIndicatorStatePropagator } from "@webapp/platform-intelligence/expandable-suggestions/suggestion-drawer/shared/utils/state-propagator";
import { IQuantivePlusMetric } from "@webapp/platform-intelligence/shared/models";
import { PiTrackingFlowNameEnum } from "@webapp/platform-intelligence/shared/utils/pi-tracking";
import { SuggestionDrawerSectionType, SuggestionDrawerSuggestionGroupType } from "../shared/models/suggestion-drawer.models";

// TODO: Should be deleted with this ticket GVS-43146
@Component({
  selector: "pi-create-kr-suggestion-drawer-legacy",
  templateUrl: "./create-kr-suggestion-drawer-legacy.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateKrSuggestionDrawerLegacyComponent implements OnChanges {
  @Input() public isOpen = false;
  @Input() public titleSection: SuggestionDrawerSectionType<{ title: { isTitleAvailable: boolean } } & SuggestionDrawerSuggestionGroupType>;
  @Input() public descriptionSection: SuggestionDrawerSectionType<{ description: { isDescriptionAvailable: boolean } } & SuggestionDrawerSuggestionGroupType>;
  @Input() public statePropagator: SuggestionDrawerIndicatorStatePropagator;
  @Input() public visibilityChange: (isVisible: boolean) => void;

  @Input() public flowId: string;
  @Input() public flowName: PiTrackingFlowNameEnum;

  public static suggestedMetricPropsWithoutSuggestionCardEquivalentInUI: (keyof IQuantivePlusMetric)[] = ["initialValue", "target", "targetOperator", "format"];

  public ngOnChanges(): void {
    if (this.statePropagator && this.titleSection && this.descriptionSection) {
      const isLoading = this.titleSection.isLoading || this.descriptionSection.isLoading;
      const suggestionStateLevel = undefined;
      const isSuggestionAvailable = this.titleSection.title.isTitleAvailable || this.descriptionSection.description.isDescriptionAvailable;
      // When creating KR, we rely entirely on the information coming from the parent Objective to generate suggestions.
      const hasRequiredDependencies = true;

      this.statePropagator.emitStateChange({ isLoading, suggestionStateLevel, hasRequiredDependencies, isSuggestionAvailable });
    }
  }
}
