import { FocusTrap, FocusTrapFactory } from "@angular/cdk/a11y";
import { Directionality } from "@angular/cdk/bidi";
import { Overlay, OverlayKeyboardDispatcher, OverlayRef } from "@angular/cdk/overlay";
import { CdkPortalOutlet, ComponentPortal, PortalModule, TemplatePortal } from "@angular/cdk/portal";
import { NgIf, NgStyle, NgTemplateOutlet } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  Injector,
  Input,
  Optional,
  Output,
  Renderer2,
  StaticProvider,
  TemplateRef,
  Type,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { UiAccessibilityModule } from "@quantive/ui-kit/accessibility";
import { uiToNz } from "@quantive/ui-kit/core";
import { UiI18nModule } from "@quantive/ui-kit/i18n";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzConfigService, WithConfig } from "ng-zorro-antd/core/config";
import { NzNoAnimationModule } from "ng-zorro-antd/core/no-animation";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { NgStyleInterface, NzSafeAny } from "ng-zorro-antd/core/types";
import { InputBoolean, toCssPixel } from "ng-zorro-antd/core/util";
import { NzDrawerComponent, NzDrawerRef } from "ng-zorro-antd/drawer";
import { DOCUMENT } from "@webapp/core/factories/document.factory";
import { UiLoadingIndicatorComponent } from "../loading-indicator/loading-indicator.component";
import { UiDrawerRef, UiDrawerRefProps } from "./abstracts/drawer-ref";
import { UiDrawerContentDirective } from "./directives/drawer-content.directive";
import { DRAWER_DEFAULT_SIZE, DRAWER_LARGE_SIZE, UiDrawerPlacement, UiDrawerSize } from "./drawer.models";

@Component({
  selector: "ui-drawer",
  exportAs: "uiDrawer",
  templateUrl: "./drawer.component.html",
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NzNoAnimationModule,
    NgStyle,
    NzOutletModule,
    UiAccessibilityModule,
    UiIconModule,
    UiI18nModule,
    PortalModule,
    NgTemplateOutlet,
    UiLoadingIndicatorComponent,
  ],
})
export class UiDrawerComponent<T = NzSafeAny, R = NzSafeAny, D = NzSafeAny> extends NzDrawerComponent implements UiDrawerRef<T, R>, AfterViewInit {
  @Input("uiContent") public nzContent!: TemplateRef<{ $implicit: D; drawerRef: UiDrawerRef<R> }> | Type<T>;
  @Input("uiCloseIcon") public nzCloseIcon: string | TemplateRef<void> = "close-big";
  @Input("uiClosable") @InputBoolean() public nzClosable = true;
  @Input("uiMaskClosable") @WithConfig() @InputBoolean() public nzMaskClosable = true;
  @Input("uiMask") @WithConfig() @InputBoolean() public nzMask = true;
  @Input("uiCloseOnNavigation") @WithConfig() @InputBoolean() public nzCloseOnNavigation = true;
  @Input("uiNoAnimation") @InputBoolean() public nzNoAnimation = false;
  @Input("uiKeyboard") @InputBoolean() public nzKeyboard = true;
  @Input("uiTitle") public nzTitle?: string | TemplateRef<unknown>;
  @Input("uiExtra") public nzExtra?: string | TemplateRef<unknown>;
  @Input("uiFooter") public nzFooter?: string | TemplateRef<unknown>;
  @Input("uiPlacement") public nzPlacement: UiDrawerPlacement = "right";
  @Input("uiSize") public nzSize: UiDrawerSize = "default";
  @Input("uiMaskStyle") public nzMaskStyle: NgStyleInterface = {};
  @Input("uiBodyStyle") public nzBodyStyle: NgStyleInterface = {};
  @Input("uiWrapClassName") public nzWrapClassName?: string;
  @Input("uiWidth") public nzWidth?: number | string;
  @Input("uiHeight") public nzHeight?: number | string;
  @Input("uiZIndex") public nzZIndex = 1000;
  @Input("uiOffsetX") public nzOffsetX = 0;
  @Input("uiOffsetY") public nzOffsetY = 0;

  @Input("uiVisible")
  set uiVisible(value: boolean) {
    super.nzVisible = value;
  }

  get uiVisible(): boolean {
    return this.isOpen;
  }

  @Output("uiOnViewInit") public readonly nzOnViewInit = new EventEmitter<void>();
  @Output("uiOnClose") public readonly nzOnClose = new EventEmitter<MouseEvent>();
  @Output("uiVisibleChange") public readonly nzVisibleChange = new EventEmitter<boolean>();

  @ViewChild("drawerTemplate", { static: true }) public drawerTemplate!: TemplateRef<void>;
  @ViewChild(CdkPortalOutlet, { static: false }) public portalOutlet?: CdkPortalOutlet;
  @ContentChild(UiDrawerContentDirective, { static: true, read: TemplateRef })
  public contentFromContentChild?: TemplateRef<NzSafeAny>;

  public previouslyFocusedElement?: HTMLElement;
  public placementChanging = false;
  public placementChangeTimeoutId: ReturnType<typeof setTimeout>;
  public nzContentParams?: D; // only service
  public overlayRef?: OverlayRef | null;
  public portal?: TemplatePortal;
  public focusTrap?: FocusTrap;
  public isOpen = false;
  public inAnimation = false;
  public templateContext: { $implicit: D | undefined; drawerRef: NzDrawerRef<R> } = {
    $implicit: undefined,
    drawerRef: this as unknown as NzDrawerRef<R>,
  };

  // Custom
  @Input("uiSidePanel") public nzSidePanel?: string | TemplateRef<unknown>;
  public nzProviders?: StaticProvider[]; // only service
  public nzLoadingIndicator?: boolean;
  public nzCloseButtonClickedCallback?: () => void;

  private uiComponentInstance: T | null = null;

  constructor(
    cdr: ChangeDetectorRef,
    @Optional() @Inject(DOCUMENT) document: NzSafeAny,
    nzConfigService: NzConfigService,
    renderer: Renderer2,
    overlay: Overlay,
    private thisInjector: Injector,
    focusTrapFactory: FocusTrapFactory,
    viewContainerRef: ViewContainerRef,
    overlayKeyboardDispatcher: OverlayKeyboardDispatcher,
    @Optional() directionality: Directionality
  ) {
    super(cdr, document, nzConfigService, renderer, overlay, thisInjector, cdr, focusTrapFactory, viewContainerRef, overlayKeyboardDispatcher, directionality);
  }

  get width(): string | null {
    if (this.isLeftOrRight) {
      const defaultWidth = this.nzSize === "large" ? DRAWER_LARGE_SIZE : DRAWER_DEFAULT_SIZE;
      return this.nzWidth === undefined ? toCssPixel(defaultWidth) : toCssPixel(this.nzWidth);
    }
    return null;
  }

  get height(): string | null {
    if (!this.isLeftOrRight) {
      const defaultHeight = this.nzSize === "large" ? DRAWER_LARGE_SIZE : DRAWER_DEFAULT_SIZE;
      return this.nzHeight === undefined ? toCssPixel(defaultHeight) : toCssPixel(this.nzHeight);
    }
    return null;
  }

  public ngAfterViewInit(): void {
    // do not invoke the parent ngAfterViewInit
    this.customAttachBodyContent();
    if (this.nzOnViewInit.observers.length) {
      setTimeout(() => {
        this.nzOnViewInit.emit();
      });
    }
  }

  public close(result?: R): void {
    this.uiComponentInstance = null;
    super.close(result);
  }

  public getContentComponent(): T | null {
    return this.uiComponentInstance;
  }

  private customAttachBodyContent(): void {
    this.bodyPortalOutlet.dispose();

    if (this.nzContent instanceof Type) {
      const childInjector = Injector.create({
        parent: this.thisInjector,
        providers: [{ provide: UiDrawerRef, useValue: this }, ...(this.nzProviders || [])],
      });
      const componentPortal = new ComponentPortal<T>(this.nzContent, null, childInjector);
      const componentRef = this.bodyPortalOutlet.attachComponentPortal(componentPortal);
      this.uiComponentInstance = componentRef.instance;
      Object.assign(componentRef.instance, this.nzContentParams);
      componentRef.changeDetectorRef.detectChanges();
    }
  }

  public closeClick(): void {
    if (this.nzCloseButtonClickedCallback) {
      this.nzCloseButtonClickedCallback();
    }

    super.closeClick();
  }

  public setProperty<P extends keyof UiDrawerRefProps, V extends UiDrawerRef[P]>(prop: P, value: V): void {
    // This creates a new micro-task and prevents the following error:
    // ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked
    Promise.resolve(null).then(() => {
      this[uiToNz(prop)] = value;
    });
  }
}
