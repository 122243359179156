import { IPromise, IQService } from "angular";
import { AccountService } from "@gtmhub/accounts/accounts.service";
import { IScimSettings } from "@gtmhub/core";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { checkIfSetOfPropertiesSame, generateDefaultScimAccountSettings } from "../utils/scim-utils";

export class SCIMService {
  public static $inject = ["AccountService", "FeatureTogglesFacade", "$q"];

  private readonly settingsKey = "scim";

  constructor(
    private accountService: AccountService,
    private featureToggleService: FeatureTogglesFacade,
    private $q: IQService
  ) {}

  public getScimAccountSettings(): IPromise<IScimSettings> {
    return this.$q.when(this.featureToggleService.isFeatureAvailable(FeatureFlag.HideExternalTeams)).then((hideExternalTeams) => {
      const currentScimAccountSettings = this.accountService.getAccountSetting(this.settingsKey) as IScimSettings;
      const defaultScimSettings = generateDefaultScimAccountSettings({ hideExternalTeams });

      if (!currentScimAccountSettings) {
        return this.setScimAccountSetting(defaultScimSettings).then(() => defaultScimSettings);
      }

      const shouldUpdateSettings = !checkIfSetOfPropertiesSame(defaultScimSettings, currentScimAccountSettings);
      if (shouldUpdateSettings) {
        const updatedSettings = { ...defaultScimSettings, ...currentScimAccountSettings };
        return this.setScimAccountSetting(updatedSettings).then(() => updatedSettings);
      }

      return currentScimAccountSettings;
    });
  }

  public setScimAccountSetting(settings: IScimSettings): IPromise<void> {
    return this.accountService.setAccountSetting(this.settingsKey, settings);
  }

  public checkIfScimProvisioningIsOn(): IPromise<boolean> {
    return this.$q
      .all({
        isScimGroupsFeatureEnabled: this.featureToggleService.isFeatureAvailable(FeatureFlag.SCIMGroups),
        scimAccountSettings: this.getScimAccountSettings(),
      })
      .then(({ isScimGroupsFeatureEnabled, scimAccountSettings }) => {
        return isScimGroupsFeatureEnabled && scimAccountSettings && scimAccountSettings.rolesProvisioningSetupType === "scim";
      });
  }
}
