import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { NzTreeNode, NzTreeNodeOptions } from "ng-zorro-antd/core/tree";
import { NzTreeNodeSwitcherComponent } from "ng-zorro-antd/tree";
import { TreeSize } from "../../tree.models";

@Component({
  selector: "ui-tree-node-switcher",
  templateUrl: "tree-node-switcher.component.html",
  styleUrls: ["tree-node-switcher.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    "[class.tree-switcher-medium]": `!uiSelectMode && treeSize === 'medium'`,
  },
  standalone: true,
  imports: [NgIf, UiIconModule, NzOutletModule],
})
export class UiTreeNodeSwitcherComponent extends NzTreeNodeSwitcherComponent {
  @Input("uiShowExpand") public nzShowExpand?: boolean;
  @Input("uiShowLine") public nzShowLine?: boolean;
  @Input("uiExpandedIcon") public nzExpandedIcon?: TemplateRef<{ $implicit: NzTreeNode; origin: NzTreeNodeOptions }>;
  @Input("uiSelectMode") public nzSelectMode = false;
  @Input() public context!: NzTreeNode;
  @Input() public isLeaf?: boolean;
  @Input() public isLoading?: boolean;
  @Input() public isExpanded?: boolean;
  @Input() public treeSize?: TreeSize;
}
