import { Directive } from "@angular/core";
import { UiDashboardWidget, UiDashboardWidgetInputs } from "../dashboard.models";

@Directive()
export abstract class BaseWidgetComponent {
  public widgetId: string;

  public abstract get title(): string;
  public abstract get a11yLabel(): string;

  public serialize(): UiDashboardWidgetInputs | undefined {
    return;
  }

  public deserialize(widget: UiDashboardWidget): void {
    this.widgetId = widget.id;

    if (widget.inputs) {
      Object.assign(this, widget.inputs);
    }

    this.widgetId = widget.id;
  }
}
