import { Injectable } from "@angular/core";
import { Observable, combineLatest, map, take } from "rxjs";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureModuleService } from "@webapp/feature-toggles/services/feature-module.service";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import {
  ItemAutomationSupportedSelectionModeType,
  ItemAutomationTargetType,
  ItemAutomationTargetTypeBySelectionTypeMap,
} from "../models/item-automation-selector.models";

const NO_SUPPORTED_AUTOMATION_TYPE_FOUND_ERROR = "No supported automation type found";

@Injectable({ providedIn: "root" })
export class SupportedItemAutomationTypeService {
  constructor(
    private featureTogglesFacade: FeatureTogglesFacade,
    private editionFeatureService: EditionFeatureService,
    private permissionsFacade: PermissionsFacade,
    private featureModuleService: FeatureModuleService
  ) {}

  public hasAutomateWithInsightFeatureForItemType$(targetType: ItemAutomationTargetTypeBySelectionTypeMap["insight"]): Observable<boolean> {
    const availabilityMap: {
      [TargetType in ItemAutomationTargetTypeBySelectionTypeMap["insight"]]: () => Observable<boolean>;
    } = {
      kr: () =>
        combineLatest([this.permissionsFacade.hasPermission$("ManageData"), this.editionFeatureService.hasFeature$("insightboards.dynamic-kr")]).pipe(
          take(1),
          map((conditionArray) => {
            return conditionArray.every((isTrue) => isTrue);
          })
        ),
      kpi: () =>
        combineLatest([
          this.permissionsFacade.hasPermission$("ManageData"),
          this.permissionsFacade.hasPermission$("AccessKPIs"),
          this.permissionsFacade.hasPermission$("ManageKPIs"),
        ]).pipe(
          take(1),
          map((conditionArray) => {
            return conditionArray.every((isTrue) => isTrue);
          })
        ),
    };

    return availabilityMap[targetType]();
  }

  public hasAutomateWithKpiFeatureForItemType$(targetType: ItemAutomationTargetTypeBySelectionTypeMap["kpi"]): Observable<boolean> {
    const availabilityMap: {
      [TargetType in ItemAutomationTargetTypeBySelectionTypeMap["kpi"]]: () => Observable<boolean>;
    } = {
      kr: () =>
        combineLatest([
          this.editionFeatureService.hasFeature$("okrs.krs.kpi-automation"),
          this.featureModuleService.isKpiModuleEnabled$(),
          this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.AutomatingKRWithAKPI),
        ]).pipe(
          take(1),
          map((conditionArray) => {
            return conditionArray.every((isTrue) => isTrue);
          })
        ),
    };

    return availabilityMap[targetType]();
  }

  public getSupportedAutomationTypeForItemType$(targetType: ItemAutomationTargetType): Observable<ItemAutomationSupportedSelectionModeType> {
    const supportedTypeCheckMap: {
      [ItemType in ItemAutomationTargetType]: () => ReturnType<
        SupportedItemAutomationTypeService[Extract<keyof SupportedItemAutomationTypeService, "getSupportedAutomationTypeForKr$" | "getSupportedAutomationTypeForKpi$">]
      >;
    } = {
      kr: this.getSupportedAutomationTypeForKr$.bind(this),
      kpi: this.getSupportedAutomationTypeForKpi$.bind(this),
    };

    return supportedTypeCheckMap[targetType]();
  }

  public getSupportedAutomationTypeForKr$(): Observable<ItemAutomationSupportedSelectionModeType> {
    return new Observable((observer) => {
      observer.add(
        combineLatest([this.hasAutomateWithInsightFeatureForItemType$("kr"), this.hasAutomateWithKpiFeatureForItemType$("kr")])
          .pipe(take(1))
          .subscribe({
            next: ([hasFeatureAutomateWithInsight, hasFeatureAutomateWithKpi]) => {
              if (hasFeatureAutomateWithInsight && hasFeatureAutomateWithKpi) {
                observer.next("insightOrKpi");
                return;
              }

              if (hasFeatureAutomateWithInsight) {
                observer.next("insight");
                return;
              }

              if (hasFeatureAutomateWithKpi) {
                observer.next("kpi");
                return;
              }

              observer.error(NO_SUPPORTED_AUTOMATION_TYPE_FOUND_ERROR);
            },
            error: (error) => {
              observer.error(error);
            },
          })
      );
    });
  }

  public getSupportedAutomationTypeForKpi$(): Observable<ItemAutomationSupportedSelectionModeType> {
    return new Observable((observer) => {
      observer.add(
        this.hasAutomateWithInsightFeatureForItemType$("kpi")
          .pipe(take(1))
          .subscribe({
            next: (hasFeatureAutomateWithInsight) => {
              if (hasFeatureAutomateWithInsight) {
                observer.next("insight");
                return;
              }

              observer.error(NO_SUPPORTED_AUTOMATION_TYPE_FOUND_ERROR);
            },
            error: (error) => {
              observer.error(error);
            },
          })
      );
    });
  }
}
