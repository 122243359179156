import { IHttpService, IPromise, IQService, angularFileUpload } from "angular";
import { saveAs } from "file-saver";
import { EnvironmentService } from "@gtmhub/env";
import { IUser } from "@gtmhub/users/models";
import { IFileDownloadData, IFileUploadedData, IUploadFilePayload, IUploadImageResponse } from "./models";

export class FileService {
  public static $inject = ["$http", "$q", "EnvironmentService", "Upload"];

  constructor(
    private $http: IHttpService,
    private $q: IQService,
    private env: EnvironmentService,
    private $upload: angularFileUpload.IUploadService
  ) {}

  public uploadFile(data: IUploadFilePayload): IPromise<IFileUploadedData> {
    return this.$upload
      .upload<IFileUploadedData>({
        method: "POST",
        url: this.env.getFileServerEndpoint("/media"),
        data,
      })
      .then(
        (response) => response.data,
        (rejection) => this.$q.reject(rejection),
        (event) => (100.0 * event.loaded) / event.total
      );
  }

  private getFile(url: string): IPromise<IFileDownloadData> {
    return this.$http.get<File>(url, { responseType: "arraybuffer" }).then((response) => ({
      file: response.data,
      type: response.headers("Content-Type"),
    }));
  }

  public deleteFile(url: string): IPromise<unknown> {
    return this.$http.delete(url);
  }

  public downloadFile(url: string, fileName: string): IPromise<void> {
    return this.getFile(url).then((data) => {
      const blobToBeSaved = new Blob([data.file], { type: data.type });
      saveAs(blobToBeSaved, fileName);
    });
  }

  public generateFileUrl(url: string): IPromise<string> {
    return this.getFile(url).then((data) => URL.createObjectURL(new Blob([data.file], { type: data.type })));
  }

  public uploadBrandingImage(file: Blob): IPromise<IUploadImageResponse> {
    return this.upload("/brand", file);
  }

  public deleteBrandingImage(): IPromise<void> {
    return this.$http.delete(this.env.getFileServerEndpoint("/brand")).then(() => null);
  }

  public uploadTeamPicture(file: Blob): IPromise<IUploadImageResponse> {
    return this.upload("/image", file);
  }

  public uploadTeamPictureV2(data: IUploadFilePayload): IPromise<IFileUploadedData> {
    return this.uploadV2("/media", data);
  }

  public uploadAvatarForCurrentUser(file: Blob): IPromise<IUploadImageResponse> {
    return this.upload("/avatar", file);
  }

  public uploadAvatarForCurrentUserV2(data: IUploadFilePayload): IPromise<IFileUploadedData> {
    return this.uploadV2("/avatar", data);
  }

  public uploadAvatarForUser(user: IUser, file: Blob): IPromise<IUploadImageResponse> {
    const path = `/avatar/${user.id}`;
    return this.upload(path, file);
  }

  public uploadAvatarForUserV2(user: IUser, data: IUploadFilePayload): IPromise<IFileUploadedData> {
    const path = `/avatar/${user.id}`;
    return this.uploadV2(path, data);
  }

  public uploadV2(path: string, data: IUploadFilePayload): IPromise<IFileUploadedData> {
    return this.$upload
      .upload<IFileUploadedData>({
        method: "POST",
        url: this.env.getFileServerEndpointV2(path),
        data,
      })
      .then(
        (response) => response.data,
        (rejection) => this.$q.reject(rejection),
        (event) => (100.0 * event.loaded) / event.total
      );
  }

  public upload(path: string, file: Blob): IPromise<IUploadImageResponse> {
    return this.$upload
      .upload<IUploadImageResponse>({
        method: "POST",
        url: this.env.getFileServerEndpoint(path),
        data: { file },
      })
      .then(
        (response) => response.data,
        (rejection) => this.$q.reject(rejection),
        (event) => (100.0 * event.loaded) / event.total
      );
  }
}
