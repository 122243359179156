<ul class="bottom-section-nav">
  <li class="bottom-section-nav__item" *ngIf="showAppsSwitcherMenu" [uiTooltipTitle]="'apps' | localize" [attr.aria-label]="'Quantive ' + 'apps' | localize" uiTooltipPlacement="right" ui-tooltip>
    <apps-dropdown [withoutBorderOnFocus]="true" iconType="apps" data-test-id="new-navigation-apps-dropdown-button"></apps-dropdown>
  </li>

  <li class="bottom-section-nav__item" *ngIf="showMarketplace$ | async">
    <a
      class="bottom-section-nav__link"
      [uiTooltipTitle]="'marketplace' | localize"
      [attr.aria-label]="'marketplace' | localize"
      (click)="setMarketplaceCookie()"
      uiTooltipPlacement="right"
      href="/marketplace"
      gh-dropdown-toggle
      ui-tooltip
      aria-describedby="marketplace_aria_description"
      data-test-id="new-nav-icon-for-marketplace">
      <i class="bottom-section-nav__icon" ui-icon uiType="add-from-marketplace" uiSize="md"></i>
    </a>
    <span class="a11y-description-hide" id="marketplace_aria_description">{{ "marketplace" | localize }} - {{ "this_links_leads_to_currently_inaccessible_content" | localize }}</span>
  </li>
  <li class="bottom-section-nav__item" *ngIf="settingsScreen.showScreenCondition$ | async">
    <a
      class="bottom-section-nav__link"
      [class.bottom-section-nav__link--active]="activeScreen === settingsScreen.key"
      [uiTooltipTitle]="settingsScreen.key | localize"
      [uiSref]="settingsScreen.state"
      [attr.aria-label]="'settings' | localize"
      uiTooltipPlacement="right"
      ui-tooltip>
      <i class="bottom-section-nav__icon" ui-icon uiType="manage"></i>
    </a>
  </li>
  <li class="bottom-section-nav__item" [uiTooltipTitle]="'support_center' | localize" uiTooltipPlacement="right" ui-tooltip>
    <help-dropdown class="bottom-section-nav__help-dropdown" [withoutBorderOnFocus]="true" iconType="help"></help-dropdown>
  </li>
  <li class="bottom-section-nav__item">
    <button
      class="bottom-section-nav__notif-btn"
      [uiTooltipTitle]="'notifications' | localize"
      [attr.aria-label]="unreadNotificationsCountAriaLabel"
      (click)="openNotificationsModal()"
      uiTooltipPlacement="right"
      ui-tooltip>
      <ui-badge *ngIf="{ count: unreadNotificationsCount } as context" [uiCount]="context.count" [uiStyle]="{ right: '2px', 'font-size': '10px' }" [uiOffset]="[-2, 0]" uiSize="small">
        <i class="bottom-section-nav__icon" ui-icon uiType="notifications"></i>
      </ui-badge>
    </button>
  </li>
  <li class="bottom-section-nav__item">
    <profile-dropdown class="bottom-section-nav__profile" [withoutBorderOnFocus]="true"></profile-dropdown>
  </li>
</ul>
