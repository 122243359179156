import { IComponentOptions } from "angular";

export type HowToStartCardType = {
  title: string;
  text: string;
  img: string;
  button: {
    actionCb(): unknown;
    kind: "primary" | "secondary";
  };
};

class HowToStartCtrl {
  // ---- Bindings ---- //
  close: () => void;
  dismiss: () => void;
  resolve: {
    cards: HowToStartCardType[];
  };

  onOptionSelection(actionCb: () => void): void {
    actionCb();
    this.close();
  }
}

export const HowToStartComponent: IComponentOptions = {
  template: require("./how-to-start.html"),
  controller: HowToStartCtrl,
  bindings: {
    close: "&",
    dismiss: "&",
    resolve: "<",
  },
};
