import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiDropdownModule } from "@quantive/ui-kit/dropdown";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiMenuModule } from "@quantive/ui-kit/menu";
import { UiToggleModule } from "@quantive/ui-kit/toggle";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { DropdownMenuItemGroupComponent } from "./components/dropdown-menu-item-group/dropdown-menu-item-group.component";
import { DropdownMenuItemComponent } from "./components/dropdown-menu-item/dropdown-menu-item.component";
import { DropdownComponent } from "./dropdown.component";

@NgModule({
  imports: [CommonModule, LocalizationModule, UiToggleModule, UiIconModule, UiDropdownModule, UiButtonModule, UiMenuModule],
  declarations: [DropdownComponent, DropdownMenuItemComponent, DropdownMenuItemGroupComponent],
  exports: [DropdownComponent, DropdownMenuItemComponent, DropdownMenuItemGroupComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DropdownModule {}
