import { Inject, Injectable, Injector, Optional } from "@angular/core";
import { UiI18nService } from "@quantive/ui-kit/i18n";
import { DateHelperService, NZ_DATE_CONFIG } from "ng-zorro-antd/i18n";
import { UiDateConfig } from "@webapp/ui/date-picker/date-picker.models";
import { DateHelperServiceFactory } from "@webapp/ui/date-picker/utils/date-picker.utils";

/**
 * Abstract DateHelperService(Token via Class)
 * Compatibility: compact for original usage by default which using DatePipe
 */
@Injectable({
  providedIn: "root",
  useFactory: DateHelperServiceFactory,
  deps: [Injector, [new Optional(), NZ_DATE_CONFIG]],
})
export abstract class UiDateHelperService extends DateHelperService {
  protected constructor(
    protected i18n: UiI18nService,
    @Optional() @Inject(NZ_DATE_CONFIG) protected config: UiDateConfig
  ) {
    super(i18n, config);
  }
}
