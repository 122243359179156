import { UIRouterModule } from "@uirouter/angular";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiMenuModule } from "@quantive/ui-kit/menu";
import { take } from "rxjs";
import { EditionFeatureDirective } from "@webapp/accounts/directives/edition-feature.directive";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { FeatureTogglesModule } from "@webapp/feature-toggles/feature-toggles.module";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { GuidedOKRsComponent, GuidedOKRsModalData } from "@webapp/platform-intelligence/guided-okrs/guided-okrs.component";
import { PiTrackingFlowNameEnum } from "@webapp/platform-intelligence/shared/utils/pi-tracking";
import { DefaultSessionResolverService } from "@webapp/sessions/services/default-session-resolver.service";
import { SessionsRepository } from "@webapp/sessions/services/sessions-repository.service";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { CreateOkrFormModalService } from "../create-okr-form/create-okr-form-modal.service";

@UntilDestroy()
@Component({
  selector: "create-okr-split-button",
  templateUrl: "./create-okr-split-button.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiButtonModule, UiMenuModule, LocalizationModule, EditionFeatureDirective, FeatureTogglesModule, UiIconModule, UIRouterModule],
  providers: [DefaultSessionResolverService],
})
export class CreateOkrSplitButtonComponent {
  constructor(
    private modalService: UiModalService,
    private analyticsService: AnalyticsService,
    private defaultSessionResolverService: DefaultSessionResolverService,
    private sessionsRepository: SessionsRepository,
    private createOkrFormModalService: CreateOkrFormModalService
  ) {}

  public openQuantivePlusWizard(): void {
    this.sessionsRepository
      .getMap$()
      .pipe(take(1), untilDestroyed(this))
      .subscribe((sessionMap) => {
        const currentSession = this.defaultSessionResolverService.getDefaultSession([...sessionMap.values()]);
        this.modalService.create<GuidedOKRsComponent, GuidedOKRsModalData>({
          uiContent: GuidedOKRsComponent,
          uiWrapClassName: "guided-okrs-modal-wrapper",
          uiWidth: "100%",
          uiFooter: null,
          uiClosable: false,
          uiKeyboard: false,
          uiData: { currentSession },
        });
      });
  }

  public openCreateObjectiveModal(): void {
    this.analyticsService.track("Button Clicked", {
      button_name: "home_screen_empty_okr_list_create_okr",
      is_my_okrs: true,
      is_team: false,
      team_name: "",
      team_id: "",
    });

    this.createOkrFormModalService.openFormModal({
      flowName: PiTrackingFlowNameEnum.CreateObjectiveFormHomeEmptyState,
    });
  }
}
