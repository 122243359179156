import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { FeatureTogglesFacade } from "../services/feature-toggles-facade.service";

@UntilDestroy()
@Directive({
  selector: "[featureToggleEnabled]",
})
export class FeatureToggleEnabledDirective implements OnChanges {
  @Input()
  public featureToggleEnabled: string;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private container: ViewContainerRef,
    private featureTogglesFacade: FeatureTogglesFacade
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (!("featureToggleEnabled" in changes)) {
      return;
    }

    this.container.clear();

    const featureKey = changes["featureToggleEnabled"].currentValue;
    this.featureTogglesFacade
      .isFeatureAvailable$(featureKey)
      .pipe(untilDestroyed(this))
      .subscribe((enabled) => {
        if (enabled) {
          this.container.createEmbeddedView(this.templateRef);
        } else {
          this.container.clear();
        }
      });
  }
}
