import { Injectable } from "@angular/core";
import { Observable, map, take } from "rxjs";
import { IAccountRolesResponse, IRemoveRoleAction, IRole, RolesActionsEnum } from "@gtmhub/roles";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { IBaseRepository } from "@webapp/core/state-management/models/base-repository.model";
import { RolesApiService } from "./roles-api.service";

const SYS_ADMIN_ROLE_NAME = "SysAdmin";

@Injectable({ providedIn: "root" })
export class RolesRepository implements Pick<IBaseRepository<IRole>, "getAll$" | "get$"> {
  constructor(private api: RolesApiService) {}

  public getAll$<RT = IAccountRolesResponse>(params?: { filter?: RequestPaging; config?: RequestConfig }): Observable<RT> {
    return this.api
      .getAll$<RT>(params?.filter, params?.config)
      .pipe(map((response) => ({ ...response, items: (<IAccountRolesResponse>response).items.filter(this.filterOutSysAdminRole) })));
  }
  private filterOutSysAdminRole(role: IRole): boolean {
    return role.name !== SYS_ADMIN_ROLE_NAME;
  }

  public get$(id: string): Observable<IRole> {
    return this.api.get$<IRole>(id);
  }

  public updateRole$(id: string, role: Partial<IRole>, config?: RequestConfig): Observable<IRole> {
    const endpointUrl = this.api.getUpdateRoleEndpoint(id);
    return this.api.put$(id, role, { ...new RequestConfig(), url: endpointUrl, ...config });
  }

  public createRole$(role: Partial<IRole>, config?: RequestConfig): Observable<IRole> {
    const endpointUrl = this.api.createRoleEndpoint();
    return this.api.post$<IRole>(role, { ...new RequestConfig(), url: endpointUrl, ...config });
  }

  public deleteRole$(id: string): Observable<void> {
    const endpointUrl = this.api.deleteRoleEndpoint(id);

    return this.api.delete$(id, { ...new RequestConfig(), url: endpointUrl }).pipe(
      take(1),
      map(() => {
        const { reduxStore } = reduxStoreContainer;
        const removeRoleAction: IRemoveRoleAction = { type: RolesActionsEnum.REMOVE_ROLE, roleId: id };
        reduxStore.dispatch(removeRoleAction);
      })
    );
  }
}
