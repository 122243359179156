import { Directionality } from "@angular/cdk/bidi";
import { Platform } from "@angular/cdk/platform";
import { DOCUMENT, NgIf, NgTemplateOutlet } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { UiTSType } from "@quantive/ui-kit/core";
import { UiI18nService } from "@quantive/ui-kit/i18n";
import { NzConfigService, WithConfig } from "ng-zorro-antd/core/config";
import { NzResizeService } from "ng-zorro-antd/core/services";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { InputBoolean, InputNumber } from "ng-zorro-antd/core/util";
import { NzTypographyComponent } from "ng-zorro-antd/typography";
import { UiTextCopyComponent } from "./components/text-copy/text-copy.component";
import { UiTextEditComponent } from "./components/text-edit/text-edit.component";

@Component({
  selector: `
  ui-typography,
  [ui-typography],
  p[ui-paragraph],
  span[ui-text],
  h1[ui-title], h2[ui-title], h3[ui-title], h4[ui-title]
  `,
  exportAs: "uiTypography",
  templateUrl: "typography.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiTextEditComponent, UiTextCopyComponent, NgIf, NgTemplateOutlet],
})
export class UiTypographyComponent extends NzTypographyComponent {
  @Input("uiCopyable") @InputBoolean() public nzCopyable = false;
  @Input("uiEditable") @InputBoolean() public nzEditable = false;
  @Input("uiDisabled") @InputBoolean() public nzDisabled = false;
  @Input("uiExpandable") @InputBoolean() public nzExpandable = false;
  @Input("uiEllipsis") @InputBoolean() public nzEllipsis = false;
  @Input("uiCopyTooltips") @WithConfig() public nzCopyTooltips?: [UiTSType, UiTSType] | null = undefined;
  @Input("uiCopyIcons") @WithConfig() public nzCopyIcons: [UiTSType, UiTSType] = ["copy", "check"];
  @Input("uiEditTooltip") @WithConfig() public nzEditTooltip?: null | UiTSType = undefined;
  @Input("uiEditIcon") @WithConfig() public nzEditIcon: UiTSType = "edit";
  @Input("uiContent") public nzContent?: string;
  @Input("uiEllipsisRows") @WithConfig() @InputNumber() public nzEllipsisRows = 1;
  @Input("uiType") public nzType: "secondary" | "warning" | "danger" | "success" | undefined;
  @Input("uiCopyText") public nzCopyText: string | undefined;
  @Input("uiSuffix") public nzSuffix: string | undefined;
  @Output("uiContentChange") public readonly nzContentChange = new EventEmitter<string>();
  @Output("uiCopy") public readonly nzCopy = new EventEmitter<string>();
  @Output("uiExpandChange") public readonly nzExpandChange = new EventEmitter<void>();
  // This is not a two-way binding output with {@link nzEllipsis}
  @Output("uiOnEllipsis") public readonly nzOnEllipsis = new EventEmitter<boolean>();

  @ViewChild(UiTextEditComponent, { static: false }) public textEditRef?: UiTextEditComponent;
  @ViewChild(UiTextCopyComponent, { static: false }) public textCopyRef?: UiTextCopyComponent;
  @ViewChild("ellipsisContainer", { static: false }) public ellipsisContainer?: ElementRef<HTMLSpanElement>;
  @ViewChild("expandable", { static: false }) public expandableBtn?: ElementRef<HTMLSpanElement>;
  @ViewChild("contentTemplate", { static: false }) public contentTemplate?: TemplateRef<{ content: string }>;

  constructor(
    nzConfigService: NzConfigService,
    host: ElementRef<HTMLElement>,
    cdr: ChangeDetectorRef,
    viewContainerRef: ViewContainerRef,
    renderer: Renderer2,
    platform: Platform,
    i18n: UiI18nService,
    @Inject(DOCUMENT) document: NzSafeAny,
    resizeService: NzResizeService,
    @Optional() directionality: Directionality
  ) {
    super(nzConfigService, host, cdr, viewContainerRef, renderer, platform, i18n, document, resizeService, directionality);
  }
}
