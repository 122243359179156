import { AfterViewInit, Directive, ElementRef } from "@angular/core";

@Directive({
  selector: "[removeInteractableAttributes]",
  standalone: true,
})
export class RemoveInteractableAttributesDirective implements AfterViewInit {
  public constructor(private hostElement: ElementRef<HTMLElement>) {}

  public ngAfterViewInit(): void {
    this.hostElement.nativeElement.querySelectorAll("a,button,[tabindex],[data-test-id],[id],[href]").forEach((item) => {
      item.removeAttribute("data-test-id");
      item.removeAttribute("id");
      item.removeAttribute("href");
      item.setAttribute("tabindex", "-1");
    });
  }
}
