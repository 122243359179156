export class OkrLinkClickEvent extends Event {
  public stateParams: OkrLinkClickEventParams;
}

export type OkrLinkClickEventParamsId = "id" | "metricId" | "kpiId";

export type OkrLinkClickEventParams = {
  path: string;
  params: {
    [K in OkrLinkClickEventParamsId]?: string;
  };
};

export const getItemTypeToStateParams = (type: OkrLinkClickEventParamsId, id: string): OkrLinkClickEventParams => {
  const map: Record<"goals" | "metrics" | "kpis", OkrLinkClickEventParams> = {
    goals: { path: ".goal", params: { id: id } },
    metrics: { path: ".metric", params: { metricId: id } },
    kpis: { path: "._kpis.details", params: { kpiId: id } },
  };

  return map[type];
};
