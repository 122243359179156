import { NgFor } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UiAccessibilityModule } from "@quantive/ui-kit/accessibility";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiI18nModule } from "@quantive/ui-kit/i18n";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { ɵYearHeaderComponent as YearHeaderComponent } from "ng-zorro-antd/date-picker";
import { UiPanelSelector } from "../../date-picker.models";

@Component({
  selector: "ui-year-header",
  exportAs: "uiYearHeader",
  templateUrl: "../abstract-panel-header.html",
  styleUrls: ["year-header.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "ui-date-header",
  },
  standalone: true,
  imports: [UiAccessibilityModule, NgFor, UiI18nModule, UiIconModule, UiButtonModule],
})
export class UiYearHeaderComponent extends YearHeaderComponent {
  /** the id of the element holding month and year buttons and used to label the table of dates/months/years/decades */
  @Input()
  public uiLabelId: string;

  public selectors: UiPanelSelector[];
}
