export enum FeedActionsEnum {
  REQUEST_FEED = "REQUEST_FEED",
  SET_SELECTED_FEED_ENTRY = "SET_SELECTED_FEED_ENTRY",
  RECEIVE_FEED = "RECEIVE_FEED",
  RECEIVE_FEED_ENTRY = "RECEIVE_FEED_ENTRY",
  CLEAR_FETCHED_DATA = "CLEAR_FETCHED_DATA",
  CLEAR_SELECTED_FEED_ENTRY = "CLEAR_SELECTED_FEED_ENTRY",
  UPDATE_FEED_ENTRY_COMMENTS = "UPDATE_FEED_ENTRY_COMMENTS",
  ERROR_REQUEST_FEED = "ERROR_REQUEST_FEED",
}
