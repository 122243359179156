export enum PiTrackingFlowNameEnum {
  CreateObjectiveFormTopNav = "create_objective_form_top_nav",
  CreateObjectiveFormHomeEmptyState = "create_objective_form_home_empty_state",
  CreateObjectiveFormOkrsEmptyState = "create_objective_form_okrs_empty_state",
  CreateMetricFormHomeEmptyState = "create_metric_form_home_empty_state",
  CreateMetricFormOkrsEmptyState = "create_metric_form_okrs_empty_state",
  CreateMetricFormAlignmentDropdown = "create_metric_form_alignment_dropdown",
  CreateMetricFormNestedViewAlignmentDropdown = "create_metric_form_nested_view_alignment_dropdown",
  CreateMetricFormobjectiveDropdown = "create_metric_form_objective_dropdown",
  CreateObjectiveFormAlignmentDropdown = "create_objective_form_alignment_dropdown",
  CreateObjectiveFormOkrViewDropdown = "create_objective_form_okr_view_dropdown",
  Description = "description",
  Tags = "tags",
  KeyResults = "key-results",
  Tasks = "tasks",
  ObjectiveDetailsView = "objective_details_view",
  ObjectiveDetailsViewAllSuggestionSidePanel = "objective_details_view_all_suggestions_side_panel",
  ObjectiveDetailsViewEffectivenessSidePanel = "objective_details_view_effectiveness_side_panel",
  ObjectiveDetailsViewOverviewSidePanel = "objective_details_view_overview_side_panel",
  KeyResultDetailsView = "key_result_details_view",
  KeyResultDetailsViewAllSuggestionsSidePanel = "key_result_details_view_all_suggestions_side_panel",
  KeyResultDetailsViewEffectivenessSidePanel = "key_result_details_view_effectiveness_side_panel",
  KeyResultDetailsViewOverviewSidePanel = "key_result_details_view_overview_side_panel",
  GuidedOkrsCreationTrialHomeEmptyState = "guided_okrs_creation_trial_home_empty_state",
  GuidedOkrsCreationCreateOkrTopNav = "guided_okrs_creation_create_okr_top_nav",
  GuidedOkrsCreationOkrViewDropdown = "guided_okrs_creation_okr_view_dropdown",
  GuidedOkrsCreationAlignmentDropdown = "guided_okrs_creation_alignment_dropdown",
  InsightSqlEditor = "insight_sql_editor",
}

export enum PiTrackingEventsEnum {
  PiFlowInitiated = "PI Flow Initiated",
  PiSuggestionAccepted = "PI Suggestion Accepted",
  PiSuggestionEdited = "PI Suggestion Edited",
  PiSuggestionRejected = "PI Suggestion Rejected",
  PiSuggestionRefreshed = "PI Suggestion Refreshed",
  PiSuggestionImproved = "PI Suggestion Improved",
  PISuggestionCopied = "PI Suggestion Copied",
}

export enum PiSqlTrackingEventsEnum {
  SqlSuggestionsTabClicked = "SQL Suggestions Tab Clicked",
  RelatedQuestionClicked = "Related Question Clicked",
}

export type PiSqlTrackingEvents =
  | Extract<PiTrackingEventsEnum, PiTrackingEventsEnum.PiSuggestionAccepted | PiTrackingEventsEnum.PISuggestionCopied>
  | PiSqlTrackingEventsEnum;

export type FlowName = ObjectiveFlowName | KeyResultFlowName;

export type ObjectiveFlowName = PiTrackingFlowNameEnum.ObjectiveDetailsView | PiTrackingFlowNameEnum.ObjectiveDetailsViewAllSuggestionSidePanel;
export type KeyResultFlowName = PiTrackingFlowNameEnum.KeyResultDetailsView | PiTrackingFlowNameEnum.KeyResultDetailsViewAllSuggestionsSidePanel;

export const PiToggleButton = "pi_suggestion_button";
