import equal from "fast-deep-equal";
import { UiDashboardNode, UiDashboardWidget } from "@webapp/ui/dashboard/dashboard.models";

export const nodeToWidget = (node: UiDashboardNode): UiDashboardWidget => {
  const { id, x, y, w, h, minH, maxH, sizeToContent, noResize, noMove, locked, selector, content, noShadow, bgColor, maxW, minW } = node;
  const widget: UiDashboardWidget = { id, x, y, w, h, minH, maxH, sizeToContent, noResize, noMove, locked, selector, content, noShadow, bgColor, maxW, minW };

  // remove undefined props
  for (const [prop] of Object.entries(widget).filter(([, value]) => typeof value === "undefined")) {
    delete widget[prop];
  }
  return widget;
};

export const handleWidgetsChange = (
  sourceWidgets: UiDashboardWidget[],
  changedWidgets: UiDashboardWidget[],
  options: { checkForY?: boolean } = { checkForY: false }
): UiDashboardWidget[] => {
  let hasChanges = false;
  const widgets = [...sourceWidgets];
  for (const changedWidget of changedWidgets) {
    const existingWidgetIndex = widgets.findIndex((w) => w.id === changedWidget.id);
    if (existingWidgetIndex < 0) {
      widgets.push(changedWidget);
      hasChanges = true;
    } else {
      const existingWidget = widgets[existingWidgetIndex];
      const widgetsEqual = areWidgetsEqual(existingWidget, changedWidget, options);
      if (widgetsEqual) {
        Object.assign(existingWidget, changedWidget);
      } else {
        widgets[existingWidgetIndex] = changedWidget;
        hasChanges = true;
      }
    }
  }

  return hasChanges ? widgets : sourceWidgets;
};

function areWidgetsEqual(existingWidget: UiDashboardWidget, changedWidget: UiDashboardWidget, options: { checkForY?: boolean } = { checkForY: false }): boolean {
  return existingWidget.sizeToContent && changedWidget.sizeToContent
    ? equal(
        { ...existingWidget, y: options.checkForY ? existingWidget.y : undefined, h: undefined },
        { ...changedWidget, y: options.checkForY ? changedWidget.y : undefined, h: undefined }
      )
    : equal({ ...existingWidget, y: options.checkForY ? existingWidget.y : undefined }, { ...changedWidget, y: options.checkForY ? changedWidget.y : undefined });
}
