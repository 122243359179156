import { IRootScopeService } from "angular";
import { TransitionService } from "@uirouter/angularjs";
import { IAppConfig } from "@gtmhub/env";
import { SurveyService } from "./survey";

const mod = angular.module("delighted", []);

mod.service("SurveyService", SurveyService);
mod.run([
  "$rootScope",
  "$transitions",
  "appConfig",
  "SurveyService",
  ($rootScope: IRootScopeService, $transitions: TransitionService, appConfig: IAppConfig, surveyService: SurveyService) => {
    $rootScope.$on("authorizationProcessed", () => {
      if (appConfig.delighted.enabled) {
        surveyService.execDelightedSurvey();
      }
    });
  },
]);

export default mod.name;
