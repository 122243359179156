import { IHttpResponse, IRequestShortcutConfig, ISCEService } from "angular";
import { EnvironmentService } from "@gtmhub/env";
import { IPluginStoreValue, IUIPlugin } from "@gtmhub/plugins";
import { ICollection } from "@webapp/core/core.models";
import { IPlugin } from "../models";

interface IUIPluginDetailsResponse extends IUIPlugin {
  id: string;
}

interface PluginErrorInterface<T> {
  statusCode: number;
  error: T;
  url: string;
  method: string;
}

export class PluginService {
  static $inject = ["$http", "EnvironmentService", "$sce"];

  constructor(
    private $http: ng.IHttpService,
    private env: EnvironmentService,
    private sceService: ISCEService
  ) {}

  getAccountPlugins(): ng.IPromise<ICollection<IPlugin>> {
    // const url = this.env.getApiEndpoint(`/plugins/ui/installed/${host}/${auth0UserId}/`);
    const url = this.env.getApiEndpoint(`/plugins/installed`);
    return this.$http.get<ICollection<IPlugin>>(url).then((response) => response.data);
  }

  getUIPluginById(pluginDataId: string): ng.IPromise<IUIPluginDetailsResponse> {
    const url = this.env.getApiEndpoint(`/plugins/ui/${pluginDataId}`);

    return this.$http.get<IUIPluginDetailsResponse>(url).then((response) => response.data);
  }

  getUIPluginsDetails(pluginDataIds: string[]): ng.IPromise<IUIPluginDetailsResponse[]> {
    const config: IRequestShortcutConfig = {
      params: {
        filter: { _id: { $in: pluginDataIds } },
      },
    };
    const url = this.env.getApiEndpoint(`/plugins/ui/`);

    return this.$http.get<IUIPluginDetailsResponse[]>(url, config).then((response) => response.data);
  }

  transformHttpError = <T>(error: IHttpResponse<T>): PluginErrorInterface<T> => {
    return {
      statusCode: error.status,
      error: error.data,
      url: error.config.url,
      method: error.config.method,
    };
  };

  getPluginStore(params: { pluginId: string; key: string }): ng.IPromise<IPluginStoreValue> {
    const url = this.env.getApiEndpoint(`/plugins/${params.pluginId}/store/${params.key}`);
    return this.$http.get<IPluginStoreValue>(url).then((response) => response.data);
  }

  setPluginStore(params: { pluginId: string; key: string; value: string }): ng.IPromise<void> {
    const url = this.env.getApiEndpoint(`/plugins/${params.pluginId}/store`);
    const storeEntry = { key: params.key, value: params.value };
    return this.$http.post<IPluginStoreValue>(url, storeEntry).then(() => null);
  }

  toTrustedResourceUrl(src: string): string {
    return this.sceService.trustAsResourceUrl(src);
  }
}
