export interface IdMap<T> {
  [id: string]: T;
}

export type NameMap<T> = {
  [key: string]: T;
};

type KeyMap<T extends { [K in keyof T] }, K extends keyof T> = {
  [keyValue in T[K]]: T;
};

export function toIdMap<T>(arr: T[]): IdMap<T> {
  return toKeyMap("id" as keyof T, arr);
}

export function toIdBooleanMap<T>(arr: T[]): IdMap<boolean> {
  return toKeyBooleanMap("id" as keyof T, arr);
}

export function toKeyMap<T extends { [K in keyof T] }>(key: keyof T, arr: T[]): KeyMap<T, keyof T> {
  if (!arr || !arr.length) {
    return {} as KeyMap<T, keyof T>;
  }

  return arr.reduce(
    function (map, item, index) {
      if (!item[key]) {
        throw new Error(`item at index ${index} does not have an '${String(key)}' property`);
      }

      map[item[key]] = item;

      return map;
    },
    {} as KeyMap<T, keyof T>
  );
}

function toKeyBooleanMap<T extends { [K in keyof T] }>(key: keyof T, arr: T[]): NameMap<boolean> {
  if (!arr || !arr.length) {
    return {};
  }

  return arr.reduce(function (map, item, index) {
    if (!item[key]) {
      throw new Error(`item at index ${index} does not have an '${String(key)}' property`);
    }

    map[item[key]] = true;

    return map;
  }, {} as NameMap<boolean>);
}

/**
 * Adds the entry to the array if not added yet and returns the array. Array should be defined. If entry's type is non-primitive arrSomeCb should be passed as well.
 * @param entry the entry to be added to the array if not present
 * @param arr collection to be extended, must be a defined array
 * @param arrSomeCb optional callback to array.some method used to check if non-primitive entry has already been added. If not passed array.indexOf is called
 */
export function addToArrayIfMissing<T>(entry: T, arr: T[], arrSomeCb?: (value: T, index?: number, array?: T[]) => boolean): T[] {
  if ((!arrSomeCb && arr.indexOf(entry) === -1) || (arrSomeCb && !arr.some(arrSomeCb))) {
    arr.push(entry);
  }

  return arr;
}

/**
 * Beware when using this method for large collections especially if nested in other iterations
 */
export function unique<T>(arr: T[]): T[] {
  const newArr: T[] = [];
  for (const item of arr) {
    if (newArr.indexOf(item) < 0) {
      newArr.push(item);
    }
  }
  return newArr;
}

export function flatten<T>(arr: T[][]): T[] {
  return arr.reduce((all, current) => [...all, ...current], []);
}

export function pushOrReplace<T>(arr: T[], item: T, indexOrPredicate?: number | ((item: T) => unknown)): T[] {
  if (typeof indexOrPredicate === "undefined") {
    return [...arr, item];
  }

  let index: number;
  if (typeof indexOrPredicate === "number") {
    index = indexOrPredicate;
  } else {
    index = arr.findIndex(indexOrPredicate);
  }

  if (index < 0 || index >= arr.length) {
    return [...arr, item];
  }

  return arr.map((x, i) => (i === index ? item : x));
}

export function getScript(source: string, options: { id?: string } = {}): Promise<void> {
  if (options.id) {
    const scriptExists = document.getElementById(options.id) != null;
    if (scriptExists) {
      return Promise.resolve();
    }
  }

  return new Promise((resolve, reject) => {
    let script = document.createElement("script");

    const resetReferences = () => {
      ieScript.onreadystatechange = null;
      ieScript.onload = null;
      ieScript.onerror = null;
      script = undefined;
    };

    // IE support
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ieScript: any = script;
    ieScript.onreadystatechange = () => {
      resetReferences();
      if (ieScript.readyState === "loaded" || ieScript.readyState === "complete") {
        resolve();
      } else {
        reject();
      }
    };

    // Other (non-IE) browsers support
    script.onload = () => {
      resetReferences();
      resolve();
    };

    script.onerror = (ev: ErrorEvent) => {
      resetReferences();
      reject(ev.error);
    };

    script.async = true;
    script.src = source;

    if (options.id) {
      script.id = options.id;
    }

    document.body.appendChild(script);
  });
}

export const emptyDateUTC = "0001-01-01T00:00:00Z";

export function roundFloatAtMost2DecPlaces(num: number): number {
  // Rounds to at most 2 decimal places (only if necessary)
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

export const getColorForIndex = (index: number, colors: string[]): string => {
  // iterates over color array. If the index is bigger then the color length,
  // the color selection starts from the beginning of the colors array.
  const colorIndex = index - colors.length * Math.floor(index / colors.length);

  return colors[colorIndex];
};

export const setTimeoutOutsideAngularTimes = (times: number, callback: () => unknown, ms: number): number => {
  return window.setTimeoutOutsideAngular(() => {
    if (callback() === false) {
      return;
    }

    if (times > 1) {
      return setTimeoutOutsideAngularTimes(times - 1, callback, ms);
    }
  }, ms);
};

export const splitBy = <T>(arr: T[], condition: (item: T) => unknown): [T[], T[]] => {
  const trueArr: T[] = [];
  const falseArr: T[] = [];

  for (const item of arr) {
    if (condition(item)) {
      trueArr.push(item);
    } else {
      falseArr.push(item);
    }
  }

  return [trueArr, falseArr];
};

export function getValueAsString(value: unknown): string {
  if (value === null) {
    return "null";
  } else if (typeof value === "boolean" || typeof value == "number") {
    return value.toString();
  } else if (Array.isArray(value) && value.length === 0) {
    return "[]";
  } else if (!value) {
    return "";
  }
  return value.toString();
}

export function escapeString(value: string): string {
  if (value) {
    return value.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  }

  return value;
}

export const setPrecisionToNumber = (value: number, precision?: number): string => {
  if (!value && value !== 0) {
    return "";
  }

  if (!precision && precision !== 0) {
    return String(value);
  }

  if (precision <= 0) {
    return String(Math.round(value));
  }

  return String(value.toFixed(precision));
};

export const splitArrayIntoTwo = <T>(
  array: T[] = [],
  splitIndex: number
): {
  firstPart: T[];
  secondPart: T[];
} => {
  if (array.length - 1 > splitIndex) {
    const firstPart = array.slice(0, splitIndex);
    const secondPart = array.slice(splitIndex);

    return { firstPart, secondPart };
  } else {
    return { firstPart: array, secondPart: [] };
  }
};
