import { IDirective, IDirectiveFactory, IScope } from "angular";
import { AccountService } from "@gtmhub/accounts/accounts.service";
import { IIndicator } from "@gtmhub/error-handling";
import { IAccountInvitationLink } from "@gtmhub/login/models";
import { copyToClipboard } from "@gtmhub/shared/dom";

export interface IInviteLinkScope extends IScope {
  indicators: {
    loadingInvitation: IIndicator;
  };
  accountInvite: IAccountInvitationLink;
  generateInvitationLink(): void;
  closeInvitationLink(): void;
  copyLink(link: string): void;
  isInvitationLinkGenerated: boolean;
}

export class InviteLink implements IDirective {
  public scope = {
    isInvitationLinkGenerated: "=",
  };
  public template = require("../views/invite-link.html");
  private $scope: IInviteLinkScope = null;

  constructor(private accountService: AccountService) {}

  public link(inviteLinkScope: IInviteLinkScope): void {
    this.$scope = inviteLinkScope;

    this.$scope.generateInvitationLink = this.generateInvitationLink;
    this.$scope.closeInvitationLink = this.closeInvitationLink;
    this.$scope.copyLink = this.copyLink;

    this.$scope.indicators = {
      loadingInvitation: { progress: true },
    };
    this.$scope.isInvitationLinkGenerated = false;
    this.getLink();
  }

  private generateInvitationLink = () => {
    this.$scope.indicators.loadingInvitation = { progress: true };

    this.accountService.generateInvitationLink().then(
      (invite) => {
        this.$scope.accountInvite = invite;
        this.$scope.isInvitationLinkGenerated = true;
        delete this.$scope.indicators.loadingInvitation;
      },
      (error) => (this.$scope.indicators.loadingInvitation = { error })
    );
  };

  private closeInvitationLink = () => {
    this.$scope.indicators.loadingInvitation = { progress: true };

    this.accountService.closeInvitationLink().then(
      (invite) => {
        this.$scope.accountInvite = invite;
        this.$scope.isInvitationLinkGenerated = false;
        delete this.$scope.indicators.loadingInvitation;
      },
      (error) => (this.$scope.indicators.loadingInvitation = { error })
    );
  };

  private getLink = () => {
    this.accountService.getInvitationLink().then(
      (invite) => {
        this.$scope.accountInvite = invite;
        delete this.$scope.indicators.loadingInvitation;

        if (this.$scope.accountInvite?.enabled) {
          this.$scope.isInvitationLinkGenerated = true;
        }
      },
      (error) => {
        const invitationNotFoundErrMsg = "not found";
        if (error.status === 404 && error.data === invitationNotFoundErrMsg) {
          delete this.$scope.indicators.loadingInvitation;
          return;
        }
        this.$scope.indicators.loadingInvitation = { error };
      }
    );
  };

  private copyLink = (link: string): void => {
    copyToClipboard(link);
  };

  public static factory(): IDirectiveFactory {
    const directive = (accountService: AccountService) => new InviteLink(accountService);
    directive.$inject = ["AccountService"];

    return directive;
  }
}
