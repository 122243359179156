import { IHttpResponse, IHttpService, IPromise, IQService, IRequestShortcutConfig, noop } from "angular";
import { EnvironmentService } from "@gtmhub/env";
import { ICollection } from "@webapp/core/core.models";
import { DataSourcePostDTO } from "@webapp/data-story/models/data-source.models";
import { Entity } from "@webapp/data-story/services/entities-facade/entities-facade.models";
import {
  ICreateDataSource,
  IDataSource,
  IDataSourceBlueprint,
  IDataSourcePatch,
  IDataflowBlueprintItem,
  IGetDataFlowParams,
  IRemoteEntity,
  IRemoteEntityField,
  IRenameEntityData,
  ISchemaChanges,
} from "../models/models";

export class DataSourceService {
  public static $inject = ["$http", "$q", "EnvironmentService"];

  constructor(
    private $http: IHttpService,
    private $q: IQService,
    private env: EnvironmentService
  ) {}

  public getBlueprints(categoryId?: string): IPromise<IDataSourceBlueprint[]> {
    let url = this.env.getApiEndpoint("/datasources/blueprints");
    if (categoryId) {
      url = url + "?categoryId=" + categoryId;
    }

    return this.$http.get<ICollection<IDataSourceBlueprint>>(url).then((response) => response.data.items);
  }

  public getBlueprintsWithConnection(fields: string = null, { isActive } = { isActive: false }): IPromise<IDataSourceBlueprint[]> {
    const url = this.env.getApiEndpoint("/datasources/blueprints-with-connection");
    const query: IRequestShortcutConfig = {
      params: {
        fields: fields,
        isActive: isActive,
      },
    };

    return this.$http.get<ICollection<IDataSourceBlueprint>>(url, query).then((response) => response.data.items);
  }

  public getBlueprint(name: string): IPromise<IDataSourceBlueprint> {
    const url = this.env.getApiEndpoint("/datasources/blueprints/" + name);

    return this.$http.get<IDataSourceBlueprint>(url).then((response) => response.data);
  }

  public createDataSource(data: DataSourcePostDTO): IPromise<IDataSource> {
    const url = this.env.getApiEndpointV2("/datasources");

    return this.$http.post<IDataSource>(url, data).then((response) => response.data);
  }

  public bulkCreateDataSources(dataSources: { datasources: IDataSource[] }): IPromise<{ items: IDataSource[] }> {
    const url = this.env.getApiEndpoint("/datasources/bulk");

    return this.$http.post<{ items: IDataSource[] }>(url, dataSources).then((response) => response.data);
  }

  public processRequiredEntitiesOfInsight(createDataSourcesData: ICreateDataSource): { datasources: IDataSource[] } {
    return createDataSourcesData.requiredEntities.reduce(
      (requiredData, requiredEntity) => {
        requiredData.datasources.push({
          name: requiredEntity.entityName,
          namespace: createDataSourcesData.namespace || null,
          key: requiredEntity.remoteEntityName,
          connectorId: createDataSourcesData.connectorId,
          syncSchedule: createDataSourcesData.syncSchedule || requiredEntity.syncSchedule || "daily",
          typeName: createDataSourcesData.typeName || null,
        });

        return requiredData;
      },
      { datasources: [] }
    );
  }

  public createVD(vd: IDataSource): IPromise<IDataSource> {
    const url = this.env.getApiEndpointV2("/datasources");

    return this.$http.post<IDataSource>(url, vd).then((response) => response.data);
  }

  public getDataSources(connectorId?: string): IPromise<IDataSource[]> {
    const queryParams: string = connectorId ? "/?connectorIds=" + connectorId : "",
      url = this.env.getApiEndpoint(`/datasources${queryParams}`);

    return this.$http.get<ICollection<IDataSource>>(url).then((response) => response.data.items);
  }

  public getDataSource(id: string): IPromise<IDataSource> {
    const url = this.env.getApiEndpoint("/datasources/" + id);

    return this.$http.get<IDataSource>(url).then((response) => response.data);
  }

  public syncDataSource(id: string): IPromise<boolean> {
    const url = this.env.getApiEndpoint("/datasources/" + id + "/sync");

    return this.$http.post<boolean>(url, null).then((response) => response.data);
  }

  public syncDataSources(ids: { ids: string[] }): IPromise<boolean> {
    const url = this.env.getApiEndpoint("/datasources/sync");

    return this.$http.post<boolean>(url, ids).then((response) => response.data);
  }

  public createEntity(dataSourceId: string): IPromise<Entity> {
    const url = this.env.getApiEndpoint("/datasources/" + dataSourceId + "/entity");

    return this.$http.post<Entity>(url, null).then((response) => response.data);
  }

  public renameEntity(data: IRenameEntityData): IPromise<Entity> {
    const url = this.env.getApiEndpoint("/datasources/" + data.dataSourceId + "/entity");

    return this.$http.patch<Entity>(url, { oldName: data.oldName, newName: data.newName }).then((response) => response.data);
  }

  public deleteDataSource(id: string): IPromise<void> {
    const url = this.env.getApiEndpoint("/datasources/" + id);

    return this.$http.delete<void>(url).then(noop);
  }

  public deleteDataSourceDefaultEntity(id: string): IPromise<boolean> {
    const url = this.env.getApiEndpoint("/datasources/" + id + "/entity");

    return this.$http.delete<boolean>(url).then(
      () => true,
      (response: IHttpResponse<unknown>) => {
        // if there was no entity to delete resolve as successful operation.
        if (response.status === 404) {
          return true;
        } else {
          return this.$q.reject(response);
        }
      }
    );
  }

  public updateDataSourceSchema(id: string): IPromise<ISchemaChanges> {
    const url = this.env.getApiEndpoint("/datasources/" + id + "/schema");

    return this.$http.put<ISchemaChanges>(url, null).then((response) => response.data);
  }

  public getDataSourceSchemaChanges(id: string): IPromise<ISchemaChanges> {
    const url = this.env.getApiEndpoint("/datasources/" + id + "/schema/changes");

    return this.$http.get<ISchemaChanges>(url, null).then((response) => response.data);
  }

  public patchDataSource(id: string, dataSourcePatch: IDataSourcePatch): IPromise<unknown> {
    const url: string = this.env.getApiEndpoint(`/datasources/${id}`);

    return this.$http.patch<unknown>(url, dataSourcePatch).then((response) => response.data);
  }

  public getDataflow(params: IGetDataFlowParams): IPromise<IDataflowBlueprintItem[]> {
    const url: string = this.env.getApiEndpoint("/datasources/dataflow");
    const query: IRequestShortcutConfig = {
      params,
    };

    return this.$http.get<ICollection<IDataflowBlueprintItem>>(url, query).then((response) => response.data.items);
  }

  public getDataflowConnectionsStatus(params: IGetDataFlowParams): IPromise<IDataflowBlueprintItem[]> {
    const url: string = this.env.getApiEndpoint("/datasources/dataflow/status");

    const query: IRequestShortcutConfig = {
      params,
    };
    return this.$http.get<ICollection<IDataflowBlueprintItem>>(url, query).then((response) => response.data.items);
  }

  public getDataflowV2(params: IGetDataFlowParams): IPromise<IDataflowBlueprintItem[]> {
    const url: string = this.env.getApiEndpointV2("/datasources/dataflow");
    const query: IRequestShortcutConfig = {
      params,
    };

    return this.$http.get<ICollection<IDataflowBlueprintItem>>(url, query).then((response) => response.data.items);
  }

  public getDataflowConnector({
    fields,
    includeEntities,
    dsBlueprintNames,
  }: {
    fields: string;
    includeEntities: boolean;
    dsBlueprintNames: string;
  }): IPromise<IDataflowBlueprintItem[]> {
    const url: string = this.env.getApiEndpoint("/datasources/dataflow");
    const query: IRequestShortcutConfig = {
      params: {
        fields: fields,
        includeEntities: includeEntities,
        dataSourceBlueprintNames: dsBlueprintNames,
      },
    };

    return this.$http.get<ICollection<IDataflowBlueprintItem>>(url, query).then((response) => response.data.items);
  }

  public getRemoteEntities(connectorId: string, root?: string): IPromise<IRemoteEntity[]> {
    let url = this.env.getApiEndpoint("/remoteentities/" + connectorId);
    if (root) {
      url = url + "?root=" + root;
    }

    return this.$http.get<IRemoteEntity[]>(url).then((response) => response.data);
  }

  public getRemoteEntitySchema(dataSourceId: string): IPromise<IRemoteEntityField[]> {
    const url = this.env.getApiEndpoint("/datasources/" + dataSourceId + "/schema");

    return this.$http.get<IRemoteEntityField[]>(url).then((response) => response.data);
  }
}
