import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { INotification } from "@gtmhub/notifications/models/notifications";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { NotificationDTO } from "@webapp/users/models/notifications.models";
import { NotificationsState } from "./notifications-state.service";

@Injectable({
  providedIn: "root",
})
export class NotificationsApiService extends BaseApiService<INotification, NotificationDTO, NotificationsState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, notificationsState: NotificationsState) {
    super("notifications/", { ...new ApiVersionModel("v1") }, httpClient, appConfig, notificationsState);
  }

  public getNotificationsV2Endpoint(): string {
    return `${this.getBasePath(HttpActions.get, { apiVersionOverwrite: "v2" })}`;
  }
}
