import { Action, Unsubscribe } from "redux";
import { BehaviorSubject, Observable, map, noop } from "rxjs";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { Cache } from "./models/cache.model";

export class ReduxCache<Store, StoreValue, CacheValue = StoreValue> implements Cache<CacheValue> {
  public unsubscribe: Unsubscribe;
  private cache$: BehaviorSubject<StoreValue>;

  constructor(private args: { selector(state: Store): StoreValue; setAction(value: CacheValue): Action; storeToCache?(value: StoreValue): CacheValue }) {
    const { reduxStore } = reduxStoreContainer;

    if (reduxStore) {
      this.cache$ = new BehaviorSubject<StoreValue>(args.selector(reduxStore.getState<Store>()));

      const listener = (): void => {
        const value = args.selector(reduxStore.getState<Store>());
        if (this.cache$.getValue() !== value) {
          this.cache$.next(value);
        }
      };
      this.unsubscribe = reduxStore.subscribe(listener);
    } else {
      // this should not normally happen, but some unit tests might have not initialized the Redux store
      this.cache$ = new BehaviorSubject<StoreValue>(undefined);
      this.unsubscribe = noop;
    }
  }

  public get$(): Observable<CacheValue> {
    return this.cache$.asObservable().pipe(
      map((value) => {
        if (this.args.storeToCache) {
          return this.args.storeToCache(value);
        }
        return value as unknown as CacheValue;
      })
    );
  }

  public set(value: CacheValue): void {
    reduxStoreContainer.reduxStore?.dispatch(this.args.setAction(value));
  }
}
