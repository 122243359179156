import { ErrorHandler, Injectable } from "@angular/core";
import { ApmService } from "@gtmhub/core/tracing/apm.service";

@Injectable()
export class ApmErrorHandler extends ErrorHandler {
  constructor(private apmService: ApmService) {
    super();
  }

  public handleError(error): void {
    this.apmService.captureError(error.originalError || error);
    super.handleError(error);
  }
}
