<button-select
  [model]="order"
  [selected]="selectedOrder"
  [options]="commentsOrderingOptions"
  [hasOptionCheckIcon]="true"
  [iconButton]="true"
  [iconButtonConfig]="{ uiTheme: 'fill', uiType: 'arrows-down-up' }"
  [dropdownStyle]="{ width: '150px' }"
  (modelChange)="onUpdateOrder($event)"
  a11yLabel="Select comments order"></button-select>
