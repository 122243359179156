import { Type } from "@angular/core";
import { INotification, OperationType, TargetType } from "@gtmhub/notifications/models/notifications";
import { KpiProjectionBeatenMissedInboxTemplateComponent } from "@webapp/notifications/components/inbox-templates/kpi-projection-inbox-template/kpi-projection-beaten-missed-inbox-template.component";
import { MetricMilestoneStatusInboxTemplateComponent } from "@webapp/notifications/components/inbox-templates/metric-milestone-status-inbox-template/metric-milestone-status-inbox-template.component";
import { AiRecommendationsInboxTemplateComponent } from "./ai-recommendations-inbox-template/ai-recommendations-inbox-template.component";
import { AnnouncementsInboxTemplateComponent } from "./announcements-inbox-template/announcements-inbox-template.component";
import { BadgeInboxTemplateComponent } from "./badge-inbox-template/badge-inbox-template.component";
import { CfSelectorMentionInboxTemplateComponent } from "./cf-selector-mention-inbox-template/cf-selector-mention-inbox-template.component";
import { CommentInboxTemplateComponent } from "./comment-inbox-template/comment-inbox-template.component";
import { GoalInboxTemplateComponent } from "./goal-inbox-template/goal-inbox-template.component";
import { InboxTemplateComponent } from "./inbox-template.component";
import { KpiInboxTemplateComponent } from "./kpi-inbox-template/kpi-inbox-template.component";
import { KpiProjectionInboxTemplateComponent } from "./kpi-projection-inbox-template/kpi-projection-inbox-template.component";
import { MentionsInboxTemplateComponent } from "./mentions-inbox-template/mentions-inbox-template.component";
import { MetricInboxTemplateComponent } from "./metric-inbox-template/metric-inbox-template.component";
import { MetricSnapshotInboxTemplateComponent } from "./metric-snapshot-inbox-template/metric-snapshot-inbox-template.component";
import { PluginInboxTemplateComponent } from "./plugin-inbox-template/plugin-inbox-template.component";
import { ReactionInboxTemplateComponent } from "./reaction-inbox-template/reaction-inbox-template.component";
import { ReflectionInboxTemplateComponent } from "./reflection-inbox-template/reflection-inbox-template.component";
import { SecondaryUserInboxTemplateComponent } from "./secondary-user-inbox-template/secondary-user-inbox-template.component";
import { ShareItemInboxTemplateComponent } from "./share-item-inbox-template/share-item-inbox-template.component";
import { TaskInboxTemplateComponent } from "./task-inbox-template/task-inbox-template.component";
import { TeamInboxTemplateComponent } from "./team-inbox-template/team-inbox-template.component";
import { WatcherInboxTemplateComponent } from "./watcher-inbox-template/watcher-inbox-template.component";

export const inboxTemplateComponentByTargetType: Record<TargetType, Type<InboxTemplateComponent<INotification>>> = {
  announcements: AnnouncementsInboxTemplateComponent,
  badge: BadgeInboxTemplateComponent,
  comment: CommentInboxTemplateComponent,
  goal: GoalInboxTemplateComponent,
  kpi: KpiInboxTemplateComponent,
  kpi_projection: KpiProjectionInboxTemplateComponent,
  kpi_projection_beaten_or_missed: KpiProjectionBeatenMissedInboxTemplateComponent,
  metric_milestone_status: MetricMilestoneStatusInboxTemplateComponent,
  mentions: MentionsInboxTemplateComponent,
  metric: MetricInboxTemplateComponent,
  metric_snapshot: MetricSnapshotInboxTemplateComponent,
  plugin: PluginInboxTemplateComponent,
  reaction: ReactionInboxTemplateComponent,
  reflection: ReflectionInboxTemplateComponent,
  secondary_user: SecondaryUserInboxTemplateComponent,
  task: TaskInboxTemplateComponent,
  team: TeamInboxTemplateComponent,
  ai_recommendation: AiRecommendationsInboxTemplateComponent,
};

export const inboxTemplateComponentByOperationType: Record<OperationType, Type<InboxTemplateComponent<INotification>>> = {
  share: ShareItemInboxTemplateComponent,
  cf_selector_mentioned: CfSelectorMentionInboxTemplateComponent,
  watcher_added: WatcherInboxTemplateComponent,
  watcher_removed: WatcherInboxTemplateComponent,
};

export const inboxTemplateComponents = [
  AnnouncementsInboxTemplateComponent,
  BadgeInboxTemplateComponent,
  CommentInboxTemplateComponent,
  GoalInboxTemplateComponent,
  KpiInboxTemplateComponent,
  KpiProjectionInboxTemplateComponent,
  KpiProjectionBeatenMissedInboxTemplateComponent,
  MetricMilestoneStatusInboxTemplateComponent,
  MentionsInboxTemplateComponent,
  MetricInboxTemplateComponent,
  MetricSnapshotInboxTemplateComponent,
  PluginInboxTemplateComponent,
  ReactionInboxTemplateComponent,
  ReflectionInboxTemplateComponent,
  SecondaryUserInboxTemplateComponent,
  TaskInboxTemplateComponent,
  ShareItemInboxTemplateComponent,
  CfSelectorMentionInboxTemplateComponent,
  TeamInboxTemplateComponent,
  WatcherInboxTemplateComponent,
  AiRecommendationsInboxTemplateComponent,
];
