<div *ngIf="notification.operationType === 'created'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">{{ "you_were_invited_to_join" | localize: { accountName: notification.notificationData.item.accountName } }}</div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <span class="item">{{ "if_you_havent_received_email_contact_admin" | localize }}</span>
  </div>
</div>

<div *ngIf="notification.operationType === 'accepted'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">{{ "accepted_your_invitation_join" | localize: { names: notification.notificationData.actor.names, accountName: notification.notificationData.item.accountName } }}</div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
</div>

<div *ngIf="notification.operationType === 'declined'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">{{ "declined_your_invitation_join" | localize: { names: notification.notificationData.actor.names, accountName: notification.notificationData.item.accountName } }}</div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
</div>
