import { ChangeDetectionStrategy, Component } from "@angular/core";
import { IWatcherNotification } from "@gtmhub/notifications/models/notifications";
import { InboxTemplateComponent } from "../inbox-template.component";

@Component({
  selector: "watcher-inbox-template",
  templateUrl: "./watcher-inbox-template.component.html",
  styleUrls: ["../inbox-template.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WatcherInboxTemplateComponent extends InboxTemplateComponent<IWatcherNotification> {}
