import { IAttributes, IAugmentedJQuery, IDirective, IDirectiveFactory, INgModelController, IScope, ITranscludeFunction } from "angular";

interface IDropdownItem {
  value?: unknown;
  text: string;
  contents?: string;
  callback?(): void;
  icon?: string;
  ghIcon?: string;
  info?: string;
  css?: string;
  color?: string;
}

export interface IGtmhubDropdownScope extends IScope {
  selectedItem: IDropdownItem;
  selectedValue: unknown;
  disabled: boolean;
  enableSearch: boolean;
  filter: { search: string };
  isOpen?: boolean;
  selectItem(item: IDropdownItem): void;
  formatToggleText(text: string): string;
  onDropdownToggle(args: { isOpen: boolean }): void;
  onChange?(args: { $value: unknown }): void;
}

interface IGtmhubDropdownItemScope extends IScope {
  item: IDropdownItem;
  isActive(): boolean;
  selectItem(): void;
}

class GtmhubDropdownController {
  private items: IDropdownItem[] = [];
  private ngModelCtrl: INgModelController;

  public static $inject = ["$scope"];

  constructor(private $scope: IGtmhubDropdownScope) {
    this.$scope.filter = { search: "" };
  }

  public setNgModelCtrl(ngModelCtrl: INgModelController): void {
    this.ngModelCtrl = ngModelCtrl;
  }

  public selectedValueChangedFromOutside(value: unknown): void {
    if (value !== undefined) {
      const filteredItems = this.items.filter((i) => i.value === value);
      if (filteredItems.length) {
        const item = filteredItems[0];
        this.selectItem(item);
        return;
      }
    }

    this.$scope.selectedItem = null;
  }

  public selectItem(item: IDropdownItem): void {
    if (item.value !== undefined) {
      this.$scope.selectedItem = item;
      this.$scope.selectedValue = item.value;
      this.ngModelCtrl.$setViewValue(item.value);
    }

    if (item.callback) {
      item.callback();
    }
  }

  public enrollItem(item: IDropdownItem): void {
    this.items.push(item);

    if (item.value !== undefined && item.value === this.$scope.selectedValue) {
      this.$scope.selectedItem = item;
    }
  }

  public itemChanged(item: IDropdownItem): void {
    if (item.value !== undefined && item.value === this.$scope.selectedValue) {
      this.$scope.selectedItem = item;
    }
  }

  public removeItem(item: IDropdownItem): void {
    this.items = this.items.filter((i) => i !== item);
  }

  public isActive(item: IDropdownItem): boolean {
    return this.$scope.selectedItem === item;
  }

  public onDropdownToggle(args: { isOpen: boolean }): void {
    this.$scope.isOpen = args.isOpen;
    if (!this.$scope.enableSearch) {
      return;
    }
    if (!args.isOpen) {
      this.$scope.filter.search = "";
    }
  }
}

export class GtmhubDropdown implements IDirective {
  public require = ["gtmhubDropdown", "ngModel"];
  public restrict = "EA";
  public transclude = true;
  public replace = true;
  public template = `
      <div ng-class="{'gh-select': mode == 'legacy', 'gh-select-v2': mode != 'legacy', 'disabled': disabled === true}" uib-dropdown on-toggle="onDropdownToggle({isOpen: open})">
        <div ng-class="{'gh-select-toggle': mode == 'legacy', 'gh-select-toggle-v2': mode != 'legacy'}" uib-dropdown-toggle>
          <input
            ng-if="enableSearch && isOpen"
            ng-click="$event.stopPropagation()"
            ng-model="filter.search"
            ng-change="onSearchValueChange(filter.search)"
            class="search-input"
            placeholder="{{ searchPlaceholder }}"
            focus-me
          />
          <span ng-if="selectedItem.icon" ng-class="'glyphicon icon glyphicon-' + selectedItem.icon"></span>
          <span ng-if="selectedItem.color" class="color-container {{selectedItem.color}}"></span>
          <span ng-if="selectedItem.ghIcon" ng-class="'gh-icon gh-icon-' + selectedItem.ghIcon + ' tiny'"></span>
          <span ng-if="selectedItem" class="selected-item-text">{{ formatToggleText ? formatToggleText({text: selectedItem.text}) : selectedItem.text }}</span>
          <span ng-if="!selectedItem" class="fake-placeholder">{{ placeholder ? placeholder : '&nbsp;' }}</span>
          <span ng-if="mode != 'legacy'" class="gh-font-icon-arrow-down-small"></span>
        </div>
        <div class="dropdown-menu" ng-if="!disabled" uib-dropdown-menu>
          <ul ng-transclude></ul>
        </div>
      </div>
    `;
  public controller = GtmhubDropdownController;
  public scope = {
    selectedValue: "=ngModel",
    mode: "@",
    placeholder: "@",
    formatToggleText: "&?",
    disabled: "=ngDisabled",
    enableSearch: "=?",
    isOpen: "=?",
    searchPlaceholder: "@",
    onSearchValueChange: "<?",
    onChange: "&?",
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public link(scope: IGtmhubDropdownScope, element: IAugmentedJQuery, attrs: IAttributes, ctrls: any[]): void {
    const dropdownCtrl = ctrls[0] as GtmhubDropdownController;
    const ngModelCtrl = ctrls[1] as INgModelController;
    dropdownCtrl.setNgModelCtrl(ngModelCtrl);

    scope.$watch("selectedValue", (newValue: unknown, oldValue: unknown): void => {
      if (newValue === oldValue) {
        return;
      }
      dropdownCtrl.selectedValueChangedFromOutside(newValue);
      if (scope.onChange) {
        scope.onChange({ $value: newValue });
      }
    });

    scope.onDropdownToggle = (args: { isOpen: boolean }) => dropdownCtrl.onDropdownToggle(args);
  }

  public static factory(): IDirectiveFactory {
    const directive = () => new GtmhubDropdown();
    directive.$inject = [];
    return directive;
  }
}

export class GtmhubDropdownItem implements IDirective {
  public require = "^gtmhubDropdown";
  public restrict = "EA";
  public replace = true;
  public transclude = true;
  public scope = true;
  public template = `
      <li ng-click="selectItem()" ng-class="{ 'active': isActive() }">
        <div class="item-container">
          <div ng-if="item.ghIcon" ng-class="'gh-icon gh-icon-' + item.ghIcon + ' tiny'"></div>
          <div class="text-and-info-container">
            <span ng-if="item.color" class="color-container {{ item.color }}"></span>
            <span class="option-text">
            {{ item.text }}
            <span ng-if="item.contents" gh-compile="item.contents"></span>
            </span>
            <span ng-if="item.info" class="option-info">{{ item.info }}</span>
          </div>
        </div>
      </li>
    `;

  public link(scope: IGtmhubDropdownItemScope, element: IAugmentedJQuery, attrs: IAttributes, ctrl: GtmhubDropdownController, transclude: ITranscludeFunction): void {
    const attrsText = attrs.text;
    const item: IDropdownItem = {
      text: attrsText,
      value: scope.$eval(attrs.value),
      icon: attrs.icon,
      ghIcon: attrs.ghIcon,
      color: attrs.color,
      info: attrs.info,
      callback: attrs.callback ? () => scope.$eval(attrs.callback) : null,
    };
    scope.item = item;

    // Since we are using the localize filter
    // there are places where both text and HTML content are used
    // Get the contents of the inner element, which
    // should be the translated text
    transclude(scope, function (clone) {
      if (clone.length > 0) {
        item.text = clone[0].textContent;

        item.contents = "";

        for (let i = 0; i < clone.length; i++) {
          const el = clone[i];
          if (el.textContent === item.text && !attrsText) continue;
          item.contents += el.outerHTML;
        }
      }
      ctrl.enrollItem(item);
    });

    scope.selectItem = (): void => ctrl.selectItem(item);

    scope.isActive = (): boolean => ctrl.isActive(item);

    scope.$watch(attrs.value, (newValue: unknown, oldValue: unknown) => {
      if (newValue !== oldValue) {
        item.value = newValue;
        ctrl.itemChanged(item);
      }
    });

    attrs.$observe<string>("text", (newText: string) => {
      item.text = newText;
      ctrl.itemChanged(item);
    });

    scope.$on("$destroy", () => ctrl.removeItem(item));
  }

  public static factory(): IDirectiveFactory {
    const directive = () => new GtmhubDropdownItem();
    directive.$inject = [];
    return directive;
  }
}
