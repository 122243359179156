import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ItemAutomationSupportedSuggestionModeType, ItemAutomationTargetType } from "../models/item-automation-selector.models";

@Injectable({ providedIn: "root" })
export class SupportedItemAutomationSuggestionTypeService {
  public getSupportedAutomationSuggestionTypeForItemType$(targetType: ItemAutomationTargetType): Observable<ItemAutomationSupportedSuggestionModeType> {
    const supportedSuggestionTypeCheckMap: {
      [ItemType in ItemAutomationTargetType]: () => ReturnType<
        SupportedItemAutomationSuggestionTypeService[Extract<
          keyof SupportedItemAutomationSuggestionTypeService,
          "getSupportedAutomationSuggestionTypeForKr$" | "getSupportedAutomationSuggestionTypeForKpi$"
        >]
      >;
    } = {
      kr: this.getSupportedAutomationSuggestionTypeForKr$.bind(this),
      kpi: this.getSupportedAutomationSuggestionTypeForKpi$.bind(this),
    };

    return supportedSuggestionTypeCheckMap[targetType]();
  }

  // Currently we don't have KPI suggestions but we plan to have them in the future
  public getSupportedAutomationSuggestionTypeForKr$(): Observable<ItemAutomationSupportedSuggestionModeType> {
    return of("insight");
  }

  public getSupportedAutomationSuggestionTypeForKpi$(): Observable<ItemAutomationSupportedSuggestionModeType> {
    return of("insight");
  }
}
