<ng-container *ngIf="comment">
  <div class="new-replies-separator" *ngIf="comment.isFirstUnreadByCurrentUser">
    <div class="separator-content">
      <div class="separator-line"></div>
      <div class="separator-label">{{ "new" | localize }}</div>
    </div>
  </div>

  <div class="comment" #focusableWrapper [class.edited]="isEdited" [attr.aria-description]="mentionedDescription" data-test-id="comment-item" tabindex="0">
    <div class="author" [ngClass]="commentOptionsDropdownPosition">
      <div class="comment-info" [class.clickable]="headerClick.observed" (click)="headerClick.emit()">
        <assignee-details-popover *ngIf="this.isCommentAuthorActive" [assigneeId]="comment.createdBy" assigneeType="user">
          <ui-assignee-avatar class="assignee-avatar" [fromAssigneeId]="comment.createdBy" uiSize="md" aria-hidden="true"></ui-assignee-avatar>
          <div class="details">
            <ui-assignee-name [fromAssigneeId]="comment.createdBy" [deletedAssigneeType]="'user'"></ui-assignee-name>
            <div class="date">
              {{ comment.createdAt | date: "MMMM dd, yyyy, HH:mm" | timeAgo: 24 }}
            </div>
          </div>
        </assignee-details-popover>
        <ng-container *ngIf="!this.isCommentAuthorActive">
          <ui-assignee-avatar class="assignee-avatar" [fromAssigneeId]="comment.createdBy" uiSize="md" aria-hidden="true"></ui-assignee-avatar>
          <div class="details">
            <ui-assignee-name [fromAssigneeId]="comment.createdBy"></ui-assignee-name>
            <div class="date">
              {{ comment.createdAt | date: "MMMM dd, yyyy, HH:mm" | timeAgo: 24 }}
            </div>
          </div>
        </ng-container>
      </div>
      <comment-options-dropdown
        class="sp-ml-8"
        *ngIf="!hideCommentOptionsDropdown"
        [commentAuthorId]="comment.createdBy"
        [disabled]="editorButtonsDisabled"
        (edit)="handleEditorOpenClick()"
        (delete)="deleteClick.emit()"
        aria-hidden="true"></comment-options-dropdown>
    </div>
    <div class="rich-text">
      <rich-text-editor
        *ngIf="comment.text || isEdited"
        [(text)]="comment.text"
        [contentOnly]="!isEdited"
        [gif]="comment.gif"
        [additionalMentions]="comment.mentioned"
        [hidePostButton]="isEdited"
        [showExternalEditorButtons]="isEdited"
        [postButtonA11yLabel]="'post_a_comment' | localize"
        [editorButtonsDisabled]="editorButtonsDisabled"
        [attr.data-test-id]="'update-comment-editor-' + comment.id"
        (keydown.escape)="handleEscapeKeyDown($event)"
        (post)="edit.emit($event)"
        (cancel)="closeEditor()"></rich-text-editor>
      <gif-view *ngIf="!isEdited" [gif]="comment.gif" [gifSize]="sizeOfGif"></gif-view>
      <span class="comment-edited-label" *ngIf="!isEdited && comment.editedBy" data-test-id="comment-edited-label">({{ "edited" | localize }})</span>
    </div>

    <div class="comment-meta" *ngIf="(!hideReactions || !hideMentions) && !isEdited" aria-hidden="true">
      <reactions
        *ngIf="!hideReactions"
        [reactions]="comment.reactions"
        [target]="comment"
        [metadata]="metadata"
        [idField]="idField"
        [targetParentId]="target?.targetParentId"
        (reactionsChange)="handleReactionsChange($event)"
        targetType="comment"></reactions>
      <div class="comment-mentions" *ngIf="!hideMentions && mentioned.length > 0">
        <div class="comment-mention" *ngFor="let mention of mentioned | slice: 0 : 4; let index = index">
          <ui-assignee-avatar
            *ngIf="assignees[mention]"
            [style.z-index]="index"
            [fromAssigneeId]="mention"
            [uiTooltipTitle]="getMentionedMetaTooltipTitle(mention)"
            [uiShowTooltip]="true"
            uiSize="sm"
            uiTooltipPlacement="bottom"></ui-assignee-avatar>
          <i class="seen-icon" *ngIf="comment.seen?.includes(mention)" ui-icon uiTheme="fill" uiType="check-circle"></i>
        </div>
        <ui-assignee-avatar-group [uiMax]="0" uiSize="sm">
          <ui-assignee-avatar *ngFor="let mention of mentioned | slice: 4 : mentioned.length" [fromAssigneeId]="mention"></ui-assignee-avatar>
        </ui-assignee-avatar-group>
      </div>
    </div>
  </div>
</ng-container>
