import { IComponentOptions, IController } from "angular";
import { AccountService } from "@gtmhub/accounts/accounts.service";

class WelcomeModalV1Ctrl implements IController {
  plan: string;
  editionPlan: string;
  close: () => void;
  public static $inject = ["AccountService"];

  constructor(private accountService: AccountService) {}

  $onInit(): void {
    Object.keys(this.plans).forEach((p) => {
      if (this.plan.indexOf(p) > -1) {
        this.editionPlan = p[0].toUpperCase() + p.slice(1);
      }
    });

    this.accountService.setAccountSetting("onboarded", true);
  }

  private plans = {
    start: "Start",
    scale: "Scale",
    summit: "Summit",
    demo: "Demo",
  };
}

export const WelcomeModalV1Component: IComponentOptions = {
  template: require("./welcome-modal-v1.html"),
  controller: WelcomeModalV1Ctrl,
  bindings: {
    close: "&",
    plan: "@",
  },
};
