import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IdMap } from "@gtmhub/util";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { Kpi, KpiDTO, ModifiableKpi } from "../models/kpis.models";
import { KpisApiService } from "./kpis-api.service";
import { KpisState } from "./kpis-state.service";

@Injectable({
  providedIn: "any",
})

// TODO: fix models
export class KpisFacade extends BaseFacade<Kpi, KpiDTO, KpisState, KpisApiService> {
  constructor(state: KpisState, api: KpisApiService) {
    super(state, api);
  }

  public patchKpis$(patchableKpis: IdMap<ModifiableKpi>): Observable<unknown> {
    return this.apiService.patch$(null, { kpis: patchableKpis }, { url: this.apiService.getBasePath(HttpActions.patch) });
  }
}
