import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { highlightElement } from "prismjs";
import "prismjs/components/prism-sql";
import {
  PI_CARD_ACTION_BUTTON_TOOLTIP_CUSTOM_STYLE,
  PI_TEXT_CARD_FONT_COLOR_CLASSES,
  PiTextCardFontColor,
} from "@webapp/platform-intelligence/shared/models/pi-card.models";

@Component({
  selector: "pi-text-subcard",
  templateUrl: "./pi-text-subcard.component.html",
  styleUrls: ["./pi-text-subcard.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None, // necessary because of prismjs style overrides
})
export class PiTextSubcardComponent implements OnInit, AfterViewInit {
  @Input()
  public isIconAvailable = true;
  @Input()
  public fontColor: PiTextCardFontColor = "medium";
  @Input()
  public fontColorOnHover: PiTextCardFontColor = "dark";
  @Input()
  public isHovered: boolean;
  @Input()
  public isSql: boolean;
  @Input()
  public textToDisplay: string | string[];
  @Input()
  public isRemoveBtnAvailable = false;
  @Input()
  public removeBtnTooltipTitle: string;

  @Output()
  public readonly remove = new EventEmitter<void>();

  public isStringText = true;
  public actionBtnBottomRightTooltipCustomStyle = PI_CARD_ACTION_BUTTON_TOOLTIP_CUSTOM_STYLE.bottomRightTooltip;

  @ViewChild("codeEle")
  public codeEle!: ElementRef<HTMLElement>;

  public get fontColorClass(): string {
    if (this.isHovered) {
      return PI_TEXT_CARD_FONT_COLOR_CLASSES[this.fontColorOnHover];
    } else {
      return PI_TEXT_CARD_FONT_COLOR_CLASSES[this.fontColor];
    }
  }

  public ngOnInit(): void {
    this.isStringText = typeof this.textToDisplay === "string";
  }

  public ngAfterViewInit(): void {
    if (this.isSql) {
      highlightElement(this.codeEle.nativeElement);
    }
  }

  public removeBtnClicked(evt: MouseEvent): void {
    evt.preventDefault();
    evt.stopImmediatePropagation();

    this.remove.emit();
  }
}
