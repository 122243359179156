import { NgModule } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiNotificationModule } from "@webapp/ui/notification/notification.module";
import { ErrorNotificationComponent } from "./error-notification.component";

@NgModule({
  imports: [UiIconModule, UiNotificationModule],
  declarations: [ErrorNotificationComponent],
  exports: [ErrorNotificationComponent],
})
export class ErrorNotificationModule {}
