import { localize } from "../localize";

export interface ILocalizeFilter {
  (key: string, templateVariables?: Record<string, unknown>): string;
}

export function LocalizeFilter(): ILocalizeFilter {
  return function (text: string, templateVariables?: Record<string, unknown>): string {
    return localize(text, templateVariables);
  };
}
