import { localize } from "@gtmhub/localization";
import { EffectivenessLowToHighScaleColor, EffectivenessLowToHighScaleIcon, LowToHighScaleMap, LowToHighScaleType } from "../models/quantive-plus-effectiveness.models";

export const effectivenessScoreToLowToHighScale = (effectivenessScore: number | unknown): LowToHighScaleType => {
  if (typeof effectivenessScore !== "number") return;

  // 0 - 40
  if (effectivenessScore < 41) return "low";

  // 41 - 75
  if (effectivenessScore < 76) return "medium";

  // 76 - 100
  return "high";
};

export const lowToHighScaleEffectivenessToLabel = (effectivenessLevel: LowToHighScaleType): string => {
  if (!effectivenessLevel) return;

  const effectivenessLabelMap: LowToHighScaleMap<string> = {
    low: localize("low_cap"),
    medium: localize("medium_cap"),
    high: localize("high_cap"),
  };

  return effectivenessLabelMap[effectivenessLevel];
};

export const lowToHighScaleEffectivenessToColor = (
  effectivenessLevel: LowToHighScaleType,
  params: { checkIfShouldSkipColor(effectivenessLevel: LowToHighScaleType): boolean } = { checkIfShouldSkipColor: () => false }
): EffectivenessLowToHighScaleColor => {
  const shouldSkipColor = params.checkIfShouldSkipColor(effectivenessLevel);
  if (!effectivenessLevel || shouldSkipColor) return;

  const effectivenessColorMap: LowToHighScaleMap<EffectivenessLowToHighScaleColor> = {
    low: "red",
    medium: "yellow",
    high: "green",
  };

  return effectivenessColorMap[effectivenessLevel];
};

export const lowToHighScaleEffectivenessToIcon = (effectivenessLevel: LowToHighScaleType): EffectivenessLowToHighScaleIcon => {
  if (!effectivenessLevel) return;

  const effectivenessIconMap: LowToHighScaleMap<EffectivenessLowToHighScaleIcon> = {
    low: "warn",
    medium: "warn",
    high: "check",
  };

  return effectivenessIconMap[effectivenessLevel];
};
