import { Injectable } from "@angular/core";
import { Observable, switchMap } from "rxjs";
import {
  PIKeyResultDescriptionSuggestion,
  PIKeyResultDescriptionSuggestionPayload,
  PIKrSuggestion,
  PIKrSuggestionPayload,
  PIKrTasksSuggestion,
  PIKrTasksSuggestionPayload,
  PIObjectiveTagsSuggestion,
  PIObjectiveTagsSuggestionPayload,
} from "@webapp/platform-intelligence/shared/models/quantive-plus-suggestions.models";
import { IQuantivePlusSuggestionsFacade } from "./quantive-plus-suggestions-facade.interface";
import { QuantivePlusSuggestionsProviderFactory } from "./quantive-plus-suggestions-provider-factory.service";

@Injectable({
  providedIn: "any",
})
export class QuantivePlusSuggestionsFacade implements IQuantivePlusSuggestionsFacade {
  constructor(private quantivePlusSuggestionsProviderFactory: QuantivePlusSuggestionsProviderFactory) {}

  public getQuantivePlusKrSuggestion(payload: PIKrSuggestionPayload): Observable<PIKrSuggestion> {
    return this.quantivePlusSuggestionsProviderFactory.getInstance("kr").pipe(switchMap((provider) => provider.getQuantivePlusKrSuggestion(payload)));
  }

  public getQuantivePlusObjectiveTagsSuggestion(payload: PIObjectiveTagsSuggestionPayload): Observable<PIObjectiveTagsSuggestion> {
    return this.quantivePlusSuggestionsProviderFactory
      .getInstance("objective-tags")
      .pipe(switchMap((provider) => provider.getQuantivePlusObjectiveTagsSuggestion(payload)));
  }

  public getQuantivePlusKrTasksSuggestion(payload: PIKrTasksSuggestionPayload): Observable<PIKrTasksSuggestion> {
    return this.quantivePlusSuggestionsProviderFactory.getInstance("kr-tasks").pipe(switchMap((provider) => provider.getQuantivePlusKrTasksSuggestion(payload)));
  }

  public getKeyResultDescriptionSuggestion(payload: PIKeyResultDescriptionSuggestionPayload): Observable<PIKeyResultDescriptionSuggestion> {
    return this.quantivePlusSuggestionsProviderFactory.getInstance("kr-description").pipe(switchMap((provider) => provider.getKeyResultDescriptionSuggestion(payload)));
  }
}
