import { ChangeDetectionStrategy, Component } from "@angular/core";
import { localize } from "@gtmhub/localization";
import { IMetricNotification } from "@gtmhub/notifications/models/notifications";
import { InboxTemplateComponent } from "../inbox-template.component";

@Component({
  selector: "metric-inbox-template",
  templateUrl: "./metric-inbox-template.component.html",
  styleUrls: ["../inbox-template.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetricInboxTemplateComponent extends InboxTemplateComponent<IMetricNotification> {
  public get title(): string {
    const metricNotificationDataItem = this.notification.notificationData.item;

    if (!metricNotificationDataItem.assignees || metricNotificationDataItem.assignees.length === 0) {
      return localize("new_kr_inside_your_okr_assigned_to", { names: this.notification.notificationData.actor.names });
    }

    if (metricNotificationDataItem.assignees.length == 1) {
      return localize("new_kr_inside_your_okr_assigned_to_single_owner", { assigneeName: metricNotificationDataItem.assignees[0].names });
    }

    if (metricNotificationDataItem.assignees.length == 2) {
      return localize("new_kr_inside_your_okr_assigned_to_two_owners", { assigneeName: metricNotificationDataItem.assignees[0].names });
    }

    if (metricNotificationDataItem.assignees.length > 2) {
      return localize("new_kr_inside_your_okr_assigned_to_two_plus_owners", {
        assigneeName: metricNotificationDataItem.assignees[0].names,
        numberOfLeftAssignees: metricNotificationDataItem.assignees.length - 1,
      });
    }
  }

  public get isOnlyMilestonesChanged(): boolean {
    const metricChanges = this.notification.notificationData.item.changes;

    const isMilestonesChanged = !!metricChanges.milestones;
    const onlyMilestonesChanges = Object.entries(metricChanges).every(([key, value]) => key === "milestones" || (value.oldValue === null && value.newValue === null));

    return isMilestonesChanged && onlyMilestonesChanges;
  }
}
