import { Injectable } from "@angular/core";
import { IReport } from "@gtmhub/reporting/models";

@Injectable({ providedIn: "root" })
export class NavigationReportsCacheService {
  private cachedReports: IReport[] = null;

  public get(): IReport[] {
    return this.cachedReports;
  }
  public set(value: IReport[]): void {
    this.cachedReports = value;
  }
}
