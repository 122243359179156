import { StateOrName } from "@uirouter/angular";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { NzTooltipTrigger } from "ng-zorro-antd/tooltip";
import { FormPopoverIcon } from "../../models/form.models";

// TODO: add support for popover templates - the title/text/link setup is not scalable
// https://quantive-inc.atlassian.net/browse/GVS-41403
@Component({
  selector: "form-popover-icon",
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPopoverIconComponent implements FormPopoverIcon {
  @Input() public iconType: string;
  @Input() public popoverTitle?: string;
  @Input() public popoverText?: string;
  @Input() public popoverTrigger?: NzTooltipTrigger = "hover";
  @Input() public popoverLink?: {
    linkText: string;
    linkLocation: StateOrName;
    linkParamKey?: string;
    linkParamValue?: string;
  };
  @Input() public a11yTabIndex: number;
  @Input() public a11yLabel: string;
}
