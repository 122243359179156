import { Inject, Injectable } from "@angular/core";
import { APP_CONFIG, IAppConfig } from "../models/app-config.models";

@Injectable({
  providedIn: "root",
})
export class ApiEndpointService {
  public dc: string;
  public envName: string;
  private readonly http: string;
  private readonly ws: string;
  private readonly api: string;
  private readonly legacyApiEndpoint: string;
  private readonly webSocketsApi: string;
  private readonly quantivePlusApi: string;

  constructor(@Inject(APP_CONFIG) appConfig: IAppConfig) {
    const { endpoints, env } = appConfig;

    this.api = endpoints.apiEndpoint;
    this.legacyApiEndpoint = endpoints.legacyApiEndpoint;
    this.webSocketsApi = endpoints.webSocketsApiEndpoint;
    this.quantivePlusApi = endpoints.quantivePlusApi;
    this.http = endpoints.secure ? "https" : "http";
    this.ws = endpoints.secure ? "wss" : "ws";
    this.dc = env.dc;
    this.envName = env.name;
  }

  public format(path?: string): string {
    return path ? (path[0] === "/" ? path.substr(1) : path) : "";
  }

  public isApiRequest(url: string): boolean {
    return (
      url.startsWith(`${this.http}://${this.api}`) ||
      url.startsWith(`${this.http}://${this.legacyApiEndpoint}`) ||
      url.startsWith(`${this.ws}://${this.webSocketsApi}`) ||
      url.startsWith(`${this.http}://${this.quantivePlusApi}`)
    );
  }

  public getWSEndpoint(path?: string): string {
    return `${this.ws}://${this.webSocketsApi}/v2/sockets/${this.format(path)}`;
  }

  public getWbSocketsEndpoint(path?: string): string {
    return `${this.ws}://${this.webSocketsApi}/v1/wbws/${this.format(path)}`;
  }

  public getInsightsSocketsEndpoint(path?: string): string {
    return `${this.ws}://${this.webSocketsApi}/v1/insights-ws/${this.format(path)}`;
  }
}
