import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ICollection } from "@webapp/core/core.models";
import { SubNavOption } from "@webapp/navigation/models/sub-nav-options.models";
import { INavItem, NavItemsSectionBroadcastConfig, NavItemsType } from "../../../../models/nav-items-list.models";
import { NavItemsSectionBroadcastConfigFactory } from "../services/nav-items-section-broadcast-config.factory";

@Component({
  selector: "default-sub-navigation",
  templateUrl: "./default-sub-navigation.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultSubNavigationComponent {
  @Input()
  public options: SubNavOption[];
  @Input()
  public itemsType: NavItemsType;
  @Input()
  public favorites: ICollection<INavItem>;
  @Input()
  public suggested: ICollection<INavItem>;
  @Input()
  public recent: ICollection<INavItem>;
  @Input()
  public loading: boolean;
  @Input()
  public broadcastConfig: NavItemsSectionBroadcastConfig = this.broadcastConfigFactory.buildDefaultNavItemsSectionBroadcastConfig();
  @Output()
  public readonly markItemAsFavorite = new EventEmitter<INavItem>();
  @Output()
  public readonly unmarkItemAsFavorite = new EventEmitter<INavItem>();
  @Output()
  public readonly removeFromSuggested = new EventEmitter<INavItem>();
  public visibleItemsCount = 5;

  public constructor(private broadcastConfigFactory: NavItemsSectionBroadcastConfigFactory) {}
}
