import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

const errorMessage = "FeatureTogglesFacade should not be used directly but rather mapped to a concrete implementation.";

@Injectable()
export class FeatureTogglesFacade {
  public isInitialized(): boolean {
    throw new Error(errorMessage);
  }

  public initialize(): void {
    throw new Error(errorMessage);
  }

  // This method is only temporary here,
  // since we have a lot of places where we use AJS FeatureToggleService.
  // TODO: It will be easier to refactor all the code to use the A12 Facade.
  // Once everything is migrated, it will be removed.
  /**
   * @deprecated This method should not be used. Use isFeatureAvailable$ instead
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public isFeatureAvailable(featureKey: string): Promise<boolean> {
    throw new Error(errorMessage);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public isFeatureAvailable$(featureKey: string): Observable<boolean> {
    throw new Error(errorMessage);
  }
}
