import { HttpErrorResponse } from "@angular/common/http";
import { AfterContentInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiThemeType } from "@quantive/ui-kit/icon";
import { Subject, take } from "rxjs";
import { ParentType } from "@gtmhub/automation/controllers/action";
import { PIFeedbackSuggestionSubType, PIFeedbackSuggestionType } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/feedback/models";
import { PiImprovementFacade } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/improvement/pi-improvement-facade.service";
import { generateImprovementPayload } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/improvement/utils";
import {
  PIStateProcessorInstanceSubType,
  PiStateProcessorInstance,
} from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.models";
import { PiStateProcessorService } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.service";
import { PiSuggestionSharedGoalStateService } from "@webapp/platform-intelligence/shared/services/pi-suggestion-shared-goal-state.service";
import { PiSuggestionSharedMetricStateService } from "@webapp/platform-intelligence/shared/services/pi-suggestion-shared-metric-state.service";
import { getPiErrorData } from "@webapp/platform-intelligence/shared/utils/pi-errors.utils";
import { PiTrackingFlowNameEnum } from "@webapp/platform-intelligence/shared/utils/pi-tracking";
import { SuggestionDrawerSuggestionGroupType } from "../../models/suggestion-drawer.models";

@UntilDestroy()
@Component({
  selector: "suggestion-drawer-suggestion-group-card",
  templateUrl: "./suggestion-drawer-suggestion-group-card.component.html",
  styleUrls: ["./suggestion-drawer-suggestion-group-card.component.less"],
})
export class SuggestionDrawerSuggestionGroupCardComponent implements OnInit, AfterContentInit, OnChanges, OnDestroy {
  @Input() public groupTitle: string;
  @Input() public iconType: string;
  @Input() public iconTheme: UiThemeType;
  @Input() public suggestionGroup: SuggestionDrawerSuggestionGroupType;
  @Input() public suggestionCardDataTestId: string;
  @Input() public errorCardDataTestId: string;
  @Input() public noSuggestionReasonCardDataTestId: string;
  @Input() public parentType: ParentType = "goal";
  @Input() public suggestionFeedbackType: PIFeedbackSuggestionType;
  @Input() public suggestionFeedbackSubType: PIFeedbackSuggestionSubType;
  @Input() public subType: PIStateProcessorInstanceSubType;
  @Input() public flowId: string;
  @Input() public flowName: PiTrackingFlowNameEnum;

  public titleInstance: PiStateProcessorInstance;
  public parent;
  public onDestroy$ = new Subject<void>();

  @Output() public readonly suggestionAccept = new EventEmitter();
  @Output() public readonly suggest = new EventEmitter();
  @Output() public readonly retry = new EventEmitter();
  @Output() public readonly goBackToSuggestion = new EventEmitter();

  constructor(
    private piStateProcessorService: PiStateProcessorService,
    private piSuggestionSharedGoalStateService: PiSuggestionSharedGoalStateService,
    private piSuggestionSharedMetricStateService: PiSuggestionSharedMetricStateService,
    private piImprovementFacade: PiImprovementFacade,
    private cd: ChangeDetectorRef
  ) {}

  public ngAfterContentInit(): void {
    if (this.flowId && this.flowName) {
      this.piStateProcessorService.assigneInstanceFlowProperties(this.titleInstance.id, { flowId: this.flowId, flowName: this.flowName });
    }
  }

  public ngOnInit(): void {
    this.titleInstance = this.piStateProcessorService.createNewInstance({
      type: this.parentType,
      subType: this.subType,
      isInline: false,
      iconType: this.iconType,
      iconTheme: this.iconTheme,
      improveSuggestionContext: this.suggestionGroup.improveSuggestionContext,
      destroyOn$: this.onDestroy$,
    });

    this.subscribeToDescriptionSubject();
    this.subscribeToDescriptionImprovement();

    this.getParent();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.suggestionGroup && changes.suggestionGroup.currentValue && this.titleInstance) {
      this.piStateProcessorService.updateInstanceImproveSuggestionContext(this.titleInstance.id, changes.suggestionGroup.currentValue.improveSuggestionContext);
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  private getParent(): void {
    if (this.parentType === "goal") {
      this.piSuggestionSharedGoalStateService
        .getGoal$()
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (goal) => {
            if (!goal) {
              return;
            }

            this.parent = goal;
          },
        });
    }

    if (this.parentType === "metric") {
      this.piSuggestionSharedMetricStateService
        .getMetric$()
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (metric) => {
            if (!metric) {
              return;
            }

            this.parent = metric;
          },
        });
    }
  }

  private subscribeToDescriptionSubject(): void {
    this.titleInstance.subject.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data.suggestAnother) {
        this.suggestAnother();
      }

      if (data.refreshError) {
        this.handleRetry();
      }

      if (data.addSuggestion) {
        this.acceptSuggestion({ id: data.suggestion.id, suggestionText: data.suggestion.text });
      }

      if (data.goBackToSuggestion) {
        this.handleGoBackToSuggestion();
      }
    });
  }

  private subscribeToDescriptionImprovement(): void {
    this.titleInstance.replacementSuggestionSubject.pipe(untilDestroyed(this)).subscribe((data) => {
      const suggestion = this.suggestionGroup.suggestions.find((suggestion) => suggestion.id === data.suggestion.id);

      suggestion.id = data.newSuggestion.id;
      suggestion.suggestionText = data.newSuggestion.text;

      this.cd.detectChanges();
    });

    this.titleInstance.improvementSubject.pipe(untilDestroyed(this)).subscribe((data) => {
      const payload = generateImprovementPayload({
        subEntityType: this.titleInstance.subType,
        instruction: data.improvement,
        suggestion: {
          title: data.suggestion.text,
        },
        isAskAI: true,
        improveSuggestionContext: this.titleInstance.improveSuggestionContext,
      });

      this.suggestionGroup.isLoading = true;

      this.piImprovementFacade
        .postImprovement<{ id: string; title: string }>(payload)
        .pipe(take(1))
        .subscribe({
          next: (improvement) => {
            const suggestion = this.suggestionGroup.suggestions.find((suggestion) => suggestion.id === data.suggestion.id);

            suggestion.id = improvement.suggestions.improvement.id;
            suggestion.suggestionText = improvement.suggestions.improvement.title;

            this.suggestionGroup.isLoading = false;

            this.cd.detectChanges();
          },
          error: (err: HttpErrorResponse) => {
            this.suggestionGroup.errorData = getPiErrorData(this.subType, err);

            this.suggestionGroup.isLoading = false;
            this.suggestionGroup.hasError = true;
            this.cd.detectChanges();
          },
        });
    });
  }

  public acceptSuggestion = (suggestion: { id: string; suggestionText: string | string[] }): void => {
    this.suggestionAccept.emit(suggestion);
  };

  public suggestAnother = (): void => {
    this.suggest.emit();
  };

  public handleRetry = (): void => {
    this.retry.emit();
  };

  public handleGoBackToSuggestion = (): void => {
    this.goBackToSuggestion.emit();
  };
}
