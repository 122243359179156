import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import jwtDecode from "jwt-decode";
import { Observable, from, mergeMap } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthenticationResolverService, getAccessToken } from "@gtmhub/auth";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private jwtHelper: JwtHelperService;

  constructor(
    private apiEndpointService: ApiEndpointService,
    private authenticationResolverService: AuthenticationResolverService
  ) {
    this.jwtHelper = new JwtHelperService();
  }

  public intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.apiEndpointService.isApiRequest(request.url)) {
      return next.handle(request);
    }

    const accessToken = getAccessToken();
    if (!accessToken) {
      return next.handle(request);
    }

    const decodedToken = jwtDecode<{ iat: number; exp: number }>(accessToken);

    // If token is expired, wait for a new one
    if (this.jwtHelper.isTokenExpired(accessToken)) {
      return from(this.authenticationResolverService.refreshToken({ blocking: true })).pipe(
        mergeMap(() => next.handle(request)),
        catchError(() => next.handle(request))
      );
    }

    // If token is at half its duration, refresh in the background
    const tokenHalfTimeExpiration = (decodedToken.exp - decodedToken.iat) / 2;
    if (this.jwtHelper.isTokenExpired(accessToken, tokenHalfTimeExpiration)) {
      this.authenticationResolverService.refreshToken({ blocking: false }).catch(() => null);
    }

    return next.handle(request);
  }
}
