/**
 * Adds a space-separated value to an exsiting attribute value of a provided HTML element.
 */
export const addAttributeValue = ({ element, attribute, value }: { element: Element; attribute: string; value: string }): void => {
  if (!element || !attribute || !value) {
    return;
  }

  const existingAttributeValues = (element.getAttribute(attribute) || "").split(" ");
  const newValues = new Set([...existingAttributeValues, value].filter(Boolean));

  if (newValues.size > 0) {
    const spaceSeparatedValues = Array.from(newValues).join(" ");
    element.setAttribute(attribute, spaceSeparatedValues);
  }
};

/**
 * Removes a space-separated value from an exsiting attribute value of a provided HTML element.
 */
export const removeAttributeValue = ({ element, attribute, value }: { element: Element; attribute: string; value: string }): void => {
  if (!element || !attribute || !value) {
    return;
  }

  const attributeValues = (element.getAttribute(attribute) || "").split(" ").filter((existingValue) => Boolean(existingValue) && existingValue !== value);

  if (attributeValues.length > 0) {
    element.setAttribute(attribute, attributeValues.join(" "));
  } else {
    element.removeAttribute(attribute);
  }
};

export function appendStylesIfMissing(styles: string, id: string): void {
  const existingStylesEl = document.head.querySelector(`style#${id}`);
  if (!existingStylesEl) {
    const stylesEl = document.createElement("style");
    stylesEl.id = id;
    stylesEl.textContent = styles;
    document.head.appendChild(stylesEl);
  }
}

export function removeStylesIfPresent(id: string): void {
  const stylesEl = document.head.querySelector(`style#${id}`);
  if (stylesEl) {
    stylesEl.remove();
  }
}
