<div class="mentions-editor" *ngIf="removable?.length > 0 || nonRemovable?.length > 0">
  <div class="label-container">
    <span class="target-notifications">{{ "users_will_notified" | localize }}</span>
    <button *ngIf="removable?.length > 0" [attr.aria-label]="removeAllMentionsAriaLabel" (click)="removeAll.emit()" ui-button uiType="link" uiSize="small">({{ "remove_all" | localize }})</button>
  </div>
  <div class="mentions-list">
    <div class="mention-list-item removable" *ngFor="let mentionId of removable | slice: 0 : limit; let index = index">
      <ui-assignee-avatar
        [uiShowTooltip]="true"
        [style.z-index]="index"
        [fromAssigneeId]="mentionId"
        [ghTrackMeta]="{ button_name: 'remove_person_from_comment_notification' }"
        [deletedAssigneeType]="'user'"
        (click)="removeOne.emit(mentionId)"
        uiSize="sm"
        ghTrack="Button Clicked"></ui-assignee-avatar>
    </div>
    <ui-assignee-avatar
      class="mention-list-item"
      *ngFor="let mentionId of nonRemovable; let index = index"
      [style.z-index]="index + limit"
      [uiShowTooltip]="true"
      [uiTooltipTitle]="'to_remove_comment' | localize"
      [fromAssigneeId]="mentionId"
      [deletedAssigneeType]="'user'"
      uiSize="sm"></ui-assignee-avatar>
    <ui-assignee-avatar-group [uiMax]="0" [class.cursor-pointer]="limit < 20" [style.z-index]="limit + (nonRemovable || []).length" (click)="limit = 20" uiSize="sm">
      <ui-assignee-avatar *ngFor="let mention of removable | slice: limit : removable.length" [fromAssigneeId]="mention"></ui-assignee-avatar>
    </ui-assignee-avatar-group>
  </div>
</div>
