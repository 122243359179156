import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { FeatureModuleService } from "@webapp/feature-toggles/services/feature-module.service";
import { Gif, GifDataParams, GifResponse, GifSize, GifType, SizeOfGif } from "@webapp/shared/components/gifs/gif.models";
import { TenorGifService } from "@webapp/shared/components/gifs/tenor-gif.service";
import { RichTextEditorIconEnum } from "@webapp/shared/rich-text-editor/rich-text-editor-icon/rich-text-editor-icon.enum";

@UntilDestroy()
@Component({
  selector: "add-gif",
  templateUrl: "./add-gif.component.html",
  styleUrls: ["./add-gif.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddGIFComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public outOfFocus: boolean;

  @Output() public readonly gifSelected = new EventEmitter<Gif>();
  @Output() public readonly selectorOpen = new EventEmitter<MouseEvent>();
  @Output() public readonly selectorClose = new EventEmitter<MouseEvent>();

  public searchQuery = "";
  public gifs: Gif[];
  public showGifMenu = false;
  public checkinDivId: string;
  public gifIcon = RichTextEditorIconEnum.GIF;
  public isModuleGifsEnabled$: Observable<boolean>;

  private removeClickListener: () => void;

  constructor(
    private elementRef: ElementRef,
    private featureModuleService: FeatureModuleService,
    private renderer: Renderer2,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private gifService: TenorGifService
  ) {}

  public ngOnInit(): void {
    this.isModuleGifsEnabled$ = this.featureModuleService.isGifsModuleEnabled$();
  }

  public ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.removeClickListener = this.renderer.listen("document", "click", (event: PointerEvent) => {
        if (!this.elementRef.nativeElement.contains(event?.target)) {
          this.ngZone.run(() => this.closeGifSearchModal(event));
        }
      });
    });
  }

  public ngOnDestroy(): void {
    if (this.removeClickListener) {
      this.removeClickListener();
      this.removeClickListener = null;
    }
  }

  public selectGif(gif: Gif, event: MouseEvent): void {
    gif.searchQuery = this.searchQuery;

    this.gifSelected.emit(gif);

    this.closeGifSearchModal(event);
  }

  public searchGifs(): void {
    this.getGifUrls("search", SizeOfGif.Small, this.searchQuery);
  }

  public toggleGifSearchModal(event?: MouseEvent): void {
    if (this.showGifMenu) {
      this.closeGifSearchModal(event);
    } else {
      this.getGifUrls("trending", SizeOfGif.Small);
      this.openGifSearchModal(event);
    }
  }

  public closeGifSearchModal(event?: MouseEvent): void {
    this.showGifMenu = false;
    this.searchQuery = "";

    this.selectorClose.emit(event);
    this.cdr.detectChanges();
  }

  private openGifSearchModal(event?: MouseEvent): void {
    this.showGifMenu = true;

    this.selectorOpen.emit(event);
  }

  private getGifUrls(gifType: GifType, gifSize: GifSize, searchQuery = ""): void {
    const params: GifDataParams = {
      gifType,
      gifSize,
      searchQuery,
    };

    this.gifService
      .getGifData(params)
      .pipe(untilDestroyed(this))
      .subscribe((response: GifResponse[]) => {
        this.gifs = response.map(
          (gifResponse: GifResponse): Gif => ({
            id: gifResponse.id,
            url: gifResponse.media.url,
          })
        );
        this.cdr.detectChanges();
      });
  }
}
