import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UiThemeType } from "@quantive/ui-kit/icon";
import { PI_ICON_COLOR_CLASSES, PiIconColor } from "@webapp/platform-intelligence/shared/models/pi-card.models";

@Component({
  selector: "pi-card-icon",
  templateUrl: "./pi-card-icon.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiCardIconComponent {
  @Input()
  public iconType: string;
  @Input()
  public iconTheme: UiThemeType;
  @Input()
  public iconColor: PiIconColor;
  @Input()
  public iconColorOnHover: PiIconColor;
  @Input()
  public isHovered: boolean;

  get iconColorClass(): string {
    if (this.iconColor && !this.isHovered) {
      return PI_ICON_COLOR_CLASSES[this.iconColor];
    }

    if (this.iconColorOnHover && this.isHovered) {
      return PI_ICON_COLOR_CLASSES[this.iconColorOnHover];
    }
  }
}
