import { AfterContentInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { UiSizeLDSType } from "@quantive/ui-kit/core";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { LocalizedFormat } from "@webapp/localization/models/localization.models";
import { shouldDisableMaxMinDate } from "@webapp/shared/utils/date";
import { UiDateMaxMin } from "@webapp/ui/date-picker/date-picker.models";

/**
 * @deprecated This component is deprecated and it is used only in old AngularJS components. Please use UIDatePicker.
 */
@Component({
  selector: "date-picker-legacy",
  exportAs: "datePickerLegacy",
  templateUrl: "./date-picker-legacy.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerLegacyComponent implements AfterContentInit, OnChanges {
  @Input() @InputBoolean() public isRange = false;
  @Input() public date: Date;
  @Input() public defaultValueToday: boolean;
  @Input() public dateRange: { startDate: Date; endDate: Date } = {
    startDate: undefined,
    endDate: undefined,
  };
  @Input() public dateFormat: LocalizedFormat = "shortDate";
  @Input() @InputBoolean() public open = false;
  @Input() public disabledDateOptions: UiDateMaxMin;
  @Input() @InputBoolean() public disabled = false;
  @Input() public disabledDate?: (d: Date) => boolean;
  @Input() @InputBoolean() public borderless = false;
  @Input() @InputBoolean() public inline = false;
  @Input() @InputBoolean() public treatUtcAsLocal = false;
  @Input() public a11yLabelledby: string;
  @Input() @InputBoolean() public a11yRequired = false;
  @Input() public size: UiSizeLDSType = "large";
  @Input() @InputBoolean() public allowClear = false;
  @Input() @InputBoolean() public backdrop = true;
  @Input() @InputBoolean() public hideSuffix = false;

  @Output() public readonly dateChange = new EventEmitter<string>();
  @Output() public readonly openStateChanged = new EventEmitter<boolean>();

  public datePickerModel: Date;
  public rangePickerModel: Date[] = [];

  public isDateDisabled = (date: Date): boolean => {
    if (this.disabledDateOptions?.maxDate || this.disabledDateOptions?.minDate) {
      const dateOptions: UiDateMaxMin = { maxDate: this.disabledDateOptions.maxDate, minDate: this.disabledDateOptions.minDate };
      return shouldDisableMaxMinDate(date, dateOptions);
    } else if (this.disabledDate) {
      return this.disabledDate(date);
    } else {
      return false;
    }
  };

  public ngAfterContentInit(): void {
    if (this.defaultValueToday && !this.date) {
      this.date = new Date();
    }

    this.formatDateModel();
  }

  private formatDateModel(): void {
    if (this.isRange) {
      this.formatDateRange();
    } else {
      this.formatDate();
    }
  }

  private formatDate(): void {
    if (!this.date || this.date.toString() === "Invalid Date") {
      this.datePickerModel = null;
    } else if (this.treatUtcAsLocal) {
      const date = new Date(this.date);
      const tzOffset = date.getTimezoneOffset() * 60000;
      this.datePickerModel = new Date(date.getTime() + tzOffset);
    } else {
      this.datePickerModel = this.date;
    }
  }

  private formatDateRange(): void {
    if (this.dateRange.startDate) {
      this.rangePickerModel[0] = this.dateRange.startDate;
    }
    if (this.dateRange.endDate) {
      this.rangePickerModel[1] = this.dateRange.endDate;
    }
  }

  public dateRangeChanged($event: string): void {
    const dates = JSON.parse($event);
    this.dateRange.startDate = dates[0];
    this.dateRange.endDate = dates[1];
    this.dateChange.emit($event);
  }

  public ngOnChanges(): void {
    // Date is already changed by the parent component, but we need to update the model
    this.formatDateModel();
  }
}
