import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { byTextField } from "@gtmhub/util/sorting-utils";
import { AccountResolverService } from "@webapp/accounts";
import { MultiAccount, UserMultiAccountInvitationType } from "@webapp/accounts/models/multi-account/multi-account.models";
import { MultiAccountsFacade } from "@webapp/accounts/services/multi-account/multi-account-facade.service";
import { UI_MODAL_DATA } from "@webapp/ui/modal/modal.models";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";

@Component({
  selector: "account-picker",
  templateUrl: "./account-picker.component.html",
  styleUrls: ["./account-picker.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountPickerComponent implements OnInit {
  public accounts: MultiAccount[];
  public approvedAccounts: MultiAccount[];
  public currentAccountId: string;
  public currentUserEmail: string;

  constructor(
    private accountResolverService: AccountResolverService,
    private profileService: UserProfileService,
    private multiAccountFacade: MultiAccountsFacade,
    @Inject(UI_MODAL_DATA) modalData: MultiAccount[]
  ) {
    this.accounts = modalData;
  }

  public ngOnInit(): void {
    this.currentAccountId = this.accountResolverService.getAccountId();
    this.currentUserEmail = this.profileService.getProfile().email;

    this.approvedAccounts = (this.accounts || [])
      .filter((account) => !account.invitation || (account.invitation && account.invitation.status === UserMultiAccountInvitationType.approved))
      .sort(byTextField("accountName"));
  }

  public switchAccount(account: MultiAccount): void {
    this.multiAccountFacade.switchUserAccount({ account, currentAccountId: this.currentAccountId, currentUserEmail: this.currentUserEmail });
  }

  public isAccountCurrent(accountId: string): boolean {
    return accountId === this.currentAccountId;
  }
}
