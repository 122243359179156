import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import { identity } from "rxjs";
import { CfMap, CustomFieldTargetType, ICustomField } from "@webapp/custom-fields/models/custom-fields.models";
import { FormItemBaseComponent } from "../form-item-base.component";

@Component({
  selector: "custom-fields-form-item",
  templateUrl: "./custom-fields-form-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FormItemBaseComponent, useExisting: forwardRef(() => CustomFieldsFormItemComponent) }],
})
export class CustomFieldsFormItemComponent extends FormItemBaseComponent<CfMap> {
  @Input() public filter: (customFields: ICustomField[]) => ICustomField[] = identity;
  @Input() public target: CustomFieldTargetType;
  @Input() public isFieldDisabled: (fieldName: string) => boolean = () => false;
  @Input() public isFieldReadonly: (fieldName: string) => boolean = () => false;
  @Input() public updateOnBlur: boolean;
  @Input() public disableRequired: boolean;
  @Input() public formName: string;

  /**
   * The form renders a gap between this form item and the rest of the form items.
   * When the given `filter`, however, returns no related custom fields to the resource,
   * the form would still leave the gap between this empty row and the rest of the form items.
   * This provides a mechanism to track if the editable-custom-fields beneath actually renders anything or not.
   */
  public hasCustomFields = false;

  public get customFieldsItemFormName(): string {
    const typography = this.form?.typography || {};

    const labelEditorGap = typography.labelEditorGap || "medium";
    const labelEditorGapClass = `custom-field-form-item-label-editor-gap-${labelEditorGap}`;

    const rowsGap = typography.rowsGap || "small";
    const rowsGapClass = `custom-field-form-item-rows-gap-${rowsGap}`;

    const labelWidth = typography.labelWidth || "medium";
    const labelWidthClass = `custom-field-form-item-label-width-${labelWidth}`;

    const labelFontSize = typography.primaryLabelFontSize || "medium";
    const labelSizeClass = `custom-field-form-item-label-size-${labelFontSize}`;

    const labelFontWeight = typography.primaryLabelFontWeight || "regular";
    const labelWeightClass = `custom-field-form-item-label-weight-${labelFontWeight}`;

    const labelPosition = typography.labelPosition || "left";
    const labelPositionClass = `custom-field-form-item-label-position-${labelPosition}`;

    return [this.formName, "custom-field-form-item", labelEditorGapClass, rowsGapClass, labelWidthClass, labelSizeClass, labelWeightClass, labelPositionClass].join(" ");
  }

  public handleCustomFieldsRequiredChange(change: { areFilled: boolean }): void {
    const error = change.areFilled ? null : { required: true };

    // sets the initial validity state before the first value change
    this.formControl?.setErrors(error);

    // sets the validity state for when value change is emitted (manually set errors get cleared without a validator on value change)
    this.formControl?.setValidators(() => error);
  }

  public handleCustomFieldsLoaded(customFields: ICustomField[]): void {
    this.hasCustomFields = customFields?.length > 0;
    this.changeDetector.markForCheck();
  }
}
