<form
  class="form-content"
  [id]="formId"
  [formGroup]="formGroup"
  [ngClass]="{
    'form-item-rows-gap-small': !typography?.rowsGap || typography?.rowsGap === 'small',
    'form-item-rows-gap-medium': typography?.rowsGap === 'medium',
    'form-item-rows-gap-large': typography?.rowsGap === 'large',
  }">
  <div
    class="form-item-row"
    *ngFor="let item of formItemDescriptors; trackBy: formItemsTrackBy"
    [ngClass]="{
      'label-top': typography?.labelPosition === 'top',
      'form-item-label-editor-gap-none': typography?.labelEditorGap === 'none',
      'form-item-label-editor-gap-small': typography?.labelEditorGap === 'small',
      'form-item-label-editor-gap-medium': !typography?.labelEditorGap || typography?.labelEditorGap === 'medium',
      'form-item-label-editor-gap-large': typography?.labelEditorGap === 'large',
    }"
    [hidden]="(item | isCustomFieldsFormItem) && !item.hasCustomFields"
    data-test-id="form-item-row">
    <ng-container *ngIf="!(item | isCustomFieldsFormItem)">
      <!-- label column -->
      <form-label
        *ngIf="!item.hideLabelColumn"
        [labelTooltip]="item.labelTooltip"
        [controlId]="item.controlId"
        [id]="item.labelId"
        [labelText]="item.labelText"
        [required]="item.required && !!item.labelText"
        [typography]="typography"
        [secondaryLabelText]="item.secondaryLabelText"></form-label>
      <!-- editor loading indicator -->
      <ui-loading-indicator class="form-item-loading-indicator" *ngIf="item.loading" [uiTip]="'loading' | localize"></ui-loading-indicator>
      <!-- editor column -->
      <div class="form-item-editor-section" *ngIf="!item.loading" [class.show-clear-icon-on-hover]="shouldShowClearValueIconOnHoverOnly(item)">
        <!-- editor and hints -->
        <div
          class="form-item-editor-column"
          [style.maxWidth.px]="typography?.editorWidth"
          [class.empty-icon-container]="!item.popoverIcon && !item.tooltipIcon && !item.clearValueIcon && formHasIcons">
          <div class="form-item-editor-content">
            <ng-template *ngTemplateOutlet="item.template"></ng-template>
          </div>
          <form-item-validation-hint *ngIf="!item.handleValidationHints" [form]="formGroup" [formItemDescriptor]="item"></form-item-validation-hint>
        </div>
        <!-- custom template inserted right after the item.template -->
        <div class="form-item-editor-custom-content-sibling" *ngIf="item.customContentSibling">
          <ng-template *ngTemplateOutlet="item.customContentSibling?.templateRef"></ng-template>
        </div>
        <!-- action icon/icon with popover -->
        <i class="form-item-clear-icon" *ngIf="item.clearValueIcon" (click)="handleClearValueIconClick(item.formControl)" ui-icon uiType="trash-bin"></i>
        <i
          class="form-item-tooltip-icon"
          *ngIf="item.tooltipIcon"
          [ngClass]="{ 'position-end': item.tooltipIcon.position === 'end' || null }"
          [uiTooltipTitle]="item.tooltipIcon.content"
          [uiRotate]="0"
          [uiType]="item.tooltipIcon.iconType"
          ui-icon
          ui-tooltip
          uiTheme="outline"
          uiTooltipPlacement="right"></i>
        <icon-with-popover
          class="form-item-popover-icon"
          *ngIf="item.popoverIcon"
          [title]="item.popoverIcon.popoverTitle"
          [content]="item.popoverIcon.popoverText"
          [trigger]="item.popoverIcon.popoverTrigger"
          [ghIconName]="item.popoverIcon.iconType"
          [linkText]="item.popoverIcon.popoverLink?.linkText"
          [linkLocation]="item.popoverIcon.popoverLink?.linkLocation"
          [linkParamKeys]="item.popoverIcon.popoverLink?.linkParamKey"
          [linkParamValue]="item.popoverIcon.popoverLink?.linkParamValue"
          [a11yTabIndex]="item.popoverIcon.a11yTabIndex"
          [a11yLabel]="item.popoverIcon.a11yLabel"></icon-with-popover>
      </div>
      <div
        class="a11y-visually-hidden"
        #descriptionSection
        *ngIf="item.hints?.length > 0 || (item.formControl?.dirty && item.formControl?.errors | firstErrorMessage: item.errorMessages) || item.popoverIcon || item.tooltipIcon"
        [id]="item.descriptionId">
        <ng-container *ngIf="item.hints?.length > 0 && (!(item.formControl?.errors | firstErrorMessage: item.errorMessages) || item.formControl?.pristine)">
          <div *ngFor="let hint of item.hints" removeInteractableAttributes>
            <ng-container *uiStringTemplateOutlet="hint.content">{{ hint.content }}</ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="item.formControl?.dirty && item.formControl?.errors | firstErrorMessage: item.errorMessages">
          {{ item.errorMessages[item.formControl?.errors | firstErrorMessage: item.errorMessages] }}
        </ng-container>
        <ng-container *ngIf="item.popoverIcon">{{ item.popoverIcon.popoverTitle }} {{ item.popoverIcon.popoverText }}</ng-container>
        <div *ngIf="item.tooltipIcon" removeInteractableAttributes>
          <ng-container *uiStringTemplateOutlet="item.tooltipIcon.content">{{ item.tooltipIcon.content }}</ng-container>
        </div>
      </div>
    </ng-container>
    <!-- editable-custom-fields are rendered without a label, hints or icons - their rendering is handled by another component -->
    <div class="form-editable-custom-fields-section" *ngIf="item | isCustomFieldsFormItem" [class.empty-icon-container]="formHasIcons">
      <ng-template *ngTemplateOutlet="item.template"></ng-template>
    </div>
  </div>
</form>
