import { NgModule } from "@angular/core";
import { UiListEmptyComponent } from "./components/list-empty/list-empty.component";
import { UiListFooterComponent } from "./components/list-footer/list-footer.component";
import { UiListHeaderComponent } from "./components/list-header/list-header.component";
import { UiListItemActionComponent } from "./components/list-item-action/list-item-action.component";
import { UiListItemActionsComponent } from "./components/list-item-actions/list-item-actions.component";
import { UiListItemExtraComponent } from "./components/list-item-extra/list-item-extra.component";
import { UiListItemMetaAvatarComponent } from "./components/list-item-meta-avatar/list-item-meta-avatar.component";
import { UiListItemMetaDescriptionComponent } from "./components/list-item-meta-description/list-item-meta-description.component";
import { UiListItemMetaTitleComponent } from "./components/list-item-meta-title/list-item-meta-title.component";
import { UiListItemMetaComponent } from "./components/list-item-meta/list-item-meta.component";
import { UiListItemComponent } from "./components/list-item/list-item.component";
import { UiListPaginationComponent } from "./components/list-pagination/list-pagination.component";
import { UiListGridDirective } from "./directives/list-grid.directive";
import { UiListLoadMoreDirective } from "./directives/list-load-more.directive";
import { UiListComponent } from "./list.component";

@NgModule({
  imports: [
    UiListComponent,
    UiListHeaderComponent,
    UiListFooterComponent,
    UiListPaginationComponent,
    UiListEmptyComponent,
    UiListItemComponent,
    UiListItemMetaComponent,
    UiListItemMetaTitleComponent,
    UiListItemMetaDescriptionComponent,
    UiListItemMetaAvatarComponent,
    UiListItemActionsComponent,
    UiListItemActionComponent,
    UiListItemExtraComponent,
    UiListLoadMoreDirective,
    UiListGridDirective,
  ],
  exports: [
    UiListComponent,
    UiListHeaderComponent,
    UiListFooterComponent,
    UiListEmptyComponent,
    UiListItemComponent,
    UiListItemMetaComponent,
    UiListItemMetaTitleComponent,
    UiListItemMetaDescriptionComponent,
    UiListItemMetaAvatarComponent,
    UiListItemActionsComponent,
    UiListItemActionComponent,
    UiListItemExtraComponent,
    UiListLoadMoreDirective,
    UiListGridDirective,
  ],
})
export class UiListModule {}
