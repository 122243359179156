import { StateService, TransitionService, UIRouterGlobals } from "@uirouter/angular";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { fromTransitionHook } from "@webapp/core/routing/rxjs";
import { SubNavOption } from "@webapp/navigation/models/sub-nav-options.models";

@UntilDestroy()
@Component({
  selector: "sub-nav-options",
  templateUrl: "./sub-navigation-options.component.html",
  styleUrls: ["./sub-navigation-options.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubNavOptionsComponent implements OnInit {
  @Input()
  public options: SubNavOption[];

  @Input()
  public isSettings = false;
  constructor(
    private stateService: StateService,
    private routerGlobals: UIRouterGlobals,
    private broadcastService: BroadcastService,
    private transitionService: TransitionService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    fromTransitionHook(this.transitionService, "onSuccess", {})
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.cdr.markForCheck();
      });
  }

  public isActive(option: SubNavOption): boolean {
    if (option.isActive !== undefined) {
      return option.isActive;
    }
    const areCurrentAndOptionStatesEqual = this.routerGlobals.current.name === option.state;

    if (option.state === "gtmhub.insightboard") {
      const idParamValue = this.routerGlobals.params["dashboardId"];
      return areCurrentAndOptionStatesEqual && idParamValue === option.stateOptions.dashboardId;
    }

    if (option.state === "gtmhub.tasks") {
      const filterIdParamValue = this.routerGlobals.params["filterId"];
      return areCurrentAndOptionStatesEqual && (filterIdParamValue === undefined || filterIdParamValue === option.stateOptions.filterId);
    }

    return areCurrentAndOptionStatesEqual;
  }

  public view(option: SubNavOption): void {
    if (option.broadcastMessage !== undefined) {
      this.broadcastService.emit(option.broadcastMessage);
    }

    if (option.state) {
      window.setTimeoutOutsideAngular(() => {
        this.stateService.go(option.state, option.stateOptions).then(() => {
          this.cdr.markForCheck();
        });
      });
    }
  }
}
