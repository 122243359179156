import { Observable } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseListModel } from "../models/base-list.model";
import { BaseApiService } from "./base-api.service";
import { BaseState } from "./base-state.service";

export abstract class BaseFacade<ItemModel, DtoModel, StateModel extends BaseState, ApiService extends BaseApiService<ItemModel, DtoModel, StateModel>> {
  protected state: StateModel;
  protected apiService: ApiService;

  protected constructor(state: StateModel, apiService: ApiService) {
    this.state = state;
    this.apiService = apiService;
  }

  public getAll$<ResponseType = BaseListModel<ItemModel>>(filter?: RequestPaging, config: RequestConfig = new RequestConfig()): Observable<ResponseType> {
    return this.apiService.getAll$<ResponseType>(filter, config) as Observable<ResponseType>;
  }

  public get$<ResponseType = ItemModel>(id: number | string, config: RequestConfig = new RequestConfig()): Observable<ResponseType> {
    return this.apiService.get$<ResponseType>(id, config) as Observable<ResponseType>;
  }

  public post$<ResponseType = ItemModel>(
    basePostModel: Partial<ItemModel | DtoModel> | string[] | FormData,
    config: RequestConfig = new RequestConfig()
  ): Observable<ResponseType> {
    return this.apiService.post$<ResponseType>(basePostModel, config) as Observable<ResponseType>;
  }

  public put$<ResponseType = ItemModel>(
    id?: number | string,
    basePostModel?: Partial<ItemModel | DtoModel>,
    config: RequestConfig = new RequestConfig()
  ): Observable<ResponseType> {
    return this.apiService.put$<ResponseType>(id, basePostModel, config) as Observable<ResponseType>;
  }

  public patch$<ResponseType = ItemModel>(
    id?: number | string,
    basePostModel?: Partial<ItemModel | DtoModel>,
    config: RequestConfig = new RequestConfig()
  ): Observable<ResponseType> {
    return this.apiService.patch$<ResponseType>(id, basePostModel, config) as Observable<ResponseType>;
  }

  public delete$<ResponseType = ItemModel>(id: number | string, config: RequestConfig = new RequestConfig()): Observable<ResponseType> {
    return this.apiService.delete$<ResponseType>(id, config) as Observable<ResponseType>;
  }

  public deleteMany$<ResponseType = ItemModel>(ids: string[], config: RequestConfig = new RequestConfig()): Observable<ResponseType> {
    return this.apiService.deleteMany$<ResponseType>(ids, config) as Observable<ResponseType>;
  }

  public getState(): StateModel {
    return this.state;
  }

  public getApiService(): ApiService {
    return this.apiService;
  }
}
