import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { UiSpecialButtonType } from "@quantive/ui-kit/button";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { statusOfTask } from "@gtmhub/tasks/utils/tasks-status";
import { IButtonSelectOptions } from "@webapp/shared/components/button-select/button-select.models";
import { TaskStatus } from "@webapp/tasks/models/tasks.models";
import { ExternalSystem } from "../../okr-tasks.models";

const TASK_STATUS_CONTROL_VALUE_ACCESSOR = { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TaskStatusComponent), multi: true };

@Component({
  selector: "task-status",
  templateUrl: "task-status.component.html",
  styleUrls: ["./task-status.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TASK_STATUS_CONTROL_VALUE_ACCESSOR],
})
export class TaskStatusComponent implements ControlValueAccessor {
  // GVS-44106 specify the type to be only TaskStatus
  @Input()
  public status!: string | TaskStatus;
  @Input() public externalSystem: ExternalSystem;
  @Input()
  @InputBoolean()
  public archived = false;
  @Input()
  @InputBoolean()
  public readonly: boolean;
  @Input()
  @InputBoolean()
  public disabled: boolean;

  /**
   * Controls whether to render padding on either side of the button trigger.
   * The padding is making the task-status component aligned with other form inputs.
   * Set `inline` to `true` to keep just the button-select without additional paddings, margins, etc.
   */
  @Input()
  public inline = false;

  @Input()
  public uiId: string;

  @Output()
  public readonly statusChange = new EventEmitter<TaskStatus>();

  public specialType: UiSpecialButtonType;

  private notifyControlChange: (value: TaskStatus) => void;

  public taskStatusOptions: (IButtonSelectOptions & { jiraValue: string })[] = [
    {
      value: "todo",
      jiraValue: "To Do",
      label: "to_do",
      uiSpecialType: "gray",
    },
    {
      value: "inprogress",
      jiraValue: "In Progress",
      label: "in_progress",
      uiSpecialType: "blue",
    },
    {
      value: "done",
      jiraValue: "Done",
      label: "done",
      uiSpecialType: "teal",
    },
  ];

  public buttonText = (): string => {
    return statusOfTask({ id: "", status: this.status, externalSystem: this.externalSystem?.name });
  };

  public buildJiraLink = (): string => {
    if (!this.externalSystem) {
      return "";
    }

    const portalUrl = this.externalSystem.id.split("/rest")[0];
    return portalUrl + "/browse/" + this.externalSystem.metadata["issue-key"];
  };

  public statusChanged = (value: TaskStatus): void => {
    this.writeValue(value);
    this.statusChange.emit(value);
    this.notifyControlChange?.(value);
  };

  public writeValue(value: TaskStatus | string): void {
    this.status = value;

    if (this.externalSystem && this.externalSystem.name === "jira") {
      const jiraOption = this.taskStatusOptions.find((statusOption) => statusOption.jiraValue === this.status);

      if (jiraOption) {
        this.specialType = jiraOption.uiSpecialType;
      } else {
        this.specialType = "gray";
      }
    }
  }

  public registerOnChange(fn: (value: TaskStatus) => void): void {
    this.notifyControlChange = fn;
  }

  public registerOnTouched(): void {
    // required by the ControlValueAccessor interface
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
