import { ChangeDetectionStrategy, Component } from "@angular/core";
import { NzListFooterComponent } from "ng-zorro-antd/list";

@Component({
  selector: "ui-list-footer",
  exportAs: "uiListFooter",
  templateUrl: "./list-footer.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class UiListFooterComponent extends NzListFooterComponent {}
