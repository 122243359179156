<ui-skeleton *ngIf="loading" [uiLoading]="true" [uiParagraph]="{ rows: 3, width: '100%' }" [uiTitle]="false"></ui-skeleton>
<ng-container *ngIf="!loading">
  <sub-nav-options [options]="options"></sub-nav-options>
  <nav-items-list
    *ngIf="favorites && favorites.items.length"
    [collection]="favorites"
    [config]="{
      sectionLabelKey: 'favorites',
      visibleItemsCount: visibleItemsCount,
      itemsType: itemsType,
      itemsGroupType: 'favorites',
      broadcastConfig: broadcastConfig,
    }"
    (markItemAsFavorite)="markItemAsFavorite.emit($event)"
    (unmarkItemAsFavorite)="unmarkItemAsFavorite.emit($event)"></nav-items-list>
  <nav-items-list
    *ngIf="recent && recent.items.length"
    [collection]="recent"
    [config]="{
      sectionLabelKey: 'recent',
      visibleItemsCount: visibleItemsCount,
      itemsType: itemsType,
      itemsGroupType: 'recent',
      broadcastConfig: broadcastConfig,
    }"
    (markItemAsFavorite)="markItemAsFavorite.emit($event)"
    (unmarkItemAsFavorite)="unmarkItemAsFavorite.emit($event)"></nav-items-list>
  <ng-content select="[before-suggested]"></ng-content>
  <nav-items-list
    [collection]="suggested"
    [config]="{
      sectionLabelKey: 'suggested',
      visibleItemsCount: visibleItemsCount,
      maxItemsCount: 10,
      itemsType: itemsType,
      itemsGroupType: 'suggested',
      broadcastConfig: broadcastConfig,
    }"
    (markItemAsFavorite)="markItemAsFavorite.emit($event)"
    (unmarkItemAsFavorite)="unmarkItemAsFavorite.emit($event)"
    (removeFromSuggested)="removeFromSuggested.emit($event)"></nav-items-list>
</ng-container>
