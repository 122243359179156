<div class="reactions-container">
  <ng-container *ngFor="let reaction of reactions">
    <button
      class="emojis"
      *ngIf="reaction.userIds.length > 0"
      [id]="target?.[idField] + '_' + reaction.name"
      [class.reacted]="reaction.currentUserReacted"
      [attr.aria-pressed]="reaction.currentUserReacted"
      [attr.aria-label]="reactionAuthorsMap[reaction.name]"
      [class.disabled]="isCurrentUserViewOnly"
      [uiTooltipTitle]="reactionTooltipTemplate"
      [uiTooltipTitleContext]="{ reaction: reaction }"
      (click)="reactUpdate(reaction, { adjustFocusOnRemove: true })"
      uiType="link"
      ui-button
      ui-tooltip>
      <div class="emoji">{{ reaction.emoji }}</div>
      <div class="emoji-count" data-test-id="emoji-count">{{ reaction.userIds.length }}</div>
    </button>
  </ng-container>

  <button class="emoji-picker-toggle-button" *ngIf="!isCurrentUserViewOnly" [attr.aria-label]="'add_reaction' | localize" (click)="emojiPicker.toggle()" aria-haspopup="true" tabindex="0">
    <i [uiType]="emojiPickerToggleIcon" ui-icon></i>
  </button>

  <!-- a placeholder for inlining other elements (e.g. other control buttons) after the reactions list -->
  <ng-content></ng-content>
</div>

<ng-template #reactionTooltipTemplate let-reaction="reaction">
  <div class="reaction-tooltip">
    <span *ngFor="let userId of reaction.userIds; let first = first; let last = last; let index = index">
      <span class="assignee-name" *ngIf="!last">
        <span>{{ assignees.get(userId)?.name.split(" ")[0] || "" }}</span>
        <span class="secondary-info" *ngIf="!assignees.get(userId)">({{ "deleted_user" | localize }})</span>
        <span class="secondary-info" *ngIf="assignees.get(userId)?.isActive === false">({{ "deactivated" | localize }})</span>
        <!-- add a comma if there are more than two reactions and if it's not the next to last reaction -->
        <span *ngIf="reaction.userIds.length > 2 && index !== reaction.userIds.length - 2">,</span>
      </span>
      <span *ngIf="!first && last">{{ "and" | localize }}</span>
      <span class="assignee-name" *ngIf="last">
        {{ assignees.get(userId)?.name.split(" ")[0] }}
        <span class="secondary-info" *ngIf="!assignees.get(userId)">({{ "deleted_user" | localize }})</span>
        <span class="secondary-info" *ngIf="assignees.get(userId)?.isActive === false">({{ "deactivated" | localize }})</span>
      </span>
    </span>
    <span class="reaction-name">{{ "reacted_with" | localize }} :{{ reaction.name }}:</span>
  </div>
</ng-template>
