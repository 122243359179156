import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { BaseState } from "@webapp/core/abstracts/services/base-state.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { UiDashboardWidget, WidgetPutDTO } from "@webapp/ui/dashboard/dashboard.models";

@Injectable({
  providedIn: "root",
})
export class HomeWidgetApiService extends BaseApiService<UiDashboardWidget[], WidgetPutDTO, BaseState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: BaseState) {
    super("", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getWidgetPath(httpAction: HttpActions): string {
    return `${this.getBasePath(httpAction)}uxcustomization/widgets`;
  }
}
