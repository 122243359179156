import { ChangeDetectionStrategy, Component, Input, TemplateRef } from "@angular/core";
import { FormHint } from "../../models/form.models";

@Component({
  selector: "form-hint",
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormHintComponent implements FormHint {
  @Input() public content: string | TemplateRef<object>;
  @Input() public hideIcon?: boolean;
  @Input() public accented?: boolean;
  @Input() public type?: "success" | "error" | "warning" | "info";
}
