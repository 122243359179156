import { IComponentOptions, IRootScopeService, IScope } from "angular";
import { IModalService } from "angular-ui-bootstrap";
import { EmailVerificationComponent } from "@gtmhub/edition-plan-change";
import { SnackbarEvents } from "@gtmhub/shared/components/ui/snackbar/snackbar.events";
import { CurrentUserRepository } from "@webapp/users";

export class VerifyUserEmailComponentCtrl {
  private snackbarEvents: SnackbarEvents;
  public type: EmailVerificationComponent;
  public emailVerified: boolean;

  public static $inject = ["$scope", "$rootScope", "$uibModal", "CurrentUserRepository"];

  constructor(
    $scope: IScope,
    private $rootScope: IRootScopeService,
    private $uibModal: IModalService,
    private currentUserRepository: CurrentUserRepository
  ) {
    this.snackbarEvents = new SnackbarEvents($scope);
    this.init();
  }

  private init = () => {
    const isEmailVerified = this.currentUserRepository.getUserSetting("emailVerified");

    if (isEmailVerified) {
      this.emailVerified = true;
    }

    this.$rootScope.$watch("emailVerified", (emailVerified) => {
      let showedSnackbar = false;

      if (emailVerified) {
        this.emailVerified = true;

        if (!showedSnackbar) {
          showedSnackbar = true;
          this.snackbarEvents.broadcastShowSnackbar("email_verified");
        }
      }
    });
  };

  openVerifyEmailModal = (): void => {
    this.$uibModal.open({
      template: require("./verify-email-modal.html"),
      backdrop: false,
      windowClass: "modal-small",
      backdropClass: "dark-backdrop",
      controller: "VerifyUserEmailModalCtrl",
    });
  };
}

export const VerifyEmailComponent: IComponentOptions = {
  template: require("./verify-email.component.html"),
  controller: VerifyUserEmailComponentCtrl,
  bindings: {
    type: "<",
  },
};
