import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "mentions-editor",
  templateUrl: "./mentions-editor.component.html",
  styleUrls: ["./mentions-editor.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentionsEditorComponent {
  /**
   * Specifies the mentioned assignee IDs that can be removed.
   */
  @Input()
  public removable: string[] = [];

  /**
   * Specifies the mentioned assignee IDs that cannot be removed.
   */
  @Input()
  public nonRemovable: string[] = [];

  /**
   * Contains the IDs of the mentioned assignees whose mentions will be removed.
   */
  @Input()
  public removeAllMentionsAriaLabel: string;

  /**
   * Emissions indicate all removable assignee IDs should be removed.
   */
  @Output()
  public readonly removeAll: EventEmitter<void> = new EventEmitter();

  /**
   * Emits the assignee ID which should be removed.
   */
  @Output()
  public readonly removeOne: EventEmitter<string> = new EventEmitter();

  /**
   * Used in the template to limit the number of displayed removable assignee IDs.
   */
  public limit = 4;
}
