import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject, skip } from "rxjs";
import { ParentType } from "@gtmhub/automation/controllers/action";
import { LowToHighScaleType, QunativePlusEffectivenessDTO } from "@webapp/platform-intelligence/expandable-suggestions/shared/models/quantive-plus-effectiveness.models";
import { effectivenessScoreToLowToHighScale } from "@webapp/platform-intelligence/expandable-suggestions/shared/utils/quantive-plus-effectiveness-adapter.utils";
import {
  SuggestionDrawerContent,
  SuggestionDrawerSectionType,
  SuggestionDrawerSuggestionGroupType,
  SuggestionDrawerTitleSuggestionParams,
} from "@webapp/platform-intelligence/expandable-suggestions/suggestion-drawer/shared/models/suggestion-drawer.models";
import { SuggestionDrawerEffectivenessTabStatePropagator } from "@webapp/platform-intelligence/expandable-suggestions/suggestion-drawer/shared/utils/state-propagator";
import { PiStateProcessorInstance } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.models";
import { PiStateProcessorService } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.service";
import { PiTrackingFlowNameEnum } from "@webapp/platform-intelligence/shared/utils/pi-tracking";

@UntilDestroy()
@Component({
  selector: "suggestion-drawer-effectiveness-tab",
  templateUrl: "./effectiveness-tab.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionDrawerEffectivenessTabComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public statePropagator: SuggestionDrawerEffectivenessTabStatePropagator;
  @Input() public effectivenessSection: SuggestionDrawerSectionType<{ effectiveness: QunativePlusEffectivenessDTO; refreshEffectiveness?(): void }>;
  @Input() public titleSection: SuggestionDrawerSectionType<{ title: SuggestionDrawerTitleSuggestionParams } & SuggestionDrawerSuggestionGroupType>;
  @Input() public parentDrawerContent: SuggestionDrawerContent;
  @Input() public improveWithSuggestionsSectionTitle: string;
  @Input() public flowId: string;
  @Input() public flowName: PiTrackingFlowNameEnum;
  @Input() public parentType: ParentType;
  @Input() public parent;

  @Output() public readonly retry = new EventEmitter<void>();

  public instance: PiStateProcessorInstance;
  public onDestroy$ = new Subject<void>();

  constructor(private piStateProcessorService: PiStateProcessorService) {}

  public ngOnInit(): void {
    this.instance = this.piStateProcessorService.createNewInstance({
      type: this.parentType,
      subType: "effectiveness",
      isInline: false,
      skipFeedbackReplacement: true,
      destroyOn$: this.onDestroy$,
    });

    this.instance.feedbackSubject.pipe(skip(1), untilDestroyed(this)).subscribe({
      next: (data) => {
        if (data.feedbackType === "negative") {
          this.effectivenessSection.refreshEffectiveness();
        }
      },
    });
  }

  public ngOnChanges(): void {
    if (!this.statePropagator) return;

    const isLoading = (this.effectivenessSection || {}).isLoading || (this.titleSection || {}).isLoading;
    const effectivenessScore = (this.effectivenessSection || {}).effectiveness?.score;

    this.statePropagator.emitStateChange({ isLoading, effectivenessScore });

    if (this.flowId) {
      this.piStateProcessorService.assigneInstanceFlowProperties(this.instance.id, {
        flowId: this.flowId,
        flowName:
          this.parentType === "goal"
            ? PiTrackingFlowNameEnum.ObjectiveDetailsViewEffectivenessSidePanel
            : PiTrackingFlowNameEnum.KeyResultDetailsViewEffectivenessSidePanel,
      });
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  public retryEffectiveness(): void {
    this.retry.emit();
  }

  public isOneOfFewPropsHighInEffectiveness(effectivenessLevel: LowToHighScaleType): boolean {
    return (
      effectivenessLevel === "high" &&
      this.effectivenessSection &&
      this.effectivenessSection.effectiveness.properties.some((prop) => effectivenessScoreToLowToHighScale(prop.score) !== "high")
    );
  }
}
