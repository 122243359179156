import { IScope } from "angular";
import { IModalScope } from "angular-ui-bootstrap";
import { IIndicator } from "@gtmhub/error-handling";
import { SearchService } from "@gtmhub/search/services/search.service";
import { Paginator } from "@gtmhub/teams";
import { IUser } from "@gtmhub/users";
import { Assignee, AssigneeSelectorType } from "@webapp/assignees/models/assignee.models";
import { ISearchParams, SearchOperatorsEnum } from "@webapp/search/models/search-api.models";
import { Search, SearchConditionSettings } from "@webapp/search/models/search.models";
import { buildAssigneesSearchRequests, searchItemsToAssignees } from "../utils/search-utils";

type SelectAssigneeModalTitleKey = "select_owner" | "select_user" | "select_team";

interface ISelectAssigneeScope extends IScope {
  titleKey?: SelectAssigneeModalTitleKey;
  indicators: {
    loadingAssignees?: IIndicator;
  };
  assignees: Assignee[] | IUser[];
  paginator: Paginator;
  querySearch: string;

  selectAssignee(assignee: Assignee): void;
  cancel(): void;
  onPageChange(page: number): void;
  clearSearch(): void;
  searchAssignees(querySearch: string): void;
}

export interface ISelectAssigneeModalScope extends ISelectAssigneeScope, IModalScope {}

export interface ISelectAssigneeModalOptions {
  assigneeType?: AssigneeSelectorType;
  titleKey?: SelectAssigneeModalTitleKey;
  includeActiveUsersOnly?: boolean;
  includeActiveTeamsOnly?: boolean;
}

export class SelectAssigneeModalCtrl {
  public static $inject = ["$scope", "options", "SearchService"];
  private assigneeType: AssigneeSelectorType;
  private searchSettings: SearchConditionSettings;

  constructor(
    private $scope: ISelectAssigneeModalScope,
    private options: ISelectAssigneeModalOptions,
    private searchService: SearchService
  ) {
    this.$scope.indicators = {};
    this.$scope.titleKey = this.options.titleKey || "select_owner";
    this.assigneeType = this.options.assigneeType;
    this.searchSettings = (this.options.includeActiveTeamsOnly !== undefined || this.options.includeActiveUsersOnly !== undefined) && {
      activeTeamsOnly: this.options.includeActiveTeamsOnly,
      activeUsersOnly: this.options.includeActiveUsersOnly,
    };
    this.$scope.selectAssignee = this.selectAssignee;
    this.$scope.cancel = this.cancel;
    this.$scope.onPageChange = this.onPageChange;

    this.$scope.searchAssignees = this.searchAssignees;

    this.setDefaultPaginator();
    this.getAssignees(this.$scope.querySearch);
  }

  private getAssignees = (searchTerm: string, indicator = "loadingAssignees") => {
    this.$scope.indicators = { [indicator]: { progress: true } };

    const queryParams: ISearchParams = {
      skip: (this.$scope.paginator.currentPage - 1) * this.$scope.paginator.itemsPerPage,
      take: this.$scope.paginator.itemsPerPage,
    };

    const searchBody = {
      searchTerm,
      operator: searchTerm ? SearchOperatorsEnum.matchPrefix : SearchOperatorsEnum.matchAny,
      searchRequests: buildAssigneesSearchRequests(this.assigneeType, searchTerm, this.searchSettings),
    };

    return this.searchService.getSomeSearchData<Search<"teams"> | Search<"users">>(searchBody, queryParams).then(
      (response) => {
        delete this.$scope.indicators[indicator];

        this.$scope.assignees = searchItemsToAssignees(response.items);

        this.$scope.paginator.totalPages = Math.ceil(response.totalCount / this.$scope.paginator.itemsPerPage);
      },
      (error) => (this.$scope.indicators[indicator] = { error })
    );
  };

  private searchAssignees = (querySearch: string) => {
    this.$scope.querySearch = querySearch;

    this.setDefaultPaginator();
    this.getAssignees(this.$scope.querySearch, "search");
  };

  private onPageChange = (page: number): void => {
    this.$scope.paginator.currentPage = page;
    this.getAssignees(this.$scope.querySearch, "search");
  };

  private setDefaultPaginator = () => {
    this.$scope.paginator = {
      currentPage: 1,
      itemsPerPage: 15,
      paginatorLimit: 5,
    };
  };

  private cancel = () => {
    this.$scope.$dismiss();
  };

  private selectAssignee = (assignee: Assignee) => {
    this.$scope.$close(assignee);
  };
}
