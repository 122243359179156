import { NgModule } from "@angular/core";
import { UiTextCopyComponent } from "./components/text-copy/text-copy.component";
import { UiTextEditComponent } from "./components/text-edit/text-edit.component";
import { UiTypographyComponent } from "./typography.component";

@NgModule({
  imports: [UiTypographyComponent, UiTextEditComponent, UiTextCopyComponent],
  exports: [UiTypographyComponent, UiTextEditComponent, UiTextCopyComponent],
})
export class UiTypographyModule {}
