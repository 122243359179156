import { Directionality } from "@angular/cdk/bidi";
import { CdkVirtualScrollViewport, ScrollingModule } from "@angular/cdk/scrolling";
import { AsyncPipe, NgFor, NgIf, NgStyle, NgTemplateOutlet } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Host,
  HostListener,
  Input,
  Optional,
  Output,
  SkipSelf,
  TemplateRef,
  ViewChild,
  forwardRef,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { NzConfigService, WithConfig } from "ng-zorro-antd/core/config";
import { NzNoAnimationDirective } from "ng-zorro-antd/core/no-animation";
import {
  NzFormatBeforeDropEvent,
  NzFormatEmitEvent,
  NzTreeBaseService,
  NzTreeHigherOrderServiceToken,
  NzTreeNode,
  NzTreeNodeKey,
  NzTreeNodeOptions,
} from "ng-zorro-antd/core/tree";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzTreeComponent } from "ng-zorro-antd/tree";
import { Observable } from "rxjs";
import { UiTreeNodeBuiltinComponent } from "./components/tree-node/tree-node.component";
import { UiTreeService } from "./services/tree.service";
import { TreeSize } from "./tree.models";

function NzTreeServiceFactory(higherOrderService: NzTreeBaseService, treeService: UiTreeService): NzTreeBaseService {
  return higherOrderService ? higherOrderService : treeService;
}

@Component({
  selector: "ui-tree",
  exportAs: "uiTree",
  templateUrl: "tree.component.html",
  styleUrls: ["./tree.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    UiTreeService,
    {
      provide: NzTreeBaseService,
      useFactory: NzTreeServiceFactory,
      deps: [[new SkipSelf(), new Optional(), NzTreeHigherOrderServiceToken], UiTreeService],
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiTreeComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgStyle, ScrollingModule, UiTreeNodeBuiltinComponent, NgIf, NgFor, NgTemplateOutlet, AsyncPipe],
})
export class UiTreeComponent extends NzTreeComponent implements AfterViewInit {
  @Input("uiShowIcon") @InputBoolean() @WithConfig() public nzShowIcon = false;
  @Input("uiHideUnMatched") @InputBoolean() @WithConfig() public nzHideUnMatched = false;
  @Input("uiBlockNode") @InputBoolean() @WithConfig() public nzBlockNode = false;
  @Input("uiExpandAll") @InputBoolean() public nzExpandAll = false;
  @Input("uiSelectMode") @InputBoolean() public nzSelectMode = false;
  @Input("uiCheckStrictly") @InputBoolean() public nzCheckStrictly = false;
  @Input("uiShowExpand") @InputBoolean() public nzShowExpand = true;
  @Input("uiShowLine") @InputBoolean() public nzShowLine = false;
  @Input("uiCheckable") @InputBoolean() public nzCheckable = false;
  @Input("uiAsyncData") @InputBoolean() public nzAsyncData = false;
  @Input("uiDraggable") @InputBoolean() public nzDraggable = false;
  @Input("uiMultiple") @InputBoolean() public nzMultiple = false;
  @Input("uiExpandedIcon") public nzExpandedIcon?: TemplateRef<{ $implicit: NzTreeNode; origin: NzTreeNodeOptions }>;
  @Input("uiVirtualItemSize") public nzVirtualItemSize = 28;
  @Input("uiVirtualMaxBufferPx") public nzVirtualMaxBufferPx = 500;
  @Input("uiVirtualMinBufferPx") public nzVirtualMinBufferPx = 28;
  @Input("uiVirtualHeight") public nzVirtualHeight: string | null = null;
  @Input("uiTreeTemplate") public nzTreeTemplate?: TemplateRef<{ $implicit: NzTreeNode; origin: NzTreeNodeOptions }>;
  @Input("uiBeforeDrop") public nzBeforeDrop?: (confirm: NzFormatBeforeDropEvent) => Observable<boolean>;
  @Input("uiData") public nzData: NzTreeNodeOptions[] | NzTreeNode[] = [];
  @Input("uiExpandedKeys") public nzExpandedKeys: NzTreeNodeKey[] = [];
  @Input("uiSelectedKeys") public nzSelectedKeys: NzTreeNodeKey[] = [];
  @Input("uiCheckedKeys") public nzCheckedKeys: NzTreeNodeKey[] = [];
  @Input("uiSearchValue") public nzSearchValue = "";
  @Input("uiSearchFunc") public nzSearchFunc?: (node: NzTreeNodeOptions) => boolean;
  /**
   * Custom size for standalone(ouside tree-select) trees. Defaults to "medium". Overrides the '@tree-title-height' ng-zorro LESS variable.
   *
   * "medium" - three node height is 32px, indent is 20px, icon size is 16px.
   *
   * Most of the tree-select nodes are 36px high. When introducing select trees with different dimensions, consider either merging logic or introducing a new Input for them.
   * @memberof UiTreeComponent
   */
  @Input() public treeSize: TreeSize = "medium";

  @Input() public uiOffset: number = 0;
  @Input() public activatedNode: NzTreeNode;
  @Input() public isTreeFlatten: boolean;
  /** Aria label of the select. */
  @Input() public ariaLabel: string = null;
  /** Input that can be used to specify the `aria-labelledby` attribute. */
  @Input() public ariaLabelledby: string = null;
  @Input() public ariaFirstNodeDescription: string = null;
  @Input() public listboxId: string;
  @ContentChild("nzTreeTemplate", { static: true }) public nzTreeTemplateChild!: TemplateRef<{
    $implicit: NzTreeNode;
    origin: NzTreeNodeOptions;
  }>;
  @ViewChild(CdkVirtualScrollViewport, { read: CdkVirtualScrollViewport })
  @Output("uiExpandedKeysChange")
  public readonly nzExpandedKeysChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output("uiSelectedKeysChange") public readonly nzSelectedKeysChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output("uiCheckedKeysChange") public readonly nzCheckedKeysChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output("uiSearchValueChange") public readonly nzSearchValueChange = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiClick") public readonly nzClick = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiDblClick") public readonly nzDblClick = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiContextMenu") public readonly nzContextMenu = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiCheckBoxChange") public readonly nzCheckBoxChange = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiExpandChange") public readonly nzExpandChange = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiOnDragStart") public readonly nzOnDragStart = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiOnDragEnter") public readonly nzOnDragEnter = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiOnDragOver") public readonly nzOnDragOver = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiOnDragLeave") public readonly nzOnDragLeave = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiOnDrop") public readonly nzOnDrop = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiOnDragEnd") public readonly nzOnDragEnd = new EventEmitter<NzFormatEmitEvent>();
  @Output() public readonly treeCheckBoxChange = new EventEmitter<KeyboardEvent>();

  public hoveredNode: NzTreeNode;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    nzTreeService: NzTreeBaseService,
    nzConfigService: NzConfigService,
    cdr: ChangeDetectorRef,
    @Optional() directionality: Directionality,
    @Host() @Optional() noAnimation?: NzNoAnimationDirective
  ) {
    super(nzTreeService, nzConfigService, cdr, directionality, noAnimation);
  }

  public ngAfterViewInit(): void {
    if (this.ariaFirstNodeDescription) {
      this.elementRef.nativeElement.querySelectorAll<HTMLElement>("ui-tree-node")[0].setAttribute("aria-description", this.ariaFirstNodeDescription);
    }
  }

  public nodeCheckBoxChange(event: KeyboardEvent): void {
    this.treeCheckBoxChange.emit(event);
  }

  public onNodeHover(node: NzTreeNode): void {
    this.hoveredNode = node;
  }

  @HostListener("keydown.home", ["$event"])
  public onKeydownHome(event: KeyboardEvent): void {
    // the tree select component(tree-select.component.ts) handles this
    if (this.nzSelectMode) {
      return;
    }

    event.preventDefault();
    const firstNode = this.elementRef.nativeElement.querySelector<HTMLElement>("ui-tree-node");
    firstNode?.focus();
  }

  @HostListener("keydown.end", ["$event"])
  public onKeydownEnd(event: KeyboardEvent): void {
    // the tree select component(tree-select.component.ts) handles this
    if (this.nzSelectMode) {
      return;
    }

    event.preventDefault();
    const lastNode = this.elementRef.nativeElement.querySelector<HTMLElement>("ui-tree-node:last-of-type");
    lastNode?.focus();
  }
}
