import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from "@angular/core";
import { UiButtonModule, UiSpecialButtonType } from "@quantive/ui-kit/button";
import { UiDropdownModule } from "@quantive/ui-kit/dropdown";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { localize } from "@gtmhub/localization";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { OkrChangeStateModalData } from "@webapp/okrs/components/change-okr-state/change-okr-state.component";
import { OkrWorkflowService } from "@webapp/okrs/components/okr-workflow/okr-workflow.service";
import { OkrStatus } from "@webapp/okrs/goals/models/goal-status.models";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownModule } from "@webapp/shared/dropdown/dropdown.module";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";

type OkrStatuses = "achieved" | "abandoned" | "active";

/**
 * A component for selecting OKR status from tree possible choices (active, achieved, abandon). Currently, it's used on the home page and OKR edit views.
 * @example <okr-status-button [goal-id]="goal.id", [goal-name]="goal.name", [goal-status]="goal.closedStatus.status"></okr-status-button>
 */
@Component({
  selector: "okr-status-button",
  templateUrl: "./okr-status.component.html",
  styleUrls: ["./okr-status.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OkrWorkflowService],
  standalone: true,
  imports: [DropdownModule, UiTooltipModule, UiButtonModule, LocalizationModule, UiIconModule, UiDropdownModule],
})
export class OkrStatusButtonComponent implements OnChanges, OnInit {
  /**
   * @param goalId string - The OKR ID
   */
  @Input() public goalId: string;
  /**
   * @param goalName string - The OKR name
   */
  @Input() public goalName: string;
  /**
   * @param goalStatus string - The OKR status
   */
  @Input() public goalStatus: OkrStatus;
  /**
   * @param tabIndex number - The `tabindex` value of the component; By default it'll be 0
   */
  @Input() public tabIndex = 0;

  public tooltip: string;
  public specialButtonType: UiSpecialButtonType;
  public toolTipClass: "okr-status-tooltip-hide" | "okr-status-tooltip";
  public menuItems: DropdownMenuItem[] = [];
  public ariaLabel: string;

  private allMenuItems: Record<OkrStatuses, DropdownMenuItem>;

  private specialButtonTypes = {
    achieved: "teal",
    active: "gray",
    abandoned: "red",
  };

  constructor(private okrWorkflowService: OkrWorkflowService) {}

  public ngOnInit(): void {
    this.allMenuItems = this.getOptions();
    this.menuItems = this.getMenuItems();

    this.setAriaLabel(this.goalStatus);
  }

  public ngOnChanges(): void {
    this.menuItems = this.getMenuItems();
    this.tooltip = this.setTooltipText();

    this.defineSpecialButtonType();
    this.setAriaLabel(this.goalStatus);
  }

  private getMenuItems(): DropdownMenuItem[] {
    const items: DropdownMenuItem[] = [];

    for (const key in this.allMenuItems) {
      if (key !== this.goalStatus) {
        items.push(this.allMenuItems[key]);
      }
    }

    return items;
  }

  private getOptions(): Record<OkrStatuses, DropdownMenuItem> {
    const menuItemBuilder = new DropdownMenuItemBuilder();

    const achieved = menuItemBuilder
      .setKey("achieve_okr")
      .setDescriptionKey("this_okr_will_be_closed_and_marked_as_achieved")
      .setAction({
        handler: () => this.onStatusSelect("achieved"),
      })
      .build();

    const abandoned = menuItemBuilder
      .setKey("abandon_okr")
      .setDescriptionKey("this_okr_will_be_closed_and_marked_as_abandoned")
      .setAction({
        handler: () => this.onStatusSelect("abandoned"),
      })
      .build();

    const active = menuItemBuilder
      .setKey("reopen_okr")
      .setDescriptionKey("work_on_this_okr_is_resuming")
      .setAction({
        handler: () => this.onStatusSelect("active"),
      })
      .build();

    return { achieved, abandoned, active };
  }

  private defineSpecialButtonType(): void {
    this.specialButtonType = this.specialButtonTypes[this.goalStatus] as UiSpecialButtonType;
  }

  public onStatusSelect(selectedStatus: string): void {
    this.okrWorkflowService.openChangeOkrStateModal({
      goalId: this.goalId,
      goalName: this.goalName,
      selectedStatus: this.goalStatus,
      oldStatus: selectedStatus,
    } as OkrChangeStateModalData);
  }

  private setTooltipText(): string {
    if (this.goalStatus === "active") {
      return localize("this_okr_is_currently_in_progress");
    } else {
      return localize("work_on_this_okr_is_finished");
    }
  }

  public dropdownVisibilityChange($event): void {
    if ($event) {
      this.toolTipClass = "okr-status-tooltip-hide";
    } else {
      this.toolTipClass = "okr-status-tooltip";
    }
  }

  private setAriaLabel(goalStatus: string): void {
    if (goalStatus) {
      this.ariaLabel = `${localize("okr_state")}: ${goalStatus}`;
    } else {
      this.ariaLabel = "";
    }
  }
}
