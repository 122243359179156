import { Injectable } from "@angular/core";
import { Observable, map, of } from "rxjs";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { Goal } from "@webapp/okrs/goals/models/goal.models";
import { GoalsFacade } from "@webapp/okrs/goals/services/goals-facade.service";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { MetricsFacade } from "@webapp/okrs/metrics/services/metrics-facade.service";
import { IGetParentSelectionItemsParams } from "@webapp/okrs/models/parent-selector.models";

const SKIP_ENTRIES_NUMBER = 0;
const getItems = <T>({ items }: { items: Array<T> }): Array<T> => items;

@Injectable({
  providedIn: "any",
})
export class MultiSelectorFacade {
  constructor(
    private goalsFacade: GoalsFacade,
    private metricsFacade: MetricsFacade
  ) {}
  public getSelectedItem$({ sessionIds, type, parentId }: IGetParentSelectionItemsParams): Observable<Array<Goal | Metric>> {
    if (parentId) {
      const filter: Record<string, unknown> = {};

      if (sessionIds?.length) {
        filter.sessionId = { $in: sessionIds };
      }

      filter._id = parentId;

      const getParentParams = { ...new RequestPaging(), filter, skip: SKIP_ENTRIES_NUMBER, take: 1 };

      const goalFields = ["name", "ownerIds", "sessionId", "metrics{id,name,ownerIds},private"];
      const getGoal = { ...getParentParams, fields: goalFields };
      const metricFields = ["id", "name", "ownerIds", "sessionId"];
      const getMetric = { ...getParentParams, fields: metricFields };

      return type === "goal" ? this.goalsFacade.getAll$(getGoal).pipe(map(getItems)) : this.metricsFacade.getAll$(getMetric).pipe(map(getItems));
    }

    return of([]);
  }
}
