import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UsersFacade } from "@webapp/users/services/users-facade.service";

@Injectable({
  providedIn: "root",
})
export class PrivacyNoticeService {
  constructor(
    private userService: UsersFacade,
    private httpClient: HttpClient
  ) {}

  public getHasAcceptedPrivacyNotice(): boolean {
    return this.userService.getUserSetting<boolean>("hasAcceptedPrivacyNotice");
  }

  public acceptPrivacyNotice$(): Observable<boolean> {
    return this.userService.setUserSetting$({ hasAcceptedPrivacyNotice: true });
  }

  public getPrivacyNotice$(fileSrc: string): Observable<Blob> {
    return this.httpClient.get(fileSrc, { responseType: "blob" });
  }
}
