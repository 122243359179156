<form class="gh-unit-selector-container" [formGroup]="validateForm" ui-form>
  <ui-form-item class="form-item">
    <ui-form-label class="field-label" *ngIf="addLabels" [uiNoColon]="true" [uiFor]="uiId">{{ "unit" | localize }}</ui-form-label>
    <ui-form-control class="field-input">
      <ui-select
        [reserveScrollbarSpace]="reserveScrollbarSpace"
        [uiDisabled]="disabled"
        [a11yDescription]="a11yDescription"
        [a11yLabel]="'unit' | localize"
        [uiId]="uiId"
        formControlName="unit"
        uiDropdownClassName="unit-option-select-container">
        <ui-option *ngFor="let unit of unitMap | keyvalue: sortByOriginalOrder" [uiValue]="unit.value" [uiLabel]="unit.value.display"></ui-option>
        <ui-option *ngIf="customUnit.value" [uiValue]="customUnit" [uiLabel]="customUnit.value"></ui-option>
        <ui-option [uiValue]="{ value: 'custom' }" [uiLabel]="'custom' | localize" uiCustomContent="true">{{ "create_custom_unit" | localize }}</ui-option>
      </ui-select>
    </ui-form-control>
  </ui-form-item>
  <ui-form-item class="form-item sp-mt-20 sp-mb-20" *ngIf="addPrecision">
    <ui-form-label class="field-label" *ngIf="addLabels" [uiNoColon]="true" [uiFor]="uiId">{{ "decimal_places" | localize }}</ui-form-label>
    <ui-form-control class="field-input" uiErrorTip="We have error">
      <ui-select
        [uiDisabled]="disabled"
        [uiOptionHeightPx]="48"
        [a11yDescription]="a11yDescription"
        [a11yLabel]="'unit' | localize"
        [uiId]="uiId"
        formControlName="fractionSize"
        uiDropdownClassName="fraction-size-option-select-container">
        <ui-option class="fraction-size-option" *ngFor="let option of fractionSizeList" [uiValue]="option.value" [uiLabel]="option.display" [uiCustomContent]="option.customContent">
          <div class="fraction-size-value">{{ option.value }}</div>
          <div class="fraction-size-example">{{ option.example }}</div>
        </ui-option>
      </ui-select>
    </ui-form-control>
  </ui-form-item>
</form>
