<gh-dropdown [menuItems]="menuItems" [hasCustomButtonContent]="true" (dropdownToggled)="dropdownVisibilityChange($event)" dropdownMenuClass="okr-status-dropdown" uiOverlayClassName="okr-status-menu">
  <button
    [uiTooltipTitle]="tooltip"
    [uiSpecialType]="specialButtonType"
    [uiTooltipOverlayClassName]="toolTipClass"
    [tabindex]="tabIndex"
    [attr.data-test-id]="'okr-' + goalId + '-status-dropdown-toggle'"
    [attr.aria-label]="ariaLabel"
    uiShape="round"
    uiSize="small"
    gh-dropdown-custom-button-content
    uiOverlayClassName="okr-status-dropdown"
    uiTooltipTrigger="hover"
    uiTooltipPlacement="bottom"
    ui-button
    uiTrigger="click"
    ui-tooltip>
    <span class="okr-status">{{ goalStatus | localize }} </span>
    <i ui-icon uiType="chevron-down-small" uiSize="sm"></i>
  </button>
</gh-dropdown>
