import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from "@angular/core";
import { NzListItemActionComponent } from "ng-zorro-antd/list";

@Component({
  selector: "ui-list-item-action",
  exportAs: "uiListItemAction",
  templateUrl: "./list-item-action.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class UiListItemActionComponent extends NzListItemActionComponent {
  @ViewChild(TemplateRef) public templateRef?: TemplateRef<void>;
}
