import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core";
import { SimpleChangesOf } from "@quantive/ui-kit/core";
import { localize } from "@gtmhub/localization";
import { IdMap } from "@gtmhub/util";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { AssigneesFacade } from "@webapp/assignees/services/assignees/assignees-facade.service";
import { CommentTarget, UiComment } from "@webapp/comments/models/comments.models";
import { AggregatedReaction } from "@webapp/comments/models/reactions.models";
import { Gif, SizeOfGif } from "@webapp/shared/components/gifs/gif.models";

@Component({
  selector: "comment",
  templateUrl: "./comment.component.html",
  styleUrls: ["./comment.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentComponent implements OnChanges, OnInit {
  @Input() public comment: UiComment;
  @Input() public idField: keyof UiComment = "id";
  @Input() public isEdited = false;
  @Input() public target: CommentTarget;
  @Input() public metadata: { [key: string]: string | boolean };
  @Input() public hideReactions = false;
  @Input() public hideMentions = false;
  @Input() public editorButtonsDisabled = false;
  @Input() public hideCommentOptionsDropdown = false;
  @Input() public commentOptionsDropdownPosition: "start" | "end" = "start";
  @Input() public scrollToComment: string;

  @Output() public readonly edit: EventEmitter<{ text: string; gif: Gif; mentions: string[] }> = new EventEmitter();
  @Output() public readonly editorClose: EventEmitter<void> = new EventEmitter();

  @Output() public readonly headerClick: EventEmitter<void> = new EventEmitter();
  @Output() public readonly editClick: EventEmitter<void> = new EventEmitter();
  @Output() public readonly deleteClick: EventEmitter<void> = new EventEmitter();
  @Output() public readonly reactionsChange: EventEmitter<AggregatedReaction[]> = new EventEmitter();

  @ViewChild("focusableWrapper")
  public focusableWrapper: ElementRef<HTMLElement>;

  public assignees: IdMap<Assignee>;
  public sizeOfGif = SizeOfGif.Big;
  public mentioned: string[] = [];
  public mentionedDescription: string;
  public isCommentAuthorActive: boolean;

  private originalCommentContent: {
    text: string;
    gif: Gif;
  };

  public constructor(
    assigneesFacade: AssigneesFacade,
    private changeDetector: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {
    this.assignees = assigneesFacade.getAssigneesIdMap();
  }

  public ngOnInit(): void {
    if (this.comment && this.scrollToComment && this.scrollToComment === this.comment.id) {
      this.elementRef.nativeElement.scrollIntoView({ block: "end" });
    }
    this.isCommentAuthorActive = !!this.assignees[this.comment?.createdBy];
  }

  public ngOnChanges(changes: SimpleChangesOf<CommentComponent>): void {
    if (changes.comment) {
      this.initMentionedUsers();
    }

    // focus the container when the comment editor closes
    if (changes.isEdited?.previousValue && !changes.isEdited?.currentValue) {
      this.focusWrapperElement();
    }
  }

  public getMentionedMetaTooltipTitle(mentionId: string): string {
    const userWasNotified = `${this.assignees[mentionId].name} ${localize("was_notified")}`;
    const seen = this.comment.seen?.includes(mentionId) ? ` (${localize("seen")})` : "";

    return `${userWasNotified}${seen}`;
  }

  public handleEditorOpenClick(): void {
    this.originalCommentContent = {
      text: this.comment.text,
      gif: { ...this.comment.gif },
    };

    this.editClick.emit();
  }

  public handleEscapeKeyDown(event: KeyboardEvent): void {
    if (this.isEdited) {
      // stops the escape bubbling up and closing the modal/sidebar
      event.stopPropagation();

      // if the default is prevented (e.g. rte link popup, mentions popup), don't close the editor
      if (!event.defaultPrevented) {
        this.closeEditor();
      }
    }
  }

  public closeEditor(): void {
    window.setTimeoutOutsideAngular(() => {
      this.comment.text = this.originalCommentContent?.text;
      this.comment.gif = this.originalCommentContent?.gif;

      this.changeDetector.detectChanges();
    });

    this.editorClose.emit();
  }

  public handleReactionsChange(reactions: AggregatedReaction[]): void {
    this.comment.reactions = reactions;
    this.reactionsChange.emit(reactions);
  }

  public focusWrapperElement(): void {
    this.focusableWrapper?.nativeElement.focus();
  }

  private initMentionedUsers(): void {
    this.mentioned = this.comment?.mentioned?.filter((mentionId) => this.assignees[mentionId] != null) || [];
    this.mentionedDescription =
      !this.hideMentions && this.mentioned.length > 0
        ? localize("users_have_been_notified", { userNames: this.mentioned.map((mentionId) => this.assignees[mentionId]?.name).join(", ") })
        : null;
  }
}
