import { NgClass, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { RemoveInteractableAttributesDirective } from "../../directives/remove-interactable-attributes.directive";
import { FormTypography } from "../../models/form.models";

@Component({
  selector: "form-label",
  templateUrl: "form-label.component.html",
  styleUrls: ["form-label.component.less"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgClass, UiTooltipModule, RemoveInteractableAttributesDirective],
  host: {
    class: "form-item-label-section",
    "[class.form-label-container-small]": `typography?.labelWidth === 'small'`,
    "[class.form-label-container-medium]": "!typography?.labelWidth || typography?.labelWidth === 'medium'",
    "[class.form-label-container-large]": `typography?.labelWidth === 'large'`,
  },
})
export class FormLabelComponent {
  @Input() public controlId: string;
  @Input() public id: string;
  @Input() public labelText: string;
  @Input() public required: boolean;
  @Input() public labelTooltip: string;
  @Input() public secondaryLabelText?: string;
  @Input() public typography: FormTypography;

  public focusBoundElement(): void {
    const elementById = document.getElementById(this.controlId);
    const isLabelableElement = elementById && ["INPUT", "TEXTAREA", "SELECT", "BUTTON", "KEYGEN", "METER", "OUTPUT", "PROGRESS"].includes(elementById.tagName);

    if (elementById && !isLabelableElement) {
      elementById.focus();
    }
  }
}
