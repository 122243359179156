export const ESCAPE = "Escape";
export const SPACE = " ";
export const ENTER = "Enter";
export const TAB = "Tab";
export const ARROW_UP = "ArrowUp";
export const ARROW_DOWN = "ArrowDown";
export const ARROW_LEFT = "ArrowLeft";
export const ARROW_RIGHT = "ArrowRight";

export const isKeyOneOf = (targetKey: string, keys: string[]): boolean => {
  return keys.includes(targetKey);
};
