import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { catchError, map, of } from "rxjs";
import { NavItemsSectionBroadcastConfigFactory } from "@webapp/navigation/components/navigation/sub-navigation/services/nav-items-section-broadcast-config.factory";
import { NavItemsSectionBroadcastConfig } from "@webapp/navigation/models/nav-items-list.models";
import { SubNavOption } from "@webapp/navigation/models/sub-nav-options.models";
import { TasksNavItemsService } from "./services/tasks-nav-items.service";

@UntilDestroy()
@Component({
  selector: "tasks-sub-navigation",
  templateUrl: "./tasks-sub-navigation.component.html",
  providers: [TasksNavItemsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksSubNavigationComponent {
  public options: SubNavOption[] = [{ label: "my_tasks", state: "gtmhub.tasks", stateOptions: { filterId: null } }];
  public visibleItemsCount = 5;
  public broadcastConfig: NavItemsSectionBroadcastConfig = this.broadcastConfigFactory.buildDefaultNavItemsSectionBroadcastConfig();
  public navItems$ = this.tasksNavItemsService.navItems$;
  public showNavItems$ = this.navItems$.pipe(
    map((customViews) => customViews && customViews.items.length > 0),
    catchError(() => of(false))
  );

  public constructor(
    private tasksNavItemsService: TasksNavItemsService,
    private broadcastConfigFactory: NavItemsSectionBroadcastConfigFactory
  ) {}
}
