import { NgIf, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { take } from "rxjs";
import { TracingService } from "@webapp/core/tracing/services/tracing.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { SearchFacetMenuComponent } from "@webapp/search/components/search-facet-menu/search-facet-menu.component";
import { SearchResultsComponent } from "@webapp/search/components/search-results/search-results.component";
import { SearchFacetsOptions, SearchFacetsOptionsEnum } from "@webapp/search/models/search.models";
import { GlobalSearchMediatorService } from "@webapp/search/services/global-search.mediator.service";
import { ModelChangeDebounceDirective } from "@webapp/shared/directives/model-change-debounce/model-change-debounce.directive";
import { UiDividerModule } from "@webapp/ui/divider/divider.module";
import { UiInputModule } from "@webapp/ui/input/input.module";
import { UiPopoverModule } from "@webapp/ui/popover/popover.module";

@UntilDestroy()
@Component({
  selector: "global-search",
  templateUrl: "./global-search.component.html",
  styleUrls: ["./global-search.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    UiIconModule,
    LocalizationModule,
    FormsModule,
    UiDividerModule,
    UiButtonModule,
    UiPopoverModule,
    UiInputModule,
    SearchResultsComponent,
    SearchFacetMenuComponent,
    NgIf,
    NgStyle,
    ModelChangeDebounceDirective,
  ],
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
  @Input() public facet: SearchFacetsOptions;

  @Output() public readonly globalSearchToggle = new EventEmitter<boolean>();

  public searchTerm: string;

  public isSearchFocused = false;
  public shouldShowPopover = false;
  private clickSubscription: () => void;

  @ViewChild("globalSearch") private globalSearch: ElementRef;
  @ViewChild("resultsPopover") private resultsPopover: ElementRef;
  @ViewChild("searchInput") private searchInput: ElementRef;

  constructor(
    private globalSearchMediatorService: GlobalSearchMediatorService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private tracingService: TracingService
  ) {
    this.globalSearchMediatorService.searchTerm$.pipe(untilDestroyed(this), take(1)).subscribe((searchTerm) => {
      this.searchTerm = searchTerm;
    });
  }

  public ngOnDestroy(): void {
    if (this.clickSubscription) this.clickSubscription();
    this.globalSearchMediatorService.setSearchTerm(null);
  }

  public ngOnInit(): void {
    this.globalSearchMediatorService.setCurrentFacet(this.facet || SearchFacetsOptionsEnum.Everywhere, { skipLoading: true });
  }

  protected onSearchClick(): void {
    if (!this.searchTerm) {
      this.globalSearchMediatorService.loadRecentItems();
    }

    this.isSearchFocused = true;
    this.shouldShowPopover = true;
    this.globalSearchToggle.emit(true);

    if (!this.clickSubscription) {
      this.subscribeForClickOutside();
    }

    this.cdr.markForCheck();
    this.tracingService.traceAction("gs__focused_search_field", () => {});

    window.setTimeoutOutsideAngular(() => {
      this.searchInput.nativeElement.focus();
      this.globalSearchMediatorService.hideSearchResults$.pipe(take(1), untilDestroyed(this)).subscribe(() => {
        this.closeSearchResults();
        this.cdr.markForCheck();
      });
    });
  }

  public handleButtonClick(event: MouseEvent): void {
    event.stopPropagation();
    this.onSearchClick();
  }

  public handleButtonMouseDown(event: MouseEvent): void {
    event.preventDefault();
  }

  public onSearchFocus(): void {
    this.onSearchClick();
  }

  public onSearchTermChange(): void {
    this.globalSearchMediatorService.setSearchTerm(this.searchTerm);
    this.shouldShowPopover = true;
    this.cdr.markForCheck();
  }

  public calculateMaxHeight(): number {
    // Top-nav-bar height is 45px, and we have 10px more for better look
    return window.innerHeight - 55;
  }

  public focusout(evt: FocusEvent): void {
    const elementToFocus = evt.relatedTarget as HTMLElement;
    const searchItemsIds = ["facet-menu-button", "search-input", "search-everywhere-instead-button"];
    const searchButtonsDataTestIds = ["view-more-results-for-", "view-all-results-for-", "search-everywhere-from-"];
    if (
      elementToFocus !== null && // This prevents closing the popover when click event is triggered(click is handled by subscribeForClickOutside)
      !searchItemsIds.find((i) => i === elementToFocus?.id) &&
      elementToFocus?.children[0]?.localName !== "search-item" &&
      !elementToFocus?.querySelector("span.facet-option") &&
      !searchButtonsDataTestIds.find((dt) => elementToFocus.dataset?.testId?.includes(dt))
    ) {
      this.closeSearchResults();
    }
  }

  public onEsc(): void {
    this.shouldShowPopover = false;
    this.searchInput.nativeElement.focus();
    this.cdr.markForCheck();
  }

  public focusSearchItems(): void {
    this.resultsPopover?.nativeElement?.querySelector("li.item-row.section-list")?.focus();
  }

  private subscribeForClickOutside(): void {
    this.clickSubscription = this.renderer.listen("window", "click", (e: Event) => {
      const facetMenu = document.querySelector<HTMLElement>("[ui-menu][data-test-id=global-facet-dropdown-menu]");
      if (
        this.isSearchFocused &&
        !this.globalSearch.nativeElement.contains(e.target) &&
        !this.resultsPopover?.nativeElement.contains(e.target) &&
        !facetMenu?.contains(e.target as Node)
      ) {
        this.closeSearchResults();
      }
    });
  }

  public closeSearchResults(): void {
    this.shouldShowPopover = false;
    this.globalSearchToggle.emit(false);
    this.isSearchFocused = false;
    if (this.clickSubscription !== null) {
      this.clickSubscription();
    }
    this.clickSubscription = null;
    this.cdr.markForCheck();
  }
}
