/* eslint-disable @typescript-eslint/no-unsafe-function-type */

/* eslint-disable prefer-rest-params */
import { IModule, IPromise, IServiceProvider, ITimeoutService, isFunction } from "angular";
import { Ng1Zone } from "../zone";
import { constructChain, splitChain } from "./util";

export function $timeoutPatch(mod: IModule) {
  mod.config([
    "$timeoutProvider",
    function ($timeoutProvider: IServiceProvider) {
      const [dependencies, oldGet] = splitChain($timeoutProvider.$get);
      $timeoutProvider.$get = constructChain(dependencies, newGet);

      function newGet(this: IServiceProvider) {
        const oldTimeout: ITimeoutService = oldGet.apply(this, arguments);

        const newTimeout = function zoneAwareTimeout(this: ITimeoutService, fn: unknown) {
          const zone = Ng1Zone.current;

          let r: IPromise<unknown>;
          if (!isFunction(fn)) {
            r = oldTimeout.apply(this, arguments);
          } else {
            const newFn = function zoneAwareCallback(this: unknown) {
              return zone.run<IPromise<unknown>>(fn as Function, this, [...arguments]);
            };

            // when calling the original $timeout, we have to replace the 1st argument
            // with our new function
            const args = [].slice.call(arguments);
            args[0] = newFn;
            r = oldTimeout.apply(this, args);
          }

          return r;
        };

        // copy other fields
        Object.keys(oldTimeout).forEach((propertyName) => {
          newTimeout[propertyName] = oldTimeout[propertyName];
        });
        return newTimeout;
      }
    },
  ]);
}
