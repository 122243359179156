import { module } from "angular";
import env from "@gtmhub/env/env.module";
import { PlanDetailsComponent } from "./components/plan-details/plan-details.component";
import { EditionPlanChangeCtrl } from "./controllers/edition-plan-change";
import { RequiredEmailNotificationCtrl } from "./controllers/required-email-notification";
import { EditionPlanSelectorService } from "./services/edition-plan-selector.service";
import { FeatureUsageService } from "./services/feature-usage.service";

const mod = module("editionPlanChange", [env]);

mod.controller("EditionPlanChangeCtrl", EditionPlanChangeCtrl);
mod.controller("RequiredEmailNotificationCtrl", RequiredEmailNotificationCtrl);

mod.service("EditionPlanSelectorService", EditionPlanSelectorService);
mod.service("FeatureUsageService", FeatureUsageService);

mod.component("planDetails", PlanDetailsComponent);

export default mod.name;
