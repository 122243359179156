import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IPISqlFeedbackItemModel, IPostSqlFeedbackPayload } from "./models";
import { PiSqlFeedbackApiService } from "./pi-sql-feedback-api.service";

@Injectable({
  providedIn: "root",
})
export class PiSqlFeedbackRepositoryService {
  constructor(private api: PiSqlFeedbackApiService) {}

  public postFeedback$(params: { postModel: Partial<IPostSqlFeedbackPayload> }): Observable<IPISqlFeedbackItemModel> {
    return this.api.post$<IPISqlFeedbackItemModel>(params.postModel);
  }
}
