import { StateService } from "@uirouter/angular";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ICommentNotification } from "@gtmhub/notifications/models/notifications";
import { checkInCommentLegacy, checkInTagetTypesLegacy } from "@webapp/reflections/models/reflections.models";
import { SizeOfGif } from "@webapp/shared/components/gifs/gif.models";
import { InboxTemplateComponent } from "../inbox-template.component";

@Component({
  selector: "comment-inbox-template",
  templateUrl: "./comment-inbox-template.component.html",
  styleUrls: ["../inbox-template.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentInboxTemplateComponent extends InboxTemplateComponent<ICommentNotification> {
  public sizeOfGif = SizeOfGif;
  public checkInCommentLegacy = checkInCommentLegacy;
  public checkInTagetTypesLegacy = checkInTagetTypesLegacy;

  public get icon(): string {
    if (this.notification.targetType === "comment") {
      return "comments";
    }
    if (this.notification.targetType === "reflection") {
      return "check-in";
    }
  }

  constructor(private stateService: StateService) {
    super();
  }

  public goToComment = (event: MouseEvent, url: string): void => {
    // Fix state transition if we are on the same state that notificaion should lead to
    if (this.stateService.is("gtmhub.teams.team")) {
      event.preventDefault();
      const memberIdPattern = /(?<=memberId=)[a-z0-9]{24}/gm;
      const targetIdPattern = /(?<=targetId=)[a-z0-9]{24}/gm;
      const commentIdPattern = /(?<=commentId=)[a-z0-9]{24}/gm;
      const cadenceStartPattern = /(?<=cadenceStart=)[0-9]{4}-[0-9]{2}-[0-9]{2}/gm;

      this.stateService.go(
        ".",
        {
          memberId: memberIdPattern.exec(url)[0],
          targetId: targetIdPattern.exec(url)[0],
          commentId: commentIdPattern.exec(url)[0],
          cadenceStart: cadenceStartPattern.exec(url)[0],
          activeTab: "check-ins",
          published: "true",
        },
        { reload: true }
      );
    }
  };
}
