import { ChangeDetectionStrategy, Component } from "@angular/core";
import { IBadgeNotification, INotificationUser } from "@gtmhub/notifications/models/notifications";
import { InboxTemplateComponent } from "../inbox-template.component";

@Component({
  selector: "badge-inbox-template",
  templateUrl: "./badge-inbox-template.component.html",
  styleUrls: ["../inbox-template.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeInboxTemplateComponent extends InboxTemplateComponent<IBadgeNotification> {
  public trackByUser(index: number, user: INotificationUser): string {
    return user.id;
  }
}
