import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { localize } from "@webapp/localization/utils/localization.utils";

@Component({
  selector: "empty-filter-results",
  templateUrl: "empty-filter-results.component.html",
  styleUrls: ["empty-filter-results.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class EmptyFilterResultsComponent {
  @Input() public title: string = localize("no_results");
  @Input() public description: string = localize("adjust_the_filter_and_try_again");
}
