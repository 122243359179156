export type RestLayerFilter = Record<string, unknown>;

export interface IRestLayerRequest {
  filter?: RestLayerFilter;
  sort?: string;
  fields?: string;
  skip?: number;
  limit?: number;
  page?: number;
  flatten?: "cf";
}

export const createRestLayerFields = (input: Record<string, unknown>): string =>
  Object.entries(input)
    .reduce(
      (fields, [field, value]) =>
        !value ? fields : fields.concat(typeof value === "object" ? field + "{" + createRestLayerFields(value as Record<string, unknown>) + "}" : field),
      []
    )
    .join(",");

export const extendRestLayerFilterWithAnd = (request: IRestLayerRequest, and: RestLayerFilter): void => {
  if (!request.filter) {
    request.filter = and;
    return;
  }

  if ("$and" in request.filter) {
    const $and = request.filter.$and as RestLayerFilter[];
    $and.push(and);
    return;
  }

  request.filter = {
    $and: [request.filter, and],
  };
};
