import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { IEntityStatusChangedArgs } from "@gtmhub/entities/models/models";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { IDaxQueryResult, IPowerBIUpdateEvent } from "@webapp/integrations/models/powerbi-models";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { ConfigurePowerBIComponent, ConfigurePowerBIModalData } from "./configure-power-bi.component";

@Injectable({
  providedIn: "any",
})
export class ConfigurePowerBIModalService implements OnDestroy {
  private powerBIUpdate$: BehaviorSubject<IPowerBIUpdateEvent> = new BehaviorSubject<IPowerBIUpdateEvent>(null);
  private entityName: string;
  private subscriptions: Subscription[] = [];

  public constructor(
    private modalService: UiModalService,
    private broadcastService: BroadcastService
  ) {
    this.subscriptions.push(
      this.broadcastService.on("entityStatusChanged").subscribe((args: IEntityStatusChangedArgs) => {
        if (this.entityName && args.entityName === this.entityName && args.status === "connected") {
          const powerBIUpdateEventData: IPowerBIUpdateEvent = {
            event: "entityStatusChanged",
            data: args,
          };

          this.powerBIUpdate$.next(powerBIUpdateEventData);
        }
      })
    );

    this.subscriptions.push(
      this.broadcastService.on("daxQueryExecuted").subscribe((data: IDaxQueryResult) => {
        if (data && data.tables && data.tables.length) {
          // PowerBI returns only one table to our backend
          this.entityName = data.tables[0].name;
        }

        const powerBIUpdateEventData: IPowerBIUpdateEvent = {
          event: "daxQueryExecuted",
          data,
        };

        this.powerBIUpdate$.next(powerBIUpdateEventData);
      })
    );
  }

  public getPowerBIUpdateObservable(): Observable<IPowerBIUpdateEvent> {
    return this.powerBIUpdate$.asObservable();
  }

  public open(params: ConfigurePowerBIModalData): UiModalRef<ConfigurePowerBIComponent, unknown> {
    return this.modalService.create<ConfigurePowerBIComponent, ConfigurePowerBIModalData, unknown>({
      uiContent: ConfigurePowerBIComponent,
      uiData: params,
      uiClosable: false,
      uiFooter: null,
      uiWidth: 600,
      uiClassName: "configure-power-bi-modal",
      uiMaskStyle: { opacity: "0.3" },
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }
}
