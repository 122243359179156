<span
  class="current-facet"
  id="facet-menu-button"
  #currentFacet
  [(uiVisible)]="isFacetMenuOpen"
  [attr.aria-expanded]="isFacetMenuOpen"
  [uiDropdownMenu]="menucl"
  (keydown)="openFacetDropdown($event)"
  (uiVisibleChange)="onFacetMenuOpen()"
  (focusout)="facetMenuFocusout.emit($event)"
  role="combobox"
  aria-controls="menu"
  ui-dropdown
  uiTrigger="click"
  uiPlacement="bottomRight"
  tabindex="0"
  data-test-id="global-facet-dropdown">
  {{ currentFacet$ | async | localize }}
  <i ui-icon uiType="chevron-down-small"></i>
</span>
<ui-dropdown-menu #menucl="uiDropdownMenu">
  <ul class="facet-option-menu" #facetMenu style="min-width: 140px" ui-menu data-test-id="global-facet-dropdown-menu">
    <li
      *ngFor="let facet of facetOptions$ | async | filterFacetOptions: facetsToHide"
      [attr.aria-selected]="(currentFacet$ | async) === facet"
      (click)="changeFacet(facet)"
      (keydown.arrowDown)="moveFocusTo('next')"
      (keydown.arrowUp)="moveFocusTo('prev')"
      (keydown.enter)="changeFacet(facet)"
      (keydown.space)="changeFacet(facet)"
      (keydown.esc)="focusCurrentFacet()"
      role="option"
      tabindex="0">
      <span class="facet-option">{{ facet | localize }}</span>
    </li>
  </ul>
</ui-dropdown-menu>
