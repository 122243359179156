// example <item-name-with-icon-and-owner [name]="metric.name" icon="metric"></item-name-with-icon-and-owner>
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "item-name-with-icon-and-owner",
  templateUrl: "./item-name-with-icon-and-owner.component.html",
  styleUrls: ["./item-name-with-icon-and-owner.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemNameWithIconAndOwnerComponent {
  @Input() public name: string;
  @Input() public icon: string;
  @Input() public ownerIds: string[];
}
