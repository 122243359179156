import { Ng1StateDeclaration } from "@uirouter/angularjs";

export const states: Ng1StateDeclaration[] = [
  {
    name: "gtmhub.userNotificationSettings",
    data: {
      requiresLogin: true,
      titleKey: "notification_settings",
      trackingPage: "User Notification Settings",
    },
    // This is the correct URL in the emails. If you change it here you should change it in the emails too! :)
    url: "/hub/user-notification-settings/",
    template: require("./views/user-notification-settings.html"),
  },
];
