import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import { FormItemBaseComponent } from "../form-item-base.component";

@Component({
  selector: "date-picker-form-item",
  templateUrl: "./date-picker-form-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FormItemBaseComponent, useExisting: forwardRef(() => DatePickerFormItemComponent) }],
})
export class DatePickerFormItemComponent extends FormItemBaseComponent<Date | string> {
  @Input() public open?: boolean;
  @Input() public backdrop?: boolean;
  @Input() public isDateDisabled?: (date: Date) => boolean;
  @Input() public allowClear?: boolean;
}
