import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { INavItem, NavItemsType } from "@webapp/navigation/models/nav-items-list.models";

@Component({
  selector: "nav-list-item-icon",
  templateUrl: "./nav-list-item-icon.component.html",
  styleUrls: ["./nav-list-item-icon.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavListItemIconComponent {
  @Input()
  public itemsType: NavItemsType;
  @Input()
  public item: INavItem;

  public avatarIsIcon(): boolean {
    return this.item.icon && this.item.icon.indexOf("http") != 0;
  }
}
