import { ChangeDetectionStrategy, Component, Input, TemplateRef } from "@angular/core";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { NzOptionGroupComponent } from "ng-zorro-antd/select";

@Component({
  selector: "ui-option-group",
  exportAs: "uiOptionGroup",
  template: "option-group.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class UiOptionGroupComponent extends NzOptionGroupComponent {
  @Input("uiLabel") public nzLabel: string | number | TemplateRef<NzSafeAny> | null = null;
}
