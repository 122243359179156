import { NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, TemplateRef } from "@angular/core";
import { UiAccessibilityModule } from "@quantive/ui-kit/accessibility";
import { UiI18nService } from "@quantive/ui-kit/i18n";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzMessageType } from "ng-zorro-antd/message";
import { Subject, takeUntil } from "rxjs";

export type UiValidationHintType = Extract<NzMessageType, "success" | "info" | "warning" | "error">;

@Component({
  selector: "ui-validation-hint",
  templateUrl: "./validation-hint.component.html",
  styleUrls: ["./validation-hint.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet, UiIconModule, UiAccessibilityModule],
})
export class UiValidationHintComponent implements OnDestroy {
  @Input() public hintContent: string | TemplateRef<object>;
  @Input() public hintType: UiValidationHintType = "info";
  @Input() @InputBoolean() public uiHideIcon = false;
  @Input() @InputBoolean() public isAccented = false;

  public get text(): string {
    return typeof this.hintContent === "string" && this.hintContent;
  }

  public get template(): TemplateRef<object> {
    return this.hintContent instanceof TemplateRef && this.hintContent;
  }

  private destroy$ = new Subject<void>();
  private hintAriaLabelMap: {
    [HintType in UiValidationHintType]?: string;
  } = {};

  @HostBinding("class")
  public get hostClasses(): string[] {
    return [`ui-hint-${this.hintType}`, `${this.isAccented ? "accented" : ""}`, `${this.hintContent instanceof TemplateRef ? "hint-custom-content" : ""}`];
  }

  constructor(i18n: UiI18nService) {
    i18n.localeChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const locale = i18n.getLocaleData("Validation");
      this.hintAriaLabelMap = {
        info: locale.info,
        success: locale.success,
        warning: locale.warning,
        error: locale.error,
      };
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public generateToastAriaLabel(type: UiValidationHintType): string {
    return this.hintAriaLabelMap[type];
  }
}
