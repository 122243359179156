import { Gif } from "@webapp/shared/components/gifs/gif.models";

type IFeedEntryItem = IFeedKrItem | IFeedBadgeAssignItem;

export enum IFeedEntryTypeEnum {
  KR_UPDATE = "kr_update",
  BADGE_ASSIGN = "badge_assign",
}

export interface IFeedKrItem {
  id: string;
  attainment: number;
  attainmentChange: number;
  comment?: string;
  gif: Gif;
  confidenceValue: number;
  createdAt: string;
  format: { prefix: string; fractionSize: number };
  initialValue: number;
  manualType: string;
  name: string;
  objectiveName: string;
  ownerIds: string[];
  target: number;
  targetOperator: string;
  value: number;
}

export interface IFeedBadgeAssignItem {
  id: string;
  name: string;
  assigneeIds: string[];
  badgeId: string;
  createdAt: string;
  comment?: string;
  status: string;
}

export interface IFeedEntryComment {
  createdAt?: string;
  createdBy?: string;
  gif?: Gif;
  id?: string;
  targetId: string;
  mentioned: string[];
  reactions?: IFeedEntryReaction[];
  text?: string;
  editedBy?: string;
}
export interface IFeedEntryReaction {
  id?: string;
  currentUserReacted: boolean;
  emoji: string;
  name: string;
  userIds: string[];
}
export interface IFeedEntry {
  accountId: string;
  comments: IFeedEntryComment[];
  created: string;
  id: string;
  item: IFeedEntryItem;
  ownerId: string;
  ownerTeamIds: string[];
  private: boolean;
  reactions: IFeedEntryReaction[];
  targetId: string;
  type: IFeedEntryTypeEnum;
  displayed?: boolean;
}

export interface IFeedResponse {
  items: IFeedEntry[];
  totalCount: number;
}
