import { IStateSnapshot } from "./routing.models";

export type OverlayComponentStateManager = {
  openerQueue: (() => Promise<unknown>)[];
  stateOpenerStack: IStateSnapshot[];
};

// States are resolves in a parent-to-child manner, but drawers are opened first on transition's
// success, so we need to maintain the order of state resolution when opening the drawers.
const openerQueue: (() => Promise<unknown>)[] = [];

const stateOpenerStack: IStateSnapshot[] = [];

export const overlayStateManager: OverlayComponentStateManager = {
  openerQueue,
  stateOpenerStack,
};
