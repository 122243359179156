import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { QuantiveProduct, QuantiveProductName } from "@webapp/permissions/models/permissions.model";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";

const productToKeyMap: Record<QuantiveProductName, string> = {
  Platform: "quantive_platform",
  Results: "gtmhub",
  Strategy: "quantive_strategyai",
};

@UntilDestroy()
@Component({
  selector: "apps-dropdown",
  templateUrl: "./apps-dropdown.component.html",
  styleUrls: ["./apps-dropdown.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppsDropdownComponent implements OnInit {
  @Input() public iconType = "apps";
  @Input() public withoutBorderOnFocus = false;

  public appsMenuItems: DropdownMenuItem[];

  constructor(
    private permissionsApiService: PermissionsFacade,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.setMenuItems();
  }

  public onDropdownToggled(toggledOn: boolean): void {
    if (toggledOn) {
      this.setMenuItems();
    }
  }

  private setMenuItems(): void {
    this.permissionsApiService
      .getAvailableProducts$()
      .pipe(untilDestroyed(this))
      .subscribe((products) => {
        this.appsMenuItems = this.getMenuItems(products);
        this.cdr.markForCheck();
      });
  }

  private getMenuItems(products: QuantiveProduct[]): DropdownMenuItem[] {
    return products.map((item) => this.getMenuItem(item));
  }

  private getMenuItem(product: QuantiveProduct): DropdownMenuItem {
    const menuItemBuilder = new DropdownMenuItemBuilder();
    menuItemBuilder
      .setKey(productToKeyMap[product.name])
      .setUiType({ iconTheme: "fill", uiType: "uiIcon", iconType: "quantive-circle-logo", uiSize: "sm" })
      .setA11yDescriptionKey(product.name.toLowerCase());

    if (product.name === "Results") {
      menuItemBuilder.setSelected();
    } else {
      menuItemBuilder.setAction({ href: product.url, target: "_blank" });
    }

    return menuItemBuilder.build();
  }
}
