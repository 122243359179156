// https://github.com/Automattic/mongoose/issues/3796
/**
 * Special chars break the $regex mongo query operator. Use this to escape problematic symbols.
 *
 * @param text string input to be escaped
 * @returns {string}
 */
export function safeMongoRegexEscape(text: string): string {
  return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

/**
 * The exact match in mongo queries is case sensitive.
 * Use this to execute a case insensitive exact match.
 *
 * @param text string input to be matched
 * @returns {string}
 */
export function safeMongoCaseInsensitiveExactMatchRegex(text: string): string {
  return `^${safeMongoRegexEscape(text)}$`;
}

export const thousandsFormatterRegExp = new RegExp(/\B(?=(\d{3})+(?!\d))/g);
