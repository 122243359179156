/*
  For now, we will hardcode the configs in the code because we don't have test configs.
  If we need it we can move it to helm values
*/

export const clickMagickConfig = {
  uid: "146897",
  hid: "1783847164",
  cmc_goal: "a",
  cmc_ref: "",
};

export const clickMagickCDN = "https:////cdn.clkmc.com/cmc.js";
