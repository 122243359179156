import { NgModule } from "@angular/core";
import { GridStack } from "gridstack";
import { UiDashboardItemComponent } from "./components/dashboard-item/dashboard-item.component";
import { UiDashboardComponent } from "./dashboard.component";
import { addRemoveHandler, saveHandler } from "./dashboard.util";

@NgModule({
  imports: [UiDashboardComponent, UiDashboardItemComponent],
  exports: [UiDashboardComponent, UiDashboardItemComponent],
})
export class UiDashboardModule {
  constructor() {
    GridStack.addRemoveCB = addRemoveHandler;
    GridStack.saveCB = saveHandler;
  }
}
