import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UiThemeType } from "@quantive/ui-kit/icon";
import { PiCardBorderStyle } from "../../models/pi-card.models";

@Component({
  selector: "pi-loading-card",
  templateUrl: "./pi-loading-card.component.html",
  styleUrls: ["./pi-loading-card.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiLoadingCardComponent {
  @Input()
  public borderStyle: PiCardBorderStyle = "solid";
  @Input()
  public iconType: string;
  @Input()
  public iconTheme: UiThemeType;
  @Input()
  public shouldStyleIcon: boolean;
  @Input()
  public isLoadingIconAvailable = true;
}
