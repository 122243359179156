import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LocalizationModule } from "@webapp/localization/localization.module";

@Component({
  selector: "mobile-warning",
  templateUrl: "./mobile-warning.component.html",
  imports: [LocalizationModule],
  styleUrls: ["./mobile-warning.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class MobileWarningComponent {}
