import { ConfigurableFocusTrapFactory } from "@angular/cdk/a11y";
import { Direction, Directionality } from "@angular/cdk/bidi";
import { NgIf, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, Optional, TemplateRef } from "@angular/core";
import { UiI18nService } from "@quantive/ui-kit/i18n";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzConfigService, WithConfig } from "ng-zorro-antd/core/config";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { InputBoolean, InputNumber } from "ng-zorro-antd/core/util";
import { NZ_CONFIG_MODULE_NAME } from "ng-zorro-antd/modal";
import { NzSpinComponent } from "ng-zorro-antd/spin";
import { BehaviorSubject, ReplaySubject, Subject, debounce, distinctUntilChanged, skip, startWith, switchMap, takeUntil, timer } from "rxjs";
import { UiLoadingIndicatorSize, UiLoadingIndicatorTipPosition, UiLoadingIndicatorType } from "@webapp/ui/loading-indicator/loading-indicator.models";

@Component({
  selector: "ui-loading-indicator",
  exportAs: "uiLoadingIndicator",
  templateUrl: "loading-indicator.component.html",
  styleUrls: ["loading-indicator.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  host: {
    "[attr.role]": "nzSpinning ? 'status' : null",
    "[attr.aria-label]": "ariaLabel",
    "[attr.aria-live]": "nzSpinning ? 'polite' : null",
  },
  standalone: true,
  imports: [NgIf, NgTemplateOutlet, UiIconModule],
})
export class UiLoadingIndicatorComponent extends NzSpinComponent implements OnInit {
  @Input("uiIndicator") @WithConfig() public nzIndicator: TemplateRef<NzSafeAny> | null = null;
  @Input("uiSize") public nzSize: UiLoadingIndicatorSize = "default";
  @Input("uiTip") public nzTip: string | null = null;
  @Input("uiDelay") @InputNumber() public nzDelay = 0;
  @Input("uiSimple") @InputBoolean() public nzSimple = false;
  @Input("uiSpinning") @InputBoolean() public nzSpinning = true;
  @Input() public uiType: UiLoadingIndicatorType = "circular";
  @Input() public uiTipPosition: UiLoadingIndicatorTipPosition = "right";
  @Input() public uiSetFocus: boolean;

  constructor(
    private focusTrapFactory: ConfigurableFocusTrapFactory,
    private elementRef: ElementRef<HTMLElement>,
    nzConfigService: NzConfigService,
    private thisCdr: ChangeDetectorRef,
    private i18n: UiI18nService,
    @Optional() private thisDirectionality: Directionality
  ) {
    super(nzConfigService, thisCdr, thisDirectionality);
  }

  public get ariaLabel(): string {
    return this.nzSpinning ? this.nzTip || this.i18n.translate("LoadingIndicator.loading") : null;
  }

  public ngOnInit(): void {
    // The logic here is copied from NzSpinComponent and adjusted to create a focus trap when the loading is complete
    const delay$: ReplaySubject<number> = this["delay$"];
    const spinning$: BehaviorSubject<boolean> = this["spinning$"];
    const destroy$: Subject<void> = this["destroy$"];

    const loading$ = delay$.pipe(
      startWith(this.nzDelay),
      distinctUntilChanged(),
      switchMap((delay) => {
        if (delay === 0) {
          return spinning$;
        }

        return spinning$.pipe(debounce((spinning) => timer(spinning ? delay : 0)));
      }),
      takeUntil(destroy$)
    );
    loading$.subscribe((loading) => {
      this.isLoading = loading;
      this.thisCdr.markForCheck();
    });

    // skip the initial value of uiSpinning
    loading$.pipe(skip(1)).subscribe((loading) => {
      if (!loading && this.uiSetFocus) {
        const focusTrap = this.focusTrapFactory.create(this.elementRef.nativeElement);
        focusTrap.focusInitialElementWhenReady().then(() => {
          focusTrap.destroy();
        });
      }
    });

    this.nzConfigService
      .getConfigChangeEventForComponent(NZ_CONFIG_MODULE_NAME)
      .pipe(takeUntil(destroy$))
      .subscribe(() => this.thisCdr.markForCheck());

    this.thisDirectionality.change?.pipe(takeUntil(destroy$)).subscribe((direction: Direction) => {
      this.dir = direction;
      this.thisCdr.detectChanges();
    });

    this.dir = this.thisDirectionality.value;
  }
}
