import { IHttpService } from "angular";
import { ThunkAction } from "redux-thunk";
import { EnvironmentService } from "@gtmhub/env";
import { IUIError, createUIError } from "@gtmhub/error-handling";
import { ISocketV2Data } from "@gtmhub/sockets";
import { IdMap } from "@gtmhub/util";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { ICollection } from "@webapp/core/core.models";
import { ITeam } from "..";
import { IErrorReceiveTeamsAction, IReceiveTeamsAction, IRequestTeamsAction, IUpdateTeamAction, IUpdateTeamsAction, TeamsAction } from "./models";
import { ITeamsStoreState } from "./teams-reducer";

const requestTeams = (): IRequestTeamsAction => ({ type: "REQUEST_TEAMS" });
export const receiveTeams = (items: ITeam[]): IReceiveTeamsAction => ({ type: "RECEIVE_TEAMS", items });
const requestTeamsError = (error: IUIError): IErrorReceiveTeamsAction => ({ type: "ERROR_RECEIVE_TEAMS", error });
const updateTeams = (update: ISocketV2Data<ITeam>): IUpdateTeamsAction => ({ type: "UPDATE_TEAMS", update });
const updateTeam = (team: ITeam): IUpdateTeamAction => ({ type: "UPDATE_TEAM", team });
const shouldFetchTeams = (state: ITeamsStoreState) => !state.teams.isFetching && !state.teams.isFetched;

export const addAssigneeDataToTeams = (teams: ITeam[], assignees: IdMap<Assignee>): ITeam[] => {
  if (!teams?.length || !assignees || Object.values(assignees).length === 0) {
    return teams;
  }

  return teams.map((team) => {
    const enriched = {
      ...assignees?.[team.id],
      ...team,
    } as ITeam;

    if (team.members?.length) {
      enriched.membersInfo = team.members.map((id) => assignees?.[id]);
    }

    if (team.manager) {
      enriched.managerInfo = assignees?.[team.manager];
    }

    return enriched;
  });
};

export const addAssigneeDataToTeamsMap = (teams: IdMap<ITeam>, assignees: IdMap<Assignee>): IdMap<ITeam> => {
  if (!teams || Object.values(teams).length === 0 || !assignees || Object.values(assignees).length === 0) {
    return teams;
  }

  const teamItems = Object.values(teams);
  const enrichedTeams = addAssigneeDataToTeams(teamItems, assignees);

  return enrichedTeams.reduce((map, team) => ({ ...map, [team.id]: team }), {});
};

export class TeamsActions {
  public static $inject = ["$http", "EnvironmentService"];

  constructor(
    private $http: IHttpService,
    private env: EnvironmentService
  ) {}

  public updateTeam(team: ITeam): (dispatch) => void {
    return (dispatch) => {
      dispatch(updateTeam(team));
    };
  }

  public updateTeams(update: ISocketV2Data<ITeam>): (dispatch) => void {
    return (dispatch) => {
      dispatch(updateTeams(update));
    };
  }

  public fetchTeamsIfMissing(): ThunkAction<void, ITeamsStoreState, null, TeamsAction> {
    return (dispatch, getState) => {
      if (shouldFetchTeams(getState())) {
        dispatch(requestTeams());

        const url = this.env.getApiEndpointV2("/teams?fields=id,manager,members,parentId,dateCreated,isActive");

        this.$http.get<ICollection<ITeam>>(url).then(
          (response) => dispatch(receiveTeams(response.data.items)),
          (rejection) => dispatch(requestTeamsError(createUIError(rejection)))
        );
      }
    };
  }
}
