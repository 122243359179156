import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiThemeType } from "@quantive/ui-kit/icon";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { getCurrentUserId } from "@gtmhub/users";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { Goal } from "@webapp/okrs/goals/models/goal.models";
import { PiCardBorderStyle } from "../../models/pi-card.models";
import { PiTrackingEventsEnum } from "../../utils/pi-tracking";
import { IPIFeedbackSuggestion } from "./models";
import { PIFeedbackSuggestionSubType, PIFeedbackSuggestionType } from "./services/feedback/models";
import { ImproveSuggestionButtonSpecificDropdownMenuItemsMap } from "./services/improvement/models";
import { generateImproveSuggestionButtonSpecificDropdownItems } from "./services/improvement/utils";
import { PiStateProcessorInstance } from "./services/state-processor/pi-state-processor.models";
import { PiStateProcessorService } from "./services/state-processor/pi-state-processor.service";
import { isSuggestionFeedbackPersisted, removePersistedSuggestionsFeedback, setPersistedSuggestionsFeedback } from "./utils/utils";

@UntilDestroy()
@Component({
  selector: "pi-feedback-card",
  templateUrl: "./pi-feedback-card.component.html",
  styleUrls: ["./pi-feedback-card.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiFeedbackCardComponent implements OnInit {
  @Input() public instance: PiStateProcessorInstance;
  @Input() public goal: Pick<Goal, "id" | "ownerIds" | "sessionId" | "name" | "description">;
  @Input() public entityId: string;
  @Input() public suggestion: IPIFeedbackSuggestion<string | string[]>;
  @Input() public suggestionType: string;
  @Input() public suggestionFeedbackType: PIFeedbackSuggestionType;
  @Input() public suggestionFeedbackSubType: PIFeedbackSuggestionSubType;
  @Input() public borderStyle: PiCardBorderStyle = "solid";
  @Input() public iconType: string;
  @Input() public iconTheme: UiThemeType;
  @Input() public primaryButtonText = "add";
  @Input() public copyBtnTooltipTitle = "copy_answer";
  @Input() @InputBoolean() public isEditBtnAvailable = false;
  @Input() @InputBoolean() public isRemoveBtnAvailable = false;
  @Input() @InputBoolean() public isRemoveBtnAtHeader = false;
  @Input() @InputBoolean() public isIconAvailable = false;
  @Input() @InputBoolean() public isInline = false;
  @Input() @InputBoolean() public isImproveBtnAvailable = true;
  @Input() @InputBoolean() public isRefreshBtnAvailable = true;
  @Input() @InputBoolean() public isCopyBtnAvailable = false;
  @Input() @InputBoolean() public isSql = false;
  @ViewChild("codeEle") public codeEle!: ElementRef<HTMLElement>;

  public improveBtnSpecificDropdownItems: (keyof ImproveSuggestionButtonSpecificDropdownMenuItemsMap)[];
  public isFeedbackActionsSectionAvailable = true;
  public isAskAiVisible = false;

  constructor(
    private cd: ChangeDetectorRef,
    private piStateProcessorService: PiStateProcessorService,
    private analyticsService: AnalyticsService
  ) {}

  public askAi(value: string): void {
    this.instance.improvementSubject.next({ improvement: value, suggestion: this.suggestion });

    this.piStateProcessorService.focusCurrentInstanceAndCloseOthers({ instanceId: this.instance.id, suggestionIndex: this.suggestion.index, isFocused: true });
  }

  public askAiTo(): void {
    this.isAskAiVisible = true;

    this.cd.detectChanges();
  }

  private subscribeToSubjectUpdates(): void {
    this.instance.subject.pipe(untilDestroyed(this)).subscribe({
      next: (data) => {
        if (!data || data.suggestion.id !== this.suggestion.id) {
          return;
        }

        const parentId = this.instance.parentType === "insight" ? this.instance.parentId : this.goal.id;

        if (data.suggestAnother) {
          this.isFeedbackActionsSectionAvailable = true;
          this.cd.detectChanges();

          removePersistedSuggestionsFeedback(data.suggestion?.id, this.instance.type, parentId);
        }

        if (data.addSuggestion || data.removeSuggestion) {
          if (this.instance.subType === "sql") {
            return;
          }

          this.piStateProcessorService.focusCurrentInstanceAndCloseOthers({ instanceId: this.instance.id, suggestionIndex: data.suggestion?.index, isFocused: false });
        }

        if (data.refreshError) {
          this.piStateProcessorService.focusCurrentInstanceAndCloseOthers({ instanceId: this.instance.id, suggestionIndex: data.suggestion?.index, isFocused: true });
        }
      },
    });
  }

  private subscribeToFeedbackSubjectUpdates(): void {
    this.instance.feedbackSubject.pipe(untilDestroyed(this)).subscribe((data) => {
      if (!data || data.suggestion.id !== this.suggestion.id) {
        return;
      }

      if (data.hasProvidedFeedback) {
        this.isFeedbackActionsSectionAvailable = false;
        this.cd.detectChanges();

        const parentId = this.instance.parentType === "insight" ? this.instance.parentId : this.goal.id;

        setPersistedSuggestionsFeedback(data.suggestion?.id, this.instance.type, parentId);
      }
    });
  }

  public ngOnInit(): void {
    this.improveBtnSpecificDropdownItems = generateImproveSuggestionButtonSpecificDropdownItems(this.suggestionFeedbackType, this.suggestionFeedbackSubType);

    this.subscribeToFeedbackSubjectUpdates();
    this.subscribeToSubjectUpdates();

    this.instance.improvementSubject.pipe(untilDestroyed(this)).subscribe({
      next: (data) => data.suggestion.id === this.suggestion.id && this.sendImprovementTracking(data.improvement),
    });

    const parentId = this.instance.parentType === "insight" ? this.instance.parentId : this.goal.id;

    if (this.suggestion) {
      this.isFeedbackActionsSectionAvailable = !isSuggestionFeedbackPersisted(this.suggestion.id, this.instance.type, parentId);
    }
  }

  private sendImprovementTracking(instruction: string): void {
    this.analyticsService.track(PiTrackingEventsEnum.PiSuggestionImproved, {
      suggestionId: this.suggestion.id,
      flowName: this.instance.flowName,
      flowId: this.instance.flowId,
      userId: getCurrentUserId(),
      instruction,
    });
  }

  public removeBtnClicked(): void {
    this.piStateProcessorService.focusCurrentInstanceAndCloseOthers({ instanceId: this.instance.id, suggestionIndex: this.suggestion.index, isFocused: false });
    this.instance.subject.next({ removeSuggestion: true, suggestion: this.suggestion });
    this.cd.detectChanges();
  }
}
