import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class KpiSnapshotsEventsMediatorService {
  private kpiSnapshotDeleted$ = new Subject<{ kpiId: string; snapshotId: string }>();

  public onKpiSnapshotDeleted$(): Observable<{ kpiId: string; snapshotId: string }> {
    return this.kpiSnapshotDeleted$.asObservable();
  }

  public emitKpiSnapshotDeleted(kpiId: string, snapshotId: string): void {
    this.kpiSnapshotDeleted$.next({ kpiId, snapshotId });
  }
}
