import { module } from "angular";
import employeesCore from "@gtmhub/employees/employees-core.module";
import quantivePlusModule from "@gtmhub/goals/components/quantive-plus/quantive-plus.module";
import sessionsCore from "@gtmhub/sessions/sessions-core.module";
import { FirstOkrGuideComponent } from "./components/first-okr-guide";
import onboardingCore from "./onboarding-core.module";

const mod = module("onboarding", [onboardingCore, quantivePlusModule, employeesCore, sessionsCore]);

mod.component("firstOkrGuide", FirstOkrGuideComponent);

export default mod.name;
