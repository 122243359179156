import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IUpgradePlan, IUpgradedEdition } from "@webapp/configuration/models/configuration.model";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { SubscriptionApiService } from "./subscription-api.service";

interface IPortalSession {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  portal_session: string;
}

@Injectable({ providedIn: "root" })
export class SubscriptionRepository {
  constructor(private subscriptionApiService: SubscriptionApiService) {}

  public getPortalSession$(redirectUrl: string): Observable<IPortalSession> {
    const basePath = this.subscriptionApiService.getBasePath(HttpActions.get);
    const queryParams = redirectUrl ? `?redirect_url=${encodeURIComponent(redirectUrl)}` : "";
    const url = basePath + "/portal_session" + queryParams;

    return this.subscriptionApiService.get$<IPortalSession>(null, { url });
  }

  public upgradeEditionPlan$(editionUpgrade: IUpgradePlan): Observable<IUpgradedEdition> {
    const url = this.subscriptionApiService.getBasePath(HttpActions.post) + "/upgrade";

    return this.subscriptionApiService.post$<IUpgradedEdition>(editionUpgrade, { url });
  }
}
