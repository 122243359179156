import { HookMatchCriteria, Transition, TransitionService } from "@uirouter/angular";
import { Observable } from "rxjs";

type Hook = "onCreate" | "onBefore" | "onStart" | "onExit" | "onRetain" | "onEnter" | "onFinish" | "onSuccess" | "onError";

export const fromTransitionHook = (transitionService: TransitionService, hook: Hook, criteria: HookMatchCriteria): Observable<Transition> => {
  return new Observable<Transition>((observer) => {
    const unregister = transitionService[hook](criteria, (transition) => {
      observer.next(transition);
    }) as () => void;
    observer.add(unregister);
  });
};
