import Quill from "quill";
import { OkrLinkClickEvent, getItemTypeToStateParams } from "../events/okr-link.event";
import { OKRLinkItem } from "../rich-text-editor.models";
import { generateOKRIconByType, isNameRestricted, okrMappingRecord } from "../rich-text-editor.utils";
import { OKR_LINK_CLASS } from "./blot-classes";

const Embed = Quill.import("blots/embed");

export class OKRLinkBlot extends Embed {
  constructor(scroll: HTMLElement, node: DOMStringMap) {
    super(scroll, node);

    this.mounted = false;
    this.domNode.addEventListener("click", this.handleClick.bind(this));
  }

  public static create(item: OKRLinkItem): unknown {
    const node: HTMLElement = super.create();
    node.appendChild(OKRLinkBlot.generateOKRLink(item));

    return OKRLinkBlot.setDataValues(node, item);
  }

  public static generateOKRLink(item: OKRLinkItem): HTMLAnchorElement {
    const link = document.createElement("a");
    link.setAttribute("aria-label", `${okrMappingRecord[item.type]} ${decodeURIComponent(item.title)}`);
    link.setAttribute("href", "javascript:void(0);");

    link.appendChild(generateOKRIconByType(item.type, "okr-link-icon"));

    const title = document.createElement("span");

    title.textContent = decodeURIComponent(item.title);
    link.appendChild(title);

    if (isNameRestricted(decodeURIComponent(item.title))) {
      link.style.pointerEvents = "none";
      link.style.color = "inherit";
    } else {
      title.className = "okr-link-title";
    }

    return link;
  }

  public static setDataValues(element: HTMLElement, data: OKRLinkItem): HTMLElement {
    const domNode = element;
    Object.keys(data).forEach((key) => {
      domNode.dataset[key] = data[key];
    });
    return domNode;
  }

  public static value(domNode: HTMLElement): DOMStringMap {
    return domNode.dataset;
  }

  public attach(): void {
    super.attach();

    if (!this.mounted) {
      this.mounted = true;
      this.container = this.domNode.closest(".rich-text-editor-container");
    }
  }

  public detach(): void {
    super.detach();
    this.domNode.removeEventListener("click", this.handleClickBound);
    this.mounted = false;
    this.container = null;
  }

  private handleClick(event: Event): void {
    event.preventDefault();

    if (isNameRestricted(decodeURIComponent(this.domNode.dataset.title))) {
      return;
    }

    const okrLinkClickEvent = new OkrLinkClickEvent("okr-clicked", {
      bubbles: true,
      cancelable: true,
    });

    const itemId = this.domNode.dataset.id;
    const itemType = this.domNode.dataset.type;

    okrLinkClickEvent.stateParams = getItemTypeToStateParams(itemType, itemId);
    this.container?.dispatchEvent(okrLinkClickEvent);
  }
}

OKRLinkBlot.blotName = "okrLink";
OKRLinkBlot.tagName = "span";
OKRLinkBlot.className = OKR_LINK_CLASS;
