import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { Team, TeamDTO } from "../models/teams.models";
import { TeamsState } from "./teams-state.service";

@Injectable({
  providedIn: "root",
})
export class TeamsApiService extends BaseApiService<Team, TeamDTO, TeamsState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: TeamsState) {
    super("teams", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getAllTeamsV2Endpoint(): string {
    return `${this.getBasePath(HttpActions.get, { apiVersionOverwrite: "v2" })}`;
  }

  public getActivateTeamEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}/activate`;
  }

  public getDeactivateTeamEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}/deactivate`;
  }

  public getDeleteTeamEndpoint(teamId: string): string {
    return `${this.getBasePath(HttpActions.post)}/${teamId}`;
  }

  public getTeamEndpoint(teamId: string): string {
    return `${this.getBasePath(HttpActions.get)}/${teamId}`;
  }
}
