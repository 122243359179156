import { LowToHighScaleType } from "../../shared/models/quantive-plus-effectiveness.models";

export const SUGGESTION_STATE_INDICATOR_TOOLTIP_CUSTOM_STYLE = {
  xPosCorrection: "-7px",
};

export const SUGGESTION_STATE_INDICATOR_TOOLTIP_MSG: SuggestionStateIndicatorTooltip = {
  default: "enter_details_to_see_suggestions",
  selected: "hide_suggestions",
  inapplicable: "no_suggestions_available",
  available: "show_suggestions",
  loading: "suggesting",
  error: "unable_to_load_suggestions_please_try_again",
  low: "view_suggestions_to_improve_effectiveness",
  medium: "view_suggestions_to_improve_effectiveness",
  high: "show_suggestions",
} as const;

export const SUGGESTION_STATE_INDICATOR_BACKGROUND_CLASSES: SuggestionStateIndicatorBackground = {
  default: "suggestion-state-indicator-default",
  selected: "suggestion-state-indicator-selected",
  inapplicable: "suggestion-state-indicator-default",
  available: "suggestion-state-indicator-available",
  loading: "suggestion-state-indicator-loading",
  error: "suggestion-state-indicator-error",
  low: "suggestion-state-indicator-low",
  medium: "suggestion-state-indicator-medium",
  high: "suggestion-state-indicator-high",
} as const;

export const SUGGESTION_STATE_INDICATOR_ICONS: SuggestionStateIndicatorIcons = {
  default: "platform-intelligence-default",
  loading: "suggestions-loading",
  error: "caution",
  retry: "refresh",
} as const;

type SuggestionStateIndicatorStatus = "default" | "selected" | "inapplicable" | "available" | "loading" | "error";

type SuggestionStateIndicatorIconStatus = "default" | "loading" | "error" | "retry";

type SuggestionStateIndicatorTooltip = {
  [key in LowToHighScaleType | SuggestionStateIndicatorStatus]: string;
};

type SuggestionStateIndicatorBackground = {
  [key in LowToHighScaleType | SuggestionStateIndicatorStatus]: string;
};

type SuggestionStateIndicatorIcons = {
  [kei in SuggestionStateIndicatorIconStatus]: string;
};
