import { Pipe, PipeTransform } from "@angular/core";
import { formatDateForPipes } from "@webapp/shared/utils/date";

@Pipe({
  name: "dayjsDateRange",
})
export class DayjsDateRangePipe implements PipeTransform {
  // eslint-disable-next-line @foxglove/no-boolean-parameters
  public transform(input: string, format?: string, local?: boolean): string {
    if (!input) {
      return;
    }

    const parts = input.split("|");
    if (parts.length !== 2) {
      return input;
    }

    const startDate = local ? parts[0].replace(".000Z", "") : parts[0];
    const endDate = local ? parts[1].replace(".000Z", "") : parts[1];

    return `${formatDateForPipes(startDate, format)} - ${formatDateForPipes(endDate, format)}`;
  }
}
