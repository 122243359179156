<div [class]="prefixCls">
  <button
    [class]="prefixCls + '-super-prev-btn'"
    [style.visibility]="showSuperPreBtn ? 'visible' : 'hidden'"
    [a11yTitle]="'DatePicker.lang.previousYear' | uiI18n"
    (click)="superPrevious()"
    tabindex="0"
    ui-button>
    <i ui-icon uiType="two-arrows-left-small"></i>
  </button>
  <button [class]="prefixCls + '-prev-btn'" [style.visibility]="showPreBtn ? 'visible' : 'hidden'" [a11yTitle]="'DatePicker.lang.previousMonth' | uiI18n" (click)="previous()" tabindex="0" ui-button>
    <i ui-icon uiType="chevron-left-small"></i>
  </button>

  <div [class]="prefixCls + '-view'" [id]="uiLabelId" aria-live="polite">
    @for(selector of selectors; track selector) {
    <button [class]="selector.className" [attr.aria-label]="selector.ariaLabel" (click)="selector.onClick()" ui-button uiType="link">{{ selector.label }}</button>
    }
  </div>
  <button [class]="prefixCls + '-next-btn'" [style.visibility]="showNextBtn ? 'visible' : 'hidden'" [a11yTitle]="'DatePicker.lang.nextMonth' | uiI18n" (click)="next()" tabindex="0" ui-button>
    <i ui-icon uiType="chevron-right-small"></i>
  </button>
  <button
    [class]="prefixCls + '-super-next-btn'"
    [style.visibility]="showSuperNextBtn ? 'visible' : 'hidden'"
    [a11yTitle]="'DatePicker.lang.nextYear' | uiI18n"
    (click)="superNext()"
    ui-button
    tabindex="0">
    <i ui-icon uiType="two-arrows-right-small"></i>
  </button>
</div>
