import { Component, Input } from "@angular/core";
import { ParentType } from "@gtmhub/automation/controllers/action";
import { QuantivePlusSuggestions } from "@gtmhub/goals/models";
import { Goal } from "@webapp/okrs/goals/models/goal.models";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { IQuantivePlusSuggestionStatus } from "@webapp/platform-intelligence/quantive-plus/models";
import { PiTrackingFlowNameEnum } from "@webapp/platform-intelligence/shared/utils/pi-tracking";
import { Task } from "@webapp/tasks/models/tasks.models";
import { SuggestionDrawerAllSuggestionsTabStatePropagator } from "../../../utils/state-propagator";

@Component({
  selector: "suggestion-drawer-all-suggestions-tab[parentType]",
  templateUrl: "./all-suggestions-tab.component.html",
  styleUrls: ["./all-suggestions-tab.component.less"],
})
export class SuggestionDrawerAllSuggestionsTabComponent {
  @Input() public statePropagator: SuggestionDrawerAllSuggestionsTabStatePropagator;
  @Input() public visibleSections = ["description", "tags", "keyResults", "tasks"];
  @Input() public parentType: ParentType;

  @Input() public suggestions: QuantivePlusSuggestions;
  @Input() public taskList: Task[];

  @Input() public metricsSuggestionStatus: IQuantivePlusSuggestionStatus;
  @Input() public tasksSuggestionStatus: IQuantivePlusSuggestionStatus;

  @Input() public goal: Goal;
  @Input() public metric: Partial<Metric>;
  @Input() public shouldSuggestQuantivePlusDescription: boolean;
  @Input() public shouldSuggestQuantivePlusTags: boolean;
  @Input() public shouldSuggestQuantivePlusMetrics: boolean;
  @Input() public shouldSuggestQuantivePlusTasks: boolean;

  @Input() public flowName: PiTrackingFlowNameEnum.ObjectiveDetailsViewAllSuggestionSidePanel | PiTrackingFlowNameEnum.KeyResultDetailsViewAllSuggestionsSidePanel;
}
