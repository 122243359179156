import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { getAccessToken } from "@gtmhub/auth";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private apiEndpointService: ApiEndpointService) {}

  public intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    return next.handle(this.attachHeaders(request));
  }

  private attachHeaders(request: HttpRequest<object>): HttpRequest<object> {
    if (!this.apiEndpointService.isApiRequest(request.url)) {
      return request;
    }

    const accessToken = getAccessToken();
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}
