import { ComponentRef, EnvironmentInjector, Type, createComponent } from "@angular/core";
import { CustomFieldDynamicComponent, EditableCustomFieldsInput, ICustomField } from "@webapp/custom-fields/models/custom-fields.models";

export abstract class AbstractCustomFieldFactory<T = CustomFieldDynamicComponent> {
  protected constructor(
    private injector: EnvironmentInjector,
    private componentType: Type<T>
  ) {}

  public build(customField: ICustomField, editableCustomFieldInputs: EditableCustomFieldsInput): ComponentRef<T> {
    const componentRef = createComponent(this.componentType, {
      environmentInjector: this.injector,
    });

    this.setComponentInputs(componentRef, customField, editableCustomFieldInputs);
    return componentRef;
  }

  protected abstract setComponentInputs(component: ComponentRef<T>, customField: ICustomField, editableCustomFieldInputs: EditableCustomFieldsInput): void;
}
