import { A11yModule } from "@angular/cdk/a11y";
import { NgIf } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiCardModule } from "@quantive/ui-kit/card";
import { UiDropdownModule } from "@quantive/ui-kit/dropdown";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { filter, fromEvent } from "rxjs";
import { getCurrentUserId } from "@gtmhub/users";
import { TrackingMetadata } from "@webapp/analytics/models/analytics.model";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { ARROW_DOWN, ARROW_UP, ENTER, SPACE, TAB, isKeyOneOf } from "@webapp/shared/utils/keys";
import { UiSelectComponent } from "@webapp/ui/select/select.component";
import { UiSelectModule } from "@webapp/ui/select/select.module";
import { ActiveOkrsListGroup, ActiveOkrsListSort } from "../assignee-active-okrs-list.models";

@UntilDestroy()
@Component({
  selector: "active-okrs-group-sort-dropdown",
  templateUrl: "./active-okrs-group-sort-dropdown.component.html",
  styleUrls: ["./active-okrs-group-sort-dropdown.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, UiButtonModule, UiDropdownModule, UiIconModule, UiCardModule, UiSelectModule, A11yModule, FormsModule, LocalizationModule],
})
export class ActiveOkrsGroupSortDropdownComponent implements AfterViewInit {
  @ViewChild("triggerButton", { read: ElementRef })
  public triggerButton: ElementRef<HTMLButtonElement>;

  @ViewChild("groupBySelect")
  public groupBySelect: UiSelectComponent;

  @Input()
  public group: ActiveOkrsListGroup;

  @Input()
  public sort: ActiveOkrsListSort;

  @Input()
  public trackMeta: TrackingMetadata;

  @Output()
  public readonly groupChange = new EventEmitter<ActiveOkrsListGroup>();

  @Output()
  public readonly sortChange = new EventEmitter<ActiveOkrsListSort>();

  public isDropDownOpen = false;

  /**
   * Tracks whether the dropdown is opened or closed via the keyboard.
   * On such occasion, the focus is moved to the popup content or to the dropdown trigger button.
   */
  private isOpenChangeByKeyboard = false;

  public constructor(
    private zone: NgZone,
    private changeDetector: ChangeDetectorRef,
    private analyticsService: AnalyticsService
  ) {}

  public ngAfterViewInit(): void {
    // if the focus is on the trigger button and the user presses TAB, close the dropdown
    this.zone.runOutsideAngular(() => {
      fromEvent(this.triggerButton.nativeElement, "keydown")
        .pipe(
          filter((event: KeyboardEvent) => event.key === TAB),
          untilDestroyed(this)
        )
        .subscribe(() => {
          this.isDropDownOpen = false;
          this.changeDetector.detectChanges();
        });
    });
  }

  public handleDropDownOpenChange(isOpen: boolean): void {
    if (this.isOpenChangeByKeyboard) {
      if (isOpen) {
        // wait for content render
        window.setTimeoutOutsideAngular(() => this.groupBySelect?.focus());
      } else {
        this.triggerButton.nativeElement.focus();
      }

      this.isOpenChangeByKeyboard = false;
    }
  }

  public handleTriggerButtonKeyDown(event: KeyboardEvent): void {
    if (isKeyOneOf(event.key, [ENTER, SPACE, ARROW_UP, ARROW_DOWN])) {
      this.isOpenChangeByKeyboard = true;
    }

    if (isKeyOneOf(event.key, [ARROW_UP, ARROW_DOWN])) {
      // prevents moving the page scroll down
      event.preventDefault();

      // emulate button click to trigger the uiVisibilityChange event of the dropdown
      this.triggerButton.nativeElement.click();
    }
  }

  public handleDropDownEscapeKeyDown(): void {
    this.isOpenChangeByKeyboard = true;
  }

  public handleGroupChange(group: ActiveOkrsListGroup): void {
    this.groupChange.emit(group);
    this.isDropDownOpen = false;
    this.triggerButton.nativeElement.focus();
    this.analyticsService.track("Menu Item Clicked", this.composeTrackMeta({ group_type: group }));
  }

  public handleSortChange(sort: ActiveOkrsListSort): void {
    this.sortChange.emit(sort);
    this.isDropDownOpen = false;
    this.triggerButton.nativeElement.focus();
    this.analyticsService.track("Menu Item Clicked", this.composeTrackMeta({ sorting_type: sort }));
  }

  private composeTrackMeta(props: object): TrackingMetadata {
    return {
      ...this.trackMeta,
      ...props,
      user_id: getCurrentUserId(),
    };
  }
}
