<div [class.ant-picker-datetime-panel]="showTimePicker">
  <div [class]="prefixCls + '-' + panelMode + '-panel'" [attr.aria-label]="ariaLabelPath | uiI18n" aria-modal="true" role="dialog">
    <ng-container [ngSwitch]="panelMode">
      <ng-container *ngSwitchCase="'decade'">
        <ui-decade-header
          [(value)]="activeDate"
          [locale]="locale"
          [showSuperPreBtn]="enablePrevNext('prev', 'decade')"
          [showSuperNextBtn]="enablePrevNext('next', 'decade')"
          [showNextBtn]="false"
          [showPreBtn]="false"
          [uiLabelId]="headerLabelId"
          (panelModeChange)="panelModeChange.emit($event)"
          (keyup.arrowdown)="datePickerService.focusFirstEnabledCellInTable(elementRef)"
          (valueChange)="headerChange.emit($event)"></ui-decade-header>
        <div [class]="prefixCls + '-body'">
          <ui-decade-table
            [activeDate]="activeDate"
            [value]="value"
            [locale]="locale"
            [disabledDate]="disabledDate"
            [uiTableLabelledBy]="headerLabelId"
            (valueChange)="onChooseDecade($event)"></ui-decade-table>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'year'">
        <ui-year-header
          [(value)]="activeDate"
          [locale]="locale"
          [showSuperPreBtn]="enablePrevNext('prev', 'year')"
          [showSuperNextBtn]="enablePrevNext('next', 'year')"
          [showNextBtn]="false"
          [showPreBtn]="false"
          [uiLabelId]="headerLabelId"
          (panelModeChange)="panelModeChange.emit($event)"
          (keyup.arrowdown)="datePickerService.focusFirstEnabledCellInTable(elementRef)"
          (valueChange)="headerChange.emit($event)">
        </ui-year-header>
        <div [class]="prefixCls + '-body'">
          <ui-year-table
            [activeDate]="activeDate"
            [value]="value"
            [locale]="locale"
            [disabledDate]="disabledDate"
            [selectedValue]="selectedValue"
            [hoverValue]="hoverValue"
            [uiTableLabelledBy]="headerLabelId"
            (valueChange)="onChooseYear($event)"
            (cellHover)="cellHover.emit($event)">
          </ui-year-table>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'month'">
        <ui-month-header
          [(value)]="activeDate"
          [locale]="locale"
          [showSuperPreBtn]="enablePrevNext('prev', 'month')"
          [showSuperNextBtn]="enablePrevNext('next', 'month')"
          [showNextBtn]="false"
          [showPreBtn]="false"
          [uiLabelId]="headerLabelId"
          (panelModeChange)="panelModeChange.emit($event)"
          (keyup.arrowdown)="datePickerService.focusFirstEnabledCellInTable(elementRef)"
          (valueChange)="headerChange.emit($event)"></ui-month-header>
        <div [class]="prefixCls + '-body'">
          <ui-month-table
            [value]="value"
            [activeDate]="activeDate"
            [locale]="locale"
            [disabledDate]="disabledDate"
            [selectedValue]="selectedValue"
            [hoverValue]="hoverValue"
            [uiTableLabelledBy]="headerLabelId"
            (valueChange)="onChooseMonth($event)"
            (cellHover)="cellHover.emit($event)"></ui-month-table>
        </div>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ui-date-header
          [(value)]="activeDate"
          [locale]="locale"
          [showSuperPreBtn]="panelMode === 'week' ? enablePrevNext('prev', 'week') : enablePrevNext('prev', 'date')"
          [showSuperNextBtn]="panelMode === 'week' ? enablePrevNext('next', 'week') : enablePrevNext('next', 'date')"
          [showPreBtn]="panelMode === 'week' ? enablePrevNext('prev', 'week') : enablePrevNext('prev', 'date')"
          [showNextBtn]="panelMode === 'week' ? enablePrevNext('next', 'week') : enablePrevNext('next', 'date')"
          [uiLabelId]="headerLabelId"
          (panelModeChange)="panelModeChange.emit($event)"
          (keyup.arrowdown)="datePickerService.focusFirstEnabledCellInTable(elementRef)"
          (valueChange)="headerChange.emit($event)"></ui-date-header>
        <div [class]="prefixCls + '-body'">
          <ui-date-table
            [uiLocale]="locale"
            [showWeek]="showWeek"
            [value]="value"
            [activeDate]="activeDate"
            [disabledDate]="disabledDate"
            [cellRender]="dateRender"
            [selectedValue]="selectedValue"
            [hoverValue]="hoverValue"
            [canSelectWeek]="panelMode === 'week'"
            [uiTableLabelledBy]="headerLabelId"
            (valueChange)="onSelectDate($event)"
            (cellHover)="cellHover.emit($event)"></ui-date-table>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="showTimePicker && timeOptions">
    <ui-time-picker-panel
      [uiInDatePicker]="true"
      [ngModel]="value?.nativeDate"
      [format]="$any(timeOptions.nzFormat)"
      [uiHourStep]="$any(timeOptions.nzHourStep)"
      [uiMinuteStep]="$any(timeOptions.nzMinuteStep)"
      [uiSecondStep]="$any(timeOptions.nzSecondStep)"
      [uiDisabledHours]="$any(timeOptions.nzDisabledHours)"
      [uiDisabledMinutes]="$any(timeOptions.nzDisabledMinutes)"
      [uiDisabledSeconds]="$any(timeOptions.nzDisabledSeconds)"
      [uiHideDisabledOptions]="!!timeOptions.nzHideDisabledOptions"
      [uiDefaultOpenValue]="$any(timeOptions.nzDefaultOpenValue)"
      [uiUse12Hours]="!!timeOptions.nzUse12Hours"
      [uiAddOn]="$any(timeOptions.nzAddOn)"
      (ngModelChange)="onSelectTime($event)"></ui-time-picker-panel>
    <!--    use [opened] to trigger time panel 'initPosition()'-->
  </ng-container>
</div>
