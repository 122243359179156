import { IAttributes, IAugmentedJQuery, IDirective, IDirectiveFactory, IScope, ITimeoutService } from "angular";

const focusWithTimeout = ($timeout: ITimeoutService, element: IAugmentedJQuery) => $timeout(() => element[0].focus());

export class FocusOn implements IDirective {
  public restrict = "A";

  constructor(private $timeout: ITimeoutService) {}

  public link(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes): void {
    scope.$on(attrs.focusOn, () => focusWithTimeout(this.$timeout, element));
  }

  public static factory(): IDirectiveFactory {
    const directive = ($timeout: ITimeoutService) => new FocusOn($timeout);
    directive.$inject = ["$timeout"];
    return directive;
  }
}

export class FocusMe implements IDirective {
  public restrict = "A";

  constructor(private $timeout: ITimeoutService) {}

  public link(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes): void {
    if (attrs.focusMe) {
      scope.$watch(attrs.focusMe, (value: unknown) => {
        if (value) {
          focusWithTimeout(this.$timeout, element);
        }
      });
    } else {
      focusWithTimeout(this.$timeout, element);
    }
  }

  public static factory(): IDirectiveFactory {
    const directive = ($timeout: ITimeoutService) => new FocusMe($timeout);
    directive.$inject = ["$timeout"];
    return directive;
  }
}
