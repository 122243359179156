import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { NzInputGroupSlotComponent } from "ng-zorro-antd/input";
import { UiGroupType } from "@webapp/ui/input/input.models";

@Component({
  selector: "[ui-input-group-slot]",
  templateUrl: "input-group-slot.component.html",
  styleUrls: ["input-group-slot.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiIconModule, NzOutletModule, NgIf],
})
export class UiInputGroupSlotComponent extends NzInputGroupSlotComponent {
  @Input("uiIcon") public icon?: string | null = null;
  @Input("uiType") public type: UiGroupType = null;
  @Input("uiTemplate") public template?: string | TemplateRef<void> | null = null;
}
