import { NgFor } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UiAccessibilityModule } from "@quantive/ui-kit/accessibility";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiI18nModule } from "@quantive/ui-kit/i18n";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { ɵDateHeaderComponent as DateHeaderComponent } from "ng-zorro-antd/date-picker";
import { UiDateHelperService } from "@webapp/ui/date-picker/services/date-helper.service";
import { UiPanelSelector } from "../../date-picker.models";

@Component({
  selector: "ui-date-header",
  exportAs: "uiDateHeader",
  templateUrl: "../abstract-panel-header.html",
  styleUrls: ["date-header.component.less"],
  host: {
    class: "ui-date-header",
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiAccessibilityModule, NgFor, UiI18nModule, UiIconModule, UiButtonModule],
})
export class UiDateHeaderComponent extends DateHeaderComponent {
  /** the id of the element holding month and year buttons and used to label the table of dates/months/years/decades */
  @Input()
  public uiLabelId: string;

  public selectors: UiPanelSelector[];

  constructor(private thisDateHelper: UiDateHelperService) {
    super(thisDateHelper);
  }

  public getSelectors(): UiPanelSelector[] {
    const selectors = super.getSelectors();
    return [
      {
        ...selectors[0],
        ariaLabel: this.thisDateHelper.format(this.value.nativeDate, "yyyy"),
      },
      {
        ...selectors[1],
        ariaLabel: this.thisDateHelper.format(this.value.nativeDate, "MMMM"),
      },
    ];
  }
}
