import { AnimationEvent } from "@angular/animations";
import { reqAnimFrame } from "ng-zorro-antd/core/polyfill";
import { BaseModalContainerComponent } from "ng-zorro-antd/modal";

export const patchBaseModalContainerComponent = (): void => {
  // There is a bug in ng-zorro when animations are disabled,
  // the focus is not properly set on new modal creation
  const onAnimationDoneOrig = BaseModalContainerComponent.prototype.onAnimationDone;
  BaseModalContainerComponent.prototype.onAnimationDone = function (event: AnimationEvent): void {
    if (this["animationDisabled"]) {
      reqAnimFrame(() => {
        onAnimationDoneOrig.call(this, event);
      });
    } else {
      onAnimationDoneOrig.call(this, event);
    }
  };
};
