<div class="screen-holder" *ngIf="isAccountMarketingInfoFormVisible">
  <div class="form-holder">
    <div class="title-holder">
      <h1 class="title fs-32px">Welcome to Quantive Results!</h1>
      <p class="subtitle fs-14px">
        Let's finish setting up your account. <br class="mobile-break" />
        All fields are required.
      </p>
    </div>
    <account-marketing-info-form (accountUpdated)="onAccountUpdated($event)"> </account-marketing-info-form>
  </div>
</div>
