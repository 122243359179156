import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from "@angular/core";
import { INotification } from "@gtmhub/notifications/models/notifications";

@Directive()
export abstract class InboxTemplateComponent<Notification extends INotification> {
  @Input()
  public notification: Notification;

  @Input()
  @HostBinding("class.expanded")
  public expanded: boolean;

  @Output()
  public readonly toggle = new EventEmitter();

  @HostBinding("class.new")
  public get unseen(): boolean {
    return this.notification && !this.notification.seen;
  }

  @HostListener("click")
  public clicked(): void {
    this.toggle.emit();
  }
}
