import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { UiAssigneeModule } from "@webapp/ui/assignee/assignee.module";
import { UiRadioModule } from "@webapp/ui/radio/radio.module";

@NgModule({
  imports: [FormsModule, UiIconModule, LocalizationModule, UiRadioModule, CommonModule, UiAssigneeModule, AssigneesModule, UiTooltipModule],
})
export class PermissionsModule {}
