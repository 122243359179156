import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from "@angular/core";
import { FormItemBaseComponent } from "@webapp/shared/form/components/form-items/form-item-base.component";
import { NoteWithGifAndMentions } from "@webapp/shared/rich-text-editor-with-mentions/rich-text-editor-with-mentions.component";

@Component({
  selector: "rich-text-editor-with-mentions-form-item",
  templateUrl: "./rich-text-editor-with-mentions-form-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FormItemBaseComponent, useExisting: forwardRef(() => RichTextEditorWithMentionsFormItemComponent) }],
})
export class RichTextEditorWithMentionsFormItemComponent extends FormItemBaseComponent<NoteWithGifAndMentions> {
  @Input() public removeAllMentionsAriaLabel: string;
  @Input() public useGifs = true;
  @Input() public useMentions = true;
  @Input() public useSubmitButton = false;

  @Output() public readonly editorBlur = new EventEmitter<void>();
  @Output() public readonly editorFocus = new EventEmitter<void>();
}
