import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { ICollection } from "@webapp/core/core.models";
import { Parameter, ParameterDTO } from "@webapp/data-story/models/parameters-facade.models";
import { DataSourcesState } from "@webapp/data-story/services/data-sources-facade/data-sources-state.service";
import { ParametersApiService } from "@webapp/data-story/services/parameters-facade/parameters-api.service";
import { ParametersState } from "@webapp/data-story/services/parameters-facade/parameters-state.service";

@Injectable({
  providedIn: "root",
})
export class ParametersFacade extends BaseFacade<Parameter, ParameterDTO, ParametersState, ParametersApiService> {
  constructor(state: DataSourcesState, api: ParametersApiService) {
    super(state, api);
  }

  public getParameters$(): Observable<ICollection<Parameter>> {
    return this.get$<ICollection<Parameter>>(null, {
      ...new RequestConfig(),
      url: this.apiService.getParametersEndpoint(),
    });
  }
}
