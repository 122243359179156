<div class="todos-widget-section">
  <div class="todos-widget-section-title">{{ sectionTitle }}</div>
  <div class="todos-widget-section-row" *ngFor="let todo of todos; trackBy: trackTodosBy" [attr.data-test-id]="'todos-container-' + sectionTitle + '-title-' + todo[nameField]">
    <ui-skeleton-line *ngIf="loadingItemId === todo[idField]" [uiActive]="true" [style]="{ height: '10px' }" />
    <ng-container *ngIf="loadingItemId !== todo[idField]">
      <div class="todos-widget-row-details-container">
        <i [uiType]="iconType" ui-icon uiTheme="fill" uiSize="sm"></i>
        <div
          class="todos-widget-row-label"
          [class.full-width-title]="!hasDueDate(todo)"
          [attr.aria-label]="todo | todoAriaLabel: { type: iconType, nameField: nameField }"
          (keydown.enter)="todoLabelClick.emit(todo)"
          (click)="todoLabelClick.emit(todo)"
          tabindex="0"
          role="link">
          {{ todo[nameField] }}
        </div>
      </div>
      <div [class.todos-widget-row-meta]="hasDueDate(todo)">
        <div class="todos-widget-row-due-date" *ngIf="hasDueDate(todo)" [class.overdue]="todo | isTodoOverdue">{{ todo | todoDueDate }}</div>
        <div class="todos-widget-row-cta-container">
          <ng-template [ngTemplateOutlet]="ctaButtonTemplate" [ngTemplateOutletContext]="{ $implicit: todo }"></ng-template>
        </div>
      </div>
    </ng-container>
  </div>
</div>
