import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import {
  QuantivePlusKeyResultsExplanationPayload,
  QuantivePlusKeyResultsExplanationResponse,
  QuantivePlusObjectivesExplanationPayload,
  QuantivePlusObjectivesExplanationResponse,
  QunativePlusExplanation,
  QunativePlusExplanationPostDTO,
} from "@webapp/platform-intelligence/expandable-suggestions/shared/models/quantive-plus-explanation.models";
import { QuantivePlusExplanationApiService } from "./quantive-plus-explanation-api.service";
import { QuantivePlusExplanationState } from "./quantive-plus-explanation-state.service";

@Injectable({
  providedIn: "any",
})
export class QuantivePlusExplanationFacade extends BaseFacade<
  QunativePlusExplanation,
  QunativePlusExplanationPostDTO,
  QuantivePlusExplanationState,
  QuantivePlusExplanationApiService
> {
  constructor(state: QuantivePlusExplanationState, api: QuantivePlusExplanationApiService) {
    super(state, api);
  }

  public getQuantivePlusObjectivesExplanation(payload: QuantivePlusObjectivesExplanationPayload): Observable<QuantivePlusObjectivesExplanationResponse> {
    return this.post$(payload, { ...new RequestConfig(), url: this.apiService.getObjectivesExplanation() });
  }

  public getQuantivePlusKeyResultsExplanation(payload: QuantivePlusKeyResultsExplanationPayload): Observable<QuantivePlusKeyResultsExplanationResponse> {
    return this.post$(payload, { ...new RequestConfig(), url: this.apiService.getKeyResultsExplanation() });
  }
}
