<div class="centered-vert">
  <i class="icon" [uiType]="icon" uiSize="sm" uiTheme="outline" ui-icon></i>
  <span class="category-name">{{ categoryNameKey | localize }}</span>
</div>

<ul class="ml-22px">
  <ng-container *ngFor="let setting of settingKeys">
    <li class="pt-16px" *ngIf="!setting.isRestricted">
      <notification-settings
        [settingKey]="setting.key"
        [titleKey]="setting.titleKey"
        [inboxValue]="notificationSettings.inboxNotificationSettings.embedded[setting.key]"
        [emailValue]="notificationSettings.emailNotificationSettings.embedded[setting.key]"
        (statusChange)="changeStatus($event)">
      </notification-settings>
    </li>
  </ng-container>
</ul>
