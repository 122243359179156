<button
  #tooltipDirectiveRef="uiTooltip"
  #suggestionStateIndicatorBtn
  [ngClass]="backgroundClass"
  [class.contextual-suggestions-onboarding-visible]="contextualSuggestionsOnboarding && contextualSuggestionsOnboarding.shouldShow"
  [uiTooltipTitle]="tooltip | localize"
  [uiTooltipOverlayStyle]="indicatorTooltipCustomStyle"
  (click)="onButtonClick(tooltipDirectiveRef)"
  uiTooltipPlacement="bottomRight"
  data-test-id="suggestion-state-indicator"
  aria-hidden="true"
  tabindex="-1"
  ui-tooltip>
  <div class="sp-p-8 suggestion-state-indicator-overlay" [class.suggestion-state-indicator-selected]="isSelected">
    <i class="fs-16px" [uiType]="icon" ui-icon></i>
  </div>
</button>
<ng-container *ngIf="onboardingType === 'suggestionStateIndicatorDefault'">
  <onboarding
    *ngIf="onboarding && onboarding.shouldShow"
    [delegate]="onboarding.delegate"
    [steps]="onboarding.totalSteps"
    [startFromStep]="onboarding.startingStep"
    [hideSecondaryButton]="true"
    [hideSteps]="true"
    section="suggestionStateIndicator"></onboarding>
</ng-container>

<ng-container *ngIf="onboardingType === 'piGoalContextualSuggestions' || onboardingType === 'piMetricContextualSuggestions'">
  <onboarding
    *ngIf="contextualSuggestionsOnboarding && contextualSuggestionsOnboarding.shouldShow"
    [delegate]="contextualSuggestionsOnboarding.delegate"
    [steps]="contextualSuggestionsOnboarding.totalSteps"
    [numericSteps]="true"
    [startFromStep]="contextualSuggestionsOnboarding.startingStep"
    [section]="onboardingType"
    [rootMarginData]="{ mt: -suggestionStateIndicatorHeight }"
    [threshold]="0"
    (visibilityChange)="ensureContextualSuggestionsOnboardingIsInViewport({ isVisible: $event, suggestionStateIndicatorBtn })"
    observeUntil="firstValue"
    isInViewport></onboarding>
</ng-container>
