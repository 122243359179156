import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { FavoritesDtoModel, IFavoritesModel } from "./favorites.models";
import { FavoritesState } from "./favorites.state";

@Injectable({
  providedIn: "root",
})
export class FavoritesApiService extends BaseApiService<IFavoritesModel, FavoritesDtoModel, FavoritesState> {
  public constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: FavoritesState) {
    super("uxcustomization/favorite", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public reorderFavoriteItem(favoriteId: string, newOrder: number): Observable<unknown> {
    const url = `${this.getBasePath(HttpActions.patch)}/${favoriteId}/reorder`;

    return this.patch$(favoriteId, { order: newOrder }, { ...new RequestConfig(), url });
  }
}
