import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { requiredStringValidator } from "../../utils";
import { FormItemBaseComponent } from "../form-items/form-item-base.component";

@Component({
  selector: "text-area-form-item",
  templateUrl: "./text-area-form-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FormItemBaseComponent, useExisting: forwardRef(() => TextAreaFormItemComponent) }],
})
export class TextAreaFormItemComponent extends FormItemBaseComponent<string> {
  public readonly requiredValidator = requiredStringValidator;
  public formGroup = new FormGroup({});

  @Input() public maxCharsCount?: number;
}
