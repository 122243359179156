<div class="gif-view">
  <gh-indicator [(ghValue)]="indicators.loading" [ghMessage]="'loading' | localize"></gh-indicator>

  <div *ngIf="gif && gif.id && !isModuleGifsEnabled">[GIF]</div>

  <div class="gif-view" *ngIf="showGif && isModuleGifsEnabled">
    <div class="gif">
      <img [ngClass]="{ responsive: isResponsive }" [src]="gifURL" alt="Quantive" />

      <i *ngIf="showClearBtn" (click)="clearGif()" ui-icon uiType="close-big"></i>
    </div>

    <img class="via-tenor-logo" src="/dist/img/tenor/via_tenor_logo_grey.svg" alt="Quantive" />
  </div>
</div>
