import { DocumentExporterService } from "@gtmhub/document-exporter/document-exporter-service";

interface IEnrichedWidget {
  widget: HTMLElement;
  top: number;
  left: number;
}

export class InsightboardPdfService {
  public static $inject = ["DocumentExporterService"];

  constructor(private documentExporterService: DocumentExporterService) {}

  public generateHtmlPayload(): string {
    // get the widgets from the gridster's list
    const widgets = document.querySelectorAll<HTMLElement>(".gridster > ul > .widget");
    const sortedWidgets = this.sortWidgets(widgets);

    const widgetsHtml: string = this.addStyleToWidgetsAndReturnHtmlV2(sortedWidgets);

    // Construct a whole html doc with required style file in head and widgets in body
    // Note: Backend needs to know the style file's name to apply styles in backend => sent in the doc's head
    const widgetsHtmlDoc = `<!doctype html>
    <html lang="en">
      <head>
        <link rel="stylesheet" href="style.css" type="text/css">
        <link rel="stylesheet" href="fonts.css" type="text/css">
      </head>
      <body>
        <ul>${widgetsHtml}</ul>
      </body>
    </html>`;

    return this.documentExporterService.minifyAndEncodeHtml(widgetsHtmlDoc);
  }

  private sortWidgets = (widgets: NodeListOf<HTMLElement>): HTMLElement[] => {
    const enrichedWidgets: IEnrichedWidget[] = [];

    for (let i = 0; i < widgets.length; i++) {
      const styles = window.getComputedStyle(widgets[i]);
      const top = styles.top;
      const left = styles.left;

      const widget = {
        widget: widgets[i],
        top: top ? Number(top.substr(0, top.length - 2)) : null,
        left: left ? Number(left.substr(0, left.length - 2)) : null,
      };

      if (widget.top && widget.left) {
        enrichedWidgets.push(widget);
      }
    }

    enrichedWidgets.sort(function (a, b) {
      return a.top - b.top || a.left - b.left;
    });

    return enrichedWidgets.map((w) => w.widget);
  };

  private addStyleToWidgetsAndReturnHtmlV2 = (widgets: HTMLElement[]) => {
    let widgetsHtml = "";
    for (let i = 0; i < widgets.length; i++) {
      const currentWidget = widgets[i];
      const pdfColumnWidth = currentWidget.getAttribute("pdf-column-size") || "6";

      this.loadImageSrc(currentWidget, "src");
      this.loadImageSrc(currentWidget, "lazy-src");
      this.addCorrectClipPathForGoogleCharts(currentWidget);

      let widgetHtml = "";
      if (widgets[i].classList.contains("section-header")) {
        const sectionHeaderTitle = document.querySelector<HTMLInputElement>(
          `.section-header[data-widget-id='${currentWidget.dataset["widgetId"]}'] .section-header-title`
        ).value;
        const sectionHeaderSubTitle = document.querySelector<HTMLInputElement>(
          `.section-header[data-widget-id='${currentWidget.dataset["widgetId"]}'] .section-header-sub-title`
        ).value;

        widgetHtml = `<li class="widget gridster-item section-header pdf-col-6"><div class="${currentWidget.firstElementChild.className}"><div class="widget-template"><div class="content"><input class="section-header-title" value="${sectionHeaderTitle}"><input class="section-header-sub-title" value="${sectionHeaderSubTitle}"></div></div></div></li>`;
      } else {
        widgetHtml = `<li class="widget gridster-item pdf-col-${pdfColumnWidth}">${currentWidget.innerHTML}</li>`;
      }

      widgetsHtml += widgetHtml;
    }

    return widgetsHtml;
  };

  private loadImageSrc = (widget: HTMLElement, attrSelector: "src" | "lazy-src"): void => {
    const images = widget.querySelectorAll(`img[${attrSelector}]`);
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      const imageSrc = image.getAttribute(attrSelector);
      const decodedImageSrc = decodeURIComponent(imageSrc);
      // directly pass the avatar url from the query params of the gravatar redirect req
      if (decodedImageSrc.includes("gravatar")) {
        const auth0Avatar = decodedImageSrc.split("d=")[1];
        image.setAttribute("src", auth0Avatar);
      } else if (attrSelector === "lazy-src") {
        // load the lazy-src avatar
        image.setAttribute("src", imageSrc);
      }
    }
  };

  private addCorrectClipPathForGoogleCharts = (widget: HTMLElement): void => {
    const chartSvgs = widget.querySelectorAll<HTMLElement>("g[clip-path]");
    if (chartSvgs.length > 0) {
      chartSvgs.forEach((chartSvg) => {
        const clipPath = chartSvg.getAttribute("clip-path");
        const regex = /https?:\/\/[-a-zA-Z0-9@:%._~#=]{2,256}\//g;
        chartSvg.setAttribute("clip-path", clipPath.replace(regex, ""));
      });
    }
  };
}
