import { Overlay } from "@angular/cdk/overlay";
import { Injectable, Injector, TemplateRef } from "@angular/core";
import { NzSingletonService } from "ng-zorro-antd/core/services";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { UiNotificationData, UiNotificationDataOptions, UiNotificationRef, UiNotificationType } from "@webapp/ui/notification/notification.models";
import { UiNotificationContainerComponent } from "../components/notification-container/notification-container.component";
import { fillDataOptionsNzValues } from "../util/notification.util";

@Injectable()
export class UiNotificationService extends NzNotificationService {
  protected override container: UiNotificationContainerComponent;

  constructor(nzSingletonService: NzSingletonService, overlay: Overlay, injector: Injector) {
    super(nzSingletonService, overlay, injector);
  }

  public success(title: string | TemplateRef<void>, content: string | TemplateRef<void>, options?: UiNotificationDataOptions): UiNotificationRef {
    return this.create("success", title, content, options);
  }

  public error(title: string | TemplateRef<void>, content: string | TemplateRef<void>, options?: UiNotificationDataOptions): UiNotificationRef {
    return this.create("error", title, content, options);
  }

  public info(title: string | TemplateRef<void>, content: string | TemplateRef<void>, options?: UiNotificationDataOptions): UiNotificationRef {
    return this.create("info", title, content, options);
  }

  public warning(title: string | TemplateRef<void>, content: string | TemplateRef<void>, options?: UiNotificationDataOptions): UiNotificationRef {
    return this.create("warning", title, content, options);
  }

  public blank(title: string | TemplateRef<void>, content: string | TemplateRef<void>, options?: UiNotificationDataOptions): UiNotificationRef {
    return this.create("blank", title, content, options);
  }

  public create(
    type: UiNotificationType | string,
    title: string | TemplateRef<void>,
    content: string | TemplateRef<void>,
    options?: UiNotificationDataOptions
  ): UiNotificationRef {
    return this.createNotificationInstance({ type, title, content }, options);
  }

  public template(template: TemplateRef<object>, options?: UiNotificationDataOptions): UiNotificationRef {
    return this.createNotificationInstance({ template }, options);
  }

  public remove(messageId?: string): void {
    this.container.remove(messageId);
  }

  // copy of the original private 'createInstance' method
  // because it only supports the creation of container instances of type NzNotificationContainerComponent
  // the copy supports the creation of container instances of type UiNotification
  private createNotificationInstance(message: UiNotificationData, options?: UiNotificationDataOptions): UiNotificationRef {
    this.container = this.withContainer(UiNotificationContainerComponent);

    return this.container.create({
      ...message,
      ...{
        createdAt: new Date(),
        messageId: options?.nzKey || this.generateMessageId(),
        options: fillDataOptionsNzValues(options),
      },
    });
  }
}
