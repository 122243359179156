<div class="d-flex justify-cont-sb">
  <div class="title">
    {{ titleKey | localize }}
  </div>
  <div class="d-flex">
    <ui-toggle [(ngModel)]="inboxValue" [uiType]="'toggle-text'" (ngModelChange)="patchSettings({ value: $event }, 'inboxNotificationSettings')"></ui-toggle>
  </div>
  <div>
    <ui-toggle [(ngModel)]="emailValue" [uiType]="'toggle-text'" (ngModelChange)="patchSettings({ value: $event }, 'emailNotificationSettings')"></ui-toggle>
  </div>
</div>
