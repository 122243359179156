import { IHttpService, IPromise } from "angular";
import { StateService } from "@uirouter/angularjs";
import { AccountService } from "@gtmhub/accounts/accounts.service";
import { AuthenticationResolverService } from "@gtmhub/auth";
import { IAccount } from "@gtmhub/core";
import { EnvironmentService } from "@gtmhub/env";
import { setCurrentUserId } from "@gtmhub/users";
import { AccountResolverService } from "@webapp/accounts";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";
import { CurrentUserRepository } from "@webapp/users";
import { IUser } from "../../users/models";
import { IUserAccountRequest } from "../models";

const userAlreadyExistsError = "user already exists";

export class InviteUserToAccountService {
  public static $inject = [
    "$state",
    "$http",
    "EnvironmentService",
    "UserProfileService",
    "CurrentUserRepository",
    "AccountService",
    "AccountResolverService",
    "AnalyticsService",
    "AuthenticationResolverService",
  ];

  constructor(
    private $state: StateService,
    private $http: IHttpService,
    private env: EnvironmentService,
    private profileService: UserProfileService,
    private currentUserRepository: CurrentUserRepository,
    private accountService: AccountService,
    private accountResolverService: AccountResolverService,
    private analyticsService: AnalyticsService,
    private authenticationResolverService: AuthenticationResolverService
  ) {}

  public addUserToAccount = (invitationKey: string): void => {
    const userAccountData: IUserAccountRequest = {
      inviteKey: invitationKey,
      createAccount: false,
      clientId: this.authenticationResolverService.getAuthUserId(),
    };

    this.inviteUserInAccount(userAccountData).then(
      (user: IUser) => {
        this.profileService.setProfileFromUser(user);
        this.currentUserRepository.setUserSettings(user);
        this.accountService
          .getAccount(user.accountId)
          .then((account: IAccount) => {
            setCurrentUserId(user.id);
            this.accountResolverService.setAccountData(account);
            this.analyticsService.track("Signed Up", { type: "invite link" });
          })
          .finally(() => {
            this.analyticsService.identify();
            // replace the location to prevent saving this URL in browser history
            this.$state.go("accountBootstrap.resolveAccount", { location: "replace" });
          });
      },
      (err) => {
        if (err.data === userAlreadyExistsError) {
          // usually this error means that the user is already registered but clicked the invite link multiple times
          this.$state.go("accountBootstrap.resolveAccount", { location: "replace" });
        } else {
          this.$state.go("error", {
            error: {
              message: "error_logging_into_account",
              data: err,
            },
          });
        }
      }
    );
  };

  private inviteUserInAccount(userAccountModel: IUserAccountRequest): IPromise<IUser> {
    const url = this.env.getApiEndpoint("/users/invite");

    return this.$http.post<IUser>(url, userAccountModel).then((response) => response.data);
  }
}
