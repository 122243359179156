import { isMsTeamsApp } from "@webapp/shared/utils/msteams";
import { documentReady } from "../dom";
import { isMobile } from "../utils";

documentReady(() => {
  const url = ", app-argument=" + window.location.href;

  const googlePlayEl = document.querySelector<HTMLMetaElement>('meta[name="google-play-app"]');
  googlePlayEl.content = googlePlayEl.content + url;

  const appStoreEl = document.querySelector<HTMLMetaElement>('meta[name="apple-itunes-app"]');
  appStoreEl.content = appStoreEl.content + url;

  /** msteams app submission requires not to show any popups/banners to mobile stores */
  /** download smartbanner.js only for mobile user-agents, since it's only used on such */
  if (isMobile() && !isMsTeamsApp()) {
    import(/* webpackChunkName: "smartbanner" */ "smartbanner.js");
  }
});
