import { IRootScopeService } from "angular";
import { IModalScope, IModalServiceInstance } from "angular-ui-bootstrap";
import { EmailVerificationComponent } from "../models";

interface IVeryfyEmailModalScope extends IModalScope {
  typeEmailVerificationComponent: EmailVerificationComponent;
}

export class RequiredEmailNotificationCtrl {
  public static $inject = ["$scope", "$rootScope", "$uibModalInstance"];

  constructor($scope: IVeryfyEmailModalScope, $rootScope: IRootScopeService, $uibModalInstance: IModalServiceInstance) {
    $scope.typeEmailVerificationComponent = "button";

    const unregister = $rootScope.$watch("emailVerified", (emailVerified) => {
      if (emailVerified) {
        $uibModalInstance.close();
      }
    });

    $uibModalInstance.result.finally(() => {
      unregister();
    });
  }
}
