import { IRootScopeService, IScope } from "angular";
import { IModalScope } from "angular-ui-bootstrap";
import { ITeam } from "./models/models";

export enum TeamEventType {
  TEAM_UPDATED = "teamUpdated",
  TEAM_DELETED = "teamDeleted",
  TEAM_CREATED = "teamCreated",
  TEAM_VISITED = "TEAM_VISITED",
}

export interface ITeamEventArgs {
  team: ITeam;
}

export interface ITeamsSelectedEventArgs {
  teams: ITeam[];
  isSingleTeam: boolean;
}

export interface ITeamDeletedArgs {
  teamId: string;
  parentId?: string;
}

export class TeamEvents {
  private $rootScope: IRootScopeService;
  constructor(private $scope: IModalScope | IScope) {
    this.$rootScope = this.$scope.$root;
  }

  public broadcastTeamCreated(options: ITeamEventArgs): void {
    this.$rootScope.$broadcast(TeamEventType.TEAM_CREATED, options);
  }

  onTeamCreated(callback: (args: ITeamEventArgs) => unknown): void {
    this.$scope.$on(TeamEventType.TEAM_CREATED, (evt, args: ITeamEventArgs) => callback(args));
  }

  public broadcastTeamUpdated(options: ITeamEventArgs): void {
    this.$rootScope.$broadcast(TeamEventType.TEAM_UPDATED, options);
  }

  onTeamUpdated(callback: (args: ITeamEventArgs) => unknown): void {
    this.$scope.$on(TeamEventType.TEAM_UPDATED, (evt, args: ITeamEventArgs) => callback(args));
  }

  public broadcastTeamDeleted(options: ITeamDeletedArgs): void {
    this.$rootScope.$broadcast(TeamEventType.TEAM_DELETED, options);
  }

  onTeamDeleted(callback: (args: ITeamDeletedArgs) => unknown): void {
    this.$scope.$on(TeamEventType.TEAM_DELETED, (evt, args: ITeamDeletedArgs) => callback(args));
  }

  public broadcastTeamsSelected(options: ITeamsSelectedEventArgs): void {
    this.$rootScope.$broadcast("teamsSelected", options);
  }

  onTeamsSelected(callback: (args: ITeamsSelectedEventArgs) => unknown): void {
    this.$scope.$on("teamsSelected", (evt, args: ITeamsSelectedEventArgs) => callback(args));
  }

  public broadcastTeamVisited(team: ITeam): void {
    this.$rootScope.$broadcast(TeamEventType.TEAM_VISITED, {
      team,
    });
  }
}
