import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IUIError } from "@gtmhub/error-handling";
import { Intercom } from "@gtmhub/shared/intercom";
import { IErrorOptions } from "./error-view.models";

@Component({
  selector: "error-view",
  templateUrl: "./error-view.component.html",
  styleUrls: ["./error-view.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorViewComponent {
  @Input()
  public error: IUIError;

  @Input()
  public errorOptions?: IErrorOptions;

  public showIntercom(): void {
    Intercom("show");
  }
}
