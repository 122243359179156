import { CheckInsWidgetComponent } from "./check-ins-widget/check-ins-widget.component";
import { KickstartOkrWidgetComponent } from "./kickstart-okr-widget/kickstart-okr-widget.component";
import { MyOkrsWidgetComponent } from "./my-okrs-widget/my-okrs-widget.component";
import { OnboardingWidgetComponent } from "./onboarding-widget/onboarding-widget.component";
import { TodosWidgetComponent } from "./todos-widget/todos-widget.component";
import { ViewAlignmentWidgetComponent } from "./view-alignment-widget/view-alignment-widget.component";
import { ViewFeedWidgetComponent } from "./view-feed-widget/view-feed-widget.component";
import { ViewOnlyRoleWidgetComponent } from "./view-only-role-widget/view-only-role-widget.component";
import { WhiteboardsWidgetComponent } from "./whiteboards-widget/whiteboards-widget.component";

export const WIDGET_COMPONENTS = [
  MyOkrsWidgetComponent,
  CheckInsWidgetComponent,
  TodosWidgetComponent,
  WhiteboardsWidgetComponent,
  ViewAlignmentWidgetComponent,
  ViewFeedWidgetComponent,
  KickstartOkrWidgetComponent,
  OnboardingWidgetComponent,
  ViewOnlyRoleWidgetComponent,
];
