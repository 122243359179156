<suggestion-drawer-content-card classes="sp-py-6 sp-px-10">
  <pi-loading-card-v2 *ngIf="isLoading" [suggestion]="feedbackSuggestion" [instance]="tagsInstance" [isIconAvailable]="false"></pi-loading-card-v2>

  <pi-clickable-error-card *ngIf="isError" [suggestion]="feedbackSuggestion" [isRemoveBtnAvailable]="false" [instance]="tagsInstance" [errorData]="errorData"> </pi-clickable-error-card>

  <pi-feedback-card
    *ngIf="!isLoading && !isError"
    [goal]="goal"
    [suggestion]="feedbackSuggestion"
    [instance]="tagsInstance"
    suggestionFeedbackType="objective"
    suggestionFeedbackSubType="tags"
    data-test-id="okr-suggestion-item-tags">
  </pi-feedback-card>
</suggestion-drawer-content-card>
