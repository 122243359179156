import { Injectable } from "@angular/core";
import { byDateField } from "@gtmhub/util/sorting-utils";
import { PlanningSessionStatus, Session } from "@webapp/sessions/models/sessions.model";
import dayjs from "@webapp/shared/libs/dayjs";
import { CurrentUserRepository } from "@webapp/users";

@Injectable()
export class DefaultSessionResolverService {
  constructor(private currentUserRepository: CurrentUserRepository) {}

  public getDefaultSession(sessions: Session[]): Session | undefined {
    return this.getClosestOpenActiveSession(sessions) || this.getClosestOpenFutureSession(sessions);
  }

  private getClosestOpenActiveSession(sessions: Session[]): Session | undefined {
    const activeSessionsSortedByEndDateAsc = this.filterOpenActiveSessionsWithPermissionToCreate(sessions).sort(byDateField("end"));
    return activeSessionsSortedByEndDateAsc[0];
  }

  private getClosestOpenFutureSession(sessions: Session[]): Session | undefined {
    const futureSessionsSortedByStartDateAsc = this.filterOpenFutureSessionsWithPermissionToCreate(sessions).sort(byDateField("start"));
    return futureSessionsSortedByStartDateAsc[0];
  }

  private filterOpenActiveSessionsWithPermissionToCreate(sessions: Session[]): Session[] {
    const now = dayjs.utc();
    return sessions.filter(
      (session) =>
        session.status === PlanningSessionStatus.OPEN &&
        now.isSameOrAfter(session.start) &&
        now.isSameOrBefore(session.end) &&
        this.currentUserRepository.allowedActionsSync(session.access).includes("create")
    );
  }

  private filterOpenFutureSessionsWithPermissionToCreate(sessions: Session[]): Session[] {
    const now = dayjs.utc();
    return sessions.filter(
      (session) =>
        session.status === PlanningSessionStatus.OPEN &&
        now.isSameOrBefore(session.start) &&
        this.currentUserRepository.allowedActionsSync(session.access).includes("create")
    );
  }
}
