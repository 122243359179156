<div class="boxes-container">
  <div [uiGutter]="[30, 30]" ui-row>
    <div *ngFor="let infoBox of infoBoxes" [uiXl]="12" [uiLg]="24" ui-col>
      <div class="info-box" ui-row>
        <div uiFlex="3" ui-col>
          <div class="text-container">
            <section id="info-box-section" [attr.tabindex]="infoBox.videoUrl ? -1 : 0">
              <div class="infobox-title-video" *ngIf="infoBox.videoUrl">
                <h3 class="infobox-title">🎬</h3>
                <h3
                  class="infobox-title video-link"
                  [attr.aria-roledescription]="'chip_aria_description' | localize"
                  (mousedown)="openVideo(infoBox.videoUrl)"
                  tabindex="0"
                  role="link"
                  aria-describedby="info-box-section">
                  {{ infoBox.title }}
                </h3>
              </div>
              <h3 class="infobox-title" *ngIf="!infoBox.videoUrl && !infoBox.titleUrl">{{ infoBox.title }}</h3>
              <h3 class="infobox-title-link" *ngIf="!infoBox.videoUrl && infoBox.titleUrl">
                <a class="link" [attr.href]="infoBox.titleUrl" (mousedown)="marketplaceRedirectHandle()" (keydown.enter)="marketplaceRedirectHandle()" target="_blank" role="link" tabindex="0"
                  >{{ infoBox.title }}&nbsp;<i ui-icon uiType="external-link" uiTheme="outline"></i
                ></a>
              </h3>
              <p class="infobox-subtext">{{ infoBox.subtext }}</p>
              <div class="video-duration" *ngIf="infoBox.videoDuration">
                <i class="icon" ui-icon uiTheme="outline" uiType="clock"></i>
                <div class="minutes">{{ infoBox.videoDuration }} min</div>
              </div>
            </section>
          </div>
        </div>
        <div ui-col uiFlex="1" ui-row uiJustify="end">
          <img class="box-icon" [attr.src]="infoBox.icon" alt="" />
        </div>
      </div>
    </div>
  </div>
</div>
