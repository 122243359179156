import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { NzFormatEmitEvent, NzTreeNodeOptions } from "ng-zorro-antd/tree";
import { BehaviorSubject, Observable, take } from "rxjs";
import { IAssigneesStoreState } from "@gtmhub/assignees";
import { ISessionsStoreState } from "@gtmhub/sessions/redux/session-reducer";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { toIdMap } from "@gtmhub/util";
import { IUXCustomizationGoalResponse, IUXCustomizationKpiResponse } from "@gtmhub/uxcustomization/models";
import { IViewHistoryGoal, IViewHistoryKpi } from "@gtmhub/view-history";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { sortAssigneeIdsByActiveStatus } from "@webapp/assignees/utils/assignee.utils";
import { ReduxStoreObserver } from "@webapp/core/state-management/redux-store-observer";
import { Kpi } from "@webapp/kpis/models/kpis.models";
import { ILinksSpecDetail, Link, RelatedGoalOrMetricLink } from "@webapp/links/models/links.models";
import { IRelatedItemsBaseEntry, IRelatedItemsSelectorEmission, IRelatedItemsSelectorNode, RelatedItemType } from "@webapp/links/models/related-items.models";
import { Goal } from "@webapp/okrs/goals/models/goal.models";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { RelatedItemsFacade } from "@webapp/okrs/services/related-items-selector-facade.service";
import { sectionNodeRelatedItems } from "@webapp/okrs/utils/okr-and-kpi-selector-nodes-builder.util";
import { sortMetricsForEveryGoalByMostRecent } from "@webapp/okrs/utils/utils";
import { IPlanningSessionsIdMap } from "@webapp/sessions/models/sessions.model";
import { filterArchivedSessions } from "@webapp/sessions/utils/utils";
import { IMultiSelectorNodesType } from "@webapp/shared/components/multi-selector/multi-selector.models";

@UntilDestroy()
@Component({
  selector: "related-items-selector",
  templateUrl: "./related-items-selector.component.html",
  styleUrls: ["./related-items-selector.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedItemsSelectorComponent implements OnInit {
  @Input() public currentItem: Goal | Metric | Kpi;
  @Input() public relationsToExclude: Set<Link>;
  @Input() public allowedTypes: RelatedItemType[] = ["goal", "metric", "kpi"];
  @Input() public triggerOpen = true;
  @Output() public readonly relatedItemChange = new EventEmitter<IRelatedItemsSelectorEmission>();

  public nodesLoading: boolean;
  public searchFn: (searchTerm: string) => Observable<NzTreeNodeOptions[]>;
  private nodesSubject$ = new BehaviorSubject<IRelatedItemsSelectorNode[]>([]);
  public get nodes$(): Observable<IRelatedItemsSelectorNode[]> {
    return this.nodesSubject$.asObservable();
  }
  private allGoals: Map<string, IRelatedItemsSelectorNode> = new Map();
  private suggestedItems: IRelatedItemsSelectorNode[] = [];
  private archivedSessionsIdMap: IPlanningSessionsIdMap;
  private assigneesMap: Record<string, Assignee>;
  public searchConfig: { idsToExclude: string[] };

  constructor(
    private relatedItemsFacade: RelatedItemsFacade,
    private analyticsService: AnalyticsService
  ) {}

  public ngOnInit(): void {
    this.setSearchConfig();
    this.searchFn = this.createSearchFunc();
    const reduxObserver = new ReduxStoreObserver(reduxStoreContainer.reduxStore);
    reduxObserver
      .whenFetched$<ISessionsStoreState & IAssigneesStoreState>("sessions", "assignees")
      .pipe(take(1), untilDestroyed(this))
      .subscribe((state) => {
        this.archivedSessionsIdMap = toIdMap(filterArchivedSessions(state.sessions.items));
        this.assigneesMap = state.assignees.map;
      });
  }

  public get multiSelectorNodesType(): IMultiSelectorNodesType {
    return this.allowedTypes.indexOf("kpi") !== -1 ? IMultiSelectorNodesType.KPI : IMultiSelectorNodesType.OKR;
  }

  public onUpdatedValue(value: IRelatedItemsSelectorNode): void {
    if (!value) {
      this.relatedItemChange.emit(null);
      return;
    }

    const objectToEmit: IRelatedItemsSelectorEmission = {
      id: value.key,
      name: value.title,
      type: value.type,
      ownerIds: value.ownerIds,
      isSuggestion: value.isSuggestion,
    };

    if (value.isSuggestion && !this.currentItem) {
      this.analyticsService.track("Related item suggestion selected", { item_type: value.type });
    }

    this.relatedItemChange.emit(objectToEmit);
  }

  private get idsToExclude(): string[] {
    if (!this.currentItem) {
      return [];
    }
    const keys = this.currentItem?.links?.spec && (Object.keys(this.currentItem.links.spec) as RelatedGoalOrMetricLink[]);

    const alreadyRelatedItemsIds =
      keys &&
      keys.reduce((acc, key) => {
        if (!this.relationsToExclude.has(key)) {
          const linkIds = this.currentItem.links.spec[key].map((link: ILinksSpecDetail<RelatedItemType>) => link.id);
          acc = acc.concat(linkIds);
        }
        return acc;
      }, []);

    const otherIdsToExclude = [this.currentItem.id];
    if ("goalId" in this.currentItem) {
      otherIdsToExclude.push(this.currentItem.goalId);
    }

    return !alreadyRelatedItemsIds ? otherIdsToExclude : alreadyRelatedItemsIds.concat(otherIdsToExclude);
  }

  public onOpenChange(isOpen: boolean): void {
    if (isOpen) {
      this.resetSuggestedItems();
      this.setSuggestedItems();
    }
  }

  private resetSuggestedItems(): void {
    this.allGoals.clear();
    this.suggestedItems = [];
    this.emitNodes();
  }

  private setSuggestedItems(): void {
    this.nodesLoading = true;
    this.relatedItemsFacade
      .getAllSuggestions$(this.idsToExclude, this.allowedTypes)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        const { recentGoals, missingGoals, allMetrics, recentKpis } = data;

        // Make all items into a tree nodes
        const recentGoalsAsNodes: IRelatedItemsSelectorNode[] = this.populateAllGoalsMapAndGetRecentGoalNodes(missingGoals, recentGoals);
        const recentMetricsAsNodes: IRelatedItemsSelectorNode[] = this.populateMetricNodesForEveryGoalAndGetRecentMetricNodes(allMetrics);
        const recentKpisAsNodes: IRelatedItemsSelectorNode[] = this.getKpiNodes(recentKpis);

        // Sort the metrics of every goal in place
        sortMetricsForEveryGoalByMostRecent(recentGoalsAsNodes);

        const itemsSortedByMostRecent: IRelatedItemsSelectorNode[] = recentGoalsAsNodes
          .concat(recentMetricsAsNodes, recentKpisAsNodes)
          .sort((a, b) => new Date(b.lastVisited).getTime() - new Date(a.lastVisited).getTime());

        const addedGoals = new Set<string>();
        for (const item of itemsSortedByMostRecent) {
          if (item.type === "goal" && !addedGoals.has(item.key)) {
            this.suggestedItems.push(item);
            addedGoals.add(item.key);
            continue;
          }

          if (item.type === "metric" && !addedGoals.has(item.goalId)) {
            this.suggestedItems.push(this.allGoals.get(item.goalId));
            addedGoals.add(item.goalId);
          }

          if (item.type === "kpi") {
            this.suggestedItems.push(item);
          }
        }

        this.emitNodes();
      });
  }

  private populateAllGoalsMapAndGetRecentGoalNodes(missingGoals: Goal[], recentGoals: IViewHistoryGoal[] = []): IRelatedItemsSelectorNode[] {
    const recentGoalsAsNodes: IRelatedItemsSelectorNode[] = [];

    recentGoals.forEach(({ lastVisited, goal }) => {
      if (this.allGoals.has(goal.id)) {
        return;
      }

      const newGoal: IRelatedItemsSelectorNode = this.createRelatedItemsSelectorGoalNode(goal, lastVisited);
      newGoal.ownerIds = sortAssigneeIdsByActiveStatus(newGoal.ownerIds, this.assigneesMap);
      recentGoalsAsNodes.push(newGoal);
      this.allGoals.set(newGoal.key, newGoal);
    });

    missingGoals.forEach((goal) => {
      if (this.allGoals.has(goal.id)) {
        return;
      }

      const newGoal: IRelatedItemsSelectorNode = this.createRelatedItemsSelectorGoalNode(goal);
      newGoal.ownerIds = sortAssigneeIdsByActiveStatus(newGoal.ownerIds, this.assigneesMap);
      newGoal.readonly = this.idsToExclude.includes(goal.id);
      this.allGoals.set(newGoal.key, newGoal);
    });

    return recentGoalsAsNodes;
  }

  private populateMetricNodesForEveryGoalAndGetRecentMetricNodes(metrics: Metric[]): IRelatedItemsSelectorNode[] {
    return metrics.reduce((metricNodes: IRelatedItemsSelectorNode[], metric: Metric) => {
      const newMetric: IRelatedItemsSelectorNode = this.createRelatedItemsSelectorMetricNode(metric);

      newMetric.ownerIds = sortAssigneeIdsByActiveStatus(newMetric.ownerIds, this.assigneesMap);
      const goalOfCurrMetric = this.allGoals.get(metric.goalId);
      if (goalOfCurrMetric) {
        goalOfCurrMetric.children.push(newMetric);
        goalOfCurrMetric.isLeaf = false;
        goalOfCurrMetric.expanded = goalOfCurrMetric.children.some((metric) => metric.isRecentItem);
      }

      if (newMetric.isRecentItem) {
        metricNodes.push(newMetric);
      }

      return metricNodes;
    }, []);
  }

  private setSearchConfig(): void {
    this.searchConfig = { idsToExclude: this.idsToExclude };
  }

  private createSearchFunc(): (searchTerm: string) => Observable<NzTreeNodeOptions[]> {
    // This construction is necessary to preserve the proper `this` binding
    return (searchTerm: string) => {
      return this.relatedItemsFacade.getRelatedItemsBySearchTerm$({ ...this.searchConfig, searchTerm, itemTypes: this.allowedTypes });
    };
  }

  private emitNodes(): void {
    const topNode = sectionNodeRelatedItems("RECENTLY VISITED", this.suggestedItems);

    if (this.suggestedItems.length) {
      this.nodesSubject$.next([topNode]);
    } else {
      this.nodesSubject$.next([]);
    }

    this.nodesLoading = false;
  }

  public onExpandChange(evt: NzFormatEmitEvent): void {
    evt.node.origin.hasBeenCollapsed = true;
    evt.node.origin.children.forEach((node) => {
      node.hidden = false;
    });
  }

  private createBaseRelateItemsSelectorNode(item: IRelatedItemsBaseEntry): IRelatedItemsSelectorNode {
    return {
      key: item.id,
      type: item.type,
      title: item.name,
      ownerIds: item.ownerIds,
      isLeaf: true,
      icon: item.type,
      isSuggestion: true,
      isFromArchivedSession: !!this.archivedSessionsIdMap[item.sessionId],
    };
  }

  private createRelatedItemsSelectorGoalNode(goal: IRelatedItemsBaseEntry | IUXCustomizationGoalResponse | Goal, lastVisited?: string): IRelatedItemsSelectorNode {
    const newGoal = this.createBaseRelateItemsSelectorNode({ ...goal, type: "goal" });
    newGoal.children = [];
    newGoal.hasBeenCollapsed = false;

    if (lastVisited) {
      newGoal.lastVisited = lastVisited;
      newGoal.isRecentItem = true;
    }

    return newGoal;
  }

  private createRelatedItemsSelectorMetricNode(metric: (IRelatedItemsBaseEntry & { goalId: string }) | Metric): IRelatedItemsSelectorNode {
    const newMetric = this.createBaseRelateItemsSelectorNode({ ...metric, type: "metric" });
    newMetric.goalId = metric.goalId;
    newMetric.hidden = true;

    if (this.relatedItemsFacade.recentMetricsMap.has(metric.id)) {
      newMetric.isRecentItem = true;
      newMetric.hidden = false;
      newMetric.lastVisited = this.relatedItemsFacade.recentMetricsMap.get(metric.id).lastVisited;
    }

    return newMetric;
  }

  private createRelatedItemsSelectorKpiNode(kpi: IUXCustomizationKpiResponse | Kpi, lastVisited?: string): IRelatedItemsSelectorNode {
    const newKpi = this.createBaseRelateItemsSelectorNode({ ...kpi, type: "kpi" });
    newKpi.isRecentItem = true;
    newKpi.lastVisited = lastVisited;

    return newKpi;
  }

  private getKpiNodes(recentKpis: IViewHistoryKpi[] = []): IRelatedItemsSelectorNode[] {
    recentKpis = recentKpis.filter((kpi, index, self) => index === self.findIndex((t) => t.itemId === kpi.itemId));
    recentKpis.forEach((recentItem) => (recentItem.kpi.ownerIds = sortAssigneeIdsByActiveStatus(recentItem.kpi.ownerIds, this.assigneesMap)));
    const recentKpisAsNodes: IRelatedItemsSelectorNode[] = [];

    recentKpis.forEach(({ lastVisited, kpi }) => {
      const newKpiNode: IRelatedItemsSelectorNode = this.createRelatedItemsSelectorKpiNode(kpi, lastVisited);

      recentKpisAsNodes.push(newKpiNode);
    });

    return recentKpisAsNodes;
  }
}
