import { TaskFilterModalService } from "@gtmhub/tasks/services/task-filter-modal.service";
import { TaskService } from "@gtmhub/tasks/services/task.service";
import { providerUpgradeFactory } from "@webapp/core/provider-upgrade/factories/provider-upgrade.factory";

export const TasksServiceProvider = {
  provide: TaskService,
  useFactory: providerUpgradeFactory("TaskService"),
  deps: ["$injector"],
};

export const TaskFilterServiceProvider = {
  provide: TaskFilterModalService,
  useFactory: providerUpgradeFactory("TaskFilterModalService"),
  deps: ["$injector"],
};
