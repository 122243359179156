import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject } from "rxjs";
import { IAssigneesStoreState } from "@gtmhub/assignees";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { ITeamsStoreState } from "@gtmhub/teams";
import { Goal } from "@webapp/okrs/goals/models/goal.models";
import { generateQuantivePlusObjectiveDescriptionSuggestionPayload } from "@webapp/platform-intelligence/quantive-plus/utils/utils";
import { generateImprovementPayload } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/improvement/utils";
import { PiStateProcessorInstance } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.models";
import { PiStateProcessorService } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.service";
import { IQuantivePlusObjectiveDescription } from "@webapp/platform-intelligence/shared/models";
import { PiErrorData } from "@webapp/platform-intelligence/shared/models/pi-errors.models";
import { PIObjectiveDescriptionSuggestion } from "@webapp/platform-intelligence/shared/models/strategic-guided-okr.models";
import { PiSuggestionSharedGoalStateService } from "@webapp/platform-intelligence/shared/services/pi-suggestion-shared-goal-state.service";
import { ObjectiveFlowName, PiTrackingFlowNameEnum } from "@webapp/platform-intelligence/shared/utils/pi-tracking";
import { SuggestionDrawerAllSuggestionsTabStatePropagator } from "../../../../utils/state-propagator";

@UntilDestroy()
@Component({
  selector: "suggestion-drawer-description[goal]",
  templateUrl: "./description.component.html",
  styleUrls: ["./description.component.less"],
})
export class SuggestionDrawerDescriptionComponent implements OnInit, OnDestroy {
  @Input() public goal: Goal;
  @Input() public statePropagator: SuggestionDrawerAllSuggestionsTabStatePropagator;

  public description: string;
  public isLoading = true;
  public isError: boolean;
  public errorData: PiErrorData;
  public descriptionV2: { id: string; text: string };
  public descriptionInstance: PiStateProcessorInstance;
  public onDestroy$ = new Subject<void>();

  private flowId: string;
  private flowName: ObjectiveFlowName = PiTrackingFlowNameEnum.ObjectiveDetailsViewAllSuggestionSidePanel;
  private currentDescriptionSuggestions: IQuantivePlusObjectiveDescription[];
  private suggestion: PIObjectiveDescriptionSuggestion;
  private lastAvailableSuggestion: PIObjectiveDescriptionSuggestion;
  private descriptionSuggestionId: string;

  constructor(
    private piSuggestionSharedStateService: PiSuggestionSharedGoalStateService,
    private piStateProcessorService: PiStateProcessorService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.descriptionInstance = this.piStateProcessorService.createNewInstance({
      type: "goal",
      subType: "description",
      isInline: false,
      iconType: "goal",
      iconTheme: "fill",
      improveSuggestionContext: {
        entityId: this.goal.id,
        parentId: this.goal.parentId,
        parentType: "objective",
      },
      destroyOn$: this.onDestroy$,
    });

    this.subscribeToDescriptionSubject();
    this.subscribeToDescriptionImprovement();

    this.piSuggestionSharedStateService
      .getDescription$()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data) => {
          this.isLoading = data.isLoading;
          this.isError = data.isError;
          this.errorData = data.errorData;
          this.description = data.value;
          this.suggestion = data.suggestion;

          this.cdr.markForCheck();

          if (!data.suggestion) {
            return;
          }

          this.descriptionV2 = {
            text: data.suggestion.suggestions[0].descriptions[0].description,
            id: data.suggestion.suggestions[0].descriptions[0].id,
          };

          this.statePropagator.emitStateChange({ isLoading: this.isLoading });

          if (data.suggestion) {
            this.flowId = data.suggestion.flowId;
            this.currentDescriptionSuggestions = data.suggestion.suggestions[0].descriptions;
            this.descriptionSuggestionId = data.suggestion.suggestions[0].descriptions[0].id;
            this.lastAvailableSuggestion = data.suggestion;
          }

          this.piStateProcessorService.assigneInstanceFlowProperties(this.descriptionInstance.id, {
            flowId: this.flowId,
            flowName: this.flowName,
          });
        },
      });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  private subscribeToDescriptionSubject(): void {
    this.descriptionInstance.subject.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data.suggestAnother) {
        this.getQuantivePlusDescriptionSuggestion({ isSuggestAnother: true });
      }

      if (data.refreshError) {
        this.getQuantivePlusDescriptionSuggestion({ isSuggestAnother: false });
      }

      if (data.addSuggestion) {
        this.onDescriptionSuggestionAdded();
      }

      if (data.goBackToSuggestion) {
        this.piSuggestionSharedStateService.goBackToLastAvailableDescriptionSuggestion(this.lastAvailableSuggestion);
      }
    });
  }

  private subscribeToDescriptionImprovement(): void {
    this.descriptionInstance.replacementSuggestionSubject.pipe(untilDestroyed(this)).subscribe((data) => {
      this.piSuggestionSharedStateService.replaceDescriptionSuggestion({
        id: data.newSuggestion.id,
        description: data.newSuggestion.text,
        suggestion: this.suggestion,
      });
    });

    this.descriptionInstance.improvementSubject.pipe(untilDestroyed(this)).subscribe((data) => {
      const payload = generateImprovementPayload({
        subEntityType: this.descriptionInstance.subType,
        instruction: data.improvement,
        suggestion: {
          description: this.description,
        },
        isAskAI: true,
        improveSuggestionContext: this.descriptionInstance.improveSuggestionContext,
      });

      this.piSuggestionSharedStateService.postDescriptionImprovement(payload, this.suggestion);
    });
  }

  public getQuantivePlusDescriptionSuggestion(args: { isSuggestAnother: boolean }): void {
    const state = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState & ITeamsStoreState>();

    const payload = generateQuantivePlusObjectiveDescriptionSuggestionPayload({
      goal: this.goal,
      assigneesMap: state.assignees.map,
      teams: state.teams.items,
      ...(args.isSuggestAnother && {
        isRefresh: true,
        rejectedDescriptions: this.currentDescriptionSuggestions,
      }),
    });

    this.piSuggestionSharedStateService.getDescription({
      suggestionId: this.descriptionSuggestionId,
      payload,
      flowId: this.flowId,
      flowName: this.flowName,
      isRefresh: args.isSuggestAnother,
    });
  }

  public onDescriptionSuggestionAdded(): void {
    this.goal.description = this.description;
    this.piSuggestionSharedStateService.acceptDescription(this.description);
  }
}
