import { ComponentRef, EnvironmentInjector, Injectable } from "@angular/core";
import { CustomFieldNumberInputFieldComponent } from "@webapp/custom-fields/components/numeric-field/number-input-field.component";
import { EditableCustomFieldsInput, ICustomField } from "../../models/custom-fields.models";
import { AbstractCustomFieldFactory } from "./abstract-custom-field.factory";

@Injectable()
export class NumericCustomFieldComponentFactory extends AbstractCustomFieldFactory<CustomFieldNumberInputFieldComponent> {
  constructor(injector: EnvironmentInjector) {
    super(injector, CustomFieldNumberInputFieldComponent);
  }

  protected setComponentInputs(
    component: ComponentRef<CustomFieldNumberInputFieldComponent>,
    customField: ICustomField,
    editableCustomFieldInputs: EditableCustomFieldsInput
  ): void {
    component.setInput("uiId", customField.name);
    component.setInput("a11yLabelledby", customField._id);
    component.setInput("a11yRequired", !editableCustomFieldInputs.disableRequired && customField.settings.validations.required);
    component.setInput("selected", editableCustomFieldInputs.values[customField.name]);
    component.setInput("disabled", editableCustomFieldInputs.disabled);
    component.setInput("readonly", editableCustomFieldInputs.readonly);
    component.setInput("formatting", customField.settings?.formatting);
    component.setInput("uiBorderless", editableCustomFieldInputs.borderless ?? true);
    let update: number;
    component.instance.selectedChange.subscribe((updateNumber: number) => {
      update = updateNumber;
      if (!editableCustomFieldInputs.updateOnBlur) {
        editableCustomFieldInputs.onChange({ fieldName: customField.name, customField: update });
      }
    });

    if (editableCustomFieldInputs.updateOnBlur) {
      component.instance.inputBlur.subscribe(() => {
        if (typeof update !== "undefined") {
          editableCustomFieldInputs.onChange({ fieldName: customField.name, customField: update });
        }
      });
    }
  }
}
