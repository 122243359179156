<div class="sp-py-6 sp-px-10 suggestion-card" [ngClass]="'border-' + borderStyle">
  <div class="suggestion-card-loading" ui-row uiAlign="middle">
    <div class="sp-pt-1" *ngIf="isLoadingIconAvailable" ui-col>
      <i class="fs-16px" [ngClass]="{ 'text-color-gray-1 suggestion-icon': shouldStyleIcon }" [uiType]="iconType" [uiTheme]="iconTheme" ui-icon></i>
    </div>
    <div ui-col uiFlex="1">
      <ui-skeleton [uiParagraph]="{ rows: 1, width: '100%' }" [uiTitle]="false" [uiActive]="true" [uiSectionHeight]="{ title: 'h-lg', paragraph: 'h-sm' }"></ui-skeleton>
    </div>
  </div>
</div>
