import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { zip } from "rxjs";
import { IAssigneesStoreState } from "@gtmhub/assignees";
import { CurrentEmployeeActions, ICurrentEmployeeStoreState } from "@gtmhub/employees";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { getCurrentUserId } from "@gtmhub/users";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { ReduxStoreObserver } from "@webapp/core/state-management/redux-store-observer";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { CurrentUserRepository } from "@webapp/users";
import { sortArrayByProperty } from "./utils/utils";

@UntilDestroy()
@Component({
  selector: "user-details",
  templateUrl: "./user-details.component.html",
  styleUrls: ["./user-details.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailsComponent implements OnInit {
  public employeeId: string;
  public employeeFirstName: string;
  public managedTeams: Assignee[] = [];
  public memberTeams: Assignee[] = [];
  public isViewOnly: boolean;
  public viewOnlyPopoverVisible: boolean;

  @Input({ required: true })
  public teamProfileSref: string;

  constructor(
    private permissionsFacade: PermissionsFacade,
    private cdr: ChangeDetectorRef,
    private editionFeatureService: EditionFeatureService,
    private currentUserRepository: CurrentUserRepository,
    private currentEmployeeActions: CurrentEmployeeActions
  ) {}

  public ngOnInit(): void {
    this.isViewOnly = this.currentUserRepository.userHasRole("view-only");
    this.employeeId = getCurrentUserId();

    const { reduxStore } = reduxStoreContainer;
    reduxStore.dispatch(this.currentEmployeeActions.fetchCurrentEmployeeIfMissing());
    const reduxObserver = new ReduxStoreObserver(reduxStore);
    zip([
      reduxObserver.whenFetched$<IAssigneesStoreState & ICurrentEmployeeStoreState>("assignees", "currentEmployee"),
      this.permissionsFacade.hasPermission$("AccessPeople"),
      this.editionFeatureService.hasFeature$("people.teams"),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([reduxStore, hasAccessPeoplePermission, hasPeopleEditionFeature]) => {
        const employee = reduxStore.currentEmployee.info;
        this.employeeFirstName = employee.firstName;

        if (hasAccessPeoplePermission && hasPeopleEditionFeature) {
          const managedTeamIds = employee.managedTeamIds || [];
          const memberTeamIds = employee.teamIds || [];
          const managedTeams = managedTeamIds.map((teamId) => reduxStore.assignees.map[teamId]).filter((team) => team?.isActive);
          this.managedTeams = sortArrayByProperty(managedTeams, "name");

          const memberTeams = memberTeamIds
            .map((teamId) => reduxStore.assignees.map[teamId])
            .filter((team) => team && team.isActive && !managedTeamIds.includes(team.id));
          this.memberTeams = sortArrayByProperty(memberTeams, "name");
        }

        this.cdr.markForCheck();
      });
  }

  public closeViewOnlyPopover(): void {
    this.handleViewOnlyPopoverVisibleChange(false);
  }

  public handleViewOnlyPopoverVisibleChange($event: boolean): void {
    this.viewOnlyPopoverVisible = $event;
    this.cdr.markForCheck();
  }
}
