import { HotkeyModule } from "angular2-hotkeys";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { HumanHotkeyComponent } from "./components/human-hotkey/human-hotkey.component";
import { HotkeyDirective } from "./directives/hotkey.directive";

@NgModule({
  imports: [CommonModule, HotkeyModule],
  declarations: [HotkeyDirective, HumanHotkeyComponent],
  exports: [HotkeyDirective, HumanHotkeyComponent],
})
export class HotkeysModule {}
