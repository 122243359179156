<pi-card-wrapper #cardWrapper [isToggleEnabled]="isFeedbackActionsSectionAvailable" [suggestion]="suggestion" [instance]="instance" [isAskAiVisible]="false" [isReadonly]="true" borderStyle="solid">
  <div pi-feedback-card-content>
    <ng-content></ng-content>
  </div>

  <pi-card-action-bar
    [isFeedbackActionsSectionAvailable]="isFeedbackActionsSectionAvailable"
    [suggestion]="suggestion"
    [instance]="instance"
    [goal]="parent"
    [suggestionFeedbackType]="suggestionFeedbackType"
    [suggestionFeedbackSubType]="suggestionFeedbackSubType">
    <pi-suggestion-action-bar-main-actions
      [suggestion]="suggestion"
      [isAddBtnAvailable]="false"
      [isRefreshBtnAvailable]="false"
      [isImproveBtnAvailable]="false"
      [isEditBtnAvailable]="false"
      [isRemoveBtnAvailable]="false"
      [isCopyBtnAvailable]="false"
      [instance]="instance"
      pi-action-bar-main-actions>
    </pi-suggestion-action-bar-main-actions>
  </pi-card-action-bar>
</pi-card-wrapper>
