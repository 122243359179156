import { IAngularEvent, IComponentOptions, ITimeoutService } from "angular";
import { IGtmhubRootScopeService } from "@gtmhub/models";

export enum SnackbarPosition {
  TOP = "top",
  BOTTOM = "bottom",
}

interface ISnackbarReceivedParams {
  contentMsg: string;
  contentParams: ISnackbarContentParams;
  skipClose?: boolean;
  info?: boolean;
  success?: boolean;
  hideCloseX?: boolean;
  customDuration?: number;
  centered?: boolean;
  position?: SnackbarPosition;
}

export interface ISnackbarContentParams {
  actionCb?(): void;
  actionBtnContent?: string;
  info?: boolean;
  success?: boolean;
  hideCloseX?: boolean;
  skipClose?: boolean;
  customDuration?: number;
  inputPlaceholders?: Record<string, unknown>;
  centered?: boolean;
  position?: SnackbarPosition;
}

export class SnackbarComponentCtrl {
  contentMsg: string;
  contentParams: ISnackbarContentParams;
  duration: number;
  show: boolean;
  info: boolean;
  success: boolean;
  hideCloseX: boolean;
  centered: boolean;
  position: SnackbarPosition;

  public static $inject = ["$rootScope", "$timeout"];

  constructor(
    private $rootScope: IGtmhubRootScopeService,
    private $timeout: ITimeoutService
  ) {
    this.contentMsg = "";
    this.contentParams = null;
    this.duration = 2000;
    this.show = false;
    this.info = false;
    this.success = false;
    this.hideCloseX = false;
    this.centered = false;
    this.position = SnackbarPosition.TOP;
    this.$rootScope.$on("showSnackbar", this.showSnackContent);
  }

  showSnackContent = (event: IAngularEvent, received: ISnackbarReceivedParams): void => {
    this.contentMsg = received.contentMsg;
    this.contentParams = received.contentParams;
    this.show = true;
    this.info = received.info;
    this.success = received.success;
    this.hideCloseX = received.hideCloseX;
    this.centered = received.centered ?? false;
    this.position = received.position ?? SnackbarPosition.TOP;

    if (received.customDuration) {
      this.duration = received.customDuration;
    }

    if (!received.skipClose) {
      this.$timeout(this.hide, this.duration);
    }
  };

  hide = (): void => {
    this.show = false;
    this.contentMsg = "";
  };

  triggerActionCallback = (): void => {
    this.contentParams.actionCb();
    this.hide();
  };
}

export const SnackbarComponent: IComponentOptions = {
  template: require("./snackbar.html"),
  controller: SnackbarComponentCtrl,
};
