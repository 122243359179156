import { Ng2StateDeclaration } from "@uirouter/angular";
import { LazyNgAndNg1ModuleRef, lazyLoadNgAndNg1Module } from "@webapp/core/routing/routing.utils";

const loadModule = (): Promise<LazyNgAndNg1ModuleRef> => import(/* webpackChunkName: "configuration" */ "./configuration.lazy");
const lazyLoad = lazyLoadNgAndNg1Module(loadModule);

const configurationFutureState: Ng2StateDeclaration = {
  name: "gtmhub.configuration.**",
  url: "/configuration/",
  lazyLoad,
};

const usersFutureState: Ng2StateDeclaration = {
  name: "gtmhub.usersList.**",
  url: "/users/",
  lazyLoad,
};

const rolesFutureState: Ng2StateDeclaration = {
  name: "gtmhub.roles.**",
  url: "/roles/",
  lazyLoad,
};

const manageCustomFieldsOrderFutureState: Ng2StateDeclaration = {
  name: "gtmhub.manageCustomFieldsOrder.**",
  url: "/configuration/custom-fields/manage-order/",
  lazyLoad,
};

const workdayFutureState: Ng2StateDeclaration = {
  name: "gtmhub.workday.**",
  url: "/workday/",
  lazyLoad,
};

export const states: Ng2StateDeclaration[] = [configurationFutureState, usersFutureState, rolesFutureState, manageCustomFieldsOrderFutureState, workdayFutureState];
