import { CustomFieldActions } from "@gtmhub/customFields/redux/custom-field-actions";
import { providerUpgradeFactory } from "@webapp/core/provider-upgrade/factories/provider-upgrade.factory";

/**
 * @deprecated Use CustomFieldsFacade instead (add methods if needed). It will be much easier to change the CFs source later.
 */
export const CustomFieldActionsProvider = {
  provide: CustomFieldActions,
  useFactory: providerUpgradeFactory("CustomFieldActions"),
  deps: ["$injector"],
};
