import { hotkeys, module } from "angular";
import "angular-hotkeys";

const mod = module("gtmhub.cfp.hotkeys", ["cfp.hotkeys"]);

mod.config([
  "hotkeysProvider",
  (hotkeysProvider: hotkeys.HotkeysProvider) => {
    hotkeysProvider.includeCheatSheet = false;
    hotkeysProvider.useNgRoute = false;
  },
]);

export default mod.name;
