import { NgModule } from "@angular/core";
import { UiAssigneeModule } from "@webapp/ui/assignee/assignee.module";
import { FromAssigneeIdDirective } from "./directives/from-assignee-id.directive";

@NgModule({
  declarations: [FromAssigneeIdDirective],
  imports: [UiAssigneeModule],
  exports: [UiAssigneeModule, FromAssigneeIdDirective],
})
export class AssigneesModule {}
