import { ChangeDetectionStrategy, Component } from "@angular/core";
import { IMetricUpdateNotification } from "@gtmhub/notifications/models/notifications";
import { SizeOfGif } from "@webapp/shared/components/gifs/gif.models";
import { InboxTemplateComponent } from "../inbox-template.component";

@Component({
  selector: "metric-snapshot-inbox-template",
  templateUrl: "./metric-snapshot-inbox-template.component.html",
  styleUrls: ["../inbox-template.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetricSnapshotInboxTemplateComponent extends InboxTemplateComponent<IMetricUpdateNotification> {
  public sizeOfGif = SizeOfGif;
}
