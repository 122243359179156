export class RequestPaging {
  public filter?: Record<string, unknown>;
  public fields?: string[] = [];
  public limit? = 15;
  public page? = 1;
  public skip? = 0;
  public take? = 0;
  public sort?: string[] = [];
}

export class GoalsRequestPaging extends RequestPaging {
  public sortMetricsBy?: string[] = [];
}
