import { IPlugin, PluginType, UIPluginAttachPoint } from "..";

export function getSinglePlugin(plugins: IPlugin[], pluginId: string): IPlugin {
  return plugins.find((plugin) => plugin.pluginId === pluginId);
}

export function filterPluginsByType(plugins: IPlugin[], pluginType: PluginType): IPlugin[] {
  return plugins.filter((p) => p.type === pluginType);
}

export function filterUIPluginsByAttachPoint(plugins: IPlugin[], attachPoint: UIPluginAttachPoint | string): IPlugin[] {
  return plugins.filter((p) => p.type === "ui" && p.uiPlugin.accessPoints && p.uiPlugin.accessPoints.some((accessPoint) => accessPoint.attachPoint === attachPoint));
}
