import { IAttributes, IAugmentedJQuery, IDirective, IDirectiveFactory, INgModelController, IScope } from "angular";
import { blurFocusedElement } from "@gtmhub/shared/dom";

interface EditableTextareaScope extends IScope {
  showNewDesign: boolean;
  disabled?: boolean;
  required?: boolean;
  inline?: boolean;
  submitEnter?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  model: {
    newText?: string;
  };
  isTooltipEnabled(): boolean;
  changeText(): void;
  onSubmit(): void;
  onKeyDown($event: KeyboardEvent): void;
}

export class EditableTextareaDirective implements IDirective {
  scope = {
    showNewDesign: "<",
    submitEnter: "<",
    autoFocus: "<",
    inline: "<",
    placeholder: "@",
    disabled: "=ngDisabled",
    required: "=ngRequired",
    onFocus: "&",
  };
  restrict = "E";
  require = "ngModel";
  template = require("./editable-textarea.html");

  link(scope: EditableTextareaScope, element: IAugmentedJQuery, attrs: IAttributes, ctrl: INgModelController): void {
    const updateView = (value: string) => {
      scope.model = { newText: value };
    };

    ctrl.$render = () => updateView(ctrl.$modelValue);

    if (scope.autoFocus) {
      this.focusElementAndRemoveHeight(element, scope);
    }

    scope.changeText = () => {
      if (scope.required && !scope.model.newText) {
        scope.model = { newText: ctrl.$viewValue };
        return;
      }

      if (scope.model.newText !== ctrl.$viewValue) {
        ctrl.$setViewValue(scope.model.newText);
      }
    };

    scope.onSubmit = () => {
      window.setTimeoutOutsideAngular(blurFocusedElement);
    };

    scope.onKeyDown = ($event: KeyboardEvent) => {
      if ($event.key === "Enter" && scope.submitEnter) {
        $event.preventDefault();
        $event.stopPropagation();
        window.setTimeoutOutsideAngular(() => ($event.target as HTMLElement).blur());
      }
    };

    scope.isTooltipEnabled = (): boolean => {
      const foundElement = element[0].querySelector("input");
      if (!foundElement) {
        return false;
      }

      const foundElementStyles = window.getComputedStyle(foundElement);
      const elWidth = foundElement.clientWidth;
      const elMaxWidth = foundElementStyles.maxWidth || "0px";
      const elMaxWidthNumber = Number(elMaxWidth.slice(0, elMaxWidth.length - 2));
      const borderSize = 2;

      return elWidth + borderSize === elMaxWidthNumber;
    };
  }

  private focusElementAndRemoveHeight(element: IAugmentedJQuery, scope: EditableTextareaScope) {
    window.setTimeoutOutsideAngular(() => {
      const elementFind = scope.inline ? "input" : "textarea";
      const foundElement = element[0].querySelector<HTMLElement>(elementFind);
      if (foundElement) {
        foundElement.focus();
      }
    }, 40);
  }

  static factory(): IDirectiveFactory {
    return () => new EditableTextareaDirective();
  }
}
