<pi-suggestion-drawer-create-form-modal-wrapper [isOpen]="isOpen">
  <pi-suggestion-drawer-base [isOpen]="isOpen" [description]="'custom_ai_suggestions_for_your_objective' | localize" (visibilityChange)="visibilityChange.emit($event)">
    <div class="sp-pt-8 sp-pb-10" ui-row>
      <suggestion-drawer-paragraph [text]="'title' | localize" size="mini" contrast="light" weight="semibold"></suggestion-drawer-paragraph>
    </div>

    <ng-container *ngIf="hasAllDependencies; else noSuggestionReason">
      <pi-feedback-card
        *ngIf="!isLoading && !hasError"
        [isEditBtnAvailable]="false"
        [isRemoveBtnAvailable]="false"
        [goal]="{
          id: null,
          description: null,
          name: title,
          sessionId: sessionId,
          ownerIds: ownerIds,
        }"
        [suggestion]="{ id: suggestions[0].id, text: suggestions[0].suggestionText }"
        [instance]="instance"
        [isIconAvailable]="true"
        borderStyle="solid"
        iconType="goal"
        iconTheme="fill"
        suggestionFeedbackType="objective"
        suggestionFeedbackSubType="title"
        data-test-id="okr-suggestion-item-title">
      </pi-feedback-card>

      <pi-clickable-error-card *ngIf="hasError" [instance]="instance" [isRemoveBtnAvailable]="false" [errorData]="errorData"> </pi-clickable-error-card>

      <pi-loading-card-v2 *ngIf="isLoading" [suggestion]="{ id: suggestions[0].id, text: suggestions[0].suggestionText }" [instance]="instance" [isCustomIcon]="true" iconType="goal" iconTheme="fill">
      </pi-loading-card-v2>
    </ng-container>

    <ng-template #noSuggestionReason>
      <pi-text-card
        [borderColorOnHover]="'light'"
        [fontColor]="'light'"
        [isRefreshBtnAvailable]="false"
        [isRemoveBtnAvailable]="false"
        [isAddBtnAvailable]="false"
        [textToDisplay]="noSuggestionReasonKey | localize"
        data-test-id="no-title-suggestion-reason-card">
      </pi-text-card>
    </ng-template>
  </pi-suggestion-drawer-base>
</pi-suggestion-drawer-create-form-modal-wrapper>
