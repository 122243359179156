import dayjs from "@webapp/shared/libs/dayjs";
import { UiDateMaxMin } from "@webapp/ui/date-picker/date-picker.models";

export const formatDateForPipes = (date: string, format?: string): string => {
  const dayjsDate = dayjs(date);
  if (!dayjsDate.isValid()) {
    return date;
  }

  return format ? dayjsDate.format(format) : dayjsDate.toISOString();
};

export function shouldDisableMaxMinDate(date: Date, dateOptions: UiDateMaxMin): boolean {
  const dueDate = dayjs(date).startOf("day");

  const { maxDate, minDate } = dateOptions;

  const minDateCheck = minDate ? dueDate.isBefore(dayjs(minDate).startOf("day")) : false;
  const maxDateCheck = maxDate ? dueDate.isAfter(dayjs(maxDate).endOf("day")) : false;

  return minDateCheck || maxDateCheck;
}
