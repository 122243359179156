import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, Input } from "@angular/core";
import { ɵDecadeTableComponent as DecadeTableComponent } from "ng-zorro-antd/date-picker";
import { UiDateBodyRow } from "../../date-picker.models";
import { UiDatePickerService } from "../../services/date-picker.service";

@Component({
  selector: "ui-decade-table",
  exportAs: "uiDecadeTable",
  templateUrl: "../abstract-table.html",
  styleUrls: ["decade-table.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "ui-date-table",
  },
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, NgSwitchDefault, NgIf, NgClass, NgFor, NgTemplateOutlet],
})
export class UiDecadeTableComponent extends DecadeTableComponent {
  /** the id of the element holding month and year buttons and used to label the table */
  @Input()
  public uiTableLabelledBy: string;

  public bodyRows: UiDateBodyRow[];

  constructor(
    public datePickerService: UiDatePickerService,
    public elementRef: ElementRef<HTMLElement>
  ) {
    super();
  }
}
