import { CheckInAnswer, CheckInMoodsAndNotesSettings, CheckInNotes } from "./checkins-models";
import { ReflectionMoodType } from "./reflections.models";

export const REFLECTION_CADENCE_DURATION_IN_DAYS = 7;

export const moodMap: { [key in ReflectionMoodType]: number } = {
  bad: 1,
  neutral: 2,
  good: 3,
};

interface CheckinsRequestStartDate {
  createdAt: {
    $gte: string;
  };
}
interface CheckinsRequestEndDate {
  createdAt: {
    $lt: string;
  };
}
export interface CheckinsRequestTeam {
  teamId: {
    $in: string[];
  };
}

export interface CheckinsRequestFilterParams {
  [key: string]: (CheckinsRequestStartDate | CheckinsRequestEndDate | CheckinsRequestTeam)[];
}

export interface CheckinPostRequestBody {
  teamId: string;
  templateId: string;
  mood: ReflectionMoodType;
  answers: CheckInAnswer[];
  notesV2: CheckInNotes;
  settings: CheckInMoodsAndNotesSettings;
  cadenceDate: string;
}

export interface CheckinPutRequestBody {
  mood: ReflectionMoodType;
  answers: CheckInAnswer[];
  notesV2: CheckInNotes;
}
