import { Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiThemeType } from "@quantive/ui-kit/icon";
import { take } from "rxjs";
import { getCurrentUserId } from "@gtmhub/users";
import { Goal } from "@webapp/okrs/goals/models/goal.models";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { IPIFeedbackSuggestion } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/models";
import { PiStateProcessorInstance } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.models";
import { PiCardBorderStyle } from "@webapp/platform-intelligence/shared/models/pi-card.models";
import { PiSuggestionSharedGoalStateService } from "@webapp/platform-intelligence/shared/services/pi-suggestion-shared-goal-state.service";
import { PiSuggestionSharedMetricStateService } from "@webapp/platform-intelligence/shared/services/pi-suggestion-shared-metric-state.service";
import { FlowName, KeyResultFlowName, ObjectiveFlowName } from "@webapp/platform-intelligence/shared/utils/pi-tracking";
import { NewTask, Task } from "@webapp/tasks/models/tasks.models";
import { TasksFacade } from "@webapp/tasks/services/task-facade.service";
import { IQuantivePlusAcceptedTask, IQuantivePlusSuggestionStatus, IQuantivePlusTask, minTasksCountNotToFetchSuggestions } from "../../models";
import { QuantivePlusAnotherSuggestionService } from "../../services/quantive-plus-another-suggestion.service";

const getTaskPayload = (
  item: Goal | Metric,
  type: "metric" | "goal",
  suggestion: IQuantivePlusTask,
  tasksInstance: PiStateProcessorInstance
): NewTask & { flowId: string; flowName: FlowName } => {
  return {
    name: suggestion.title,
    description: suggestion.description,
    parentId: item.id,
    status: "todo",
    ownerId: getCurrentUserId(),
    parentType: type,
    flowId: tasksInstance.flowId,
    flowName: tasksInstance.flowName as FlowName,
  };
};

@UntilDestroy()
@Component({
  selector: "quantive-plus-tasks",
  templateUrl: "./quantive-plus-tasks.component.html",
  styleUrls: ["./quantive-plus-tasks.component.less"],
})
export class QuantivePlusTasksComponent implements OnInit {
  @Input() public item;
  @Input() public tasks: IQuantivePlusTask[];
  @Input() public taskList: Task[];
  @Input() public suggestionsStatus: IQuantivePlusSuggestionStatus;

  @Input() public tasksInstance: PiStateProcessorInstance;
  @Input() public maxTasksToSuggest = minTasksCountNotToFetchSuggestions;
  @Input() public parentType: "metric" | "goal";
  @Input() public flowName: FlowName;
  @Input() public sessionId: string;

  @Input() public iconType: string;
  @Input() public iconTheme: UiThemeType;
  @Input() public borderStyle: PiCardBorderStyle;
  @Input() public isInline = false;

  @Input() public isRemoveBtnAvailable = false;
  @Input() public isRemoveBtnAtHeader = false;

  public quantivePlusCardBorderStyle: PiCardBorderStyle = "dashed";

  constructor(
    private quantivePlusAnotherSuggestionService: QuantivePlusAnotherSuggestionService,
    private tasksFacade: TasksFacade,
    private piSuggestionSharedGoalStateService: PiSuggestionSharedGoalStateService,
    private piSuggestionSharedMetricStateService: PiSuggestionSharedMetricStateService
  ) {}

  public ngOnInit(): void {
    this.subscribeTasksSubject();
    this.subscribeTasksImprovement();
    this.subscribeToTaskSuggestionReplacement();
  }

  public getTaskFeedbackSuggestion(task: IQuantivePlusTask, index: number): IPIFeedbackSuggestion {
    return { id: task.id, text: task.title, index };
  }

  private subscribeTasksSubject(): void {
    this.tasksInstance.subject.pipe(untilDestroyed(this)).subscribe((data) => {
      if (!data.suggestion) {
        if (data.refreshError) {
          return this.suggestAllTasks();
        }

        return;
      }

      const taskToReplace = this.tasks.find((kr) => kr.id === data.suggestion.id);
      if (!taskToReplace) {
        return;
      }

      if (data.suggestAnother || data.refreshError) {
        if (this.parentType === "goal") {
          this.quantivePlusAnotherSuggestionService.setObjectiveTaskSuggestionToReplace({
            taskToReplace,
            taskToReplaceIndex: data.suggestion?.index,
            flowName: this.flowName as ObjectiveFlowName,
          });
        }
        if (this.parentType === "metric") {
          this.quantivePlusAnotherSuggestionService.setKrTaskSuggestionToReplace({
            taskToReplace,
            taskToReplaceIndex: data.suggestion?.index,
            flowName: this.flowName as KeyResultFlowName,
          });
        }
      }

      if (data.addSuggestion) {
        this.createTask(taskToReplace, data.suggestion?.index);
      }
    });
  }

  private suggestAllTasks = (): void => {
    if (this.parentType === "goal") {
      this.quantivePlusAnotherSuggestionService.setRefreshAllObjectiveTasksEvent({ isRefreshAllTasks: true });
    } else {
      this.quantivePlusAnotherSuggestionService.setRefreshAllKrTasksEvent({ isRefreshAllTasks: true });
    }
  };

  public onSuggestAnotherTask = (taskToReplace: IQuantivePlusTask, taskToReplaceIndex: number): void => {
    if (this.parentType === "goal") {
      this.quantivePlusAnotherSuggestionService.setObjectiveTaskSuggestionToReplace({
        taskToReplace,
        taskToReplaceIndex,
        flowName: this.flowName as ObjectiveFlowName,
      });
    }
    if (this.parentType === "metric") {
      this.quantivePlusAnotherSuggestionService.setKrTaskSuggestionToReplace({
        taskToReplace,
        taskToReplaceIndex,
        flowName: this.flowName as KeyResultFlowName,
      });
    }
  };

  public createTask(suggestion: IQuantivePlusTask, index: number): void {
    const payload = getTaskPayload(this.item, this.parentType, suggestion, this.tasksInstance);

    this.tasksFacade
      .post$<Task>(payload)
      .pipe(take(1))
      .subscribe((newTask) => {
        const args = { task: newTask, sessionId: this.sessionId, quantivePlusSuggestionIndex: index, flowName: this.flowName };

        if (this.parentType === "goal") {
          this.piSuggestionSharedGoalStateService.setSingleTask(args);
        }

        if (this.parentType === "metric") {
          this.piSuggestionSharedMetricStateService.setSingleTask(args);
        }
      });
  }

  public onGoBackToSuggestionClicked(index: number): void {
    const args: IQuantivePlusAcceptedTask = {
      sessionId: this.sessionId,
      quantivePlusSuggestionIndex: index,
      flowName: this.flowName,
      type: "backToSuggestion",
    };

    if (this.parentType === "goal") {
      this.piSuggestionSharedGoalStateService.setSingleTask(args);
    }

    if (this.parentType === "metric") {
      this.piSuggestionSharedMetricStateService.setSingleTask(args);
    }
  }

  private subscribeToTaskSuggestionReplacement(): void {
    this.tasksInstance.replacementSuggestionSubject.pipe(untilDestroyed(this)).subscribe((data) => {
      const suggestionToReplace = this.tasks.find((kr) => kr.id === data.suggestion.id);

      if (!suggestionToReplace) {
        return;
      }

      if (this.parentType === "goal") {
        this.piSuggestionSharedGoalStateService.setSingleTask({
          sessionId: this.sessionId,
          quantivePlusSuggestionIndex: data.suggestion?.index,
          flowName: this.flowName,
          type: "replacing",
          newSuggestionName: data.newSuggestion.text,
        });
      }

      if (this.parentType === "metric") {
        this.piSuggestionSharedMetricStateService.setSingleTask({
          sessionId: this.sessionId,
          quantivePlusSuggestionIndex: data.suggestion?.index,
          flowName: this.flowName,
          type: "replacing",
          newSuggestionName: data.newSuggestion.text,
        });
      }
    });
  }

  private subscribeTasksImprovement(): void {
    this.tasksInstance.improvementSubject.pipe(untilDestroyed(this)).subscribe((data) => {
      const suggestionToReplace = this.tasks.find((kr) => kr.id === data.suggestion.id);

      if (!suggestionToReplace) {
        return;
      }

      if (this.parentType === "goal") {
        this.piSuggestionSharedGoalStateService.setSingleTask({
          sessionId: this.sessionId,
          quantivePlusSuggestionIndex: data.suggestion?.index,
          flowName: this.flowName,
          type: "improving",
          improvement: data.improvement,
          improveSuggestionContext: this.tasksInstance.improveSuggestionContext,
        });
      }

      if (this.parentType === "metric") {
        this.piSuggestionSharedMetricStateService.setSingleTask({
          sessionId: this.sessionId,
          quantivePlusSuggestionIndex: data.suggestion?.index,
          flowName: this.flowName,
          type: "improving",
          improvement: data.improvement,
          improveSuggestionContext: this.tasksInstance.improveSuggestionContext,
        });
      }
    });
  }
}
