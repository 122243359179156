import { NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiI18nService } from "@quantive/ui-kit/i18n";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { isPromise } from "ng-zorro-antd/core/util";
import { ModalButtonOptions, ModalOptions, NzModalFooterComponent, NzModalRef } from "ng-zorro-antd/modal";
import { CustomModalOptions, UiModalButtonOptions } from "../../modal.models";

@Component({
  selector: "div[ui-modal-footer]",
  exportAs: "UiModalFooterBuiltin",
  templateUrl: "modal-footer.component.html",
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
  host: {
    class: "ant-modal-footer",
  },
  standalone: true,
  imports: [NgIf, NzOutletModule, UiButtonModule, NgFor],
})
export class UiModalFooterComponent extends NzModalFooterComponent {
  @Input() public modalRef!: NzModalRef;
  @Output() public readonly cancelTriggered = new EventEmitter<void>();
  @Output() public readonly okTriggered = new EventEmitter<void>();

  constructor(i18n: UiI18nService, config: ModalOptions) {
    super(i18n, config);

    if (Array.isArray(config.nzFooter)) {
      this.buttonsFooter = true;
      this.buttons = (config.nzFooter as UiModalButtonOptions[]).map(mergeDefaultOption);
    }
  }

  public onButtonClick(options: ModalButtonOptions): void {
    const loading = this.getButtonCallableProp(options, "loading");
    if (!loading) {
      const result = this.getButtonCallableProp(options, "onClick");
      const isPrimary = options.type === "primary";
      if (isPromise(result) && isPrimary) {
        const oldDisabled = options.disabled;
        this.customConfig.nzLoadingIndicator = true;
        this.customConfig.nzError = null;
        options.disabled = true;

        result.then(
          () => {
            this.customConfig.nzLoadingIndicator = false;
            options.disabled = oldDisabled;
          },
          (error) => {
            this.customConfig.nzLoadingIndicator = false;
            this.customConfig.nzError = error;
            options.disabled = oldDisabled;
            throw error;
          }
        );
      }
    }
  }

  get customConfig(): CustomModalOptions {
    return this.config as CustomModalOptions;
  }

  public getButtonCallableProp(options: ModalButtonOptions, prop: keyof ModalButtonOptions): boolean {
    const value = options[prop];
    const componentInstance = this.modalRef.getContentComponent();
    return typeof value === "function" ? value.apply(options, [componentInstance, this.modalRef]) : value;
  }
}

function mergeDefaultOption(options: UiModalButtonOptions): UiModalButtonOptions {
  return {
    type: null,
    size: "large",
    shape: "round",
    show: true,
    disabled: false,
    ...options,
  };
}
