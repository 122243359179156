import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { IPIFeedbackDTO, IPIFeedbackItemModel, IPostFeedbackPayload } from "./models";
import { PiFeedbackApiService } from "./pi-feedback-api.service";
import { PiFeedbackServiceState } from "./pi-feedback-state.service";

@Injectable({
  providedIn: "any",
})
export class PiFeedbackFacade extends BaseFacade<IPIFeedbackItemModel, IPIFeedbackDTO, PiFeedbackServiceState, PiFeedbackApiService> {
  constructor(state: PiFeedbackServiceState, api: PiFeedbackApiService) {
    super(state, api);
  }

  public postFeedback(payload: IPostFeedbackPayload): Observable<IPIFeedbackItemModel> {
    return this.post$(payload, { ...new RequestConfig(), url: this.apiService.getQuantivePlusFeedbackEndpoint() });
  }
}
