import { IHttpService, IPromise, IQService, IRequestShortcutConfig } from "angular";
import { EnvironmentService } from "@gtmhub/env";
import { IUIError, createUIError } from "@gtmhub/error-handling";
import { getFullCollectionInBatches } from "@gtmhub/util";
import { ICustomField, ICustomFieldsAllTypesMap } from "@webapp/custom-fields/models/custom-fields.models";
import { ICustomFieldsScope } from "../controllers/custom-fields";
import { CustomFieldsService } from "../services/custom-fields.service";
import { ICustomFieldsStoreState } from "./custom-field-reducer";
import {
  CustomFieldCrudAction,
  CustomFieldCrudActionType,
  IReceiveCustomFieldsAction,
  IReceiveCustomFieldsErrorAction,
  IRequestCustomFieldsAction,
  IResetIndicatorCustomFieldAction,
  IUpdateAllCustomFieldsAction,
  IUpdateAllCustomFieldsErrorAction,
  IUpdateAllCustomFieldsSuccessAction,
} from "./models";

const requestCustomFields = (): IRequestCustomFieldsAction => ({ type: "REQUEST_CUSTOM_FIELDS" });
export const receiveCustomFields = (map: ICustomFieldsAllTypesMap): IReceiveCustomFieldsAction => ({ type: "RECEIVE_CUSTOM_FIELDS", map });
const requestCustomFieldsError = (error: IUIError): IReceiveCustomFieldsErrorAction => ({ type: "RECEIVE_CUSTOM_FIELDS_ERROR", error });
const shouldFetchCustomFields = (state: ICustomFieldsStoreState): boolean => !state.customFields.fetching && !state.customFields.isFetched;

const updateAllCustomFields = (): IUpdateAllCustomFieldsAction => ({
  type: "UPDATE_ALL_CUSTOM_FIELDS",
});

const updateAllCustomFieldsSuccess = (cfMap: ICustomFieldsAllTypesMap): IUpdateAllCustomFieldsSuccessAction => ({
  type: "UPDATE_ALL_CUSTOM_FIELDS_SUCCESS",
  map: cfMap,
});

const updateAllCustomFieldsError = (error: IUIError): IUpdateAllCustomFieldsErrorAction => ({
  type: "UPDATE_ALL_CUSTOM_FIELDS_ERROR",
  error,
});

const crudCustomField = (type: CustomFieldCrudActionType, error: IUIError = null): CustomFieldCrudAction => ({
  type,
  error,
});

const resetIndicator = (indicator: keyof ICustomFieldsScope["indicators"]): IResetIndicatorCustomFieldAction => ({
  type: "RESET_INDICATOR",
  [indicator]: null,
});

const processAllCustomFieldsMap = (customFields: ICustomField[]): ICustomFieldsAllTypesMap => {
  return customFields.reduce((customFieldsMap, customField) => {
    if (!customFieldsMap[customField.targetTypes[0]]) {
      customFieldsMap[customField.targetTypes[0]] = [];
    }

    customFieldsMap[customField.targetTypes[0]].push(customField);
    return customFieldsMap;
  }, {} as ICustomFieldsAllTypesMap);
};

export class CustomFieldActions {
  private initializationPromise = null;

  public static $inject = ["$http", "$q", "EnvironmentService", "CustomFieldsService"];

  constructor(
    private $http: IHttpService,
    private $q: IQService,
    private env: EnvironmentService,
    private customFieldsService: CustomFieldsService
  ) {
    // Changes in custom fields after some action are updated from the socket message, that's why we don't use the response from requests
  }

  public getCustomFields(): (dispatch, getState: () => ICustomFieldsStoreState) => IPromise<void> {
    return (dispatch, getState) => {
      if (shouldFetchCustomFields(getState())) {
        dispatch(requestCustomFields());

        const url = this.env.getApiEndpointV2("/customFields");
        const query: IRequestShortcutConfig = {
          params: { limit: -1 },
        };

        this.initializationPromise = getFullCollectionInBatches<ICustomField>(url, query, this.$http, this.$q).then(
          (response) => dispatch(receiveCustomFields(processAllCustomFieldsMap(response.items))),
          (rejection) => dispatch(requestCustomFieldsError(createUIError(rejection)))
        );
      }

      return this.initializationPromise;
    };
  }

  public createCustomField(customField: ICustomField): (dispatch) => IPromise<unknown> {
    return (dispatch) => {
      dispatch(crudCustomField("CREATE_CUSTOM_FIELD"));

      return this.customFieldsService.createCustomField(customField).then(
        () => dispatch(crudCustomField("CREATE_CUSTOM_FIELD_SUCCESS")),
        (rejection) => {
          dispatch(crudCustomField("CREATE_CUSTOM_FIELD_ERROR", createUIError(rejection)));
          return this.$q.reject(rejection);
        }
      );
    };
  }

  public updateCustomField(customField: ICustomField): (dispatch) => IPromise<unknown> {
    return (dispatch) => {
      dispatch(crudCustomField("UPDATE_CUSTOM_FIELD"));

      return this.customFieldsService.updateCustomField(customField).then(
        () => dispatch(crudCustomField("UPDATE_CUSTOM_FIELD_SUCCESS")),
        (rejection) => {
          dispatch(crudCustomField("UPDATE_CUSTOM_FIELD_ERROR", createUIError(rejection)));
          return this.$q.reject(rejection);
        }
      );
    };
  }

  public deleteCustomField(id: string): (dispatch) => void {
    return (dispatch) => {
      dispatch(crudCustomField("DELETE_CUSTOM_FIELD"));

      this.customFieldsService.delete(id).then(
        () => dispatch(crudCustomField("DELETE_CUSTOM_FIELD_SUCCESS")),
        (rejection) => dispatch(crudCustomField("DELETE_CUSTOM_FIELD_ERROR", createUIError(rejection)))
      );
    };
  }

  public updateAllCustomFields(): (dispatch) => void {
    return (dispatch) => {
      dispatch(updateAllCustomFields());

      const url = this.env.getApiEndpointV2("/customFields");
      const query: IRequestShortcutConfig = {
        params: { limit: -1 },
      };

      getFullCollectionInBatches<ICustomField>(url, query, this.$http, this.$q).then(
        (customFields) => {
          const customFieldsMap: ICustomFieldsAllTypesMap = processAllCustomFieldsMap(customFields.items);
          dispatch(updateAllCustomFieldsSuccess(customFieldsMap));
        },
        (err) => dispatch(updateAllCustomFieldsError(createUIError(err)))
      );
    };
  }

  public resetCustomFieldIndicators(indicator: keyof ICustomFieldsScope["indicators"]): (dispatch) => void {
    return (dispatch) => {
      dispatch(resetIndicator(indicator));
    };
  }
}
