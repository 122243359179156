import { CdkVirtualScrollViewport, ScrollingModule } from "@angular/cdk/scrolling";
import { NgClass, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from "@angular/common";
import { Component, Input, ViewChild } from "@angular/core";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { NzEmptyModule } from "ng-zorro-antd/empty";
import { NzOptionContainerComponent } from "ng-zorro-antd/select";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { UiLoadingIndicatorModule } from "@webapp/ui/loading-indicator/loading-indicator.module";
import { ValueToID } from "@webapp/ui/pipes/value-to-id.pipe";
import { UiSelectItemInterface, UiSelectModeType } from "@webapp/ui/select/select.models";
import { UiOptionItemGroupComponent } from "../option-item-group/option-item-group.component";
import { UiOptionItemComponent } from "../option-item/option-item.component";

@Component({
  selector: "ui-option-container",
  exportAs: "uiOptionContainer",
  templateUrl: "option-container.component.html",
  styleUrls: ["option-container.component.less"],
  standalone: true,
  imports: [
    NzEmptyModule,
    NgIf,
    NgClass,
    ScrollingModule,
    NgSwitch,
    NgSwitchCase,
    UiOptionItemGroupComponent,
    UiOptionItemComponent,
    ValueToID,
    NgTemplateOutlet,
    UiLoadingIndicatorModule,
    LocalizationModule,
  ],
})
export class UiOptionContainerComponent extends NzOptionContainerComponent {
  @Input() public mode: UiSelectModeType = "default";
  @Input() public listOfContainerItem: UiSelectItemInterface[] = [];
  @Input() public keyboardActivatedValue: NzSafeAny;
  @Input() public loading?: boolean;
  // Equals to default value of UiSelectComponent property uiOptionOverflowSize
  @Input() public reserveScrollbarSpace = this.maxItemLength >= 8;
  @ViewChild(CdkVirtualScrollViewport, { static: false }) public cdkVirtualScrollViewport!: CdkVirtualScrollViewport;

  /**
   * The extra space added with css on top of each item.
   */
  public readonly itemMarginTop = 4;

  private scrolledIdx = 0;

  public onScrolledIndexChange(index: number): void {
    this.scrolledIdx = index;
    super.onScrolledIndexChange(index);
  }

  public scrollToActivatedValue(): void {
    const keyboardActivatedValueIndex = this.listOfContainerItem.findIndex((i) => this.compareWith(i.key, this.keyboardActivatedValue));
    const activatedValueIndex = this.listOfContainerItem.findIndex((i) => this.compareWith(i.key, this.activatedValue));
    const index = keyboardActivatedValueIndex > activatedValueIndex ? keyboardActivatedValueIndex : activatedValueIndex;

    if (this.cdkVirtualScrollViewport && (index < this.scrolledIdx || index >= this.scrolledIdx + this.maxItemLength)) {
      this.cdkVirtualScrollViewport.scrollToIndex(index || 0);
    }
  }

  get maxBufferPx(): number {
    return Math.max((this.itemSize + this.itemMarginTop) * this.maxItemLength, (this.itemSize + this.itemMarginTop) * this.listOfContainerItem.length);
  }
}
