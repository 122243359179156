import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { IIndicatorMap } from "@gtmhub/error-handling";
import { INotificationSettings } from "@gtmhub/notifications/models/notifications";
import { NotificationsService } from "@gtmhub/notifications/services/notifications.service";
import { ISettingParameters } from "@webapp/notifications/models/notification-settings.model";
import { ToggleChangeService } from "@webapp/notifications/services/toggle-change.service";

@Component({
  selector: "personal-notification",
  templateUrl: "./personal-notification.component.html",
})
export class PersonalNotificationComponent implements OnInit, OnDestroy {
  @Input()
  public notificationSettings: INotificationSettings;

  public indicators: IIndicatorMap = {
    patchIndicator: undefined,
    loadingIndicator: { progress: true },
  };

  private toggleChangeSubscription$: Subscription;

  constructor(
    private notificationsService: NotificationsService,
    private toggleChangeService: ToggleChangeService
  ) {}

  public ngOnInit(): void {
    this.toggleChangeSubscription$ = this.toggleChangeService.getToggleStateChange$().subscribe((parameters) => {
      this.savePersonalNotification(parameters);
    });
    this.loadUserSettings();
  }

  public ngOnDestroy(): void {
    if (this.toggleChangeSubscription$) {
      this.toggleChangeSubscription$.unsubscribe();
    }
  }

  private savePersonalNotification(settingParameters: ISettingParameters): void {
    this.notificationsService
      .patchUserNotificationSettings(settingParameters.patchedSetting)
      .then()
      .catch((error) => {
        this.indicators.patchIndicator = { error };
      });
  }

  private loadUserSettings(): void {
    this.notificationsService
      .getUserNotificationSettings()
      .then((notificationSettings) => {
        this.notificationSettings = notificationSettings;
        delete this.indicators.loadingIndicator;
      })
      .catch((error) => {
        this.indicators.loadingIndicator = { error };
      });
  }
}
