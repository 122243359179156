export class MultiAccount {
  public accountId: string;
  public accountName: string;
  public accountDomain: string;
  public userId: string;
  public userIsPrimary: boolean;
  public invitation?: UserMultiAccountInvitation;
}

export class MultiAccountDTO {}

class UserMultiAccountInvitation {
  public id: string;
  public invitedByEmail: string;
  public invitedByNames: string;
  public invitedOn: string;
  public status: UserMultiAccountInvitationType;
}

export enum UserMultiAccountInvitationType {
  pending,
  approved,
  rejected,
}
