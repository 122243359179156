import { module } from "angular";
import upgrade from "@gtmhub/core/upgrade/module";
import employeesCore from "@gtmhub/employees/employees-core.module";
import hotkeys from "@gtmhub/shared/libs/angular-hotkeys/angular-hotkeys.module";
import viewHistory from "@gtmhub/view-history/view-history.module";
import { GlobalSearchComponent } from "@webapp/search/components/global-search/global-search.component";
import { SearchPageComponent } from "@webapp/search/components/search-page/search-page.component";
import { SimpleSearchComponent } from "@webapp/search/components/simple-search/simple-search.component";
import searchCore from "./search-core.module";

const mod = module("search", [upgrade, searchCore, hotkeys, employeesCore, viewHistory]);

mod.downgradeComponent("ghSearchNext", { component: GlobalSearchComponent, propagateDigest: false });
mod.downgradeComponent("searchPage", { component: SearchPageComponent, propagateDigest: false });
mod.downgradeComponent("ghSimpleSearch", { component: SimpleSearchComponent, propagateDigest: false });

export default mod.name;
