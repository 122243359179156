/* eslint-disable @typescript-eslint/no-unsafe-function-type */
/* eslint-disable prefer-rest-params */

/**
 * This is a simple implementation of Zone.js (https://github.com/angular/angular/tree/master/packages/zone.js/) made to work with Angular.js.
 * In Angular 2+ there is the complete Zone.js, but we cannot reuse it directly since it should not trigger Angular's digest cycle
 * (see https://github.com/angular/angular/blob/main/packages/upgrade/static/src/upgrade_module.ts) That's why we will keep both
 * Zone.js implementations separate.
 *
 * How does it work? Consider the following example:
 *
 * ```
 * class MyCtrl {
 *  constructor($http, $timeout, $q) {
 *    this.$http = $http;
 *    this.$timeout = $timeout;
 *    this.$q = $q;
 *
 *    const zoneA = Ng1Zone.current.fork("zoneA");
 *    const zoneB = Ng1Zone.current.fork("zoneB");
 *
 *    zoneA.run(this.init, this);
 *    zoneB.run(this.init, this);
 *  }
 *
 *  init() {
 *    console.log(`init: I am coming from zone ${Ng1Zone.current.name}`);
 *
 *    this.$http.get("some-url").then(() => {
 *      console.log(`$http: I am coming from zone ${Ng1Zone.current.name}`);
 *    });
 *
 *    this.$timeout(() => {
 *      console.log(`$timeout: I am coming from zone ${Ng1Zone.current.name}`);
 *    }, 1500);
 *
 *    const d = this.$q.defer();
 *    d.promise.then(() => {
 *      console.log(`$q: I am coming from zone ${Ng1Zone.current.name}`);
 *    });
 *    setTimeout(() => { d.resolve(); }, 2000);
 *  }
 * }
 * ```
 *
 * As we are dealing with callbacks, we have to propagate some context between them.
 * This is done by monkey-patching Angular.js services. Running the example above,
 * will print the correct zone in each callback.
 */

interface Ng1ZoneFrame {
  parent: Ng1ZoneFrame;
  zone: Ng1Zone;
}

export type Ng1ZoneSpec = {
  name: string;
  properties?: Record<string, unknown>;
};

let currentZoneFrame: Ng1ZoneFrame = null;

export class Ng1Zone {
  constructor(
    public name: string,
    public parent: Ng1Zone,
    private properties: Record<string, unknown>
  ) {}

  public static get current(): Ng1Zone {
    return currentZoneFrame.zone;
  }

  public static get root(): Ng1Zone {
    let zone = Ng1Zone.current;
    while (zone.parent) {
      zone = zone.parent;
    }
    return zone;
  }

  public get<T>(key: string): T | undefined {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let zone: Ng1Zone = this;
    while (zone) {
      if (key in zone.properties) {
        return zone.properties[key] as T;
      }

      zone = zone.parent;
    }
  }

  public printParentTree(): void {
    const names: string[] = [];
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let zone: Ng1Zone = this;
    while (zone) {
      names.push(zone.name);
      zone = zone.parent;
    }
    const tree = names.reduce((result, name, index) => result + "-".repeat(index) + " " + name + "\n", "").trim();
    // eslint-disable-next-line no-console
    console.log(tree);
  }

  public fork(spec: Ng1ZoneSpec): Ng1Zone {
    return new Ng1Zone(spec.name, this, spec.properties || {});
  }

  public run<TResult>(fn: Function, applyThis?: unknown, applyArgs?: unknown): TResult {
    currentZoneFrame = { parent: currentZoneFrame, zone: this };
    try {
      return fn.apply(applyThis, applyArgs);
    } finally {
      currentZoneFrame = currentZoneFrame.parent;
    }
  }

  public wrap<T extends Function>(callback: T): T {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const zone = this;
    return function (this: unknown) {
      return zone.run(callback, this, arguments);
    } as unknown as T;
  }
}

currentZoneFrame = { parent: null, zone: new Ng1Zone("<root>", null, {}) };
