import { NgIf, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, Input, TemplateRef } from "@angular/core";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { NzListItemMetaComponent } from "ng-zorro-antd/list";
import { UiListItemMetaAvatarComponent } from "../list-item-meta-avatar/list-item-meta-avatar.component";
import {
  UiListItemMetaDescriptionComponent as DescriptionComponent,
  UiListItemMetaDescriptionComponent,
} from "../list-item-meta-description/list-item-meta-description.component";
import { UiListItemMetaTitleComponent as TitleComponent, UiListItemMetaTitleComponent } from "../list-item-meta-title/list-item-meta-title.component";

@Component({
  selector: "ui-list-item-meta, [ui-list-item-meta]",
  exportAs: "uiListItemMeta",
  templateUrl: "./list-item-meta.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiListItemMetaAvatarComponent, NgTemplateOutlet, UiListItemMetaTitleComponent, UiListItemMetaDescriptionComponent, NzOutletModule, NgIf],
})
export class UiListItemMetaComponent extends NzListItemMetaComponent {
  @Input("uiAvatar")
  set nzAvatar(value: string | TemplateRef<void>) {
    if (value instanceof TemplateRef) {
      this.avatarStr = "";
      this.avatarTpl = value;
    } else {
      this.avatarStr = value;
    }
  }

  @Input("uiTitle") public nzTitle?: string | TemplateRef<void>;

  @Input("uiDescription") public nzDescription?: string | TemplateRef<void>;

  @ContentChild(DescriptionComponent) public descriptionComponent?: DescriptionComponent;
  @ContentChild(TitleComponent) public titleComponent?: TitleComponent;

  constructor(public elementRef: ElementRef) {
    super(elementRef);
  }
}
