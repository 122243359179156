import { IDirective, IDirectiveFactory, IScope } from "angular";
import { IIndicator, IUIError, createUIError } from "@gtmhub/error-handling";
import { IInvitationRequest, IUser } from "@gtmhub/users/models";
import { UserService } from "@gtmhub/users/user-service";

interface IInviteUserScope extends IScope {
  email: string;
  invitation: IInvitationRequest;
  skipEmail?: boolean;
  status?: "sent" | "problem";
  error?: IUIError;
  indicators: {
    inviting?: IIndicator;
  };
  resetInvitationStatus(): void;
  close(): void;
  invited(args: { user: IUser }): void;
  inviteUser(): void;
}

export class InviteUser implements IDirective {
  public scope = {
    close: "&",
    invited: "&",
    email: "=",
  };
  public restrict = "E";
  public template = require("../views/invite-user.html");

  constructor(private userService: UserService) {}

  public link(scope: IInviteUserScope): void {
    scope.indicators = {};

    scope.invitation = {
      email: scope.email,
      firstName: "",
      lastName: "",
      status: "",
      subscriptionType: "regular",
    };

    scope.resetInvitationStatus = (): void => {
      scope.invitation.status = null;
    };

    scope.inviteUser = (): void => {
      scope.indicators.inviting = { progress: true };

      this.userService.createUser(scope.invitation, { skipEmail: scope.skipEmail }).then(
        (newUser: IUser) => {
          delete scope.indicators.inviting;
          scope.status = "sent";

          newUser.type = "user";
          scope.invited({ user: newUser });
          scope.close();
        },
        (rejection) => {
          delete scope.indicators.inviting;
          scope.status = "problem";

          const err = createUIError(rejection);
          const isCannotAddNonBusinessEmailDomainError =
            err.body.indexOf("Sandbox Error: Internal server error") !== -1 || err.body.indexOf("Sandbox Error: Extensibility error") !== -1;
          if (isCannotAddNonBusinessEmailDomainError) {
            err.message = "cannot_add_non_business_email_domain";
          }

          scope.error = err;
        }
      );
    };
  }

  public static factory(): IDirectiveFactory {
    const directive = (userService: UserService) => new InviteUser(userService);
    directive.$inject = ["UserService"];
    return directive;
  }
}
