import { IModule, IPromise, IQService } from "angular";
import {
  Ng1StateDeclaration,
  RawParams,
  RejectType,
  Rejection,
  StateOrName,
  StateRegistry,
  StateService,
  TransitionOptions,
  TransitionPromise,
  UIRouterGlobals,
} from "@uirouter/angularjs";
import { Ng1Zone } from "@gtmhub/core/tracing";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";

const titleCase = (title: string): string => title.charAt(0).toUpperCase() + title.substring(1).toLowerCase();

const formatStringWithDelimiters = (str: string, delimiter = "_"): string => {
  const strArray = str.split(delimiter);
  for (let i = 0; i < strArray.length; i++) {
    strArray[i] = titleCase(strArray[i]);
  }

  return strArray.join(" ");
};

const parsePageName = (state: Ng1StateDeclaration): string => {
  if (state.data?.trackingPage) {
    return state.data.trackingPage;
  }

  if (state.data?.titleKey) {
    return formatStringWithDelimiters(state.data.titleKey);
  }

  return formatStringWithDelimiters(state.name.replace("gtmhub.", ""), ".");
};

const trackStateChangeSuccess = (state: Ng1StateDeclaration, analyticsService: AnalyticsService): void => {
  if (!state.data?.disableAnalytics) {
    analyticsService.page(parsePageName(state), {
      controller: typeof state.controller === "string" ? state.controller : null,
    });
  }
};

const trackStateChangeError = (state: Ng1StateDeclaration, analyticsService: AnalyticsService, error: string): void => {
  analyticsService.track(error, {
    controller: state.controller,
    screen_name: state.name,
    url: state.url,
    params: state.params,
  });
};

export function trackStateChanges(mod: IModule) {
  mod.run([
    "$state",
    "$uiRouterGlobals",
    "$stateRegistry",
    "$q",
    "AnalyticsService",
    ($state: StateService, $uiRouterGlobals: UIRouterGlobals, $stateRegistry: StateRegistry, $q: IQService, analyticsService: AnalyticsService) => {
      const oldTransitionTo = $state.transitionTo;
      $state.transitionTo = function trackingTransitionTo(to: StateOrName, toParams?: RawParams, options?: TransitionOptions): TransitionPromise {
        const currentStateName = $uiRouterGlobals.current.name;

        // eslint-disable-next-line prefer-rest-params
        const promise: TransitionPromise = oldTransitionTo.apply($state, arguments);
        promise.then(
          (resolvedState) => {
            const state = resolvedState as unknown as Ng1StateDeclaration;

            // init promises are populated when navigating to a state; we want to wait
            // for all initialization before tracking the page
            const initPromises = Ng1Zone.current.get<IPromise<unknown>[]>("initPromises") || [];
            return $q.all(initPromises).then(
              () => {
                if (currentStateName !== resolvedState.name) {
                  return trackStateChangeSuccess(state, analyticsService);
                }
              },
              () => {
                if (currentStateName !== resolvedState.name) {
                  return trackStateChangeError(state, analyticsService, "Webapp Init Error");
                }
              }
            );
          },
          (err: Rejection | undefined) => {
            const base = (options || {}).relative;
            const toState = $stateRegistry.get(to, base);
            const shouldTrackRejection = toState && (!err || err.type === RejectType.ERROR);
            if (shouldTrackRejection) {
              trackStateChangeError(toState, analyticsService, "Webapp Broken Link Hit");
            }
          }
        );
        return promise;
      };
    },
  ]);
}
