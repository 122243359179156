<div ui-row uiJustify="start" uiAlign="middle">
  <button class="sp-pl-16" *ngIf="isAddBtnAvailable" (click)="onAddSuggestionClicked(); $event.stopPropagation()" ui-button uiType="primary" uiShape="round" data-test-id="suggestion-accept-button">
    <i class="fs-16px text-color-gray-1" ui-icon uiType="plus"></i>
    <span class="sp-ml-4">{{ primaryButtonText | localize }}</span>
  </button>

  <gh-dropdown
    class="sp-ml-4"
    *ngIf="isImproveBtnAvailable"
    [menuItems]="improveBtnDropdownItems"
    [hasCustomButtonContent]="true"
    (click)="$event.stopPropagation()"
    (dropdownToggled)="onImproveBtnDropdownToggle({ isDropdownOpened: $event, tooltipDirective: improveBtnTooltipDirectiveRef })"
    dropdownMenuClass="pi-card-menu-dynamic-width">
    <button
      class="suggestion-card-action"
      #improveBtnTooltipDirectiveRef="uiTooltip"
      [class.dropdown-btn-selected]="isImproveBtnDropdownOpened"
      [uiTooltipTitle]="'improve_suggestion' | localize"
      [uiTooltipOverlayStyle]="actionBtnBottomLeftTooltipCustomStyle"
      ui-button
      gh-dropdown-custom-button-content
      ui-tooltip
      uiTooltipPlacement="bottomLeft">
      <i class="text-color-gray-9" ui-icon uiType="idea"></i>
    </button>
  </gh-dropdown>

  <button
    class="sp-ml-4 suggestion-card-action"
    *ngIf="isCopyBtnAvailable"
    [uiTooltipTitle]="copyBtnTooltipTitle | localize"
    [uiTooltipOverlayStyle]="actionBtnBottomLeftTooltipCustomStyle"
    (click)="onCopyClicked(); $event.stopPropagation()"
    ui-button
    ui-tooltip
    uiTooltipPlacement="bottomLeft"
    data-test-id="suggestion-copy-button">
    <i class="fs-16px text-color-gray-9" ui-icon uiType="make-copy"></i>
  </button>

  <button
    class="sp-ml-4 suggestion-card-action"
    *ngIf="isRefreshBtnAvailable"
    [uiTooltipTitle]="'suggest_another' | localize"
    [uiTooltipOverlayStyle]="actionBtnBottomLeftTooltipCustomStyle"
    (click)="onSuggestAnotherClicked(); $event.stopPropagation()"
    ui-button
    ui-tooltip
    uiTooltipPlacement="bottomLeft"
    data-test-id="suggestion-refresh-button">
    <i class="fs-16px text-color-gray-9" ui-icon uiType="refresh"></i>
  </button>

  <button
    class="sp-ml-4 suggestion-card-action"
    *ngIf="isEditBtnAvailable && !shouldShowVerticalMenuBtn"
    [uiTooltipTitle]="'edit_suggestion' | localize"
    [uiTooltipOverlayStyle]="actionBtnBottomLeftTooltipCustomStyle"
    (click)="$event.stopPropagation()"
    ui-button
    ui-tooltip
    uiTooltipPlacement="bottomLeft">
    <i class="fs-16px text-color-gray-9" ui-icon uiType="edit"></i>
  </button>

  <button
    class="sp-ml-4 suggestion-card-action"
    *ngIf="isRemoveBtnAvailable && !shouldShowVerticalMenuBtn"
    [uiTooltipTitle]="'remove_suggestion' | localize"
    [uiTooltipOverlayStyle]="actionBtnBottomLeftTooltipCustomStyle"
    (click)="onRemoveSuggestionClicked(); $event.stopPropagation()"
    ui-button
    ui-tooltip
    uiTooltipPlacement="bottomLeft"
    data-test-id="suggestion-decline-button">
    <i class="fs-16px text-color-gray-9" ui-icon uiType="close-big"></i>
  </button>

  <gh-dropdown
    class="sp-ml-4"
    *ngIf="shouldShowVerticalMenuBtn"
    [menuItems]="verticalMenuBtnDropdownItems"
    [hasCustomButtonContent]="true"
    (click)="$event.stopPropagation()"
    (dropdownToggled)="onVerticalMenuBtnDropdownToggle({ isDropdownOpened: $event, tooltipDirective: verticalMenuBtnTooltipDirectiveRef })"
    dropdownMenuClass="pi-card-menu">
    <button
      class="suggestion-card-action"
      #verticalMenuBtnTooltipDirectiveRef="uiTooltip"
      [class.dropdown-btn-selected]="isVerticalMenuBtnDropdownOpened"
      [uiTooltipTitle]="'edit_or_remove' | localize"
      [uiTooltipOverlayStyle]="actionBtnBottomLeftTooltipCustomStyle"
      ui-button
      gh-dropdown-custom-button-content
      ui-tooltip
      uiTooltipPlacement="bottomLeft">
      <i class="fs-16px text-color-gray-9" ui-icon uiType="menu-vertical"></i>
    </button>
  </gh-dropdown>
</div>
