export const ordinal = () => {
  return function (d: number): string {
    const j = d % 10;
    const k = d % 100;

    if (j === 1 && k !== 11) {
      return d + "st";
    }
    if (j === 2 && k !== 12) {
      return d + "nd";
    }
    if (j === 3 && k !== 13) {
      return d + "rd";
    }
    return d + "th";
  };
};
