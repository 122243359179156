import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { UiCheckboxModule } from "@quantive/ui-kit/checkbox";
import { UiStringTemplateOutletDirective } from "@quantive/ui-kit/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiLabelModule } from "@quantive/ui-kit/label";
import { UiToggleModule } from "@quantive/ui-kit/toggle";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { CustomFieldsModule } from "@webapp/custom-fields/custom-fields.module";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { MetricDrawerTagsComponent } from "@webapp/okrs/metrics/components/metric-drawer-tags/metric-drawer-tags.component";
import { ItemAutomationModule } from "@webapp/shared/components/item-automation/item-automation.module";
import { FormLabelComponent } from "@webapp/shared/form/components/form-label/form-label.component";
import { RichTextEditorWithMentionsModule } from "@webapp/shared/rich-text-editor-with-mentions/rich-text-editor-with-mentions.module";
import { UnitSelectorModule } from "@webapp/shared/unit-selector/unit-selector.module";
import { UiDatePickerModule } from "@webapp/ui/date-picker/date-picker.module";
import { UiFormModule } from "@webapp/ui/form/form.module";
import { UiInputNumberModule } from "@webapp/ui/input-number/input-number.module";
import { UiInputModule } from "@webapp/ui/input/input.module";
import { UiLoadingIndicatorModule } from "@webapp/ui/loading-indicator/loading-indicator.module";
import { UiRadioModule } from "@webapp/ui/radio/radio.module";
import { UiSelectModule } from "@webapp/ui/select/select.module";
import { PeopleSelectorModule } from "../components/people-selector/people-selector.module";
import { IconWithPopoverModule } from "../icon-with-popover/icon-with-popover.module";
import { FORM_CONTENT_TEMPLATE_DECLARABLES } from "./components";
import { FORM_ITEM_DECLARABLES } from "./components/form-items";
import { RemoveInteractableAttributesDirective } from "./directives/remove-interactable-attributes.directive";
import { FormGroupComponent } from "./form-group.component";
import { FORM_PIPES } from "./pipes";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconWithPopoverModule,
    UiInputModule,
    PeopleSelectorModule,
    UiCheckboxModule,
    CustomFieldsModule,
    UiLoadingIndicatorModule,
    UiInputNumberModule,
    UiDatePickerModule,
    UiIconModule,
    UnitSelectorModule,
    RichTextEditorWithMentionsModule,
    UiToggleModule,
    UiTooltipModule,
    ItemAutomationModule,
    UiRadioModule,
    FormLabelComponent,
    MetricDrawerTagsComponent,
    UiSelectModule,
    LocalizationModule,
    UiFormModule,
    UiLabelModule,
    LocalizationModule,
    UiStringTemplateOutletDirective,
    RemoveInteractableAttributesDirective,
  ],
  declarations: [FormGroupComponent, ...FORM_PIPES, ...FORM_CONTENT_TEMPLATE_DECLARABLES, ...FORM_ITEM_DECLARABLES],
  exports: [ReactiveFormsModule, FormGroupComponent, ...FORM_CONTENT_TEMPLATE_DECLARABLES, ...FORM_ITEM_DECLARABLES],
})
export class FormModule {}
