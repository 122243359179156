import { IGtmhubRootScopeService } from "@gtmhub/models";
import { UXCustomizationType } from "@gtmhub/uxcustomization/models";
import NavigationItemsMediator from "@webapp/navigation/services/uxcustomization/navigation-items.mediator.service";
import { FavoritesManager } from "./favorites.manager";

export type FavoritesManagerFactory = (entityTypes: UXCustomizationType[]) => FavoritesManager;

favoritesManagerFactory.$inject = ["$rootScope", "NavigationItemsMediator"];

export function favoritesManagerFactory($rootScope: IGtmhubRootScopeService, navigationItemsMediator: NavigationItemsMediator): FavoritesManagerFactory {
  return (entityTypes: UXCustomizationType[]) => {
    return new FavoritesManager(navigationItemsMediator, $rootScope, entityTypes);
  };
}
