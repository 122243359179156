<div *ngIf="notification.operationType === 'created'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>

  <div *ngIf="notification.notificationData.item.announcementType === 0">
    <div class="title">
      {{ "user_awarded_badge_to" | localize: { names: notification.notificationData.actor.names, name: notification.notificationData.item.name } }}
    </div>
    <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
    <div class="summary">
      <div class="message-icon">
        <i [uiTooltipTitle]="'badge' | localize" ui-tooltip ui-icon uiTheme="fill" uiType="badge"></i>
      </div>
      <span class="item">{{ assignees }}</span>
    </div>
    <div class="full">
      <div class="inbox-box">
        <div class="inbox-section">
          <div class="flex" *ngFor="let assignee of notification.notificationData.assignees; trackBy: trackByUser">
            <span>🏅</span>
            <ui-assignee [fromAssigneeId]="assignee.id" uiSize="sm"></ui-assignee>
          </div>
        </div>
        <div class="inbox-section" *ngIf="notification.notificationData.item.comment">
          <div class="heading inline-notifications">
            {{ "comment" | localize }}
          </div>
          : <rich-text-viewer [text]="notification.notificationData.item.comment"></rich-text-viewer>
        </div>
        <div class="inbox-section">
          <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "badge_preview" | localize }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
