<!--Old API Start-->
<ui-list-item-meta-avatar *ngIf="avatarStr" [uiSrc]="avatarStr"></ui-list-item-meta-avatar>
<ui-list-item-meta-avatar *ngIf="avatarTpl">
  <ng-container [ngTemplateOutlet]="avatarTpl"></ng-container>
</ui-list-item-meta-avatar>
<!--Old API End-->

<ng-content select="ui-list-item-meta-avatar"></ng-content>

<div class="ant-list-item-meta-content" *ngIf="nzTitle || nzDescription || descriptionComponent || titleComponent">
  <!--Old API Start-->
  <ui-list-item-meta-title *ngIf="nzTitle && !titleComponent">
    <ng-container *nzStringTemplateOutlet="nzTitle">{{ nzTitle }}</ng-container>
  </ui-list-item-meta-title>
  <ui-list-item-meta-description *ngIf="nzDescription && !descriptionComponent">
    <ng-container *nzStringTemplateOutlet="nzDescription">{{ nzDescription }}</ng-container>
  </ui-list-item-meta-description>
  <!--Old API End-->

  <ng-content select="ui-list-item-meta-title"></ng-content>
  <ng-content select="ui-list-item-meta-description"></ng-content>
</div>
