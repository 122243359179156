import { isPromiseLike } from "@gtmhub/util/promise";
import { ApmService } from "./apm.service";

export class ApmSpanService {
  public static $inject = ["ApmService"];

  constructor(private apmService: ApmService) {}

  public dataLoadSpan<T>(name: string, fn: () => T): T {
    this.apmService.startDataLoadSpan(name);
    const res = fn();
    if (isPromiseLike(res)) {
      return res.finally(() => this.apmService.endDataLoadSpan(name)) as unknown as T;
    }

    this.apmService.endDataLoadSpan(name);
    return res;
  }

  public renderSpan<T>(name: string, fn: () => T): T {
    this.apmService.startRenderSpan(name);
    const res = fn();
    if (isPromiseLike(res)) {
      return res.finally(() => this.apmService.endRenderSpan(name)) as unknown as T;
    }

    this.apmService.endRenderSpan(name);
    return res;
  }

  public addLabels(labels: Record<string, string>): void {
    this.apmService.addLabelsToCurrentTransactions(labels);
  }
}
