import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { localize } from "@webapp/localization/utils/localization.utils";
import type { CreateMetricFormComponent } from "@webapp/okrs/metrics/components/create-metric-form/create-metric-form.component";
import { MetricFormModalData } from "@webapp/okrs/metrics/components/create-metric-form/create-metric-form.models";
import { loadModule } from "@webapp/okrs/okrs.future-states";
import { LazyModalService } from "@webapp/shared/modal/lazy-modal.service";
import { cancelButton, primaryButton } from "@webapp/shared/modal/modal.utils";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";

@Injectable({
  providedIn: "root",
})
export class CreateMetricModalService {
  constructor(private readonly lazyModalService: LazyModalService) {}

  public openCreateMetricModal(data: MetricFormModalData): Observable<UiModalRef<CreateMetricFormComponent>> {
    return this.lazyModalService.create({
      lazyConfig: { loadModule, component: (mod) => mod.CreateMetricFormComponent },
      uiWidth: 657,
      uiTitle: "metricId" in data ? localize("edit_manual_kr") : localize("create_manual_kr"),
      uiDescription: localize("all_required_fields_are_marked_with_an_asterisk"),
      uiData: data,
      uiClassName: "display-flex",
      uiFooter: [
        primaryButton(localize("add_key_result"), {
          disabled: (instance) => !!instance.indicators.saving || !!instance.indicators.loading || instance.form?.invalid,
          show: (instance) => instance.showForm && !instance.isEdit,
          onClick: (instance) => {
            return instance.save();
          },
        }),
        primaryButton(localize("save"), {
          disabled: (instance) => !!instance.indicators.saving || !!instance.indicators.loading || instance.form?.invalid,
          show: (instance) => instance.showForm && instance.isEdit,
          onClick: (instance) => {
            return instance.edit();
          },
        }),
        primaryButton(localize("got_it"), {
          show: (instance) => instance.showLimitReached || instance.showNoPermissions,
        }),
        cancelButton({
          show: (instance) => !instance.showLimitReached && !instance.showNoPermissions && !instance.indicators.loading,
        }),
      ],
    });
  }
}
