import { NgModule } from "@angular/core";
import { UiCalendarFooterComponent } from "./components/calendar-footer/calendar-footer.component";
import { UiDateRangePopupComponent } from "./components/date-range-popup/date-range-popup.component";
import { UiInnerPopupComponent } from "./components/inner-popup/inner-popup.component";
import { UiDatePickerComponent } from "./date-picker.component";
import { UiMonthPickerComponent } from "./directives/month-picker.directive";
import { UiRangePickerComponent } from "./directives/range-picker.directive";
import { UiWeekPickerComponent } from "./directives/week-picker.directive";
import { UiYearPickerComponent } from "./directives/year-picker.directive";

@NgModule({
  imports: [
    UiDatePickerComponent,
    UiMonthPickerComponent,
    UiYearPickerComponent,
    UiWeekPickerComponent,
    UiRangePickerComponent,
    UiCalendarFooterComponent,
    UiInnerPopupComponent,
    UiDateRangePopupComponent,
  ],
  exports: [UiDatePickerComponent, UiRangePickerComponent, UiMonthPickerComponent, UiYearPickerComponent, UiWeekPickerComponent],
})
export class UiDatePickerModule {}
