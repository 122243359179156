import { IHttpService } from "angular";
import { ThunkAction } from "redux-thunk";
import { EnvironmentService } from "@gtmhub/env";
import { IUIError, createUIError } from "@gtmhub/error-handling";
import { ICollection } from "@webapp/core/core.models";
import { IBadge } from "..";
import { IBadgesStoreState } from "./badges-reducer";
import { BadgesAction, IErrorReceiveBadgesAction, IReceiveBadgesAction, IRequestBadgesAction } from "./models";

const requestBadges = (): IRequestBadgesAction => ({ type: "REQUEST_BADGES" });
const receiveBadges = (items: IBadge[]): IReceiveBadgesAction => ({ type: "RECEIVE_BADGES", items });
const requestBadgesError = (error: IUIError): IErrorReceiveBadgesAction => ({ type: "ERROR_RECEIVE_BADGES", error });
const shouldFetchBadges = (state: IBadgesStoreState) => !state.badges.isFetching && !state.badges.isFetched;

export class BadgesActions {
  public static $inject = ["$http", "EnvironmentService"];

  constructor(
    private $http: IHttpService,
    private env: EnvironmentService
  ) {}

  public updateBadges(badges: IBadge[]): (dispatch) => void {
    return (dispatch) => {
      dispatch(receiveBadges(badges));
    };
  }

  public fetchBadgesIfMissing(): ThunkAction<void, IBadgesStoreState, null, BadgesAction> {
    return (dispatch, getState) => {
      if (shouldFetchBadges(getState())) {
        dispatch(requestBadges());

        const url = this.env.getApiEndpoint("/badges");
        this.$http.get<ICollection<IBadge>>(url).then(
          (response) => {
            dispatch(receiveBadges(response.data.items));
          },
          (rejection) => dispatch(requestBadgesError(createUIError(rejection)))
        );
      }
    };
  }
}
