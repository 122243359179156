<ng-container *uiModalDescription>
  <span *ngIf="mode === 'approve'">{{ "okr_approve_workflow_will_be_published" | localize }}.</span>
  <span *ngIf="mode === 'decline'">{{ "its_workflow_status_draft_owner_resend" | localize }}</span>
  <span *ngIf="mode === 'withdraw'">{{ "its_workflow_status_draft_current_approval_lost" | localize }}</span>
</ng-container>
<form-group *ngIf="mode !== 'withdraw'">
  <rich-text-editor-with-mentions-form-item
    [labelText]="'comment' | localize"
    [useGifs]="false"
    [useMentions]="false"
    [useSubmitButton]="false"
    [placeholder]="'please_enter_comment' | localize"
    [value]="{ note: '' }"
    [required]="mode === 'decline'"
    controlName="okrWorkflowComment">
  </rich-text-editor-with-mentions-form-item>
</form-group>
