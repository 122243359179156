import { module } from "angular";
import upgrade from "@gtmhub/core/upgrade/module";
import employeesCore from "@gtmhub/employees/employees-core.module";
import favorites from "@gtmhub/favorites/favorites.module";
import listsCore from "@gtmhub/lists/lists-core.module";
import multiAccount from "@gtmhub/multi-account/module";
import notificationsCore from "@gtmhub/notifications/notifications-core.module";
import ngExtensions from "@gtmhub/shared/ng-extensions/ng-extensions.module";
import viewHistory from "@gtmhub/view-history/view-history.module";
import { DemoBannerComponent } from "@webapp/navigation/components/demo-banner/demo-banner.component";
import { NavigationComponent } from "@webapp/navigation/components/navigation/navigation/navigation.component";
import { ToggleNavComponent } from "@webapp/navigation/components/toggle-navigation/toggle-nav.component";
import { BillingInfoService } from "@webapp/navigation/services/billing-info.service";
import { IsNavigationExpandedCache } from "@webapp/navigation/services/is-navigation-expanded.cache";
import { NavigationReportsCacheService } from "@webapp/navigation/services/navigation-reports-cache.service";
import { configurePageTitleChange } from "./page-title";

const mod = module("navigation", [upgrade, ngExtensions, listsCore, notificationsCore, multiAccount, employeesCore, favorites, viewHistory]);

mod.downgradeComponent("demoBanner", { component: DemoBannerComponent, propagateDigest: false });
mod.downgradeComponent("navigation", { component: NavigationComponent, propagateDigest: false });
mod.downgradeComponent("toggleNav", { component: ToggleNavComponent, propagateDigest: false });
mod.downgradeInjectable("IsNavigationExpandedCache", IsNavigationExpandedCache);
mod.downgradeInjectable("NavigationReportsCache", NavigationReportsCacheService);
mod.downgradeInjectable("BillingInfoService", BillingInfoService);

configurePageTitleChange(mod);

export default mod.name;
