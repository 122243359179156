import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from "@angular/core";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzOptionComponent } from "ng-zorro-antd/select";

@Component({
  selector: "ui-option",
  exportAs: "uiOption",
  templateUrl: "option.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class UiOptionComponent extends NzOptionComponent {
  @Input("uiLabel") public nzLabel: string | number | null = null;
  @Input("uiValue") public nzValue: NzSafeAny | null = null;
  @Input("uiDisabled") @InputBoolean() public nzDisabled = false;
  @Input("uiHide") @InputBoolean() public nzHide = false;
  @Input("uiCustomContent") @InputBoolean() public nzCustomContent = false;
  @ViewChild(TemplateRef, { static: true }) public template!: TemplateRef<NzSafeAny>;
}
