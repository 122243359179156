import { NgClass, NgIf } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, NgModel } from "@angular/forms";
import { ThemeType } from "@ant-design/icons-angular";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { formatMaxCount } from "@webapp/ui/input/utils.input";
import { addAttributeValue } from "@webapp/ui/utils/dom.utils";
import { MaxStringLengthDirective } from "../../directives/max-string-length.directive";
import { DisplayMaxCharacterCountMode, InputTextFieldType, UpdateStrategy } from "../../input.models";
import { UiValidationHintComponent } from "../validation-hint/validation-hint.component";

const INPUT_TEXT_FIELD_CONTROL_VALUE_ACCESSOR = { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UiInputTextFieldComponent), multi: true };

@Component({
  selector: "input[ui-input-text-field], textarea[ui-input-text-field], ui-input-text-field",
  exportAs: "uiInputTextField",
  templateUrl: "./input-text-field.component.html",
  styleUrls: ["./input-text-field.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [INPUT_TEXT_FIELD_CONTROL_VALUE_ACCESSOR],
  host: {
    "[class.with-counter]": "maxCharacterCount && displayMaxCharacterCount",
  },
  standalone: true,
  imports: [NgClass, UiIconModule, FormsModule, UiTooltipModule, NgIf, UiValidationHintComponent, MaxStringLengthDirective],
})
export class UiInputTextFieldComponent implements OnChanges, OnInit, AfterViewInit, ControlValueAccessor {
  @Input() public uiId: string;
  @Input() public uiSize: "large" | "small" = "large";
  @Input() public uiName: string;
  @Input() public value: string;
  @Input() public uiPlaceholder: string;
  @Input() public a11yRequired = false;
  @Input() public a11yLabel = "";
  @Input() public a11yLabelledby: string;
  @Input() public a11yDescription?: string;
  @Input() public uiType: string;
  @Input() public uiTheme: ThemeType;
  @Input() public preventEmpty = false;
  @Input() public disabled = false;
  @Input() public readonly = false;
  @Input() public required = false;
  @Input() public borderless = false;
  @Input() public focusMe = false;
  @Input() public showTooltip = false;
  @Input() public e2eTestId: string;
  @Input() public updateOn: UpdateStrategy = "blur";
  @Input() public debounceTime = 1000;
  @Input() public maxCharacterCount: number = null;
  @Input() public displayMaxCharacterCount = false;
  @Input() public displayMaxCharacterCountMode: DisplayMaxCharacterCountMode = "always";
  @Input() public enforceCharactersLimit = false;
  @Input() public validationHint: string;
  @Input() public validationError: string;
  @Input() public isValidationHintAccented = false;
  @Input() public className: string;
  @Input() public valueChangeEvent: string;
  @Input() public shouldFitContent: boolean;
  @Input() public inputType: InputTextFieldType = "text";

  @Output() public readonly valueChange = new EventEmitter<string>();
  @Output() public readonly focusOut = new EventEmitter();
  @Output() public readonly enter = new EventEmitter();

  @ViewChild("inputField", { read: ElementRef, static: true }) public inputField: ElementRef<HTMLInputElement>;
  @ViewChild("inputField", { read: NgModel, static: true }) public inputFieldControl: NgModel;
  @ViewChild("inputFieldWrapper", { read: ElementRef, static: true }) public inputFieldWrapper: ElementRef<HTMLDivElement>;

  private notifyControlChange: (value: string) => void;

  public constructor(
    private zone: NgZone,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2
  ) {}

  public isFocused: boolean;
  private originalInput: string;
  private debounceTimeout: ReturnType<typeof setTimeout>;

  public ngOnInit(): void {
    this.originalInput = this.value;
  }

  public ngAfterViewInit(): void {
    addAttributeValue({
      element: this.inputField.nativeElement,
      attribute: "aria-describedby",
      value: this.uiId && this.a11yDescription ? this.uiId + "-description" : null,
    });

    if (this.shouldFitContent) {
      this.updateInputSize(this.value?.length);
    }
  }

  private countCharacters(): void {
    const currentCount = this.value?.length || 0;
    const dataCount = formatMaxCount(currentCount, this.maxCharacterCount);
    this.renderer.setAttribute(this.inputFieldWrapper.nativeElement, "data-count", dataCount);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.maxCharacterCount && this.displayMaxCharacterCount) {
      this.countCharacters();
    }

    if (changes.focusMe && changes.focusMe.currentValue) {
      this.inputField.nativeElement.focus();
      this.inputField.nativeElement.setSelectionRange(this.value?.length, this.value?.length);
    }

    if (changes.readonly && changes.readonly.currentValue) {
      this.resetControl();
    }
  }

  public onChange(value: string): void {
    if (this.maxCharacterCount && this.displayMaxCharacterCount) {
      this.countCharacters();
      this.cdr.detectChanges();
    }

    switch (this.updateOn) {
      case "blur":
        this.onBlur();
        break;
      case "change": {
        if (this.valueChangeEvent !== "blur") {
          this.zone.runOutsideAngular(() => {
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = setTimeout(() => {
              this.valueChange.emit(value);
              this.notifyControlChange?.(value);
            }, this.debounceTime);
          });
        }
      }
    }
  }

  public onFocus(): void {
    this.isFocused = true;
  }

  public onFocusOut(): void {
    if (this.updateOn !== "blur" && this.valueChangeEvent === "blur") {
      this.onBlur();
    }

    this.isFocused = false;

    this.focusOut.emit();

    this.cdr.detectChanges();
  }

  public handleInputChange(): void {
    if (this.shouldFitContent) {
      this.updateInputSize();
    }
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.notifyControlChange = fn;
  }

  public registerOnTouched(): void {
    // required by the ControlValueAccessor interface
  }

  public writeValue(value: string): void {
    this.value = value;

    if (this.displayMaxCharacterCount && this.maxCharacterCount > 0) {
      this.countCharacters();
    }

    this.cdr.markForCheck();
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  // 1. the reason we use on blur is because when we need to reset the value to what it originally was
  // needs first to change the value and then to reset it - if done in one step what happens it will
  // leave the input empty but the value underneath is changed
  // 2. Timeout is to handle the case where the value is a string with spaces/whitespaces only.
  // Giving 20ms for the value to be updated by angular before we check if it is empty.
  // Because if we restore it to originalValue it won't detect changes and input stays empty.
  private onBlur(): void {
    window.setTimeoutOutsideAngular(() => {
      if (this.preventEmpty && !this.value?.trim()) {
        this.value = this.originalInput;
      }

      if (this.value !== this.originalInput) {
        this.originalInput = this.value;
        this.valueChange.emit(this.value);
        this.notifyControlChange?.(this.value);
      }

      if (this.shouldFitContent) {
        this.updateInputSize(this.value?.length);
      }

      this.cdr.markForCheck();
    }, 20);
  }

  private resetControl(): void {
    this.inputFieldControl.control.markAsPristine();
    this.inputFieldControl.control.markAsUntouched();
  }

  private updateInputSize(valueLength?: number): void {
    const inputSize = (valueLength ?? this.inputField.nativeElement.value?.length) || 1;
    this.inputField.nativeElement.setAttribute("size", inputSize.toString());
  }
}
