import { Observable, Subject } from "rxjs";
import { ISettingParameters } from "../models/notification-settings.model";

export class ToggleChangeService {
  private toggleStateChange$: Subject<ISettingParameters> = new Subject<ISettingParameters>();

  public getToggleStateChange$(): Observable<ISettingParameters> {
    return this.toggleStateChange$.asObservable();
  }

  public setToggleStateChange$(settingParameters: ISettingParameters): void {
    if (settingParameters) {
      this.toggleStateChange$.next(settingParameters);
    }
  }
}
