import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, EventEmitter, Input, Output, TemplateRef, Type, ViewContainerRef } from "@angular/core";
import { UiButtonType } from "@quantive/ui-kit/button";
import { nzToUi } from "@quantive/ui-kit/core";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { ModalOptions, NzModalComponent, NzModalService } from "ng-zorro-antd/modal";
import { takeUntil } from "rxjs";
import { UiModalOptionsButtonDirective } from "@webapp/ui/modal/directives/modal-options-button.directive";
import { UiModalSideContentDirective } from "@webapp/ui/modal/directives/modal-side-content.directive";
import { UiModalContentDirective } from "./directives/modal-content.directive";
import { UiModalDescriptionDirective } from "./directives/modal-description.directive";
import { UiModalFooterDirective } from "./directives/modal-footer.directive";
import { UiModalTitleDirective } from "./directives/modal-title.directive";
import { UiConfirmType, UiModalButtonOptions, UiModalModels, UiModalOptions, UiOnClickCallback, UiStyleObjectLike } from "./modal.models";
import { UiModalService } from "./services/modal.service";

@Component({
  selector: "ui-modal",
  exportAs: "uiModal",
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class UiModalComponent<T = NzSafeAny, R = NzSafeAny> extends NzModalComponent {
  @Input("uiMask") @InputBoolean() public nzMask?: boolean;
  @Input("uiMaskClosable") @InputBoolean() public nzMaskClosable?: boolean;
  @Input("uiCloseOnNavigation") @InputBoolean() public nzCloseOnNavigation?: boolean;
  @Input("uiVisible") @InputBoolean() public nzVisible = false;
  @Input("uiClosable") @InputBoolean() public nzClosable = true;
  @Input("uiOkDisabled") @InputBoolean() public nzOkDisabled = false;
  @Input("uiCancelDisabled") @InputBoolean() public nzCancelDisabled = false;
  @Input("uiKeyboard") @InputBoolean() public nzKeyboard = true;
  @Input("uiCentered") @InputBoolean() public nzCentered = false;
  @Input("uiContent") public nzContent?: string | TemplateRef<object> | Type<T>;
  @Input("uiComponentParams") public nzComponentParams?: T;
  @Input("uiFooter") public nzFooter?: string | TemplateRef<object> | Array<UiModalButtonOptions<T>> | null;
  @Input("uiZIndex") public nzZIndex = 1000;
  @Input("uiWidth") public nzWidth: number | string = 500;
  @Input("uiWrapClassName") public nzWrapClassName?: string;
  @Input("uiClassName") public nzClassName?: string;
  @Input("uiStyle") public nzStyle?: object;
  @Input("uiTitle") public nzTitle?: string | TemplateRef<object>;
  @Input("uiCloseIcon") public nzCloseIcon: string | TemplateRef<void> = "close-big";
  @Input("uiMaskStyle") public nzMaskStyle?: UiStyleObjectLike;
  @Input("uiBodyStyle") public nzBodyStyle?: UiStyleObjectLike;
  @Input("uiOkText") public nzOkText?: string | null;
  @Input("uiCancelText") public nzCancelText?: string | null;
  @Input("uiOkType") public nzOkType: UiButtonType = "primary";
  @Input("uiCancelType") public nzCancelType: UiButtonType = "link";
  @Input("uiOkDanger") @InputBoolean() public nzOkDanger = false;
  @Input("uiIconType") public nzIconType = "help"; // Confirm Modal ONLY
  @Input("uiModalType") public nzModalType: UiModalModels = "default";
  @Input("uiConfirmType") public nzConfirmType: UiConfirmType = "confirm"; // Confirm Modal ONLY
  @Input("uiAutofocus") public nzAutofocus: "ok" | "cancel" | "auto" | null = "auto";
  @Input("uiNoAnimation") @InputBoolean() public nzNoAnimation = false;
  @Input("uiDescription") public nzDescription?: string | TemplateRef<NzSafeAny>;
  @Input("uiModalSideContent") public nzModalSideContent?: TemplateRef<object>;
  @Input("uiModalOptionsButton") public nzModalOptionsButton?: TemplateRef<object>;

  // TODO(@from ng-zorro) Input will not be supported
  @Input("uiOnOk")
  @Output("uiOnOk")
  public readonly nzOnOk: EventEmitter<T> | UiOnClickCallback<T> | NzSafeAny = new EventEmitter<T>();

  // TODO(@from ng-zorro) Input will not be supported
  @Input("uiOnCancel")
  @Output("uiOnCancel")
  public readonly nzOnCancel: EventEmitter<T> | UiOnClickCallback<T> | NzSafeAny = new EventEmitter<T>();

  @Output("uiAfterOpen") public readonly nzAfterOpen = new EventEmitter<void>();
  @Output("uiAfterClose") public readonly nzAfterClose = new EventEmitter<R>();
  @Output("uiVisibleChange") public readonly nzVisibleChange = new EventEmitter<boolean>();

  @ContentChild(UiModalTitleDirective, { static: true, read: TemplateRef })
  public set modalTitle(value: TemplateRef<NzSafeAny>) {
    super.modalTitle = value;
  }

  @ContentChild(UiModalDescriptionDirective, { static: true, read: TemplateRef })
  public set modalDescription(value: TemplateRef<NzSafeAny>) {
    if (value) {
      this.setDescriptionWithTemplate(value);
    }
  }

  @ContentChild(UiModalSideContentDirective, { static: true, read: TemplateRef })
  public set modalSideContent(value: TemplateRef<NzSafeAny>) {
    if (value) {
      this.setSideContentWithTemplate(value);
    }
  }

  @ContentChild(UiModalOptionsButtonDirective, { static: true, read: TemplateRef })
  public set modalOptionsButton(value: TemplateRef<NzSafeAny>) {
    if (value) {
      this.setOptionsButtonWithTemplate(value);
    }
  }

  @ContentChild(UiModalContentDirective, { static: true, read: TemplateRef })
  public contentFromContentChild!: TemplateRef<NzSafeAny>;

  @ContentChild(UiModalFooterDirective, { static: true, read: TemplateRef })
  public set modalFooter(value: TemplateRef<NzSafeAny>) {
    super.modalFooter = value;
  }

  constructor(
    cdr: ChangeDetectorRef,
    private modalService: UiModalService,
    viewContainerRef: ViewContainerRef
  ) {
    super(cdr, modalService as unknown as NzModalService, viewContainerRef);
  }

  public open(): void {
    if (!this.nzVisible) {
      this.nzVisible = true;
      this.nzVisibleChange.emit(true);
    }

    if (!this["modalRef"]) {
      const config: ModalOptions = this["getConfig"]();
      const uiConfig: UiModalOptions = nzToUi(config);

      if (uiConfig.uiModalType === "confirm") {
        delete uiConfig.uiFooter;
        delete uiConfig.uiAutofocus;

        this["modalRef"] = this.modalService.confirm(uiConfig, this.nzConfirmType);
      } else {
        if (this.nzDescription) {
          uiConfig.uiDescription = this.nzDescription;
        }

        if (this.nzModalSideContent) {
          uiConfig.uiModalSideContent = this.nzModalSideContent;
        }

        if (this.nzModalOptionsButton) {
          uiConfig.uiModalOptionsButton = this.nzModalOptionsButton;
        }

        this["modalRef"] = this.modalService.create(uiConfig);
      }

      // When the modal is implicitly closed (e.g. closeAll) the nzVisible needs to be set to the correct value and emit.
      this["modalRef"].afterClose
        .asObservable()
        .pipe(takeUntil(this["destroy$"]))
        .subscribe(() => {
          this.close();
        });
    }
  }

  private setDescriptionWithTemplate(templateRef: TemplateRef<NzSafeAny>): void {
    this.nzDescription = templateRef;
    if (this["modalRef"]) {
      // If modalRef already created, set the title in next tick
      Promise.resolve().then(() => {
        this["modalRef"].updateConfig({ nzDescription: this.nzDescription });
      });
    }
  }

  private setSideContentWithTemplate(templateRef: TemplateRef<NzSafeAny>): void {
    this.nzModalSideContent = templateRef;
    if (this["modalRef"]) {
      Promise.resolve().then(() => {
        this["modalRef"].updateConfig({ nzModalSideContent: this.nzModalSideContent });
      });
    }
  }

  private setOptionsButtonWithTemplate(templateRef: TemplateRef<NzSafeAny>): void {
    this.nzModalOptionsButton = templateRef;
    if (this["modalRef"]) {
      Promise.resolve().then(() => {
        this["modalRef"].updateConfig({ nzModalOptionsButton: this.nzModalOptionsButton });
      });
    }
  }
}
