import { getPathPrefix, isLocalhost, isQuantiveDomain } from "@gtmhub/env";

export interface IAuthCookieValue {
  state: string;
  nonce: string;
  appState?: Record<string, string>;
}

export class AuthCookie {
  constructor(
    private domain: string,
    private secure: boolean
  ) {}

  set(value: IAuthCookieValue, opts: { maxAgeInSecs: number }): void {
    const cookieDomain = isQuantiveDomain(this.domain) ? "quantive.com" : "gtmhub.com";
    const cookieParts = [
      "a0s=" + encodeURIComponent(value ? JSON.stringify(value) : ""),
      "domain=" + (isLocalhost(this.domain) ? this.domain.split(":")[0] : cookieDomain),
      "max-age=" + opts.maxAgeInSecs,
      `path=${getPathPrefix() ? getPathPrefix() : "/"}`,
      "SameSite=None",
    ];

    if (this.secure) {
      cookieParts.push("secure=true");
    }

    document.cookie = cookieParts.join("; ");
  }

  getAndRemove(): IAuthCookieValue {
    const stateCookies = (document.cookie || "").split("; ").filter(function (cookie) {
      return cookie.indexOf("a0s=") === 0;
    });
    if (!stateCookies.length) {
      return null;
    }

    const value = decodeURIComponent(stateCookies[0].split("=")[1]);
    if (!value) {
      return null;
    }

    this.set(null, { maxAgeInSecs: -1 });
    return JSON.parse(value) as IAuthCookieValue;
  }
}

export const setCookie = (cookieKey, cookieValue, domain, opts?: { maxAgeInSecs?: number; sameSite?: "None" | "Strict" | "Lax"; secure?: boolean }) => {
  const cookieDomain = isQuantiveDomain(domain) ? "quantive.com" : "gtmhub.com";
  const cookieParts = [
    `${cookieKey}=` + encodeURIComponent(cookieValue ? JSON.stringify(cookieValue) : ""),
    "domain=" + (isLocalhost(domain) ? domain.split(":")[0] : cookieDomain),
    `path=${getPathPrefix() ? getPathPrefix() : "/"}`,
    `SameSite=${opts?.sameSite ? opts?.sameSite : "None"}`,
  ];

  if (opts?.maxAgeInSecs) {
    cookieParts.push("max-age=" + opts.maxAgeInSecs);
  }

  if (opts?.secure) {
    cookieParts.push("secure=true");
  }

  document.cookie = cookieParts.join("; ");
};
