import { ModuleWithProviders, NgModule } from "@angular/core";
import { ShortNumberPipe } from "./short-number.pipe";

@NgModule({
  declarations: [ShortNumberPipe],
  exports: [ShortNumberPipe],
})
export class ShortNumberModule {
  public static forRoot(): ModuleWithProviders<ShortNumberModule> {
    return {
      ngModule: ShortNumberModule,
      providers: [ShortNumberPipe],
    };
  }
}
