import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { MarketplaceInsightTemplate, MarketplaceInsightTemplateDTO } from "@webapp/data-story/models/marketplace-facade.models";
import { DataSourcesState } from "@webapp/data-story/services/data-sources-facade/data-sources-state.service";
import { MarketplaceState } from "@webapp/data-story/services/marketplace-facade/marketplace-state.service";

@Injectable({
  providedIn: "root",
})
export class MarketplaceApiService extends BaseApiService<MarketplaceInsightTemplate, MarketplaceInsightTemplateDTO, MarketplaceState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: DataSourcesState) {
    super("marketplace", { ...new ApiVersionModel("v2"), post: "v2" }, httpClient, appConfig, state);
  }

  public getMarketplaceInsightTemplateInstallEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}/templates/insights/install`;
  }

  public getMarketplaceInsightTemplateEndpoint(): string {
    return `${this.getBasePath(HttpActions.get)}/templates/insights`;
  }

  public getSubmitMarketplaceInsightTemplateEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}/templates/insights`;
  }

  public getSubmitOkrToMarketplaceEndpoint(goalId: string): string {
    return `${this.getBasePath(HttpActions.post)}/submit-to-marketplace/okr/${goalId}`;
  }

  public getFeaturedInsightboardsEndpoint(): string {
    return this.getMarketplaceInsightboardsEndpoint();
  }

  public getInstallMarketplaceInsightboardsEndpoint(): string {
    return `${this.getMarketplaceInsightboardsEndpoint()}/install`;
  }

  public getDirectSubmitInsightboardEndpoint(): string {
    return `${this.getMarketplaceInsightboardsEndpoint()}/direct`;
  }

  private getMarketplaceInsightboardsEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}/templates/insight-boards`;
  }

  public getMarketplaceEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}/templates/goals`;
  }
}
