export enum SearchOperatorsEnum {
  matchPrefix = 0,
  matchAny = 4,
}

export interface ISearchRequestsBody {
  collectionName: string;
  // empty array if operator is 4
  searchFields?: ISearchField[];
  responseFields?: string[];
  searchConditions?: ISearchCondition[];
  optionalSearchFilter?: IOptionalSearchFilter[];
}

export interface ISearchField {
  name: string;
}

export interface ISearchCondition {
  fieldName: string;
  fieldCondition: ISearchFieldCondition;
}

export interface IOptionalSearchFilter {
  name: string;
  operator: OperatorType;
  value: string | string[];
  weight: number;
}

export interface ISearchFieldCondition {
  operator: OperatorType;
  value: string | string[];
}

type OperatorType = "is" | "isNot" | "in" | "notIn";

export interface ISearchParams {
  skip: number;
  take: number;
}
