import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { INotificationSettings } from "@gtmhub/notifications/models/notifications";
import { ISettingKeys, ISettingParameters } from "@webapp/notifications/models/notification-settings.model";
import { ToggleChangeService } from "@webapp/notifications/services/toggle-change.service";

@Component({
  selector: "notification-category",
  templateUrl: "./notification-category.component.html",
  styleUrls: ["./notification-category.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationCategoryComponent {
  @Input()
  public notificationSettings: INotificationSettings;
  @Input()
  public settingKeys: ISettingKeys[];
  @Input()
  public categoryNameKey: string;
  @Input()
  public icon: string;

  constructor(private toggleChangeService: ToggleChangeService) {}

  public changeStatus(settingParameters: ISettingParameters): void {
    this.toggleChangeService.setToggleStateChange$(settingParameters);
  }
}
