import { NgIf } from "@angular/common";
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Type,
  ViewChild,
  ViewContainerRef,
  reflectComponentType,
} from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SimpleChangesOf } from "@quantive/ui-kit/core";
import { GridHTMLElement, GridItemHTMLElement, GridStack, GridStackNode, GridStackOptions, GridStackWidget } from "gridstack";
import { isMobile } from "@gtmhub/shared/utils";
import { LoginMobileWarningService } from "@webapp/login/services/login-mobile-warning.service";
import { BaseWidgetComponent } from "./components/base-widget.component";
import { UiDashboardItemComponent } from "./components/dashboard-item/dashboard-item.component";
import { UiDashboardDropEvent, UiDashboardElementEvent, UiDashboardNodesEvent, UiDashboardOptions, UiDashboardWidget } from "./dashboard.models";

const getSelector = (type: Type<object>): string => reflectComponentType(type)!.selector;

export interface UiGridHTMLElement extends GridHTMLElement {
  component?: UiDashboardComponent;
}

@UntilDestroy()
@Component({
  selector: "ui-dashboard",
  template: `
    <!-- content to show when when grid is empty, like instructions on how to add widgets -->
    <ng-content *ngIf="isEmpty" select="[ui-dashboard-empty]"></ng-content>

    <!-- where dynamic items go -->
    <ng-template #container></ng-template>

    <!-- where template items go -->
    <ng-content></ng-content>
  `,
  styleUrls: ["./dashboard.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [LoginMobileWarningService],
  imports: [NgIf],
})
export class UiDashboardComponent implements OnInit, AfterContentInit, OnDestroy, OnChanges {
  @Input() public set uiOptions(val: UiDashboardOptions) {
    this.tempOptions = val;
  }
  public get uiOptions(): UiDashboardOptions {
    return this.gridInternal?.opts || this.tempOptions || {};
  }

  @Input() public uiWidgets: UiDashboardWidget[] = [];

  @Output() public readonly uiAdd = new EventEmitter<UiDashboardNodesEvent>();
  @Output() public readonly uiChange = new EventEmitter<UiDashboardNodesEvent>();
  @Output() public readonly uiDisable = new EventEmitter<void>();
  @Output() public readonly uiDrag = new EventEmitter<UiDashboardElementEvent>();
  @Output() public readonly uiDragStart = new EventEmitter<UiDashboardElementEvent>();
  @Output() public readonly uiDragStop = new EventEmitter<UiDashboardElementEvent>();
  @Output() public readonly uiDrop = new EventEmitter<UiDashboardDropEvent>();
  @Output() public readonly uiEnable = new EventEmitter<void>();
  @Output() public readonly uiRemove = new EventEmitter<UiDashboardNodesEvent>();
  @Output() public readonly uiResize = new EventEmitter<UiDashboardElementEvent>();
  @Output() public readonly uiResizeStart = new EventEmitter<UiDashboardElementEvent>();
  @Output() public readonly uiResizeStop = new EventEmitter<UiDashboardElementEvent>();

  @ViewChild("container", { read: ViewContainerRef, static: true }) public container?: ViewContainerRef;
  @ContentChildren(UiDashboardItemComponent) public dashboardItems: QueryList<UiDashboardItemComponent>;

  public isEmpty?: boolean;

  public get el(): UiGridHTMLElement {
    return this.elementRef.nativeElement;
  }

  public get grid(): GridStack | undefined {
    return this.gridInternal;
  }

  public ref: ComponentRef<UiDashboardComponent> | undefined;

  public static selectorToType: Record<string, Type<BaseWidgetComponent>> = {};

  public static registerWidgetComponents(...types: Type<BaseWidgetComponent>[]): void {
    for (const type of types) {
      UiDashboardComponent.selectorToType[getSelector(type)] = type;
    }
  }

  private tempOptions?: GridStackOptions;
  private gridInternal?: GridStack;
  private loaded?: boolean;

  constructor(
    private elementRef: ElementRef<UiGridHTMLElement>,
    private cdr: ChangeDetectorRef,
    private loginMobileWarningService: LoginMobileWarningService
  ) {
    this.el.component = this;
  }

  public ngOnChanges(changes: SimpleChangesOf<UiDashboardComponent>): void {
    if (changes.uiWidgets && this.gridInternal) {
      this.gridInternal.load(structuredClone(this.uiWidgets) || []);
      this.checkEmpty();
    }
  }

  public ngOnInit(): void {
    const isFromMobileDevice = isMobile();
    if (isFromMobileDevice) {
      this.loginMobileWarningService.openWarningModal();
    }

    // init ourself before any template children are created since we track them below anyway - no need to double create+update widgets
    const widgets = this.uiWidgets || [];
    this.loaded = !!widgets.length;
    if (widgets.length) {
      this.tempOptions = { ...this.tempOptions, children: structuredClone(widgets) };
    }
    this.gridInternal = GridStack.init(this.tempOptions, this.el);
    this.patchGrid();
    delete this.tempOptions;

    this.checkEmpty();
  }

  public ngAfterContentInit(): void {
    this.dashboardItems.changes.pipe(untilDestroyed(this)).subscribe(() => this.updateAll());

    // ...and do this once at least unless we loaded children already
    if (!this.loaded) {
      this.updateAll();
    }

    this.hookEvents();
  }

  public ngOnDestroy(): void {
    delete this.ref;
    this.grid?.destroy();
    delete this.gridInternal;
    delete this.el.component;
  }

  /**
   * called when the TEMPLATE list of items changes - get a list of nodes and
   * update the layout accordingly (which will take care of adding/removing items changed by Angular)
   */
  public updateAll(): void {
    if (!this.grid) {
      return;
    }

    const layout: GridStackWidget[] = [];
    this.dashboardItems?.forEach((item) => {
      layout.push(item.uiOptions);
      item.clearOptions();
    });
    this.grid.load(layout);
  }

  public checkEmpty(): void {
    if (!this.grid) {
      return;
    }

    const isEmpty = !this.grid.engine.nodes.length;
    if (isEmpty === this.isEmpty) {
      return;
    }

    this.isEmpty = isEmpty;
    this.cdr.detectChanges();
  }

  private hookEvents(): void {
    this.grid
      .on("added", (event: Event, nodes: GridStackNode[]) => {
        this.checkEmpty();
        this.uiAdd.emit({ nodes });
      })
      .on("change", (event: Event, nodes: GridStackNode[]) => this.uiChange.emit({ nodes }))
      .on("disable", () => this.uiDisable.emit())
      .on("drag", (event: Event, el: GridItemHTMLElement) => this.uiDrag.emit({ el }))
      .on("dragstart", (event: Event, el: GridItemHTMLElement) => this.uiDragStart.emit({ el }))
      .on("dragstop", (event: Event, el: GridItemHTMLElement) => this.uiDragStop.emit({ el }))
      .on("dropped", (event: Event, previousNode: GridStackNode, newNode: GridStackNode) => this.uiDrop.emit({ previousNode, newNode }))
      .on("enable", () => this.uiEnable.emit())
      .on("removed", (event: Event, nodes: GridStackNode[]) => {
        this.checkEmpty();
        this.uiRemove.emit({ nodes });
      })
      .on("resize", (event: Event, el: GridItemHTMLElement) => this.uiResize.emit({ el }))
      .on("resizestart", (event: Event, el: GridItemHTMLElement) => this.uiResizeStart.emit({ el }))
      .on("resizestop", (event: Event, el: GridItemHTMLElement) => this.uiResizeStop.emit({ el }));
  }

  private patchGrid(): void {
    const resizeToContent = this.gridInternal.resizeToContent;
    // eslint-disable-next-line @foxglove/no-boolean-parameters
    this.gridInternal.resizeToContent = function (el: GridItemHTMLElement, useAttrSize?: boolean): void {
      el.style.removeProperty("--ui-dashboard-item-height");

      resizeToContent.call(this, el, useAttrSize);

      const { offsetTop } = el.querySelector<HTMLElement>(".grid-stack-item-content");
      const height = parseInt(el.getAttribute("gs-h"), 10) - 2 * offsetTop;
      el.style.setProperty("--ui-dashboard-item-height", `${height}px`);
    };
  }
}
