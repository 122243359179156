import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { finalize, take } from "rxjs";
import { IUIError, UIErrorHandlingService } from "@gtmhub/error-handling";
import { localize } from "@gtmhub/localization";
import { getCurrentUserId } from "@gtmhub/users";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { AssigneesRepository } from "@webapp/assignees/services/assignees-repository.service";
import { AggregatedReaction, Comment, CreateReactionDTO, Reaction, ReactionType, ReactionsFacade } from "@webapp/comments";
import { IMetricSnapshot } from "@webapp/okrs/metrics/models/metric.models";
import { checkinTargetTypes } from "@webapp/reflections/models/reflections.models";
import { EmojiPickerController } from "@webapp/shared/emoji-picker";
import { CurrentUserRepository } from "@webapp/users";

export const MAX_REACTIONS_ALLOWED = 23;

@UntilDestroy()
@Component({
  selector: "reactions",
  templateUrl: "./reactions.component.html",
  styleUrls: ["./reactions.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactionsComponent implements OnInit, OnDestroy {
  @Input() public target: Comment | IMetricSnapshot;
  @Input() public idField = "id";
  @Input() public targetType: string;
  /**
   * @param targetParentId {string} - represents Check-in ID that reaction is associated with
   */
  @Input() public targetParentId: string;
  @Input() public reactions;
  @Input() public metadata: { [key: string]: string | boolean };

  @Output() public readonly reactionsChange: EventEmitter<AggregatedReaction[]> = new EventEmitter();

  public reactionAuthorsMap: Record<string, string> = {};

  public get isCurrentUserViewOnly(): boolean {
    return this.currentUserRepository.userHasRole("view-only");
  }

  public get emojiPickerToggleIcon(): string {
    return this.emojiPicker.isLoading ? "loading" : "reaction";
  }

  public emojiPicker: EmojiPickerController;
  public assignees: Map<string, Assignee> = new Map();

  private disableReactUpdate = false;
  private maxReactionsAllowed = MAX_REACTIONS_ALLOWED;

  constructor(
    private currentUserRepository: CurrentUserRepository,
    private uiErrorHandlingService: UIErrorHandlingService,
    private assigneesRepository: AssigneesRepository,
    private changeDetectorRef: ChangeDetectorRef,
    private reactionsFacade: ReactionsFacade,
    private elementRef: ElementRef<HTMLElement>
  ) {
    this.emojiPicker = new EmojiPickerController({
      referenceElement: (): HTMLElement => elementRef.nativeElement.querySelector<HTMLButtonElement>("button.emoji-picker-toggle-button"),
      triggerElement: (): HTMLElement => elementRef.nativeElement.querySelector<HTMLButtonElement>("button.emoji-picker-toggle-button"),
    });
  }

  public ngOnInit(): void {
    this.assigneesRepository
      .getMap$()
      .pipe(untilDestroyed(this))
      .subscribe((map) => {
        this.assignees = map;
        this.updateReactionAuthorsMap();
      });

    this.setUpPickerSubscriptions();
  }

  public ngOnDestroy(): void {
    this.emojiPicker?.destroy();
  }

  public reactUpdate(selectedReaction: ReactionType, options?: { adjustFocusOnRemove: boolean }): void {
    // prevent new updates until the request for the previous one has completed
    if (this.disableReactUpdate || this.isCurrentUserViewOnly) {
      return;
    }

    this.disableReactUpdate = true;

    const existingReaction = this.reactions?.find((reaction) => reaction.name.toLowerCase() === selectedReaction.name);

    if (existingReaction?.currentUserReacted) {
      this.removeReaction(existingReaction, options);
    } else {
      this.addReaction(selectedReaction);
    }
  }

  /**
   * Creates aria-label texts for each existing reaction.
   *
   * @example User X, User Y and User Z reacted with some-emoji-name
   * @example User X and User Y reacted with some-emoji-name
   * @example User X reacted with some-emoji-name
   */
  private updateReactionAuthorsMap(): void {
    this.reactionAuthorsMap = (this.reactions || []).reduce((map, reaction) => {
      // map the user IDs of reacted users to their names
      const names = reaction.userIds.map((id) => this.assignees.get(id)?.name).filter(Boolean);

      // compose a string with all but the last name of reacted users, separated with commas
      const commaSeparatedNames = names.slice(0, names.length - 1).join(", ");

      // if there's more than one user reacted, add the 'and' preposition
      const preposition = names.length > 1 ? ` ${localize("and")} ` : "";

      // add the last name on the reacted users list
      const lastNameOnList = `${names[names.length - 1]}`;

      // 'has reacted with {emoji name}'
      const hasReactedWithEmoji = ` ${localize("reacted_with")} ${reaction.name}`;

      // merge all of the above
      map[reaction.name] = `${commaSeparatedNames}${preposition}${lastNameOnList}${hasReactedWithEmoji}`;

      return map;
    }, {});
  }

  private setUpPickerSubscriptions(): void {
    this.emojiPicker.pickerLoaded$.pipe(untilDestroyed(this), take(1)).subscribe(() => this.changeDetectorRef.markForCheck());
    this.emojiPicker.selectedReaction$.pipe(untilDestroyed(this)).subscribe((reaction) => this.reactUpdate(reaction, { adjustFocusOnRemove: false }));
  }

  private addReaction(selectedReaction: ReactionType): void {
    const isReactionTypeNew = !this.reactions?.some((reaction) => reaction.name === selectedReaction.name);
    const isReactionTypesLimitReached = this.reactions?.length >= this.maxReactionsAllowed;

    if (isReactionTypeNew && isReactionTypesLimitReached) {
      this.uiErrorHandlingService.handleModal({
        title: localize("max_number_of_reactions_reached_title"),
        message: localize("max_number_of_reactions_reached_description", { maxReactionsAllowed: this.maxReactionsAllowed }),
      } as IUIError);

      this.disableReactUpdate = false;

      return;
    }

    const reaction: CreateReactionDTO = {
      name: selectedReaction.name,
      emoji: selectedReaction.emoji,
      targetId: this.target[this.idField],
      targetType: this.targetType,
      ...(this?.targetParentId && { targetParentId: this.targetParentId }),
    };

    if (!this.metadata) {
      this.metadata = {};
    }

    this.metadata["reactionOnGif"] = !!this.target.gif?.id;

    if (this.isTargetCheckins) {
      this.metadata["name"] = selectedReaction.name;
    }

    this.reactionsFacade
      .createReaction$(reaction, this.metadata)
      .pipe(
        take(1),
        untilDestroyed(this),
        finalize(() => {
          this.disableReactUpdate = false;
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe({
        next: (newReaction) => {
          this.updateAggregatedReactionsOnAdd(newReaction);
        },
        error: (error) => {
          this.uiErrorHandlingService.handleModal(error);
        },
      });
  }

  private removeReaction(reaction: AggregatedReaction, options?: { adjustFocusOnRemove: boolean }): void {
    if (this.isTargetCheckins) {
      if (!this.metadata) {
        this.metadata = {};
      }
      this.metadata["name"] = reaction.name;
    }
    this.reactionsFacade
      .deleteReaction$(reaction.name, this.target[this.idField], this.metadata)
      .pipe(
        take(1),
        untilDestroyed(this),
        finalize(() => {
          this.disableReactUpdate = false;
          this.changeDetectorRef.markForCheck();
        })
      )
      .subscribe({
        next: () => {
          this.updateAggregatedReactionsOnRemove(reaction, options);
        },
        error: (error) => {
          this.uiErrorHandlingService.handleModal(error);
        },
      });
  }

  private updateAggregatedReactionsOnAdd(newReaction: Reaction): void {
    this.reactions = this.reactions || [];
    const existingAggregatedReaction = this.reactions.find((reaction) => reaction.name === newReaction.name);

    if (existingAggregatedReaction) {
      existingAggregatedReaction.currentUserReacted = true;
      existingAggregatedReaction.userIds.push(newReaction.userId);
      existingAggregatedReaction.count = existingAggregatedReaction.userIds.length;
    } else {
      this.reactions.push({
        name: newReaction.name,
        emoji: newReaction.emoji,
        targetId: newReaction.targetId,
        targetType: newReaction.targetType,
        userIds: [newReaction.userId],
        currentUserReacted: true,
        count: 1,
      });
    }

    this.updateReactionAuthorsMap();
    this.reactionsChange.emit(this.reactions);
  }

  private updateAggregatedReactionsOnRemove(deletedReaction: AggregatedReaction, options?: { adjustFocusOnRemove: boolean }): void {
    this.reactions = this.reactions || [];

    const aggregatedReaction = this.reactions.find((reaction) => reaction.name === deletedReaction.name);

    if (aggregatedReaction) {
      aggregatedReaction.currentUserReacted = false;
      aggregatedReaction.userIds = aggregatedReaction.userIds.filter((userId) => userId !== getCurrentUserId());
      aggregatedReaction.count = aggregatedReaction.userIds.length;

      if (aggregatedReaction.count === 0) {
        // move the focus to a sibling emoji before removing the element
        if (options?.adjustFocusOnRemove) {
          this.moveFocusOnSiblingEmoji(aggregatedReaction.name);
        }

        this.reactions = this.reactions.filter((currentReaction) => currentReaction.name !== deletedReaction.name);
      }
    }

    this.updateReactionAuthorsMap();
    this.reactionsChange.emit(this.reactions);
  }

  private get isTargetCheckins(): boolean {
    const targetType = this.metadata?.targetType as string;
    if (!targetType) {
      return false;
    }

    return checkinTargetTypes.includes(this.metadata?.targetType as string);
  }

  private moveFocusOnSiblingEmoji(currentFocusedEmojiName: string): void {
    const reactionIndex = this.reactions.findIndex((currentReaction) => currentReaction.name === currentFocusedEmojiName);
    const shouldMoveLeft = reactionIndex === this.reactions.length - 1 && this.reactions.length > 1;

    const reactionElement = this.elementRef.nativeElement.querySelector(`[id="${this.target?.id}_${currentFocusedEmojiName}"]`);
    const nextFocusTarget = (shouldMoveLeft ? reactionElement?.previousElementSibling : reactionElement?.nextElementSibling) as HTMLButtonElement;

    nextFocusTarget?.focus();
  }
}
