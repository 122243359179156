<gh-dropdown *ngIf="menuItems" [menuItems]="menuItems" [hasCustomButtonContent]="true" [showNewDesign]="true" (dropdownToggled)="dropdownToggled($event)" placement="bottomRight">
  <button
    id="createNewOKRBtn"
    [disabled]="disabled"
    [attr.aria-expanded]="dropdownVisible"
    uiType="primary"
    uiShape="round"
    gh-dropdown-custom-button-content
    ui-button
    aria-haspopup="true"
    uiTrigger="click"
    data-test-id="top-nav-bar-buttons-dropdown">
    <span class="selected">
      <span class="text">{{ "create_okr" | localize }} </span>
    </span>

    <i class="sp-ml-10" ui-icon uiType="chevron-down-small" uiSize="sm"></i>
  </button>
</gh-dropdown>
