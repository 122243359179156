import { IRootScopeService, IScope } from "angular";
import { EmailVerificationComponent } from "@gtmhub/edition-plan-change";
import { IIndicator } from "@gtmhub/error-handling";
import { SnackbarEvents } from "@gtmhub/shared/components/ui/snackbar/snackbar.events";
import { IProfile } from "@webapp/user-profile/models/user-profile.models";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";
import { IUserMetadata } from "../../login/models";
import { getCurrentUserId } from "../../users/current-user-storage";
import { ChangeUserPictureModalService } from "../../users/services/change-user-picture-modal.service";
import { UserService } from "../../users/user-service";

interface IUserProfileScope extends IScope {
  userId: string;
  page: string;
  errorMessage: string;
  hasModifications: boolean;
  profile: IProfile & { id: string };
  indicators: {
    saving?: IIndicator;
  };
  type: string;
  typeEmailVerificationComponent: EmailVerificationComponent;

  save(): void;

  trackChange(): void;

  openChangeUserPictureModal(): void;
}

export class UserProfileCtrl {
  private snackbarEvents: SnackbarEvents;
  public static $inject = ["$rootScope", "$scope", "UserService", "UserProfileService", "ChangeUserPictureModalService"];

  constructor(
    private $rootScope: IRootScopeService,
    private $scope: IUserProfileScope,
    private userService: UserService,
    private profileService: UserProfileService,
    private changeUserPictureModalService: ChangeUserPictureModalService
  ) {
    this.snackbarEvents = new SnackbarEvents($scope);
    this.init();
  }

  private init = () => {
    this.$scope.indicators = {};
    this.$scope.errorMessage = "";
    this.$scope.type = "profile";
    this.$scope.typeEmailVerificationComponent = "profile";
    this.$scope.save = this.save;
    this.$scope.trackChange = this.trackChange;
    this.$scope.hasModifications = false;
    this.$scope.openChangeUserPictureModal = this.openChangeUserPictureModal;

    this.loadUser();
    this.subscribeForProfileUpdate();
  };

  private trackChange = () => {
    this.$scope.hasModifications = true;
  };

  private save = () => {
    this.$scope.errorMessage = "";
    this.$scope.indicators.saving = { progress: true };
    const userMetadata: IUserMetadata = {
      firstName: this.$scope.profile.firstName,
      lastName: this.$scope.profile.lastName,
    };

    this.userService.updateUserInfo(this.$scope.userId, userMetadata).then(
      () => {
        delete this.$scope.indicators.saving;
        this.$scope.hasModifications = false;
        this.snackbarEvents.broadcastShowSnackbar("profile_updated");
      },
      (error) => {
        this.$scope.errorMessage = error.message;
        delete this.$scope.indicators.saving;
      }
    );
  };

  private loadUser = () => {
    this.$scope.userId = getCurrentUserId();
    this.$scope.profile = { ...this.profileService.getProfile(), id: this.$scope.userId };
  };

  private subscribeForProfileUpdate = () => {
    const removeUserProfileUpdatedListener = this.$rootScope.$on("userProfileUpdated", (evt, profile: IProfile) => {
      this.$scope.profile = { ...profile, id: this.$scope.userId };
    });

    this.$scope.$on("$destroy", removeUserProfileUpdatedListener);
  };

  private openChangeUserPictureModal = () => {
    this.changeUserPictureModalService.openChangeUserPictureModal(this.$scope.userId);
  };
}
