export interface IChecklist {
  stepsV1: {
    watchedVideo: boolean;
    invitedTeammate: boolean;
    createdOkr: boolean;
    visitedDemoAccount: boolean;
    visitedMarketplace: boolean;
    hasAssignedOkrs: boolean;
  };
  stepsV1Completed: boolean;
  showCards: {
    inviteTeammate: boolean;
    visitDemoAccount: boolean;
    visitMarketplace: boolean;
  };
  optOut: boolean;
}

export const INITIAL_STEP = 0;
export const FAVORITES_STEP = 1;
