import { NgFor } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UiAccessibilityModule } from "@quantive/ui-kit/accessibility";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiI18nModule } from "@quantive/ui-kit/i18n";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { ɵMonthHeaderComponent as MonthHeaderComponent } from "ng-zorro-antd/date-picker";
import { UiDateHelperService } from "@webapp/ui/date-picker/services/date-helper.service";
import { UiPanelSelector } from "../../date-picker.models";

@Component({
  selector: "ui-month-header",
  exportAs: "uiMonthHeader",
  templateUrl: "../abstract-panel-header.html",
  styleUrls: ["month-header.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "ui-date-header",
  },
  standalone: true,
  imports: [UiAccessibilityModule, NgFor, UiI18nModule, UiIconModule, UiButtonModule],
})
export class UiMonthHeaderComponent extends MonthHeaderComponent {
  /** the id of the element holding month and year buttons and used to label the table of dates/months/years/decades */
  @Input()
  public uiLabelId: string;

  public selectors: UiPanelSelector[];

  constructor(dateHelper: UiDateHelperService) {
    super(dateHelper);
  }
}
