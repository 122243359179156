import { IScope } from "angular";
import { MonoTypeOperatorFunction, Subject, takeUntil } from "rxjs";

interface ICustomScope extends IScope {
  destroy$?: Subject<void>;
}

export function untilScopeDestroyed<T>(scope: IScope): MonoTypeOperatorFunction<T> {
  const custom = scope as ICustomScope;
  if (!custom.destroy$) {
    custom.destroy$ = new Subject<void>();

    scope.$on("$destroy", () => {
      custom.destroy$.next();
      custom.destroy$.complete();
    });
  }
  return takeUntil(custom.destroy$);
}
