import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { UiDashboardWidget, WidgetPositionChange } from "@webapp/ui/dashboard/dashboard.models";
import { DEFAULT_WIDGET_HEIGHT } from "../models/home-widgets.models";
import { HomeWidgetService } from "./home-widget.service";

@Injectable()
export class HomeWidgetConfigurationService {
  constructor(private homeWidgetService: HomeWidgetService) {}

  public createConfiguratorForCurrentDashboardWidgets$(): Observable<HomeWidgetConfigurator> {
    return this.homeWidgetService.getDashboardWidgets$().pipe(map((widgets) => new HomeWidgetConfigurator(this.homeWidgetService, widgets)));
  }
}

const isWidgetFullWidth = (widget: UiDashboardWidget): boolean => widget.w === 2;

const isWidgetPositionedLeft = (widget: UiDashboardWidget): boolean => widget.x === 0;

const getSortedWidgetsVertically = (widgets: UiDashboardWidget[], options: { bottomToTop: boolean } = { bottomToTop: false }): UiDashboardWidget[] => {
  const multiplier = options.bottomToTop ? -1 : 1;
  return structuredClone(widgets).sort((a, b) => (a.y - b.y) * multiplier);
};

const getSortedWidgets = (widgets: UiDashboardWidget[]): UiDashboardWidget[] => {
  return structuredClone(widgets).sort((a, b) => a.y - b.y || a.x - b.x);
};

export const getWidgetPosition = (widgetId: string, widgets: UiDashboardWidget[], changedWidgets?: UiDashboardWidget[]): number => {
  if (!widgets) {
    throw new Error("No widgets provided");
  }

  if (changedWidgets?.length) {
    const changedWidgetsIds = changedWidgets.map((w) => w.id);
    return (
      getSortedWidgets([...widgets.filter((w) => !changedWidgetsIds.includes(w.id)), ...changedWidgets])
        .map((w) => w.id)
        .indexOf(widgetId) + 1
    );
  }

  return (
    getSortedWidgets(widgets)
      .map((w) => w.id)
      .indexOf(widgetId) + 1
  );
};

export class HomeWidgetConfigurator {
  constructor(
    private homeWidgetService: HomeWidgetService,
    private widgets: UiDashboardWidget[]
  ) {}

  public isFullWidth(widgetId: string): boolean {
    const widget = this.getWidgetById(widgetId);
    return isWidgetFullWidth(widget);
  }

  public canToggleFullWidth(widgetId: string): boolean {
    const widget = this.getWidgetById(widgetId);
    return !widget.noResize;
  }

  public canMoveUp(widgetId: string): boolean {
    const widget = this.getWidgetById(widgetId);
    const widgetsAbove = this.getWidgetsOnRowAbove(widget);
    return widgetsAbove.length > 0 && !widgetsAbove[0].noMove && !widgetsAbove[0].locked;
  }

  public canMoveDown(widgetId: string): boolean {
    const widget = this.getWidgetById(widgetId);
    const widgetsBelow = this.getWidgetsOnRowBelow(widget);
    return widgetsBelow.length > 0 && !widgetsBelow[0].noMove && !widgetsBelow[0].locked;
  }

  public canMoveLeft(widgetId: string): boolean {
    const widget = this.getWidgetById(widgetId);
    if (isWidgetFullWidth(widget) || isWidgetPositionedLeft(widget)) {
      return false;
    }

    const widgetOnSameRow = this.getWidgetOnSameRow(widget);
    if (!widgetOnSameRow) {
      return true;
    }

    return !widgetOnSameRow.noMove && !widgetOnSameRow.locked;
  }

  public canMoveRight(widgetId: string): boolean {
    const widget = this.getWidgetById(widgetId);
    if (isWidgetFullWidth(widget) || !isWidgetPositionedLeft(widget)) {
      return false;
    }

    const widgetOnSameRow = this.getWidgetOnSameRow(widget);
    if (!widgetOnSameRow) {
      return true;
    }

    return !widgetOnSameRow.noMove && !widgetOnSameRow.locked;
  }

  public toggleFullWidth(widgetId: string): void {
    const widget = this.getWidgetById(widgetId);
    const wasFullWidth = isWidgetFullWidth(widget);
    const resizedWidget: UiDashboardWidget = {
      ...widget,
      x: 0,
      w: wasFullWidth ? 1 : 2,
    };

    const isFullWidth = !wasFullWidth;
    if (!isFullWidth && resizedWidget.minH !== DEFAULT_WIDGET_HEIGHT) {
      resizedWidget.minH = DEFAULT_WIDGET_HEIGHT;
    } else if (isFullWidth && typeof resizedWidget.minH !== "undefined") {
      resizedWidget.minH = undefined;
    }

    // if there is another widget on the left/right side, we need to move this widget + all widgets below down
    const changedWidgets = [resizedWidget];
    if (isFullWidth) {
      if (typeof widget.h === "number") {
        resizedWidget.h = widget.h - 1; // triggers calculation in the height
      }

      const widgetOnSameRow = this.getWidgetOnSameRow(widget);
      if (widgetOnSameRow) {
        const widgetsBelow = this.getWidgetsBelow(resizedWidget);
        const widgetsToMoveDown = [widgetOnSameRow, ...widgetsBelow];
        changedWidgets.push(...this.moveWidgetsVerticallyBy(widgetsToMoveDown, this.getWidgetHeight(widget)));
      }
    }

    this.homeWidgetService.handleWidgetsChange(changedWidgets, { persistChanges: true });
  }

  public moveUp(widgetId: string): WidgetPositionChange {
    if (!this.canMoveUp(widgetId)) {
      throw new Error(`Cannot move widget ${widgetId} up`);
    }

    const widget = this.getWidgetById(widgetId);
    const widgetsAbove = this.getWidgetsOnRowAbove(widget);

    // If the widget we are moving up is full width
    if (isWidgetFullWidth(widget)) {
      const changedWidgets = this.moveFullWidthWidgetUp(widget);
      this.homeWidgetService.handleWidgetsChange(changedWidgets, { checkForY: true, persistChanges: true });
      return this.getWidgetPositionAfterMove(widgetId, changedWidgets);
    }

    // If above the widget we are moving there is a widget with the same size and position, just swap them
    const widgetAboveWithSameWidthAndPosition = widgetsAbove.find((w) => w.w === widget.w && w.x === widget.x);
    if (widgetAboveWithSameWidthAndPosition) {
      const changedWidgets = this.swapWidgetsVertically(widget, widgetAboveWithSameWidthAndPosition);
      this.homeWidgetService.handleWidgetsChange(changedWidgets, { checkForY: true, persistChanges: true });
      return this.getWidgetPositionAfterMove(widgetId, changedWidgets);
    }

    // If there is free space for the widget to be placed above, place the widget there
    const widgetsTwoLevelsAbove = this.getWidgetsOnRowAbove(widgetsAbove[0]);
    if (widgetsTwoLevelsAbove.length === 1 && !isWidgetFullWidth(widgetsTwoLevelsAbove[0]) && widgetsTwoLevelsAbove[0].x !== widget.x) {
      const movedWidget = { ...widget };
      movedWidget.y = widgetsTwoLevelsAbove[0].y;

      // If there is not a widget on the same row as the widget before moving it up, move all widgets below it up to fill the empty space after widget has been moved
      const widgetOnSameRow = this.getWidgetOnSameRow(widget);
      let additionalMovedWidgets = [];
      if (!widgetOnSameRow) {
        additionalMovedWidgets = this.moveWidgetsBelowVerticallyBy(widget, -this.getWidgetHeight(widget));
      }

      this.homeWidgetService.handleWidgetsChange([movedWidget, ...additionalMovedWidgets], { checkForY: true, persistChanges: true });
      return this.getWidgetPositionAfterMove(widgetId, [movedWidget, ...additionalMovedWidgets]);
    }

    // Move the widget above and fix vertical position of all widgets below it
    const movedWidget = { ...widget };
    movedWidget.y = widgetsAbove[0].y;

    const additionalMovedWidgets: UiDashboardWidget[] = [];

    const widgetOnSameRowAfterMove = this.getWidgetOnSameRow(movedWidget);
    widgetOnSameRowAfterMove.y += this.getWidgetHeight(movedWidget);
    additionalMovedWidgets.push(widgetOnSameRowAfterMove);

    const widgetOnSameRowBeforeMove = this.getWidgetOnSameRow(widget);
    if (widgetOnSameRowBeforeMove) {
      additionalMovedWidgets.push(...this.moveWidgetsBelowVerticallyBy(movedWidget, this.getWidgetHeight(movedWidget)));
    }

    this.homeWidgetService.handleWidgetsChange([movedWidget, ...additionalMovedWidgets], { checkForY: true, persistChanges: true });
    return this.getWidgetPositionAfterMove(widgetId, [movedWidget, ...additionalMovedWidgets]);
  }

  public moveDown(widgetId: string): WidgetPositionChange {
    if (!this.canMoveDown(widgetId)) {
      throw new Error(`Cannot move widget ${widgetId} down`);
    }

    const widget = this.getWidgetById(widgetId);
    const widgetsBelow = this.getWidgetsOnRowBelow(widget);

    // If the widget we are moving up is full width
    if (isWidgetFullWidth(widget)) {
      const changedWidgets = this.moveFullWidthWidgetDown(widget);
      this.homeWidgetService.handleWidgetsChange(changedWidgets, { checkForY: true, persistChanges: true });
      return this.getWidgetPositionAfterMove(widgetId, changedWidgets);
    }

    // If below the widget we are moving there is a widget with the same size and position, just swap them
    const widgetBelowWithSameWidthAndPosition = widgetsBelow.find((w) => w.w === widget.w && w.x === widget.x);
    if (widgetBelowWithSameWidthAndPosition) {
      const changedWidgets = this.swapWidgetsVertically(widget, widgetBelowWithSameWidthAndPosition);
      this.homeWidgetService.handleWidgetsChange(changedWidgets, { checkForY: true, persistChanges: true });
      return this.getWidgetPositionAfterMove(widgetId, changedWidgets);
    }

    // If there is free space for the widget to be placed below, place the widget there
    const widgetsTwoLevelsBelow = this.getWidgetsOnRowBelow(widgetsBelow[0]);
    if (widgetsTwoLevelsBelow.length === 1 && !isWidgetFullWidth(widgetsTwoLevelsBelow[0]) && widgetsTwoLevelsBelow[0].x !== widget.x) {
      const movedWidget = { ...widget };
      movedWidget.y = widgetsBelow[0].y + this.getWidgetHeight(widgetsBelow[0]);

      // If there is not a widget on the same row as the widget before moving it up, move all widgets below it up to fill the empty space after widget has been moved
      const widgetOnSameRow = this.getWidgetOnSameRow(widget);
      let additionalMovedWidgets = [];
      if (!widgetOnSameRow) {
        movedWidget.y -= this.getWidgetHeight(widget);
        additionalMovedWidgets = this.moveWidgetsBelowVerticallyBy(widget, -this.getWidgetHeight(widget));
      }

      this.homeWidgetService.handleWidgetsChange([movedWidget, ...additionalMovedWidgets], { checkForY: true, persistChanges: true });
      return this.getWidgetPositionAfterMove(widgetId, [movedWidget, ...additionalMovedWidgets]);
    }

    const widgetOnSameRowBeforeMove = this.getWidgetOnSameRow(widget);
    if (!widgetOnSameRowBeforeMove) {
      const changedWidgets = this.swapWidgetsVertically(widget, widgetsBelow[0]);
      this.homeWidgetService.handleWidgetsChange(changedWidgets, { checkForY: true, persistChanges: true });
      return this.getWidgetPositionAfterMove(widgetId, changedWidgets);
    }

    const movedWidget = { ...widget };
    movedWidget.y = widgetsBelow[0].y + this.getWidgetHeight(widgetsBelow[0]);

    const additionalMovedWidgets: UiDashboardWidget[] = [];

    const widgetOnSameRowAfterMove = this.getWidgetOnSameRow(movedWidget);
    if (widgetOnSameRowAfterMove) {
      widgetOnSameRowAfterMove.y += this.getWidgetHeight(movedWidget);
      additionalMovedWidgets.push(widgetOnSameRowAfterMove);
    }

    additionalMovedWidgets.push(...this.moveWidgetsBelowVerticallyBy(movedWidget, this.getWidgetHeight(movedWidget)));
    this.homeWidgetService.handleWidgetsChange([movedWidget, ...additionalMovedWidgets], { checkForY: true, persistChanges: true });
    return this.getWidgetPositionAfterMove(widgetId, [movedWidget, ...additionalMovedWidgets]);
  }

  public moveLeft(widgetId: string): WidgetPositionChange {
    if (!this.canMoveLeft(widgetId)) {
      throw new Error(`Cannot move widget ${widgetId} left`);
    }

    return this.moveHorizontally(widgetId, 0);
  }

  public moveRight(widgetId: string): WidgetPositionChange {
    if (!this.canMoveRight(widgetId)) {
      throw new Error(`Cannot move widget ${widgetId} right`);
    }

    return this.moveHorizontally(widgetId, 1);
  }

  private moveFullWidthWidgetUp(widget: UiDashboardWidget): UiDashboardWidget[] {
    const widgetsAbove = this.getWidgetsOnRowAbove(widget);
    const movedWidget = { ...widget, y: widgetsAbove[0].y };
    return [movedWidget, ...this.moveWidgetsVerticallyBy(widgetsAbove, this.getWidgetHeight(widget))];
  }

  private moveFullWidthWidgetDown(widget: UiDashboardWidget): UiDashboardWidget[] {
    const widgetsBelow = this.getWidgetsOnRowBelow(widget);
    const movedWidget = { ...widget, y: widgetsBelow[0].y };
    return [movedWidget, ...this.moveWidgetsVerticallyBy(widgetsBelow, -this.getWidgetHeight(widget))];
  }

  private getWidgetById(widgetId: string): UiDashboardWidget {
    const widget = this.widgets.find((w) => w.id === widgetId);
    if (!widget) {
      throw new Error(`Widget with id ${widgetId} not found`);
    }
    return widget;
  }

  private getWidgetsOnRowAbove(widget: UiDashboardWidget): UiDashboardWidget[] {
    const sortedWidgets = getSortedWidgetsVertically(this.widgets, { bottomToTop: true });
    return sortedWidgets.reduce<UiDashboardWidget[]>((result, w) => {
      if (w.y < widget.y && w.id !== widget.id) {
        if (!result.length) {
          return [w];
        }

        if (w.y === result[0].y) {
          return [...result, w];
        }
      }
      return result;
    }, []);
  }

  private getWidgetsOnRowBelow(widget: UiDashboardWidget): UiDashboardWidget[] {
    const sortedWidgets = getSortedWidgetsVertically(this.widgets);
    return sortedWidgets.reduce<UiDashboardWidget[]>((result, w) => {
      if (w.y > widget.y && w.id !== widget.id) {
        if (!result.length) {
          return [w];
        }

        if (w.y === result[0].y) {
          return [...result, w];
        }
      }
      return result;
    }, []);
  }

  private getWidgetsBelow(widget: UiDashboardWidget): UiDashboardWidget[] {
    return structuredClone(this.widgets.filter((w) => w.id !== widget.id && w.y > widget.y));
  }

  private moveWidgetsBelowVerticallyBy(widget: UiDashboardWidget, by: number): UiDashboardWidget[] {
    const widgetsBelow = this.getWidgetsBelow(widget);
    widgetsBelow.forEach((widgetBelow) => {
      widgetBelow.y += by;
    });
    return widgetsBelow;
  }

  private moveWidgetsVerticallyBy(widgets: UiDashboardWidget[], by: number): UiDashboardWidget[] {
    widgets.forEach((widget) => {
      widget.y += by;
    });
    return widgets;
  }

  private getWidgetOnSameRow(widget: UiDashboardWidget): UiDashboardWidget {
    return structuredClone(this.widgets.find((w) => w.y === widget.y && w.id !== widget.id));
  }

  private moveHorizontally(widgetId: string, x: number): WidgetPositionChange {
    const widget = this.getWidgetById(widgetId);
    const widgetOnSameRow = this.widgets.find((w) => w.y === widget.y && w.id !== widget.id);
    let changedWidgets = [];

    if (widgetOnSameRow) {
      changedWidgets = this.swapWidgetsHorizontally(widget, widgetOnSameRow);
    } else {
      changedWidgets = [
        {
          ...widget,
          x,
        },
      ];
    }

    this.homeWidgetService.handleWidgetsChange(changedWidgets, { persistChanges: true });
    return this.getWidgetPositionAfterMove(widgetId, changedWidgets);
  }

  private swapWidgetsHorizontally(widget1: UiDashboardWidget, widget2: UiDashboardWidget): UiDashboardWidget[] {
    const changedWidgets = [];
    changedWidgets.push({
      ...widget1,
      x: widget2.x,
      y: widget2.y,
    });
    changedWidgets.push({
      ...widget2,
      x: widget1.x,
      y: widget1.y,
    });

    return changedWidgets;
  }

  private swapWidgetsVertically(widget1: UiDashboardWidget, widget2: UiDashboardWidget): UiDashboardWidget[] {
    const sortedWidgets = getSortedWidgetsVertically([widget1, widget2]);

    sortedWidgets[1].y = sortedWidgets[0].y;
    sortedWidgets[0].y = sortedWidgets[1].y + this.getWidgetHeight(sortedWidgets[1]);

    return sortedWidgets;
  }

  private getWidgetPositionAfterMove(widgetId: string, changedWidgets: UiDashboardWidget[]): WidgetPositionChange {
    return {
      startingWidgetPosition: getWidgetPosition(widgetId, this.widgets),
      endingWidgetPosition: getWidgetPosition(widgetId, this.widgets, changedWidgets),
      widgetsCount: this.widgets.length,
    };
  }

  private getWidgetHeight(widget: UiDashboardWidget): number {
    return widget?.h ?? DEFAULT_WIDGET_HEIGHT;
  }
}
