import { Injectable, NgZone } from "@angular/core";
import { localize } from "@gtmhub/localization";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";

@Injectable()
export class MissingDataSourceAccessModalService {
  constructor(
    private modalService: UiModalService,
    private ngZone: NgZone
  ) {}

  public confirmRemoveItemAutomation = (args: { action(): void }): void => {
    this.modalService.confirm({
      uiTitle: localize("remove_the_automation"),
      uiContent: localize("data_source_custom_permissions_remove_automation"),
      uiIconType: null,
      uiCentered: true,
      uiOkText: localize("remove"),
      uiOkDanger: true,
      uiOnOk: () => args.action(),
      uiCancelText: localize("cancel"),
    });
  };

  public confirmRemoveInsightWidget = (args: { action(): void }): void => {
    if (!NgZone.isInAngularZone()) {
      this.ngZone.run(() => this.openRemoveInsightWidgetModal({ action: args.action }));
    } else {
      this.openRemoveInsightWidgetModal({ action: args.action });
    }
  };

  public unableToExecuteSqlQueryWarning = (args: { action(): void; cancel(): void }): void => {
    this.modalService.warning({
      uiTitle: localize("unable_to_execute"),
      uiContent: localize("one_or_more_entities_missing_data_source_access"),
      uiCentered: true,
      uiOkText: localize("got_it"),
      uiOnOk: () => args.action(),
      uiOnCancel: () => args.cancel(),
      uiModalContentCentered: true,
    });
  };

  private openRemoveInsightWidgetModal = (args: { action(): void }): void => {
    this.modalService.confirm({
      uiTitle: localize("remove_the_insight"),
      uiContent: localize("data_source_custom_permissions_remove_insight"),
      uiIconType: null,
      uiCentered: true,
      uiOkText: localize("remove"),
      uiOkDanger: true,
      uiOnOk: () => args.action(),
      uiCancelText: localize("cancel"),
    });
  };
}
