<div class="task-status-btn-wrapper">
  <button-select
    *ngIf="!externalSystem && !archived"
    [uiId]="uiId"
    [disabled]="disabled || readonly"
    [model]="status"
    [selected]="status"
    [options]="taskStatusOptions"
    [hasOptionCheckIcon]="false"
    [inline]="inline"
    [dropdownMatchSelectWidth]="false"
    (modelChange)="statusChanged($event)"
    a11yLabel="task status selector"
    data-test-id="task-status-dropdown">
  </button-select>

  <div class="task-status-ui-btn">
    <button
      *ngIf="externalSystem && externalSystem.name === 'jira' && !archived"
      [attr.id]="uiId"
      [uiTrigger]="'click'"
      [uiPlacement]="'bottomLeft'"
      [uiContent]="contentTemplate"
      [uiBackdrop]="true"
      [uiSpecialType]="specialType"
      uiShape="round"
      uiSize="small"
      ui-button
      ui-popover>
      {{ buttonText() }} <i uiSize="xs" ui-icon uiType="chevron-down-small"></i>
    </button>

    <button class="archived-task" *ngIf="archived" [uiSpecialType]="'gray'" uiShape="round" uiSize="small" ui-button>{{ "archived" | localize }}</button>
  </div>
</div>

<ng-template #contentTemplate>
  <div class="gh-p-2 jira-link-popover-container">
    <p>{{ "update_status_task_in_jira" | localize }}</p>
    <a [attr.href]="buildJiraLink()" ui-button uiShape="round" uiType="primary" uiSize="default" target="_blank" rel="noopener">{{ "view_in_jira" | localize }}</a>
  </div>
</ng-template>
