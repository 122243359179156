<span class="ant-input-number-wrapper ant-input-number-group" *ngIf="isAddOn; else noAddOnTemplate">
  <div
    class="add-on-before"
    *ngIf="nzAddOnBefore || nzAddOnBeforeIcon"
    [ngStyle]="uiAddonStyle"
    [uiIcon]="nzAddOnBeforeIcon"
    [uiTemplate]="nzAddOnBefore"
    ui-input-number-group-slot
    type="addon"></div>
  <div
    class="ant-input-number-affix-wrapper"
    *ngIf="isAffix; else contentTemplate"
    [class.ant-input-number-affix-wrapper-disabled]="uiDisabled"
    [class.ant-input-number-affix-wrapper-readonly]="uiReadOnly"
    [class.ant-input-number-affix-wrapper-sm]="isSmall"
    [class.ant-input-number-affix-wrapper-lg]="isLarge"
    [class.ant-input-number-affix-wrapper-focused]="focused"
    [ngClass]="affixInGroupStatusCls">
    <ng-template [ngTemplateOutlet]="affixTemplate"></ng-template>
  </div>
  <span class="add-on-after" *ngIf="nzAddOnAfter || nzAddOnAfterIcon" [ngStyle]="uiAddonStyle" [uiIcon]="nzAddOnAfterIcon" [uiTemplate]="nzAddOnAfter" ui-input-number-group-slot type="addon"></span>
</span>
<ng-template #noAddOnTemplate>
  <ng-template [ngIf]="isAffix" [ngIfElse]="contentTemplate">
    <ng-template [ngTemplateOutlet]="affixTemplate"></ng-template>
  </ng-template>
</ng-template>
<ng-template #affixTemplate>
  <span *ngIf="nzPrefix || nzPrefixIcon" [uiIcon]="nzPrefixIcon" [uiTemplate]="nzPrefix" ui-input-number-group-slot type="prefix"></span>
  <ng-template [ngTemplateOutlet]="contentTemplate"></ng-template>
  <span *ngIf="nzSuffix || nzSuffixIcon" [uiIcon]="nzSuffixIcon" [uiTemplate]="nzSuffix" ui-input-number-group-slot type="suffix"> </span>
</ng-template>
<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>
