import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ITeamJoinNotification } from "@gtmhub/notifications/models/notifications";
import { InboxTemplateComponent } from "../inbox-template.component";

@Component({
  selector: "team-inbox-template",
  templateUrl: "./team-inbox-template.component.html",
  styleUrls: ["../inbox-template.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamInboxTemplateComponent extends InboxTemplateComponent<ITeamJoinNotification> {}
