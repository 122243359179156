import { AbstractControl, ValidationErrors } from "@angular/forms";

export const hasDuplicateValues = <T>(array: T[], targetKey: keyof T): boolean => {
  if (!array || array.length <= 1) {
    return false;
  }

  const keys = new Set();
  for (let index = 0; index < array.length; index += 1) {
    const currentItem = array[index];
    const targetKeyValue = currentItem[targetKey];

    if (keys.has(targetKeyValue)) {
      return true;
    }

    keys.add(targetKeyValue);
  }

  return false;
};

export const requiredStringValidator = (control: AbstractControl): ValidationErrors | null => {
  return (control.value || "").trim().length > 0 ? null : { required: true };
};

export const isEmptyArray = (value: unknown): boolean => Array.isArray(value) && value.length === 0;
