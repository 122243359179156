import { ChangeDetectionStrategy, Component, Input, TemplateRef } from "@angular/core";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";

@Component({
  selector: "div[ui-modal-side-content]",
  templateUrl: "modal-side-content.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NzOutletModule],
})
export class UiModalSideContentComponent {
  @Input() public uiSideDrawer: TemplateRef<unknown>;
}
