import { module } from "angular";
import tagsCore from "@gtmhub/tags/tags-core.module";
import { ColoringService } from "@webapp/configuration/services/coloring.service";
import { SubscriptionRepository } from "@webapp/configuration/services/subscription/subscription-repository.service";
import { ConfidenceSettingsService } from "@webapp/okrs/metrics/services/confidence-settings.service";
import customFieldsCore from "../customFields/custom-fields-core.module";
import { ParameterService } from "../parameters/parameter.service";
import pluginsCore from "../plugins/plugins-core.module";
import rolesCore from "../roles/roles-core.module";
import usersCore from "../users/users-core.module";
import { ChargebeeService } from "./services/chargebee-service";
import { IntegrationService } from "./services/integration.service";
import { SCIMService } from "./services/scim.service";

const mod = module("configurationCore", [rolesCore, usersCore, customFieldsCore, tagsCore, pluginsCore]);

mod.service("ChargebeeService", ChargebeeService);
mod.service("ParameterService", ParameterService);
mod.service("SCIMService", SCIMService);
mod.service("IntegrationService", IntegrationService);

mod.downgradeInjectable("ConfidenceSettingsService", ConfidenceSettingsService);
mod.downgradeInjectable("SubscriptionRepository", SubscriptionRepository);
mod.downgradeInjectable("ColoringService", ColoringService);

export default mod.name;
