import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { ITag } from "../../../../../webapp-legacy/src/tags/models";
import { RequestConfig } from "../../core/abstracts/models/request-config.model";
import { RequestPaging } from "../../core/abstracts/models/request.paging";
import { ICollection } from "../../core/core.models";
import { Tag, TagDTO } from "../models/tag.model";
import { TagsApiService } from "../services/tags-api.service";
import { TagsState } from "../services/tags-state.service";

@Injectable({
  providedIn: "any",
})
export class TagsFacade extends BaseFacade<Tag, TagDTO, TagsState, TagsApiService> {
  public constructor(state: TagsState, api: TagsApiService) {
    super(state, api);
  }

  public getTags$(params: RequestPaging): Observable<Tag[]> {
    return this.apiService.getAll$<ICollection<ITag>>(params, { ...new RequestConfig(), url: this.apiService.getTagsEndpoint() }).pipe(map((result) => result.items));
  }

  public createTag$(tag: Partial<Tag>): Observable<Tag> {
    const requestConfig = {
      ...new RequestConfig(),
      url: this.apiService.createTagsEndpoint(),
    };

    return this.apiService.post$<Tag>(tag, requestConfig);
  }
}
