import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { ICollection } from "@webapp/core/core.models";
import { DataSourceBlueprint, DataSourceBlueprintDTO, GetBlueprintsWithConnectionQueryParams } from "@webapp/data-story/models/data-sources-facade.models";
import { DataSourceBlueprintsApiService } from "@webapp/data-story/services/data-source-blueprints-facade/data-source-blueprints-api.service";
import { DataSourceBlueprintsState } from "@webapp/data-story/services/data-source-blueprints-facade/data-source-blueprints-state.service";

@Injectable({
  providedIn: "root",
})
export class DataSourceBlueprintsFacade extends BaseFacade<DataSourceBlueprint, DataSourceBlueprintDTO, DataSourceBlueprintsState, DataSourceBlueprintsApiService> {
  constructor(state: DataSourceBlueprintsState, api: DataSourceBlueprintsApiService) {
    super(state, api);
  }

  public getBlueprintByName$(name: string): Observable<DataSourceBlueprint> {
    return this.get$<DataSourceBlueprint>(null, {
      ...new RequestConfig(),
      url: this.apiService.getBlueprintByNameEndpoint(name),
    });
  }

  public getBlueprintsWithConnection$(
    fields: string = null,
    { isActive }: GetBlueprintsWithConnectionQueryParams = { isActive: false }
  ): Observable<DataSourceBlueprint[]> {
    return this.get$<ICollection<DataSourceBlueprint>>(null, {
      ...new RequestConfig(),
      url: this.apiService.getBlueprintsWithConnectionEndpoint(),
      queryParams: { fields, isActive },
    }).pipe(map((result) => result.items));
  }
}
