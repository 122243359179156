import { IRootScopeService } from "angular";
import { Observable, Subscription } from "rxjs";
import { UXCustomizationType } from "@gtmhub/uxcustomization/models";
import { ICollection } from "@webapp/core/core.models";
import { INavItem, uxcToNavItemsMap } from "@webapp/navigation/models/nav-items-list.models";
import NavigationItemsMediator from "@webapp/navigation/services/uxcustomization/navigation-items.mediator.service";

export class FavoritesManager {
  private favoritesSubscription: Subscription;
  private favorites: Map<string, INavItem> = new Map();

  constructor(
    private navigationItemsMediator: NavigationItemsMediator,
    private $rootScope: IRootScopeService,
    private entityTypes: UXCustomizationType[]
  ) {}

  $onDestroy(): void {
    this.favoritesSubscription.unsubscribe();
  }

  public initFavorites(): Observable<ICollection<INavItem>> {
    this.navigationItemsMediator.loadFavorites(uxcToNavItemsMap[this.entityTypes[0]]).subscribe();
    const $favorites = this.navigationItemsMediator.getFavoritesItems$(uxcToNavItemsMap[this.entityTypes[0]]);
    this.favoritesSubscription = $favorites.subscribe((favoritesCollection) => {
      this.favorites = (favoritesCollection?.items || []).reduce<Map<string, INavItem>>((map, item) => map.set(item.id, item), new Map());
      if (!this.$rootScope.$$phase) {
        this.$rootScope.$apply();
      }
    });
    return $favorites;
  }

  public entityIsFavorite = (entity: { id: string }): boolean => {
    return this.favorites.has(entity.id);
  };

  public getFavoriteItem = (entity: { id: string }): INavItem => {
    return this.favorites.get(entity.id);
  };

  public addToFavorites = (entity: { id: string }, type: UXCustomizationType, navItem: INavItem) => {
    this.navigationItemsMediator.markItemAsFavorite(entity.id, type, navItem, uxcToNavItemsMap[navItem.uxcType]);
  };

  public removeFromFavorites = (entity: { id: string }, navItem: INavItem) => {
    const existingNavItem = this.favorites.get(entity.id);
    this.navigationItemsMediator.unmarkItemAsFavorite(existingNavItem.favoriteId, navItem.uxcType, navItem, uxcToNavItemsMap[navItem.uxcType]);
  };
}
