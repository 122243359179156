import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, EventEmitter, Input, Output, Renderer2 } from "@angular/core";
import { isNotNil } from "ng-zorro-antd/core/util";
import { NzTextareaCountComponent } from "ng-zorro-antd/input";
import { UiInputDirective } from "@webapp/ui/input/input.directive";
import { FormatedTextareaCountChange } from "../../input.models";

@Component({
  selector: "ui-textarea-count",
  templateUrl: "textarea-count.component.html",
  styleUrls: ["./textarea-count.component.less"],
  host: {
    "[class.hide-counter]": "!displayCounter",
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class UiTextareaCountComponent extends NzTextareaCountComponent {
  @ContentChild(UiInputDirective, { static: true }) public nzInputDirective!: UiInputDirective;
  @Input("uiMaxCharacterCount") public nzMaxCharacterCount = 0;
  @Input("uiComputeCharacterCount") public uiComputeCharacterCount(v: string): number {
    return this.nzComputeCharacterCount(v);
  }
  @Input("uiFormatter") public uiFormatter(cur: number, max: number): string {
    return this.nzFormatter(cur, max);
  }
  @Input() public displayCounter = true;

  @Output() public readonly formatedCountChange = new EventEmitter<FormatedTextareaCountChange>();

  constructor(renderer: Renderer2, elementRef: ElementRef<HTMLElement>) {
    super(renderer, elementRef);
  }

  public setDataCount(value: string): void {
    super.setDataCount(value);

    this.emitOnValueChange(value);
  }

  private emitOnValueChange(value: string): void {
    const inputValue = isNotNil(value) ? String(value) : "";
    const currentCount = this.nzComputeCharacterCount(inputValue);
    const isMaxLengthExceeded = currentCount > this.nzMaxCharacterCount;
    const isMaxLengthReached = currentCount === this.nzMaxCharacterCount;
    this.formatedCountChange.emit({ value: this.nzFormatter(currentCount, this.nzMaxCharacterCount), isMaxLengthExceeded, isMaxLengthReached });
  }
}
