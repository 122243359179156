import { UiCollapseLabel } from "@quantive/ui-kit/collapse";
import { localize } from "@gtmhub/localization";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { AggregatedReaction, Comment, CommentTargetType, CommentsScrollToEditorEvent } from "@webapp/comments";
import { Gif } from "@webapp/shared/components/gifs/gif.models";
import { ReflectionOrder } from "./reflection-question.model";

export type ReflectionScheduleCadence = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday";

export interface CadenceChangeParams {
  currCadenceStartDate: string;
  currCadenceEndDate?: string;
  prevCadenceStartDate?: string;
  prevCadenceEndDate?: string;
}

export interface ReflectionNote {
  id: string;
  type: ReflectionNoteType;
  text: string;
  gif: Gif;
  isCommentsEnabled: boolean;
  reactions: AggregatedReaction[];
  comments: Comment[];
  commentsAssignees: Assignee[];
  commentPendingText: string;
}

export const reflectionMoodTypes = ["bad", "neutral", "good"] as const;
export type ReflectionMoodType = (typeof reflectionMoodTypes)[number];

export const reflectionNoteTypes = ["blockers", "requests", "wins", "kudos", "takeaways"] as const;
export type ReflectionNoteType = (typeof reflectionNoteTypes)[number];

interface ReflectionOverfiewFilterProps {
  count: number;
  selected: boolean;
}

export type ReflectionOverviewFilterName = ReflectionNoteType | "notPublished" | "published";

export type ReflectionOverviewFilterType = { [key in ReflectionOverviewFilterName]?: ReflectionOverfiewFilterProps };

export interface ReflectionLabel extends UiCollapseLabel {
  type: ReflectionOverviewFilterName;
}

export const noteLabelMapping: Record<ReflectionNoteType, ReflectionLabel> = {
  blockers: {
    text: localize("reflections_overview_blockers"),
    type: "blockers",
    color: "red",
  },
  requests: {
    text: localize("reflections_overview_requests"),
    type: "requests",
    color: "yellow",
  },
  wins: {
    text: localize("reflections_overview_wins"),
    type: "wins",
    color: "green",
  },
  kudos: {
    text: localize("reflections_overview_kudos"),
    type: "kudos",
    color: "purple",
  },
  takeaways: {
    text: localize("reflections_overview_takeaways"),
    type: "takeaways",
    color: "blue",
  },
};

export const noReflectionLabel: ReflectionLabel = {
  text: localize("check_ins_no_check_in"),
  type: "notPublished",
  color: "grey",
};

export const DEFAULT_CADENCE_DURATION_IN_DAYS = 7;

export type CheckinCommentTarget = "questions" | "notes";
interface CheckinCommentsBase {
  type: ReflectionOrder;
  target: CheckinCommentTarget;
  commentParentIndex: number;
}

export type CheckinCommentsToggleSource = "cancelButton" | "addButton";

export interface CheckinCommentsToggle extends CheckinCommentsBase, CommentsScrollToEditorEvent {
  status: boolean;
  source?: CheckinCommentsToggleSource;
}

export interface CheckinCommentChange extends CheckinCommentsBase {
  comment: Comment;
}

export interface CheckinCommentPendingTextChange extends CheckinCommentsBase {
  text: string;
}

const CHECK_IN_REACTION_ANSWER = "check-in-reaction-answer";
const CHECK_IN_REACTION_NOTE = "check-in-reaction-note";
export const CHECK_IN_COMMENT_ANSWER = "check-in-comment-answer";
export const CHECK_IN_COMMENT_NOTE = "check-in-comment-note";
export const checkinTargetTypes = ["checkin_comment", "checkin_answer", "checkin_blocker", "checkin_request", "checkin_takeaway", "checkin_win", "checkin_kudos"];

export const checkInTagetTypesLegacy = [...checkinTargetTypes, CHECK_IN_COMMENT_ANSWER, CHECK_IN_REACTION_ANSWER, CHECK_IN_COMMENT_NOTE, CHECK_IN_REACTION_NOTE];
export const checkInReactionLegacy = [CHECK_IN_REACTION_ANSWER, CHECK_IN_REACTION_NOTE, ...checkinTargetTypes];
export const checkInCommentLegacy = [CHECK_IN_COMMENT_ANSWER, CHECK_IN_COMMENT_NOTE, ...checkinTargetTypes];

export const checkinTargetTypesRecord: Record<ReflectionNoteType, CommentTargetType> = {
  blockers: "checkin_blocker",
  requests: "checkin_request",
  kudos: "checkin_kudos",
  takeaways: "checkin_takeaway",
  wins: "checkin_win",
};
