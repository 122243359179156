import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { UiGridModule } from "@webapp/ui/grid/grid.module";
import { CurrentUserRepository } from "@webapp/users";
import { EmptyStateService } from "../empty-state.service";
import { InfoBox } from "../models";

@Component({
  selector: "empty-state-info-boxes",
  templateUrl: "./empty-state-info-boxes.component.html",
  styleUrls: ["./empty-state-info-boxes.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UiIconModule, UiGridModule, LocalizationModule],
})
export class EmptyStateViewInfoBoxesComponent implements OnInit {
  @Input() public infoBoxesId: string;

  // eslint-disable-next-line @angular-eslint/prefer-output-readonly
  @Output() public videoUrl = new EventEmitter<string>();

  public infoBoxes: InfoBox[];

  constructor(
    private emptyStateService: EmptyStateService,
    private currentUserRepository: CurrentUserRepository,
    private analyticsService: AnalyticsService
  ) {}

  public ngOnInit(): void {
    this.infoBoxes = this.emptyStateService.getEmptyStateInfoBoxes(this.infoBoxesId);
  }

  public openVideo(url: string): void {
    this.videoUrl.emit(url);
  }

  public marketplaceRedirectHandle(): void {
    this.currentUserRepository.setMarketplaceRedirectCookie();
    this.analyticsService.track("Navigation Consulting clicked", { location: "EmptyStateInfoBoxesComponent" });
  }
}
