import { IPromise } from "angular";
import { IModalService } from "angular-ui-bootstrap";
import { IEditionPlanChangeResolveParams, IPlan } from "../models";

export class EditionPlanSelectorService {
  static $inject = ["$uibModal"];

  constructor(private $uibModal: IModalService) {}

  selectEditionPlan(args: IEditionPlanChangeResolveParams): IPromise<IPlan> {
    return this.$uibModal.open({
      template: require("../views/edition-plan-change.html"),
      windowClass: "large",
      controller: "EditionPlanChangeCtrl",
      resolve: {
        resolveParams: (): IEditionPlanChangeResolveParams => args,
      },
    }).result;
  }
}
