<ng-container *ngFor="let button of buttons">
  <button
    *ngIf="!button.isVisible$ || (button.isVisible$ | async)"
    [uiType]="button.type"
    [uiSize]="button.size"
    [uiShape]="button.shape"
    [attr.aria-label]="button.ariaLabel"
    [attr.data-test-id]="button.dataTestId"
    (click)="button.onClick()"
    ui-button>
    <i *ngIf="shouldDisplayIcon(button)" [uiType]="getIconType(button)" [uiSize]="getIconSize(button)" [uiTheme]="getIconTheme(button)" ui-icon></i>
    {{ button.key | localize }}
  </button>
</ng-container>

<gh-dropdown
  *ngIf="dropdown && (!dropdown.isVisible$ || (dropdown.isVisible$ | async))"
  [menuItems]="dropdown.menuItems"
  [hasCustomButtonContent]="true"
  [showNewDesign]="true"
  (dropdownToggled)="dropdownToggled($event)"
  placement="bottomRight">
  <button
    [attr.aria-expanded]="dropdown.isExpanded"
    [disabled]="dropdown.disabled"
    data-test-id="top-nav-bar-buttons-dropdown"
    uiType="primary"
    uiShape="round"
    gh-dropdown-custom-button-content
    ui-button
    aria-haspopup="true"
    uiTrigger="click">
    <span> {{ dropdownKey | localize }}</span>
    <i class="sp-ml-10" ui-icon uiType="chevron-down-small" uiSize="sm"></i>
  </button>
</gh-dropdown>
