import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { FeatureTogglesDtoModel, FeatureTogglesModel } from "../models/feature-toggles.models";
import { FeatureTogglesState } from "./feature-toggles-state.service";

@Injectable()
export class FeatureTogglesApiService extends BaseApiService<FeatureTogglesModel, FeatureTogglesDtoModel, FeatureTogglesState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: FeatureTogglesState) {
    super("feature-toggler", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getUserSignatureEndpoint(): string {
    return `${this.getBasePath(HttpActions.get)}/user/secure-id`;
  }
}
