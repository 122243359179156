import { IAppConfig } from "./config";
import { isCurrentDomainQuantive } from "./util";

const format = (path?: string) => (path ? (path[0] === "/" ? path.substr(1) : path) : "");

export class EnvironmentService {
  private http: string;
  private ws: string;
  private api: string;
  private legacyApi: string;
  private gtmhubApi: string;
  private webSocketsApi: string;
  private webappEndpoint: string;
  private webSocketsApiEndpoint: string;
  private quantivePlusApi: string;
  private jiraAddonApi: string;

  static $inject = ["appConfig"];

  constructor(private appConfig: IAppConfig) {
    const { endpoints } = appConfig;

    this.api = endpoints.apiEndpoint;
    this.legacyApi = endpoints.legacyApiEndpoint;
    this.gtmhubApi = endpoints.apiEndpoint.replace("quantive", "gtmhub").replace("/results", "");
    this.webSocketsApi = this.api.replace("app.", "sockets.");
    this.webappEndpoint = endpoints.webappEndpoint;
    this.webSocketsApiEndpoint = endpoints.webSocketsApiEndpoint;
    this.quantivePlusApi = endpoints.quantivePlusApi;
    this.jiraAddonApi = endpoints.apiEndpoint.split("/")[0] + "/jira";
    this.http = endpoints.secure ? "https" : "http";
    this.ws = endpoints.secure ? "wss" : "ws";
  }

  isApiRequest(url: string): boolean {
    return (
      url.startsWith(this.getGtmhubApiBaseEndpoint()) ||
      url.startsWith(this.getApiBaseEndpoint()) ||
      url.startsWith(this.getLegacyApiBaseEndpoint()) ||
      url.startsWith(`${this.ws}://${this.webSocketsApiEndpoint}`) ||
      url.startsWith(this.getQuantivePlusApiBaseEndpoint())
    );
  }

  getApiEndpoint(path?: string): string {
    return `${this.getApiBaseEndpoint()}/v1/${format(path)}`;
  }

  getApiEndpointV2(path?: string): string {
    return `${this.getApiBaseEndpoint()}/v2/${format(path)}`;
  }

  getIntegrationsEndpoint(path?: string): string {
    return `${this.getApiBaseEndpoint()}/v2/integrations/${format(path)}`;
  }

  getInsightsEndpoint(path?: string): string {
    return `${this.getApiBaseEndpoint()}/v2/insights/${format(path)}`;
  }

  getMomentsEndpoint(path?: string): string {
    return `${this.getApiBaseEndpoint()}/v2/moments/${format(path)}`;
  }

  getFileServerEndpoint(path?: string): string {
    return `${this.getApiBaseEndpoint()}/v1/fileserver/${format(path)}`;
  }

  getFileServerEndpointV2(path?: string): string {
    return `${this.getApiBaseEndpoint()}/v2/fileserver/${format(path)}`;
  }

  getHubfiltersEndpoint(path?: string): string {
    return `${this.getApiBaseEndpoint()}/v1/filterdefinitions/hub/${format(path)}`;
  }

  getEbisuEndpoint(path?: string): string {
    return `${this.getApiBaseEndpoint()}/v1/notifications/${format(path)}`;
  }

  getDocumentExporterEndpoint(path?: string): string {
    return `${this.getApiBaseEndpoint()}/v2/exporter/${format(path)}`;
  }

  getDocumentExporterEndpointV3(path?: string): string {
    return `${this.getApiBaseEndpoint()}/v3/exporter/${format(path)}`;
  }

  getMarketplaceEndpointV2(path?: string): string {
    return `${this.getApiBaseEndpoint()}/v2/marketplace/${format(path)}`;
  }

  getSearchEndpoint(path?: string): string {
    return `${this.getApiBaseEndpoint()}/v1/search/${format(path)}`;
  }

  getSearchEndpointV2(path?: string): string {
    return `${this.getApiBaseEndpoint()}/v2/search/${format(path)}`;
  }

  getWhiteboardsEndpoint(path?: string): string {
    return `${this.getApiBaseEndpoint()}/v1/whiteboards/${format(path)}`;
  }

  getWhiteboardsV2Endpoint(path?: string): string {
    return `${this.getApiBaseEndpoint()}/v2/whiteboards/${format(path)}`;
  }

  getWorkdayUserProvisioningEndpoint(path?: string): string {
    return `${this.getApiBaseEndpoint()}/v1/workday-user-provisioning/${format(path)}`;
  }

  getAutoFieldsEndpoint(path?: string): string {
    return `${this.getApiBaseEndpoint()}/v1/autofields/${format(path)}`;
  }

  getQuantivePlusEndpointV1(path?: string): string {
    return `${this.getQuantivePlusApiBaseEndpoint()}/v1/${format(path)}`;
  }

  getQuantivePlusEndpointV2(path?: string): string {
    return `${this.getQuantivePlusApiBaseEndpoint()}/v2/${format(path)}`;
  }

  getAutoInsightsEndpoint(path?: string): string {
    return `${this.getApiBaseEndpoint()}/v1/autoinsights/${format(path)}`;
  }

  getUXCustomizationEndpoint(path?: string): string {
    return `${this.getApiBaseEndpoint()}/v1/uxcustomization/${format(path)}`;
  }

  getWSEndpoint(path?: string): string {
    return `${this.ws}://${this.webSocketsApi}/v2/sockets/${format(path)}`;
  }

  getWbSocketsEndpoint(path?: string): string {
    return `${this.ws}://${this.webSocketsApi}/v1/wbws/${format(path)}`;
  }

  getWbSocketsEndpointV2(path?: string): string {
    return `${this.ws}://${this.webSocketsApi}/v2/wbws/${format(path)}`;
  }

  getInsightsSocketsEndpoint(path?: string): string {
    return `${this.ws}://${this.webSocketsApi}/v1/insights-ws/${format(path)}`;
  }

  getApiBaseEndpoint(): string {
    return `${this.http}://${this.api}`;
  }

  getLegacyApiBaseEndpoint(): string {
    return `${this.http}://${this.legacyApi}`;
  }

  getGtmhubApiBaseEndpoint(): string {
    return `${this.http}://${this.gtmhubApi}`;
  }

  getQuantivePlusApiBaseEndpoint(): string {
    return `${this.http}://${this.quantivePlusApi}`;
  }

  getWebappEndpoint(): string {
    return `${this.http}://${this.webappEndpoint.replace("gtmhub", "quantive")}`;
  }

  getJiraEndpoint(): string {
    return `${this.http}://${this.jiraAddonApi}`;
  }

  getDC(): string {
    return this.appConfig.env.dc;
  }

  getEnvName(): string {
    return this.appConfig.env.name;
  }

  constructGtmhubDomain(
    vanityDomain: string,
    options: { preserveDomainForLocalhost?: boolean; overrideDomainToQuantive: boolean } = { overrideDomainToQuantive: true }
  ): string {
    const { name, dc, bypassDomainCheck } = this.appConfig.env;
    if (bypassDomainCheck && !options.preserveDomainForLocalhost) {
      return location.host;
    }

    const domain = options.overrideDomainToQuantive && isCurrentDomainQuantive() ? "quantive.com" : "gtmhub.com";
    if (name === "staging") {
      return `${vanityDomain}.staging.${domain}`;
    }
    if (dc === "eu") {
      return `${vanityDomain}.${domain}`;
    }

    return `${vanityDomain}.${dc}.${domain}`;
  }
}
