import { Injectable } from "@angular/core";
import { NzTreeNodeOptions } from "ng-zorro-antd/tree";
import { Observable, forkJoin, map, of, switchMap } from "rxjs";
import { ISessionsStoreState } from "@gtmhub/sessions/redux/session-reducer";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { IViewHistoryGoal, IViewHistoryItemsGroupedResponse, IViewHistoryKpi, IViewHistoryMetric } from "@gtmhub/view-history";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { RelatedItemType } from "@webapp/links/models/related-items.models";
import { RecentsFacade } from "@webapp/navigation/services/uxcustomization/recents/recents.facade.service";
import { ISearchCondition, ISearchFieldCondition, ISearchRequestsBody } from "@webapp/search/models/search-api.models";
import { SearchDTO } from "@webapp/search/models/search.models";
import { SearchFacade } from "@webapp/search/services/search-facade.service";
import { CurrentUserRepository } from "@webapp/users";
import { Goal } from "../goals/models/goal.models";
import { GoalsFacade } from "../goals/services/goals-facade.service";
import { Metric } from "../metrics/models/metric.models";
import { MetricsFacade } from "../metrics/services/metrics-facade.service";
import { buildSearchNodes } from "../utils/okr-and-kpi-selector-nodes-builder.util";
import { getRecentMetricsGoalIdsNotFetchedWithRecentGoals } from "../utils/utils";

@Injectable({
  providedIn: "any",
})
export class RelatedItemsFacade {
  private recentMetrics: Map<string, IViewHistoryMetric> = new Map();

  constructor(
    private recentsFacade: RecentsFacade,
    private metricsFacade: MetricsFacade,
    private goalsFacade: GoalsFacade,
    private searchFacade: SearchFacade,
    private currentUserRepository: CurrentUserRepository
  ) {}

  public get recentMetricsMap(): Map<string, IViewHistoryMetric> {
    return this.recentMetrics;
  }

  private getRecentlyVisitedItems$(idsToExclude: string[], itemTypes: RelatedItemType[]): Observable<IViewHistoryItemsGroupedResponse> {
    const requestPaging: RequestPaging = {
      filter: {
        $and: [{ type: { $in: itemTypes } }],
      },
    };
    const requestConfig: RequestConfig = {
      queryParams: {
        rejectedIds: idsToExclude.join(","),
      },
    };
    return this.recentsFacade.getAll$<IViewHistoryItemsGroupedResponse>(requestPaging, requestConfig);
  }

  private getAllMetricsPerRecentGoals$(recentGoalIds: string[], idsToExclude: string[]): Observable<Metric[]> {
    const requestPaging: RequestPaging = {
      filter: {
        $and: [{ goalId: { $in: recentGoalIds } }, { _id: { $nin: idsToExclude } }],
      },
      fields: ["id,name,ownerIds,goalId,sessionId"],
    };
    return this.metricsFacade.getAll$(requestPaging).pipe(map((data) => data.items));
  }

  private getAllGoalsPerRecentMetrics$(goalIds: string[]): Observable<Goal[]> {
    const requestPaging: RequestPaging = {
      fields: ["id,name,ownerIds,sessionId"],
      filter: {
        _id: { $in: goalIds },
      },
    };
    return this.goalsFacade.getAll$(requestPaging).pipe(map((data) => data.items));
  }

  public getAllSuggestions$(
    idsToExclude: string[],
    itemTypes: RelatedItemType[]
  ): Observable<{
    recentGoals: IViewHistoryGoal[];
    missingGoals: Goal[];
    allMetrics: Metric[];
    recentKpis: IViewHistoryKpi[];
  }> {
    if (!itemTypes.length) {
      return of({
        recentGoals: [],
        missingGoals: [],
        allMetrics: [],
        recentKpis: [],
      });
    }

    return this.getRecentlyVisitedItems$(idsToExclude, itemTypes).pipe(
      switchMap(({ goalItems: recentGoals, metricItems: recentMetrics, kpiItems: recentKpis }) => {
        const okrsIncluded: boolean = itemTypes.includes("goal") || itemTypes.includes("metric");

        if (!okrsIncluded) {
          return of({
            recentGoals: [],
            missingGoals: [],
            allMetrics: [],
            recentKpis,
          });
        } else {
          // If recent metrics exist - get all goals of recently visited metrics
          let uniqueIdsOfNonFetchedGoals = [];
          if (recentMetrics) {
            recentMetrics.forEach((metric) => this.recentMetrics.set(metric.metric.metricId, metric));
            const nonFetchedGoalsIds = getRecentMetricsGoalIdsNotFetchedWithRecentGoals(recentMetrics, recentGoals);
            uniqueIdsOfNonFetchedGoals = [...new Set(nonFetchedGoalsIds)];
          }

          const missingGoals$ = this.getAllGoalsPerRecentMetrics$(uniqueIdsOfNonFetchedGoals);

          // Get all metrics of recently visited goal and of goals which metrics have been recently visited, but goals haven't
          const recentGoalsIds = (recentGoals || []).map((goal) => goal.itemId);
          const allGoalIds = recentGoalsIds.concat(uniqueIdsOfNonFetchedGoals);
          const uniqueIdsOfAllGoals = [...new Set(allGoalIds)];

          const allMetrics$ = this.getAllMetricsPerRecentGoals$(uniqueIdsOfAllGoals, idsToExclude);

          return forkJoin([missingGoals$, allMetrics$]).pipe(switchMap(([missingGoals, allMetrics]) => of({ recentGoals, missingGoals, allMetrics, recentKpis })));
        }
      })
    );
  }

  private createExcludeCondition(sessionsToExcludeIds: string[]): {
    goalCondition: ISearchCondition;
    metricCondition: ISearchCondition;
  } {
    const fieldCondition: ISearchFieldCondition = {
      operator: "notIn",
      value: sessionsToExcludeIds,
    };

    const goalCondition = {
      fieldName: "sessionId",
      fieldCondition,
    };

    const metricCondition = {
      fieldName: "session._id",
      fieldCondition,
    };

    return {
      goalCondition,
      metricCondition,
    };
  }

  private createSearchRequests(searchConditionsMap: Map<RelatedItemType, ISearchCondition[]>): ISearchRequestsBody[] {
    const searchFields = [
      { name: "name" },
      { name: "description" },
      {
        name: searchConditionsMap.has("kpi") ? "assignee.name" : "assignees.name",
      },
    ];

    const requests: ISearchRequestsBody[] = [];

    searchConditionsMap.forEach((searchConditions, itemType) => {
      const request: ISearchRequestsBody = {
        collectionName: `${itemType}s`,
        searchConditions,
        searchFields,
      };

      requests.push(request);
    });

    return requests;
  }

  public getRelatedItemsBySearchTerm$(config: { searchTerm: string; idsToExclude: string[]; itemTypes: RelatedItemType[] }): Observable<NzTreeNodeOptions[]> {
    const searchConditionsMap: Map<RelatedItemType, ISearchCondition[]> = this.getSearchConditionsMap(config);

    const searchBody: SearchDTO = {
      searchTerm: config.searchTerm,
      searchRequests: this.createSearchRequests(searchConditionsMap),
    };

    return this.searchFacade.searchData$<"goals" | "metrics" | "kpis">(searchBody).pipe(map(({ items }) => buildSearchNodes(items)));
  }

  private getSearchConditionsMap(config: { searchTerm: string; idsToExclude: string[]; itemTypes: RelatedItemType[] }): Map<RelatedItemType, ISearchCondition[]> {
    if (!config.searchTerm) {
      return new Map();
    }

    const sessions = reduxStoreContainer.reduxStore.getState<ISessionsStoreState>().sessions.items;
    const sessionsToExcludeIds: string[] = sessions.reduce((sessionIds, session) => {
      if (!this.currentUserRepository.allowedActionsSync(session.access).includes("read")) {
        sessionIds.push(session.id);
      }

      return sessionIds;
    }, []);
    let excludeConditions: {
      goalCondition?: ISearchCondition;
      metricCondition?: ISearchCondition;
    } = null;

    if (sessionsToExcludeIds.length && (config.itemTypes.includes("goal") || config.itemTypes.includes("metric"))) {
      excludeConditions = this.createExcludeCondition(sessionsToExcludeIds);
    }

    const searchConditionsMap: Map<RelatedItemType, ISearchCondition[]> = new Map();

    if (config.itemTypes.includes("goal")) {
      searchConditionsMap.set("goal", [
        {
          fieldName: "_id",
          fieldCondition: {
            operator: "notIn",
            value: [...sessionsToExcludeIds, ...config.idsToExclude],
          },
        },
        ...(excludeConditions?.goalCondition ? [excludeConditions.goalCondition] : []),
      ]);
    }

    if (config.itemTypes.includes("metric")) {
      searchConditionsMap.set("metric", [
        {
          fieldName: "_id",
          fieldCondition: {
            operator: "notIn",
            value: [...sessionsToExcludeIds, ...config.idsToExclude],
          },
        },
        ...(excludeConditions?.metricCondition ? [excludeConditions.metricCondition] : []),
      ]);
    }

    if (config.itemTypes.includes("kpi")) {
      searchConditionsMap.set("kpi", [
        {
          fieldName: "_id",
          fieldCondition: {
            operator: "notIn",
            value: [...config.idsToExclude],
          },
        },
      ]);
    }

    return searchConditionsMap;
  }
}
