import { Observable, noop, of } from "rxjs";
import { IDisabledLaunchDarklyConfig } from "@webapp/core/app-config/models/app-config.models";

/**
 * This is used in our on-premise version.
 */
export class StaticFeatureTogglesFacade {
  private enabledFeatureFlags: string[] = [];

  constructor(config: IDisabledLaunchDarklyConfig) {
    this.enabledFeatureFlags = config.enabledFeatures.split(",").map((featureKey) => featureKey.trim());
  }

  public isInitialized(): boolean {
    return true;
  }

  public initialize = noop;

  public isFeatureAvailable(featureKey: string): Promise<boolean> {
    return Promise.resolve(this.isFeatureFlagEnabled(featureKey));
  }

  public isFeatureAvailable$(featureKey: string): Observable<boolean> {
    return of(this.isFeatureFlagEnabled(featureKey));
  }

  private isFeatureFlagEnabled(featureKey: string): boolean {
    return this.enabledFeatureFlags.includes(featureKey);
  }
}
