import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { ButtonSelectModule } from "@webapp/shared/components/button-select/button-select.module";
import { UiPopoverModule } from "@webapp/ui/popover/popover.module";
import { TaskStatusComponent } from "./task-status.component";

@NgModule({
  declarations: [TaskStatusComponent],
  exports: [TaskStatusComponent],
  imports: [CommonModule, LocalizationModule, ButtonSelectModule, UiButtonModule, UiPopoverModule, UiIconModule],
})
export class TaskStatusModule {}
