import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { NzInputNumberGroupSlotComponent } from "ng-zorro-antd/input-number";
import { UiGroupType } from "@webapp/ui/input/input.models";

@Component({
  selector: "[ui-input-number-group-slot]",
  templateUrl: "./input-number-group-slot.component.html",
  styleUrls: ["./input-number-group-slot.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiIconModule, NzOutletModule, NgIf],
})
export class UiInputNumberGroupSlotComponent extends NzInputNumberGroupSlotComponent {
  @Input("uiIcon") public icon?: string | null = null;
  @Input("uiType") public type: UiGroupType = null;
  @Input("uiTemplate") public template?: string | TemplateRef<void> | null = null;
}
