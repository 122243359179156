import { IModule } from "angular";

export function loadModuleStyles(mod: IModule, styles: string): IModule {
  return mod.config(() => {
    const styleEl = document.createElement("style");
    styleEl.setAttribute("data-module", mod.name);
    styleEl.textContent = styles;
    document.head.appendChild(styleEl);
  });
}
