import { getLanguageCode } from "@gtmhub/localization/utils";
import { capitalize } from "@gtmhub/util/capitalize";
import lang from "../../../../../../localization/lang_en.json";

export const currentTexts: { map: Record<string, string> } = { map: lang.l };
export const currentTextsInEnglish: { map: Record<string, string> } = { map: lang.l };

export const localize = (key: string, templateVariables?: Record<string, unknown>): string => {
  if (currentTexts.map[key] && templateVariables && templateVariables.length != 0) {
    return stringTemplateParser(currentTexts.map[key], templateVariables);
  }

  return currentTexts.map[key] || key;
};

export const localizeToEnglish = (key: string, templateVariables?: Record<string, unknown>): string => {
  if (currentTexts.map[key] && templateVariables && templateVariables.length != 0) {
    return stringTemplateParser(currentTexts.map[key], templateVariables);
  }

  return currentTextsInEnglish.map[key] || key;
};

const stringTemplateParser = (expression: string, valueObj: Record<string, unknown>): string => {
  const templateMatcher = /{{(\s?)+([^{}\s]*)(\s?)+}}/g;
  return expression.replace(templateMatcher, (key, value) => {
    value = valueObj[key.substring(2, key.length - 2).trim()]; // cut out {{}} from RegEx return string
    return value;
  });
};

export const localizeToCase = (key: string): string => {
  const locale = getLanguageCode();

  const translatedString = localize(key);

  return locale === "de" ? capitalize(translatedString) : translatedString.toLowerCase();
};

export const capitelizeFirst = (word: string): string => {
  if (!word) {
    return "";
  }

  const firstChar = word.charAt(0).toUpperCase();
  const restChars = word.slice(1);

  return firstChar + restChars;
};
