import { IModalService } from "angular-ui-bootstrap";

export class TaskFilterModalService {
  public static $inject = ["$uibModal", "$rootScope"];

  constructor(private $uibModal: IModalService) {}

  public open(): void {
    this.$uibModal.open({
      template: require("../../assignees/views/filter-form.html"),
      windowClass: "medium-small",
      controller: "FilterFormCtrl",
    });
  }
}
