<div *ngIf="notification.operationType === 'user_reflection_update_published'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">{{ "reflection_is_updated" | localize: { names: notification.notificationData.actor.names } }}</div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">{{ "reflection_summary" | localize: { teamName: notification.notificationData.item.teamName } }}</div>
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url" (click)="trackNotificationClicked('team_member_checkin_published')">{{ "view_check_in" | localize }}</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'user_reflection_update_reminder'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">{{ "time_to_update_reflection" | localize: { teamName: notification.notificationData.item.teamName } }}</div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url" (click)="trackNotificationClicked('publish_team_checkin_reminder')">{{ "view_check_in" | localize }}</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'mentioned'" feature-toggle-enabled="reflections-mention">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">{{ "user_mentioned_you_in_checkin" | localize: { names: notification.notificationData.actor.names } }}</div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" : "UTC" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiType]="'check-in'" [uiTooltipTitle]="'Check-in' | localize" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item" *ngIf="teamName">{{ "reflection_summary" | localize: { teamName: teamName } }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <div class="heading inline-notifications">
          {{ notification.notificationData.item.cadenceStart | date: "dd MMM, yyyy" : "UTC" }} - {{ notification.notificationData.item.cadenceEnd | date: "dd MMM, yyyy" : "UTC" }}
        </div>
      </div>
      <div class="inbox-section" *ngFor="let notificationBox of notification.notificationData.item.fields" [ngSwitch]="notificationBox.type">
        <div class="inline-notifications" *ngSwitchCase="'question_default'">
          <div class="heading inline-notifications" *ngIf="notificationBox.name === 'question_one'">
            {{ "checkins_default_question_one" | localize }}
          </div>
          <div class="heading inline-notifications" *ngIf="notificationBox.name === 'question_two'">
            {{ "checkins_default_question_two" | localize }}
          </div>
          <div class="heading inline-notifications question_three" *ngIf="notificationBox.name === 'question_three'">
            {{ "checkins_default_question_three" | localize }}
          </div>
        </div>
        <div class="heading inline-notifications blockers" *ngSwitchCase="'blockers'">
          {{ "reflections_overview_blockers" | localize }}
        </div>
        <div class="heading inline-notifications requests" *ngSwitchCase="'requests'">
          {{ "reflections_overview_requests" | localize }}
        </div>
        <div class="heading inline-notifications wins" *ngSwitchCase="'wins'">
          {{ "reflections_overview_wins" | localize }}
        </div>
        <div class="heading inline-notifications kudos" *ngSwitchCase="'kudos'">
          {{ "reflections_overview_kudos" | localize }}
        </div>
        <div class="heading inline-notifications takeaways" *ngSwitchCase="'takeaways'">
          {{ "reflections_overview_takeaways" | localize }}
        </div>
        <div class="heading inline-notifications" *ngSwitchDefault>
          {{ notificationBox.name | localize }}
        </div>
        <rich-text-viewer [text]="notificationBox.text"></rich-text-viewer>
      </div>
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.url">{{ "view_check_in" | localize }}</a>
      </div>
    </div>
  </div>
</div>
