import { IHttpService, IRootScopeService } from "angular";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { EnvironmentService } from "@gtmhub/env";
import { IUIError, createUIError } from "@gtmhub/error-handling";
import { ISocketV2Data } from "@gtmhub/sockets";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { ICollection } from "@webapp/core/core.models";
import { IAssigneesStoreState } from "./assignee-reducer";
import { AssigneesAction, IErrorReceiveAssigneesAction, IReceiveAssigneesAction, IRequestAssigneesAction, IUpdateAssigneesAction } from "./models";

const requestAssignees = (): IRequestAssigneesAction => ({ type: "REQUEST_ASSIGNEES" });
export const receiveAssignees = (items: Assignee[]): IReceiveAssigneesAction => ({ type: "RECEIVE_ASSIGNEES", items });
const requestAssigneesError = (error: IUIError): IErrorReceiveAssigneesAction => ({ type: "ERROR_RECEIVE_ASSIGNEES", error });
export const updateAssignees = (update: ISocketV2Data<Assignee>): IUpdateAssigneesAction => ({ type: "UPDATE_ASSIGNEES", update });
const shouldFetchAssignees = (state: IAssigneesStoreState) => !state.assignees.isFetching && !state.assignees.isFetched;

export class AssigneeActions {
  public static $inject = ["$rootScope", "$http", "EnvironmentService"];

  constructor(
    private $rootScope: IRootScopeService,
    private $http: IHttpService,
    private env: EnvironmentService
  ) {}

  public updateAssignees(update: ISocketV2Data<Assignee>): Action {
    return updateAssignees(update);
  }

  public getAssignees(): ThunkAction<void, IAssigneesStoreState, null, AssigneesAction> {
    return (dispatch, getState) => {
      if (shouldFetchAssignees(getState())) {
        dispatch(requestAssignees());

        const url = this.env.getApiEndpoint("/assignees");

        this.$http.get<ICollection<Assignee>>(url).then(
          (response) => {
            dispatch(receiveAssignees(response.data.items));
            this.$rootScope.$broadcast("accountAssigneesFetched");
          },
          (rejection) => dispatch(requestAssigneesError(createUIError(rejection)))
        );
      }
    };
  }
}
