import { Injectable } from "@angular/core";
import { localize } from "@webapp/localization/utils/localization.utils";
import { ChangeOkrStateComponent, OkrChangeStateModalData } from "@webapp/okrs/components/change-okr-state/change-okr-state.component";
import {
  OkrWorkflowEditReviewersData,
  OkrWorkflowEditReviewersModalComponent,
} from "@webapp/okrs/components/okr-workflow/okr-workflow-edit-reviewers/okr-workflow-edit-reviewers.component";
import { OkrWorkflowModalComponent, OkrWorkflowModalData } from "@webapp/okrs/components/okr-workflow/okr-workflow-modal/okr-workflow-modal.component";
import { cancelButton, primaryButton } from "@webapp/shared/modal/modal.utils";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";

@Injectable()
export class OkrWorkflowService {
  private modeMappings = {
    approve: {
      buttonTextKey: "approve",
      action: "approveOkr",
      danger: false,
      title: "okr_approve_workflow",
    },
    decline: {
      buttonTextKey: "decline",
      action: "declineOkr",
      danger: true,
      title: "okr_decline_workflow",
    },
    withdraw: {
      buttonTextKey: "withdraw",
      action: "withdrawOkr",
      danger: true,
      title: "okr_withdraw_workflow",
    },
  };

  public constructor(private modalService: UiModalService) {}

  public openWorkflowApprovalModal(options: OkrWorkflowModalData): void {
    const modeOption = this.modeMappings[options.mode];
    this.modalService.create<OkrWorkflowModalComponent, OkrWorkflowModalData>({
      uiContent: OkrWorkflowModalComponent,
      uiData: {
        goalId: options.goalId,
        mode: options.mode,
      },
      uiTitle: localize(modeOption.title),
      uiWidth: "600px",
      uiFooter: [
        primaryButton(localize(modeOption.buttonTextKey), {
          onClick: (component) => component[modeOption.action](),
          danger: modeOption.danger,
          disabled: (component) => component.isDisabled,
        }),
        cancelButton(),
      ],
    });
  }

  public openEditReviewersModal(options: OkrWorkflowEditReviewersData): void {
    this.modalService.create<OkrWorkflowEditReviewersModalComponent, OkrWorkflowEditReviewersData>({
      uiContent: OkrWorkflowEditReviewersModalComponent,
      uiData: {
        goalId: options.goalId,
        ownerIds: options.ownerIds,
        session: options.session,
        methodologySettings: options.methodologySettings,
        selectedUsers: options.selectedUsers,
        isPrivate: options.isPrivate,
      },
      uiTitle: localize("send_for_approval"),
      uiWidth: "600px",
      uiFooter: [
        primaryButton(localize("send_for_approval"), {
          onClick: (component) => component.sendForApproval(),
        }),
        cancelButton(),
      ],
    });
  }

  public openChangeOkrStateModal(options: OkrChangeStateModalData): void {
    this.modalService.create<ChangeOkrStateComponent, OkrChangeStateModalData>({
      uiContent: ChangeOkrStateComponent,
      uiWidth: 600,
      uiTitle: options.oldStatus !== "active" ? localize("you_are_closing_okr") : localize("reopen_okr") + "?",
      uiData: {
        goalId: options.goalId,
        goalName: options.goalName,
        selectedStatus: options.selectedStatus,
        oldStatus: options.oldStatus,
      },
      uiOnOk: (component) => component.saveGoalStatus(),
    });
  }
}
