import { Injectable } from "@angular/core";
import { Observable, map, of, zip } from "rxjs";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { CurrentUserRepository } from "@webapp/users";

@Injectable()
export class IntegrationsOptionsVisibilityService {
  constructor(
    private permissionsFacade: PermissionsFacade,
    private currentUserRepository: CurrentUserRepository,
    private editionFeatureService: EditionFeatureService,
    private featureTogglesFacade: FeatureTogglesFacade
  ) {}

  get anyOption$(): Observable<boolean> {
    return zip(this.dataSources$, this.installedApps$, this.apiTokens$, this.slack$, this.jira$, this.workday$).pipe(
      map(([dataSources, installedApps, apiTokens, slack, jira, workday]) => {
        return dataSources || installedApps || apiTokens || slack || jira || workday;
      })
    );
  }

  get dataSources$(): Observable<boolean> {
    return zip(this.permissionsFacade.hasPermission$("ManageData"), this.editionFeatureService.hasFeature$("setup.datasources")).pipe(
      map(([manageDataPermission, featureAvailable]) => {
        return manageDataPermission && featureAvailable;
      })
    );
  }

  get installedApps$(): Observable<boolean> {
    return zip(this.permissionsFacade.hasPermission$("ManageApplications"), this.editionFeatureService.hasFeature$("setup.plugins")).pipe(
      map(([manageApplicationsPermission, featureAvailable]) => {
        return manageApplicationsPermission && featureAvailable;
      })
    );
  }

  get apiTokens$(): Observable<boolean> {
    return zip(
      this.permissionsFacade.hasPermission$("ManageApiTokens"),
      this.editionFeatureService.hasFeature$("integrations.api"),
      this.editionFeatureService.hasFeature$("platform"),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.PlatformLD)
    ).pipe(
      map(([manageApiTokensPermission, featureAvailable, isPlatformEditionFeatureEnabled, isPlatformFeatureFlagEnabled]) => {
        if (isPlatformEditionFeatureEnabled && isPlatformFeatureFlagEnabled) {
          return false;
        }

        return manageApiTokensPermission && featureAvailable;
      })
    );
  }

  get slack$(): Observable<boolean> {
    return zip(this.permissionsFacade.hasPermission$("ManageConfiguration"), this.editionFeatureService.hasFeature$("setup.slack")).pipe(
      map(([manageConfigurationPermission, featureAvailable]) => {
        return manageConfigurationPermission && featureAvailable;
      })
    );
  }

  get jira$(): Observable<boolean> {
    return zip(
      this.permissionsFacade.hasPermission$("ManageConfiguration"),
      this.editionFeatureService.hasFeature$("integrations.jira"),
      of(this.currentUserRepository.userHasRole("admin"))
    ).pipe(
      map(([manageConfigurationPermission, featureAvailable, isAdmin]) => {
        return manageConfigurationPermission && featureAvailable && isAdmin;
      })
    );
  }

  get workday$(): Observable<boolean> {
    return zip(this.permissionsFacade.hasPermission$("ManageConfiguration"), this.editionFeatureService.hasFeature$("setup.workday")).pipe(
      map(([manageConfigurationPermission, featureAvailable]) => {
        return manageConfigurationPermission && featureAvailable;
      })
    );
  }
}
