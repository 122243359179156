import { IConfidenceMapping } from "@gtmhub/core";
import { ConfidenceScale, ConfidenceScaleEnum, ConfidenceType } from "@webapp/configuration/models/configuration.model";

export const getFloatedMappings = (mappings: IConfidenceMapping[], scaleFactor: number): IConfidenceMapping[] => {
  const biggestMappingRange = Math.max(...mappings.map((m) => m.range.to));

  if (biggestMappingRange > 1) {
    return mappings.map((it) => {
      const copy = Object.assign({}, it);

      copy.range.to = copy.range.to / scaleFactor;
      copy.range.from = copy.range.from / scaleFactor;

      return copy;
    });
  }

  return mappings;
};

export const getConfidenceFloatNumber = (value: string | number, scaleFactor: number): number => {
  const numberedValue = +value;
  if (value === null || typeof value === "undefined" || value === "" || isNaN(Number(value))) {
    return null;
  }

  /** webapp works with float values, ensure to divide integers bigger than 1 */
  if (numberedValue > 1) {
    return numberedValue / scaleFactor;
  }

  if (numberedValue < 0.1) {
    return numberedValue * scaleFactor;
  }

  return numberedValue;
};

export const findRange = (confidenceValue: number, mappings: IConfidenceMapping[]): IConfidenceMapping => {
  // if no confidence is set yet --> return null and check for it in setViewVariables
  if (confidenceValue === null) {
    return null;
  }

  // mappings are sorted --> checking if our confidence value is greater than lower boundaries of each mapping
  for (const mapping of mappings) {
    if (confidenceValue <= mapping.range.to) {
      return mapping;
    }
  }

  // zero is inclusive so if our value is zero we return the last mapping with lowest ranges
  return mappings[mappings.length - 1];
};

const isScaleMinor = (confidenceScale: ConfidenceScale): boolean => {
  return confidenceScale === ConfidenceScaleEnum.Minor;
};

export const getConfidenceName = (confidenceValue: number, confidenceType: ConfidenceType, confidenceScale: ConfidenceScale, mappings: IConfidenceMapping[]): string => {
  let confidenceName = "";
  const mapping = findRange(confidenceValue, mappings);

  switch (confidenceType) {
    case "numeric":
      confidenceName = isScaleMinor(confidenceScale) ? confidenceValue.toFixed(1) : Math.round(confidenceValue * 10) + "/10";
      break;
    case "text":
      confidenceName = mapping.name;
      break;
  }

  return confidenceName;
};
