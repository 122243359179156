import { Component } from "@angular/core";
import { SubNavOption } from "@webapp/navigation/models/sub-nav-options.models";
import NavigationItemsMediator from "@webapp/navigation/services/uxcustomization/navigation-items.mediator.service";
import { BaseSubNav } from "../services/base-sub-nav";

@Component({
  selector: "insightboards-sub-navigation",
  templateUrl: "./insightboards-sub-navigation.component.html",
})
export class InsightboardsSubNavigationComponent extends BaseSubNav {
  public options: SubNavOption[] = [{ label: "all_insightboards", state: "gtmhub.insightboards" }];

  public constructor(protected navigationItemsMediator: NavigationItemsMediator) {
    super();
    this.setupEntityTypes(["dashboard"], "insightboards");
  }
}
