import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import { FormItemBaseComponent } from "../form-item-base.component";

@Component({
  selector: "toggle-form-item",
  templateUrl: "./toggle-form-item.component.html",
  styleUrls: ["./toggle-form-item.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FormItemBaseComponent, useExisting: forwardRef(() => ToggleFormItemComponent) }],
})
export class ToggleFormItemComponent extends FormItemBaseComponent<boolean> {
  @Input() public align: string;
  @Input() public inlineLabel = false;
}
