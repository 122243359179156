import { IAccount } from "@gtmhub/core";
import { IRole } from "@gtmhub/roles/models";
import { ITeam } from "@gtmhub/teams/models/models";
import { IUser } from "@gtmhub/users/models";
import { UserAction } from "@webapp/sessions/models/sessions.model";

export const defaultUserActionSet = (): UserAction[] => ["read", "update", "create", "delete", "modifyPermissions"];

export const DEFAULT_ACCOUNT_PRINCIPAL = { name: "Account", type: "account" };

export type AccountPrincipal = Pick<IAccount, "name" | "id" | "isActive"> & { type: "account"; used: boolean };

export type UserPrincipal = IUser & { type: "user" };
export type RolePrincipal = IRole & { type: "role" };
export type TeamPrincipal = ITeam & { type: "team" };
export type TeamAndSubTeamsPrincipal = IRole & { type: "team_and_subteams" };

export type Principal = UserPrincipal | AccountPrincipal | RolePrincipal | TeamPrincipal | TeamAndSubTeamsPrincipal;
