import { IHttpService, IPromise, IRequestShortcutConfig } from "angular";
import { IRestLayerRequest } from "@gtmhub/core";
import { EnvironmentService } from "@gtmhub/env";
import { ICollection } from "@webapp/core/core.models";
import { ICustomField, ICustomFieldReorderPayload, ICustomFieldReorderResponse } from "@webapp/custom-fields/models/custom-fields.models";
import { PluginGtmhubAdditionalParams } from "@webapp/plugins/plugins.models";

export class CustomFieldsService {
  public static $inject = ["$http", "EnvironmentService"];

  constructor(
    private $http: IHttpService,
    private env: EnvironmentService
  ) {}

  public getCustomFieldsV2<T extends ICustomField>(params: IRestLayerRequest, additionalGtmhubParams?: PluginGtmhubAdditionalParams): IPromise<ICollection<T>> {
    const url = this.env.getApiEndpointV2("/customFields");

    const config: IRequestShortcutConfig = {
      params: { ...params, ...additionalGtmhubParams },
    };

    return this.$http.get<ICollection<T>>(url, config).then((response) => response.data);
  }

  public createCustomField(customField: ICustomField): IPromise<unknown> {
    const url = this.env.getApiEndpoint("/customFields");

    return this.$http.post<unknown>(url, customField);
  }

  public updateCustomField(customField: ICustomField): IPromise<unknown> {
    const url = this.env.getApiEndpoint("/customFields/" + customField._id);

    return this.$http.put<unknown>(url, customField);
  }

  public reorderCustomField(payload: ICustomFieldReorderPayload): IPromise<ICustomFieldReorderResponse> {
    const url = this.env.getApiEndpoint("/customFields/reorder");

    return this.$http.patch<ICustomFieldReorderResponse>(url, payload).then((response) => response.data);
  }

  public delete(id: string): IPromise<unknown> {
    const url = this.env.getApiEndpoint("/customFields/" + id);

    return this.$http.delete<unknown>(url);
  }
}
