import { Injectable } from "@angular/core";
import { Observable, switchMap } from "rxjs";
import { loadModule } from "@webapp/data-story/data-story.future-states";
import { AllInsightsListOutputActionsParams, InsightSelectorOutputActionsParams } from "@webapp/data-story/models/insights.models";
import { LazyModalService } from "@webapp/shared/modal/lazy-modal.service";

@Injectable({
  providedIn: "root",
})
export class InsightsModalsService {
  constructor(private readonly lazyModalService: LazyModalService) {}

  public openInsightSelectorModal(params?: AllInsightsListOutputActionsParams): Observable<InsightSelectorOutputActionsParams> {
    const insightSelectorModal$ = this.lazyModalService.create({
      lazyConfig: { loadModule, component: (mod) => mod.InsightSelectorComponent },
      uiData: params,
      uiFooter: null,
      uiWidth: 750,
    });

    return insightSelectorModal$.pipe(
      switchMap((modal) => {
        return new Observable<InsightSelectorOutputActionsParams>((subscriber) => {
          const actionEmitter = modal.componentInstance.action;

          subscriber.add(
            actionEmitter.subscribe((action) => {
              modal.close();
              subscriber.next(action);
            })
          );
          subscriber.add(modal.afterClose.subscribe(() => subscriber.complete()));
        });
      })
    );
  }
}
