import { BACKSPACE } from "@angular/cdk/keycodes";
import { NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Host,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { NzNoAnimationDirective } from "ng-zorro-antd/core/no-animation";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { NzSelectTopControlComponent } from "ng-zorro-antd/select";
import { fromEvent, takeUntil } from "rxjs";
import { ValueToID } from "@webapp/ui/pipes/value-to-id.pipe";
import { UiSelectSearchComponent } from "@webapp/ui/select/components/select-search/select-search.component";
import { UiSelectItemInterface, UiSelectModeType } from "@webapp/ui/select/select.models";
import { UiSelectItemComponent } from "../select-item/select-item.component";
import { UiSelectPlaceholderComponent } from "../select-placeholder/select-placeholder.component";

@Component({
  selector: "ui-select-top-control",
  exportAs: "uiSelectTopControl",
  templateUrl: "select-top-control.component.html",
  styleUrls: ["select-top-control.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, NgSwitchDefault, NgIf, UiSelectSearchComponent, ValueToID, UiSelectItemComponent, UiSelectPlaceholderComponent, NgFor],
})
export class UiSelectTopControlComponent extends NzSelectTopControlComponent implements OnInit, OnChanges {
  // we keep the imports here due to the typings that were r elated to NzSelect and overridden with UiSelect
  @Input() public nzId: string | null = null;
  @Input() public hideSelectedTags = false;
  @Input() public showSearch = false;
  @Input() public placeholder: string | TemplateRef<NzSafeAny> | null = null;
  @Input() public open = false;
  @Input() public maxTagCount = Infinity;
  @Input() public autofocus = false;
  @Input() public disabled = false;
  @Input() public readonly = false;
  @Input() public deletable = true;
  @Input() public autoHideSearch: boolean;
  @Input() public selectSearchInputTabIndex: number;
  @Input() public mode: UiSelectModeType = "default";
  @Input() public customTemplate: TemplateRef<{ $implicit: UiSelectItemInterface }> | null = null;
  @Input() public visibleTemplateOverflow: boolean;
  @Input() public maxTagPlaceholder: TemplateRef<{ $implicit: NzSafeAny[] }> | null = null;
  @Input() public removeIcon: TemplateRef<NzSafeAny> | null = null;
  @Input() public listOfTopItem: UiSelectItemInterface[] = [];
  @Input() public tokenSeparators: string[] = [];
  @Input() public activatedValue: NzSafeAny | null;
  @Input() public a11yRequired = false;
  @Input() public uiPlaceHolderTemplate: TemplateRef<{ $implicit: UiSelectItemInterface }> | null = null;
  @Input() public listboxId: string;
  @Input() public renderRemoveChipIcon: boolean;
  /** Aria label of the select. */
  @Input() public a11yLabel = "";
  /** Input that can be used to specify the `aria-labelledby` attribute. */
  @Input() public a11yLabelledby: string;
  /** Input that can be used to specify the `aria-description` attribute. */
  @Input() public a11yDescription?: string;
  @Input() public focusedValue: string;
  @Input() public a11yDescribedby: string;

  @Input() public problematicSelectItems: string[] = [];

  @Output() public readonly tokenize = new EventEmitter<string[]>();
  @Output() public readonly inputValueChange = new EventEmitter<string>();
  @Output() public readonly deleteItem = new EventEmitter<UiSelectItemInterface>();
  @ViewChild(UiSelectSearchComponent) public nzSelectSearchComponent!: UiSelectSearchComponent;

  public disabledTabIndex = -1;

  constructor(
    private uiElementRef: ElementRef<HTMLElement>,
    private uiNgZone: NgZone,
    @Host() @Optional() noAnimation: NzNoAnimationDirective | null,
    private changeDetector: ChangeDetectorRef
  ) {
    super(uiElementRef, uiNgZone, noAnimation);
  }

  public ngOnInit(): void {
    this.uiNgZone.runOutsideAngular(() => {
      fromEvent<MouseEvent>(this.uiElementRef.nativeElement, "click")
        .pipe(takeUntil(this["destroy$"]))
        .subscribe((event) => {
          // `HTMLElement.focus()` is a native DOM API which doesn't require Angular to run change detection.
          if (event.target !== this.nzSelectSearchComponent.inputElement.nativeElement) {
            this.nzSelectSearchComponent.focus();
          }
        });

      fromEvent<KeyboardEvent>(this.uiElementRef.nativeElement, "keydown")
        .pipe(takeUntil(this["destroy$"]))
        .subscribe((event) => {
          if (event.target instanceof HTMLInputElement) {
            const inputValue = event.target.value;

            if (event.keyCode === BACKSPACE && this.mode !== "default" && !inputValue && this.listOfTopItem.length > 0) {
              event.preventDefault();
              // Run change detection only if the user has pressed the `Backspace` key and the following condition is met.
              this.uiNgZone.run(() => {
                const lastElement = this.listOfTopItem[this.listOfTopItem.length - 1];
                const selectedItem = this.listOfTopItem.find((item) => item.nzValue === this.focusedValue);
                this.onDeleteItem(selectedItem || lastElement);
              });
            }
          }
        });
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.disabled) {
      this.disabledTabIndex = changes.disabled.currentValue ? 0 : -1;
    }
  }

  public onInputValueChange(value: string): void {
    const initialState = this.isShowPlaceholder;
    super.onInputValueChange(value);

    // fixes a ui glitch with the placeholder blinking on first char typed
    if (initialState !== this.isShowPlaceholder) {
      this.changeDetector.detectChanges();
    }
  }
}
