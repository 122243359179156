import { NgModule } from "@angular/core";
import { UiToastContainerComponent } from "./components/toast-container/toast-container.component";
import { UiToastService } from "./services/toast.service";
import { UiToastComponent } from "./toast.component";

@NgModule({
  imports: [UiToastContainerComponent, UiToastComponent],
  exports: [UiToastContainerComponent, UiToastComponent],
  providers: [UiToastService],
})
export class UiToastModule {}
