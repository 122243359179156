import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { Permission } from "../models/permissions.model";
import { PermissionsFacadeWithFeatureFlag } from "../services/permission-facade-ff.service";

@UntilDestroy()
@Directive({
  selector: "[hasPermissionWhen]",
  standalone: true,
})
export class HasPermissionWithFeatureFlagDirective implements OnInit {
  @Input()
  public hasPermissionWhen: keyof typeof FeatureFlag;

  @Input()
  public hasPermissionWhenNegate?: boolean;

  @Input()
  public hasPermissionWhenFlagIsOn: Permission;

  @Input()
  public hasPermissionWhenFlagIsOff: Permission;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private container: ViewContainerRef,
    private permissionsFacade: PermissionsFacadeWithFeatureFlag
  ) {}

  public ngOnInit(): void {
    this.container.clear();

    this.permissionsFacade
      .hasPermissionWithFeatureFlag$(FeatureFlag[this.hasPermissionWhen], this.hasPermissionWhenFlagIsOn, this.hasPermissionWhenFlagIsOff)
      .pipe(untilDestroyed(this))
      .subscribe((hasPermission) => {
        const show = hasPermission && !this.hasPermissionWhenNegate;

        if (show) {
          this.container.createEmbeddedView(this.templateRef);
        } else {
          this.container.clear();
        }
      });
  }
}
