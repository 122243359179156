import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { CfDropdownUI, CfMapUI } from "@webapp/custom-fields/models/custom-fields.models";

@Component({
  selector: "custom-field-select",
  templateUrl: "./select.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldSelectComponent implements OnChanges {
  @Input()
  public disabled: boolean;
  @Input()
  public readonly: boolean;
  @Input()
  public fieldName: string;
  @Input()
  public uiId: string;
  @Input()
  public targetCustomFieldsMap: CfMapUI;
  @Input()
  public options: CfDropdownUI[];
  @Input()
  public deselectOption: boolean;
  @Output()
  public readonly optionSelect = new EventEmitter<{ fieldName: string; cf: CfDropdownUI }>();

  @Input() public a11yLabelledby: string;
  @Input() public a11yRequired = false;

  public readonlyTabIndex: number;

  public selectedValue: CfDropdownUI;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.fieldName?.isFirstChange() && changes.fieldName.currentValue && this.targetCustomFieldsMap) {
      this.selectedValue = this.targetCustomFieldsMap[changes.fieldName.currentValue] as CfDropdownUI;
    }

    if (changes.targetCustomFieldsMap?.currentValue && this.fieldName) {
      this.selectedValue = changes.targetCustomFieldsMap.currentValue[this.fieldName] as CfDropdownUI;
    }
  }

  public onSelection(newValue: CfDropdownUI): void {
    this.optionSelect.emit({ fieldName: this.fieldName, cf: newValue });
  }
}
