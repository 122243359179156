import { IRootScopeService, IScope } from "angular";
import { localize } from "@gtmhub/localization";
import { ISnackbarContentParams } from "./snackbar.component";

export class SnackbarEvents {
  private $rootScope: IRootScopeService;
  constructor($scope: IScope) {
    this.$rootScope = $scope.$root;
  }

  public broadcastShowSnackbar(key: string, params?: ISnackbarContentParams): void {
    this.$rootScope.$broadcast("showSnackbar", {
      contentMsg: localize(key, params?.inputPlaceholders),
      ...(params && {
        contentParams: { actionCb: params.actionCb, actionBtnContent: localize(params.actionBtnContent) },
        skipClose: params.skipClose,
        customDuration: params.customDuration,
        info: params.info,
        success: params.success,
        hideCloseX: params.hideCloseX,
        centered: params.centered,
        position: params.position,
      }),
    });
  }
}
