import { IQuantivePlusTag } from "@webapp/platform-intelligence/quantive-plus/models";
import { PIFeedbackSuggestionSubType, PIFeedbackSuggestionType } from "../feedback/models";
import { PIStateProcessorInstanceSubType } from "../state-processor/pi-state-processor.models";
import {
  IMPROVE_DESCRIPTION_DROPDOWN_MENU_ITEMS,
  IMPROVE_KR_TITLE_DROPDOWN_MENU_ITEMS,
  IMPROVE_OBJECTIVE_TITLE_DROPDOWN_MENU_ITEMS,
  IMPROVE_TAGS_DROPDOWN_MENU_ITEMS,
  IMPROVE_TASK_TITLE_DROPDOWN_MENU_ITEMS,
  IPostImprovementPayload,
  ImproveSuggestionButtonSpecificDropdownMenuItemsMap,
  PIImproveSuggestionContext,
  PIImprovementSuggestionType,
} from "./models";

export const generateImproveSuggestionButtonSpecificDropdownItems = (
  suggestionFeedbackType: PIFeedbackSuggestionType,
  suggestionFeedbackSubType: PIFeedbackSuggestionSubType
): (keyof ImproveSuggestionButtonSpecificDropdownMenuItemsMap)[] => {
  if (suggestionFeedbackSubType === "title") {
    if (suggestionFeedbackType === "objective") {
      return IMPROVE_OBJECTIVE_TITLE_DROPDOWN_MENU_ITEMS;
    }

    if (suggestionFeedbackType === "KR") {
      return IMPROVE_KR_TITLE_DROPDOWN_MENU_ITEMS;
    }

    if (suggestionFeedbackType === "task") {
      return IMPROVE_TASK_TITLE_DROPDOWN_MENU_ITEMS;
    }
  }

  if (suggestionFeedbackSubType === "description") {
    return IMPROVE_DESCRIPTION_DROPDOWN_MENU_ITEMS;
  }

  if (suggestionFeedbackSubType === "tags") {
    return IMPROVE_TAGS_DROPDOWN_MENU_ITEMS;
  }
};

export const tagsImprovementSplitOrJoinSuffix = ",";

export const generateTagImprovementPayload = (args: {
  instruction: string;
  tags: IQuantivePlusTag[];
  isAskAI: boolean;
  improveSuggestionContext: PIImproveSuggestionContext;
  subEntityType: PIStateProcessorInstanceSubType;
}): {
  subEntityType: PIStateProcessorInstanceSubType;
  entityId: string;
  improvement: {
    instruction: string;
    suggestion: { tags: IQuantivePlusTag[] };
    isAskAI: boolean;
    parentId: string | null;
    parentType: PIImprovementSuggestionType | null;
  };
} => {
  return {
    subEntityType: args.subEntityType,
    entityId: args.improveSuggestionContext.entityId || null,
    improvement: {
      instruction: `${args.instruction}, use one word only AND ALWAYS generate 3 tags`,
      suggestion: {
        tags: args.tags,
      },
      isAskAI: args.isAskAI,
      parentId: args.improveSuggestionContext.parentId || null,
      parentType: args.improveSuggestionContext.parentType || null,
    },
  };
};

export const generateImprovementPayload = (args: {
  instruction: string;
  suggestion: Record<string, unknown>;
  isAskAI: boolean;
  improveSuggestionContext: PIImproveSuggestionContext;
  subEntityType: PIStateProcessorInstanceSubType;
}): IPostImprovementPayload => {
  return {
    subEntityType: args.subEntityType,
    entityId: args.improveSuggestionContext.entityId || null,
    improvement: {
      instruction: args.instruction,
      suggestion: args.suggestion,
      isAskAI: args.isAskAI,
      parentId: args.improveSuggestionContext.parentId || null,
      parentType: args.improveSuggestionContext.parentType || null,
    },
  };
};
