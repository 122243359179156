import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject, take, zip } from "rxjs";
import { AuthenticationResolverService } from "@gtmhub/auth";
import { localize } from "@gtmhub/localization";
import { AccountPickerComponent } from "@webapp/accounts/components/account-picker/account-picker.component";
import { MultiAccount, UserMultiAccountInvitationType } from "@webapp/accounts/models/multi-account/multi-account.models";
import { MultiAccountsFacade } from "@webapp/accounts/services/multi-account/multi-account-facade.service";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { IProfile } from "@webapp/user-profile/models/user-profile.models";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";

@UntilDestroy()
@Component({
  selector: "profile-dropdown",
  templateUrl: "./profile-dropdown.component.html",
  styleUrls: ["./profile-dropdown.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileDropdownComponent implements OnInit {
  @Input() public withoutBorderOnFocus = false;

  public profile: IProfile;
  public profileMenuItems: DropdownMenuItem[];

  private userAccounts: MultiAccount[];
  private isAllowedToSwitchAccount$ = new Subject<boolean>();

  constructor(
    private authenticationResolverService: AuthenticationResolverService,
    private multiAccountsFacade: MultiAccountsFacade,
    private modalService: UiModalService,
    private featureTogglesFacade: FeatureTogglesFacade,
    private userProfileService: UserProfileService,
    broadcastService: BroadcastService
  ) {
    zip([
      this.multiAccountsFacade.getMultiAccounts$(this.authenticationResolverService.getAuthUserId()),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.LockMultiAccount),
    ])
      .pipe(take(1), untilDestroyed(this))
      .subscribe(([accounts, isLockMultiAccountEnabled]) => {
        this.userAccounts = accounts;

        this.isAllowedToSwitchAccount$.next(
          !isLockMultiAccountEnabled && accounts?.some((account) => account.invitation?.status === UserMultiAccountInvitationType.approved)
        );
      });

    broadcastService
      .on("profilePictireUpdated")
      .pipe(untilDestroyed(this))
      .subscribe(() => this.setProfile());
  }

  public ngOnInit(): void {
    this.profileMenuItems = this.getProfileMenuItems();
    this.setProfile();
  }

  public getProfileMenuItems(): DropdownMenuItem[] {
    const menuItemBuilder = new DropdownMenuItemBuilder();
    return [
      menuItemBuilder.setKey("edit_profile").setAction({ state: "gtmhub.userProfile" }).build(),

      menuItemBuilder.setKey("manage_notifications").setAction({ state: "gtmhub.userNotificationSettings" }).setToBeEndOfSection().build(),

      menuItemBuilder
        .setKey("switch_account")
        .setAction({ handler: () => this.openAccountsModal() })
        .setIsVisibleCondition(this.isAllowedToSwitchAccount$.asObservable())
        .build(),

      menuItemBuilder
        .setKey("logout")
        .setAction({ handler: () => this.authenticationResolverService.logout({ ssoLogoutIfEnabled: true }) })
        .setUiType({ uiType: "icon", icon: "export" })
        .build(),
    ];
  }

  private setProfile(): void {
    this.profile = this.userProfileService.getProfile();
  }

  private openAccountsModal(): void {
    this.modalService.create({
      uiTitle: localize("switch_account"),
      uiContent: AccountPickerComponent,
      uiData: this.userAccounts,
      uiClassName: "account-picker",
      uiFooter: null,
      uiWidth: 600,
    });
  }
}
