import { Injectable, Injector } from "@angular/core";
import { Observable, map, of, take } from "rxjs";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { IQuantivePlusSuggestionsFacade } from "./quantive-plus-suggestions-facade.interface";
import { QuantivePlusSuggestionsFacadeV1 } from "./v1/quantive-plus-suggestions-facade-v1.service";
import { QuantivePlusSuggestionsFacadeV2 } from "./v2/quantive-plus-suggestions-facade-v2.service";

type QuantivePlusSuggestionType = "kr" | "objective-tags" | "kr-tasks" | "kr-description";

@Injectable({
  providedIn: "root",
})
export class QuantivePlusSuggestionsProviderFactory {
  constructor(
    private injector: Injector,
    private featureTogglesFacade: FeatureTogglesFacade
  ) {}

  public getInstance(suggestionType: QuantivePlusSuggestionType): Observable<IQuantivePlusSuggestionsFacade> {
    const getProviderBySuggestionType: {
      [key in QuantivePlusSuggestionType]: () => Observable<IQuantivePlusSuggestionsFacade>;
    } = {
      kr: this.getKrSuggestionsProvider.bind(this),
      "objective-tags": this.getTagsSuggestionsProvider.bind(this),
      "kr-tasks": this.getKrTasksProvider.bind(this),
      "kr-description": this.getKeyResultDescriptionProvider.bind(this),
    };

    return getProviderBySuggestionType[suggestionType]();
  }

  private getKrSuggestionsProvider(): Observable<IQuantivePlusSuggestionsFacade> {
    return this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.QuantivePlusSuggestionsKrV2).pipe(
      take(1),
      map((isV2SuggestionsAvailable) => {
        if (isV2SuggestionsAvailable) {
          return this.injector.get(QuantivePlusSuggestionsFacadeV2);
        }

        return this.injector.get(QuantivePlusSuggestionsFacadeV1);
      })
    );
  }

  private getTagsSuggestionsProvider(): Observable<IQuantivePlusSuggestionsFacade> {
    return of(this.injector.get(QuantivePlusSuggestionsFacadeV1));
  }

  private getKrTasksProvider(): Observable<IQuantivePlusSuggestionsFacade> {
    return of(this.injector.get(QuantivePlusSuggestionsFacadeV1));
  }

  private getKeyResultDescriptionProvider(): Observable<IQuantivePlusSuggestionsFacade> {
    return of(this.injector.get(QuantivePlusSuggestionsFacadeV1));
  }
}
