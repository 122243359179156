import { ITeam } from "@gtmhub/teams";
import { IWhiteboard } from "@gtmhub/whiteboards";
import { Insightboard } from "@webapp/insightboards/models";
import { KpiGroup } from "@webapp/kpis/models/kpi-group.models";
import { List } from "@webapp/lists/models";
import { Session } from "@webapp/sessions/models/sessions.model";

export enum SuggestedModelKeys {
  Sessions = "sessions",
  Dashboards = "dashboards",
  Teams = "teams",
  KpiGroups = "kpiGroups",
  Whiteboards = "whiteboards",
  Lists = "lists",
}
export interface SuggestedModel {
  [SuggestedModelKeys.Sessions]: {
    userSessions: Session[];
    teamsSessions: Session[];
    otherSessions: Session[];
  };
  [SuggestedModelKeys.Dashboards]: {
    teamMembersDashboards: Insightboard[];
  };
  [SuggestedModelKeys.Teams]: {
    userTeams: ITeam[];
    userParentTeams: ITeam[];
    userSubTeams: ITeam[];
  };
  [SuggestedModelKeys.KpiGroups]: {
    teamMembersKpiGroups: KpiGroup[];
  };
  [SuggestedModelKeys.Whiteboards]: {
    teamMembersWhiteboards: IWhiteboard[];
  };
  [SuggestedModelKeys.Lists]: {
    teamMembersLists: List[];
  };
}

export class SuggestedDtoModel {}

export type SuggestedEntityType = Session | List | Insightboard | IWhiteboard | KpiGroup | ITeam;
