import { Observable, switchMap } from "rxjs";
import { Cache } from "./models/cache.model";

/**
 * Tries to get an item from the cache. If the item does not exist in the cache,
 * it is taken from a provided observable (usually, a HTTP request). Then the cache
 * is populated with the fetched value and next time it will be retrieved from there.
 */
export function cacheOrGet<T>(cache: Cache<T>, get$: Observable<T>): Observable<T> {
  return cache.get$().pipe(
    switchMap((cacheValue) => {
      if (typeof cacheValue !== "undefined") {
        return cache.get$();
      }

      return getAndCache(cache, get$);
    })
  );
}

export function getAndCache<T>(cache: Cache<T>, get$: Observable<T>): Observable<T> {
  return get$.pipe(
    switchMap((getValue) => {
      cache.set(getValue);
      return cache.get$();
    })
  );
}
