import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "valueToID",
  standalone: true,
})
export class ValueToID implements PipeTransform {
  public transform(value: unknown): string {
    if (!value) {
      return "";
    }

    const regex = /[\w$%€£\p{sc=Cyrillic}\p{sc=Arabic}\p{sc=Han}]+/gu;
    const matchedArray = JSON.stringify(value).match(regex);

    return matchedArray.join("").slice(0, 20);
  }
}
