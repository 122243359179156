import { NgModule } from "@angular/core";
import { UiFormControlComponent } from "./components/form-control/form-control.component";
import { UiFormItemComponent } from "./components/form-item/form-item.component";
import { UiFormLabelComponent } from "./components/form-label/form-label.component";
import { UiFormSplitComponent } from "./components/form-split/form-split.component";
import { UiFormTextComponent } from "./components/form-text/form-text.component";
import { UiFormDirective } from "./form.directive";

@NgModule({
  imports: [UiFormDirective, UiFormItemComponent, UiFormControlComponent, UiFormSplitComponent, UiFormLabelComponent, UiFormTextComponent],
  exports: [UiFormDirective, UiFormItemComponent, UiFormControlComponent, UiFormSplitComponent, UiFormLabelComponent, UiFormTextComponent],
})
export class UiFormModule {}
