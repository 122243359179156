import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { SuggestedDtoModel, SuggestedModel } from "./suggested.models";
import { SuggestedState } from "./suggested.state";

@Injectable({
  providedIn: "root",
})
export class SuggestedApiService extends BaseApiService<SuggestedModel, SuggestedDtoModel, SuggestedState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: SuggestedState) {
    super("uxcustomization/suggestions", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }
}
