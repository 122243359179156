import { module } from "angular";
import upgrade from "@gtmhub/core/upgrade/module";
import env from "@gtmhub/env/env.module";
import userProfile from "@gtmhub/user-profile/user-profile.module";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { TrackDirective } from "./directives/track.directive";

const mod = module("analytics", [upgrade, env, userProfile]);
mod.downgradeInjectable("AnalyticsService", AnalyticsService);
mod.directive("ghTrack", TrackDirective.factory());

export default mod.name;
