import { IBadge, IBadgeAssignment } from "@gtmhub/badges";
import { ITag } from "@gtmhub/tags/models";
import { IUser } from "@gtmhub/users/models";
import { IdMap } from "@gtmhub/util";
import { CfMap } from "@webapp/custom-fields/models/custom-fields.models";
import { TeamBase } from "@webapp/teams/models/teams.models";

export type IReduxTeam = TeamBase;

export interface ITeam {
  id: string;
  accountId?: string;
  parentId?: string;
  name?: string;
  description?: string;
  customFields?: CfMap;
  color?: string;
  avatar?: string;
  picture?: string;
  manager?: string;
  managerInfo?: Partial<IUser>;
  members?: string[];
  membersInfo?: Partial<IUser>[];
  assignedBadges?: IBadgeAssignment[];
  badges?: IBadge[];
  // ui stuff
  folded?: boolean;
  folding?: boolean; // used by the old Org Tree
  type?: string;
  used?: boolean;
  tags?: ITag[];
  isActive?: boolean;
  dateDeactivated?: string;
}

export interface IEnrichedTeam extends ITeam {
  type?: "team";
}

export type ITeamsIdMap = IdMap<ITeam>;

export interface IBulkActionOnTeamsResponse {
  hasFailures: boolean;
  items?: IBulkActionOnTeamsResponseItem[];
}

interface IBulkActionOnTeamsResponseItem {
  isSuccessful: boolean;
  teamId: string;
}

export type Paginator = {
  currentPage: number;
  itemsPerPage: number;
  paginatorLimit: number;
  totalPages?: number;
  totalCount?: number;
};

export type TeamProfilePermissions = {
  canAccessPeople: boolean;
  canEditTeam: boolean;
  canManageTeamActivation: boolean;
  canDeleteTeam: boolean;
  canManageReflections: boolean;
  canAccessGoals: boolean;
};

export type BaseTeamProfileInfoPermissions = { canEditTeam: boolean; canManageTeamMembers: boolean };
export type TeamsScreenPermissions = { canAccessPeople: boolean; canManageTeamMembers: boolean; canCreateTeam: boolean };
export type BulkEditTeamMembersScreenPermissions = { canAccessPeople: boolean; canManageTeamMembers: boolean };

export type OrganizationScreenPermissions = {
  canAccessPeople: boolean;
  canCreateTeam: boolean;
  canEditTeam: boolean;
  canDeleteTeam: boolean;
  canManageTeamMembers: boolean;
};

export type OrganizationTreeNodePermissions = Pick<OrganizationScreenPermissions, "canCreateTeam" | "canDeleteTeam" | "canManageTeamMembers">;

export type OrganizationChartSettings = {
  showDeactivatedMembers?: boolean;
  showDeactivatedTeams?: boolean;
};

export const DEFAULT_ORG_CHART_SHOW_DEACTIVATED_MEMBERS = false;
export const DEFAULT_ORG_CHART_SHOW_DEACTIVATED_TEAMS = false;
