import { StateService } from "@uirouter/angular";
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiIconSize, UiThemeType } from "@quantive/ui-kit/icon";
import { Subject } from "rxjs";
import { actionHandlerGenerator } from "@gtmhub/shared/utils";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { DropdownMenuItem, IDropdownMenuItemIcon, IDropdownMenuItemToggle, IDropdownMenuItemUiIcon } from "@webapp/shared/dropdown/dropdown.models";

/**
 * @example <li *ngFor="let item of this.menuItems" gh-dropdown-menu-item [item]="item"></li>
 * @param item IDropdownMenuItem
 */
@UntilDestroy()
@Component({
  selector: "[gh-dropdown-menu-item]",
  templateUrl: "./dropdown-menu-item.component.html",
  styleUrls: ["./dropdown-menu-item.component.less"],
})
export class DropdownMenuItemComponent implements OnInit, OnChanges {
  @Input()
  public item: DropdownMenuItem;

  @Input() public activeDropdownItemChange$: Subject<string>;
  public actionHandler: () => unknown;

  @Input()
  public isActive: boolean;

  @Input() public withGap: boolean;

  @Input() public showNewDesign = false;

  @Output()
  public readonly closeGroup: EventEmitter<unknown> = new EventEmitter<unknown>();

  constructor(
    private stateService: StateService,
    private analyticsService: AnalyticsService,
    private hostElement: ElementRef<HTMLElement>
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isActive && changes.isActive.currentValue) {
      this.hostElement.nativeElement.tabIndex = -1;
      this.hostElement.nativeElement.focus();
    }
  }

  public ngOnInit(): void {
    if (this.item.action && !this.item.disabled) {
      this.actionHandler = actionHandlerGenerator(this.item.action, { $state: this.stateService, $window: window });
      this.activeDropdownItemChange$.pipe(untilDestroyed(this)).subscribe((activeItemKey) => {
        if (activeItemKey === this.item.key) {
          this.invokeAction();
        }
      });
    }
  }

  public handleButtonClick(): void {
    this.invokeAction();
    this.closeGroup.emit();
  }

  public getIconClass(): string {
    return "gh-font-icon-" + (this.item as IDropdownMenuItemIcon).icon;
  }

  public getIconType(): string {
    return (this.item as IDropdownMenuItemUiIcon).iconType;
  }

  public getIconTheme(): UiThemeType {
    return (this.item as IDropdownMenuItemUiIcon).iconTheme;
  }

  public getIconSize(): UiIconSize {
    return (this.item as IDropdownMenuItemUiIcon).iconSize;
  }

  public getToggleValue(): boolean {
    return (this.item as IDropdownMenuItemToggle).value === undefined ? false : (this.item as IDropdownMenuItemToggle).value;
  }

  public getItemTrackMetaDataAsString(): string {
    return this.item.trackMetaData ? JSON.stringify(this.item.trackMetaData) : "";
  }

  public invokeAction(): void {
    if (!this.item.action) return;

    if (this.item.track) {
      this.analyticsService.track(this.item.track, this.item.trackMetaData);
    }

    this.actionHandler();
  }
}
