import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { Subject, catchError, debounce, of, switchMap, timer } from "rxjs";
import { localize } from "@gtmhub/localization";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { ErrorNotificationMessage } from "@webapp/shared/error-notification/error-notification.models";
import { ErrorNotificationModule } from "@webapp/shared/error-notification/error-notification.module";
import { Tag } from "@webapp/tags/models/tag.model";
import { UiGridModule } from "@webapp/ui/grid/grid.module";
import { UiSelectOptionInterface } from "@webapp/ui/select/select.models";
import { UiSelectModule } from "@webapp/ui/select/select.module";
import { TagsFacade } from "../../facades/tags-facade.service";

/**
 * Component for selecting tags, currently used single view okr form in session grid and draft okr view in Whiteboards
 * @example <tag-selector aria-label="tags" tabindex="0" [selected-tags]="model.tags" (tag-add)="assignTag($event)" (tag-remove)="unassignTag($event)"></tag-selector>
 */
@UntilDestroy()
@Component({
  selector: "tag-selector",
  templateUrl: "./tag-selector.component.html",
  styleUrls: ["./tag-selector.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UiSelectModule, LocalizationModule, ErrorNotificationModule, FormsModule, UiIconModule, UiGridModule],
})
export class TagSelectorComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * @param selectedTags already selected tags
   */
  @Input() public selectedTags: Tag[] = [];
  /**
   * @param placeholderKey input field placeholder
   */
  @Input() public placeholderKey: string;
  /**
   * @param disabled shows if field is locked for changes
   */
  @Input() public disabled: boolean;
  /**
   * @param ariaRequired announces if field is required
   */
  @Input() public a11yRequired = false;
  /**
   * @param ariaLabel announces the field label
   */
  @Input() public a11yLabel: string;
  /**
   * @param borderless when true input should not have border
   */
  @Input()
  @HostBinding("class.borderless")
  public borderless: boolean;
  @Input() public uiId: string;
  @Input() public autofocus: boolean;
  @Input() public open: boolean;
  /**
   * @param tagAdd emits the tag to the parent component that is to be assigned to specific element
   */
  @Output() public readonly tagAdd = new EventEmitter<Tag>();
  /**
   * @param tagRemove emits the name of the tag to the parent component that is to be unassigned from specific element
   */
  @Output() public readonly tagRemove = new EventEmitter<Tag>();
  @Output() public readonly selectBlur = new EventEmitter();
  @Output() public readonly openChange = new EventEmitter<boolean>();

  @ViewChild("createTagTemplate", { static: false }) public createTagTemplate: TemplateRef<NzSafeAny>;
  public uiSelectedTagTitles: string[] = [];
  private selectedTagTitlesSet: Set<string>; // this is just for skipping iteration when creating uiOptionsTitles
  public tagsOptions: Tag[];
  public uiTagOptions: UiSelectOptionInterface[] = [];
  public uiTagOptionsOnAssigning: UiSelectOptionInterface[] = [];
  public assigning: boolean;
  public errorNotification: ErrorNotificationMessage;
  public currentInputTyping: string;
  private lastSelectedTagTitles: string[];
  private uiOptionsTitles: Map<string, boolean>;
  public createTagsEnabled: boolean;
  public tagsLoading: boolean;
  private readonly searchSubject = new Subject<string | undefined>();

  public constructor(
    private permissionsFacade: PermissionsFacade,
    private tagsFacade: TagsFacade,
    private cdr: ChangeDetectorRef
  ) {}

  @HostListener("dispatchNzLabel", ["$event"])
  public handleTagAssignment(event: CustomEvent): void {
    this.tagUnassign(event.detail.data["title"]);
  }

  public ngOnDestroy(): void {
    this.searchSubject.complete();
  }

  public ngOnInit(): void {
    this.permissionsFacade.hasPermission$("CreateTags").subscribe((hasPermission) => {
      this.createTagsEnabled = hasPermission;
    });

    this.tagsOptions = this.selectedTags || [];

    this.uiOptionsTitles = new Map();
    this.uiSelectedTagTitles = this.tagsOptions.map((e) => e.title);
    this.selectedTagTitlesSet = new Set(this.uiSelectedTagTitles);
    this.processTagsInput(this.tagsOptions);

    this.searchSubject
      .pipe(
        debounce((value) => (value ? timer(1000) : timer(0))), // debounce http request only when user is typing
        switchMap((searchQuery) => {
          const filters: Record<string, unknown> = { isActive: true };
          if (searchQuery) {
            filters.title = { $regex: searchQuery };
          }

          return this.tagsFacade.getTags$({ filter: filters, limit: 30 }).pipe(
            catchError(() => {
              this.createErrorObject();
              this.tagsLoading = false;
              return of([]);
            })
          );
        })
      )
      .subscribe({
        next: (options) => {
          const set = new Set();
          const unionArray = options.concat(this.selectedTags || []).filter((item) => {
            if (!set.has(item.title)) {
              set.add(item.title);
              return true;
            }
            return false;
          }, set);

          this.assigning = false;

          this.tagsOptions = unionArray.sort((a, b) => a.title.localeCompare(b.title));

          this.processTagsInput(this.tagsOptions);
          this.tagsLoading = false;

          // this is needed to fire detect change, current change detection strategy is OnPush
          // strategy Default prevents change detection as well because of ignoredProperties "input" in zone-flags.ts
          this.cdr.detectChanges();
        },
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedTags && !changes.selectedTags.isFirstChange() && changes.selectedTags.currentValue?.length !== changes.selectedTags.previousValue?.length) {
      this.uiSelectedTagTitles = changes.selectedTags.currentValue.map((e) => e.title);
      this.selectedTagTitlesSet = new Set(this.uiSelectedTagTitles);

      this.searchSubject.next("");
    }
  }

  private setOptions(options: Tag[]): UiSelectOptionInterface[] {
    return options?.reduce((uiOptionsModel, option) => {
      uiOptionsModel.push({
        label: option.title,
        value: option.title,
        hide: this.uiOptionsTitles.get(option.title),
      });

      return uiOptionsModel;
    }, []);
  }

  private processTagsInput(tagsOptions: Tag[]): void {
    tagsOptions.forEach((option) => {
      this.uiOptionsTitles.set(option.title, this.selectedTagTitlesSet.has(option.title));
    });

    this.lastSelectedTagTitles = this.uiSelectedTagTitles;
    this.uiTagOptions = this.setOptions(tagsOptions); // when tagsOptions is [] it returns error

    const tagExists = this.tagExists(this.currentInputTyping, "title");

    if (this.currentInputTyping && !tagExists && this.createTagsEnabled) {
      this.uiTagOptions.push({
        label: this.createTagTemplate,
        value: this.currentInputTyping,
      });
    }
  }

  public onFocus(): void {
    this.currentInputTyping = null;
  }

  public handleBlur(): void {
    this.selectBlur.emit();
  }

  public handleOpenChange(isOpen: boolean): void {
    this.openChange.emit(isOpen);
  }

  public search(query: string): void {
    this.tagsLoading = true;
    this.currentInputTyping = query;

    this.searchSubject.next(query);
  }

  private createTag(newTagName: string): void {
    if (this.tagExists(newTagName, "title")) {
      return;
    }

    this.tagsFacade
      .createTag$({ title: newTagName })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (res) => {
          this.tagAssign(res);
        },
        error: (error) => {
          if (error.status === 403) {
            this.errorNotification = {
              message: localize("current_user_has_no_permissions_to_create_tag"),
            };
          } else {
            this.createErrorObject();
          }
          this.cdr.detectChanges();
        },
      });
  }

  private createErrorObject(): void {
    this.errorNotification = {
      message: localize("there_was_a_server_issue_on_our_end"),
    };
  }

  private tagExists(value: string, tagKey: string): Tag {
    if (!value) {
      return null;
    }

    return this.tagsOptions.find((e) => e[tagKey].toLocaleLowerCase() === value.toLocaleLowerCase());
  }

  public tagUnassign(tagTitle: string): void {
    const tagFromInput = this.selectedTags?.find((e) => e.title === tagTitle);
    this.tagRemove.emit(tagFromInput);
  }

  public tagAssign(tag: Tag): void {
    this.assigning = true;
    this.uiTagOptionsOnAssigning = [];
    this.uiTagOptions.forEach((option) => {
      if (tag.title === option.label) {
        option.hide = true;
      }

      this.uiTagOptionsOnAssigning.push(option);
    });

    this.tagAdd.emit(tag);
  }

  public onChangeModel(value: string[]): void {
    if (this.lastSelectedTagTitles?.length > value.length) {
      const valueSet = new Set(value);
      const tagToRemove = this.lastSelectedTagTitles.find((v) => !valueSet.has(v));
      this.tagUnassign(tagToRemove);
      return;
    }

    const existingTag = this.tagExists(value[value.length - 1], "title");

    if (existingTag) {
      this.tagAssign(existingTag);
    } else {
      this.createTag(value[value.length - 1]);
    }
  }
}
