import { module } from "angular";
import { ViewHistoryPayloadBuilder } from "@webapp/navigation/utils/view-history-payload.builder";
import { ViewHistoryFactory } from "./view-history-factory";
import { ViewHistoryService } from "./view-history.service";

const mod = module("view-history", []);

mod.service("ViewHistoryService", ViewHistoryService);
mod.service("ViewHistoryFactory", ViewHistoryFactory);
mod.downgradeInjectable("ViewHistoryPayloadBuilder", ViewHistoryPayloadBuilder);

export default mod.name;
