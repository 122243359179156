import { IRequestShortcutConfig } from "angular";
import { EnvironmentService } from "@gtmhub/env";
import { buildSearchConditions } from "@gtmhub/shared/utils/search-utils";
import { ICollection } from "@webapp/core/core.models";
import { ISearchParams } from "@webapp/search/models/search-api.models";
import { SearchConditionSettings, SearchDTO, fieldsToSearchByMap, searchCollectionsTranslationMap } from "@webapp/search/models/search.models";

export const compoundSuffix = "Compound";

export class SearchService {
  public static $inject = ["$http", "EnvironmentService"];

  constructor(
    private $http: ng.IHttpService,
    private env: EnvironmentService
  ) {}

  public getSomeSearchData<T>(searchBody: Partial<SearchDTO>, queryParams?: Partial<ISearchParams>): ng.IPromise<ICollection<T>> {
    const url = this.env.getSearchEndpoint(`/multi`);
    const query: IRequestShortcutConfig = {
      params: queryParams,
    };

    return this.$http.post<ICollection<T>>(url, searchBody, query).then((response) => response.data);
  }

  public getSearchBody(searchTerm: string, currentFacet: string, settings: SearchConditionSettings = {}): Partial<SearchDTO> {
    const compound = searchCollectionsTranslationMap[currentFacet].length > 1;

    return {
      searchTerm,
      operator: 0,
      searchRequests: searchCollectionsTranslationMap[currentFacet].map((collection) => {
        const collectionKey = compound ? collection + compoundSuffix : collection;

        return {
          collectionName: collection,
          searchFields: fieldsToSearchByMap[collectionKey].map((field: string) => {
            return {
              name: field,
            };
          }),
          searchConditions: buildSearchConditions(collection, settings),
        };
      }),
    };
  }
}
