import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import { FormItemBaseComponent } from "@webapp/shared/form/components/form-items/form-item-base.component";
import { Tag } from "@webapp/tags/models/tag.model";

@Component({
  selector: "metric-tags-form-item",
  templateUrl: "./metric-tags-form-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FormItemBaseComponent, useExisting: forwardRef(() => MetricTagsFormItemComponent) }],
})
export class MetricTagsFormItemComponent extends FormItemBaseComponent<Tag[]> {
  @Input() public metricId: string;
}
