import { module } from "angular";
import "ng-file-upload/dist/ng-file-upload";
import ngImgCrop from "ng-img-crop-npm";
import env from "@gtmhub/env/env.module";
import errorHandling from "@gtmhub/error-handling/module";
import files from "@gtmhub/files/module";
import searchCore from "@gtmhub/search/search-core.module";
import { UserInvitationModalService } from "@gtmhub/users/components/user-invitation-form/user-invitation-modal.service";
import { StatusActionComponent } from "@webapp/teams/components/status-action/status-action.component";
import { CurrentUserRepository } from "@webapp/users";
import { EditUserNamesComponent } from "./components/edit-user-names/edit-user-names.component";
import { UserInvitationComponent } from "./components/user-invitation-form/user-invitation.component";
import { ChangeUserAvatarCtrl } from "./controllers/change-user-avatar";
import { MultiUserSelectorCtrl } from "./controllers/users-selector";
import { InviteLink } from "./directives/invite-link";
import { PermissionsService } from "./permissions-service";
import { PermissionsActions } from "./redux/permissions-actions";
import { ChangeUserPictureModalService } from "./services/change-user-picture-modal.service";
import { UserOwnershipService } from "./services/user-ownership.service";
import { UserService } from "./user-service";

const mod = module("usersCore", [errorHandling, env, "ngFileUpload", ngImgCrop, files, searchCore]);

mod.controller("ChangeUserAvatarCtrl", ChangeUserAvatarCtrl);
mod.controller("MultiUserSelectorCtrl", MultiUserSelectorCtrl);

mod.component("userInvitation", UserInvitationComponent);
mod.component("editUserNames", EditUserNamesComponent);

mod.directive("inviteLink", InviteLink.factory());

mod.service("UserService", UserService);
mod.service("PermissionsService", PermissionsService);
mod.downgradeInjectable("CurrentUserRepository", CurrentUserRepository);
mod.service("UserInvitationModalService", UserInvitationModalService);
mod.service("ChangeUserPictureModalService", ChangeUserPictureModalService);
mod.service("UserOwnershipService", UserOwnershipService);

mod.service("PermissionsActions", PermissionsActions);

mod.downgradeComponent("statusAction", { component: StatusActionComponent });

export default mod.name;
