import { Ng1StateDeclaration } from "@uirouter/angularjs";

export const states: Ng1StateDeclaration[] = [
  {
    name: "gtmhub.userProfile",
    data: {
      requiresLogin: true,
      titleKey: "user_profile",
      trackingPage: "User Profile",
    },
    url: "/user-profile/",
    template: require("./views/user-profile.html"),
    controller: "UserProfileCtrl",
  },
  {
    name: "gtmhub.sendChangePasswordRequest",
    data: {
      requiresLogin: true,
      titleKey: "user_profile",
    },
    url: "/send-change-password-request/",
    template: require("./views/send-change-password-request.html"),
    controller: "SendChangePasswordRequestCtrl",
  },
  {
    name: "gtmhub.userLanguage",
    data: {
      requiresLogin: true,
      titleKey: "user_profile",
      trackingPage: "User Language",
    },
    url: "/user-language",
    template: require("./views/user-language.html"),
    controller: "UserLanguageCtrl",
  },
];
