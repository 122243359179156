<ng-template>
  <rich-text-editor-with-mentions
    [useGifs]="useGifs"
    [useMentions]="useMentions"
    [useSubmitButton]="useSubmitButton"
    [placeholder]="placeholder"
    [formControl]="formControl"
    [readonly]="readonly"
    [disabled]="disabled"
    [uiId]="controlId"
    [a11yLabelledby]="labelId"
    [attr.data-test-id]="e2eTestId"
    [focusMe]="autofocus"
    [removeAllMentionsAriaLabel]="removeAllMentionsAriaLabel"
    [inline]="borderless"
    (editorFocus)="editorFocus.emit()"
    (editorBlur)="editorBlur.emit()"></rich-text-editor-with-mentions>
</ng-template>
