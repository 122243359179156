import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { localize } from "@webapp/localization/utils/localization.utils";
import { loadModule } from "@webapp/okrs/okrs.future-states";
import { LazyModalService } from "@webapp/shared/modal/lazy-modal.service";
import { cancelButton, primaryButton } from "@webapp/shared/modal/modal.utils";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";
import type { CreateOkrFormComponent } from "./create-okr-form.component";
import { CreateOkrFormModalData } from "./create-okr-form.models";

const MODAL_WIDTH = 662;

@Injectable({
  providedIn: "root",
})
export class CreateOkrFormModalService {
  constructor(private readonly lazyModalService: LazyModalService) {}

  public openFormModal(data?: CreateOkrFormModalData): Observable<UiModalRef<CreateOkrFormComponent>> {
    return this.lazyModalService.create({
      lazyConfig: { loadModule, component: (mod) => mod.CreateOkrFormComponent },
      uiData: data || {},
      uiStyle: { display: "flex" },
      uiTitle: localize("create_objective"),
      uiDescription: localize("all_required_fields_are_marked_with_an_asterisk"),
      uiFooter: [
        primaryButton(localize("create_objective"), {
          disabled: (component) => !component.canSubmitForm,
          onClick: (component) => {
            component.submitForm();
            return false;
          },
        }),
        cancelButton(),
      ],
      uiWidth: MODAL_WIDTH,
    });
  }
}
