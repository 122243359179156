import { Injectable, Injector } from "@angular/core";
import { Observable, map, of, take } from "rxjs";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { IStrategicGuidedOkrFacade } from "./strategic-guided-okr-facade.interface";
import { StrategicGuidedOkrFacadeV1 } from "./v1/strategic-guided-okr-facade-v1.service";
import { StrategicGuidedOkrFacadeV2 } from "./v2/strategic-guided-okr-facade-v2.service";

type StrategicGuidedOkrSuggestionType = "kr" | "objective" | "objective-description";

@Injectable({
  providedIn: "root",
})
export class StrategicGuidedOkrProviderFactory {
  constructor(
    private injector: Injector,
    private featureTogglesFacade: FeatureTogglesFacade
  ) {}

  public getInstance(suggestionType: StrategicGuidedOkrSuggestionType): Observable<IStrategicGuidedOkrFacade> {
    const getProviderBySuggestionType: {
      [key in StrategicGuidedOkrSuggestionType]: () => Observable<IStrategicGuidedOkrFacade>;
    } = {
      kr: this.getKrSuggestionsProvider.bind(this),
      objective: this.getObjectiveSuggestionsProvider.bind(this),
      "objective-description": this.getObjectiveDescriptionProvider.bind(this),
    };

    return getProviderBySuggestionType[suggestionType]();
  }

  private getKrSuggestionsProvider(): Observable<IStrategicGuidedOkrFacade> {
    return this.getSuggestionsProvider(FeatureFlag.StrategicGuidedOkrKrV2);
  }

  private getObjectiveSuggestionsProvider(): Observable<IStrategicGuidedOkrFacade> {
    return this.getSuggestionsProvider(FeatureFlag.StrategicGuidedOkrObjectiveV2);
  }

  private getObjectiveDescriptionProvider(): Observable<IStrategicGuidedOkrFacade> {
    return of(this.injector.get(StrategicGuidedOkrFacadeV1));
  }

  private getSuggestionsProvider(featureFlag: FeatureFlag): Observable<IStrategicGuidedOkrFacade> {
    return this.featureTogglesFacade.isFeatureAvailable$(featureFlag).pipe(
      take(1),
      map((isV2SuggestionsAvailable) => {
        if (isV2SuggestionsAvailable) {
          return this.injector.get(StrategicGuidedOkrFacadeV2);
        }
        return this.injector.get(StrategicGuidedOkrFacadeV1);
      })
    );
  }
}
