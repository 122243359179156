// eslint-disable-next-line no-restricted-imports
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

export default (o: unknown, c: typeof dayjs.Dayjs, d: typeof dayjs): void => {
  localizedFormat(o, c, d);

  // en_US is the default locale and the formats are set by this plugin
  d["en"].formats["L"] = "MM/DD/YYYY";
  d["en"].formats["LL"] = "MMMM DD, YYYY";
};
