import { NgIf } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
} from "@angular/core";
import { NzFormatBeforeDropEvent, NzFormatEmitEvent, NzTreeBaseService, NzTreeNode, NzTreeNodeOptions } from "ng-zorro-antd/core/tree";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzTreeNodeBuiltinComponent } from "ng-zorro-antd/tree";
import { Observable, fromEvent, takeUntil } from "rxjs";
import { TreeSize } from "../../tree.models";
import { UiTreeIndentComponent } from "../tree-indent/tree-indent.component";
import { UiTreeNodeBuiltinCheckboxComponent } from "../tree-node-checkbox/tree-node-checkbox.component";
import { UiTreeNodeSwitcherComponent } from "../tree-node-switcher/tree-node-switcher.component";
import { UiTreeNodeTitleComponent } from "../tree-node-title/tree-node-title.component";

@Component({
  selector: "ui-tree-node[builtin]",
  exportAs: "uiTreeBuiltinNode",
  templateUrl: "tree-node.component.html",
  styleUrls: ["tree-node.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    "[class.tree-node-medium]": `!uiSelectMode && treeSize === 'medium'`,
    "[class.tree-node-large]": `!uiSelectMode && treeSize === 'large'`,
  },
  standalone: true,
  imports: [UiTreeIndentComponent, UiTreeNodeSwitcherComponent, UiTreeNodeBuiltinCheckboxComponent, UiTreeNodeTitleComponent, NgIf],
})
export class UiTreeNodeBuiltinComponent extends NzTreeNodeBuiltinComponent implements OnInit {
  /**
   * for global property
   */
  @Input() public icon = "";
  @Input() public title = "";
  @Input() public isLoading = false;
  @Input() public isSelected = false;
  @Input() public isDisabled = false;
  @Input() public isMatched = false;
  @Input() public isExpanded!: boolean;
  @Input() public isLeaf!: boolean;
  @Input() public isChecked?: boolean;
  @Input() public isHalfChecked?: boolean;
  @Input() public isDisableCheckbox?: boolean;
  @Input() public isSelectable?: boolean;
  @Input() public canHide?: boolean;
  @Input() public isStart: boolean[] = [];
  @Input() public isEnd: boolean[] = [];
  @Input() public isTreeFlatten: boolean;
  @Input() public uiOffset: number = 0;
  @Input() public treeSize?: TreeSize;
  @Input("uiTreeNode") public nzTreeNode!: NzTreeNode;
  @Input("uiShowLine") @InputBoolean() public nzShowLine?: boolean;
  @Input("uiShowExpand") @InputBoolean() public nzShowExpand?: boolean;
  @Input("uiCheckable") @InputBoolean() public nzCheckable?: boolean;
  @Input("uiAsyncData") @InputBoolean() public nzAsyncData?: boolean;
  @Input("uiHideUnMatched") @InputBoolean() public nzHideUnMatched = false;
  @Input("uiSelectMode") @InputBoolean() public nzSelectMode = false;
  @Input("uiShowIcon") @InputBoolean() public nzShowIcon = false;
  @Input("uiExpandedIcon") public nzExpandedIcon?: TemplateRef<{ $implicit: NzTreeNode; origin: NzTreeNodeOptions }>;
  @Input("uiTreeTemplate") public nzTreeTemplate: TemplateRef<{ $implicit: NzTreeNode; origin: NzTreeNodeOptions }> | null = null;
  @Input("uiBeforeDrop") public nzBeforeDrop?: (confirm: NzFormatBeforeDropEvent) => Observable<boolean>;
  @Input("uiSearchValue") public nzSearchValue = "";
  @Input("uiDraggable") public nzDraggable = false;
  @Output("uiClick") public readonly nzClick = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiDblClick") public readonly nzDblClick = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiContextMenu") public readonly nzContextMenu = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiCheckBoxChange") public readonly nzCheckBoxChange = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiExpandChange") public readonly nzExpandChange = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiOnDragStart") public readonly nzOnDragStart = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiOnDragEnter") public readonly nzOnDragEnter = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiOnDragOver") public readonly nzOnDragOver = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiOnDragLeave") public readonly nzOnDragLeave = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiOnDrop") public readonly nzOnDrop = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiOnDragEnd") public readonly nzOnDragEnd = new EventEmitter<NzFormatEmitEvent>();
  @Output() public readonly uiHover = new EventEmitter<NzTreeNode>();
  @Output() public readonly nodeCheckBoxChange = new EventEmitter<KeyboardEvent>();

  private zone: NgZone;
  private elRef: ElementRef<HTMLElement>;

  constructor(nzTreeService: NzTreeBaseService, ngZone: NgZone, renderer: Renderer2, elementRef: ElementRef<HTMLElement>, cdr: ChangeDetectorRef) {
    super(nzTreeService, ngZone, renderer, elementRef, cdr);
    this.zone = ngZone;
    this.elRef = elementRef;
  }

  public ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      fromEvent(this.elRef.nativeElement, "mouseenter")
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (!this.isDisabled) {
            this.zone.run(() => this.uiHover.emit(this.nzTreeNode));
          }
        });
    });
  }

  public clickSelect(event: MouseEvent): void {
    if (this.nzTreeNode.origin.readonly) {
      return;
    }

    super.clickSelect(event);
  }

  @HostListener("keydown.arrowright", ["$event"])
  public onKeydownArrowRight(event: KeyboardEvent): void {
    // the tree select component(tree-select.component.ts) handles the first part of this
    if (this.nzSelectMode || this.nzTreeNode.isLeaf) {
      return;
    }

    event.preventDefault();
    if (this.nzTreeNode.isExpanded) {
      const firstChildNodeElement = <HTMLElement>this.elRef.nativeElement.nextElementSibling;
      firstChildNodeElement?.focus();
      return;
    }

    // the clickExpand method accepts a MouseEvent that is just used to prevent default, then attached the emitted data
    // it is safe to pass a KeyboardEvent instead until we have to rely on some data from the event when consuming the emitted data
    // this way we're reusing the ng-zorro expand logic - https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/tree/tree-node.component.ts#L204
    super.clickExpand(event as unknown as MouseEvent);
  }

  @HostListener("keydown.arrowleft", ["$event"])
  public onKeydownArrowLeft(event: KeyboardEvent): void {
    // the tree select component(tree-select.component.ts) handles this
    if (this.nzSelectMode) {
      return;
    }

    event.preventDefault();
    if (this.nzTreeNode.isExpanded) {
      // the clickExpand method accepts a MouseEvent that is just used to prevent default, then attached the emitted data
      // it is safe to pass a KeyboardEvent instead until we have to rely on some data from the event when consuming the emitted data
      // this way we're reusing the ng-zorro expand logic - https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/tree/tree-node.component.ts#L204
      super.clickExpand(event as unknown as MouseEvent);
    } else if (this.nzTreeNode.parentNode) {
      let previousNodeElement = <HTMLElement>this.elRef.nativeElement.previousElementSibling;
      while (previousNodeElement && previousNodeElement.id !== this.getNodeHtmlId(this.nzTreeNode.parentNode)) {
        previousNodeElement = <HTMLElement>previousNodeElement?.previousElementSibling;
      }
      previousNodeElement?.focus();
    }
  }

  @HostListener("keydown.arrowdown", ["$event"])
  public onKeydownArrowDown(event: KeyboardEvent): void {
    // the tree select component(tree-select.component.ts) handles this
    if (this.nzSelectMode) {
      return;
    }

    event.preventDefault();
    const nextSiblingElement = <HTMLElement>this.elRef.nativeElement.nextElementSibling;
    nextSiblingElement?.focus();
  }

  @HostListener("keydown.arrowup", ["$event"])
  public onKeydownArrowUp(event: KeyboardEvent): void {
    // the tree select component(tree-select.component.ts) handles this
    if (this.nzSelectMode) {
      return;
    }

    event.preventDefault();
    const previousSiblingElement = <HTMLElement>this.elRef.nativeElement.previousElementSibling;
    previousSiblingElement?.focus();
  }

  @HostListener("keydown.enter", ["$event"])
  @HostListener("keydown.space", ["$event"])
  public onKeydownEnter(event: KeyboardEvent): void {
    // the tree select component(tree-select.component.ts) handles the first part of this
    if (this.nzSelectMode || this.nzTreeNode.isSelected) {
      return;
    }

    // the clickSelect method accepts a MouseEvent that is just used to prevent default, then attached the emitted data
    // it is safe to pass a KeyboardEvent instead until we have to rely on some data from the event when consuming the emitted data
    // this way we're reusing the ng-zorro select logic - https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/tree/tree-node.component.ts#L218
    super.clickSelect(event as unknown as MouseEvent);
    if (this.nzCheckable) {
      this.preventSelection();
      super.clickCheckBox(event as unknown as MouseEvent);
      this.nodeCheckBoxChange.emit(event);
    }
  }

  @HostListener("click", ["$event"])
  public click(event: MouseEvent): void {
    if (this.nzCheckable) {
      this.preventSelection();
      this.clickCheckBox(event);
    }
  }

  private getNodeHtmlId(node: NzTreeNode): string {
    return `node-${node.key}`;
  }

  private preventSelection(): void {
    if (!this.nzSelectMode) {
      this.nzTreeNode.isSelected = false;
    }
  }
}
