import { Injectable } from "@angular/core";
import circleCheckIconUrl from "wwwroot/img/icons/circle-check.svg";
import { localize } from "@webapp/localization/utils/localization.utils";

@Injectable({ providedIn: "root" })
export class ClipboardService {
  public defaultCopyURLNotificationEl(): HTMLDivElement {
    const notificationContainer = document.createElement("div");
    notificationContainer.style.cssText = `
        position: fixed;
        left: 50%;
        width: 192px;
        height: 46px;
        margin-left: -96px;
        top: 41px;
        background: #00A688;
        text-align: center;
        border-radius: 8px;
        padding: 12px;
        color: #fff;
        z-index: 9999;
      `;

    const icon = new Image();
    icon.src = circleCheckIconUrl;
    icon.style.cssText = `
        width: 22px;
        display: inline-block;
        margin-right: 9px;
      `;

    notificationContainer.appendChild(icon);

    return notificationContainer;
  }

  public copyToClipboard(text: string): void {
    if (!navigator.clipboard) {
      this.deprecatedCopyToClipboard(text);
    } else {
      navigator.clipboard.writeText(text).then(() => {
        this.notifySuccessfulCopy();
      });
    }
  }

  private notifySuccessfulCopy(options: { container: HTMLDivElement; duration: number } = { container: this.defaultCopyURLNotificationEl(), duration: 3000 }): void {
    const notificationContainer = options.container;
    const notificationText = document.createTextNode(localize("copied_to_clipboard"));
    notificationContainer.appendChild(notificationText);
    document.body.appendChild(notificationContainer);
    window.setTimeoutOutsideAngular(() => notificationContainer.remove(), options.duration);
  }

  private deprecatedCopyToClipboard(text: string, options?: { container: HTMLDivElement; duration: number }): void {
    const clipEl = document.createElement("input");
    clipEl.id = "clip";
    document.body.appendChild(clipEl);

    clipEl.value = text;
    clipEl.select();
    document.execCommand("copy");
    clipEl.remove();
    this.notifySuccessfulCopy(options);
  }
}
