import { IAttributes, IAugmentedJQuery, IDirective, IDirectiveFactory, IScope, ITimeoutService } from "angular";

const clickWithTimeout = ($timeout: ITimeoutService, element: IAugmentedJQuery) =>
  $timeout(() => {
    const htmlEl = element[0].querySelector<HTMLElement>("input") || element[0];
    htmlEl?.click();
  });

export class ClickOnFirstInput implements IDirective {
  constructor(private $timeout: ITimeoutService) {}

  public link(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes): void {
    if (attrs.clickOnFirstInput) {
      scope.$watch(attrs.clickOnFirstInput, (value: unknown) => {
        if (value) {
          clickWithTimeout(this.$timeout, element);
        }
      });
    } else {
      clickWithTimeout(this.$timeout, element);
    }
  }

  public static factory(): IDirectiveFactory {
    const directive = ($timeout: ITimeoutService) => new ClickOnFirstInput($timeout);
    directive.$inject = ["$timeout"];
    return directive;
  }
}
