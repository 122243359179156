<ng-container *ngIf="notification.operationType === 'share' && (notification.targetType === 'whiteboard' || notification.targetType === 'list' || notification.targetType === 'shareable_filter')">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    <span *ngIf="notification.notificationData.item.teamId && notification.targetType === 'whiteboard'">{{
      "user_shared_whiteboard_with_your_team" | localize: { names: notification.notificationData.actor.names }
    }}</span>
    <span *ngIf="!notification.notificationData.item.teamId && notification.targetType === 'whiteboard'">{{
      "user_shared_whiteboard_with_you" | localize: { names: notification.notificationData.actor.names }
    }}</span>
    <span *ngIf="notification.notificationData.item.teamId && notification.targetType === 'list'">{{
      "user_shared_report_with_your_team" | localize: { names: notification.notificationData.actor.names }
    }}</span>
    <span *ngIf="!notification.notificationData.item.teamId && notification.targetType === 'list'">{{
      "user_shared_report_with_you" | localize: { names: notification.notificationData.actor.names }
    }}</span>
    <span *ngIf="notification.notificationData.item.teamId && notification.targetType === 'shareable_filter'">{{
      "user_shared_okr_view_with_your_team" | localize: { names: notification.notificationData.actor.names }
    }}</span>
    <span *ngIf="!notification.notificationData.item.teamId && notification.targetType === 'shareable_filter'">{{
      "user_shared_okr_view_with_you" | localize: { names: notification.notificationData.actor.names }
    }}</span>
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiType]="iconType" [uiTooltipTitle]="iconType | localize" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.resourceName }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section" *ngIf="notification.notificationData.item.teamId">
        <div class="heading inline-notifications">
          {{ "shared_with" | localize }} : <span class="team-name">{{ teamName }}</span>
        </div>
      </div>
      <div class="inbox-section">
        <a class="link classic" *ngIf="notification.targetType === 'whiteboard'" [attr.href]="notification.notificationData.url">{{ "view_whiteboard" | localize }}</a>
        <a class="link classic" *ngIf="notification.targetType === 'list'" [attr.href]="notification.notificationData.url">{{ "view_report" | localize }}</a>
        <a class="link classic" *ngIf="notification.targetType === 'shareable_filter'" [attr.href]="notification.notificationData.url">{{ "view_okr_view" | localize }}</a>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="notification.operationType === 'share' && (notification.targetType === 'session' || notification.targetType === 'dashboard' || notification.targetType === 'kpi')">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    <span *ngIf="notification.notificationData.item.teamId && notification.targetType === 'session'">{{
      "user_shared_session_with_your_team" | localize: { names: notification.notificationData.actor.names }
    }}</span>
    <span *ngIf="!notification.notificationData.item.teamId && notification.targetType === 'session'">{{
      "user_shared_session_with_you" | localize: { names: notification.notificationData.actor.names }
    }}</span>
    <span *ngIf="notification.notificationData.item.teamId && notification.targetType === 'dashboard'">{{
      "user_shared_insightboard_with_your_team" | localize: { names: notification.notificationData.actor.names }
    }}</span>
    <span *ngIf="!notification.notificationData.item.teamId && notification.targetType === 'dashboard'">{{
      "user_shared_insightboard_with_you" | localize: { names: notification.notificationData.actor.names }
    }}</span>
    <span *ngIf="notification.notificationData.item.teamId && notification.targetType === 'kpi'">{{
      "user_shared_kpi_with_your_team" | localize: { names: notification.notificationData.actor.names }
    }}</span>
    <span *ngIf="!notification.notificationData.item.teamId && notification.targetType === 'kpi'">{{
      "user_shared_kpi_with_you" | localize: { names: notification.notificationData.actor.names }
    }}</span>
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiType]="iconType" [uiTooltipTitle]="iconType | localize" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.resourceName }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section" *ngIf="notification.notificationData.item.teamId">
        <div class="heading inline-notifications">
          {{ "shared_with" | localize }} : <span class="team-name">{{ teamName }}</span>
        </div>
      </div>
      <div class="inbox-section">
        <a class="link classic" *ngIf="notification.targetType === 'session'" [attr.href]="notification.notificationData.url">{{ "view_session" | localize }}</a>
        <a class="link classic" *ngIf="notification.targetType === 'dashboard'" [attr.href]="notification.notificationData.url">{{ "view_insightboard" | localize }}</a>
        <a class="link classic" *ngIf="notification.targetType === 'kpi'" [attr.href]="notification.notificationData.url">{{ "view_kpi" | localize }}</a>
      </div>
    </div>
  </div>
</ng-container>
