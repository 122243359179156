import { Pipe, PipeTransform } from "@angular/core";
import { EffectivenessLowToHighScaleIcon, LowToHighScaleType } from "../models/quantive-plus-effectiveness.models";
import { lowToHighScaleEffectivenessToIcon } from "../utils/quantive-plus-effectiveness-adapter.utils";

@Pipe({ name: "lowToHighScaleToIcon" })
export class LowToHighScaleEffectivenessToIconPipe implements PipeTransform {
  public transform(effectivenessLevel: LowToHighScaleType): EffectivenessLowToHighScaleIcon {
    return lowToHighScaleEffectivenessToIcon(effectivenessLevel);
  }
}
