import { UiIconSize, UiThemeType } from "@quantive/ui-kit/icon";
import { Observable } from "rxjs";
import { ItemAction } from "@gtmhub/shared/components/ui/models";
import { DropdownMenuItem, IDropdownMenuItemIcon, IDropdownMenuItemToggle, IDropdownMenuItemUiIcon } from "../dropdown.models";

export class DropdownMenuItemBuilder {
  private menuItem: DropdownMenuItem;

  constructor() {
    this.reset();
  }

  public reset(): void {
    this.menuItem = {} as DropdownMenuItem;
  }

  public setKey(key: string): DropdownMenuItemBuilder {
    this.menuItem.key = key;
    return this;
  }

  public setDescriptionKey(descriptionKey: string): DropdownMenuItemBuilder {
    this.menuItem.descriptionKey = descriptionKey;
    return this;
  }

  public setTrack(trackName: string): DropdownMenuItemBuilder {
    this.menuItem.track = trackName;
    return this;
  }

  public setTrackMetaData(metaData: { [key: string]: string }): DropdownMenuItemBuilder {
    this.menuItem.trackMetaData = metaData;
    return this;
  }

  public setAction(action: ItemAction): DropdownMenuItemBuilder {
    this.menuItem.action = action;
    return this;
  }

  public setClasses(classes: string[]): DropdownMenuItemBuilder {
    this.menuItem.classes = classes;
    return this;
  }

  public setUiType(
    option:
      | { uiType: "toggle"; value: boolean }
      | { uiType: "icon"; icon: string }
      | { uiType: "uiIcon"; iconType: string; iconTheme: UiThemeType; uiSize?: UiIconSize }
      | { uiType: "button" }
  ): DropdownMenuItemBuilder {
    switch (option.uiType) {
      case "toggle":
        (this.menuItem as IDropdownMenuItemToggle).value = option.value;
        this.menuItem.uiType = "toggle";
        break;
      case "icon":
        (this.menuItem as IDropdownMenuItemIcon).icon = option.icon;
        this.menuItem.uiType = "icon";
        break;
      case "uiIcon":
        (this.menuItem as IDropdownMenuItemUiIcon).iconType = option.iconType;
        (this.menuItem as IDropdownMenuItemUiIcon).iconTheme = option.iconTheme;
        (this.menuItem as IDropdownMenuItemUiIcon).iconSize = option.uiSize;
        this.menuItem.uiType = "uiIcon";
        break;
      case "button":
        this.menuItem.uiType = "button";
        break;
    }
    return this;
  }

  public setToBeEndOfSection(): DropdownMenuItemBuilder {
    this.menuItem.endOfSection = true;
    return this;
  }

  public setEndOfSection(endOfSectionValue: boolean): DropdownMenuItemBuilder {
    this.menuItem.endOfSection = endOfSectionValue;
    return this;
  }

  public setToBeStartOfSection(): DropdownMenuItemBuilder {
    this.menuItem.startOfSection = true;
    return this;
  }

  public setToBeDestructive(): DropdownMenuItemBuilder {
    this.menuItem.isDestructive = true;
    return this;
  }

  public setToBeInfoTextOnly(): DropdownMenuItemBuilder {
    this.menuItem.isInfoTextOnly = true;
    return this;
  }

  public setIsVisibleCondition(isVisible$: Observable<boolean>): DropdownMenuItemBuilder {
    this.menuItem.isVisible$ = isVisible$;
    return this;
  }

  public setDisabledState(disabled: boolean): DropdownMenuItemBuilder {
    this.menuItem.disabled = disabled;
    return this;
  }

  public setSkipFromTabOrder(): DropdownMenuItemBuilder {
    this.menuItem.skipFromTabOrder = true;
    return this;
  }

  public setExternalLink(): DropdownMenuItemBuilder {
    this.menuItem.externalLink = true;
    return this;
  }

  public setDataTestId(dataTestId: string): DropdownMenuItemBuilder {
    this.menuItem.dataTestId = dataTestId;
    return this;
  }

  public addSubitems(subitems: DropdownMenuItem[]): DropdownMenuItemBuilder {
    this.menuItem.subitems = subitems;
    return this;
  }

  public build(): DropdownMenuItem {
    const result = this.menuItem;
    this.reset();
    return result;
  }

  public setA11yDescriptionKey(description: string): DropdownMenuItemBuilder {
    this.menuItem.a11yDescription = description;
    return this;
  }

  public setSelected(): DropdownMenuItemBuilder {
    this.menuItem.selected = true;
    return this;
  }
}
