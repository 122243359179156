import { UIRouterModule } from "@uirouter/angular";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiAccessibilityModule } from "@quantive/ui-kit/accessibility";
import { UiAlertModule } from "@quantive/ui-kit/alert";
import { UiAvatarModule } from "@quantive/ui-kit/avatar";
import { UiBadgeModule } from "@quantive/ui-kit/badge";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiDropdownModule } from "@quantive/ui-kit/dropdown";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiLabelModule } from "@quantive/ui-kit/label";
import { UiSkeletonModule } from "@quantive/ui-kit/skeleton";
import { UiToggleModule } from "@quantive/ui-kit/toggle";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { AccountServiceProvider } from "@webapp/accounts/providers/account.provider";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { AuthenticationResolverServiceProvider } from "@webapp/authentication/providers/authentication-resolver.provider";
import { EmployeesRepository } from "@webapp/employees";
import { UIErrorHandlingServiceProvider } from "@webapp/error-handling/providers/ui-error-handling-service.provider";
import { FavoritesModule } from "@webapp/favorites/favorites.module";
import { FavoritesFactoryProvider } from "@webapp/favorites/favorites.provider";
import { FeatureTogglesModule } from "@webapp/feature-toggles/feature-toggles.module";
import { InsightboardApiService } from "@webapp/insightboards/services/insightboard/insightboard-api.service";
import { InsightboardRepositoryService } from "@webapp/insightboards/services/insightboard/insightboard-repository.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { MetricServiceProvider } from "@webapp/okrs/metrics/providers/metrics.provider";
import { CurrentEmployeeActionsProvider } from "@webapp/people/providers/people.provider";
import { PlanningSessionsActionsProvider, PlanningSessionsServiceProvider } from "@webapp/sessions/providers/sessions.provider";
import { CdnImgModule } from "@webapp/shared/directives/cdn-img/cdn-img.module";
import { DropdownModule } from "@webapp/shared/dropdown/dropdown.module";
import { TaskFilterServiceProvider, TasksServiceProvider } from "@webapp/tasks/providers/tasks.provider";
import { TeamsActionsProvider } from "@webapp/teams/providers/teams.provider";
import { UiAssigneeNameComponent } from "@webapp/ui/assignee/components/assignee-name/assignee-name.component";
import { UiGridModule } from "@webapp/ui/grid/grid.module";
import { UiModalModule } from "@webapp/ui/modal/modal.module";
import { WhiteboardsServiceProvider } from "@webapp/whiteboards/providers/whiteboards.provider";
import { BetaFeaturesServiceProvider } from "../feature-toggles/providers/beta-features.provider";
import { DemoBannerComponent } from "./components/demo-banner/demo-banner.component";
import { NavigationDropdownComponent } from "./components/navigation-dropdown/navigation-dropdown.component";
import { AppsDropdownComponent } from "./components/navigation/apps-dropdown/apps-dropdown.component";
import { BottomSectionNavComponent } from "./components/navigation/bottom-section-nav/bottom-section-nav.component";
import { HelpDropdownComponent } from "./components/navigation/help-dropdown/help-dropdown.component";
import { NavigationComponent } from "./components/navigation/navigation/navigation.component";
import { ProfileDropdownComponent } from "./components/navigation/profile-dropdown/profile-dropdown.component";
import { DefaultSubNavigationComponent } from "./components/navigation/sub-navigation/default-sub-navigation/default-sub-navigation.component";
import { HomeEmptySubNavigationComponent } from "./components/navigation/sub-navigation/home-sub-navigation/home-empty-container/home-empty-sub-nav.component";
import { HomeSubNavigationComponent } from "./components/navigation/sub-navigation/home-sub-navigation/home-sub-navigation.component";
import { InsightboardsSubNavigationComponent } from "./components/navigation/sub-navigation/insightboards-sub-navigation/insightboards-sub-navigation.component";
import { KpisSubNavigationComponent } from "./components/navigation/sub-navigation/kpis-sub-navigation/kpis-sub-navigation.component";
import { NavItemsListComponent } from "./components/navigation/sub-navigation/nav-items-list/nav-items-list/nav-items-list.component";
import { NavListItemContextMenuComponent } from "./components/navigation/sub-navigation/nav-items-list/nav-list-item/nav-list-item-context-menu/nav-list-item-context-menu.component";
import { NavListItemIconComponent } from "./components/navigation/sub-navigation/nav-items-list/nav-list-item/nav-list-item-icon/nav-list-item-icon.component";
import { NavListItemComponent } from "./components/navigation/sub-navigation/nav-items-list/nav-list-item/nav-list-item/nav-list-item.component";
import { PeopleSubNavigationComponent } from "./components/navigation/sub-navigation/people-sub-navigation/people-sub-navigation.component";
import { ReportsSubNavigationComponent } from "./components/navigation/sub-navigation/reports-sub-navigation/reports-sub-navigation.component";
import { SessionsSubNavigationComponent } from "./components/navigation/sub-navigation/sessions-sub-navigation/sessions-sub-navigation.component";
import { SettingsSubNavigationComponent } from "./components/navigation/sub-navigation/settings-sub-navigation/settings-sub-navigation.component";
import { TitledSubNavOptionsComponent } from "./components/navigation/sub-navigation/settings-sub-navigation/titled-sub-nav-options/titled-sub-nav-options.component";
import { SubNavOptionsComponent } from "./components/navigation/sub-navigation/sub-navigation-options/sub-navigation-options.component";
import { SubNavigationComponent } from "./components/navigation/sub-navigation/sub-navigation/sub-navigation.component";
import { CustomViewsCache } from "./components/navigation/sub-navigation/tasks-sub-navigation/services/custom-views.cache";
import { CustomViewsRepository } from "./components/navigation/sub-navigation/tasks-sub-navigation/services/custom-views.repository";
import { TasksEmptySubNavigationComponent } from "./components/navigation/sub-navigation/tasks-sub-navigation/tasks-empty-container/tasks-empty-sub-nav.component";
import { TasksSubNavigationComponent } from "./components/navigation/sub-navigation/tasks-sub-navigation/tasks-sub-navigation.component";
import { WhiteboardsSubNavigationComponent } from "./components/navigation/sub-navigation/whiteboards-sub-navigation/whiteboards-sub-navigation.component";
import { TopSectionNavComponent } from "./components/navigation/top-section-nav/top-section-nav.component";
import { ToggleNavComponent } from "./components/toggle-navigation/toggle-nav.component";
import { ViewHistoryFactoryProvider, ViewHistoryServiceProvider } from "./providers/view-history.provider";
import { BillingInfoService } from "./services/billing-info.service";
import { IsNavigationExpandedCache } from "./services/is-navigation-expanded.cache";
import { NavigationReportsCacheService } from "./services/navigation-reports-cache.service";
import { AccountOptionsVisibilityService } from "./services/settings/account-options-visibility.service";
import { IntegrationsOptionsVisibilityService } from "./services/settings/integrations-options-visibility.service";
import { SettingsSubNavStateService } from "./services/settings/settings-sub-nav-state.service";
import { SettingsVisibilityService } from "./services/settings/settings-visibility.service";
import { SystemOptionsVisibilityService } from "./services/settings/system-options-visibility.service";
import { UserOptionsVisibilityService } from "./services/settings/user-options-visibility.service";
import { RecentsModule } from "./services/uxcustomization/recents/recents.module";
import { SuggestedModule } from "./services/uxcustomization/suggested/suggested.module";
import { FavoritesPayloadBuilder } from "./utils/favorites-payload.builder";
import { SessionsSubNavigationOptions } from "./utils/sessions-sub-navigation-options";
import { ViewHistoryPayloadBuilder } from "./utils/view-history-payload.builder";

@NgModule({
  declarations: [
    NavigationComponent,
    NavListItemContextMenuComponent,
    HomeSubNavigationComponent,
    HomeEmptySubNavigationComponent,
    TasksEmptySubNavigationComponent,
    SessionsSubNavigationComponent,
    KpisSubNavigationComponent,
    TasksSubNavigationComponent,
    WhiteboardsSubNavigationComponent,
    SettingsSubNavigationComponent,
    InsightboardsSubNavigationComponent,
    TitledSubNavOptionsComponent,
    ReportsSubNavigationComponent,
    PeopleSubNavigationComponent,
    NavListItemIconComponent,
    NavigationDropdownComponent,
    HelpDropdownComponent,
    ProfileDropdownComponent,
    AppsDropdownComponent,
    DemoBannerComponent,
    TopSectionNavComponent,
    BottomSectionNavComponent,
    SubNavigationComponent,
    SubNavOptionsComponent,
    DefaultSubNavigationComponent,
    NavListItemComponent,
    NavItemsListComponent,
  ],
  imports: [
    CommonModule,
    UIRouterModule,
    DragDropModule,
    SuggestedModule,
    FavoritesModule,
    RecentsModule,
    LocalizationModule,
    DropdownModule,
    UiIconModule,
    UiTooltipModule,
    UiSkeletonModule,
    UiAvatarModule,
    UiBadgeModule,
    UiModalModule,
    UiGridModule,
    UiButtonModule,
    UiToggleModule,
    UiDropdownModule,
    CdnImgModule,
    FeatureTogglesModule,
    UiAccessibilityModule,
    UiLabelModule,
    UiAlertModule,
    ToggleNavComponent,
    UiAssigneeNameComponent,
    AssigneesModule,
  ],
  providers: [
    IsNavigationExpandedCache,
    NavigationReportsCacheService,
    SettingsVisibilityService,
    SystemOptionsVisibilityService,
    UserOptionsVisibilityService,
    SettingsSubNavStateService,
    IntegrationsOptionsVisibilityService,
    AccountOptionsVisibilityService,
    BillingInfoService,
    CustomViewsRepository,
    CustomViewsCache,
    AccountServiceProvider,
    MetricServiceProvider,
    AuthenticationResolverServiceProvider,
    BetaFeaturesServiceProvider,
    PlanningSessionsServiceProvider,
    TasksServiceProvider,
    TaskFilterServiceProvider,
    WhiteboardsServiceProvider,
    EmployeesRepository,
    ViewHistoryServiceProvider,
    ViewHistoryFactoryProvider,
    FavoritesFactoryProvider,
    UIErrorHandlingServiceProvider,
    FavoritesPayloadBuilder,
    ViewHistoryPayloadBuilder,
    SessionsSubNavigationOptions,
    CurrentEmployeeActionsProvider,
    PlanningSessionsActionsProvider,
    TeamsActionsProvider,
    InsightboardRepositoryService,
    InsightboardApiService,
  ],
})
export class NavigationModule {}
