import { IQService } from "angular";
import { ThunkAction } from "redux-thunk";
import { IUIError, createUIError } from "@gtmhub/error-handling";
import { IPlugin } from "..";
import { PluginService } from "../services/plugin.service";
import { IErrorReceivePluginsAction, IReceivePluginsAction, IRequestPluginsAction, PluginsAction } from "./models";
import { IPluginsStoreState } from "./plugin-reducer";

const requestPlugins = (): IRequestPluginsAction => ({ type: "REQUEST_PLUGINS" });
export const receivePlugins = (items: IPlugin[]): IReceivePluginsAction => ({ type: "RECEIVE_PLUGINS", items });
const requestPluginsError = (error: IUIError): IErrorReceivePluginsAction => ({ type: "ERROR_RECEIVE_PLUGINS", error });
const shouldFetchPlugins = (state: IPluginsStoreState) => !state.plugins.isFetching && !state.plugins.isFetched;

export class PluginsActions {
  public static $inject = ["PluginService", "$q"];

  constructor(
    private pluginService: PluginService,
    private $q: IQService
  ) {}

  public getPlugins(): ThunkAction<IPlugin[] | void, IPluginsStoreState, null, PluginsAction> {
    return (dispatch, getState) => {
      if (shouldFetchPlugins(getState())) {
        dispatch(requestPlugins());

        this.pluginService.getAccountPlugins().then(
          (plugins) => {
            const uiPlugins = plugins.items.filter((plugin) => plugin.type === "ui");
            const ids = uiPlugins.map((plugin) => plugin.pluginDataId);

            const promiseArr = ids.map((pluginDataId) => this.pluginService.getUIPluginById(pluginDataId));
            this.$q.all(promiseArr).then(
              (pluginsDetails) => {
                const mappedPlugins: IPlugin[] = pluginsDetails.reduce((mappedPlugins, pluginDetails) => {
                  const plugin = uiPlugins.find((p) => p.pluginDataId === pluginDetails.id);

                  if (plugin) {
                    return [...mappedPlugins, { ...plugin, uiPlugin: pluginDetails }];
                  }

                  return mappedPlugins;
                }, []);

                dispatch(receivePlugins(mappedPlugins));
              },
              (rejection) => dispatch(requestPluginsError(createUIError(rejection)))
            );
          },
          (rejection) => dispatch(requestPluginsError(createUIError(rejection)))
        );
      }
    };
  }
}
