import { IUIError } from "@gtmhub/error-handling";
import { IPlugin } from "../models";
import { PluginsAction } from "./models";

export interface IPluginsStoreState {
  plugins: IPluginsState;
}

interface IPluginsState {
  isFetched: boolean;
  isFetching: boolean;
  items: IPlugin[];
  error: IUIError;
}

export function pluginsReducer(
  state: IPluginsState = { isFetched: false, isFetching: false, items: [] as IPlugin[], error: null },
  action: PluginsAction
): IPluginsState {
  switch (action.type) {
    case "REQUEST_PLUGINS":
      return { ...state, isFetching: true, isFetched: false, error: null };
    case "RECEIVE_PLUGINS":
      return { ...state, isFetching: false, isFetched: true, items: action.items, error: null };
    case "ERROR_RECEIVE_PLUGINS":
      return { ...state, isFetching: false, isFetched: false, error: action.error };
    default: {
      return state;
    }
  }
}
