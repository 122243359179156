import { ChangeDetectionStrategy, Component, Input, TemplateRef } from "@angular/core";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";

@Component({
  selector: "div[ui-modal-description]",
  exportAs: "UiModalDescriptionBuiltin",
  templateUrl: "modal-description.component.html",
  styleUrls: ["modal-description.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NzOutletModule],
})
export class UiModalDescriptionComponent {
  @Input() public uiDescription: string | TemplateRef<unknown>;
}
