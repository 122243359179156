<ng-template #notificationContent>
  <div class="ant-notification-notice-content" role="alert">
    <div class="ant-notification-notice-with-icon">
      <span class="ant-notification-notice-icon">
        <i [uiType]="errorNotificationStyles.icon" ui-icon></i>
      </span>
      <div class="ant-notification-notice-message">{{ errorNotification.message }}</div>
    </div>
  </div>
</ng-template>

<ng-template #closeIcon>
  <i class="close-icon" ui-icon uiType="close-big"></i>
</ng-template>
