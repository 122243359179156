import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { BaseState } from "@webapp/core/abstracts/services/base-state.service";
import { HomeWidgetApiService } from "@webapp/home/services/home-widget-api.service";
import { UiDashboardWidget, WidgetPutDTO, WidgetResponseDTO } from "@webapp/ui/dashboard/dashboard.models";

@Injectable({
  providedIn: "any",
})
export class HomeWidgetFacade extends BaseFacade<UiDashboardWidget[], WidgetPutDTO, BaseState, HomeWidgetApiService> {
  constructor(state: BaseState, api: HomeWidgetApiService) {
    super(state, api);
  }

  public getWidgets$(): Observable<WidgetResponseDTO> {
    return this.apiService.getAll$(null, {
      ...new RequestConfig(),
      url: this.apiService.getWidgetPath(HttpActions.get),
    });
  }

  public updateWidget$(widget: WidgetPutDTO): Observable<void> {
    return this.apiService.put$(null, widget, {
      ...new RequestConfig(),
      url: this.apiService.getWidgetPath(HttpActions.put),
    });
  }
}
