export interface Gif {
  id: string;
  searchQuery?: string;
  url?: string;
}

interface GifMedia {
  dims: [number, number];
  size: number;
  url: string;
}

export enum SizeOfGif {
  Small = "nanogif",
  Medium = "tinygif",
  Big = "gif",
}

export interface TenorResponse {
  results: GifResponse[];
}

export interface GifResponse {
  id: string;
  media: GifMedia;
}

export type GifType = "trending" | "search" | "gifs";

export type GifSize = `${SizeOfGif}`;

export type GifDataParams = { gifType: GifType; gifSize: GifSize; searchQuery?: string; gifId?: string };
