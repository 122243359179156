import { ApiVersionOption } from "@webapp/core/abstracts/enums/api-versions.enum";

export class ApiVersionModel {
  public getAll: ApiVersionOption;
  public get: ApiVersionOption;
  public post: ApiVersionOption;
  public put: ApiVersionOption;
  public patch: ApiVersionOption;
  public delete: ApiVersionOption;

  constructor(baseVersion: ApiVersionOption) {
    this.getAll = this.get = this.post = this.put = this.patch = this.delete = baseVersion;
  }
}
