<ui-assignee-avatar
  class="ui-group-assignee"
  *ngFor="let component of visibleComponents; let i = index"
  [style.z-index]="i"
  [uiAlt]="component.uiAssignee?.name"
  [uiAssignee]="component.uiAssignee"
  [uiSize]="uiSize"
  [uiTooltipTitle]="uiAssigneeTooltipTitle ? uiAssigneeTooltipTitle(component, i) : null"
  [uiTooltipPlacement]="uiAssigneeTooltipPlacement"
  [uiShowTooltip]="true"></ui-assignee-avatar>

<ui-avatar
  class="ui-group-assignee"
  *ngIf="hiddenComponents.length > 0"
  [uiText]="'+' + hiddenComponents.length"
  [style.z-index]="visibleComponents.length"
  [uiTooltipTitle]="avatarGroupTooltipTemplate"
  [uiSize]="uiSize"
  ui-tooltip
  uiTooltipPlacement="bottom">
</ui-avatar>

<ng-template #avatarGroupTooltipTemplate>
  <div class="ui-assignee-avatar-group-tooltip-wrapper">
    <ui-assignee *ngFor="let component of hiddenComponents" [uiAssignee]="component.uiAssignee" [uiNameEllipsis]="true" [uiNameMultiline]="true" uiSize="sm"></ui-assignee>
  </div>
</ng-template>
