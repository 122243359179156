import { module } from "angular";
import { NotificationsInboxService } from "./controllers/notifications-inbox";
import { NotificationsService } from "./services/notifications.service";

const mod = module("notifications-core", []);

mod.service("NotificationsService", NotificationsService);
mod.service("NotificationsInboxService", NotificationsInboxService);

export default mod.name;
