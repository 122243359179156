import { HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, noop, shareReplay, switchMap, take } from "rxjs";
import { storage } from "@gtmhub/core/storage";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { GTMHUB_ADDITIONAL_PARAMS } from "@webapp/core/http/interceptors/track-data.interceptor";
import { cacheOrGet, getAndCache } from "@webapp/core/state-management/state-management.utils";
import { Account, UpdateAccount } from "../models/accounts.models";
import { AccountsApiService } from "./accounts-api.service";
import { CurrentAccountCache, currentAccountCache } from "./current-account-cache";

const currentAccountId = (): string => storage.get<string>("accountId");

@Injectable({ providedIn: "root" })
export class CurrentAccountRepository {
  private cache: CurrentAccountCache;
  private apiGetObservable$: Observable<Account>;

  constructor(private api: AccountsApiService) {
    this.cache = currentAccountCache;
  }

  public getAndCache$(): Observable<Account> {
    return getAndCache(this.cache, this.apiGet$());
  }

  public get$(): Observable<Account> {
    if (!this.apiGetObservable$) {
      this.apiGetObservable$ = this.apiGet$();
    }
    return cacheOrGet(this.cache, this.apiGetObservable$);
  }

  public update$(account: Partial<UpdateAccount>, options?: { shouldTryGeneratingDomainFromAccountName?: boolean; sendNotification?: boolean }): Observable<void> {
    return this.api
      .patch$<void>(currentAccountId(), account, {
        ...new RequestConfig(),
        queryParams: options,
      })
      .pipe(
        switchMap(() => this.getAndCache$()),
        map(noop)
      );
  }

  public updateSettings$(settings: object): Observable<void> {
    return this.api.putCurrentAccountSettings$(settings).pipe(
      switchMap(() => this.cache.get$()),
      take(1),
      map((account) => {
        if (account) {
          this.cache.set({ ...account, settings: JSON.stringify(settings) });
        }
      })
    );
  }

  public emptyCache(): void {
    this.cache.empty();
  }

  private apiGet$(): Observable<Account> {
    const id = currentAccountId();
    return this.api
      .get$(id, {
        ...new RequestConfig(),
        context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, { id }),
      })
      .pipe(shareReplay(1));
  }
}
