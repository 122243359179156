import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { byDateFieldDesc } from "@gtmhub/util/sorting-utils";
import { UXCustomizationType } from "@gtmhub/uxcustomization/models";
import { ICollection } from "@webapp/core/core.models";
import { INavItem, NavItemsType } from "@webapp/navigation/models/nav-items-list.models";
import { SubNavItemsModel, getNavItemsType, navItemsFactory } from "@webapp/navigation/utils/nav-items.util";
import { Session } from "@webapp/sessions/models/sessions.model";
import { SuggestedModel, SuggestedModelKeys } from "./suggested.models";
import { SuggestedRepository } from "./suggested.repository";

@Injectable()
export class SuggestedNavItemFacade {
  constructor(private repository: SuggestedRepository) {}

  public getSuggestedSessions$(): Observable<ICollection<INavItem>> {
    return this.repository.get$(SuggestedModelKeys.Sessions, byDateFieldDesc<Session>("dateCreated")).pipe(
      map((sessions) => ({
        items: sessions.map((s) => navItemsFactory.buildSessionNavItem(s)),
        totalCount: sessions.length,
      }))
    );
  }

  public getSuggestedLists$(): Observable<ICollection<INavItem>> {
    return this.repository.get$(SuggestedModelKeys.Lists).pipe(
      map((lists) => ({
        items: lists.map((s) => navItemsFactory.buildListNavItem(s)),
        totalCount: lists.length,
      }))
    );
  }

  public getSuggestedDashboards$(): Observable<ICollection<INavItem>> {
    return this.repository.get$(SuggestedModelKeys.Dashboards).pipe(
      map((dashboards) => ({
        items: dashboards.map((s) => navItemsFactory.buildInsightboardNavItem(s)),
        totalCount: dashboards.length,
      }))
    );
  }

  public getSuggestedWhiteboards$(): Observable<ICollection<INavItem>> {
    return this.repository.get$(SuggestedModelKeys.Whiteboards).pipe(
      map((whiteboards) => ({
        items: whiteboards.map((s) => navItemsFactory.buildWhiteboardNavItem(s)),
        totalCount: whiteboards.length,
      }))
    );
  }

  public getSuggestedKpiGroups$(): Observable<ICollection<INavItem>> {
    return this.repository.get$(SuggestedModelKeys.KpiGroups).pipe(
      map((whiteboards) => ({
        items: whiteboards.map((s) => navItemsFactory.buildKpiGroupNavItem(s)),
        totalCount: whiteboards.length,
      }))
    );
  }

  public getSuggestedTeams$(): Observable<ICollection<INavItem>> {
    return this.repository.get$(SuggestedModelKeys.Teams).pipe(
      map((teams) => ({
        items: teams.filter((team) => team.isActive === true).map((s) => navItemsFactory.buildTeamNavItem(s)),
        totalCount: teams.length,
      }))
    );
  }

  public blacklistItem(id: string, type: UXCustomizationType): Observable<SuggestedModel> {
    return this.repository.blacklistItem(id, type);
  }

  private getSuggestedType(type: NavItemsType): SuggestedModelKeys {
    const navItemsTypeToSuggestedTypes: { [key in NavItemsType]?: SuggestedModelKeys } = {
      sessions: SuggestedModelKeys.Sessions,
      insightboards: SuggestedModelKeys.Dashboards,
      people: SuggestedModelKeys.Teams,
      kpis: SuggestedModelKeys.KpiGroups,
      whiteboards: SuggestedModelKeys.Whiteboards,
      lists: SuggestedModelKeys.Lists,
    };
    return navItemsTypeToSuggestedTypes[type];
  }

  public updateItem(uxcType: UXCustomizationType, item: SubNavItemsModel): void {
    const suggestedType: keyof SuggestedModel = this.getSuggestedType(getNavItemsType(uxcType));
    if (!suggestedType) return;
    this.repository.updateItem(suggestedType as SuggestedModelKeys, item);
  }

  public deleteItem(uxcType: UXCustomizationType, id: string): void {
    const suggestedType: keyof SuggestedModel = this.getSuggestedType(getNavItemsType(uxcType));
    if (!suggestedType) return;
    this.repository.deleteItem(suggestedType as SuggestedModelKeys, id);
  }
  public isLoaded$(): Observable<boolean> {
    return this.repository.isLoaded$();
  }
}
