export const DEFAULT_KENDO_ICON_OVERRIDES = new Map<string, string>([
  ["caret-alt-up", "chevron-up-small-o"],
  ["caret-alt-down", "chevron-down-small-o"],
  ["caret-alt-right", "chevron-right-small-o"],
  ["caret-alt-left", "chevron-left-small-o"],
  ["caret-alt-to-right", "chevron-right-double-o"],
  ["caret-alt-to-left", "chevron-left-double-o"],
  ["more-vertical", "menu-vertical-o"],
  ["columns", "table-o"],
  ["sort-desc-small", "down-o"],
  ["sort-asc-small", "up-o"],
]);
