import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { IBaseRepository } from "@webapp/core/state-management/models/base-repository.model";
import { AiRecommendation } from "../../models/ai-recommendations.models";
import { AiRecommendationsApiService } from "./ai-recommendations-api.service";

@Injectable({
  providedIn: "root",
})
export class AiRecommendationRepository implements Pick<IBaseRepository<AiRecommendation>, "patch$"> {
  constructor(private aiRecommendationsApiService: AiRecommendationsApiService) {}

  public patch$({ id, patchModel, config }: { id: string; patchModel: Partial<AiRecommendation>; config?: RequestConfig }): Observable<AiRecommendation> {
    return this.aiRecommendationsApiService.patch$(id, patchModel, config);
  }
}
