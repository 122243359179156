import { NgModule } from "@angular/core";
import { FeatureTogglesModule } from "@webapp/feature-toggles/feature-toggles.module";
import { CdnImgDirective } from "@webapp/shared/directives/cdn-img/cdn-img.directive";

@NgModule({
  imports: [FeatureTogglesModule],
  declarations: [CdnImgDirective],
  exports: [CdnImgDirective],
})
export class CdnImgModule {}
