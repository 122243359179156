import { IPromise, IQService, jwt } from "angular";
import jwtDecode from "jwt-decode";
import { AuthenticationResolverService, getToken } from "@gtmhub/auth";

export class TokenProvider {
  public static $inject = ["$q", "jwtHelper", "AuthenticationResolverService"];

  constructor(
    private $q: IQService,
    private jwtHelper: jwt.IJwtHelper,
    private authenticationResolverService: AuthenticationResolverService
  ) {}

  public getValidToken(): IPromise<string> {
    const token = getToken();

    if (!token) {
      return this.$q.reject({
        error: "No token to refresh!",
        expiredToken: null,
      });
    }

    const decodedToken = jwtDecode<{ iat: number; exp: number }>(token);

    // If token is expired, wait for a new one
    if (this.jwtHelper.isTokenExpired(token)) {
      return this.authenticationResolverService
        .refreshToken({ blocking: true })
        .then((newToken) => this.$q.resolve(newToken))
        .catch((err) => {
          return this.$q.reject({
            error: err,
            expiredToken: token,
          });
        });
    }

    // If token is at half its duration, refresh in the background
    const tokenHalfTimeExpiration = (decodedToken.exp - decodedToken.iat) / 2;
    if (this.jwtHelper.isTokenExpired(token, tokenHalfTimeExpiration)) {
      this.authenticationResolverService.refreshToken({ blocking: false }).catch(() => null);
    }

    return this.$q.resolve(token);
  }
}
