// suggested escaping RegExp from MDN docs:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
function escapeRegExp(text: string) {
  return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export function bolder() {
  return function (text: string, phrase: string) {
    return phrase ? text.replace(new RegExp(`(^|\\s)(${escapeRegExp(phrase)})`, "gi"), "$1<span class='bolder'>$2</span>") : text;
  };
}
