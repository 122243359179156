<ng-container *ngIf="goal">
  <suggestion-drawer-card *ngIf="visibleSections.indexOf('description') > -1 && shouldSuggestQuantivePlusDescription && !goal.description" [heading]="'description' | localize">
    <suggestion-drawer-description [statePropagator]="statePropagator" [goal]="goal"></suggestion-drawer-description>
  </suggestion-drawer-card>

  <suggestion-drawer-card *ngIf="visibleSections.indexOf('tags') > -1 && shouldSuggestQuantivePlusTags && goal.tags.length === 0" [heading]="'tags' | localize">
    <suggestion-drawer-tags [statePropagator]="statePropagator" [goal]="goal"></suggestion-drawer-tags>
  </suggestion-drawer-card>

  <suggestion-drawer-card *ngIf="visibleSections.indexOf('keyResults') > -1 && shouldSuggestQuantivePlusMetrics" [heading]="'key_results' | localize">
    <suggestion-drawer-key-results [statePropagator]="statePropagator" [status]="metricsSuggestionStatus" [goal]="goal"></suggestion-drawer-key-results>
  </suggestion-drawer-card>

  <suggestion-drawer-card *ngIf="taskList && visibleSections.indexOf('tasks') > -1 && shouldSuggestQuantivePlusTasks" [heading]="'tasks' | localize">
    <suggestion-drawer-tasks [flowName]="flowName" [suggestions]="suggestions" [taskList]="taskList" [status]="tasksSuggestionStatus" [parentType]="parentType" [item]="goal">
    </suggestion-drawer-tasks>
  </suggestion-drawer-card>
</ng-container>

<ng-container *ngIf="metric">
  <suggestion-drawer-card *ngIf="taskList && visibleSections.indexOf('tasks') > -1" [heading]="'tasks' | localize">
    <suggestion-drawer-tasks [flowName]="flowName" [suggestions]="suggestions" [taskList]="taskList" [status]="tasksSuggestionStatus" [parentType]="parentType" [item]="metric">
    </suggestion-drawer-tasks>
  </suggestion-drawer-card>
</ng-container>
