import { IFilterService } from "angular";
import { localize } from "@gtmhub/localization";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import dayjs from "@webapp/shared/libs/dayjs";
import { MetricValueFilter } from "./metric-value.filter";

const maxFractionSize = 3;

const formatDate = (dueDate: string): string => dayjs(dueDate).format("DD MMMM, YYYY");

function displayCriticalTarget(kr: Metric): boolean {
  // the conditions for targetOperators may be needed
  // because when changing the targetOperator of anti-target metrics with critical targets patch method may has kept their critical value
  // kr critical could potentially be 0, so check explicitly for null or undefined
  if (typeof kr.critical === "undefined" || kr.critical === null || kr.targetOperator === "at_least" || kr.targetOperator === "at_most") {
    return false;
  }

  return true;
}

function getTargetOperators(key: string): string {
  return {
    at_least: `${localize("should_increase_to")} `,
    at_most: `${localize("should_decrease_to")} `,
    should_stay_above: `${localize("should_stay_above")} `,
    should_stay_below: `${localize("should_stay_below")} `,
    boolean: `${localize("achieved_or_not")} `,
  }[key];
}

function getDeadlinesMap(key: string): string {
  return {
    hardDeadlinePre: `${localize("by")} `,
    softDeadLinePre: `, ${localize("the_soft_deadline_is")} `,
    1: ` ${localize("day")}`,
    days: ` ${localize("days")}`,
    left: ` ${localize("left")}`,
    ago: ` ${localize("ago")}`,
  }[key];
}

function getCriticalOperators(key: string): string {
  return {
    should_stay_above: ` ${localize("and_abs_must_above")} `,
    should_stay_below: ` ${localize("and_abs_must_below")} `,
  }[key];
}

const targetValueCase = ($filter: IFilterService, metric: Metric, additionalInfo?: string) => {
  if (metric.manualType === "boolean") {
    return localize("should_be_achieved");
  }

  return $filter<MetricValueFilter>("metricValue")(metric.target, additionalInfo === "formatted" ? metric.format : null, { maxFractionSize });
};

const initialCase = ($filter: IFilterService, metric: Metric, additionalInfo?: string) => {
  if (metric.manualType === "boolean") {
    return localize("not_achieved");
  }

  return $filter<MetricValueFilter>("metricValue")(metric.initialValue, additionalInfo === "formatted" ? metric.format : null, {
    maxFractionSize,
  });
};

const actualCase = ($filter: IFilterService, metric: Metric, additionalInfo?: string) => {
  if (metric.manualType === "boolean") {
    return metric.actual === metric.target ? localize("achieved") : localize("not_achieved_yet");
  }

  return $filter<MetricValueFilter>("metricValue")(metric.actual, additionalInfo === "formatted" ? metric.format : null, { maxFractionSize });
};

const criticalCase = ($filter: IFilterService, metric: Metric, additionalInfo?: string) => {
  if (metric.manualType === "boolean") {
    return;
  }

  return $filter<MetricValueFilter>("metricValue")(metric.critical, additionalInfo === "formatted" ? metric.format : null, { maxFractionSize });
};

const dueDatesCase = (metric: Metric, daysLeftText: string, hardDeadlineText: string) => {
  if (!metric.percentTime) {
    return "";
  }

  if (metric.daysLeft > 0) {
    daysLeftText += getDeadlinesMap("left");
  } else if (metric.daysLeft < 0) {
    daysLeftText += getDeadlinesMap("ago");
  }

  if (metric.percentTimeToSoftDeadline) {
    const softDeadLineText = getDeadlinesMap("softDeadLinePre") + formatDate(metric.softDueDate);

    return hardDeadlineText + daysLeftText + softDeadLineText;
  }

  return hardDeadlineText + daysLeftText;
};

const targetsCase = ($filter: IFilterService, metric: Metric, additionalInfo: string, targetOperatorText: string, targetText: string) => {
  if (metric.manualType === "boolean") {
    if (additionalInfo === "goal-view") {
      const boolTargetText = metric.actual === metric.target ? "outcome_achieved" : "did_not_achieve_desired_outcome_yet";

      return localize(boolTargetText);
    }

    if (additionalInfo === "update-metric") {
      return `${localize("desired_outcome_should_be_achieved")} (100%)`;
    }

    return targetOperatorText;
  }

  if (!displayCriticalTarget(metric)) {
    return targetText;
  }

  return targetText + getCriticalOperators(metric.targetOperator) + $filter<MetricValueFilter>("metricValue")(metric.critical, metric.format, { maxFractionSize });
};

export interface IMetricFilter {
  (metric: Metric, detailType: string, additionalInfo?: string, withoutRounding?: boolean): string;
}

export const metric = [
  "$filter",
  ($filter: IFilterService): IMetricFilter => {
    // eslint-disable-next-line @foxglove/no-boolean-parameters
    return function (metric: Metric, detailType: string, additionalInfo?: string, withoutRounding?: boolean): string {
      if (!metric || !detailType) {
        return "";
      }

      const targetOperatorText = metric.manualType === "boolean" ? getTargetOperators("boolean") : getTargetOperators(metric.targetOperator);

      const hardDeadlineText = getDeadlinesMap("hardDeadlinePre") + formatDate(metric.dueDate) + ", ";

      const daysLeft = Math.abs(metric.daysLeft).toFixed(0);

      const daysLeftText = getDeadlinesMap(daysLeft) ? daysLeft + getDeadlinesMap(daysLeft) : daysLeft + getDeadlinesMap("days");

      const targetText =
        targetOperatorText +
        $filter<MetricValueFilter>("metricValue")(metric.target, metric.format, {
          maxFractionSize,
          withoutRounding,
        });

      switch (detailType) {
        case "targetValue":
          return targetValueCase($filter, metric, additionalInfo);
        case "initial":
          return initialCase($filter, metric, additionalInfo);
        case "actual":
          return actualCase($filter, metric, additionalInfo);
        case "critical":
          return criticalCase($filter, metric, additionalInfo);
        case "dueDates":
          return dueDatesCase(metric, daysLeftText, hardDeadlineText);
        case "operator":
          return targetOperatorText;
        case "targets":
          return targetsCase($filter, metric, additionalInfo, targetOperatorText, targetText);
        default:
          return "";
      }
    };
  },
];
