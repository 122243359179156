import { IHttpService } from "angular";
import { ThunkAction } from "redux-thunk";
import { ApmSpanService } from "@gtmhub/core/tracing/apm-span.service";
import { ICurrentEmployeeStoreState } from "@gtmhub/employees";
import { EnvironmentService } from "@gtmhub/env";
import { IUIError, createUIError } from "@gtmhub/error-handling";
import { ISocketV2Data } from "@gtmhub/sockets";
import { ITeam } from "@gtmhub/teams";
import { getCurrentUserId } from "@gtmhub/users";
import { Employee, EmployeeDetails } from "@webapp/employees";
import { applyChangesToEmployeeTeams } from "../util";
import {
  CurrentEmployeeAction,
  IErrorReceiveCurrentEmployeeAction,
  IReceiveCurrentEmployeeAction,
  IRequestCurrentEmployeeAction,
  IUpdateCurrentEmployeeAction,
} from "./models";

const requestCurrentEmployee = (): IRequestCurrentEmployeeAction => ({ type: "REQUEST_CURRENT_EMPLOYEE" });
export const receiveCurrentEmployee = (info: Employee): IReceiveCurrentEmployeeAction => ({ type: "RECEIVE_CURRENT_EMPLOYEE", info });
const requestCurrentEmployeeError = (error: IUIError): IErrorReceiveCurrentEmployeeAction => ({ type: "ERROR_RECEIVE_CURRENT_EMPLOYEE", error });
const updateCurrentEmployee = (employee: Employee): IUpdateCurrentEmployeeAction => ({ type: "UPDATE_CURRENT_EMPLOYEE", employee });
const shouldFetchCurrentEmployee = (state: ICurrentEmployeeStoreState) => !state.currentEmployee.isFetching && !state.currentEmployee.isFetched;

export class CurrentEmployeeActions {
  public static $inject = ["$http", "EnvironmentService", "ApmSpanService"];

  constructor(
    private $http: IHttpService,
    private env: EnvironmentService,
    private apmSpanService: ApmSpanService
  ) {}

  public updateCurrentEmployee(employee: Employee): IUpdateCurrentEmployeeAction {
    return updateCurrentEmployee(employee);
  }

  public updateCurrentEmployeeTeams(teamsUpdates: ISocketV2Data<ITeam>): (dispatch, getState) => void {
    return (dispatch, getState) => {
      const currentEmployee = getState().currentEmployee.info;
      const { teamIds, managedTeamIds } = applyChangesToEmployeeTeams(currentEmployee, teamsUpdates);
      const employee: Partial<Employee> = {
        managedTeamIds,
        teamIds,
      };

      dispatch(updateCurrentEmployee(employee));
    };
  }

  public fetchCurrentEmployeeIfMissing(): ThunkAction<void, ICurrentEmployeeStoreState, null, CurrentEmployeeAction> {
    return (dispatch, getState) => {
      if (shouldFetchCurrentEmployee(getState())) {
        dispatch(requestCurrentEmployee());
        const currentEmployeeId = getCurrentUserId();
        const url = this.env.getApiEndpoint("/employees/" + currentEmployeeId);

        this.apmSpanService.dataLoadSpan("employee-load", () => {
          return this.$http.get<EmployeeDetails>(url).then(
            (response) => dispatch(receiveCurrentEmployee(response.data.info)),
            (rejection) => dispatch(requestCurrentEmployeeError(createUIError(rejection)))
          );
        });
      }
    };
  }
}
