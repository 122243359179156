import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from "@angular/core";
import { PeopleSelectorRequest } from "@webapp/shared/components/people-selector/models/models";
import { FormItemBaseComponent } from "../form-item-base.component";

const FORM_ITEM_BASE_PROVIDER = {
  provide: FormItemBaseComponent,
  useExisting: forwardRef(() => AssigneeFormItemComponent),
};

@Component({
  selector: "assignee-form-item",
  templateUrl: "./assignee-form-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FORM_ITEM_BASE_PROVIDER],
})
export class AssigneeFormItemComponent extends FormItemBaseComponent<string[]> {
  @Input() public request: PeopleSelectorRequest;
  @Input() public mode: "default" | "multiple";
  @Input() public allowViewOnlyUsersForSelection = false;
  @Input() public atLeastOneValue: boolean;
  @Input() public allowClear: boolean;

  @Output()
  public readonly inputFocus: EventEmitter<void> = new EventEmitter();

  @Output()
  public readonly openChange: EventEmitter<boolean> = new EventEmitter();

  @Output()
  public readonly selectionChange: EventEmitter<string[]> = new EventEmitter();
}
