import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { IIndicator } from "@gtmhub/error-handling";
import { Insight, InsightSelectorData } from "@webapp/data-story/models/insights.models";
import { POWER_BI_CONNECTION_NAME } from "@webapp/data-story/utils/insights";
import { KpiAutomationData } from "@webapp/kpis/models/kpis.models";

@Component({
  selector: "selected-automation",
  templateUrl: "./selected-automation.component.html",
  styleUrls: ["./selected-automation.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedAutomationComponent {
  @Input() public data: InsightSelectorData;
  @Input() public kpiData: KpiAutomationData;
  @Input() public confirmedInsight: Insight;
  @Input() public readonly: boolean;
  @Input() public isEditable: boolean;
  @Input() public disabledStyle: boolean;
  @Input() public dataSourceType: string;
  @Input() public indicators: { loading?: IIndicator; loadingParams?: IIndicator };
  @Input() public isRestricted: boolean;

  @Output() public readonly editFilter = new EventEmitter<void>();
  @Output() public readonly removeItem = new EventEmitter<void>();
  @Output() public readonly goToInsight = new EventEmitter<void>();
  @Output() public readonly openInsight = new EventEmitter<void>();
  @Output() public readonly openPowerBIModal = new EventEmitter<void>();

  public onInsightClick = (): void => {
    if (!this.isEditable) {
      return;
    }

    if (this.readonly) {
      this.goToInsight.emit();
    } else if (this.dataSourceType === POWER_BI_CONNECTION_NAME) {
      this.openPowerBIModal.emit();
    } else {
      this.openInsight.emit();
    }
  };

  public onFilterClick = (): void => {
    if (!this.isEditable) {
      return;
    }

    this.editFilter.emit();
  };
}
