import { IHttpService, IPromise, IQService } from "angular";
import { AccountType } from "@gtmhub/core";
import { EnvironmentService } from "@gtmhub/env";
import { INgRedux } from "@gtmhub/state-management/ng-redux";
import { IPermissionsStoreState, hasPermissions } from "@gtmhub/users/redux";
import { IPlan } from "../models";

export class PricingEditionService {
  static $inject = ["$http", "$q", "EnvironmentService", "$ngRedux"];

  constructor(
    private $http: IHttpService,
    private $q: IQService,
    private env: EnvironmentService,
    private $ngRedux: INgRedux
  ) {}

  getAllowedTransitions(accountType: AccountType): IPromise<IPlan[]> {
    const isMissingManageBilling = !hasPermissions(this.$ngRedux.getState<IPermissionsStoreState>(), "ManageBilling");
    const isAccountNotTrialOrClientOrFree =
      accountType !== AccountType.TrialAccount && accountType !== AccountType.ClientAccount && accountType !== AccountType.FreeAccount;

    if (isMissingManageBilling || isAccountNotTrialOrClientOrFree) {
      return this.$q.resolve([]);
    }

    const url = this.env.getApiEndpoint("/subscriptions/allowed-transitions");

    return this.$http.get<IPlan[]>(url).then((response) => response.data);
  }
}
