import { IHttpService, IPromise, IQService, noop } from "angular";
import { firstValueFrom, take } from "rxjs";
import { IAccount, IAccountNotification, IConfidenceSettings } from "@gtmhub/core";
import { storage } from "@gtmhub/core/storage";
import { EnvironmentService } from "@gtmhub/env";
import { IAccountInvitationLink, IAccountSubscriptionInfo, ICheckoutPageData, ICheckoutRequest } from "@gtmhub/login/models";
import { IGtmhubRootScopeService } from "@gtmhub/models";
import { AccountResolverService } from "@webapp/accounts";
import { EditionFeature } from "@webapp/accounts/models/edition.models";
import { CurrentAccountRepository } from "@webapp/accounts/services/current-account-repository.service";
import { ConfidenceScale, ConfidenceScaleEnum, ConfidenceType, ConfidenceTypeEnum, IAccountSubscription } from "@webapp/configuration/models/configuration.model";

export interface ISharedOkrsAccountSetting {
  objectives: { disabled: boolean };
  krs: { disabled: boolean };
}

export interface IAccountsRootScopeService extends IGtmhubRootScopeService {
  confidenceScale: ConfidenceScale;
  aggregation: boolean;
  confidenceSettings: IConfidenceSettings;
}

export const MAX_VALUE_MINOR_SCALE = 1;
export const MAX_VALUE_MAJOR_SCALE = 10;

export interface IMaxConfidenceValue {
  max: 1 | 10;
}

type RelyingParties = {
  allowAccountDomainChange: boolean;
  items: [];
};

export class AccountService {
  public static $inject = ["$rootScope", "$http", "$q", "EnvironmentService", "AccountResolverService", "CurrentAccountRepository"];

  constructor(
    private $rootScope: IAccountsRootScopeService,
    private $http: IHttpService,
    private $q: IQService,
    private env: EnvironmentService,
    private accountResolverService: AccountResolverService,
    private currentAccountRepository: CurrentAccountRepository
  ) {
    $rootScope.$on("accountDataInitialized", () => {
      this.setConfidenceSettingsToRootScope();
    });
  }

  private accSubscription: IAccountSubscription;
  private gettingAccSubInProgress = false;
  private gettingAccSubFinished = false;
  private accSubPromise: IPromise<IAccountSubscription>;

  public checkForSSO(accountDomain: string): IPromise<RelyingParties> {
    const domain = this.env.constructGtmhubDomain(accountDomain, { preserveDomainForLocalhost: true, overrideDomainToQuantive: false });

    const url = this.env.getApiEndpoint("/relying-parties/") + domain;

    return this.$http.get<RelyingParties>(url).then((response) => response.data);
  }

  public checkIfActive(): IPromise<boolean> {
    const url = this.env.getApiEndpoint("/subscriptions/is_active_account");

    return this.$http.get<boolean>(url).then((response) => response.data);
  }

  public getAccountSetting<T>(key: string): T {
    if (!key) {
      throw new Error("You must provide account setting key.");
    }

    const settingsStr = storage.get("accountSettings");
    if (settingsStr) {
      const settings = typeof settingsStr === "string" ? JSON.parse(settingsStr) : settingsStr;
      return settings[key] as T;
    }

    return null;
  }

  public getFeatureStatus<T>(key: string): T {
    if (!key) {
      throw new Error("You must provide account setting key.");
    }

    const settingsStr = storage.get("accountSettings");

    if (settingsStr) {
      const settings = typeof settingsStr === "string" ? JSON.parse(settingsStr) : settingsStr;
      return settings.features?.[key] as T;
    }

    return null;
  }

  public updateNotifications(notifications: IAccountNotification): IPromise<void> {
    const url = this.env.getApiEndpoint("/accounts/" + storage.get("accountId") + "/notifications");

    return this.$http.put(url, notifications).then(() => null);
  }

  public setAccountSetting<T>(key: string, value: T, subKey = ""): IPromise<void> {
    if (!key) {
      throw new Error("You must provide account setting key.");
    }

    const accountSettingsStr = storage.get<string>("accountSettings");
    let settings = null;
    if (accountSettingsStr) {
      settings = JSON.parse(accountSettingsStr);
    }

    if (!settings) {
      settings = {
        [key]: {},
      };
    }

    if (subKey) {
      settings[key] = settings[key] || {};
      settings[key][subKey] = value;
    } else {
      settings[key] = value;
    }

    storage.set("accountSettings", JSON.stringify(settings));

    return this.$q.when(firstValueFrom(this.currentAccountRepository.updateSettings$(settings)));
  }

  public generateBasicToken(): IPromise<string> {
    const url = this.env.getApiEndpoint("/accounts/" + storage.get("accountId") + "/tokens");

    return this.$http.post<string>(url, null).then((response) => response.data);
  }

  public getGeneratedBasicToken(): IPromise<string> {
    const url = this.env.getApiEndpoint("/accounts/" + storage.get("accountId") + "/tokens");

    return this.$http.get<string>(url, null).then((response) => response.data);
  }

  public getAccount(id: string): IPromise<IAccount> {
    const url = this.env.getApiEndpoint("/accounts/" + id);

    return this.$http.get<IAccount>(url, {}).then((response) => response.data);
  }

  public getConfidenceScale(): ConfidenceScale {
    return this.getAccountSetting("confidenceScale") || ConfidenceScaleEnum.Minor;
  }

  public featureAvailable(name: EditionFeature): boolean {
    const edition = this.accountResolverService.getAccountData()?.edition;
    // backwards compatibility
    // if edition is not specified we allow everything
    if (!edition || !edition.features) {
      return true;
    }

    return edition.features.indexOf(name) !== -1;
  }

  public getCheckoutPage(checkoutRequest: ICheckoutRequest): IPromise<ICheckoutPageData> {
    const url = this.env.getApiEndpoint("/subscriptions/checkout");

    return this.$http.post<ICheckoutPageData>(url, checkoutRequest).then((response) => response.data);
  }

  public getAccountSubscriptionInfo(): IPromise<IAccountSubscriptionInfo> {
    const url = this.env.getApiEndpoint("/subscriptions/subscription_info");

    return this.$http.get<IAccountSubscriptionInfo>(url).then((response) => response.data);
  }

  public setConfidenceSettings(id: string, settings: IConfidenceSettings): IPromise<unknown> {
    const url = this.env.getApiEndpoint("/accounts/" + id + "/confidence");

    return this.$http.put(url, settings).then((response) => {
      // save new settings to rootscope
      this.$rootScope.confidenceSettings = settings;

      this.currentAccountRepository.getAndCache$().pipe(take(1)).subscribe();

      return response.data;
    });
  }

  public setConfidenceSettingsToRootScope(): void {
    if (!this.$rootScope.confidenceSettings) {
      this.$rootScope.confidenceSettings = this.accountResolverService.getAccountData().confidenceSettings;
    }

    const confidenceSettings: IConfidenceSettings = this.$rootScope.confidenceSettings;

    // if there are not confidence settings in rootScope OR
    // confidence settings are with default values set by backend <-- this one and corresponding conditions will be removed later
    if (!confidenceSettings || (confidenceSettings.confidenceType === ConfidenceTypeEnum.Emoji && confidenceSettings.confidenceMapping.length === 0)) {
      const defaults: { confidenceType: ConfidenceType; colors: Record<string, string>; ranges: { [key: string]: { from: number; to: number } } } = {
        colors: { high: "#18C191", medium: "#0057D7", low: "#ffc400" },
        ranges: {
          high: { from: 0.7, to: 1 },
          medium: { from: 0.3, to: 0.7 },
          low: { from: 0, to: 0.3 },
        },
        confidenceType: "numeric",
      };

      this.$rootScope.confidenceSettings = {
        confidenceType: defaults.confidenceType,
        confidenceMapping: [
          {
            color: defaults.colors.high,
            name: null,
            range: defaults.ranges.high,
          },
          {
            color: defaults.colors.medium,
            name: null,
            range: defaults.ranges.medium,
          },
          {
            color: defaults.colors.low,
            name: null,
            range: defaults.ranges.low,
          },
        ],
      };
    }
  }

  public getAccountSubscription(): IPromise<IAccountSubscription> {
    const url = this.env.getApiEndpoint("/accounts/subscription");

    return this.$http.get<IAccountSubscription>(url).then((response) => response.data);
  }

  public getSubscription(): IPromise<IAccountSubscription> {
    if (this.gettingAccSubFinished) {
      return this.$q.resolve(this.accSubscription);
    }

    if (this.gettingAccSubInProgress) {
      return this.accSubPromise;
    }

    if (!this.gettingAccSubFinished && !this.gettingAccSubInProgress) {
      this.gettingAccSubInProgress = true;
      this.accSubPromise = this.getAccountSubscription();
      this.accSubPromise.then(
        (subscription: IAccountSubscription) => {
          this.accSubscription = subscription;
          this.gettingAccSubInProgress = false;
          this.gettingAccSubFinished = true;
        },
        () => {
          this.gettingAccSubInProgress = false;
          this.gettingAccSubFinished = false;
        }
      );

      return this.accSubPromise;
    }
  }

  public generateInvitationLink(): IPromise<IAccountInvitationLink> {
    const accountId = this.accountResolverService.getAccountId();
    const url = this.env.getApiEndpoint(`/accounts/${accountId}/invite-link`);

    return this.$http.post<IAccountInvitationLink>(url, null).then((response) => response.data);
  }

  public getInvitationLink(): IPromise<IAccountInvitationLink> {
    const accountId = this.accountResolverService.getAccountId();
    const url = this.env.getApiEndpoint(`/accounts/${accountId}/invite-link`);

    return this.$http.get<IAccountInvitationLink>(url).then((response) => response.data);
  }

  public closeInvitationLink(): IPromise<IAccountInvitationLink> {
    const accountId = this.accountResolverService.getAccountId();
    const url = this.env.getApiEndpoint(`/accounts/${accountId}/invite-link`);

    return this.$http.patch<IAccountInvitationLink>(url, null).then((response) => response.data);
  }

  public ssoSettingsUpdatedInIdp(): IPromise<IAccount> {
    const accountId = this.accountResolverService.getAccountId();
    const url = this.env.getApiEndpoint(`/accounts/${accountId}`);

    return this.$http.patch<IAccount>(url, { useQuantiveAuth0Domain: true }).then((response) => response.data);
  }

  public checkAccountDomain(account: Partial<{ accountName: string; domain: string }>): IPromise<void> {
    const url = this.env.getApiEndpoint("/accounts/domain");

    return this.$http.post(url, account).then(noop);
  }
}
