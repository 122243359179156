<div class="no-results-container" *ngIf="(searchResults$ | async)?.length === 0 && !!(searchTerm$ | async)" data-test-id="no-search-result-element">
  <ng-container *ngIf="currentFacet === 'everywhere'; else nonEverywhereTemplate">
    <i class="sp-mb-15" ui-illustration uiType="no-results"></i>
    <div class="empty-module-title">
      {{ "no_matches_found_for" | localize }}&nbsp;
      <span class="bolder">{{ searchTerm$ | async }}.</span>
    </div>
    <div class="empty-module-suggestion fs-14px" *ngIf="searchMode !== 'simple'">{{ "try_using_another_term" | localize }}.</div>
  </ng-container>
  <ng-template #nonEverywhereTemplate>
    <div>
      <i [style]="'font-size: @font-size-lg'" [uiTooltipTitle]="facetInfo.tooltipTitle | localize" [uiType]="facetInfo.type" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <div class="empty-module-title">
      {{ "no_matches_found_for" | localize }}&nbsp;<span class="bolder">{{ searchTerm$ | async }} </span>
      <span>&nbsp;{{ "in_preposition" | localize }}&nbsp;</span>
      <span class="bolder">{{ currentFacet | localize }}.</span>
    </div>
    <div class="gh-empty-module-action">
      {{ "try_using_another_term" | localize }}&nbsp;{{ "or" | localize }}&nbsp;
      <a id="search-everywhere-instead-button" (click)="searchEverywhere(); $event.stopPropagation()" ui-button uiType="link">{{ "search_everywhere_instead" | localize }}</a>
    </div>
  </ng-template>
</div>
