import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiSkeletonModule } from "@quantive/ui-kit/skeleton";
import { IsTodoOverduePipe } from "../pipes/is-todo-overdue.pipe";
import { TodoAriaLabelPipe } from "../pipes/todo-aria-label.pipe";
import { TodoDueDatePipe } from "../pipes/todo-due-date.pipe";
import { TodoItem, TodoItemType } from "../todos-widget.models";

@Component({
  selector: "todos-widget-section",
  templateUrl: "./todos-widget-section.component.html",
  styleUrls: ["./todos-widget-section.component.less"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UiIconModule, TodoDueDatePipe, IsTodoOverduePipe, TodoAriaLabelPipe, UiSkeletonModule],
})
export class TodosWidgetSectionComponent {
  /**
   * Specifies and renders the section title text.
   */
  @Input() public sectionTitle: string;

  /**
   * Icon type for each todo row.
   */
  @Input() public iconType: TodoItemType;

  /**
   * Specifies the ID of the item that should be put in loading state.
   */
  @Input() public loadingItemId: string;

  /**
   * Different todo items have different id fields (e.g. id/teamId).
   */
  @Input() public idField = "id";

  /**
   * Different todo items have different name fields (e.g. name/teamName).
   */
  @Input() public nameField = "name";

  /**
   * Renders a row for each passed todo item.
   */
  @Input() public todos: TodoItem[] = [];

  /**
   * The cta button can be of different component type, so pass a template.
   */
  @Input() public ctaButtonTemplate: TemplateRef<object>;

  /**
   * Emits each time a todo item title is clicked.
   */
  @Output() public readonly todoLabelClick: EventEmitter<TodoItem> = new EventEmitter();

  public hasDueDate(item: TodoItem): item is TodoItem & { dueDate: string } {
    return "dueDate" in item;
  }

  // used in another directive's context so needs to be an arrow fn
  public trackTodosBy = (index: number, item: TodoItem): string => {
    return item[this.idField];
  };
}
