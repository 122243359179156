import { IPromise, IQService } from "angular";
import { StateService } from "@uirouter/angular";
import { firstValueFrom } from "rxjs";
import { UIErrorHandlingService } from "@gtmhub/error-handling";
import { MetricService } from "@gtmhub/okrs/metrics/services/metric.service";
import { ICollection } from "@webapp/core/core.models";
import { Insight } from "@webapp/data-story/models/insights.models";
import { InsightsFacade } from "@webapp/data-story/services/insights-facade/insights-facade.service";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";

interface IDispatchParams {
  itemType: string;
  itemID: string;
  target: string;
  targetID: string;
}

export class DispatchCtrl {
  public static $inject = ["$state", "$stateParams", "$q", "UIErrorHandlingService", "MetricService", "InsightsFacade"];

  constructor(
    private $state: StateService,
    private $stateParams: IDispatchParams,
    private $q: IQService,
    private uiErrorHandlingService: UIErrorHandlingService,
    private metricService: MetricService,
    private insightsFacade: InsightsFacade
  ) {
    this.redirect();
  }

  private redirect = () => {
    switch (this.$stateParams.itemType) {
      case "comment":
        this.redirectComment();
        break;
      // this seems to be no longer/not set by BE as targetType. leaving it here only because there might be some notifications containing it
      case "note":
        this.$state.go("gtmhub.home.dashboard.metric", {
          metricId: this.$stateParams.targetID,
          returnState: "gtmhub.home",
        });
        break;
      default:
        this.$state.go("gtmhub.home");
    }
  };

  private redirectComment = () => {
    switch (this.$stateParams.target) {
      case "insight": {
        this.$q
          .all({
            insightboards: firstValueFrom(this.insightsFacade.getInsightUsages$(this.$stateParams.targetID, "boards")),
            insight: firstValueFrom(this.insightsFacade.getInsightsSafe$(this.$stateParams.targetID, "name")).then((insights: ICollection<Insight>) => insights.items[0]),
          })
          .then(
            ({ insightboards, insight }) => {
              let stateName = "gtmhub.insightboards._comments.timeline";
              const stateParams = {
                insightUniqueName: insight.name,
                returnState: "gtmhub.insightboards",
              };

              if (insightboards.length) {
                stateName = "gtmhub.insightboard._comments.timeline";
                stateParams["dashboardId"] = insightboards[0].id;
                stateParams.returnState = "gtmhub.insightboard";
              }

              this.$state.go(stateName, stateParams);
            },
            (err) => this.uiErrorHandlingService.handleModal(err)
          );

        break;
      }
      case "employee":
        this.$state.go("gtmhub.employees.employee", { employeeId: this.$stateParams.targetID, returnState: "gtmhub.employees" });
        break;
      case "team":
        this.$state.go("gtmhub.teams.team", { teamId: this.$stateParams.targetID, returnState: "gtmhub.teams" });
        break;
      case "goal":
        this.$state.go("gtmhub.home.dashboard._goals.goal", { id: this.$stateParams.targetID, returnState: "gtmhub.home" });
        break;
      case "metric":
        this.$state.go("gtmhub.home.dashboard.metric", {
          metricId: this.$stateParams.targetID,
          returnState: "gtmhub.home",
        });
        break;
      case "checkin":
        this.openMetricMessageThread(this.$stateParams.targetID, this.$stateParams.target);
        break;
      case "checkinMention":
        this.openMetricMessageThread(this.$stateParams.targetID, this.$stateParams.target);
        break;
      case "task": {
        this.$state.go("gtmhub.tasks.task", { taskId: this.$stateParams.targetID, returnState: "gtmhub.tasks" });
        break;
      }
      default:
        this.$state.go("gtmhub.home");
    }
  };

  private openMetricMessageThread = (targetId: string, target: "checkin" | "checkinMention") => {
    this.getMetricByCheckin(targetId)
      .then((metric) => {
        this.$state.go("gtmhub.home.dashboard.metric.thread", {
          metricId: metric.id,
          targetType: target,
          targetId: targetId,
          returnState: "^.^",
        });
      })
      .catch((error) => this.uiErrorHandlingService.handleModal(error));
  };

  private getMetricByCheckin = (id: string): IPromise<Metric> => {
    return this.metricService.getMetricByCheckin(id).then((metric) => metric);
  };
}
