import { Pipe, PipeTransform } from "@angular/core";
import { UiI18nService } from "@quantive/ui-kit/i18n";
import { LocalizedFormat } from "@webapp/localization/models/localization.models";

const mapping: Record<LocalizedFormat, string> = {
  shortDate: "short",
  longDate: "long",
};

@Pipe({ name: "toDatefnsFormat", standalone: true })
export class ToDatefnsFormatPipe implements PipeTransform {
  constructor(private i18n: UiI18nService) {}

  public transform(input: LocalizedFormat): string {
    const locale = this.i18n.getDateLocale();
    return locale?.formatLong?.date({ width: mapping[input] || input });
  }
}
