import { IIntervalService, IPromise } from "angular";
import { IModalScope } from "angular-ui-bootstrap";
import { HttpErrorResponse } from "@angular/common/http";
import { firstValueFrom } from "rxjs";
import { IIndicator } from "@gtmhub/error-handling";
import { IGtmhubRootScopeService } from "@gtmhub/models";
import { IProfile } from "@webapp/user-profile/models/user-profile.models";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";
import { CurrentUserRepository } from "@webapp/users";

export interface IVerifyEmailRootScopeService extends IGtmhubRootScopeService {
  emailVerified: boolean;
}

interface IVerificationCode {
  a: string;
  b: string;
  c: string;
  d: string;
  e: string;
  f: string;
  g: string;
  h: string;
}

export interface IVerifyUserEmailScope extends IModalScope {
  timeLeftInSeconds: number;
  timer: IPromise<void>;
  indicators: {
    loading?: IIndicator;
    errorMessage: string;
  };
  profile: IProfile;
  verificationCode: IVerificationCode;

  verify(): void;
  keyup(event): void;
  paste(event): void;
  resend(): void;
}

export class VerifyUserEmailModalCtrl {
  public static $inject = ["$scope", "$rootScope", "UserProfileService", "CurrentUserRepository", "$interval"];

  constructor(
    private $scope: IVerifyUserEmailScope,
    private $rootScope: IVerifyEmailRootScopeService,
    private profileService: UserProfileService,
    private currentUserRepository: CurrentUserRepository,
    private interval: IIntervalService
  ) {
    this.init();
  }

  private init = (): void => {
    this.$scope.profile = this.profileService.getProfile();
    this.$scope.indicators = {
      errorMessage: "",
    };
    this.$scope.timeLeftInSeconds = 60;
    this.$rootScope.emailVerified = false;
    this.resetVerificationCodeObj();

    this.$scope.verify = this.verify;
    this.$scope.keyup = this.keyup;
    this.$scope.resend = this.resend;
    this.$scope.paste = this.paste;

    this.$scope.$on("$destroy", () => {
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      if (this.$scope.timer) {
        this.interval.cancel(this.$scope.timer);
      }
    });

    const isVerificationEmailSent = this.currentUserRepository.getUserSetting("verificationEmailSent");

    if (!isVerificationEmailSent) {
      this.sendVerificationEmail();
    }
  };

  private sendVerificationEmail = () => {
    firstValueFrom(this.currentUserRepository.verifyEmail$())
      .then(() => {
        const isVerificationEmailSent = this.currentUserRepository.getUserSetting("verificationEmailSent");

        if (!isVerificationEmailSent) {
          this.currentUserRepository.setUserSetting({ verificationEmailSent: true });
        }
      })
      .catch(({ error }: HttpErrorResponse) => {
        this.$scope.indicators.errorMessage = error;

        if (error === "The user has already verified his email") {
          this.currentUserRepository.setUserSetting({ emailVerified: true });
          this.$rootScope.emailVerified = true;
        }
      })
      .finally(() => {
        this.$scope.$evalAsync();
      });
  };

  private verify = () => {
    this.$scope.indicators.loading = { progress: true };
    let verificationCode = "";

    for (const key in this.$scope.verificationCode) {
      verificationCode += this.$scope.verificationCode[key];
    }

    firstValueFrom(this.currentUserRepository.validateEmailVerification$(verificationCode))
      .then(() => {
        this.currentUserRepository.setUserSetting({ emailVerified: true });
        this.$rootScope.emailVerified = true;
        this.$scope.$close();
      })
      .catch(({ error }: HttpErrorResponse) => {
        delete this.$scope.indicators.loading;
        this.$scope.indicators.errorMessage = error;
      })
      .finally(() => {
        this.$scope.$evalAsync();
      });
  };

  private paste = (event) => {
    const paste = (event.clipboardData || window.clipboardData).getData("text");

    if (paste) {
      const arrKeys = Object.keys(this.$scope.verificationCode);

      paste.split("").forEach((letter, index) => {
        this.$scope.verificationCode[arrKeys[index]] = letter;
      });
    }
  };

  private keyup = (event) => {
    // registers events that contains only letters or numbers
    if (event.keyCode < 48 || (event.keyCode > 90 && event.keyCode < 96) || event.keyCode > 105) {
      return;
    }

    const form = event.currentTarget.form;
    const pos = +event.currentTarget.attributes["data-pos"].nodeValue;

    if (pos === 7) {
      return;
    }

    form[pos + 1].focus();
  };

  private resetVerificationCodeObj = (): void => {
    this.$scope.verificationCode = {
      a: "",
      b: "",
      c: "",
      d: "",
      e: "",
      f: "",
      g: "",
      h: "",
    };
  };

  private resend = () => {
    this.$scope.indicators.errorMessage = "";
    this.resetVerificationCodeObj();
    this.sendVerificationEmail();

    this.$scope.timer = this.interval(() => {
      if (this.$scope.timeLeftInSeconds <= 0) {
        this.$scope.timeLeftInSeconds = 60;
        this.interval.cancel(this.$scope.timer);
      }

      this.$scope.timeLeftInSeconds -= 1;
    }, 1000);
  };
}
