import { IDirective, IDirectiveFactory, IScope } from "angular";
import { AccountService } from "@gtmhub/accounts/accounts.service";
import { IConfidenceMapping, IConfidenceSettings } from "@gtmhub/core";
import { localize } from "@gtmhub/localization";
import { IGtmhubRootScopeService } from "@gtmhub/models";
import { ConfidenceScale, ConfidenceScaleEnum, ConfidenceType } from "@webapp/configuration/models/configuration.model";
import { findRange, getConfidenceFloatNumber } from "@webapp/okrs/utils/confidence-level.utils";

interface IConfidencePreviewRootScopeService extends IGtmhubRootScopeService {
  confidenceScale: ConfidenceScale;
  confidenceSettings: IConfidenceSettings;
}
export interface IConfidencePreviewScope extends IScope {
  confidenceName: string;
  confidenceType: ConfidenceType;
  confidenceValue;
  currentAttainment;
  confidenceLevelTooltip: string;
  confidenceOverflow: string;
  color: string;
  emojiClass: string;
  $root: IConfidencePreviewRootScopeService;
}

const getFloattedMappings = (mappings: IConfidenceMapping[], scaleFactor: number): IConfidenceMapping[] => {
  const clonedMappings = angular.copy(mappings);
  const biggestMappingRange = Math.max(...clonedMappings.map((m) => m.range.to));
  if (biggestMappingRange > 1) {
    clonedMappings.forEach((m) => {
      m.range.to = m.range.to / scaleFactor;
      m.range.from = m.range.from / scaleFactor;
      return m;
    });
  }
  return clonedMappings;
};

const isScaleMinor = (confidenceScale: ConfidenceScale): boolean => confidenceScale === ConfidenceScaleEnum.Minor;

export class ConfidencePreview implements IDirective {
  public scope = {
    confidenceValue: "@",
    disableTooltip: "=?",
    currentAttainment: "@?",
    confidenceOverflow: "=?",
  };
  public restrict = "E";
  public template: string =
    "<span style='color: {{color}}; background-color: {{color}};' class='confidence-badge {{emojiClass}} confidenceType-{{confidenceType}}' " +
    "uib-tooltip='{{!disableTooltip ? confidenceLevelTooltip : undefined}}' data-test-id='confidence-label' tooltip-append-to-body='true'>{{confidenceName}}</span>";

  constructor(private accountService: AccountService) {}

  public link(scope: IConfidencePreviewScope): void {
    const init = () => {
      const scaleFactor = 10;
      this.accountService.setConfidenceSettingsToRootScope();
      scope.confidenceValue = getConfidenceFloatNumber(scope.confidenceValue, scaleFactor);
      scope.confidenceType = scope.$root.confidenceSettings.confidenceType ? scope.$root.confidenceSettings.confidenceType : "numeric";

      const mappings = getFloattedMappings(
        angular.copy(scope.$root.confidenceSettings.confidenceMapping).sort((a: IConfidenceMapping, b: IConfidenceMapping) => a.range.to - b.range.to),
        scaleFactor
      );

      const setViewVariables = (mapping: IConfidenceMapping, confidenceValue: number) => {
        // if mapping is null we display nothing
        if (!mapping) {
          scope.confidenceName = null;
          scope.color = null;
          scope.emojiClass = null;
          return;
        }
        const confidenceScale = this.accountService.getConfidenceScale();
        const confidenceTooltipValue = isScaleMinor(confidenceScale) ? confidenceValue.toFixed(1) : Math.round(confidenceValue * scaleFactor);

        switch (scope.confidenceType) {
          case "numeric":
            scope.confidenceName = isScaleMinor(confidenceScale) ? confidenceValue.toFixed(1) : Math.round(confidenceValue * scaleFactor) + "/10";
            scope.color = mapping.color;
            scope.confidenceLevelTooltip = localize("confidence_level_cap");
            break;
          case "text":
            scope.confidenceName = mapping.name;
            scope.color = mapping.color;
            scope.confidenceLevelTooltip = `${localize("confidence_level_cap")} ${confidenceTooltipValue}`;
            break;
          case "emoji":
            scope.emojiClass = "twa twa-lg twa-" + mapping.name;
            scope.color = null;
            scope.confidenceLevelTooltip = `${localize("confidence_level_cap")} ${confidenceTooltipValue}`;
            break;
          default:
            scope.confidenceName = null;
            scope.color = null;
            scope.emojiClass = null;
            break;
        }
      };

      const mapping = findRange(scope.confidenceValue, mappings);
      setViewVariables(mapping, scope.confidenceValue);
      this.setConfidenceOverflow(scope);
    };

    init();

    scope.$watch("confidenceValue", function () {
      init();
    });
  }

  private setConfidenceOverflow(scope: IConfidencePreviewScope): void {
    if (scope.confidenceOverflow && scope.currentAttainment) {
      const attainmentValue = (scope.currentAttainment * 100).toLocaleString() + "%";
      const smallScreenOverflow = window.innerWidth < 980 && (attainmentValue + scope.confidenceName).length > 50;
      const bigScreenOverflow = window.innerWidth >= 980 && (attainmentValue + scope.confidenceName).length > 20;
      scope.confidenceOverflow = smallScreenOverflow || bigScreenOverflow ? "overflow" : "no-overflow";
    }
  }

  public static factory(): IDirectiveFactory {
    const directive = (accountService: AccountService) => new ConfidencePreview(accountService);
    directive.$inject = ["AccountService"];
    return directive;
  }
}
