import { Injectable } from "@angular/core";
import { Observable, from, map, switchMap, take } from "rxjs";
import { localize } from "@gtmhub/localization";
import { PlanningSessionService } from "@gtmhub/sessions/services/planning-session.service";
import { toKeyMap } from "@gtmhub/util";
import { ISelectorAssignee } from "@webapp/assignees/models/assignee.models";
import { IPlanningSessionStatsAssignee } from "@webapp/sessions/models/sessions.model";
import { IGoalLimitSetting, IPeopleFilterChain, PeopleSelectorRequestInternal } from "../models/models";

@Injectable()
export class GoalFilterChain implements IPeopleFilterChain {
  private nextChain: IPeopleFilterChain;

  public constructor(private planningSessionService: PlanningSessionService) {}

  public setNextChain(chain: IPeopleFilterChain): void {
    this.nextChain = chain;
  }

  public handle(request: PeopleSelectorRequestInternal, assignees$: Observable<ISelectorAssignee[]>): Observable<ISelectorAssignee[]> {
    if (!request.goalsLimitSetting) {
      return this.nextChain ? this.nextChain.handle(request, assignees$) : assignees$;
    }

    const filteredAssignees$ = assignees$.pipe(switchMap((assignees) => this.getGoalStats$(assignees, request.goalsLimitSetting)));

    return this.nextChain ? this.nextChain.handle(request, filteredAssignees$) : filteredAssignees$;
  }

  private getGoalStats$(assignees: ISelectorAssignee[], goalsLimitSetting: IGoalLimitSetting): Observable<ISelectorAssignee[]> {
    const { sessionId, limit } = goalsLimitSetting;

    const assigneeIds = assignees.map((a) => a.id).toString();
    return from(this.planningSessionService.statsAssignees(sessionId, { assigneeIds })).pipe(
      take(1),
      map((stats) => {
        const assigneeStatsIdMap = toKeyMap<IPlanningSessionStatsAssignee>("assigneeId", stats);
        return assignees.map((a) => {
          const assigneeStats = assigneeStatsIdMap[a.id]?.planningSessionStats;
          if (assigneeStats && assigneeStats.goalCount >= limit && !a.invalid) {
            a.name = this.enrichNameWithError(a.name, assigneeStats.goalCount);
            a.invalid = true;
          }

          return a;
        });
      })
    );
  }

  private enrichNameWithError(name: string, count: number): string {
    const limitReached = localize(count === 1 ? "owns_one_okr" : "owns_n_okrs", { count });
    return `${name} (${limitReached})`;
  }
}
