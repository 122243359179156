import { IPromise, IQService } from "angular";
import { ThunkAction } from "redux-thunk";
import { IUIError, createUIError } from "@gtmhub/error-handling";
import { PermissionsService } from "@gtmhub/users";
import { Permission } from "@webapp/permissions/models/permissions.model";
import { IPermissionsStoreState, IReceivePermissionsAction, IReceivePermissionsErrorAction, IRequestPermissionsAction, PermissionsAction } from "./models";

const requestPermissions = (): IRequestPermissionsAction => ({ type: "REQUEST_PERMISSIONS" });
export const receivePermissions = (items: Permission[]): IReceivePermissionsAction => ({ type: "RECEIVE_PERMISSIONS", items });
const receiveRolesError = (error: IUIError): IReceivePermissionsErrorAction => ({ type: "RECEIVE_PERMISSIONS_ERROR", error });
const shouldFetchPermissions = (state: IPermissionsStoreState): boolean => !state.permissions.isFetching && !state.permissions.isFetched;

export class PermissionsActions {
  static $inject = ["$q", "PermissionsService"];

  constructor(
    private $q: IQService,
    private permissionsService: PermissionsService
  ) {}

  getEffectivePermissionsIfMissing(): ThunkAction<IPromise<unknown>, IPermissionsStoreState, null, PermissionsAction> {
    return (dispatch, getState) => {
      if (!shouldFetchPermissions(getState())) {
        return this.$q.resolve();
      }

      dispatch(requestPermissions());

      return this.permissionsService.getEffectivePermissions().then(
        (permissions) => dispatch(receivePermissions(permissions)),
        (error) => dispatch(receiveRolesError(createUIError(error)))
      );
    };
  }
}
