import { Directive, Host, Optional } from "@angular/core";
import { NzRangePickerComponent } from "ng-zorro-antd/date-picker";
import { UiDatePickerComponent } from "../date-picker.component";

@Directive({
  selector: "ui-range-picker",
  exportAs: "uiRangePicker",
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class UiRangePickerComponent extends NzRangePickerComponent {
  constructor(@Optional() @Host() public datePicker: UiDatePickerComponent) {
    super(datePicker);
  }
}
