import { ChangeDetectionStrategy, Component, Input, TemplateRef } from "@angular/core";
import { UiEmptyModule } from "@quantive/ui-kit/empty";
import { NzListEmptyComponent } from "ng-zorro-antd/list";

@Component({
  selector: "ui-list-empty",
  exportAs: "uiListEmpty",
  templateUrl: "./list-empty.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiEmptyModule],
})
export class UiListEmptyComponent extends NzListEmptyComponent {
  @Input("uiNoResult") public nzNoResult?: string | TemplateRef<void>;
}
