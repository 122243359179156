import { BehaviorSubject, Observable } from "rxjs";
import { Cache } from "./models/cache.model";

export class ObservableCache<T> implements Cache<T> {
  private cache$: BehaviorSubject<T>;

  constructor(initialValue?: T) {
    this.cache$ = new BehaviorSubject(initialValue);
  }

  public get$(): Observable<T> {
    return this.cache$;
  }

  public set(value: T): void {
    this.cache$.next(value);
  }

  public empty(): void {
    this.cache$.next(undefined);
  }
}
