import { IComponentOptions, ITimeoutService } from "angular";
import { StateService } from "@uirouter/angularjs";
import { AccountService } from "@gtmhub/accounts/accounts.service";
import { GtmhubController } from "@gtmhub/core";
import { IGtmhubRootScopeService } from "@gtmhub/models";
import { offset } from "@gtmhub/shared/dom";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { CreateOkrFormModalService } from "@webapp/okrs/components/create-okr-form/create-okr-form-modal.service";
import { Session } from "@webapp/sessions/models/sessions.model";
import { CurrentUserRepository } from "@webapp/users";
import { IChecklist } from "../models/onboarding.models";

export interface IFirstOkrGuide {
  steps: {
    showedSession: boolean;
    welcomedToSession: boolean;
    explainedObjectiveView: boolean;
    explainedAlignmentView: boolean;
    showedCreateNextOkrButton: boolean;
  };
  optOut: boolean;
}

export class FirstOkrGuideCtrl extends GtmhubController {
  progressWithGuide: IFirstOkrGuide;
  shouldSeePopupBasedOnState: {
    home: boolean;
    goals: boolean;
  };

  fromState: string;
  session: Session;
  static $inject = ["$rootScope", "$state", "$timeout", "AccountService", "AnalyticsService", "CurrentUserRepository", "CreateOkrFormModalService"];

  constructor(
    private $rootScope: IGtmhubRootScopeService,
    private $state: StateService,
    private $timeout: ITimeoutService,
    private accountService: AccountService,
    private analyticsService: AnalyticsService,
    private currentUserRepository: CurrentUserRepository,
    private createOkrFormModalService: CreateOkrFormModalService
  ) {
    super();
  }

  $onInit(): void {
    this.progressWithGuide = this.accountService.getAccountSetting("firstOkrGuide");

    if (this.progressWithGuide && this.progressWithGuide.steps) {
      this.setShouldSeePopupBasedOnState();

      if (this.shouldSeePopupBasedOnState.home || this.shouldSeePopupBasedOnState.goals) {
        this.setInitialPositionOfTheGuideModal();
      }
    }
  }

  private setShouldSeePopupBasedOnState = (): void => {
    const checklist: IChecklist = this.currentUserRepository.getUserSetting("checklist");

    this.shouldSeePopupBasedOnState = {
      home: this.fromState === "home" && checklist && checklist.stepsV1 && checklist.stepsV1.createdOkr,
      goals: this.fromState === "goals",
    };
  };

  private setPositionOnHomeModal = (): void => {
    if (this.shouldSeePopupBasedOnState.home) {
      const topOffsetWhenSidebarOpened = 15;
      const topOffsetWhenSidebarClosed = 150;

      const okrsItemOffset = offset(document.querySelector(".okrs-item"));
      const offsetTop = okrsItemOffset?.top ? okrsItemOffset.top + topOffsetWhenSidebarOpened : topOffsetWhenSidebarClosed;
      const homeGuide = document.querySelector<HTMLElement>(".home-guide");
      if (homeGuide) {
        homeGuide.style.top = offsetTop + "px";
      }

      const leftNavItems = this.currentUserRepository.getUserSetting<{ okrs: boolean }>("leftNavItems");

      if (!leftNavItems || leftNavItems.okrs === false) {
        this.$rootScope.$broadcast("untoggleSessions");
      }
    }
  };

  private setInitialPositionOfTheGuideModal = (): void => {
    this.$timeout(() => {
      // timeout is needed for proper popup render on reload/first load
      this.setPositionOfTheGuideModal();
    }, 1000);
  };

  private setPositionOfTheGuideModal = (): void => {
    if (!this.progressWithGuide.steps.showedSession) {
      this.setPositionOnHomeModal();
      if (this.$state.current.name.indexOf("gtmhub.home") !== -1) {
        document.querySelector<HTMLElement>(".home-guide").style.visibility = "visible";
      }
      return;
    }

    const guide = document.querySelector<HTMLElement>(".goals-guide");
    const sidebarWidth = 220;

    if (!guide) {
      return;
    }

    if (
      document.querySelector<HTMLElement>(".objectives-view") &&
      this.progressWithGuide.steps.welcomedToSession &&
      !this.progressWithGuide.steps.explainedObjectiveView
    ) {
      guide.style.left = `${offset(document.querySelector(".objectives-view")).left - sidebarWidth}px`;
    }

    if (
      document.querySelector<HTMLElement>(".alignment-view") &&
      this.progressWithGuide.steps.explainedObjectiveView &&
      !this.progressWithGuide.steps.explainedAlignmentView
    ) {
      guide.style.left = `${offset(document.querySelector(".alignment-view")).left - sidebarWidth}px`;
    }

    if (
      document.querySelector<HTMLElement>("create-okr-button") &&
      this.progressWithGuide.steps.explainedAlignmentView &&
      !this.progressWithGuide.steps.showedCreateNextOkrButton
    ) {
      guide.style.left = `${offset(document.querySelector("create-okr-button")).left - sidebarWidth}px`;
    }

    guide.style.visibility = "visible";
  };

  nextStep = (step: string): void => {
    switch (step) {
      case "showSession":
        this.progressWithGuide.steps.showedSession = true;
        this.$state.go("gtmhub.goals.all", { planningSessionId: this.session.id });
        break;
      case "showObjectiveView":
        this.progressWithGuide.steps.welcomedToSession = true;
        break;
      case "showAlignmentView":
        this.progressWithGuide.steps.explainedObjectiveView = true;
        break;
      case "showHowToCreateNextOkr":
        this.$state.go("gtmhub.goals.alignmentSidebar.tree", { planningSessionId: this.session.id });
        this.progressWithGuide.steps.explainedAlignmentView = true;
        break;
      case "openCreateOkrModal":
        this.createOkrFormModalService.openFormModal({
          sessionId: this.session?.id,
        });

        this.progressWithGuide.steps.showedCreateNextOkrButton = true;
        this.$rootScope.$broadcast("closeFirstOkrGuide");
        break;
    }

    this.setPositionOfTheGuideModal();
    this.accountService.setAccountSetting("firstOkrGuide", this.progressWithGuide);
    this.analyticsService.track("First OKR Guide", {
      steps: {
        session_menu_shown: this.progressWithGuide.steps.showedSession,
        session_welcomed: this.progressWithGuide.steps.welcomedToSession,
        objective_view_shown: this.progressWithGuide.steps.explainedObjectiveView,
        alignment_view_shown: this.progressWithGuide.steps.explainedAlignmentView,
        next_okr_button_shown: this.progressWithGuide.steps.showedCreateNextOkrButton,
      },
      opt_out: this.progressWithGuide.optOut,
    });
  };

  dismissGuide = (): void => {
    this.progressWithGuide.optOut = true;
    this.accountService.setAccountSetting("firstOkrGuide", this.progressWithGuide);
    this.$rootScope.$broadcast("closeFirstOkrGuide");
  };
}

export const FirstOkrGuideComponent: IComponentOptions = {
  template: require("./first-okr-guide.html"),
  controller: FirstOkrGuideCtrl,
  bindings: {
    fromState: "@",
    session: "<",
  },
};
