<div class="ant-form-item-control-input">
  <div class="ant-form-item-control-input-content">
    <ng-content></ng-content>
  </div>
</div>
<div class="ant-form-item-explain ant-form-item-explain-connected" *ngIf="innerTip">
  <div [ngClass]="['ant-form-item-explain-' + status]" role="alert">
    <ng-container *nzStringTemplateOutlet="innerTip; context: { $implicit: validateControl }">{{ innerTip }}</ng-container>
  </div>
</div>
<div class="ant-form-item-extra" *ngIf="nzExtra">
  <ng-container *nzStringTemplateOutlet="nzExtra">{{ nzExtra }}</ng-container>
</div>
