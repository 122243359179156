import { IPromise, IQService, IRootScopeService, ITimeoutService } from "angular";
import { IModalInstanceService, IModalScope, IModalService, IModalServiceInstance } from "angular-ui-bootstrap";
import { StateService, TransitionService } from "@uirouter/angularjs";
import { IStateInit } from "@gtmhub/core/routing";
import { ITraceRootScopeService } from "@gtmhub/core/tracing";
import { IAppConfig, isCurrentDomainQuantive } from "@gtmhub/env";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { hasPermissions } from "@gtmhub/users/redux";
import { ModuleFlag } from "@webapp/feature-toggles/models/feature-module.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { INotification, INotificationIndicators, INotificationSettings, NOTIFICATION_TARGET_TYPES, TargetType } from "../models/notifications";
import { NotificationsService } from "../services/notifications.service";

const replaceHostWithLocation = (inputUrl: string): string => {
  const { host, protocol } = location;
  const url = new URL(inputUrl);
  url.host = host;
  url.protocol = protocol;
  return url.toString();
};

const useLocationHost = (notification: INotification): void => {
  const { notificationData } = notification;
  if (notificationData && notificationData.item && "url" in notificationData.item) {
    notificationData.item.url = replaceHostWithLocation(notificationData.item.url);
  }
};

interface INotificationsInboxData {
  indicators: INotificationIndicators;
  newMessagesCount: number;
}

interface INotificationsScope extends IModalScope {
  pagingSize: number;
  page: number;
  messages: INotification[];
  indicators: INotificationIndicators;
  newMessageAvailable: boolean;
  newMessagesCount: number;
  loadInbox(): IPromise<void>;
  markAll(): void;
  showMoreMessages(): void;
  markAsSeen($event: { index: number; notification: INotification }): void;
  closeInbox(): void;
}

export class NotificationsInboxService {
  public static $inject = ["$uibModal"];

  constructor(private $uibModal: IModalService) {}

  public open(indicators: INotificationIndicators, newMessagesCount: number): IModalInstanceService {
    return this.$uibModal.open({
      template: require("../views/inbox.html"),
      windowClass: "sidebar-modal inbox-modal large right",
      controller: "NotificationsInboxCtrl",
      resolve: {
        inboxData: (): INotificationsInboxData => ({
          indicators,
          newMessagesCount,
        }),
      },
    });
  }
}

export class NotificationsInboxCtrl implements IStateInit {
  private commaSeparatedTargetTypes: string;
  private userNotificationSettings: INotificationSettings;

  static $inject = [
    "$rootScope",
    "$transitions",
    "$scope",
    "$state",
    "$uibModalInstance",
    "$timeout",
    "$q",
    "NotificationsService",
    "inboxData",
    "appConfig",
    "FeatureTogglesFacade",
  ];

  constructor(
    private $rootScope: IRootScopeService & ITraceRootScopeService,
    private $transitions: TransitionService,
    private $scope: INotificationsScope,
    private $state: StateService,
    private $uibModalInstance: IModalServiceInstance,
    private $timeout: ITimeoutService,
    private $q: IQService,
    private notificationsService: NotificationsService,
    private inboxData: INotificationsInboxData,
    private appConfig: IAppConfig,
    private featureToggleService: FeatureTogglesFacade
  ) {}

  public stateInit(): IPromise<unknown> {
    return this.init();
  }

  private init(): IPromise<void> {
    this.$scope.indicators = {};
    this.$scope.pagingSize = 50;
    this.$scope.indicators = this.inboxData.indicators;
    this.$scope.newMessagesCount = this.inboxData.newMessagesCount;

    this.addEventListeners();

    this.$scope.loadInbox = (): IPromise<void> => {
      return this.$rootScope.traceAction("load_inbox", () => {
        this.$scope.newMessageAvailable = false;

        this.$scope.indicators.loading = { progress: true };

        return this.getNotifications({ skip: 0, take: this.$scope.pagingSize }).then(
          (notifications) => {
            this.$scope.page = 1;
            this.$scope.messages = notifications;
            delete this.$scope.indicators.loading;
          },
          (error) => {
            this.$scope.indicators.loading = { error };
          }
        );
      });
    };

    this.$scope.markAll = () => {
      this.$rootScope.traceAction("mark_all_as_seen", () => {
        this.$scope.indicators.markingAllAsSeen = { progress: true };
        this.notificationsService
          .markAllAsSeen()
          .then(() => {
            this.$scope.messages = this.$scope.messages.map((notification) => Object.assign(notification, { seen: true }));
            return this.updateNewMessagesCount();
          })
          .then(
            () => {
              delete this.$scope.indicators.markingAllAsSeen;
            },
            (error) => (this.$scope.indicators.markingAllAsSeen = { error })
          );
      });
    };

    this.$scope.showMoreMessages = () => {
      this.$rootScope.traceAction("show_more_messages", () => {
        this.$scope.indicators.loadingMoreMessages = { progress: true };
        this.getNotifications({ skip: this.$scope.page * this.$scope.pagingSize, take: this.$scope.pagingSize }).then(
          (notifications) => {
            // takes height of inbox-messages container with old messages, subtracting the height of the loading indicator
            const inboxEl = document.getElementById("inbox-messages");
            const loadingMoreIndicatorElement = document.getElementById("loading-more-messages-indicator");
            const msgsHeight = inboxEl.scrollHeight - loadingMoreIndicatorElement.clientHeight;
            this.$scope.messages = this.$scope.messages.concat(notifications);
            delete this.$scope.indicators.loadingMoreMessages;

            this.$timeout(() => {
              // takes height of inbox-messages container with old and new messages
              const newMsgsHeight = inboxEl.scrollHeight;

              // with some arbitrary offset present, the last notification from the previous batch is still visible in the viewport after the scroll,
              // and with the applied ".shown" css mask, it's much easier to distinguish which is the first notification from the new batch
              const firstNewNotificationOffset = 100;
              inboxEl.scrollTo({ top: msgsHeight - firstNewNotificationOffset });

              const coverEl = document.createElement("div");
              coverEl.id = "new-msg-cover";
              coverEl.classList.add("new-messages-cover");
              inboxEl.appendChild(coverEl);

              coverEl.style.height = `${newMsgsHeight - msgsHeight}px`;
              coverEl.style.top = `${msgsHeight}px`;

              coverEl.classList.add("shown");

              this.$timeout(() => {
                coverEl.classList.remove("shown");
              }, 500);

              this.$timeout(() => {
                coverEl.remove();
              }, 1000);
            });

            this.$scope.page++;
          },
          (error) => (this.$scope.indicators.loadingMoreMessages = { error })
        );
      });
    };

    this.$scope.markAsSeen = ($event: { index: number; notification: INotification }) => {
      this.$rootScope.traceAction("mark_as_seen", () => {
        this.notificationsService.markAsSeen($event.notification.id).then(() => {
          return this.updateNewMessagesCount();
        });
      });
    };

    this.$scope.closeInbox = () => {
      document.querySelector<HTMLElement>(".notifications")?.classList.remove("active");
      this.$uibModalInstance.close();
    };

    return this.getUserNotificationSettings().then(() => this.loadAllowedTargetTypes().then(() => this.$scope.loadInbox()));
  }

  private getUserNotificationSettings = (): IPromise<void> => {
    return this.notificationsService.getUserNotificationSettings().then((userNotificationSettings) => {
      this.userNotificationSettings = userNotificationSettings;
    });
  };

  private loadAllowedTargetTypes = (): IPromise<void> => {
    return this.$q
      .all<TargetType>([
        this.featureToggleService.isFeatureAvailable(ModuleFlag.Tasks).then((enabled) => (enabled ? undefined : "task")),
        this.featureToggleService.isFeatureAvailable(ModuleFlag.Reflections).then((enabled) => {
          const userInboxCheckinNotificationsEnabled =
            this.userNotificationSettings.inboxNotificationSettings.embedded.reflectionReminders ||
            this.userNotificationSettings.inboxNotificationSettings.embedded.reflectionsPublishedByTeammates;

          return enabled && userInboxCheckinNotificationsEnabled && hasPermissions(reduxStoreContainer.reduxStore.getState(), "AccessPeople") ? undefined : "reflection";
        }),
        this.featureToggleService.isFeatureAvailable(ModuleFlag.Kpis).then((enabled) => (enabled ? undefined : "kpi")),
      ])
      .catch(() => [])
      .then((disallowedTargetTypes) => {
        const allowedTargetTypes = NOTIFICATION_TARGET_TYPES.filter((targetType) => !disallowedTargetTypes.includes(targetType));
        if (allowedTargetTypes.length !== NOTIFICATION_TARGET_TYPES.length) {
          this.commaSeparatedTargetTypes = allowedTargetTypes.join(",");
        }
      });
  };

  private getNotifications = (query: { skip: number; take: number }): IPromise<INotification[]> => {
    return this.notificationsService.getNotifications({ ...query, targets: this.commaSeparatedTargetTypes }).then((col) => {
      col.items.forEach((notification) => {
        if (this.appConfig.env.bypassDomainCheck || isCurrentDomainQuantive()) {
          useLocationHost(notification);
        }
      });
      return col.items;
    });
  };

  private getNewMessageCount = (): IPromise<number> => {
    return this.notificationsService.getNotifications({ seen: false, skip: 0, take: 1, targets: this.commaSeparatedTargetTypes }).then(
      (notifications) => notifications.totalCount,
      (error) => {
        this.$scope.indicators.loading = { error };
        return this.$q.reject(error);
      }
    );
  };

  private updateNewMessagesCount = () => {
    return this.getNewMessageCount().then((count) => {
      this.$scope.newMessagesCount = count;
      this.$rootScope.$broadcast("newMessagesCount", count);
    });
  };

  private addEventListeners() {
    this.$scope.$on("notificationsCount", () => {
      this.$timeout(() => {
        this.$scope.newMessageAvailable = true;
      });

      this.$timeout(() => {
        this.$scope.newMessageAvailable = false;
      }, 3000);
    });

    this.$scope.$on(
      "$destroy",
      this.$transitions.onSuccess({}, () => {
        const stateName = this.$state.current.name;

        if (stateName.includes("insight")) {
          this.$scope.$close();
        }
      })
    );
  }
}
