import { IController, IScope } from "angular";
import { AuthenticationResolverService } from "@gtmhub/auth";
import { shouldUseQuantiveUrlBehavior } from "@gtmhub/env";
import { removeFullNavSkeleton } from "@webapp/shared/skeleton/skeleton.utils";

export interface IUserDeactivatedScope extends IScope {
  transactionID: string;
}

interface IUserDeactivatedStateParams {
  transactionID: string;
}

export class UserDeactivatedCtrl implements IController {
  transactionID: string;

  public static $inject = ["$scope", "$stateParams", "AuthenticationResolverService"];

  constructor(
    private $scope: IUserDeactivatedScope,
    private $stateParams: IUserDeactivatedStateParams,
    private authenticationResolverService: AuthenticationResolverService
  ) {}

  $onInit(): void {
    if (this.authenticationResolverService.isAuthDataPresent) {
      const pathPrefix = shouldUseQuantiveUrlBehavior() ? "/results" : "/#";
      this.authenticationResolverService.logout({
        ssoLogoutIfEnabled: false,
        returnUrl: `${pathPrefix}/user-deactivated?transactionID=${this.$stateParams.transactionID}`,
      });
    } else {
      this.$scope.transactionID = this.$stateParams.transactionID;
      removeFullNavSkeleton({ force: true });
    }
  }
}
