<ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
<div class="title">
  <span class="item" *ngIf="notification.operationType === 'milestone_hit'"> {{ "kr_milestone_was_hit" | localize: { metricName: notification.notificationData.item.metricName } }}</span>
  <span class="item" *ngIf="notification.operationType === 'milestone_missed'"> {{ "kr_milestone_was_missed" | localize: { metricName: notification.notificationData.item.metricName } }}</span>
  <span> </span>
</div>
<div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
<div class="summary">
  <div class="message-icon"><i class="fs-20px" [uiTooltipTitle]="'metric' | localize" ui-icon uiTheme="fill" uiType="metric" ui-tooltip></i></div>
  <span class="item"> {{ notification.notificationData.item.metricName }}</span>
</div>
<div class="full">
  <div class="inbox-box">
    <div class="inbox-section">
      <div *ngIf="notification.operationType === 'milestone_hit'">
        <span class="item">
          <div class="kpi-notification-box">
            <span class="item bold"> {{ "kr_milestone_was_hit" | localize: { metricName: notification.notificationData.item.metricName } }}</span>
            <br />
            {{ "milestone_for_date" | localize }} <span class="bold">{{ notification.notificationData.item.milestoneDate | date: "dd MMM, yyyy" }}</span>
            <div class="milestone-notification-number fs-13px">
              {{ notification.notificationData.item.targetValue }}
            </div>
            <br />
            {{ "current_value_cap" | localize }}
            <div class="milestone-notification-number fs-13px green">
              {{ notification.notificationData.item.metricCurrentValue }}
            </div>
          </div>
        </span>
      </div>
      <div *ngIf="notification.operationType === 'milestone_missed'">
        <span class="item">
          <span class="item bold"> {{ "kr_milestone_was_missed" | localize: { metricName: notification.notificationData.item.metricName } }}</span>
          <br />
          {{ "milestone_for_date" | localize }} <span style="font-weight: bold">{{ notification.notificationData.item.milestoneDate | date: "dd MMM, yyyy" }}</span>
          <div class="milestone-notification-number fs-13px">
            {{ notification.notificationData.item.targetValue }}
          </div>
          <br />
          <div class="kpi-notification-box">
            {{ "current_value_cap" | localize }}
            <div class="milestone-notification-number fs-13px red">
              {{ notification.notificationData.item.metricCurrentValue }}
            </div>
          </div>
        </span>
      </div>
    </div>
    <div class="inbox-section">
      <a class="link classic" [attr.href]="notification.notificationData.item.metricUrl">{{ "view_key_result" | localize }}</a>
    </div>
  </div>
</div>
