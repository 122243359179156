import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { Search, SearchDTO } from "../models/search.models";
import { SearchState } from "./search-state.service";

@Injectable({
  providedIn: "root",
})
export class SearchApiService extends BaseApiService<Search<null>, SearchDTO, SearchState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: SearchState) {
    super("search", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getMultiPath(httpAction: HttpActions): string {
    return `${this.appConfig.endpoints.secure ? "https://" : "http://"}${this.appConfig.endpoints.apiEndpoint}/${this.apiVersion[httpAction]}/${this.entityPath}/multi`;
  }

  public getMultiPathV2(httpAction: HttpActions): string {
    return this.getBasePath(httpAction, { apiVersionOverwrite: "v2" }) + `/multi`;
  }
}
