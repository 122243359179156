import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from "@angular/core";
import { NzFormItemComponent } from "ng-zorro-antd/form";

/** should add nz-row directive to host, track https://github.com/angular/angular/issues/8785 **/
@Component({
  selector: "ui-form-item",
  exportAs: "uiFormItem",
  templateUrl: "form-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class UiFormItemComponent extends NzFormItemComponent {
  constructor(cdr: ChangeDetectorRef) {
    super(cdr);
  }
}
