import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { DataSourceDTO, DataSourcePostDTO } from "@webapp/data-story/models/data-source.models";
import { DataSourcesState } from "@webapp/data-story/services/data-sources-facade/data-sources-state.service";

@Injectable({
  providedIn: "root",
})
export class DataSourcesApiService extends BaseApiService<DataSourceDTO, DataSourcePostDTO, DataSourcesState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: DataSourcesState) {
    super("datasources", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public createDataSourceEndpoint(): string {
    return `${this.getBasePath(HttpActions.post, { apiVersionOverwrite: "v2" })}`;
  }

  public getDataSourceByIdEndpoint(dataSourceId: string): string {
    return `${this.getBasePath(HttpActions.get)}/${dataSourceId}`;
  }

  public patchDataSourceEndpoint(dataSourceId: string): string {
    return `${this.getBasePath(HttpActions.get)}/${dataSourceId}`;
  }

  public createEntityEndpoint(dataSourceId: string): string {
    return `${this.getBasePath(HttpActions.post)}/${dataSourceId}/entity`;
  }
}
