import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { QuantivePlusWhiteboardDTO, QuantivePlusWhiteboardPostDTO } from "../models";
import { QuantivePlusWhiteboardStateV1 } from "./quantive-plus-whiteboard-state-v1.service";

@Injectable({
  providedIn: "root",
})
export class QuantivePlusWhiteboardApiV1Service extends BaseApiService<QuantivePlusWhiteboardDTO, QuantivePlusWhiteboardPostDTO, QuantivePlusWhiteboardStateV1> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: QuantivePlusWhiteboardStateV1) {
    super("whiteboard", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getObjectiveKrEndpoint(): string {
    return `${this.getQuantivePlusBasePath(HttpActions.post)}/objective-kr`;
  }
}
