import { IHttpService, IPromise } from "angular";
import { EnvironmentService } from "@gtmhub/env";
import { ISectionHeader } from "@webapp/insightboards/models";

export class SectionHeaderService {
  public static $inject = ["$http", "EnvironmentService"];

  constructor(
    private $http: IHttpService,
    private env: EnvironmentService
  ) {}

  public createSectionHeader(sectionHeader: ISectionHeader): IPromise<ISectionHeader> {
    const url = this.env.getApiEndpoint("/section-headers");

    return this.$http.post<ISectionHeader>(url, sectionHeader).then((response) => response.data);
  }

  public updateSectionHeader(sectionHeader: ISectionHeader): IPromise<ISectionHeader> {
    const url = this.env.getApiEndpoint("/section-headers/" + sectionHeader.id);

    return this.$http.put<ISectionHeader>(url, sectionHeader).then((response) => response.data);
  }

  public deleteSectionHeader(sectionHeaderId: string): IPromise<unknown> {
    const url = this.env.getApiEndpoint("/section-headers/" + sectionHeaderId);

    return this.$http.delete(url).then((response) => response.data);
  }
}
