import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { ICollection } from "@webapp/core/core.models";
import { Employee, EmployeeDetails } from "../models/employees.models";
import { EmployeesApiService } from "./employees-api.service";

@Injectable({ providedIn: "root" })
export class EmployeesRepository {
  constructor(private apiService: EmployeesApiService) {}

  public getEmployeeDetails$(id: string, config?: RequestConfig): Observable<EmployeeDetails> {
    return this.apiService.get$(id, config);
  }

  public getEmployees$(filter?: RequestPaging, config?: RequestConfig): Observable<ICollection<Employee>> {
    return this.apiService.getAll$(filter, config);
  }
}
