import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";

const stripTokensFromQueryString = (pathWithQueryString: string): string => {
  const [path, queryString] = pathWithQueryString.split("?");
  if (queryString && queryString.toLowerCase().includes("token")) {
    const searchParams = new URLSearchParams(queryString);
    const toDelete: string[] = [];

    for (const key of searchParams.keys()) {
      if (key.toLowerCase().includes("token")) {
        toDelete.push(key);
      }
    }

    for (const key of toDelete) {
      searchParams.delete(key);
    }

    pathWithQueryString = path + "?" + searchParams.toString();
  }

  return pathWithQueryString;
};

/**
 * Attaches a `gtmhub-request-origin` header to all API requests
 * to denote the current URL path
 */
@Injectable()
export class RequestOriginInterceptor implements HttpInterceptor {
  constructor(private apiEndpointService: ApiEndpointService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.apiEndpointService.isApiRequest(request.url)) {
      return next.handle(this.attachHeaders(request));
    }

    return next.handle(request);
  }

  private attachHeaders(request: HttpRequest<unknown>): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        "gtmhub-request-origin": stripTokensFromQueryString(location.hash.substring(1)),
      },
    });
  }
}
