import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { skip } from "rxjs";
import { Goal } from "@webapp/okrs/goals/models/goal.models";
import { IPIFeedbackSuggestion } from "../pi-feedback-card/models";
import { PIFeedbackSuggestionSubType, PIFeedbackSuggestionType } from "../pi-feedback-card/services/feedback/models";
import { PiStateProcessorInstance } from "../pi-feedback-card/services/state-processor/pi-state-processor.models";
import { isSuggestionFeedbackPersisted, setPersistedSuggestionsFeedback } from "../pi-feedback-card/utils/utils";

@UntilDestroy()
@Component({
  selector: "pi-feedback-card-readonly[suggestion][instance][parent]",
  templateUrl: "./pi-feedback-card-readonly.component.html",
  styleUrls: ["./pi-feedback-card-readonly.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiFeedbackCardReadonlyComponent implements OnInit {
  @Input() public instance: PiStateProcessorInstance;
  @Input() public parent: Goal;
  @Input() public suggestion: IPIFeedbackSuggestion<string | string[]>;
  @Input() public suggestionType: string;
  @Input() public suggestionFeedbackType: PIFeedbackSuggestionType;
  @Input() public suggestionFeedbackSubType: PIFeedbackSuggestionSubType;

  public isFeedbackActionsSectionAvailable = true;

  constructor(private cd: ChangeDetectorRef) {}

  public ngOnInit(): void {
    const initId = this.instance.subType ? this.instance.subType + "_" + this.suggestion.id : this.suggestion.id;

    this.isFeedbackActionsSectionAvailable = !isSuggestionFeedbackPersisted(initId, this.instance.type, this.parent.id);
    this.cd.detectChanges();

    this.instance.feedbackSubject.pipe(skip(1), untilDestroyed(this)).subscribe({
      next: (data) => {
        if (this.instance.subType !== data.subType) {
          return;
        }

        if (data.hasProvidedFeedback) {
          const id = this.instance.subType ? this.instance.subType + "_" + data.suggestion?.id : data.suggestion?.id;
          this.isFeedbackActionsSectionAvailable = false;
          this.cd.detectChanges();
          setPersistedSuggestionsFeedback(id, this.instance.type, this.parent.id);
        }
      },
    });
  }
}
