import { Directionality } from "@angular/cdk/bidi";
import { Directive, Input, Optional } from "@angular/core";
import { UiThemeType } from "@quantive/ui-kit/icon";
import { NzConfigService, WithConfig } from "ng-zorro-antd/core/config";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzFormDirective } from "ng-zorro-antd/form";
import { UiFormLayoutType, uiDefaultTooltipIcon } from "@webapp/ui/form/form.models";

@Directive({
  selector: "[ui-form]",
  exportAs: "uiForm",
  standalone: true,
})
export class UiFormDirective extends NzFormDirective {
  @Input("uiLayout") public nzLayout: UiFormLayoutType = "horizontal";
  @Input("uiNoColon") @WithConfig() @InputBoolean() public nzNoColon = false;
  @Input("uiAutoTips") @WithConfig() public nzAutoTips: Record<string, Record<string, string>> = {};
  @Input("uiDisableAutoTips") @InputBoolean() public nzDisableAutoTips = false;
  @Input("uiTooltipIcon") @WithConfig() public nzTooltipIcon: string | { type: string; theme: UiThemeType } = uiDefaultTooltipIcon;

  constructor(nzConfigService: NzConfigService, @Optional() directionality: Directionality) {
    super(nzConfigService, directionality);
  }
}
