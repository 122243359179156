import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject } from "rxjs";
import { Goal } from "@webapp/okrs/goals/models/goal.models";
import { IQuantivePlusAcceptedMetric, IQuantivePlusRefreshedArgs, IQuantivePlusSuggestionStatus } from "@webapp/platform-intelligence/quantive-plus/models";
import { PiStateProcessorInstance } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.models";
import { PiStateProcessorService } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.service";
import { IQuantivePlusMetric } from "@webapp/platform-intelligence/shared/models";
import { PiSuggestionSharedGoalStateService } from "@webapp/platform-intelligence/shared/services/pi-suggestion-shared-goal-state.service";
import { ObjectiveFlowName, PiTrackingFlowNameEnum } from "@webapp/platform-intelligence/shared/utils/pi-tracking";
import { SuggestionDrawerAllSuggestionsTabStatePropagator } from "../../../../utils/state-propagator";

@UntilDestroy()
@Component({
  selector: "suggestion-drawer-key-results[goal][status]",
  templateUrl: "./key-results.component.html",
  styleUrls: ["./key-results.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionDrawerKeyResultsComponent implements OnInit, OnDestroy {
  @Input() public goal: Goal;
  @Input() public status: IQuantivePlusSuggestionStatus;
  @Input() public statePropagator: SuggestionDrawerAllSuggestionsTabStatePropagator;

  public metrics: IQuantivePlusMetric[];
  public metricsInstance: PiStateProcessorInstance;
  public onDestroy$ = new Subject<void>();

  private flowId: string;
  public flowName: ObjectiveFlowName = PiTrackingFlowNameEnum.ObjectiveDetailsViewAllSuggestionSidePanel;

  constructor(
    private piSuggestionSharedStateService: PiSuggestionSharedGoalStateService,
    private cd: ChangeDetectorRef,
    private piStateProcessorService: PiStateProcessorService
  ) {}

  public ngOnInit(): void {
    this.metricsInstance = this.piStateProcessorService.createNewInstance({
      type: "metric",
      subType: "metric",
      isInline: false,
      iconTheme: "fill",
      iconType: "metric",
      improveSuggestionContext: {
        entityId: this.goal.id,
        parentId: this.goal.parentId,
        parentType: "objective",
      },
      destroyOn$: this.onDestroy$,
    });

    this.piSuggestionSharedStateService
      .getMetrics$()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (metrics) => {
          this.metrics = metrics || [];

          const isLoadingMetrics = this.metrics.some((metric) => metric.isLoading);
          this.statePropagator.emitStateChange({ isLoading: isLoadingMetrics });
          this.cd.detectChanges();
        },
      });

    this.piSuggestionSharedStateService
      .getQuantiveSuggestionsFlowId()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (flowId) => {
          this.flowId = flowId;

          this.piStateProcessorService.assigneInstanceFlowProperties(this.metricsInstance.id, { flowId: this.flowId, flowName: this.flowName });
        },
      });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  public changeQuantivePlusSuggestion(args: IQuantivePlusRefreshedArgs): void {
    this.piSuggestionSharedStateService.setSingleMetric({ type: "refreshed", args });
  }

  public getQuantivePlusSuggestions(): void {
    this.piSuggestionSharedStateService.setRefreshAllKeyResults();
  }

  public onQuantivePlusMetricCreated(args: IQuantivePlusAcceptedMetric): void {
    this.piSuggestionSharedStateService.setSingleMetric({ type: "accepted", args });
  }

  public onGoBackToSuggestionClicked = (args: { suggestionIndex: number }): void => {
    this.piSuggestionSharedStateService.setGoBackToLastSuggestion(args.suggestionIndex);
  };
}
