<ng-template>
  <editable-custom-fields
    [borderless]="borderless"
    [formControl]="formControl"
    [customFieldsFilter]="filter"
    [targetType]="target"
    [isFieldDisabled]="isFieldDisabled"
    [isFieldReadonly]="isFieldReadonly"
    [updateOnBlur]="updateOnBlur"
    [disableRequired]="disableRequired"
    [editorWidth]="form.typography?.editorWidth"
    [formName]="customFieldsItemFormName"
    (checkRequired)="handleCustomFieldsRequiredChange($event)"
    (customFieldsLoaded)="handleCustomFieldsLoaded($event)"></editable-custom-fields>
</ng-template>
