import { Injectable, OnDestroy } from "@angular/core";
import { Subscription, take, tap } from "rxjs";
import { AccountResolverService } from "@webapp/accounts";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { AnalyticsService } from "./analytics.service";

@Injectable()
export class IdentifyUserService implements OnDestroy {
  private accountCreatedSubscription: Subscription;
  private authorizationProcessedSubscription: Subscription;
  private accountDataInitializedSubscription: Subscription;

  constructor(
    private analyticsService: AnalyticsService,
    private accountResolverService: AccountResolverService,
    private broadcastService: BroadcastService
  ) {}

  public ngOnDestroy(): void {
    this.accountCreatedSubscription?.unsubscribe();
    this.authorizationProcessedSubscription?.unsubscribe();
    this.accountDataInitializedSubscription?.unsubscribe();
  }

  public registerIdentifyingUser(): void {
    const account = this.accountResolverService.getAccountData();
    if (account) {
      this.analyticsService.identify();
    } else {
      this.accountCreatedSubscription = this.broadcastService
        .on("accountCreated")
        .pipe(
          take(1),
          tap(() => {
            this.analyticsService.identify();
          })
        )
        .subscribe();

      this.authorizationProcessedSubscription = this.broadcastService
        .on("authorizationProcessed")
        .pipe(
          take(1),
          tap(() => {
            this.analyticsService.identify();
          })
        )
        .subscribe();

      this.accountDataInitializedSubscription = this.broadcastService
        .on("accountDataInitialized")
        .pipe(
          take(1),
          tap(() => {
            this.analyticsService.identify();
          })
        )
        .subscribe();
    }
  }
}
