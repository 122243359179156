import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";

@Injectable()
export class CdnRewriteInterceptor implements HttpInterceptor {
  constructor(@Inject(APP_CONFIG) private appConfig: IAppConfig) {}

  public intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    return next.handle(this.attachHeaders(request));
  }

  private attachHeaders(request: HttpRequest<object>): HttpRequest<object> {
    if (this.appConfig.cdnStorage.enabled && (request.method === "GET" || request.method === "HEAD")) {
      const filename = request.url.substring(request.url.lastIndexOf("/") + 1);
      const ext = filename.indexOf(".") >= 0 ? filename.substring(filename.lastIndexOf(".")) : null;

      if ((ext === ".html" || ext === ".css") && request.url.substring(0, 1) === "/") {
        return request.clone({
          url: this.appConfig.cdnStorage.url + request.url,
        });
      }
    }

    return request;
  }
}
