import { Pipe, PipeTransform } from "@angular/core";
import { TodoItem } from "@webapp/home/components/widgets/todos-widget/todos-widget.models";
import { isDateInThePast } from "./utils";

@Pipe({
  name: "isTodoOverdue",
  standalone: true,
})
export class IsTodoOverduePipe implements PipeTransform {
  public transform(todo: TodoItem): boolean {
    if (!todo) {
      return false;
    }

    const date = "dueDate" in todo && todo.dueDate;
    const isMarkedAsOverdue = "isOverdue" in todo && todo.isOverdue;

    return isMarkedAsOverdue || isDateInThePast(date);
  }
}
