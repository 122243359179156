<ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>

<div>
  <div class="title">
    {{ "user_edited_kpi" | localize: { names: notification.notificationData.actor.names } }}
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon"><i class="fs-20px" [uiTooltipTitle]="'kpi' | localize" ui-icon ui-tooltip uiTheme="fill" uiType="kpi"></i></div>
    <span class="item">{{ notification.notificationData.item.kpiName }}</span>
  </div>

  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <div class="heading">{{ "projection" | localize }}:</div>

        <div *ngIf="notification.operationType === 'kpi_projection_user_created' || notification.operationType === 'kpi_projection_team_created'">
          <div class="list-item">{{ "projection_added" | localize }}:</div>
          {{ "value_on_date" | localize: { value: notification.notificationData.item.value, date: notification.notificationData.item.date | date: "dd MMM, yyyy" } }}
        </div>

        <div *ngIf="notification.operationType === 'kpi_projection_user_deleted' || notification.operationType === 'kpi_projection_team_deleted'">
          <div class="list-item">{{ "projection_deleted" | localize }}:</div>
          {{ "value_on_date" | localize: { value: notification.notificationData.item.value, date: notification.notificationData.item.date | date: "dd MMM, yyyy" } }}
        </div>

        <div *ngIf="notification.operationType === 'kpi_projection_user_modified' || notification.operationType === 'kpi_projection_team_modified'">
          <div>
            <div class="list-item">{{ "from" | localize }}:</div>
            <span *ngIf="notification.notificationData.item.changes.value.newValue && notification.notificationData.item.changes.date.newValue">
              {{
                "value_on_date" | localize: { value: notification.notificationData.item.changes.value.newValue, date: notification.notificationData.item.changes.date.newValue | date: "dd MMM, yyyy" }
              }}
            </span>
            <span *ngIf="notification.notificationData.item.changes.value.newValue && !notification.notificationData.item.changes.date.newValue">
              {{ "value_on_date" | localize: { value: notification.notificationData.item.changes.value.newValue, date: notification.notificationData.item.date | date: "dd MMM, yyyy" } }}
            </span>
            <span *ngIf="!notification.notificationData.item.changes.value.newValue && notification.notificationData.item.changes.date.newValue">
              {{ "value_on_date" | localize: { value: notification.notificationData.item.value, date: notification.notificationData.item.date | date: "dd MMM, yyyy" } }}
            </span>
          </div>

          <div>
            <div class="list-item">{{ "to_change_state" | localize }}:</div>
            <span *ngIf="notification.notificationData.item.changes.value.oldValue && notification.notificationData.item.changes.date.oldValue">
              {{
                "value_on_date" | localize: { value: notification.notificationData.item.changes.value.oldValue, date: notification.notificationData.item.changes.date.oldValue | date: "dd MMM, yyyy" }
              }}
            </span>
            <span *ngIf="notification.notificationData.item.changes.value.oldValue && !notification.notificationData.item.changes.date.oldValue">
              {{ "value_on_date" | localize: { value: notification.notificationData.item.changes.value.oldValue, date: notification.notificationData.item.date | date: "dd MMM, yyyy" } }}
            </span>
            <span *ngIf="!notification.notificationData.item.changes.value.oldValue && notification.notificationData.item.changes.date.oldValue">
              {{ "value_on_date" | localize: { value: notification.notificationData.item.value, date: notification.notificationData.item.date | date: "dd MMM, yyyy" } }}
            </span>
          </div>
        </div>
      </div>

      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_kpi" | localize }}</a>
      </div>
    </div>
  </div>
</div>
