import { ChangeDetectionStrategy, Component } from "@angular/core";
import { localize } from "@gtmhub/localization";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";
import { ICreateFirstWhitbeboardCard } from "./models";

@Component({
  selector: "create-your-first-whiteboard",
  templateUrl: "./create-your-first-whiteboard.component.html",
  styleUrls: ["./create-your-first-whiteboard.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateYourFirstWhiteboardComponent {
  public cards: ICreateFirstWhitbeboardCard[] = [
    {
      title: localize("start_with_suggestions"),
      description: localize("draft_okrs_personalized_suggestions"),
      icon: "artificial-intelligence",
      type: "suggestion",
      isPrimary: true,
    },
    {
      title: localize("start_with_template"),
      description: localize("use_ready_made_templates"),
      icon: "add-from-marketplace",
      type: "template",
    },
    {
      title: localize("start_with_blank_whiteboard"),
      description: localize("draft_okrs_sticky_notes"),
      icon: "whiteboards",
      type: "blankWhiteboard",
    },
  ];

  constructor(private modalRef: UiModalRef) {}

  public openCard(card: ICreateFirstWhitbeboardCard): void {
    this.modalRef.destroy(card.type);
  }
}
