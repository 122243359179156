import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ISelectorAssignee } from "@webapp/assignees/models/assignee.models";
import { ViewOnlyUserChain } from "@webapp/shared/components/people-selector/chains/view-only-user.chain";
import { CustomFilterChain } from "../chains/custom-filter.chain";
import { GoalFilterChain } from "../chains/goal-filter.chain";
import { IdFieldFilterChain } from "../chains/id-filter.chain";
import { LimitFilterChain } from "../chains/limit-filter.chain";
import { NameFilterChain } from "../chains/name-filter.chain";
import { PermissionFilterChain } from "../chains/permission-filter.chain";
import { TeamFilterChain } from "../chains/team-filter.chain";
import { UserFilterChain } from "../chains/user-filter.chain";
import { IHideSelectedQuery, IPeopleFilterChain, PeopleSelectorRequest, PeopleSelectorRequestInternal } from "../models/models";

@Injectable()
export class PeopleFilterBuilder {
  public filterChain: IPeopleFilterChain;
  public request: PeopleSelectorRequestInternal;

  public constructor(
    private teamFilterChain: TeamFilterChain,
    private userFilterChain: UserFilterChain,
    private goalFilterChain: GoalFilterChain,
    private permissionFilterChain: PermissionFilterChain,
    private limitFilterChain: LimitFilterChain,
    private idFieldFilterChain: IdFieldFilterChain,
    private viewOnlyUserChain: ViewOnlyUserChain,
    private nameFilterChain: NameFilterChain,
    private customFilterChain: CustomFilterChain
  ) {}

  public filter(assignees$: Observable<ISelectorAssignee[]>): Observable<ISelectorAssignee[]> {
    return this.filterChain.handle(this.request, assignees$);
  }

  public setRequest(request = new PeopleSelectorRequest()): PeopleFilterBuilder {
    this.request = request;
    return this;
  }

  public setNameQuery(query: string): PeopleFilterBuilder {
    this.request.nameQuery = query;
    return this;
  }

  public setNotInIdsQuery(query: IHideSelectedQuery): PeopleFilterBuilder {
    if (query.enabled) {
      this.request.notInIdFieldQuery = query.ids;
    }
    return this;
  }

  public configureChain(): PeopleFilterBuilder {
    /*
    Order is important for performance reasons, not for implementation reasons.
    Type Filter Chains are always first to reduce the collection size.
    IdFilterChain should be after Type Filter Chain since we do not want to include already selected in the results.
    NameFilterChain should be after IdFilterChain since we always want to reduce the collection size before the next filter.
    CustomFilterChain determines if an assignee to be displayed at all based on a custom callback - shouldDisplayAssignee.
    LimitFilterChain should be after Type & Name Filter Chains because it needs to always get the same amount of data.
    GoalFilterChain & PermissionFilterChain make requests, therefore they should follow the LimitFilterChain.
    GoalFilterChain should be after PermissionFilterChain because it needs to enrich only in case PermissionFilterChain didn't.
    */
    this.filterChain = this.teamFilterChain;
    this.filterChain.setNextChain(this.userFilterChain);
    // Id and Name could potentially be unified into a more abstract FieldFilterChain
    this.userFilterChain.setNextChain(this.idFieldFilterChain);
    this.idFieldFilterChain.setNextChain(this.nameFilterChain);
    this.nameFilterChain.setNextChain(this.customFilterChain);
    this.customFilterChain.setNextChain(this.limitFilterChain);
    this.limitFilterChain.setNextChain(this.permissionFilterChain);
    this.permissionFilterChain.setNextChain(this.goalFilterChain);
    this.goalFilterChain.setNextChain(this.viewOnlyUserChain);

    return this;
  }
}
