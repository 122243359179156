import { IHttpInterceptor, IHttpInterceptorFactory, IPromise, IRequestConfig, auto } from "angular";
import { TokenProvider } from "@gtmhub/auth";

export class TokenRefreshInterceptor implements IHttpInterceptor {
  constructor(private $injector: auto.IInjectorService) {}

  public request = (config: IRequestConfig): IPromise<IRequestConfig> | IRequestConfig => {
    if (config.headers["Authorization"]) {
      // Update the Authorization header only if it existed before
      // The Authorization header is sometimes intentionally removed from the request
      const tokenProvider: TokenProvider = this.$injector.get("TokenProvider");

      return tokenProvider
        .getValidToken()
        .then((token) => {
          config.headers["Authorization"] = `Bearer ${token}`;

          return config;
        })
        .catch((err) => {
          if (err.expiredToken) {
            config.headers["Authorization"] = `Bearer ${err.expiredToken}`;
          }
          return config;
        });
    }

    return config;
  };
  public static factory(): IHttpInterceptorFactory {
    const factory = ($injector: auto.IInjectorService) => new TokenRefreshInterceptor($injector);
    factory.$inject = ["$injector"];
    return factory;
  }
}
