import { IHttpResponse } from "angular";
import { HttpErrorResponse } from "@angular/common/http";
import { isUserDeactivatedOrDeletedResponse } from "@gtmhub/core/routing";
import { isUserDeactivatedOrDeletedNg2Response } from "@gtmhub/core/routing/util";
import { IAppConfig } from "@gtmhub/env";
import { localize } from "@gtmhub/localization";
import { LoggingService } from "@webapp/core/logging/services/logging.service";
import { IIndicator, IUIError } from "./models";

const isObject = (input: unknown): input is object => typeof input === "object" && !Array.isArray(input) && input !== null;

export const logCaughtJsError = <T>(input: T, appConfig: IAppConfig): void => {
  // for some reason js errors occurring in chained returned promises are not displayed in console and not logged
  // if we catch such an error in catch block -> log it and console.error it
  if (input instanceof Error) {
    console.error(input);
    const loggingService = new LoggingService(appConfig.logging, "spa");
    loggingService.logError(input);
  }
};

export const humanError = (error: unknown): string => {
  const uiError = createUIError(error);
  return localize(uiError.message || uiError.title);
};

export const createUIError = <T>(input: T): IUIError => {
  if (isObject(input)) {
    const response = input as unknown as IHttpResponse<unknown>;

    // the input should in most cases be a HTTP response from $http service
    if ("xhrStatus" in input) {
      // checking the type of response.data because when not a string, the object has to be created
      // using createUIErrorFromResponseBodyError fn
      if (response.data && typeof response.data !== "string") {
        return createUIErrorFromNg1ResponseBodyError(response);
      }

      return uiErrorFromNg1Response(response);
    } else if (input instanceof HttpErrorResponse) {
      if (input.error && typeof input.error !== "string" && input.error["error"]) {
        return createUIErrorFromNg2ResponseBodyError(input);
      }

      return uiErrorFromNg2Response(input);
    }

    // browser errors have message prop so we are checking for title and body which are part of IUIError
    if ("title" in input || "body" in input) {
      return input as IUIError;
    }
  }

  return {
    title: "unexpected_error_title",
    message: "unexpected_error_message",
    showIntercomLink: true,
    statusCode: 1000,
  };
};

const createUIErrorFromNg2ResponseBodyError = (response: HttpErrorResponse): IUIError => {
  let title: string;
  let message: string;
  const uiError: IUIError = {
    body: `Error message: '${response.error["error"]}'.`,
    statusCode: typeof response.status === "number" ? response.status : 1000,
    title,
    message,
    transactionId: response.headers ? response.headers["transaction-id"] : null,
  };
  return uiError;
};

const createUIErrorFromNg1ResponseBodyError = <T>(response: IHttpResponse<T>): IUIError => {
  let title: string;
  let message: string;
  const uiError: IUIError = {
    body: `Error message: '${response.data["error"]}'.`,
    statusCode: response.status && typeof response.status === "number" ? response.status : 1000,
    title,
    message,
    transactionId: response.config.headers ? response.config.headers["transaction-id"] : null,
  };
  return uiError;
};

const uiErrorFromNg1Response = <T>(response: IHttpResponse<T>): IUIError => {
  if (!response) {
    return null;
  }

  // When user is deactivated, it is handled by another interceptor.
  if (isUserDeactivatedOrDeletedResponse(response)) {
    return null;
  }

  let title: string;
  let message: string;
  let showIntercomLink: boolean;

  switch (response.status) {
    case 0:
      title = "connection_error_title_1";
      message = "connection_error_message_1";
      break;

    case 400:
      title = "bad_request_error_title";
      message = "bad_request_error_message";
      showIntercomLink = true;
      break;

    case 401:
      title = "unauthorized_error_title";
      message = "unauthorized_error_message";
      showIntercomLink = true;
      break;

    case 402:
      // When account is suspended, it is handled by another interceptor
      return null;

    case 403:
      title = "forbidden_error_title";
      message = "forbidden_error_message";
      showIntercomLink = true;
      break;

    case 404:
      title = "not_found_error_title";
      message = "not_found_error_message";
      break;

    case 409:
      title = "conflict_error_title";
      message = "conflict_error_message";
      break;

    case 413:
      title = "bad_request_error_title";
      message = "file_too_large";
      break;

    case 423:
      title = "locked_error_title";
      message = "locked_error_message";
      break;

    case 500:
      title = "server_error_title";
      message = "server_error_message";
      showIntercomLink = true;
      break;

    case -1:
      title = "denied_error_title";
      message = "denied_error_message";
      showIntercomLink = true;
      break;

    default:
      title = "unexpected_error_title";
      message = "unexpected_error_message";
      showIntercomLink = true;
      break;
  }

  const contentType = response.headers("Content-Type");
  const hasPlainTextContentType = contentType && contentType.startsWith("text/plain");
  const uiError: IUIError = {
    body: hasPlainTextContentType ? `Error message: '${response.data}'.` : null,
    statusCode: response.status && typeof response.status === "number" ? response.status : 1000,
    title,
    message,
    transactionId: response.config.headers ? response.config.headers["transaction-id"] : null,
  };

  if (response.status === 0) {
    uiError.connectionError = true;
  }
  if (response.status === 404) {
    uiError.notFound = true;
  }
  if (showIntercomLink) {
    uiError.showIntercomLink = true;
  }

  return uiError;
};

const uiErrorFromNg2Response = (response: HttpErrorResponse): IUIError => {
  if (!response) {
    return null;
  }

  // When user is deactivated, it is handled by another interceptor.
  if (isUserDeactivatedOrDeletedNg2Response(response)) {
    return null;
  }

  let title: string;
  let message: string;
  let showIntercomLink: boolean;

  switch (response.status) {
    case 0:
      title = "connection_error_title_1";
      message = "connection_error_message_1";
      break;

    case 400:
      title = "bad_request_error_title";
      message = "bad_request_error_message";
      showIntercomLink = true;
      break;

    case 401:
      title = "unauthorized_error_title";
      message = "unauthorized_error_message";
      showIntercomLink = true;
      break;

    case 402:
      // When account is suspended, it is handled by another interceptor
      return null;

    case 403:
      title = "forbidden_error_title";
      message = "forbidden_error_message";
      showIntercomLink = true;
      break;

    case 404:
      title = "not_found_error_title";
      message = "not_found_error_message";
      break;

    case 409:
      title = "conflict_error_title";
      message = "conflict_error_message";
      break;

    case 413:
      title = "bad_request_error_title";
      message = "file_too_large";
      break;

    case 423:
      title = "locked_error_title";
      message = "locked_error_message";
      break;

    case 500:
      title = "server_error_title";
      message = "server_error_message";
      showIntercomLink = true;
      break;

    case -1:
      title = "denied_error_title";
      message = "denied_error_message";
      showIntercomLink = true;
      break;

    default:
      title = "unexpected_error_title";
      message = "unexpected_error_message";
      showIntercomLink = true;
      break;
  }

  const contentType = response.headers["Content-Type"];
  const hasPlainTextContentType = contentType && contentType.startsWith("text/plain");
  const uiError: IUIError = {
    body: hasPlainTextContentType ? `Error message: '${response.error}'.` : null,
    statusCode: response.status && typeof response.status === "number" ? response.status : 1000,
    title,
    message,
    transactionId: response.headers["transaction-id"],
  };

  if (response.status === 0) {
    uiError.connectionError = true;
  }
  if (response.status === 404) {
    uiError.notFound = true;
  }
  if (showIntercomLink) {
    uiError.showIntercomLink = true;
  }

  return uiError;
};

export const customNotFoundError = (): IUIError => {
  return { title: "not_found_error_title", message: "not_found_error_message" };
};

export const isErrorIndicator = (indicator: IIndicator): indicator is { error: unknown } => {
  return indicator && "error" in indicator;
};

export const isProgressIndicator = (indicator: IIndicator): indicator is { progress: true } => {
  return indicator && "progress" in indicator && indicator.progress;
};
