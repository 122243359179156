import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, combineLatest, first, map, zip } from "rxjs";
import { OnboardingService } from "@gtmhub/onboarding";
import { HomeWidgetSettings } from "@gtmhub/users/models";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureModuleService } from "@webapp/feature-toggles/services/feature-module.service";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { HomeWidgetFacade } from "@webapp/home/services/home-widget-facade.service";
import { setWidgetsPositions, stretchSingleWidgetsToFullWidth, toDTO } from "@webapp/home/utils/home-widget.util";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { UiDashboardWidget } from "@webapp/ui/dashboard/dashboard.models";
import { CurrentUserRepository } from "@webapp/users";
import { handleWidgetsChange } from "../components/home-dashboard/home-dashboard.utils";
import { SUPPORTED_WIDGETS } from "../models/home-widgets.models";

const count = (b: boolean): number => (b ? 1 : 0);

@Injectable()
export class HomeWidgetService {
  private widgets: UiDashboardWidget[] = [];
  private availability: Record<string, boolean>;
  private changes$ = new BehaviorSubject<void>(undefined);
  private availability$: Observable<Record<string, boolean>>;
  private areWidgetsPersisted: boolean;

  constructor(
    private featureModuleService: FeatureModuleService,
    private permissionsFacade: PermissionsFacade,
    private featureTogglesFacade: FeatureTogglesFacade,
    private currentUserRepository: CurrentUserRepository,
    private onboardingService: OnboardingService,
    private editionFeatureService: EditionFeatureService,
    private homeWidgetFacade: HomeWidgetFacade
  ) {
    this.homeWidgetFacade
      .getWidgets$()
      .pipe(first())
      .subscribe({
        next: (response) => {
          this.areWidgetsPersisted = response?.items?.length > 0;
          // create new widget object instances as not to mutate the original entities
          this.widgets = SUPPORTED_WIDGETS.map((widget) => ({ ...widget }));
          setWidgetsPositions(this.widgets, response);
          this.changes$.next();
        },
      });
  }

  public getDashboardWidgets$(): Observable<UiDashboardWidget[]> {
    if (!this.availability$) {
      const shouldSeeOnboardingExperiment = this.onboardingService.shouldSeeOnboardingExperiment();
      const isViewOnly = this.currentUserRepository.userHasRole("view-only");
      const closed = isViewOnly ? this.getClosedWidgetIdsFromUserSettings() : [];

      this.availability$ = zip([
        this.isMyOkrsWidgetAvailable$(),
        this.isCheckInsWidgetAvailable$({ isViewOnly }),
        this.isTodosWidgetAvailable$({ isViewOnly }),
        this.isWhiteboardsWidgetAvailable$(),
      ]).pipe(
        map(([isMyOkrsWidgetAvailable, isCheckInsWidgetAvailable, isTodosWidgetAvailable, isWhiteboardsWidgetAvailable]) => {
          const mainWidgetsCount =
            count(isMyOkrsWidgetAvailable) + count(isCheckInsWidgetAvailable) + count(isTodosWidgetAvailable) + count(isWhiteboardsWidgetAvailable);
          return {
            "check-ins": isCheckInsWidgetAvailable,
            whiteboards: isWhiteboardsWidgetAvailable,
            "to-do": isTodosWidgetAvailable,
            "my-okrs": isMyOkrsWidgetAvailable,
            "view-alignment": isViewOnly && !closed.includes("view-alignment"),
            "view-feed": isViewOnly && !closed.includes("view-feed"),
            "kickstart-okr": (mainWidgetsCount <= 1 || isViewOnly) && !closed.includes("kickstart-okr"),
            "view-only-role": isViewOnly && !closed.includes("view-only-role"),
            onboarding: shouldSeeOnboardingExperiment && !this.getClosedWidgetIdsFromUserSettings().includes("onboarding"),
          };
        })
      );
    }
    return combineLatest([this.availability$, this.changes$]).pipe(
      map(([availability]) => {
        this.availability = availability;
        const availableWidgets = this.widgets.filter((widget) => availability[widget.id] !== false);

        if (!this.areWidgetsPersisted) {
          stretchSingleWidgetsToFullWidth(availableWidgets);
        }

        return availableWidgets;
      })
    );
  }

  public handleWidgetsChange(
    changedWidgets: UiDashboardWidget[],
    options: { checkForY?: boolean; persistChanges?: boolean } = { checkForY: false, persistChanges: false }
  ): void {
    const widgets = handleWidgetsChange(this.widgets, changedWidgets, options);

    if (this.widgets !== widgets) {
      this.widgets = widgets;
      if (options.persistChanges) {
        if (this.areWidgetsPersisted) {
          this.updatePosition(changedWidgets);
        } else {
          const availableWidgets = this.widgets.filter((widget) => this.availability[widget.id] !== false);
          this.updatePosition(availableWidgets);
        }
        this.changes$.next();
      }
    }
  }

  public addWidget(widget: UiDashboardWidget): void {
    const widgetExists = SUPPORTED_WIDGETS.some((w) => w.id === widget.id);
    if (!widgetExists) {
      throw new Error(`Widget ${widget.id} does not exist`);
    }

    this.widgets.push(widget);
    this.changes$.next();
    this.unmarkWidgetAsClosedInUserSettings(widget.id);
  }

  public removeWidget(widgetId: string): void {
    const index = this.widgets.findIndex((w) => w.id === widgetId);
    if (index < 0) {
      throw new Error(`Widget ${widgetId} is not present`);
    }

    this.widgets.splice(index, 1);
    this.changes$.next();
    this.markWidgetAsClosedInUserSettings(widgetId);
  }

  private isCheckInsWidgetAvailable$(args: { isViewOnly: boolean }): Observable<boolean> {
    return zip([
      this.featureModuleService.isReflectionsModuleEnabled$(),
      this.permissionsFacade.hasPermission$("AccessPeople"),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.FeatureReflections),
    ]).pipe(
      map(([isCheckInsModuleEnabled, hasAccessPeople, isCheckInsFeatureAvailable]) => {
        return isCheckInsModuleEnabled && hasAccessPeople && isCheckInsFeatureAvailable && !args.isViewOnly;
      })
    );
  }

  private isWhiteboardsWidgetAvailable$(): Observable<boolean> {
    return this.featureModuleService.isWhiteboardModuleEnabled$();
  }

  private isTodosWidgetAvailable$(args: { isViewOnly: boolean }): Observable<boolean> {
    return zip([
      // required for okr approvals and kr updates
      this.permissionsFacade.hasPermission$("ManageGoals"),
      this.permissionsFacade.hasPermission$("AccessGoals"),
      // required for check-in updates
      this.featureModuleService.isReflectionsModuleEnabled$(),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.FeatureReflections),
      this.permissionsFacade.hasPermission$("AccessPeople"),
      // required for task updates
      this.featureModuleService.isTaskModuleEnabled$(),
      this.permissionsFacade.hasPermission$("ManageTasks"),
    ]).pipe(
      map(
        ([hasManageGoals, hasAccessGoals, checkInsModuleEnabled, checkInsFeatFlagEnabled, hasAccessPeoplePermission, isTasksModuleEnabled, hasManageTasksPermission]) => {
          const canUpdateCheckIns = checkInsModuleEnabled && checkInsFeatFlagEnabled && hasAccessPeoplePermission;
          const canUpdateTasks = isTasksModuleEnabled && hasManageTasksPermission;
          const canUpdateOkrs = hasManageGoals && hasAccessGoals;

          return !args.isViewOnly && (canUpdateOkrs || canUpdateCheckIns || canUpdateTasks);
        }
      )
    );
  }

  private isMyOkrsWidgetAvailable$(): Observable<boolean> {
    return zip([this.permissionsFacade.hasPermission$("AccessGoals"), this.editionFeatureService.hasFeature$("okrs")]).pipe(
      map(([hasAccessGoalsPermission, hasOkrsFeature]) => hasAccessGoalsPermission && hasOkrsFeature)
    );
  }

  private getClosedWidgetIdsFromUserSettings(): string[] {
    const settings = this.currentUserRepository.getUserSetting<HomeWidgetSettings>("homeWidgets");
    return settings?.closed || [];
  }

  private markWidgetAsClosedInUserSettings(widgetId: string): void {
    let settings = this.currentUserRepository.getUserSetting<HomeWidgetSettings>("homeWidgets");
    if (!settings) {
      settings = {};
    }
    if (!settings.closed) {
      settings.closed = [];
    }
    if (settings.closed.includes(widgetId)) {
      return;
    }

    settings.closed.push(widgetId);
    this.currentUserRepository.setUserSetting({ homeWidgets: settings });
  }

  private unmarkWidgetAsClosedInUserSettings(widgetId: string): void {
    const settings = this.currentUserRepository.getUserSetting<HomeWidgetSettings>("homeWidgets");
    if (!settings?.closed) {
      return;
    }

    const index = settings.closed.indexOf(widgetId);
    if (index < 0) {
      return;
    }

    settings.closed.splice(index, 1);
    this.currentUserRepository.setUserSetting({ homeWidgets: settings });
  }

  public updatePosition(changedWidgets: UiDashboardWidget[]): void {
    this.areWidgetsPersisted = true;

    this.homeWidgetFacade
      .updateWidget$(toDTO(changedWidgets))
      .pipe(first())
      .subscribe({
        next: () => {},
        error: (err) => console.error(err),
      });
  }
}
