import { Injectable, OnDestroy } from "@angular/core";
import { IAssigneesStoreState, receiveAssignees } from "@gtmhub/assignees";
import { ReduxCache } from "@webapp/core/state-management/redux-cache";
import { Assignee } from "../models/assignee.models";

@Injectable({ providedIn: "root" })
export class AssigneesCache extends ReduxCache<IAssigneesStoreState, Record<string, Assignee>, Map<string, Assignee>> implements OnDestroy {
  constructor() {
    super({
      selector: (state) => (state.assignees.isFetched ? state.assignees.map : undefined),
      setAction: (value) => receiveAssignees(Array.from(value.values())),
      storeToCache: (value) => (value != null ? new Map(Object.entries(value)) : undefined),
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe?.();
  }
}
