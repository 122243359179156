<div class="search-page-container">
  <header class="search-header-container">
    <div class="search-filters">
      <i class="fs-16px" ui-icon uiType="search" data-test-id="global-search-icon"></i>
      <input
        class="search-input fs-14px"
        id="search-input"
        [(ngModel)]="searchTerm"
        [uiBorderless]="true"
        [placeholder]="'search' | localize"
        [modelChangeDebounce]="500"
        (ngModelChange)="onSearchTermChange()"
        ui-input
        role="searchbox"
        data-test-id="nav-bar-global-search-input" />
      <ui-divider uiType="vertical"></ui-divider>
      <search-facet-menu class="search-facet-menu-dropdown" [facetsToHide]="facetsToHide"></search-facet-menu>
    </div>
  </header>
  <article class="search-article">
    <search-results [maxItems]="itemsLimit" searchMode="search-page"></search-results>
  </article>
</div>

<footer class="search-footer">
  <ng-container *ngIf="searchResults && searchResults[0]?.totalCount > 20 && !hidePagination">
    <ui-divider class="sp-mb-0 sp-mt-0" uiType="horizontal"></ui-divider>
    <nav [uiPageIndex]="pageIndex" [uiTotal]="searchResults[0]?.totalCount" [uiPageSize]="20" (uiPageIndexChange)="pageChange($event)" ui-pagination></nav>
  </ng-container>
</footer>
