import { AsyncPipe, NgForOf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UiDropDownDirective, UiDropdownMenuComponent } from "@quantive/ui-kit/dropdown";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiMenuDirective } from "@quantive/ui-kit/menu";
import { Observable } from "rxjs";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { localize } from "@webapp/localization/utils/localization.utils";
import { FocusParams, NextPrevItem, OpenSelectKeys, SearchFacetsOptions, SearchFacetsOptionsEnum } from "@webapp/search/models/search.models";
import { FilterFacetOptionsPipe } from "@webapp/search/pipes/filter-facet-options.pipe";
import { GlobalSearchMediatorService } from "@webapp/search/services/global-search.mediator.service";
import { moveFocusTo } from "@webapp/search/utils/global-search.utils";

@Component({
  selector: "search-facet-menu",
  standalone: true,
  imports: [AsyncPipe, FilterFacetOptionsPipe, LocalizationModule, UiDropDownDirective, UiDropdownMenuComponent, UiIconModule, NgForOf, UiMenuDirective],
  templateUrl: "./search-facet-menu.component.html",
  styleUrl: "./search-facet-menu.component.less",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFacetMenuComponent implements OnInit {
  @Input()
  public facetsToHide: SearchFacetsOptions[] = [SearchFacetsOptionsEnum.Roles];

  @Output() public readonly facetMenuFocusout = new EventEmitter<FocusEvent>();

  public isFacetMenuOpen: boolean;
  protected currentFacet$: Observable<SearchFacetsOptions>;
  protected readonly facetOptions$: Observable<SearchFacetsOptions[]>;

  @ViewChild("currentFacet") private currentFacet: ElementRef;
  @ViewChild("facetMenu") private facetMenu: ElementRef;

  constructor(
    private globalSearchMediatorService: GlobalSearchMediatorService,
    private cdr: ChangeDetectorRef
  ) {
    this.facetOptions$ = this.globalSearchMediatorService.facetToggleOptions$;
  }

  public ngOnInit(): void {
    this.currentFacet$ = this.globalSearchMediatorService.currentFacet$;
  }

  public focusCurrentFacet(): void {
    this.currentFacet?.nativeElement?.focus();
  }

  public moveFocusTo(to: NextPrevItem): void {
    const params: FocusParams = {
      siblingSelectors: "[ui-menu][data-test-id=global-facet-dropdown-menu] li",
      parentElementId: "facet-menu-button",
      to,
    };
    const shouldCloseFacetMenu = moveFocusTo(params);
    if (shouldCloseFacetMenu) {
      this.isFacetMenuOpen = false;
    }
  }

  public changeFacet(facet: SearchFacetsOptions): void {
    this.isFacetMenuOpen = false;
    // Set the current page to 1 when changing the facet for the search page
    // Following info is used on facet change in the search page
    this.globalSearchMediatorService.setCurrentPageInfo({
      pageNumber: 1,
      selectedFacet: facet,
    });
    this.globalSearchMediatorService.setCurrentFacet(facet);
    this.focusCurrentFacet();
    this.cdr.markForCheck();
  }

  public onFacetMenuOpen(): void {
    window.setTimeoutOutsideAngular(() => {
      for (const c of this.facetMenu.nativeElement.children) {
        if (c.innerText.includes(localize(this.currentFacet.nativeElement.innerText))) {
          c.focus();
        }
      }
    });
  }

  public openFacetDropdown(evt: KeyboardEvent): void {
    if (OpenSelectKeys.find((f) => f === evt.key)) {
      // Click on nativeElement is used to fire emit event for opening the dropdown menu. Then we can set focus on the current facet when menu is visible.
      this.currentFacet.nativeElement.click();
    }
  }
}
