import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { localize } from "@gtmhub/localization";
import { sendIntercomMessage } from "@gtmhub/shared/intercom";
import { UiToastService } from "@webapp/ui/toast/services/toast.service";
import { ErrorAction } from "../error-util";
import { actionsMap } from "../toast-error-utils-insightboards";

@Injectable({
  providedIn: "root",
})
export class ToastErrorHandlingService {
  constructor(private toastService: UiToastService) {}

  public handleError(error: HttpErrorResponse, action: ErrorAction, retryAction: () => void): void {
    const { title, intercomAction } = actionsMap[action];
    this.toastService.error(title, {
      uiPlacement: "top",
      uiPrimaryCTA: localize("try_again_cap"),
      uiSecondaryCTA: localize("contact_us"),
      uiOffset: 38,
      uiOnPrimaryAction: () => retryAction(),
      uiOnSecondaryAction: () => this.sendIntercomError(error, intercomAction),
    });
  }

  public sendIntercomError(error: HttpErrorResponse, intercomAction: string): void {
    sendIntercomMessage(
      `Unexpected error occurred while ${intercomAction}.\nWhat could be done to solve this issue?\n${error.status} ${error.statusText}: ${error.error}\nTransaction ID: ${error.headers["transaction-id"]}`
    );
  }
}
