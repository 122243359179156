const DISPLAY_KEYS_COMMON = {
  UP: "↑",
  DOWN: "↓",
  LEFT: "←",
  RIGHT: "→",
  CAPSLOCK: "CAPS",
};

export const MODIFIER_KEYS_MAC = ["CTRL", "OPTION", "SHIFT", "COMMAND"];
export const ALIAS_KEYS_MAC = {
  MOD: "COMMAND",
  META: "COMMAND",
};
export const DISPLAY_KEYS_MAC = {
  MOD: "⌘",
  META: "⌘",
  COMMAND: "⌘",
  SHIFT: "⇧",
  CTRL: "⌃",
  OPTION: "⌥",
  ...DISPLAY_KEYS_COMMON,
};

export const MODIFIER_KEYS_WIN = ["WIN", "CTRL", "ALT", "SHIFT"];
export const ALIAS_KEYS_WIN = {
  MOD: "CTRL",
  META: "WIN",
};
export const DISPLAY_KEYS_WIN = {
  MOD: "CTRL",
  META: "⊞",
  WIN: "⊞",
  SHIFT: "SHIFT",
  CTRL: "CTRL",
  ...DISPLAY_KEYS_COMMON,
};

export const byModifierKeys =
  (modifierKeys: string[]) =>
  (a: string, b: string): number => {
    const aIndex = modifierKeys.indexOf(a);
    const bIndex = modifierKeys.indexOf(b);

    // if the key is not a modifier, give it a high index so that it comes last
    return (aIndex < 0 ? modifierKeys.length : aIndex) - (bIndex < 0 ? modifierKeys.length : bIndex);
  };
