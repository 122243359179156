import { module } from "angular";
import employeesCore from "@gtmhub/employees/employees-core.module";
import env from "@gtmhub/env/env.module";
import teamsCore from "@gtmhub/teams/teams-core.module";
import { attachAssigneeAvatarEnhancements } from "./assignee-avatar-enhancements";
import { AssigneeSelectorModal } from "./assignee-selector-modal";
import { AssigneeDetailsPopoverComponent } from "./components/assignee-details-popover/assignee-details-popover.component";
import { DynamicAssigneeTooltipComponent } from "./components/dynamic-assignee-tooltip/dynamic-assignee-tooltip.component";
import { DynamicAssigneeTooltipService } from "./components/dynamic-assignee-tooltip/dynamic-assignee-tooltip.service";
import { OwnerSelectorCtrl } from "./controllers/owner-selector";
import { SelectAssigneeModalCtrl } from "./controllers/select-assignee-modal-ctrl";
import { SelectMultipleAssigneesCtrl } from "./controllers/select-multiple-assignees-ctrl";
import { FromAssigneeIdDirective } from "./directives/from-assignee-id.directive";
import { InviteUser } from "./directives/invite-user";

const mod = module("assignees", [env, employeesCore, teamsCore]);

mod.component("assigneeDetailsPopover", AssigneeDetailsPopoverComponent);
mod.component("dynamicAssigneeTooltip", DynamicAssigneeTooltipComponent);

mod.controller("OwnerSelectorCtrl", OwnerSelectorCtrl);
mod.controller("SelectAssigneeModalCtrl", SelectAssigneeModalCtrl);
mod.controller("SelectMultipleAssigneesCtrl", SelectMultipleAssigneesCtrl);

mod.service("AssigneeSelectorModal", AssigneeSelectorModal);
mod.service("DynamicAssigneeTooltipService", DynamicAssigneeTooltipService);

mod.directive("inviteUser", InviteUser.factory());
mod.directive("fromAssigneeId", FromAssigneeIdDirective.factory());

attachAssigneeAvatarEnhancements(mod);

export default mod.name;
