import { ChangeDetectionStrategy, Component, Optional } from "@angular/core";
import { uiToNz } from "@quantive/ui-kit/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { ModalOptions, NzModalCloseComponent } from "ng-zorro-antd/modal";
import { UiModalOptions } from "../../modal.models";

@Component({
  selector: "button[ui-modal-close]",
  exportAs: "UiModalCloseBuiltin",
  templateUrl: "modal-close.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiIconModule, NzOutletModule],
})
export class UiModalCloseComponent extends NzModalCloseComponent {
  constructor(@Optional() config?: ModalOptions) {
    super(config || uiToNz(new UiModalOptions()));
  }
}
