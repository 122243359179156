/* eslint-disable @typescript-eslint/naming-convention */
import { Ng1StateDeclaration, StateService } from "@uirouter/angularjs";
import { removeFullNavSkeleton } from "@webapp/shared/skeleton/skeleton.utils";
import { CurrentUserRepository } from "@webapp/users";
import { getHomeActiveScreenState, modal } from "./core/routing/util";
import { IGtmhubRootScopeService } from "./models";

export class StateFactory {
  private _bootstrapStates: AccountBootstrapStates;
  private _bootstrapModalStates: AccountBootstrapModalStates;
  private _privacyNoticeStates: PrivacyNoticeStates;
  private _addAccountDetailsStates: AddAccountDetailsStates;
  private _globalSearchStates: GlobalSearchStates;
  private _multiAccountStates: MultiAccountStates;
  private _miscStates: MiscStates;
  private _notificationsUrlsRedirectStates: NotificationsUrlsRedirectStates;
  private _redirectToPlatformStates: RedirectToPlatformStates;

  public default(): Ng1StateDeclaration {
    return {
      url: "/",
      data: {
        trackingName: "Home Page",
      },
      redirectTo: () => getHomeActiveScreenState("gtmhub.home"),
    };
  }

  public rootProtectedState(): Ng1StateDeclaration {
    return {
      abstract: true,
      template: require("./main.html"),
      controller: "RootLayoutCtrl",
      data: {
        requiresLogin: true,
        requiresJwtProfile: true,
        requiresAccount: true,
      },
    };
  }

  public errorState(): Ng1StateDeclaration {
    return {
      url: "/error/",
      params: {
        error: null,
      },
      data: {
        requiresLogin: false,
        trackingPage: "Error Page",
      },
      template: require("./error-handling/views/error.html"),
      controller: "ErrorCtrl",
      onEnter: () => {
        removeFullNavSkeleton({ force: true });
      },
    };
  }

  public accountBootstrapStates(): AccountBootstrapStates {
    if (!this._bootstrapStates) {
      this._bootstrapStates = new AccountBootstrapStates();
    }
    return this._bootstrapStates;
  }

  public accountBootstrapModalStates(): AccountBootstrapModalStates {
    if (!this._bootstrapModalStates) {
      this._bootstrapModalStates = new AccountBootstrapModalStates();
    }
    return this._bootstrapModalStates;
  }

  public privacyNoticeStates(): PrivacyNoticeStates {
    if (!this._privacyNoticeStates) {
      this._privacyNoticeStates = new PrivacyNoticeStates();
    }
    return this._privacyNoticeStates;
  }

  public addAccountDetailsStates(): AddAccountDetailsStates {
    if (!this._addAccountDetailsStates) {
      this._addAccountDetailsStates = new AddAccountDetailsStates();
    }
    return this._addAccountDetailsStates;
  }

  public globalSearchStates(): GlobalSearchStates {
    if (!this._globalSearchStates) {
      this._globalSearchStates = new GlobalSearchStates();
    }
    return this._globalSearchStates;
  }

  public redirectToPlatformStates(): RedirectToPlatformStates {
    if (!this._redirectToPlatformStates) {
      this._redirectToPlatformStates = new RedirectToPlatformStates();
    }
    return this._redirectToPlatformStates;
  }

  public multiAccountStates(): MultiAccountStates {
    if (!this._multiAccountStates) {
      this._multiAccountStates = new MultiAccountStates();
    }
    return this._multiAccountStates;
  }

  public miscStates(): MiscStates {
    if (!this._miscStates) {
      this._miscStates = new MiscStates();
    }
    return this._miscStates;
  }

  public notificationsUrlsRedirectStates(): NotificationsUrlsRedirectStates {
    if (!this._notificationsUrlsRedirectStates) {
      this._notificationsUrlsRedirectStates = new NotificationsUrlsRedirectStates();
    }
    return this._notificationsUrlsRedirectStates;
  }
}

class AccountBootstrapModalStates {
  public accountBootstrapModalRoot(): Ng1StateDeclaration {
    return modal({
      view: "accountBootstrapModalView",
      windowClass: "modal",
      abstract: true,
      backdrop: false,
    });
  }

  public accountSwitchedRoot(): Ng1StateDeclaration {
    return {
      data: {
        requiresLogin: false,
        requiresJwtProfile: false,
        requiresAccount: false,
        trackingPage: "Account Switched",
      },
      params: {
        accountId: null,
        billingUrl: null,
      },
      views: {
        "accountBootstrapModalView@": {
          controller: "AccountSwitchedCtrl",
          template: require("./login/views/logout-switched.html"),
        },
      },
      url: "/account-switched",
    };
  }
}

class AccountBootstrapStates {
  public accountBootstrapRoot(): Ng1StateDeclaration {
    return {
      abstract: true,
      data: {
        requiresLogin: true,
        requiresJwtProfile: true,
        requiresAccount: false,
      },
      template: "<main ui-view></main>",
    };
  }

  public accountSuspendedRoot(): Ng1StateDeclaration {
    return {
      data: {
        requiresLogin: false,
        requiresJwtProfile: false,
        requiresAccount: true,
        trackingPage: "Account Suspended",
      },
      params: {},
      url: "/account-suspended",
      controller: "AccountSuspendedCtrl",
      template: require("./login/views/login-suspended.html"),
    };
  }

  public userDeactivatedRoot(): Ng1StateDeclaration {
    return {
      data: {
        requiresLogin: false,
        requiresJwtProfile: false,
        requiresAccount: false,
      },
      url: "/user-deactivated?transactionID",
      params: {
        transactionID: {
          value: null,
        },
      },
      controller: "UserDeactivatedCtrl",
      template: require("./login/views/user-deactivated.html"),
    };
  }

  public resolveToken(): Ng1StateDeclaration {
    return {
      data: {
        disableAnalytics: true,
        requiresLogin: false,
        requiresJwtProfile: false,
        requiresAccount: false,
        ignoreApm: true,
      },
      url: "/resolve-token/",
      template: "",
      controller: "ResolveTokenCtrl",
    };
  }

  public resolveAccount(): Ng1StateDeclaration {
    return {
      data: {
        disableAnalytics: true,
        ignoreApm: true,
        ignoreDrawerOpener: true,
      },
      params: {
        // This is only used locally or on PR links to provide the real Gtmhub domain for the user
        // (since we use fake hosts locally and on PR links)
        domain: null,
      },
      url: "/resolve-account/?domain",
      template: "",
      controller: "ResolveAccountCtrl",
    };
  }

  public createAccount(): Ng1StateDeclaration {
    return {
      template: require("./login/views/create-account.html"),
      controller: "CreateAccountController",
      onEnter: () => {
        removeFullNavSkeleton({ force: true });
      },
    };
  }
}

class PrivacyNoticeStates {
  public privacyNotice(): Ng1StateDeclaration {
    return {
      data: {
        requiresLogin: true,
        requiresAccount: true,
      },
      url: "/privacy-notice",
      component: "privacyNoticeComponent",
      onEnter: [
        "$rootScope",
        function ($rootScope: IGtmhubRootScopeService) {
          removeFullNavSkeleton({ force: true });
          $rootScope.noNavigation = true;
        },
      ],
      onExit: [
        "$rootScope",
        "$state",
        "CurrentUserRepository",
        function ($rootScope: IGtmhubRootScopeService, stateService: StateService, userService: CurrentUserRepository) {
          const showPrivacyNotice = !userService.getUserSetting<boolean>("hasAcceptedPrivacyNotice");
          if (showPrivacyNotice) {
            stateService.go("gtmhub.privacyNotice");
            return;
          }
          $rootScope.noNavigation = false;
        },
      ],
    };
  }
}

class AddAccountDetailsStates {
  public addAccountDetails(): Ng1StateDeclaration {
    return {
      name: "gtmhub.addAccountDetails",
      data: {
        requiresLogin: true,
        requiresAccount: true,
        trackingPage: "Sign-up Additional Form",
      },
      component: "accountMarketingInfoScreenComponent",
      url: "/account-details/",
      onEnter: [
        "$rootScope",
        function ($rootScope: IGtmhubRootScopeService) {
          removeFullNavSkeleton({ force: true });
          $rootScope.noNavigation = true;
        },
      ],
      onExit: [
        "$rootScope",
        function ($rootScope: IGtmhubRootScopeService) {
          $rootScope.noNavigation = false;
        },
      ],
    };
  }
}

class GlobalSearchStates {
  public globalSearch(): Ng1StateDeclaration {
    return {
      data: {
        requiresLogin: true,
        trackingPage: "Global Search",
        newLayout: true,
      },
      params: {
        facet: { dynamic: true },
        searchTerm: { dynamic: true },
        page: { dynamic: true },
      },
      url: "/search/?facet&searchTerm&page",
      component: "searchPage",
    };
  }
}

class RedirectToPlatformStates {
  public platformRedirect(): Ng1StateDeclaration {
    return {
      url: "/platform-redirect/?to",
      component: "platformRedirect",
      params: {
        to: { dynamic: true },
      },
      data: {
        requiresLogin: true,
        trackingPage: "Platform Redirect",
      },
    };
  }
}

class MultiAccountStates {
  public respondToMultiInvitation(): Ng1StateDeclaration {
    return {
      data: {
        requiresLogin: true,
        trackingPage: "Multi Account Invitation Response Page",
      },
      url: "/multi-account-invitation/?invitationId&action",
      template: require("./multi-account/views/multi-account-invitation.html"),
      controller: "MultiAccountInvitationResponseCtrl",
    };
  }
}

class MiscStates {
  public dispatch(): Ng1StateDeclaration {
    return {
      url: "/dispatch?itemType&itemID&target&targetID",
      data: {
        requiresLogin: false,
        ignoreApm: true,
      },
      template: "",
      controller: "DispatchCtrl",
    };
  }
}

// states below are used to redirect user when he is navigated to invalid/non-existing webapp url via notifications
// at some point if we have multiple redirects to one state we rename states to fromStartToDestination
class NotificationsUrlsRedirectStates {
  public fromUrlToStateRedirect(opts: { url: string; state: string }): Ng1StateDeclaration {
    return {
      url: opts.url,
      redirectTo: { state: opts.state },
    };
  }
}
