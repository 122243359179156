import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiIllustrationModule } from "@quantive/ui-kit/illustration";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { Observable, filter } from "rxjs";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { FacetIcon, GlobalSearchResult, SearchFacetsOptionsEnum, SearchMode } from "@webapp/search/models/search.models";
import { GlobalSearchMediatorService } from "@webapp/search/services/global-search.mediator.service";
import { facetIcons } from "@webapp/search/utils/global-search.utils";

@UntilDestroy()
@Component({
  selector: "no-search-items-found",
  templateUrl: "./no-search-items-found.component.html",
  styleUrls: ["./no-search-items-found.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LocalizationModule, UiTooltipModule, CommonModule, UiIconModule, UiButtonModule, UiIllustrationModule],
})
export class NoSearchItemsFoundComponent implements OnInit {
  @Input()
  public searchMode: SearchMode;

  protected searchTerm$: Observable<string>;
  protected searchResults$: Observable<GlobalSearchResult[]>;
  protected currentFacet: string;
  protected facetInfo: FacetIcon;

  constructor(private globalSearchMediator: GlobalSearchMediatorService) {}

  public searchEverywhere(): void {
    this.globalSearchMediator.setCurrentFacet(SearchFacetsOptionsEnum.Everywhere);
  }

  public ngOnInit(): void {
    this.searchTerm$ = this.globalSearchMediator.searchTerm$;
    this.searchResults$ = this.globalSearchMediator.searchResults$;
    this.globalSearchMediator.currentFacet$
      .pipe(
        untilDestroyed(this),
        filter((f) => !!f)
      )
      .subscribe((currentFacet) => {
        this.currentFacet = currentFacet;
        this.facetInfo = facetIcons.find((s) => this.currentFacet.includes(s.facet));
      });
  }
}
