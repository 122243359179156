<div
  class="btn-group"
  #buttonContainer
  [(uiVisible)]="visible"
  [attr.id]="'gh-dropdown-' + dropdownButtonId"
  [uiDropdownMenu]="menu"
  [uiOverlayClassName]="uiOverlayClassName"
  [uiPlacement]="placement"
  [uiDisabled]="disabled"
  (uiVisibleChange)="onVisibleChange($event)"
  ui-dropdown
  uiTrigger="click">
  <button
    *ngIf="!hasCustomButtonContent"
    [class.latest-active-dropdown-button]="visible"
    [attr.aria-label]="a11yLabel"
    [attr.aria-expanded]="visible ? true : null"
    [attr.aria-controls]="currentActiveItemIndex !== null ? 'gh-dropdown-menu' : null"
    [attr.data-test-id]="'icon-ellipsis'"
    [tabindex]="tabIndex"
    [disabled]="disabled"
    ui-button
    type="button"
    aria-haspopup="true">
    <i *ngIf="iconName" [uiType]="iconName" ui-icon></i>
    <ng-content select="[gh-dropdown-toggle]"></ng-content>
  </button>

  <ng-content *ngIf="hasCustomButtonContent" select="[gh-dropdown-custom-button-content]"></ng-content>

  <ui-dropdown-menu #menu>
    <dropdown-menu-item-group
      [attr.data-test-id]="e2eTestId"
      [menuItems]="menuItems"
      [dropdownMenuClass]="dropdownMenuClass"
      [activeDropdownItemChange$]="activeDropdownItemChange$"
      [currentActiveItemIndex]="currentActiveItemIndex"
      [currentActiveItem]="currentActiveItem"
      [testIdSuffix]="testIdSuffix"
      [showNewDesign]="showNewDesign"
      (menuKeyDown)="onKeyDown($event)"
      (closeMenu)="closeMenuAndEmitSelected($event)">
    </dropdown-menu-item-group>
  </ui-dropdown-menu>
</div>
