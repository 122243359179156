import { IController } from "angular";

export class GtmhubController implements IController {
  private destroyCallbacks: (() => void)[] = [];

  protected onDestroy(callback: () => void): void {
    this.destroyCallbacks.push(callback);
  }

  $onDestroy(): void {
    for (const callback of this.destroyCallbacks) {
      callback();
    }
  }
}
