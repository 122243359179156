<ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
<div class="title">
  <span *ngIf="notification.notificationData.item.isTeam && notification.operationType !== 'task_due_date_notification'">
    {{ "user_assigned_task_to_your_team" | localize: { names: notification.notificationData.actor.names } }}
  </span>
  <span *ngIf="!notification.notificationData.item.isTeam && notification.operationType !== 'task_due_date_notification'">
    {{ "user_assigned_you_task" | localize: { names: notification.notificationData.actor.names } }}
  </span>
  <span *ngIf="notification.operationType === 'task_due_date_notification'">
    {{ "your_task_is_due" | localize: { names: notification.notificationData.item.assignee.names } }} {{ notification.notificationData.item.dueDate | date: "yyyy-MM-dd" }}
  </span>
</div>
<div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
<div class="summary">
  <div class="message-icon">
    <i class="fs-20px" [uiType]="notification.targetType" [uiTooltipTitle]="notification.targetType | localize" ui-icon ui-tooltip uiTheme="fill"></i>
  </div>
  <span class="item">{{ notification.notificationData.item.name }}</span>
</div>
<div class="full">
  <div class="inbox-box">
    <div class="inbox-section">
      <div class="heading inline-notifications">
        {{ "owner_cap" | localize }}
      </div>
      : <ui-assignee [uiAssignee]="notification.notificationData.item.assignee | asAssignee" uiSize="sm"></ui-assignee>
    </div>
    <div class="inbox-section">
      <div class="heading inline-notifications">
        {{ "title" | localize }}
      </div>
      : {{ notification.notificationData.item.name }}
    </div>
    <div class="inbox-section">
      <div class="heading inline-notifications">
        {{ "description" | localize }}
      </div>
      : <rich-text-viewer [text]="notification.notificationData.item.description"></rich-text-viewer>
    </div>
    <div class="inbox-section">
      <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_task" | localize }}</a>
    </div>
  </div>
</div>
