import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "favorite-button",
  templateUrl: "./favorite-button.component.html",
  styleUrls: ["./favorite-button.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoriteButtonComponent {
  @Input()
  public isFavorite: boolean;
  @Input()
  public a11yLabel: string;
  @Output()
  public readonly addToFavorites = new EventEmitter<void>();
  @Output()
  public readonly removeFromFavorites = new EventEmitter<void>();

  public toggleFavorite(): void {
    if (this.isFavorite) {
      this.removeFromFavorites.emit();
    } else {
      this.addToFavorites.emit();
    }
  }
}
