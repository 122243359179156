import { Directive, Input, NgZone } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";

@Directive({
  selector: "[maxStringLength]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MaxStringLengthDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class MaxStringLengthDirective implements Validator {
  @Input("maxStringLength") public maxStringLength: number;

  constructor(private ngZone: NgZone) {}

  public validate(control: AbstractControl): ValidationErrors | null {
    return this.ngZone.run(() => {
      if (this.maxStringLength && control?.value?.length > this.maxStringLength) {
        return { maxStringLength: true };
      }
      return null;
    });
  }
}
