import { IHttpResponse, IHttpService, IPromise, IScope, ITemplateCacheService, auto, copy, equals } from "angular";
import { IModalInstanceService, IModalService, IModalServiceInstance, IModalSettings } from "angular-ui-bootstrap";
import { Ng1StateDeclaration, StateDeclaration, StateParams, StateService } from "@uirouter/angularjs";
import { HttpErrorResponse } from "@angular/common/http";
import { storage } from "@gtmhub/core/storage";
import { IUserSettings } from "@gtmhub/users";
import { OverlayComponentStateDeclarationBuilder } from "@webapp/core/routing/utils/overlay-components/overlay-component-state-declaration.builder";
import { OverlayComponentStateDeclarationFactory } from "@webapp/core/routing/utils/overlay-components/overlay-component-state-declaration.factory";
import { IStateSnapshot } from "@webapp/core/routing/utils/overlay-components/routing.models";

export interface IStateModalSettings extends IModalSettings {
  isStateModal: boolean;
}

export interface IModalComponent<Resolve = Record<string, unknown>> {
  close(args?: { $value: unknown }): void;
  dismiss(args?: { $value: unknown }): void;
  modalInstance: IModalInstanceService;
  resolve: Resolve;
}

type IModalStateBase = Omit<
  Ng1StateDeclaration,
  "template" | "templateUrl" | "templateProvider" | "controller" | "controllerProvider" | "controllerAs" | "onEnter" | "onExit" | "component" | "views"
> & {
  windowClass?: string;
  backdrop?: boolean;
  backdropClass?: string;
};
type IModalStateWithView = IModalStateBase & { view: string };
type IModalStateWithComponent = IModalStateBase & { component: string };
type IModalState = IModalStateWithView | IModalStateWithComponent;

interface IModalUtilScope extends IScope {
  mousedown(): void;
  mouseup(): void;
}

const createStateInitiatedModalSettings = (state: IModalState): IModalSettings => {
  const modalConfig: IStateModalSettings = {
    isStateModal: true,
    backdrop: state.backdrop !== false,
    windowClass: state.windowClass ? state.windowClass + " state-initiated" : "state-initiated",
    backdropClass: state.backdropClass || "",
    controller: [
      "$scope",
      function ($scope: IModalUtilScope) {
        storage.remove("modalCloseReason");
        $scope.mousedown = function () {
          storage.set("preventAccidentalOutisdeClick", true);
        };
        $scope.mouseup = function () {
          storage.set("preventAccidentalOutisdeClick", false);
        };

        $scope.$on("modal.closing", function (event) {
          if (storage.get("preventAccidentalOutisdeClick") === true) {
            storage.set("preventAccidentalOutisdeClick", false);
            event.preventDefault();
          }
        });
      },
    ],
  };

  if ("component" in state) {
    modalConfig.component = state.component;
  } else {
    modalConfig.template = `<div ui-view='${state.view}' ng-mousedown="mousedown()" ng-mouseup="mouseup()"></div>`;
  }

  return modalConfig;
};

const handleStateInitiatedModalCloseByStateChange = ($state: StateService, stateOpenerStack: IStateSnapshot[]) => {
  // reset the opener stack if we are going to a previous state in the stack
  const newStateName = $state.current.name;
  const newStateParams = copy($state.params);

  const stateIndex = stateOpenerStack.findIndex((x) => x.name === newStateName && equals(x.params, newStateParams));
  if (stateIndex >= 0) {
    stateOpenerStack.splice(stateIndex);
  }
};

const isModalState = (state: StateDeclaration): boolean => state.data && Object.prototype.hasOwnProperty.call(state.data, "drawer") && state.data.drawer === true;

const ignoreDrawerOpener = (state: StateDeclaration): boolean =>
  state.data && Object.prototype.hasOwnProperty.call(state.data, "ignoreDrawerOpener") && state.data.ignoreDrawerOpener === true;

const getParentState = ($state: StateService, state: StateDeclaration): StateDeclaration => $state.get("^", state);

const findFirstNonModalAndNonAbstractParentState = ($state: StateService): string => {
  // if the current modal state has opened a non-modal state, we need to find the parent of the modal state
  let modalState = $state.current;
  while (!isModalState(modalState)) {
    modalState = getParentState($state, modalState);
  }

  let returnState = getParentState($state, modalState);
  while (returnState.abstract) {
    returnState = getParentState($state, returnState);
  }

  return returnState.name;
};

const handleStateInitiatedModalCloseByModalClose = (
  $state: StateService,
  stateOpenerStack: IStateSnapshot[],
  options: { returnState?: string; opener?: IStateSnapshot; beforeNavigationState: IStateSnapshot }
) => {
  let gotoPromise: IPromise<unknown>;
  if (options.returnState) {
    gotoPromise = $state.go(options.returnState);
  } else if (options.opener) {
    gotoPromise = $state.go(options.opener.name, options.opener.params);
  } else {
    // we have opened the URL directly and we don't know the opener,
    // so go to the first non-abstract parent state
    const returnState = findFirstNonModalAndNonAbstractParentState($state);
    gotoPromise = $state.go(returnState);
  }

  gotoPromise.then(() => {
    // prevent endless loop when going back
    if (stateOpenerStack.length) {
      popLast(options.beforeNavigationState, stateOpenerStack);
    }
  });
};

class ModalNg1StateDeclarationFactory extends OverlayComponentStateDeclarationFactory<"ng1", IModalState> {
  public getStateDeclaration(state: IModalState): Ng1StateDeclaration {
    let modalInstance: IModalServiceInstance;

    const modalState: Ng1StateDeclaration = {
      data: {
        ...(state.data || {}),
        drawer: true,
      },
      params: {
        ...(state.params || {}),
        returnState: null,
      },
      template: "",
      onEnter: [
        "$uibModal",
        "$state",
        "$injector",
        ($uibModal: IModalService, $state: StateService, $injector: auto.IInjectorService) => {
          const stateSnapshot = (): IStateSnapshot => ({
            name: $state.current.name,
            params: copy($injector.get<StateParams>("$stateParams")),
          });

          // This is the state before we navigate to the new modal state
          const prevState = stateSnapshot();

          // if you want to prevent coming back to certain states,
          // you need to attach `ignoreDrawerOpener` data
          if (prevState.name && !ignoreDrawerOpener($state.get(prevState.name))) {
            this.stateManager.stateOpenerStack.push(prevState);
          }

          const modalSettings = createStateInitiatedModalSettings(state);

          this.stateManager.openerQueue.push(() => {
            modalInstance = $uibModal.open(modalSettings);
            modalInstance.result
              .then(
                () => null,
                (reason: string) => reason
              )
              .then((reason) => {
                storage.set("modalCloseReason", reason);

                // we have called dismiss() / close() on the modal instance from a controller or backdrop
                if (reason === "exiting") {
                  $state.transition.promise.then(() => handleStateInitiatedModalCloseByStateChange($state, this.stateManager.stateOpenerStack));
                  return;
                }

                const opener = this.stateManager.stateOpenerStack.pop();
                const beforeNavigationState = stateSnapshot();
                const returnState: string = beforeNavigationState.params.returnState;
                handleStateInitiatedModalCloseByModalClose($state, this.stateManager.stateOpenerStack, { returnState, opener, beforeNavigationState });
              });
            return Promise.resolve(modalInstance.opened);
          });
        },
      ],
      onExit: () => {
        // if we have a modal instance it means we are exiting this state using `$state.go`
        // or clicking on an element with ui-sref directive
        if (modalInstance) {
          modalInstance.dismiss("exiting");
          modalInstance = null;
        }
      },
    };

    const copyProps: (keyof IModalState)[] = ["lazyLoad", "name", "abstract", "resolve", "url"];
    for (const prop of copyProps) {
      if (state[prop]) {
        modalState[prop] = state[prop];
      }
    }

    return modalState;
  }
}

/**
 * Opens a modal when entering a state and closes it when exiting. When using
 * $close() / $dismiss() it will automatically return to the opener, unless
 * returnState was provided in the state params.
 */
export const modal = (state: IModalState): Ng1StateDeclaration =>
  new OverlayComponentStateDeclarationBuilder<"ng1", IModalState>().withFactory(ModalNg1StateDeclarationFactory).withStateBase(state).build();

const popLast = (untilEqualsState: IStateSnapshot, stateOpenerStack: IStateSnapshot[]): void => {
  const lastOpener = stateOpenerStack[stateOpenerStack.length - 1];
  if (equals(lastOpener, untilEqualsState)) {
    stateOpenerStack.pop();
    popLast(untilEqualsState, stateOpenerStack);
  }
};

export interface IFeatureToggleView {
  viewName?: string;
  featureDisabled: {
    ctrl: string;
    view: string;
  };
  featureEnabled: {
    ctrl: string;
    view: string;
  };
}

export const featureToggleView = (data: IFeatureToggleView & { isFeatureAvailable: string }): Partial<Ng1StateDeclaration> => {
  const view = {
    controllerProvider: [data.isFeatureAvailable, (featureEnabled: boolean) => (featureEnabled ? data.featureEnabled.ctrl : data.featureDisabled.ctrl)],
    controllerAs: "$ctrl",
    templateProvider: [
      data.isFeatureAvailable,
      "$http",
      "$templateCache",
      // eslint-disable-next-line @foxglove/no-boolean-parameters
      (featureEnabled: boolean, $http: IHttpService, $templateCache: ITemplateCacheService) => {
        const view = featureEnabled ? data.featureEnabled.view : data.featureDisabled.view;

        return $http.get(view, { cache: $templateCache, headers: { Accept: "text/html" } }).then((response) => response.data);
      },
    ],
  };

  return data.viewName ? { views: { [data.viewName]: view } } : view;
};

export type ResponseError = {
  statusCode: number;
  error: string;
};

export type EntityExistsResponseError = {
  resourceId: string;
  data: object;
  error: string;
};

export type UserDeactivatedResponse = ResponseError & { error: "user deactivated" };

type UserDeletedResponse = ResponseError & { error: "could not resolve the user with the provided claims" };

export const isErrorResponse = (response: IHttpResponse<unknown>): response is IHttpResponse<ResponseError> => {
  if (!response.status || !response.data) {
    return false;
  }

  const r = response as IHttpResponse<ResponseError>;
  return r.data.error !== undefined && r.data.statusCode !== undefined;
};

export const isUserDeactivatedOrDeletedResponse = (response: IHttpResponse<unknown>): response is IHttpResponse<UserDeactivatedResponse | UserDeletedResponse> => {
  if (!isErrorResponse(response) || response.status !== 403) {
    return false;
  }

  return response.data.error === "user deactivated" || response.data.error === "could not resolve the user with the provided claims";
};

export const isUserDeactivatedOrDeletedNg2Response = (response: HttpErrorResponse): boolean => {
  if (response.status !== 403) {
    return false;
  }

  return response.error.error === "user deactivated" || response.error.error === "could not resolve the user with the provided claims";
};

export const isEntityExistsResponse = (response: IHttpResponse<unknown>): response is IHttpResponse<EntityExistsResponseError> => {
  if (!response.status || !response.data || response.status !== 409) {
    return false;
  }

  const r = response as IHttpResponse<EntityExistsResponseError>;
  return r.data.error !== undefined && r.data.resourceId !== undefined && r.data.data !== undefined;
};

type THomeState = "gtmhub.home.feed" | "gtmhub.home.dashboard" | "gtmhub.msTeamsHome.feed" | "gtmhub.msTeamsHome.dashboard";

export const getHomeActiveScreenState = (state: "gtmhub.home" | "gtmhub.msTeamsHome"): THomeState => {
  const userSettings = storage.get<IUserSettings>("userSettings");

  if (userSettings?.["homeScreen"] === "feed") {
    return `${state}.feed`;
  } else {
    return `${state}.dashboard`;
  }
};
