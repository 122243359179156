import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, combineLatest, filter, map, switchMap, tap, zip } from "rxjs";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { ModuleFlag, ModuleKey } from "@webapp/feature-toggles/models/feature-module.models";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { FeatureTogglesFacade } from "./feature-toggles-facade.service";

@Injectable({
  providedIn: "root",
})
export class FeatureModuleService {
  private moduleSubjectMap = new Map<ModuleKey, BehaviorSubject<boolean>>([
    [ModuleKey.Whiteboards, new BehaviorSubject<boolean>(false)],
    [ModuleKey.Tasks, new BehaviorSubject<boolean>(false)],
    [ModuleKey.Kpis, new BehaviorSubject<boolean>(false)],
    [ModuleKey.Badges, new BehaviorSubject<boolean>(false)],
    [ModuleKey.Gif, new BehaviorSubject<boolean>(false)],
    [ModuleKey.Feed, new BehaviorSubject<boolean>(false)],
    [ModuleKey.Tags, new BehaviorSubject<boolean>(false)],
    [ModuleKey.Reflections, new BehaviorSubject<boolean>(false)],
    [ModuleKey.PlatformIntelligence, new BehaviorSubject<boolean>(false)],
  ]);
  private isLDReady = new BehaviorSubject<boolean>(false);

  constructor(
    private permissionsFacade: PermissionsFacade,
    private featureTogglesFacade: FeatureTogglesFacade,
    private editionFeatureService: EditionFeatureService
  ) {
    this.prepareInitialData();
  }

  private prepareInitialData(): void {
    zip(
      ...Object.values(ModuleFlag).map((flag) => {
        return this.featureTogglesFacade.isFeatureAvailable$(flag);
      })
    )
      .pipe(
        map((moduleValues) => {
          const featureModuleMap: { [key in ModuleKey]?: boolean } = {};

          Object.keys(ModuleKey).forEach((key, i) => {
            const moduleKey = ModuleKey[key];
            featureModuleMap[moduleKey] = moduleValues[i];
          });

          return featureModuleMap;
        }),
        tap((featureModuleMap) => {
          Object.keys(featureModuleMap).forEach((key) => {
            const moduleKey = key as ModuleKey;
            this.moduleSubjectMap.get(moduleKey).next(featureModuleMap[moduleKey]);
          });

          this.isLDReady.next(true);
        })
      )
      .subscribe();
  }

  public setModuleValue(module: { key: ModuleKey; value: boolean }): void {
    this.moduleSubjectMap.get(module.key)?.next(module.value);
  }

  public isWhiteboardModuleEnabled$(): Observable<boolean> {
    return this.getModuleSubjectSafe$(ModuleKey.Whiteboards);
  }

  public isTaskModuleEnabled$(): Observable<boolean> {
    return this.isModuleEnabled$(ModuleKey.Tasks);
  }

  public isKpiModuleEnabled$(): Observable<boolean> {
    return this.isModuleEnabled$(ModuleKey.Kpis);
  }

  public isGifsModuleEnabled$(): Observable<boolean> {
    return this.getModuleSubjectSafe$(ModuleKey.Gif);
  }

  public isBadgesModuleEnabled$(): Observable<boolean> {
    return this.isModuleEnabled$(ModuleKey.Badges);
  }

  public isTagsModuleEnabled$(): Observable<boolean> {
    return this.isModuleEnabled$(ModuleKey.Tags);
  }

  public isReflectionsModuleEnabled$(): Observable<boolean> {
    return this.getModuleSubjectSafe$(ModuleKey.Reflections);
  }

  public isPlatformIntelligenceModuleEnabled$(): Observable<boolean> {
    return this.getModuleSubjectSafe$(ModuleKey.PlatformIntelligence);
  }

  private isModuleEnabled$(key: ModuleKey): Observable<boolean> {
    return combineLatest([this.isModuleAvailable(key), this.getModuleSubjectSafe$(key)]).pipe(
      map(([isFeatureAvailable, isModuleEnabled]) => isFeatureAvailable && isModuleEnabled)
    );
  }

  private getModuleSubjectSafe$(key: ModuleKey): Observable<boolean> {
    return this.isLDReady.pipe(
      filter((isLoading) => isLoading),
      switchMap(() => this.moduleSubjectMap.get(key))
    );
  }

  private isModuleAvailable(key: ModuleKey): Observable<boolean> {
    switch (key) {
      case ModuleKey.Tasks:
        return this.editionFeatureService.hasFeature$("hub.tasks");
      case ModuleKey.Kpis:
        return combineLatest([this.editionFeatureService.hasFeature$("kpis"), this.permissionsFacade.getPermissions$()]).pipe(
          map(([isKpiFeatureAvailable, permissions]) => {
            return isKpiFeatureAvailable && permissions.has("AccessKPIs");
          })
        );
      case ModuleKey.Badges:
        return combineLatest([
          this.editionFeatureService.hasFeature$("people.badges"),
          this.permissionsFacade.hasPermission$("AccessPeople"),
          this.permissionsFacade.hasPermission$("ManageBadges"),
        ]).pipe(
          map(([badgesFeatureAvailable, canAccessPeople, canManageBadges]) => {
            return badgesFeatureAvailable && (canAccessPeople || canManageBadges);
          })
        );
      case ModuleKey.Tags:
        return combineLatest([
          this.editionFeatureService.hasFeature$("setup.tags"),
          this.permissionsFacade.hasPermission$("CreateTags"),
          this.permissionsFacade.hasPermission$("ManageConfiguration"),
          this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.EnableTagManagement),
        ]).pipe(
          map(([tagsFeatureAvailable, createTagsPermission, manageConfigurationPermission, tagManagementEnabled]) => {
            return tagsFeatureAvailable && createTagsPermission && manageConfigurationPermission && tagManagementEnabled;
          })
        );
    }
  }
}
