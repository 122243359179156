import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { IOnboardingMetadata, IOnboardingMetadataEntry } from "@gtmhub/users";
import { completeJourneyState, createInitialOnboardingState, disabledJourneyState, journeyFirstStep } from "@webapp/shared/components/onboarding/onboarding-state.util";
import { IOnboardingJourneyState, SUGGESTION_STATE_INDICATOR_SETTINGS_KEY } from "@webapp/shared/components/onboarding/onboarding.models";
import dayjs from "@webapp/shared/libs/dayjs";
import { CurrentUserRepository } from "@webapp/users";
import {
  SUGGESTION_STATE_INDICATOR_ONBOARDING_MAX_VIEWS,
  SUGGESTION_STATE_INDICATOR_ONBOARDING_TOTAL_STEPS,
} from "../models/suggestion-state-indicator-onboarding.models";

@Injectable({
  providedIn: "any",
})
export class SuggestionStateIndicatorOnboardingService {
  private onboardingMetadata: IOnboardingMetadataEntry;
  private onboardingState$: BehaviorSubject<IOnboardingJourneyState> = new BehaviorSubject<IOnboardingJourneyState>(
    createInitialOnboardingState(SUGGESTION_STATE_INDICATOR_ONBOARDING_TOTAL_STEPS)
  );
  private markedForCompletion = false;
  private totalSteps = SUGGESTION_STATE_INDICATOR_ONBOARDING_TOTAL_STEPS;
  private skipSteps = [];

  constructor(private currentUserRepository: CurrentUserRepository) {
    const onboardingMetaData = {
      [SUGGESTION_STATE_INDICATOR_SETTINGS_KEY]: { timesShown: 0, isCompleted: false, lastWatchedDate: null },
      ...this.currentUserRepository.getUserSetting<IOnboardingMetadata>("onboardingMetadata"),
    };

    this.onboardingMetadata = onboardingMetaData[SUGGESTION_STATE_INDICATOR_SETTINGS_KEY];

    if (this.shouldSeeJourneyAccordingToMetadata()) {
      this.updateState(journeyFirstStep(this.totalSteps, this.skipSteps));
    } else {
      this.updateState(disabledJourneyState(this.totalSteps));
    }
  }

  public getState$(): Observable<IOnboardingJourneyState> {
    return this.onboardingState$.asObservable();
  }

  public completeJourney(): void {
    this.markedForCompletion = true;
    this.onboardingMetadata.isCompleted = true;
    this.syncOnboardingMetadata();
  }

  public markAsShown(): void {
    this.onboardingMetadata.timesShown++;
    this.onboardingMetadata.lastWatchedDate = dayjs().utc().toISOString();
    this.onboardingMetadata.isCompleted = this.onboardingMetadata.timesShown >= SUGGESTION_STATE_INDICATOR_ONBOARDING_MAX_VIEWS;
    this.syncOnboardingMetadata();
  }

  public shouldSeeJourneyAccordingToMetadata(): boolean {
    const isCompleted = this.onboardingMetadata.isCompleted;
    const exceededMaxViews = this.onboardingMetadata.timesShown >= SUGGESTION_STATE_INDICATOR_ONBOARDING_MAX_VIEWS;
    const lastWatchedWithin24hours = dayjs().diff(this.onboardingMetadata.lastWatchedDate, "hours") <= 24;

    if (isCompleted || exceededMaxViews || lastWatchedWithin24hours) {
      return false;
    }

    return true;
  }

  private syncOnboardingMetadata(): void {
    this.currentUserRepository.setUserSetting({
      onboardingMetadata: {
        ...this.currentUserRepository.getUserSetting<IOnboardingMetadata>("onboardingMetadata"),
        [SUGGESTION_STATE_INDICATOR_SETTINGS_KEY]: this.onboardingMetadata,
      },
    });
  }

  private updateState(state: IOnboardingJourneyState): void {
    if (this.markedForCompletion) {
      this.onboardingState$.next(completeJourneyState(state));
      this.markedForCompletion = false;
    } else {
      this.onboardingState$.next(state);
    }
  }
}
