<div class="box-wrapper">
  <i class="info-img" ui-icon uiType="info" a11yTitle="Info icon"></i>
  <div>
    <div class="box-header">{{ heading }}</div>
    <div class="box-body">{{ description }}</div>
    <button class="box-btn" [disabled]="loading" [tabindex]="-1" (click)="automate.emit()" ui-button data-test-id="automate-button" uiType="primary" uiShape="round">
      {{ "automate" | localize }}
    </button>
  </div>
  <ui-loading-indicator class="loading-indicator" *ngIf="loading" uiType="linear"></ui-loading-indicator>
</div>
