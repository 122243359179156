import { byTextField } from "@gtmhub/util/sorting-utils";
import { Assignee } from "@webapp/assignees/models/assignee.models";

export function searchAssignees(
  query: string,
  assignees: Assignee[],
  options: {
    limit: number;
    shouldSkip(assignee: Assignee): boolean;
  }
): Assignee[] {
  query = query || "";
  const topMatches: Assignee[] = [];
  const otherMatches: Assignee[] = [];

  for (const assignee of assignees) {
    if (options.shouldSkip(assignee)) {
      continue;
    }

    const assigneeNameStartsWithInput = assignee.name.toLowerCase().startsWith(query.toLowerCase());
    const assigneeNameContainsInput = assignee.name.toLowerCase().includes(query.toLowerCase());

    if (assigneeNameStartsWithInput) {
      topMatches.push(assignee);
    } else if (assigneeNameContainsInput) {
      otherMatches.push(assignee);
    }
  }

  return sortAndSliceAssigneeResults(topMatches, otherMatches, options.limit);
}

function sortAndSliceAssigneeResults(topMatches: Assignee[], otherMatches: Assignee[], limit: number): Assignee[] {
  topMatches.sort(byTextField("name"));

  if (topMatches.length >= limit) {
    return topMatches.slice(0, limit);
  }

  otherMatches.sort(byTextField("name"));
  const otherMatchesSliced = otherMatches.slice(0, limit - topMatches.length);

  return topMatches.concat(otherMatchesSliced);
}
