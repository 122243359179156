import { Ng2StateDeclaration } from "@uirouter/angular";

export const states: Ng2StateDeclaration[] = [
  {
    data: {
      requiresLogin: true,
    },
    name: "gtmhub.globalSearch.**",
    url: "/search",
    loadChildren: () => import(/* webpackChunkName: "search" */ "./search-routing.module").then((m) => m.SearchRoutingModule),
  },
];
