import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import {
  CheckInQuestionEndpointParams,
  CheckInSettingsEndpointParams,
  CheckInTemplateEndpointParams,
  PatchCheckInEndpointParams,
  TeamCheckInEndpointParams,
} from "../models/checkins-api.models";
import { CheckInDTO } from "../models/checkins-facade.models";
import { CheckIn } from "../models/checkins-models";
import { CheckInsState } from "./checkins-state.service";

@Injectable({
  providedIn: "root",
})
export class CheckInsApiService extends BaseApiService<CheckIn, CheckInDTO, CheckInsState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: CheckInsState) {
    super("reflections", { ...new ApiVersionModel("v1"), getAll: "v2" }, httpClient, appConfig, state);
  }

  public getCheckInsEndpoint(): string {
    return `${this.getBasePath(HttpActions.get)}/`;
  }

  public getTeamCheckInsEndpoint(): string {
    return `${this.getBasePath(HttpActions.get)}`;
  }

  public getCheckInTemplateEndpoint({ teamId }: TeamCheckInEndpointParams): string {
    return `${this.getBasePath(HttpActions.get)}/teams/${teamId}/template`;
  }

  public getCheckInSettingsEndpoint(): string {
    return `${this.getBasePath(HttpActions.get)}/settings`;
  }

  public putCheckInEndpoint({ reflectionId }: PatchCheckInEndpointParams): string {
    return `${this.getBasePath(HttpActions.get)}/${reflectionId}`;
  }

  public patchCheckInSettingEndpoint({ settingsId }: CheckInSettingsEndpointParams): string {
    return `${this.getBasePath(HttpActions.get)}/settings/${settingsId}`;
  }

  public patchCheckInTemplateEndpoint({ templateId: templateId }: CheckInTemplateEndpointParams): string {
    return `${this.getBasePath(HttpActions.get)}/templates/${templateId}`;
  }

  public patchCheckInQuestionEndpoint({ questionId: questionId, templateId: templateId }: CheckInQuestionEndpointParams): string {
    return `${this.getBasePath(HttpActions.get)}/templates/${templateId}/questions/${questionId}`;
  }

  public postCheckInQuestionEndpoint({ templateId: templateId }: CheckInTemplateEndpointParams): string {
    return `${this.getBasePath(HttpActions.get)}/templates/${templateId}/questions`;
  }
}
