import { Injectable } from "@angular/core";
import { localize } from "@gtmhub/localization";
import { cancelButton, primaryButton } from "@webapp/shared/modal/modal.utils";
import { TaskParentType } from "@webapp/tasks/models/tasks.models";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { CreateTaskComponent } from "../components/create-task/create-task.component";

@Injectable({
  providedIn: "any",
})
export class TaskModalService {
  constructor(private modalService: UiModalService) {}

  public openCreateTask(parentType?: TaskParentType): void {
    this.modalService.create<CreateTaskComponent>({
      uiTitle: localize("create_task"),
      uiDescription: localize("all_required_fields_are_marked_with_an_asterisk"),
      uiData: { parentType: parentType },
      uiContent: CreateTaskComponent,
      uiFooter: [
        primaryButton(localize("create_task"), {
          e2eTestId: "create-task",
          disabled: (component) => !component.form || component.form.invalid || Boolean(component.indicators.creating),
          onClick: (component) => {
            component.submitForm();
            return false;
          },
        }),
        cancelButton(),
      ],
      uiWidth: 662,
    });
  }
}
