import { FormControl, ValidationErrors, Validators } from "@angular/forms";
import { IDraftGoalMetricState } from "@gtmhub/whiteboards";

export const validateWhitespaces = (control: FormControl): ValidationErrors => {
  const whitespacesOnly = !control.value.trim();
  return whitespacesOnly ? { whitespaces: true } : null;
};

export const descriptionValidators = [Validators.required, validateWhitespaces];

export const getMetricRowsNumber = (metrics: IDraftGoalMetricState[]): number => {
  return metrics.reduce((agg, metric) => {
    // metric title in whiteboard is split on multiple rows, usually the value is 25 each row,
    // but we increase it to 30 here so that we can mimic less font-size on the title
    if (metric.name.length >= 30) {
      const titleRows = metric.name.split(/(.{30})/).filter((row) => row);

      return agg + titleRows.length;
    }

    return agg + 1;
  }, 0);
};
