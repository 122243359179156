import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { EnvironmentServiceProvider } from "@webapp/env/providers/environment.provider";
import { SuggestedNavItemFacade } from "@webapp/navigation/services/uxcustomization/suggested/suggested-nav-item.facade";
import { SuggestedApiService } from "./suggested.api.service";

@NgModule({
  exports: [],
  imports: [],
  providers: [EnvironmentServiceProvider, SuggestedNavItemFacade, SuggestedApiService, provideHttpClient(withInterceptorsFromDi())],
})
export class SuggestedModule {}
