import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";
import { StorageService } from "@webapp/core/storage/services/storage.service";

@Injectable()
export class AccountIdHeaderInterceptor implements HttpInterceptor {
  constructor(
    private storageService: StorageService,
    private apiEndpointService: ApiEndpointService
  ) {}

  public intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    return next.handle(this.attachHeaders(request));
  }

  private attachHeaders(request: HttpRequest<object>): HttpRequest<object> {
    const accountId = this.storageService.get("accountId");

    if (this.apiEndpointService.isApiRequest(request.url) && accountId) {
      return request.clone({
        setHeaders: {
          "gtmhub-accountId": `${accountId}`,
        },
      });
    }

    return request;
  }
}
