import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, ViewContainerRef, forwardRef } from "@angular/core";
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { noop } from "rxjs";
import { IUnitFormatting } from "@gtmhub/shared/models";
import { localize } from "@webapp/localization/utils/localization.utils";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { SimpleChangesTyped } from "../models";
import { CustomUnitComponent, CustomUnitModalData } from "./custom-unit-modal/custom-unit.component";
import { Affix, FULL_NUMBER_PRECISION, INumericFieldFormatting, IUnitItem, IUnitItemMap } from "./models/unit-selector.models";

interface FormFields {
  unit: FormControl<IUnitItem>;
  fractionSize: FormControl<number>;
  isCustomUnit: FormControl<boolean>;
}

@UntilDestroy()
@Component({
  selector: "unit-selector",
  templateUrl: "./unit-selector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UnitSelectorComponent),
      multi: true,
    },
  ],
})
export class UnitSelectorComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() public uiId: string;
  @Input() public unitFormat: IUnitFormatting;
  @Input() public a11yDescription: string;
  @Input() public disabled: boolean;
  @Input() public reserveScrollbarSpace = true;
  @Input() public addLabels = false;
  @Input() public addPrecision = false;

  @Output() public readonly unitFormatChange = new EventEmitter<IUnitFormatting>();

  @HostBinding("style.width") public hostWidth = "100%";

  public validateForm: FormGroup<FormFields>;
  public customUnit: IUnitItem = { value: "", prefix: false, suffix: false };
  public readonly fractionSizeList = [
    { value: FULL_NUMBER_PRECISION, display: localize("full_number"), customContent: false },
    { value: 0, display: "0", customContent: true, example: "e.g. 0" },
    { value: 1, display: "1", customContent: true, example: "e.g. 0.0" },
    { value: 2, display: "2", customContent: true, example: "e.g. 0.00" },
    { value: 3, display: "3", customContent: true, example: "e.g. 0.000" },
  ];
  public readonly unitMap: IUnitItemMap = {
    "": { value: "", display: localize("not_set") },
    "%": { value: "%", display: localize("percentage_symbol_desc"), prefix: false, suffix: true },
    $: { value: "$", display: localize("us_dollar_symbol_desc"), prefix: true, suffix: false },
    "£": { value: "£", display: localize("british_pound_symbol_desc"), prefix: true, suffix: false },
    "€": { value: "€", display: localize("euro_symbol_desc"), prefix: true, suffix: false },
  };

  private prevUnitValue: string;
  private initialFormatting: INumericFieldFormatting = {
    unit: this.unitMap[""],
    fractionSize: FULL_NUMBER_PRECISION,
    isCustomUnit: false,
  };
  private customFormat: IUnitFormatting = {
    prefix: "",
    suffix: "",
    fractionSize: FULL_NUMBER_PRECISION,
    isCustomUnit: false,
  };
  private propagateChange: (input: IUnitFormatting) => void = noop;

  constructor(
    private uiModalService: UiModalService,
    private viewContainerRef: ViewContainerRef
  ) {}

  public ngOnInit(): void {
    const { unit, fractionSize, isCustomUnit } = this.setFormatting();
    this.validateForm = new FormGroup<FormFields>({
      unit: new FormControl<IUnitItem>(unit),
      fractionSize: new FormControl<number>(fractionSize),
      isCustomUnit: new FormControl<boolean>(isCustomUnit),
    });
    this.registerFormListeners();
  }

  public writeValue(value: IUnitFormatting): void {
    this.unitFormat = value;
    this.handleUnitFormatChanges(this.unitFormat);
  }

  public registerOnChange(fn: (input: IUnitFormatting) => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(): void {
    // required by the ControlValueAccessor interface
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public ngOnChanges(changes: SimpleChangesTyped<UnitSelectorComponent>): void {
    if (!this.validateForm || !changes.unitFormat) return;

    this.handleUnitFormatChanges(changes.unitFormat.currentValue);
  }

  private handleUnitFormatChanges(unitFormat: IUnitFormatting): void {
    const { unit, fractionSize, isCustomUnit } = this.setFormatting();

    if (isCustomUnit) {
      this.customUnit = this.createUnitListItemFromFormat(unitFormat);
    }
    const currentUnitFromDefaultUnits = this.unitMap[unit.value];
    const newUnitValue = currentUnitFromDefaultUnits || this.customUnit;

    this.validateForm.controls.unit.patchValue(newUnitValue, { emitEvent: false });
    this.validateForm.controls.fractionSize.patchValue(fractionSize, { emitEvent: false });
    this.validateForm.controls.isCustomUnit.patchValue(isCustomUnit, { emitEvent: false });
  }

  private registerFormListeners(): void {
    this.validateForm.valueChanges.pipe(untilDestroyed(this)).subscribe((changes) => {
      const currentUnitIsFromDefaultUnits = this.unitMap[changes.unit.value];

      if (changes.unit.value === "custom") {
        const prevValue = this.unitMap[this.prevUnitValue] || this.customUnit;
        this.validateForm.controls.unit.patchValue(prevValue);
        this.openCustomFormatModal();
        return;
      }

      if (this.validateForm.value.isCustomUnit && currentUnitIsFromDefaultUnits) {
        this.validateForm.controls.isCustomUnit.patchValue(false);
      } else if (!this.validateForm.value.isCustomUnit && !currentUnitIsFromDefaultUnits) {
        this.validateForm.controls.isCustomUnit.patchValue(true);
      }

      this.prevUnitValue = this.validateForm.value.unit.value;
      this.emitFormat();
    });
  }

  private emitFormat(): void {
    const emitValue = {
      prefix: this.validateForm.value.unit.prefix ? this.validateForm.value.unit.value : "",
      suffix: this.validateForm.value.unit.suffix ? this.validateForm.value.unit.value : "",
      fractionSize: this.validateForm.value.fractionSize,
      isCustomUnit: this.validateForm.value.isCustomUnit ?? false,
    };

    this.unitFormatChange.emit(emitValue);
    this.propagateChange(emitValue);
  }

  private openCustomFormatModal(): void {
    this.uiModalService
      .create<CustomUnitComponent, CustomUnitModalData>({
        uiTitle: localize("custom_formatting_title"),
        uiContent: CustomUnitComponent,
        uiViewContainerRef: this.viewContainerRef,
        uiData: {
          unit: this.validateForm.value.unit.value,
          affix: this.validateForm.value.unit.prefix ? Affix.Prefix : Affix.Suffix,
        },
        uiOkText: localize("done"),
        uiOnOk(componentInstance: CustomUnitComponent): void {
          componentInstance.onCustomUnitCreated();
        },
        uiCancelText: localize("cancel"),
        uiOnCancel(componentInstance: CustomUnitComponent): void {
          componentInstance.closeModal();
        },
      })
      .afterClose.subscribe((formatting) => {
        if (!formatting) {
          return;
        }

        const { unit, prefix, suffix } = formatting;
        const format = {
          prefix: prefix ? unit : "",
          suffix: suffix ? unit : "",
        };
        this.customUnit = this.createUnitListItemFromFormat(format);
        this.validateForm.patchValue({
          unit: this.customUnit,
          isCustomUnit: this.customFormat.isCustomUnit,
        });
      });
  }

  private setFormatting(): INumericFieldFormatting {
    if (!this.unitFormat) {
      return this.initialFormatting;
    }

    const unitValue = this.unitFormat.prefix || this.unitFormat.suffix;
    const unit = this.createUnitListItemFromFormat(this.unitFormat);
    this.customUnit = this.unitMap[unitValue] ? this.customUnit : unit;

    return {
      unit,
      fractionSize: this.unitFormat.fractionSize,
      isCustomUnit: !this.unitMap[unitValue],
    };
  }

  private createUnitListItemFromFormat(format: IUnitFormatting | { prefix: string; suffix: string }): IUnitItem {
    const unit = format.prefix || format.suffix;
    const defaultUnitFormat = this.unitMap[unit];

    if (defaultUnitFormat) {
      return defaultUnitFormat;
    }

    return {
      value: format.prefix || format.suffix,
      prefix: !!format.prefix,
      suffix: !!format.suffix,
    };
  }

  // keyvalue pipe is sorting by key, and we need the original order
  public sortByOriginalOrder = (): number => {
    return 0;
  };
}
