import { ItemAction } from "./action-handler.models";

export interface SubTitle {
  text: string;
  action: ItemAction;
}

export enum TopNavBarAvatarsBreakpoints {
  LARGE_SCREEN = 1440,
  MEDIUM_SCREEN = 1024,
  SMALL_SCREEN = 768,
}

export enum TopNavBarVisibleAvatarsCount {
  LARGE_SCREEN = 5,
  MEDIUM_SCREEN = 3,
  SMALL_SCREEN = 2,
  XS_SCREEN = 1,
}

export interface TopNavBarAvatarsConfig {
  assigneesIdColorMap: Map<string, string>;
  a11yLabel?: string;
}
