import { getLanguageCode } from "@gtmhub/localization";

export const thousandsSeparator = () => {
  return function (value: number, decimalPlaces?: number): string {
    if (!value) return "0";
    if (value % 1 !== 0) {
      return value.toLocaleString(getLanguageCode(), { minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces });
    }
    return value.toLocaleString(getLanguageCode());
  };
};
