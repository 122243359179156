import { IQService } from "angular";
import { IExtendedRootScopeService } from "./models";

const truthy = <Value>(value: Value) => !!value;

const mod = angular.module("ng-extensions", []);

mod.run([
  "$rootScope",
  "$q",
  ($rootScope: IExtendedRootScopeService, $q: IQService) => {
    $rootScope["__proto__"].watchUntil = function <Value>(fn: () => Value, condition?: (value: Value) => unknown) {
      return $q((resolve) => {
        if (!condition) {
          condition = truthy;
        }

        const unwatch = this.$watch(fn, (value: Value) => {
          if (condition(value)) {
            unwatch();
            resolve();
          }
        });
      });
    };

    $rootScope["__proto__"].watchUntilAndRejectOnError = function (complete: () => boolean, error: () => unknown) {
      const completeOrError = () => complete() || !!error();
      return this.watchUntil(completeOrError).then(() => {
        const err = error();
        if (err) {
          return $q.reject(err);
        }
      });
    };
  },
]);

export default mod.name;
