import { IComponentOptions, IOnChangesObjectOf } from "angular";
import { GtmhubController } from "@gtmhub/core";
import { ISessionsStoreState } from "@gtmhub/sessions/redux/session-reducer";
import { ITreeSelectorItem } from "@gtmhub/shared/components/tree-selector/models";
import { INgRedux } from "@gtmhub/state-management/ng-redux";
import { Session } from "@webapp/sessions/models/sessions.model";
import { DynamicFiltersPosition, ISessionOption, dynamicFiltersPresets } from "../generic-session-selector/dynamic-filters-presets";
import { IDynamicValue } from "../grid-filtering-bar";
import { byEndDescAndStartAsc, findNodeInTreeById, isAnyChild } from "./utils";

export interface ISessionItem extends ITreeSelectorItem {
  isHostSession: boolean;
  hasChildrenSelected: boolean;
  hasChildHostSession: boolean;
}

export interface ISessionTreeSelectorComponentBindings {
  hostSessionId: string;
  selectedIds: string[];
  dynamicValues: IDynamicValue[];
  dynamicFiltersPreset?: string;
  isExpanded?: boolean;
  isSingleSelector?: boolean;
  subTreeRootLevelSessionId?: string;
  filterSessions: Session[];
  onSelection?(args: { sessionId: string }): void;
  onDeselection?(args: { sessionId: string }): void;
  filterWithHostId: boolean;
  subTreeBuild?: boolean;
  enableItemDisabledState: boolean;
  showAllSessionsLabel?: boolean;
}

const DEFAULT_EXPANDED_DEPTH_LIMIT = 1;

/**
 * @example <session-tree-selector on-selection="$ctrl.selectSession(sessionId)" host-session-id="$ctrl.currentSession.id" filter-sessions="$ctrl.sessions" filter-with-host-id="$ctrl.filterWithHostId" sub-tree-build="$ctrl.subTreeBuild" sub-tree-root-level-session-id="$ctrl.currentSession.parentId" is-single-selector="true" enable-item-disabled-state="true"></session-tree-selector>
 *
 * @param selectedIds holds the Ids of the currently selected sessions in order to have them checked when building the tree
 * @param hostSessionId the session we are currently in, or in the case of the session-selector-dropdown, the host session of the goal
 * @param isExpanded how to present the tree expanded or collapsed
 * @param dynamicFiltersPreset adds dynamic filters as additional options to the tree of sessions. The preset should exist in `dynamicFiltersPresets`. If it doesn't exist an error is thrown.
 * @param isSingleSelector if it's true the component is used as single selector, otherwise it's a toggle action
 * @param filterWithHostId this is used when we want to include the host Id when building the tree
 * @param subTreeBuild when we want to build a sub tree of sessions our root level will not always be without a parent and we want to specify if it is a subtree build in order to include the root session of the sub tree
 * @param subTreeRootLevelSessionId works with the subTreeBuild it provides the root of the subtree we want to build
 * @param onSelection emits the sessionId of the selected session to the parent controller
 * @param onDeselection same thing as the above but on deselection
 * @param filterSessions receives the sessions[] we want to build the tree of
 * @param enableItemDisabledState used when we want to have some of the sessions disabled based on session.status and/or user permissions to create inside said session
 */

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface SessionTreeSelectorComponentCtrl extends ISessionTreeSelectorComponentBindings {}
export class SessionTreeSelectorComponentCtrl extends GtmhubController {
  static $inject = ["$ngRedux"];

  constructor(private $ngRedux: INgRedux) {
    super();
  }

  tree: ISessionItem[];
  dynamicFiltersTree: ISessionItem[];
  includeDynamicValues: boolean;
  dynamicOptions: ISessionOption[];
  dynamicFiltersPosition?: DynamicFiltersPosition;

  $onInit(): void {
    this.buildTrees();
  }

  $onChanges(onChangesObj: IOnChangesObjectOf<ISessionTreeSelectorComponentBindings>): void {
    if (onChangesObj.selectedIds && !onChangesObj.selectedIds.isFirstChange()) {
      this.onSelectedIdsChange(onChangesObj.selectedIds.previousValue, onChangesObj.selectedIds.currentValue);
    }

    if (onChangesObj.dynamicValues && !onChangesObj.dynamicValues.isFirstChange()) {
      this.onDynamicValuesChange(onChangesObj.dynamicValues.previousValue, onChangesObj.dynamicValues.currentValue);
    }

    if (onChangesObj.filterSessions && !onChangesObj.filterSessions.isFirstChange()) {
      this.buildTrees();
    }

    if (onChangesObj.dynamicFiltersPreset) {
      this.buildTrees();
    }
  }

  onSelect(item: ISessionItem): void {
    if (this.onSelection) {
      this.onSelection({ sessionId: item.id });
    }
  }

  onDeselect(item: ISessionItem): void {
    if (this.onDeselection) {
      this.onDeselection({ sessionId: item.id });
    }
  }

  private onSelectedIdsChange(previousSelectedId: string[], currentSelectedIds: string[]): void {
    if (!currentSelectedIds) {
      currentSelectedIds = [];
    }

    if (!previousSelectedId) {
      previousSelectedId = [];
    }

    const deselectedIds = previousSelectedId.filter((id) => !currentSelectedIds.includes(id));
    deselectedIds.forEach((id) => {
      this.markNodeAsDeselected(id);
    });

    const selectedIds = currentSelectedIds.filter((id) => !previousSelectedId.includes(id));
    selectedIds.forEach((id) => {
      this.markNodeAsSelected(id);
    });
  }

  private onDynamicValuesChange(previousValue?: IDynamicValue[], currentValue?: IDynamicValue[]): void {
    const previouslySeslected = (previousValue || []).map((value) => value.type);
    const currentlySelected = (currentValue || []).map((value) => value.type);
    const nodesToDeselect = previouslySeslected.filter((value) => !currentlySelected.includes(value));
    nodesToDeselect.forEach((node) => this.markNodeAsDeselected(node));
    const nodesToSelect = currentlySelected.filter((value) => !previouslySeslected.includes(value));
    nodesToSelect.forEach((node) => this.markNodeAsSelected(node));
  }

  private readPreset() {
    const presetName = this.dynamicFiltersPreset;
    if (presetName) {
      const preset = dynamicFiltersPresets.get(presetName);
      if (preset) {
        this.dynamicOptions = preset.options;
        this.includeDynamicValues = true;
        this.dynamicFiltersPosition = preset.position;
      } else {
        const supportedPresets = dynamicFiltersPresets
          .getNames()
          .map((name) => `"${name}"`)
          .join(", ");
        throw new Error(`Unknown preset "${presetName}". Supported presets: ${supportedPresets}.`);
      }
    } else {
      this.dynamicOptions = [];
      this.includeDynamicValues = false;
      this.dynamicFiltersPosition = undefined;
    }
  }

  private markNodeAsSelected(id: string) {
    const node = findNodeInTreeById(id, this.tree.concat(this.dynamicFiltersTree));
    if (node && !node.selected) {
      node.selected = true;
    }
  }

  private markNodeAsDeselected(id: string) {
    const node = findNodeInTreeById(id, this.tree.concat(this.dynamicFiltersTree));
    if (node && node.selected) {
      node.selected = false;
    }
  }

  private get sessions() {
    return this.filterSessions || this.$ngRedux.getState<ISessionsStoreState>().sessions.items;
  }

  private get sessionIdMap() {
    return this.$ngRedux.getState<ISessionsStoreState>().sessions.map;
  }

  private buildTrees(): void {
    this.readPreset();
    this.tree = this.buildTree();
    this.dynamicFiltersTree = this.buildDynamicFiltersTree();
  }

  private buildTree(): ISessionItem[] {
    const parentNodes: ISessionItem[] = [];
    const rootLevelSessions = !this.subTreeBuild
      ? this.sessions.filter((s) => !s.parentId || !this.sessionIdMap[s.parentId] || (this.filterWithHostId && s.id === this.hostSessionId)).sort(byEndDescAndStartAsc)
      : this.sessions.filter((s) => s.id === this.subTreeRootLevelSessionId).sort(byEndDescAndStartAsc);

    rootLevelSessions.forEach((session) => {
      const sessionItem = this.createSessionItem(session.id);
      parentNodes.push(sessionItem);
    });

    const hostNode = parentNodes.find((n) => n.isHostSession || n.hasChildHostSession);
    return hostNode ? [hostNode, ...parentNodes.filter((n) => n.id !== hostNode.id)] : parentNodes;
  }

  private buildDynamicFiltersTree(): ISessionItem[] {
    if (this.includeDynamicValues) {
      return this.addDynamicFilters();
    } else {
      return [];
    }
  }

  private createSessionItem(sessionId: string, depth = 0): ISessionItem {
    const session = this.sessions.find((s) => s.id === sessionId);
    const sessionItem: ISessionItem = {
      id: sessionId,
      name: session.title,
      avatar: {
        color: session.color,
      },
      expanded: depth < DEFAULT_EXPANDED_DEPTH_LIMIT,
      selected: !!(this.selectedIds && this.selectedIds.includes(sessionId)),
      isHostSession: !!(this.hostSessionId && sessionId === this.hostSessionId),
      userHasPermissionToCreate: session.userHasPermissionToCreate,
    } as ISessionItem;
    this.setChildrenPropertiesToSessionItem(sessionItem, depth);

    return sessionItem;
  }

  private setChildrenPropertiesToSessionItem(sessionItem: ISessionItem, depth: number) {
    sessionItem.children = [];
    const childSessionsIds = this.sessions
      .filter((s) => s.parentId === sessionItem.id)
      .sort(byEndDescAndStartAsc)
      .map((cs) => cs.id);

    if (childSessionsIds.length === 0) {
      sessionItem.hasChildrenSelected = false;
      sessionItem.hasChildHostSession = false;
    } else {
      childSessionsIds.forEach((childSessionId) => {
        sessionItem.children.push(this.createSessionItem(childSessionId, depth + 1));
      });

      sessionItem.hasChildrenSelected = isAnyChild(sessionItem, (c) => c.selected);
      sessionItem.hasChildHostSession = !!(this.hostSessionId && isAnyChild(sessionItem, (c) => c.id === this.hostSessionId));

      if (sessionItem.hasChildrenSelected || sessionItem.hasChildHostSession) {
        sessionItem.expanded = true;
      }
    }
  }

  private addDynamicFilters(): ISessionItem[] {
    const sessionItems = this.createSessionItemsFromDynamicFilters();
    if (this.dynamicFiltersPosition === DynamicFiltersPosition.bottom && sessionItems.length > 0) {
      sessionItems[0].separator = true;
    }
    return sessionItems;
  }

  private createSessionItemsFromDynamicFilters(): ISessionItem[] {
    return this.dynamicOptions.map((option) => ({
      id: option.id,
      name: option.name,
      avatar: option.avatar,
      selected: !!(this.dynamicValues && this.dynamicValues.some((dv) => dv.type === option.id)),
      expanded: false,
      isHostSession: false,
      children: [],
      hasChildrenSelected: false,
      hasChildHostSession: false,
      separator: false,
    }));
  }
}

export const SessionTreeSelectorComponent: IComponentOptions = {
  template: require("./session-tree-selector.html"),
  controller: SessionTreeSelectorComponentCtrl,
  bindings: {
    selectedIds: "<",
    dynamicValues: "<",
    hostSessionId: "<",
    dynamicFiltersPreset: "@",
    isExpanded: "<",
    activeSession: "<",
    isSingleSelector: "<",
    filterWithHostId: "<",
    subTreeBuild: "<",
    subTreeRootLevelSessionId: "<",
    onSelection: "&",
    onDeselection: "&",
    filterSessions: "<",
    enableItemDisabledState: "<?",
    showAllSessionsLabel: "<?",
  },
};
