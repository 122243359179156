import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "a[ui-external-link], button[ui-external-link]",
  template: `<ng-content></ng-content><i ui-icon uiType="external-link"></i>`,
  styleUrls: ["external-link.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class UiExternalLinkComponent {}
