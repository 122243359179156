export const localhost = "localokrs.com";

export function isLocalhost(host: string): boolean {
  return host.indexOf(localhost) === 0;
}

function isCurrentDomainLocalhost(): boolean {
  return window.location.hostname.indexOf(localhost) === 0;
}

export function isCurrentDomainQuantive(): boolean {
  return window.location.hostname.includes(".quantive.com");
}

export function isCurrentDomainGtmhub(): boolean {
  return window.location.hostname.includes(".gtmhub.com");
}

export function isQuantiveDomain(host: string): boolean {
  return host.includes(".quantive.com");
}

export function isSecure(protocol: string): boolean {
  return protocol === "https:";
}

export function toGtmhubDomain(host: string): string {
  return host.replace(".quantive.com", ".gtmhub.com");
}

export function toQuantiveDomain(host: string): string {
  return host.replace(".gtmhub.com", ".quantive.com");
}

export function pathContainsResultsPrefix(): boolean {
  return window.location.pathname.startsWith("/results");
}

export function getPathPrefix(): string {
  return pathContainsResultsPrefix() ? "/results" : "";
}

// Whether to use ...com/results/home/dashboard (true) or ...com/#/home/dashboard (false)
export function shouldUseQuantiveUrlBehavior(): boolean {
  return isCurrentDomainQuantive() || (isCurrentDomainLocalhost() && webappConfig().env.useQuantiveBasePath);
}

export function stripProtocolFromURL(url: string): string {
  const protocolPattern = /^(https?:\/\/)/i;
  return url.replace(protocolPattern, "");
}

export function switchToQuantiveDomain(email: string, quantiveDomain: string, returnPath?: string) {
  let newUrl = `https://${toQuantiveDomain(window.location.hostname)}/results/login?user=${email}&domain=${quantiveDomain}`;
  if (returnPath) {
    newUrl += `&returnPath=${encodeURIComponent(returnPath)}`;
  }

  window.location.href = newUrl;
}
