import { IDirective, IDirectiveFactory } from "angular";
import { ApmService } from "./apm.service";

interface TraceElement {
  name: string;
}

export class RenderTransactionCtrl {
  public static $inject = ["ApmService"];
  constructor(private apmService: ApmService) {}

  public initTraceElements(collectorName: string, traceElements: TraceElement[]): void {
    for (let i = 0; i < traceElements.length; i++) {
      this.addTraceElement(collectorName, traceElements[i]);
    }
  }

  public addTraceElement(collectorName: string, traceElement: TraceElement): void {
    this.apmService.startRenderSpan(`[${collectorName}]${traceElement.name}`);
  }

  public elementRendered(name: string): void {
    this.apmService.endRenderSpan(name);
  }
}

/**
 * start/make connection to an existing Transaction and contain the render trace logic
 * currently, a timer is started when new elements are added to the wait time, or something is rendered
 */
export class RenderTransactionDirective implements IDirective {
  public restrict = "A";
  public controller = RenderTransactionCtrl;

  public static factory(): IDirectiveFactory {
    return () => new RenderTransactionDirective();
  }
}
