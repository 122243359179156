import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { QuantivePlusSuggestionDTO, QuantivePlusSuggestionPostDTO } from "@webapp/platform-intelligence/shared/models/quantive-plus-suggestions.models";
import { QuantivePlusSuggestionsStateV1 } from "./quantive-plus-suggestions-state-v1.service";

@Injectable({
  providedIn: "root",
})
export class QuantivePlusSuggestionsApiV1Service extends BaseApiService<QuantivePlusSuggestionDTO, QuantivePlusSuggestionPostDTO, QuantivePlusSuggestionsStateV1> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: QuantivePlusSuggestionsStateV1) {
    super("suggestions", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getKeyResultsEndpoint(): string {
    return `${this.getQuantivePlusBasePath(HttpActions.get)}/krs`;
  }

  public getTagsEndpoint(): string {
    return `${this.getQuantivePlusBasePath(HttpActions.get)}/tags`;
  }

  public getKrTasksEndpoint(): string {
    return `${this.getQuantivePlusBasePath(HttpActions.get)}/kr-tasks`;
  }

  public getKeyResultDescriptionEndpoint(): string {
    return `${this.getQuantivePlusBasePath(HttpActions.post)}/kr-description`;
  }
}
