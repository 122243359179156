import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { SessionsAlphabeticalOrderByPipe } from "@webapp/sessions/pipes/sessions-alphabetical-order-by.pipe";
import { SessionsFilterByTitlePipe } from "@webapp/sessions/pipes/sessions-filter.pipe";
import { PlanningSessionsActionsProvider, PlanningSessionsServiceProvider } from "@webapp/sessions/providers/sessions.provider";
import { DropdownModule } from "@webapp/shared/dropdown/dropdown.module";
import { UibModalProvider } from "@webapp/shared/providers/uib-modal.provider";
import { TasksServiceProvider } from "@webapp/tasks/providers/tasks.provider";
import { ItemNameWithIconComponent } from "./components/item-name-with-icon/item-name-with-icon.component";
import { MetricValuePipe } from "./pipes/metric-value.pipe";
import { MetricPipe } from "./pipes/metric.pipe";

@NgModule({
  declarations: [ItemNameWithIconComponent, MetricValuePipe, MetricPipe, SessionsFilterByTitlePipe, SessionsAlphabeticalOrderByPipe],
  exports: [ItemNameWithIconComponent, MetricValuePipe, MetricPipe, SessionsFilterByTitlePipe, SessionsAlphabeticalOrderByPipe],
  providers: [PlanningSessionsServiceProvider, TasksServiceProvider, PlanningSessionsActionsProvider, UibModalProvider],
  imports: [CommonModule, FormsModule, UiIconModule, UiTooltipModule, UiButtonModule, DropdownModule, LocalizationModule],
})
export class OkrsCoreModule {}
