import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ToDatefnsFormatPipe } from "@webapp/shared/pipes/to-datefns-format.pipe";
import { UiDatePickerModule } from "@webapp/ui/date-picker/date-picker.module";
import { DatePickerLegacyComponent } from "./date-picker-legacy.component";

@NgModule({
  imports: [CommonModule, FormsModule, UiDatePickerModule, ToDatefnsFormatPipe],
  declarations: [DatePickerLegacyComponent],
  exports: [DatePickerLegacyComponent],
})
export class DatePickerLegacyModule {}
