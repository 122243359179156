import { IComponentOptions, IOnChangesObjectOf, IScope } from "angular";
import { GtmhubController } from "@gtmhub/core";
import { IIndicator, IUIError } from "@gtmhub/error-handling";
import { IGoal } from "@gtmhub/goals/models";
import { PlanningSessionsActions } from "@gtmhub/sessions/redux/session-actions";
import { ISessionsStoreState } from "@gtmhub/sessions/redux/session-reducer";
import { IExtendedRootScopeService } from "@gtmhub/shared/ng-extensions";
import { INgRedux } from "@gtmhub/state-management";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { PlanningSessionStatus, Session } from "@webapp/sessions/models/sessions.model";
import { SessionSelectorUtilsService } from "@webapp/sessions/services/session-selector-utils.service";
import { getSessionAndOneLevelChildren } from "@webapp/sessions/utils/utils";

const bindStateToCtrl = (state: ISessionsStoreState): ISessionSelectorDropdownRedux => {
  const { items: sessions, map: sessionIdMap, isFetched: sessionsFetched, error: sessionsError } = state.sessions;

  return {
    sessionIdMap: sessionIdMap,
    sessions: sessions,
    reduxItemsFetched: sessionsFetched,
    reduxItemsError: sessionsError,
  };
};

interface ISessionSelectorDropdownRedux {
  sessionIdMap: Record<string, Session>;
  sessions: Session[];
  reduxItemsFetched: boolean;
  reduxItemsError: IUIError;
}

export interface ISessionSelectorDropdownCtrlScope extends IExtendedRootScopeService, IScope, ISessionSelectorDropdownRedux {}

export interface ISessionSelectorDropdownComponentBindings {
  currentSession: Session;
  goal: IGoal;
  parentItem: IGoal | Metric;
  enableItemDisabledState: boolean;
  disabled?: boolean;
  onSelection(args: { sessionId: string }): void;
}

export interface SessionSelectorDropdownCtrl extends ISessionSelectorDropdownComponentBindings, ISessionSelectorDropdownRedux {
  allowDropdownToggle: boolean;
  isSearchInputVisible: boolean;
  sessionSearch: string;
  sessionIdMap: Record<string, Session>;
  sessions: Session[];
  enableItemDisabledState: boolean;
  sessionSelectorGeneratedId: number;
  filterWithHostId: boolean;
  subTreeBuild: boolean;
  indicators: { loading?: IIndicator };
}
export class SessionSelectorDropdownCtrl extends GtmhubController {
  public static $inject = ["$scope", "$ngRedux", "PlanningSessionsActions", "SessionSelectorUtilsService"];

  constructor(
    private $scope: ISessionSelectorDropdownCtrlScope,
    private $ngRedux: INgRedux,
    private sessionsActions: PlanningSessionsActions,
    private sessionSelectorUtilsService: SessionSelectorUtilsService
  ) {
    super();
    this.onDestroy(this.$ngRedux.connect(bindStateToCtrl)(this));
  }

  $onInit(): void {
    this.$ngRedux.dispatch(this.sessionsActions.getSessionsIfMissing());
    this.indicators = {
      loading: { progress: true },
    };
    this.allowDropdownToggle = true;
    this.subTreeBuild = false;
    this.sessionSelectorGeneratedId = Math.round(Math.random() * 1000 + 47);

    this.$scope
      .watchUntilAndRejectOnError(
        () => this.reduxItemsFetched,
        () => this.reduxItemsError
      )
      .then(
        () => {
          this.loadData();
        },
        (error) => (this.indicators.loading = { error })
      );
  }

  $onChanges(onChangesObj: IOnChangesObjectOf<ISessionSelectorDropdownComponentBindings>) {
    const { parentItem } = onChangesObj;
    if (parentItem && !parentItem.isFirstChange()) {
      if (parentItem.previousValue && !parentItem.currentValue) {
        this.sessions = Object.values(this.sessionIdMap);
      } else {
        const parentItemSession = this.sessionIdMap[parentItem.currentValue.sessionId];
        this.sessions = [parentItemSession, ...parentItemSession.children];
      }
      this.loadData();
    }
  }

  private loadData(): void {
    this.filterWithHostId = !!this.goal?.parentId;
    this.sessionSearch = "";
    this.isSearchInputVisible = false;

    // If we are inside edit objective view where the session is LOCKED we want to restrict all operations with the session selector
    const shouldRestrictOperationsWithSessionSelector = this.currentSession && this.currentSession.status !== PlanningSessionStatus.OPEN;
    if (shouldRestrictOperationsWithSessionSelector) {
      this.allowDropdownToggle = false;
    }

    // Whenever we have our goal aligned we want to show only our current session and its children
    if (this.parentItem && this.currentSession) {
      this.sessions = getSessionAndOneLevelChildren(this.sessions, this.parentItem.sessionId);

      const currentGoalAndParentItemAreInSharedSession = this.currentSession.id === this.parentItem.sessionId;

      this.filterWithHostId = currentGoalAndParentItemAreInSharedSession;
      this.subTreeBuild = this.sessionIdMap[this.parentItem.sessionId].parentId && !currentGoalAndParentItemAreInSharedSession;
    }

    // We want to have only OPEN sessions, however if a session is CLOSED or LOCKED we want to check if there is an open session in its children and show it in the tree
    if (this.enableItemDisabledState) {
      this.addPermissionsToSessions();
    }

    delete this.indicators.loading;
  }

  showCurrentSession(): void {
    this.sessionSearch = "";
    this.isSearchInputVisible = false;
  }

  hideCurrentSession(): void {
    window.setTimeoutOutsideAngular(() => {
      const search = document.getElementById(`sessionSearch-${this.sessionSelectorGeneratedId}`);
      search?.focus();
    });
    this.sessionSearch = "";
    this.isSearchInputVisible = true;
  }

  showSessionSelector(sessionSearch: string): void {
    this.sessionSearch = sessionSearch;
    this.isSearchInputVisible = this.sessionSearch?.length > 0 || this.isSearchInputVisible;
  }

  selectSession(sessionId: string): void {
    this.onSelection({ sessionId });
    this.currentSession = this.sessionIdMap[sessionId];
    this.isSearchInputVisible = false;
  }

  private addPermissionsToSessions(): void {
    this.sessions = this.sessionSelectorUtilsService.fillSessionWithPermissions(this.sessions);
    const sessionsWithPermissions = this.sessions.filter((session) => session.userHasPermissionToCreate);

    if (sessionsWithPermissions.length === 1 && !this.currentSession) {
      this.selectSession(sessionsWithPermissions[0].id);
    }
  }
}

export const SessionSelectorDropdownComponent: IComponentOptions = {
  controller: SessionSelectorDropdownCtrl,
  template: require("./session-selector-dropdown.html"),
  bindings: {
    currentSession: "<",
    goal: "<",
    parentItem: "<",
    onSelection: "&",
    enableItemDisabledState: "<?",
    disabled: "<?",
  },
};
