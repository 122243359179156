import { IAugmentedJQuery, IComponentOptions, IController, IPromise } from "angular";
import { IIndicator } from "@gtmhub/error-handling";
import { clickOnElement, hasParentElement } from "../../dom";

export interface IDndDroppedParams<P> {
  items: P[];
  item: P;
  oldIndex: number;
  newIndex: number;
}

export interface IDndInsertedParams<T, P> extends IDndDroppedParams<P> {
  data?: T;
}

export interface IOnDndDroppedCbResponse<T> {
  shouldProceed: boolean;
  data?: T;
}

interface IDndListComponentBindings<T, P> {
  items: P[];
  onDndDroppedCb<P>(params: IDndDroppedParams<P>): IPromise<IOnDndDroppedCbResponse<T>>;
  onDndInsertedCb<P>(params: IDndInsertedParams<T, P>): void;
}

const defaultInvalidIndex = -1;

export interface DndListComponentCtrl<T, P> extends IDndListComponentBindings<T, P> {
  enablePointerEvents: boolean;
  enableDndActions: boolean;
  disabledElementIdx: number;
  indicatorIdxs: {
    indicatorBeforeIdx: number;
    indicatorAfterIdx: number;
  };
  indicators: {
    indicatorBefore?: IIndicator;
    indicatorAfter?: IIndicator;
  };
}

export class DndListComponentCtrl<T, P> implements IController {
  static $inject = ["$element"];

  constructor(private $element: IAugmentedJQuery) {
    this.enableDndActions = true;
    this.disabledElementIdx = defaultInvalidIndex;
    this.indicators = {};
    this.indicatorIdxs = {
      indicatorBeforeIdx: defaultInvalidIndex,
      indicatorAfterIdx: defaultInvalidIndex,
    };
  }

  $onInit(): void {
    this.enablePointerEvents = true;
  }

  onDndDragStart() {
    // Prevent undesired hover effect while drag & drop items
    this.enablePointerEvents = false;
  }

  onDndCanceled(event) {
    this.enablePointerEvents = true;
    event.target.style.pointerEvents = "none";
    event.target.style.pointerEvents = "auto";
  }

  dragoverCallback(indexTo: number, callback: () => number): boolean {
    const indexFrom = callback();

    // Enable pointer events again if drag over on the same element
    // otherwise continue to disable them until onDndInserted event
    if (!this.isNotSamePositionInVerticalDndList(indexFrom, indexTo)) {
      this.enablePointerEvents = true;
    } else {
      this.enablePointerEvents = false;
    }

    return this.isNotSamePositionInVerticalDndList(indexFrom, indexTo);
  }

  onDndDrop(indexTo: number, callback: () => number, item: P) {
    const indexFrom = callback();
    const actualIndexTo = this.dndPlaceholderIdxToActualIdx(indexFrom, indexTo);

    this.enableDndActions = false;
    this.disabledElementIdx = indexFrom;
    if (actualIndexTo === 0) {
      this.indicators.indicatorBefore = { progress: true };
      this.indicatorIdxs.indicatorBeforeIdx = actualIndexTo;
    } else {
      this.indicators.indicatorAfter = { progress: true };
      this.indicatorIdxs.indicatorAfterIdx = actualIndexTo;
    }

    this.onDndDroppedCb<P>({ items: this.items, item, newIndex: actualIndexTo, oldIndex: indexFrom }).then((res) => {
      this.indicatorIdxs = {
        indicatorBeforeIdx: defaultInvalidIndex,
        indicatorAfterIdx: defaultInvalidIndex,
      };
      this.indicators = {};
      this.enableDndActions = true;

      if (!res.shouldProceed) {
        this.disabledElementIdx = defaultInvalidIndex;
        this.enablePointerEvents = true;
        return;
      }

      // We move the item manually after the onDndDroppedCb promise has been successfully resolved
      this.disabledElementIdx = defaultInvalidIndex;
      this.items.splice(indexFrom, 1);
      this.items.splice(actualIndexTo, 0, item);
      this.onDndInserted(indexTo, callback, item, res?.data);
    });

    // The drop will be canceled and the element won't be inserted automatically
    return false;
  }

  onDndInserted(indexTo: number, callback: () => number, item: P, data?: T) {
    this.enablePointerEvents = true;
    const indexFrom = callback();
    const actualIndexTo = this.dndPlaceholderIdxToActualIdx(indexFrom, indexTo);
    this.onDndInsertedCb({ items: this.items, item, newIndex: actualIndexTo, oldIndex: indexFrom, data });

    // Fixes a problem related to the usage of dnd-list inside an $uibModal
    // ==> after the list items have been rearranged the first backdrop click does not close the modal
    // ==> we simulate a click programmatically each time a dnd action occurs
    if (hasParentElement(this.$element, "[uib-modal-window]")) {
      clickOnElement(".modal.goal-sidebar");
    }
  }

  private isNotSamePositionInVerticalDndList(indexFrom: number, indexTo: number) {
    return indexFrom !== indexTo && indexFrom + 1 !== indexTo;
  }

  private dndPlaceholderIdxToActualIdx(indexFrom: number, indexTo: number) {
    // the index param is actually the index of the dnd-placeholder ui element
    // we want to find the actual index of the object
    // for reference: https://github.com/marceljuenemann/angular-drag-and-drop-lists/issues/173
    return indexFrom < indexTo ? indexTo - 1 : indexTo;
  }
}

export const GhDndListComponent: IComponentOptions = {
  template: require("./dnd-list.html"),
  controller: DndListComponentCtrl,
  bindings: {
    items: "<",
    onDndInsertedCb: "<",
    onDndDroppedCb: "<",
  },
  transclude: true,
};
