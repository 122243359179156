import { FocusTrapFactory } from "@angular/cdk/a11y";
import { OverlayRef } from "@angular/cdk/overlay";
import { CdkPortalOutlet, PortalModule } from "@angular/cdk/portal";
import { DOCUMENT, NgClass, NgIf, NgStyle } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  NgZone,
  Optional,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { ANIMATION_MODULE_TYPE } from "@angular/platform-browser/animations";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiAlertModule } from "@quantive/ui-kit/alert";
import { UiI18nModule } from "@quantive/ui-kit/i18n";
import { NzConfigService } from "ng-zorro-antd/core/config";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { ModalOptions, NzModalContainerComponent } from "ng-zorro-antd/modal";
import { NzToCssUnitPipe } from "ng-zorro-antd/pipes";
import { UiLoadingIndicatorModule } from "@webapp/ui/loading-indicator/loading-indicator.module";
import { UiScrollableContentDirective } from "@webapp/ui/utils/directives/scrollable-content.directive";
import { CustomModalOptions, UI_MODAL_INDEX } from "../../modal.models";
import { UiModalCloseComponent } from "../modal-close/modal-close.component";
import { UiModalDescriptionComponent } from "../modal-description/modal-description.component";
import { UiModalFooterComponent } from "../modal-footer/modal-footer.component";
import { UiModalOptionsButtonComponent } from "../modal-options-button/modal-options-button.component";
import { UiModalSideContentComponent } from "../modal-side-content/modal-side-content.component";
import { UiModalTitleComponent } from "../modal-title/modal-title.component";

@UntilDestroy()
@Component({
  selector: "ui-modal-container",
  exportAs: "uiModalContainer",
  templateUrl: "modal-container.component.html",
  // Using OnPush for modal caused footer can not to detect changes. we can fix it when 8.x.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
  host: {
    tabindex: "-1",
    role: "dialog",
    "[attr.aria-labelledby]": `config.nzTitle && modalTitleId`,
    "[attr.aria-describedby]": `modalDescriptionId`,
    "[class]": 'config.nzWrapClassName ? "ant-modal-wrap " + config.nzWrapClassName : "ant-modal-wrap"',
    "[class.ant-modal-wrap-rtl]": `dir === 'rtl'`,
    "[class.ant-modal-centered]": "config.nzCentered",
    "[style.zIndex]": "config.nzZIndex",
    "(click)": "onContainerClick($event)",
  },
  standalone: true,
  imports: [
    NgIf,
    NgStyle,
    NgClass,
    NzToCssUnitPipe,
    UiModalTitleComponent,
    UiI18nModule,
    UiModalDescriptionComponent,
    UiModalOptionsButtonComponent,
    CdkPortalOutlet,
    UiAlertModule,
    UiLoadingIndicatorModule,
    UiModalFooterComponent,
    UiModalSideContentComponent,
    UiScrollableContentDirective,
    UiModalCloseComponent,
    PortalModule,
  ],
})
export class UiModalContainerComponent extends NzModalContainerComponent implements AfterViewInit {
  @ViewChild(CdkPortalOutlet, { static: true }) public portalOutlet!: CdkPortalOutlet;
  @ViewChild("modalElement", { static: true }) public modalElementRef!: ElementRef<HTMLDivElement>;
  @ViewChildren("modalTitle") public modalTitleElementRef?: QueryList<ElementRef<HTMLDivElement>>;
  @ViewChildren("modalFooter") public modalFooterElementRef?: QueryList<ElementRef<HTMLDivElement>>;

  constructor(
    ngZone: NgZone,
    private elementRef: ElementRef<HTMLElement>,
    focusTrapFactory: FocusTrapFactory,
    cdr: ChangeDetectorRef,
    render: Renderer2,
    overlayRef: OverlayRef,
    nzConfigService: NzConfigService,
    config: ModalOptions,
    @Inject(UI_MODAL_INDEX) private modalIndex: number,
    @Optional() @Inject(DOCUMENT) document?: NzSafeAny,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) animationType?: string
  ) {
    super(ngZone, elementRef, focusTrapFactory, cdr, render, overlayRef, nzConfigService, config, document, animationType);
  }

  get modalTitleId(): string {
    return `ui-modal-${this.modalIndex}-title`;
  }

  get modalDescriptionId(): string {
    return `ui-modal-${this.modalIndex}-desc`;
  }

  get customConfig(): CustomModalOptions {
    return this.config as CustomModalOptions;
  }

  get hasScrollableContent(): boolean {
    return this.customConfig.nzNoScrollableContent !== true;
  }

  public ngAfterViewInit(): void {
    if (this.hasScrollableContent) {
      this.setBodyMinMaxHeight();

      this.handleModalElementResize();
      this.handleTitleElementChanges();
      this.handleFooterElementChanges();
    }
  }

  private handleModalElementResize(): void {
    const modalEl = this.elementRef.nativeElement.querySelector<HTMLElement>(".ant-modal");

    new ResizeObserver(() => {
      this.setBodyMinMaxHeight();
    }).observe(modalEl);
  }

  private handleTitleElementChanges(): void {
    this.modalTitleElementRef?.changes.pipe(untilDestroyed(this)).subscribe(() => {
      this.setBodyMinMaxHeight();
    });
  }

  private handleFooterElementChanges(): void {
    this.modalFooterElementRef?.changes.pipe(untilDestroyed(this)).subscribe(() => {
      this.setBodyMinMaxHeight();
    });
  }

  private setBodyMinMaxHeight(): void {
    const { nativeElement } = this.elementRef;
    const modalEl = nativeElement.querySelector<HTMLElement>(".ant-modal");
    const modalFooterEl = nativeElement.querySelector<HTMLElement>("[ui-modal-footer]");
    const scrollableEl = nativeElement.querySelector<HTMLElement>(".body-wrapper");

    scrollableEl.style.minHeight = null;

    const { top } = modalEl.getBoundingClientRect();
    const { top: scrollableTop, height: scrollableHeight } = scrollableEl.getBoundingClientRect();
    const modalFooterHeight = modalFooterEl ? modalFooterEl.getBoundingClientRect().height : 0;

    scrollableEl.style.maxHeight = `calc(100vh - ${scrollableTop + modalFooterHeight + top}px)`;
    scrollableEl.style.minHeight = `${Math.min(scrollableHeight, 100)}px`;
  }
}
