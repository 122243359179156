import { NgModule } from "@angular/core";
import { UiModalOptionsButtonComponent } from "@webapp/ui/modal/components/modal-options-button/modal-options-button.component";
import { UiModalSideContentComponent } from "@webapp/ui/modal/components/modal-side-content/modal-side-content.component";
import { UiModalOptionsButtonDirective } from "@webapp/ui/modal/directives/modal-options-button.directive";
import { UiModalSideContentDirective } from "@webapp/ui/modal/directives/modal-side-content.directive";
import { UiModalCloseComponent } from "./components/modal-close/modal-close.component";
import { UiModalConfirmContainerComponent } from "./components/modal-confirm-container/modal-confirm-container.component";
import { UiModalContainerComponent } from "./components/modal-container/modal-container.component";
import { UiModalDescriptionComponent } from "./components/modal-description/modal-description.component";
import { UiModalFooterComponent } from "./components/modal-footer/modal-footer.component";
import { UiModalTitleComponent } from "./components/modal-title/modal-title.component";
import { UiModalContentDirective } from "./directives/modal-content.directive";
import { UiModalDescriptionDirective } from "./directives/modal-description.directive";
import { UiModalFooterDirective } from "./directives/modal-footer.directive";
import { UiModalTitleDirective } from "./directives/modal-title.directive";
import { UiModalComponent } from "./modal.component";
import { UiModalService } from "./services/modal.service";
import { patchBaseModalContainerComponent } from "./utils/modal-container.utils";

patchBaseModalContainerComponent();

@NgModule({
  imports: [
    UiModalFooterDirective,
    UiModalContentDirective,
    UiModalTitleDirective,
    UiModalCloseComponent,
    UiModalConfirmContainerComponent,
    UiModalContainerComponent,
    UiModalFooterComponent,
    UiModalTitleComponent,
    UiModalComponent,
    UiModalDescriptionComponent,
    UiModalDescriptionDirective,
    UiModalSideContentDirective,
    UiModalOptionsButtonDirective,
    UiModalSideContentComponent,
    UiModalOptionsButtonComponent,
  ],
  exports: [
    UiModalFooterDirective,
    UiModalContentDirective,
    UiModalCloseComponent,
    UiModalFooterComponent,
    UiModalTitleComponent,
    UiModalTitleDirective,
    UiModalContainerComponent,
    UiModalConfirmContainerComponent,
    UiModalComponent,
    UiModalDescriptionComponent,
    UiModalDescriptionDirective,
    UiModalSideContentDirective,
    UiModalOptionsButtonDirective,
    UiModalSideContentComponent,
    UiModalOptionsButtonComponent,
  ],
  providers: [UiModalService],
})
export class UiModalModule {}
