import { ChangeDetectionStrategy, Component } from "@angular/core";
import { IMentionNotification } from "@gtmhub/notifications/models/notifications";
import { InboxTemplateComponent } from "../inbox-template.component";

const headingKeys: Record<string, string> = {
  description: "description",
  customFields: "custom_field",
  comment: "announcement",
};

const titleKeys: Record<string, string> = {
  goal: "user_mentioned_you_in_the_objective",
  metric: "user_mentioned_you_in_the_key_result",
  task: "user_mentioned_you_in_the_task",
  announcements: "user_mentioned_you_in_the_badge",
  kpi: "user_mentioned_you_in_the_kpi",
  user: "user_mentioned_you_in_the_user",
  team: "user_mentioned_you_in_the_team",
  automation: "user_mentioned_you_in_the_automation",
  metric_snapshot: "user_mentioned_you_in_kr_update",
};

const targetItemKeys: Record<string, string> = {
  goal: "objective",
  metric: "key_result_cap",
  task: "task",
  announcements: "announcement",
  kpi: "kpi",
  user: "user_cap",
  team: "team_cap",
  automation: "automation",
  metric_snapshot: "update",
};

@Component({
  selector: "mentions-inbox-template",
  templateUrl: "./mentions-inbox-template.component.html",
  styleUrls: ["../inbox-template.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentionsInboxTemplateComponent extends InboxTemplateComponent<IMentionNotification> {
  public get headingKey(): string {
    return headingKeys[this.notification.notificationData.item.fieldName];
  }

  public get titleKey(): string {
    return titleKeys[this.notification.notificationData.item.targetType];
  }

  public get targetItemKey(): string {
    return targetItemKeys[this.notification.notificationData.item.targetType];
  }
  public get icon(): string {
    if (this.notification.notificationData.item.targetType === "metric_snapshot") {
      return "metric";
    } else if (this.notification.notificationData.item.targetType === "announcements") {
      return "announcement";
    } else {
      return this.notification.notificationData.item.targetType;
    }
  }
}
