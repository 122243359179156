import { IComponentOptions, IPromise, IQService } from "angular";
import { IRestLayerRequest } from "@gtmhub/core";
import { ITraceRootScopeService } from "@gtmhub/core/tracing";
import { UIErrorHandlingService } from "@gtmhub/error-handling";
import { localize } from "@gtmhub/localization";
import { IGtmhubRootScopeService } from "@gtmhub/models";
import { ITag, TagService } from "@gtmhub/tags";
import { addRecentlyUsedTag, getRecentlyUsedTags } from "@gtmhub/tags/persisting/models";
import { isTagExistsResponseError } from "@gtmhub/tags/utils";
import { ICollection } from "@webapp/core/core.models";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { Ng1UiModalService } from "../modal/ng1-modal.service";

const DEFAULT_MAX_AUTOCOMPLETE_RESULTS_TO_SHOW = 10;

export interface ITagsAutocompleteInputComponentBindings {
  tags: ITag[];
  placeholder: string;
  onTagAdding(params: { tag: ITag }): void;
  onInvalidTag(params: { tag: ITag }): void;
  onTagAdded(params: { tag: ITag }): void;
  onTagRemoved(params: { tag: ITag }): void;
  onTagsLoading(params: { loadingState: IPromise<ITag[]> }): void;
  preventCustomValues?: boolean;
  disabled?: boolean;
  maxAutocompleteResultsToShow?: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface TagsAutocompleteInputCtrl extends ITagsAutocompleteInputComponentBindings {}
export class TagsAutocompleteInputCtrl {
  public static $inject = ["$rootScope", "$q", "Ng1UiModalService", "UIErrorHandlingService", "TagService", "FeatureTogglesFacade"];
  public tags: ITag[] = [];
  public inputText = "";
  public showingRecentlyUsedSuggestions = false;

  private loadedTags: ITag[];
  private isTagAdding = false;
  private hasCreatePermission = false;

  constructor(
    private $rootScope: IGtmhubRootScopeService & ITraceRootScopeService,
    private $q: IQService,
    private modalService: Ng1UiModalService,
    private uiErrorHandlingService: UIErrorHandlingService,
    private tagService: TagService,
    private featureToggleService: FeatureTogglesFacade
  ) {}

  public $onInit(): IPromise<void> {
    this.maxAutocompleteResultsToShow = this.maxAutocompleteResultsToShow || DEFAULT_MAX_AUTOCOMPLETE_RESULTS_TO_SHOW;
    return this.featureToggleService.isFeatureAvailable(FeatureFlag.EnableTagManagement).then((featureAvailable) => {
      this.hasCreatePermission = !featureAvailable || this.$rootScope.hasPermission("CreateTags");
    });
  }

  public loadTags(title?: string): IPromise<ITag[]> {
    const deferred = this.$q.defer<ITag[]>();

    this.onTagsLoading?.({ loadingState: deferred.promise });
    this.$rootScope.traceAction("load_tags", (): void => {
      const req: IRestLayerRequest = { filter: { isActive: true }, sort: "-itemsTaggedCount" };
      if (title) {
        req.filter.title = { $regex: title };
      } else {
        req.limit = -1;
      }

      this.tagService.getTagsV2(req).then(
        (collection) => deferred.resolve(this.getLoadedTagsSuggestions(collection)),
        (error) => deferred.reject(error)
      );
    });

    return deferred.promise;
  }

  public onInputBlur(): void {
    // clear the input from any left-over text if tags creation is disabled/forbidden for the current user
    // when tags creation is allowed, blurring the component resolves the text as an existing/new tag
    if (this.preventCustomValues || !this.hasCreatePermission) {
      this.inputText = "";
    }
  }

  public handleTagAdded(tag: ITag): void {
    this.onTagAdded?.({ tag });
    addRecentlyUsedTag(tag);
  }

  public handleTagAdding(tag: ITag): IPromise<ITag> | boolean {
    this.onTagAdding?.({ tag });

    if (tag.id) {
      return true;
    }
    if (this.isTagAdding) {
      return false;
    }
    this.isTagAdding = true;
    return this.addTag(tag.title.trim()).finally(() => {
      this.isTagAdding = false;
    });
  }

  public handleTagRemoved(tag: ITag): void {
    this.onTagRemoved?.({ tag });
  }

  public handleInvalidTag(tag: ITag): void {
    this.onInvalidTag?.({ tag });
  }

  public canCreateTag(title: string): boolean {
    return (
      !this.preventCustomValues &&
      Boolean(title) &&
      this.hasCreatePermission &&
      !this.loadedTags?.some((item) => title.toLocaleLowerCase() === item.title.toLocaleLowerCase())
    );
  }

  private addTag(title: string): IPromise<ITag> {
    if (!title) {
      return this.$q.reject("invalid title");
    }
    const existingTag = this.loadedTags?.find((t) => t.title.toLocaleLowerCase() === title.toLocaleLowerCase());
    if (existingTag) {
      return this.$q.resolve(existingTag);
    }
    if (!this.hasCreatePermission) {
      return this.$q.reject("no permission creating tags");
    }
    return this.tagService.createTag({ title }).catch((error) => {
      if (!isTagExistsResponseError(error)) {
        this.uiErrorHandlingService.handleModal(error);
        return this.$q.reject(error);
      }
      if (error.data.data.isActive) {
        return this.$q.resolve(error.data.data);
      }
      return this.showInactiveTagExistsModal(error.data.data);
    });
  }

  private showInactiveTagExistsModal(tag: ITag): IPromise<ITag> {
    const d = this.$q.defer<ITag>();
    this.modalService.confirm({
      uiTitle: localize("tag_exists_title"),
      uiContent: localize("existing_tag_is_deactivated_cta"),
      uiOkText: localize("activate"),
      uiOnOk: () => this.activateTag(tag).then(d.resolve, d.reject),
      uiCancelText: localize("cancel"),
      uiOnCancel: () => d.reject(null),
    });
    return d.promise;
  }

  private activateTag(tag: ITag): IPromise<ITag> {
    return this.tagService.changeTagsActivationStatus([tag.id], "activate").then(
      () => tag,
      (error) => {
        this.uiErrorHandlingService.handleModal(error);
        return this.$q.reject(error);
      }
    );
  }

  private getLoadedTagsSuggestions(collection: ICollection<ITag>): ITag[] {
    this.loadedTags = collection.items;
    if (this.inputText) {
      this.showingRecentlyUsedSuggestions = false;
      return this.loadedTags;
    }

    const recentlyUsedTagsCached = getRecentlyUsedTags();
    const sortedByRecentlyUsed = this.loadedTags.sort((a, b) => {
      const aIdx = recentlyUsedTagsCached.indexOf(a.title);
      const bIdx = recentlyUsedTagsCached.indexOf(b.title);
      return (aIdx > -1 ? aIdx : collection.totalCount) - (bIdx > -1 ? bIdx : collection.totalCount);
    });
    this.showingRecentlyUsedSuggestions = Boolean(sortedByRecentlyUsed.length);
    return sortedByRecentlyUsed;
  }
}

export const TagsAutocompleteInputComponent: IComponentOptions = {
  controller: TagsAutocompleteInputCtrl,
  template: require("./tags-autocomplete-input.component.html"),
  bindings: {
    tags: "=",
    placeholder: "@",
    onTagAdding: "&",
    onTagAdded: "&",
    onInvalidTag: "&",
    onTagRemoved: "&",
    onTagsLoading: "&",
    preventCustomValues: "<",
    disabled: "<?",
    maxAutocompleteResultsToShow: "<",
  },
};
