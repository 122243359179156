import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CommentsModule } from "@webapp/comments";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { RichTextEditorModule } from "../rich-text-editor/rich-text-editor.module";
import { RichTextEditorWithMentionsComponent } from "./rich-text-editor-with-mentions.component";

@NgModule({
  imports: [CommonModule, RichTextEditorModule, CommentsModule, LocalizationModule],
  declarations: [RichTextEditorWithMentionsComponent],
  exports: [RichTextEditorWithMentionsComponent],
  providers: [],
})
export class RichTextEditorWithMentionsModule {}
