import { Directionality } from "@angular/cdk/bidi";
import { AfterViewInit, Directive, ElementRef, Input, Optional, Renderer2, Self, ViewContainerRef, ɵgetHostElement } from "@angular/core";
import { NgControl } from "@angular/forms";
import { UiSizeLDSType } from "@quantive/ui-kit/core";
import { NzFormNoStatusService, NzFormStatusService } from "ng-zorro-antd/core/form";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzInputDirective } from "ng-zorro-antd/input";

@Directive({
  selector: "input[ui-input],textarea[ui-input]",
  exportAs: "uiInput",
  standalone: true,
})
export class UiInputDirective extends NzInputDirective implements AfterViewInit {
  @Input() @InputBoolean() public uiFocus = false;
  @Input("uiBorderless") @InputBoolean() public nzBorderless = false;
  @Input("uiSize") public nzSize: UiSizeLDSType = "default";
  @Input("disabled")
  get disabled(): boolean {
    return super.disabled;
  }

  set disabled(value: boolean) {
    super.disabled = value;
  }

  constructor(
    @Optional() @Self() ngControl: NgControl,
    renderer: Renderer2,
    elementRef: ElementRef,
    hostView: ViewContainerRef,
    @Optional() directionality: Directionality,
    @Optional() nzFormStatusService?: NzFormStatusService,
    @Optional() nzFormNoStatusService?: NzFormNoStatusService
  ) {
    super(ngControl, renderer, elementRef, hostView, directionality, nzFormStatusService, nzFormNoStatusService);
  }

  public ngAfterViewInit(): void {
    if (this.uiFocus) {
      window.setTimeoutOutsideAngular(() => {
        const el = ɵgetHostElement(this) as HTMLInputElement;
        el.focus();
      }, 10);
    }
  }
}
