import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import { IUnitFormatting } from "@gtmhub/shared/models";
import { FormItemBaseComponent } from "@webapp/shared/form/components/form-items/form-item-base.component";

@Component({
  selector: "unit-selector-form-item",
  templateUrl: "./unit-selector-form-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FormItemBaseComponent, useExisting: forwardRef(() => UnitSelectorFormItemComponent) }],
})
export class UnitSelectorFormItemComponent extends FormItemBaseComponent<IUnitFormatting> {
  @Input() public reserveScrollbarSpace = true;
}
