import { FocusMonitor } from "@angular/cdk/a11y";
import { Directionality } from "@angular/cdk/bidi";
import { CONTROL, DOWN_ARROW, END, ENTER, ESCAPE, HOME, LEFT_ARROW, META, RIGHT_ARROW, SHIFT, SPACE, TAB, UP_ARROW } from "@angular/cdk/keycodes";
import { CdkConnectedOverlay, CdkOverlayOrigin, OverlayModule } from "@angular/cdk/overlay";
import { NgClass, NgFor, NgIf, NgStyle, SlicePipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  DoCheck,
  ElementRef,
  EventEmitter,
  Host,
  HostListener,
  Injector,
  Input,
  NgZone,
  OnChanges,
  Optional,
  Output,
  Renderer2,
  Self,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { UiSizeLDSType } from "@quantive/ui-kit/core";
import { UiEmptyModule } from "@quantive/ui-kit/empty";
import { UiI18nService } from "@quantive/ui-kit/i18n";
import { NzConfigService, WithConfig } from "ng-zorro-antd/core/config";
import { NzNoAnimationDirective } from "ng-zorro-antd/core/no-animation";
import { NzFormatEmitEvent, NzTreeBaseService, NzTreeHigherOrderServiceToken, NzTreeNode, NzTreeNodeOptions } from "ng-zorro-antd/core/tree";
import { NgStyleInterface, NzSafeAny } from "ng-zorro-antd/core/types";
import { InputBoolean, isNotNil } from "ng-zorro-antd/core/util";
import { NzSelectSearchComponent } from "ng-zorro-antd/select";
import { NzTreeSelectComponent } from "ng-zorro-antd/tree-select";
import { UiSelectSearchComponent } from "@webapp/ui/select/components/select-search/select-search.component";
import { UiTreeComponent } from "@webapp/ui/tree/tree.component";
import { UiSelectModule } from "../select/select.module";
import { UiTreeModule } from "../tree/tree.module";
import { UiTreeSelectService } from "./services/tree-select.service";

function higherOrderServiceFactory(injector: Injector): NzTreeBaseService {
  return injector.get(UiTreeSelectService);
}

let nextUniqueId = 0;
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: "ui-tree-select",
  exportAs: "uiTreeSelect",
  templateUrl: "tree-select.component.html",
  styleUrls: ["tree-select.component.less"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    UiTreeSelectService,
    {
      provide: NzTreeHigherOrderServiceToken,
      useFactory: higherOrderServiceFactory,
      deps: [[new Self(), Injector]],
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiTreeSelectComponent),
      multi: true,
    },
  ],
  host: {
    "[class.tree-select-readonly]": "readonly",
    "[class.ant-select-disabled]": "nzDisabled",
  },
  standalone: true,
  imports: [NgClass, NgStyle, UiTreeModule, UiEmptyModule, NgIf, UiSelectModule, SlicePipe, NgFor, OverlayModule],
})
export class UiTreeSelectComponent extends NzTreeSelectComponent implements OnChanges, DoCheck {
  @Input("uiId") public nzId: string | null = null;
  @Input("uiAllowClear") @InputBoolean() public nzAllowClear = true;
  @Input("uiShowExpand") @InputBoolean() public nzShowExpand = true;
  @Input("uiShowLine") @InputBoolean() public nzShowLine = false;
  @Input("uiDropdownMatchSelectWidth") @InputBoolean() @WithConfig() public nzDropdownMatchSelectWidth = true;
  @Input("uiCheckable") @InputBoolean() public nzCheckable = false;
  @Input("uiHideUnMatched") @InputBoolean() @WithConfig() public nzHideUnMatched = false;
  @Input("uiShowIcon") @InputBoolean() @WithConfig() public nzShowIcon = false;
  @Input("uiClearIcon") public nzClearIcon: TemplateRef<NzSafeAny>;
  @Input("uiShowSearch") @InputBoolean() public nzShowSearch = false;
  @Input("uiInputValue") public inputValue: string;
  /**
   * Disables editing, focusing, and submitting the input.
   */
  @Input("uiDisabled") @InputBoolean() public nzDisabled = false;
  /**
   * Disables editing (the field is still focusable).
   */
  @Input() @InputBoolean() public readonly = false;
  @Input("uiAsyncData") @InputBoolean() public nzAsyncData = false;
  @Input("uiMultiple") @InputBoolean() public nzMultiple = false;
  @Input("uiDefaultExpandAll") @InputBoolean() public nzDefaultExpandAll = false;
  @Input("uiCheckStrictly") @InputBoolean() public nzCheckStrictly = false;
  @Input("uiLoading") @InputBoolean() public uiLoading = false;
  @Input("uiLoadingContentReference") public uiLoadingContentReference?: TemplateRef<NzSafeAny>;
  @Input("uiVirtualItemSize") public nzVirtualItemSize = 28;
  @Input("uiVirtualMaxBufferPx") public nzVirtualMaxBufferPx = 500;
  @Input("uiVirtualMinBufferPx") public nzVirtualMinBufferPx = 28;
  @Input("uiVirtualHeight") public nzVirtualHeight: string | null = null;
  @Input("uiExpandedIcon") public nzExpandedIcon?: TemplateRef<{ $implicit: NzTreeNode; origin: NzTreeNodeOptions }>;
  @Input("uiNotFoundContent") public nzNotFoundContent?: string;
  @Input("uiNotFoundContentReference") public nzNotFoundContentReference?: TemplateRef<NzSafeAny>;
  @Input("uiSearchFieldRequiredCharsTemplate") public uiSearchFieldRequiredCharsTemplate?: TemplateRef<NzSafeAny>;
  @Input("uiNodes") public nzNodes: Array<NzTreeNode | NzTreeNodeOptions> = [];
  @Input("uiOpen") public nzOpen = false;
  @Input("uiNotFound") public isNotFound: boolean;
  @Input("uiSize") @WithConfig() public nzSize: UiSizeLDSType = "default";
  @Input("uiPlaceHolder") public nzPlaceHolder = "";
  @Input("uiTitleTemplate") public nzTitleTemplate: TemplateRef<NzSafeAny>;
  @Input("uiShowTitle") @InputBoolean() @WithConfig() public nzShowTitle = false;
  @Input("uiDropdownStyle") public nzDropdownStyle: NgStyleInterface | null = null;
  @Input("uiDropdownClassName") public nzDropdownClassName?: string;
  @Input("uiBackdrop") @WithConfig() public nzBackdrop = false;
  @Input() public uiOffset: number = 0;
  @Input() public focusMe: boolean;
  @Input() public isTreeFlatten: boolean;
  /** Aria label of the select. */
  @Input() public a11yLabel = "";
  /** Input that can be used to specify the `aria-labelledby` attribute. */
  @Input() public a11yLabelledby: string;
  @Input() public a11yDescribedby: string;
  @Input() public a11yDescription: string;
  @Input() public a11yRequired = false;
  @Input() public a11yDisabled = false;
  @Input() public autofocus = false;
  @Input() public triggerOpen = false;
  @Input() @InputBoolean() public uiNodeExpansionEnabled = true;

  @HostListener("keydown.backspace", ["$event"])
  @HostListener("keydown.delete", ["$event"])
  @HostListener("click", ["$event"])
  protected handleRemoveItem(event: KeyboardEvent): void {
    const target = event.target as HTMLElement;
    const svg = event.target as SVGTextElement;

    const clickedOnSvg = svg?.getAttribute("data-icon") === "close-medium" || svg?.parentElement?.getAttribute("data-icon") === "close-medium";
    const clickedOnIcon = target?.getAttribute("uitype") === "close-medium";
    const clickedOnCloseButtonIcon = target?.parentElement?.getAttribute("uitype") === "close-medium";
    const clickedOnCloseButton = target?.firstElementChild?.getAttribute("uitype") === "close-medium";

    if (this.nzAllowClear && !this.nzDisabled && this.selectedNodes.length > 0 && (clickedOnCloseButton || clickedOnCloseButtonIcon || clickedOnIcon || clickedOnSvg)) {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          this.onClearSelection();
        });
      });
    }
  }

  public searchInputAriaDescription = "";

  public setSearchInputAriaDescription(): void {
    const description: string[] = [];

    if (this.hasNoSearchResults()) {
      description.push(this.i18n.translate("Select.noSearchResultsFound"));
    }

    if (this.selectedNodes.length === 0) {
      description.push(this.i18n.translate("Select.noValueSelected"));
      if (this.nzPlaceHolder) {
        description.push(this.nzPlaceHolder);
      }
    }

    if (this.selectedNodes.length > 0) {
      const selectedItemsStr =
        this.selectedNodes.length === 1
          ? this.i18n.translate("Select.selectedItem", { item: this.selectedNodes[0].origin.title })
          : this.i18n.translate("Select.selectedItems", { itemsCount: this.selectedNodes.length, items: this.selectedNodes.map((v) => v.origin.title).join(", ") });

      description.push(selectedItemsStr);
    }

    if (this.a11yDescription) {
      description.push(this.a11yDescription);
    }

    this.searchInputAriaDescription = description.join(" ");
  }

  private hasNoSearchResults(): boolean {
    return this.nzNodes.length === 0 || this.isNotFound;
  }

  private oldSelectedNodes: NzTreeNode[];
  private oldHasNoSearchResults: boolean;

  public ngDoCheck(): void {
    const selectedNodesChanged = this.selectedNodes !== this.oldSelectedNodes;
    const hasNoSearchResultsChanged = this.oldHasNoSearchResults !== this.hasNoSearchResults();

    if (selectedNodesChanged || hasNoSearchResultsChanged) {
      this.oldSelectedNodes = this.selectedNodes;
      this.oldHasNoSearchResults = this.hasNoSearchResults();
      this.setSearchInputAriaDescription();
    }
  }

  public listboxId = `ui-tree-select-listboxId-${nextUniqueId++}`;

  @Input("uiSelectItemTemplate") public selectItemTemplate: TemplateRef<{ $implicit: NzTreeNode; origin: NzTreeNodeOptions }>;

  @Input("uiExpandedKeys")
  public set nzExpandedKeys(value: string[]) {
    this.expandedKeys = value;
  }

  public get nzExpandedKeys(): string[] {
    return this.expandedKeys;
  }

  @Input("uiDisplayWith") public nzDisplayWith: (node: NzTreeNode) => string | undefined = (node: NzTreeNode) => node.title;
  @Input("uiMaxTagCount") public nzMaxTagCount!: number;
  @Input("uiMaxTagPlaceholder") public nzMaxTagPlaceholder: TemplateRef<{ $implicit: NzTreeNode[] }> | null = null;
  @Input("uiSelectedKeys") public value: string[] = [];
  @Input("uiSearchFunc") public nzSearchFunc?: (node: NzTreeNodeOptions) => boolean;
  @Output("uiOpenChange") public readonly nzOpenChange = new EventEmitter<boolean>();
  @Output("uiCleared") public readonly nzCleared = new EventEmitter<void>();
  @Output("uiRemoved") public readonly nzRemoved = new EventEmitter<NzTreeNode>();
  @Output("uiExpandChange") public readonly nzExpandChange = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiTreeClick") public readonly nzTreeClick = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiTreeCheckBoxChange") public readonly nzTreeCheckBoxChange = new EventEmitter<NzFormatEmitEvent>();
  @Output("uiSearchValueChange") public readonly searchValueChange = new EventEmitter<string>();
  @Output("uiSelectedValueChange") public readonly selectedValueChange = new EventEmitter<string[]>();

  @ViewChild(NzSelectSearchComponent, { static: false }) public nzSelectSearchComponent!: UiSelectSearchComponent;
  @ViewChild("treeRef", { static: false }) public treeRef!: UiTreeComponent;
  @ViewChild(CdkOverlayOrigin, { static: true }) public cdkOverlayOrigin!: CdkOverlayOrigin;
  @ViewChild(CdkConnectedOverlay, { static: false }) public cdkConnectedOverlay!: CdkConnectedOverlay;

  @Input("uiTreeTemplate") public nzTreeTemplate!: TemplateRef<{ $implicit: NzTreeNode; origin: NzTreeNodeOptions }>;
  @ContentChild("nzTreeTemplate", { static: true }) public nzTreeTemplateChild!: TemplateRef<{
    $implicit: NzTreeNode;
    origin: NzTreeNodeOptions;
  }>;
  public activatedNode: NzTreeNode;

  public constructor(
    private zone: NgZone,
    nzTreeService: UiTreeSelectService,
    nzConfigService: NzConfigService,
    renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef,
    focusMonitor: FocusMonitor,
    private i18n: UiI18nService,
    @Optional() directionality: Directionality,
    @Host() @Optional() noAnimation?: NzNoAnimationDirective
  ) {
    super(nzTreeService, nzConfigService, renderer, changeDetectorRef, elementRef, directionality, focusMonitor, noAnimation);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.value?.currentValue && changes.value?.currentValue.length === 0) {
      this.selectedNodes = [];
    }
  }

  public setInputValue(value: string): void {
    this.zone.run(() => {
      super.setInputValue(value);
      this.activatedNode = null;
      this.searchValueChange.emit(value);
    });
  }

  public updateSelectedNodes(init = false): void {
    super.updateSelectedNodes(init);
    this.selectedValueChange.emit(this.selectedNodes.map((node) => node.key));
  }

  public openDropdown(): void {
    if (this.nzDisabled || this.readonly) return;

    this.nzOpen = true;
    this.nzOpenChange.emit(this.nzOpen);
    this.updateCdkConnectedOverlayStatus();
    if (this.nzShowSearch || this.isMultiple) {
      this.focusOnInput();
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public onKeydown(event: KeyboardEvent): void {
    if (this.nzDisabled || this.readonly) {
      return;
    }

    switch (event.keyCode) {
      case HOME:
        {
          if (this.nzOpen) {
            // tested - Home / Fn+Left arrow - Moves focus to the first node in the tree without opening or closing a node.
            this.activateFirstNode();
            event.preventDefault();
          }
        }
        break;
      case END:
        {
          if (this.nzOpen) {
            // tested -  End / Fn + Right Arrow - Moves focus to the last node in the tree that is focusable without opening a node.
            this.activateLastNode();
            event.preventDefault();
          }
        }
        break;
      case LEFT_ARROW:
        {
          if (this.nzOpen) {
            //  When focus is on a root node that is also either an end node or a closed node, does nothing.
            if (!this.activatedNode) {
              return;
            }

            // Fn+Left arrow - Moves focus to the first node in the tree without opening or closing a node.

            event.preventDefault();
            // collapse When focus is on an open node, closes the node.
            if (this.activatedNode.isExpanded && this.activatedNode.children?.length > 0 && this.uiNodeExpansionEnabled) {
              this.toggleNodeExpansion(this.activatedNode);
            } else if (this.activatedNode.parentNode) {
              // When focus is on a child node that is also either an end node or a closed node, moves focus to its parent node.
              // focus parent
              this.activatedNode = this.activatedNode.parentNode;
            }
          }
        }
        break;
      case RIGHT_ARROW:
        {
          if (this.nzOpen) {
            // When focus is on an end node, does nothing.
            if (!this.activatedNode || this.activatedNode.isLeaf || this.activatedNode.children?.length <= 0) {
              return;
            }

            event.preventDefault();
            // expand -  When focus is on a closed node, opens the node; focus does not move.
            if (!this.activatedNode.isExpanded && this.uiNodeExpansionEnabled) {
              this.toggleNodeExpansion(this.activatedNode);
            } else if (this.activatedNode.isExpanded) {
              // When focus is on a open node, moves focus to the first child node.
              this.activateNextNode();
            }
          }
        }
        break;
      case DOWN_ARROW:
        {
          // If the dropdown is displayed and an option is selected, moves visual focus to the next value.

          // If the textbox is empty and the dropdown is not displayed, opens the dropdown and moves visual focus to the first option.

          // In both cases DOM focus remains on the textbox.
          event.preventDefault();
          if (this.nzOpen) {
            this.activateNextNode();
          } else {
            this.openDropdown();
            if (!event.altKey) {
              // Opens the dropdown without moving focus or changing selection.
              this.activateFirstNode();
            }
          }
        }
        break;
      case UP_ARROW:
        {
          // If the dropdown is displayed and an option is selected, moves visual focus to the last value in the list.
          // If the textbox is empty, first opens the dropdown if it is not already displayed and then moves visual focus to the last option.
          // In both cases DOM focus remains on the textbox.
          event.preventDefault();
          if (this.nzOpen) {
            this.activatePreviousNode();
          } else {
            this.openDropdown();
            this.activateLastNode();
          }
        }
        break;
      case ENTER:
        {
          // Sets the textbox value to the content of the focused option in the dropdown.
          // Closes the dropdown.
          // Sets visual focus on the textbox.
          event.preventDefault();
          if (this.nzOpen && isNotNil(this.activatedNode) && !this.activatedNode.origin.readonly) {
            this.activatedNode.isSelected = true;
            this.nzTreeClick.emit({ node: this.activatedNode, eventName: "click" });
            this.closeDropDown();
          }
        }
        break;
      case SPACE:
        if (!this.nzOpen) {
          event.preventDefault();
        }
        break;
      case META:
      case CONTROL:
      case SHIFT:
      case ESCAPE:
        /**
         * Skip the ESCAPE processing, it will be handled in {@link onOverlayKeyDown}.
         */
        break;
      case TAB:
        this.closeDropDown();
        break;
      default: {
        this.activatedNode = null;
        if (this.nzOpen || event.ctrlKey || event.altKey || event.metaKey || event.shiftKey) {
          return;
        }
        this.openDropdown();
        this.activateFirstNode();
      }
    }
  }

  private activatePreviousNode(): void {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        const nodes = this.visibleNodes;
        const activatedIndex = this.getActivatedNodeIndex(nodes);
        const prevIndex = this.calcPrevIndex(activatedIndex, nodes);
        this.activatedNode = nodes[prevIndex];
        this.changeDetectorRef.markForCheck();
      });
    });
  }

  private activateNextNode(): void {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        const nodes = this.visibleNodes;
        const activatedIndex = this.getActivatedNodeIndex(nodes);
        const nextIndex = this.calcNextIndex(activatedIndex, nodes);
        this.activatedNode = nodes?.[nextIndex];
        this.changeDetectorRef.markForCheck();
      });
    });
  }

  private calcPrevIndex(activatedIndex: number, nodes: NzTreeNode[]): number {
    let prevIndex = activatedIndex > 0 ? activatedIndex - 1 : nodes.length - 1;

    for (let i = prevIndex; i >= 0; i--) {
      prevIndex = i;
      const prev = nodes[i];

      if (!prev.origin.hidden) {
        return i;
      }
    }

    return prevIndex;
  }

  private calcNextIndex(activatedIndex: number, nodes: NzTreeNode[]): number {
    let nextIndex = activatedIndex < nodes.length - 1 ? activatedIndex + 1 : 0;

    for (let i = nextIndex; i < nodes.length; i++) {
      nextIndex = i;
      const next = nodes[i];

      if (!next.origin.hidden) {
        return i;
      }
    }

    return nextIndex;
  }

  private activateFirstNode(): void {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.activatedNode = this.visibleNodes[0];
        this.changeDetectorRef.markForCheck();
      });
    });
  }

  private activateLastNode(): void {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        const notDisabledNodes = this.visibleNodes.filter((item) => !item.isDisabled);
        this.activatedNode = notDisabledNodes?.[notDisabledNodes.length - 1];
        this.changeDetectorRef.markForCheck();
      });
    });
  }

  private get visibleNodes(): NzTreeNode[] {
    const nodes = this.inputValue ? this.nzTreeService.matchedNodeList : this.treeRef?.nzFlattenNodes;
    return nodes.filter((item) => !item.isDisabled);
  }

  private getActivatedNodeIndex(nodes: NzTreeNode[]): number {
    return nodes.findIndex((item) => item === this.activatedNode);
  }

  private toggleNodeExpansion(node: NzTreeNode): void {
    if (!node) {
      return;
    }

    if (!node.isLoading && !node.isLeaf) {
      if (this.nzAsyncData && node.children.length === 0 && !node.isExpanded) {
        node.isLoading = true;
      }
      node.setExpanded(!node.isExpanded);
    }
    this.nzTreeService.setExpandedNodeList(node);
    this.treeRef.renderTree();
    this.nzExpandChange.emit({ node, eventName: "expand" });
  }
}
