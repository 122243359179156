import { IDirective, IDirectiveFactory, IScope } from "angular";
import { CurrentUserRepository } from "@webapp/users";

export class MarketplaceLinkDirective implements IDirective {
  constructor(private currentUserRepository: CurrentUserRepository) {}

  public link(scope: IScope, element: JQuery<HTMLIFrameElement>): void {
    element.on("mousedown", () => this.currentUserRepository.setMarketplaceRedirectCookie());
  }

  public static factory(): IDirectiveFactory {
    const directive = (currentUserRepository: CurrentUserRepository) => new MarketplaceLinkDirective(currentUserRepository);

    directive.$inject = ["CurrentUserRepository"];

    return directive;
  }
}
