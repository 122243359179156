import { ContributorType, IGoal } from "@gtmhub/goals/models";
import { IReduxTeam } from "@gtmhub/teams";
import { IdMap } from "@gtmhub/util";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { PIStateProcessorInstanceSubType } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.models";
import {
  IQuantivePlusAssignees,
  IQuantivePlusExistingKr,
  IQuantivePlusExistingTask,
  IQuantivePlusMetric,
  IQuantivePlusMetricDescription,
  IQuantivePlusObjectiveDescription,
} from "@webapp/platform-intelligence/shared/models";
import {
  PIKeyResultDescriptionSuggestionPayload,
  PIKrSuggestionPayload,
  PIKrTasksSuggestionPayload,
  PIObjectiveTagsSuggestionPayload,
} from "@webapp/platform-intelligence/shared/models/quantive-plus-suggestions.models";
import { PIObjectiveDescriptionSuggestionPayload } from "@webapp/platform-intelligence/shared/models/strategic-guided-okr.models";
import { Task } from "@webapp/tasks/models/tasks.models";
import { IQuantivePlusMetricsAndTasksSuggestionStatus, IQuantivePlusSuggestionStatus, IQuantivePlusTag, IQuantivePlusTask, maxKrsSuggestions } from "../models";

export const generateAssignees = (ownerIds: string[], assignees: IdMap<Assignee>, teams: IReduxTeam[]): IQuantivePlusAssignees => {
  const filteredOwnerIds = (ownerIds || []).filter((id) => assignees[id]);
  const teamOwnersNames = filteredOwnerIds.filter((id) => assignees[id].type === "team").map((teamId) => ({ id: assignees[teamId].id, name: assignees[teamId].name }));
  const userOwners = filteredOwnerIds.filter((id) => assignees[id].type === "user").map((userId) => assignees[userId]);

  const userOwnersNamesWithManagingOrMemberingTeams = userOwners.map((user) => {
    const managingOrMemberingTeamsIds = teams.filter((team) => team.manager === user.id || team.members?.includes(user.id)).map((team) => team.id);
    const managingOrMemberingTeamsNames = managingOrMemberingTeamsIds.map((teamId) => assignees[teamId].name);

    return {
      id: user.id,
      name: user.name,
      team: managingOrMemberingTeamsNames,
    };
  });

  return {
    users: userOwnersNamesWithManagingOrMemberingTeams,
    teams: teamOwnersNames,
  };
};

export const generateDummyMetricSuggestion = (args: IQuantivePlusSuggestionStatus): IQuantivePlusMetric => ({
  description: "",
  format: { prefix: "", suffix: "", fractionSize: null },
  initialValue: null,
  target: null,
  targetOperator: "at_least",
  name: "",
  ...args,
});

export const generateDummyTaskSuggestion = (args: IQuantivePlusSuggestionStatus): IQuantivePlusTask => ({
  title: "",
  description: "",
  ...args,
});

export const generateMetricsSuggestionsStatus = (status: IQuantivePlusMetricsAndTasksSuggestionStatus = {}): IQuantivePlusSuggestionStatus => {
  return {
    ...(status.isLoading && {
      isLoading: true,
    }),
    ...(status.isError && {
      isError: true,
    }),
    ...(status.errorDataMetrics && {
      errorData: status.errorDataMetrics,
    }),
  };
};

export const generateTasksSuggestionsStatus = (status: IQuantivePlusMetricsAndTasksSuggestionStatus = {}): IQuantivePlusSuggestionStatus => {
  return {
    ...(status.isLoading && {
      isLoading: true,
    }),
    ...(status.isError && {
      isError: true,
    }),
    ...(status.errorDataTasks && {
      errorData: status.errorDataTasks,
    }),
  };
};

export const generateQuantivePlusSuggestionsPayload = (args: {
  goal: IGoal;
  assigneesMap: IdMap<Assignee>;
  teams: IReduxTeam[];
  existingKrs?: IQuantivePlusExistingKr[];
  tasks?: Task[];
  nSuggestions?: number;
  isRefresh?: boolean;
  rejectedKrs?: IQuantivePlusMetric[];
  rejectedTasks?: IQuantivePlusTask[];
  subEntityType: PIStateProcessorInstanceSubType;
}): PIKrSuggestionPayload => {
  const assignees = generateAssignees(args.goal.ownerIds, args.assigneesMap, args.teams);
  const existingTasks = buildExistingTasksPayload(args.tasks || []);

  return {
    entityId: args.goal.id,
    subEntityType: args.subEntityType,
    title: args.goal.name,
    ...(args.goal.description && {
      description: args.goal.description,
    }),
    nSuggestions: args.nSuggestions || maxKrsSuggestions,
    ...(args.existingKrs && {
      existingKrs: args.existingKrs,
    }),
    existingTasks,
    assignees,
    ...(args.isRefresh && {
      refresh: true,
    }),
    ...((args.rejectedKrs || args.rejectedTasks) && {
      rejected: {
        ...(args.rejectedKrs && {
          keyresults: args.rejectedKrs,
        }),
        ...(args.rejectedTasks && {
          tasks: args.rejectedTasks,
        }),
      },
    }),
  };
};

export const generateQuantivePlusObjectiveDescriptionSuggestionPayload = (args: {
  goal: IGoal;
  assigneesMap: IdMap<Assignee>;
  teams: IReduxTeam[];
  isRefresh?: boolean;
  rejectedDescriptions?: IQuantivePlusObjectiveDescription[];
}): PIObjectiveDescriptionSuggestionPayload => {
  const assignees = generateAssignees(args.goal.ownerIds, args.assigneesMap, args.teams);
  const descriptionsToSuggest = 1;

  return {
    entityId: args.goal.id,
    subEntityType: "description",
    objectives: [
      {
        title: args.goal.name,
        assignees,
        nSuggestions: descriptionsToSuggest,
        ...(args.rejectedDescriptions && {
          rejected: {
            descriptions: args.rejectedDescriptions,
          },
        }),
      },
    ],
    ...(args.isRefresh && {
      refresh: true,
    }),
  };
};

export const generateQuantivePlusKeyResultDescriptionSuggestionPayload = (args: {
  metric: Partial<Metric>;
  assigneesMap: IdMap<Assignee>;
  teams: IReduxTeam[];
  isRefresh?: boolean;
  rejectedDescriptions?: IQuantivePlusMetricDescription[];
  entityId: string;
  subEntityType: PIStateProcessorInstanceSubType;
}): PIKeyResultDescriptionSuggestionPayload => {
  const assignees = generateAssignees(args.metric.ownerIds, args.assigneesMap, args.teams);

  return {
    entityId: args.entityId,
    subEntityType: args.subEntityType,
    title: args.metric.name,
    assignees,
    ...(args.rejectedDescriptions && {
      rejected: {
        descriptions: args.rejectedDescriptions,
      },
    }),
    ...(args.isRefresh && {
      refresh: true,
    }),
  };
};

export const generateQuantivePlusObjectiveTagsSuggestionPayload = (args: {
  goal: IGoal;
  assigneesMap: IdMap<Assignee>;
  teams: IReduxTeam[];
  isRefresh?: boolean;
  rejectedTags?: IQuantivePlusTag[];
}): PIObjectiveTagsSuggestionPayload => {
  const assignees = generateAssignees(args.goal.ownerIds, args.assigneesMap, args.teams);
  const tagsToSuggest = 3;

  return {
    entityId: args.goal.id,
    subEntityType: "tags",
    title: args.goal.name,
    ...(args.goal.description && {
      description: args.goal.description,
    }),
    nSuggestions: tagsToSuggest,
    assignees,
    ...(args.isRefresh && {
      refresh: true,
    }),
    ...(args.rejectedTags && {
      rejected: {
        tags: args.rejectedTags,
      },
    }),
  };
};

const buildExistingTasksPayload = (tasks: Task[]): IQuantivePlusExistingTask[] => {
  return tasks.map((task) => {
    return {
      title: task.name,
      ...(task.description && {
        description: task.description,
      }),
    };
  });
};

export const generateQuantivePlusKrTasksSuggestionsPayload = (args: {
  metric: Metric;
  goal: IGoal;
  assigneesMap: IdMap<Assignee>;
  teams: IReduxTeam[];
  combinedGoalAndMetricExistingTasks: Task[];
  tasksToSuggest: number;
  isRefresh?: boolean;
  rejectedTasks?: IQuantivePlusTask[];
  subEntityType: PIStateProcessorInstanceSubType;
  entityId: string;
}): PIKrTasksSuggestionPayload => {
  const metricAssignees = generateAssignees(args.metric.ownerIds, args.assigneesMap, args.teams);
  const goalAndMetricExistingTasksData = buildExistingTasksPayload(args.combinedGoalAndMetricExistingTasks);

  return {
    entityId: args.entityId,
    subEntityType: args.subEntityType,
    krTitle: args.metric.name,
    ...(args.metric.description && {
      krDescription: args.metric.description,
    }),
    initialValue: args.metric.initialValue,
    actualValue: args.metric.actual,
    targetValue: args.metric.target,
    assignees: metricAssignees,
    parentTitle: args.goal.name,
    ...(args.goal.description && {
      parentDescription: args.goal.description,
    }),
    existingTasks: goalAndMetricExistingTasksData,
    nSuggestions: args.tasksToSuggest,
    ...(args.isRefresh && {
      refresh: true,
    }),
    ...(args.rejectedTasks && {
      rejected: {
        tasks: args.rejectedTasks,
      },
    }),
  };
};

export const getExistingKrsFromGoal = (goalChildren: ContributorType[]): IQuantivePlusExistingKr[] => {
  if (!goalChildren.length) {
    return [];
  }

  return goalChildren
    .filter((child) => child.childType === "metric")
    .map((metric) => ({
      title: metric.name,
      ...(metric.description && {
        description: metric.description,
      }),
    }));
};

export const calculateSuggestionCardActionButtonsContainerWidth = (args: { availableActionButtons: number }): number => {
  const singleButtonContainerWidth = 32;
  const gapBetweenTwoButtons = 4;
  const totalGapBetweenButtons = (args.availableActionButtons - 1) * gapBetweenTwoButtons;

  return args.availableActionButtons * singleButtonContainerWidth + totalGapBetweenButtons;
};
