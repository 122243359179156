import { Pipe, PipeTransform } from "@angular/core";
import { TodoItem } from "@webapp/home/components/widgets/todos-widget/todos-widget.models";
import { getTodoDueDate } from "./utils";

@Pipe({
  name: "todoDueDate",
  standalone: true,
})
export class TodoDueDatePipe implements PipeTransform {
  public transform(todo: TodoItem): string {
    return getTodoDueDate(todo);
  }
}
