// eslint-disable-next-line no-restricted-imports
import * as m from "marked";
import { gfmHeadingId } from "marked-gfm-heading-id";
import { mangle } from "marked-mangle";

m.marked.use(mangle());
m.marked.use(gfmHeadingId());

export const marked = m.marked;
export default m;
