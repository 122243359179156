<div class="suggestion-card-error" ui-row uiAlign="top">
  <div class="sp-pt-7 sp-pb-5" ui-col>
    <i class="fs-16px text-color-gray-1 caution-icon" ui-icon uiType="quantive-plus-caution"></i>
  </div>
  <div class="sp-py-6" ui-col uiFlex="1">
    <span class="text-content">{{ errorMessage }}</span>
  </div>
  <div *ngIf="areActionButtonsAvailable" [uiFlex]="actionButtonsContainerWidth + 'px'" ui-col>
    <div *ngIf="showActionButtons" ui-row uiJustify="end" uiAlign="middle">
      <button
        class="sp-p-6 suggestion-card-action"
        *ngIf="isRefreshBtnAvailable"
        [uiTooltipTitle]="'suggest_again' | localize"
        (click)="onRefreshErrorClicked()"
        ui-button
        uiSize="default"
        ui-tooltip
        uiTooltipOverlayClassName="suggestion-card-action-button-tooltip"
        uiTooltipPlacement="topRight">
        <i class="fs-16px" ui-icon uiType="suggestion-card-refresh"></i>
      </button>
      <button
        class="sp-ml-4 sp-p-6 suggestion-card-action"
        *ngIf="isRemoveBtnAvailable"
        [uiTooltipTitle]="'remove' | localize"
        (click)="onRemoveErrorClicked()"
        ui-button
        uiSize="default"
        ui-tooltip
        uiTooltipOverlayClassName="suggestion-card-action-button-tooltip"
        uiTooltipPlacement="topRight">
        <i class="text-color-gray-6 fs-16px" ui-icon uiType="suggestion-card-remove"></i>
      </button>
    </div>
  </div>
</div>
