import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiToggleModule } from "@quantive/ui-kit/toggle";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { EditionFeatureDirective } from "@webapp/accounts/directives/edition-feature.directive";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { FeatureTogglesModule } from "@webapp/feature-toggles/feature-toggles.module";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { OkrsCoreModule } from "@webapp/okrs/okrs-core.module";
import { GifViewModule } from "@webapp/shared/components/gifs/gif-view.module";
import { PeopleSelectorModule } from "@webapp/shared/components/people-selector/people-selector.module";
import { CdnImgModule } from "@webapp/shared/directives/cdn-img/cdn-img.module";
import { IndicatorModule } from "@webapp/shared/indicator/indicator.module";
import { RichTextEditorModule } from "@webapp/shared/rich-text-editor/rich-text-editor.module";
import { InboxTemplateListComponent } from "./components/inbox-template-list/inbox-template-list.component";
import { inboxTemplateComponents } from "./components/inbox-templates";
import { NotificationCategoryComponent } from "./components/notification-category/notification-category.component";
import { NotificationLayoutComponent } from "./components/notification-layout/notification-layout.component";
import { NotificationSettingsComponent } from "./components/notification-settings/notification-settings.component";
import { PersonalNotificationComponent } from "./components/personal-notification/personal-notification.component";
import { NotificationsRoutingModule } from "./notifications-routing.module";
import { AsAssigneePipe } from "./pipes/as-assignee.pipe";
import { CheckinNoteTypePipe } from "./pipes/checkin-note-type.pipe";
import { NotificationsServiceProvider } from "./providers/notification.provider";
import { NotificationsInboxServiceProvider } from "./providers/notifications-inbox.provider";
import { ToggleChangeService } from "./services/toggle-change.service";

@NgModule({
  declarations: [
    NotificationSettingsComponent,
    NotificationLayoutComponent,
    NotificationCategoryComponent,
    PersonalNotificationComponent,
    ...inboxTemplateComponents,
    InboxTemplateListComponent,
    AsAssigneePipe,
    CheckinNoteTypePipe,
  ],
  exports: [NotificationLayoutComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NotificationsRoutingModule,
    PeopleSelectorModule,
    LocalizationModule,
    IndicatorModule,
    UiToggleModule,
    UiIconModule,
    UiTooltipModule,
    RichTextEditorModule,
    AssigneesModule,
    OkrsCoreModule,
    CdnImgModule,
    GifViewModule,
    FeatureTogglesModule,
    EditionFeatureDirective,
  ],
  providers: [ToggleChangeService, NotificationsServiceProvider, NotificationsInboxServiceProvider],
})
export class NotificationsModule {}
