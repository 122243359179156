import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { IPIImprovementDTO, IPIImprovementItemModel, IPostImprovementPayload } from "./models";
import { PiImprovementApiService } from "./pi-improvement-api.service";
import { PiImprovementServiceState } from "./pi-improvement-state.service";

@Injectable({
  providedIn: "any",
})
export class PiImprovementFacade extends BaseFacade<IPIImprovementItemModel, IPIImprovementDTO, PiImprovementServiceState, PiImprovementApiService> {
  constructor(state: PiImprovementServiceState, api: PiImprovementApiService) {
    super(state, api);
  }

  public postImprovement<T = IPIImprovementItemModel>(payload: IPostImprovementPayload): Observable<IPIImprovementItemModel<T>> {
    return this.post$(payload, { ...new RequestConfig(), url: this.apiService.getRoot() });
  }
}
