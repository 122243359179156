import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { ICollection } from "@webapp/core/core.models";
import { ActiveOkrsListGroup, ActiveOkrsListSort, OkrsSessionGroup } from "../assignee-active-okrs-list.models";
import { AssigneeActiveOkrsApiService } from "./assignee-active-okrs-api.service";

@Injectable({ providedIn: "root" })
export class AssigneeActiveOkrsRepository {
  constructor(private api: AssigneeActiveOkrsApiService) {}

  public getMyOkrs$<ResponseType = ICollection<OkrsSessionGroup>>(params: {
    options: {
      sort: ActiveOkrsListSort;
      group: ActiveOkrsListGroup;
    };
    filter?: RequestPaging;
    config?: RequestConfig;
  }): Observable<ResponseType> {
    return this.api.getMyOkrs$({
      ...params?.config,
      queryParams: {
        ...params?.config?.queryParams,
        sortBy: params.options.sort,
        groupBy: params.options.group,
      },
    });
  }

  public getTeamOkrs$<ResponseType = ICollection<OkrsSessionGroup>>(
    options: { teamId: string; sort?: ActiveOkrsListSort; group?: ActiveOkrsListGroup },
    params?: { filter?: RequestPaging; config?: RequestConfig }
  ): Observable<ResponseType> {
    return this.api.getTeamOkrs$(options.teamId, {
      ...params?.config,
      queryParams: {
        ...params?.config?.queryParams,
        ...(options.sort && { sortBy: options.sort }),
        ...(options.group && { groupBy: options.group }),
      },
    });
  }

  public teamHasOkrs$(teamId: string): Observable<boolean> {
    const config = {
      ...new RequestConfig(),
      queryParams: { onlyCount: true },
    } as RequestConfig;

    return this.getTeamOkrs$({ teamId }, { config }).pipe(map((response) => response.totalCount > 0));
  }
}
