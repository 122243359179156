import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { NzSelectArrowComponent } from "ng-zorro-antd/select";
import { UiLoadingIndicatorModule } from "@webapp/ui/loading-indicator/loading-indicator.module";

@Component({
  selector: "ui-select-arrow",
  templateUrl: "select-arrow.component.html",
  styleUrls: ["select-arrow.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, UiLoadingIndicatorModule, UiIconModule, NzOutletModule],
})
export class UiSelectArrowComponent extends NzSelectArrowComponent {}
