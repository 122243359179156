<div class="sp-px-32 sp-py-12" ui-row uiJustify="space-between" uiAlign="middle">
  <h3 data-test-id="page-title">{{ "suggest_okrs" | localize }}</h3>
  <i (click)="closeModal()" ui-icon ui-col uiType="close"></i>
</div>

<div
  class="bg-color-gray-2 sp-py-32 body"
  *ngIf="stage === 'initial' && !hasSuggestedObjectives && !suggestingObjectives && !suggestingObjectivesError && getObjectives.value.length === 0"
  ui-row
  uiJustify="center">
  <div ui-col uiFlex="628px">
    <div class="sp-mb-12">
      <p class="text-color-gray-13">{{ "use_ai_powered_okrs" | localize }}</p>
    </div>

    <div class="sp-mb-12 readonly">
      <div class="text-color-gray-13" data-test-id="suggest-okrs-modal-session">{{ "session" | localize }}</div>
      <gh-session-selector
        *ngIf="!readOnlySession"
        [a11yRequired]="true"
        [value]="currentSession?.id"
        [borderless]="true"
        (valueChange)="changeSelectedSession($event)"
        a11yLabelledby="session-label"></gh-session-selector>

      <div class="title sp-p-8" *ngIf="readOnlySession" ui-row uiAlign="middle">
        <i class="sp-mr-6" [uiTooltipTitle]="'session' | localize" ui-icon ui-tooltip uiTheme="fill" uiType="session"></i>
        <span>{{ currentSession.title }}</span>
      </div>
    </div>

    <div class="sp-mb-12 readonly" *ngIf="parent">
      <div class="text-color-gray-13">{{ "parent" | localize }}</div>
      <div class="title sp-p-8" ui-row uiAlign="middle">
        <ui-assignee-avatar class="sp-mr-6" *ngFor="let id of parent.ownerIds" [uiAssignee]="assigneeIdMap[id]" [uiShowTooltip]="true" uiSize="xs"></ui-assignee-avatar>
        <span #parentTitleEl *ngIf="!this.firstPart">{{ this.parent.title }}</span>
        <div class="parent-title" *ngIf="!!this.firstPart" [uiTooltipTrigger]="!!this.firstPart ? 'hover' : null" [uiTooltipTitle]="this.parent.title" ui-tooltip uiTooltipPlacement="bottom">
          <span class="first-part">{{ this.firstPart }}</span>
          <span class="second-part">{{ this.secondPart }}</span>
        </div>
      </div>
    </div>

    <div class="sp-mb-12">
      <div class="text-color-gray-13">{{ "who_is_involved" | localize }}</div>
      <people-selector
        [a11yRequired]="true"
        [atLeastOneValue]="true"
        [request]="peopleSelectorRequestInternal"
        [selectedIds]="selectedIds"
        [placeholderKey]="'add_users' | localize"
        (selectionChange)="selectedIds = $event"
        data-test-id="suggest-okrs-modal-who-is-involved"></people-selector>
      <div class="text-color-gray-9 fs-12px" ui-row>{{ "select_involved_owners" | localize }}</div>
    </div>

    <div>
      <div class="text-color-gray-13">{{ "what_do_you_want_to_accomplish" | localize }}</div>
      <form [formGroup]="objectiveForm" ui-form nzLayout="vertical">
        <ui-form-item ui-row>
          <ui-form-control>
            <ui-textarea-count [uiMaxCharacterCount]="500">
              <textarea data-test-id="strategy" formControlName="strategy" rows="4" ui-input></textarea>
            </ui-textarea-count>
          </ui-form-control>
        </ui-form-item>
      </form>

      <div class="sp-mt-12 text-color-gray-9 info fs-12px" ui-row>
        {{ "more_details_stronger_suggestion" | localize }}
      </div>
    </div>
  </div>
</div>

<div
  class="bg-color-gray-2 sp-py-32 body"
  *ngIf="stage !== 'initial' || hasSuggestedObjectives || suggestingObjectives || suggestingObjectivesError || getObjectives.value.length"
  [ngClass]="{ krs: hasSuggestedKrs, objectives: hasSuggestedObjectives }">
  <div ui-row uiJustify="center">
    <div ui-col uiFlex="628px">
      <div *ngIf="!suggestingObjectivesError" ui-row>
        <div *ngIf="suggestingObjectives">{{ "suggesting_objectives" | localize }}</div>
        <div *ngIf="!suggestingObjectives">{{ "these_are_your_recommended_objs" | localize }}</div>
      </div>

      <quantive-plus-wizard-objective
        class="sp-mt-12"
        *ngIf="!suggestingObjectivesError"
        [formGroup]="objectiveForm"
        [flowId]="flowId"
        [flowName]="flowName"
        [hasSuggestedKrs]="hasSuggestedKrs"
        [suggestingKeyResults]="suggestingKeyResults"
        [isEditing]="isEditing()"
        [isKeyResultsStage]="stage === 'keyresults'"
        (confirmObjective)="confirmObjective($event)"
        (removeObjective)="removeObjective($event)"
        (addKeyResultCb)="addKeyResult($event)"
        (removeKeyResultCb)="removeKeyResult($event)"
        (suggestMoreKeyResultsEvent)="suggestMoreKeyResults($event)"
        (refreshObjectiveDescriptionEvent)="refreshObjectiveDescription($event)"
        (refreshKeyResultsErrorEvent)="suggestMoreKeyResults($event)"
        ui-row>
      </quantive-plus-wizard-objective>

      <ng-container *ngIf="suggestingObjectives">
        <div class="sp-mt-12" *ngFor="let i of [1, 2, 3, 4]" ui-row>
          <div class="skeleton" ui-col uiFlex="1">
            <div ui-row>
              <div ui-col>
                <i class="fs-16px" ui-icon uiTheme="fill" uiType="goal"></i>
              </div>
              <div class="sp-ml-12" ui-col uiFlex="1">
                <ui-skeleton [uiActive]="true" [uiTitle]="false" [uiParagraph]="{ rows: 3, width: ['100%', '100%', '24px'] }" [uiSectionHeight]="{ title: 'h-md', paragraph: 'h-sm' }"></ui-skeleton>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <pi-error-card
        *ngIf="suggestingObjectivesError"
        [isRemoveBtnAvailable]="false"
        [refreshBtnTooltipTitle]="'suggest_again' | localize"
        [errorMessage]="'unable_to_suggest_objectives' | localize"
        (errorRefresh)="suggestObjectives()"></pi-error-card>

      <div class="sp-mt-12" *ngIf="suggestingMoreObjectivesError">
        <pi-error-card
          [isRemoveBtnAvailable]="false"
          [refreshBtnTooltipTitle]="'suggest_again' | localize"
          [errorMessage]="'unable_to_suggest_objectives' | localize"
          (errorRefresh)="suggestMoreObjectives()"></pi-error-card>
      </div>

      <div class="sp-mt-12" *ngIf="stage !== 'keyresults' && !suggestingObjectives && !suggestingKeyResults && !hasSuggestedKrs && getObjectives.value.length < 5" ui-row>
        <button class="sp-mr-12" *ngIf="!suggestingObjectivesError" [disabled]="isEditing()" (click)="suggestMoreObjectives()" ui-button uiShape="round" uiType="link" uiSize="default">
          <i ui-icon uiTheme="fill" uiType="loader_refresh"></i>
          <span class="sp-ml-6" *ngIf="getObjectives.value.length !== 0">{{ "suggest_more" | localize }}</span>
          <span class="sp-ml-6" *ngIf="getObjectives.value.length === 0">{{ "suggest_objectives" | localize | titlecase }}</span>
        </button>

        <button class="sp-mr-12" *ngIf="suggestingObjectivesError" (click)="suggestObjectives()" ui-button uiShape="round" uiType="link" uiSize="default">
          <i ui-icon uiTheme="fill" uiType="loader_refresh"></i>
          <span class="sp-ml-6">{{ "suggest_objectives" | localize | titlecase }}</span>
        </button>

        <button [disabled]="isEditing()" (click)="addObjective()" ui-button uiShape="round" uiType="link" uiSize="default">
          <i class="fs-18px" ui-icon uiType="plus"></i>
          <span class="sp-ml-6">{{ "add_manually" | localize }}</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="sp-px-32 sp-py-12">
  <div class="sp-mb-12" *ngIf="addingOkrsToWhiteboardError" ui-row uiJustify="end">
    <div class="sp-p-10 bg-color-error-3 error-container" ui-row uiAlign="middle">
      <i class="fs-16px text-color-error-8" ui-icon ui-col uiType="failed"></i>

      <div class="text-color-gray-13">
        <span>{{ "we_experience_problems" | localize }}</span>
        <a (click)="openIntercom()">{{ "contact_support" | localize }}.</a>
      </div>

      <i class="fs-16px" (click)="addingOkrsToWhiteboardError = false" ui-icon ui-col uiTheme="fill" uiType="ui-1_simple-remove"></i>
    </div>
  </div>

  <div ui-row uiJustify="end" uiAlign="middle">
    <div *ngIf="showConfirmationDialog" ui-row uiAlign="middle">
      <div class="sp-mr-12" ui-col>{{ "going_back_reset_progress" | localize }}</div>

      <button *ngIf="stage !== 'initial'" (click)="back()" ui-button ui-col uiShape="round" uiType="primary">{{ "back" | localize }}</button>
      <button *ngIf="stage !== 'initial'" (click)="reset(false)" ui-button ui-col uiShape="round" uiType="link">{{ "cancel" | localize }}</button>
    </div>

    <div *ngIf="!showConfirmationDialog">
      <button *ngIf="stage === 'initial'" (click)="closeModal()" ui-button uiShape="round" uiType="link">{{ "cancel" | localize }}</button>
      <button *ngIf="stage !== 'initial'" [disabled]="suggestingObjectives || suggestingKeyResults || isEditing()" (click)="reset(true)" ui-button uiShape="round" uiType="link">
        {{ "back" | localize }}
      </button>

      <button class="sp-ml-12" *ngIf="stage === 'initial'" [disabled]="getStrategy.invalid || isEditing()" (click)="suggestObjectives()" ui-button uiShape="round" uiType="primary">
        <span class="sp-mr-6" data-test-id="suggest-okrs-modal-suggest-objectives-button">{{ "suggest_objectives" | localize | titlecase }}</span>
        <i ui-icon uiType="chevron-right-big"></i>
      </button>

      <button
        class="sp-ml-12"
        *ngIf="stage === 'objectives'"
        [disabled]="suggestingObjectives || isFetchingDescription() || getObjectives.invalid || isEditing()"
        (click)="suggestKeyResults()"
        ui-button
        uiShape="round"
        uiType="primary">
        <span class="sp-mr-6" data-test-id="suggest-okrs-modal-suggest-key-results-button">{{ "suggest_key_results" | localize | titlecase }}</span>
        <i ui-icon uiType="chevron-right-big"></i>
      </button>

      <button
        class="sp-ml-12"
        *ngIf="stage === 'keyresults'"
        [disabled]="suggestingKeyResults || isSuggestingMoreKeyResults() || getObjectives.invalid || isEditing()"
        (click)="addOkrsToWhiteboard()"
        ui-button
        uiShape="round"
        uiType="primary">
        {{ "add_okrs_to_wb" | localize }}
      </button>
    </div>
  </div>
</div>
