import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { UiNotificationPlacement } from "@webapp/ui/notification/notification.models";
import { UiNotificationService } from "@webapp/ui/notification/services/notification.service";
import { ErrorNotificationMessage, ErrorNotificationStyles } from "./error-notification.models";

@Component({
  selector: "error-notification",
  templateUrl: "./error-notification.component.html",
  styleUrls: ["./error-notification.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorNotificationComponent implements OnChanges {
  /**
   * @param errorNotification object with id in case the same message needs to be spanned multiple times and the message to display
   */
  @Input() public errorNotification: ErrorNotificationMessage;
  /**
   * @param placement in which part of the screen the message will be displayed
   */
  @Input() public placement: UiNotificationPlacement;
  @ViewChild("notificationContent", { static: false }) public notificationContent?: TemplateRef<object>;
  @ViewChild("closeIcon", { static: false }) public closeIcon?: TemplateRef<void>;

  constructor(private notification: UiNotificationService) {}

  public errorNotificationStyles: ErrorNotificationStyles = {
    icon: "caution",
    background: "#DA555D",
  };

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.errorNotification) {
      /**
       * setTimeout is needed because creating the view/template is async operation and sometimes
       * notifications are created without content inside it
       */
      setTimeout(() => {
        this.createBasicNotification(this.notificationContent);
      }, 0);
    }
  }

  private createBasicNotification(template: TemplateRef<object>): void {
    this.notification.template(template, {
      notificationStyle: {
        background: this.errorNotificationStyles.background,
        width: "auto",
        ["border-radius"]: "8px",
      },
      notificationPlacement: this.placement,
      notificationCloseIcon: this.closeIcon,
    });
  }
}
