import { Directionality } from "@angular/cdk/bidi";
import { OverlayModule } from "@angular/cdk/overlay";
import { Platform } from "@angular/cdk/platform";
import { AsyncPipe, NgClass, NgIf } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";
import { UiAccessibilityModule } from "@quantive/ui-kit/accessibility";
import { UiI18nService } from "@quantive/ui-kit/i18n";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzConfigService, WithConfig } from "ng-zorro-antd/core/config";
import { NzFormNoStatusService, NzFormStatusService } from "ng-zorro-antd/core/form";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzTimePickerComponent } from "ng-zorro-antd/time-picker";
import { UiDateHelperService } from "@webapp/ui/date-picker/services/date-helper.service";
import { UiTimePickerPanelComponent } from "./components/time-picker-panel/time-picker-panel.component";

@Component({
  selector: "ui-time-picker",
  exportAs: "uiTimePicker",
  templateUrl: "time-picker.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: UiTimePickerComponent, multi: true }],
  standalone: true,
  imports: [FormsModule, NgClass, UiIconModule, NgIf, UiAccessibilityModule, UiTimePickerPanelComponent, AsyncPipe, NzOutletModule, OverlayModule],
})
export class UiTimePickerComponent extends NzTimePickerComponent {
  @Input("uiId")
  public nzId: string | null = null;
  @Input("uiSize")
  public nzSize: string | null = null;
  @Input("uiHourStep")
  @WithConfig()
  public nzHourStep = 1;
  @Input("uiMinuteStep")
  @WithConfig()
  public nzMinuteStep = 1;
  @Input("uiSecondStep")
  @WithConfig()
  public nzSecondStep = 1;
  @Input("uiClearText")
  @WithConfig()
  public nzClearText = "clear";
  @Input("uiNowText")
  @WithConfig()
  public nzNowText = "";
  @Input("uiOkText")
  @WithConfig()
  public nzOkText = "";
  @Input("uiPopupClassName")
  @WithConfig()
  public nzPopupClassName = "";
  @Input("uiPlaceHolder")
  public nzPlaceHolder = "";
  @Input("uiAddOn")
  public nzAddOn?: TemplateRef<void>;
  @Input("uiDefaultOpenValue")
  public nzDefaultOpenValue?: Date;
  @Input("uiDisabledHours")
  public nzDisabledHours?: () => number[];
  @Input("uiDisabledMinutes")
  public nzDisabledMinutes?: (hour: number) => number[];
  @Input("uiDisabledSeconds")
  public nzDisabledSeconds?: (hour: number, minute: number) => number[];
  @Input("uiFormat")
  @WithConfig()
  public nzFormat = "HH:mm:ss";
  @Input("uiOpen")
  public nzOpen = false;
  @Input("uiUse12Hours")
  @WithConfig()
  @InputBoolean()
  public nzUse12Hours = false;
  @Input("uiSuffixIcon")
  @WithConfig()
  public nzSuffixIcon: string | TemplateRef<NzSafeAny> = "clock-circle";
  @Input("uiHideDisabledOptions")
  @InputBoolean()
  public nzHideDisabledOptions = false;
  @Input("uiAllowEmpty")
  @WithConfig()
  @InputBoolean()
  public nzAllowEmpty = true;
  @Input("uiDisabled")
  @InputBoolean()
  public nzDisabled = false;
  @Input("uiAutoFocus")
  @InputBoolean()
  public nzAutoFocus = false;
  @Input("uiBackdrop")
  @WithConfig()
  public nzBackdrop = false;

  @Output("uiOpenChange")
  public readonly nzOpenChange = new EventEmitter<boolean>();

  @ViewChild("inputElement", { static: true })
  public inputRef!: ElementRef<HTMLInputElement>;

  constructor(
    public uiConfigService: NzConfigService,
    i18n: UiI18nService,
    element: ElementRef,
    renderer: Renderer2,
    cdr: ChangeDetectorRef,
    dateHelper: UiDateHelperService,
    platform: Platform,
    @Optional() directionality: Directionality,
    @Optional() nzFormStatusService?: NzFormStatusService,
    @Optional() nzFormNoStatusService?: NzFormNoStatusService
  ) {
    super(uiConfigService, i18n, element, renderer, cdr, dateHelper, platform, directionality, nzFormStatusService, nzFormNoStatusService);
  }
}
