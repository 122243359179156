import { AccountPickerComponent } from "./components/account-picker/account-picker.component";
import { MultiAccountInvitationResponseCtrl } from "./controllers/multi-account-invitation-response";
import { MultiAccountService } from "./services/multi-account-service";

const mod = angular
  .module("multiAccount", [])
  .service("MultiAccountService", MultiAccountService)
  .controller("MultiAccountInvitationResponseCtrl", MultiAccountInvitationResponseCtrl)
  .component("accountPicker", AccountPickerComponent);

export default mod.name;
