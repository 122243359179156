import { IAugmentedJQuery, IDirective, IModule, IScope } from "angular";

/**
 * Those are wrapper directives since we cannot downgrade Angular directives
 */
export class MenuDirective implements IDirective {
  restrict = "A";

  link(scope: IScope, element: IAugmentedJQuery): void {
    element[0].classList.add("ant-dropdown-menu", "ant-dropdown-menu-root", "ant-dropdown-menu-light", "ant-dropdown-menu-vertical");
  }

  static register(mod: IModule): void {
    const directive = () => new MenuDirective();
    mod.directive("ghMenu", directive);
  }
}

export class MenuItemDirective implements IDirective {
  restrict = "A";

  link(scope: IScope, element: IAugmentedJQuery): void {
    element[0].classList.add("ant-dropdown-menu-item");
  }

  static register(mod: IModule): void {
    const directive = () => new MenuItemDirective();
    mod.directive("ghMenuItem", directive);
  }
}
