<ng-template #progressInfoTemplate>
  @if (nzShowInfo) {
    <span class="ant-progress-text">
      @if ((status === "exception" || status === "success") && !nzFormat) {
        <i [uiType]="icon" ui-icon></i>
      } @else {
        <ng-container *nzStringTemplateOutlet="formatter; context: { $implicit: nzPercent }; let formatter">
          {{ formatter(nzPercent) }}
        </ng-container>
      }
    </span>
  }
</ng-template>

<div
  [ngClass]="'ant-progress ant-progress-status-' + status"
  [class.ant-progress-line]="nzType === 'line'"
  [class.ant-progress-small]="nzSize === 'small'"
  [class.ant-progress-default]="nzSize === 'default'"
  [class.ant-progress-show-info]="nzShowInfo"
  [class.ant-progress-circle]="isCircleStyle"
  [class.ant-progress-steps]="isSteps"
  [class.ant-progress-rtl]="dir === 'rtl'">
  @if (nzType === "line") {
    <div class="progress-flex">
      <!-- normal line style -->
      @if (isSteps) {
        <div class="ant-progress-steps-outer">
          @for (step of steps; track step) {
            <div class="ant-progress-steps-item" [ngStyle]="step"></div>
          }
          <ng-template [ngTemplateOutlet]="progressInfoTemplate" />
        </div>
      } @else {
        <div class="ant-progress-outer">
          <div class="ant-progress-inner">
            <div
              class="ant-progress-bg"
              [style.width.%]="nzPercent"
              [style.border-radius]="nzStrokeLinecap === 'round' ? '100px' : '0'"
              [style.background]="!isGradient ? nzStrokeColor : null"
              [style.background-image]="isGradient ? lineGradient : null"
              [style.height.px]="strokeWidth"></div>
            @if (nzSuccessPercent || nzSuccessPercent === 0) {
              <div class="ant-progress-success-bg" [style.width.%]="nzSuccessPercent" [style.border-radius]="nzStrokeLinecap === 'round' ? '100px' : '0'" [style.height.px]="strokeWidth"></div>
            }
          </div>
        </div>
        <ng-template [ngTemplateOutlet]="progressInfoTemplate" />
      }
    </div>
  }
  <!-- line progress -->

  <!-- circle / dashboard progress -->

  @if (isCircleStyle) {
    <div class="ant-progress-inner" [style.width.px]="this.nzWidth" [style.height.px]="this.nzWidth" [style.fontSize.px]="this.nzWidth * 0.15 + 6" [class.ant-progress-circle-gradient]="isGradient">
      <svg class="ant-progress-circle" viewBox="0 0 100 100">
        @if (isGradient) {
          <defs>
            <linearGradient [id]="'gradient-' + gradientId" x1="100%" y1="0%" x2="0%" y2="0%">
              @for (i of circleGradient; track i) {
                <stop [attr.offset]="i.offset" [attr.stop-color]="i.color"></stop>
              }
            </linearGradient>
          </defs>
        }

        <path
          class="ant-progress-circle-trail"
          [attr.stroke-width]="uiStrokeTrailWidth ? uiStrokeTrailWidth : strokeWidth"
          [attr.d]="pathString"
          [ngStyle]="trailPathStyle"
          [style.stroke]="nzStrokeTrailColor"
          fill-opacity="0"></path>
        @for (p of progressCirclePath; track p) {
          <path
            class="ant-progress-circle-path"
            [attr.d]="pathString"
            [attr.stroke-linecap]="nzStrokeLinecap"
            [attr.stroke]="p.stroke"
            [attr.stroke-width]="nzPercent ? strokeWidth : 0"
            [ngStyle]="p.strokePathStyle"
            fill-opacity="0"></path>
        }
      </svg>
      <ng-template [ngTemplateOutlet]="progressInfoTemplate" />
    </div>
  }
</div>
