import { Ng2StateDeclaration } from "@uirouter/angular";
import { LazyNgAndNg1ModuleRef, lazyLoadNgAndNg1Module } from "@webapp/core/routing/routing.utils";

const loadModule = (): Promise<LazyNgAndNg1ModuleRef> => import(/* webpackChunkName: "whiteboards" */ "./whiteboards.lazy");
const lazyLoad = lazyLoadNgAndNg1Module(loadModule);

const whiteboardsFutureState: Ng2StateDeclaration = {
  name: "gtmhub.whiteboards.**",
  url: "/whiteboards/",
  lazyLoad,
};

const whiteboardFutureState: Ng2StateDeclaration = {
  name: "gtmhub.whiteboard.**",
  url: "/whiteboards/:whiteboardId/",
  lazyLoad,
};

export const states: Ng2StateDeclaration[] = [whiteboardsFutureState, whiteboardFutureState];
