import { module } from "angular";
import upgrade from "@gtmhub/core/upgrade/module";
import { DataSourceService } from "@gtmhub/datasources/services/data-source.service";
import { EntityService } from "@gtmhub/entities/services/entity.service";
import env from "@gtmhub/env/env.module";
import { InsightboardPdfService } from "@gtmhub/insightboards/services/insighboard-pdf.service";
import { SectionHeaderService } from "@gtmhub/insightboards/services/section-header.service";
import sharedFilters from "@gtmhub/shared/filters/filters.module";
import { InsightsModalsService } from "@webapp/data-story/services/insights-modals.service";
import { InsightTemplateSuggestionsService } from "@webapp/insightboards/services/insight-template-suggestions.service";
import { ConfigurePowerBIModalService } from "@webapp/integrations/power-bi/components/configure-power-bi/configure-power-bi-modal.service";
import { EditInsightService } from "@webapp/shared/insights/edit-modal/edit-insight.service";
import { ConnectorService } from "../connectors/connector.service";
import { InsightFilterFilter } from "./filters/insight-filter.filter";
import { AutoFieldsService } from "./services/auto-fields.service";

const mod = module("insights-core", [upgrade, env, sharedFilters]);

mod.service("SectionHeaderService", SectionHeaderService);
mod.service("DataSourceService", DataSourceService);
mod.service("EntityService", EntityService);
mod.service("InsightboardPdfService", InsightboardPdfService);
mod.service("ConnectorService", ConnectorService);
mod.downgradeInjectable("InsightTemplateSuggestionsService", InsightTemplateSuggestionsService);
mod.downgradeInjectable("EditInsightService", EditInsightService);
mod.downgradeInjectable("InsightsModalsService", InsightsModalsService);
mod.service("AutoFieldsService", AutoFieldsService);

mod.filter("insightFilter", InsightFilterFilter);
mod.filter(
  "getDataSourceBlueprintLogo",
  () => (logoUrl: string, dataSourceName: string, ext: "png" | "jpg") => logoUrl || (dataSourceName && ext ? `/dist/img/logos/${dataSourceName}.${ext}` : null)
);
mod.filter("highlight", function () {
  return function (text: string, phrase: string) {
    return phrase ? text.replace(new RegExp("(" + phrase + ")", "gi"), "<kbd class='highlighted'>$1</kbd>") : text;
  };
});

mod.downgradeInjectable("ConfigurePowerBIModalService", ConfigurePowerBIModalService);

export default mod.name;
