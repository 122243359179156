import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { ApiEndpointService } from "@webapp/core/app-config/services/api-endpoint.service";
import { TracingService } from "@webapp/core/tracing/services/tracing.service";
import { GtmhubAdditionalParams } from "../models/http.models";

export const GTMHUB_ADDITIONAL_PARAMS = new HttpContextToken<GtmhubAdditionalParams>(() => ({}));

@Injectable()
export class TrackDataInterceptor implements HttpInterceptor {
  constructor(
    private apiEndpointService: ApiEndpointService,
    private tracingService: TracingService
  ) {}

  public intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    return next.handle(this.attachHeaders(request));
  }

  private attachHeaders(request: HttpRequest<object>): HttpRequest<object> {
    if (this.apiEndpointService.isApiRequest(request.url)) {
      const screen = this.tracingService.getScreen();
      const stateName = screen ? screen.screenName : "<unknown>";
      const stateTrackingName = screen ? screen.stateTrackingName : null;
      // segment/analytics-next library is managing 'analytics_session_id' analytics.factory.ts
      // This sessionId is used to connect events which are sent from BE to the same session with those from FE.
      // This is a timestamp (when current session has started)
      const analyticsSessionId = localStorage.getItem("analytics_session_id");

      let map = {
        "gtmhub-application-name": "webapp",
        "gtmhub-path": stateName,
      };

      if (stateTrackingName) {
        map["gtmhub-screen-name"] = stateTrackingName;
      }

      if (analyticsSessionId) {
        map["gtmhub-session-id"] = analyticsSessionId;
      }

      const gtmhubAdditionalParams = request.context.get(GTMHUB_ADDITIONAL_PARAMS);
      map = {
        ...map,
        ...gtmhubAdditionalParams,
      };

      const params = Object.entries(map)
        .map((keyValuePair) => keyValuePair.join("="))
        .join(",");

      return request.clone({
        setHeaders: {
          "gtmhub-additional-params": params,
          "transaction-id": uuidv4(),
        },
      });
    }

    return request;
  }
}
