import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { InputBoolean, InputNumber } from "ng-zorro-antd/core/util";
import { UiPaginationModule } from "@webapp/ui/pagination/pagination.module";

/**
 * This wrapper should only be used in Angular.js. When replacing this with `ui-pagination`
 * note that the `uiTotal` property takes the total number of items, rather than pages.
 */
@Component({
  // eslint-disable-next-line webapp/no-component-selector-prefix
  selector: "gh-pagination",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  template: `<nav
    [uiDisabled]="disabled"
    [uiTotal]="total * pageSize"
    [uiPageIndex]="current"
    [uiPageSize]="pageSize"
    (uiPageIndexChange)="pageChange.next($event)"
    ui-pagination></nav>`,
  standalone: true,
  imports: [UiPaginationModule],
})
export class PaginationComponent {
  @Input() @InputBoolean() public disabled?: boolean;
  @Input() @InputNumber() public total = 0;
  @Input() @InputNumber() public current = 1;
  @Input() @InputNumber() public pageSize = 10;
  @Output() public readonly pageChange: EventEmitter<number> = new EventEmitter();
}
