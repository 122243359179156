import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "basic-automation",
  templateUrl: "./basic-automation.component.html",
  styleUrls: ["./basic-automation.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicAutomationComponent {
  @Input() public heading: string;
  @Input() public description: string;
  @Input() public loading: boolean;

  @Output() public readonly automate = new EventEmitter<void>();
}
