<div class="ant-picker-input">
  <input
    #inputElement
    [(ngModel)]="inputValue"
    [attr.id]="nzId"
    [size]="inputSize"
    [placeholder]="nzPlaceHolder || (i18nPlaceHolder$ | async)"
    [disabled]="nzDisabled"
    (focus)="onFocus(true)"
    (blur)="onFocus(false)"
    (keyup.enter)="onKeyupEnter()"
    (keyup.escape)="onKeyupEsc()"
    (ngModelChange)="onInputChange($event)"
    type="text"
    autocomplete="off" />
  <span class="ant-picker-suffix">
    <ng-container *nzStringTemplateOutlet="nzSuffixIcon; let suffixIcon">
      <i [uiType]="suffixIcon" ui-icon></i>
    </ng-container>
  </span>
  <span class="ant-picker-clear" *ngIf="nzAllowEmpty && !nzDisabled && value" (click)="onClickClearBtn($event)">
    <i [a11yTitle]="nzClearText" ui-icon uiType="close-circle"></i>
  </span>
</div>

<ng-template
  [cdkConnectedOverlayHasBackdrop]="nzBackdrop"
  [cdkConnectedOverlayPositions]="overlayPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="nzOpen"
  [cdkConnectedOverlayTransformOriginOn]="'.ant-picker-dropdown'"
  (detach)="close()"
  (overlayOutsideClick)="onClickOutside($event)"
  cdkConnectedOverlay
  nzConnectedOverlay>
  <div class="ant-picker-dropdown" style="position: relative">
    <div class="ant-picker-panel-container">
      <div class="ant-picker-panel" tabindex="-1">
        <ui-time-picker-panel
          [(ngModel)]="value"
          [ngClass]="nzPopupClassName"
          [uiFormat]="nzFormat"
          [uiHourStep]="nzHourStep"
          [uiMinuteStep]="nzMinuteStep"
          [uiSecondStep]="nzSecondStep"
          [uiDisabledHours]="nzDisabledHours"
          [uiDisabledMinutes]="nzDisabledMinutes"
          [uiDisabledSeconds]="nzDisabledSeconds"
          [uiPlaceHolder]="nzPlaceHolder || (i18nPlaceHolder$ | async)"
          [uiHideDisabledOptions]="nzHideDisabledOptions"
          [uiUse12Hours]="nzUse12Hours"
          [uiDefaultOpenValue]="nzDefaultOpenValue"
          [uiAddOn]="nzAddOn"
          [uiClearText]="nzClearText"
          [uiNowText]="nzNowText"
          [uiOkText]="nzOkText"
          [uiAllowEmpty]="nzAllowEmpty"
          (ngModelChange)="onPanelValueChange($event)"
          (closePanel)="setCurrentValueAndClose()"></ui-time-picker-panel>
      </div>
    </div>
  </div>
</ng-template>
