import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { QunativePlusEffectivenessDTO, QunativePlusEffectivenessPostDTO } from "../../models/quantive-plus-effectiveness.models";
import { QuantivePlusEffectivenessState } from "./quantive-plus-effectiveness-state.service";

@Injectable({
  providedIn: "root",
})
export class QuantivePlusEffectivenessApiService extends BaseApiService<QunativePlusEffectivenessDTO, QunativePlusEffectivenessPostDTO, QuantivePlusEffectivenessState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: QuantivePlusEffectivenessState) {
    super("effectiveness", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getObjectivesEffectiveness(): string {
    return `${this.getQuantivePlusBasePath(HttpActions.post)}/objectives`;
  }

  public getKeyResultsEffectiveness(): string {
    return `${this.getQuantivePlusBasePath(HttpActions.post)}/krs`;
  }
}
