import { StateRegistry, StateService, loadNgModule } from "@uirouter/angular";
import { Injectable, Type } from "@angular/core";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { Observable, from } from "rxjs";
import { LazyNgAndNg1ModuleRef } from "@webapp/core/routing/routing.utils";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";
import { UiModalOptions } from "@webapp/ui/modal/modal.models";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";

type LazyModalOptions<P extends LazyNgAndNg1ModuleRef, T, D = NzSafeAny> = Omit<UiModalOptions<T, D>, "uiContent"> & {
  lazyConfig: {
    loadModule(): Promise<P>;
    component(mod: P): Type<T>;
  };
};

/**
 * Opens a modal with a lazy-loaded component. The component is loaded only when the modal is opened for the 1st time.
 * The lazy-loading happens via UIRouter, so you also need to specify a state name to load. The state must lazy-load
 * the same module that contains the component.
 */
@Injectable({
  providedIn: "root",
})
export class LazyModalService {
  constructor(
    private stateService: StateService,
    private stateRegistry: StateRegistry
  ) {}

  public create<P extends LazyNgAndNg1ModuleRef, T, D = NzSafeAny>(config: LazyModalOptions<P, T, D>): Observable<UiModalRef<T, D>> {
    const { loadModule, component } = config.lazyConfig;

    return from(
      loadModule()
        .then((mod) => {
          const { lazyState } = mod;
          const state = this.stateRegistry.get(lazyState);

          if (!state) {
            throw new Error(`Modal is set to lazy-load state ${lazyState}, but such state doesn't exist`);
          }

          // Angular states often use `loadChildren` instead of `lazyLoad`. Normally the conversion happens
          // when you navigate to the route, but here we need to do it manually
          if (state["loadChildren"]) {
            state.lazyLoad = loadNgModule(state["loadChildren"]);
            delete state["loadChildren"];
          }

          // if the state has already been loaded, the UIRouter will remove its `lazyLoad` property
          const lazyLoadStatePromise = state.lazyLoad ? this.stateService.lazyLoad(lazyState) : Promise.resolve();
          return lazyLoadStatePromise.then(() => mod);
        })
        .then((mod) => {
          const modalConfig = { ...config, lazyConfig: undefined } as UiModalOptions;
          modalConfig.uiContent = component(mod);

          const moduleInjector = mod.getModuleInjector();
          const modalService = moduleInjector.get(UiModalService);
          return modalService.create<T, D>(modalConfig);
        })
    );
  }
}
