import { ChangeDetectionStrategy, Component } from "@angular/core";
import { NzListHeaderComponent } from "ng-zorro-antd/list";

@Component({
  selector: "ui-list-header",
  exportAs: "uiListHeader",
  templateUrl: "./list-header.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class UiListHeaderComponent extends NzListHeaderComponent {}
