import { Directionality } from "@angular/cdk/bidi";
import { NgFor, NgIf, NgTemplateOutlet } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { NzPaginationI18nInterface } from "ng-zorro-antd/i18n";
import { NzPaginationDefaultComponent } from "ng-zorro-antd/pagination";
import { PaginationItemRenderContext } from "../../pagination.models";
import { UiPaginationItemComponent } from "../pagination-item/pagination-item.component";
import { UiPaginationOptionsComponent } from "../pagination-options/pagination-options.component";

@Component({
  selector: "ui-pagination-default",
  preserveWhitespaces: false,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "pagination-default.component.html",
  standalone: true,
  imports: [NgIf, NgFor, UiPaginationItemComponent, UiPaginationOptionsComponent, NgTemplateOutlet],
})
export class UiPaginationDefaultComponent extends NzPaginationDefaultComponent {
  @ViewChild("containerTemplate", { static: true }) public template!: TemplateRef<NzSafeAny>;
  @Input("uiSize") public nzSize: "default" | "small" = "default";
  @Input() public itemRender: TemplateRef<PaginationItemRenderContext> | null = null;
  @Input() public showTotal: TemplateRef<{ $implicit: number; range: [number, number] }> | null = null;
  @Input() public disabled = false;
  @Input() public locale!: NzPaginationI18nInterface;
  @Input() public showSizeChanger = false;
  @Input() public showQuickJumper = false;
  @Input() public total = 0;
  @Input() public pageIndex = 1;
  @Input() public pageSize = 10;
  @Input() public pageSizeOptions: number[] = [10, 20, 30, 40];

  @Output() public readonly pageIndexChange = new EventEmitter<number>();
  @Output() public readonly pageSizeChange = new EventEmitter<number>();

  constructor(cdr: ChangeDetectorRef, renderer: Renderer2, elementRef: ElementRef, @Optional() directionality: Directionality) {
    super(cdr, renderer, elementRef, directionality);
  }
}
