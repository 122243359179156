import { Ng2StateDeclaration } from "@uirouter/angular";
import { LazyNgAndNg1ModuleRef, lazyLoadNgAndNg1Module } from "@webapp/core/routing/routing.utils";

const loadModule = (): Promise<LazyNgAndNg1ModuleRef> => import(/* webpackChunkName: "lists" */ "./lists.lazy");
const lazyLoad = lazyLoadNgAndNg1Module(loadModule);

export const states: Ng2StateDeclaration[] = [
  {
    name: "gtmhub.lists.**",
    url: "/lists/",
    lazyLoad,
  },
  {
    name: "gtmhub.list.**",
    url: "/lists/:listId/",
    lazyLoad,
  },
];
