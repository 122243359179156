import { PlanningSessionStatus, Session } from "@webapp/sessions/models/sessions.model";
import dayjs from "@webapp/shared/libs/dayjs";

export function isActiveSession(s: Session) {
  const now = dayjs.utc();
  return s.status !== PlanningSessionStatus.ARCHIVED && now.isSameOrAfter(s.start) && now.isSameOrBefore(s.end);
}

export function filterActiveSessions(sessions: Session[]): Session[] {
  return sessions.filter(isActiveSession);
}

export function filterActiveSessionsWithoutCheckDate(sessions: Session[]): Session[] {
  return sessions.filter((s) => s.status !== PlanningSessionStatus.ARCHIVED);
}

export function filterActiveAndFutureSessions(sessions: Session[]): Session[] {
  const now = dayjs.utc();
  return sessions.filter((s) => s.status !== PlanningSessionStatus.ARCHIVED && now.isSameOrBefore(s.end));
}

export function filterOpenedActiveAndFutureSessions(sessions: Session[]): Session[] {
  const now = dayjs.utc();
  return sessions.filter((s) => s.status === PlanningSessionStatus.OPEN && now.isSameOrBefore(s.end));
}

export function filterOpenedActiveAndFutureSessionsAndOneLevelChildren(sessions: Session[], sessionId: string): Session[] {
  return sessions.filter((s) => s.status === PlanningSessionStatus.OPEN && dayjs().isSameOrBefore(s.end) && (s.id === sessionId || s.parentId === sessionId));
}

export function getSessionDurationInDays(session: Session): number {
  return dayjs(session.end).diff(session.start, "days");
}
