import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "container-empty-state",
  templateUrl: "./container-empty-state.component.html",
  styleUrls: ["./container-empty-state.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class ContainerEmptyStateComponent {
  @Input() public message: string;
  @Input({ required: true }) public pictureUrl: string;
  @Input() public heading: string;
}
