import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from "@angular/core";
import { NgStyleInterface } from "ng-zorro-antd/core/types";

// Code is copied from ng-zorro's source since NzTreeDropIndicatorComponent is no longer exported in version 13.4.0
@Component({
  selector: "ui-tree-drop-indicator",
  exportAs: "UiTreeDropIndicator",
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  host: {
    "[class.ant-tree-drop-indicator]": "true",
    "[style]": "style",
  },
  standalone: true,
})
export class UiTreeDropIndicatorComponent implements OnChanges {
  @Input() public dropPosition?: number;
  @Input() public level = 1;
  @Input() public direction = "ltr";
  public style: NgStyleInterface = {};

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnChanges(): void {
    this.renderIndicator(this.dropPosition, this.direction);
  }

  private renderIndicator(dropPosition: number, direction = "ltr"): void {
    const offset = 4;
    const startPosition = direction === "ltr" ? "left" : "right";
    const endPosition = direction === "ltr" ? "right" : "left";
    const style: NgStyleInterface = {
      [startPosition]: `${offset}px`,
      [endPosition]: "0px",
    };
    switch (dropPosition) {
      case -1:
        style.top = `${-3}px`;
        break;
      case 1:
        style.bottom = `${-3}px`;
        break;
      case 0:
        style.bottom = `${-3}px`;
        style[startPosition] = `${offset + 24}px`;
        break;
      default:
        style.display = "none";
        break;
    }
    this.style = style;
    this.cdr.markForCheck();
  }
}
