import { ChangeDetectionStrategy, Component } from "@angular/core";
import { NzListItemMetaTitleComponent } from "ng-zorro-antd/list";

@Component({
  selector: "ui-list-item-meta-title",
  exportAs: "uiListItemMetaTitle",
  templateUrl: "./list-item-meta-title.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class UiListItemMetaTitleComponent extends NzListItemMetaTitleComponent {}
