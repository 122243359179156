import { module } from "angular";
import usersCore from "@gtmhub/users/users-core.module";
import { DefaultSessionResolverService } from "@webapp/sessions/services/default-session-resolver.service";
import { SessionsRepository } from "@webapp/sessions/services/sessions-repository.service";
import { PlanningSessionsActions } from "./redux/session-actions";
import { PlanningSessionService } from "./services/planning-session.service";

const mod = module("sessions-core", [usersCore]);

mod.service("PlanningSessionsActions", PlanningSessionsActions);
mod.service("PlanningSessionService", PlanningSessionService);

mod.downgradeInjectable("DefaultSessionResolverService", DefaultSessionResolverService);
mod.downgradeInjectable("SessionsRepository", SessionsRepository);

export default mod.name;
