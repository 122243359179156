import { NgModule } from "@angular/core";
import { UiOptionContainerComponent } from "./components/option-container/option-container.component";
import { UiOptionGroupComponent } from "./components/option-group/option-group.component";
import { UiOptionItemGroupComponent } from "./components/option-item-group/option-item-group.component";
import { UiOptionItemComponent } from "./components/option-item/option-item.component";
import { UiOptionComponent } from "./components/option/option.component";
import { UiSelectArrowComponent } from "./components/select-arrow/select-arrow.component";
import { UiSelectClearComponent } from "./components/select-clear/select-clear.component";
import { UiSelectErrorComponent } from "./components/select-error/select-error.component";
import { UiSelectItemComponent } from "./components/select-item/select-item.component";
import { UiSelectPlaceholderComponent } from "./components/select-placeholder/select-placeholder.component";
import { UiSelectSearchComponent } from "./components/select-search/select-search.component";
import { UiSelectTopControlComponent } from "./components/select-top-control/select-top-control.component";
import { UiSelectComponent } from "./select.component";

@NgModule({
  imports: [
    UiSelectComponent,
    UiSelectTopControlComponent,
    UiSelectSearchComponent,
    UiSelectArrowComponent,
    UiSelectClearComponent,
    UiSelectErrorComponent,
    UiSelectPlaceholderComponent,
    UiSelectItemComponent,
    UiOptionComponent,
    UiOptionGroupComponent,
    UiOptionItemGroupComponent,
    UiOptionContainerComponent,
    UiOptionItemComponent,
  ],
  exports: [
    UiSelectComponent,
    UiSelectSearchComponent,
    UiSelectArrowComponent,
    UiSelectClearComponent,
    UiSelectErrorComponent,
    UiSelectPlaceholderComponent,
    UiSelectItemComponent,
    UiOptionComponent,
    UiOptionGroupComponent,
  ],
})
export class UiSelectModule {}
