import { NothingFoundSize, NothingFoundStyles } from "./nothing-found.models";

export const ghNothingFoundStyles: {
  [size in NothingFoundSize]: NothingFoundStyles;
} = {
  default: {
    fontSize: {
      titleSize: 24,
      infoSize: 15,
    },
    iconSize: {
      height: 192,
      width: 400,
    },
  },
  small: {
    fontSize: {
      titleSize: 20,
      infoSize: 14,
    },
    iconSize: {
      height: 130,
      width: 225,
    },
  },
};
