import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "custom-field-number-input-field",
  templateUrl: "./number-input-field.component.html",
  styleUrls: ["./number-input-field.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldNumberInputFieldComponent {
  @Input() public uiId: string;
  @Input() public errorMessageId?: string;
  @Input() public selected: number;
  @Input() public placeholder = "";
  @Input() public disabled: boolean;
  @Input() public readonly: boolean;
  @Input() public a11yLabel: string;
  @Input() public a11yLabelledby: string;
  @Input() public a11yDescription: string;
  @Input() public a11yRequired: boolean;
  @Input() public max = 9_999_999_999;
  @Input() public e2eTestId: string;
  @Input() public uiBorderless = true;
  @Input() public formatting: {
    prefix?: string;
    suffix?: string;
    precision?: number;
  };

  @Output() public readonly selectedChange = new EventEmitter<number>();
  @Output() public readonly inputBlur = new EventEmitter<void>();
}
