import { Insightboard } from "../models";

export const convertDataToArray = (data: string): string[] => {
  if (!data) {
    return [];
  }
  return data.split(/\n/);
};

export function getTeamsWithSubteamsPermissionsCount(insightboard: Insightboard): number {
  if (!insightboard.access || !insightboard.access.permissions) {
    return 0;
  }

  return insightboard.access.permissions.filter((permission) => permission.principalKind === "team_and_subteams").length;
}
