import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Observable, delay, merge, of } from "rxjs";
import { IIndicator, IUIError, createUIError, isErrorIndicator, isProgressIndicator } from "@gtmhub/error-handling";
import { localize } from "@webapp/localization/utils/localization.utils";
import { UiLoadingIndicatorSize, UiLoadingIndicatorTipPosition, UiLoadingIndicatorType } from "@webapp/ui/loading-indicator/loading-indicator.models";
import { IErrorOptions } from "../error-view/error-view.models";

@Component({
  // This needs to be refactored, but since it is widely used I will leave its selector as-is
  // eslint-disable-next-line webapp/no-component-selector-prefix
  selector: "gh-indicator",
  templateUrl: "./indicator.component.html",
  styleUrls: ["./indicator.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    "[class.align-center]": "align === 'center'",
    "[class.align-right]": "align === 'right'",
    "[class.align-left]": "align === 'left'",
    "[class.inactive]": "!error && !progress",
  },
})
export class IndicatorComponent implements OnChanges {
  @Input("ghValue")
  public value: IIndicator;

  @Input("ghMessage")
  public message: string;

  /**
   *   used to position the indicator in relation to the parent container
   */
  @Input()
  public align?: "left" | "center" | "right" = "left";

  /**
   *  used to position the text in relation to the spinning svg
   */
  @Input()
  public tipPosition?: UiLoadingIndicatorTipPosition = "right";

  @Input()
  public type?: UiLoadingIndicatorType = "circular";

  @Input()
  public size?: UiLoadingIndicatorSize = "default";

  @Input()
  public errorOptions?: IErrorOptions & { inline?: boolean };

  @Output("ghValueChange")
  public readonly valueChange = new EventEmitter<IIndicator>();

  @Output()
  public readonly errorReset = new EventEmitter<void>();

  public progress: boolean;
  public error: IUIError;
  public progressMessage$: Observable<string>;

  public ngOnChanges(changes: SimpleChanges): void {
    const value: IIndicator = changes.value?.currentValue;
    if (!value) {
      this.progress = null;
      this.error = null;
      return;
    }

    if (isErrorIndicator(value)) {
      this.progress = null;
      this.error = createUIError(value.error);
    } else if (isProgressIndicator(value) && value.progress) {
      if (this.message) {
        this.progressMessage$ = merge(of(this.message), of(localize("sluggish_give_secs")).pipe(delay(5000)), of(localize("request_too_long")).pipe(delay(80000)));
      }

      this.error = null;
      this.progress = true;
    }
  }

  public resetError(): void {
    this.valueChange.emit(undefined);
    this.errorReset.emit();
  }
}
