import { IUIError } from "@gtmhub/error-handling";
import { Employee } from "@webapp/employees";
import { CurrentEmployeeAction } from "./models";

export interface ICurrentEmployeeStoreState {
  currentEmployee: ICurrentEmployeeState;
}

export interface ICurrentEmployeeState {
  isFetched: boolean;
  isFetching: boolean;
  info: Employee;
  error: IUIError;
}

export function currentEmployeeReducer(
  state: ICurrentEmployeeState = { isFetched: false, isFetching: false, info: {}, error: null },
  action: CurrentEmployeeAction
): ICurrentEmployeeState {
  switch (action.type) {
    case "REQUEST_CURRENT_EMPLOYEE":
      return { ...state, isFetching: true, isFetched: false, error: null };
    case "RECEIVE_CURRENT_EMPLOYEE": {
      return { ...state, isFetched: true, isFetching: false, info: action.info };
    }
    case "UPDATE_CURRENT_EMPLOYEE": {
      return state.isFetched ? { isFetched: true, isFetching: false, error: null, info: { ...state.info, ...action.employee } } : state;
    }
    case "ERROR_RECEIVE_CURRENT_EMPLOYEE":
      return { ...state, error: action.error, isFetching: false, isFetched: false };
    default: {
      return state;
    }
  }
}
