<ng-container *ngIf="isRange; else singlePanel">
  <div [class]="prefixCls + '-range-wrapper ' + prefixCls + '-date-range-wrapper'">
    <div [class]="prefixCls + '-panel-container'">
      <div [class]="prefixCls + '-panels'">
        <ng-container *ngIf="hasTimePicker; else noTimePicker">
          <ng-container *ngTemplateOutlet="tplInnerPopup; context: { partType: datePickerService.activeInput }"></ng-container>
        </ng-container>
        <ng-template #noTimePicker>
          <ng-container *ngTemplateOutlet="tplInnerPopup; context: { partType: 'left' }"></ng-container>
          <ng-container *ngTemplateOutlet="tplInnerPopup; context: { partType: 'right' }"></ng-container>
        </ng-template>
      </div>
      <ng-container *ngTemplateOutlet="tplFooter"></ng-container>
    </div>
  </div>
</ng-container>
<ng-template #singlePanel>
  <div [class]="prefixCls + '-panel-container ' + (showWeek ? prefixCls + '-week-number' : '') + ' ' + (hasTimePicker ? prefixCls + '-time' : '') + ' ' + (isRange ? prefixCls + '-range' : '')">
    <div [class]="prefixCls + '-panel'" [class.ant-picker-panel-rtl]="dir === 'rtl'" tabindex="-1">
      <!-- Single ONLY -->
      <ng-container *ngTemplateOutlet="tplInnerPopup"></ng-container>
      <ng-container *ngTemplateOutlet="tplFooter"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #tplInnerPopup let-partType="partType">
  <div [class]="prefixCls + '-panel'" [class.ant-picker-panel-rtl]="dir === 'rtl'">
    <!-- TODO(@wenqi73) [selectedValue] [hoverValue] types-->
    <ui-inner-popup
      [uiShowWeek]="showWeek"
      [uiEndPanelMode]="getPanelMode(endPanelMode, partType)"
      [uiPartType]="partType"
      [uiLocale]="locale!"
      [uiShowTimePicker]="hasTimePicker"
      [uiTimeOptions]="getTimeOptions(partType)"
      [uiPanelMode]="getPanelMode(panelMode, partType)"
      [uiActiveDate]="getActiveDate(partType)"
      [uiValue]="getValue(partType)"
      [uiDisabledDate]="disabledDate"
      [uiDateRender]="dateRender"
      [uiSelectedValue]="$any(datePickerService?.value)"
      [uiHoverValue]="$any(hoverValue)"
      (uiPanelModeChange)="onPanelModeChange($event, partType)"
      (uiCellHover)="onCellHover($event)"
      (uiSelectDate)="changeValueFromSelect($event, !showTime)"
      (uiSelectTime)="onSelectTime($event, partType)"
      (uiHeaderChange)="onActiveDateChange($event, partType)"></ui-inner-popup>
  </div>
</ng-template>

<ng-template #tplFooter>
  <ui-calendar-footer
    *ngIf="hasFooter"
    [uiLocale]="locale!"
    [uiIsRange]="isRange"
    [uiShowToday]="showToday"
    [uiShowNow]="showNow"
    [uiHasTimePicker]="hasTimePicker"
    [uiOkDisabled]="!isAllowed($any(datePickerService?.value))"
    [uiExtraFooter]="extraFooter"
    [uiRangeQuickSelector]="ranges ? tplRangeQuickSelector : null"
    (uiClickOk)="onClickOk()"
    (uiClickToday)="onClickToday($event)"></ui-calendar-footer>
</ng-template>

<!-- Range ONLY: Range Quick Selector -->
<ng-template #tplRangeQuickSelector>
  <li
    *ngFor="let name of getObjectKeys(ranges)"
    [class]="prefixCls + '-preset'"
    (click)="onClickPresetRange(ranges![name])"
    (mouseenter)="onHoverPresetRange(ranges![name])"
    (mouseleave)="onPresetRangeMouseLeave()">
    <span class="ant-tag ant-tag-blue">{{ name }}</span>
  </li>
</ng-template>
