import { ITeam } from "@gtmhub/teams";
import { unique } from "./utils";

export function getAllTeamMembersIncludingManager(team: ITeam): string[] {
  const allTeamMembers = [];

  if (team.members) {
    allTeamMembers.push(...team.members);
  }

  if (team.manager) {
    allTeamMembers.push(team.manager);
  }

  return unique(allTeamMembers);
}
