import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { localize } from "@gtmhub/localization";
import { IOnboardingMetadata, IOnboardingMetadataEntry } from "@gtmhub/users";
import { PiContextualSuggestionsSection } from "@webapp/shared/components/onboarding/onboarding.component";
import { IOnboardingDelegate, IOnboardingJourneyState, PI_CONTEXTUAL_SUGGESTIONS_SETTINGS_KEY } from "@webapp/shared/components/onboarding/onboarding.models";
import dayjs from "@webapp/shared/libs/dayjs";
import { CurrentUserRepository } from "@webapp/users";
import { PIContextualSuggestionButtonParentType } from "../models/pi-contextual-suggestion-button.models";
import {
  PI_CONTEXTUAL_SUGGESTIONS_ONBOARDING_INITIAL_STATE,
  PI_CONTEXTUAL_SUGGESTIONS_ONBOARDING_MAX_VIEWS,
  PiContextualSuggestionsOnboardingStepsEnum as StepsEnum,
} from "../utils/pi-contextual-suggestions-onboarding.utils";

export type PiContextualSuggestionsOnboardingState = Pick<IOnboardingJourneyState, "currentStep" | "stage" | "isCompleted"> & {
  parentContext: PIContextualSuggestionButtonParentType;
};

export type PiContextualSuggestionsOnboardingType = PiContextualSuggestionsSection;

type CurrentElShowingOnboarding = HTMLButtonElement | HTMLDivElement;

type PrimaryCTA = {
  primaryCTA: string;
  primaryCTAHandler(): void;
};

type SecondaryCTA = {
  secondaryCTA: string;
  secondaryCTAHandler(): void;
};

@Injectable({
  providedIn: "root",
})
export class PiContextualSuggestionsOnboardingService implements Partial<IOnboardingDelegate> {
  private onboardingMetadata: IOnboardingMetadataEntry;
  private parentContext: PIContextualSuggestionButtonParentType;
  private onboardingState$ = new BehaviorSubject<PiContextualSuggestionsOnboardingState>(PI_CONTEXTUAL_SUGGESTIONS_ONBOARDING_INITIAL_STATE);
  private currentElShowingOnboarding$ = new Subject<CurrentElShowingOnboarding>();

  private primaryCTAsByStep: Record<keyof typeof StepsEnum, PrimaryCTA> = {
    contextualSuggestionButton: {
      primaryCTA: localize("skip"),
      primaryCTAHandler: () => {
        this.updateState({ currentStep: StepsEnum.contextualSuggestionButton, stage: "ENDED_BY_USER", isCompleted: false, parentContext: this.parentContext });
      },
    },
    suggestionStateIndicator: {
      primaryCTA: localize("back"),
      primaryCTAHandler: () => {
        this.updateState({ currentStep: StepsEnum.contextualSuggestionButton, stage: "ONGOING", isCompleted: false, parentContext: this.parentContext });
      },
    },
  };

  private secondaryCTAsByStep: Record<keyof typeof StepsEnum, SecondaryCTA> = {
    contextualSuggestionButton: {
      secondaryCTA: localize("next"),
      secondaryCTAHandler: () => {
        this.updateState({ currentStep: StepsEnum.suggestionStateIndicator, stage: "ONGOING", isCompleted: false, parentContext: this.parentContext });
      },
    },
    suggestionStateIndicator: {
      secondaryCTA: localize("got_it"),
      secondaryCTAHandler: () => {
        const currentState = this.onboardingState$.value;
        this.updateState({ ...currentState, isCompleted: true });
        this.completeJourney();
      },
    },
  };

  constructor(private currentUserRepository: CurrentUserRepository) {
    const onboardingMetadata: { [key: string]: IOnboardingMetadataEntry } = {
      [PI_CONTEXTUAL_SUGGESTIONS_SETTINGS_KEY]: { timesShown: 0, isCompleted: false, lastWatchedDate: null },
      ...this.currentUserRepository.getUserSetting<IOnboardingMetadata>("onboardingMetadata"),
    };

    this.onboardingMetadata = onboardingMetadata[PI_CONTEXTUAL_SUGGESTIONS_SETTINGS_KEY];
  }

  public getJourneyState$ = (): Observable<PiContextualSuggestionsOnboardingState> => {
    return this.onboardingState$.asObservable();
  };

  public setOnboardingParentContextAndInitialize = (parentContext: PIContextualSuggestionButtonParentType): void => {
    this.parentContext = parentContext;
    this.updateState({ currentStep: StepsEnum.contextualSuggestionButton, stage: "INITIALIZING", isCompleted: false, parentContext });
  };

  public getCurrentElShowingOnboarding$ = (): Observable<CurrentElShowingOnboarding> => {
    return this.currentElShowingOnboarding$.asObservable();
  };

  public setCurrentElShowingOnboarding = (el: CurrentElShowingOnboarding): void => {
    this.currentElShowingOnboarding$.next(el);
  };

  public getPrimaryActionForStep = (step: number): PrimaryCTA => {
    return this.primaryCTAsByStep[StepsEnum[step]];
  };

  public getSecondaryActionForStep = (step: number): SecondaryCTA => {
    return this.secondaryCTAsByStep[StepsEnum[step]];
  };

  public shouldSeeJourneyAccordingToMetadata = (): boolean => {
    const isCompleted = this.onboardingMetadata.isCompleted;
    const exceededMaxViews = this.onboardingMetadata.timesShown >= PI_CONTEXTUAL_SUGGESTIONS_ONBOARDING_MAX_VIEWS;
    const lastWatchedWithin24hours = dayjs().diff(this.onboardingMetadata.lastWatchedDate, "hours") <= 24;

    if (isCompleted || exceededMaxViews || lastWatchedWithin24hours) {
      return false;
    }

    return true;
  };

  public markAsShown = (): void => {
    this.onboardingMetadata.timesShown++;
    this.onboardingMetadata.lastWatchedDate = dayjs().utc().toISOString();
    this.onboardingMetadata.isCompleted = this.onboardingMetadata.timesShown >= PI_CONTEXTUAL_SUGGESTIONS_ONBOARDING_MAX_VIEWS;
    this.syncOnboardingMetadata();
  };

  public closeJourney = (state: PiContextualSuggestionsOnboardingState): void => {
    const isCompleted = state.currentStep === StepsEnum.suggestionStateIndicator;
    this.updateState({ ...state, stage: "ENDED_BY_USER", isCompleted });

    if (isCompleted) {
      this.completeJourney();
    }
  };

  public completeJourney = (): void => {
    this.onboardingMetadata = {
      ...this.onboardingMetadata,
      isCompleted: true,
    };

    this.syncOnboardingMetadata();
  };

  public resetOnboardingState = (): void => {
    this.updateState(PI_CONTEXTUAL_SUGGESTIONS_ONBOARDING_INITIAL_STATE);
  };

  private syncOnboardingMetadata = (): void => {
    this.currentUserRepository.setUserSetting({
      onboardingMetadata: {
        ...this.currentUserRepository.getUserSetting<IOnboardingMetadata>("onboardingMetadata"),
        [PI_CONTEXTUAL_SUGGESTIONS_SETTINGS_KEY]: this.onboardingMetadata,
      },
    });
  };

  private updateState = (state: PiContextualSuggestionsOnboardingState): void => {
    this.onboardingState$.next(state);
  };
}
