<default-sub-navigation
  [options]="options"
  [itemsType]="'whiteboards'"
  [favorites]="favorites$ | async"
  [recent]="recents$ | async"
  [suggested]="suggested$ | async"
  [loading]="loading$ | async"
  (markItemAsFavorite)="markItemAsFavorite($event)"
  (unmarkItemAsFavorite)="unmarkItemAsFavorite($event)"
  (removeFromSuggested)="removeFromSuggested($event)"></default-sub-navigation>
