type OS = "windows" | "macOS" | "unix" | "linux";

// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/appVersion
export const getBrowserOS = (): OS => {
  if (navigator.appVersion.indexOf("Win") != -1) {
    return "windows";
  }

  if (navigator.appVersion.indexOf("Mac") != -1) {
    return "macOS";
  }

  if (navigator.appVersion.indexOf("X11") != -1) {
    return "unix";
  }

  if (navigator.appVersion.indexOf("Linux") != -1) {
    return "linux";
  }
};

/**
 *
 * Supports only primitive types, Date, Array, and Object
 * @param {T} obj
 */
export function simpleClone<T>(obj: T): T {
  // Handle the 3 simple types, and null or undefined
  if (obj === null || typeof obj !== "object") return obj as T;

  // Handle Date
  if (obj instanceof Date) {
    const copy = new Date();
    copy.setTime((obj as Date).getTime());
    return copy as T;
  }

  // Handle Array
  if (Array.isArray(obj)) {
    const copy: unknown[] = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = simpleClone(obj[i]);
    }
    return copy as T;
  }

  // Handle Object
  if (typeof obj === "object") {
    const copy: Record<string, unknown> = {};
    for (const attr in obj) {
      copy[attr] = simpleClone(obj[attr]);
    }
    return copy as T;
  }
}
