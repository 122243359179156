import { ITreeSelectorItem } from "@gtmhub/shared/components/tree-selector/models";
import { Session } from "@webapp/sessions/models/sessions.model";
import dayjs from "@webapp/shared/libs/dayjs";

export const isAnyChild = (parent: ITreeSelectorItem, predicate: (child: ITreeSelectorItem) => boolean): boolean => {
  if (!parent.children || !parent.children.length) {
    return false;
  } else if (parent.children.find(predicate)) {
    return true;
  } else {
    for (let i = 0; i < parent.children.length; i++) {
      if (isAnyChild(parent.children[i], predicate)) {
        return true;
      }
    }

    return false;
  }
};

export const findNodeInTreeById = (id: string, parentNodes: ITreeSelectorItem[]): ITreeSelectorItem => {
  for (let i = 0; i < parentNodes.length; i++) {
    const node = parentNodes[i];

    if (node.id === id) {
      return node;
    } else if (node.children && node.children.length) {
      const foundNodeInChildren = findNodeInTreeById(id, node.children);
      if (foundNodeInChildren) {
        return foundNodeInChildren;
      }
    }
  }

  return null;
};

export const byEndDescAndStartAsc = (a: Session, b: Session): -1 | 0 | 1 => {
  // "-end,start"
  const startA = dayjs(a.start);
  const startB = dayjs(b.start);
  const endA = dayjs(a.end);
  const endB = dayjs(b.end);

  if (endA.isSame(endB)) {
    return startA.isBefore(startB) ? -1 : startA.isAfter(startB) ? 1 : 0;
  } else {
    return endB.isBefore(endA) ? -1 : 1;
  }
};
