import { IComponentOptions, IController, IScope } from "angular";
import { localize } from "@gtmhub/localization";
import { IExtendedRootScopeService } from "@gtmhub/shared/ng-extensions";
import { handleOutsideMouseEvent } from "@gtmhub/shared/utils/close-on-mousedown";
import { IDropdownActionsMenuOptions } from "../models";

class DropdownActionsMenuCtrl implements IController {
  testIdSuffix?: string;
  options: IDropdownActionsMenuOptions;
  isOpen: boolean;
  private dropDownIsOpen = false;
  removeClickSubscription: () => void;
  a11yLabel = localize("dropdown_actions_menu");
  onToggle: (args: unknown) => unknown;
  onKeyPress(event: KeyboardEvent): void {
    if (event.code === "Enter") {
      this.isOpen = true;
    }
  }

  static $inject = ["$scope"];
  constructor(private $scope: IScope & IExtendedRootScopeService) {}

  public toggleDropDown(event: Event): void {
    event.stopPropagation();
    this.dropDownIsOpen = !this.dropDownIsOpen;
    if (this.dropDownIsOpen) {
      this.addClickSubscription();
    } else {
      this.removeClickSubscription();
      this.isOpen = false;
    }
  }

  private isClickedInsideDropDown(event): boolean {
    return !!event.target.closest("div[uib-dropdown-menu]");
  }

  private addClickSubscription(): void {
    this.removeClickSubscription = handleOutsideMouseEvent("mousedown", (event) => {
      if (this.isClickedInsideDropDown(event)) {
        return;
      }
      this.$scope.$evalAsync(() => {
        this.dropDownIsOpen = false;
        this.isOpen = false;
      });
      this.removeClickSubscription();
    });
  }

  $onInit(): void {
    if (!this.options) {
      this.options = { iconName: "menu", size: "large" };
    }

    if (!this.options.iconName) {
      this.options.iconName = "menu";
    }

    if (!this.options.size) {
      this.options.size = "large";
    }
  }
}

export const DropdownActionsMenuComponent: IComponentOptions = {
  controller: DropdownActionsMenuCtrl,
  template: require("./dropdown-actions-menu.html"),
  transclude: true,
  bindings: {
    testIdSuffix: "@",
    options: "<",
    onToggle: "&",
    a11yLabel: "@",
  },
};
