import { ENTER, SPACE } from "@angular/cdk/keycodes";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiCardModule } from "@quantive/ui-kit/card";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { ApmService } from "@gtmhub/core/tracing/apm.service";
import { localize } from "@gtmhub/localization";
import { HomeWidgetService } from "@webapp/home/services/home-widget.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { BaseWidgetComponent } from "@webapp/ui/dashboard/components/base-widget.component";
import { UiDashboardWidget } from "@webapp/ui/dashboard/dashboard.models";
import { UiLinkModule } from "@webapp/ui/link/link.module";
import { UiToastService } from "@webapp/ui/toast/services/toast.service";
import { WidgetActionsComponent } from "../widget-actions/widget-actions.component";

@UntilDestroy()
@Component({
  selector: "kickstart-okr-widget",
  templateUrl: "./kickstart-okr-widget.component.html",
  styleUrls: ["./kickstart-okr-widget.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiButtonModule, UiCardModule, UiIconModule, CommonModule, UiLinkModule, LocalizationModule, WidgetActionsComponent],
})
export class KickstartOkrWidgetComponent extends BaseWidgetComponent implements OnInit {
  public boxes = [
    { icon: "help", type: localize("support_center"), title: localize("get_advice_and_answers"), link: "https://help.quantive.com/en/" },
    { icon: "award", type: localize("okr_courses"), title: localize("expand_okr_skills"), link: "https://quantive.com/resources/certifications" },
    { icon: "users-group", type: localize("community"), title: localize("join_community"), link: "https://community.quantive.com" },
    { icon: "calendar", type: localize("events"), title: localize("register_for_events"), link: "https://quantive.com/resources/events" },
  ];
  private widget: UiDashboardWidget;

  public get title(): string {
    return localize("kickstart_okr_success");
  }

  public get a11yLabel(): string {
    return "Kickstart OKR success";
  }

  constructor(
    private homeWidgetService: HomeWidgetService,
    private toastService: UiToastService,
    private apmService: ApmService
  ) {
    super();
    this.apmService.startDataLoadSpan("kickstart-okr-widget-init");
  }

  public ngOnInit(): void {
    this.homeWidgetService
      .getDashboardWidgets$()
      .pipe(untilDestroyed(this))
      .subscribe((widgets) => {
        this.widget = widgets.find((w) => w.id === this.widgetId);
        this.apmService.endDataLoadSpan("kickstart-okr-widget-init");
      });
  }

  public remove(): void {
    if (!this.widget) {
      throw new Error(`Cannot find ${this.widgetId} widget`);
    }

    const widget = this.widget;
    this.homeWidgetService.removeWidget(this.widgetId);

    const message = localize("you_can_find_resources_in_support_center");
    this.toastService.info(message, {
      uiShouldTimeout: true,
      uiDuration: 5000,
      uiPrimaryCTA: localize("undo"),
      uiOnPrimaryAction: () => this.homeWidgetService.addWidget(widget),
    });
  }

  public handleBoxMouseDown(event: MouseEvent, link: string): void {
    const target = event.target as HTMLElement;
    if (target.tagName !== "A") {
      this.openBoxLink(link);
    }
  }

  public handleBoxKeyDown(event: KeyboardEvent, link: string): void {
    if (event.keyCode === ENTER || event.keyCode === SPACE) {
      this.openBoxLink(link);
    }
  }

  private openBoxLink(link: string): void {
    window.open(link, "_blank");
  }
}
