import { Inject, Injectable, InjectionToken, StaticProvider } from "@angular/core";
import { MessageService } from "@progress/kendo-angular-l10n";
import { localize } from "@webapp/localization/utils/localization.utils";
import { MESSAGE_KEY_OVERRIDES } from "./message-keys-overrides";

const KENDO_MESSAGES = new InjectionToken<Record<string, string>>("KENDO_MESSAGES");

/**
 * A provider enabling the localization of Kendo component copy (messages).
 * Enables overriding of Kendo message keys with webapp localization keys when needed.
 * The overridden keys will be processed by the current webapp localization mechanism.
 *
 * @param {Record<string, string>} kendoMessages - Generated automatically when resolving locales on app bootstrap.
 * Based on kendo default messages extracted by the `extract-kendo-messages` script.
 *
 * @return {*}  {StaticProvider}
 *
 * @example
 * ```ts
 * // component overrides
 * const TEXTBOX_MESSAGE_KEYS_OVERRIDES: Record<string, string> = { "kendo.textbox.clear": "clear_all" };
 *
 * // all overrides
 * import { GRID_MESSAGE_KEYS_OVERRIDES } from "../grid/utils/message-keys-overrides";
 * import { TEXTBOX_MESSAGE_KEYS_OVERRIDES } from "../textbox/utils/message-keys-overrides";
 *
 * export const MESSAGE_KEY_OVERRIDES: Record<string, string> = {
 *   ...GRID_MESSAGE_KEYS_OVERRIDES,
 *   ...TEXTBOX_MESSAGE_KEYS_OVERRIDES,
 * };
 * ```
 */
export function kendoMessageServiceProvider(kendoMessages: Record<string, string>): StaticProvider {
  return {
    provide: MessageService,
    useFactory: (): KendoMessageService => new KendoMessageService({ ...kendoMessages, ...MESSAGE_KEY_OVERRIDES }),
  };
}

/**
 * A basic implementation of a service that provides localized messages for Kendo components.
 * This service (and related) code could be changed to support more complex localization scenarios(e.g. changing current language or direction, etc.).
 */
@Injectable()
class KendoMessageService extends MessageService {
  constructor(@Inject(KENDO_MESSAGES) private messages: Record<string, string>) {
    super();
  }

  public get(key: string): string {
    const webappLocalizationKey = MESSAGE_KEY_OVERRIDES[key];

    return webappLocalizationKey ? localize(webappLocalizationKey) : this.messages[key];
  }
}
