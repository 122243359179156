export enum NavigationEvents {
  SUB_NAVIGATION_ITEM_CLICKED = "SUB_NAVIGATION_ITEM_CLICKED",
  SUB_NAV_SWITCH_TO_FEED = "SUB_NAV_SWITCH_TO_FEED",
  SUB_NAV_SWITCH_TO_DASHBOARD = "SUB_NAV_SWITCH_TO_DASHBOARD",
  SUB_NAVIGATION_ITEM_REMOVED_FROM_FAVORITES = "SUB_NAVIGATION_ITEM_REMOVED_FROM_FAVORITES",
  SUB_NAVIGATION_ITEM_ADDED_TO_FAVORITES = "SUB_NAVIGATION_ITEM_ADDED_TO_FAVORITES",
  SUB_NAVIGATION_ITEM_SHAREABLE_LINK_COPIED_TO_CLIPBOARD = "SUB_NAVIGATION_ITEM_SHAREABLE_LINK_COPIED_TO_CLIPBOARD",
}

export enum NavigationLifeCycleEvents {
  NAV_LOADED = "NAV_LOADED",
  SUB_NAV_LOADED = "SUB_NAV_LOADED",
}
