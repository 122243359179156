import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "suggestion-drawer-content-card",
  templateUrl: "./suggestion-drawer-content-card.component.html",
  styleUrls: ["./suggestion-drawer-content-card.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionDrawerContentCardComponent {
  @Input() public classes: string;
  @HostBinding("class") get hostClasses(): string {
    return this.classes ? this.classes : "sp-p-16";
  }
}
