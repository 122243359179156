import { NzTreeNodeOptions } from "ng-zorro-antd/tree";
import { ILoggable } from "@gtmhub/core/logging";
import { IField } from "@gtmhub/entities/models/models";
import { IInsightSelectorData } from "@gtmhub/insights";
import { IUnitFormatting } from "@gtmhub/shared/models";
import { IInsightCalculationData } from "@gtmhub/sockets";
import { UserAssignee } from "@webapp/assignees/models/assignee.models";
import { Entity } from "@webapp/data-story/services/entities-facade/entities-facade.models";
import { IInsightSource, IInsightState, IInsightboardItemSetting } from "@webapp/insightboards/models";
import { IDaxQueryResult } from "@webapp/integrations/models/powerbi-models";
import { IAccess, UserAction } from "@webapp/sessions/models/sessions.model";
import { SuggestedMarketplaceInsightTemplate } from "./marketplace-facade.models";

export interface InsightSqlConditionOperators {
  fieldType: string;
  operators: string[];
}

export enum SupportedDataType {
  Integer = "integer",
  Long = "long",
  Double = "double",
  Date = "date",
  String = "string",
  Timestamp = "timestamp",
  Bool = "bool",
  DateTime = "dateTime",
  UnixTime = "unixTime",
  ArrayStrings = "[]string",
}

export enum SupportedOperators {
  Equals = "equals",
  NotEqual = "notEqual",
  Contains = "contains",
  DoesNotContain = "doesNotContain",
  IsContainedBy = "isContainedBy",
  IsNotContainedBy = "isNotContainedBy",
  Overlaps = "overlaps",
  DoesNotOverlap = "doesNotOverlap",
  HasLengthOf = "hasLengthOf",
  Greater = "greater",
  Less = "less",
  Longer = "longer",
  Shorter = "shorter",
}

export interface SqlCondition {
  value: string;
  dataType: SupportedDataType;
  strOperator: SupportedOperators;
  title: string;
  field: string;
  sqlOperators: string[];
  isSingleValue?: boolean;
}

export interface DataSourceEntity {
  dataSource: string;
  dataSourceImg: string;
  dataSourceLogoUrl?: string;
  dataSourceTitle: string;
  aggregatedTitle: string;
  entity: string;
  entityTitle: string;
  connectionData?: ConnectionData;
  fields?: IField[];
}

export interface DataSourceEntitiesMap {
  [dataSource: string]: DataSourceMapping;
}

export interface DataSourceMapping {
  dataSource: string;
  dataSourceTitle: string;
  dataSourceLogoUrl?: string;
  showEntities: boolean;
  entities: DataSourceEntity[];
  connections: ConnectionData[];
}

export interface InsightQueryCondition {
  title: string;
  dataType: SupportedDataType;
  value: unknown;
  bluredValue: unknown;
  templateFunc?(condition): string;
  strOperator: SupportedOperators;
  field: string;
  nextConditionBooleanLogic?: ConditionalOperator;
  nextCondition?: InsightQueryCondition;
  sqlOperators?: string[];
  isSingleValue?: boolean;
}

export interface AggregationField {
  name: string;
  title: string;
  type: string;
}

export enum SqlFunction {
  Count = "count",
  Sum = "sum",
  Avg = "avg",
  Min = "min",
  Max = "max",
}

export interface InsightQueryLayout {
  sqlFunction: SqlFunction;
  dataSource: string;
  dataSourceImg: string;
  dataSourceLogoUrl?: string;
  entity: string;
  entityTitle: string;
  aggregatedField?: AggregationField;
  conditions?: InsightQueryCondition;
}

export interface SuggestedInsight extends Pick<Insight, "id" | "title" | "name"> {
  semanticSimilarityScore?: number;
  kpiUsageCount: number;
  krUsageCount: number;
  // TODO: auto-insights - pending response from api about proper types returned and reuse one of the insight's interfaces
  requiredEntities: { connector: string }[];
}

export interface SuggestedInsights {
  marketplaceTemplates?: SuggestedMarketplaceInsightTemplate[];
  accountInsights?: SuggestedInsight[];
  predictionId?: string;
}

export interface ConnectionData {
  id?: string;
  name: string;
  title: string;
  logoUrl?: string;
}

export type InsightEditor = "automatic" | "html";

export interface InsightFormatting {
  color?: string;
  editor?: InsightEditor;
  decimalSize?: number | string;
  metadata?: InsightMetadata;
  thousandsSeparator?: boolean;
  unit?: string;
  isCustomUnit?: boolean;
  suffix?: boolean;
  uiInvalid?: boolean;
  editSettings?: boolean;
  isCodeless?: boolean;
}

export interface InsightMetadata {
  [key: string]: number | string;
}

export interface CreateKrKpiFilter {
  name: string;
  value: string;
  valueDisplayName?: string;
}

export interface Snippet {
  name: string;
  code: string;
  language: string;
}

export interface InsightMetricUsageMap {
  [insightId: string]: InsightMetricUsage[];
}

export interface InsightMetricUsage {
  actual: number;
  format: IUnitFormatting;
  goal: InsightMetricUsageGoal;
  goalId: string;
  id: string;
  initialValue: number;
  manualType: string;
  name: string;
  ownerIds: string[];
  sessionId: string;
  target: number;
  targetOperator: string;
}

interface InsightMetricUsageGoal {
  name: string;
  ownerIds: string[];
}

export interface InsightChangeLog {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _id: string;
  createdBy: string;
  created: string;
  version: string;
  changeLogItems: InsightChangeLogItem[];
  metadata: InsightChangeLogMetadata;
}

interface InsightChangeLogItem {
  diffs: {
    oldValue: string;
    newValue: string;
  };
  field: string;
}

interface InsightChangeLogMetadata {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  insight_filters: Record<string, unknown>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  insight_formatting: Record<string, unknown>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  insight_view?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  insight_algorithm?: string;
}

export interface SubmitInsightEmailContent {
  mailto: string;
  subject: string;
  body: string;
}

export interface DirectSubmitInsight {
  title: string;
  description: string;
  view: string;
  language: string;
  algorithm: string;
  isScalar: boolean;
  hidden?: boolean;
}

export interface MarketplaceInsightTemplateInstallRequest {
  dashboardId?: string;
  insightTemplateId: string;
  dashboardName?: string;
}

export interface MarketplaceInsightTemplateInstallResponse {
  createdDashboardIds: string[];
  installedInsightsIds: string[];
}

export class InsightExecutionError extends Error implements ILoggable {
  constructor(
    public insightId: string,
    public originalError: Error
  ) {
    super(`Insight threw an error: ${insightId} -> ${originalError.message}`);

    this.name = "InsightExecutionError";
  }

  public loggableData(): Record<string, unknown> {
    return {
      insight: true,
    };
  }
}

export interface IInsightUsage {
  insightboards: InsightboardInsightUsage[];
  kpis: KpiInsightUsage[];
  metrics: MetricInsightUsage[];
  totalCount?: number;
  itemsWithoutPermissions?: number;
}

export type InsightFeedbackType = "kr" | "kpi";

export type InsightCalculationData = {
  [key: string]: unknown;
  default: { [key: string]: unknown }[];
};
export interface IInsightTotalUsageCount {
  usageCount: number;
}

export type InsightActions = {
  clone: boolean;
  edit: boolean;
  delete: boolean;
};

export enum InsightSelectorActionType {
  BROWSE_CONNECTIONS = "browseConnections",
  BROWSE_INSIGHTS = "browseInsights",
  CONFIGURE_POWER_BI = "configurePowerBI",
  CHANGE_STATE = "changeState",
  BUILD_INSIGHT = "buildInsight",
  EXECUTE_INSIGHT = "executeInsight",
  UPDATE_INSIGHT_SELECTOR_DATA = "updateInsightSelectorData",
  OPEN_POWERBI_INSIGHT = "openPowerBIInsight",
}

export interface BrowseInsightsContext {
  connectionData: ConnectionData;
}

export interface ExecuteInsightContext {
  insight: Insight;
  data?: IInsightSelectorData;
}

export interface BuildInsightContext {
  insight?: Insight;
  connectionData: ConnectionData;
  canGoBack: boolean;
  daxQueryResult?: IDaxQueryResult;
}

export interface BrowseConnectionsActionsParams {
  action: InsightSelectorActionType.BROWSE_CONNECTIONS;
}

export interface BrowseInsightsActionParams {
  action: InsightSelectorActionType.BROWSE_INSIGHTS;
  connectionData: ConnectionData;
}

export interface BuildInsightActionParams {
  action: InsightSelectorActionType.BUILD_INSIGHT;
  connectionData: ConnectionData;
  insight?: Insight;
  daxQueryResult?: IDaxQueryResult;
  canGoBack?: boolean;
}

export interface ExecuteInsightActionParams {
  action: InsightSelectorActionType.EXECUTE_INSIGHT;
  insight: Insight;
  data?: IInsightSelectorData;
}

export interface UpdateInsightSelectorDataActionParams {
  action: InsightSelectorActionType.UPDATE_INSIGHT_SELECTOR_DATA;
  settings: DynamicItemSettings[];
  insight: Insight;
  calculatedValue: number;
  fieldName: string;
}

export interface ConfigurePowerBIActionParams {
  action: InsightSelectorActionType.CONFIGURE_POWER_BI;
  connectionData: ConnectionData;
}

export interface OpenPowerBIInsightActionParams {
  action: InsightSelectorActionType.OPEN_POWERBI_INSIGHT;
  connectionData: ConnectionData;
  daxQueryResult: IDaxQueryResult;
}

export type InsightsBrowseConnectionsOutputActionsParams = BrowseInsightsActionParams | ConfigurePowerBIActionParams;

export type BrowseInsightsListOutputActionsParams = BrowseConnectionsActionsParams | BuildInsightActionParams | ExecuteInsightActionParams;

export type ExecuteInsightsListOutputActionsParams = UpdateInsightSelectorDataActionParams | BrowseInsightsActionParams | BrowseConnectionsActionsParams;

export type BuildInsightsListOutputActionsParams = BrowseInsightsActionParams | BrowseConnectionsActionsParams | UpdateInsightSelectorDataActionParams;

export type InsightSelectorOutputActionsParams = UpdateInsightSelectorDataActionParams | ConfigurePowerBIActionParams | OpenPowerBIInsightActionParams;

export type AllInsightsListOutputActionsParams =
  | BrowseConnectionsActionsParams
  | BrowseInsightsActionParams
  | BuildInsightActionParams
  | ExecuteInsightActionParams
  | UpdateInsightSelectorDataActionParams
  | ConfigurePowerBIActionParams
  | OpenPowerBIInsightActionParams;

export interface InsightsQueryParams {
  categoryId?: string;
  insightIds?: string;
  fields?: string;
  skip?: number;
  take?: number;
  stringSearch?: string;
  sort?: string;
  isScalar?: boolean;
  usedConnectors?: string[];
}

export interface InsightDTO {
  id: string;
  // shows if currently there is a process going on with the given insight
  processing?: boolean | "deleted";
  accountId: string;
  name: string;
  title: string;
  description: string;
  categoryId: string;
  requiredEntities: Entity[];
  status: string;

  createdById?: string;
  dateCreated?: string;
  modifiedById?: string;
  dateUpdated?: string;
  totalCount?: number;
  source?: IInsightSource;

  view: string;

  formatting?: InsightFormatting;

  queryLayout?: InsightQueryLayout;

  language: string;
  algorithm: string;
  filters?: InsightFilters | DynamicItemSettings[];
  size?: {
    width: number;
    height: number;
  };
  direct?: boolean;
  isScalar?: boolean;
  usedFilters?: string[];
  usedEntities?: InsightUsedEntity[];
  aggregateAccess?: InsightEntityAccess[];
  currentUserAllowedActions?: UserAction[];
}

export interface Insight extends InsightDTO {
  createdBy?: UserAssignee;
  modifiedBy?: UserAssignee;
  state?: IInsightState;
  submitEmailContent?: SubmitInsightEmailContent;
  connection?: ConnectionData;
  isShadow?: boolean;
}

export interface InsightCalculationTransaction {
  transactionId: string;
}

export interface InsightFilters {
  [filterKey: string]: string;
}

export interface DynamicItemSettings {
  name: string;
  value: string;
  title?: string;
  valueDisplayName?: string;
  type?: string;
}

export interface InsightSelectorData {
  insightName: string;
  fieldName: string;
  settings: DynamicItemSettings[];
  initialValue?: number;
  datasourceBlueprintName?: string;
  datasourceBlueprintLogoUrl?: string;
  formatting?: InsightFormatting;
  marketplacePredictionId?: string;
  accountPredictionId?: string;
  insightTemplate?: SuggestedMarketplaceInsightTemplate | SuggestedInsight;
  suggestedInsights?: Array<SuggestedInsight | SuggestedMarketplaceInsightTemplate>;
  type?: InsightFeedbackType;
  isInsightFromAccount?: boolean;
  suggestedAccountCount?: number;
  suggestedMarketplaceCount?: number;
}

export interface InsightListFilterOption {
  value: string;
  title?: string;
}

export enum ConditionalOperatorEnum {
  AND = "AND",
  OR = "OR",
}

export type ConditionalOperator = keyof typeof ConditionalOperatorEnum;

export interface InsightCalculationPayload {
  language: string;
  script: string;
  filters: InsightFilters | DynamicItemSettings[];
  settings: IInsightboardItemSetting[];
  id?: string;
}

export interface DataSourceNode extends NzTreeNodeOptions {
  dataSource: string;
  logoUrl?: string;
  isLookerPlaceholder?: boolean;
  children?: DataSourceNode[];
}

export interface BuildInsightSqlOperatorWithLocalisation {
  value: SupportedOperators;
  label: string;
}

export enum LookerDataSourceType {
  DASHBOARD = "dashboard",
  LOOK = "look",
}

export interface BuildInsightSetDataSourceParams {
  getEntityFields?: boolean;
}

export interface InsightUsedEntity {
  name: string;
  dataSourceBlueprintName: string;
  dataSourceBlueprintLogoUrl?: string;
  remoteEntityName: string;
  requiredFields: InsightEntityRequiredField[];
}

export interface InsightEntityRequiredField {
  name: string;
}

export interface InsightResponseMap {
  [transactionId: string]: IInsightCalculationData;
}

export interface StringMap {
  [id: string]: string;
}

export type KpiInsightUsage = { id: string; name: string };
export type InsightboardInsightUsage = { id: string; name: string };
export type MetricInsightUsage = { id: string; goalId: string; sessionId: string; name: string; ownerIds: string[] };
export type InsightUsage = KpiInsightUsage | InsightboardInsightUsage | MetricInsightUsage;

export type InsightEntityAccess = {
  access: IAccess;
};

export type RestrictedInsightsStatus = {
  restrictedInsightsExist: boolean;
};
