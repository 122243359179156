import { NgIf } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { COMPOSITION_BUFFER_MODE, FormsModule } from "@angular/forms";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { NzSelectSearchComponent } from "ng-zorro-antd/select";
import { addAttributeValue } from "@webapp/ui/utils/dom.utils";

@Component({
  selector: "ui-select-search",
  templateUrl: "select-search.component.html",
  styleUrls: ["./select-search.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: COMPOSITION_BUFFER_MODE, useValue: false }],
  standalone: true,
  imports: [NgIf, FormsModule],
  host: {
    "[class.hide-search-input]": `uiAutoClearSearchValue`,
  },
})
export class UiSelectSearchComponent extends NzSelectSearchComponent implements AfterViewInit {
  @Input() public nzId: string | null = null;
  @Input() public disabled = false;
  @Input() public readonly = false;
  @Input() public mirrorSync = false;
  @Input() public showInput = true;
  @Input() public focusTrigger = false;
  @Input() public value = "";
  @Input() public nzOpen: boolean;
  @Input() public activeDescendant: string;
  @Input() public popUpType: "tree" | "listbox";
  /** Aria label of the select. */
  @Input() public a11yLabel = "";
  @Input() public a11yLabelledby: string;
  @Input() public listboxId: string;
  /** Input that can be used to specify the `aria-description` attribute. */
  @Input() public a11yDescription: string;
  @Input() public a11yDescribedby: string;
  @Input() public a11yRequired = false;
  @Input() public placeholder: string | TemplateRef<NzSafeAny> | null = null;
  @Input() public autofocus = false;
  @Input() public triggerOpen = false;
  @Input() public selectSearchInputTabIndex = 0;
  @Input() public autoHideSearch: boolean;
  @Input() public focusMe: boolean;
  @Output() public readonly valueChange = new EventEmitter<string>();
  @Output() public readonly isComposingChange = new EventEmitter<boolean>();
  @ViewChild("inputElement", { static: true }) public inputElement!: ElementRef<HTMLInputElement>;
  @ViewChild("mirrorElement", { static: false }) public mirrorElement?: ElementRef;

  public ngAfterViewInit(): void {
    if (this.focusMe) {
      this.inputElement.nativeElement.focus();
    }

    if (this.triggerOpen) {
      this.inputElement.nativeElement.click();
    }

    addAttributeValue({
      element: this.inputElement.nativeElement,
      attribute: "aria-describedby",
      value: this.nzId && this.a11yDescription ? this.nzId + "-description" : null,
    });
  }
}
