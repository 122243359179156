import { v4 as uuidv4 } from "uuid";
import { localize } from "@gtmhub/localization";
import { IPosition } from "@gtmhub/shared/models";
import { getCurrentUserId } from "@gtmhub/users";
import { MetricTargetOperator } from "@webapp/okrs/metrics/models/metric.models";
import { QuantivePlusWhiteboardPostDTO } from "@webapp/platform-intelligence/shared/services/quantive-plus-whiteboard/models";
import {
  AlertType,
  BasicShapeType,
  CreateNewImageArgs,
  IAlertState,
  IBasicShapeState,
  IDraftGoalMetricState,
  IDraftGoalState,
  IHeadingState,
  IImageState,
  IImportedGoalMetricState,
  INoteState,
  IPenDrawingShapeArgs,
  IPenDrawingShapeState,
  ISize,
  IWhiteboardShapeState,
} from "./rendering/shapes/models";

export const RENDER_TIMEOUT_BEFORE_SELECT = 500;

export const DEFAULT_SHAPE_SIZE = 200;

const MAX_IMAGE_SIZE_ALLOWED = 5 * 1024 * 1024; // 5MB

export const isMaxSizeExceeded = (sizeInBytes: number, maxImageSizeAllowed = MAX_IMAGE_SIZE_ALLOWED): boolean => sizeInBytes >= maxImageSizeAllowed;

export const isImageFileExtensionTypeAllowed = (fileName: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  const ext = fileName.match(/\.([^\.]+)$/)[1].toLowerCase();

  return isImageTypeAllowed(ext);
};

export const isImageTypeAllowed = (ext: string): boolean => {
  const allowedImageExtensions = ["png", "jpg", "jpeg", "bmp", "tif", "webp"];

  return allowedImageExtensions.includes(ext);
};

export const offsetShape = (shape: IWhiteboardShapeState): IWhiteboardShapeState => {
  shape.position.x += 50;
  shape.position.y += 50;

  return shape;
};

export const validateImage = (file: File) => {
  if (!isImageFileExtensionTypeAllowed(file.name)) {
    throw localize("not_allowed_image_type");
  }

  const url = URL.createObjectURL(file);
  const { name, size, type } = file;

  if (isMaxSizeExceeded(size)) {
    throw localize("image_too_large");
  }

  return { url, name, size, type };
};

export const getSystemShortcutKey = () => {
  return navigator.platform.includes("Mac") ? "Cmd" : "Ctrl";
};

/**
 * Dragging an image from a search engine's result page tends to be a URL for a preview of the image
 * The URL of the image is set as a query param in it
 *
 * @return The given URL if not a search engine result or the URL of the image itself if a search engine result URL
 */
export const sanitizeSearchEngineResultLink = (url: string): string => {
  const searchEngineUrl = [
    [(url) => url.hostname.includes("google") && url.pathname === "/imgres", (urlSearchParams) => urlSearchParams.get("imgurl")],
    [(url) => url.hostname.includes("bing") && url.pathname === "/images/search", (urlSearchParams) => urlSearchParams.get("mediaurl")],
    // DuckDuckGo works out of the box
  ];

  const parsedUrl = new URL(url);

  for (const [checkUrl, getUrlFromQueryParam] of searchEngineUrl) {
    if (checkUrl(parsedUrl)) {
      const params = new URLSearchParams(parsedUrl.search);

      return getUrlFromQueryParam(params);
    }
  }

  return url;
};

export const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0) byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // Separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // Write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const extractHtmlEncodedImage = (html: string) => {
  // MacOS encapsulates a <img> in a <meta> tag, whereas Windows provides the <img> directly
  const htmlEncodedImageRegex = /^<(?:meta.+|img).+src="(.+?)".*>$/;

  const match = html.match(htmlEncodedImageRegex);

  return match ? match[1] : null;
};

export const scaleShapePosition = (
  { x, y }: IPosition,
  width: number,
  height: number,
  { x: viewportX, y: viewportY }: IPosition,
  { x: scaleX, y: scaleY }: IPosition
): IPosition => {
  const topPadding = 20;

  return {
    x: x / scaleX + viewportX - width / 2,
    y: y / scaleY + viewportY - height / 2 - topPadding / scaleY,
  };
};

export function createNewHeading(position: IPosition, createdById: string, fontFamily: string, zIndex: number): IHeadingState {
  return {
    type: "heading",
    id: uuidv4(),
    content: "",
    position,
    highlightColor: "#FFE380",
    textColor: "#000",
    fontSize: 48,
    fontFamily,
    createdById,
    zIndex,
    restricted: false,
  };
}
export function createNewNote(position: IPosition, createdById: string, zIndex: number): INoteState {
  return {
    type: "note",
    id: uuidv4(),
    content: "",
    position,
    size: { width: 150, height: 100 },
    backgroundColor: "#FFFAE7",
    fontSize: 12,
    fontFamily: "Arial",
    textAlignment: "left",
    createdById,
    zIndex,
    restricted: false,
  };
}

export function createDraftGoal(name: string, ownerIds: string[], position: IPosition, sessionId: string, parent?: { id: string; type: "goal" }): IDraftGoalState {
  return {
    type: "draftGoal",
    id: uuidv4(),
    name,
    description: "",
    ownerIds: ownerIds,
    position: position,
    metrics: [],
    createdById: getCurrentUserId(),
    sessionId: sessionId,
    attainmentTypeString: "average_kr",
    tags: [],
    private: false,
    customFields: null,
    parent,
  };
}

export function createBasicShape(type: BasicShapeType, position: IPosition, createdById: string, zIndex: number): IBasicShapeState {
  return {
    type,
    id: uuidv4(),
    content: "",
    position,
    size: getBasicShapeDefaultSize(type),
    backgroundColor: "#FFE380",
    fontSize: 12,
    fontFamily: "Arial",
    textAlignment: {
      textHorizontalAlignment: "center",
      textVerticalAlignment: "middle",
    },
    createdById,
    zIndex,
  };
}

export function createPenDrawingShape(args: IPenDrawingShapeArgs): IPenDrawingShapeState {
  const { position, path, strokeColor, strokeWidth, createdById, zIndex } = args;
  return {
    type: "pen-drawing",
    id: uuidv4(),
    position,
    path,
    strokeColor,
    strokeWidth,
    createdById,
    zIndex,
    restricted: false,
  };
}

export function createNewImage(newImageArgs: CreateNewImageArgs): IImageState {
  const { imageShapeId, imageUrl, renderingSize, rawImage, position, createdById, zIndex, rotationAngle } = newImageArgs;
  return {
    type: "image",
    id: imageShapeId,
    url: imageUrl,
    size: renderingSize,
    rawImage,
    position,
    createdById,
    zIndex,
    rotationAngle,
    restricted: false,
  };
}

function getBasicShapeDefaultSize(type: BasicShapeType): ISize {
  switch (type) {
    case "rectangle":
    case "rounded-rectangle":
      return { width: 232, height: 111 };
    case "ellipse":
      return { width: 166, height: 166 };
    case "rhombus":
      return { width: 214, height: 182 };
    case "parallelogram":
      return { width: 202, height: 151 };
    default:
      console.warn("Unsupported basic shape type: " + type);
  }
}

export const createDraftMetric = (options: {
  name: string;
  ownerId?: string;
  ownerIds?: string[];
  description?: string;
  targetOperator?: MetricTargetOperator;
  target?: number;
  initialValue?: number;
}): IDraftGoalMetricState => ({
  id: uuidv4(),
  name: options.name,
  description: options.description || "",
  ...(options.ownerIds ? { ownerIds: options.ownerIds } : { ownerIds: [options.ownerId] }),
  manualType: "double",
  target: options.target || 1,
  targetOperator: options.targetOperator || "at_least",
  initialValue: options.initialValue || 0,
  format: { prefix: "", suffix: "", fractionSize: 0 },
  dueDate: null,
  softDueDate: null,
  customFields: null,
  orderId: null,
  tags: [],
});

export const toAlertState = (shape: IWhiteboardShapeState, alertType: AlertType, message: string): IAlertState => {
  return {
    type: "alert",
    id: uuidv4(),
    alertType,
    position: shape.position,
    message,
    original: shape,
    createdById: shape.createdById,
  };
};

export const getAccountIdFromImageURL = (url: string): string => {
  const idPattern = new RegExp("[a-z0-9]{24}");
  return idPattern.exec(url)[0];
};

function pickFromObject<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
  return Object.fromEntries(Object.entries({ ...obj }).filter(([key]) => keys.includes(key as K))) as Pick<T, K>;
}

export const getWhiteboardSuggestionsPayload = (selectedShapes: IWhiteboardShapeState[]): QuantivePlusWhiteboardPostDTO => {
  const payload: QuantivePlusWhiteboardPostDTO = {
    nSuggestions: 3,
    items: [],
  };
  for (const shape of selectedShapes) {
    switch (shape.type) {
      case "goal":
      case "draftGoal": {
        const goal = pickFromObject(shape, "type", "name", "description", "ownerIds", "metrics", "parent");
        const metrics = goal.metrics.map((metric: IImportedGoalMetricState | IDraftGoalMetricState) =>
          pickFromObject(metric, "id", "name", "ownerIds", "manualType", "target", "targetOperator", "initialValue", "format")
        );

        payload.items.push({ ...goal, metrics });
        break;
      }
      case "note":
      case "heading":
      case "rhombus":
      case "ellipse":
      case "rounded-rectangle":
      case "rectangle":
      case "parallelogram": {
        payload.items.push(pickFromObject(shape, "type", "content"));
        break;
      }
    }
  }

  return payload;
};

export const findMostBottomYAxisShape = (shapes: IWhiteboardShapeState[]): IWhiteboardShapeState | null => {
  return shapes.reduce((previous, current) => (current.position.y > previous.position.y ? current : previous));
};
