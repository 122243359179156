import { UXCustomizationType } from "@gtmhub/uxcustomization/models";
import { NavItemsType } from "@webapp/navigation/models/nav-items-list.models";
import { Screens } from "@webapp/navigation/models/shared.models";

export const getItemsScreenMap = (): { [K in NavItemsType]: ItemScreen } => ({
  okrs: {
    menuScreenKey: "home",
    itemState: { name: "._goals.goal", paramsMap: { id: "id" } },
  }, // we can open okrs in session and in home, where should we redirect
  metrics: {
    menuScreenKey: "home",
    itemState: { name: "._goals.goal", paramsMap: { id: "id" } },
  },
  sessions: {
    menuScreenKey: "okrs",
    subTypes: {
      session: {
        menuScreenKey: "okrs",
        itemState: { name: "gtmhub.goals.all", paramsMap: { id: "planningSessionId" } },

        activeStates: [
          { name: "gtmhub.goals.all", paramsMap: { id: "planningSessionId" } },
          { name: "gtmhub.goals.grid", paramsMap: { id: "planningSessionId" } },
          { name: "gtmhub.goals.workflow", paramsMap: { id: "planningSessionId" } },
          { name: "gtmhub.goals.overview", paramsMap: { id: "planningSessionId" } },
          { name: "gtmhub.goals.alignmentSidebar.tree", paramsMap: { id: "planningSessionId" } },
        ],
      },
      filter: {
        menuScreenKey: "okrs",
        itemState: { name: `gtmhub.okrView.all`, paramsMap: { id: "okrViewId" } },
      },
    },
  },
  whiteboards: {
    menuScreenKey: "whiteboards",
    itemState: { name: "gtmhub.whiteboard", paramsMap: { id: "whiteboardId" } },
  },
  kpis: {
    menuScreenKey: "kpis",
    itemState: { name: "gtmhub.kpis", paramsMap: { id: "kpiGroupId" } },
  },
  tasks: {
    menuScreenKey: "tasks",
    itemState: { name: "gtmhub.tasks", paramsMap: { id: "filterId" } },
  },
  insightboards: {
    menuScreenKey: "insightboards",
    itemState: { name: "gtmhub.insightboard", paramsMap: { id: "dashboardId" } },
  },
  lists: {
    menuScreenKey: "reports",
    itemState: { name: "gtmhub.list", paramsMap: { id: "listId" } },
  },
  people: {
    menuScreenKey: "people",
    subTypes: {
      user: {
        menuScreenKey: "people",
        itemState: { name: "gtmhub.employees.employee", paramsMap: { id: "employeeId" } },
      },
      team: {
        menuScreenKey: "people",
        itemState: { name: "gtmhub.teams.team", paramsMap: { id: "teamId" } },
      },
    },
  }, // .employee({'employeeId': employee.id}) .team({'teamId': team.id}) here it will be more tricky how to handle the states based on item type
});

export interface ItemState {
  name: string;
  paramsMap?: { id: string };
}
interface ItemScreen {
  menuScreenKey: Screens;
  itemState?: ItemState;
  activeStates?: ItemState[];
  subTypes?: { [K in UXCustomizationType]?: ItemScreen };
}
