import { AfterViewInit, ChangeDetectionStrategy, Component, ComponentRef, ElementRef, Input, OnDestroy, ViewChild, ViewContainerRef } from "@angular/core";
import { HeightResizeObservable } from "@quantive/ui-kit/core";
import { GridItemHTMLElement } from "gridstack";
import { Subject, distinctUntilChanged, takeUntil } from "rxjs";
import { UiDashboardNode } from "../../dashboard.models";
import { BaseWidgetComponent } from "../base-widget.component";

export interface UiGridItemHTMLElement extends GridItemHTMLElement {
  component?: UiDashboardItemComponent;
  gridstackNode?: UiDashboardNode;
}

@Component({
  selector: "ui-dashboard-item",
  template: `
    <div class="grid-stack-item-content" [style.background-color]="uiOptions.bgColor || '#fff'">
      <!-- where dynamic items go based on component types, or sub-grids, etc...) -->
      <ng-template #container></ng-template>

      <!-- any static (defined in dom) content goes here -->
      <ng-content></ng-content>

      <!-- fallback HTML content from GridStackWidget content field if used instead -->
      {{ uiOptions.content }}
    </div>
  `,
  styleUrls: ["./dashboard-item.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    tabindex: "0",
    "[class.no-shadow]": `!!uiOptions.noShadow`,
  },
  standalone: true,
})
export class UiDashboardItemComponent implements OnDestroy, AfterViewInit {
  private childWidgetValue: BaseWidgetComponent;

  @Input() public set uiOptions(val: UiDashboardNode) {
    if (this.el.gridstackNode?.grid) {
      this.el.gridstackNode.grid.update(this.el, val);
    } else {
      this.options = { ...val, el: this.el };
    }
  }
  public get uiOptions(): UiDashboardNode {
    return this.el.gridstackNode || this.options || { el: this.el };
  }

  public get el(): UiGridItemHTMLElement {
    return this.elementRef.nativeElement;
  }

  @ViewChild("container", { read: ViewContainerRef, static: true }) public container: ViewContainerRef;

  public ref: ComponentRef<UiDashboardItemComponent> | undefined;

  public get childWidget(): BaseWidgetComponent | undefined {
    return this.childWidgetValue;
  }
  public set childWidget(value: BaseWidgetComponent) {
    this.childWidgetValue = value;
    this.el.setAttribute("aria-label", value.a11yLabel);
  }

  private options?: UiDashboardNode;
  private destroy$ = new Subject<void>();

  private get hasSizeToContent(): boolean {
    const { sizeToContent } = this.uiOptions;
    return sizeToContent === true || (typeof sizeToContent === "number" && sizeToContent > 0);
  }

  constructor(private elementRef: ElementRef<UiGridItemHTMLElement>) {
    this.el.component = this;
  }

  public clearOptions(): void {
    delete this.options;
  }

  public ngAfterViewInit(): void {
    const contentEl = this.el.querySelector<HTMLElement>(".grid-stack-item-content");
    new HeightResizeObservable(contentEl).pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe(() => this.triggerResizeToContent());

    const innerContentEl =
      this.el.querySelector<HTMLElement>(".grid-stack-item-content ui-card .ant-card-body") || this.el.querySelector<HTMLElement>(".grid-stack-item-content > *");
    if (innerContentEl) {
      new HeightResizeObservable(innerContentEl).pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe(() => this.triggerResizeToContent());
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    delete this.ref;
    delete this.el.component;
  }

  private triggerResizeToContent(): void {
    if (this.hasSizeToContent) {
      requestAnimationFrame(() => this.uiOptions.grid.resizeToContent(this.el));
    }
  }
}
