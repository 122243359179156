import { IHttpService, IPromise, IRequestShortcutConfig } from "angular";
import { EnvironmentService } from "@gtmhub/env";
import { IInsightSelectorData } from "@gtmhub/insights";
import { InsightFeedbackType, SuggestedInsights } from "@webapp/data-story/models/insights.models";
import { MetricTargetOperator } from "@webapp/okrs/metrics/models/metric.models";

export interface IMetricPrediction {
  predId: string;
  targetOperator: MetricTargetOperator;
  target?: number;
  format?: { prefix: string; suffix: string; fractionSize: number };
  operatorModelVersion: string;
  targetUnitModelVersion: string;
  initialValue?: number;
  metadata: Map<string, string>;
}

export interface IMetricFieldsChoice {
  metricId: string;
  predId: string;
  title: string;
  operatorSelected: string;
  operatorPredicted: string;
  targetSelected: number;
  targetPredicted: number;
  unitSelected: string | number;
  unitPredicted: string | number;
  unitTypeSelected: string;
  unitTypePredicted: string;
  operatorModelVersion: string;
  targetUnitModelVersion: string;
  initialValuePredicted: number;
  initialValueSelected: number;
  metadata: Map<string, string>;
}

interface IInsightFieldsChoice {
  selectedInsightId?: string;
  type?: InsightFeedbackType;
}

export class AutoFieldsService {
  public static $inject = ["$http", "EnvironmentService"];

  constructor(
    private $http: IHttpService,
    private env: EnvironmentService
  ) {}

  public getPredictionFromMetric(title: string): IPromise<IMetricPrediction> {
    const url = this.env.getAutoFieldsEndpoint("/metric");
    return this.$http.post<IMetricPrediction>(url, { title }).then((response) => response.data);
  }

  /*
   * @param {string} title - To search by;
   * @param {fromAccount: false} - If true will load suggested insights from current accounts, otherwise from marketplace;
   */
  public getInsightRecommendations(title: string, { fromAccount = false }): IPromise<SuggestedInsights> {
    const url = this.env.getAutoInsightsEndpoint(`${fromAccount ? "/account" : ""}/recommendation`);
    const payload: IRequestShortcutConfig = {
      params: {
        kr: title,
      },
    };

    return this.$http.get<SuggestedInsights>(url, payload).then((response) => response.data);
  }

  public sendInsightRecommendtationFeedback(predictionId: string, data: IInsightFieldsChoice): IPromise<void> {
    const url = this.env.getAutoInsightsEndpoint(`/feedback/${predictionId}`);
    return this.$http.put<void>(url, data).then((response) => response.data);
  }

  public sendMetricAnalyticsData(data: IMetricFieldsChoice): IPromise<IMetricFieldsChoice> {
    const url = this.env.getAutoFieldsEndpoint("/metric/feedback");
    return this.$http.post<IMetricFieldsChoice>(url, data).then((response) => response.data);
  }

  public getRecommendedInsightAnalyticsData(data: IInsightSelectorData): { marketplaceBody: IInsightFieldsChoice; accountBody: IInsightFieldsChoice } {
    const marketplaceBody: IInsightFieldsChoice = {
        type: data.type,
      },
      accountBody: IInsightFieldsChoice = {
        type: data.type,
      };

    /** selectedInsight is required and must be a valid ObjectID, otherwise validation kicsk in */
    if (data.insightTemplate) {
      if (data.isInsightFromAccount) {
        accountBody.selectedInsightId = data.insightTemplate.id;
      } else {
        marketplaceBody.selectedInsightId = data.insightTemplate.id;
      }
    }

    return { marketplaceBody, accountBody };
  }
}
