<div
  class="sp-py-6 sp-px-10 suggestion-card"
  [ngClass]="['border-' + borderStyle, borderColorOnHover + '-border-on-hover']"
  (mouseenter)="showActionButtons = true"
  (mouseleave)="showActionButtons = false">
  <div class="suggestion-card-content" ui-row uiAlign="top">
    <div class="sp-pt-7 sp-pb-5" *ngIf="isIconAvailable" ui-col>
      <i class="fs-16px" [ngClass]="{ 'text-color-gray-1 suggestion-icon-dynamic-color': isIconColorDynamic }" [uiType]="iconType" [uiTheme]="iconTheme" ui-icon></i>
    </div>
    <div class="sp-py-6" ui-col uiFlex="1">
      <span
        class="text-content"
        [ngClass]="{
          'text-color-gray-7': fontColor === 'light',
          'text-color-gray-9': fontColor === 'medium',
          'text-color-gray-13': fontColor === 'dark',
          'text-content-dynamic-color': isFontColorDynamic,
        }"
        >{{ textToDisplay }}</span
      >
    </div>
    <div *ngIf="areActionButtonsAvailable" [uiFlex]="actionButtonsContainerWidth + 'px'" ui-col>
      <div *ngIf="showActionButtons" ui-row uiJustify="end" uiAlign="middle">
        <button
          class="sp-p-6 suggestion-card-action"
          *ngIf="isRefreshBtnAvailable"
          [uiTooltipTitle]="'suggest_another' | localize"
          (click)="onSuggestAnotherClicked()"
          ui-button
          uiSize="default"
          ui-tooltip
          uiTooltipOverlayClassName="suggestion-card-action-button-tooltip"
          uiTooltipPlacement="topRight"
          data-test-id="suggestion-refresh-button">
          <i class="fs-16px" ui-icon uiType="suggestion-card-refresh"></i>
        </button>
        <button
          class="sp-ml-4 sp-p-6 suggestion-card-action"
          *ngIf="isRemoveBtnAvailable"
          [uiTooltipTitle]="'remove_suggestion' | localize"
          (click)="onRemoveSuggestionClicked()"
          ui-button
          uiSize="default"
          ui-tooltip
          uiTooltipOverlayClassName="suggestion-card-action-button-tooltip"
          uiTooltipPlacement="topRight"
          data-test-id="suggestion-decline-button">
          <i class="text-color-gray-6 fs-16px" ui-icon uiType="suggestion-card-remove"></i>
        </button>
        <button
          class="sp-ml-4"
          *ngIf="isAddBtnAvailable"
          [uiTooltipTitle]="'add_this_suggestion' | localize"
          (click)="onAddSuggestionClicked()"
          ui-button
          uiType="primary"
          uiShape="circle"
          uiSize="default"
          ui-tooltip
          uiTooltipOverlayClassName="suggestion-card-action-button-tooltip"
          uiTooltipPlacement="topRight"
          data-test-id="suggestion-accept-button">
          <i class="text-color-gray-1 fs-16px add-suggestion-icon" ui-icon uiType="plus"></i>
        </button>
      </div>
    </div>
  </div>
</div>
