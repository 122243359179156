import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { FavoriteButtonComponent } from "./favorite-button/favorite-button.component";

@NgModule({
  declarations: [FavoriteButtonComponent],
  imports: [CommonModule, UiIconModule, UiButtonModule],
  exports: [FavoriteButtonComponent],
})
export class FavoritesModule {}
