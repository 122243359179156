import { ComponentRef, Injectable, Injector } from "@angular/core";
import { CustomFieldLabelComponent } from "@webapp/custom-fields/components/label/label.component";
import { CustomFieldDynamicComponent, CustomFieldVisualizationKind, EditableCustomFieldsInput, ICustomField } from "../../models/custom-fields.models";
import { AbstractCustomFieldFactory } from "./abstract-custom-field.factory";
import { DatepickerCustomFieldComponentFactory } from "./datepicker-custom-field-component.factory";
import { CustomFieldLabelComponentFactory } from "./label-custom-field-component.factory";
import { MultiSelectCustomFieldComponentFactory } from "./multi-select-custom-field-component.factory";
import { NumericCustomFieldComponentFactory } from "./numeric-custom-field-component.factory";
import { RichTextEditorCustomFieldComponentFactory } from "./rich-text-area-custom-field-component.factory";
import { SelectAssigneeCustomFieldComponentFactory } from "./select-assignee-custom-field-component.factory";
import { SelectCustomFieldComponentFactory } from "./select-custom-field-component.factory";

@Injectable()
export class CustomFieldComponentFactory {
  private customFieldFactoryMap = {
    "gtmhub.text": RichTextEditorCustomFieldComponentFactory,
    "gtmhub.textarea": RichTextEditorCustomFieldComponentFactory,
    "gtmhub.datepicker": DatepickerCustomFieldComponentFactory,
    "gtmhub.select": SelectCustomFieldComponentFactory,
    "gtmhub.multiselect": MultiSelectCustomFieldComponentFactory,
    "gtmhub.selectassignee": SelectAssigneeCustomFieldComponentFactory,
    "gtmhub.numeric": NumericCustomFieldComponentFactory,
  };

  constructor(private injector: Injector) {}

  public build(
    visualizationKind: CustomFieldVisualizationKind,
    customField: ICustomField,
    customFieldsInputValues: EditableCustomFieldsInput
  ): { customFieldLabel: ComponentRef<CustomFieldLabelComponent>; customFieldBody: ComponentRef<CustomFieldDynamicComponent> } {
    const customFieldLabel = this.buildLabelComponent(customField, customFieldsInputValues);
    const customFieldBody = this.buildBodyComponent(visualizationKind, customField, customFieldsInputValues);

    return {
      customFieldLabel,
      customFieldBody,
    };
  }

  private buildLabelComponent(customField: ICustomField, customFieldsInputValues: EditableCustomFieldsInput): ComponentRef<CustomFieldLabelComponent> {
    const service = this.injector.get<AbstractCustomFieldFactory>(CustomFieldLabelComponentFactory);
    return service.build(customField, customFieldsInputValues) as ComponentRef<CustomFieldLabelComponent>;
  }

  private buildBodyComponent(
    visualizationKind: CustomFieldVisualizationKind,
    customField: ICustomField,
    customFieldsInputValues: EditableCustomFieldsInput
  ): ComponentRef<CustomFieldDynamicComponent> {
    const componentFactory = this.customFieldFactoryMap[visualizationKind];
    const service = this.injector.get<AbstractCustomFieldFactory>(componentFactory);
    return service.build(customField, customFieldsInputValues);
  }
}
