import { Assignee } from "@webapp/assignees/models/assignee.models";
import { DataSourceBlueprint } from "@webapp/data-story/models/data-sources-facade.models";
import { ConnectionData, DynamicItemSettings, Insight } from "@webapp/data-story/models/insights.models";
import { ISessionsIdMap } from "@webapp/sessions/models/sessions.model";

type IToValueDisplayName = {
  key: string;
  ids: string;
  assigneesIdMap: Record<string, Assignee>;
  sessionsIdMap: ISessionsIdMap;
};

export const toValueDisplayName = ({ key, ids, assigneesIdMap, sessionsIdMap }: IToValueDisplayName): string => {
  if (!ids) return "";

  return ids
    .split(",")
    .map((id) => {
      let displayName: string;

      switch (key) {
        case "team":
          displayName = assigneesIdMap[id]?.name;
          break;
        case "session":
          displayName = sessionsIdMap[id]?.title;
          break;
        // if there is no match in the switch it will just return
        // what it is by default on the next line
      }
      return displayName || id;
    })
    .toString();
};

export const getDataSourceBlueprintName = (insight: Insight): string => {
  return insight.usedEntities && insight.usedEntities.length ? insight.usedEntities[0].dataSourceBlueprintName : null;
};

export const getFilterFromItemSettings = (name: string, settings: DynamicItemSettings[] | undefined): DynamicItemSettings | null => {
  if (settings) {
    const filter = settings.find((filter) => filter.name === name);
    return filter ?? null;
  }
  return null;
};

export const formatInsightConnectionData = (blueprint: DataSourceBlueprint): ConnectionData => {
  return {
    name: blueprint.name,
    title: blueprint.title,
    logoUrl: blueprint.logoUrl,
  };
};
