import { PIStateProcessorInstanceSubType } from "../state-processor/pi-state-processor.models";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface IPIImprovementDTO {}
export interface IPIImprovementItemModel<K = unknown> {
  suggestions: {
    improvement: K;
  };
  flowId: string;
}

export type PIImprovementSuggestionType = "objective" | "KR" | "";

export type PIImproveSuggestionContext = {
  parentId: string;
  parentType: PIImprovementSuggestionType;
  entityId: string;
};

export interface IPostImprovementPayload {
  subEntityType: PIStateProcessorInstanceSubType;
  entityId: string;
  improvement: {
    instruction: PostImprovementInstruction;
    suggestion: Record<string, unknown>;
    isAskAI: boolean;
    parentId: string | null;
    parentType: PIImprovementSuggestionType | null;
  };
}

type ImproveSuggestionCommonDropdownMenuItems = "ASK_AI_TO";
type ImproveSuggestionSpecificDropdownMenuItems =
  | "MORE_CONCISE"
  | "MORE_AMBITIOUS"
  | "MORE_STRATEGIC"
  | "MORE_CREATIVE"
  | "MORE_QUANTIFIABLE"
  | "MORE_SPECIFIC"
  | "SHORTER"
  | "LONGER"
  | "SIMPLER"
  | "BROADER";

type ImproveSuggestionCommonDropdownMenuItemsMap = {
  [key in ImproveSuggestionCommonDropdownMenuItems]: string;
};

export type ImproveSuggestionButtonSpecificDropdownMenuItemsMap = {
  [key in ImproveSuggestionSpecificDropdownMenuItems]: string;
};

export const IMPROVE_SUGGESTION_COMMON_DROPDOWN_MENU_ITEMS_MAP: ImproveSuggestionCommonDropdownMenuItemsMap = {
  ASK_AI_TO: "ask_ai_to",
} as const;

export const OR_MAKE_IT_ITEM = "or_make_it";

export const IMPROVE_SUGGESTION_SPECIFIC_DROPDOWN_MENU_ITEMS_MAP: ImproveSuggestionButtonSpecificDropdownMenuItemsMap = {
  MORE_CONCISE: "more_concise",
  MORE_AMBITIOUS: "more_ambitious",
  MORE_STRATEGIC: "more_strategic",
  MORE_CREATIVE: "more_creative",
  MORE_QUANTIFIABLE: "more_quantifiable",
  MORE_SPECIFIC: "more_specific",
  SHORTER: "shorter",
  LONGER: "longer",
  SIMPLER: "simpler",
  BROADER: "broader",
} as const;

export const IMPROVE_OBJECTIVE_TITLE_DROPDOWN_MENU_ITEMS: (keyof ImproveSuggestionButtonSpecificDropdownMenuItemsMap)[] = [
  "MORE_CONCISE",
  "MORE_AMBITIOUS",
  "MORE_STRATEGIC",
  "MORE_CREATIVE",
];

export const IMPROVE_KR_TITLE_DROPDOWN_MENU_ITEMS: (keyof ImproveSuggestionButtonSpecificDropdownMenuItemsMap)[] = [
  "MORE_CONCISE",
  "MORE_QUANTIFIABLE",
  "MORE_AMBITIOUS",
];

export const IMPROVE_TASK_TITLE_DROPDOWN_MENU_ITEMS: (keyof ImproveSuggestionButtonSpecificDropdownMenuItemsMap)[] = ["BROADER", "MORE_SPECIFIC"];

export const IMPROVE_DESCRIPTION_DROPDOWN_MENU_ITEMS: (keyof ImproveSuggestionButtonSpecificDropdownMenuItemsMap)[] = ["LONGER", "SHORTER", "SIMPLER"];
export const IMPROVE_TAGS_DROPDOWN_MENU_ITEMS: (keyof ImproveSuggestionButtonSpecificDropdownMenuItemsMap)[] = ["BROADER", "MORE_SPECIFIC"];

type PostImprovementInstructionItems =
  | "askAiTo"
  | "moreConcise"
  | "moreAmbitious"
  | "moreStrategic"
  | "moreCreative"
  | "moreQuantifiable"
  | "moreSpecific"
  | "shorter"
  | "longer"
  | "simpler"
  | "broader";
type PostImprovementInstructionItemsMapKeys = ImproveSuggestionCommonDropdownMenuItems | ImproveSuggestionSpecificDropdownMenuItems;

export const POST_IMPROVEMENT_INSTRUCTIONS_MAP: Record<PostImprovementInstructionItemsMapKeys, PostImprovementInstructionItems> = {
  ASK_AI_TO: "askAiTo",
  MORE_CONCISE: "moreConcise",
  MORE_AMBITIOUS: "moreAmbitious",
  MORE_STRATEGIC: "moreStrategic",
  MORE_CREATIVE: "moreCreative",
  MORE_QUANTIFIABLE: "moreQuantifiable",
  MORE_SPECIFIC: "moreSpecific",
  SHORTER: "shorter",
  LONGER: "longer",
  SIMPLER: "simpler",
  BROADER: "broader",
} as const;

export type PostImprovementInstruction = (typeof POST_IMPROVEMENT_INSTRUCTIONS_MAP)[PostImprovementInstructionItemsMapKeys] | string;
