import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ALIAS_KEYS_MAC, ALIAS_KEYS_WIN, DISPLAY_KEYS_MAC, DISPLAY_KEYS_WIN, MODIFIER_KEYS_MAC, MODIFIER_KEYS_WIN, byModifierKeys } from "./human-hotkey.util";

@Component({
  selector: "human-hotkey",
  template: `
    <ng-container>
      <span *ngFor="let key of keys">{{ key }}</span>
    </ng-container>
  `,
  styleUrls: ["./human-hotkey.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    "aria-hidden": "true",
  },
})
export class HumanHotkeyComponent implements OnInit {
  @Input() public combo: string;

  private isMac: boolean;

  public get keys(): string[] {
    return this.combo
      ?.toUpperCase()
      .split(/\+/g)
      .map((key) => this.aliasKeys[key] || key)
      .sort(byModifierKeys(this.modifierKeys))
      .map((key) => this.displayKeys[key] || key);
  }

  private get aliasKeys(): Record<string, string> {
    return this.isMac ? ALIAS_KEYS_MAC : ALIAS_KEYS_WIN;
  }

  private get displayKeys(): Record<string, string> {
    return this.isMac ? DISPLAY_KEYS_MAC : DISPLAY_KEYS_WIN;
  }

  private get modifierKeys(): string[] {
    return this.isMac ? MODIFIER_KEYS_MAC : MODIFIER_KEYS_WIN;
  }

  public ngOnInit(): void {
    this.isMac = /Mac|iPod|iPhone|iPad/.test(window.navigator.userAgent);
  }
}
