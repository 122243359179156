import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { QueryParams } from "@webapp/core/abstracts/models/query-params.model";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { ICollection } from "@webapp/core/core.models";
import { DataSourceDTO, DataSourceFilterParams, DataSourcePatchDTO, DataSourcePostDTO } from "@webapp/data-story/models/data-source.models";
import { DataSourcesApiService } from "@webapp/data-story/services/data-sources-facade/data-sources-api.service";
import { DataSourcesState } from "@webapp/data-story/services/data-sources-facade/data-sources-state.service";

@Injectable({
  providedIn: "root",
})
export class DataSourcesFacade extends BaseFacade<DataSourceDTO, DataSourcePostDTO, DataSourcesState, DataSourcesApiService> {
  constructor(state: DataSourcesState, api: DataSourcesApiService) {
    super(state, api);
  }

  public createSingle$(data: DataSourcePostDTO): Observable<DataSourceDTO> {
    return this.post$<DataSourceDTO>(data, {
      ...new RequestConfig(),
      url: this.apiService.createDataSourceEndpoint(),
    });
  }

  public getByQuery$(params: DataSourceFilterParams): Observable<DataSourceDTO[]> {
    const queryParams: QueryParams = {
      ...(params.keys && { keys: params.keys }),
      ...(params.connectorIds && { connectorIds: params.connectorIds }),
      ...(params.includeHidden && { includeHidden: params.includeHidden }),
    };

    return this.getAll$<ICollection<DataSourceDTO>>(null, {
      ...new RequestConfig(),
      url: this.apiService.getBasePath(HttpActions.get),
      queryParams,
    }).pipe(map((result) => result.items));
  }

  public getById$(id: string): Observable<DataSourceDTO> {
    return this.get$<DataSourceDTO>(null, {
      ...new RequestConfig(),
      url: this.apiService.getDataSourceByIdEndpoint(id),
    });
  }

  public patchSingle$(id: string, payload: DataSourcePatchDTO): Observable<DataSourceDTO> {
    return this.patch$<DataSourceDTO>(id, payload, {
      ...new RequestConfig(),
      url: this.apiService.patchDataSourceEndpoint(id),
    });
  }

  public createEntity$(dataSourceId: string): Observable<DataSourceDTO> {
    return this.post$<DataSourceDTO>(
      {},
      {
        ...new RequestConfig(),
        url: this.apiService.createEntityEndpoint(dataSourceId),
      }
    );
  }
}
