<form
  class="ui-input-text-field"
  #inputFieldWrapper
  [ngClass]="{
    borderless: borderless,
    disabled: disabled,
    readonly: readonly,
    'display-flex': !!uiType,
    'error-state': inputField.invalid && inputField.dirty,
    count: maxCharacterCount && displayMaxCharacterCount && (displayMaxCharacterCountMode === 'always' || this.isFocused),
    'count-error':
      (maxCharacterCount && displayMaxCharacterCount && (displayMaxCharacterCountMode === 'always' || this.isFocused) && value?.length >= maxCharacterCount) ||
      (required && inputField.invalid && inputField.dirty),
    'height-small': uiSize === 'small',
    'characters-on-focus': maxCharacterCount && displayMaxCharacterCount && displayMaxCharacterCountMode === 'focus',
  }">
  <i class="fs-20px sp-mr-4" *ngIf="uiType" [uiTheme]="uiTheme" [uiType]="uiType" ui-icon></i>
  <input
    class="input-text-field-box"
    #inputField="ngModel"
    [(ngModel)]="value"
    [ngClass]="className"
    [ngModelOptions]="{ updateOn: updateOn }"
    [attr.id]="uiId"
    [disabled]="disabled"
    [readonly]="readonly"
    [required]="required"
    [type]="inputType"
    [attr.placeholder]="uiPlaceholder"
    [attr.aria-required]="a11yRequired"
    [attr.aria-label]="a11yLabel"
    [attr.aria-labelledby]="a11yLabelledby"
    [uiTooltipTitle]="value"
    [uiTooltipTrigger]="showTooltip ? 'hover' : null"
    [attr.data-test-id]="e2eTestId"
    [maxStringLength]="maxCharacterCount"
    [name]="uiName || 'input-text-field'"
    [maxlength]="maxCharacterCount && enforceCharactersLimit ? maxCharacterCount : null"
    (blur)="onFocusOut()"
    (focus)="onFocus()"
    (input)="handleInputChange()"
    (ngModelChange)="onChange($event)"
    (keydown.enter)="enter.emit()"
    pattern=".*\S+.*"
    uiTooltipPlacement="bottom"
    ui-tooltip />
</form>

<div class="input-after-content sp-py-4" *ngIf="!readonly && (validationHint || validationError)">
  <div class="validation-hint" *ngIf="validationHint && !validationError">{{ validationHint }}</div>

  <ui-validation-hint *ngIf="validationError" [hintContent]="validationError" [isAccented]="isValidationHintAccented" hintType="error"></ui-validation-hint>
</div>

<!-- The div below plays the role of aria-description since it still doesn't have full support for macOs and safari -->
<!-- Once we have full support we can simply remove it and attach back [attr.aria-description]="a11yDescription" to the input element -->
<div class="select-a11y-description" *ngIf="uiId && a11yDescription" [attr.id]="uiId + '-description'">{{ a11yDescription }}</div>
