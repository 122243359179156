import { ChangeDetectionStrategy, Component, Inject, ViewChild } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { GoalsFacade } from "@webapp/okrs/goals/services/goals-facade.service";
import { FormGroupComponent } from "@webapp/shared/form/form-group.component";
import { UI_MODAL_DATA } from "@webapp/ui/modal/modal.models";
import { OkrWorkflowAction } from "../okr-workflow.models";

export type OkrWorkflowModalData = {
  goalId: string;
  mode: string;
};

interface ApprovalForm {
  okrWorkflowComment: { note: string };
}

@Component({
  selector: "okr-workflow-modal",
  templateUrl: "./okr-workflow-modal.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OkrWorkflowModalComponent {
  public mode: OkrWorkflowAction;
  public goalId: string;

  @ViewChild(FormGroupComponent, { static: false })
  public workflowFormGroup: { value: ApprovalForm };

  constructor(
    @Inject(UI_MODAL_DATA) modalData: OkrWorkflowModalData,
    private goalFacade: GoalsFacade
  ) {
    Object.assign(this, modalData);
  }

  get isDisabled(): boolean {
    return this.mode === "decline" && (!this.workflowFormGroup.value.okrWorkflowComment.note || this.workflowFormGroup.value.okrWorkflowComment.note.trim().length === 0);
  }

  public async approveOkr(): Promise<void> {
    await firstValueFrom(this.goalFacade.approveGoal$(this.goalId, this.workflowFormGroup.value.okrWorkflowComment.note));
  }

  public async declineOkr(): Promise<void> {
    await firstValueFrom(this.goalFacade.declineGoal$(this.goalId, this.workflowFormGroup.value.okrWorkflowComment.note));
  }

  public async withdrawOkr(): Promise<void> {
    await firstValueFrom(this.goalFacade.withdrawGoal$(this.goalId));
  }
}
