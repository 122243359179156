import { buildTeamsSearchConditions } from "@gtmhub/teams/utils/search-utils";
import { buildUsersSearchConditions } from "@gtmhub/users/utils/search-utils";
import { ISearchCondition } from "@webapp/search/models/search-api.models";
import { SearchCollection, SearchConditionSettings } from "@webapp/search/models/search.models";

export const buildSearchConditions = (collection: SearchCollection, settings: SearchConditionSettings): ISearchCondition[] => {
  if (collection === "users") {
    return buildUsersSearchConditions(settings);
  }
  if (collection === "teams") {
    return buildTeamsSearchConditions(settings);
  }
  return [];
};

export const isActiveSearchCondition = (): ISearchCondition => {
  return {
    fieldName: "isActive",
    fieldCondition: {
      operator: "isNot",
      value: "false",
    },
  };
};

export const getExcludedIdsSearchCondition = (excludedIds: string[]): ISearchCondition => {
  return {
    fieldName: "_id",
    fieldCondition: {
      operator: "notIn",
      value: excludedIds,
    },
  };
};
