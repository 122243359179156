import { ITag } from "@gtmhub/tags/models";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { CfMap } from "@webapp/custom-fields/models/custom-fields.models";
import { Links } from "@webapp/links/models/links.models";
import { IDesignScore } from "@webapp/okrs/goals/models/goal-design-score.models";
import { IGoalsGroupedBySession } from "@webapp/okrs/goals/models/goal-links.models";
import { IOkrClosedStatus } from "@webapp/okrs/goals/models/goal-status.models";
import { IItemWorkflow } from "@webapp/okrs/goals/models/goal-workflow.models";
import { AttainmentTypeString, GoalParentType } from "@webapp/okrs/goals/models/goal.models";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { IQuantivePlusTask } from "@webapp/platform-intelligence/quantive-plus/models";
import { IQuantivePlusMetric } from "@webapp/platform-intelligence/shared/models";
import { IAccess, Session, UserAction } from "@webapp/sessions/models/sessions.model";
import { Gif } from "@webapp/shared/components/gifs/gif.models";
import { Tag } from "@webapp/tags/models/tag.model";
import { Task } from "@webapp/tasks/models/tasks.models";

export interface IBaseGoal {
  id: string;
  name: string;
}

export type ContributorType = IGoal | Metric;

export enum ContributorTypeEnum {
  goal = "goal",
  metric = "metric",
}

export interface IGoalParent {
  id: string;
  type: GoalParentType;
}

// to do: when this IGoal model is refactored IAlignmentTreeGoal can be refactored as well to extend goal base, extract props from other etc.
export interface IGoal {
  id?: string;
  name?: string;
  description?: string;
  ownerIds?: string[];
  assignee?: Assignee;
  assignees?: Assignee[];
  sessionId?: string;
  dateTo?: string;
  dateFrom?: string;
  closedStatus?: IOkrClosedStatus;
  currentUserAllowedActions?: UserAction[];
  parentId?: string;
  parentType?: GoalParentType;
  parentGoalSessionId?: string;
  metrics?: Metric[];
  metricsCount?: number;
  tasks?: Task[];
  tasksCount?: number;
  nonArchivedTasksCount?: number;
  childrenCount?: number;
  fullSubTreeCount?: number;
  attainment?: number;
  attainmentTypeString?: AttainmentTypeString;
  aggregatedAttainment?: number;
  fullAggregatedAttainment?: number;
  obfuscated?: boolean;
  private?: boolean;
  locked?: boolean;
  tags?: ITag[];
  customFields?: CfMap;
  access?: IAccess;
  designScore?: IDesignScore;
  links?: Links<"goal">;
  workflow?: IItemWorkflow;
  orderId?: number;
  childType?: "goal";
  color?: string;
  createdById?: string;
  watchers?: string[];
}

export type GoalChildType = "goal" | "metric";

interface IGoalChildReorderItem {
  id: string;
  type: GoalChildType;
}
export interface IGoalChildReorderPayload extends Record<string, unknown> {
  parentId: string;
  previous?: IGoalChildReorderItem;
  moved: IGoalChildReorderItem;
  next?: IGoalChildReorderItem;
}

export interface IGoalChildReorderResponse {
  orderId: number;
}

export interface IWorkflowActions {
  none: boolean;
  draft: boolean;
  review: boolean;
  needsReviewers: boolean;
  approved: boolean;
  owner: boolean;
  reviewer: boolean;
  random: boolean;
}

export interface IWorkflowUi {
  actions?: IWorkflowUiActions;
  owner?: boolean;
  showAllReviewers?: boolean;
  limitReviewers?: number;
  defaultLimitReviewers?: number;
  view: IWorkflowUiView;
}

interface IWorkflowUiView {
  buttons: IWorkflowUiViewActions;
}

interface IWorkflowUiViewActions {
  approve?: IUiActionsDisplay;
  decline?: IUiActionsDisplay;
  withdraw?: IUiActionsDisplay;
}

interface IUiActionsDisplay {
  show: boolean;
  hide?: boolean;
}

interface IWorkflowUiActions {
  approved?: IWorkflowUiAction;
  draft?: IWorkflowUiAction;
  inReview?: IWorkflowUiAction;
  notEnoughReviewers?: IWorkflowUiAction;
}

interface IWorkflowUiAction {
  owner?: boolean;
  reviewer?: boolean;
  ownerAndReviewer?: boolean;
  declined?: boolean;
  approves?: boolean;
}

export interface IGoalSupport {
  supported?: IGoalsGroupedBySession[];
  supporting?: IGoalsGroupedBySession[];
}

export interface IGoalInTree extends IGoal {
  orphaned?: boolean;
  filtersMatched?: number;
  childrenFiltersMatched?: number;
  subtreeFiltersMatched?: number;
  filterOutChildren?: boolean;
  folded?: boolean;
  folding?: boolean;
  aggregatedAttainmentColor?: string;
  color?: string;
}

// used to create the goals tree hierarchy when visualizing the alignment
export interface IGoalNode {
  goal: IGoalInTree;
  children: IGoalNode[];
  depth?: number;
  meta?: IGoalsAlignmentMeta;
  expanded?: boolean;
}

export interface IGoalsAlignmentMeta {
  matchedFiltersData: IGoalsAlignmentMatchedFiltersData;
}

export interface IGoalsAlignmentMatchedFiltersData {
  ownerIds: IGoalsAlignmentFilterMeta[][];
  tags: IGoalsAlignmentFilterMeta[][];
}

export interface IGoalsAlignmentFilterMeta {
  goalId: string;
  rootParentId: string;
  depth: number;
}

export interface ISessionNode {
  session: Session;
  children: ISessionNode[];
}

// used to create the comment object to be send to the backend when editing/ patching the Key Result update note
export interface ICheckinComment {
  comment?: string;
  gif?: Gif;
}

export interface IGoalsIdMap {
  [goalId: string]: IGoal;
}

export interface IPartialGoal {
  id: string;
  fullAggregatedAttainment?: number;
  attainment?: number;
}

export interface IOkrGridColumn {
  field: string;
  label: string;
  visible: boolean;
  isCustom: boolean;
  isSortable?: boolean;
}

export interface ITreeViewOrder {
  field: string;
  name: string;
}

export type SingleGoalSelectorInvokedBy = "LinkOKR" | "AlignOKR" | "CloneOKR" | "TaskRow" | "TaskSingleView" | "TaskKanban" | "KR" | "Whiteboards" | "Automation";

export enum GoalFormEventType {
  GOAL_FORM_OPENED = "Goal Form Opened",
}

export interface IOkrTemplate {
  name: string;
  description: string;
  installationsCount: string;
  appId: string;
  metrics: Metric[];
  tags: ITag[];
  topics: unknown[];
  partner: {
    // key = 1 for Internal accounts & Gtmhub live account -> Gtmhub partner
    key: number;
    name: string;
    logo: {
      key: number;
      name: string;
    };
  };
}

export interface IAllGoal extends IBaseGoal {
  metrics: Metric[];
  parentId: string;
  ownerIds: string[];
  description: string;
  childrenCount: number;
  metricsCount: number;
  attainment: number;
  fullAggregatedAttainment: number;
  sessionId: string;
  customFields: CfMap;
  obfuscated: boolean;
  private: boolean;
  workflow: IItemWorkflow;
  tags: IAllGoalTag[];
  attainmentTypeString: AttainmentTypeString;
  orderId?: number;
}

export interface IAllMetric {
  id: string;
  name: string;
  ownerIds: string[];
  confidence?: IAllConfidence;
  attainment: number;
  orderId: number;
  tags?: Tag[];
}

interface IAllConfidence {
  value: number;
  reason: string;
}

export interface IAllGoalTag {
  title: string;
}

export type GridContributorType = IAllGoal | IAllMetric;

export interface IOkrGridContributorAdditionalParams {
  childType: ContributorTypeEnum;
  links?: Links<"metric">;
}

export type OkrGridContributor = GridContributorType & IOkrGridContributorAdditionalParams;

export const MISSING_SESSION_ID_STATE_PARAM = "none";

export const MISSING_SESSION_ID = "000000000000000000000000";

export type QuantivePlusSuggestions = { sessionId: string; tasks: IQuantivePlusTask[]; keyresults: IQuantivePlusMetric[] };

export interface OkrMethodologySettings {
  areMultipleObjectiveOwnersDisabled: boolean;
  areMultipleKrOwnersDisabled: boolean;
}
