import { IHttpService, IPromise } from "angular";
import { EnvironmentService } from "@gtmhub/env";
import { INgRedux } from "@gtmhub/state-management";
import { IIdpGroup, IIdpRoleMapping, IPutIdpRoleMapping } from "@webapp/configuration/models/configuration.model";
import { ICollection } from "@webapp/core/core.models";
import { IDynamicSubteamsRole, RolesActions } from ".";
import { IUser } from "../users/models";
import { IAccountRolesResponse, IRole } from "./models";
import { filterOutSysAdminRole, generateGetRolesQuery } from "./utils";

export class RoleService {
  public static $inject = ["$http", "EnvironmentService", "$ngRedux", "RolesActions"];

  constructor(
    private $http: IHttpService,
    private env: EnvironmentService,
    private $ngRedux: INgRedux,
    private rolesActions: RolesActions
  ) {}

  public getRoles(options: { includeTeamRoles?: boolean; includeUserCount?: boolean } = {}): IPromise<IAccountRolesResponse> {
    const query = generateGetRolesQuery(options);
    const url = this.env.getApiEndpoint("/roles" + query);

    return this.$http.get<IAccountRolesResponse>(url).then((response) => {
      return filterOutSysAdminRole(response.data);
    });
  }

  public deleteRole(id: string): IPromise<void> {
    const url = this.env.getApiEndpoint("/roles/account/" + id);

    return this.$http.delete(url).then(() => {
      this.$ngRedux.dispatch(this.rolesActions.removeRole(id));
    });
  }

  public getRole(id: string): IPromise<IRole | IDynamicSubteamsRole> {
    const url = this.env.getApiEndpoint("/roles/" + id);

    return this.$http.get<IRole>(url).then((response) => response.data);
  }

  public createRole(role: IRole): IPromise<IRole> {
    const url = this.env.getApiEndpoint("/roles/account");

    return this.$http.post<IRole>(url, role).then((response) => {
      this.$ngRedux.dispatch(this.rolesActions.addRole(response.data));

      return response.data;
    });
  }

  public addUserToRole(role: IRole, user: IUser): IPromise<void> {
    const url = this.env.getApiEndpoint("/users/" + user.id + "/roles");

    return this.$http.post<void>(url, { roleId: role.id }).then(() => null);
  }

  public removeUserFromRole(role: IRole, user: IUser): IPromise<void> {
    const url = this.env.getApiEndpoint("/users/" + user.id + "/roles/" + role.id);

    return this.$http.delete<void>(url).then(() => null);
  }

  public removeUserFromRoles(roles: IRole[], user: IUser): IPromise<void> {
    const roleIds = roles.map((role) => role.id).join(",");
    const url = this.env.getApiEndpoint(`/users/${user.id}/roles?ids=${encodeURIComponent(roleIds)}`);

    return this.$http.delete<void>(url).then(() => null);
  }

  public updateRole(role: IRole): IPromise<IRole> {
    const url = this.env.getApiEndpoint("/roles/account/" + role.id);

    return this.$http.put<IRole>(url, role).then((response) => {
      this.$ngRedux.dispatch(this.rolesActions.updateRole(response.data));

      return response.data;
    });
  }

  public getIdpGroups(): IPromise<ICollection<IIdpGroup>> {
    const url = this.env.getApiEndpoint("/roles/account/mapping/scim-groups");

    return this.$http.get<ICollection<IIdpGroup>>(url).then((response) => response.data);
  }

  public scimGroupExists(): IPromise<boolean> {
    const url = this.env.getApiEndpoint("/roles/account/mapping/scim-groups/exists");

    return this.$http
      .head(url)
      .then((response) => response.status === 200)
      .catch(() => false);
  }

  public getIdpRoleMappings(): IPromise<IIdpRoleMapping[]> {
    const url = this.env.getApiEndpoint("/roles/account/mapping");

    return this.$http.get<IIdpRoleMapping[]>(url).then((response) => response.data);
  }

  public postIdpRoleMappings(mapping: IIdpRoleMapping[]): IPromise<IIdpRoleMapping[]> {
    const url = this.env.getApiEndpoint("/roles/account/mapping");

    return this.$http.post<IIdpRoleMapping[]>(url, mapping).then((response) => response.data);
  }

  public bulkUpdateIdpRoleMappings(mappings: IPutIdpRoleMapping[]): IPromise<void> {
    const url = this.env.getApiEndpoint("roles/account/mapping");

    return this.$http.put<IRole>(url, mappings).then(() => null);
  }

  public bulkDeleteIdpRoleMappings(ids: string[]): IPromise<void> {
    const url = this.env.getApiEndpoint("roles/account/delete-mappings");

    return this.$http.post<IRole>(url, { ids }).then(() => null);
  }

  public deleteIdpRoleMapping(id: string): IPromise<void> {
    const url = this.env.getApiEndpoint(`/roles/account/mapping/${id}`);

    return this.$http.delete<void>(url).then(() => null);
  }

  public resetUserRoles(): IPromise<void> {
    const url = this.env.getApiEndpoint("/users/roles/recalculate-scim-roles");

    return this.$http.post<void>(url, null).then(() => null);
  }
}
