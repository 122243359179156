<div class="form-item-label-section" [uiTooltipTitle]="labelTooltip" [uiTooltipTrigger]="labelTooltip ? 'hover' : null" uiTooltipPlacement="left" ui-tooltip>
  <div
    class="primary-label"
    [ngClass]="{
      'form-item-label-size-small': typography?.primaryLabelFontSize === 'small',
      'form-item-label-size-medium': !typography?.primaryLabelFontSize || typography?.primaryLabelFontSize === 'medium',
      'form-item-label-size-large': typography?.primaryLabelFontSize === 'large',
      'form-item-label-weight-regular': !typography?.primaryLabelFontWeight || typography?.primaryLabelFontWeight === 'regular',
      'form-item-label-weight-bold': typography?.primaryLabelFontWeight === 'bold',
      'editor-respected-line-height': !secondaryLabelText && (!typography?.labelPosition || typography.labelPosition === 'left'),
    }">
    <label [attr.for]="controlId" [id]="id" (click)="focusBoundElement()">
      {{ labelText }}<span class="required-star" *ngIf="required" aria-hidden="true">*</span>
      <span class="a11y-visually-hidden" *ngIf="secondaryLabelText" [innerHTML]="secondaryLabelText" removeInteractableAttributes></span>
    </label>
  </div>
  <div
    class="secondary-label"
    *ngIf="secondaryLabelText"
    [ngClass]="{
      'form-item-label-size-small': typography?.secondaryLabelFontSize === 'small',
      'form-item-label-size-medium': !typography?.secondaryLabelFontSize || typography?.secondaryLabelFontSize === 'medium',
      'form-item-label-size-large': typography?.secondaryLabelFontSize === 'large',
      'form-item-label-weight-regular': !typography?.secondaryLabelFontWeight || typography?.secondaryLabelFontWeight === 'regular',
      'form-item-label-weight-bold': typography?.secondaryLabelFontWeight === 'bold',
    }"
    [innerHTML]="secondaryLabelText"
    aria-hidden="true"></div>
</div>
