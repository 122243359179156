import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormArray, FormGroup } from "@angular/forms";
import { IAssigneesStoreState } from "@gtmhub/assignees";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { getCurrentUserId } from "@gtmhub/users";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { PiTrackingEventsEnum } from "@webapp/platform-intelligence/shared/utils/pi-tracking";

@Component({
  selector: "quantive-plus-wizard-objective[flowId][flowName]",
  templateUrl: "./objective.component.html",
  styleUrls: ["./objective.component.less"],
})
export class QuantiveObjectiveComponent implements OnInit {
  @Input() public flowId: string;
  @Input() public flowName: string;
  @Input() public formGroup: FormGroup;
  @Input() public hasSuggestedKrs: boolean;
  @Input() public suggestingKeyResults: boolean;
  @Input() public isEditing: boolean;
  @Input() public isKeyResultsStage: boolean;

  @Output() public readonly addKeyResultCb = new EventEmitter();
  @Output() public readonly addObjectiveEvent = new EventEmitter();
  @Output() public readonly removeKeyResultCb = new EventEmitter();
  @Output() public readonly removeObjective = new EventEmitter();
  @Output() public readonly confirmObjective = new EventEmitter();
  @Output() public readonly suggestMoreKeyResultsEvent = new EventEmitter();
  @Output() public readonly refreshObjectiveDescriptionEvent = new EventEmitter();
  @Output() public readonly refreshKeyResultsErrorEvent = new EventEmitter();

  constructor(private analyticsService: AnalyticsService) {}

  public isEditingObjectiveKeyResults(objective: AbstractControl): boolean {
    return objective.value.keyResults.some((kr) => kr.isEdit);
  }

  public assigneeIdMap: Record<string, Assignee>;

  public ngOnInit(): void {
    const state = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState>();
    this.assigneeIdMap = state.assignees.map;
  }

  public getAssignees(objective: AbstractControl): string[] {
    return objective.value.assignees;
  }

  public setAssignees(objective: AbstractControl, ids: string[]): void {
    objective.patchValue({ ...objective.value, assignees: ids });
  }

  public get objectives(): FormArray {
    return this.formGroup.get("objectives") as FormArray;
  }

  public objectiveKeyResults(objective: AbstractControl): FormArray {
    return objective.get("keyResults") as FormArray;
  }

  public refreshDescription(objective: AbstractControl, objIndex: number): void {
    objective.patchValue({ ...objective.value, isFetchingDescriptionError: false });

    this.refreshObjectiveDescriptionEvent.emit(objIndex);
  }

  public openEditMode(objective: AbstractControl): void {
    objective.patchValue({
      ...objective.value,
      isEdit: true,
      previousSavedTitle: objective.value.title,
      previousSavedDescription: objective.value.description,
      prevAssignees: objective.value.assignees,
    });
  }

  public confirm(objective: AbstractControl, objIndex: number): void {
    objective.patchValue({
      ...objective.value,
      title: objective.value.title,
      description: objective.value.description,
      isEdit: false,
      isFetchingDescriptionError: false,
    });

    this.confirmObjective.emit(objIndex);

    if (objective.value.id) {
      this.analyticsService.track(PiTrackingEventsEnum.PiSuggestionEdited, {
        flowId: this.flowId,
        flowName: this.flowName,
        userId: getCurrentUserId(),
        suggestionId: objective.value.id,
      });
    }
  }

  public cancel(objective: AbstractControl, objectiveIndex: number): void {
    if (objective.value.isManual) {
      this.removeObjective.emit(objectiveIndex);
      return;
    }

    objective.patchValue({
      title: objective.value.previousSavedTitle,
      description: objective.value.previousSavedDescription,
      assignees: objective.value.prevAssignees,
      isEdit: false,
    });
  }

  public addKeyResult(objIndex, objective: AbstractControl): void {
    objective.patchValue({
      ...objective.value,
      isFetchingKeyResultSuggestionsError: false,
    });

    this.resetObjectiveKeyResults(objective);
    this.addKeyResultCb.emit(objIndex);
  }

  public removeKeyResult(metricIndex, objIndex): void {
    this.removeKeyResultCb.emit({ metricIndex, objIndex });
  }

  public suggestMoreKeyResults(objIndex: number, objective: AbstractControl): void {
    this.resetObjectiveKeyResults(objective);
    this.suggestMoreKeyResultsEvent.emit(objIndex);
  }

  private resetObjectiveKeyResults(objective: AbstractControl): void {
    const krs = this.objectiveKeyResults(objective);
    krs.controls.forEach((group) => group.get("isEdit").setValue(false));
  }

  public refreshKeyResultsError(objIndex: number): void {
    this.refreshKeyResultsErrorEvent.emit(objIndex);
  }

  public calculateObjectiveDataContainerWidth(objective: AbstractControl): string {
    if (objective.value.isFetchingDescription) {
      return "1";
    } else if (objective.value.isFetchingDescriptionError) {
      return "448px";
    } else {
      return "482px";
    }
  }
}
