import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { localize } from "@gtmhub/localization";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { IsNavigationExpandedCache } from "@webapp/navigation/services/is-navigation-expanded.cache";

@Component({
  selector: "toggle-nav",
  templateUrl: "./toggle-nav.component.html",
  styleUrls: ["./toggle-nav.component.less"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [UiTooltipModule, LocalizationModule, CommonModule, UiIconModule],
})
export class ToggleNavComponent implements OnInit {
  @Input()
  public action: "expand" | "collapse";
  @Input()
  public uiId: string;

  @Input()
  public tooltipTitle: string;

  public activeScreen: string;

  constructor(
    private isNavExpanded: IsNavigationExpandedCache,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    if (this.action !== "expand") {
      this.tooltipTitle = localize("close_side_bar");
    }
  }

  public onFocus(): void {
    this.activeScreen = document.getElementById("new-nav-sub-nav-title")?.textContent;
  }

  public expandNav(value: boolean): void {
    this.isNavExpanded.set({ value, persistValue: true });

    window.setTimeoutOutsideAngular(() => {
      const idToFocus = this.action === "collapse" ? "top-subnav-toggle" : "subnav-toggle";
      const toggleSubnav = document.getElementById(idToFocus);

      toggleSubnav?.focus();
    }, 300);

    this.cdr.markForCheck();
  }
}
