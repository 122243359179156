import { IAttributes, IAugmentedJQuery, IDirective, IDirectiveFactory, IScope } from "angular";

const renderedTimesMap = new Map<string, number>();

/**
 * This is a helper directive to detect re-rendering issues. It
 * displays the number of times it has been rendered.
 */
export class RerenderDetectorDirective implements IDirective {
  link(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes): void {
    const id = attrs.for ? scope.$eval(attrs.for) : "";
    if (!renderedTimesMap.has(id)) {
      renderedTimesMap.set(id, 1);
    }

    let renderedTimes = renderedTimesMap.get(id);
    element[0].textContent = `${renderedTimes}`;
    renderedTimes++;
    renderedTimesMap.set(id, renderedTimes);
  }

  static factory(): IDirectiveFactory {
    return () => new RerenderDetectorDirective();
  }
}
