import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { UiThemeType } from "@quantive/ui-kit/icon";
import { calculateSuggestionCardActionButtonsContainerWidth } from "../../../quantive-plus/utils/utils";
import { PiCardBorderColor, PiCardBorderStyle, PiTextCardFontColor } from "../../models/pi-card.models";

@Component({
  selector: "pi-text-card",
  templateUrl: "./pi-text-card.component.html",
  styleUrls: ["./pi-text-card.component.less"],
})
export class PiTextCardComponent implements OnChanges {
  public showActionButtons: boolean;
  public areActionButtonsAvailable = true;
  public actionButtonsContainerWidth: number;

  @Input()
  public borderStyle: PiCardBorderStyle = "solid";
  @Input()
  public borderColorOnHover: PiCardBorderColor = "dark";
  @Input()
  public isIconAvailable = false;
  @Input()
  public iconType: string;
  @Input()
  public iconTheme: UiThemeType;
  @Input()
  public isIconColorDynamic = false;
  @Input()
  public fontColor: PiTextCardFontColor = "dark";
  @Input()
  public isFontColorDynamic = false;
  @Input()
  public isRefreshBtnAvailable = true;
  @Input()
  public isRemoveBtnAvailable = true;
  @Input()
  public isAddBtnAvailable = true;
  @Input()
  public textToDisplay: string;

  @Output()
  public readonly suggest = new EventEmitter();
  @Output()
  public readonly suggestionRemove = new EventEmitter();
  @Output()
  public readonly suggestionAdd = new EventEmitter();

  public ngOnChanges(changes: SimpleChanges): void {
    this.isRefreshBtnAvailable = changes.isRefreshBtnAvailable ? changes.isRefreshBtnAvailable.currentValue : this.isRefreshBtnAvailable;
    this.isRemoveBtnAvailable = changes.isRemoveBtnAvailable ? changes.isRemoveBtnAvailable.currentValue : this.isRemoveBtnAvailable;
    this.isAddBtnAvailable = changes.isAddBtnAvailable ? changes.isAddBtnAvailable.currentValue : this.isAddBtnAvailable;

    const availableActionButtons = [this.isRefreshBtnAvailable, this.isRemoveBtnAvailable, this.isAddBtnAvailable].filter(Boolean).length;

    if (!availableActionButtons) {
      this.areActionButtonsAvailable = false;

      return;
    }

    this.actionButtonsContainerWidth = calculateSuggestionCardActionButtonsContainerWidth({ availableActionButtons });
  }

  public onSuggestAnotherClicked = (): void => {
    this.suggest.emit();
  };

  public onRemoveSuggestionClicked = (): void => {
    this.suggestionRemove.emit();
  };

  public onAddSuggestionClicked = (): void => {
    this.suggestionAdd.emit();
  };
}
