import { IRelatedItemsBaseEntry } from "@webapp/links/models/related-items.models";
import { AttainmentTypeString } from "@webapp/okrs/goals/models/goal.models";
import { IParentSelectorEntry, IParentSelectorNode } from "@webapp/okrs/models/parent-selector.models";

export interface IMultiSelectorSearchParams {
  [key: string]: string | string[];
}

export type IMultiSelectorSelectedEntry = IParentSelectorEntry | IRelatedItemsBaseEntry;

export interface IMultiSelectorGoal {
  id: string;
  name: string;
  ownerIds: string[];
  sessionId?: string;
  metrics?: IMultiSelectorMetric[];
  private?: boolean;
  attainmentTypeString?: AttainmentTypeString;
}

export interface IMultiSelectorMetric {
  id?: string;
  name?: string;
  ownerIds?: string[];
  sessionId?: string;
}

export interface IMultiSelectorKpi {
  id?: string;
  name?: string;
  ownerIds?: string[];
}

export interface IMultiSelectorTrackingParams {
  canPerformSearch: boolean;
  isEmptyInputValue: boolean;
  inputValue: string;
}

export interface IMultiSelectorNodeContext {
  goal: IMultiSelectorGoal;
  hasExpandedGoal: boolean;
}

export interface IMultiSelectorNodesContext {
  sessionId: string;
  hasExpandedGoal: boolean;
}

export interface IMultiSelectorGoalNodes {
  goalNodes: IParentSelectorNode[];
  hasExpandedGoal: boolean;
}

export enum IMultiSelectorNodesType {
  OKR = "okr",
  KPI = "kpi",
}
