import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { ButtonSelectComponent } from "@webapp/shared/components/button-select/button-select.component";
import { IButtonSelectOptions } from "@webapp/shared/components/button-select/button-select.models";
import { CurrentUserRepository } from "@webapp/users";
import { CommentsOrdering, DEFAULT_COMMENTS_ORDER } from "./comments-ordering.models";

@Component({
  selector: "comments-ordering",
  templateUrl: "./comments-ordering.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentsOrderingComponent implements OnInit {
  @Output() public readonly orderUpdate = new EventEmitter<CommentsOrdering>();

  @ViewChild(ButtonSelectComponent) public buttonSelect: ButtonSelectComponent;

  public order: CommentsOrdering;
  public readonly commentsOrderingOptions: IButtonSelectOptions[] = [
    {
      value: "-createdAt",
      label: "newest_first",
    },
    {
      value: "createdAt",
      label: "oldest_first",
    },
  ];
  public selectedOrder: string;

  public constructor(private currentUserRepository: CurrentUserRepository) {}

  public ngOnInit(): void {
    this.order = this.currentUserRepository.getUserSetting("commentsOrdering");

    if (this.order === undefined || this.order === null) {
      this.setDefaultCommentsOrder();
    }

    this.selectedOrder = this.commentsOrderingOptions.find((option) => this.order === option.value).label;
  }

  public onUpdateOrder(update: CommentsOrdering): void {
    this.currentUserRepository.setUserSetting({ commentsOrdering: update });
    this.selectedOrder = this.commentsOrderingOptions.find((option) => update === option.value).label;
    this.orderUpdate.emit(update);
  }

  private setDefaultCommentsOrder(): void {
    this.currentUserRepository.setUserSetting({ commentsOrdering: DEFAULT_COMMENTS_ORDER });
    this.order = DEFAULT_COMMENTS_ORDER;
  }
}
