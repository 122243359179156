import { ISocketV2Data } from "@gtmhub/sockets";
import { ITeam } from "@gtmhub/teams";
import { unique } from "@gtmhub/util";
import { Employee } from "@webapp/employees";

export const applyChangesToEmployeeTeams = (employee: Employee, teamsUpdates: ISocketV2Data<ITeam>): Pick<Employee, "managedTeamIds" | "teamIds"> => {
  let teamIds = employee.teamIds || [];
  let managedTeamIds = employee.managedTeamIds || [];

  Object.keys(teamsUpdates).forEach((key: keyof ISocketV2Data<ITeam>) => {
    switch (key) {
      case "added":
        teamsUpdates[key].items.forEach((team) => {
          const updatedEmployeeTeams = addMissingTeamToEmployeeTeams(employee.id, team, managedTeamIds, teamIds);
          teamIds = updatedEmployeeTeams.teamIds;
          managedTeamIds = updatedEmployeeTeams.managedTeamIds;
        });
        break;
      case "modified":
        teamsUpdates[key].items.forEach((team) => {
          let updatedEmployeeTeams = addMissingTeamToEmployeeTeams(employee.id, team, managedTeamIds, teamIds);
          teamIds = updatedEmployeeTeams.teamIds;
          managedTeamIds = updatedEmployeeTeams.managedTeamIds;
          updatedEmployeeTeams = removeTeamFromEmployeeTeams(employee.id, team, managedTeamIds, teamIds);
          teamIds = updatedEmployeeTeams.teamIds;
          managedTeamIds = updatedEmployeeTeams.managedTeamIds;
        });
        break;
      case "removed":
        teamsUpdates[key].items.forEach((team) => {
          const updatedEmployeeTeams = removeDeletedTeamFromEmployeeTeams(team, managedTeamIds, teamIds);
          teamIds = updatedEmployeeTeams.teamIds;
          managedTeamIds = updatedEmployeeTeams.managedTeamIds;
        });
        break;
    }
  });

  return { teamIds, managedTeamIds };
};

const addMissingTeamToEmployeeTeams = (
  employeeId: string,
  team: ITeam,
  managedTeamIds: string[],
  memberedTeamIds: string[]
): Pick<Employee, "managedTeamIds" | "teamIds"> => {
  if (team.manager === employeeId && !managedTeamIds.includes(team.id)) {
    managedTeamIds.push(team.id);
  }
  if (!memberedTeamIds.includes(team.id) && Array.isArray(team.members) && team.members.includes(employeeId)) {
    memberedTeamIds.push(team.id);
  }

  return { managedTeamIds, teamIds: memberedTeamIds };
};

const removeTeamFromEmployeeTeams = (
  employeeId: string,
  team: ITeam,
  managedTeamIds: string[],
  memberedTeamIds: string[]
): Pick<Employee, "managedTeamIds" | "teamIds"> => {
  if (team.manager !== employeeId && managedTeamIds.includes(team.id)) {
    managedTeamIds = managedTeamIds.filter((managedTeamId) => managedTeamId !== team.id);
  }
  if (memberedTeamIds.includes(team.id) && Array.isArray(team.members) && !team.members.includes(employeeId)) {
    memberedTeamIds = memberedTeamIds.filter((teamId) => teamId !== team.id);
  }

  return { managedTeamIds, teamIds: memberedTeamIds };
};

const removeDeletedTeamFromEmployeeTeams = (
  team: Pick<ITeam, "id">,
  managedTeamIds: string[],
  memberedTeamIds: string[]
): Pick<Employee, "managedTeamIds" | "teamIds"> => {
  if (managedTeamIds.includes(team.id)) {
    managedTeamIds = managedTeamIds.filter((managedTeamId) => managedTeamId !== team.id);
  }
  if (memberedTeamIds.includes(team.id)) {
    memberedTeamIds = memberedTeamIds.filter((teamId) => teamId !== team.id);
  }

  return { managedTeamIds, teamIds: memberedTeamIds };
};

export const isGoalOwnedByTeamOfEmployee = (employee: Employee): string[] => {
  return unique([...employee.managedTeamIds, ...employee.teamIds]);
};

export const sortEmployeesByActiveStatus = (employees: Employee[]): Employee[] => {
  return employees.sort((a: Employee, b: Employee) => {
    if (a.isActive === b.isActive) {
      return 0;
    } else if (a.isActive) {
      return -1;
    } else {
      return 1;
    }
  });
};
