import { IHttpInterceptor, IHttpInterceptorFactory, IHttpResponse, IPromise, IQService, IRequestConfig, IWindowService, auto } from "angular";
import { StateService } from "@uirouter/angularjs";
import { v4 as uuidv4 } from "uuid";
import { AuthenticationResolverService } from "@gtmhub/auth";
import { ResponseError, isUserDeactivatedOrDeletedResponse } from "@gtmhub/core/routing";
import { storage } from "@gtmhub/core/storage";
import { TracingService } from "@gtmhub/core/tracing";
import { EnvironmentService, IAppConfig } from "@gtmhub/env";
import { increaseGlobalRetries } from "@webapp/core/http/global-retries";
import { LoggingService } from "@webapp/core/logging/services/logging.service";
import { isMsTeamsApp } from "@webapp/shared/utils/msteams";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";

export class SetAccountIdRequestHeaderInterceptor implements IHttpInterceptor {
  constructor(private $injector: auto.IInjectorService) {}

  public request = (config: IRequestConfig): IRequestConfig | IPromise<IRequestConfig> => {
    config.headers = config.headers || {};

    const envService = this.$injector.get<EnvironmentService>("EnvironmentService");
    const accountId = storage.get("accountId");
    if (envService.isApiRequest(config.url) && accountId) {
      config.headers["gtmhub-accountId"] = accountId;
    } else {
      delete config.headers["gtmhub-accountId"];
    }

    return config;
  };

  public static factory(): IHttpInterceptorFactory {
    const factory = ($injector: auto.IInjectorService) => new SetAccountIdRequestHeaderInterceptor($injector);
    factory.$inject = ["$injector"];
    return factory;
  }
}

export class RewriteUrlForCdnInterceptor implements IHttpInterceptor {
  constructor(private appConfig: IAppConfig) {}

  public request = (config: IRequestConfig): IRequestConfig | IPromise<IRequestConfig> => {
    config.headers = config.headers || {};

    if (this.appConfig.cdnStorage.enabled && (config.method === "GET" || config.method === "HEAD")) {
      const filename = config.url.substring(config.url.lastIndexOf("/") + 1);
      const ext = filename.indexOf(".") >= 0 ? filename.substring(filename.lastIndexOf(".")) : null;

      if ((ext === ".html" || ext === ".css") && config.url.substr(0, 1) === "/") {
        config.url = this.appConfig.cdnStorage.url + config.url;
        delete config.headers["Authorization"];
      }
    }

    return config;
  };

  public static factory(): IHttpInterceptorFactory {
    const factory = (appConfig: IAppConfig) => new RewriteUrlForCdnInterceptor(appConfig);
    factory.$inject = ["appConfig"];
    return factory;
  }
}

interface ITrackDataRequestParams {
  gtmhubAdditionalParams?: Record<string, string | boolean | number>;
}

export class TrackDataInterceptor implements IHttpInterceptor {
  constructor(private $injector: auto.IInjectorService) {}

  public request = (config: IRequestConfig): IRequestConfig | IPromise<IRequestConfig> => {
    const envService = this.$injector.get<EnvironmentService>("EnvironmentService");
    if (envService.isApiRequest(config.url)) {
      const tracingService = this.$injector.get<TracingService>("TracingService");
      const screen = tracingService.getScreen();
      const stateName = screen ? screen.screenName : "<unknown>";
      const stateTrackingName = screen ? screen.stateTrackingName : null;
      // segment/analytics-next library is managing 'analytics_session_id' analytics.factory.ts
      // This id is used to connect events in amplitude which are sent from BE to the same session with those from FE.
      // This is a timestamp (when current session has started)
      const analyticsSessionId = localStorage.getItem("analytics_session_id");

      let map = {
        "gtmhub-application-name": "webapp",
        "gtmhub-path": stateName,
      };

      if (stateTrackingName) {
        map["gtmhub-screen-name"] = stateTrackingName;
      }

      if (analyticsSessionId) {
        map["gtmhub-session-id"] = analyticsSessionId;
      }

      const requestParams = config.params as ITrackDataRequestParams;
      if (requestParams && requestParams.gtmhubAdditionalParams) {
        map = {
          ...map,
          ...requestParams.gtmhubAdditionalParams,
        };
        delete requestParams.gtmhubAdditionalParams;
      }

      const params = [];
      Object.keys(map).forEach((key) => {
        const value = map[key];
        const param = [key, value].join("=");
        params.push(param);
      });
      config.headers["gtmhub-additional-params"] = params.join(",");
      config.headers["transaction-id"] = uuidv4();
    }
    return config;
  };

  public static factory(): IHttpInterceptorFactory {
    const factory = ($injector: auto.IInjectorService) => new TrackDataInterceptor($injector);
    factory.$inject = ["$injector"];
    return factory;
  }
}

const noLogForResponseWithStatusCodes = [404, 409];

export class ResponseErrorLoggerInterceptor implements IHttpInterceptor {
  private loggingService: LoggingService;

  constructor(
    private $q: IQService,
    private $injector: auto.IInjectorService,
    private appConfig: IAppConfig
  ) {
    this.loggingService = new LoggingService(appConfig.logging, "spa");
  }

  public responseError = <T>(rejection: IHttpResponse<T>): IPromise<IHttpResponse<T>> | IHttpResponse<T> => {
    const envService = this.$injector.get<EnvironmentService>("EnvironmentService");
    const shouldLogError =
      rejection.config.url !== this.appConfig.logging.endpoint &&
      (!envService.isApiRequest(rejection.config.url) || noLogForResponseWithStatusCodes.indexOf(rejection.status) < 0);

    if (shouldLogError) {
      this.loggingService.logHttpErrorLegacy(rejection);
    }

    return this.$q.reject(rejection);
  };

  public static factory(): IHttpInterceptorFactory {
    const factory = ($q: IQService, $injector: auto.IInjectorService, appConfig: IAppConfig) => new ResponseErrorLoggerInterceptor($q, $injector, appConfig);
    factory.$inject = ["$q", "$injector", "appConfig"];
    return factory;
  }
}

export class HandleUnauthorizedResponseInterceptor implements IHttpInterceptor {
  constructor(
    private $injector: auto.IInjectorService,
    private $window: IWindowService,
    private $q: IQService
  ) {}

  public responseError = <T>(rejection: IHttpResponse<T>): IPromise<IHttpResponse<T>> | IHttpResponse<T> => {
    if (rejection.status === 401) {
      const authenticationResolverService = this.$injector.get<AuthenticationResolverService>("AuthenticationResolverService");

      if (isMsTeamsApp()) {
        const gtmhubUserId = authenticationResolverService.getAuthUserId();
        const teamsBotDomain = localStorage.getItem("teamsBotDomain") || "msteams-bot.gtmhub.com";
        const url = `https://${teamsBotDomain}/tab/expire-token?gtmhubUserId=${gtmhubUserId}`;

        this.$window.location.href = url;
      } else if (!this.$window.location.hash.includes("account-suspended")) {
        const userProfileService = this.$injector.get<UserProfileService>("UserProfileService");
        authenticationResolverService.navigateToLogin(userProfileService.getProfile());
      }
    }
    return this.$q.reject(rejection);
  };

  public static factory(): IHttpInterceptorFactory {
    const factory = ($injector: auto.IInjectorService, $window: IWindowService, $q: IQService) => new HandleUnauthorizedResponseInterceptor($injector, $window, $q);
    factory.$inject = ["$injector", "$window", "$q"];
    return factory;
  }
}

export class UserDeactivatedInterceptor implements IHttpInterceptor {
  constructor(
    private $injector: auto.IInjectorService,
    private $q: IQService
  ) {}

  public responseError = <T>(rejection: IHttpResponse<ResponseError>): IPromise<IHttpResponse<T>> | IHttpResponse<T> => {
    // We cannot inject $state directly due to circular dependency graph
    const $state = this.$injector.get<StateService>("$state");
    const userDeactivatedStateName = "userDeactivated";

    if (isUserDeactivatedOrDeletedResponse(rejection) && !$state.is(userDeactivatedStateName) && $state.transition?.to().name !== userDeactivatedStateName) {
      const transactionID = rejection.config.headers["transaction-id"];
      $state.go(userDeactivatedStateName, { transactionID }, { reload: true });
    }

    return this.$q.reject(rejection);
  };

  public static factory(): IHttpInterceptorFactory {
    const factory = ($injector: auto.IInjectorService, $q: IQService) => new UserDeactivatedInterceptor($injector, $q);
    factory.$inject = ["$injector", "$q"];
    return factory;
  }
}

export class AccountSuspendedResponseInterceptor implements IHttpInterceptor {
  constructor(
    private $injector: auto.IInjectorService,
    private $q: IQService
  ) {
    noLogForResponseWithStatusCodes.push(402);
  }

  public responseError = <T>(rejection: IHttpResponse<T>): IPromise<IHttpResponse<T>> | IHttpResponse<T> => {
    // We cannot inject $state directly due to circular dependency graph
    const $state = this.$injector.get<StateService>("$state");
    const destinationStateName = "accountSuspended";

    if (rejection.status === 402 && $state.current.name !== destinationStateName) {
      $state.go(destinationStateName, { reload: true });
    }

    return this.$q.reject(rejection);
  };

  public static factory(): IHttpInterceptorFactory {
    const factory = ($injector: auto.IInjectorService, $q: IQService) => new AccountSuspendedResponseInterceptor($injector, $q);
    factory.$inject = ["$injector", "$q"];
    return factory;
  }
}

export interface IRetryRequestConfig extends IRequestConfig {
  retries?: number;
  maxRetries?: number;
}

const DEF_MAX_RETRIES = 1;

export class RetryFailedResponseInterceptor implements IHttpInterceptor {
  public static enabled = true; // unit tests need to be able disable this interceptor

  constructor(
    private $injector: auto.IInjectorService,
    private $q: IQService
  ) {}

  public responseError = <T>(response: IHttpResponse<T>): IPromise<IHttpResponse<T>> | IHttpResponse<T> => {
    const config: IRetryRequestConfig = response.config;

    if (!RetryFailedResponseInterceptor.enabled) {
      return this.$q.reject(response);
    }

    if (response.status === -1 || response.status === 0 || response.status >= 500) {
      /*
        The easiest way to associate data to the $http request without bringing additional complexity
        is by using the config object with which it has been created.
        This way the initiator may even specify maxRetries by explicitly setting it like:
          $http({
            url: '...',
            method: 'GET',
            maxRetries: 3 // <-- if not set and response status is -1, then it is considered DEF_MAX_RETRIES
          });
      */
      if (config.maxRetries === undefined) {
        config.maxRetries = DEF_MAX_RETRIES;
      }
      if (config.retries === undefined) {
        config.retries = 0;
      }

      config.retries += 1;

      if (config.retries <= config.maxRetries) {
        increaseGlobalRetries();
        const $http = this.$injector.get("$http");
        return $http(config);
      }
    }
    return this.$q.reject(response);
  };

  public static factory(): IHttpInterceptorFactory {
    const factory = ($injector: auto.IInjectorService, $q: IQService) => new RetryFailedResponseInterceptor($injector, $q);
    factory.$inject = ["$injector", "$q"];
    return factory;
  }
}
