import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import { InputTextFieldType } from "@webapp/ui/input/input.models";
import { requiredStringValidator } from "../../../utils";
import { FormItemBaseComponent } from "../form-item-base.component";

@Component({
  selector: "text-form-item",
  templateUrl: "./text-form-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FormItemBaseComponent, useExisting: forwardRef(() => TextFormItemComponent) }],
})
export class TextFormItemComponent extends FormItemBaseComponent<string> {
  public readonly requiredValidator = requiredStringValidator;

  @Input() public maxCharsCount?: number;
  @Input() public inputType: InputTextFieldType;
}
