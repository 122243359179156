import { localize } from "@gtmhub/localization";
import { ISessionTag } from "@webapp/custom-fields/models/custom-fields.models";

export enum DynamicFiltersPosition {
  top = "top",
  bottom = "bottom",
}

export interface ISessionOption extends ISessionTag {
  avatar: { color: string } | { picture: string };
}

interface IDynamicFiltersPreset {
  name: string;
  position: DynamicFiltersPosition;
  options: ISessionOption[];
}

class DynamicFiltersPresets {
  private presets: Record<string, IDynamicFiltersPreset> = {};
  getNames(): string[] {
    return Object.keys(this.presets);
  }
  get(name: string): IDynamicFiltersPreset | undefined {
    const preset = this.presets[name];
    if (preset) {
      const localizedOptions = preset.options.map((option) => ({ ...option, name: localize(option.name) }));
      return { ...preset, options: localizedOptions };
    }
  }
  add(preset: IDynamicFiltersPreset): DynamicFiltersPresets {
    this.presets[preset.name] = preset;
    return this;
  }
}

export enum DynamicFilterType {
  activeSessions = "activeSessions",
  pastSessions = "pastSessions",
  upcomingSessions = "upcomingSessions",
  openSessions = "openSessions",
  inProgressSessions = "inProgressSessions",
  notSetSessions = "notSetSessions",
}

export const dynamicFilterTypeSet: Set<string> = new Set(Object.values(DynamicFilterType));

/**
 * Localization keys for the dynamic filter types.
 */
export const dynamicFilterTypeNames: Record<DynamicFilterType, string> = {
  [DynamicFilterType.activeSessions]: "active_session_short",
  [DynamicFilterType.pastSessions]: "past_session_short",
  [DynamicFilterType.upcomingSessions]: "upcoming_session_short",
  [DynamicFilterType.openSessions]: "open_session_short",
  [DynamicFilterType.inProgressSessions]: "in_progress_session_short",
  [DynamicFilterType.notSetSessions]: "not_set_session_short",
};

export const dynamicFilterSessionTags = Object.keys(dynamicFilterTypeNames).map(
  (key: DynamicFilterType): ISessionOption => ({
    id: key,
    name: dynamicFilterTypeNames[key],
    avatar: { picture: "" },
  })
);

export const dynamicFiltersPresets = new DynamicFiltersPresets()
  .add({
    name: "active",
    position: DynamicFiltersPosition.bottom,
    options: [
      {
        id: DynamicFilterType.activeSessions,
        name: "active_sessions",
        avatar: { picture: require("wwwroot/img/icons/session.svg") },
      },
    ],
  })
  .add({
    name: "all",
    position: DynamicFiltersPosition.top,
    options: dynamicFilterSessionTags,
  });
