import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "bold",
  standalone: true,
})
export class BoldPipe implements PipeTransform {
  public transform(textValue: string, subTextValue: string): string {
    if (!subTextValue) {
      return textValue;
    }
    return textValue.replace(new RegExp(`(${subTextValue})`, "gi"), "<b>$1</b>");
  }
}
