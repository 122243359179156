import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "shortNumber",
})
export class ShortNumberPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}
  public transform(number: number): string {
    if (isNaN(number) || number === null) {
      return null;
    }

    const lookup = [
      { lowerLimit: 1e13, divider: 1e12, symbol: "t" },
      { lowerLimit: 1e10, divider: 1e9, symbol: "b" },
      { lowerLimit: 1e7, divider: 1e6, symbol: "m" },
      { lowerLimit: 1e4, divider: 1e3, symbol: "k" },
      { lowerLimit: 0, divider: 1, symbol: "" },
    ];

    const abs = Math.abs(number);
    const isNegative = number < 0;
    const item = lookup.find((item) => abs >= item.lowerLimit);

    return (isNegative ? "-" : "") + Math.trunc(abs / item.divider).toLocaleString(this.locale) + item.symbol;
  }
}
