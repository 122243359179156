import { LowToHighScaleType } from "@webapp/platform-intelligence/expandable-suggestions/shared/models/quantive-plus-effectiveness.models";
import { effectivenessScoreToLowToHighScale } from "@webapp/platform-intelligence/expandable-suggestions/shared/utils/quantive-plus-effectiveness-adapter.utils";

export interface EffectivenessScoreToLowToHighScaleFilter {
  (effectivenessScore: number): LowToHighScaleType;
}

export function EffectivenessScoreToLowToHighScaleFilter(): EffectivenessScoreToLowToHighScaleFilter {
  return function (effectivenessScore: number): LowToHighScaleType {
    return effectivenessScoreToLowToHighScale(effectivenessScore);
  };
}
