import { Component } from "@angular/core";
import { NavItemsSectionBroadcastConfig } from "@webapp/navigation/models/nav-items-list.models";
import { SubNavOption } from "@webapp/navigation/models/sub-nav-options.models";
import NavigationItemsMediator from "@webapp/navigation/services/uxcustomization/navigation-items.mediator.service";
import { BaseSubNav } from "../services/base-sub-nav";
import { NavItemsSectionBroadcastConfigFactory } from "../services/nav-items-section-broadcast-config.factory";

@Component({
  selector: "kpis-sub-navigation",
  templateUrl: "./kpis-sub-navigation.component.html",
})
export class KpisSubNavigationComponent extends BaseSubNav {
  public options: SubNavOption[] = [{ label: "all_kpis", state: "gtmhub.kpis" }];
  public broadcastConfig: NavItemsSectionBroadcastConfig = this.broadcastConfigFactory.buildKpiNavItemsSectionBroadcastConfig();

  public constructor(
    protected navigationItemsMediator: NavigationItemsMediator,
    private broadcastConfigFactory: NavItemsSectionBroadcastConfigFactory
  ) {
    super();
    this.setupEntityTypes(["kpigroup"], "kpis");
  }
}
