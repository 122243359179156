<ui-select
  [(ngModel)]="model"
  [(uiOpen)]="isDropDownOpen"
  [uiId]="uiId"
  [uiCustomTemplate]="buttonCustomTemplate"
  [uiPlaceHolderTemplate]="buttonCustomTemplate"
  [uiShowArrow]="false"
  [a11yLabel]="a11yLabel"
  [uiSelectSearchInputTabIndex]="-1"
  [uiDisabled]="disabled"
  [contentOnly]="inline"
  [visibleTemplateOverflow]="true"
  [uiDropdownStyle]="dropdownStyle"
  [uiDropdownMatchSelectWidth]="dropdownMatchSelectWidth"
  [renderSelectedListItemIcon]="hasOptionCheckIcon"
  (ngModelChange)="handleChange($event)"
  uiBorderless
  uiDropdownClassName="button-select-container">
  <ui-option *ngFor="let option of options" [uiValue]="option.value" [uiLabel]="option.label | localize" uiCustomContent>
    <div class="button-select-option">
      <i class="option-icon" *ngIf="option.uiIconTheme && option.uiIconType" [uiTheme]="option.uiIconTheme" [uiType]="option.uiIconType" ui-icon></i>
      <div class="text" data-test-id="button-text">{{ option.label | localize }}</div>
    </div>
  </ui-option>
</ui-select>

<ng-template #buttonCustomTemplate>
  <button
    *ngIf="!iconButton"
    [disabled]="disabled"
    [tabindex]="buttonTabindex"
    [uiSpecialType]="enrichedSelected?.uiSpecialType || 'gray'"
    [attr.aria-label]="a11yLabel"
    (keydown.enter)="openDropDown()"
    (keydown.space)="openDropDown()"
    ui-button
    aria-haspopup="listbox"
    uiSize="small"
    uiShape="round">
    <span class="button-options selected">
      <i class="option-icon" *ngIf="enrichedSelected" [uiTheme]="enrichedSelected.uiIconTheme" [uiType]="enrichedSelected.uiIconType" uiSize="xs" ui-icon></i>

      <span class="text" data-test-id="button-selected-text">{{ selected | localize }}</span>
    </span>
    <i class="sp-ml-8" uiSize="xs" ui-icon uiType="chevron-down-small"></i>
  </button>
  <button
    *ngIf="iconButton"
    [attr.aria-label]="a11yLabel"
    (keydown.enter)="openDropDown()"
    (keydown.space)="openDropDown()"
    ui-button
    uiShape="circle"
    uiType="default"
    uiSize="default"
    aria-haspopup="listbox">
    <i [uiType]="iconButtonConfig.uiType" [uiTheme]="iconButtonConfig.uiTheme" ui-icon></i>
  </button>
</ng-template>
