<div [class.has-insight]="data?.insightName">
  <suggested-automation
    *ngIf="showSuggestions && !data?.insightName"
    [suggestedInsights]="suggestedInsights"
    (selectSuggestedInsight)="selectSuggestedInsight($event)"
    (selectSuggestedTemplate)="selectSuggestedTemplate($event)"
    (openBrowseConnectionsModal)="openBrowseConnectionsModal()"></suggested-automation>

  <selected-automation
    *ngIf="data?.insightName && hasPermissionToSeeAutomation"
    [data]="data"
    [confirmedInsight]="confirmedInsight"
    [readonly]="readonly"
    [isEditable]="isEditable"
    [disabledStyle]="disabledStyle"
    [dataSourceType]="dataSourceType"
    [indicators]="indicators"
    (editFilter)="editInsightFilters()"
    (removeItem)="removeItemAutomation()"
    (goToInsight)="goToInsight()"
    (openInsight)="openInsight()"
    (openPowerBIModal)="openEditPowerBIConfiguration()"></selected-automation>

  <selected-automation
    *ngIf="kpiData && hasPermissionToSeeAutomation"
    [kpiData]="kpiData"
    [readonly]="readonly"
    [isEditable]="isEditable"
    [disabledStyle]="disabledStyle"
    [dataSourceType]="dataSourceType"
    [indicators]="indicators"
    (editFilter)="editInsightFilters()"
    (removeItem)="removeItemAutomation()"
    (goToInsight)="goToInsight()"
    (openInsight)="openInsight()"
    (openPowerBIModal)="openEditPowerBIConfiguration()"></selected-automation>

  <ng-container *ngIf="!showSuggestions && !hasPermissionToSeeAutomation && isAutomated; else canAutomate">
    <selected-automation [disabledStyle]="true" [readonly]="readonly" [isEditable]="isEditable" [isRestricted]="true"></selected-automation>
  </ng-container>

  <ng-template #canAutomate>
    <basic-automation
      *ngIf="!showSuggestions && !data?.insightName && !kpiData"
      [heading]="basicAutomationLocalisationKeys?.heading | localize"
      [description]="basicAutomationLocalisationKeys?.description | localize"
      [loading]="!!indicators.loadingSuggestions"
      (automate)="onAutomate()"></basic-automation>
  </ng-template>

  <ui-validation-hint class="sp-mt-8" *ngIf="showDataSourceAccessAlert && hasPermissionToSeeAutomation" [hintContent]="dataSourceAccessAlert" [uiHideIcon]="true"></ui-validation-hint>

  <ng-template #dataSourceAccessAlert>
    <ui-alert [uiDescription]="'you_cant_modify_this_insight' | localize"></ui-alert>
  </ng-template>
</div>
