import { IHttpProvider, IPromise, module } from "angular";
import { TransitionService } from "@uirouter/angular";
import uirouter, { Transition, UIRouterGlobals } from "@uirouter/angularjs";
import { $intervalPatch } from "./zone-patches/interval-patch";
import { $qPatch } from "./zone-patches/q-patch";
import { $timeoutPatch } from "./zone-patches/timeout-patch";
import { INBROWSER_TRACING_STATS } from "@gtmhub/build";
import { EnvironmentService, IAppConfig } from "@gtmhub/env";
import env from "@gtmhub/env/env.module";
import uiBootstrap from "@gtmhub/ui-bootstrap.module";
import { TracingService as Ng2TracingService } from "@webapp/core/tracing/services/tracing.service";
import upgrade from "../upgrade/module";
import { ApmSpanService } from "./apm-span.service";
import { IApmService } from "./apm.models";
import { ApmService } from "./apm.service";
import { InBrowserStatsInterceptor } from "./in-browser-stats.interceptor";
import { ITraceRootScopeService } from "./models";
import { NullApmService } from "./null-apm.service";
import { RenderTransactionDirective } from "./render-transaction.directive";
import { TraceRenderIdCollector } from "./trace-render-id-collector.directive";
import { TraceRenderId } from "./trace-render-id.directive";
import { TracingInterceptor } from "./tracing.interceptor";
import { TracingService } from "./tracing.service";
import { Ng1Zone, Ng1ZoneSpec } from "./zone";

const mod = module("tracing", [uirouter, uiBootstrap, env, upgrade]);
mod.directive("ghRenderTransaction", RenderTransactionDirective.factory());
mod.directive("ghTraceRenderId", TraceRenderId.factory());
mod.directive("ghTraceRenderIdCollector", TraceRenderIdCollector.factory());
mod.service("TracingService", TracingService);
mod.downgradeInjectable("Ng2TracingService", Ng2TracingService);

mod.service("ApmService", [
  "appConfig",
  "EnvironmentService",
  "$uiRouterGlobals",
  function (appConfig: IAppConfig, env: EnvironmentService, $uiRouterGlobals: UIRouterGlobals): IApmService {
    return appConfig.rum.enabled ? new ApmService(appConfig, env, $uiRouterGlobals) : new NullApmService();
  },
]);
mod.service("ApmSpanService", ApmSpanService);

$qPatch(mod);
$timeoutPatch(mod);
$intervalPatch(mod);

mod.config([
  "$httpProvider",
  ($httpProvider: IHttpProvider) => {
    $httpProvider.interceptors.push(TracingInterceptor.factory());

    if (INBROWSER_TRACING_STATS) {
      $httpProvider.interceptors.push(InBrowserStatsInterceptor.factory());
    }
  },
]);

mod.run([
  "TracingService",
  (tracingService: TracingService) => {
    tracingService.initSessionID();
  },
]);

mod.run([
  "$rootScope",
  "$transitions",
  "TracingService",
  "Ng2TracingService",
  "ApmService",
  ($rootScope: ITraceRootScopeService, $transitions: TransitionService, tracingService: TracingService, ng2TracingService: Ng2TracingService, apmService: ApmService) => {
    let initZone: Ng1Zone = null;
    let stateZone: Ng1Zone = null;

    const createTracingZoneSpec = (actionName: string): Ng1ZoneSpec => ({
      name: actionName,
      properties: {
        screen: tracingService.getScreen(),
        action: tracingService.createAction(actionName),
      },
    });

    $transitions.onStart({}, (transition: Transition) => {
      apmService.handleTransitionStart(transition.targetState());
    });

    $rootScope.traceInit = <TPromise>(fn: () => IPromise<TPromise> | undefined): IPromise<TPromise> | undefined => {
      if (!initZone) {
        return fn.apply($rootScope);
      }

      const promise = initZone.run<IPromise<TPromise>>(fn, $rootScope);

      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      if (promise) {
        const initPromises = stateZone.get<IPromise<unknown>[]>("initPromises");
        initPromises.push(promise);
      }

      return promise;
    };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    $rootScope.traceAction = <TResult>(name: string, fn: Function, parentZone?: Ng1Zone): TResult => {
      if (!parentZone) {
        parentZone = stateZone || Ng1Zone.root;
      }

      const zone = parentZone.fork(createTracingZoneSpec(name));
      return zone.run(fn, $rootScope);
    };

    // create init zone on beginning of each transaction, so that all
    // subsequent resources are loaded within that zone
    $transitions.onBefore({}, (trans) => {
      const state = trans.to();
      const stateName = state.name;
      const stateTrackingName = state.data?.trackingPage || null;

      tracingService.initScreen(stateName, stateTrackingName);
      ng2TracingService.initScreen(stateName, stateTrackingName);

      stateZone = Ng1Zone.root.fork({
        name: stateName,
        properties: {
          // here we will collect additional init promises from child controllers / directives
          initPromises: [],
        },
      });

      initZone = stateZone.fork(createTracingZoneSpec("init"));
    });
  },
]);

export default mod.name;
