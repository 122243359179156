import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UiTSType } from "@quantive/ui-kit/core";
import { FormTooltipIcon } from "../../models/form.models";

@Component({
  selector: "form-tooltip-icon",
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormTooltipIconComponent implements FormTooltipIcon {
  @Input() public content: UiTSType;
  @Input() public iconType = "help";
  @Input() public position: "start" | "end" = "start";
}
