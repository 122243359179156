import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Cache } from "@webapp/core/state-management/models/cache.model";
import { SuggestedModel } from "./suggested.models";

@Injectable({ providedIn: "root" })
export class SuggestedCache implements Cache<SuggestedModel> {
  private cache$ = new BehaviorSubject(undefined);

  public get$(): Observable<SuggestedModel | undefined> {
    return this.cache$.asObservable();
  }

  public set(value: SuggestedModel): void {
    this.cache$.next(value);
  }
}
