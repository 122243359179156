import { EventEmitter } from "@angular/core";
import { PopupPickerController } from "@picmo/popup-picker";
import { EmojiSelection } from "picmo";
import { Observable, Subject } from "rxjs";
import { ReactionType } from "@webapp/comments";
import { appendPicmoStylesIfMissing, defaultPickerOptions, defaultPopupOptions, removePicmoStylesIfPresent, stopPropagationOnEscapeKeydown } from "../libs/picmo/utils";

export class EmojiPickerController {
  public readonly pickerOpen = new EventEmitter<MouseEvent>();
  public readonly pickerClose = new EventEmitter<MouseEvent>();
  private picmoPickerLoaded$ = new Subject<void>();
  private reactionSelected$ = new Subject<ReactionType>();
  private picmoPickerPopup: PopupPickerController;
  private loadingEmojiPicker = false;
  private picmoStyles: string;

  get isLoading(): boolean {
    return this.loadingEmojiPicker;
  }

  get isOpen(): boolean {
    return this.picmoPickerPopup?.isOpen;
  }

  constructor(private options: { referenceElement(): HTMLElement; triggerElement(): HTMLElement }) {}

  public destroy(): void {
    removePicmoStylesIfPresent();
    this.picmoPickerLoaded$.complete();
    this.reactionSelected$.complete();

    if (this.picmoPickerPopup) {
      this.picmoPickerPopup.destroy();
    }
    this.picmoPickerPopup = null;
  }

  public toggle(): void {
    if (!this.picmoPickerPopup && !this.loadingEmojiPicker) {
      this.createEmojiPickerPopup().then(() => this.toggleEmojiPickerPopup());
    } else if (this.picmoPickerPopup) {
      this.toggleEmojiPickerPopup();
    }
  }

  public get selectedReaction$(): Observable<ReactionType> {
    return this.reactionSelected$.asObservable();
  }

  public get pickerLoaded$(): Observable<void> {
    return this.picmoPickerLoaded$.asObservable();
  }

  private async createEmojiPickerPopup(): Promise<void> {
    this.loadingEmojiPicker = true;
    try {
      // Load picmo dynamically as a separate bundle
      const { createPopup, emojiData, messages, styles } = await import(/* webpackChunkName: "picmo" */ "@webapp/shared/libs/picmo");

      this.picmoStyles = styles;
      this.picmoPickerPopup = createPopup(
        // there are incompatible values in the MessagesDataset -> SubgroupMessage -> SubgroupKey - one of the type declarations is missing "heart"
        // updating the 'picmo' and 'emojibase-data' libraries at some point would most probably fix the typing issue
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        { ...defaultPickerOptions, emojiData, messages: messages as any },
        { ...defaultPopupOptions, triggerElement: this.options.triggerElement(), referenceElement: this.options.referenceElement() }
      );

      this.picmoPickerPopup.addEventListener("emoji:select", (emojiSelection: EmojiSelection) => {
        this.reactionSelected$.next({
          name: emojiSelection.label.replace(/\s+/g, "-").toLowerCase(),
          emoji: emojiSelection.emoji,
        });
      });
      this.picmoPickerPopup.addEventListener("picker:open", () => {
        // in case the styles were removed while opening the popup (e.g. another popup destroyed/closed) we append them again
        appendPicmoStylesIfMissing(styles);
        stopPropagationOnEscapeKeydown();
        this.pickerOpen.emit();
      });
      this.picmoPickerPopup.addEventListener("picker:close", () => {
        removePicmoStylesIfPresent();
        this.pickerClose.emit();
      });
    } catch (err) {
      console.error("Error loading picmo library", err);
    } finally {
      this.loadingEmojiPicker = false;
      this.picmoPickerLoaded$.next();
    }
  }

  private toggleEmojiPickerPopup(): void {
    // append the styles before opening the popup so the rules will kick in immediately after open
    if (!this.picmoPickerPopup.isOpen) {
      appendPicmoStylesIfMissing(this.picmoStyles);
    }
    this.picmoPickerPopup.toggle().then(() => this.picmoPickerPopup.isOpen && this.picmoPickerPopup.picker.setInitialFocus());
  }
}
