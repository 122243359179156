import { HierarchyNode, stratify } from "d3-hierarchy";
import { byEndDescAndStartAsc } from "@gtmhub/shared/components/session-tree-selector/utils";
import { toIdMap } from "@gtmhub/util";
import { Session } from "@webapp/sessions/models/sessions.model";
import dayjs from "@webapp/shared/libs/dayjs";

type RestLayerLogicalOperator = "$or" | "$and";
type RestLayerComparisonOperator = "$in" | "$nin" | "$lt" | "$lte" | "$gt" | "$gte";

export interface IPropFilterConfig {
  prop: string;
  exists: boolean;
  logicalOperator: RestLayerLogicalOperator;
  comparisonOperator: RestLayerComparisonOperator;
  values: string[];
}

export type IFilterObject = {
  [key in RestLayerLogicalOperator]?: [
    {
      [x: string]: {
        [key in RestLayerComparisonOperator]?: string[];
      };
    },
    {
      [x: string]: {
        $exists: boolean;
      };
    },
  ];
};

// the method respects the permissions between linked sessions, if a child has been aligned under a parent
// having access only to the child one will make it appear on a "root" level, otherwise the stratify fails
export const createSessionTree = (sessions: Session[]): HierarchyNode<Session> => {
  const fakeSessionRoot = { id: "root" };
  const sessionsMap = toIdMap(sessions);

  const buildTree = stratify<Session>()
    .id((d) => d.id)
    .parentId((d) => {
      if (d.id === fakeSessionRoot.id) {
        return null;
      }

      return !d.parentId || !sessionsMap[d.parentId] ? fakeSessionRoot.id : d.parentId;
    });

  return buildTree([...sessions, fakeSessionRoot as Session]);
};

export function addChildSessionsToSession(sessions: Session[]): Session[] {
  for (const session of sessions) {
    session.children = getSessionChildrenByEndDescAndStartAsc(session, sessions);
  }

  return sessions;
}

function getSessionChildrenByEndDescAndStartAsc(currSession: Session, sessions: Session[]): Session[] {
  const children: Session[] = [];

  const childrenIds = sessions
    .filter((s) => s.parentId === currSession.id)
    .sort(byEndDescAndStartAsc)
    .map((s) => s.id);

  childrenIds.forEach((childId) => {
    children.push(sessions.find((session) => session.id === childId));
  });

  return children;
}

export const bySessionEndDateDesc = (a: HierarchyNode<Session>, b: HierarchyNode<Session>): 0 | 1 | -1 => {
  const aEndDate = dayjs(a.data.end);
  const bEndDate = dayjs(b.data.end);

  if (aEndDate.isAfter(bEndDate)) {
    return 1;
  } else if (aEndDate.isBefore(bEndDate)) {
    return -1;
  }
  return 0;
};

export const calculateSessionsTimeframe = (sessions: Session[]): { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs; timeframe: number } => {
  if (!sessions.length) {
    return {
      startDate: null,
      endDate: null,
      timeframe: 0,
    };
  }

  let startDate = dayjs(sessions[0].start);
  let endDate = dayjs(sessions[0].end);

  for (let i = 0; i < sessions.length; i++) {
    if (startDate.diff(sessions[i].start, "days") > 0) {
      startDate = dayjs(sessions[i].start);
    }

    if (endDate.diff(sessions[i].end, "days") < 1) {
      endDate = dayjs(sessions[i].end);
    }
  }

  startDate = startDate.startOf("year");
  endDate = endDate.endOf("year");

  return {
    startDate,
    endDate,
    timeframe: endDate.diff(startDate, "days"),
  };
};

export const generateTimePeriods = (startDateOfTimeframe: dayjs.Dayjs, endDateOfTimeframe: dayjs.Dayjs) => {
  const lines: Record<string, string[]> = {};
  for (let i = startDateOfTimeframe; i <= endDateOfTimeframe; i = i.add(1, "month")) {
    const year = i.format("YYYY");
    const month = i.format("MMM");
    if (!lines[year]) {
      lines[year] = [month];
    } else {
      lines[year].push(month);
    }
  }
  return lines;
};

export const getPropertyFilter = (propFilterConfig: IPropFilterConfig): IFilterObject => {
  const { prop, exists, logicalOperator, comparisonOperator, values } = propFilterConfig;

  return {
    [logicalOperator]: [
      {
        [prop]: {
          [comparisonOperator]: values,
        },
      },
      {
        [prop]: {
          $exists: exists,
        },
      },
    ],
  };
};
