import { IHttpService, IPromise } from "angular";
import { EnvironmentService } from "@gtmhub/env";
import { IBetaFeatureOptRequest } from "./models";

export class BetaFeaturesService {
  public static $inject = ["$http", "EnvironmentService"];

  constructor(
    private $http: IHttpService,
    private env: EnvironmentService
  ) {}

  public optForBetaFeature({ key, enabled }: { key: string; enabled: boolean }): IPromise<void> {
    const url = this.env.getApiEndpoint(`/feature-toggler/user/beta-features`);

    const request: IBetaFeatureOptRequest = {
      featureFlagKey: key,
      enabled: enabled,
    };

    return this.$http.post<void>(url, request).then((response) => response.data);
  }

  public getCurrentUserBetaFeatures(): IPromise<unknown> {
    const url = this.env.getApiEndpoint(`/feature-toggler/user/beta-features`);

    return this.$http.get<void>(url).then((response) => response.data);
  }
}
