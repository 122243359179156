import { Injectable } from "@angular/core";
import { Observable, catchError, map, switchMap } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { FileserverFacade } from "@webapp/fileserver/services/fileserver-facade.service";
import { Whiteboard, WhiteboardDto, WhiteboardTemplatePayload } from "@webapp/whiteboards/models/whiteboard.models";
import { WhiteboardsState } from "@webapp/whiteboards/services/whiteboards-state.service";
import { WhiteboardsApiService } from "./whiteboards-api.service";

@Injectable({
  providedIn: "any",
})
export class WhiteboardsFacade extends BaseFacade<Whiteboard, WhiteboardDto, WhiteboardsState, WhiteboardsApiService> {
  constructor(
    state: WhiteboardsState,
    api: WhiteboardsApiService,
    private fileFacade: FileserverFacade
  ) {
    super(state, api);
  }

  public createWhiteboard(payload, config?: RequestConfig): Observable<Whiteboard> {
    return this.post$(payload, {
      url: this.apiService.postWhiteboardEndpoint(),
      ...config,
    });
  }

  public createWhiteboardTemplate$(whiteboardId: string, payload: WhiteboardTemplatePayload): Observable<Whiteboard> {
    return this.post$(payload, {
      ...new RequestConfig(),
      url: this.apiService.whiteboardTemplateEndpoint(whiteboardId),
      queryParams: {
        saveAsTemplate: true,
      },
    });
  }

  public createWhiteboardFromTemplate$(whiteboardId: string, config?: RequestConfig): Observable<Whiteboard> {
    return this.post$(
      {},
      {
        ...config,
        url: this.apiService.whiteboardTemplateEndpoint(whiteboardId),
        queryParams: {
          saveAsTemplate: false,
        },
      }
    );
  }

  public updatePicture$(whiteboard: Whiteboard, canvasDataUrl: string): Observable<unknown> {
    const file = this.dataUrlToFile(canvasDataUrl, `${whiteboard.id}.png`);

    const upload = new FormData();
    upload.append("file", file);
    upload.append("targetId", whiteboard.id);
    upload.append("targetType", "whiteboard");
    upload.append("ref", "attachment");

    return this.fileFacade.uploadFile$(upload).pipe(
      switchMap((uploadedFile) => {
        const picture = uploadedFile.previewUrl || uploadedFile.url;
        const params: RequestConfig = {
          queryParams: {
            fields: "picture",
          },
        };

        return this.patch$(whiteboard.id, { picture }, params).pipe(map(() => this.replacePicture(whiteboard, picture)));
      })
    );
  }

  private replacePicture(whiteboard: Whiteboard, picture: string): void {
    const previousPicture = whiteboard.picture;
    whiteboard.picture = picture;

    if (previousPicture) {
      this.tryDeletePreviousPicture(whiteboard.id, previousPicture);
    }
  }

  private tryDeletePreviousPicture(whiteboardId: string, picture: string): void {
    this.fileFacade.delete$(picture).pipe(
      catchError((error) => {
        if (error.status !== 404) {
          console.warn("Error while deleting previous whiteboard picture", whiteboardId, picture, error);
        }

        return [];
      })
    );
  }

  private dataUrlToFile(dataUrl: string, fileName: string): File {
    const dataUriParts = dataUrl.split(",");
    const byteString = window.atob(dataUriParts[1]);
    const mimeString = dataUriParts[0].split(":")[1].split(";")[0];

    const byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([byteArray], { type: mimeString });
    return new File([blob], fileName, { type: mimeString });
  }
}
