import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "suggestion-drawer-heading[heading]",
  templateUrl: "./heading.component.html",
  styleUrls: ["./heading.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionDrawerHeadingComponent {
  @Input() public heading: string;
}
