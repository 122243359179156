import { IAttributes, IAugmentedJQuery, IDirective, IDirectiveFactory, IScope } from "angular";

export class KeydownDirective implements IDirective {
  public restrict = "A";

  constructor(
    private directiveName: string,
    private keyCode: number
  ) {}

  public link(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes): void {
    element.on("keydown", (evt: JQueryKeyEventObject) => {
      if (evt.keyCode === this.keyCode) {
        scope.$eval(attrs[this.directiveName]);
        scope.$apply();

        evt.preventDefault();
        return false;
      }
    });
  }

  public static factoryEsc(): IDirectiveFactory {
    const directive = () => new KeydownDirective("esc", 27);
    directive.$inject = [];
    return directive;
  }

  public static factoryEnter(): IDirectiveFactory {
    const directive = () => new KeydownDirective("enter", 13);
    directive.$inject = [];
    return directive;
  }
  public static factoryTab(): IDirectiveFactory {
    const directive = () => new KeydownDirective("tabKey", 9);
    directive.$inject = [];
    return directive;
  }
}

/**
 * Submits a form by pressing cmd/ctrl + Enter on an input
 *
 * @example
 *
 * 1) Pass a callback to the directive
 * <input cmd-enter-submit="doSomething()" />
 *
 * 2) Use ng-submit on the parent form
 * <form ng-submit="doSomething()">
 *   <input cmd-enter-submit />
 * </form>
 */
export class CmdEnterSubmitDirective implements IDirective {
  public restrict = "A";

  public link(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes): void {
    element.on("keydown", (evt: JQueryKeyEventObject) => {
      const isEnter = evt.keyCode === 13;
      if ((evt.ctrlKey || evt.metaKey) && isEnter) {
        const directiveName = "cmd-enter-submit";
        if (attrs[directiveName]) {
          scope.$eval(attrs[directiveName]);
        } else {
          // try to find a parent form
          const form = element.closest("form");
          if (form.length && form.attr("ng-submit")) {
            scope.$eval(form.attr("ng-submit"));
          } else {
            throw new Error("CmdEnterSubmit directive requires either a callback to be passed as an attribute or a HTML form with ng-submit as a parent");
          }
        }

        scope.$apply();

        evt.preventDefault();
        return false;
      }
    });
  }

  public static factory(): IDirectiveFactory {
    const directive = () => new CmdEnterSubmitDirective();
    directive.$inject = [];
    return directive;
  }
}
