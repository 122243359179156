export function GhLimitToFilter() {
  return (input: unknown, limit: number) => {
    if (input && Array.isArray(input)) {
      return limitArraySize(input, limit);
    }

    if (input && typeof input === "object" && input !== null) {
      return limitObjectSize(input, limit);
    }
  };
}

function limitObjectSize(object: unknown, limit: number): Record<string, unknown> {
  const keys = Object.keys(object);
  if (keys.length < 1) {
    return {};
  }

  const slicedObject = {};
  const slicedKeys = limit < 0 ? keys.slice(limit) : keys.slice(0, limit);
  slicedKeys.forEach((key) => {
    const value = object[key];

    slicedObject[key] = value;
  });
  return slicedObject;
}

function limitArraySize(array: unknown[], limit: number): unknown[] {
  if (array && array.length < 1) {
    return [];
  }

  const slicedObject = [];

  if (limit < 0) {
    array.slice(limit).forEach((item) => {
      slicedObject.push(item);
    });
    return slicedObject;
  }

  array.slice(0, limit).forEach((item) => {
    slicedObject.push(item);
  });
  return slicedObject;
}
