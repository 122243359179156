<div ui-row uiJustify="start" uiAlign="middle">
  <button
    class="suggestion-card-feedback"
    [uiTooltipTitle]="'share_positive_feedback' | localize"
    [uiTooltipOverlayStyle]="actionBtnBottomRightTooltipCustomStyle"
    (click)="shareFeedback({ event: $event, feedbackType: 'positive' })"
    ui-button
    ui-tooltip
    uiTooltipPlacement="bottomRight">
    <i class="fs-16px text-color-gray-1" ui-icon uiTheme="fill" uiType="thumb-up"></i>
  </button>

  <button
    class="sp-ml-4 suggestion-card-feedback"
    [uiTooltipTitle]="'share_feedback' | localize"
    [uiTooltipOverlayStyle]="actionBtnBottomRightTooltipCustomStyle"
    (click)="shareFeedback({ event: $event, feedbackType: 'negative' })"
    ui-button
    ui-tooltip
    uiTooltipPlacement="bottomRight">
    <i class="fs-16px text-color-gray-1" ui-icon uiTheme="fill" uiType="thumb-down"></i>
  </button>
</div>
