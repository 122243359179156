import { StateService, UIRouterGlobals } from "@uirouter/angular";
import { AsyncPipe, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { combineLatest, tap } from "rxjs";
import { FeatureTogglesModule } from "@webapp/feature-toggles/feature-toggles.module";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { SearchFacetMenuComponent } from "@webapp/search/components/search-facet-menu/search-facet-menu.component";
import { SearchResultsComponent } from "@webapp/search/components/search-results/search-results.component";
import { GlobalSearchResult, ITEMS_LIMIT_SEARCH_PAGE, SearchFacetsOptions, SearchFacetsOptionsEnum } from "@webapp/search/models/search.models";
import { GlobalSearchMediatorService } from "@webapp/search/services/global-search.mediator.service";
import { ModelChangeDebounceDirective } from "@webapp/shared/directives/model-change-debounce/model-change-debounce.directive";
import { UiDividerComponent } from "@webapp/ui/divider/divider.component";
import { UiColDirective } from "@webapp/ui/grid/col.directive";
import { UiRowDirective } from "@webapp/ui/grid/row.directive";
import { UiInputDirective } from "@webapp/ui/input/input.directive";
import { UiPaginationComponent } from "@webapp/ui/pagination/pagination.component";
import { UiPopoverDirective } from "@webapp/ui/popover/popover.directive";

@UntilDestroy()
@Component({
  selector: "search-page",
  templateUrl: "./search-page.component.html",
  styleUrls: ["./search-page.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    LocalizationModule,
    SearchFacetMenuComponent,
    UiDividerComponent,
    UiIconModule,
    UiInputDirective,
    UiPopoverDirective,
    SearchResultsComponent,
    FeatureTogglesModule,
    UiPaginationComponent,
    UiRowDirective,
    UiColDirective,
    AsyncPipe,
    NgIf,
    ModelChangeDebounceDirective,
  ],
})
export class SearchPageComponent implements OnInit, OnDestroy {
  protected searchTerm: string;
  protected searchResults: GlobalSearchResult[];
  protected readonly facetsToHide: SearchFacetsOptions[] = [SearchFacetsOptionsEnum.Roles];
  protected readonly itemsLimit = ITEMS_LIMIT_SEARCH_PAGE;
  protected pageIndex: number;
  protected hidePagination = false;
  private currentFacet: SearchFacetsOptions; // Used to keep track of the current facet to show/hide pagination

  constructor(
    private globalSearchMediator: GlobalSearchMediatorService,
    private routerGlobals: UIRouterGlobals,
    private stateService: StateService
  ) {
    this.searchTerm = this.routerGlobals.params.searchTerm;
    this.currentFacet = this.routerGlobals.params.facet || SearchFacetsOptionsEnum.OKRs;
    this.pageIndex = this.routerGlobals.params.page || 1;
  }

  public ngOnInit(): void {
    this.globalSearchMediator.setStaticItemsLimit(ITEMS_LIMIT_SEARCH_PAGE);
    this.globalSearchMediator.setCurrentPageInfo({
      pageNumber: this.pageIndex,
      selectedFacet: this.currentFacet,
    });

    // On page change, update the URL and the query params for the api call with the new page number
    // to get the new set of results corresponding to the new page
    // The below is triggered when the pagination is changed or the facet is changed from the search-facet-menu
    const currentPage$ = this.globalSearchMediator.currentPageInfo$.pipe(
      tap((info) => {
        const { pageNumber, selectedFacet } = info;
        const doLoad = selectedFacet === this.currentFacet && this.pageIndex !== pageNumber;

        this.globalSearchMediator.setQueryParams({ take: this.itemsLimit, skip: this.calculateSkip(pageNumber) }, { load: doLoad });
        this.pageIndex = pageNumber;
      })
    );

    const currentFacet$ = this.globalSearchMediator.currentFacet$.pipe(
      tap((facet) => {
        this.currentFacet = facet;
        this.handlePaginationVisibility();
      })
    );

    this.globalSearchMediator.setCurrentFacet(this.currentFacet, { skipLoading: true });

    // Once the current page and the current facet are set, update the URL with the new page number and facet
    combineLatest([currentPage$, currentFacet$])
      .pipe(untilDestroyed(this))
      .subscribe(([{ pageNumber }, facet]) => {
        this.stateService.go(".", { facet, page: pageNumber });
      });

    this.globalSearchMediator.searchResults$.pipe(untilDestroyed(this)).subscribe((searchResults) => {
      this.searchResults = searchResults;
    });

    this.onSearchTermChange();
  }

  public ngOnDestroy(): void {
    this.globalSearchMediator.setQueryParams({ take: this.itemsLimit, skip: 0 }, { load: false });
    this.globalSearchMediator.setSearchTerm(null);
  }

  public onSearchTermChange(): void {
    this.handlePaginationVisibility();
    this.globalSearchMediator.setSearchTerm(this.searchTerm);
    this.stateService.go(".", { searchTerm: this.searchTerm });
  }

  public pageChange(pageIndex: number): void {
    this.globalSearchMediator.setCurrentPageInfo({
      pageNumber: pageIndex,
      selectedFacet: this.currentFacet,
    });
  }

  private calculateSkip(pageIndex: number): number {
    return (pageIndex - 1) * this.itemsLimit;
  }

  private handlePaginationVisibility(): void {
    this.hidePagination = this.currentFacet === SearchFacetsOptionsEnum.Everywhere || !this.searchTerm;
  }
}
