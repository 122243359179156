import { Inject, Injectable } from "@angular/core";
import { IConfigurationRootScopeService } from "@gtmhub/configuration/models/configuration.models";
import { IProgress } from "@gtmhub/core";
import { IColoringRange, IColoringSetup } from "../models/configuration.model";

const sortRangesAsc = (a: IColoringRange, b: IColoringRange): number => a.lower - b.lower;

const genericColor = "#31475a";

@Injectable({ providedIn: "root" })
export class ColoringService {
  private coloring: IColoringSetup;

  constructor(@Inject("$rootScope") private $rootScope: IConfigurationRootScopeService) {}

  public getColoring(): IColoringSetup {
    return this.coloring;
  }

  public setColoring(coloring: IColoringSetup): void {
    if (!coloring) {
      return;
    }

    this.$rootScope.coloringStrategy = coloring.strategy;
    this.coloring = { ...coloring, ranges: [...coloring.ranges].sort(sortRangesAsc) };
  }

  public wrapProgress(value: number): IProgress {
    const color = this.getColorOrGeneric(value);

    if (this.coloring && this.coloring.strategy === "colors") {
      return { color };
    }

    return { value, color };
  }

  // methods like applyColoringToXxx do not seem to set the color to any value in case
  // there is no coloring, so this method is added as backward-compatible for these case;
  // but I believe we should always set the color and either user `wrapProgress` or check
  // the strategy to determine how to visualize it
  public getColor(progress: number): string {
    if (!this.coloring || this.coloring.strategy === "none") {
      return;
    }

    return this.getColorOrGeneric(progress);
  }

  public getColorOrGeneric(progress: number): string {
    if (!this.coloring || this.coloring.strategy === "none") {
      return genericColor;
    }

    let color = this.coloring.defaultColor;

    const progressPercentage = progress * 100;
    for (const range of this.coloring.ranges) {
      if (progressPercentage < range.lower) {
        color = range.color;
        break;
      }
    }

    return color;
  }
}
