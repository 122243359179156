import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Subject } from "rxjs";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";

@Component({
  selector: "dropdown-menu-item-group",
  templateUrl: "./dropdown-menu-item-group.component.html",
  styleUrls: ["./dropdown-menu-item-group.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownMenuItemGroupComponent {
  /**
   * @param menuItems The items to be displayed in the dropdown
   */
  @Input()
  public menuItems: DropdownMenuItem[];

  /**
   *  The name of the item which will be used for the construction of the data-test-id
   */
  @Input()
  public testIdSuffix: string;

  /**
   *  @param dropdownMenuClass The css class to be applied to the ul of items
   */
  @Input()
  public dropdownMenuClass: string;

  @Input()
  public currentActiveItem: DropdownMenuItem;

  @Input()
  public currentActiveItemIndex: number | null = null;

  @Input()
  public showNewDesign = false;

  @Input()
  public activeDropdownItemChange$: Subject<string> = new Subject<string>();

  @Output() public readonly menuKeyDown: EventEmitter<KeyboardEvent> = new EventEmitter();
  @Output() public readonly closeMenu: EventEmitter<DropdownMenuItem> = new EventEmitter();

  public isItemActive(item: DropdownMenuItem): boolean {
    return item.key === this.currentActiveItem?.key;
  }

  public closeGroup(): void {
    this.closeMenu.emit();
  }
}
