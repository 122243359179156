import { NgModule } from "@angular/core";
import { UiAssigneeComponent } from "./assignee.component";
import { UiAssigneeAvatarGroupComponent } from "./components/assignee-avatar-group/assignee-avatar-group.component";
import { UiAssigneeAvatarComponent } from "./components/assignee-avatar/assignee-avatar.component";
import { UiAssigneeNameComponent } from "./components/assignee-name/assignee-name.component";

@NgModule({
  imports: [UiAssigneeComponent, UiAssigneeNameComponent, UiAssigneeAvatarComponent, UiAssigneeAvatarGroupComponent],
  exports: [UiAssigneeComponent, UiAssigneeNameComponent, UiAssigneeAvatarComponent, UiAssigneeAvatarGroupComponent],
})
export class UiAssigneeModule {}
