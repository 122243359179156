import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewEncapsulation,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { BehaviorSubject, Observable, debounceTime, map, mergeWith, switchMap, tap } from "rxjs";
import { IdMap } from "@gtmhub/util";
import { Assignee, DeletedAssigneeType, ISelectorAssignee, UnknownAssignee } from "@webapp/assignees/models/assignee.models";
import { AssigneesFacade } from "@webapp/assignees/services/assignees/assignees-facade.service";
import { deletedAssignee, sortAssigneeIdsByActiveStatus } from "@webapp/assignees/utils/assignee.utils";
import { IGroupedSelectedPeople, IHideSelectedQuery, PeopleSelectorRequest } from "./models/models";
import { PeopleFilterBuilder } from "./services/people-filter.builder";

export const LIMIT_INCREASE = 8;
const LIMIT_RESET = new PeopleSelectorRequest().limit;
const DEBOUNCE_TIME = 200;
const toSelectorAssignee = (assignee: Assignee): ISelectorAssignee => ({ ...assignee, invalid: false });

// This will become a factory that is passed to the facade
const toSelectorAssignees = (assignees: Assignee[]): ISelectorAssignee[] => assignees.map(toSelectorAssignee);

const PEOPLE_SELECTOR_CONTROL_VALUE_ACCESSOR = { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PeopleSelectorComponent), multi: true };

@UntilDestroy()
@Component({
  selector: "people-selector",
  templateUrl: "./people-selector.component.html",
  styleUrls: ["./people-selector.component.less"],
  encapsulation: ViewEncapsulation.None,
  providers: [PeopleFilterBuilder, PEOPLE_SELECTOR_CONTROL_VALUE_ACCESSOR],
})
export class PeopleSelectorComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() public uiId: string;
  @Input() public mode: "default" | "multiple" = "multiple";
  @Input() public placeholderKey: string;
  /**
   * Disables editing, focusing, and submitting the input.
   */
  @Input() public disabled: boolean;
  /**
   * Disables editing (the field is still focusable).
   */
  @Input() public readonly: boolean;
  @Input() public selectedIds: string[] = [];
  @Input() public request = new PeopleSelectorRequest();
  @Input() public hideSelectedTags = false;
  @Input() public hideSelected = true;
  @Input() public a11yLabelledby: string;
  @Input() public a11yRequired = false;
  @Input() public a11yDescription: string;
  @Input() public atLeastOneValue = false;
  @Input() public borderless: boolean;
  @Input() public uiShowArrow: boolean;
  @Input() public allowClear = false;
  @Input() public preventInfiniteScrolling = false;
  @Input() public allowViewOnlyUsersForSelection = false;
  @Input() public groupSelected = false;
  @Input() public problematicSelectItems: string[] = [];
  @Input() public errorOcurred: boolean;
  @Input() public selectedAssigneesMenu: TemplateRef<{ $implicit: NzSafeAny[] }> | null = null;
  @Input() public uiDropdownStyle: { [key: string]: string } | null = null;

  @Output() public readonly selectionChange = new EventEmitter<string[]>();
  @Output() public readonly selectionChangeWithGrouping = new EventEmitter<IGroupedSelectedPeople>();
  @Output() public readonly selectorFocus = new EventEmitter<void>();
  @Output() public readonly openChange = new EventEmitter<boolean>();

  @HostBinding("class.borderless") public get borderlessClass(): boolean {
    return this.borderless;
  }

  public isLoading: boolean;
  public selectorOnFocus = false;
  public showCloseIcon: boolean;
  public isLazyLoading: boolean;
  public uiSelectedValue: string[] | string;
  private isLastChangeSearch: boolean;
  private searchQuery: string;

  public assigneeOptions: ISelectorAssignee[] = [];
  public assigneesIdMap: IdMap<Assignee>;
  public deletedAssigneesIdMap: IdMap<UnknownAssignee> = {};
  private searchChange$: BehaviorSubject<string>;
  private selectChange$: BehaviorSubject<IHideSelectedQuery>;
  public uiSelectedIdMap: IdMap<boolean>;
  private selectorAssignees$: Observable<ISelectorAssignee[]>;

  private notifyControlChange: (value: string[]) => void;

  public constructor(
    private peopleFilterBuilder: PeopleFilterBuilder,
    private assigneesFacade: AssigneesFacade,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.selectedIds = this.selectedIds ?? [];
    this.peopleFilterBuilder.setRequest(this.request).configureChain();

    this.processSelectedToUi();
    this.setChangeListeners();
    this.setAssignees();

    this.getOptionList$()
      .pipe(untilDestroyed(this))
      .subscribe((assigneeOptions) => {
        // Because of how ng-zorro works, we need to always add all selected assignees (including the deleted ones) to the options so they can appear as selected
        // Async pipe didn't work for listing the options, that is the reason why subscription happens here
        const selectedAssignees = (this.selectedIds ?? []).map((id) =>
          this.assigneesIdMap[id] ? toSelectorAssignee(this.assigneesIdMap[id]) : this.toDeletedAssignee(id)
        );
        this.assigneeOptions = assigneeOptions.concat(selectedAssignees);

        this.isLoading = false;
        this.isLazyLoading = false;

        this.cdr.detectChanges();
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.mode && !changes.mode.isFirstChange()) {
      this.mode = changes.mode.currentValue;
      this.processSelectedToUi();
    }

    if (changes.request && !changes.request.isFirstChange()) {
      this.request = changes.request.currentValue;
      this.peopleFilterBuilder.setRequest(this.request).configureChain();
      this.selectChange$.next({ enabled: this.hideSelected, ids: this.selectedIds });
    }

    if (changes.selectedIds && !changes.selectedIds.isFirstChange()) {
      this.selectedIds = changes.selectedIds.currentValue;
      this.processSelectedToUi();
      this.selectChange$.next({ enabled: this.hideSelected, ids: this.selectedIds });
    }
  }

  public deletedAssignee(id: string): UnknownAssignee {
    const typeOfDeletedAssignee: DeletedAssigneeType = this.mode === "default" ? "user" : "assignee";
    this.setShowCloseIcon();
    return deletedAssignee(id, typeOfDeletedAssignee);
  }

  public toDeletedAssignee(id: string): ISelectorAssignee {
    const unknownUserOrTeam: UnknownAssignee = this.deletedAssignee(id);
    const deletedUserOrTeam: ISelectorAssignee = { ...unknownUserOrTeam, invalid: false };

    this.deletedAssigneesIdMap[id] = deletedUserOrTeam;

    return deletedUserOrTeam;
  }

  private processSelectedToUi(): void {
    this.uiSelectedValue = this.mode === "default" ? this.selectedIds[0] : this.selectedIds;
    this.uiSelectedIdMap = [].concat(this.uiSelectedValue).reduce((map, id) => ((map[id] = true), map), {});
  }

  public search(query: string): void {
    this.isLoading = true;
    this.searchQuery = query;
    this.isLastChangeSearch = query.length > 0;

    this.searchChange$.next(query);
  }

  public onScrollToBottom(): void {
    if (this.preventInfiniteScrolling) {
      return;
    }

    if (this.isLazyLoading) {
      return;
    }

    if (this.disabled || this.readonly) {
      return;
    }

    this.isLazyLoading = true;
    this.request.limit += LIMIT_INCREASE;
    this.reloadSuggestions();
  }

  public onBlur(): void {
    if (this.disabled || this.readonly) {
      return;
    }

    this.request.limit = LIMIT_RESET;
    this.peopleFilterBuilder.setRequest(this.request);
    this.reloadSuggestions();
  }

  public emitOpenChange(isOpen: boolean): void {
    this.openChange.emit(isOpen);
    this.selectorOnFocus = isOpen;
    this.setShowCloseIcon();
  }

  private setShowCloseIcon(): void {
    if (this.mode === "default") {
      this.showCloseIcon = this.selectorOnFocus && !this.atLeastOneValue;
      return;
    }

    const shouldShowCloseIcon = this.selectorOnFocus && this.uiSelectedValue.length > 1;
    this.showCloseIcon = this.atLeastOneValue ? shouldShowCloseIcon : this.selectorOnFocus;
  }

  private reloadSuggestions(): void {
    if (this.isLastChangeSearch) {
      this.searchChange$.next(this.searchQuery);
    } else {
      this.selectChange$.next({ enabled: this.hideSelected, ids: this.selectedIds });
    }
  }

  public onSelected(value: string | string[]): void {
    const isLastValueBeingDeleted = Array.isArray(value) && !value.length && this.uiSelectedValue.length;

    if (this.atLeastOneValue && isLastValueBeingDeleted) {
      this.selectedIds = [].concat(this.uiSelectedValue);
      this.processSelectedToUi();
      this.setShowCloseIcon();
      return;
    }

    this.isLoading = true;
    this.selectedIds = [].concat(value);
    this.processSelectedToUi();
    this.setShowCloseIcon();

    this.selectChange$.next({ enabled: this.hideSelected, ids: this.selectedIds });

    this.emitChange();
  }

  private groupSelectedIds(): IGroupedSelectedPeople {
    const [teamIds, userIds] = this.selectedIds.reduce(
      ([teams, users], id) => {
        return this.assigneesIdMap[id]?.type === "team" ? [[...teams, id], users] : [teams, [...users, id]];
      },
      [[], []]
    );

    return { teamIds, userIds };
  }

  private emitChange(): void {
    if (this.groupSelected) {
      this.selectionChangeWithGrouping.emit(this.groupSelectedIds());
    } else {
      this.selectionChange.emit(this.selectedIds);
      this.notifyControlChange?.(this.selectedIds);
    }
  }

  public emitFocus(): void {
    this.selectorFocus.emit();
  }

  public removeAssignee(id: string): void {
    this.selectedIds = this.selectedIds.filter((selectedId) => selectedId !== id);
    delete this.deletedAssigneesIdMap[id];

    this.processSelectedToUi();
    this.setShowCloseIcon();
    this.selectChange$.next({ enabled: this.hideSelected, ids: this.selectedIds });
    this.emitChange();
  }

  public writeValue(value: string[]): void {
    this.selectedIds = sortAssigneeIdsByActiveStatus(value, this.assigneesFacade.getAssigneesIdMap()) ?? [];
    this.processSelectedToUi();
    this.setAssignees();

    this.cdr.markForCheck();
  }

  public registerOnChange(fn: (value: string[]) => void): void {
    this.notifyControlChange = fn;
  }

  public registerOnTouched(): void {
    // required by the ControlValueAccessor interface
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private getOptionList$(): Observable<ISelectorAssignee[]> {
    return this.getSelectChange$().pipe(mergeWith(this.getSearchChange$()));
  }

  private getSelectChange$(): Observable<ISelectorAssignee[]> {
    return this.selectChange$.asObservable().pipe(
      debounceTime(DEBOUNCE_TIME),
      tap((meta) => this.peopleFilterBuilder.setNameQuery("").setNotInIdsQuery(meta)),
      switchMap(() => this.peopleFilterBuilder.filter(this.selectorAssignees$))
    );
  }

  private getSearchChange$(): Observable<ISelectorAssignee[]> {
    return this.searchChange$.asObservable().pipe(
      debounceTime(DEBOUNCE_TIME),
      tap((query) => this.peopleFilterBuilder.setNameQuery(query)),
      switchMap(() => this.peopleFilterBuilder.filter(this.selectorAssignees$))
    );
  }

  private setChangeListeners(): void {
    this.searchChange$ = new BehaviorSubject("");
    this.selectChange$ = new BehaviorSubject({ enabled: this.hideSelected, ids: this.selectedIds });
  }

  private setAssignees(): void {
    this.assigneesIdMap = this.assigneesFacade.getAssigneesIdMap();
    this.selectorAssignees$ = this.assigneesFacade.getAllActiveAssignees$().pipe(map(toSelectorAssignees));

    // map initially selected assignee IDs to assignees according to the redux data
    this.assigneeOptions = this.selectedIds.map((id) => (this.assigneesIdMap[id] ? toSelectorAssignee(this.assigneesIdMap[id]) : this.toDeletedAssignee(id)));
  }
}
