import { NgIf, NgTemplateOutlet } from "@angular/common";
import { Component, ElementRef, HostListener, Input, NgZone, OnInit } from "@angular/core";
import { NzDestroyService } from "ng-zorro-antd/core/services";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { NzOptionItemComponent } from "ng-zorro-antd/select";
import { isSafari } from "@gtmhub/shared/utils";

@Component({
  selector: "ui-option-item",
  templateUrl: "option-item.component.html",
  styleUrls: ["./option-item.component.less"],
  host: {
    "[class.hovered-option-item]": "hoveredItem",
    "[class.activated-option-item]": "keyboardActivatedValue === value",
    // Since aria-selected is currently not entirely supported by Safari we flag every option as false,
    // otherwise it messes with the way VoiceOver reads the options.
    // (https://quantive-inc.atlassian.net/browse/GVS-27625).
    "[attr.aria-selected]": "(selected && !disabled || listOfSelectedValue.indexOf(item) >= 0) && !attrDisableForSafari",
    "[attr.title]": "attrDisableForSafari ? null : label",
    role: "option",
    tabindex: "0",
  },
  providers: [NzDestroyService],
  standalone: true,
  imports: [NgIf, NgTemplateOutlet],
})
export class UiOptionItemComponent extends NzOptionItemComponent implements OnInit {
  @Input() public keyboardActivatedValue: NzSafeAny;

  public hoveredItem = false;
  public attrDisableForSafari = false;

  constructor(elementRef: ElementRef<HTMLElement>, ngZone: NgZone, destroy$: NzDestroyService) {
    super(elementRef, ngZone, destroy$);
  }

  @HostListener("mouseenter")
  public onHostMouseEnter(): void {
    this.hoveredItem = true;
  }

  @HostListener("mouseleave")
  public onHostMouseLeave(): void {
    this.hoveredItem = false;
  }

  public ngOnInit(): void {
    this.attrDisableForSafari = isSafari();
    super.ngOnInit();
  }
}
