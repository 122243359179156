import { IHttpService, IPromise, IQService } from "angular";
import { ThunkAction } from "redux-thunk";
import { EnvironmentService } from "@gtmhub/env";
import { IUIError, createUIError } from "@gtmhub/error-handling";
import { IAccountRolesResponse, IRole, filterOutSysAdminRole, generateGetRolesQuery } from "@gtmhub/roles";
import {
  IAddRoleAction,
  IReceiveRolesAction,
  IReceiveRolesErrorAction,
  IRemoveRoleAction,
  IRequestRolesAction,
  IRolesStoreState,
  IUpdateRoleAction,
  RolesAction,
  RolesActionsEnum,
} from "./models";

const requestRoles = (): IRequestRolesAction => ({ type: RolesActionsEnum.REQUEST_ROLES });
const receiveRoles = (roles: IRole[]): IReceiveRolesAction => ({ type: RolesActionsEnum.RECEIVE_ROLES, items: roles });
const receiveRolesError = (error: IUIError): IReceiveRolesErrorAction => ({
  type: RolesActionsEnum.RECEIVE_ROLES_ERROR,
  error,
});
const addRole = (role: IRole): IAddRoleAction => ({ type: RolesActionsEnum.ADD_ROLE, role });
const updateRole = (role: IRole): IUpdateRoleAction => ({ type: RolesActionsEnum.UPDATE_ROLE, role });
const removeRole = (roleId: string): IRemoveRoleAction => ({ type: RolesActionsEnum.REMOVE_ROLE, roleId });
const shouldFetchRoles = (state: IRolesStoreState): boolean => !state.roles.isFetching && !state.roles.isFetched;

export class RolesActions {
  static $inject = ["$q", "$http", "EnvironmentService"];

  constructor(
    private $q: IQService,
    private $http: IHttpService,
    private env: EnvironmentService
  ) {}

  getRolesIfMissing(): ThunkAction<IPromise<unknown>, IRolesStoreState, null, RolesAction> {
    return (dispatch, getState) => {
      if (!shouldFetchRoles(getState())) {
        return this.$q.resolve();
      }

      dispatch(requestRoles());

      const query = generateGetRolesQuery({ includeTeamRoles: true });
      const url = this.env.getApiEndpoint("/roles" + query);

      this.$http.get<IAccountRolesResponse>(url).then(
        (response) => {
          const roleData = filterOutSysAdminRole(response.data);
          dispatch(receiveRoles(roleData.items));
        },
        (error) => dispatch(receiveRolesError(createUIError(error)))
      );
    };
  }

  addRole(role: IRole): IAddRoleAction {
    return addRole(role);
  }

  updateRole(role: IRole): IUpdateRoleAction {
    return updateRole(role);
  }

  removeRole(id: string): IRemoveRoleAction {
    return removeRole(id);
  }
}
