<div class="sp-py-6 sp-px-10 loading-card" [ngClass]="'border-' + borderStyle" ui-row>
  <div ui-col>
    <div class="loading-subcard" ui-row uiAlign="middle">
      <div class="icon-container" *ngIf="isIconAvailable" ui-col>
        <i class="fs-16px text-color-gray-7" *ngIf="!isCustomIcon" ui-icon uiType="platform-intelligence-default"></i>
        <i class="fs-16px" *ngIf="isCustomIcon" [uiType]="iconType" [uiTheme]="iconTheme" ui-icon></i>
      </div>
      <div ui-col uiFlex="1">
        <ui-skeleton-line [uiActive]="true" [style]="{ height: '20px' }" />
      </div>
    </div>
  </div>
  <div class="sp-py-4" *ngIf="isSelected" ui-col>
    <ui-skeleton-line [uiActive]="true" [style]="{ width: '120px', height: '32px' }" />
  </div>
</div>
