import { IPromise, IQService } from "angular";
import { firstValueFrom } from "rxjs";
import { IAccount } from "@gtmhub/core";
import { IAppConfig } from "@gtmhub/env";
import { AccountResolverService } from "@webapp/accounts";
import { IPortalSectionOptions, IPortalSections } from "@webapp/configuration/models/configuration.model";
import { SubscriptionRepository } from "@webapp/configuration/services/subscription/subscription-repository.service";
import { determineChargeBeeDCTarget } from "../utils/utils";

export class ChargebeeService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private cbInstance: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private cbPortalInstance: any;
  private cbPortalSections: IPortalSections;

  public static $inject = ["appConfig", "AccountResolverService", "$q", "SubscriptionRepository"];

  constructor(
    private appConfig: IAppConfig,
    private accountResolverService: AccountResolverService,
    private $q: IQService,
    private subscriptionRepository: SubscriptionRepository
  ) {}

  public openPortalSection = (sectionName: string): void => {
    this.initializePortal().then(() => {
      const cbPortalSections = this.getCbPortalSections();

      switch (sectionName) {
        case "SUBSCRIPTION_DETAILS":
          this.openCbPortalSection({
            sectionType: cbPortalSections.SUBSCRIPTION_DETAILS,
            params: {
              subscriptionId: this.accountResolverService.getAccountData().subscriptionId,
            },
          });
          break;
        case "BILLING_HISTORY":
          this.openCbPortalSection({
            sectionType: cbPortalSections.BILLING_HISTORY,
          });
          break;
        case "PAYMENT_SOURCES":
          this.openCbPortalSection({
            sectionType: cbPortalSections.PAYMENT_SOURCES,
          });
          break;
        case "ADDRESS":
          this.openCbPortalSection({
            sectionType: cbPortalSections.ADDRESS,
          });
          break;
        default:
          // do nothing
          console.warn("no such portal section found in Chargebee");
          break;
      }
    });
  };

  public createCbInstance = (): unknown => {
    this.initializeCb();
    return window["Chargebee"].getInstance();
  };

  // http endpoint at our server that should communicate with Chargebee and return a portal session object
  public setCbPortalSession = (): void => {
    this.cbInstance.setPortalSession(() =>
      firstValueFrom(this.subscriptionRepository.getPortalSession$(window.location.href)).then((portalSession) => portalSession.portal_session)
    );
  };

  public createCbPortal = (): void => {
    this.cbPortalInstance = this.cbInstance.createChargebeePortal();
  };

  public openCbPortal = (forwardOptions?: IPortalSectionOptions): void => {
    this.cbPortalInstance.open(forwardOptions);
  };

  public openCbPortalSection = (options: IPortalSectionOptions): void => {
    this.cbPortalInstance.openSection(options);
  };

  public logoutFromCbPortalSession = (): void => {
    this.cbInstance.logout();
  };

  public closeAllCbModals = (): void => {
    this.cbInstance.closeAll();
  };

  // opens a checkout window if a customer wants to subscribe to a plan
  // a hostedPageUrl should be passed leading to that plan id (plan should be set to be used with hosted pages via Chargebee Configuration on the site)
  public openCbCheckout = (hostedPageUrl: string): void => {
    window.open(hostedPageUrl, "_blank");
  };

  public getCbPortalSections = (): IPortalSections => {
    if (this.cbPortalSections) {
      return this.cbPortalSections;
    }

    return (this.cbPortalSections = window["Chargebee"].getPortalSections());
  };

  private initializeCb = () => {
    const site = this.determineChargeBeeInstance();
    this.cbInstance = window["Chargebee"].init({
      site,
    });
  };

  private initializePortal = (): IPromise<void> => {
    const init = !this.cbInstance ? this.initializeLib() : this.$q.resolve();
    return init.then(() => {
      this.setCbPortalSession();
      this.createCbPortal();
    });
  };

  private initializeLib = (): IPromise<unknown> => {
    return this.getChargebee().then(() => this.createCbInstance());
  };

  private getChargebee(): IPromise<void> {
    const depDefer = this.$q.defer<void>();
    import(
      /* webpackChunkName: "chargebee" */
      "./chargebee"
    ).then(() => depDefer.resolve(), depDefer.reject);
    return depDefer.promise;
  }

  private determineChargeBeeInstance(): string {
    if (!this.appConfig.chargebee.enabled) {
      return null;
    }

    const acc: IAccount = this.accountResolverService.getAccountData();

    if (acc.billingSystem) {
      const targetedDC = determineChargeBeeDCTarget(acc.billingSystem.toLowerCase());
      const matching = this.appConfig.chargebee.sites.find((item) => item.dc == targetedDC);
      if (matching) {
        return matching.site;
      }
    }

    return this.appConfig.chargebee.site;
  }
}
