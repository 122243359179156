import { IFilterService } from "angular";

export const absolutePercentage = [
  "$filter",
  function ($filter: IFilterService) {
    return function (input: number, decimals: number) {
      return $filter("number")(Math.abs(input) * 100, decimals) + "%";
    };
  },
];
