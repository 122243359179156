import { IAttributes, IAugmentedJQuery, ICompileService, IDirective, IDirectiveFactory, IScope, IWindowService } from "angular";
import { StateService } from "@uirouter/angularjs";
import { localize } from "@gtmhub/localization";
import { actionHandlerGenerator } from "@gtmhub/shared/utils";
import { IDropdownButtonMenuItemAttributes, ItemAction } from "../../models";

interface IDropdownButtonMenuItemScope extends IScope {
  action: ItemAction;
  actionHandler(): unknown;
  itemDisabled: boolean;
}

export class DropdownButtonMenuItem implements IDirective {
  public scope = {
    action: "<",
    itemDisabled: "<",
  };
  public restrict = "A";

  constructor(
    private $compile: ICompileService,
    private $state: StateService,
    private $window: IWindowService
  ) {}

  public link(scope: IDropdownButtonMenuItemScope, element: IAugmentedJQuery, attrs: IAttributes & IDropdownButtonMenuItemAttributes): void {
    const alignItemsCenterClass = attrs.ghFontIcon ? " align-items-c" : "";
    const buttonClasses = attrs.imageSrc || attrs.emoji || attrs.ghFontIcon ? "dropdown-item with-image" : "dropdown-item";
    const buttonDisabledAttribute = attrs.itemDisabled ? `ng-disabled="itemDisabled"` : "";
    const imageTemplate = attrs.imageSrc ? `<img ng-src="${attrs.imageSrc}" alt="icon"/>` : "";
    const ghTrack = attrs.ghTrackEventName ? `gh-track="${attrs.ghTrackEventName}"` : "";
    const ghTrackMeta = attrs.ghTrackMeta ? `gh-track-meta="${attrs.ghTrackMeta}"` : "";
    const emojiTemplate = attrs.emoji ? `<span class="leading-emoji">${attrs.emoji}</span>` : "";
    const ghFontIconTemplate = attrs.ghFontIcon ? `<span class="gh-font-icon-${attrs.ghFontIcon}"></span>` : "";
    const externalLinkTemplate = attrs.externalLink ? "<span class='gh-font-icon-external-link'></span>" : "";
    const descriptionTemplate = attrs.descriptionKey ? `<div class="description">${localize(attrs.descriptionKey)}</div>` : "";
    const dataE2eAttr = attrs.e2eData ? `data-test-id="${attrs.e2eData}"` : "";
    scope.actionHandler = actionHandlerGenerator(scope.action, { $state: this.$state, $window: this.$window });

    const template = `
      <button class="${buttonClasses}${alignItemsCenterClass}" ng-click="actionHandler()" ${ghTrack} ${ghTrackMeta} type="button" ${buttonDisabledAttribute} ${dataE2eAttr}>
        ${imageTemplate}
        ${emojiTemplate}
        ${ghFontIconTemplate}
        <div class="context">
          <div class="headline">${localize(attrs.textKey)}${externalLinkTemplate}</div>
          ${descriptionTemplate}
        </div>
      </button>
    `;
    const html = this.$compile(template)(scope);
    element.append(html);
  }

  public static factory(): IDirectiveFactory {
    const directive = ($compile: ICompileService, $state: StateService, $window: IWindowService) => new DropdownButtonMenuItem($compile, $state, $window);
    directive.$inject = ["$compile", "$state", "$window"];
    return directive;
  }
}
