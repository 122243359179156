import { Pipe, PipeTransform } from "@angular/core";
import { SearchFacetsOptions } from "@webapp/search/models/search.models";

@Pipe({
  name: "filterFacetOptions",
  standalone: true,
})
export class FilterFacetOptionsPipe implements PipeTransform {
  public transform(facets: SearchFacetsOptions[], facetsToSkip: SearchFacetsOptions[]): SearchFacetsOptions[] {
    return facets.filter((facet: SearchFacetsOptions) => this.filterFacet(facet, facetsToSkip));
  }

  private filterFacet(facet: SearchFacetsOptions, facetsToSkip: SearchFacetsOptions[]): boolean {
    return !facetsToSkip.includes(facet);
  }
}
