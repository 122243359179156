import { ILocationProvider, module } from "angular";
import { ParamTypeDefinition, UrlMatcherFactory } from "@uirouter/angular";
import uiRouter from "@uirouter/angularjs";
import { upgradeModule } from "@uirouter/angular-hybrid";
import lazyLoad from "oclazyload/dist/modules/ocLazyLoad.core.js";
import "oclazyload/dist/modules/ocLazyLoad.loaders.core.js";
import { ROUTE_TRACING } from "@gtmhub/build";
import { shouldUseQuantiveUrlBehavior } from "@gtmhub/env";
import env from "@gtmhub/env/env.module";
import uiBootstrap from "@gtmhub/ui-bootstrap.module";
import upgrade from "../upgrade/module";
import { configureCdn } from "./cdn";
import { decorateModal } from "./decorators/modal";
import { patchStateGo } from "./state-go";
import { configureStateInit } from "./state-init";
import { enableRouteTracing } from "./tracing";
import { configureViewDecorators } from "./view-decorators";

const mod = module("routing", [uiRouter, upgradeModule.name, upgrade, uiBootstrap, lazyLoad, env]);

patchStateGo(mod);

mod.config([
  "$locationProvider",
  "$urlMatcherFactoryProvider",
  ($locationProvider: ILocationProvider, $urlMatcherFactory: UrlMatcherFactory) => {
    $urlMatcherFactory.type("boolean", {
      decode: function (val) {
        return val == "true";
      },
      encode: function (val) {
        return val ? "true" : "false";
      },
      equals: function (this: ParamTypeDefinition, a, b) {
        return this.is(a) && a == b;
      },
      is: function (val) {
        return [true, false].indexOf(val) !== -1;
      },
      pattern: /false|true/,
    });

    if (shouldUseQuantiveUrlBehavior()) {
      $locationProvider.html5Mode({ enabled: true, requireBase: false, rewriteLinks: true });
    } else {
      $locationProvider.html5Mode(false);
    }
    $locationProvider.hashPrefix("");
  },
]);

configureViewDecorators(mod);

configureStateInit(mod);

if (ROUTE_TRACING) {
  enableRouteTracing(mod);
}

configureCdn(mod);

decorateModal(mod);

export default mod.name;
