import { Ng2StateDeclaration } from "@uirouter/angular";
import { LazyNgAndNg1ModuleRef, lazyLoadNgAndNg1Module, registerChildFutureState } from "@webapp/core/routing/routing.utils";

const loadModule = (): Promise<LazyNgAndNg1ModuleRef> => import(/* webpackChunkName: "people" */ "./people.lazy");
const lazyLoad = lazyLoadNgAndNg1Module(loadModule);

const teamsFutureStates: Ng2StateDeclaration[] = [
  {
    name: "gtmhub.teams",
    url: "/teams/",
    redirectTo: "gtmhub.teams.list",
  },
  {
    name: "gtmhub.teams.list.**",
    url: "",
    lazyLoad,
  },
  {
    name: "gtmhub.teams.team.**",
    url: ":teamId/team-details/",
    lazyLoad,
  },
];

const organizationChartFutureState: Ng2StateDeclaration = {
  name: "gtmhub.organization_chart.**",
  url: "/organization/",
  lazyLoad,
};

const badgesFutureState: Ng2StateDeclaration = {
  name: "gtmhub.badges.**",
  url: "/badges/",
  lazyLoad,
};

const approveBadgesFutureState: Ng2StateDeclaration = {
  name: "gtmhub.approveBadges.**",
  url: "/badges/approve/",
  lazyLoad,
};

const employeesFutureStates: Ng2StateDeclaration[] = [
  {
    name: "gtmhub.employees",
    url: "/employees/",
    redirectTo: "gtmhub.employees.list",
  },
  {
    name: "gtmhub.employees.list.**",
    url: "",
    lazyLoad,
  },
  {
    name: "gtmhub.employees.employee.**",
    url: ":employeeId/employee-details/",
    lazyLoad,
  },
  {
    name: "gtmhub.employees.team.**",
    url: ":teamId/team-details/",
    lazyLoad,
  },
];

export const states: Ng2StateDeclaration[] = [organizationChartFutureState, badgesFutureState, approveBadgesFutureState, ...employeesFutureStates, ...teamsFutureStates];

export const teamFutureStates = (base: string): Ng2StateDeclaration[] => {
  registerChildFutureState({ base, factory: "teamStates" });
  return [
    {
      name: `${base}.team.**`,
      url: ":teamId/team-details/",
      lazyLoad,
    },
  ];
};

export const employeeFutureStates = (base: string): Ng2StateDeclaration[] => {
  registerChildFutureState({ base, factory: "employeeStates" });
  return [
    {
      name: `${base}.employee.**`,
      url: ":employeeId/employee-details/",
      lazyLoad,
    },
  ];
};
