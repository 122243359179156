import { HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { IAssigneesStoreState } from "@gtmhub/assignees";
import { filterActiveAssigneeIdsOrCurrentUserId } from "@gtmhub/assignees/redux/assignee-selectors";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { TaskEventType } from "@gtmhub/tasks/task.events";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { ICollection } from "@webapp/core/core.models";
import { GTMHUB_ADDITIONAL_PARAMS } from "@webapp/core/http/interceptors/track-data.interceptor";
import { GtmhubAdditionalParams } from "@webapp/core/http/models/http.models";
import { NewTask, Task, TaskDTO } from "../models/tasks.models";
import { TasksApiService } from "./task-api.service";
import { TaskState } from "./task-state.service";

@Injectable({
  providedIn: "any",
})
export class TasksFacade extends BaseFacade<Task, TaskDTO, TaskState, TasksApiService> {
  constructor(
    state: TaskState,
    api: TasksApiService,
    private broadcastService: BroadcastService
  ) {
    super(state, api);
  }

  public getTask$(taskId: string, additionalGtmhubParams?: GtmhubAdditionalParams): Observable<Task> {
    const context = additionalGtmhubParams ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) } : {};

    return this.get$(taskId, {
      ...new RequestConfig(),
      ...context,
    });
  }

  public getTasksV2$(filter?: RequestPaging, additionalGtmhubParams?: GtmhubAdditionalParams): Observable<ICollection<Task>> {
    const context = additionalGtmhubParams ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) } : {};

    return this.apiService.getAll$<ICollection<Task>>(filter, { ...new RequestConfig(), ...context });
  }

  public create$(task: Task | NewTask, gtmhubAdditionalParams?: { pluginId?: string; cloned?: boolean }): Observable<Task> {
    if (gtmhubAdditionalParams?.cloned) {
      // check if we have deactivated owners and remove them
      task.ownerId = filterActiveAssigneeIdsOrCurrentUserId(reduxStoreContainer.reduxStore.getState<IAssigneesStoreState>(), Array.of(task.ownerId)).shift();
    }

    const requestConfig = {
      ...new RequestConfig(),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, gtmhubAdditionalParams),
    };

    return this.apiService.post$<Task>(task, requestConfig);
  }

  public update$(taskId: string, task: Partial<Task>, additionalGtmhubParams?: GtmhubAdditionalParams): Observable<Task> {
    const context = additionalGtmhubParams ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) } : {};

    return this.apiService
      .patch$<Task>(taskId, task, {
        ...new RequestConfig(),
        ...context,
      })
      .pipe(
        tap((updatedTask) => {
          this.broadcastService.emit(TaskEventType.TASK_PATCHED, { update: updatedTask, task: { taskId, ...task } });
        })
      );
  }

  public deleteTask$(id: string, additionalGtmhubParams?: GtmhubAdditionalParams): Observable<void> {
    const context = additionalGtmhubParams ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) } : {};
    return this.apiService
      .delete$<void>(id, {
        ...new RequestConfig(),
        ...context,
      })
      .pipe(
        tap(() => {
          this.broadcastService.emit(TaskEventType.TASK_DELETED_REQUEST_SUCCEEDED, { task: { id } });
        })
      );
  }
}
