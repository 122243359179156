import { IAugmentedJQuery, IDirective, IDirectiveFactory, IScope } from "angular";

interface IDropdownKeysScope extends IScope {
  notifyParent(focusedElementy: unknown, keyReleased?: boolean): void;
  cb(): (config: DropdownKeysConfig<unknown>, ...args: unknown[]) => void;
  cbArgs: unknown;
}

export interface DropdownKeysConfig<T> {
  focusedElement: T;
  keyReleased: boolean;
}

export class DropdownKeys implements IDirective {
  public scope = {
    elements: "=",
    cb: "&",
    cbArgs: "=",
  };

  private innerCounter = -1;
  private elements = null;
  private focusedElement = null;

  public link(scope: IDropdownKeysScope, element: IAugmentedJQuery): void {
    scope.notifyParent = (focusedElement, keyReleased) => {
      scope.$apply(function () {
        scope.cb()({ focusedElement, keyReleased }, scope.cbArgs);
      });
    };

    scope.$watch("elements", (val) => {
      this.elements = val;
    });

    element.on("keydown", (event) => {
      switch (event.keyCode) {
        case 40: // down arrow ev
          this.innerCounter++;

          // keeping in array bounds
          if (this.innerCounter > this.elements.length - 1) {
            this.innerCounter = 0;
          }

          this.focusedElement = this.elements[this.innerCounter];
          scope.notifyParent(this.focusedElement);
          break;

        case 38: // up arrow ev
          this.innerCounter--;

          // keeping in array bounds
          if (this.innerCounter < 0) {
            this.innerCounter = this.elements.length - 1;
          }

          this.focusedElement = this.elements[this.innerCounter];
          scope.notifyParent(this.focusedElement);
          break;

        case 13: // enter ev
          scope.notifyParent(this.focusedElement, true);
          document.body.click(); // cheat closing dropdown instead passing instances and making it more complex
          break;

        default:
          break;
      }
    });
  }

  public static factory(): IDirectiveFactory {
    const directive = () => new DropdownKeys();
    directive.$inject = [];
    return directive;
  }
}
