import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzSelectClearComponent } from "ng-zorro-antd/select";

@Component({
  selector: "ui-select-clear",
  templateUrl: "select-clear.component.html",
  styleUrls: ["./select-clear.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, UiIconModule],
})
export class UiSelectClearComponent extends NzSelectClearComponent {}
