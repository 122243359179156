import { module } from "angular";
import viewHistory from "@gtmhub/view-history/view-history.module";
import NavigationItemsMediator from "@webapp/navigation/services/uxcustomization/navigation-items.mediator.service";
import { FavoritesFactory } from "./favorites-factory";
import { FavoritesPayloadBuilder } from "./favorites-payload.builder";
import { favoritesManagerFactory } from "./favorites.manager.factory";

const mod = module("favorites", [viewHistory]);

mod.factory("FavoritesManagerFactory", favoritesManagerFactory);
mod.service("FavoritesFactory", FavoritesFactory);
mod.service("FavoritesPayloadBuilder", FavoritesPayloadBuilder);
mod.downgradeInjectable("NavigationItemsMediator", NavigationItemsMediator);

export default mod.name;
