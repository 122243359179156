import { Injectable } from "@angular/core";
import { CfMap, CfMapRequired, CfValue, ICustomField } from "@webapp/custom-fields/models/custom-fields.models";

@Injectable({
  providedIn: "root",
})
export class CustomFieldValidatorService {
  public checkIfAllRequiredNotEmpty(customFields: ICustomField[], customFieldValues: CfMap): boolean {
    const requiredCustomFieldsMap = this.generateRequiredCustomFieldMap(customFields, customFieldValues);
    const requiredCustomFields = Object.values(requiredCustomFieldsMap);

    return !requiredCustomFields.length || !requiredCustomFields.some((customField) => customField.empty);
  }
  private generateRequiredCustomFieldMap(customFields: ICustomField[], customFieldValues: CfMap): CfMapRequired {
    if (!customFields) {
      return {};
    }

    return customFields.reduce((requiredCustomFields, customField) => {
      if (customField.settings.validations.required) {
        requiredCustomFields[customField.name] = {
          value: customFieldValues[customField.name],
          empty: this.isCfEmpty(customFieldValues[customField.name]),
        };
      }

      return requiredCustomFields;
    }, {});
  }
  private isCfEmpty(customFieldValue: CfValue): boolean {
    let isEmpty = true;

    if (customFieldValue) {
      if (Array.isArray(customFieldValue)) {
        isEmpty = customFieldValue.length == 0;
      } else {
        isEmpty = (<string>customFieldValue).toString().trim().length == 0;
      }
    }

    return isEmpty;
  }
}
