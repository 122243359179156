import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { IEntityStatusChangedArgs } from "@gtmhub/entities/models/models";
import {
  BrowseConnectionsActionsParams,
  BuildInsightActionParams,
  ConnectionData,
  InsightSelectorActionType,
  OpenPowerBIInsightActionParams,
} from "@webapp/data-story/models/insights.models";
import { IDaxQueryResult, IPowerBIUpdateEvent } from "@webapp/integrations/models/powerbi-models";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";
import { UI_MODAL_DATA } from "@webapp/ui/modal/modal.models";
import { ConfigurePowerBIModalService } from "./configure-power-bi-modal.service";

export type ConfigurePowerBIModalData = {
  type: "kpi" | "kr";
  connectionData: ConnectionData;
  isEdit: boolean;
  dataSourceDetails?: EditPowerBIModalData;
};

export type EditPowerBIModalData = {
  dataSourceId: string;
  workspaceName: string;
  dataSetName: string;
  daxQuery: string;
};

@Component({
  selector: "configure-power-bi",
  templateUrl: "./configure-power-bi.component.html",
  styleUrls: ["./configure-power-bi.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurePowerBIComponent implements OnInit, OnDestroy {
  public connectionData: ConnectionData;
  public type: "kpi" | "kr";
  public isEdit: boolean;
  public dataSourceDetails: EditPowerBIModalData;

  private powerBIUpdateSubscription: Subscription;
  private daxQueryResult: IDaxQueryResult;

  constructor(
    private modalRef: UiModalRef,
    private configurePowerBIModalService: ConfigurePowerBIModalService,
    @Inject(UI_MODAL_DATA) modalData: ConfigurePowerBIModalData
  ) {
    Object.assign(this, modalData);
  }

  public ngOnInit(): void {
    this.powerBIUpdateSubscription = this.configurePowerBIModalService.getPowerBIUpdateObservable().subscribe((powerBIUpdateEventData: IPowerBIUpdateEvent) => {
      if (powerBIUpdateEventData && powerBIUpdateEventData.event === "daxQueryExecuted") {
        const daxQueryExecutedEventData: IDaxQueryResult = powerBIUpdateEventData.data as IDaxQueryResult;

        this.daxQueryResult = daxQueryExecutedEventData;

        if (daxQueryExecutedEventData && daxQueryExecutedEventData.isScalar) {
          const params: OpenPowerBIInsightActionParams = {
            action: InsightSelectorActionType.OPEN_POWERBI_INSIGHT,
            connectionData: this.connectionData,
            daxQueryResult: daxQueryExecutedEventData,
          };

          this.modalRef.destroy(params);
        }
      } else if (powerBIUpdateEventData && powerBIUpdateEventData.event === "entityStatusChanged") {
        /* for non scalar result we must wait the entity to be completely synced */
        const entityStatusChangedEventData: IEntityStatusChangedArgs = powerBIUpdateEventData.data as IEntityStatusChangedArgs;
        const entityNameCondition: boolean =
          this.daxQueryResult &&
          this.daxQueryResult.tables &&
          this.daxQueryResult.tables.length &&
          entityStatusChangedEventData.entityName === this.daxQueryResult.tables[0].name;

        if (entityNameCondition && entityStatusChangedEventData.status === "connected") {
          const params: BuildInsightActionParams = {
            action: InsightSelectorActionType.BUILD_INSIGHT,
            connectionData: this.connectionData,
            daxQueryResult: this.daxQueryResult,
          };

          this.modalRef.destroy(params);
        }
      }
    });
  }

  public goBack(): void {
    const params: BrowseConnectionsActionsParams = {
      action: InsightSelectorActionType.BROWSE_CONNECTIONS,
    };

    this.modalRef.destroy(params);
  }

  public ngOnDestroy(): void {
    this.powerBIUpdateSubscription?.unsubscribe();
  }
}
