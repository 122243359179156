import dayjs from "@webapp/shared/libs/dayjs";

// navigator is undefined in some jest tests - when undefined is provided to the collator, the default en locale is used
const locale = typeof navigator !== "undefined" ? navigator.language : undefined;

const alphaNumericCollator = new Intl.Collator(locale, {
  sensitivity: "base", // a ≠ b, a = a, a = á, a = A
  numeric: true, // "1" < "2" < "10"
});

export function byTextField<T extends { [K in keyof T] }>(field: keyof T): (a: T, b: T) => number {
  return function (a: T, b: T): number {
    return alphaNumericCollator.compare(a[field], b[field]);
  };
}

export function byDateField<T extends { [K in keyof T] }>(field: keyof T): (a: T, b: T) => number {
  return function (a: T, b: T): number {
    return Date.parse(a[field]) - Date.parse(b[field]);
  };
}

export function byDateFieldDesc<T extends { [K in keyof T] }>(field: keyof T): (a: T, b: T) => number {
  return function (a: T, b: T): number {
    return Date.parse(b[field]) - Date.parse(a[field]);
  };
}

export function byDaysDuration<T extends { [K in keyof T] }>(fieldStart: keyof T, fieldEnd: keyof T): (a: T, b: T) => number {
  return function (a: T, b: T): number {
    return dayjs(a[fieldEnd]).diff(a[fieldStart], "days") - dayjs(b[fieldEnd]).diff(b[fieldStart], "days");
  };
}

export function byNumericFieldDesc<T extends { [K in keyof T] }>(field: keyof T): (a: T, b: T) => number {
  return function (a: T, b: T): number {
    return b[field] - a[field];
  };
}

export function byBooleanField<T extends { [K in keyof T] }>(field: keyof T): (a: T, b: T) => number {
  return function (a: T, b: T): number {
    if (a[field] && !b[field]) {
      return 1;
    }
    if (!a[field] && b[field]) {
      return -1;
    }
    return 0;
  };
}

export function byNumericField<T extends { [K in keyof T] }>(field: keyof T): (a: T, b: T) => number {
  return function (a: T, b: T): number {
    return a[field] - b[field];
  };
}

// byCustomAppend applies sorting based on provided `ordered`
// param appending items that are missing from the source array.
export function byCustomAppend<T extends { [K in keyof T] }>(field: keyof T, ordered: Array<unknown>): (a: T, b: T) => number {
  return function (a: T, b: T): number {
    return ordered.indexOf(a[field]) - ordered.indexOf(b[field]);
  };
}

// byCustomAppend applies sorting based on provided `ordered`
// param prepending items that are missing from the source array.
export function byCustomPrepend<T extends { [K in keyof T] }>(field: keyof T, ordered: Array<unknown>): (a: T, b: T) => number {
  return function (a: T, b: T): number {
    const aIdx = ordered.indexOf(a[field]);
    const bIdx = ordered.indexOf(b[field]);
    return (aIdx > -1 ? aIdx : Infinity) - (bIdx > -1 ? bIdx : Infinity);
  };
}

// applies subsequently sorting functions
export function byEach<T>(...fns: ((a: T, b: T) => number)[]): (a: T, b: T) => number {
  return function (a: T, b: T): number {
    return fns.reduce((result, fn) => result || fn(a, b), 0);
  };
}
