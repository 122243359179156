import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from "@angular/core";

@Directive({
  selector: "[ui-scrollable-content]",
  standalone: true,
})
export class UiScrollableContentDirective implements AfterViewInit {
  @Input("ui-scrollable-content")
  public scrollableContent?: unknown;

  constructor(
    private element: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) {}

  public ngAfterViewInit(): void {
    if (this.scrollableContent === false) {
      return;
    }

    const element = this.element.nativeElement;
    element.style.overflowY = "auto";
    element.parentElement.style.position = "relative";

    const showHideScrollShadows = (): void => {
      const SHADOW_HEIGHT = 8;
      const hasScrolledTop = element.scrollTop < SHADOW_HEIGHT;
      if (hasScrolledTop) {
        this.renderer.removeClass(element, "ui-scrollable-content-shadow-top");
      } else {
        this.renderer.addClass(element, "ui-scrollable-content-shadow-top");
      }

      const hasScrolledDown = Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < SHADOW_HEIGHT;
      if (hasScrolledDown) {
        this.renderer.removeClass(element, "ui-scrollable-content-shadow-bottom");
      } else {
        this.renderer.addClass(element, "ui-scrollable-content-shadow-bottom");
      }
    };

    element.addEventListener("scroll", showHideScrollShadows);
    new ResizeObserver(showHideScrollShadows).observe(element);
  }
}
