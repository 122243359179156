import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiPopoverModule } from "@webapp/ui/popover/popover.module";
import { IconWithPopoverComponent } from "./icon-with-popover.component";

@NgModule({
  imports: [CommonModule, UiIconModule, UiPopoverModule],
  declarations: [IconWithPopoverComponent],
  exports: [IconWithPopoverComponent],
})
export class IconWithPopoverModule {}
