import { UiDashboardWidget, WidgetPutDTO, WidgetResponseDTO } from "@webapp/ui/dashboard/dashboard.models";

export const toDTO = (widgets: UiDashboardWidget[]): WidgetPutDTO => {
  return {
    changes: widgets.map((widget) => {
      return {
        key: widget.id,
        position: {
          x: widget.x,
          y: widget.y,
          width: widget.w,
        },
      };
    }),
  };
};

export const setWidgetsPositions = (defaultWidgets: UiDashboardWidget[], widgets: WidgetResponseDTO): void => {
  widgets.items?.forEach((widget) => {
    const defaultWidget = defaultWidgets.find((defaultWidget) => defaultWidget.id === widget.key);
    if (defaultWidget) {
      defaultWidget.x = widget.position.x;
      defaultWidget.y = widget.position.y;
      defaultWidget.w = widget.position.width;
    }
  });
};

export const stretchSingleWidgetsToFullWidth = (widgets: UiDashboardWidget[]): void => {
  // uses the widget `y` prop as row indicator and creates a key-value pair,
  // where the key is the row identifier, while the value is the widgets part of this row
  const rows = widgets.reduce<Record<number, UiDashboardWidget[]>>((map, widget) => {
    map[widget.y] = map[widget.y] || [];
    map[widget.y].push(widget);

    return map;
  }, {});

  Object.values(rows).forEach((row) => {
    // if a widget remains single on its own row, ensure its width is 100% (w === 2)
    if (row.length === 1) {
      row[0].w = 2;
    }
  });
};
