import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UiSkeletonModule } from "@quantive/ui-kit/skeleton";

@Component({
  selector: "widget-skeleton",
  templateUrl: "./widget-skeleton.component.html",
  styleUrls: ["./widget-skeleton.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiSkeletonModule],
})
export class WidgetSkeletonComponent {}
