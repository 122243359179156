<ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.target | asAssignee" uiSize="sm"></ui-assignee-avatar>
<div class="title">
  <span class="item" *ngIf="notification.operationType === 'kpi_projection_beaten'"> {{ "s_kpi_beat_a_projection" | localize: { name: notification.notificationData.target.names } }}</span>
  <span class="item" *ngIf="notification.operationType === 'kpi_projection_missed'"> {{ "s_kpi_missed_a_projection" | localize: { name: notification.notificationData.target.names } }}</span>
  <span> </span>
</div>
<div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
<div class="summary">
  <div class="message-icon"><i class="fs-20px" [uiTooltipTitle]="'kpi' | localize" ui-icon uiTheme="fill" uiType="kpi" ui-tooltip></i></div>
  <span class="item">{{ notification.notificationData.item.kpiName }}</span>
</div>
<div class="full">
  <div class="inbox-box">
    <div class="inbox-section">
      <div *ngIf="notification.operationType === 'kpi_projection_beaten'">
        <span class="item">
          {{ "the_projection_on" | localize }} <span class="bold">{{ notification.notificationData.item.projectionDate | date: "dd MMM, yyyy" }}</span>
          {{ "was_hit_with" | localize }}
          <div class="kpi-notification-box bright-green-background">
            <div class="kpi-notification-number fs-13px green">
              {{ notification.notificationData.item.delta }}
            </div>
          </div>
        </span>
      </div>
      <div *ngIf="notification.operationType === 'kpi_projection_missed'">
        <span class="item">
          {{ "the_projection_on" | localize }} <span style="font-weight: bold">{{ notification.notificationData.item.projectionDate | date: "dd MMM, yyyy" }}</span>
          {{ "was_missed_with" | localize }}
          <div class="kpi-notification-box bright-red-background">
            <div class="kpi-notification-number fs-13px red">
              {{ notification.notificationData.item.delta }}
            </div>
          </div>
        </span>
      </div>
    </div>
    <div class="inbox-section">
      <a class="link classic" [attr.href]="notification.notificationData.item.kpiUrl">{{ "view_kpi" | localize }}</a>
    </div>
  </div>
</div>
