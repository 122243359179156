<button
  #triggerButton
  [(uiVisible)]="isDropDownOpen"
  [attr.aria-expanded]="isDropDownOpen"
  [uiDropdownMenu]="menu"
  [attr.aria-haspopup]="true"
  (uiVisibleChange)="handleDropDownOpenChange($event)"
  (keydown)="handleTriggerButtonKeyDown($event)"
  aria-label="Grouping and sorting"
  ui-button
  uiShape="round"
  ui-dropdown
  uiTrigger="click"
  uiPlacement="bottomRight">
  <i ui-icon uiType="arrange" uiTheme="outline"></i>
</button>

<ui-dropdown-menu #menu="uiDropdownMenu">
  <!-- adding *ngIf to the content below as otherwise for some reason the cdkTrapFocus stops working after closing and reopening the dropdown -->
  <div class="dropdown-content-wrapper" *ngIf="isDropDownOpen" (keydown.escape)="handleDropDownEscapeKeyDown()" cdkTrapFocus>
    <div class="dropdown-content-row">
      <label class="dropdown-editor-label" for="group-active-okrs-by">{{ "group_okrs_by" | localize }}</label>
      <ui-select class="dropdown-editor-select" #groupBySelect [ngModel]="group" (ngModelChange)="handleGroupChange($event)" uiId="group-active-okrs-by">
        <ui-option [uiLabel]="'session' | localize" uiValue="session"></ui-option>
        <ui-option [uiLabel]="'ownership' | localize" uiValue="ownership"></ui-option>
      </ui-select>
    </div>
    <div class="dropdown-content-row">
      <label class="dropdown-editor-label" for="sort-active-okrs-by">{{ "sort_within_groups_by" | localize }}</label>
      <ui-select class="dropdown-editor-select" [ngModel]="sort" (ngModelChange)="handleSortChange($event)" uiId="sort-active-okrs-by">
        <ui-option [uiLabel]="'relevance' | localize" uiValue="relevance"></ui-option>
        <ui-option [uiLabel]="'alphabetical_a_z' | localize" uiValue="alphabeticalAsc"></ui-option>
        <ui-option [uiLabel]="'alphabetical_z_a' | localize" uiValue="alphabeticalDesc"></ui-option>
      </ui-select>
    </div>
  </div>
</ui-dropdown-menu>
