import { StateService } from "@uirouter/angular";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiCardModule } from "@quantive/ui-kit/card";
import { Observable, take } from "rxjs";
import { Flavor, IRecommendationArgs, recommendation } from "@webapp/analytics/models/analytics.model";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { MarketplaceFacade } from "@webapp/data-story/services/marketplace-facade/marketplace-facade.service";
import { ToastErrorHandlingService } from "@webapp/error-handling/services/toast-error-handling.service";
import { MarketplaceInsightboardTemplate } from "@webapp/insightboards/models";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { ErrorNotificationMessage } from "@webapp/shared/error-notification/error-notification.models";
import { ErrorNotificationModule } from "@webapp/shared/error-notification/error-notification.module";
import { UiGridModule } from "@webapp/ui/grid/grid.module";
import { UiLoadingIndicatorModule } from "@webapp/ui/loading-indicator/loading-indicator.module";
import { UiModalModule } from "@webapp/ui/modal/modal.module";

interface CurrentPreviewTemplate extends MarketplaceInsightboardTemplate {
  loading: boolean;
}
@Component({
  selector: "featured-insightboards",
  templateUrl: "./featured-insightboards.component.html",
  styleUrl: "./featured-insightboards.component.less",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [UiLoadingIndicatorModule, LocalizationModule, CommonModule, UiGridModule, UiCardModule, UiButtonModule, UiModalModule, ErrorNotificationModule],
  standalone: true,
})
export class FeaturedInsightboardsComponent implements OnInit {
  @Input() public insightboardTemplates: MarketplaceInsightboardTemplate[];

  public hasPermissionToAdd$: Observable<boolean>;
  public insightboardTemplatesModified: CurrentPreviewTemplate[] = [];
  public isVisible = false;
  public currentPreviewTemplate: CurrentPreviewTemplate;
  public errorMessage: ErrorNotificationMessage;

  constructor(
    private permissionsFacade: PermissionsFacade,
    private marketPlaceFacade: MarketplaceFacade,
    private state: StateService,
    private analyticsService: AnalyticsService,
    private errorService: ToastErrorHandlingService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.insightboardTemplates.forEach((value) => this.insightboardTemplatesModified.push({ ...value, loading: false }));
    this.hasPermissionToAdd$ = this.permissionsFacade.hasPermission$("ManageData");
  }

  public addInsightboardTemplate(template: CurrentPreviewTemplate): void {
    template.loading = true;

    this.marketPlaceFacade
      .installMarketplaceInsightboards$([template.id])
      .pipe(take(1))
      .subscribe({
        next: (subscription) => {
          this.trackRecommendationHelpfulness({ isUseful: true });
          this.state.go("gtmhub.insightboard", { dashboardId: subscription.installedInsightboards[0].id });
          template.loading = false;
        },
        error: (error) => {
          template.loading = false;
          this.errorService.handleError(error, "install-marketplace-insightboards", () => this.addInsightboardTemplate(template));
          this.cdr.detectChanges();
        },
      });
  }

  private trackRecommendationHelpfulness(args: IRecommendationArgs): void {
    // Tracking Insightboards creation recommendation helpfulness. Recommendation is helpful if user selects an insightboard from templates
    this.analyticsService.track(recommendation, {
      is_useful: args.isUseful,
      flavor: Flavor.PASSIVE,
      action: "creates_insightboard",
    });
  }

  public openModal(template: CurrentPreviewTemplate): void {
    this.currentPreviewTemplate = template;
    this.isVisible = true;
  }

  public handleCancel(): void {
    this.isVisible = false;
  }
}
