import Quill, { BoundsStatic, RangeStatic } from "quill";
import Emitter from "quill/core/emitter";
import { Range } from "quill/core/selection";
import { BaseTooltip } from "quill/themes/base";
import { localize } from "@webapp/localization/utils/localization.utils";

const SnowTheme = Quill.import("themes/snow");
const LinkBlot = Quill.import("formats/link");

const POLYGON_WIDTH = 14;

enum CustomQuillThemeClassesEnum {
  Editing = "ql-editing",
  Hidden = "ql-hidden",
}

enum CustomQuillThemeSelectorsEnum {
  NameField = ".tooltip-link-name",
  LinkField = ".tooltip-link-url",
  LinkPreview = "a.ql-preview",
  ActionBtn = "a.ql-action",
  RemoveBtn = "a.ql-remove",
  TopPolygon = ".top-polygon",
  BottomPolygon = ".bottom-polygon",
}

export class WebappQuillTheme extends SnowTheme {
  constructor(quill, options) {
    super(quill, options);

    // root is the ql-editor element
    const root = this.quill.root as HTMLElement;

    if (root) {
      // Get the data attributes from the quill-editor element
      const ngxQuillElement = this.quill.container.parentElement as HTMLElement;

      const a11yLabel = ngxQuillElement.getAttribute("data-a11y-label");
      const a11yLabelledby = ngxQuillElement.getAttribute("data-a11y-labelledby");
      const a11yDescription = ngxQuillElement.getAttribute("data-a11y-description");
      const a11yRequired = ngxQuillElement.getAttribute("data-a11y-required");
      const id = ngxQuillElement.getAttribute("data-id");

      if (a11yLabel) {
        root.setAttribute("aria-label", a11yLabel);
      }

      if (a11yLabelledby) {
        root.setAttribute("aria-labelledby", a11yLabelledby);
      }

      if (a11yDescription) {
        root.setAttribute("aria-description", a11yDescription);
      }

      if (a11yRequired) {
        root.setAttribute("aria-required", a11yRequired);
      }

      if (id) {
        root.setAttribute("id", id);
      }
    }
  }

  public extendToolbar(toolbar): void {
    toolbar.container.classList.add("ql-snow");
    this.tooltip = new CustomQuillTooltip(this.quill, this.options.bounds);

    if (toolbar.container.querySelector(".ql-link")) {
      this.quill.keyboard.addBinding({ key: "K", shortKey: true }, function (range, context) {
        toolbar.handlers["link"].call(toolbar, !context.format.link);
      });
    }
  }
}

// This handler is initialized first from `SnowTheme.DEFAULTS` and this implementation is very similar
WebappQuillTheme.DEFAULTS.modules.toolbar.handlers.link = function (value): void {
  if (value) {
    const range = this.quill.getSelection();
    const { tooltip } = this.quill.theme;

    // There's selected text - it should be used as the hyperlink text
    if (range && range.length > 0) {
      let preview = this.quill.getText(range);

      if (/^\S+@\S+\.\S+$/.test(preview) && preview.indexOf("mailto:") !== 0) {
        preview = "mailto:" + preview;
      }

      tooltip.edit("link", preview);
    } else {
      // Open the link tooltip with empty inputs
      tooltip.edit("link", "", "");
    }
  } else {
    this.quill.format("link", false);
  }
};

class CustomQuillTooltip extends BaseTooltip {
  private readonly root: HTMLElement;
  private readonly boundsContainer: HTMLInputElement;
  private readonly preview: HTMLElement;
  private readonly actionBtn: HTMLElement;
  private readonly removeBtn: HTMLElement;
  private readonly nameField: HTMLInputElement;
  private readonly textbox: HTMLInputElement;
  private readonly topPolygon: HTMLElement;
  private readonly bottomPolygon: HTMLElement;

  private clickedLinkName: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public quill: any;
  public linkRange: RangeStatic;
  public restoreFocus: () => void;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static TEMPLATE: string;

  constructor(quill, bounds) {
    super(quill, bounds);

    this.nameField = this.root.querySelector(CustomQuillThemeSelectorsEnum.NameField);
    this.textbox = this.root.querySelector(CustomQuillThemeSelectorsEnum.LinkField);
    this.preview = this.root.querySelector(CustomQuillThemeSelectorsEnum.LinkPreview);
    this.actionBtn = this.root.querySelector(CustomQuillThemeSelectorsEnum.ActionBtn);
    this.removeBtn = this.root.querySelector(CustomQuillThemeSelectorsEnum.RemoveBtn);
    this.topPolygon = this.root.querySelector(CustomQuillThemeSelectorsEnum.TopPolygon);
    this.bottomPolygon = this.root.querySelector(CustomQuillThemeSelectorsEnum.BottomPolygon);
  }

  public listen(): void {
    super.listen();

    this.root.querySelector(CustomQuillThemeSelectorsEnum.LinkField).addEventListener("keydown", (event) => {
      if (event.key === "Enter" && this.root.classList.contains(CustomQuillThemeClassesEnum.Editing)) {
        this.save();
        event.preventDefault();
      } else if (event.key === "Escape") {
        super.hide();
        event.preventDefault();
      }
    });

    this.root.querySelector(CustomQuillThemeSelectorsEnum.ActionBtn).addEventListener("click", (event) => {
      if (this.root.classList.contains(CustomQuillThemeClassesEnum.Editing)) {
        this.save();
      } else {
        this.edit("link", this.clickedLinkName, this.preview.textContent);
      }

      event.preventDefault();
    });

    this.root.querySelector(CustomQuillThemeSelectorsEnum.RemoveBtn).addEventListener("click", (event) => {
      if (!this.root.classList.contains(CustomQuillThemeClassesEnum.Editing) && this.linkRange != null) {
        const range = this.linkRange;
        this.restoreFocus();
        this.quill.formatText(range, "link", false, Emitter.sources.USER);

        delete this.linkRange;
      } else if (this.root.classList.contains(CustomQuillThemeClassesEnum.Editing)) {
        // This will restore focus to the editor, when the tooltip for editing link is dismissed
        this.restoreFocus();
      }

      event.preventDefault();
      super.hide();
    });

    this.quill.on(Emitter.events.SELECTION_CHANGE, (range, oldRange, source) => {
      if (range == null) return;

      if (range.length === 0 && source === Emitter.sources.USER) {
        const [link, offset] = this.quill.scroll.descendant(LinkBlot, range.index);

        if (link !== null) {
          this.linkRange = new Range(range.index - offset, link.length());

          const preview = LinkBlot.formats(link.domNode);
          const { text } = link.domNode;

          this.clickedLinkName = text;
          this.preview.textContent = preview;
          this.preview.setAttribute("href", preview);

          this.show();

          // Sometimes the CSS isn't applied fast enough, especially on opening the 1st tooltip
          setTimeout(() => {
            this.position(this.quill.getBounds(this.linkRange));
          });

          return;
        }
      } else {
        delete this.linkRange;
      }

      super.hide();
    });
  }

  public edit(mode = "link", name = null, link = null): void {
    this.root.classList.remove(CustomQuillThemeClassesEnum.Hidden);
    this.root.classList.add(CustomQuillThemeClassesEnum.Editing);

    if (name != null) {
      this.nameField.value = name;
      this.textbox.value = link;
    } else if (mode !== this.root.getAttribute("data-mode")) {
      this.nameField.value = "";
      this.textbox.value = "";
    }

    // Sometimes the CSS isn't applied fast enough, especially on opening the 1st tooltip
    setTimeout(() => {
      this.position(this.quill.getBounds(this.quill.selection.savedRange));

      // If the name is empty, we should select it
      // The timeout is needed since it's a dynamic input
      if (!name) {
        this.nameField.select();
      }
    });

    // If the name is set, select the URL input
    if (name) {
      this.textbox.select();
    }

    this.textbox.setAttribute("placeholder", this.textbox.getAttribute(`data-${mode}`) || "");

    this.actionBtn.textContent = localize("save");
    this.removeBtn.textContent = localize("cancel");

    this.root.setAttribute("data-mode", mode);
  }

  public save(): void {
    const { value } = this.textbox;

    if (this.root.getAttribute("data-mode") === "link") {
      const { scrollTop } = this.quill.root;

      let index, length;

      if (this.linkRange) {
        // When editing an existing link
        ({ index, length } = this.linkRange);

        this.linkRange = null;
        this.restoreFocus();
      } else {
        // When inserting a new link
        this.restoreFocus();

        ({ index, length } = this.quill.getSelection());
      }

      const name = this.nameField.value;

      // Replace
      this.quill.deleteText(index, length);
      this.quill.insertText(index, name);

      this.quill.formatText({ index, length: name.length }, "link", value, Emitter.sources.USER);

      this.quill.root.scrollTop = scrollTop;

      this.textbox.value = "";
      super.hide();
    }
  }

  public show(): void {
    super.show();

    this.root.removeAttribute("data-mode");

    if (this.actionBtn) {
      this.actionBtn.textContent = localize("edit");
    }

    if (this.removeBtn) {
      this.removeBtn.textContent = localize("remove");
    }

    // we need setTimeout to let the tooltip show
    window.setTimeoutOutsideAngular(() => this.preview.focus());
  }

  public hide(): void {
    // `hide` is called multiple times, but we only need to execute our logic once
    const isAlreadyHidden = this.root.classList.contains("ql-hidden");
    if (!isAlreadyHidden) {
      // manually trigger blur event on the root so that when we close the tooltip
      // we will execute the custom blur logic that saves the content in the editor
      const root: HTMLElement = this.quill.root;
      root.dispatchEvent(new Event("blur"));
    }

    super.hide();
  }

  public position(reference: BoundsStatic): number {
    // Almost a copy of the original method
    const left = reference.left + reference.width / 2 - this.root.offsetWidth / 2;
    // root.scrollTop should be 0 if scrollContainer !== root
    const top = reference.bottom + this.quill.root.scrollTop;
    this.root.style.left = left + "px";
    this.root.style.top = top + "px";

    this.root.classList.remove("ql-flip");

    const containerBounds = this.boundsContainer.getBoundingClientRect();
    const rootBounds = this.root.getBoundingClientRect();
    let shift = 0;

    if (rootBounds.right > containerBounds.right) {
      shift = containerBounds.right - rootBounds.right;
      this.root.style.left = left + shift + "px";
    }
    if (rootBounds.left < containerBounds.left) {
      shift = containerBounds.left - rootBounds.left;
      this.root.style.left = left + shift + "px";
    }
    const topDistanceIfElementIsAboveText = rootBounds.top - rootBounds.height;
    if (rootBounds.height < topDistanceIfElementIsAboveText) {
      const verticalShift = reference.bottom - reference.top + rootBounds.height;

      this.root.style.top = top - verticalShift + "px";
      this.root.classList.add("ql-flip");
    }

    // Custom polygon placement logic
    const isFlipped = this.root.classList.contains("ql-flip");

    // Polygon
    const polygonLeft = this.root.offsetWidth / 2 - POLYGON_WIDTH / 2 - shift;
    if (isFlipped) {
      this.topPolygon.style.display = "none";
      this.bottomPolygon.style.display = "block";
      this.bottomPolygon.style.left = `${polygonLeft}px`;
    } else {
      this.bottomPolygon.style.display = "none";
      this.topPolygon.style.display = "block";
      this.topPolygon.style.left = `${polygonLeft}px`;
    }

    return shift;
  }
}

CustomQuillTooltip.TEMPLATE = `<div class="top-polygon"></div>
  <a class="ql-preview" rel="noopener noreferrer" target="_blank" href="about:blank"></a>
  <span class="tooltip-label-name">Name</span>
  <input class="tooltip-link-name" type="text">

  <span class="tooltip-label-link">Link</span>
  <input class="tooltip-link-url" type="text" data-formula="e=mc^2" data-video="Embed URL">

  <div class="actions">
    <a class="ql-action"></a>
    <a class="ql-remove"></a>
  </div>

  <div class="bottom-polygon"></div>`;
