import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";
import { UI_MODAL_DATA } from "@webapp/ui/modal/modal.models";

export interface ConfirmDeleteWithInputModalData {
  itemName: string;
  toDeleteItemText: string;
  description: string;
  inputPlaceholder: string;
}

@Component({
  selector: "confirm-delete-with-input",
  templateUrl: "confirm-delete-with-input.component.html",
  styleUrls: ["confirm-delete-with-input.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDeleteWithInputComponent implements ConfirmDeleteWithInputModalData {
  public itemName: string;
  public toDeleteItemText: string;
  public description: string;
  public inputPlaceholder: string;
  private enteredItemName: string;

  constructor(
    private modalRef: UiModalRef,
    @Inject(UI_MODAL_DATA) modalData: ConfirmDeleteWithInputModalData
  ) {
    Object.assign(this, modalData);
  }

  public get canDelete(): boolean {
    return this.itemName === this.enteredItemName;
  }

  public changeEnteredItemName($event: string): void {
    this.enteredItemName = $event;

    // trigger change detection in the modal container
    this.modalRef.containerInstance.cdr.detectChanges();
  }
}
