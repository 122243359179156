import { CommonModule } from "@angular/common";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { CdnImgModule } from "@webapp/shared/directives/cdn-img/cdn-img.module";
import { IndicatorModule } from "@webapp/shared/indicator/indicator.module";
import { GifViewComponent } from "./gif-view/gif-view.component";
import { TenorGifService } from "./tenor-gif.service";

@NgModule({
  declarations: [GifViewComponent],
  exports: [GifViewComponent],
  imports: [CommonModule, IndicatorModule, LocalizationModule, UiIconModule, CdnImgModule],
  providers: [TenorGifService, provideHttpClient(withInterceptorsFromDi())],
})
export class GifViewModule {}
