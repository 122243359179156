import dayjs, { QUnitType } from "@webapp/shared/libs/dayjs";

const formatDate = (date: string, format?: string): string => {
  const dayjsDate = dayjs(date);
  if (!dayjsDate.isValid()) {
    return date;
  }

  return format ? dayjsDate.format(format) : dayjsDate.toISOString();
};

export interface IDateRangeFilter {
  (input: string, format?: string, local?: boolean): string;
}

export function DateRangeFilter(): IDateRangeFilter {
  // eslint-disable-next-line @foxglove/no-boolean-parameters
  return (input: string, format?: string, local?: boolean): string => {
    if (!input) {
      return;
    }

    const parts = input.split("|");
    if (parts.length !== 2) {
      return input;
    }

    const startDate = local ? parts[0].replace(".000Z", "") : parts[0];
    const endDate = local ? parts[1].replace(".000Z", "") : parts[1];

    return `${formatDate(startDate, format)} - ${formatDate(endDate, format)}`;
  };
}

export interface IDateFilter {
  (input: string, format?: string, local?: boolean): string;
}

export function DateFilter(): IDateFilter {
  // eslint-disable-next-line @foxglove/no-boolean-parameters
  return (input: string, format?: string, local?: boolean): string => {
    if (!input) {
      return;
    }

    const date = local ? input.replace(".000Z", "") : input;
    return formatDate(date, format);
  };
}

export function TimeAgoFilter() {
  return (input: string): string => {
    if (!input) {
      return;
    }

    return dayjs(input).fromNow();
  };
}

export function DateDifferenceFilter() {
  return (input: string, otherDate: string, unit: QUnitType | dayjs.OpUnitType): number => {
    if (!input) {
      return;
    }

    return dayjs(input).diff(otherDate || dayjs(), unit);
  };
}
