import { Ng2StateDeclaration } from "@uirouter/angular";
import { lazyLoadNgAndNg1Module } from "@webapp/core/routing/routing.utils";
import type * as lazy from "./data-story.lazy";

export const loadModule = (): Promise<typeof lazy> => import(/* webpackChunkName: "data-story" */ "./data-story.lazy");
const lazyLoad = lazyLoadNgAndNg1Module(loadModule);

const insightboardsFutureState: Ng2StateDeclaration = {
  name: "gtmhub.insightboards.**",
  url: "/insightboards/",
  lazyLoad,
};

const insightboardFutureStates: Ng2StateDeclaration[] = [
  {
    name: "gtmhub.insightboard",
    url: "/insightboard/:dashboardId/?sessionId",
    redirectTo: "gtmhub.insightboard.list",
    params: {
      sessionId: null,
    },
  },
  {
    name: "gtmhub.insightboard.list.**",
    url: "",
    lazyLoad,
  },
  {
    name: "gtmhub.insightboard.widgets",
    abstract: true,
    lazyLoad,
  },
  {
    name: "gtmhub.insightboard.widgets.browser",
    url: "widgets/?widgetsDashboardId",
    lazyLoad,
  },
  {
    name: "gtmhub.insightboard.widgets.browser._revisions.**",
    url: "revisions/:insightName",
    lazyLoad,
  },
  {
    name: "gtmhub.insightboard._edit",
    abstract: true,
    lazyLoad,
  },
  {
    name: "gtmhub.insightboard._edit.edit.**",
    url: "edit/:editDashboardId/",
    lazyLoad,
  },
  {
    name: "gtmhub.insightboard.goal.**",
    url: ":id/metrics/",
    lazyLoad,
  },
  {
    name: "gtmhub.insightboard.metric.**",
    url: "metric/:metricId/",
    lazyLoad,
  },
  {
    name: "gtmhub.insightboard._edit.tags.**",
    url: "edit-tags/:tagsDashboardId/",
    lazyLoad,
  },
  {
    name: "gtmhub.insightboard._comments.**",
    url: "comments/:insightUniqueName/timeline",
    lazyLoad,
  },
  {
    name: "gtmhub.insightboard._kpis.**",
    url: "kpis/:kpiId/details/",
    lazyLoad,
  },
  {
    name: "gtmhub.reporting.**",
    url: "/reporting/",
    lazyLoad,
  },
];

const virtualDataSourceFutureStates: Ng2StateDeclaration[] = [
  {
    name: "gtmhub.virtualDatasource",
    url: "/virtual-data-source/:vdId/",
    redirectTo: "gtmhub.virtualDatasource.list",
  },
  {
    name: "gtmhub.virtualDatasource.list.**",
    url: "",
    lazyLoad,
  },
  {
    name: "gtmhub.virtualDatasource._templates.**",
    url: "my-snippets/",
    lazyLoad,
  },
];

const dataSourcesFutureStates: Ng2StateDeclaration[] = [
  {
    name: "gtmhub.dataflow",
    url: "/data-sources/",
    redirectTo: "gtmhub.dataflow.list",
  },
  {
    name: "gtmhub.dataflow.list.**",
    url: "",
    lazyLoad,
  },
  {
    name: "gtmhub.dataflow._edit.**",
    url: ":dataSourceId/edit/",
    lazyLoad,
  },
  {
    name: "gtmhub.dataflow._refreshSchema",
    abstract: true,
    lazyLoad,
  },
  {
    name: "gtmhub.dataflow._refreshSchema.progress.**",
    url: "schema-refresh/:dataSourceId/",
    lazyLoad,
  },
  {
    name: "gtmhub.dataflow._refreshSchema.changes.**",
    url: "schema-changes/:dataSourceId/",
    lazyLoad,
  },
  // Set up connection permissions
  {
    name: "gtmhub.dataflow.setUpConnectionPermissions",
    url: "connection/:connectionId/set-up-permissions/?createdConnectionId",
    lazyLoad,
  },
  // Set up data source permissions
  {
    name: "gtmhub.dataflow.setUpDataSourcePermissions",
    url: "data-sources/:dataSourceId/set-up-permissions/",
    lazyLoad,
  },
  // Connecting entity - mapping entity's fields to a datasource's fields
  {
    name: "gtmhub.dataflow.connect",
    abstract: true,
    url: "{entityName}/",
    lazyLoad,
  },
  {
    name: "gtmhub.dataflow.connect.mapper.**",
    url: "mappings/",
    lazyLoad,
  },
  {
    name: "gtmhub.dataflow.connect.connectorsList.**",
    url: "connectors/?activeCategoryName&highlightedBlueprintName",
    lazyLoad,
  },
  {
    name: "gtmhub.dataflow.connect.connectorSelector.**",
    url: ":dataSourceBlueprintName/connectors-select/",
    lazyLoad,
  },
  {
    name: "gtmhub.dataflow.connect.connectorConfigure.**",
    url: ":dataSourceBlueprintName/configure/?connectionId",
    lazyLoad,
  },
  {
    name: "gtmhub.dataflow.connect.connected.**",
    url: "{connectorId}/",
    lazyLoad,
  },
  {
    name: "gtmhub.dataflow.connect.setSyncSchedule.**",
    url: ":connectorId/:remoteEntity/set-name-and-schedule/",
    lazyLoad,
  },
  // Connections
  {
    name: "gtmhub.dataflow._editRight.**",
    url: ":dataSourceBlueprintName/configure/?connectionId",
    lazyLoad,
  },
  // Add new data source
  {
    name: "gtmhub.dataflow.add",
    abstract: true,
    url: "add-data-source/",
    lazyLoad,
  },
  {
    name: "gtmhub.dataflow.add.connectorsList.**",
    url: "connectors/?activeCategoryName&highlightedBlueprintName",
    lazyLoad,
  },
  {
    name: "gtmhub.dataflow.add.connectorSelector.**",
    url: ":dataSourceBlueprintName/connectors-select/",
    lazyLoad,
  },
  {
    name: "gtmhub.dataflow.add.connectorConfigure.**",
    url: ":dataSourceBlueprintName/configure/?connectionId",
    lazyLoad,
  },
  {
    name: "gtmhub.dataflow.add.connected.**",
    url: "{connectorId}/",
    lazyLoad,
  },
  {
    name: "gtmhub.dataflow.add.setSyncSchedule.**",
    url: ":connectorId/:remoteEntity/set-name-and-schedule/",
    lazyLoad,
  },
  // Entity
  {
    name: "gtmhub.dataflow._createEntity.**",
    url: "create-entity/",
    lazyLoad,
  },
  {
    name: "gtmhub.dataflow.manage.**",
    url: "manage/entity/:entityName/",
    lazyLoad,
  },
  // Data sources
  {
    name: "gtmhub.dataflow._vdForm",
    abstract: true,
    url: "/virtual-data-source-form/",
    lazyLoad,
  },
  {
    name: "gtmhub.dataflow._vdForm.vdForm.**",
    url: ":virtualDatasourceId/",
    lazyLoad,
  },
];

const insightFutureState: Ng2StateDeclaration = {
  name: "gtmhub.insight.**",
  url: "/insight/:name/?dashboardId&newInsight",
  lazyLoad,
};

const insightCodelessEditorFutureState: Ng2StateDeclaration = {
  name: "gtmhub.insightCodelessEditor.**",
  url: "/insight-codeless-editor/:name/?dashboardId",
  lazyLoad,
};

export const states: Ng2StateDeclaration[] = [
  insightboardsFutureState,
  ...insightboardFutureStates,
  ...virtualDataSourceFutureStates,
  ...dataSourcesFutureStates,
  insightFutureState,
  insightCodelessEditorFutureState,
];
