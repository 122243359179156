import { ComponentRef, EnvironmentInjector, Injectable } from "@angular/core";
import { PeopleSelectorRequest } from "@webapp/shared/components/people-selector/models/models";
import { PeopleSelectorComponent } from "@webapp/shared/components/people-selector/people-selector.component";
import { EditableCustomFieldsInput, ICustomField } from "../../models/custom-fields.models";
import { AbstractCustomFieldFactory } from "./abstract-custom-field.factory";

@Injectable()
export class SelectAssigneeCustomFieldComponentFactory extends AbstractCustomFieldFactory<PeopleSelectorComponent> {
  constructor(injector: EnvironmentInjector) {
    super(injector, PeopleSelectorComponent);
  }

  private setPeopleSelectorRequestNameMap(customField: ICustomField): PeopleSelectorRequest {
    return {
      ...new PeopleSelectorRequest(),
      type: customField.settings.predefinedValues["targetGroup"],
    };
  }

  protected setComponentInputs(component: ComponentRef<PeopleSelectorComponent>, customField: ICustomField, editableCustomFieldInputs: EditableCustomFieldsInput): void {
    component.setInput("uiId", customField.name);
    component.setInput("a11yLabelledby", customField._id);
    component.setInput("borderless", editableCustomFieldInputs.borderless ?? true);
    component.setInput("selectedIds", editableCustomFieldInputs.values[customField.name]);
    component.setInput("request", this.setPeopleSelectorRequestNameMap(customField));
    component.setInput("mode", "multiple");
    component.setInput("preventInfiniteScrolling", true);
    component.setInput("a11yRequired", !editableCustomFieldInputs.disableRequired && customField.settings.validations.required);
    component.setInput("disabled", editableCustomFieldInputs.disabled);
    component.setInput("readonly", editableCustomFieldInputs.readonly);
    component.instance.selectionChange.subscribe((selectedIds: string[]) =>
      editableCustomFieldInputs.onChange({ fieldName: customField.name, customField: selectedIds })
    );
  }
}
