import { Pipe, PipeTransform } from "@angular/core";
import { LowToHighScaleType } from "../models/quantive-plus-effectiveness.models";
import { effectivenessScoreToLowToHighScale } from "../utils/quantive-plus-effectiveness-adapter.utils";

@Pipe({ name: "scoreToLowToHighScale" })
export class EffectivenessScoreToLowToHighScalePipe implements PipeTransform {
  public transform(effectivenessScore: number): LowToHighScaleType {
    return effectivenessScoreToLowToHighScale(effectivenessScore);
  }
}
