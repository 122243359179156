import { ChangeDetectorRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { distinctUntilChanged } from "rxjs";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { EditionFeature } from "../models/edition.models";

@UntilDestroy()
@Directive({
  selector: "[editionFeature]",
  standalone: true,
})
export class EditionFeatureDirective implements OnInit {
  @Input()
  public editionFeature: EditionFeature;

  @Input()
  public editionFeatureNegate?: boolean;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private container: ViewContainerRef,
    private editionFeatureService: EditionFeatureService,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.container.clear();

    this.editionFeatureService
      .hasFeature$(this.editionFeature)
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe((hasFeature) => {
        if (hasFeature === !this.editionFeatureNegate) {
          this.container.createEmbeddedView(this.templateRef);
        } else {
          this.container.clear();
        }

        this.changeDetector.markForCheck();
      });
  }
}
