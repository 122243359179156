import { IdMap, toIdMap } from "@gtmhub/util";
import { ISocketV2Data } from "./models";

interface IItemBase {
  id: string;
}

export function applySocketUpdatesToIdMap<T>(idMap: IdMap<T>, updates: ISocketV2Data<T>): IdMap<T> {
  if (!idMap || typeof idMap !== "object") {
    return {};
  }

  if (!updates || typeof updates !== "object") {
    return idMap;
  }

  const idMapCopy = { ...idMap };

  Object.keys(updates).forEach((key: keyof ISocketV2Data<T>) => {
    switch (key) {
      case "added":
      case "modified":
        Object.assign(idMapCopy, toIdMap(updates[key].items));
        break;
      case "removed":
        updates.removed.items.forEach((item) => delete idMapCopy[item.id]);
        break;
    }
  });

  return idMapCopy;
}

export function applySocketUpdatesToCollection<T extends IItemBase>(items: Array<T>, updates: ISocketV2Data<T>): T[] {
  if (!Array.isArray(items)) {
    return [];
  }

  if (!updates || typeof updates !== "object") {
    return items;
  }

  Object.keys(updates).forEach((key: keyof ISocketV2Data<T>) => {
    switch (key) {
      case "added":
        for (const createdItem of updates.added.items) {
          const sameItem = items.find((item) => item.id === createdItem.id);
          if (sameItem) {
            Object.assign(sameItem, createdItem);
          } else {
            items.push(createdItem);
          }
        }
        break;
      case "modified":
        items = items.map((item) => Object.assign(item, updates.modified.items.find((updatedItem) => updatedItem.id === item.id) || {}));
        break;
      case "removed":
        items = items.filter((item) => !updates.removed.items.some((removedItem) => removedItem.id === item.id));
        break;
    }
  });

  return items;
}
