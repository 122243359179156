import { IAttributes, IAugmentedJQuery, IDirective, IDirectiveFactory, IScope } from "angular";
import { ITraceRootScopeService } from "@gtmhub/core/tracing";
import { FileService } from "./file.service";

export class GtmhubSrcDirective implements IDirective {
  constructor(
    private fileService: FileService,
    private $rootScope: ITraceRootScopeService
  ) {}

  link(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes): void {
    attrs.$observe("ghSrc", (sourceUrl: string) => {
      if (!sourceUrl) {
        element.removeAttr("src");
        return;
      }
      this.$rootScope.traceInit(() => {
        return this.fileService.generateFileUrl(sourceUrl).then(
          (fileUrl) => {
            element.attr("src", fileUrl);
          },
          (error) => {
            element.removeAttr("src");
            console.error(error);
          }
        );
      });
    });
  }

  static factory(): IDirectiveFactory {
    const factory = (fileService: FileService, $rootScope: ITraceRootScopeService) => new GtmhubSrcDirective(fileService, $rootScope);
    factory.$inject = ["FileService", "$rootScope"];
    return factory;
  }
}
