import { ILocationService, IWindowService } from "angular";
import { RawParams, StateService } from "@uirouter/angularjs";
import { getAccessToken, getToken } from "@gtmhub/auth";
import { getAuthCookie, resolveAuthToken } from "@gtmhub/auth/providers/auth0.provider";
import { IAppConfig } from "@gtmhub/env";
import { stringifyMessage } from "@webapp/core/logging/logging.utils";
import { LoggingService } from "@webapp/core/logging/services/logging.service";
import { InviteUserToAccountService } from "../services/invite-user-to-account.service";

export class ResolveTokenCtrl {
  private loggingService: LoggingService;

  public static $inject = ["$location", "$window", "$state", "appConfig", "InviteUserToAccountService"];

  constructor(
    private $location: ILocationService,
    private $window: IWindowService,
    private $state: StateService,
    private appConfig: IAppConfig,
    private inviteUserToAccountService: InviteUserToAccountService
  ) {
    this.loggingService = new LoggingService(this.appConfig.logging, "spa");
    this.resolveToken();
  }

  private navigateToNextPhase({ returnPath, domain, isMsTeams }: Record<string, string> = {}) {
    if (returnPath && returnPath.startsWith("/")) {
      // if we are inside the case where we have a return path and we are going to the root of the website - add the window.location.origin
      this.$window.location.href = returnPath;
    } else if (returnPath && isMsTeams) {
      const queryParams = new URLSearchParams();
      queryParams.set("accessToken", getAccessToken());
      queryParams.set("idToken", getToken());
      returnPath = `${returnPath}?${queryParams.toString()}`;
      this.$window.location.href = returnPath;
    } else {
      this.goToState("accountBootstrap.resolveAccount", domain ? { domain } : undefined);
    }
  }

  private resolveToken = () => {
    // get query string as object
    const queryParams = this.$location.search();

    if (this.appConfig.auth.type === "auth0") {
      // ensure we have an auth cookie present
      const { webappEndpoint, secure } = this.appConfig.endpoints;
      const authCookieValue = getAuthCookie({ domain: webappEndpoint, secure });
      if (!authCookieValue) {
        throw new Error("Cannot find state cookie to verify authentication");
      }

      resolveAuthToken(queryParams)
        .then(() => {
          const invitationKey = queryParams.invitationKey;
          if (invitationKey) {
            this.inviteUserToAccountService.addUserToAccount(decodeURIComponent(invitationKey));
          } else {
            this.navigateToNextPhase(authCookieValue.appState);
          }
        })
        .catch((error) => {
          this.handleError({ error });
        });
    } else if (this.appConfig.auth.type === "azure") {
      const token = getToken();

      if (token) {
        this.goToState("accountBootstrap.resolveAccount");
      } else {
        this.handleError({ azure_error: "No token has been set" });
      }
    }
  };

  private handleError = (error: Record<string, unknown>) => {
    const errorMessage = stringifyMessage(error);
    console.error(errorMessage);
    this.loggingService.logError(new Error(errorMessage));

    localStorage.setItem("auth_error", errorMessage);
    this.goToState("error");
  };

  private goToState = (state: string, params?: RawParams): void => {
    // replace the location to prevent saving this URL in browser history
    this.$state.go(state, params, { location: "replace", inherit: false });
  };
}
