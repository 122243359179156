import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PiStateProcessorService } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.service";

@Component({
  selector: "pi-suggestion-drawer-base",
  templateUrl: "./suggestion-drawer-base.component.html",
  styleUrls: ["./suggestion-drawer-base.component.less"],
})
export class SuggestionDrawerBaseComponent {
  @Input() public isOpen = false;
  @Input() public description: string;

  @Output() public readonly visibilityChange = new EventEmitter<boolean>();

  constructor(private piStateProcessorService: PiStateProcessorService) {}

  public closeSuggestionDrawer(): void {
    this.isOpen = false;
    this.piStateProcessorService.collapseAllInstances();
    this.visibilityChange.emit(this.isOpen);
  }
}
