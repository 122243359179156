import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownMenuItemBuilder } from "@webapp/shared/dropdown/util/dropdown-menu-item-builder";

@Component({
  selector: "help-dropdown",
  templateUrl: "./help-dropdown.component.html",
  styleUrls: ["./help-dropdown.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpDropdownComponent implements OnInit {
  @Input() public iconType = "help";
  @Input() public withoutBorderOnFocus = false;

  public helpMenuItems: DropdownMenuItem[];

  public ngOnInit(): void {
    this.helpMenuItems = this.getHelpMenuItems();
  }

  private getHelpMenuItems(): DropdownMenuItem[] {
    const menuItemBuilder = new DropdownMenuItemBuilder();

    return [
      menuItemBuilder
        .setKey("live_faq_s")
        .setUiType({ uiType: "uiIcon", iconType: "help", iconTheme: "outline" })
        .setAction({ href: "https://help.quantive.com/en/", target: "_blank" })
        .setA11yDescriptionKey("this_links_leads_to_currently_inaccessible_content")
        .build(),

      menuItemBuilder
        .setKey("resource_library")
        .setUiType({ uiType: "uiIcon", iconType: "edit", iconTheme: "outline" })
        .setAction({ href: "https://quantive.com/resources", target: "_blank" })
        .setA11yDescriptionKey("this_links_leads_to_currently_inaccessible_content")
        .build(),

      menuItemBuilder
        .setKey("product_updates")
        .setUiType({ uiType: "uiIcon", iconType: "manage", iconTheme: "outline" })
        .setAction({ href: "https://updates.quantive.com/", target: "_blank" })
        .setA11yDescriptionKey("this_links_leads_to_currently_inaccessible_content")
        .build(),

      menuItemBuilder
        .setKey("platform_status")
        .setUiType({ uiType: "uiIcon", iconType: "refresh", iconTheme: "outline" })
        .setAction({ href: "https://status.quantive.com/", target: "_blank" })
        .setA11yDescriptionKey("this_links_leads_to_currently_inaccessible_content")
        .build(),

      menuItemBuilder
        .setKey("give_feedback")
        .setUiType({ uiType: "uiIcon", iconType: "comments", iconTheme: "outline" })
        .setAction({ href: "https://quantive-inc.atlassian.net/servicedesk/customer/portal/1", target: "_blank" })
        .setA11yDescriptionKey("this_links_leads_to_currently_inaccessible_content")
        .build(),
    ];
  }
}
