import { Overlay } from "@angular/cdk/overlay";
import { Injectable, Injector, TemplateRef } from "@angular/core";
import { MessageConfig, NzConfigKey, NzConfigService } from "ng-zorro-antd/core/config";
import { NzSingletonService } from "ng-zorro-antd/core/services";
import { NzMessageService } from "ng-zorro-antd/message";
import { UiToastConfig, UiToastData, UiToastDataOptions, UiToastRef, UiToastType } from "@webapp/ui/toast/toast.models";
import { toToastConfig, uiToastToNzMessageDataOptions } from "@webapp/ui/toast/utils/toast.utils";
import { UiToastContainerComponent } from "../components/toast-container/toast-container.component";

@Injectable()
export class UiToastService extends NzMessageService {
  protected override container?: UiToastContainerComponent;
  private nzConfigModuleName: NzConfigKey = "message";

  constructor(
    nzSingletonService: NzSingletonService,
    overlay: Overlay,
    injector: Injector,
    private nzConfigService: NzConfigService
  ) {
    super(nzSingletonService, overlay, injector);
  }

  public success(content: string | TemplateRef<void>, options?: UiToastDataOptions): UiToastRef {
    this.updateNzMessageConfig(toToastConfig(options));
    return this.createUiToastInstance({ type: "success", content }, options);
  }

  public info(content: string | TemplateRef<void>, options?: UiToastDataOptions): UiToastRef {
    this.updateNzMessageConfig(toToastConfig(options));
    return this.createUiToastInstance({ type: "info", content }, options);
  }

  public error(content: string | TemplateRef<void>, options?: UiToastDataOptions): UiToastRef {
    this.updateNzMessageConfig(toToastConfig(options));
    return this.createUiToastInstance({ type: "error", content }, options);
  }

  /**
   * @deprecated toast type NOT supported
   */
  public warning(content: string | TemplateRef<void>, options?: UiToastDataOptions): UiToastRef {
    this.updateNzMessageConfig(toToastConfig(options));
    return this.createUiToastInstance({ type: "warning", content }, options);
  }

  /**
   * @deprecated toast type NOT supported
   */
  public loading(content: string | TemplateRef<void>, options?: UiToastDataOptions): UiToastRef {
    this.updateNzMessageConfig(toToastConfig(options));
    return this.createUiToastInstance({ type: "loading", content }, options);
  }

  /**
   * @deprecated it is highly recommended to use
   * the strictly defined methods for the specific message types supported
   */
  public create(type: UiToastType | string, content: string | TemplateRef<void>, options?: UiToastDataOptions): UiToastRef {
    this.updateNzMessageConfig(toToastConfig(options));
    return this.createUiToastInstance({ type, content }, options);
  }

  // the original 'createInstance' method is private and cannot be overridden
  // it only supports the creation of container instances of type NzMessageContainerComponent
  // the following method is a copy of the original 'createInstance' method with the only difference
  // that it supports the creation of container instances of type UiToastContainerComponent
  private createUiToastInstance(toast: UiToastData, options?: UiToastDataOptions): UiToastRef {
    this.container = this.withContainer(UiToastContainerComponent);

    return this.container.create({
      ...toast,
      ...{
        createdAt: new Date(),
        messageId: this.getInstanceId(),
        options: uiToastToNzMessageDataOptions(options),
      },
    });
  }

  private updateNzMessageConfig(messageConfig: UiToastConfig): void {
    const currentMessageConfig = this.nzConfigService.getConfigForComponent(this.nzConfigModuleName) || {};
    const updatedMessageConfig: MessageConfig = { ...currentMessageConfig, ...messageConfig };
    this.nzConfigService.set(this.nzConfigModuleName, updatedMessageConfig);
  }
}
