import { IPromise, IQService, IRootScopeService } from "angular";
import { IModalScope } from "angular-ui-bootstrap";
import { StateService } from "@uirouter/angularjs";
import { ITraceRootScopeService } from "@gtmhub/core/tracing";
import { IIndicator } from "@gtmhub/error-handling";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { IProfile } from "@webapp/user-profile/models/user-profile.models";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";
import { getCurrentUserId } from "../current-user-storage";
import { IPatchedUser, IUser } from "../models";
import { UserService } from "../user-service";

export interface IChangeUserAvatarScope extends IModalScope {
  indicators: {
    loading?: IIndicator;
    uploading?: IIndicator;
  };
  picFile?: Blob;
  profile: IProfile;
  croppedDataUrl?: string;
  uploadedFileUrl?: string;
  upload(): void;
  remove(): void;
  restore(): void;
}

export class ChangeUserAvatarCtrl {
  private user: IUser;
  private isUploadMediaAvatarEnabled: boolean;

  public static $inject = ["$q", "$rootScope", "$scope", "UserService", "$state", "userId", "UserProfileService", "FeatureTogglesFacade"];

  constructor(
    private $q: IQService,
    private $rootScope: IRootScopeService & ITraceRootScopeService,
    private $scope: IChangeUserAvatarScope,
    private userService: UserService,
    private $stateService: StateService,
    private userId: string,
    private profileService: UserProfileService,
    private featureTogglesFacade: FeatureTogglesFacade
  ) {
    this.$scope.upload = this.upload;
    this.$scope.remove = this.remove;
    this.$scope.restore = this.restore;
    this.$scope.indicators = {};
    this.init();
  }

  private init = (): void => {
    this.$scope.indicators.loading = { progress: true };

    this.$q
      .all({
        isUploadMediaAvatarEnabled: this.featureTogglesFacade.isFeatureAvailable(FeatureFlag.UploadMediaAvatar),
        user: this.userService.getUser(this.userId),
      })
      .then(
        ({ isUploadMediaAvatarEnabled, user }) => {
          this.isUploadMediaAvatarEnabled = isUploadMediaAvatarEnabled;

          delete this.$scope.indicators.loading;
          this.user = user;
          this.$scope.profile = {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            avatar: user.avatar,
            picture: user.picture,
            created: user.dateCreated,
            language: user.language,
            pictureInfo: user.pictureInfo,
          };
        },
        (error) => {
          this.$scope.indicators.loading = { error };
        }
      );
  };

  private restore = () => {
    const patchedUser: IPatchedUser = {
      restoreUserPicture: true,
    };

    this.$scope.indicators.uploading = { progress: true };

    this.userService.patchUser(this.user.id, patchedUser).then(
      () => {
        delete this.$scope.indicators.uploading;
        this.$rootScope.$broadcast("usersChanged", false);
        if (this.$stateService.current.name.includes("employees")) {
          this.$rootScope.$broadcast("employeeChanged");
          this.$rootScope.$broadcast("employeesChanged");
        }
        this.$scope.$close();
      },
      (error) => {
        this.$scope.indicators.uploading = { error };
      }
    );
  };

  private remove = () => {
    this.$scope.picFile = null;
    this.$scope.croppedDataUrl = "";
  };

  private upload = (): void => {
    if (!this.$scope.croppedDataUrl) {
      return;
    }
    this.$rootScope.traceAction("upload_user_avatar", () => {
      this.$scope.indicators.uploading = { progress: true };

      let uploadPromise: IPromise<string>;
      if (this.isUploadMediaAvatarEnabled) {
        uploadPromise = this.userService.uploadAvatarV2(this.user, this.$scope.croppedDataUrl);
      } else {
        uploadPromise = this.userService.uploadAvatar(this.user, this.$scope.croppedDataUrl);
      }

      uploadPromise.then(
        (url) => {
          if (this.user.id === getCurrentUserId()) {
            this.profileService.setProfileFromUser({ ...this.user, picture: url });
            this.$rootScope.$broadcast("profilePictireUpdated");
          }

          delete this.$scope.indicators.uploading;
          this.$scope.uploadedFileUrl = url;
          this.$rootScope.$broadcast("usersChanged", false);
          if (this.$stateService.current.name.includes("employees")) {
            this.$rootScope.$broadcast("employeeChanged");
            this.$rootScope.$broadcast("employeesChanged");
          }
          this.$scope.$close();
        },
        (error) => {
          this.$scope.indicators.uploading = { error };
        }
      );
    });
  };
}
