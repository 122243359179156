import { IRootScopeService, IScope } from "angular";
import { StateService } from "@uirouter/angularjs";
import dayjs from "@webapp/shared/libs/dayjs";

interface IDateRangePickerScope extends IScope {
  model: {
    dateRange: { startDate: string; endDate: string };
  };
  done(): void;
}

export class DateRangePickerCtrl {
  public static $inject = ["$scope", "$rootScope", "$state"];

  constructor(
    private $scope: IDateRangePickerScope,
    private $rootScope: IRootScopeService,
    private $state: StateService
  ) {
    this.$scope.model = {
      dateRange: {
        startDate: dayjs().toISOString(),
        endDate: dayjs().toISOString(),
      },
    };

    this.$scope.done = () => {
      const daterange = {
        start: this.$scope.model.dateRange.startDate,
        end: this.$scope.model.dateRange.endDate,
      };

      this.$rootScope.$broadcast("selectedDaterange", daterange);
      this.$state.go("^.^");
    };
  }
}
