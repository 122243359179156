import { IdMap } from "@gtmhub/util";
import { Session } from "@webapp/sessions/models/sessions.model";

export const parentOkrSelectorSections = (config: {
  sessionMap: IdMap<Session>;
  currentSessionId: string;
  isGrandParentAlignmentEnabled: boolean;
}): Map<string, Map<string, { expand: boolean }>> => {
  const sectionsMap = new Map();

  if (config.isGrandParentAlignmentEnabled) {
    const currentSessionAndDirectParentSession = currentSessionAndItsDirectParent(config.sessionMap, config.currentSessionId);
    const currentSessionParentSessionId = config.sessionMap[config.currentSessionId].parentId;
    const sectionSecondLevelAndAboveParents = getSecondLevelAndAboveParents(config.sessionMap, currentSessionParentSessionId);

    sectionsMap.set("okrs_related_to_you", currentSessionAndDirectParentSession);
    sectionsMap.set("okrs_in_related_sessions", sectionSecondLevelAndAboveParents);
  } else {
    const currentSessionAndDirectParentSession = currentSession(config.sessionMap, config.currentSessionId);
    const sectionSessionDirectParent = currentSessionDirectParent(config.sessionMap, config.currentSessionId);

    sectionsMap.set("okrs_related_to_you", currentSessionAndDirectParentSession);
    sectionsMap.set("okrs_in_related_sessions", sectionSessionDirectParent);
  }

  return sectionsMap;
};

export const currentSession = (sessionMap: IdMap<Session>, currentSessionId: string): Map<string, { expand: boolean }> => {
  const sectionsMap = new Map();
  const currentSession = sessionMap[currentSessionId];

  if (currentSession) {
    sectionsMap.set(currentSession.id, { expand: true });
  }

  return sectionsMap;
};

export const currentSessionDirectParent = (sessionMap: IdMap<Session>, currentSessionId: string): Map<string, { expand: boolean }> => {
  const sectionsMap = new Map();
  const currentSession = sessionMap[currentSessionId];

  if (currentSession?.parentId) {
    sectionsMap.set(currentSession.parentId, { expand: true });
  }

  return sectionsMap;
};

export const currentSessionAndItsDirectParent = (sessionMap: IdMap<Session>, currentSessionId: string): Map<string, { expand: boolean }> => {
  const sectionsMap = new Map();
  const currentSession = sessionMap[currentSessionId];

  if (currentSession) {
    sectionsMap.set(currentSession.id, { expand: true });
  }

  if (currentSession?.parentId) {
    sectionsMap.set(currentSession.parentId, { expand: true });
  }

  return sectionsMap;
};

export const getSecondLevelAndAboveParents = (sessionMap: IdMap<Session>, parentSessionId: string): Map<string, { expand: boolean }> => {
  const sectionsMap = new Map();
  const isParentSessionPrivate = !sessionMap[parentSessionId];
  let nextLevelParentSessionId = parentSessionId && !isParentSessionPrivate ? sessionMap[parentSessionId].parentId : "";

  while (nextLevelParentSessionId) {
    const nextLevelParentSession = sessionMap[nextLevelParentSessionId];
    if (!nextLevelParentSession) {
      break;
    }

    sectionsMap.set(nextLevelParentSessionId, { expand: false });
    nextLevelParentSessionId = nextLevelParentSession.parentId;
  }

  return sectionsMap;
};

export const getSessionAncestorsIds = (sessionMap: IdMap<Session>, currentSessionId: string): string[] => {
  let currentSession = sessionMap[currentSessionId];
  let sessionsIds = [currentSessionId];

  while (currentSession && currentSession.parentId) {
    sessionsIds = [...sessionsIds, currentSession.parentId];

    currentSession = sessionMap[currentSession.parentId];
  }

  return sessionsIds;
};
