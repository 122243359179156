import { IFilterService } from "angular";
import { localize } from "@gtmhub/localization";
import { IDateFilter, IDateRangeFilter } from "@gtmhub/shared/components/ui/datepicker/date.filter";
import { InsightParameterSelectorType } from "@webapp/data-story/models/parameters-facade.models";

export interface IInsightFilterUi {
  name: string;
  title: string;
  value: string | number;
  valueDisplayName?: string;
  type: InsightParameterSelectorType;
}

export interface IInsightFilterFilter {
  (filter: IInsightFilterUi, valueMaxLength?: number): string;
}

export function InsightFilterFilter($filter: IFilterService): IInsightFilterFilter {
  return function (filter: IInsightFilterUi, valueMaxLength?: number): string {
    if (!filter) return "";

    let displayValue: string;
    if (typeof filter.value !== "number" && !filter.value) {
      displayValue = localize("none_selected");
    } else {
      switch (filter.type) {
        case "daterange":
        case "systemdaterange": {
          const value = filter.value.toString();
          const isDateRange = value.split("|")[1];
          const dateRangeFilter = $filter<IDateRangeFilter>("ghDaterange");
          displayValue = isDateRange ? dateRangeFilter(value, "DD MMM, YYYY", true) : value;
          break;
        }

        case "datepicker": {
          const value = filter.value.toString();
          const dateFilter = $filter<IDateFilter>("ghDate");
          displayValue = dateFilter(value, "DD MMM, YYYY", true);
          break;
        }

        default: {
          const value = filter.valueDisplayName || filter.value.toString();
          displayValue = $filter("limitTo")(value, valueMaxLength) + (value.length > valueMaxLength ? "..." : "");
          break;
        }
      }
    }

    return `${filter.title}: ${displayValue}`;
  };
}
InsightFilterFilter.$inject = ["$filter"];
