import { IAttributes, IAugmentedJQuery, ICompileService, IDirective, IDirectiveFactory, IScope } from "angular";
import { IDropdownButtonMenuItem, IDropdownButtonMenuItemAttributes } from "../../models";

export interface IDropdownButtonScope extends IScope {
  toggleDisabled: boolean;
  menuItems: IDropdownButtonMenuItem[];
}

const generateMenuItemAttr = (attr: keyof IDropdownButtonMenuItemAttributes, menuItem: IDropdownButtonMenuItem): string => {
  switch (attr) {
    case "descriptionKey":
      return menuItem.descriptionKey ? `description-key="${menuItem.descriptionKey}"` : "";
    case "imageSrc":
      return menuItem.imageSrc ? `image-src="${menuItem.imageSrc}"` : "";
    case "externalLink":
      return menuItem.externalLink ? `external-link="${menuItem.externalLink}"` : "";
    case "emoji":
      return menuItem.emoji ? `emoji="${menuItem.emoji}"` : "";
    case "ghFontIcon":
      return menuItem.ghFontIcon ? `gh-font-icon="${menuItem.ghFontIcon}"` : "";
    case "ghTrackEventName":
      return menuItem.ghTrackEventName ? `gh-track-event-name="${menuItem.ghTrackEventName}"` : "";
    case "ghTrackMeta":
      return menuItem.ghTrackMeta ? `gh-track-meta="${menuItem.ghTrackMeta}"` : "";
    case "e2eData":
      return menuItem.e2eData ? `data-test-id="${menuItem.e2eData}"` : "";
  }
};

/**
 * @example <gh-dropdown-button size="large" kind="primary" toggle-id="testId" toggle-disabled="toggleDisabled" menu-position="right" menu-items="menuItems">My DD Button</gh-dropdown-button>
 * @param size "large" | "small"
 * @param kind "primary" | "secondary" | "destructive"
 * @param toggleId (toggle-id as attr) string id of the button, needed for aria-labelledby attr
 * @param toggleDisabled (toggle-disabled as attr, optional) boolean variable
 * @param menuPosition (menu-position as attr, optional) "right" - 'dropdown-menu-right' class will be added to the dropdown menu
 * @param menuItems (menu-items as attr) array of options for each menu item; see dummy data example below
 * @example
  $scope.menuItems = [
    {
      textKey: "first_option",
      descriptionKey: "some_description",
      action: { handler: () => myHandler() },
      imageSrc: require("wwwroot/img/icons/some-icon.svg"),
      disabled: boolean variable,
    },
    {
      textKey: "second_option",
      descriptionKey: "some_description",
      action: { href: "/url", target: "_blank" },
      imageSrc: require("wwwroot/img/icons/another-icon.svg"),
      externalLink: true, // adds external link icon next to text
    },
    {
      text: "third_option",
      action: { state: "stateName", params?: { sessionId: "abc" }, options?: { reload: true } },
    },
  ];
 */
export class DropdownButton implements IDirective {
  public scope = {
    toggleDisabled: "<",
    menuItems: "<",
  };
  public restrict = "E";

  constructor(private $compile: ICompileService) {}

  public link(scope: IDropdownButtonScope, element: IAugmentedJQuery, attrs: IAttributes): void {
    const dropdownToggleHtml = element.html();
    element.html(null);

    const dropdownToggleE2eAttribute = attrs.e2eData ? `data-test-id="${attrs.e2eData}"` : "";
    const dropdownToggleDisabledAttribute = attrs.toggleDisabled ? `ng-disabled="toggleDisabled"` : "";
    const dropdownMenuClasses = attrs.menuPosition === "right" ? "dropdown-menu-right" : "";
    const dropdownItemsTemplate = scope.menuItems.reduce((template, menuItem, index) => {
      const disabledAttr = "disabled" in menuItem ? `item-disabled="menuItems[${index}].disabled"` : "";

      template += `<li role="menuitem" gh-dropdown-button-menu-item action="menuItems[${index}].action" text-key="${menuItem.textKey}"
                      ${disabledAttr}
                      ${generateMenuItemAttr("descriptionKey", menuItem)}
                      ${generateMenuItemAttr("imageSrc", menuItem)}
                      ${generateMenuItemAttr("externalLink", menuItem)}
                      ${generateMenuItemAttr("emoji", menuItem)}
                      ${generateMenuItemAttr("ghTrackEventName", menuItem)}
                      ${generateMenuItemAttr("ghTrackMeta", menuItem)}
                      ${generateMenuItemAttr("ghFontIcon", menuItem)}
                      ${generateMenuItemAttr("e2eData", menuItem)}></li>`;
      return template;
    }, "");

    const template = `
      <div uib-dropdown>
        <button id="${attrs.toggleId}" aria-haspopup="true" aria-expanded="true" gh-button uib-dropdown-toggle size="${attrs.size}" kind="${attrs.kind}" type="button" data-toggle="dropdown" ${dropdownToggleDisabledAttribute} ${dropdownToggleE2eAttribute}>${dropdownToggleHtml}</button>
        <ul uib-dropdown-menu role="menu" aria-labelledby="${attrs.toggleId}" class="${dropdownMenuClasses}">
          ${dropdownItemsTemplate}
        </ul>
      </div>
    `;

    const html = this.$compile(template)(scope);
    element.append(html);
  }

  public static factory(): IDirectiveFactory {
    const directive = ($compile: ICompileService) => new DropdownButton($compile);
    directive.$inject = ["$compile"];
    return directive;
  }
}
