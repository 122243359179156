import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AccountResolverService } from "@webapp/accounts";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { FeatureBannerComponent } from "@webapp/shared/feature-banner/feature-banner.component";

@NgModule({
  imports: [CommonModule, LocalizationModule],
  declarations: [FeatureBannerComponent],
  providers: [AccountResolverService],
  exports: [FeatureBannerComponent],
})
export class FeatureBannerModule {}
