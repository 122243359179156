import { IAttributes, IAugmentedJQuery, IDirective, IDirectiveFactory, IScope } from "angular";
import { TRACE_ELEMENT_RENDERED, TRACE_ELEMENT_START_RENDER } from "./apm.models";
import { RenderTransactionCtrl } from "./render-transaction.directive";
import { TraceElementRenderedData } from "./trace-render-id.directive";

/**
 * Serves two main purposes:
 * 1. to scan gh-render-id directives at compile time and
 * 2. to group render events (value of this function is still under question)
 */
export class TraceRenderIdCollector implements IDirective {
  public require = "?^ghRenderTransaction";
  public controllerAs?: string = "ctrl";
  public scope = true;
  public compile(element: IAugmentedJQuery) {
    const compileTimeNodes = [...element[0].querySelectorAll("[gh-trace-render-id]")];
    return {
      pre: (scope: IScope, element: IAugmentedJQuery, attrs: IAttributes, transactionCtrl: RenderTransactionCtrl) => {
        if (!transactionCtrl) return;
        const collectorName = element.attr("gh-trace-render-id-collector");
        const compileTimeTraceElements = compileTimeNodes.map((node) => {
          return {
            name: node.getAttribute("gh-trace-render-id"),
          };
        });
        transactionCtrl.initTraceElements(collectorName, compileTimeTraceElements);

        scope.$on(TRACE_ELEMENT_RENDERED, (event, data: TraceElementRenderedData) => {
          transactionCtrl.elementRendered(`[${collectorName}]${data.name}`);
          if (event.stopPropagation) {
            event.stopPropagation();
          }
        });

        scope.$on(TRACE_ELEMENT_START_RENDER, (event, data: TraceElementRenderedData) => {
          transactionCtrl.addTraceElement(collectorName, data);
          if (event.stopPropagation) {
            event.stopPropagation();
          }
        });
      },
    };
  }

  public static factory(): IDirectiveFactory {
    const directive = () => new TraceRenderIdCollector();
    directive.$inject = [];
    return directive;
  }
}
