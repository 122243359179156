import { IIndicator, IUIError } from "@gtmhub/error-handling";
import { ICustomFieldsAllTypesMap } from "@webapp/custom-fields/models/custom-fields.models";
import { CustomFieldsAction, IResetIndicatorCustomFieldAction } from "./models";

export interface ICustomFieldsStoreState {
  customFields: ICustomFieldsState;
}

export interface ICustomFieldsState {
  isFetched: boolean;
  fetching?: IIndicator;
  creating?: IIndicator;
  updating?: IIndicator;
  deleting?: IIndicator;
  map: ICustomFieldsAllTypesMap;
  error: IUIError;
}

export function customFieldsReducer(
  state: ICustomFieldsState = {
    isFetched: false,
    fetching: null,
    creating: null,
    updating: null,
    deleting: null,
    map: {} as ICustomFieldsAllTypesMap,
    error: null,
  },
  action: CustomFieldsAction
): ICustomFieldsState {
  switch (action.type) {
    case "REQUEST_CUSTOM_FIELDS":
      return { ...state, fetching: { progress: true } };
    case "RECEIVE_CUSTOM_FIELDS":
      return { ...state, isFetched: true, fetching: null, map: action.map };
    case "RECEIVE_CUSTOM_FIELDS_ERROR":
      return { ...state, fetching: { error: action.error } };

    case "UPDATE_ALL_CUSTOM_FIELDS":
      return { ...state, fetching: { progress: true } };
    case "UPDATE_ALL_CUSTOM_FIELDS_SUCCESS":
      return { ...state, isFetched: true, fetching: null, map: action.map };
    case "UPDATE_ALL_CUSTOM_FIELDS_ERROR":
      return { ...state, fetching: { error: action.error } };

    case "CREATE_CUSTOM_FIELD":
      return { ...state, creating: { progress: true } };
    case "CREATE_CUSTOM_FIELD_SUCCESS":
      return { ...state, creating: null };
    case "CREATE_CUSTOM_FIELD_ERROR":
      return { ...state, creating: { error: action.error } };

    case "UPDATE_CUSTOM_FIELD":
      return { ...state, updating: { progress: true } };
    case "UPDATE_CUSTOM_FIELD_SUCCESS":
      return { ...state, updating: null };
    case "UPDATE_CUSTOM_FIELD_ERROR":
      return { ...state, updating: { error: action.error } };

    case "DELETE_CUSTOM_FIELD":
      return { ...state, deleting: { progress: true } };
    case "DELETE_CUSTOM_FIELD_SUCCESS":
      return { ...state, deleting: null };
    case "DELETE_CUSTOM_FIELD_ERROR":
      return { ...state, deleting: { error: action.error } };

    case "RESET_INDICATOR": {
      const indicator = Object.keys(action).filter((key: keyof IResetIndicatorCustomFieldAction) => key !== "type")[0];
      return { ...state, [indicator]: null };
    }

    default: {
      return state;
    }
  }
}
