import { noop } from "angular";
import { IApmService } from "./apm.models";

export class NullApmService implements IApmService {
  public startDataLoadSpan = noop;
  public startRenderSpan = noop;
  public endDataLoadSpan = noop;
  public endRenderSpan = noop;
  public addLabelsToCurrentTransactions = noop;
  public addMarkToCurrentTransactions = noop;
  public handleTransitionStart = noop;
  public setUserContext = noop;
  public captureError = noop;
  public starTimerEndOffsetSpan = noop;
  public endTimerEndOffsetSpan = noop;
}
