import { IAttributes, IAugmentedJQuery, IDirective, IDirectiveFactory, IScope } from "angular";
import { TrackingMetadata } from "@webapp/analytics/models/analytics.model";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";

interface ITrackOptions {
  on?: string;
}

/**
 * Tracks events on DOM elements
 *
 * @example
 * <a gh-track="Clicked a link">Track clicks on this element</a>
 * <a gh-track="Clicked a link" gh-track-meta="{ year: 2020 }">Track clicks on this element with additional metadata</a>
 * <a gh-track="Clicked a link" gh-track-meta="{ year: 2020, email: vm.email }">Track clicks on this element with additional metadata resolved from current scope</a>
 * <a gh-track="Dropped on a link" gh-track-options="{ on: 'drop' }">Track drops on this element</a>
 */
export class TrackDirective implements IDirective {
  public scope = false;
  public restrict = "A";

  constructor(private analyticsService: AnalyticsService) {}

  public link(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes): void {
    let options: ITrackOptions = {};
    if (attrs.ghTrackOptions) {
      options = scope.$eval(attrs.ghTrackOptions) || {};
    }

    const listenToEvent = options.on || "click";
    element.on(listenToEvent, () => {
      const trackEvent = attrs.ghTrack;
      let meta: TrackingMetadata;
      if (attrs.ghTrackMeta) {
        meta = scope.$eval(attrs.ghTrackMeta);
      }
      this.analyticsService.track(trackEvent, meta);
    });
  }

  public static factory(): IDirectiveFactory {
    const directive = (analyticsService: AnalyticsService) => new TrackDirective(analyticsService);
    directive.$inject = ["AnalyticsService"];
    return directive;
  }
}
