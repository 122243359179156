import { IDirective, IModule, IScope } from "angular";
import { Ng1StateDeclaration, StateService, TransitionService } from "@uirouter/angularjs";
import { localize } from "@gtmhub/localization";
import { PageTitleService } from "@webapp/shared/services/page-title-service";

function getTitleExpressionValue(state: Ng1StateDeclaration): string {
  const data = state.data || {};

  let titleExpression = data.titleExpression || "pageTitle()";

  // component states are wrapped into a separate scope, so we use the child
  // reference here to obtain the correct expression
  const componentLoadedInsideView = state.views && Object.values(state.views).some((view) => typeof view === "object" && view.component);
  if (state.component || componentLoadedInsideView) {
    titleExpression = `$$childHead.${data.titleExpression || "$ctrl.pageTitle()"}`;
  }

  return titleExpression;
}

export function configurePageTitleChange(mod: IModule): void {
  // Change title for states with a dynamic title:
  // * it could be a title expression evaluated against the scope of the state
  // * it could be an implementation of the `IPageTitle` interface of the state/component controller
  //
  // We create a directive override of `ui-view` so that we can access the created scope for the state
  $ViewDirectiveConfigurePageTitle.$inject = ["$state", "PageTitleService"];
  function $ViewDirectiveConfigurePageTitle($state: StateService, pageTitleService: PageTitleService): IDirective {
    const watchForPageTitleChange = (scope: IScope) => {
      const state = $state.current as unknown as Ng1StateDeclaration;

      const data = state.data || {};
      if (data.titleExpression || !data.titleKey || data.pageTitle) {
        const titleExpression = getTitleExpressionValue(state);

        scope.$watchGroup([titleExpression, () => $state.current.name], (changes: string[]) => {
          const title = changes[0];
          if (title && title !== " ") {
            pageTitleService.setPageTitle(title);
          }
        });
      }
    };

    return {
      restrict: "ECA",
      priority: 300,
      link: function (scope: IScope) {
        watchForPageTitleChange(scope);
      },
    };
  }
  mod.directive("uiView", $ViewDirectiveConfigurePageTitle);

  // Change title for states with a static title
  mod.run([
    "$transitions",
    "PageTitleService",
    ($transitions: TransitionService, pageTitleService: PageTitleService) => {
      $transitions.onSuccess({}, (trans) => {
        const data = trans.router.globals.current.data || {};
        if (Object.prototype.hasOwnProperty.call(data, "titleKey")) {
          const title = localize(data.titleKey);
          pageTitleService.setPageTitle(title);
        }
      });
    },
  ]);
}
