import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { ISelectorAssignee } from "@webapp/assignees/models/assignee.models";
import { IPeopleFilterChain, PeopleSelectorRequestInternal } from "../models/models";

@Injectable()
export class LimitFilterChain implements IPeopleFilterChain {
  private nextChain: IPeopleFilterChain;

  public setNextChain(chain: IPeopleFilterChain): void {
    this.nextChain = chain;
  }
  public handle(request: PeopleSelectorRequestInternal, assignees$: Observable<ISelectorAssignee[]>): Observable<ISelectorAssignee[]> {
    if (!request.limit) {
      return this.nextChain ? this.nextChain.handle(request, assignees$) : assignees$;
    }

    const filteredAssignees$ = assignees$.pipe(map((assignees) => assignees.slice(0, request.limit)));

    return this.nextChain ? this.nextChain.handle(request, filteredAssignees$) : filteredAssignees$;
  }
}
