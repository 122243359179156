import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { UiInputDirective } from "@webapp/ui/input/input.directive";

@Component({
  selector: "okr-views-copy-link-form",
  templateUrl: "./okr-views-copy-link-form.component.html",
  styleUrls: ["./okr-views-copy-link-form.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, LocalizationModule, UiIconModule, UiInputDirective],
})
export class OkrViewsCopyLinkFormComponent {
  @Input()
  public url: string;
  @Output()
  public readonly copyLink = new EventEmitter<void>();
}
