import { ChangeDetectionStrategy, Component } from "@angular/core";
import { localize } from "@webapp/localization/utils/localization.utils";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";
import { AutomationOptionCard } from "./choose-automation.model";

@Component({
  selector: "choose-automation",
  templateUrl: "./choose-automation.component.html",
  styleUrls: ["./choose-automation.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseAutomationComponent {
  public cards: AutomationOptionCard[] = [
    {
      title: localize("automate_with_kpi"),
      description: localize("automate_with_kpi_description"),
      icon: "kpi",
      type: "kpi",
      buttonText: localize("choose_a_kpi"),
    },
    {
      title: localize("automate_with_an_insight"),
      description: localize("automate_with_an_insight_description"),
      icon: "automation",
      type: "insight",
      buttonText: localize("choose_a_data_source"),
    },
  ];

  constructor(private modalRef: UiModalRef) {}

  public openCard(card: AutomationOptionCard): void {
    this.modalRef.close(card.type);
  }
}
