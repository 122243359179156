import { NgFor, NgIf, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, Input, QueryList, TemplateRef } from "@angular/core";
import { NzDestroyService } from "ng-zorro-antd/core/services";
import { NzListItemActionsComponent } from "ng-zorro-antd/list";
import { UiListItemActionComponent } from "../list-item-action/list-item-action.component";

@Component({
  selector: "ul[ui-list-item-actions]",
  exportAs: "uiListItemActions",
  templateUrl: "./list-item-actions.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NzDestroyService],
  standalone: true,
  imports: [NgTemplateOutlet, NgFor, NgIf],
})
export class UiListItemActionsComponent extends NzListItemActionsComponent {
  @Input("uiActions") public nzActions: Array<TemplateRef<void>> = [];
  @ContentChildren(UiListItemActionComponent) public nzListItemActions!: QueryList<UiListItemActionComponent>;

  constructor(cdr: ChangeDetectorRef, destroy$: NzDestroyService) {
    super(cdr, destroy$);
  }
}
