import { IController, IControllerService, IModule, auto, extend } from "angular";
import { ITraceRootScopeService } from "../tracing";
import { IStateInit } from "./models";

export const configureStateInit = (mod: IModule) => {
  mod.config([
    "$provide",
    ($provide: auto.IProvideService) => {
      $provide.decorator("$controller", [
        "$delegate",
        "$rootScope",
        // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
        ($delegate: Function, $rootScope: ITraceRootScopeService) => {
          const tryRunStateInit = (ctrl: unknown) => {
            const stateInitCtrl = ctrl as IStateInit;
            if (typeof stateInitCtrl.stateInit === "function") {
              $rootScope.traceInit(stateInitCtrl.stateInit.bind(stateInitCtrl));
            }
          };

          const tryPatchOnInit = (ctrl: unknown) => {
            const initCtrl = ctrl as IController;
            if (initCtrl.$onInit) {
              const origOnInit = initCtrl.$onInit;
              initCtrl.$onInit = function () {
                $rootScope.traceInit(() => origOnInit.bind(initCtrl)());
              };
            }
          };
          return function stateInitAwareController(this: IControllerService, ...args: unknown[]) {
            const ctrl = $delegate.apply(this, args);
            if (typeof ctrl === "function") {
              // Angular.js may create a lazy wrapper for initialization, so
              // we will replace it in a way we can call `stateInit`.
              // PS: search for `$controllerInit` in the Angular.js source code
              // if you want to see their implementation.
              if ("instance" in ctrl && "identifier" in ctrl) {
                return extend(
                  function stateAwareControllerInit() {
                    const instance = ctrl();
                    tryRunStateInit(instance);
                    tryPatchOnInit(instance);
                    return instance;
                  },
                  {
                    instance: ctrl.instance,
                    identifier: ctrl.identifier,
                  }
                );
              }
            } else {
              tryRunStateInit(ctrl);
            }
            return ctrl;
          };
        },
      ]);
    },
  ]);
};
