import { TargetState, UIRouterGlobals } from "@uirouter/angular";
import { ApmBase, Transaction, apm } from "@elastic/apm-rum";
import { EnvironmentService, IAppConfig } from "@gtmhub/env";
import { getCurrentUserId } from "@gtmhub/users";
import { sanitizeHref } from "@webapp/core/logging/logging.utils";
import { storage } from "../storage";
import {
  ANGULAR_PAGE_LOAD_TRANSACTION_TYPE,
  ANGULAR_ROUTE_CHANGE_TRANSACTION_TYPE,
  ApmTransaction,
  ApmTransactionProxy,
  BLACKLIST,
  BLOCKING_SPAN_NAME,
  CoreApmSpan,
  CoreApmTransaction,
  DATA_LOAD_SPAN_TYPE,
  DUPLICATED_SPAN_TYPE,
  IApmService,
  PAGE_LOAD_TRANSACTION_TYPE,
  PENDING_DATA_LOAD_SPAN_TYPE,
  PENDING_RENDER_SPAN_TYPE,
  RENDER_ENDED_MARK_NAME,
  RENDER_SPAN_TYPE,
  ROUTE_CHANGE_BLOCKING_SPAN_TYPE,
  ROUTE_CHANGE_TRANSACTION_TYPE,
  SHORT_OFFSET,
  TIMER_END_OFFSET,
} from "./apm.models";

let isPerformanceTest: boolean;
window.setRUMPerfTestLabels = (labels) => {
  isPerformanceTest = true;
  ApmService.setPerfTestLabels(labels);
};

export class ApmService implements IApmService {
  private static apmInstance: ApmBase | null;
  /** Proxy of the built-in `"page-load"` transaction that starts on wep app initialization (after reload or successful login). */
  private static pageLoadTransaction: ApmTransactionProxy;
  /**
   * Proxy of the custom non-managed (transaction options = `{ managed: false }`) `"angular-page-load"` transaction that starts if we have "render" or "data-load" spans added to the current screen.
   * Ref: https://www.elastic.co/guide/en/apm/agent/rum-js/5.x/agent-api.html#apm-start-transaction
   */
  private static angularPageLoadTransaction: ApmTransactionProxy;
  /** Proxy of the built-in `"route-change"` transaction that starts on each route change after the `"page-load"` transaction ends. */
  private static routeChangeTransaction: ApmTransactionProxy;
  private static angularRouteChangeTransaction: ApmTransactionProxy;
  private lastCustomSpanName: string;
  private accountIdAdded = false;
  private modifiedPageLoadTransaction = { blockingSpanAdded: false, nameChanged: false };

  constructor(
    appConfig: IAppConfig,
    env: EnvironmentService,
    private $uiRouterGlobals: UIRouterGlobals
  ) {
    ApmService.apmInstance = apm.init({
      serviceName: "gtmhub-frontend",
      serverUrl: appConfig.rum.url,
      propagateTracestate: true,
      breakdownMetrics: true,
      distributedTracingOrigins: [env.getWebappEndpoint()],
      environment: appConfig.rum.environment,
      // https://www.elastic.co/guide/en/apm/agent/rum-js/current/configuration.html#disable-instrumentations
      disableInstrumentations: ["click"],
      // https://www.elastic.co/guide/en/apm/agent/rum-js/current/configuration.html#ignore-transactions
      ignoreTransactions: [/\*"apiffe.quantive.com*/, /\*apiff.quantive.com*/, /\*apipa.quantive.com*/],
    });
    this.setAccountId();

    const currentUserId = getCurrentUserId();
    if (currentUserId) {
      this.setUserContext({ id: currentUserId });
    }

    const sanitizedUrl = sanitizeHref(window.location.href);
    ApmService.apmInstance.setInitialPageLoadName(sanitizedUrl);

    this.observeTransactionStart();
    this.observeTransactionEnd();
  }

  private observeTransactionEnd(): void {
    ApmService.apmInstance.observe("transaction:end", (transaction) => {
      if (transaction.type === PAGE_LOAD_TRANSACTION_TYPE) {
        // the page load transaction variable may still hold its value in some cases
        this.resetPageLoadTransaction();
      }

      this.setAccountId();
      const apmTransaction: CoreApmTransaction = transaction as unknown as CoreApmTransaction;

      this.setPerformanceStats(apmTransaction);
      if (this.isRouteChangeOrPageLoad(apmTransaction)) {
        const lastCustomSpan = apmTransaction.spans.find((span) => span.name === this.lastCustomSpanName) as CoreApmSpan;
        let maxSpanEnd = 0;

        apmTransaction.spans.forEach((apmSpan) => {
          const span: CoreApmSpan = apmSpan as CoreApmSpan;

          this.spanApplyAction(span, lastCustomSpan);

          if (span._end > maxSpanEnd) {
            maxSpanEnd = span._end;
          }
        });

        if (apmTransaction._start > maxSpanEnd) {
          apmTransaction._start = maxSpanEnd;
        }

        apmTransaction._end = maxSpanEnd;

        this.markRenderEnd(apmTransaction, { pageLoadTime: maxSpanEnd });
      }

      if (isPerformanceTest) {
        // eslint-disable-next-line no-console
        console.log(`${apmTransaction.type}, id:${apmTransaction.id}`);
      }
    });
  }

  private observeTransactionStart(): void {
    ApmService.apmInstance.observe("transaction:start", (transaction) => {
      if (transaction.type === ROUTE_CHANGE_TRANSACTION_TYPE) {
        this.processStartingRouteChangeTransaction(transaction);
      }
    });
  }

  /**
   * Sets the name of the `"route-change"` transaction to the current state name
   * and adds managed-transaction-labels to the `"angular-page-load"` and `"angular-route-change"` transactions (if already started)
   */
  private processStartingRouteChangeTransaction(routeChangeTransaction: Transaction): void {
    routeChangeTransaction.name = this.$uiRouterGlobals.current.name;
    ApmService.routeChangeTransaction = this.setupTransactionProxy(routeChangeTransaction);
    this.addBlockingSpanToTransaction(ApmService.routeChangeTransaction);

    const { id: managedTransactionId, type: managedTransactionType } = ApmService.routeChangeTransaction;
    // The "route-change" transaction may start before or after the "angular-page-load" and/or "angular-route-change" transaction
    // If the "angular-page-load" and/or "angular-route-change" transaction has started => add the managed transaction labels to it
    ApmService.angularPageLoadTransaction?.addLabels({ managedTransactionId, managedTransactionType });
    ApmService.angularRouteChangeTransaction?.addLabels({ managedTransactionId, managedTransactionType });
  }

  private setPerformanceStats(apmTransaction: CoreApmTransaction) {
    const connectionInfo = window.navigator?.connection;
    const memory = window.performance?.memory;
    if (connectionInfo) {
      apmTransaction.addLabels({ rttMs: connectionInfo.rtt });
      apmTransaction.addLabels({ downlinkMbps: connectionInfo.downlink });
      apmTransaction.addLabels({ connectionEffectiveType: connectionInfo.effectiveType });
    }
    if (memory) {
      apmTransaction.addLabels({ jsHeapSizeLimit: memory.jsHeapSizeLimit });
      apmTransaction.addLabels({ usedJSHeapSize: memory.usedJSHeapSize });
      apmTransaction.addLabels({ totalJSHeapSize: memory.totalJSHeapSize });
    }
  }

  private setAccountId(): void {
    if (this.accountIdAdded) return;
    const accountId = storage.get("accountId");
    if (accountId) {
      ApmService.apmInstance.addLabels({
        accountId: accountId as string,
      });
      this.accountIdAdded = true;
    }
  }

  public captureError(error: string | Error): void {
    ApmService.apmInstance.captureError(error);
  }

  public static setPerfTestLabels(labels: Labels): void {
    ApmService.apmInstance.addLabels(labels);
  }

  public setUserContext(context: UserObject): void {
    ApmService.apmInstance.setUserContext(context);
  }

  public addLabelsToCurrentTransactions(labels: Labels): void {
    for (const transaction of this.getOpenedTransactions()) {
      transaction.addLabels(labels);
    }
  }

  public addMarkToCurrentTransactions(key: string): void {
    for (const transaction of this.getOpenedTransactions()) {
      transaction.mark(key);
    }
  }

  /**
   * The `"page-load"` transactions starts before the first transition is started.
   * The transition's start always precedes the `"angular-page-load"`, `"angular-route-change"`, and the `"route-change"` transactions start.
   */
  public handleTransitionStart(targetState: TargetState) {
    this.processPageLoadTransaction(targetState);
    this.resetAngularPageLoadTransaction(targetState);
    this.resetRouteChangeTransaction(targetState);
    this.processAngularRouteChangeTransaction(targetState);
  }

  public starTimerEndOffsetSpan(name: string): void {
    this.startSpan(name, TIMER_END_OFFSET);
  }

  public endTimerEndOffsetSpan(name: string): void {
    this.endSpan(name, TIMER_END_OFFSET);
  }

  public startDataLoadSpan(name: string): void {
    this.startSpan(name, PENDING_DATA_LOAD_SPAN_TYPE);
  }

  public startRenderSpan(name: string): void {
    this.startSpan(name, PENDING_RENDER_SPAN_TYPE);
  }

  public endDataLoadSpan(name: string): void {
    this.endSpan(name, DATA_LOAD_SPAN_TYPE);
  }

  public endRenderSpan(name: string): void {
    this.endSpan(name, RENDER_SPAN_TYPE);
  }

  /**
   * The `"page-load"` transaction is treated as current by the AMP instance until it ends.
   * The AMP instance does not treat our custom `"angular-page-load"` transaction as current when in progress.
   * It starts automatically after login(e.g. "resolve-token"=>"resolve-account"=>"gtmhub.home.feed") or page reload (when fetching html, css, JS, etc.).
   *
   * If the `"page-load"` has just started, add a "blocking" span to it.
   *
   * If the target state is marked to be ignored by APM, add a "non-interactive" span to the transaction.
   * When the target is not ignored, set the transaction name, add the mandatory labels, and end the "non-interactive" span.
   *
   * If the transaction is fully processed (e.g. reloading the browser when visiting "home" and in a few seconds navigate to "KPIs"),
   * end the "blocking" span and reset the transaction variable.
   */
  private processPageLoadTransaction(targetState: TargetState): void {
    if (ApmService.pageLoadTransaction && this.pageLoadTransactionIsFullyProcessed) {
      // the page load transaction is fully processed but interrupted by a transition
      // in most cases we will have all the data needed, just the blocking span is preventing the transaction from ending
      ApmService.pageLoadTransaction?.addLabels(this.generateStateChangeToLabel(targetState));
      ApmService.pageLoadTransaction?.endSpanByName(BLOCKING_SPAN_NAME, ROUTE_CHANGE_BLOCKING_SPAN_TYPE);
      this.resetPageLoadTransaction();

      return;
    }

    const currentTransaction = ApmService.apmInstance.getCurrentTransaction();
    if (currentTransaction?.type !== PAGE_LOAD_TRANSACTION_TYPE) {
      return;
    }

    // the app is initializing - either reloading or after login

    if (!this.modifiedPageLoadTransaction.blockingSpanAdded) {
      // the first state that is navigated to - resolve-token after login or the state corresponding to the url after reload
      ApmService.pageLoadTransaction = this.setupTransactionProxy(currentTransaction);
      // add the blocking span so the transaction won't end before an "angular-page-load" transaction is started
      // e.g. login -> resolve-token: (add a blocking span to "page-load" transaction) -> resolve-account -> home (start "angular-page-load" transaction)
      this.addBlockingSpanToTransaction(ApmService.pageLoadTransaction);
      this.modifiedPageLoadTransaction.blockingSpanAdded = true;
    }

    if (targetState.state().data?.ignoreApm === true) {
      // the states that have been marked to be ignored by APM
      ApmService.pageLoadTransaction?.startSpanWithName(`noninteractive-state`, TIMER_END_OFFSET);
    } else {
      // the first reached state that is shall be traced by APM => set the transaction name and add the mandatory labels
      currentTransaction.name = targetState.name();
      this.modifiedPageLoadTransaction.nameChanged = true;
      ApmService.pageLoadTransaction?.endSpanByName(`noninteractive-state`, TIMER_END_OFFSET);
      ApmService.pageLoadTransaction?.addLabels(this.getMandatoryLabels(ApmService.pageLoadTransaction));
    }
  }

  private get pageLoadTransactionIsFullyProcessed(): boolean {
    return this.modifiedPageLoadTransaction.nameChanged && this.modifiedPageLoadTransaction.blockingSpanAdded;
  }

  private resetPageLoadTransaction(): void {
    ApmService.pageLoadTransaction = null;
  }

  /**
   * Before the next state is entered, if the `"route-change"` transaction exists,
   * add labels related to the target state, end the blocking span, and delete the related variable value.
   */
  private resetRouteChangeTransaction(targetState: TargetState): void {
    ApmService.routeChangeTransaction?.addLabels(this.generateStateChangeToLabel(targetState));
    ApmService.routeChangeTransaction?.endSpanByName(BLOCKING_SPAN_NAME, ROUTE_CHANGE_BLOCKING_SPAN_TYPE);
    ApmService.routeChangeTransaction = null;
  }

  private processAngularRouteChangeTransaction(targetState: TargetState): void {
    this.resetAngularRouteChangeTransaction(targetState);

    if (targetState.state().data?.ignoreApm === true) {
      return;
    }

    this.startAngularRouteChangeTransaction(targetState);
  }

  private resetAngularRouteChangeTransaction(targetState: TargetState): void {
    this.endAngularTransaction(ApmService.angularRouteChangeTransaction, targetState);
    ApmService.angularRouteChangeTransaction = null;
  }

  private startAngularRouteChangeTransaction(targetState: TargetState): void {
    const angularRouteChangeTransaction = ApmService.apmInstance.startTransaction(targetState.name(), ANGULAR_ROUTE_CHANGE_TRANSACTION_TYPE, { managed: false });
    ApmService.angularRouteChangeTransaction = this.setupTransactionProxy(angularRouteChangeTransaction);
    ApmService.angularRouteChangeTransaction.resetTransactionEndTimer(SHORT_OFFSET);
    ApmService.angularRouteChangeTransaction.addLabels(this.getManagedByTransactionLabels());
  }

  /**
   * Before the next state is entered, if the `"angular-page-load"` transaction exists,
   * add labels related to the target state, end it if in progress, and delete the related variable value.
   */
  private resetAngularPageLoadTransaction(targetState: TargetState): void {
    this.endAngularTransaction(ApmService.angularPageLoadTransaction, targetState);
    ApmService.angularPageLoadTransaction = null;
  }

  private endAngularTransaction(transaction: ApmTransactionProxy, targetState: TargetState): void {
    transaction?.addLabels(this.generateStateChangeToLabel(targetState));
    if (!transaction?.ended) {
      transaction?.end();
    }
  }

  private generateStateChangeToLabel(targetState: TargetState): Labels & { stateChangedTo: string } {
    return { stateChangedTo: targetState.name() };
  }

  private startAngularPageLoadTransaction(): void {
    const angularPageLoadTransaction = ApmService.apmInstance.startTransaction(this.$uiRouterGlobals.current.name, ANGULAR_PAGE_LOAD_TRANSACTION_TYPE, {
      managed: false,
    });
    ApmService.angularPageLoadTransaction = this.setupTransactionProxy(angularPageLoadTransaction);
    ApmService.angularPageLoadTransaction.resetTransactionEndTimer(SHORT_OFFSET);
    ApmService.angularPageLoadTransaction.addLabels(this.getManagedByTransactionLabels());
  }

  private setupTransactionProxy(apmTransaction: ApmTransaction): ApmTransactionProxy {
    const transaction = ApmTransactionProxy.newInstance(apmTransaction);
    apmTransaction.addLabels(this.getMandatoryLabels(transaction));

    return transaction;
  }

  private getOpenedTransactions(): ApmTransactionProxy[] {
    return [ApmService.pageLoadTransaction, ApmService.routeChangeTransaction, ApmService.angularPageLoadTransaction, ApmService.angularRouteChangeTransaction].filter(
      Boolean
    );
  }

  /**
   * Adds a blocking span the built-in `"page-load"` or `"route-change"` transactions.
   * The blocking span is used to prevent the transaction from ending before the `"angular-page-load"` transaction is started.
   * This way the built-in transactions will include our `"data-load"` and `"render"` spans.
   * The blocking span is later ignored from the performance stats.
   */
  private addBlockingSpanToTransaction(transactionProxy: ApmTransactionProxy): void {
    transactionProxy.startSpanWithName(BLOCKING_SPAN_NAME, ROUTE_CHANGE_BLOCKING_SPAN_TYPE, { blocking: true });
  }

  private startSpan(name: string, type: string): void {
    if (!ApmService.angularPageLoadTransaction || ApmService.angularPageLoadTransaction.ended) {
      this.startAngularPageLoadTransaction();
    }

    for (const transaction of this.getOpenedTransactions()) {
      transaction.customSpans = true;
      transaction.startSpanWithName(name, type, { labels: this.getMandatoryLabels(transaction) });
    }
  }

  private endSpan(name: string, type: string): void {
    for (const transaction of this.getOpenedTransactions()) {
      this.lastCustomSpanName = name;
      transaction.endSpanByName(name, type);
    }
  }

  private spanApplyAction(span: CoreApmSpan, lastCustomSpan: CoreApmSpan): void {
    if (lastCustomSpan && span._start > lastCustomSpan._end) {
      this.markForExclusion(span);
    }
    if (span.type.indexOf(DUPLICATED_SPAN_TYPE) != -1 || span.type.indexOf(ROUTE_CHANGE_BLOCKING_SPAN_TYPE) != -1) {
      this.markForExclusion(span);
    }
    if (span.context && span.context.destination && BLACKLIST[span.context.destination.address]) {
      this.markForExclusion(span);
    }
    if (span.type.indexOf(PENDING_RENDER_SPAN_TYPE) != -1) {
      this.adjustSpan(span, PENDING_RENDER_SPAN_TYPE);
    }
    if (span.type.indexOf(PENDING_DATA_LOAD_SPAN_TYPE) != -1) {
      this.adjustSpan(span, PENDING_DATA_LOAD_SPAN_TYPE);
    }
  }

  private adjustSpan(span: CoreApmSpan, type: string): void {
    span._end = span._start + 1;
    span.name = span.name + " - not resolved";
    span.type = type;
  }

  private markForExclusion(span: CoreApmSpan): void {
    span._end = 0;
  }

  private markRenderEnd(apmTransaction: CoreApmTransaction, { pageLoadTime }: { pageLoadTime: number }): void {
    performance.mark(RENDER_ENDED_MARK_NAME, {
      detail: {
        pageLoadTime,
        transactionId: apmTransaction.id,
      },
    });
  }

  private isRouteChangeOrPageLoad(apmTransaction: CoreApmTransaction): boolean {
    return (
      apmTransaction.type === PAGE_LOAD_TRANSACTION_TYPE ||
      apmTransaction.type === ROUTE_CHANGE_TRANSACTION_TYPE ||
      apmTransaction.type === ANGULAR_PAGE_LOAD_TRANSACTION_TYPE ||
      apmTransaction.type === ANGULAR_ROUTE_CHANGE_TRANSACTION_TYPE
    );
  }

  private getMandatoryLabels(transaction: ApmTransactionProxy): Labels & Record<"transactionType" | "transactionName", string> {
    return { transactionName: transaction.name, transactionType: transaction.type };
  }

  /**
   * When starting "angular-page-load" or "angular-route-change" transaction:
   * If there is an active managed transaction, we add the managed transaction labels to the "angular-page-load" or "angular-route-change" transaction.
   * If there is not an active transaction (e.g. "route-change" starts after), the labels will be added when the "route-change" transaction is started
   *
   * @returns Labels with the managed transaction id and type
   */
  private getManagedByTransactionLabels(): Labels & Record<"managedTransactionId" | "managedTransactionType", string> {
    const currentTransactionProxy = ApmService.pageLoadTransaction || ApmService.routeChangeTransaction || { id: undefined, type: undefined };
    const { id: managedTransactionId, type: managedTransactionType } = currentTransactionProxy;

    return { managedTransactionId, managedTransactionType };
  }
}
