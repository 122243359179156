import { IHttpService, IRequestShortcutConfig } from "angular";
import { ThunkAction } from "redux-thunk";
import { ApmSpanService } from "@gtmhub/core/tracing/apm-span.service";
import { EnvironmentService } from "@gtmhub/env";
import { IUIError, createUIError } from "@gtmhub/error-handling";
import { IFeedParams } from "@gtmhub/feed/controllers/feed";
import { IFeedEntry, IFeedEntryComment, IFeedResponse } from "@gtmhub/feed/models";
import { FeedActionsEnum } from "@gtmhub/feed/redux/feed-actions.enum";
import { IFeedStoreState } from "@gtmhub/feed/redux/feed-reducer";
import {
  FeedAction,
  IClearFetchedDataAction,
  IClearSelectedFeedEntryAction,
  IErrorRequestFeedAction,
  IReceiveFeedAction,
  IReceiveFeedEntryAction,
  IRequestFeedAction,
  ISetFeedEntryAction,
  IUpdateFeedEntryCommentsAction,
} from "./models";

const requestFeed = (): IRequestFeedAction => ({ type: FeedActionsEnum.REQUEST_FEED });
const setSelectedFeedEntry = (feedEntry: IFeedEntry): ISetFeedEntryAction => ({ type: FeedActionsEnum.SET_SELECTED_FEED_ENTRY, feedEntry });
const receiveFeed = (feed: IFeedResponse): IReceiveFeedAction => ({ type: FeedActionsEnum.RECEIVE_FEED, feed });
const receiveFeedEntry = (feedEntry: IFeedEntry): IReceiveFeedEntryAction => ({ type: FeedActionsEnum.RECEIVE_FEED_ENTRY, feedEntry });
const shouldFetchFeed = (state: IFeedStoreState) => !state.feed.isFetching;
const clearFetchedData = (): IClearFetchedDataAction => ({ type: FeedActionsEnum.CLEAR_FETCHED_DATA });
const clearSelectFeedEntry = (): IClearSelectedFeedEntryAction => ({ type: FeedActionsEnum.CLEAR_SELECTED_FEED_ENTRY });
const fetchFeedError = (error: IUIError): IErrorRequestFeedAction => ({ type: FeedActionsEnum.ERROR_REQUEST_FEED, error });
const updateFeedEntryComments = (id: string, comments: IFeedEntryComment[]): IUpdateFeedEntryCommentsAction => ({
  type: FeedActionsEnum.UPDATE_FEED_ENTRY_COMMENTS,
  feedEntryId: id,
  comments,
});

export class FeedActions {
  public static $inject = ["$http", "EnvironmentService", "ApmSpanService"];

  constructor(
    private $http: IHttpService,
    private env: EnvironmentService,
    private apmSpanService: ApmSpanService
  ) {}

  public selectFeedEntryById(id: string): ThunkAction<void, IFeedStoreState, null, FeedAction> {
    return (dispatch, getState) => {
      const feedEntry = getState().feed.feed?.find((it) => it.id === id);

      // If feed entries is in the already pulled data, use it
      // Otherwise pull it from the API
      if (feedEntry) {
        return dispatch(setSelectedFeedEntry(feedEntry));
      } else {
        return dispatch(this.fetchAndSelectFeedEntryById(id));
      }
    };
  }

  public selectFeedEntry(feedEntry: IFeedEntry): ISetFeedEntryAction {
    return setSelectedFeedEntry(feedEntry);
  }

  public fetchFeed(params: IFeedParams): ThunkAction<void, IFeedStoreState, null, FeedAction> {
    return (dispatch, getState) => {
      if (shouldFetchFeed(getState())) {
        dispatch(requestFeed());

        const url = this.env.getApiEndpoint("/feed");
        const config: IRequestShortcutConfig = {
          params,
        };

        this.apmSpanService.dataLoadSpan("feed-load", () => {
          return this.$http.get<IFeedResponse>(url, config).then(
            (response) => {
              params.skip = params.skip + params.take;

              dispatch(receiveFeed(response.data));
            },
            (rejection) => dispatch(fetchFeedError(createUIError(rejection)))
          );
        });
      }
    };
  }

  public fetchAndSelectFeedEntryById(id: string): ThunkAction<void, IFeedStoreState, null, FeedAction> {
    return (dispatch) => {
      const url = this.env.getApiEndpoint(`/feed/${id}`);
      this.$http.get<IFeedEntry>(url).then(
        (response) => {
          dispatch(receiveFeedEntry(response.data));
        },
        (rejection) => dispatch(fetchFeedError(createUIError(rejection)))
      );
    };
  }

  public updateFeedEntryComments(feedEntryId: string, comments: IFeedEntryComment[]): IUpdateFeedEntryCommentsAction {
    return updateFeedEntryComments(feedEntryId, comments);
  }

  public clearFeedData(): IClearFetchedDataAction {
    return clearFetchedData();
  }

  public clearSelectFeedEntry(): IClearSelectedFeedEntryAction {
    return clearSelectFeedEntry();
  }
}
