<div
  class="rich-text-editor-container"
  #richTextEditor
  [attr.tabindex]="readonly ? 0 : -1"
  [ngClass]="{
    inline: contentOnly || (inline && !isFocused && !readonly),
    outOfFocus: !isFocused,
    'inline-with-border': inlineWithBorder,
    'inline-focus': inline && isFocused,
    'readonly-no-border': contentOnly,
    'readonly-focus': readonly,
    'disabled-container': disabled,
    ellipsis: ellipsis,
  }">
  <quill-editor
    [(ngModel)]="richText"
    [attr.data-id]="uiId"
    [modules]="modules"
    [placeholder]="!readonly && !disabled && placeholderToShow ? placeholderToShow : ''"
    [readOnly]="readonly || contentOnly"
    [disabled]="disabled"
    [attr.data-a11y-label]="a11yLabel"
    [attr.data-a11y-labelledby]="a11yLabelledby"
    [attr.data-a11y-description]="placeholderToShow"
    [attr.data-a11y-required]="a11yRequired"
    (onContentChanged)="onContentChanged()"
    (onEditorCreated)="onEditorCreated()"
    (onFocus)="focusHandler()"
    bounds="self"
    data-test-id="rich-text-editor"
    customToolbarPosition="bottom"
    theme="ghQuillTheme">
    <div [hidden]="contentOnly || (inline && !isFocused)" (mousedown)="toolBarHandler($event)" quill-editor-toolbar>
      <div class="selected-gif-preview" *ngIf="gif && gif.id">
        <gif-view [gifSize]="sizeOfGif.Small" [gif]="gif"></gif-view>

        <div class="remove-gif-btn" (click)="removeGif()">
          <span class="nc-icon-outline icon ui-1_simple-remove"></span>
        </div>
      </div>

      <span class="ql-formats" [hidden]="hideToolbarWhenUnfocused && !isFocused">
        <button class="ql-bold" aria-label="bold" type="button">
          <rich-text-editor-icon [icon]="icons.BOLD" [outOfFocus]="!isFocused"></rich-text-editor-icon>
        </button>
        <button class="ql-italic" aria-label="italic" type="button">
          <rich-text-editor-icon [icon]="icons.ITALIC" [outOfFocus]="!isFocused"></rich-text-editor-icon>
        </button>
        <button class="ql-strike" aria-label="strikethrough" type="button">
          <rich-text-editor-icon [icon]="icons.STRIKE" [outOfFocus]="!isFocused"></rich-text-editor-icon>
        </button>
        <button class="ql-list" aria-label="ordered list" type="button" value="ordered">
          <rich-text-editor-icon [icon]="icons.ORDERED" [outOfFocus]="!isFocused"></rich-text-editor-icon>
        </button>
        <button class="ql-list" aria-label="unordered list" type="button" value="bullet">
          <rich-text-editor-icon [icon]="icons.BULLET" [outOfFocus]="!isFocused"></rich-text-editor-icon>
        </button>

        <div class="vertical-separator"></div>

        <button class="ql-link" aria-label="hyperlink" type="button">
          <rich-text-editor-icon [icon]="icons.LINK" [outOfFocus]="!isFocused"></rich-text-editor-icon>
        </button>

        <add-gif *ngIf="!withoutGifs" [outOfFocus]="!isFocused" (gifSelected)="selectGif($event)" (selectorOpen)="popupMenuOpen()"></add-gif>

        <button class="mention-btn" (click)="showMentionMenu()" (blur)="onBlurMenus($event, 'mention')" aria-label="mention" type="button">
          <rich-text-editor-icon [icon]="icons.MENTION" [outOfFocus]="!isFocused"></rich-text-editor-icon>
        </button>

        <emoji-picker [outOfFocus]="!isFocused" (selectedEmoji)="addEmoji($event)" (pickerOpen)="popupMenuOpen()" (pickerClose)="focusInput()"></emoji-picker>

        <ng-container *hasAnyPermissions="['AccessGoals', 'AccessKPIs']">
          <button class="okr-link-selector-btn" *ngIf="isOKRLinkSelectorEnabled" (blur)="onBlurMenus($event, 'okr')" (click)="showOKRLinkMenu()" aria-label="Insert OKR or KPI" type="button">
            <rich-text-editor-icon [icon]="icons.OKR" [outOfFocus]="!isFocused"></rich-text-editor-icon>
            <span class="okr-link-selector-btn-tooltip"> {{ "link_selector_insert_okr_or_kpi" | localize }} </span>
          </button>
        </ng-container>

        <button
          class="post-comment-btn"
          *ngIf="!hidePostButton"
          [disabled]="!canPostContent()"
          [ngClass]="{ disabled: !canPostContent() }"
          [a11yTitle]="postButtonA11yLabel"
          (click)="postContent()"
          (blur)="blurHandler()"
          data-test-id="rich-text-post-comment-btn">
          <rich-text-editor-icon [icon]="icons.POST_COMMENT" [outOfFocus]="!isFocused" [disabled]="!canPostContent()"></rich-text-editor-icon>
        </button>
      </span>
    </div>
  </quill-editor>

  <ui-mention-popover *ngIf="mentionElRef" [user]="mentionPopoverUser" [sourceMentionEl]="mentionElRef" (closed)="mentionPopoverClosed()"></ui-mention-popover>
</div>
<div class="external-editor-buttons-container" *ngIf="showExternalEditorButtons">
  <button [disabled]="editorButtonsDisabled" (click)="cancel.emit()" type="button" ui-button uiType="default" uiShape="round" uiSize="default">
    {{ "cancel" | localize }}
  </button>
  <button
    class="post-button"
    [disabled]="!canPostContent()"
    [attr.aria-label]="postButtonA11yLabel"
    (click)="postContent()"
    data-test-id="external-post-button"
    type="submit"
    ui-button
    uiType="primary"
    uiShape="round"
    uiSize="default">
    {{ "confirm" | localize }}
  </button>
</div>
