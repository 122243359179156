import { NgIf, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, HostBinding, Input, TemplateRef } from "@angular/core";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzListItemComponent } from "ng-zorro-antd/list";
import { UiListComponent } from "../../list.component";
import { UiListItemActionsComponent } from "../list-item-actions/list-item-actions.component";
import { UiListItemExtraComponent } from "../list-item-extra/list-item-extra.component";

@Component({
  selector: "ui-list-item, [ui-list-item]",
  exportAs: "uiListItem",
  templateUrl: "./list-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, UiListItemActionsComponent, NzOutletModule, NgTemplateOutlet, UiListItemExtraComponent],
})
export class UiListItemComponent extends NzListItemComponent {
  @Input("uiActions") public nzActions: Array<TemplateRef<void>> = [];
  @Input("uiContent") public nzContent?: string | TemplateRef<void>;
  @Input("uiExtra") public nzExtra: TemplateRef<void> | null = null;
  @Input("uiNoFlex") @InputBoolean() @HostBinding("class.ant-list-item-no-flex") public nzNoFlex = false;

  @ContentChild(UiListItemExtraComponent) public listItemExtraDirective?: UiListItemExtraComponent;

  constructor(parentComp: UiListComponent, cdr: ChangeDetectorRef) {
    super(parentComp, cdr);
  }
}
