import { IModule, IRootScopeService, ITimeoutService } from "angular";
import { ISocketV2Data } from "@gtmhub/sockets";
import { AssigneeComponentInstances } from "@webapp/assignees/component-instance-tracker";
import { Assignee } from "@webapp/assignees/models/assignee.models";

/**
 * When assignees are updated via WebSockets, we manually go over all registered avatar instances and trigger the change detection.
 */
export function attachAssigneeAvatarEnhancements(mod: IModule): void {
  mod.run([
    "$rootScope",
    "$timeout",
    ($rootScope: IRootScopeService, $timeout: ITimeoutService) => {
      $rootScope.$on("accountAssigneesFetched", () => {
        // This timeout is a hack, so that we trigger the markForCheck after $redux has been updated
        // When we switch to ngrx (or other state management solution), we could refactor this
        $timeout(() => {
          for (const component of AssigneeComponentInstances.values()) {
            component.refresh();
          }
        });
      });

      $rootScope.$on("accountAssigneesUpdated", (evt, data: ISocketV2Data<Assignee>) => {
        const changedAssigneeIds = new Set<string>([
          ...(data.added?.items || []).map((x) => x.id),
          ...(data.modified?.items || []).map((x) => x.id),
          ...(data.removed?.items || []).map((x) => x.id),
        ]);

        // This timeout is a hack, so that we trigger the markForCheck after $redux has been updated
        // When we switch to ngrx (or other state management solution), we could refactor this
        $timeout(() => {
          for (const component of AssigneeComponentInstances.values()) {
            if (component.assigneeIds.some((assigneeId) => changedAssigneeIds.has(assigneeId))) {
              component.refresh();
            }
          }
        });
      });
    },
  ]);
}
