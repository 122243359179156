import { module } from "angular";
import upgrade from "@gtmhub/core/upgrade/module";
import { QuantivePlusEffectivenessFacade } from "@webapp/platform-intelligence/expandable-suggestions/shared/services/quantive-plus-effectiveness/quantive-plus-effectiveness-facade.service";
import { QuantivePlusExplanationFacade } from "@webapp/platform-intelligence/expandable-suggestions/shared/services/quantive-plus-explanation/quantive-plus-explanation-facade.service";
import { CreateKrSuggestionDrawerLegacyComponent } from "@webapp/platform-intelligence/expandable-suggestions/suggestion-drawer/create-kr-suggestion-drawer-legacy/create-kr-suggestion-drawer-legacy.component";
import { CreateObjectiveSuggestionDrawerComponent } from "@webapp/platform-intelligence/expandable-suggestions/suggestion-drawer/create-objective-suggestion-drawer/create-objective-suggestion-drawer.component";
import { UpdateKrSuggestionDrawerComponent } from "@webapp/platform-intelligence/expandable-suggestions/suggestion-drawer/update-kr-suggestion-drawer/update-kr-suggestion-drawer.component";
import { UpdateObjectiveSuggestionDrawerComponent } from "@webapp/platform-intelligence/expandable-suggestions/suggestion-drawer/update-objective-suggestion-drawer/update-objective-suggestion-drawer.component";
import { SuggestionStateIndicatorComponent } from "@webapp/platform-intelligence/expandable-suggestions/suggestion-state-indicator/suggestion-state-indicator.component";
import { QuantivePlusMetricsComponent } from "@webapp/platform-intelligence/quantive-plus/components/quantive-plus-metrics/quantive-plus-metrics.component";
import { QuantivePlusObjectiveDescriptionComponent } from "@webapp/platform-intelligence/quantive-plus/components/quantive-plus-objective-description/quantive-plus-objective-description.component";
import { QuantivePlusObjectiveTagsComponent } from "@webapp/platform-intelligence/quantive-plus/components/quantive-plus-objective-tags/quantive-plus-objective-tags.component";
import { QuantivePlusSuggestionCardTagsComponent } from "@webapp/platform-intelligence/quantive-plus/components/quantive-plus-suggestion-card-tags/quantive-plus-suggestion-card-tags.component";
import { QuantivePlusTasksComponent } from "@webapp/platform-intelligence/quantive-plus/components/quantive-plus-tasks/quantive-plus-tasks.component";
import { PiClickableErrorCardComponent } from "@webapp/platform-intelligence/shared/components/pi-clickable-error-card/pi-clickable-error-card.component";
import { PiContextualSuggestionButtonComponent } from "@webapp/platform-intelligence/shared/components/pi-contextual-suggestion-button/pi-contextual-suggestion-button.component";
import { PiErrorCardComponent } from "@webapp/platform-intelligence/shared/components/pi-error-card/pi-error-card.component";
import { PiFeedbackCardComponent } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/pi-feedback-card.component";
import { PiImprovementFacade } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/improvement/pi-improvement-facade.service";
import { PiStateProcessorService } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.service";
import { PiLoadingCardV2Component } from "@webapp/platform-intelligence/shared/components/pi-loading-card-v2/pi-loading-card-v2.component";
import { PiLoadingCardComponent } from "@webapp/platform-intelligence/shared/components/pi-loading-card/pi-loading-card.component";
import { PiTextCardComponent } from "@webapp/platform-intelligence/shared/components/pi-text-card/pi-text-card.component";
import { PiFeaturePackagingFacade } from "@webapp/platform-intelligence/shared/services/pi-feature-packaging-facade.service";
import { PiFeatureVisibilityFacade } from "@webapp/platform-intelligence/shared/services/pi-feature-visibility-facade.service";
import { PiSuggestionSharedGoalStateService } from "@webapp/platform-intelligence/shared/services/pi-suggestion-shared-goal-state.service";
import { PiSuggestionSharedMetricStateService } from "@webapp/platform-intelligence/shared/services/pi-suggestion-shared-metric-state.service";
import { QuantivePlusSuggestionsFacade } from "@webapp/platform-intelligence/shared/services/quantive-plus-suggestions/quantive-plus-suggestions-facade.service";
import { QuantivePlusWhiteboardFacade } from "@webapp/platform-intelligence/shared/services/quantive-plus-whiteboard/quantive-plus-whiteboard-facade.service";
import { StrategicGuidedOkrFacade } from "@webapp/platform-intelligence/shared/services/strategic-guided-okr/strategic-guided-okr-facade.service";
import { EffectivenessScoreToLowToHighScaleFilter } from "./filters/effectiveness-score-to-low-to-high-scale.filter";

const mod = module("quantivePlus", [upgrade]);
mod.downgradeComponent("quantivePlusTasks", { component: QuantivePlusTasksComponent, propagateDigest: false });
mod.downgradeComponent("quantivePlusMetrics", { component: QuantivePlusMetricsComponent, propagateDigest: false });
mod.downgradeComponent("quantivePlusObjectiveDescription", { component: QuantivePlusObjectiveDescriptionComponent, propagateDigest: false });
mod.downgradeComponent("quantivePlusObjectiveTags", { component: QuantivePlusObjectiveTagsComponent, propagateDigest: false });
mod.downgradeComponent("quantivePlusSuggestionCardTags", { component: QuantivePlusSuggestionCardTagsComponent, propagateDigest: false });

mod.downgradeComponent("piSuggestionStateIndicator", { component: SuggestionStateIndicatorComponent, propagateDigest: false });
mod.downgradeComponent("piUpdateKrSuggestionDrawer", { component: UpdateKrSuggestionDrawerComponent, propagateDigest: false });
mod.downgradeComponent("piUpdateObjectiveSuggestionDrawer", { component: UpdateObjectiveSuggestionDrawerComponent, propagateDigest: false });
mod.downgradeComponent("piCreateObjectiveSuggestionDrawer", { component: CreateObjectiveSuggestionDrawerComponent, propagateDigest: false });
mod.downgradeComponent("piCreateKrSuggestionDrawerLegacy", { component: CreateKrSuggestionDrawerLegacyComponent, propagateDigest: false });
mod.downgradeComponent("piLoadingCard", { component: PiLoadingCardComponent, propagateDigest: false });
mod.downgradeComponent("piTextCard", { component: PiTextCardComponent, propagateDigest: false });
mod.downgradeComponent("piErrorCard", { component: PiErrorCardComponent, propagateDigest: false });
mod.downgradeComponent("piClickableErrorCard", { component: PiClickableErrorCardComponent, propagateDigest: false });
mod.downgradeComponent("piLoadingCardV2", { component: PiLoadingCardV2Component, propagateDigest: false });
mod.downgradeComponent("piFeedbackCard", { component: PiFeedbackCardComponent, propagateDigest: false });

mod.downgradeComponent("piContextualSuggestionButton", { component: PiContextualSuggestionButtonComponent, propagateDigest: false });

mod.downgradeInjectable("QuantivePlusSuggestionsFacade", QuantivePlusSuggestionsFacade);
mod.downgradeInjectable("QuantivePlusWhiteboardFacade", QuantivePlusWhiteboardFacade);
mod.downgradeInjectable("StrategicGuidedOkrFacade", StrategicGuidedOkrFacade);
mod.downgradeInjectable("QuantivePlusExplanationFacade", QuantivePlusExplanationFacade);
mod.downgradeInjectable("QuantivePlusEffectivenessFacade", QuantivePlusEffectivenessFacade);

mod.downgradeInjectable("PiSuggestionSharedGoalStateService", PiSuggestionSharedGoalStateService);
mod.downgradeInjectable("PiSuggestionSharedMetricStateService", PiSuggestionSharedMetricStateService);
mod.downgradeInjectable("PiStateProcessorService", PiStateProcessorService);
mod.downgradeInjectable("PiImprovementFacade", PiImprovementFacade);
mod.downgradeInjectable("PiFeaturePackagingFacade", PiFeaturePackagingFacade);
mod.downgradeInjectable("PiFeatureVisibilityFacade", PiFeatureVisibilityFacade);

mod.filter("scoreToLowToHighScale", EffectivenessScoreToLowToHighScaleFilter);

export default mod.name;
