<pi-card-wrapper #cardWrapper [suggestion]="suggestion" [instance]="instance" [borderStyle]="isInline ? 'dashed' : borderStyle" [isAskAiVisible]="isAskAiVisible">
  <ng-container *ngIf="!isInline; else subcardWithInlineStyle" pi-subcard>
    <pi-text-subcard [isSql]="isSql" [isIconAvailable]="isIconAvailable" [textToDisplay]="suggestion?.text" fontColor="dark">
      <pi-card-icon *ngIf="isIconAvailable" [iconType]="iconType" [iconTheme]="iconTheme"></pi-card-icon>
    </pi-text-subcard>
  </ng-container>

  <ng-template #subcardWithInlineStyle pi-subcard>
    <pi-text-subcard [isHovered]="cardWrapper.isHovered" [textToDisplay]="suggestion?.text" [isRemoveBtnAvailable]="isRemoveBtnAvailable && isRemoveBtnAtHeader" (remove)="removeBtnClicked()">
      <pi-card-icon [isHovered]="cardWrapper.isHovered" iconType="platform-intelligence-default" iconTheme="outline" iconColor="light" iconColorOnHover="dark"></pi-card-icon>
    </pi-text-subcard>
  </ng-template>

  <ng-container *ngIf="isAskAiVisible" ask-ai-to>
    <input #input [uiFocus]="true" [placeholder]="'ask_ai_to' | localize" (keydown.enter)="askAi(input.value)" ui-input ui-row type="text" data-test-id="pi-card-ask-ai-to-input-field" />
    <div class="gap-4px" ui-row>
      <button (click)="askAi(input.value)" ui-button uiShape="round" uiType="primary" data-test-id="pi-card-ask-ai-to-btn">{{ "ask_ai" | localize }}</button>
      <button (click)="isAskAiVisible = false" ui-button uiShape="round" uiType="link" data-test-id="pi-card-cancel-ask-ai-to-btn">{{ "cancel" | localize }}</button>
    </div>
  </ng-container>

  <pi-card-action-bar
    *ngIf="cardWrapper.isSelected"
    [isFeedbackActionsSectionAvailable]="isFeedbackActionsSectionAvailable"
    [suggestion]="suggestion"
    [instance]="instance"
    [goal]="goal"
    [entityId]="entityId"
    [suggestionFeedbackType]="suggestionFeedbackType"
    [suggestionFeedbackSubType]="suggestionFeedbackSubType">
    <pi-suggestion-action-bar-main-actions
      [isRefreshBtnAvailable]="isRefreshBtnAvailable"
      [primaryButtonText]="primaryButtonText"
      [isImproveBtnAvailable]="isImproveBtnAvailable"
      [isCopyBtnAvailable]="isCopyBtnAvailable"
      [copyBtnTooltipTitle]="copyBtnTooltipTitle"
      [suggestion]="suggestion"
      [isEditBtnAvailable]="isEditBtnAvailable"
      [isRemoveBtnAvailable]="isRemoveBtnAvailable && !isRemoveBtnAtHeader"
      [improveBtnSpecificDropdownItems]="improveBtnSpecificDropdownItems"
      [instance]="instance"
      (askAiClick)="askAiTo()"
      pi-action-bar-main-actions>
    </pi-suggestion-action-bar-main-actions>
  </pi-card-action-bar>
</pi-card-wrapper>
