<button class="back-btn" *ngIf="!isEdit" (click)="goBack()" ui-button uiType="link">
  <i class="fs-18px sp-pr-4" ui-icon uiType="back"></i><span class="sp-ml-6">{{ "back_to_list" | localize }}</span>
</button>

<div class="title-holder sp-mt-20 sp-mb-20">
  <img class="logo" src="/dist/img/logos/powerbi.png" alt="Power BI logo" />
  <h1 class="fs-30px sp-ml-16">{{ connectionData.title }}</h1>
</div>
<p class="text-color-gray-9 fs-14px" [innerHTML]="'power_bi_info' | localize"></p>

<power-bi-form [connection]="connectionData" [type]="type" [isEdit]="isEdit" [dataSourceDetails]="dataSourceDetails" (backButtonClicked)="goBack()"></power-bi-form>
