import { IPromise, IQService } from "angular";
import { IModalScope } from "angular-ui-bootstrap";
import { firstValueFrom } from "rxjs";
import { IStateInit } from "@gtmhub/core/routing";
import { IIndicator, IUIError } from "@gtmhub/error-handling";
import { localize } from "@gtmhub/localization";
import { IRole, RoleService } from "@gtmhub/roles";
import { Ng1UiModalService } from "@gtmhub/shared/components/modal/ng1-modal.service";
import { IExtendedRootScopeService } from "@gtmhub/shared/ng-extensions";
import { INgRedux } from "@gtmhub/state-management";
import { ITeam, ITeamsStoreState, Paginator } from "@gtmhub/teams";
import { TeamsActions, addAssigneeDataToTeams } from "@gtmhub/teams/redux/teams-actions";
import { getCurrentUserId } from "@gtmhub/users";
import { AccountResolverService } from "@webapp/accounts";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { Employee, EmployeesRepository } from "@webapp/employees";
import { IAssigneesStoreState } from "../redux/assignee-reducer";

type ActiveTab = "teams" | "employees" | "roles";

export interface IOwnerSelectorScope extends IModalScope, IExtendedRootScopeService, IOwnerSelectorRedux {
  indicators: {
    loading?: IIndicator;
    search?: IIndicator;
  };
  paginator: Paginator;
  ownerIds: string[];
  userId: string;
  employees: Employee[];
  roles: IRole[];
  teams: ITeam[];
  accountId: string;
  activeTab: ActiveTab;
  employeesSearch: string;
  teamsSearch: string;
  rolesSearch: string;

  toggle(id: string): void;
  apply(): void;

  onPageChange(page: number, type: "employees" | "teams"): void;
  onTabChange(tab: ActiveTab): void;

  search(querySearch: string, type: "employees" | "teams"): void;
}
interface IOwnerSelectorRedux {
  teamsInStore: ITeam[];
  reduxItemsFetched: boolean;
  reduxError: IUIError;
}

const bindStateToScope = (state: ITeamsStoreState & IAssigneesStoreState): IOwnerSelectorRedux => {
  return {
    teamsInStore: addAssigneeDataToTeams(state.teams.items, state.assignees.map),
    reduxItemsFetched: state.teams.isFetched && state.assignees.isFetched,
    reduxError: state.teams.error || state.assignees.error,
  };
};
export class OwnerSelectorCtrl implements IStateInit {
  private currentUserId: string = getCurrentUserId();

  public static $inject = [
    "$scope",
    "$q",
    "RoleService",
    "AccountResolverService",
    "EmployeesRepository",
    "$ngRedux",
    "TeamsActions",
    "Ng1UiModalService",
    "principalIds",
  ];

  constructor(
    private $scope: IOwnerSelectorScope,
    private $q: IQService,
    private roleService: RoleService,
    private accountResolverService: AccountResolverService,
    private employeesRepository: EmployeesRepository,
    $ngRedux: INgRedux,
    teamsActions: TeamsActions,
    private modalService: Ng1UiModalService,
    principalIds: string[]
  ) {
    this.$scope.ownerIds = principalIds;

    const unsubscribe = $ngRedux.connect(bindStateToScope)(this.$scope);
    this.$scope.$on("$destroy", unsubscribe);
    $ngRedux.dispatch(teamsActions.fetchTeamsIfMissing());
  }

  public stateInit(): IPromise<unknown> {
    return this.init();
  }

  private init = (): IPromise<unknown> => {
    this.$scope.indicators = {};

    this.$scope.onPageChange = this.onPageChange;
    this.$scope.onTabChange = this.onTabChange;

    this.$scope.search = this.search;

    this.setDefaultPaginator();

    this.$scope.toggle = this.toggle;
    this.$scope.apply = this.apply;

    this.$scope.userId = this.currentUserId;
    this.$scope.accountId = this.accountResolverService.getAccountId();
    this.$scope.indicators.loading = { progress: true };

    return this.$q
      .all({
        employees: this.getEmployees(),
        roles: this.roleService.getRoles(),
        __: this.$scope.watchUntilAndRejectOnError(
          () => this.$scope.reduxItemsFetched,
          () => this.$scope.reduxError
        ),
      })
      .then(
        ({ roles }) => {
          delete this.$scope.indicators.loading;
          this.$scope.roles = roles?.items || [];
        },
        (rejection) => (this.$scope.indicators.loading = { error: rejection })
      );
  };

  private setDefaultPaginator = () => {
    this.$scope.paginator = {
      currentPage: 1,
      itemsPerPage: 20,
      paginatorLimit: 5,
    };
  };

  private onTabChange = (tab: ActiveTab): void => {
    this.$scope.activeTab = tab;
    this.setDefaultPaginator();

    if (tab === "teams") {
      this.getTeams(this.$scope.teamsSearch);
    }

    if (tab === "employees") {
      this.$scope.indicators.search = { progress: true };
      // if no name is present at the moment empty string will be passed and no filter will be added to the query
      this.getEmployees(this.$scope.employeesSearch);
    }
  };

  private search = (querySearch: string, type: ActiveTab): void => {
    this.setDefaultPaginator();

    if (type === "employees") {
      this.$scope.employeesSearch = querySearch;
      this.$scope.indicators.search = { progress: true };
      this.getEmployees(this.$scope.employeesSearch);
    }

    if (type === "teams") {
      this.$scope.teamsSearch = querySearch;
      this.getTeams(this.$scope.teamsSearch);
    }

    if (type === "roles") {
      this.$scope.rolesSearch = querySearch;
      this.$scope.$evalAsync();
    }
  };

  private getTeams = (searchTeam: string): void => {
    const regex = new RegExp(searchTeam, "gi");
    const filterCondition = (team: ITeam) =>
      searchTeam?.length > 0 ? team.isActive !== false && (regex.test(team.name) || regex.test(team.description)) : team.isActive !== false;

    const matchedTeams = this.$scope.teamsInStore.filter(filterCondition);

    const skip = (this.$scope.paginator.currentPage - 1) * this.$scope.paginator.itemsPerPage;
    const take = this.$scope.paginator.currentPage * this.$scope.paginator.itemsPerPage;

    this.$scope.teams = matchedTeams.slice(skip, take);

    this.$scope.paginator.totalPages = Math.ceil(matchedTeams.length / this.$scope.paginator.itemsPerPage);
    this.$scope.paginator.totalCount = matchedTeams.length;
    this.$scope.$evalAsync();
  };

  private getEmployees = (querySearch?: string) => {
    this.$scope.indicators.search = { progress: true };

    const queryParams: RequestPaging = {
      skip: (this.$scope.paginator.currentPage - 1) * this.$scope.paginator.itemsPerPage,
      limit: this.$scope.paginator.itemsPerPage,
      fields: ["id", "firstName", "lastName", "picture", "email", "isActive"],
    };

    if (querySearch) {
      queryParams.filter = {
        $and: [{ $or: [{ firstName: { $regex: querySearch } }, { lastName: { $regex: querySearch } }] }, { isActive: { $ne: false } }],
      };
    } else {
      queryParams.filter = { isActive: { $ne: false } };
    }

    return firstValueFrom(this.employeesRepository.getEmployees$(queryParams))
      .then((employeesCollection) => {
        delete this.$scope.indicators.search;
        this.$scope.employees = employeesCollection.items;

        this.$scope.paginator.totalPages = Math.ceil(employeesCollection.totalCount / this.$scope.paginator.itemsPerPage);
      })
      .catch((error) => (this.$scope.indicators.search = { error }))
      .finally(() => this.$scope.$evalAsync());
  };

  private onPageChange = (page: number, type: "employees" | "teams"): void => {
    this.$scope.paginator.currentPage = page;

    if (type === "employees") {
      this.$scope.indicators.search = { progress: true };
      this.getEmployees(this.$scope.employeesSearch);
    }

    if (type === "teams") {
      this.getTeams(this.$scope.teamsSearch);
    }
  };

  private apply = () => {
    this.$scope.$close(this.$scope.ownerIds);
  };

  private toggle = (id: string) => {
    const index = this.$scope.ownerIds.indexOf(id);

    if (index === -1) {
      this.$scope.ownerIds.push(id);
    } else {
      const callback = () => this.$scope.ownerIds.splice(index, 1);
      if (id === this.currentUserId) {
        this.deselectConfirmationModal(callback);
      } else {
        callback();
      }
    }
  };

  private deselectConfirmationModal = (callback: () => void): void => {
    this.modalService.confirm({
      uiTitle: localize("r_u_sure_u_want_remove_ur_permissions"),
      uiContent: localize("permissions_removal_description"),
      uiIconType: null,
      uiOkText: localize("remove"),
      uiOkDanger: true,
      uiOnOk: callback,
      uiCancelText: localize("cancel"),
    });
  };
}
