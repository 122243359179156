import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { AccountIdHeaderInterceptor } from "./account-id-header.interceptor";
import { AccountSuspendedInterceptor } from "./account-suspended.interceptor";
import { AuthTokenInterceptor } from "./auth-token.interceptor";
import { CdnRewriteInterceptor } from "./cdn-rewrite.interceptor";
import { LogFailedRequestsInterceptor } from "./log-failed-requests.interceptor";
import { RefreshTokenInterceptor } from "./refresh-token.interceptor";
import { RequestOriginInterceptor } from "./request-origin.interceptor";
import { RetryFailedGetRequestsInterceptor } from "./retry-failed-get-requests.interceptor";
import { TracingInterceptor } from "./tracing.interceptor";
import { TrackDataInterceptor } from "./track-data.interceptor";
import { UnauthorizedResponseInterceptor } from "./unauthorized-response.interceptor";
import { UserDeactivatedInterceptor } from "./user-deactivated.interceptor";

// Order of insertion is important:
//   request: first -> last
//   response: last -> first
const interceptors = [
  RefreshTokenInterceptor,
  AuthTokenInterceptor,
  AccountIdHeaderInterceptor,
  CdnRewriteInterceptor,
  TrackDataInterceptor,
  TracingInterceptor,
  RequestOriginInterceptor,
  LogFailedRequestsInterceptor,
  AccountSuspendedInterceptor,
  UnauthorizedResponseInterceptor,
  UserDeactivatedInterceptor,
  RetryFailedGetRequestsInterceptor,
];

@NgModule({
  imports: [],
  providers: [
    ...interceptors.map((interceptor) => ({
      provide: HTTP_INTERCEPTORS,
      useClass: interceptor,
      multi: true,
    })),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class HttpInterceptorsModule {}
