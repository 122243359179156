import { CheckInOverview } from "@webapp/home/services/check-ins-widget/check-ins-widget.service";

export const checkInComparer = (a: CheckInOverview, b: CheckInOverview): number => {
  if (!a.hasCurrentUserFilledOut && b.hasCurrentUserFilledOut) {
    return -1;
  } else if (a.hasCurrentUserFilledOut && !b.hasCurrentUserFilledOut) {
    return 1;
  } else if (a.isUserManagerOfTeam && !b.isUserManagerOfTeam) {
    return -1;
  } else if (!a.isUserManagerOfTeam && b.isUserManagerOfTeam) {
    return 1;
  } else {
    return a.name.localeCompare(b.name);
  }
};
