import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable, of, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { AuthenticationResolverService } from "@gtmhub/auth";
import { isMsTeamsApp } from "@webapp/shared/utils/msteams";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";

@Injectable()
export class UnauthorizedResponseInterceptor implements HttpInterceptor {
  constructor(
    private authenticationResolverService: AuthenticationResolverService,
    private userProfileService: UserProfileService
  ) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === 401) {
          if (isMsTeamsApp()) {
            const gtmhubUserId = this.authenticationResolverService.getAuthUserId();
            const teamsBotDomain = localStorage.getItem("teamsBotDomain") || "msteams-bot.gtmhub.com";
            const url = `https://${teamsBotDomain}/tab/expire-token?gtmhubUserId=${gtmhubUserId}`;
            window.location.href = url;
            return EMPTY;
          }

          const isOnAccountSuspendedUrl = window.location.hash.includes("account-suspended");
          if (!isOnAccountSuspendedUrl) {
            return of(this.authenticationResolverService.navigateToLogin(this.userProfileService.getProfile())).pipe(switchMap(() => EMPTY));
          }
        }

        return throwError(() => response);
      })
    );
  }
}
