import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Badge, BadgeAssignment } from "@webapp/badges/models/badges.models";

@Component({
  selector: "badge-assignment",
  templateUrl: "./badge-assignment.component.html",
  styleUrls: ["./badge-assignment.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeAssignmentComponent {
  @Input()
  public assignment: BadgeAssignment;

  @Input()
  public badge: Badge;
}
