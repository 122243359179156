import { Inject, Injectable } from "@angular/core";
import { isCurrentDomainQuantive } from "@gtmhub/env/util";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";

@Injectable({
  providedIn: "any",
})
export class DomainService {
  constructor(@Inject(APP_CONFIG) private appConfig: IAppConfig) {}

  public constructGtmhubDomain(vanityDomain: string, options: { preserveDomainForLocalhost?: boolean } = {}): string {
    const { name, dc, bypassDomainCheck } = this.appConfig.env;
    if (bypassDomainCheck && !options.preserveDomainForLocalhost) {
      return window.location.host;
    }

    const domain = isCurrentDomainQuantive() ? "quantive.com" : "gtmhub.com";
    if (name === "staging") {
      return `${vanityDomain}.staging.${domain}`;
    }
    if (dc === "eu") {
      return `${vanityDomain}.${domain}`;
    }

    return `${vanityDomain}.${dc}.${domain}`;
  }
}
