import { module } from "angular";
import { loadModuleStyles } from "@gtmhub/shared/ng-extensions/util";
import { TaskStatusComponent } from "./components/task-status.component";
import { jiraIssueLink } from "./filters/jira-issue-link.filter";
import { TaskFilterModalService } from "./services/task-filter-modal.service";
import { TaskService } from "./services/task.service";
import moduleStyles from "./tasks-core.module.less";

const mod = module("tasksCore", []);

mod.component("taskStatus", TaskStatusComponent);

mod.service("TaskService", TaskService);
mod.service("TaskFilterModalService", TaskFilterModalService);

mod.filter("jiraIssueLink", jiraIssueLink);

loadModuleStyles(mod, moduleStyles);

export default mod.name;
