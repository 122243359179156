<ul class="top-section-nav">
  <li class="top-section-nav__item top-section-nav__item--logo">
    <i class="top-section-nav__logo" uiType="quantive-logo-white" uiTheme="fill" ui-icon uiSize="lg"></i>
  </li>
  <ng-container *ngFor="let screen of screens">
    <li class="top-section-nav__item" *ngIf="screen.showScreenCondition$ | async">
      <a
        class="top-section-nav__link"
        [class.top-section-nav__link--active]="activeScreen === screen.key"
        [uiTooltipTitle]="screen.key | localize"
        [uiSref]="screen.state"
        [uiParams]="screen.stateParams"
        [attr.data-test-id]="'new-nav-icon-for-tab-' + screen.key"
        [attr.aria-label]="screen.ariaLabel | localize"
        uiTooltipPlacement="right"
        ui-tooltip>
        <i class="top-section-nav__icon" [uiType]="screen.icon" ui-icon></i>
      </a>
    </li>
  </ng-container>
</ul>
