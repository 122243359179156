import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { UiGridModule } from "@webapp/ui/grid/grid.module";
import { EmptyStateViewInfoBoxesComponent } from "./empty-state-info-boxes/empty-state-info-boxes.component";
import { EmptyStateService } from "./empty-state.service";
import { EmptyState } from "./models";

@Component({
  selector: "empty-state",
  templateUrl: "./empty-state.component.html",
  styleUrls: ["./empty-state.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, EmptyStateViewInfoBoxesComponent, UiGridModule],
  standalone: true,
})

/**
 * @example <empty-state id='lists' background-color='light'>
 * @param backgroundColor "light" | "dark"
 * @param id finds the empty state data and loads it from EmptyStateService
 */
export class EmptyStateViewComponent implements OnInit {
  @Input() public emptyStateId: string;
  @Input() public background: "light" | "dark";
  @Input() public infoBoxesId: string;

  public emptyState: EmptyState;
  public videoDialogOpen: boolean;
  public videoUrl: SafeResourceUrl;

  public constructor(
    private emptyStateService: EmptyStateService,
    private sanitizer: DomSanitizer
  ) {}

  public ngOnInit(): void {
    this.emptyState = this.emptyStateService.getEmptyState(this.emptyStateId);
    this.videoDialogOpen = false;
  }

  public openVideoDialog(videoUrl: string): void {
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
    this.videoDialogOpen = true;
  }

  public closeVideoDialog(): void {
    this.videoUrl = "";
    this.videoDialogOpen = false;
  }
}
