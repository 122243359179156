import { Injectable } from "@angular/core";
import { Observable, switchMap } from "rxjs";
import {
  PIKeyResultSuggestion,
  PIKeyResultSuggestionPayload,
  PIObjectiveDescriptionSuggestion,
  PIObjectiveDescriptionSuggestionPayload,
  PIObjectiveSuggestion,
  PIObjectiveSuggestionPayload,
} from "@webapp/platform-intelligence/shared/models/strategic-guided-okr.models";
import { IStrategicGuidedOkrFacade } from "@webapp/platform-intelligence/shared/services/strategic-guided-okr/strategic-guided-okr-facade.interface";
import { StrategicGuidedOkrProviderFactory } from "@webapp/platform-intelligence/shared/services/strategic-guided-okr/strategic-guided-okr-provider-factory.service";

@Injectable({
  providedIn: "any",
})
export class StrategicGuidedOkrFacade implements IStrategicGuidedOkrFacade {
  constructor(private strategicGuidedOkrProviderFactory: StrategicGuidedOkrProviderFactory) {}

  public getObjectiveDescriptionSuggestion(payload: PIObjectiveDescriptionSuggestionPayload): Observable<PIObjectiveDescriptionSuggestion> {
    return this.strategicGuidedOkrProviderFactory.getInstance("objective-description").pipe(switchMap((provider) => provider.getObjectiveDescriptionSuggestion(payload)));
  }

  public getObjectiveSuggestions(payload: PIObjectiveSuggestionPayload): Observable<PIObjectiveSuggestion> {
    return this.strategicGuidedOkrProviderFactory.getInstance("objective").pipe(switchMap((provider) => provider.getObjectiveSuggestions(payload)));
  }

  public getKeyResultSuggestions(payload: PIKeyResultSuggestionPayload): Observable<PIKeyResultSuggestion> {
    return this.strategicGuidedOkrProviderFactory.getInstance("kr").pipe(switchMap((provider) => provider.getKeyResultSuggestions(payload)));
  }
}
