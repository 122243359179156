import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { StrategicGuidedOkrSuggestionDTO, StrategicGuidedOkrSuggestionPostDTO } from "@webapp/platform-intelligence/shared/models/strategic-guided-okr.models";
import { StrategicGuidedOkrStateV1 } from "@webapp/platform-intelligence/shared/services/strategic-guided-okr/v1/strategic-guided-okr-state-v1.service";

@Injectable({
  providedIn: "root",
})
export class StrategicGuidedOkrApiV1Service extends BaseApiService<StrategicGuidedOkrSuggestionDTO, StrategicGuidedOkrSuggestionPostDTO, StrategicGuidedOkrStateV1> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: StrategicGuidedOkrStateV1) {
    super("strategic-guided-okr", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getObjectiveDescriptionEndpoint(): string {
    return `${this.getQuantivePlusBasePath(HttpActions.post)}/objective-description`;
  }

  public getObjectiveEndpoint(): string {
    return `${this.getQuantivePlusBasePath(HttpActions.post)}/objective`;
  }

  public getKeyResultEndpoint(): string {
    return `${this.getQuantivePlusBasePath(HttpActions.post)}/kr`;
  }
}
