<ng-template
  #overlay="cdkConnectedOverlay"
  [cdkConnectedOverlayHasBackdrop]="hasBackdrop"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayPositions]="_positions"
  [cdkConnectedOverlayOpen]="_visible"
  [cdkConnectedOverlayPush]="true"
  [nzArrowPointAtCenter]="nzArrowPointAtCenter"
  (overlayOutsideClick)="onClickOutside($event)"
  (detach)="hide()"
  (positionChange)="onPositionChange($event)"
  cdkConnectedOverlay
  nzConnectedOverlay>
  <div class="ant-popover" [class.ant-popover-rtl]="dir === 'rtl'" [ngClass]="_classMap" [ngStyle]="nzOverlayStyle">
    <div class="ant-popover-content">
      <div class="ant-popover-arrow">
        <span class="ant-popover-arrow-content"></span>
      </div>
      <div class="ant-popover-inner" role="tooltip">
        <div>
          <div class="ant-popover-title" *ngIf="nzTitle">
            <ng-container *nzStringTemplateOutlet="nzTitle">{{ nzTitle }}</ng-container>
          </div>
          <div class="ant-popover-inner-content">
            <ng-container *nzStringTemplateOutlet="nzContent">{{ nzContent }}</ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
