import { IComponentOptions } from "angular";
import { IModalService } from "angular-ui-bootstrap";
import { GtmhubController } from "@gtmhub/core";
import { IApiPermission, UserAction } from "@webapp/sessions/models/sessions.model";
import { Principal, defaultUserActionSet } from "./models";

type PermissionPrincipalBindings = {
  userActions?: UserAction[];
  permission: IApiPermission;
  principal: Principal;
  check(permission: IApiPermission, option: UserAction): void;
  removePermissions(permission: IApiPermission): void;
  canUnselectAction(permission: IApiPermission, option: UserAction): boolean;
  canRemovePrincipal(permission: IApiPermission): boolean;
  lastWithModifyPermissions(principalId: string): boolean;
};

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface PermissionPrincipalCtrl extends PermissionPrincipalBindings {}
class PermissionPrincipalCtrl extends GtmhubController {
  defaultSet: UserAction[] = defaultUserActionSet();
  disabledAutoCloseDropdown = false;
  public static $inject = ["$uibModal"];
  constructor(private $uibModal: IModalService) {
    super();
  }

  openWhoIsInRoleModal(permission: IApiPermission): void {
    this.disabledAutoCloseDropdown = true;
    this.$uibModal
      .open({
        windowClass: "medium-small included-in-permission",
        component: "includedInPermission",
        resolve: {
          principalId: () => permission.principalId,
          principalKind: () => permission.principalKind,
        },
      })
      .closed.then(() => {
        this.disabledAutoCloseDropdown = false;
      });
  }

  /**
   * The function checks if the number of elements in two arrays, excluding the "create" element, is
   * different.
   * @param p1 - An array of values representing the first set of parameters.
   * @param p2 - The parameter `p2` is an array.
   * @returns a boolean value.
   */
  checkIfModified(p1: string[], p2: string[]): boolean {
    return p1.filter((i) => i !== "create").length !== p2.filter((i) => i !== "create").length;
  }
}

export const PermissionPrincipalComponent: IComponentOptions = {
  template: require("./permission-principal.html"),
  controller: PermissionPrincipalCtrl,
  bindings: {
    permission: "=",
    principal: "=",
    userActions: "=?",
    check: "&",
    removePermissions: "&",
    canUnselectAction: "&",
    canRemovePrincipal: "&",
    lastWithModifyPermissions: "&",
  },
};
