import { Injector, NgZone } from "@angular/core";
import { BehaviorSubject, Observable, of, switchMap, take } from "rxjs";
import { getInsightSelectorData } from "@gtmhub/insights";
import {
  BrowseConnectionsActionsParams,
  BrowseInsightsActionParams,
  BuildInsightActionParams,
  ConfigurePowerBIActionParams,
  ExecuteInsightActionParams,
  Insight,
  InsightSelectorActionType,
  InsightSelectorOutputActionsParams,
  OpenPowerBIInsightActionParams,
  UpdateInsightSelectorDataActionParams,
} from "@webapp/data-story/models/insights.models";
import { InsightsModalsService } from "@webapp/data-story/services/insights-modals.service";
import { ConfigurePowerBIModalService } from "@webapp/integrations/power-bi/components/configure-power-bi/configure-power-bi-modal.service";
import { ConfigurePowerBIModalData } from "@webapp/integrations/power-bi/components/configure-power-bi/configure-power-bi.component";
import {
  ScalarDataSourceModalComponent,
  ScalarDataSourceModalData,
} from "@webapp/integrations/power-bi/components/scalar-datasource-modal/scalar-datasource-modal.component";
import { ItemAutomationSelectorDataType } from "@webapp/shared/components/item-automation-selector/models/item-automation-selector.models";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { ExtendWithWriteValueOriginCheck } from "../components/item-automation-selector/item-automation-selector.component";

export class AutomatingInsightSelectionManager {
  private ngZone: NgZone;
  private uiModalService: UiModalService;
  private insightsModalsService: InsightsModalsService;
  private configurePowerBIModalService: ConfigurePowerBIModalService;
  private insightRef: Insight;

  constructor(
    injector: Injector,
    private selectorData$: BehaviorSubject<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"insight">>>
  ) {
    this.ngZone = injector.get(NgZone);
    this.uiModalService = injector.get(UiModalService);
    this.insightsModalsService = injector.get(InsightsModalsService);
    this.configurePowerBIModalService = injector.get(ConfigurePowerBIModalService);
  }

  public handleInsightSelectorAction$({
    actionParams,
  }: {
    actionParams:
      | BrowseConnectionsActionsParams
      | BrowseInsightsActionParams
      | BuildInsightActionParams
      | ExecuteInsightActionParams
      | UpdateInsightSelectorDataActionParams
      | ConfigurePowerBIActionParams
      | OpenPowerBIInsightActionParams;
  }): Observable<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"insight">>> {
    switch (actionParams?.action) {
      case InsightSelectorActionType.BROWSE_CONNECTIONS:
        return this.openBrowseConnectionsModal$();

      case InsightSelectorActionType.CONFIGURE_POWER_BI:
        return this.configurePowerBI$({
          connectionData: actionParams.connectionData,
          type: this.selectorData$.value.data.targetType,
          isEdit: !!this.selectorData$.value?.data.insightName,
        });

      case InsightSelectorActionType.OPEN_POWERBI_INSIGHT:
        return this.openPowerBiScalarResultModal$(actionParams);
      case InsightSelectorActionType.BUILD_INSIGHT:
        return this.openBuildInsightModal$({
          connectionData: actionParams.connectionData,
          daxQueryResult: actionParams.daxQueryResult,
          insight: this.insightRef,
        });

      case InsightSelectorActionType.EXECUTE_INSIGHT:
        return this.openExecuteInsightModal$({ insight: actionParams.insight });

      case InsightSelectorActionType.UPDATE_INSIGHT_SELECTOR_DATA:
        return this.mapActionParamsToInsightSelectorData$(actionParams);
    }
  }

  public openBrowseConnectionsModal$ = (): Observable<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"insight">>> => {
    return new Observable<InsightSelectorOutputActionsParams>((observer) => {
      this.ngZone.run(() => {
        observer.add(
          this.insightsModalsService
            .openInsightSelectorModal()
            .pipe(take(1))
            .subscribe({
              next: (actionParams) => {
                observer.next(actionParams);
              },
              error: (error) => {
                observer.error(error);
              },
              complete: () => {
                observer.complete();
              },
            })
        );
      });
    }).pipe(
      take(1),
      switchMap((actionParams) => this.handleInsightSelectorAction$({ actionParams }))
    );
  };

  public configurePowerBI$ = ({
    connectionData,
    type,
    isEdit,
    dataSourceDetails,
  }: ConfigurePowerBIModalData): Observable<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"insight">>> => {
    return new Observable<OpenPowerBIInsightActionParams | BuildInsightActionParams | BrowseConnectionsActionsParams>((observer) => {
      this.ngZone.run(() => {
        observer.add(
          this.configurePowerBIModalService
            .open({ connectionData, type, isEdit, dataSourceDetails })
            .afterClose.pipe(take(1))
            .subscribe({
              next: (actionParams: OpenPowerBIInsightActionParams | BuildInsightActionParams | BrowseConnectionsActionsParams) => {
                observer.next(actionParams);
              },
              error: (error) => {
                observer.error(error);
              },
            })
        );
      });
    }).pipe(
      take(1),
      switchMap((actionParams) => this.handleInsightSelectorAction$({ actionParams }))
    );
  };

  private openPowerBiScalarResultModal$ = (
    actionParams: OpenPowerBIInsightActionParams
  ): Observable<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"insight">>> => {
    return new Observable<UpdateInsightSelectorDataActionParams>((observer) => {
      this.ngZone.run(() => {
        observer.add(
          this.uiModalService
            .create<ScalarDataSourceModalComponent, ScalarDataSourceModalData>({
              uiContent: ScalarDataSourceModalComponent,
              uiWrapClassName: "",
              uiFooter: null,
              uiClosable: false,
              uiKeyboard: false,
              uiWidth: "700px",
              uiData: {
                connectionData: actionParams.connectionData,
                daxQueryResult: actionParams.daxQueryResult,
              },
            })
            .afterClose.pipe(take(1))
            .subscribe({
              next: (actionParams: UpdateInsightSelectorDataActionParams) => {
                observer.next(actionParams);
              },
              error: (error) => {
                observer.error(error);
              },
            })
        );
      });
    }).pipe(
      take(1),
      switchMap((actionParams) => this.handleInsightSelectorAction$({ actionParams }))
    );
  };

  public openBuildInsightModal$ = ({
    connectionData,
    insight,
    daxQueryResult,
  }: {
    connectionData: BuildInsightActionParams["connectionData"];
    insight: BuildInsightActionParams["insight"];
    daxQueryResult?: BuildInsightActionParams["daxQueryResult"];
  }): Observable<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"insight">>> => {
    return new Observable<UpdateInsightSelectorDataActionParams>((observer) => {
      this.ngZone.run(() => {
        observer.add(
          this.insightsModalsService
            .openInsightSelectorModal({
              action: InsightSelectorActionType.BUILD_INSIGHT,
              insight,
              connectionData,
              daxQueryResult,
              canGoBack: true,
            })
            .pipe(take(1))
            .subscribe({
              next: (actionParams: UpdateInsightSelectorDataActionParams) => {
                observer.next(actionParams);
              },
              error: (error) => {
                observer.error(error);
              },
              complete: () => {
                observer.complete();
              },
            })
        );
      });
    }).pipe(
      take(1),
      switchMap((actionParams) => this.handleInsightSelectorAction$({ actionParams }))
    );
  };

  public openExecuteInsightModal$ = ({
    insight,
  }: {
    insight: ExecuteInsightActionParams["insight"];
  }): Observable<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"insight">>> => {
    return new Observable<UpdateInsightSelectorDataActionParams>((observer) => {
      this.ngZone.run(() => {
        observer.add(
          this.insightsModalsService
            .openInsightSelectorModal({
              action: InsightSelectorActionType.EXECUTE_INSIGHT,
              insight,
              data: this.selectorData$.value.data,
            })
            .pipe(take(1))
            .subscribe({
              next: (actionParams: UpdateInsightSelectorDataActionParams) => {
                observer.next(actionParams);
              },
              error: (error) => {
                observer.error(error);
              },
              complete: () => {
                observer.complete();
              },
            })
        );
      });
    }).pipe(
      take(1),
      switchMap((actionParams) => this.handleInsightSelectorAction$({ actionParams }))
    );
  };

  private mapActionParamsToInsightSelectorData$ = (
    actionParams: UpdateInsightSelectorDataActionParams
  ): Observable<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"insight">>> => {
    return of({
      data: {
        ...this.selectorData$.value.data,
        ...getInsightSelectorData(actionParams),
        selectionType: "insight",
      },
      hasWriteValueOrigin: false,
    });
  };
}
