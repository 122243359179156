import { ChangeDetectorRef } from "@angular/core";
import { BehaviorSubject, Observable, filter } from "rxjs";
import { QuantivePlusSuggestionDataStatus } from "@webapp/platform-intelligence/quantive-plus/models";
import { PIStateProcessorInstanceSubType, PIStateProcessorInstanceType } from "../services/state-processor/pi-state-processor.models";

const getPersistedSuggestionsFeedbackKey = (parentType: PIStateProcessorInstanceType): string => `pi_${parentType}_suggestions_persisted_feedback`;

const getPersistedSuggestionsFeedback = (parentType: PIStateProcessorInstanceType): Record<string, string[]> => {
  return JSON.parse(localStorage.getItem(getPersistedSuggestionsFeedbackKey(parentType))) || {};
};

export const setPersistedSuggestionsFeedback = (suggestionId: string, parentType: PIStateProcessorInstanceType, parentId: string): Record<string, string[]> => {
  const feedbackSuggestions = getPersistedSuggestionsFeedback(parentType);
  const suggestions = feedbackSuggestions[parentId] || [];
  suggestions.push(suggestionId);
  feedbackSuggestions[parentId] = suggestions;

  localStorage.setItem(getPersistedSuggestionsFeedbackKey(parentType), JSON.stringify(feedbackSuggestions));

  return feedbackSuggestions;
};

export const removePersistedSuggestionsFeedback = (suggestionId: string, parentType: PIStateProcessorInstanceType, parentId: string): Record<string, string[]> => {
  const feedbackSuggestions = getPersistedSuggestionsFeedback(parentType);
  const suggestions = feedbackSuggestions[parentId] || [];
  feedbackSuggestions[parentId] = suggestions.filter((id) => id !== suggestionId);

  localStorage.setItem(getPersistedSuggestionsFeedbackKey(parentType), JSON.stringify(feedbackSuggestions));

  return feedbackSuggestions;
};

export const removeAllPersistedSuggestionsFeedback = (parentType: PIStateProcessorInstanceType, parentId: string): Record<string, string[]> => {
  const feedbackSuggestions = getPersistedSuggestionsFeedback(parentType);
  delete feedbackSuggestions[parentId];

  localStorage.setItem(getPersistedSuggestionsFeedbackKey(parentType), JSON.stringify(feedbackSuggestions));

  return {};
};

export const isSuggestionFeedbackPersisted = (suggestionId: string, parentType: PIStateProcessorInstanceType, parentId: string): boolean => {
  const feedbackSuggestions = getPersistedSuggestionsFeedback(parentType);
  const feedbackSuggestionsForParent = feedbackSuggestions[parentId] || [];

  return feedbackSuggestionsForParent.some((id) => id === suggestionId);
};

export function triggerFakeLoadingForSuggestion<T extends PIStateProcessorInstanceSubType>(
  suggestionsStatus$: BehaviorSubject<Partial<Record<T, QuantivePlusSuggestionDataStatus>>>,
  suggestionType: T,
  cdr: ChangeDetectorRef
): Observable<never> {
  if (suggestionsStatus$.value[suggestionType]?.isLoading) suggestionsStatus$.pipe(filter((status) => !status[suggestionType]?.isLoading));

  suggestionsStatus$.next({
    ...suggestionsStatus$.value,
    [suggestionType]: {
      ...suggestionsStatus$.value[suggestionType],
      isLoading: true,
    },
  });

  return new Observable((observer) => {
    window.setTimeoutOutsideAngular(() => {
      suggestionsStatus$.next({
        ...suggestionsStatus$.value,
        [suggestionType]: {
          ...suggestionsStatus$.value[suggestionType],
          isLoading: false,
        },
      });
      cdr.detectChanges();

      observer.next();
      observer.complete();
    }, 1000);
  });
}
