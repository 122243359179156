import { Injectable } from "@angular/core";
import { Observable, map, mergeMap, of, switchMap, take, tap } from "rxjs";
import { ITeam } from "@gtmhub/teams";
import { UXCustomizationType } from "@gtmhub/uxcustomization/models";
import { IWhiteboard } from "@gtmhub/whiteboards";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { Insightboard } from "@webapp/insightboards/models";
import { KpiGroup } from "@webapp/kpis/models/kpi-group.models";
import { List } from "@webapp/lists/models";
import { SubNavItemsModel } from "@webapp/navigation/utils/nav-items.util";
import { Session } from "@webapp/sessions/models/sessions.model";
import { SuggestedApiService } from "./suggested.api.service";
import { SuggestedCache } from "./suggested.cache";
import { SuggestedEntityType, SuggestedModel, SuggestedModelKeys } from "./suggested.models";

@Injectable({ providedIn: "any" })
export class SuggestedRepository {
  constructor(
    private api: SuggestedApiService,
    private cache: SuggestedCache
  ) {}

  public get$(key: SuggestedModelKeys.Sessions, comparator: (lhs: SuggestedEntityType, rhs: SuggestedEntityType) => number): Observable<Session[]>;
  public get$(key: SuggestedModelKeys.Lists): Observable<List[]>;
  public get$(key: SuggestedModelKeys.Dashboards): Observable<Insightboard[]>;
  public get$(key: SuggestedModelKeys.Whiteboards): Observable<IWhiteboard[]>;
  public get$(key: SuggestedModelKeys.KpiGroups): Observable<KpiGroup[]>;
  public get$(key: SuggestedModelKeys.Teams): Observable<ITeam[]>;
  public get$(key: SuggestedModelKeys, comparator?: (lhs: SuggestedEntityType, rhs: SuggestedEntityType) => number): Observable<SuggestedEntityType[]> {
    return this.cache.get$().pipe(
      switchMap((value) => {
        if (value) {
          return of(value);
        } else {
          return this.api.get$("").pipe(
            tap((suggested) => {
              this.cache.set(suggested);
            }),
            mergeMap(() => this.cache.get$())
          );
        }
      }),
      map((suggestedResponse) => {
        const suggestedResponseObj = suggestedResponse[key] || {};
        const items = Object.values(suggestedResponseObj).flat();

        if (comparator) {
          items.sort(comparator);
        }

        return [...new Set(items)];
      })
    );
  }

  public isLoaded$(): Observable<boolean> {
    return this.cache.get$().pipe(map((v) => !!v));
  }

  public blacklistItem(id: string, type: UXCustomizationType): Observable<SuggestedModel> {
    return this.api.post$(null, { url: `${this.api.getBasePath(HttpActions.post)}/${type}/${id}/blacklist` });
  }

  public updateItem(type: SuggestedModelKeys, item: SubNavItemsModel): void {
    this.cache
      .get$()
      .pipe(take(1))
      .subscribe((suggested) => {
        if (!suggested?.[type]) return;

        const newSuggestionCollection = Object.keys(suggested[type]).reduce((previousValue: Record<string, unknown>, currentValue: string) => {
          previousValue[currentValue] = suggested[type][currentValue].map((oldItem) => (oldItem.id === item.id ? item : oldItem));
          return previousValue;
        }, {});
        this.cache.set({
          ...suggested,
          [type]: newSuggestionCollection,
        } as SuggestedModel);
      });
  }

  public deleteItem(type: SuggestedModelKeys, id: string): void {
    this.cache
      .get$()
      .pipe(take(1))
      .subscribe((suggested) => {
        if (!suggested?.[type]) return;
        let suggestionRemoved = false;

        const newSuggestionCollection = Object.keys(suggested[type]).reduce((previousValue: Record<string, unknown>, currentValue: string) => {
          previousValue[currentValue] = suggested[type][currentValue].filter((oldItem) => {
            if (oldItem.id === id) {
              suggestionRemoved = true;
            }
            return oldItem.id !== id;
          });
          return previousValue;
        }, {});

        if (suggestionRemoved) {
          this.cache.set({
            ...suggested,
            [type]: newSuggestionCollection,
          } as SuggestedModel);
        }
      });
  }
}
