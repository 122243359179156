import { Inject, Injectable, NgZone, OnDestroy } from "@angular/core";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { Observable, Subscription, take, tap } from "rxjs";
import { IAccount } from "@gtmhub/core";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";

@Injectable()
export class AnalyticsFactoryService implements OnDestroy {
  private initializeSubscription$: Subscription;
  private analytics: AnalyticsBrowser;

  constructor(
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private ngZone: NgZone,
    private broadcastService: BroadcastService
  ) {
    // This will initialize analytics and add all tracking events in queue
    // and send them when .load() method is called
    this.ngZone.runOutsideAngular(() => {
      this.initializeSubscription$ = this.initialize$().subscribe();
    });
  }

  public ngOnDestroy(): void {
    this.initializeSubscription$.unsubscribe();
  }

  public getAnalytics(): AnalyticsBrowser {
    return this.analytics;
  }

  private initialize$(): Observable<IAccount> {
    this.analytics = new AnalyticsBrowser();

    // Wait for account data initialization
    return this.broadcastService.on("accountDataInitialized").pipe(
      take(1),
      tap((account: IAccount) => {
        if (!this.appConfig.segment.enabled || !account.trackingProperties.shouldTrack) {
          // When segment || tracking we don't need to load analytics at all
          // Intercom will be loaded from container.ejs
          return;
        }

        this.analytics.load(
          {
            writeKey: this.appConfig.segment.writekey,
            cdnURL: "https://cdnpa.quantivestatic.com",
          },
          {
            obfuscate: true,
            integrations: {
              "Segment.io": {
                apiHost: "apipa.quantive.com/v1",
                protocol: "https",
              },
            },
          }
        );
      })
    );
  }
}
