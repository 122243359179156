import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiThemeType } from "@quantive/ui-kit/icon";
import { PiCardBorderStyle } from "../../models/pi-card.models";
import { IPIFeedbackSuggestion } from "../pi-feedback-card/models";
import { PiStateProcessorInstance } from "../pi-feedback-card/services/state-processor/pi-state-processor.models";

@UntilDestroy()
@Component({
  selector: "pi-loading-card-v2",
  templateUrl: "./pi-loading-card-v2.component.html",
  styleUrls: ["./pi-loading-card-v2.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiLoadingCardV2Component implements OnInit {
  @Input()
  public borderStyle: PiCardBorderStyle = "solid";
  @Input()
  public isIconAvailable = true;
  @Input()
  public isCustomIcon = false;
  @Input()
  public iconType: string;
  @Input()
  public iconTheme: UiThemeType;
  @Input()
  public isSelected: boolean;

  @Input()
  public instance: PiStateProcessorInstance;
  @Input()
  public suggestion: IPIFeedbackSuggestion<string | string[]>;

  constructor(private cd: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.instance?.isFeedbackCardFocused.pipe(untilDestroyed(this)).subscribe({
      next: (suggestionCard) => {
        if (this.suggestion?.index === suggestionCard.index) {
          this.isSelected = suggestionCard.isFocused;
          this.cd.detectChanges();

          return;
        }

        this.isSelected = false;
        this.cd.detectChanges();
      },
    });
  }
}
