import "angular";
import { IAppConfig } from "./config";
import { EnvironmentService } from "./environment";

// when the page loads it will contain this global function returning the config
// see `ng-config.ts`
declare let webappConfig: () => IAppConfig;

const mod = angular.module("environment", []);
mod.constant("appConfig", webappConfig());
mod.service("EnvironmentService", EnvironmentService);
export default mod.name;
