import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, take } from "rxjs";
import { QuantivePlusSuggestions } from "@gtmhub/goals/models";
import { ITag } from "@gtmhub/tags";
import { getCurrentUserId } from "@gtmhub/users";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { Goal } from "@webapp/okrs/goals/models/goal.models";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import {
  IQuantivePlusAcceptedMetric,
  IQuantivePlusAcceptedTask,
  IQuantivePlusMetricsAndTasksSuggestionStatus,
  IQuantivePlusRefreshedArgs,
  IQuantivePlusTag,
} from "@webapp/platform-intelligence/quantive-plus/models";
import { PIObjectiveTagsSuggestion, PIObjectiveTagsSuggestionPayload } from "@webapp/platform-intelligence/shared/models/quantive-plus-suggestions.models";
import { PIObjectiveDescriptionSuggestion, PIObjectiveDescriptionSuggestionPayload } from "@webapp/platform-intelligence/shared/models/strategic-guided-okr.models";
import { QuantivePlusSuggestionsFacade } from "@webapp/platform-intelligence/shared/services/quantive-plus-suggestions/quantive-plus-suggestions-facade.service";
import { StrategicGuidedOkrFacade } from "@webapp/platform-intelligence/shared/services/strategic-guided-okr/strategic-guided-okr-facade.service";
import { Task } from "@webapp/tasks/models/tasks.models";
import { IPostImprovementPayload, PIImproveSuggestionContext } from "../components/pi-feedback-card/services/improvement/models";
import { PiImprovementFacade } from "../components/pi-feedback-card/services/improvement/pi-improvement-facade.service";
import { IQuantivePlusMetric, IQuantivePlusObjectiveDescription } from "../models";
import { PiErrorData } from "../models/pi-errors.models";
import { getPiErrorData } from "../utils/pi-errors.utils";
import { ObjectiveFlowName, PiTrackingEventsEnum } from "../utils/pi-tracking";

type DescriptionStatus = {
  isLoading: boolean;
  value: string;
  isError: boolean;
  errorData: PiErrorData;
  suggestion: PIObjectiveDescriptionSuggestion;
  hasAcceptedDescription: boolean;
  description: string;
  hasProvidedFeedback: boolean;
};
type TagsStatus = {
  isLoading: boolean;
  value: IQuantivePlusTag[];
  isError: boolean;
  errorData: PiErrorData;
  suggestion: PIObjectiveTagsSuggestion;
  hasAcceptedTags: boolean;
  tags: ITag[];
  hasProvidedFeedback: boolean;
  flowId: string;
};

type QuantivePlusSuggestionPayload<T, K> = {
  payload: T;
  isRefresh: boolean;
  flowName: K;
  flowId: string;
  suggestionId: string;
};

export type SingleMetricSubject = { type: "refreshed" | "accepted" | "improving" | "replacing" } & {
  args: IQuantivePlusRefreshedArgs | IQuantivePlusAcceptedMetric;
  improvement: string;
  improveSuggestionContext: PIImproveSuggestionContext;
  suggestionToReplaceIndex: number;
  newSuggestionName: string;
};

type SingleTaskSubject = { type: "refreshed" | "accepted" } & {
  args: IQuantivePlusAcceptedTask;
};

@Injectable({
  providedIn: "root",
})
export class PiSuggestionSharedGoalStateService {
  private description$ = new BehaviorSubject<Partial<DescriptionStatus>>({ isLoading: false });
  private tags$ = new BehaviorSubject<Partial<TagsStatus>>({ isLoading: false });
  private keyResults$ = new BehaviorSubject<IQuantivePlusMetric[]>([]);
  private tasks$ = new Subject<QuantivePlusSuggestions>();
  private singleMetricTasks$ = new Subject<Partial<SingleTaskSubject>>();
  private goal$ = new BehaviorSubject<Partial<Goal>>(null);
  private metric$ = new Subject<Partial<Metric>>();
  private singleMetric$ = new Subject<Partial<SingleMetricSubject>>();
  private singleTask$ = new Subject<Partial<IQuantivePlusAcceptedTask>>();
  private quantiveSuggestionsFlowId$ = new Subject<string>();
  private refreshAllKeyResults = new Subject<void>();
  private goBackToLastSuggestion = new Subject<{ quantivePlusSuggestionIndex: number }>();

  private metricsAndTasksSuggestionStatus$ = new BehaviorSubject<IQuantivePlusMetricsAndTasksSuggestionStatus>(null);
  private taskList$ = new BehaviorSubject<Task[]>([]);
  private shouldSuggestQuantivePlusDescription = new BehaviorSubject<boolean>(false);
  private shouldSuggestQuantivePlusTags = new BehaviorSubject<boolean>(false);
  private shouldSuggestQuantivePlusMetrics = new BehaviorSubject<boolean>(false);
  private shouldSuggestQuantivePlusTasks = new BehaviorSubject<boolean>(false);

  constructor(
    private strategicGuidedOkrFacade: StrategicGuidedOkrFacade,
    private analyticsService: AnalyticsService,
    private quantivePlusSuggestionsFacade: QuantivePlusSuggestionsFacade,
    private piImprovementFacade: PiImprovementFacade
  ) {}

  public getDescription$(): Observable<Partial<DescriptionStatus>> {
    return this.description$.asObservable();
  }

  public resetDescription(): void {
    this.description$.next({ isLoading: false, isError: false, value: "", suggestion: null, description: "", hasAcceptedDescription: false });
  }

  public acceptDescription(description: string): void {
    this.description$.next({ hasAcceptedDescription: true, description });
  }

  public getDescription(opts: QuantivePlusSuggestionPayload<PIObjectiveDescriptionSuggestionPayload, ObjectiveFlowName>): void {
    if (opts.isRefresh) {
      this.analyticsService.track(PiTrackingEventsEnum.PiSuggestionRefreshed, {
        flowName: opts.flowName,
        flowId: opts.flowId,
        userId: getCurrentUserId(),
        suggestedEntity: "description",
        suggestionId: opts.suggestionId,
      });
    }

    this.description$.next({ isLoading: true });

    this.strategicGuidedOkrFacade
      .getObjectiveDescriptionSuggestion(opts.payload)
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          const description = data.suggestions[0].descriptions[0].description;
          this.description$.next({
            isLoading: false,
            isError: false,
            value: description,
            suggestion: data,
          });
        },
        error: (err: HttpErrorResponse) => {
          this.description$.next({ isLoading: false, isError: true, errorData: getPiErrorData("description", err) });
        },
      });
  }

  public replaceDescriptionSuggestion(props: { description: string; id: string; suggestion: PIObjectiveDescriptionSuggestion }): void {
    const clonedSuggestion = angular.copy(props.suggestion);
    clonedSuggestion.suggestions[0].descriptions[0].description = props.description;
    clonedSuggestion.suggestions[0].descriptions[0].id = props.id;

    this.description$.next({
      isLoading: false,
      isError: false,
      value: props.description,
      suggestion: clonedSuggestion,
    });
  }

  public postDescriptionImprovement(payload: IPostImprovementPayload, suggestion: PIObjectiveDescriptionSuggestion): void {
    this.description$.next({ isLoading: true });

    this.piImprovementFacade
      .postImprovement<IQuantivePlusObjectiveDescription>(payload)
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          this.replaceDescriptionSuggestion({ id: data.suggestions.improvement.id, description: data.suggestions.improvement.description, suggestion });
        },
        error: (err: HttpErrorResponse) => {
          this.description$.next({ isLoading: false, isError: true, errorData: getPiErrorData("description", err) });
        },
      });
  }

  public setDescriptionFeedbackAsProvided(): void {
    this.description$.next({ hasProvidedFeedback: true });
  }

  public goBackToLastAvailableDescriptionSuggestion(suggestion: PIObjectiveDescriptionSuggestion): void {
    this.description$.next({
      isLoading: false,
      isError: false,
      value: suggestion.suggestions[0].descriptions[0].description,
      suggestion,
    });
  }

  public replaceTagSuggestion(tags: IQuantivePlusTag[], suggestion: PIObjectiveTagsSuggestion): void {
    const clonedSuggestion = angular.copy(suggestion);
    clonedSuggestion.suggestions.tags = tags;

    this.tags$.next({ isLoading: false, isError: false, value: suggestion.suggestions.tags, suggestion: clonedSuggestion });
  }

  public postTagsImprovement(payload: IPostImprovementPayload, splitOrJoinSuffix: string, suggestion: PIObjectiveTagsSuggestion): void {
    this.tags$.next({ isLoading: true });

    this.piImprovementFacade
      .postImprovement<{ tags: IQuantivePlusTag[] }>(payload)
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          this.replaceTagSuggestion(data.suggestions.improvement.tags, suggestion);
        },
        error: (err: HttpErrorResponse) => {
          this.tags$.next({ isLoading: false, isError: true, errorData: getPiErrorData("tags", err) });
        },
      });
  }

  public acceptTAgs(tags: ITag[]): void {
    this.tags$.next({ hasAcceptedTags: true, tags });
  }

  public getTags$(): Observable<Partial<TagsStatus>> {
    return this.tags$.asObservable();
  }

  public resetTags(): void {
    this.tags$.next({ isLoading: false, isError: false, value: [], suggestion: null, tags: [], hasAcceptedTags: false });
  }

  public getTags(opts: QuantivePlusSuggestionPayload<PIObjectiveTagsSuggestionPayload, ObjectiveFlowName>): void {
    if (opts.isRefresh) {
      this.analyticsService.track(PiTrackingEventsEnum.PiSuggestionRefreshed, {
        flowName: opts.flowName,
        flowId: opts.flowId,
        userId: getCurrentUserId(),
        suggestedEntity: "tags",
        suggestionId: opts.suggestionId,
      });
    }

    this.tags$.next({ isLoading: true });

    this.quantivePlusSuggestionsFacade
      .getQuantivePlusObjectiveTagsSuggestion(opts.payload)
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          this.tags$.next({ isLoading: false, isError: false, value: data.suggestions.tags, suggestion: data });
        },
        error: (err: HttpErrorResponse) => {
          this.tags$.next({ isLoading: false, isError: true, errorData: getPiErrorData("tags", err) });
        },
      });
  }

  public setTagsFeedbackAsProvided(): void {
    this.tags$.next({ hasProvidedFeedback: true });
  }

  public goBackToLastAvailableTagsSuggestion(suggestion: PIObjectiveTagsSuggestion): void {
    this.tags$.next({
      isLoading: false,
      isError: false,
      value: suggestion.suggestions.tags,
      suggestion,
    });
  }

  public getSingleMetric$(): Observable<Partial<SingleMetricSubject>> {
    return this.singleMetric$.asObservable();
  }

  public setSingleMetric(args: Partial<SingleMetricSubject>): void {
    this.singleMetric$.next(args);
  }

  public setKeyResults(keyResults: IQuantivePlusMetric[]): void {
    this.keyResults$.next(keyResults);
  }

  public getMetrics$(): Observable<IQuantivePlusMetric[]> {
    return this.keyResults$.asObservable();
  }

  public setRefreshAllKeyResults(): void {
    this.refreshAllKeyResults.next();
  }

  public getRefreshAllKeyResults$(): Observable<void> {
    return this.refreshAllKeyResults.asObservable();
  }

  public setGoBackToLastSuggestion(suggestionIndex: number): void {
    this.goBackToLastSuggestion.next({ quantivePlusSuggestionIndex: suggestionIndex });
  }

  public getGoBackToLastSuggestion$(): Observable<{ quantivePlusSuggestionIndex: number }> {
    return this.goBackToLastSuggestion.asObservable();
  }

  public setTasks(tasks: QuantivePlusSuggestions): void {
    this.tasks$.next(tasks);
  }

  public setSingleMetricTasks(tasks: Partial<SingleTaskSubject>): void {
    this.singleMetricTasks$.next(tasks);
  }

  public getSingleMetricTasks$(): Observable<Partial<SingleTaskSubject>> {
    return this.singleMetricTasks$.asObservable();
  }

  public getTasks$(): Observable<QuantivePlusSuggestions> {
    return this.tasks$.asObservable();
  }

  public getSingleTask$(): Observable<Partial<IQuantivePlusAcceptedTask>> {
    return this.singleTask$.asObservable();
  }

  public setSingleTask(args: IQuantivePlusAcceptedTask): void {
    this.singleTask$.next(args);
  }

  public setGoal(goal: Partial<Goal>): void {
    this.goal$.next(goal);
  }

  public getGoal$(): Observable<Partial<Goal>> {
    return this.goal$.asObservable();
  }

  public setMetric(metric: Partial<Metric>): void {
    this.metric$.next(metric);
  }

  public getMetric$(): Observable<Partial<Metric>> {
    return this.metric$.asObservable();
  }

  public setMetricsAndTasksSuggestionStatus(status: IQuantivePlusMetricsAndTasksSuggestionStatus): void {
    this.metricsAndTasksSuggestionStatus$.next(status);
  }

  public getMetricsAndTasksSuggestionStatus$(): Observable<IQuantivePlusMetricsAndTasksSuggestionStatus> {
    return this.metricsAndTasksSuggestionStatus$.asObservable();
  }

  public setTaskList(tasks: Task[]): void {
    this.taskList$.next(tasks);
  }

  public getTaskList$(): Observable<Task[]> {
    return this.taskList$.asObservable();
  }

  public setShouldSuggestQuantivePlusDescription(val: boolean): void {
    this.shouldSuggestQuantivePlusDescription.next(val);
  }

  public getShouldSuggestQuantivePlusDescription(): Observable<boolean> {
    return this.shouldSuggestQuantivePlusDescription.asObservable();
  }

  public setShouldSuggestQuantivePlusTags(val: boolean): void {
    this.shouldSuggestQuantivePlusTags.next(val);
  }

  public getShouldSuggestQuantivePlusTags(): Observable<boolean> {
    return this.shouldSuggestQuantivePlusTags.asObservable();
  }

  public setShouldSuggestQuantivePlusMetrics(val: boolean): void {
    this.shouldSuggestQuantivePlusMetrics.next(val);
  }

  public getShouldSuggestQuantivePlusMetrics(): Observable<boolean> {
    return this.shouldSuggestQuantivePlusMetrics.asObservable();
  }

  public setShouldSuggestQuantivePlusTasks(val: boolean): void {
    this.shouldSuggestQuantivePlusTasks.next(val);
  }

  public getShouldSuggestQuantivePlusTasks(): Observable<boolean> {
    return this.shouldSuggestQuantivePlusTasks.asObservable();
  }

  public setQuantiveSuggestionsFlowId(flowId: string): void {
    this.quantiveSuggestionsFlowId$.next(flowId);
  }

  public getQuantiveSuggestionsFlowId(): Observable<string> {
    return this.quantiveSuggestionsFlowId$.asObservable();
  }
}
