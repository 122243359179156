export function sortArrayByProperty<T>(array: T[], property: keyof T): T[] {
  return array.sort((a, b) => {
    const valueA = String(a[property]).toLowerCase();
    const valueB = String(b[property]).toLowerCase();
    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }
    return 0;
  });
}
