import { ChangeDetectionStrategy, Component, Input, forwardRef } from "@angular/core";
import { IInsightSelectorData } from "@gtmhub/insights";
import { KpiAutomationData } from "@webapp/kpis/models/kpis.models";
import { FormItemBaseComponent } from "../form-item-base.component";

@Component({
  selector: "item-automation-form-item",
  templateUrl: "./item-automation-form-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FormItemBaseComponent, useExisting: forwardRef(() => ItemAutomationFormItemComponent) }],
})
export class ItemAutomationFormItemComponent extends FormItemBaseComponent<IInsightSelectorData> {
  @Input() public itemTitle: string;
  @Input() public itemType: "kr" | "kpi";
  @Input() public isEdit: boolean;
  @Input() public hasPermissionToSeeAutomation: boolean;
  @Input() public isAutomated: boolean;
  @Input() public kpiData: KpiAutomationData;
}
