import { IAttributes, IAugmentedJQuery, IDirective, IDirectiveFactory, IDirectiveLinkFn, INgModelController, IScope, ITranscludeFunction } from "angular";
import { untilScopeDestroyed } from "@gtmhub/core/rxjs";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";

export class FeatureToggle implements IDirective {
  public restrict = "A";
  public transclude = this.ngIf.transclude;
  public priority = this.ngIf.priority - 1;
  public terminal = true;

  constructor(
    private ngIf: IDirective,
    private featureTogglesFacade: FeatureTogglesFacade
  ) {}

  public link(scope: IScope, element: IAugmentedJQuery, attrs: IAttributes, ctrl: INgModelController, transclude: ITranscludeFunction): void {
    let featureToggleEnabled: boolean;
    const attrToObserve = attrs.featureToggleEnabled ? "featureToggleEnabled" : "featureToggleDisabled";

    // until the promise is resolved, always negate the if-expression
    const featureToggleIfEvaluator = (): boolean =>
      typeof featureToggleEnabled === "boolean" && ((attrs.featureToggleEnabled && featureToggleEnabled) || (attrs.featureToggleDisabled && !featureToggleEnabled));

    const initialNgIf = attrs.ngIf;
    let ifEvaluator: () => boolean;
    if (initialNgIf) {
      ifEvaluator = () => featureToggleIfEvaluator() && scope.$eval(initialNgIf);
    } else {
      ifEvaluator = featureToggleIfEvaluator;
    }
    attrs.ngIf = ifEvaluator;

    attrs.$observe<string>(attrToObserve, (featureKey) => {
      this.featureTogglesFacade
        .isFeatureAvailable$(featureKey)
        .pipe(untilScopeDestroyed(scope))
        .subscribe((enabled) => {
          featureToggleEnabled = enabled;
          scope.$evalAsync();
        });
    });

    const ngIfLinkFn = this.ngIf.link as IDirectiveLinkFn;
    ngIfLinkFn.apply(this.ngIf, [scope, element, attrs, ctrl, transclude]);
  }

  public static factory(): IDirectiveFactory {
    const directive = (ngIfDirective: IDirective[], featureTogglesFacade: FeatureTogglesFacade) => new FeatureToggle(ngIfDirective[0], featureTogglesFacade);
    directive.$inject = ["ngIfDirective", "FeatureTogglesFacade"];
    return directive;
  }
}
