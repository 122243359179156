<ng-template>
  <form [formGroup]="formGroup">
    <ui-input-text-area
      [formControl]="formControl"
      [maxCharacterCount]="maxCharsCount"
      [e2eTestId]="e2eTestId"
      [isReadonly]="readonly"
      [uiId]="controlId"
      [focusMe]="autofocus"
      [isBorderless]="borderless"
      [uiPlaceholder]="placeholder"
      [isRequired]="required"
      [isAutoresized]="true"
      updateOn="change"></ui-input-text-area>
  </form>
</ng-template>
