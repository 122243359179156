import { ComponentRef, TemplateRef } from "@angular/core";
import { UiProps } from "@quantive/ui-kit/core";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { Observable } from "rxjs";
import { UiDrawerPlacement } from "../drawer.models";

export type UiDrawerRefProps = UiProps<UiDrawerRef>;

export abstract class UiDrawerRef<T = NzSafeAny, R = NzSafeAny> {
  public abstract afterClose: Observable<R>;
  public abstract afterOpen: Observable<void>;
  public abstract close(result?: R): void;
  public abstract open(): void;
  public abstract getContentComponent(): T | null;
  public abstract getContentComponentRef(): Readonly<ComponentRef<T>> | null;
  public abstract uiClosable?: boolean;
  public abstract uiMaskClosable?: boolean;
  public abstract uiKeyboard?: boolean;
  public abstract uiMask?: boolean;
  public abstract uiTitle?: string | TemplateRef<unknown>;
  public abstract uiExtra?: string | TemplateRef<unknown>;
  public abstract uiFooter?: string | TemplateRef<unknown>;
  public abstract uiPlacement?: UiDrawerPlacement;
  public abstract uiMaskStyle?: object;
  public abstract uiBodyStyle?: object;
  public abstract uiWrapClassName?: string;
  public abstract uiWidth?: number | string;
  public abstract uiHeight?: number | string;
  public abstract uiZIndex?: number | string;
  public abstract uiOffsetX?: number | string;
  public abstract uiOffsetY?: number | string;

  // custom
  public abstract uiSidePanel?: string | TemplateRef<unknown>;
  public abstract uiLoadingIndicator?: boolean;
  public abstract uiCloseButtonClickedCallback?: () => void;
  public abstract setProperty<P extends keyof UiDrawerRefProps, V extends UiDrawerRef[P]>(prop: P, value: V): void;
}
