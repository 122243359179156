import { NgIf, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from "@angular/core";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { NzSelectPlaceholderComponent } from "ng-zorro-antd/select";
import { UiSelectItemInterface } from "../../select.models";

@Component({
  selector: "ui-select-placeholder",
  templateUrl: "select-placeholder.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NzOutletModule, NgTemplateOutlet],
})
export class UiSelectPlaceholderComponent extends NzSelectPlaceholderComponent {
  @Input() public uiPlaceHolderTemplate: TemplateRef<{ $implicit: UiSelectItemInterface }> | null = null;
}
