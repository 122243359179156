import { UIRouterGlobals } from "@uirouter/angular";
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, EMPTY, Observable, catchError, combineLatest, filter, map, of, take, takeWhile, tap, zip } from "rxjs";
import { UIErrorHandlingService } from "@gtmhub/error-handling";
import { AccountResolverService } from "@webapp/accounts";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { Comment, CommentsFacade } from "@webapp/comments";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { createErrorObject } from "@webapp/error-handling/error-util";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { FirstCommentContext, KpiChartCommentData, KpiChartData } from "@webapp/kpis/components/kpi-details/kpi-chart/kpi-chart.models";
import { KpiProjection } from "@webapp/kpis/models/kpi-projections.models";
import { KpiSnapshot } from "@webapp/kpis/models/kpi-snapshot.models";
import { Kpi, KpiTimeFilter } from "@webapp/kpis/models/kpis.models";
import { KpiProjectionsFacade } from "@webapp/kpis/services/kpi-projections/kpi-projections-facade.service";
import { KpiSnapshotsEventsMediatorService } from "@webapp/kpis/services/kpi-snapshots/kpi-snapshots-events.mediator.service";
import { KpiSnapshotsFacade } from "@webapp/kpis/services/kpi-snapshots/kpi-snapshots-facade.service";
import { KpisFacade } from "@webapp/kpis/services/kpis-facade.service";
import { MetricFormModalData } from "@webapp/okrs/metrics/components/create-metric-form/create-metric-form.models";
import { MetricTargetOperatorEnum } from "@webapp/okrs/metrics/models/metric.models";
import { PermissionsFacadeWithFeatureFlag } from "@webapp/permissions/services/permission-facade-ff.service";
import dayjs from "@webapp/shared/libs/dayjs";
import { CHART_POINT_DATE_FORMAT } from "../kpi-chart/utils";

const commentFileds: (keyof Comment)[] = ["id", "targetType", "targetId", "targetDate", "createdAt", "createdBy"];
@Injectable()
export class KpiDetailsStateService {
  constructor(
    private kpisFacade: KpisFacade,
    private kpiProjectionsFacade: KpiProjectionsFacade,
    private permissionsFacade: PermissionsFacadeWithFeatureFlag,
    private kpiSnapshotsFacade: KpiSnapshotsFacade,
    private accountResolverService: AccountResolverService,
    private routerGlobals: UIRouterGlobals,
    private commentsFacade: CommentsFacade,
    private uiErrorHandlingService: UIErrorHandlingService,
    private featureTogglesFacade: FeatureTogglesFacade,
    private editionFeatureService: EditionFeatureService,
    private kpiSnapshotsEventsMediatorService: KpiSnapshotsEventsMediatorService
  ) {}

  public canManageKpis$(): Observable<boolean> {
    return this.permissionsFacade.hasPermission$("ManageKPIs");
  }

  public canEditKpi$(): Observable<boolean> {
    return zip(this.getKpi$(), this.canManageKpis$()).pipe(map(([kpi, canManageKpis]) => canManageKpis && kpi.currentUserAllowedActions.includes("update")));
  }

  public canDeleteKpi$(): Observable<boolean> {
    return zip(this.getKpi$(), this.canManageKpis$(), this.isOnKpisScreen$).pipe(
      map(([kpi, canManageKpis, isOnKpisScreen]) => canManageKpis && kpi.currentUserAllowedActions.includes("delete") && isOnKpisScreen)
    );
  }

  public canAddCommentToPoint$(point: { targetType: string; targetDate: string }): Observable<boolean> {
    return combineLatest({
      canManage: this.canManageKpis$(),
      pointHasNoCommentsYet: this.pointHasNoCommentsYet$(point),
    }).pipe(
      take(1),
      map((response: { canManage: boolean; pointHasNoCommentsYet: boolean }) => response.canManage && response.pointHasNoCommentsYet)
    );
  }

  public canCreateKrFromKpi$(): Observable<boolean> {
    return zip([
      this.permissionsFacade.hasPermissionWithFeatureFlag$(FeatureFlag.ManageOKRsGranularPermissions, "goals:create", "ManageGoals"),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.AutomatingKRWithAKPI),
      this.editionFeatureService.hasFeature$("okrs.krs.kpi-automation"),
    ]).pipe(
      take(1),
      map(([canCreateMetrics, isFeatureAvailable, featureAvailable]) => {
        return canCreateMetrics && isFeatureAvailable && featureAvailable;
      })
    );
  }

  public getCreateMetricModalArgs$(): Observable<MetricFormModalData> {
    return zip(this.getKpi$(), this.getSnapshots$()).pipe(
      take(1),
      map(([kpi, snapshots]) => {
        return {
          kpiData: {
            kpiId: kpi.id,
            name: kpi.name,
            selectionType: "kpi",
            targetType: "kr",
            currentUserAllowedActions: kpi.currentUserAllowedActions,
            targetOperator: kpi.targetOperator === "should_increase" ? MetricTargetOperatorEnum.AT_LEAST : MetricTargetOperatorEnum.AT_MOST,
            initialValue: snapshots.length > 0 ? snapshots[snapshots.length - 1].value : 0,
            format: {
              prefix: kpi.formatting.prefix,
              suffix: kpi.formatting.suffix,
              fractionSize: 0,
              isCustomUnit: kpi.formatting.isCustomUnit,
            },
          },
          selectGoalInForm: true,
          sessionMode: "available-sessions",
        };
      })
    );
  }

  private pointHasNoCommentsYet$(point: { targetType: string; targetDate: string }): Observable<boolean> {
    return this.getComments$().pipe(
      map(
        (comments) =>
          !comments.some((comment) => comment.targetType === point.targetType && dayjs(comment.targetDate).format(CHART_POINT_DATE_FORMAT) === point.targetDate)
      )
    );
  }

  private firstCommentToPoint$: BehaviorSubject<FirstCommentContext> = new BehaviorSubject({
    inProgress: false,
  });

  public getAddFirstCommentToPointContext$(): Observable<FirstCommentContext> {
    return this.firstCommentToPoint$.asObservable();
  }

  public showAddFirstCommentView(pointData: KpiChartCommentData): void {
    this.firstCommentToPoint$.next({ inProgress: true, pointData });
  }

  public hideAddFirstCommentView(): void {
    this.firstCommentToPoint$.next({ inProgress: false });
  }

  private snapshots$: BehaviorSubject<KpiSnapshot[]> = new BehaviorSubject<KpiSnapshot[]>(null);
  public getSnapshots$(): Observable<KpiSnapshot[]> {
    return this.snapshots$.asObservable().pipe(filter((snapshot) => snapshot !== null));
  }

  private kpi$: BehaviorSubject<Kpi> = new BehaviorSubject<Kpi>(null);
  public getKpi$(): Observable<Kpi> {
    return this.kpi$.asObservable().pipe(filter((kpi) => kpi !== null));
  }

  private timeFilter$: BehaviorSubject<KpiTimeFilter> = new BehaviorSubject<KpiTimeFilter>("three-months");
  public getTimeFilter$(): Observable<KpiTimeFilter> {
    return this.timeFilter$.asObservable();
  }

  private projections$: BehaviorSubject<KpiProjection[]> = new BehaviorSubject<KpiProjection[]>(null);
  public getProjections$(): Observable<KpiProjection[]> {
    return this.projections$.asObservable().pipe(filter((projections) => projections !== null));
  }

  private comments$: BehaviorSubject<Comment[]> = new BehaviorSubject<Comment[]>(null);
  public getComments$(): Observable<Comment[]> {
    return this.comments$.asObservable().pipe(filter((comments) => comments !== null));
  }

  private chartRefresh$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  private getChartRefresh$(): Observable<void> {
    return this.chartRefresh$.asObservable();
  }

  private shouldBuildChart$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public getShouldBuildChart$(): Observable<boolean> {
    return this.shouldBuildChart$.asObservable();
  }

  public init(kpiId: string): void {
    this.fetchKpi(kpiId);
    this.fetchKpiSnapshots(kpiId);
    this.fetchKpiProjections(kpiId);
    this.fetchKpiComments(kpiId);
  }

  public isLoading$(): Observable<boolean> {
    return combineLatest({
      kpi: this.kpisFacade.getState().isLoading$(HttpActions.get),
      snapshots: this.kpisFacade.getState().isLoading$(HttpActions.getAll),
      projections: this.kpiProjectionsFacade.getState().isLoading$(HttpActions.get),
    }).pipe(
      map((response: { kpi: boolean; snapshots: boolean; projections: boolean }) => {
        return response.kpi || response.snapshots || response.projections;
      }),
      takeWhile((isLoading) => {
        return isLoading;
      }, true)
    );
  }

  public getError$(): Observable<HttpErrorResponse> {
    return combineLatest({
      kpi: this.kpisFacade.getState().isError$(HttpActions.get),
      snapshots: this.kpisFacade.getState().isError$(HttpActions.getAll),
      projections: this.kpiProjectionsFacade.getState().isError$(HttpActions.get),
    }).pipe(
      map(
        (errorResponse: { kpi: HttpErrorResponse | null; snapshots: HttpErrorResponse | null; projections: HttpErrorResponse | null }) =>
          errorResponse.kpi || errorResponse.snapshots || errorResponse.projections
      )
    );
  }

  public fetchKpi(kpiId: string): void {
    const filter = { _id: kpiId };
    const fields: (keyof Kpi)[] = ["name", "description", "formatting", "ownerIds", "insightLink", "targetOperator", "currentUserAllowedActions", "links", "groups"];
    this.kpisFacade
      .getAll$({ filter, fields })
      .pipe(tap(({ items: [kpi] }) => this.kpi$.next(kpi)))
      .subscribe();
  }

  public fetchKpiSnapshots(kpiId: string): void {
    const timeZone = this.accountResolverService.getTimezone();
    this.kpiSnapshotsFacade
      .getKpiSnapshots$(kpiId, { groupBy: "day", timeZone })
      .pipe(tap((snapshots) => this.snapshots$.next(snapshots[kpiId])))
      .subscribe();
  }

  public deleteKpiSnapshot$(kpiId: string, snapshotId: string): Observable<void> {
    return this.kpiSnapshotsFacade.deleteKpiSnapshot$(kpiId, snapshotId).pipe(
      tap(() => {
        this.snapshots$.next(this.snapshots$.value.filter((snapshot) => snapshot.snapshotId !== snapshotId));

        this.kpiSnapshotsEventsMediatorService.emitKpiSnapshotDeleted(kpiId, snapshotId);

        this.refreshChartData();
      })
    );
  }

  public fetchKpiProjections(kpiId: string): void {
    this.kpiProjectionsFacade
      .getProjectionsOfKpi$(kpiId)
      .pipe(tap((projections) => this.projections$.next(projections.items)))
      .subscribe();
  }

  public fetchKpiComments(kpiId: string): void {
    // the created by field is needed by the kpi-comment component regarding current user's permissions
    const filter = { fields: commentFileds };
    const queryParams = { targetIds: [kpiId] };

    this.commentsFacade
      .getComments$(filter, queryParams)
      .pipe(take(1))
      .subscribe((comments) => this.comments$.next(comments));
  }

  public fetchKpiProjectionsAndComments(kpiId: string): void {
    const commentsFilter = { fields: commentFileds };
    const commentsQueryParams = { targetIds: [kpiId] };

    zip([this.kpiProjectionsFacade.getProjectionsOfKpi$(kpiId), this.commentsFacade.getComments$(commentsFilter, commentsQueryParams)])
      .pipe(
        take(1),
        catchError((error) => {
          this.uiErrorHandlingService.handleModal(createErrorObject(error));
          this.refreshChartData();
          return EMPTY;
        })
      )
      .subscribe(([proj, comments]) => {
        this.projections$.next(proj.items);
        if (comments) {
          this.comments$.next(comments);
        }
      });
  }

  public changeTimeFilter(timeFilter: KpiTimeFilter): void {
    this.timeFilter$.next(timeFilter);
  }

  public getChartData$(): Observable<KpiChartData> {
    return combineLatest({
      snapshots: this.getSnapshots$(),
      kpi: this.getKpi$(),
      projections: this.getProjections$(),
      timeFilter: this.getTimeFilter$(),
      chartRefresh: this.getChartRefresh$(),
      canEditKpi: this.canEditKpi$(),
      canManageKpis: this.canManageKpis$(),
    });
  }

  public refreshChartData(): void {
    this.chartRefresh$.next();
  }

  public setShouldBuildChart(value: boolean): void {
    this.shouldBuildChart$.next(value);
  }

  public isKpiCommentsOnboardingAvailable$(): Observable<boolean> {
    return combineLatest({
      snapshots: this.getSnapshots$(),
      projections: this.getProjections$(),
    }).pipe(
      map(({ snapshots, projections }) => {
        const hasSnapshots = snapshots.length > 0;
        const hasProjections = projections.length > 0;

        return hasSnapshots || hasProjections;
      })
    );
  }

  public onCommentCreated(kpiId: string): void {
    this.fetchKpiComments(kpiId);
  }

  public onCommentDeleted(commentId: string): void {
    const deletedComment = this.comments$.value.find((comment) => comment.id === commentId);
    const commentsWithoutTheDeletedOneAndItsReplies = this.comments$.value.filter((comment) => {
      const commentIsTheDeletedOne = comment.id === deletedComment.id;
      // no need to check if targetId is the same since we fetch comments by current kpiId (targetId) so all comments are with the same targetId
      const commentIsOnTheSameDateAndEntity = comment.targetDate === deletedComment.targetDate && comment.targetType === deletedComment.targetType;
      return !commentIsTheDeletedOne && !commentIsOnTheSameDateAndEntity;
    });
    this.comments$.next(commentsWithoutTheDeletedOneAndItsReplies);
  }

  private get isOnKpisScreen$(): Observable<boolean> {
    return of(this.routerGlobals.current.name.startsWith("gtmhub.kpis.details"));
  }
}
