import { Component, OnInit } from "@angular/core";
import { BehaviorSubject, Observable, combineLatest, map } from "rxjs";
import { CurrentEmployeeActions, ICurrentEmployeeStoreState } from "@gtmhub/employees";
import { EmployeeEventType, IEmployeeUpdatedArgs } from "@gtmhub/employees/services/events";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { ITeam, ITeamsStoreState, TeamsActions, addAssigneeDataToTeamsMap } from "@gtmhub/teams";
import { ITeamDeletedArgs, ITeamEventArgs, TeamEventType } from "@gtmhub/teams/events";
import { getCurrentUserId } from "@gtmhub/users";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { AssigneesFacade } from "@webapp/assignees/services/assignees/assignees-facade.service";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { ICollection } from "@webapp/core/core.models";
import { ReduxStoreObserver } from "@webapp/core/state-management/redux-store-observer";
import { INavItem, NavItemsSectionBroadcastConfig } from "@webapp/navigation/models/nav-items-list.models";
import { SubNavOption } from "@webapp/navigation/models/sub-nav-options.models";
import NavigationItemsMediator from "@webapp/navigation/services/uxcustomization/navigation-items.mediator.service";
import { navItemsFactory } from "@webapp/navigation/utils/nav-items.util";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { BaseSubNav } from "../services/base-sub-nav";
import { NavItemsSectionBroadcastConfigFactory } from "../services/nav-items-section-broadcast-config.factory";

@Component({
  selector: "people-sub-navigation",
  templateUrl: "./people-sub-navigation.component.html",
})
export class PeopleSubNavigationComponent extends BaseSubNav implements OnInit {
  public options: SubNavOption[] = [
    { label: "employees", state: "gtmhub.employees", showOptionCondition$: this.permissionsFacade.hasPermission$("AccessPeople") },
    { label: "teams_cap", state: "gtmhub.teams", showOptionCondition$: this.showTeams() },
    { label: "organization", state: "gtmhub.organization_chart", showOptionCondition$: this.showOrgChart() },
  ];
  public myTeamsSubject$: BehaviorSubject<ICollection<INavItem>> = new BehaviorSubject<ICollection<INavItem>>({ items: [], totalCount: 0 });
  public myTeams$: Observable<ICollection<INavItem>> = this.myTeamsSubject$.asObservable();
  public broadcastConfig: NavItemsSectionBroadcastConfig = this.broadcastConfigFactory.buildDefaultNavItemsSectionBroadcastConfig();

  public constructor(
    protected navigationItemsMediator: NavigationItemsMediator,
    private broadcastConfigFactory: NavItemsSectionBroadcastConfigFactory,
    private broadcastService: BroadcastService,
    private currentEmployeeActions: CurrentEmployeeActions,
    private teamsActions: TeamsActions,
    private assigneesFacade: AssigneesFacade,
    private editionFeatureService: EditionFeatureService,
    private permissionsFacade: PermissionsFacade
  ) {
    super();
    this.setupEntityTypes(["user", "team"], "people");
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const { reduxStore } = reduxStoreContainer;
    reduxStore.dispatch(this.currentEmployeeActions.fetchCurrentEmployeeIfMissing());
    reduxStore.dispatch(this.teamsActions.fetchTeamsIfMissing());

    const reduxObserver = new ReduxStoreObserver(reduxStore);
    reduxObserver.whenFetched$<ICurrentEmployeeStoreState & ITeamsStoreState>("currentEmployee", "teams").subscribe((state) => {
      const allTeamIds = [...state.currentEmployee.info.teamIds, ...state.currentEmployee.info.managedTeamIds];
      const teams = Object.values(addAssigneeDataToTeamsMap(state.teams.map, this.assigneesFacade.getAssigneesIdMap())).filter((team) => allTeamIds.includes(team.id));
      this.transformTeamsToNavItems(teams);
    });
    this.favorites$.subscribe(this.handleFavoritesChanged.bind(this));
    this.broadcastService.on<ITeamEventArgs>(TeamEventType.TEAM_UPDATED).subscribe(this.handleTeamUpdated.bind(this));
    this.broadcastService.on<ITeamDeletedArgs>(TeamEventType.TEAM_DELETED).subscribe(this.handleTeamDeleted.bind(this));
    this.broadcastService.on<ITeamEventArgs>(TeamEventType.TEAM_CREATED).subscribe(this.handleTeamCreated.bind(this));
    this.broadcastService.on<ITeamEventArgs>(EmployeeEventType.EMPLOYEE_UPDATED).subscribe(this.handleEmployeeUpdated.bind(this));
  }

  private handleFavoritesChanged(favorites: ICollection<INavItem>): void {
    if (this.myTeamsSubject$.value) {
      const newItems = this.myTeamsSubject$.value.items.map((teamNavItem) => {
        const favorite = favorites.items.find((item) => item.id === teamNavItem.id);
        return {
          ...teamNavItem,
          favoriteId: favorite ? favorite.favoriteId : undefined,
        };
      });
      this.emitMyTeamsValue(newItems);
    }
  }

  private handleTeamUpdated(teamUpdatedArgs: ITeamEventArgs): void {
    let newItems = [];
    const currentUserIsTeamMember = teamUpdatedArgs.team.members && teamUpdatedArgs.team.members.some((memberId) => memberId === getCurrentUserId());
    const currentUserIsManager = teamUpdatedArgs.team.manager === getCurrentUserId();
    if (currentUserIsManager || currentUserIsTeamMember) {
      let updated = false;
      newItems = this.myTeamsSubject$.value.items.map((item) => {
        if (item.id === teamUpdatedArgs.team.id) {
          updated = true;
          return navItemsFactory.buildTeamNavItem(teamUpdatedArgs.team);
        }
        return item;
      });
      if (!updated) {
        newItems = [...this.myTeamsSubject$.value.items, navItemsFactory.buildTeamNavItem(teamUpdatedArgs.team)];
      }
    } else {
      newItems = this.myTeamsSubject$.value.items.filter((item) => item.id !== teamUpdatedArgs.team.id);
    }
    this.emitMyTeamsValue(newItems);
  }

  private handleTeamDeleted(teamDeletedArgs: ITeamDeletedArgs): void {
    const filteredItems = this.myTeamsSubject$.value.items.filter((item) => item.id !== teamDeletedArgs.teamId);
    this.emitMyTeamsValue(filteredItems);
  }

  private handleTeamCreated(teamCreatedArgs: ITeamEventArgs): void {
    const currentUserIsTeamMember = teamCreatedArgs.team.members && teamCreatedArgs.team.members.some((memberId) => memberId === getCurrentUserId());
    const currentUserIsManager = teamCreatedArgs.team.manager === getCurrentUserId();
    if (currentUserIsManager || currentUserIsTeamMember) {
      this.emitMyTeamsValue([...this.myTeamsSubject$.value.items, navItemsFactory.buildTeamNavItem(teamCreatedArgs.team)]);
    }
  }

  private handleEmployeeUpdated(employeeUpdatedArgs: IEmployeeUpdatedArgs): void {
    if (employeeUpdatedArgs.employee.id === getCurrentUserId()) {
      const { reduxStore } = reduxStoreContainer;
      reduxStore.dispatch(this.teamsActions.fetchTeamsIfMissing());

      const reduxObserver = new ReduxStoreObserver(reduxStore);
      reduxObserver.whenFetched$<ITeamsStoreState>("teams").subscribe((state) => {
        const allTeamIds = [...employeeUpdatedArgs.employee.teamIds, ...employeeUpdatedArgs.employee.managedTeamIds];
        const teams = Object.values(addAssigneeDataToTeamsMap(state.teams.map, this.assigneesFacade.getAssigneesIdMap())).filter((team) => allTeamIds.includes(team.id));
        this.transformTeamsToNavItems(teams);
      });
    }
  }

  private transformTeamsToNavItems(teams: ITeam[]): void {
    const teamNavItems = teams.map((team: ITeam) => navItemsFactory.buildTeamNavItem(team));
    if (this.favoritesCollection) {
      teamNavItems.forEach((teamNavItem) => {
        const favorite = this.favoritesCollection.items.find((item) => item.id === teamNavItem.id);
        if (favorite) {
          teamNavItem.favoriteId = favorite.favoriteId;
        }
      });
    }
    this.emitMyTeamsValue(teamNavItems);
  }

  private emitMyTeamsValue(items: INavItem[]): void {
    const alphabeticallySortedTeams = items.sort((lhs, rhs) => lhs.title.localeCompare(rhs.title));
    this.myTeamsSubject$.next({
      items: alphabeticallySortedTeams,
      totalCount: items.length,
    });
  }

  private showTeams(): Observable<boolean> {
    return combineLatest([this.editionFeatureService.hasFeature$("people.teams"), this.permissionsFacade.hasPermission$("AccessPeople")]).pipe(
      map(([hasEditionFeaturePeopleTeams, hasPermissionAccessPeople]) => hasEditionFeaturePeopleTeams && hasPermissionAccessPeople)
    );
  }

  private showOrgChart(): Observable<boolean> {
    return combineLatest([this.editionFeatureService.hasFeature$("people.organization"), this.permissionsFacade.hasPermission$("AccessPeople")]).pipe(
      map(([hasEditionFeaturePeopleOrganization, hasPermissionAccessPeople]) => hasEditionFeaturePeopleOrganization && hasPermissionAccessPeople)
    );
  }
}
