import { WeekDay } from "@angular/common";
import { localize } from "@gtmhub/localization";
import dayjs from "@webapp/shared/libs/dayjs";
import { TodoItem } from "../todos-widget.models";

const isDateInCurrentWeek = (date: string | dayjs.Dayjs | Date): boolean => {
  // day(1) -> Monday, as dayjs uses an index based system to get the week days, treating Sunday as the first day of the week (0 index, en-US locale standard)
  // startOf day(1) -> Monday 00:00
  const startOfWeek = dayjs().day(1).startOf("day");

  // day(7) -> returns the upcoming Sunday; day(0) would return the previous Sunday from EU point of view (en-US locale standard treats Sunday as the first day of the week)
  // endOf day(7) -> Sunday 23:59
  const endOfWeek = dayjs().day(7).endOf("day");

  return dayjs(date).isSameOrAfter(startOfWeek) && dayjs(date).isSameOrBefore(endOfWeek);
};

export const isDateInThePast = (date: string | dayjs.Dayjs | Date): boolean => {
  const now = dayjs();
  return now.isAfter(date);
};

const getWeekDayTranslationKey = (date: string | dayjs.Dayjs | Date): string => {
  const weekDayIndex = dayjs(date).get("day");
  const weekDayName = WeekDay[weekDayIndex];

  return weekDayName.toLowerCase();
};

export const getTodoDueDate = (todo: TodoItem): string => {
  const isExplicitlyMarkedAsOvedue = todo && "isOverdue" in todo && todo.isOverdue;
  if (isExplicitlyMarkedAsOvedue) {
    return localize("overdue");
  }

  const dueDate = todo && "dueDate" in todo && todo.dueDate;
  if (!dueDate) {
    return "";
  }

  const date = dayjs(dueDate);

  if (isDateInThePast(date)) {
    return localize("overdue");
  }

  if (date.isToday()) {
    return localize("due_today");
  }

  if (date.isTomorrow()) {
    return localize("due_tomorrow");
  }

  if (isDateInCurrentWeek(date)) {
    return localize("due_on", { day: localize(getWeekDayTranslationKey(date)) });
  }

  return localize("due_on", { day: date.format("DD MMM") });
};
