import { localize } from "@gtmhub/localization";
import { IdMap, NameMap } from "@gtmhub/util";
import { EmptyState, InfoBox } from "./models";

export const getEmptyStateInfoBoxes = (): IdMap<InfoBox[]> => ({
  insightboard: [],
  kpis: [
    {
      title: localize("leverage_kpis_with_okrs"),
      subtext: localize("using_kpis_combined_with_okrs"),
      icon: require("wwwroot/img/empty-states/kpis/bulb-icon.png"),
    },
    {
      title: localize("manage_and_monitor_kpis"),
      subtext: localize("in_this_video_create_group_monitor_kpis"),
      icon: require("wwwroot/img/empty-states/kpis/eye-icon.png"),
      videoUrl: "https://www.youtube.com/embed/NsIkxUjfuRY",
      videoDuration: 4,
    },
  ],
  lists: [
    {
      title: localize("control_access"),
      subtext: localize("lists_can_be_private_shared_or_visible"),
      icon: require("wwwroot/img/empty-states/lists/people-icon.png"),
    },
    {
      title: localize("export_lists_to_excel"),
      subtext: localize("use_exports_as_part_of_your_company"),
      icon: require("wwwroot/img/empty-states/lists/file-icon.png"),
    },
  ],
  lists_reports: [
    {
      title: localize("control_access"),
      subtext: localize("reports_can_be_private_shared_or_visible"),
      icon: require("wwwroot/img/empty-states/lists/people-icon.png"),
    },
    {
      title: localize("export_reports_to_excel"),
      subtext: localize("use_exports_as_part_of_your_company"),
      icon: require("wwwroot/img/empty-states/lists/file-icon.png"),
    },
  ],
  automation: [
    {
      title: localize("work_with_automations"),
      subtext: localize("automation_helps_you_systemize_the_process"),
      icon: require("wwwroot/img/empty-states/automation/swap-icon.png"),
    },
    {
      title: localize("create_automations"),
      subtext: localize("learn_how_to_use_automations_video"),
      icon: require("wwwroot/img/empty-states/automation/gear-icon.png"),
      videoDuration: 4,
      videoUrl: "https://www.youtube.com/embed/lZgEmoTG-Qo",
    },
  ],
  tasks: [
    {
      title: localize("work_with_tasks"),
      subtext: localize("learn_how_to_create_and_associate_tasks"),
      icon: require("wwwroot/img/empty-states/tasks/list-icon.png"),
      videoDuration: 4,
      videoUrl: "https://www.youtube.com/embed/BG-0n59iao8",
    },
    {
      title: localize("link_jira_issue_to_gtmhub"),
      subtext: localize("learn_about_possible_jura_integrations"),
      icon: require("wwwroot/img/empty-states/tasks/jira-icon.png"),
    },
  ],
  insight: [],
  whiteboardsBasicUser: [
    {
      title: localize("brainstorm_and_analyze_okrs"),
      subtext: localize("draft_okrs_and_publish_them"),
      icon: require("wwwroot/img/empty-states/whiteboards/brainstorm-and-analyze.png"),
    },
    {
      title: localize("start_with_template"),
      subtext: localize("start_with_template_subtext"),
      icon: require("wwwroot/img/empty-states/whiteboards/template.png"),
    },
  ],
  whiteboardsAdmin: [
    {
      title: localize("brainstorm_and_analyze_okrs"),
      subtext: localize("draft_okrs_and_publish_them"),
      icon: require("wwwroot/img/empty-states/whiteboards/brainstorm-and-analyze.png"),
    },
    {
      title: localize("ideate_with_consultant"),
      titleUrl: "/marketplace/consulting",
      subtext: localize("did_you_know_that_we_partner_with"),
      icon: require("wwwroot/img/empty-states/whiteboards/ideate-with-consultant.png"),
    },
  ],
  whiteboards_trial: [],
  automations_teaser: [],
  okr_views_regular: [],
});

export function emptyStatesMap(): NameMap<EmptyState> {
  return {
    insightboard: {
      title: localize("ready_to_use_insightboards"),
      customClasses: "insightboards-empty-state",
    },
    kpis: {
      title: localize("put_power_in_kpis_with_okrs"),
      image: require("wwwroot/img/empty-states/kpis/main-image.svg"),
    },
    lists: {
      title: localize("extract_the_essentials_organize_and_share"),
      image: require("wwwroot/img/empty-states/lists/main-image.svg"),
    },
    lists_reports: {
      title: localize("extract_the_essentials_organize_and_share"),
      image: require("wwwroot/img/empty-states/lists_reports/main-image.svg"),
    },
    automation: {
      title: localize("take_your_time_back_with_automations"),
      image: require("wwwroot/img/empty-states/automation/main-image.svg"),
      withoutShadow: true,
    },
    tasks: {
      title: localize("organize_work_in_trenches"),
      image: require("wwwroot/img/empty-states/tasks/main-image.svg"),
    },
    insight: {
      title: localize("your_board_is_empty"),
      image: require("wwwroot/img/empty-states/insights/main-image.svg"),
      customClasses: "insight-empty-state",
      withoutShadow: true,
    },
    whiteboards: {
      title: localize("brainstorm_ideas_and_strategy"),
      image: require("wwwroot/img/empty-states/whiteboards/main-gif.gif"),
    },
    whiteboards_trial: {
      title: localize("brainstorm_ideas_and_strategy"),
      image: require("wwwroot/img/empty-states/whiteboards/illustration-whiteboards.svg"),
      customClasses: "whiteboard-trials",
      withoutShadow: true,
    },
    reports_teaser: {
      title: localize("track_your_progress_with_reports"),
      image: require("wwwroot/img/empty-states/reports/main-image.svg"),
      withoutShadow: true,
    },
    automations_teaser: {
      title: localize("take_your_time_back_with_automations"),
      image: require("wwwroot/img/empty-states/automation/teaser.svg"),
      withoutShadow: true,
    },
    okr_views_regular: {
      title: localize("okr_views_onboarding_screen_title"),
      image: require("wwwroot/img/empty-states/okr_views/okr-views.svg"),
    },
  };
}
