import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { encode as base64encode } from "js-base64";
import { Subscription } from "rxjs";
import { ConnectorService } from "@gtmhub/connectors/connector.service";
import { IConnection } from "@gtmhub/connectors/models";
import { DataSourceService } from "@gtmhub/datasources";
import { IIndicatorMap } from "@gtmhub/error-handling";
import { localize } from "@gtmhub/localization";
import { DataSourceDTO, DataSourcePatchDTO, DataSourcePostDTO } from "@webapp/data-story/models/data-source.models";
import { ConnectionData } from "@webapp/data-story/models/insights.models";
import { EditPowerBIModalData } from "@webapp/integrations/power-bi/components/configure-power-bi/configure-power-bi.component";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";

@Component({
  selector: "power-bi-form",
  templateUrl: "./power-bi-form.component.html",
  styleUrls: ["./power-bi-form.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PowerBIFormComponent implements OnInit, OnDestroy {
  @Input() public type: "kpi" | "kr";

  public form: FormGroup;
  private subscriptions: Subscription[] = [];
  public datasets: DataSourceDTO[] = [];
  public workspaces: IConnection[] = [];
  public indicators: IIndicatorMap = {
    loadingWorkspaces: { progress: true },
    loadingDatasets: null,
    submittingForm: null,
  };
  public shouldUpdateDatasource = false;
  public formErrors: {
    upperFormError?: string;
    inlineDAXQueryError?: string;
    lowerFormError?: string;
  } = {};
  private genericErrorLocalizationKey: string;

  @Output() public readonly backButtonClicked = new EventEmitter<void>();
  @Input() public connection: ConnectionData;
  @Input() public isEdit: boolean;
  @Input() public dataSourceDetails: EditPowerBIModalData;

  constructor(
    private formBuilder: FormBuilder,
    private connectorService: ConnectorService,
    private dataSourceService: DataSourceService,
    private cdRef: ChangeDetectorRef,
    private modalRef: UiModalRef
  ) {}

  public ngOnInit(): void {
    this.loadConnections();
    this.form = this.formBuilder.group({
      workspace: new FormControl("", [Validators.required]),
      dataset: new FormControl({ value: "", disabled: true }, [Validators.required]),
      daxQuery: new FormControl("", [Validators.required]),
    });

    this.subscribeToValueChanges();

    this.setGenericErrorLocalizationKey();
  }

  private setGenericErrorLocalizationKey(): void {
    this.genericErrorLocalizationKey = this.type === "kpi" ? "unable_to_automate_kpi" : "unable_to_automate_kr";
  }

  public ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  private loadConnections(): void {
    this.connectorService.getConnections(this.connection.name).then((connections: IConnection[]) => {
      this.workspaces = connections;

      if (this.workspaces.length === 1) {
        this.form.controls["workspace"].setValue(this.workspaces[0]);
      }

      if (this.dataSourceDetails && this.dataSourceDetails?.workspaceName) {
        const workspaceToBePreselected: IConnection = this.workspaces.find((workspace) => workspace.name === this.dataSourceDetails.workspaceName);
        this.form.controls["workspace"].setValue(workspaceToBePreselected);
        this.form.controls["workspace"].disable();
      }

      delete this.indicators.loadingWorkspaces;
      this.cdRef.detectChanges();
    });
  }

  private subscribeToValueChanges(): void {
    const workspaceSub: Subscription = this.form.controls["workspace"].valueChanges.subscribe((value: IConnection) => {
      this.onWorkspaceChange(value);
    });

    // clear inlineDAXQueryError when daxQuery changes with valid value
    const daxQuerySub: Subscription = this.form.controls["daxQuery"].valueChanges.subscribe(() => {
      if (!this.form.controls["daxQuery"].invalid && this.formErrors.inlineDAXQueryError) delete this.formErrors.inlineDAXQueryError;
    });

    this.subscriptions.push(workspaceSub, daxQuerySub);
  }

  private onWorkspaceChange(workspace: IConnection): void {
    this.form.controls["dataset"].reset();

    if (workspace) {
      this.indicators.loadingDatasets = { progress: true };
      this.indicators.loadingDAXQuery = { progress: true };

      this.dataSourceService.getDataSources(workspace.id).then((datasets: DataSourceDTO[]) => {
        this.datasets = datasets;

        if (this.isEdit && this.dataSourceDetails && this.dataSourceDetails?.dataSetName) {
          const datasetToBePreselected: DataSourceDTO = this.datasets.find((dataset) => dataset.name === this.dataSourceDetails.dataSetName);
          this.form.controls["dataset"].setValue(datasetToBePreselected);
          this.form.controls["daxQuery"].setValue(this.dataSourceDetails.daxQuery);
        }

        delete this.indicators.loadingDatasets;

        if (!this.isEdit) this.form.controls["dataset"].enable();
      });
    } else this.form.controls["dataset"].disable();
  }

  private clearSubscriptions(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public onBackButtonClick(): void {
    this.backButtonClicked.emit();
  }

  public closeModal(): void {
    this.modalRef.close();
  }

  public onFormSubmit(): void {
    if (this.form.invalid) {
      return this.form.markAllAsTouched();
    }

    const createDataSourcePayload: DataSourcePostDTO = {
      syncSchedule: this.form.controls["dataset"].value.syncSchedule,
      connectorId: this.form.controls["workspace"].value.id,
      virtualFields: {
        algorithm: base64encode(this.form.get("daxQuery").getRawValue()),
        type: "PowerBI",
        settings: {
          datasetID: this.form.controls["dataset"].value.key,
          originalDataSourceID: this.form.controls["dataset"].value.id,
        },
      },
      settings: {
        workspaceName: this.form.controls["workspace"].value.name,
        dataSetName: this.form.controls["dataset"].value.name,
      },
    };

    const updateDataSourcePayload: DataSourcePatchDTO = {
      virtualFields: {
        algorithm: base64encode(this.form.get("daxQuery").getRawValue()),
        type: "PowerBI",
        settings: {
          datasetID: this.form.controls["dataset"].value.key,
          originalDataSourceID: this.form.controls["dataset"].value.id,
        },
      },
    };

    this.indicators.submittingForm = { progress: true };
    this.formErrors = {};

    if (this.isEdit) {
      this.dataSourceService.patchDataSource(this.dataSourceDetails.dataSourceId, updateDataSourcePayload).then(
        () => {
          this.formErrors = {};
        },
        (error) => {
          delete this.indicators.submittingForm;
          this.handleDSCreateOrUpdateError(error);
        }
      );
    } else {
      this.dataSourceService.createDataSource(createDataSourcePayload).then(
        () => {
          this.formErrors = {};
        },
        (error) => {
          delete this.indicators.submittingForm;
          this.handleDSCreateOrUpdateError(error);
        }
      );
    }
  }

  private handleDSCreateOrUpdateError(error: { status: number }): void {
    this.formErrors = {};
    switch (error.status) {
      case 400:
        this.formErrors.inlineDAXQueryError = localize("invalid_query_syntax");
        break;
      case 410:
        this.formErrors.upperFormError = localize("workspace_dataset_no_longer_available");
        break;
      case 503:
        this.formErrors.lowerFormError = localize("power_bi_is_down");
        break;
      case 500:
      default:
        this.formErrors.lowerFormError = localize(this.genericErrorLocalizationKey);
        break;
    }
  }
}
