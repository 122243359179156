import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { PiCardBorderColor, PiCardBorderStyle } from "@webapp/platform-intelligence/shared/models/pi-card.models";
import { IPIFeedbackSuggestion } from "../models";
import { PiStateProcessorInstance } from "../services/state-processor/pi-state-processor.models";
import { PiStateProcessorService } from "../services/state-processor/pi-state-processor.service";

@UntilDestroy()
@Component({
  selector: "pi-card-wrapper",
  templateUrl: "./pi-card-wrapper.component.html",
  styleUrls: ["./pi-card-wrapper.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiCardWrapperComponent implements OnInit {
  public isHovered: boolean;
  public isSelected = false;

  @Input()
  public isToggleEnabled = true;
  @Input()
  public borderStyle: PiCardBorderStyle = "solid";
  @Input()
  public borderColorOnHover: PiCardBorderColor = "dark";
  @Input()
  public isAskAiVisible = false;
  @Input()
  public isReadonly = false;

  @Input()
  public instance: PiStateProcessorInstance;
  @Input()
  public suggestion: IPIFeedbackSuggestion<string | string[]>;

  constructor(
    private piStateProcessorService: PiStateProcessorService,
    private cd: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    if (this.instance) {
      this.subscribeToInstanceEvents();
    }
  }

  private subscribeToInstanceEvents(): void {
    this.instance.isFeedbackCardFocused.pipe(untilDestroyed(this)).subscribe({
      next: (suggestionCard) => {
        if (this.suggestion?.index === suggestionCard.index) {
          this.isSelected = suggestionCard.isFocused;
          this.cd.markForCheck();

          return;
        }

        this.isSelected = false;
        this.cd.markForCheck();
      },
    });
  }

  public toggleCard(): void {
    if (!this.isToggleEnabled) {
      return;
    }

    this.isSelected = !this.isSelected;

    if (!this.instance) {
      return;
    }

    this.piStateProcessorService.focusCurrentInstanceAndCloseOthers({
      instanceId: this.instance.id,
      suggestionIndex: this.suggestion?.index,
      isFocused: this.isSelected,
    });
  }
}
