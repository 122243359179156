<div class="sp-m-0 sp-p-8" [uiGutter]="[24, 24]" ui-row>
  <!-- icon size is 20px + 8px margin-right -->
  <div class="sp-p-0 bullet-icon-col" ui-col nzFlex="28px">
    <div [ngClass]="iconWrapperClasses">
      <i class="fs-16px text-color-gray-9" [uiType]="icon" ui-icon></i>
    </div>
  </div>

  <div class="sp-p-0" ui-col nzFlex="auto">
    <div class="sp-pb-2" ui-row>
      <div ui-col nzFlex="auto">
        <suggestion-drawer-paragraph [text]="title" size="small" weight="semibold"></suggestion-drawer-paragraph>
      </div>
    </div>

    <div ui-row>
      <div ui-col nzFlex="auto">
        <suggestion-drawer-paragraph [text]="description" size="small" contrast="medium"></suggestion-drawer-paragraph>
      </div>
    </div>
  </div>
</div>
