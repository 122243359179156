import { IPromise, IRootScopeService, IScope } from "angular";
import { IStateInit } from "@gtmhub/core/routing";
import { IUIError, UIErrorHandlingService } from "@gtmhub/error-handling";
import { INgRedux } from "@gtmhub/state-management";
import { Assignee, AssigneeSelectorType } from "@webapp/assignees/models/assignee.models";
import { IExtendedRootScopeService } from "../../shared/ng-extensions/models";
import { AssigneeActions } from "../redux/assignee-actions";
import { IAssigneesStoreState } from "../redux/assignee-reducer";

interface ISelectMultipleAssigneesRedux {
  assignees: Assignee[];
  itemsFetched: boolean;
  itemsError: IUIError;
}

const bindStateToScope = (state: IAssigneesStoreState): ISelectMultipleAssigneesRedux => {
  return {
    assignees: state.assignees.items,
    itemsFetched: state.assignees.isFetched,
    itemsError: state.assignees.error,
  };
};

export interface ISelectMultipleAssigneesScope extends IScope, IExtendedRootScopeService, ISelectMultipleAssigneesRedux {
  indicators: {
    loading: boolean;
  };
  itemsFetched: boolean;
  itemsError: IUIError;
  selectedAssignees: string[];
  countSelected: number;
  toggleAssignee(id: string): void;
  finish(): void;
}

export class SelectMultipleAssigneesCtrl implements IStateInit {
  public static $inject = ["$ngRedux", "AssigneeActions", "$rootScope", "$scope", "UIErrorHandlingService", "selectedAssigneeIdsFromController", "filterBy"];

  constructor(
    $ngRedux: INgRedux,
    assigneeActions: AssigneeActions,
    private $rootScope: IRootScopeService,
    private $scope: ISelectMultipleAssigneesScope,
    private uiErrorHandlingService: UIErrorHandlingService,
    private selectedAssigneeIdsFromController?: string,
    private filterBy?: { type: AssigneeSelectorType }
  ) {
    this.$scope.indicators = {
      loading: false,
    };
    this.$scope.selectedAssignees = [];
    this.$scope.countSelected = 0;

    this.$scope.toggleAssignee = this.toggleAssignee;
    this.$scope.finish = this.finish;

    if (this.selectedAssigneeIdsFromController) {
      this.loadSelectedAssigneeIds(this.selectedAssigneeIdsFromController);
      this.$scope.countSelected = this.$scope.selectedAssignees.length || 0;
    }

    const unsubscribe = $ngRedux.connect(bindStateToScope)(this.$scope);
    this.$scope.$on("$destroy", unsubscribe);
    $ngRedux.dispatch(assigneeActions.getAssignees());
  }

  public stateInit(): IPromise<unknown> {
    return this.init();
  }

  private init = (): IPromise<unknown> => {
    this.$scope.indicators.loading = true;

    return this.$scope
      .watchUntilAndRejectOnError(
        () => this.$scope.itemsFetched,
        () => this.$scope.itemsError
      )
      .then(
        () => {
          if (this.filterBy) {
            this.$scope.assignees = this.$scope.assignees.filter((a) => a.type === this.filterBy.type);
          }
          this.$scope.$broadcast("focusTextInput");
          this.$scope.indicators.loading = false;
        },
        () => this.uiErrorHandlingService.handleModal(this.$scope.itemsError)
      );
  };

  private loadSelectedAssigneeIds = (assigneeIds: string): void => {
    if (!assigneeIds) {
      return;
    }
    const assigneeIdsNew: string[] = assigneeIds.toString().split(",");
    for (const assigneeId of assigneeIdsNew) {
      this.$scope.selectedAssignees.push(assigneeId);
    }
  };

  private toggleAssignee = (id: string): void => {
    if (!this.$scope.selectedAssignees.includes(id)) {
      this.$scope.selectedAssignees.push(id);
      this.$scope.countSelected++;
    } else {
      const index = this.$scope.selectedAssignees.indexOf(id);
      this.$scope.selectedAssignees.splice(index, 1);
      this.$scope.countSelected--;
    }
  };

  private finish = (): void => {
    this.$rootScope.$broadcast("assigneesSelected", this.$scope.selectedAssignees);
  };
}
