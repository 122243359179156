import { IUser } from "@gtmhub/users";
import { IAssigneeExtended } from "../components/user-selector/user-selector.component";

export const assignIsRestrictedPropToAssignee = (params: { assignees: IAssigneeExtended[]; users: IUser[]; requiredPermission: string }): void => {
  params.assignees.forEach((assignee) => {
    const assigneeUser = params.users.find((user) => user.id === assignee.id) || { permissions: [] };

    assignee.isRestricted = assignee.type === "team" ? false : !(assigneeUser.permissions || []).some((p) => p === params.requiredPermission);
  });
};
