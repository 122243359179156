import { Direction } from "@angular/cdk/bidi";
import { NgFor, NgIf, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, Output, TemplateRef } from "@angular/core";
import { CandyDate, CompatibleValue } from "ng-zorro-antd/core/time";
import { SingleValue } from "ng-zorro-antd/core/time/candy-date";
import { FunctionProp } from "ng-zorro-antd/core/types";
import { ɵDateRangePopupComponent as DateRangePopupComponent } from "ng-zorro-antd/date-picker";
import {
  UiCalendarInterface,
  UiCompatibleDate,
  UiDateMode,
  UiDisabledDateFn,
  UiDisabledTimeFn,
  UiPresetRanges,
  UiSupportTimeOptions,
} from "@webapp/ui/date-picker/date-picker.models";
import { UiDatePickerService } from "@webapp/ui/date-picker/services/date-picker.service";
import { UiCalendarFooterComponent } from "../calendar-footer/calendar-footer.component";
import { UiInnerPopupComponent } from "../inner-popup/inner-popup.component";

@Component({
  selector: "ui-date-range-popup",
  exportAs: "uiDateRangePopup",
  templateUrl: "date-range-popup.component.html",
  styleUrls: ["./date-range-popup.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "ui-date-range-popup",
  },
  standalone: true,
  imports: [UiInnerPopupComponent, NgTemplateOutlet, UiCalendarFooterComponent, NgIf, NgFor],
})
export class UiDateRangePopupComponent extends DateRangePopupComponent {
  @Input("uiIsRange")
  public isRange!: boolean;
  @Input("uiInline")
  public inline = false;
  @Input("uiShowWeek")
  public showWeek!: boolean;
  @Input("uiLocale")
  public locale!: UiCalendarInterface | undefined;
  @Input("uiDisabledDate")
  public disabledDate?: UiDisabledDateFn;
  @Input("uiDisabledTime")
  public disabledTime?: UiDisabledTimeFn; // This will lead to rebuild time options
  @Input("uiShowToday")
  public showToday!: boolean;
  @Input("uiShowNow")
  public showNow!: boolean;
  @Input("uiShowTime")
  public showTime!: UiSupportTimeOptions | boolean;
  @Input("uiExtraFooter")
  public extraFooter?: TemplateRef<void> | string;
  @Input("uiRanges")
  public ranges?: UiPresetRanges;
  @Input("uiDateRender")
  public dateRender?: string | TemplateRef<Date> | FunctionProp<TemplateRef<Date> | string>;
  @Input("uiPanelMode")
  public panelMode!: UiDateMode | UiDateMode[];
  @Input("uiDefaultPickerValue")
  public defaultPickerValue!: UiCompatibleDate | undefined | null;
  @Input("uiDir")
  public dir: Direction = "ltr";

  @Output("uiPanelModeChange")
  public readonly panelModeChange = new EventEmitter<UiDateMode | UiDateMode[]>();
  @Output("uiCalendarChange")
  public readonly calendarChange = new EventEmitter<CompatibleValue>();
  @Output("uiResultOk")
  public readonly resultOk = new EventEmitter<void>(); // Emitted when done with date selecting

  /** used by date-picker.component.ts to locate the elements inside the popup */
  public elementRef: ElementRef<HTMLElement>;

  constructor(
    public datePickerService: UiDatePickerService,
    public cdr: ChangeDetectorRef,
    ngZone: NgZone,
    host: ElementRef<HTMLElement>
  ) {
    super(datePickerService, cdr, ngZone, host);
    this.elementRef = host;
  }

  // eslint-disable-next-line @foxglove/no-boolean-parameters, @typescript-eslint/no-inferrable-types
  public changeValueFromSelect(value: CandyDate, emitValue: boolean = true): void {
    const keepFocusToEndDate = this.datePickerService.isRange && this.datePickerService.activeInput === "right";
    this.setTimeForRange(value);
    super.changeValueFromSelect(value, emitValue);
    if (keepFocusToEndDate) {
      this.datePickerService.activeInput = "right";
      this.datePickerService.inputPartChange$.next("right");
    }
    // When initializing the range picker with already selected dates, the emitValue$ is not triggered
    // and the value is not emitted. This is a workaround to emit the value in this case.
    if (this.datePickerService.isRange && super.isBothAllowed(this.datePickerService.value as SingleValue[]) && (!this.checkedPartArr[0] || !this.checkedPartArr[1])) {
      this.datePickerService.emitValue$.next();
    }
  }

  private setTimeForRange(value: CandyDate): void {
    if (this.datePickerService.isRange) {
      if (this.datePickerService.activeInput === "left") {
        value.setHms(0, 0, 0);
      } else {
        value.setHms(23, 59, 59);
      }
    }
  }
}
