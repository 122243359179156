import { IAttributes, IAugmentedJQuery, IDirective, IDirectiveFactory, IScope } from "angular";

interface IToggleDropdownAttrs extends IAttributes {
  toggleDropdown: string;
}

export class ToggleDropdown implements IDirective {
  private isOpen: boolean = null;

  public link(scope: IScope, element: IAugmentedJQuery, attrs: IToggleDropdownAttrs): void {
    // clicking on a focused element won't close the dropdown
    scope.$watch(attrs.toggleDropdown, (value: boolean) => {
      this.isOpen = value;
    });

    element.on("click", (event) => {
      if (this.isOpen) {
        event.preventDefault();
        event.stopPropagation();
      }
    });
  }

  public static factory(): IDirectiveFactory {
    const directive = () => new ToggleDropdown();
    directive.$inject = [];
    return directive;
  }
}
