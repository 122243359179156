import { TransitionService, UIRouterGlobals } from "@uirouter/angular";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, map, zip } from "rxjs";
import { ICollection } from "@webapp/core/core.models";
import { fromTransitionHook } from "@webapp/core/routing/rxjs";
import { ModuleFlag } from "@webapp/feature-toggles/models/feature-module.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { HomeScreenView } from "@webapp/home/models/home.models";
import { SubNavOption } from "@webapp/navigation/models/sub-nav-options.models";
import NavigationItemsMediator from "@webapp/navigation/services/uxcustomization/navigation-items.mediator.service";
import { getNavItemsType } from "@webapp/navigation/utils/nav-items.util";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { CurrentUserRepository } from "@webapp/users";
import { INavItem, NavItemsGroupType, NavItemsSectionBroadcastConfig, NavItemsType } from "../../../../models/nav-items-list.models";
import { NavigationEvents } from "../../navigation.events";
import { NavItemsSectionBroadcastConfigFactory } from "../services/nav-items-section-broadcast-config.factory";

@UntilDestroy()
// Todo: remove this when the new navigation is ready
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: "home-sub-navigation",
  templateUrl: "./home-sub-navigation.component.html",
})
export class HomeSubNavigationComponent implements OnInit {
  @Output()
  public readonly addBorder = new EventEmitter<boolean>();

  public options: SubNavOption[];
  public sessions$: Observable<ICollection<INavItem>>;
  public okrs$: Observable<ICollection<INavItem>>;
  public kpis$: Observable<ICollection<INavItem>>;
  public whiteboards$: Observable<ICollection<INavItem>>;
  public lists$: Observable<ICollection<INavItem>>;
  public insightboards$: Observable<ICollection<INavItem>>;
  public people$: Observable<ICollection<INavItem>>;
  public loading$: Observable<boolean>;
  public visibleItemsCount = 3;
  public indicators: { loading?: boolean } = {};
  public itemsGroupType = "favorites" as NavItemsGroupType;
  public broadcastConfig: NavItemsSectionBroadcastConfig = this.broadcastConfigFactory.buildDefaultNavItemsSectionBroadcastConfig();
  public homeHasNoFavorites = false;
  public sectionsWithItems: NavItemsType[];
  public hasUserAccessToGoals$: Observable<boolean>;

  public constructor(
    protected navigationItemsMediator: NavigationItemsMediator,
    private currentUserRepository: CurrentUserRepository,
    private broadcastConfigFactory: NavItemsSectionBroadcastConfigFactory,
    private routerGlobals: UIRouterGlobals,
    private transitionService: TransitionService,
    private permissionsFacade: PermissionsFacade,
    private featureToggleFacade: FeatureTogglesFacade
  ) {}

  public ngOnInit(): void {
    this.options = this.setOptions();
    this.hasUserAccessToGoals$ = this.permissionsFacade.hasPermission$("AccessGoals");

    const SECTIONS_LIST: NavItemsType[] = ["sessions", "okrs", "kpis", "whiteboards", "lists", "insightboards", "people"];
    this.sectionsWithItems = SECTIONS_LIST;
    this.navigationItemsMediator.loadFavorites(SECTIONS_LIST).subscribe();

    SECTIONS_LIST.forEach((navItemsType: NavItemsType) => {
      this[navItemsType + "$"] = this.navigationItemsMediator.getFavoritesItems$(navItemsType);
      this[navItemsType + "$"].subscribe((collection) => {
        if (!collection) return;
        this.collectionUpdated(navItemsType, collection.totalCount);
      });
    });

    const state = this.routerGlobals.current.name;
    const activeScreen = state.split(".")[2];
    this.addBorder.emit(activeScreen === "next");

    fromTransitionHook(this.transitionService, "onSuccess", {})
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const state = this.routerGlobals.current.name;
        const activeScreen = state.split(".")[2];
        const homeScreenViewValueLabel = { [HomeScreenView.dashboard]: HomeScreenView.dashboard, [HomeScreenView.feed]: "feed" };
        if (activeScreen in homeScreenViewValueLabel) {
          this.setActiveOption(homeScreenViewValueLabel[activeScreen]);
        }
      });
  }

  private setOptions(): SubNavOption[] {
    return [
      {
        label: "dashboard",
        icon: "grid-view",
        state: "gtmhub.home.dashboard",
        broadcastMessage: NavigationEvents.SUB_NAV_SWITCH_TO_DASHBOARD,
      },
      {
        label: "feed",
        icon: "parent-child-view",
        state: "gtmhub.home.feed",
        broadcastMessage: NavigationEvents.SUB_NAV_SWITCH_TO_FEED,
        showOptionCondition$: zip([this.permissionsFacade.hasPermission$("AccessGoals"), this.featureToggleFacade.isFeatureAvailable$(ModuleFlag.Feed)]).pipe(
          untilDestroyed(this),
          map(([hasAccessGoals, featureAvailable]) => {
            return hasAccessGoals && featureAvailable;
          })
        ),
      },
    ];
  }

  private setActiveOption(activeOptionLabel: HomeScreenView): void {
    this.addBorder.emit(activeOptionLabel === HomeScreenView.dashboard);

    const labelHomeScreenViewValue = { dashboard: HomeScreenView.dashboard, feed: HomeScreenView.feed };
    this.options.forEach((option) => {
      if (option.label === activeOptionLabel) {
        option.isActive = true;
        this.currentUserRepository.setUserSetting({ homeScreen: labelHomeScreenViewValue[option.label] });
      } else {
        option.isActive = false;
      }
    });
  }

  private collectionUpdated(collectionName: NavItemsType, totalCount: number): void {
    if (totalCount === 0) {
      this.sectionsWithItems = this.sectionsWithItems.filter((item) => item !== collectionName);
    } else {
      this.sectionsWithItems.push(collectionName);
    }
    this.homeHasNoFavorites = this.sectionsWithItems.length === 0;
  }

  public onUnmarkItemAsFavorite(item: INavItem): void {
    const navItemsType: NavItemsType | undefined = getNavItemsType(item.uxcType);
    if (navItemsType !== undefined) {
      this.navigationItemsMediator.unmarkItemAsFavorite(item.favoriteId, item.uxcType, item, navItemsType);
    }
  }
}
