import { IHttpService, IPromise, IQService } from "angular";
import { ThunkAction } from "redux-thunk";
import { IRestLayerRequest } from "@gtmhub/core";
import { EnvironmentService } from "@gtmhub/env";
import { IUIError, createUIError } from "@gtmhub/error-handling";
import { IUser, getCurrentUserId } from "@gtmhub/users";
import { ICollection } from "@webapp/core/core.models";
import {
  CurrentUserRolesAction,
  ICurrentUserRoles,
  ICurrentUserRolesStoreState,
  IReceiveCurrentUserRolesAction,
  IReceiveCurrentUserRolesErrorAction,
  IRequestCurrentUserRolesAction,
} from "./models";

const SHADOW = "shadowRoles";
const NON_SHADOW = "nonShadowRoles";

const requestCurrentUserRoles = (): IRequestCurrentUserRolesAction => ({ type: "REQUEST_CURRENT_USER_ROLES" });
export const receiveCurrentUserRoles = ({ nonShadowRoles, shadowRoles }: ICurrentUserRoles): IReceiveCurrentUserRolesAction => {
  return { type: "RECEIVE_CURRENT_USER_ROLES", shadowRoles, nonShadowRoles };
};
const receiveCurrentUserRolesError = (error: IUIError): IReceiveCurrentUserRolesErrorAction => ({ type: "RECEIVE_CURRENT_USER_ROLES_ERROR", error });
const shouldFetchCurrentUserRoles = (state: ICurrentUserRolesStoreState): boolean => !state.currentUserRoles.fetching && !state.currentUserRoles.isFetched;

const generateGetUsersParams = (userId: string): IRestLayerRequest => {
  return {
    filter: {
      _id: userId,
    },
    fields: "roles{id,name,team{name}}",
  };
};

export class CurrentUserRolesActions {
  public static $inject = ["$http", "$q", "EnvironmentService"];

  constructor(
    private $http: IHttpService,
    private $q: IQService,
    private env: EnvironmentService
  ) {}

  getCurrentUserRoles(): ThunkAction<IPromise<unknown>, ICurrentUserRolesStoreState, null, CurrentUserRolesAction> {
    return (dispatch, getState) => {
      if (!shouldFetchCurrentUserRoles(getState())) {
        return this.$q.resolve();
      }

      dispatch(requestCurrentUserRoles());

      const url = this.env.getApiEndpointV2("/users");
      return this.$http.get<ICollection<IUser>>(url, { params: generateGetUsersParams(getCurrentUserId()) }).then(
        (response) => dispatch(receiveCurrentUserRoles(this.processCurrentUserRoles(response.data.items))),
        (rejection) => dispatch(receiveCurrentUserRolesError(createUIError(rejection)))
      );
    };
  }

  private processCurrentUserRoles(users: IUser[]): ICurrentUserRoles {
    const currentUser = users[0];
    if (!currentUser) {
      return { nonShadowRoles: [], shadowRoles: [] };
    }

    return currentUser.roles.reduce(
      (roles, role) => {
        const res = {
          name: role.name,
          id: role.id,
        };

        const roleType = role.team ? SHADOW : NON_SHADOW;
        roles[roleType].push(res);

        return roles;
      },
      { nonShadowRoles: [], shadowRoles: [] }
    );
  }
}
