import { IAngularEvent, IHttpResponse, IHttpService, IPromise, IQService, IRequestShortcutConfig, IRootScopeService, angularFileUpload } from "angular";
import md5 from "md5";
import { IRestLayerRequest } from "@gtmhub/core";
import { EnvironmentService } from "@gtmhub/env";
import { FileService } from "@gtmhub/files";
import { IFileUploadedData, IUploadImageResponse } from "@gtmhub/files/models";
import { ISendChangePasswordRequestRequest, IUserMetadata } from "@gtmhub/login/models";
import { ISocketV2Data } from "@gtmhub/sockets";
import { applySocketUpdatesToIdMap } from "@gtmhub/sockets/util";
import { IdMap, getFullCollectionInBatches, toIdMap } from "@gtmhub/util";
import { ICollection } from "@webapp/core/core.models";
import { PluginGtmhubAdditionalParams } from "@webapp/plugins/plugins.models";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";
import { getCurrentUserId } from "./current-user-storage";
import {
  IBulkActionOnUsersResponse,
  IBulkUpdateUsersRolesResponse,
  IGetUsersParams,
  IInvitationRequest,
  IInvitationRequestWithTeamsAndRolesMap,
  IPatchedUser,
  IUpdateUserSettings,
  IUser,
  IUsersIdMap,
  IUsersLimit,
} from "./models";

const defaultGetUsersParams: IGetUsersParams = {
  skip: 0,
  // also set to 100 in the backend
  take: 100,
  filter: null,
  includeShadowedRoles: true,
};

export class UserService {
  private usersIdMapCache: IPromise<IdMap<IUser>>;

  public static $inject = ["$rootScope", "$http", "$q", "Upload", "FileService", "UserProfileService", "EnvironmentService"];

  constructor(
    private $rootScope: IRootScopeService,
    private $http: IHttpService,
    private $q: IQService,
    private $upload: angularFileUpload.IUploadService,
    private fileService: FileService,
    private profileService: UserProfileService,
    private env: EnvironmentService
  ) {
    this.$rootScope.$on("accountUsersUpdatedV2", (evt: IAngularEvent, usersUpdates: ISocketV2Data<IUser>) => this.accountUsersUpdated(usersUpdates));
  }

  public uploadAvatar = (user: IUser, dataUrl: string): IPromise<string> => {
    const fileName = md5(user.email);
    const file = this.$upload.dataUrltoBlob(dataUrl, fileName);

    let uploadAvatarPromise: IPromise<IUploadImageResponse>;

    if (user.id === getCurrentUserId()) {
      uploadAvatarPromise = this.fileService.uploadAvatarForCurrentUser(file);
    } else {
      uploadAvatarPromise = this.fileService.uploadAvatarForUser(user, file);
    }

    return uploadAvatarPromise.then((result) => {
      return result.url.replace("upload/", "upload/q_auto:eco/v100/").replace(".png", ".jpg").replace("http:", "https:");
    });
  };

  public uploadAvatarV2 = (user: IUser, dataUrl: string): IPromise<string> => {
    const fileName = md5(user.email);
    const fileBlob = this.$upload.dataUrltoBlob(dataUrl, fileName);
    const file = new File([fileBlob], fileName);

    let uploadAvatarPromise: IPromise<IFileUploadedData>;

    if (user.id === getCurrentUserId()) {
      uploadAvatarPromise = this.fileService.uploadAvatarForCurrentUserV2({ file, targetId: user.id, targetType: "user", ref: "picture" });
    } else {
      uploadAvatarPromise = this.fileService.uploadAvatarForUserV2(user, { file, targetId: user.id, targetType: "user", ref: "picture" });
    }

    return uploadAvatarPromise.then((result) => {
      return result.url;
    });
  };

  public updateRoles(userIds: string[], roleIds: string[]): IPromise<IBulkUpdateUsersRolesResponse> {
    const url = this.env.getApiEndpoint("/users/roles");
    const packet = {
      roleIds: roleIds,
      userIds: userIds,
    };

    return this.$http.post<IBulkUpdateUsersRolesResponse>(url, packet).then((response) => response.data);
  }

  public patchUser(userId: string, patchedUser: IPatchedUser, additionalGtmhubParams?: PluginGtmhubAdditionalParams): IPromise<void> {
    const url = this.env.getApiEndpoint(`/users/${userId}`);

    const config: IRequestShortcutConfig = {
      params: additionalGtmhubParams,
    };

    return this.$http.patch<void>(url, patchedUser, config).then(() => {
      if (userId === getCurrentUserId()) {
        this.profileService.updateProfileNames({
          firstName: patchedUser.firstName,
          lastName: patchedUser.lastName,
        });
      }
    });
  }

  public deleteUsers(userIds: string[]): IPromise<IBulkActionOnUsersResponse> {
    const url = this.env.getApiEndpoint("/users/delete");

    return this.$http.post<IBulkActionOnUsersResponse>(url, userIds).then((response) => response.data);
  }

  public createUser(invite: IInvitationRequest, options: { skipEmail?: boolean }, additionalGtmhubParams?: PluginGtmhubAdditionalParams): IPromise<IUser> {
    let url = this.env.getApiEndpoint("/users");
    if (options.skipEmail) {
      url += "?skipEmail=true";
    }

    // set first name to the email and last to empty space
    invite.firstName = invite.email.split("@")[0];
    invite.lastName = " ";

    const config: IRequestShortcutConfig = {
      params: additionalGtmhubParams,
    };

    return this.$http.post<IUser>(url, invite, config).then((response) => {
      this.$rootScope.$broadcast("userCreated", response.data);
      return response.data;
    });
  }

  public createUsers(
    invitations: IInvitationRequestWithTeamsAndRolesMap,
    options?: { sendEmailInvitaion: boolean },
    additionalGtmhubParams?: PluginGtmhubAdditionalParams
  ): IPromise<ICollection<IUser>> {
    let url = this.env.getApiEndpointV2("/users");
    if (!options.sendEmailInvitaion) {
      url += "?skipEmail=true";
    }

    // set first name to the email and last to empty space
    Object.values(invitations).forEach((i) => {
      i.firstName = i.email.split("@")[0];
      i.lastName = " ";
    });

    const config: IRequestShortcutConfig = {
      params: additionalGtmhubParams,
    };

    return this.$http.post<ICollection<IUser>>(url, invitations, config).then((response) => {
      this.$rootScope.$broadcast("userCreated", response.data);
      return response.data;
    });
  }

  public getUser(
    userId: string,
    options: { includeShadowedRoles: boolean } = { includeShadowedRoles: true },
    additionalGtmhubParams?: PluginGtmhubAdditionalParams
  ): IPromise<IUser> {
    const url = this.env.getApiEndpoint(`/users/${userId}`);
    const config: IRequestShortcutConfig = {
      params: {
        includeShadowedRoles: options.includeShadowedRoles,
        ...additionalGtmhubParams,
      },
    };

    return this.$http.get<IUser>(url, config).then((response) => response.data);
  }

  public updateUserSettings(userId: string, userSettings: IUpdateUserSettings): IPromise<string> {
    const url = this.env.getApiEndpoint(`/users/${userId}`);

    return this.$http.put<string>(url, userSettings).then((response) => response.data);
  }

  public updateUserInfo(userId: string, userInfo: IUserMetadata): IPromise<void> {
    const url = this.env.getApiEndpoint(`/users/${userId}/info`);

    return this.$http.patch<IUserMetadata>(url, userInfo).then((response) => {
      this.profileService.updateProfileNames(response.data);
    });
  }

  public getManagers(userId: string): IPromise<ICollection<string>> {
    const url = this.env.getApiEndpoint(`/users/${userId}/managers`);

    return this.$http.get<ICollection<string>>(url).then((response) => response.data);
  }

  public getUsers(params: IGetUsersParams = defaultGetUsersParams): IPromise<ICollection<IUser>> {
    const config: IRequestShortcutConfig = { params };
    const url = this.env.getApiEndpoint("/accounts/users");

    return this.$http.get<ICollection<IUser>>(url, config).then((response) => response.data);
  }

  public getUsersV2<Model = IUser>(params: IRestLayerRequest, additionalGtmhubParams?: PluginGtmhubAdditionalParams): IPromise<ICollection<Model>> {
    const url = this.env.getApiEndpointV2("/users");

    const config: IRequestShortcutConfig = {
      params: { ...params, additionalGtmhubParams },
    };

    return this.$http.get<ICollection<Model>>(url, config).then((response) => response.data);
  }

  public getAllUsers(query: Pick<IGetUsersParams, "filter" | "includeShadowedRoles"> = { filter: null, includeShadowedRoles: true }): IPromise<ICollection<IUser>> {
    const params: IGetUsersParams = { ...query, skip: 0, take: 2500 };
    const url = this.env.getApiEndpoint("/accounts/users");

    return getFullCollectionInBatches(url, { params }, this.$http, this.$q);
  }

  public getUsersIdMap(): IPromise<IUsersIdMap> {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (this.usersIdMapCache) {
      return this.usersIdMapCache;
    }

    const promise = this.getAllUsers()
      .then((fullCollection) => toIdMap(fullCollection.items))
      .catch((response: IHttpResponse<unknown>) => {
        this.usersIdMapCache = null;
        return this.$q.reject(response);
      });

    this.usersIdMapCache = promise;

    return promise;
  }

  public deleteUser(userId: string, params?: PluginGtmhubAdditionalParams): IPromise<void> {
    const url = this.env.getApiEndpoint(`/accounts/user/${userId}`);

    const config: IRequestShortcutConfig = {
      params,
    };

    return this.$http.delete<void>(url, config).then(() => null);
  }

  public activateUsers(userIds: string[]): IPromise<IBulkActionOnUsersResponse> {
    const url = this.env.getApiEndpoint(`/users/activate`);

    return this.$http.post<IBulkActionOnUsersResponse>(url, userIds).then((response) => response.data);
  }

  public deactivateUsers(userIds: string[]): IPromise<IBulkActionOnUsersResponse> {
    const url = this.env.getApiEndpoint(`/users/deactivate`);

    return this.$http.post<IBulkActionOnUsersResponse>(url, userIds).then((response) => response.data);
  }

  public sendChangePasswordRequest(userId: string, data: ISendChangePasswordRequestRequest): IPromise<unknown> {
    const url = this.env.getApiEndpoint(`/users/${userId}/send-change-password-request`);

    return this.$http.post(url, data);
  }

  public validateUserEmail(email: string): IPromise<void> {
    const url = this.env.getApiEndpoint("users/validate-email");

    return this.$http.post<void>(url, { email }).then((response) => response.data);
  }

  private accountUsersUpdated(usersUpdates: ISocketV2Data<IUser>): void {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (this.usersIdMapCache) {
      this.usersIdMapCache = this.usersIdMapCache.then((usersIdMap) => this.$q.resolve(applySocketUpdatesToIdMap(usersIdMap, usersUpdates)));
    }
  }

  public getUserLimit(numberOfEmailsToBeAdded: number): IPromise<IUsersLimit> {
    const url = this.env.getApiEndpoint(`/users/limit`);
    const config: IRequestShortcutConfig = {
      params: {
        addingUsersCount: numberOfEmailsToBeAdded,
      },
    };
    return this.$http.get<IUsersLimit>(url, config).then((response) => response.data);
  }

  public patchFavoriteSystemGroup(payload: { groupId: string; favorite: boolean }): IPromise<unknown> {
    const url: string = this.env.getApiEndpoint(`/users/kpi-virtual-group/favorite`);

    return this.$http.patch<unknown>(url, payload);
  }
}
