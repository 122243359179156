import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnChanges } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiAssigneeI18nInterface, UiI18nService } from "@quantive/ui-kit/i18n";
import { UiLabelComponent } from "@quantive/ui-kit/label";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { Assignee, AssigneeType, MissingAssignee, UnknownAssignee } from "@webapp/assignees/models/assignee.models";
import { isMissingAssignee, isUnknownAssignee } from "@webapp/assignees/utils/assignee.utils";

/**
 * Displays the name of an assignee. If the assignee is not active, it adds a suffix `(deactivated)`.
 * This suffix is displayed on the same line, unless `uiMultiline` is set to true. When you want to
 * control the length of the name, you can set `uiEllipsis` (the parent container must have
 * a limited width to take effect).
 *
 * If the assignee does not exist, it displays `(deleted user)`.
 */
@UntilDestroy()
@Component({
  selector: "ui-assignee-name",
  exportAs: "uiAssigneeName",
  templateUrl: "assignee-name.component.html",
  styleUrls: ["./assignee-name.component.less"],
  host: {
    "[class.ellipsis]": `uiEllipsis`,
    "[class.multiline]": `uiMultiline`,
    "[style.text-align]": `uiAlign`,
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, UiLabelComponent],
})
export class UiAssigneeNameComponent implements OnChanges {
  @Input()
  public uiAssignee: Assignee | UnknownAssignee | MissingAssignee;

  @Input()
  @InputBoolean()
  public uiEllipsis?: boolean;

  @Input()
  @InputBoolean()
  public uiMultiline?: boolean;

  @Input()
  public uiAlign?: "left" | "right" | "center" = "left";

  @Input()
  public uiAssigneeType?: AssigneeType;

  @Input()
  public uiPattern?: string;

  @Input()
  public uiDeactivatedLabel?: boolean;

  /**
   * @internal
   */
  public isUnknown?: boolean;

  /**
   * @internal
   */
  public isMissing?: boolean;

  /**
   * @internal
   */
  public isDeactivated?: boolean;

  /**
   * @internal
   */
  public fullName?: string;

  /**
   * @internal
   */
  public locale: UiAssigneeI18nInterface;

  constructor(i18n: UiI18nService) {
    i18n.localeChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.locale = i18n.getLocaleData("Assignee");
    });
  }

  private getFullName(): string | undefined {
    if (!this.uiAssignee) {
      return;
    }

    if (isUnknownAssignee(this.uiAssignee) && this.uiAssigneeType === "team") {
      this.uiAssignee.name = this.locale.deletedTeam;
    }

    const name = (this.uiAssignee.name || "").trim();
    return this.uiPattern ? this.uiPattern.replace("%s", name) : name;
  }

  public ngOnChanges(): void {
    this.fullName = this.getFullName();
    this.isDeactivated = this.uiAssignee && !isUnknownAssignee(this.uiAssignee) && this.uiAssignee.isActive === false;
    this.isUnknown = !this.uiAssignee || isUnknownAssignee(this.uiAssignee);
    this.isMissing = !this.uiAssignee || isMissingAssignee(this.uiAssignee);
  }
}
