import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import {
  QuantivePlusKeyResultsEffectivenessPayload,
  QuantivePlusKeyResultsEffectivenessResponse,
  QuantivePlusObjectivesEffectivenessPayload,
  QuantivePlusObjectivesEffectivenessResponse,
  QunativePlusEffectivenessDTO,
  QunativePlusEffectivenessPostDTO,
} from "@webapp/platform-intelligence/expandable-suggestions/shared/models/quantive-plus-effectiveness.models";
import { QuantivePlusEffectivenessApiService } from "./quantive-plus-effectiveness-api.service";
import { QuantivePlusEffectivenessState } from "./quantive-plus-effectiveness-state.service";

@Injectable({
  providedIn: "any",
})
export class QuantivePlusEffectivenessFacade extends BaseFacade<
  QunativePlusEffectivenessDTO,
  QunativePlusEffectivenessPostDTO,
  QuantivePlusEffectivenessState,
  QuantivePlusEffectivenessApiService
> {
  constructor(state: QuantivePlusEffectivenessState, api: QuantivePlusEffectivenessApiService) {
    super(state, api);
  }

  public getQuantivePlusObjectivesEffectiveness(payload: QuantivePlusObjectivesEffectivenessPayload): Observable<QuantivePlusObjectivesEffectivenessResponse> {
    return this.post$(payload, { ...new RequestConfig(), url: this.apiService.getObjectivesEffectiveness() });
  }

  public getQuantivePlusKeyResultsEffectiveness(payload: QuantivePlusKeyResultsEffectivenessPayload): Observable<QuantivePlusKeyResultsEffectivenessResponse> {
    return this.post$(payload, { ...new RequestConfig(), url: this.apiService.getKeyResultsEffectiveness() });
  }
}
