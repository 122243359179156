<div>
  <!-- Display the avatar of the actor -->
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>

  <!-- Display the title of the notification -->
  <div class="title">New AI Recommendation</div>

  <!-- Display the date of the notification -->
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>

  <!-- Summary section with icon and title -->
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiType]="notification.targetType" [uiTooltipTitle]="notification.notificationData.item.targetType | localize" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.targetTitle }}</span>
  </div>

  <!-- Main container for the full notification display -->
  <div class="full">
    <!-- Reasoning Section -->
    <div class="reasoning-section">
      <h4 class="section-heading">Reasoning:</h4>
      <p class="reasoning-text">
        {{ notification.notificationData.item.reasoning }}
      </p>
    </div>

    <!-- Divider or Additional Space to Enhance Separation -->
    <hr class="section-divider" />

    <!-- Visualization of Item Changes -->
    <div class="inbox-section">
      <div class="title">Item Changes:</div>
      <ng-container *ngFor="let changeKey of getChangeKeys()">
        <div class="change-section">
          <!-- Display the change key as a section heading -->
          <div class="change-heading">{{ changeKey }}:</div>

          <!-- Two-Column Layout for Before and After -->
          <div class="change-columns">
            <!-- Before Column (Old Value) -->
            <div class="before-column">
              <div class="list-item">
                <span class="label">{{ "before" | localize }}:</span>
                <del>
                  <rich-text-viewer [text]="getOldValue(changeKey) || 'N/A'" class="text-old"></rich-text-viewer>
                </del>
              </div>
            </div>

            <!-- After Column (New Value) -->
            <div class="after-column">
              <div class="list-item">
                <span class="label">{{ "after" | localize }}:</span>
                <rich-text-viewer [text]="getNewValue(changeKey) || 'N/A'" class="text-new"></rich-text-viewer>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- Actions -->
    <div class="inbox-box">
      <div class="inbox-section">
        <div class="inbox-section">
          <a class="link classic" (click)="applyRecommendation()">Apply</a>
        </div>
        <div class="inbox-section">
          <a class="link classic" (click)="rejectRecommendation()">Reject</a>
        </div>
      </div>
    </div>
  </div>
</div>
