import { Injectable } from "@angular/core";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";

@Injectable()
export class SmartBannerTrackingService {
  constructor(private analyticsService: AnalyticsService) {}

  public trackSmartBanner(): void {
    this.trackSmartBannerDismissedByUser();
    this.trackSmartBannerViewed();
    this.trackSmartBannerViewClicked();
  }

  private trackSmartBannerDismissedByUser(): void {
    document.addEventListener("smartbanner.exit", () => {
      this.analyticsService.track("banner-dismissed", {
        title: "Install OKRs mobile app banner",
      });
    });
  }

  private trackSmartBannerViewed(): void {
    document.addEventListener("smartbanner.view", () => {
      this.analyticsService.track("banner-loaded", {
        title: "Install OKRs mobile app banner",
      });
    });
  }

  private trackSmartBannerViewClicked(): void {
    document.addEventListener("smartbanner.clickout", () => {
      this.analyticsService.track("banner-clicked", {
        title: "Install OKRs mobile app banner",
      });
    });
  }
}
