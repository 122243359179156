import { ConfirmDeleteService } from "@webapp/shared/modal/confirm-delete.service";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";

export class Ng1ConfirmDeleteService extends ConfirmDeleteService {
  static $inject = ["Ng1UiModalService"];

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(modalService: UiModalService) {
    super(modalService);
  }
}
