import { IIndicator } from "@gtmhub/error-handling";
import { CustomFieldVisualizationKind } from "@webapp/custom-fields/models/custom-fields.models";
import { Milestone } from "@webapp/okrs/metrics/components/metric-milestone/metric-milestone.models";
import { Gif } from "@webapp/shared/components/gifs/gif.models";

export type INotification =
  | IAnnouncementNotification
  | IBadgeNotification
  | ICommentNotification
  | IGoalNotification
  | IMentionNotification
  | IMetricNotification
  | IMetricUpdateNotification
  | IReactionNotification
  | ITaskNotification
  | ISecondaryUserInvitationNotification
  | IKpiNotification
  | IKpiProjectionNotification
  | IKpiProjectionBeatenMissedNotification
  | IMetricMilestoneStatusNotification
  | IPluginNotification
  | IReflectionNotification
  | IWhiteboardNotification
  | IReportNotification
  | ISessionNotification
  | IInsightboardNotification
  | ICfSelectorMentionNotification
  | IOkrViewNotification
  | ITeamJoinNotification
  | IWatcherNotification
  | IAiRecommendationsNotification;

export const NOTIFICATION_TARGET_TYPES = [
  "announcements",
  "badge",
  "comment",
  "goal",
  "metric",
  "metric_snapshot",
  "mentions",
  "reaction",
  "secondary_user",
  "task",
  "kpi",
  "kpi_projection",
  "kpi_projection_beaten_or_missed",
  "plugin",
  "reflection",
  "whiteboard",
  "list",
  "session",
  "dashboard",
  "user",
  "team",
  "shareable_filter",
];

export type TargetType = (typeof NOTIFICATION_TARGET_TYPES)[number];
export type OperationType = "share" | "cf_selector_mentioned" | "watcher_added" | "watcher_removed";

interface INotificationBase<D extends INotificationDataItem, T extends TargetType> {
  accountId: string;
  createdAt: string;
  createdById: string;
  id: string;
  notificationData: INotificationData<D>;
  operationType: string;
  seen: boolean;
  targetId: string;
  targetType: T;
  userId: string;
}

export type IAnnouncementNotification = INotificationBase<IAnnouncementNotificationDataItem, "announcements">;
export type IBadgeNotification = INotificationBase<IBadgeNotificationDataItem, "badge">;
export type ICommentNotification = INotificationBase<ICommentNotificationDataItem, "comment" | "reflection">;
export type IGoalNotification = INotificationBase<IGoalNotificationDataItem, "goal">;
export type IMentionNotification = INotificationBase<IMentionNotificationDataItem, "mentions">;
export type IMetricNotification = INotificationBase<IMetricNotificationDataItem, "metric">;
export type IMetricUpdateNotification = INotificationBase<IMetricUpdateNotificationDataItem, "metric_snapshot">;
export type IReactionNotification = INotificationBase<IReactionNotificationDataItem, "reaction">;
export type ITaskNotification = INotificationBase<ITaskNotificationDataItem, "task">;
export type ISecondaryUserInvitationNotification = INotificationBase<ISecondaryUserInvitationNotificationDataItem, "secondary_user">;
export type IKpiNotification = INotificationBase<IKpiNotificationDataItem, "kpi">;
export type IKpiProjectionNotification = INotificationBase<IKpiProjectionNotificationDataItem, "kpi_projection">;
export type IKpiProjectionBeatenMissedNotification = INotificationBase<IKpiProjectionBeatenMissedNotificationDataItem, "kpi_projection_beaten_or_missed">;
export type IMetricMilestoneStatusNotification = INotificationBase<IMetricMilestoneStatusNotificationDataItem, "metric_milestone_status">;
export type IPluginNotification = INotificationBase<IPluginNotificationDataItem, "plugin">;
export type IReflectionNotification = INotificationBase<IReflectionNotificationDataItem, "reflection">;
export type IWhiteboardNotification = INotificationBase<IWhiteboardNotificationDataItem, "whiteboard">;
export type IReportNotification = INotificationBase<IReportNotificationDataItem, "list">;
export type ISessionNotification = INotificationBase<ISessionNotificationDataItem, "session">;
export type IInsightboardNotification = INotificationBase<IInsightboardNotificationDataItem, "dashboard">;
export type ICfSelectorMentionNotification = INotificationBase<ICfSelectorMentionNotificationDataItem, "goal" | "metric" | "metric_snapshot" | "task" | "team" | "user">;
export type IOkrViewNotification = INotificationBase<IOkrViewNotificationDataItem, "shareable_filter">;
export type ITeamJoinNotification = INotificationBase<ITeamJoinNotificationDataItem, "team">;
export type IWatcherNotification = INotificationBase<IWatcherNotificationDataItem, "goal" | "metric">;
export type IAiRecommendationsNotification = INotificationBase<IAiRecommendationsNotificationDataItem, "metric">;

interface INotificationData<D extends INotificationDataItem> {
  actor: INotificationUser;
  target?: INotificationUser;
  assignees?: INotificationUser[];
  item: D;
  url?: string;
  userNotificationSettingsUrl?: string;
  channel?: string;
}

export interface INotificationUser {
  id: string;
  names: string;
  avatar: string;
  email: string;
  language: string;
}

type INotificationDataItem =
  | IAnnouncementNotificationDataItem
  | IBadgeNotificationDataItem
  | ICommentNotificationDataItem
  | IGoalNotificationDataItem
  | IMentionNotificationDataItem
  | IMetricNotificationDataItem
  | IMetricUpdateNotificationDataItem
  | IReactionNotificationDataItem
  | ITaskNotificationDataItem
  | ISecondaryUserInvitationNotificationDataItem
  | IKpiNotificationDataItem
  | IKpiProjectionNotificationDataItem
  | IKpiProjectionBeatenMissedNotificationDataItem
  | IMetricMilestoneStatusNotificationDataItem
  | IPluginNotificationDataItem
  | IReflectionNotificationDataItem
  | IWhiteboardNotificationDataItem
  | IReportNotificationDataItem
  | ISessionNotificationDataItem
  | IInsightboardNotificationDataItem
  | ICfSelectorMentionNotificationDataItem
  | IOkrViewNotificationDataItem
  | ITeamJoinNotificationDataItem
  | IWatcherNotificationDataItem
  | IAiRecommendationsNotificationDataItem;

enum AnnouncementType {
  AnnouncementRecognition = 0,
  AnnouncementTextPost = 1,
}

interface IAnnouncementNotificationDataItem {
  comment: string;
  url: string;
  name: string;
  announcementType: AnnouncementType;
}

interface IBadgeNotificationDataItem {
  badgeName: string;
  comment: string;
  url: string;
}

export interface ICommentNotificationDataTargetItem {
  name: string;
  updateNote: string;
  updatedValue: IMetricTargetInfo;
  target: number;
  targetOperator: string;
  targetInfo: IMetricTargetInfo;
  text: string;
  type: string;
  teamName: string;
  cadenceStart: string;
  cadenceEnd: string;
  url: string;
}

interface ICommentNotificationDataItem {
  text: string;
  targetType: string;
  targetTitle: string;
  targetItem: ICommentNotificationDataTargetItem;
  isMention: boolean;
  url: string;
  gif: Gif;
}

interface IGoalNotificationDataItem {
  name: string;
  url: string;
  description: string;
  sessionName: string;
  changes: IGoalUpdateChanges;
  parent: string;
  parentType: string;
  assignee: INotificationUser;
  tags: string;
  isTeamObjective: boolean;
}

interface IWhiteboardNotificationDataItem {
  teamId: string;
  resourceName: string;
}

interface IReportNotificationDataItem {
  teamId: string;
  resourceName: string;
}

interface ISessionNotificationDataItem {
  teamId: string;
  resourceName: string;
}

interface IInsightboardNotificationDataItem {
  teamId: string;
  resourceName: string;
}

interface ITeamJoinNotificationDataItem {
  name: string;
  isMember: boolean;
  isManager: boolean;
}

interface IWatcherNotificationDataItem {
  name: string;
  url: string;
}

interface IOkrViewNotificationDataItem {
  teamId: string;
  resourceName: string;
}

interface ICfSelectorMentionNotificationDataItem {
  fieldName: string;
  targetName: string;
  singular: boolean;
  teamIds: string[];
}

interface IAiRecommendationsNotificationDataItem {
  targetTitle: string;
  targetType: string;
  targetId: string;
  actionType: string;
  recommendationId: string;
  targetItemId: string;
  targetItemTitle: string;
  targetItemType: string;
  reasoning: string;
  itemChanges: IAiRecommendationsNotificationDataItemChanges;
}

interface IAiRecommendationsNotificationDataItemChanges {
  items: {
    [key: string]: IAiRecommendationsNotificationDataItemChange;
  };
}

interface IAiRecommendationsNotificationDataItemChange {
  oldValue: string | number | null;
  newValue: string | number | null;
}

interface IGoalUpdateChanges {
  name: IDiff<string>;
  assignee: IDiff<INotificationUser>;
  assignees: IDiff<INotificationUser[]>;
  parent: IDiff<string>;
  parentType: IDiff<string>;
  tags: IDiff<string>;
  description: IDiff<string>;
  customFields: ICustomFieldsDiff<string | string[] | INotificationUser[]>[];
}

interface IDiff<V> {
  oldValue: V;
  newValue: V;
}

interface ICustomFieldsDiff<V> extends IDiff<V> {
  label: string;
  visualizationKind: CustomFieldVisualizationKind;
}

interface IMentionNotificationDataItem {
  title: string;
  targetType: string;
  targetUrl: string;
  fieldName: string;
  fieldValue: string;
  customFieldLabel: string;
}

interface IMetricNotificationDataItem {
  name: string;
  actual: number;
  attainment: number;
  goalName: string;
  url: string;
  targetOperator: string;
  description: string;
  changes: IMetricChanges;
  softDueDate?: string;
  dueDate?: string;
  assignee: INotificationUser;
  assignees?: INotificationUser[];
  targetInfo: IMetricTargetInfo;
  isTeamMetric: boolean;
}

interface IMetricUpdateNotificationDataItem {
  name: string;
  goalName: string;
  updateNote: string;
  updatedValue: IMetricTargetInfo;
  target: number;
  url: string;
  targetOperator: string;
  description: string;
  changes: IMetricUpdateChanges;
  assignee: INotificationUser;
  targetInfo: IMetricTargetInfo;
  isTeamMetric: boolean;
  modifiedBy: string;
  gif: Gif;
}

interface IMetricChanges {
  name: IDiff<string>;
  goalName: IDiff<string>;
  assignees: IDiff<INotificationUser[]>;
  assignee: IDiff<INotificationUser>;
  attainment: IDiff<number>;
  initialValue: IDiff<number>;
  milestones?: (Milestone & { status: "removed" | "added" })[];
  targetOperator: IDiff<string>;
  softDueDate: IDiff<string>;
  dueDate: IDiff<string>;
  targetInfo: IDiff<IMetricTargetInfo>;
  description: IDiff<string>;
  tags: IDiff<string>;
  customFields: ICustomFieldsDiff<string | string[] | INotificationUser[]>[];
}

interface IMetricTargetInfo {
  critical: number;
  target: number;
  manualType: string;
  format: IMetricFormat;
}

interface IMetricFormat {
  prefix: string;
  suffix: string;
}

interface IMetricUpdateChanges {
  updateNote: IDiff<string>;
}

interface IReactionNotificationDataTargetItem {
  name: string;
  updateNote: string;
  updatedValue: IMetricTargetInfo;
  target: number;
  targetOperator: string;
  targetInfo: IMetricTargetInfo;
  text: string;
  type: string;
  teamName: string;
  cadenceStart: string;
  cadenceEnd: string;
  url: string;
  gif: Gif;
}

export interface IReactionNotificationDataItem {
  targetId: string;
  targetType: string;
  url: string;
  targetItem: IReactionNotificationDataTargetItem;
  content: string;
  reactedUsers: INotificationUser[];
  reactions: IReaction[];
  targetItemType?: string;
  targetItemName: string;
  template?: string;
  text: string;
  emoji: string;
}

interface IReaction {
  name: string;
  emoji: string;
  users: INotificationUser[];
}

interface ISecondaryUserInvitationNotificationDataItem {
  accountName: string;
}

interface ITaskNotificationDataItem {
  id: string;
  ownerID: string;
  name: string;
  description: string;
  url: string;
  isTeam: boolean;
  assignee: INotificationUser;
  dueDate: string;
}

interface IKpiNotificationDataItem {
  id: string;
  ownerId: string;
  ownerIds: string;
  name: string;
  description: string;
  url: string;
  isTeam: boolean;
  assignee: INotificationUser;
  changes: IKpiUpdateChanges;
  teamId?: string;
  resourceName?: string;
}

interface IKpiUpdateChanges {
  name: IDiff<string>;
  assignees: IDiff<INotificationUser[]>;
  description: IDiff<string>;
  aggregation: IDiff<string>;
  fractionSize: IDiff<string>;
  prefix: IDiff<string>;
  targetOperator: IDiff<string>;
}

interface IKpiProjectionNotificationDataItem {
  projectionId: string;
  value: number;
  date: string;
  kpiName: string;
  url: string;
  changes: IKpiProjectionUpdateChanges;
}

interface IKpiProjectionUpdateChanges {
  value: IDiff<number>;
  date: IDiff<string>;
}

interface IKpiProjectionBeatenMissedNotificationDataItem {
  kpiName: string;
  projectionDate: string;
  delta: string;
  kpiUrl: string;
}

interface IMetricMilestoneStatusNotificationDataItem {
  metricUrl: string;
  metricId: string;
  metricName: string;
  metricCurrentValue: string;
  milestoneDate: string;
  targetValue: string;
  isHit: string;
}

interface IPluginNotificationDataItem {
  id: string;
  pluginTitle: string;
  url: string;
  comment: string;
}

interface IReflectionNotificationDataItem {
  id: string;
  teamName: string;
  reflectionTitle: string;
  url: string;
  userId: string;
  teamId: string;
  cadenceStart: string;
  cadenceEnd: string;
  createdAt: string;
  fields: [
    {
      type: string;
      name?: string;
      text: string;
    },
  ];
}

interface INotificationSettingsEmbedded {
  announcements: boolean;
  badges: boolean;
  krProgressUpdateMe: boolean;
  krProgressUpdateTeam: boolean;
  mentionsInComment: boolean;
  objectiveApprovals: boolean;
  okrsAndTasksAssignedMeMyTeam: boolean;
  okrsEditedByMe: boolean;
  okrsEditedByTeam: boolean;
  kpiAssignedMeMyTeam: boolean;
  taskAssignedMeMyTeam: boolean;
  reflectionsPublishedByTeammates: boolean;
  reflectionReminders: boolean;
}

export interface INotificationSettings {
  emailNotificationSettings: {
    embedded: INotificationSettingsEmbedded;
  };
  inboxNotificationSettings: {
    embedded: INotificationSettingsEmbedded;
  };
  slackNotificationSettings: {
    embedded: INotificationSettingsEmbedded;
  };
}

export interface IPatchedNotificationSettings {
  emailNotificationSettings?: {
    embedded: Partial<INotificationSettingsEmbedded>;
  };
  inboxNotificationSettings?: {
    embedded: Partial<INotificationSettingsEmbedded>;
  };
  slackNotificationSettings?: {
    embedded: {
      announcements?: boolean;
      badges?: boolean;
      conversations?: boolean;
      krProgressUpdateMe?: boolean;
      krProgressUpdateTeam?: boolean;
      mentionsInComment?: boolean;
      objectiveApprovals?: boolean;
      okrsAndTasksAssignedMeMyTeam?: boolean;
      okrsEditedByMe?: boolean;
      okrsEditedByTeam?: boolean;
      kpiAssignedMeMyTeam?: boolean;
    };
  };
}

export interface INotificationIndicators {
  loading?: IIndicator;
  markingAllAsSeen?: IIndicator;
  loadingMoreMessages?: IIndicator;
}
