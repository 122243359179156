export interface INumericFieldFormatting {
  unit: IUnitItem;
  fractionSize: number;
  isCustomUnit: boolean;
}

export interface IUnitItem {
  value: string;
  prefix?: boolean;
  suffix?: boolean;
  display?: string;
}

export interface IUnitItemMap {
  [unit: string]: IUnitItem;
}

export enum Affix {
  Prefix = "prefix",
  Suffix = "suffix",
}

export const FULL_NUMBER_PRECISION = -1;
