import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { IQuantivePlusTask } from "@webapp/platform-intelligence/quantive-plus/models";
import { KeyResultFlowName, ObjectiveFlowName } from "@webapp/platform-intelligence/shared/utils/pi-tracking";

@Injectable({
  providedIn: "root",
})
export class QuantivePlusAnotherSuggestionService {
  private objectiveTaskSuggestionToReplace$ = new BehaviorSubject<{
    taskToReplace: IQuantivePlusTask;
    taskToReplaceIndex: number;
    flowName: ObjectiveFlowName;
  }>(null);
  private krTaskSuggestionToReplace$ = new BehaviorSubject<{
    taskToReplace: IQuantivePlusTask;
    taskToReplaceIndex: number;
    flowName: KeyResultFlowName;
  }>(null);
  private refreshAllObjectiveTasks$ = new BehaviorSubject<{ isRefreshAllTasks: true }>(null);
  private refreshAllKrTasks$ = new BehaviorSubject<{ isRefreshAllTasks: true }>(null);

  public setObjectiveTaskSuggestionToReplace = (
    args: {
      taskToReplace: IQuantivePlusTask;
      taskToReplaceIndex: number;
      flowName?: ObjectiveFlowName;
    } | null
  ): void => {
    this.objectiveTaskSuggestionToReplace$.next(
      args ? { taskToReplace: args.taskToReplace, taskToReplaceIndex: args.taskToReplaceIndex, flowName: args.flowName } : null
    );
  };

  public getObjectiveTaskSuggestionToReplace$(): Observable<{ taskToReplace: IQuantivePlusTask; taskToReplaceIndex: number }> {
    return this.objectiveTaskSuggestionToReplace$.asObservable();
  }

  public setKrTaskSuggestionToReplace = (
    args: {
      taskToReplace: IQuantivePlusTask;
      taskToReplaceIndex: number;
      flowName?: KeyResultFlowName;
    } | null
  ): void => {
    this.krTaskSuggestionToReplace$.next(args ? { taskToReplace: args.taskToReplace, taskToReplaceIndex: args.taskToReplaceIndex, flowName: args.flowName } : null);
  };

  public getKrTaskSuggestionToReplace$(): Observable<{ taskToReplace: IQuantivePlusTask; taskToReplaceIndex: number }> {
    return this.krTaskSuggestionToReplace$.asObservable();
  }

  public setRefreshAllObjectiveTasksEvent = (args: { isRefreshAllTasks: true } | null): void => {
    this.refreshAllObjectiveTasks$.next(args ? { isRefreshAllTasks: true } : null);
  };

  public getRefreshAllObjectiveTasksEvent$(): Observable<{ isRefreshAllTasks: true }> {
    return this.refreshAllObjectiveTasks$.asObservable();
  }

  public setRefreshAllKrTasksEvent = (args: { isRefreshAllTasks: true } | null): void => {
    this.refreshAllKrTasks$.next(args ? { isRefreshAllTasks: true } : null);
  };

  public getRefreshAllKrTasksEvent$(): Observable<{ isRefreshAllTasks: true }> {
    return this.refreshAllKrTasks$.asObservable();
  }
}
