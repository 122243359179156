import { Ng2StateDeclaration, StateDeclaration } from "@uirouter/angular";
// eslint-disable-next-line no-restricted-imports
import { Ng1StateDeclaration } from "@uirouter/angularjs";
import { OverlayComponentStateManager, overlayStateManager } from "./overlay-component-state.manager";

export type StateVersion = "ng1" | "ng2";

export type StateDeclarationForVersion = {
  [V in StateVersion]: StateDeclarationBaseTypeByVersion[V];
};

type StateDeclarationBaseTypeByVersion = {
  ng1: Partial<Ng1StateDeclaration>;
  ng2: Partial<StateDeclaration>;
};

type StateDeclarationTypeByVersion = {
  ng1: Ng1StateDeclaration;
  ng2: Ng2StateDeclaration;
};

export abstract class OverlayComponentStateDeclarationFactory<Version extends StateVersion, StateBase extends StateDeclarationForVersion[Version]> {
  protected stateManager: OverlayComponentStateManager = overlayStateManager;

  public abstract getStateDeclaration(state: StateBase): StateDeclarationTypeByVersion[Version];
}
