import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiCardModule } from "@quantive/ui-kit/card";
import { take } from "rxjs";
import { localize } from "@gtmhub/localization";
import { getCurrentUserId } from "@gtmhub/users";
import { FeatureTogglesModule } from "@webapp/feature-toggles/feature-toggles.module";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { ActiveOkrsGroupSortDropdownComponent } from "@webapp/okrs/components/assignee-active-okrs-list/active-okrs-group-sort-dropdown/active-okrs-group-sort-dropdown.component";
import { AssigneeActiveOkrsListComponent } from "@webapp/okrs/components/assignee-active-okrs-list/assignee-active-okrs-list.component";
import {
  ActiveOkrsListGroup,
  ActiveOkrsListGroupSort,
  ActiveOkrsListSort,
  DEFAULT_ACTIVE_OKRS_LIST_GROUP,
  DEFAULT_ACTIVE_OKRS_LIST_SORT,
} from "@webapp/okrs/components/assignee-active-okrs-list/assignee-active-okrs-list.models";
import { BaseWidgetComponent } from "@webapp/ui/dashboard/components/base-widget.component";
import { CurrentUserRepository } from "@webapp/users";
import { WidgetActionsComponent } from "../widget-actions/widget-actions.component";

@UntilDestroy()
@Component({
  selector: "my-okrs-widget",
  templateUrl: "./my-okrs-widget.component.html",
  styleUrls: ["./my-okrs-widget.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LocalizationModule, UiCardModule, AssigneeActiveOkrsListComponent, WidgetActionsComponent, ActiveOkrsGroupSortDropdownComponent, FeatureTogglesModule, NgIf],
})
export class MyOkrsWidgetComponent extends BaseWidgetComponent {
  public get title(): string {
    return localize("my_okrs");
  }

  public get a11yLabel(): string {
    return "My OKRs. Owned by me and my teams.";
  }

  public get currentUserId(): string {
    return getCurrentUserId();
  }

  public groupBy: ActiveOkrsListGroup;
  public sortBy: ActiveOkrsListSort;

  public constructor(
    private currentUserRepository: CurrentUserRepository,
    private featureTogglesFacade: FeatureTogglesFacade
  ) {
    super();
    this.processDefaultOrderValues();
  }

  public handleGroupChange(value: ActiveOkrsListGroup): void {
    this.groupBy = value;
    this.persistOkrsGroupSortSettings();
  }

  public handleSortChange(value: ActiveOkrsListSort): void {
    this.sortBy = value;
    this.persistOkrsGroupSortSettings();
  }

  private persistOkrsGroupSortSettings(): void {
    this.currentUserRepository.setUserSetting({
      myOkrsGrouping: {
        groupBy: this.groupBy,
        sortBy: this.sortBy,
      },
    });
  }

  private processDefaultOrderValues(): void {
    this.featureTogglesFacade
      .isFeatureAvailable$("switch-default-group-by-my-okrs-axpo")
      .pipe(take(1), untilDestroyed(this))
      .subscribe((isAxpo) => {
        const currentUserSettingsForMyOkrsGrouping = this.currentUserRepository.getUserSetting<ActiveOkrsListGroupSort>("myOkrsGrouping");

        if (isAxpo && !currentUserSettingsForMyOkrsGrouping) {
          this.groupBy = "ownership";
          this.sortBy = currentUserSettingsForMyOkrsGrouping?.sortBy ?? DEFAULT_ACTIVE_OKRS_LIST_SORT;
          this.currentUserRepository.setUserSetting({
            myOkrsGrouping: {
              groupBy: this.groupBy,
              sortBy: this.sortBy,
            },
          });
        } else {
          this.groupBy = currentUserSettingsForMyOkrsGrouping?.groupBy ?? DEFAULT_ACTIVE_OKRS_LIST_GROUP;
          this.sortBy = currentUserSettingsForMyOkrsGrouping?.sortBy ?? DEFAULT_ACTIVE_OKRS_LIST_SORT;
        }
      });
  }
}
