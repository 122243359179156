import { IAugmentedJQuery } from "angular";

export const handleOutsideMouseEvent = (
  event: "click" | "mousedown" | "mouseup",
  callback: (evt: JQueryEventObject) => unknown,
  ...containers: (string | IAugmentedJQuery)[]
): (() => void) => {
  const eventHandler = (evt: JQueryEventObject) => {
    const possibleParents = containers.map((e) => (typeof e === "string" ? document.querySelector<HTMLElement>(e) : e[0])).filter((e) => !!e);
    for (const possibleParent of possibleParents) {
      if (possibleParent.contains(evt.target)) {
        return;
      }
    }

    callback(evt);
  };

  document.body.addEventListenerOutsideAngular(event, eventHandler);
  return () => document.body.removeEventListener(event, eventHandler);
};

export const handleInsideMouseEvent = (event: "click" | "mousedown" | "mouseup", callback: () => unknown, ...containers: (string | IAugmentedJQuery)[]): (() => void) => {
  const eventHandler = (evt: JQueryEventObject) => {
    const possibleParents = containers.map((e) => (typeof e === "string" ? document.querySelector<HTMLElement>(e) : e[0])).filter((e) => !!e);
    for (const possibleParent of possibleParents) {
      if (possibleParent.contains(evt.target)) {
        callback();
        return;
      }
    }
  };

  document.body.addEventListenerOutsideAngular(event, eventHandler);
  return () => document.body.removeEventListener(event, eventHandler);
};
