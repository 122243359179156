import { localize } from "@gtmhub/localization";

export type InsightboardsActions =
  | "add-favorite-insightboard"
  | "remove-favorite-insightboard"
  | "install-marketplace-insightboards"
  | "unarchive-insightboard"
  | "archive-insightboard";

export const actionsMap: Record<InsightboardsActions, { title: string; intercomAction: string }> = {
  /*
   Titles should be localized. Intercom actions don't need to be localized. 
  */
  "add-favorite-insightboard": {
    title: localize("unable_to_add_favorites"),
    intercomAction: "adding an Insightboard to Favorites",
  },
  "remove-favorite-insightboard": {
    title: localize("unable_to_remove_favorites"),
    intercomAction: "removing an Insightboard to Favorites",
  },
  "install-marketplace-insightboards": {
    title: localize("unable_to_install_insightboard"),
    intercomAction: "installing Insightboard from Marketplace",
  },
  "unarchive-insightboard": {
    title: localize("unable_unarchive_insightboard"),
    intercomAction: "un-archiving Insightboard",
  },
  "archive-insightboard": {
    title: localize("unable_archive_insightboard"),
    intercomAction: "archiving Insightboard",
  },
};
