import { Component } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { SettingsSubNavStateService } from "@webapp/navigation/services/settings/settings-sub-nav-state.service";

@UntilDestroy()
@Component({
  selector: "settings-sub-navigation",
  templateUrl: "./settings-sub-navigation.component.html",
})
export class SettingsSubNavigationComponent {
  constructor(public state: SettingsSubNavStateService) {}
}
