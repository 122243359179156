import { NgModule } from "@angular/core";
import { UiNotificationContainerComponent } from "./components/notification-container/notification-container.component";
import { UiNotificationComponent } from "./notification.component";
import { UiNotificationService } from "./services/notification.service";

@NgModule({
  imports: [UiNotificationComponent, UiNotificationContainerComponent],
  exports: [UiNotificationComponent, UiNotificationContainerComponent],
  providers: [UiNotificationService],
})
export class UiNotificationModule {}
