import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest } from "rxjs";
import { Permission } from "../models/permissions.model";
import { PermissionsFacade } from "../services/permissions-facade.service";

@UntilDestroy()
@Directive({
  selector: "[hasAnyPermissions]",
  standalone: true,
})
export class HasAnyPermissionsDirective implements OnInit {
  @Input()
  public hasAnyPermissions: Permission | Permission[];

  @Input()
  public hasAnyPermissionsNegate?: boolean;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private container: ViewContainerRef,
    private permissionsFacade: PermissionsFacade
  ) {}

  public ngOnInit(): void {
    this.container.clear();

    const permissions = Array.isArray(this.hasAnyPermissions) ? this.hasAnyPermissions : [this.hasAnyPermissions];
    combineLatest(permissions.map((permission) => this.permissionsFacade.hasPermission$(permission)))
      .pipe(untilDestroyed(this))
      .subscribe((hasPermissions) => {
        const show = hasPermissions.some((x) => x === !this.hasAnyPermissionsNegate);
        if (show) {
          this.container.createEmbeddedView(this.templateRef);
        } else {
          this.container.clear();
        }
      });
  }
}
