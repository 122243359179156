<ng-container *ngIf="notification.operationType === 'join'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    <span>{{ "user_added_you_to_team" | localize: { names: notification.notificationData.actor.names } }}</span>
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiType]="notification.targetType" [uiTooltipTitle]="notification.targetType | localize" ui-icon ui-tooltip uiTheme="outline"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section" *ngIf="notification.notificationData.item.isManager">
        <div class="heading inline-notifications manager">{{ "manager" | localize }}:</div>
        <ui-assignee class="sp-mr-5 sp-mt-10" [fromAssigneeId]="notification.notificationData.target.id" uiSize="xs"></ui-assignee>
      </div>
      <div class="inbox-section" *ngIf="notification.notificationData.item.isMember">
        <div class="heading inline-notifications member">{{ "member" | localize }}:</div>
        <ui-assignee class="sp-mr-5 sp-mt-10" [fromAssigneeId]="notification.notificationData.target.id" uiSize="xs"></ui-assignee>
      </div>
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.url">{{ "view_profile" | localize }}</a>
      </div>
    </div>
  </div>
</ng-container>
