import { IUIError } from "@gtmhub/error-handling";
import { IOrganization } from "../models";
import { OrganizationsAction } from "./models";

export interface IOrganizationsStoreState {
  organizations: IOrganizationsState;
}

export interface IOrganizationsState {
  isFetched: boolean;
  isFetching: boolean;
  organizations: IOrganization[];
  error: IUIError;
}

export function organizationsReducer(
  state: IOrganizationsState = { isFetched: false, isFetching: false, organizations: [], error: null },
  action: OrganizationsAction
): IOrganizationsState {
  switch (action.type) {
    case "REQUEST_ORGANIZATIONS":
      return { ...state, isFetching: true, isFetched: false, error: null };
    case "RECEIVE_ORGANIZATIONS":
      return { ...state, isFetched: true, isFetching: false, organizations: action.items };
    case "ERROR_RECEIVE_ORGANIZATIONS":
      return { ...state, error: action.error, isFetching: false, isFetched: false };
    default: {
      return state;
    }
  }
}
