import { Inject, Injectable } from "@angular/core";
import { AccountService } from "@gtmhub/accounts/accounts.service";
import { IConfidenceSettings } from "@gtmhub/core";
import { IGtmhubRootScopeService } from "@gtmhub/models";
import { ConfidenceScale } from "@webapp/configuration/models/configuration.model";

@Injectable({
  providedIn: "root",
})
export class ConfidenceSettingsService {
  constructor(
    private accountService: AccountService,
    @Inject("$rootScope") private rootScope: IGtmhubRootScopeService
  ) {}

  public getConfidenceSettings(): IConfidenceSettings {
    return this.rootScope.confidenceSettings;
  }

  public getConfidenceScale(): ConfidenceScale {
    return this.accountService.getConfidenceScale();
  }
}
