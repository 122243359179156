<div class="a11y-skip-to-main-container" id="notification-layout">
  <ul>
    <li class="sp-pb-16 first-category">
      <notification-category [icon]="'goal'" [settingKeys]="notificationSettingsMap.okrs" [notificationSettings]="notificationSettings" [categoryNameKey]="'okrs'"> </notification-category>
      <div [class]="'col-titles-container ' + notificationClass">
        <span class="col-title">Quantive</span>
        <span class="col-title">Email</span>
      </div>
    </li>
    <li class="sp-pb-16 sp-mt-32">
      <notification-category [icon]="'kr'" [settingKeys]="notificationSettingsMap.krs" [notificationSettings]="notificationSettings" [categoryNameKey]="'key_result_updates'"> </notification-category>
    </li>
    <ng-container *editionFeature="'kpis'">
      <li class="sp-pb-16 sp-mt-32" *ngIf="!hasViewOnlyRole">
        <notification-category [icon]="'kpi'" [settingKeys]="notificationSettingsMap.kpis" [notificationSettings]="notificationSettings" [categoryNameKey]="'kpis'"> </notification-category>
      </li>
    </ng-container>
    <ng-container *editionFeature="'hub.tasks'">
      <li class="sp-pb-16 sp-mt-32" *ngIf="!hasViewOnlyRole">
        <notification-category [icon]="'task'" [settingKeys]="notificationSettingsMap.tasks" [notificationSettings]="notificationSettings" [categoryNameKey]="'tasks'"> </notification-category>
      </li>
    </ng-container>

    <li class="sp-mt-32 sp-pb-16">
      <notification-category [icon]="'users-group'" [settingKeys]="notificationSettingsMap.teams" [notificationSettings]="notificationSettings" [categoryNameKey]="'teams_with_capital'">
      </notification-category>
    </li>
    <li class="sp-pb-16 sp-mt-32" *ngIf="!hasViewOnlyRole">
      <notification-category [icon]="'award'" [settingKeys]="notificationSettingsMap.badges" [notificationSettings]="notificationSettings" [categoryNameKey]="'badges'"> </notification-category>
    </li>
    <li class="sp-pb-16 sp-mt-32">
      <notification-category
        [icon]="'mention'"
        [settingKeys]="notificationSettingsMap.mentionsInComments"
        [notificationSettings]="notificationSettings"
        [categoryNameKey]="'mentions_and_comments_and_reactions'">
      </notification-category>
    </li>
    <li class="sp-mt-32 sp-pb-16" *ngIf="canManageCheckinNotifications">
      <notification-category [icon]="'check-in'" [settingKeys]="notificationSettingsMap.reflections" [notificationSettings]="notificationSettings" [categoryNameKey]="'check_ins'">
      </notification-category>
    </li>
    <li class="sp-pb-16 sp-mt-32">
      <notification-category [icon]="'view-only'" [settingKeys]="notificationSettingsMap.sharing" [notificationSettings]="notificationSettings" [categoryNameKey]="'sharing'"> </notification-category>
    </li>
  </ul>
</div>
