import { ILogService, auto } from "angular";
import { IAppConfig } from "@gtmhub/env";
import env from "@gtmhub/env/env.module";
import { stringifyMessage } from "@webapp/core/logging/logging.utils";
import { LogLevel } from "@webapp/core/logging/models/logging.models";
import { LoggingService } from "@webapp/core/logging/services/logging.service";
import { ApmService } from "../tracing/apm.service";

const mod = angular.module("logging", [env]);

// "enriching" $log service behaviour to POST to fluentd if log.level is included in config
mod.config([
  "$provide",
  "appConfig",
  ($provide: auto.IProvideService, appConfig: IAppConfig) => {
    if (!appConfig.logging.enabled) {
      return;
    }

    const loggingService = new LoggingService(appConfig.logging, "spa");

    // log window console error messages to fluentd in case $exceptionHandler service is overridden
    loggingService.captureConsoleError();

    $provide.decorator("$log", [
      "$delegate",
      "ApmService",
      ($delegate: ILogService, apmService: ApmService) => {
        const wrapLogFunction = function (logFn: (...args: unknown[]) => void, level: LogLevel) {
          const wrappedFn = function (...args: unknown[]) {
            logFn(...args);

            // skip messages which are not included in logging config
            if (!appConfig.logging.levels.includes(level)) {
              return;
            }

            const err = args[0] instanceof Error ? args[0] : (args.length === 1 ? args[0] : args) || {};
            if (err instanceof Error) {
              loggingService.logError(err);
              apmService.captureError(err);
            } else {
              loggingService.logMessage(err, { level });
              apmService.captureError(stringifyMessage(err));
            }
          };

          // added in compliance with in angular-loggly-logger and typings
          const logs: string[] = [];
          wrappedFn.logs = logs;

          return wrappedFn;
        };

        // wrap the existing API, for example: $log.warn(warning) will send the warning to fluentd if level "WARN" is included in config
        $delegate.log = wrapLogFunction($delegate.log, "INFO");
        $delegate.debug = wrapLogFunction($delegate.debug, "DEBUG");
        $delegate.info = wrapLogFunction($delegate.info, "INFO");
        $delegate.warn = wrapLogFunction($delegate.warn, "WARN");
        $delegate.error = wrapLogFunction($delegate.error, "ERROR");

        return $delegate;
      },
    ]);
  },
]);

export default mod.name;
