import { HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { QueryParams } from "@webapp/core/abstracts/models/query-params.model";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { GTMHUB_ADDITIONAL_PARAMS } from "@webapp/core/http/interceptors/track-data.interceptor";
import { Comment, CommentStatsMap, CreateCommentDTO } from "../models/comments.models";
import { CommentsApiService } from "./comments-api.service";
import { CommentsState } from "./comments-state.service";

@Injectable({
  providedIn: "root",
})
export class CommentsFacade extends BaseFacade<Comment, CreateCommentDTO, CommentsState, CommentsApiService> {
  constructor(state: CommentsState, api: CommentsApiService) {
    super(state, api);
  }

  public getComments$(filter: RequestPaging, queryParams: QueryParams): Observable<Comment[]> {
    return this.getAll$(filter, {
      ...new RequestConfig(),
      queryParams,
    }).pipe(map(({ items }) => items));
  }

  public deleteComment$(commentId: string, metadata?: { [key: string]: string | boolean }): Observable<Comment> {
    return this.apiService.delete$(commentId, {
      ...new RequestConfig(),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, { ...metadata }),
    });
  }

  public postComment$(comment: CreateCommentDTO, metadata?: { [key: string]: string | boolean }): Observable<Comment> {
    const requestConfig = {
      ...new RequestConfig(),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, { hasGif: !!comment.gif?.id, ...metadata }),
    };

    return this.apiService.post$<Comment>(comment, requestConfig);
  }

  public edit$(
    id: string,
    comment: Partial<Comment>,
    gifChanges: { isDeleted: boolean; isAdded: boolean },
    metadata?: { [key: string]: string | boolean }
  ): Observable<Comment> {
    const isGifEdited = gifChanges.isAdded || gifChanges.isDeleted;
    const gtmhubAdditionalParams = isGifEdited
      ? {
          gifEdited: gifChanges.isAdded ? "added" : "deleted",
        }
      : {};

    const requestConfig = {
      ...new RequestConfig(),
      context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, { ...gtmhubAdditionalParams, ...metadata }),
    };

    return this.apiService.put$(id, comment, requestConfig);
  }

  public markAsSeen$(id: string): Observable<Comment> {
    const requestConfig = {
      ...new RequestConfig(),
      url: this.apiService.getSeeEndpoint(id),
    };

    return this.apiService.post$<Comment>(null, requestConfig);
  }

  public stats$(targetIds: string): Observable<CommentStatsMap> {
    return this.apiService.get$(null, {
      ...new RequestConfig(),
      url: this.apiService.getStatsEndpoint(),
      queryParams: { targetIds },
    } as RequestConfig);
  }
}
