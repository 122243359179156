<div class="nav-list">
  <div class="nav-list__header" (click)="expanded = !expanded" (keydown.enter)="expanded = !expanded" tabindex="0">
    <span>{{ label | localize }}</span>
    <span>
      <i class="nav-list__header__icon" [uiType]="expanded ? 'chevron-down-small' : 'chevron-right-small'" ui-icon uiSize="xs"></i>
    </span>
  </div>
  <div class="nav-list__content" *ngIf="expanded">
    <sub-nav-options [isSettings]="true" [options]="options"></sub-nav-options>
  </div>
</div>
