import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import {
  PIKeyResultSuggestion,
  PIKeyResultSuggestionPayload,
  PIObjectiveDescriptionSuggestion,
  PIObjectiveDescriptionSuggestionPayload,
  PIObjectiveSuggestion,
  PIObjectiveSuggestionPayload,
  StrategicGuidedOkrSuggestionDTO,
  StrategicGuidedOkrSuggestionPostDTO,
} from "@webapp/platform-intelligence/shared/models/strategic-guided-okr.models";
import { StrategicGuidedOkrApiV2Service } from "@webapp/platform-intelligence/shared/services/strategic-guided-okr/v2/strategic-guided-okr-api-v2.service";
import { StrategicGuidedOkrStateV2 } from "@webapp/platform-intelligence/shared/services/strategic-guided-okr/v2/strategic-guided-okr-state-v2.service";

@Injectable({
  providedIn: "any",
})
export class StrategicGuidedOkrFacadeV2 extends BaseFacade<
  StrategicGuidedOkrSuggestionDTO,
  StrategicGuidedOkrSuggestionPostDTO,
  StrategicGuidedOkrStateV2,
  StrategicGuidedOkrApiV2Service
> {
  constructor(state: StrategicGuidedOkrStateV2, api: StrategicGuidedOkrApiV2Service) {
    super(state, api);
  }

  public getObjectiveDescriptionSuggestion(payload: PIObjectiveDescriptionSuggestionPayload): Observable<PIObjectiveDescriptionSuggestion> {
    return this.post$(payload, { ...new RequestConfig(), url: this.apiService.getObjectiveDescriptionEndpoint() });
  }

  public getObjectiveSuggestions(payload: PIObjectiveSuggestionPayload): Observable<PIObjectiveSuggestion> {
    return this.post$(payload, { ...new RequestConfig(), url: this.apiService.getObjectiveEndpoint() });
  }

  public getKeyResultSuggestions(payload: PIKeyResultSuggestionPayload): Observable<PIKeyResultSuggestion> {
    return this.post$(payload, { ...new RequestConfig(), url: this.apiService.getKeyResultEndpoint() });
  }
}
