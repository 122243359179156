import { localize } from "@gtmhub/localization";
import { getCurrentUserId } from "@gtmhub/users";
import { capitalize } from "@gtmhub/util";
import {
  AccessTypes,
  DynamicFilters,
  IListColumn,
  IListSort,
  List,
  ListFilterTypes,
  ListTargetType,
  ListType,
  ListsPropertyNames,
  OrListFiltersGroup,
  SupportedOperators,
} from "../models";

export function getDictionaryByListType(type: ListTargetType): Record<string, string> {
  switch (type) {
    case "goal":
      return goalPropertiesDictionary();
    case "metric":
      return metricPropertiesDictionary();
    case "task":
      return taskPropertiesDictionary();
    case "employee":
      return employeePropertiesDictionary();
    case "team":
      return teamPropertiesDictionary();
    default:
      return {};
  }
}

export function goalPropertiesDictionary(): Record<string, string> {
  return {
    ownerId: localize("owner_cap"),
    ownerIds: localize("objective_owner"),
    childrenCount: localize("child_objectives"),
    attainment: localize("progress_cap"),
    sessionId: localize("session_cap"),
    sessionTitle: localize("session_cap"),
    dateCreated: localize("creation_date_cap"),
    dateFrom: localize("starting_date_cap"),
    dateTo: localize("ending_date_cap"),
    createdById: localize("the_user_who_created_cap"),
    metricsCount: localize("number_of_key_results_cap"),
    parentId: localize("parent_cap"),
    parentGoalSessionId: localize("parent_session_cap"),
    tasksCount: localize("number_of_tasks_cap"),
    designScore: localize("design_score_cap"),
    tags: localize("tags_cap"),
    name: localize("objective_name"),
    description: localize("description_cap"),
    closedStatus: localize("okr_state"),
    "workflow.status": localize("approval_status"),
    "designScore.totalPoints": localize("okr_design_score"),
  };
}

export function metricPropertiesDictionary(): Record<string, string> {
  return {
    goalId: localize("objective_cap"),
    dateCreated: localize("date_created_cap"),
    "confidence.reason": localize("last_update_note_cap"),
    target: localize("target_cap"),
    // will be removed
    manualType: localize("manual_type_cap"),
    initialValue: localize("initial_cap"),
    actual: localize("actual_cap"),
    name: capitalize(localize("key_result_name")),
    targetOperator: localize("key_result_type_cap"),
    attainment: localize("progress_cap"),
    "confidence.value": localize("confidence_level_cap"),
    "confidence.date": localize("confidence_level_date_cap"),
    createdById: localize("the_user_who_created_cap"),
    ownerId: localize("owner_cap"),
    ownerIds: localize("owners_cap"),
    lastCheckInDate: localize("last_updated"),
    description: localize("description_cap"),
    dueDate: localize("hard_deadline_cap"),
    sessionId: localize("session_cap"),
    sessionTitle: localize("session_cap"),
    goalOwnerId: localize("objective_owner"),
    goalOwnerIds: localize("objective_owners"),
    goalDescription: localize("objective_description"),
    goalAttainment: localize("objective_progress"),
    goalClosedStatus: localize("okr_state"),
  };
}

export function taskPropertiesDictionary(): Record<string, string> {
  return {
    parentId: capitalize(localize("linked_to")),
    status: localize("status_cap"),
    dueDate: localize("due_date_cap"),
    name: capitalize(localize("task_name")),
    externalId: localize("external_id_cap"),
    externalSystem: localize("external_system_cap"),
    description: localize("description_cap"),
    ownerId: localize("owner_cap"),
    priority: localize("priority_cap"),
    createdById: localize("created_by_cap"),
    dateCreated: localize("date_created_cap"),
    sessionId: localize("session_cap"),
    sessionTitle: localize("session_cap"),
    closedStatus: localize("okr_state"),
  };
}

export function employeePropertiesDictionary(): Record<string, string> {
  return {
    firstName: localize("first_name_cap"),
    lastName: localize("last_name_cap"),
    badges: localize("badges_cap"),
    teamIds: localize("teams_cap"),
    goalCount: localize("active_objectives_cap"),
    progress: localize("progress_cap"),
    manualMetricCount: localize("manual_key_results_cap"),
    dynamicMetricCount: localize("dynamic_key_results_cap"),
    checkinCount: localize("kr_updates_cap"),
    userRoles: localize("roles"),
    status: localize("status"),
  };
}

export function teamPropertiesDictionary(): Record<string, string> {
  return {
    name: localize("name_cap"),
    manager: localize("manager_cap"),
    members: localize("team_members_cap"),
    teamGoalCount: localize("active_team_objectives_cap"),
    progress: localize("team_progress_cap"),
    badges: localize("badges_cap"),
    membersGoalCount: localize("active_team_members_objectives_cap"), // Active team members' objectives
    dynamicMetricCount: localize("dynamic_key_results_cap"),
    manualMetricCount: localize("manual_key_results_cap"),
    status: localize("status"),
  };
}

export function getListPropertiesDictionary(): Record<string, string> {
  return {
    title: localize("name_cap"),
    uiAccess: localize("access_cap"),
    navigationShow: localize("show_in_navigation_cap"),
    ownerId: localize("owner_cap"),
  };
}

export function getListAccessType(list: List): string {
  for (const p of list.access.permissions) {
    if (p.principalKind === "account" && p.grant.general.indexOf("read") !== -1 && p.grant.general.indexOf("update") === -1) {
      return AccessTypes.publicRead;
    }

    if (p.principalKind === "account" && p.grant.general.indexOf("read") !== -1 && p.grant.general.indexOf("update") !== -1) {
      return AccessTypes.publicUpdate;
    }
  }

  if (list.access.permissions.length === 0) {
    return AccessTypes.private;
  }

  return AccessTypes.restricted;
}

interface PredefinedListRules {
  predefinedFilterRule: OrListFiltersGroup[];
  predefinedSortingRule: IListSort[];
  predefinedColumns: IListColumn[];
}

export function getPredefinedListRules(type: ListType): PredefinedListRules {
  const currentUserId = getCurrentUserId();

  return predefinedListRulesByType(currentUserId)[type];
}

function predefinedListRulesByType(currentUserId: string): Record<ListType, PredefinedListRules> {
  return {
    Objective: {
      predefinedColumns: [
        { fieldName: ListsPropertyNames.name, type: ListFilterTypes.alphabetic, width: 250 },
        ...[{ fieldName: ListsPropertyNames.closedStatus, type: ListFilterTypes.alphabetic, width: 250 }],
        { fieldName: ListsPropertyNames.ownerIds, type: ListFilterTypes.id, width: 250 },
        { fieldName: ListsPropertyNames.attainment, type: ListFilterTypes.numeric, width: 250 },
        { fieldName: ListsPropertyNames.sessionId, type: ListFilterTypes.id, width: 250 },
        { fieldName: ListsPropertyNames.tags, type: ListFilterTypes.array, width: 250 },
      ],
      predefinedFilterRule: [
        [
          {
            fieldName: ListsPropertyNames.sessionId,
            dataType: ListFilterTypes.id,
            operator: SupportedOperators.isOneOf,
            dynamicFilters: [{ dynamicValueType: DynamicFilters.currentSessions }],
          },
          {
            fieldName: ListsPropertyNames.ownerIds,
            dataType: ListFilterTypes.id,
            operator: SupportedOperators.contains,
            dynamicFilters: [{ dynamicValueType: DynamicFilters.currentUser, value: [currentUserId] }],
          },
        ],
      ],
      predefinedSortingRule: [{ fieldName: ListsPropertyNames.attainment, reversed: true }],
    },
    "Key Result": {
      predefinedColumns: [
        { fieldName: ListsPropertyNames.name, type: ListFilterTypes.alphabetic, width: 250 },
        { fieldName: ListsPropertyNames.ownerIds, type: ListFilterTypes.id, width: 250 },
        { fieldName: ListsPropertyNames.attainment, type: ListFilterTypes.numeric, width: 250 },
        { fieldName: ListsPropertyNames.goalId, type: ListFilterTypes.id, width: 250 },
        { fieldName: ListsPropertyNames.tags, type: ListFilterTypes.array, width: 250 },
        { fieldName: ListsPropertyNames.confidenceValue, type: ListFilterTypes.numeric, width: 250 },
        { fieldName: ListsPropertyNames.lastCheckInDate, type: ListFilterTypes.date, width: 250 },
      ],
      predefinedFilterRule: [
        [
          {
            fieldName: ListsPropertyNames.sessionId,
            dataType: ListFilterTypes.id,
            operator: SupportedOperators.isOneOf,
            dynamicFilters: [{ dynamicValueType: DynamicFilters.currentSessions }],
          },
          {
            fieldName: ListsPropertyNames.ownerIds,
            dataType: ListFilterTypes.id,
            operator: SupportedOperators.contains,
            dynamicFilters: [{ dynamicValueType: DynamicFilters.currentUser, value: [currentUserId] }],
          },
        ],
      ],
      predefinedSortingRule: [{ fieldName: ListsPropertyNames.attainment, reversed: true }],
    },
    Task: {
      predefinedColumns: [
        { fieldName: ListsPropertyNames.name, type: ListFilterTypes.alphabetic, width: 250 },
        { fieldName: ListsPropertyNames.ownerId, type: ListFilterTypes.id, width: 250 },
        { fieldName: ListsPropertyNames.dueDate, type: ListFilterTypes.date, width: 250 },
        { fieldName: ListsPropertyNames.status, type: ListFilterTypes.alphabetic, width: 250 },
        { fieldName: ListsPropertyNames.parentId, type: ListFilterTypes.id, width: 250 },
      ],
      predefinedFilterRule: [
        [
          {
            fieldName: ListsPropertyNames.ownerId,
            dataType: ListFilterTypes.id,
            operator: SupportedOperators.isOneOf,
            dynamicFilters: [{ dynamicValueType: DynamicFilters.currentUser, value: [currentUserId] }],
          },
        ],
      ],
      predefinedSortingRule: [{ fieldName: ListsPropertyNames.dueDate, reversed: true }],
    },
    Employee: {
      predefinedColumns: [
        { fieldName: ListsPropertyNames.picture, type: ListFilterTypes.alphabetic, width: 100 },
        { fieldName: ListsPropertyNames.firstName, type: ListFilterTypes.alphabetic, width: 150 },
        { fieldName: ListsPropertyNames.lastName, type: ListFilterTypes.alphabetic, width: 150 },
        { fieldName: ListsPropertyNames.isActive, type: ListFilterTypes.boolean, width: 140 },
        { fieldName: ListsPropertyNames.teamIds, type: ListFilterTypes.id, width: 250 },
        { fieldName: ListsPropertyNames.goalCount, type: ListFilterTypes.numeric, width: 140 },
        { fieldName: ListsPropertyNames.progress, type: ListFilterTypes.numeric, width: 140 },
        { fieldName: ListsPropertyNames.manualMetricCount, type: ListFilterTypes.numeric, width: 140 },
        { fieldName: ListsPropertyNames.dynamicMetricCount, type: ListFilterTypes.numeric, width: 140 },
      ],
      predefinedFilterRule: [
        [
          {
            fieldName: ListsPropertyNames.teamIds,
            dataType: ListFilterTypes.id,
            operator: SupportedOperators.contains,
            dynamicFilters: [{ dynamicValueType: DynamicFilters.currentUserTeams }],
          },
          {
            fieldName: ListsPropertyNames.isActive,
            dataType: ListFilterTypes.boolean,
            operator: SupportedOperators.notEqual,
            value: false,
          },
        ],
      ],
      predefinedSortingRule: [{ fieldName: ListsPropertyNames.firstName, reversed: false }],
    },
    Team: {
      predefinedColumns: [
        { fieldName: ListsPropertyNames.avatar, type: ListFilterTypes.alphabetic, width: 100 },
        { fieldName: ListsPropertyNames.name, type: ListFilterTypes.alphabetic, width: 150 },
        { fieldName: ListsPropertyNames.isActive, type: ListFilterTypes.boolean, width: 140 },
        { fieldName: ListsPropertyNames.manager, type: ListFilterTypes.id, width: 250 },
        { fieldName: ListsPropertyNames.members, type: ListFilterTypes.id, width: 250 },
        { fieldName: ListsPropertyNames.teamGoalCount, type: ListFilterTypes.numeric, width: 140 },
        { fieldName: ListsPropertyNames.progress, type: ListFilterTypes.numeric, width: 140 },
      ],
      predefinedFilterRule: [
        [
          {
            fieldName: ListsPropertyNames.members,
            dataType: ListFilterTypes.id,
            operator: SupportedOperators.contains,
            dynamicFilters: [{ dynamicValueType: DynamicFilters.currentUserTeamsMember }],
          },
          {
            fieldName: ListsPropertyNames.isActive,
            dataType: ListFilterTypes.boolean,
            operator: SupportedOperators.notEqual,
            value: false,
          },
        ],
      ],
      predefinedSortingRule: [{ fieldName: ListsPropertyNames.name, reversed: false }],
    },
  };
}
