import { StateService } from "@uirouter/angular";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { localize } from "@gtmhub/localization";
import { INotificationUser, IReactionNotification, IReactionNotificationDataItem } from "@gtmhub/notifications/models/notifications";
import { checkInReactionLegacy } from "@webapp/reflections/models/reflections.models";
import { SizeOfGif } from "@webapp/shared/components/gifs/gif.models";
import { OkrLinkClickEvent } from "@webapp/shared/rich-text-editor/events/okr-link.event";
import { InboxTemplateComponent } from "../inbox-template.component";

const commentOwnerSuffixMap: Record<string, string> = {
  announcement_comment_reaction_created: "your_comment_on_badge",
  task_comment_reaction_created: "your_comment_on_task",
  goal_comment_reaction_created: "your_comment_on_goal",
  metric_comment_reaction_created: "your_comment_on_key_result",
  insight_comment_reaction_created: "your_comment_on_insight",
  metric_snapshot_comment_reaction_created: "your_comment_on_kr_update",
  kpi_projection_comment_reaction_created: "your_comment_on_kpi_projection",
  kpi_snapshot_comment_reaction_created: "your_comment_on_kpi_snapshot",
};

const krUpdatesSuffixMap: Record<string, string> = {
  metric_snapshot_reaction_created: "your_kr_update",
  assigned_metric_snapshot_reaction_created: "an_automatic_update_on_your_kr",
  team_assigned_metric_snapshot_reaction_created: "an_automatic_update_on_your_teams_kr",
};

interface CheckinTransitionStateParams {
  activeTab: string;
  published: string;
  cadenceStart: string;
  memberId?: string;
  targetId?: string;
  commentId?: string;
}

@Component({
  selector: "reaction-inbox-template",
  templateUrl: "./reaction-inbox-template.component.html",
  styleUrls: ["../inbox-template.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactionInboxTemplateComponent extends InboxTemplateComponent<IReactionNotification> {
  public sizeOfGif = SizeOfGif;
  public checkInReactionLegacy = checkInReactionLegacy;

  constructor(private stateService: StateService) {
    super();
  }

  public get title(): string {
    const { item, actor } = this.notification.notificationData;
    const { reactedUsers } = item;
    const prefix = this.localizePrefix(reactedUsers, actor);
    const suffix = this.localizeSuffix(item);
    return `${prefix} ${suffix}`;
  }

  public get badgeTitle(): string {
    const { item, actor } = this.notification.notificationData;
    const { reactedUsers } = item;
    const prefix = this.localizePrefix(reactedUsers, actor);
    const suffix = this.localizeBadgeSuffix(item);
    return `${prefix} ${suffix}`;
  }

  public get icon(): string {
    return this.notification.notificationData.item.targetType === "comment" ? "comments" : this.notification.notificationData.item.targetType;
  }

  private localizePrefix(reactedUsers: INotificationUser[], actor: INotificationUser): string {
    if (!reactedUsers || reactedUsers.length <= 1) {
      return localize("reaction_notification_title", { names: actor.names });
    } else {
      return localize("reaction_notification_title_plural", { names: actor.names, length: reactedUsers.length - 1 });
    }
  }

  private localizeSuffix(item: IReactionNotificationDataItem): string {
    const targetItemName = item.targetItemName || "";
    if (item.template === "comment_reaction_mentioned") {
      return localize("a_comment_on_where_you_were_mentioned", { name: targetItemName });
    } else if (item.template === "comment_reaction") {
      return localize(commentOwnerSuffixMap[this.notification.operationType], { name: targetItemName });
    }

    return localize(krUpdatesSuffixMap[this.notification.operationType], { name: targetItemName });
  }

  private localizeBadgeSuffix(item: IReactionNotificationDataItem): string {
    const targetItemName = item.targetItemName || "";
    return localize("react_on_your_badge", { name: targetItemName });
  }

  public goToCheckinOrComment = (event: MouseEvent, url: string, type: string): void => {
    // Fix state transition if we are on the same state that notificaion should lead to
    if (this.stateService.is("gtmhub.teams.team")) {
      event.preventDefault();

      // Matches format YYYY-MM-DD
      const cadenceStartPattern = /(?<=cadenceStart=)\d{4}-\d{2}-\d{2}/gm;
      const memberIdPattern = /(?<=memberId=)[a-z0-9]{24}/gm;

      const params: CheckinTransitionStateParams = {
        activeTab: "check-ins",
        published: "true",
        cadenceStart: cadenceStartPattern.exec(url)[0],
        memberId: memberIdPattern.exec(url)[0],
      };

      if (type !== "comment") {
        this.stateService.go(".", params, { reload: true });
      } else {
        const targetIdPattern = /(?<=targetId=)[a-z0-9]{24}/gm;
        const commentIdPattern = /(?<=commentId=)[a-z0-9]{24}/gm;

        this.stateService.go(
          ".",
          {
            ...params,
            targetId: targetIdPattern.exec(url)[0],
            commentId: commentIdPattern.exec(url)[0],
          },
          { reload: true }
        );
      }
    }
  };

  public okrLinkClicked(event: OkrLinkClickEvent): void {
    const url = this.notification.notificationData.item?.url;

    const teamIdRegex = /teams\/([a-f0-9]+)/;
    const teamId = url.match(teamIdRegex)[1];
    const memberIdRegex = /memberId=([a-f0-9]+)/;
    const memberId = url.match(memberIdRegex)[1];
    const cadenceStartPattern = /(?<=cadenceStart=)\d{4}-\d{2}-\d{2}/gm;
    const cadenceStart = cadenceStartPattern.exec(url)[0];

    this.stateService.go(`.team`, { ...event.stateParams.params, teamId, activeTab: "check-ins", memberId, cadenceStart }).then(() => {
      this.stateService.go(event.stateParams.path, event.stateParams.params);
    });
  }
}
