import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { NzElementPatchModule } from "ng-zorro-antd/core/element-patch";
import { formatAttainmentProgressValue } from "@gtmhub/okrs/metrics/utils";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { localize } from "@webapp/localization/utils/localization.utils";
import { UiProgressModule } from "@webapp/ui/progress/progress.module";

/**
 * @example <metric-progress-bar [progress]="0.5" [color]="#7E5FBF"></metric-progress-bar>
 *
 * @param progress number
 * @param color string
 * @param delta number
 */
@Component({
  selector: "metric-progress-bar",
  templateUrl: "./metric-progress-bar.component.html",
  styleUrls: ["./metric-progress-bar.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UiProgressModule, LocalizationModule, UiIconModule, UiTooltipModule, NzElementPatchModule],
})
export class MetricProgressBarComponent implements OnInit, OnChanges {
  /**
   * @param progress number - The Progress that should be displayed
   */
  @Input() public progress: number;
  /**
   * @param color string - The color code to be used
   */
  @Input() public color: string;
  /**
   * @param delta number - The Delta in the Progress
   */
  @Input() public delta: number;
  @Input()
  public uiHideBar: boolean = false;
  @Input()
  public uiHideProgress: boolean = false;
  @Input()
  public uiHideDelta: boolean = false;

  public percent: number;
  public progressToShow: number;
  public progressLabel: string;
  public deltaLabel: string;
  public deltaSignToShow: string;
  public deltaValueToShow: number;

  public ngOnInit(): void {
    this.parseProgress(this.progress);

    if (this.delta) {
      this.parseDelta(this.delta);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.progress) {
      this.parseProgress(this.progress);
    }

    if (changes.delta) {
      this.parseDelta(this.delta);
    }
  }

  private parseProgress(progress = 0): void {
    this.progressToShow = formatAttainmentProgressValue(progress);

    if (this.progressToShow < 0) {
      this.percent = 0;
    } else {
      this.percent = this.progressToShow;
    }

    this.progressLabel = `${localize("progress_cap")} ${this.progressToShow}%.`;
  }

  private parseDelta(delta: number): void {
    const signedTopLimit = 10;
    const signedLowLimit = 0.005;

    let sign = "";

    if (delta >= signedLowLimit && delta < signedTopLimit && delta > -signedTopLimit) {
      sign = "+";
    } else if (delta <= -signedLowLimit && delta < signedTopLimit && delta > -signedTopLimit) {
      sign = "-";
    }

    this.deltaSignToShow = sign;
    this.deltaValueToShow = Math.abs(formatAttainmentProgressValue(delta));
    this.deltaLabel = `${localize("progress_in_last_14_days")}: ${this.deltaSignToShow}${this.deltaValueToShow}%.`;
  }
}
