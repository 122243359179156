import { module } from "angular";
import badgesCore from "@gtmhub/badges/badges-core.module";
import upgrade from "@gtmhub/core/upgrade/module";
import employeesCore from "@gtmhub/employees/employees-core.module";
import feed from "@gtmhub/feed/feed.module";
import quantivePlusModule from "@gtmhub/goals/components/quantive-plus/quantive-plus.module";
import { Ng1LoginMobileWarningService } from "@gtmhub/login/services/ng1-login-mobile-warning.service";
import navigation from "@gtmhub/navigation/navigation.module";
import onboardingCore from "@gtmhub/onboarding/onboarding-core.module";
import reflectionsCore from "@gtmhub/reflections/reflections-core.module";
import teamsCore from "@gtmhub/teams/teams-core.module";
import { FeedTopNavBarComponent } from "@webapp/home/components/feed-top-nav-bar/feed-top-nav-bar.component";
import { HomeDashboardComponent } from "@webapp/home/components/home-dashboard/home-dashboard.component";
import { OkrWorkflowService } from "@webapp/okrs/components/okr-workflow/okr-workflow.service";
import { ConfidenceLabelComponent } from "@webapp/shared/components/confidence-label/confidence-label.component";
import { TaskModalService } from "@webapp/tasks/services/task-modal.service";
import { HomeSkeletonComponent } from "../../../webapp/src/app/home/components/home-skeleton/home-skeleton.component";

const mod = module("home", [upgrade, feed, quantivePlusModule, onboardingCore, navigation, badgesCore, employeesCore, teamsCore, reflectionsCore]);

mod.downgradeComponent("homeSkeleton", { component: HomeSkeletonComponent, propagateDigest: false });
mod.downgradeComponent("homeDashboard", { component: HomeDashboardComponent, propagateDigest: false });
mod.downgradeInjectable("OkrWorkflowService", OkrWorkflowService);
mod.downgradeInjectable("TaskModalService", TaskModalService);
mod.service("Ng1LoginMobileWarningService", Ng1LoginMobileWarningService);
mod.downgradeComponent("feedTopNavBar", { component: FeedTopNavBarComponent, propagateDigest: false });
mod.downgradeComponent("confidenceLabel", { component: ConfidenceLabelComponent, propagateDigest: false });

export default mod.name;
