import { Directive, Input } from "@angular/core";
import { NzAutosizeDirective } from "ng-zorro-antd/input";
import { UiAutoSizeType } from "@webapp/ui/input/input.models";

@Directive({
  selector: "textarea[uiAutosize]",
  exportAs: "uiAutosize",
  standalone: true,
})
export class UiAutosizeDirective extends NzAutosizeDirective {
  @Input("uiAutosize")
  set uiAutosize(value: string | boolean | UiAutoSizeType) {
    super.nzAutosize = value;
  }
}
