import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { APP_CONFIG, IAppConfig } from "@gtmhub/env";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { Tag } from "@webapp/tags/models/tag.model";
import { HttpActions } from "../../core/abstracts/enums/http-actions.enum";
import { TagDTO } from "../models/tag.model";
import { TagsState } from "./tags-state.service";

@Injectable({
  providedIn: "root",
})
export class TagsApiService extends BaseApiService<Tag, TagDTO, TagsState> {
  public constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, tagsState: TagsState) {
    super("tags", { ...new ApiVersionModel("v1") }, httpClient, appConfig, tagsState);
  }

  public getTagsEndpoint(): string {
    return `${this.getBasePath(HttpActions.get, { apiVersionOverwrite: "v2" })}`;
  }

  public createTagsEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}`;
  }
}
