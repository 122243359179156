<div
  *ngIf="
    notification.operationType === 'metric_user_assigned_create' ||
    notification.operationType === 'metric_user_assigned' ||
    notification.operationType === 'metric_team_assigned_create' ||
    notification.operationType === 'metric_team_assigned'
  ">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    <span *ngIf="notification.notificationData.item.isTeamMetric">{{ "user_assigned_kr_to_your_team" | localize: { names: notification.notificationData.actor.names } }}</span
    ><span *ngIf="!notification.notificationData.item.isTeamMetric">{{ "user_assigned_you_kr" | localize: { names: notification.notificationData.actor.names } }}</span>
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiType]="notification.targetType" [uiTooltipTitle]="notification.targetType | localize" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section inbox-section-multiple-owners">
        <div class="heading inline-notifications">{{ "owner_cap" | localize }} :</div>
        <div *ngFor="let assignee of notification.notificationData.item.assignees">
          <ui-assignee [fromAssigneeId]="assignee.id" uiSize="sm"></ui-assignee>
        </div>
      </div>
      <div class="inbox-section">
        <div class="heading inline-notifications">
          {{ "title" | localize }}
        </div>
        : {{ notification.notificationData.item.name }}
      </div>
      <div class="inbox-section">
        <div class="heading inline-notifications">
          {{ "target" | localize }}
        </div>
        :&nbsp;
        <span *ngIf="notification.notificationData.item.targetInfo.manualType === 'boolean'">{{ "target_should_achieved_or_not" | localize }}&nbsp;</span>
        <span *ngIf="notification.notificationData.item.targetOperator === 'should_stay_above'">
          {{ "should_stay_above_target_info_value" | localize }} {{ notification.notificationData.item.targetInfo.target | metricValue: notification.notificationData.item.targetInfo.format
          }}<span *ngIf="notification.notificationData.item.targetInfo.critical"
            >{{ "and_absolutely_must_be_above_critical_value" | localize }}
            {{ notification.notificationData.item.targetInfo.critical | metricValue: notification.notificationData.item.targetInfo.format }}</span
          ></span
        ><span *ngIf="notification.notificationData.item.targetOperator === 'should_stay_below'"
          >{{ "should_stay_below_target_info_value" | localize }} {{ notification.notificationData.item.targetInfo.target | metricValue: notification.notificationData.item.targetInfo.format
          }}<span *ngIf="notification.notificationData.item.targetInfo.critical"
            >{{ "and_absolutely_must_be_below_critical_value" | localize }}
            {{ notification.notificationData.item.targetInfo.critical | metricValue: notification.notificationData.item.targetInfo.format }}</span
          ></span
        ><span *ngIf="notification.notificationData.item.targetOperator === 'at_least'"
          >{{ "should_increase_to_target_info_value" | localize }} {{ notification.notificationData.item.targetInfo.target | metricValue: notification.notificationData.item.targetInfo.format
          }}<span *ngIf="notification.notificationData.item.targetInfo.critical"
            >{{ "and_absolutely_must_be_above_critical_value" | localize }}
            {{ notification.notificationData.item.targetInfo.critical | metricValue: notification.notificationData.item.targetInfo.format }}</span
          ></span
        ><span *ngIf="notification.notificationData.item.targetOperator === 'at_most'"
          >{{ "should_decrease_to_target_info_value" | localize }} {{ notification.notificationData.item.targetInfo.target | metricValue: notification.notificationData.item.targetInfo.format
          }}<span *ngIf="notification.notificationData.item.targetInfo.critical"
            >{{ "and_absolutely_must_be_below_critical_value" | localize }}
            {{ notification.notificationData.item.targetInfo.critical | metricValue: notification.notificationData.item.targetInfo.format }}</span
          ></span
        >
      </div>
      <div class="inbox-section" *ngIf="notification.notificationData.item.dueDate">
        <div class="heading inline-notifications">
          {{ "hard_deadline" | localize }}
        </div>
        : {{ notification.notificationData.item.dueDate | date: "dd MMM, yyyy" }}
      </div>
      <div class="inbox-section" *ngIf="notification.notificationData.item.softDueDate">
        <div class="heading inline-notifications">
          {{ "soft_deadline" | localize }}
        </div>
        : {{ notification.notificationData.item.softDueDate | date: "dd MMM, yyyy" }}
      </div>
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_key_result" | localize }}</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'metric_user_modified' || notification.operationType === 'metric_team_modified'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    {{ (isOnlyMilestonesChanged ? "user_edited_kr_milestones" : "user_edited_kr") | localize: { names: notification.notificationData.actor.names } }}
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiType]="notification.targetType" [uiTooltipTitle]="notification.targetType | localize" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section" *ngIf="notification.notificationData.item.changes.assignees?.oldValue || notification.notificationData.item.changes.assignees?.newValue">
        <div class="heading">{{ "owners_cap" | localize }}:</div>
        <div>
          <div class="list-item">{{ "from" | localize }}:</div>
          <span>
            <ui-assignee *ngFor="let assignee of notification.notificationData.item.changes.assignees?.oldValue" [uiAssignee]="assignee | asAssignee" style="display: inline" uiSize="sm"></ui-assignee
            >&nbsp;
          </span>
        </div>
        <div>
          <div class="list-item">{{ "to_change_state" | localize }}:</div>
          <ui-assignee *ngFor="let assignee of notification.notificationData.item.changes.assignees?.newValue" [uiAssignee]="assignee | asAssignee" style="display: inline" uiSize="sm"></ui-assignee
          >&nbsp;
        </div>
      </div>
      <div class="inbox-section" *ngIf="notification.notificationData.item.changes.name?.oldValue">
        <div class="heading">{{ "title" | localize }}:</div>
        <div>
          <div class="list-item">{{ "from" | localize }}</div>
          :<rich-text-viewer [text]="notification.notificationData.item.changes.name.oldValue"></rich-text-viewer>
        </div>
        <div>
          <div class="list-item">{{ "to_change_state" | localize }}</div>
          :<rich-text-viewer [text]="notification.notificationData.item.changes.name.newValue"></rich-text-viewer>
        </div>
      </div>
      <div class="inbox-section" *ngIf="notification.notificationData.item.changes.dueDate?.oldValue">
        <div class="heading">{{ "due_date_cap" | localize }}:</div>
        <div>
          <div class="list-item">{{ "from" | localize }}:</div>
          {{ notification.notificationData.item.changes.dueDate?.oldValue | date: "dd MMM, yyyy" }}
        </div>
        <div>
          <div class="list-item">{{ "to_change_state" | localize }}:</div>
          {{ notification.notificationData.item.changes.dueDate?.newValue | date: "dd MMM, yyyy" }}
        </div>
      </div>

      <div class="inbox-section" *ngIf="notification.notificationData.item.changes.softDueDate?.oldValue">
        <div class="heading">{{ "soft_deadline" | localize }}:</div>
        <div>
          <div class="list-item">{{ "from" | localize }}:</div>
          {{ notification.notificationData.item.changes.softDueDate?.oldValue | date: "dd MMM, yyyy" }}
        </div>
        <div>
          <div class="list-item">{{ "to_change_state" | localize }}:</div>
          {{ notification.notificationData.item.changes.softDueDate?.newValue | date: "dd MMM, yyyy" }}
        </div>
      </div>
      <div class="inbox-section" *ngIf="notification.notificationData.item.changes.targetInfo?.oldValue || notification.notificationData.item.changes.targetOperator?.oldValue">
        <div class="heading">{{ "target" | localize }}:</div>
        <div>
          <div class="list-item">{{ "from" | localize }}</div>
          :&nbsp;<span *ngIf="notification.notificationData.item.changes.targetInfo.oldValue?.manualType === 'boolean'">{{ "target_should_achieved_or_not" | localize }}&nbsp;</span
          ><span *ngIf="notification.notificationData.item.changes.targetInfo.oldValue?.manualType !== 'boolean'"
            ><span *ngIf="notification.notificationData.item.changes.targetOperator.oldValue === 'should_stay_above'"
              >{{ "should_stay_above_target_info_old_value" | localize }}
              {{ notification.notificationData.item.changes.targetInfo.oldValue?.target | metricValue: notification.notificationData.item.changes.targetInfo.oldValue?.format
              }}<span *ngIf="notification.notificationData.item.changes.targetInfo.oldValue?.critical"
                >{{ "and_absolutely_must_be_above_old_critical_value" | localize }}
                {{ notification.notificationData.item.changes.targetInfo.oldValue?.critical | metricValue: notification.notificationData.item.changes.targetInfo.oldValue?.format }}</span
              ></span
            ><span *ngIf="notification.notificationData.item.changes.targetOperator.oldValue === 'should_stay_below'"
              >{{ "should_stay_below_target_info_old_value" | localize }}
              {{ notification.notificationData.item.changes.targetInfo.oldValue?.target | metricValue: notification.notificationData.item.changes.targetInfo.oldValue?.format
              }}<span *ngIf="notification.notificationData.item.changes.targetInfo.oldValue?.critical"
                >{{ "and_absolutely_must_be_below_old_critical_value" | localize }}
                {{ notification.notificationData.item.changes.targetInfo.oldValue?.critical | metricValue: notification.notificationData.item.changes.targetInfo.oldValue?.format }}</span
              ></span
            ><span *ngIf="notification.notificationData.item.changes.targetOperator.oldValue === 'at_least'"
              >{{ "should_increase_to_target_info_old_value" | localize }}
              {{ notification.notificationData.item.changes.targetInfo.oldValue?.target | metricValue: notification.notificationData.item.changes.targetInfo.oldValue?.format
              }}<span *ngIf="notification.notificationData.item.changes.targetInfo.oldValue?.critical"
                >{{ "and_absolutely_must_be_above_old_critical_value" | localize }}
                {{ notification.notificationData.item.changes.targetInfo.oldValue?.critical | metricValue: notification.notificationData.item.changes.targetInfo.oldValue?.format }}</span
              ></span
            ><span *ngIf="notification.notificationData.item.changes.targetOperator.oldValue === 'at_most'"
              >{{ "should_decrease_to_target_info_old_value" | localize }}
              {{ notification.notificationData.item.changes.targetInfo.oldValue?.target | metricValue: notification.notificationData.item.changes.targetInfo.oldValue?.format
              }}<span *ngIf="notification.notificationData.item.changes.targetInfo.oldValue?.critical"
                >{{ "and_absolutely_must_be_below_old_critical_value" | localize }}
                {{ notification.notificationData.item.changes.targetInfo.oldValue?.critical | metricValue: notification.notificationData.item.changes.targetInfo.oldValue?.format }}</span
              ></span
            ></span
          >
        </div>
        <div>
          <div class="list-item">{{ "to_change_state" | localize }}</div>
          :&nbsp;<span *ngIf="notification.notificationData.item.changes.targetInfo.newValue?.manualType === 'boolean'">{{ "target_should_achieved_or_not" | localize }}&nbsp;</span
          ><span *ngIf="notification.notificationData.item.changes.targetInfo.newValue?.manualType !== 'boolean'"
            ><span *ngIf="notification.notificationData.item.changes.targetOperator.newValue === 'should_stay_above'"
              >{{ "should_stay_above_target_info_new_value" | localize }}
              {{ notification.notificationData.item.changes.targetInfo.newValue?.target | metricValue: notification.notificationData.item.changes.targetInfo.newValue?.format
              }}<span *ngIf="notification.notificationData.item.changes.targetInfo.newValue?.critical"
                >{{ "and_absolutely_must_be_above_new_critical_value" | localize }}
                {{ notification.notificationData.item.changes.targetInfo.newValue?.critical | metricValue: notification.notificationData.item.changes.targetInfo.newValue?.format }}</span
              ></span
            ><span *ngIf="notification.notificationData.item.changes.targetOperator.newValue === 'should_stay_below'"
              >{{ "should_stay_below_target_info_new_value" | localize }}
              {{ notification.notificationData.item.changes.targetInfo.newValue?.target | metricValue: notification.notificationData.item.changes.targetInfo.newValue?.format
              }}<span *ngIf="notification.notificationData.item.changes.targetInfo.newValue?.critical"
                >{{ "and_absolutely_must_be_below_new_critical_value" | localize }}
                {{ notification.notificationData.item.changes.targetInfo.newValue?.critical | metricValue: notification.notificationData.item.changes.targetInfo.newValue?.format }}</span
              ></span
            ><span *ngIf="notification.notificationData.item.changes.targetOperator.newValue === 'at_least'"
              >{{ "should_increase_to_target_info_new_value" | localize }}
              {{ notification.notificationData.item.changes.targetInfo.newValue?.target | metricValue: notification.notificationData.item.changes.targetInfo.newValue?.format
              }}<span *ngIf="notification.notificationData.item.changes.targetInfo.newValue?.critical"
                >{{ "and_absolutely_must_be_above_new_critical_value" | localize }}
                {{ notification.notificationData.item.changes.targetInfo.newValue?.critical | metricValue: notification.notificationData.item.changes.targetInfo.newValue?.format }}</span
              ></span
            ><span *ngIf="notification.notificationData.item.changes.targetOperator.newValue === 'at_most'"
              >{{ "should_decrease_to_target_info_new_value" | localize }}
              {{ notification.notificationData.item.changes.targetInfo.newValue?.target | metricValue: notification.notificationData.item.changes.targetInfo.newValue?.format
              }}<span *ngIf="notification.notificationData.item.changes.targetInfo.newValue?.critical"
                >{{ "and_absolutely_must_be_below_new_critical_value" | localize }}
                {{ notification.notificationData.item.changes.targetInfo.newValue?.critical | metricValue: notification.notificationData.item.changes.targetInfo.newValue?.format }}</span
              ></span
            ></span
          >
        </div>
      </div>
      <div class="inbox-section" *ngIf="notification.notificationData.item.changes.description?.oldValue || notification.notificationData.item.changes.description?.newValue">
        <div class="heading">{{ "description" | localize }}:</div>
        <div>
          <div class="list-item">{{ "from" | localize }}</div>
          :<rich-text-viewer [text]="notification.notificationData.item.changes.description.oldValue || 'N/A'"></rich-text-viewer>
        </div>
        <div>
          <div class="list-item">{{ "to_change_state" | localize }}</div>
          :<rich-text-viewer [text]="notification.notificationData.item.changes.description.newValue || 'N/A'"></rich-text-viewer>
        </div>
      </div>
      <div class="inbox-section" *ngIf="notification.notificationData.item.changes.tags?.oldValue || notification.notificationData.item.changes.tags?.newValue">
        <div class="heading">{{ "tags" | localize }}:</div>
        <div>
          <div class="list-item" *ngIf="notification.notificationData.item.changes.tags?.oldValue">{{ "removed" | localize }} : {{ notification.notificationData.item.changes.tags.oldValue }}</div>
        </div>
        <div>
          <div class="list-item" *ngIf="notification.notificationData.item.changes.tags?.newValue">{{ "added" | localize }} : {{ notification.notificationData.item.changes.tags.newValue }}</div>
        </div>
      </div>
      <div *ngFor="let custField of notification.notificationData.item.changes.customFields">
        <div class="inbox-section" *ngIf="custField?.oldValue || custField?.newValue">
          <div class="heading">{{ "custom_field_updated" | localize: { custFieldName: custField.label } }}:</div>
          <div [ngSwitch]="custField.visualizationKind">
            <div class="list-item">{{ "from" | localize }}:</div>
            <div *ngSwitchCase="'gtmhub.selectassignee'">
              <span> <ui-assignee *ngFor="let assignee of $any(custField.oldValue)" [uiAssignee]="assignee | asAssignee" style="display: inline" uiSize="sm"></ui-assignee>&nbsp; </span>
            </div>

            <div *ngSwitchCase="'gtmhub.multiselect'">
              <rich-text-viewer *ngFor="let value of $any(custField?.oldValue)" [text]="$any(value) || 'N/A'"></rich-text-viewer>
            </div>
            <div *ngSwitchCase="'gtmhub.datepicker'">
              {{ $any(custField?.oldValue) | date: "dd MMM, yyyy" }}
            </div>
            <div *ngSwitchDefault>
              <rich-text-viewer [text]="$any(custField?.oldValue) || 'N/A'"></rich-text-viewer>
            </div>

            <div class="list-item">{{ "to_change_state" | localize }}:</div>
            <div *ngSwitchCase="'gtmhub.selectassignee'">
              <span> <ui-assignee *ngFor="let assignee of $any(custField?.newValue)" [uiAssignee]="assignee | asAssignee" style="display: inline" uiSize="sm"></ui-assignee>&nbsp; </span>
            </div>
            <div *ngSwitchCase="'gtmhub.multiselect'">
              <rich-text-viewer *ngFor="let value of $any(custField?.newValue)" [text]="$any(value) || 'N/A'"></rich-text-viewer>
            </div>
            <div *ngSwitchCase="'gtmhub.datepicker'">
              {{ $any(custField?.newValue) | date: "dd MMM, yyyy" }}
            </div>
            <div *ngSwitchDefault>
              <rich-text-viewer [text]="$any(custField?.newValue) || 'N/A'"></rich-text-viewer>
            </div>
          </div>
        </div>
      </div>
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_key_result" | localize }}</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'metric_user_deleted' || notification.operationType === 'metric_team_deleted'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    {{ "user_deleted_kr" | localize: { names: notification.notificationData.actor.names } }}
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiType]="notification.targetType" [uiTooltipTitle]="notification.targetType | localize" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <div class="heading inline-notifications">{{ "owner_cap" | localize }}:</div>
        <ui-assignee [uiAssignee]="notification.notificationData.item.assignee | asAssignee" uiSize="sm"></ui-assignee>
      </div>
      <div class="inbox-section">
        <div class="heading inline-notifications">
          {{ "title" | localize }}
        </div>
        : {{ notification.notificationData.item.name }}
      </div>
      <div class="inbox-section">
        <div class="heading inline-notifications">
          {{ "target" | localize }}
        </div>
        :&nbsp;<span *ngIf="notification.notificationData.item.targetInfo.manualType === 'boolean'">{{ "target_should_achieved_or_not" | localize }}&nbsp;</span
        ><span *ngIf="notification.notificationData.item.targetOperator === 'should_stay_above'"
          >{{ "should_stay_above_target_info_value" | localize }} {{ notification.notificationData.item.targetInfo.target | metricValue: notification.notificationData.item.targetInfo.format
          }}<span *ngIf="notification.notificationData.item.targetInfo.critical"
            >{{ "and_absolutely_must_be_above_critical_value" | localize }}
            {{ notification.notificationData.item.targetInfo.critical | metricValue: notification.notificationData.item.targetInfo.format }}</span
          ></span
        ><span *ngIf="notification.notificationData.item.targetOperator === 'should_stay_below'"
          >{{ "should_stay_below_target_info_value" | localize }} {{ notification.notificationData.item.targetInfo.target | metricValue: notification.notificationData.item.targetInfo.format
          }}<span *ngIf="notification.notificationData.item.targetInfo.critical"
            >{{ "and_absolutely_must_be_below_critical_value" | localize }}
            {{ notification.notificationData.item.targetInfo.critical | metricValue: notification.notificationData.item.targetInfo.format }}</span
          ></span
        ><span *ngIf="notification.notificationData.item.targetOperator === 'at_least'"
          >{{ "should_increase_to_target_info_value" | localize }} {{ notification.notificationData.item.targetInfo.target | metricValue: notification.notificationData.item.targetInfo.format
          }}<span *ngIf="notification.notificationData.item.targetInfo.critical"
            >{{ "and_absolutely_must_be_above_critical_value" | localize }}
            {{ notification.notificationData.item.targetInfo.critical | metricValue: notification.notificationData.item.targetInfo.format }}</span
          ></span
        ><span *ngIf="notification.notificationData.item.targetOperator === 'at_most'"
          >{{ "should_decrease_to_target_info_value" | localize }} {{ notification.notificationData.item.targetInfo.target | metricValue: notification.notificationData.item.targetInfo.format
          }}<span *ngIf="notification.notificationData.item.targetInfo.critical"
            >{{ "and_absolutely_must_be_below_critical_value" | localize }}
            {{ notification.notificationData.item.targetInfo.critical | metricValue: notification.notificationData.item.targetInfo.format }}</span
          ></span
        >
      </div>
      <div class="inbox-section">
        <div class="heading inline-notifications">
          {{ "current_progress" | localize }}
        </div>
        : {{ notification.notificationData.item.attainment | number: "1.0-0" }}%
      </div>
      <div class="inbox-section" *ngIf="notification.notificationData.item.dueDate">
        <div class="heading inline-notifications">
          {{ "hard_deadline" | localize }}
        </div>
        : {{ notification.notificationData.item.dueDate | date: "dd MMM, yyyy" }}
      </div>
      <div class="inbox-section" *ngIf="notification.notificationData.item.softDueDate">
        <div class="heading inline-notifications">
          {{ "soft_deadline" | localize }}
        </div>
        : {{ notification.notificationData.item.softDueDate | date: "dd MMM, yyyy" }}
      </div>
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_objective" | localize }}</a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'objective_metric_added'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">{{ title }}</div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiType]="notification.targetType" [uiTooltipTitle]="notification.targetType | localize" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.name }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_key_result" | localize }}</a>
      </div>
    </div>
  </div>
</div>
