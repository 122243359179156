import { module } from "angular";
import teamsCore from "@gtmhub/teams/teams-core.module";
import { CreateOkrFormModalService } from "@webapp/okrs/components/create-okr-form/create-okr-form-modal.service";
import { ItemNameWithIconComponent } from "@webapp/okrs/components/item-name-with-icon/item-name-with-icon.component";
import { OkrStatusButtonComponent } from "@webapp/okrs/components/okr-status/okr-status.component";
import { CreateMetricModalService } from "@webapp/okrs/metrics/services/create-metric-modal.service";
import { UpdateMetricModalService } from "@webapp/okrs/metrics/services/update-metric-modal.service";
import goalsCore from "../goals/goals-core.module";
import sessionsCore from "../sessions/sessions-core.module";
import tasksCore from "../tasks/tasks-core.module";
import metricsCore from "./metrics/metrics-core.module";

const mod = module("okrsCore", [sessionsCore, goalsCore, tasksCore, metricsCore, teamsCore]);

mod.downgradeComponent("itemNameWithIcon", { component: ItemNameWithIconComponent, propagateDigest: false });
mod.downgradeComponent("okrStatusButton", { component: OkrStatusButtonComponent, propagateDigest: false });
mod.downgradeInjectable("UpdateMetricModalService", UpdateMetricModalService);
mod.downgradeInjectable("CreateMetricModalService", CreateMetricModalService);
mod.downgradeInjectable("CreateOkrFormModalService", CreateOkrFormModalService);

export default mod.name;
