<div
  class="content-wrapper"
  #contextualSuggestionBtn
  [rootMarginData]="{ mb: -approximateOnboardingBoxHeight }"
  [threshold]="1"
  (visibilityChange)="onContextualButtonViewportVisibilityChange($event)"
  observeUntil="visible"
  isInViewport>
  <i
    class="contextual-icon"
    [class.contextual-icon-default]="!isLoading && !showDummyLoading"
    [class.contextual-icon-loading]="isLoading || showDummyLoading"
    [class.onboarding-visible]="onboarding && onboarding.shouldShow"
    [uiTooltipTitle]="'show_contextual_suggestion' | localize"
    [uiType]="isLoading || showDummyLoading ? 'artificial-intelligence-animated' : 'artificial-intelligence'"
    (click)="toggle.emit()"
    ui-tooltip
    ui-icon>
  </i>

  <onboarding
    *ngIf="onboarding && onboarding.shouldShow"
    [delegate]="onboarding.delegate"
    [steps]="onboarding.totalSteps"
    [numericSteps]="true"
    [startFromStep]="onboarding.startingStep"
    [section]="parentType === 'goal' ? 'piGoalContextualSuggestions' : 'piMetricContextualSuggestions'"
    [rootMarginData]="{ mb: -approximateOnboardingBoxHeight }"
    [threshold]="0"
    (visibilityChange)="ensureOnboardingIsInViewport({ isVisible: $event, contextualSuggestionBtn })"
    observeUntil="firstValue"
    isInViewport></onboarding>
</div>
