import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UiI18nModule } from "@quantive/ui-kit/i18n";
import { CandyDate } from "ng-zorro-antd/core/time";
import { FunctionProp } from "ng-zorro-antd/core/types";
import { ɵInnerPopupComponent as InnerPopupComponent } from "ng-zorro-antd/date-picker";
import { NzCalendarI18nInterface } from "ng-zorro-antd/i18n";
import { v4 as uuidv4 } from "uuid";
import { UiDateMode, UiDisabledDateFn, UiRangePartType, UiSupportTimeOptions } from "@webapp/ui/date-picker/date-picker.models";
import { UiTimePickerModule } from "@webapp/ui/time-picker/time-picker.module";
import { UiLibPackerModule } from "../../lib-packer.module";
import { UiDatePickerService } from "../../services/date-picker.service";

const dateModeToI18nPathMap: Record<UiDateMode, string> = {
  date: "DatePicker.lang.dateSelect",
  decade: "DatePicker.lang.decadeSelect",
  month: "DatePicker.lang.monthSelect",
  time: "DatePicker.lang.timeSelect",
  week: "DatePicker.lang.weekSelect",
  year: "DatePicker.lang.yearSelect",
  // to do: fix when working on https://quantive-inc.atlassian.net/browse/GVS-57874
  quarter: "Choose quarter",
};

@Component({
  selector: "ui-inner-popup",
  exportAs: "uiInnerPopup",
  // to do: changed template and new quarter component - https://quantive-inc.atlassian.net/browse/GVS-57874
  templateUrl: "inner-popup.component.html",
  styleUrls: ["inner-popup.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "ui-inner-popup",
  },
  standalone: true,
  imports: [UiLibPackerModule, UiTimePickerModule, FormsModule, NgSwitch, NgSwitchCase, NgSwitchDefault, UiI18nModule, NgIf],
})
export class UiInnerPopupComponent extends InnerPopupComponent {
  @Input("uiActiveDate")
  public activeDate!: CandyDate;
  @Input("uiEndPanelMode")
  public endPanelMode!: UiDateMode;
  @Input("uiPanelMode")
  public panelMode!: UiDateMode;
  @Input("uiShowWeek")
  public showWeek!: boolean;
  @Input("uiLocale")
  public locale!: NzCalendarI18nInterface;
  @Input("uiShowTimePicker")
  public showTimePicker!: boolean;
  @Input("uiTimeOptions")
  public timeOptions!: UiSupportTimeOptions | null;
  @Input("uiDisabledDate")
  public disabledDate?: UiDisabledDateFn;
  @Input("uiDateRender")
  public dateRender?: string | TemplateRef<Date> | FunctionProp<TemplateRef<Date> | string>;
  @Input("uiSelectedValue")
  public selectedValue!: CandyDate[]; // Range ONLY
  @Input("uiHoverValue")
  public hoverValue!: CandyDate[]; // Range ONLY
  @Input("uiValue")
  public value!: CandyDate;
  @Input("uiPartType")
  public partType!: UiRangePartType;

  @Output("uiPanelModeChange")
  public readonly panelModeChange = new EventEmitter<UiDateMode>();
  // TODO: name is not proper
  @Output("uiHeaderChange")
  public readonly headerChange = new EventEmitter<CandyDate>(); // Emitted when user changed the header's value
  @Output("uiSelectDate")
  public readonly selectDate = new EventEmitter<CandyDate>(); // Emitted when the date is selected by click the date panel
  @Output("uiSelectTime")
  public readonly selectTime = new EventEmitter<CandyDate>();
  @Output("uiCellHover")
  public readonly cellHover = new EventEmitter<CandyDate>(); // Emitted when hover on a day by mouse enter

  /** the id of the element holding month and year buttons and used to label the table of dates/months/years/decades */
  public headerLabelId: string = uuidv4();

  constructor(
    public datePickerService: UiDatePickerService,
    public elementRef: ElementRef
  ) {
    super();
  }

  get ariaLabelPath(): string {
    return dateModeToI18nPathMap[this.panelMode];
  }
}
