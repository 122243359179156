<div class="insight-redirect-container">
  <div class="text">{{ "where_do_you_want_to_go_next" | localize }}</div>
  <ul class="options">
    <li class="option" (click)="goToInsightsEditor($event)" data-test-id="go-to-insights-editor">
      <div class="action">
        {{ "go_to_insight_editor" | localize }}<span> <i ui-icon uiType="chevron-right-big"></i></span>
      </div>
    </li>
  </ul>
  <div class="title" *ngIf="modalData.boardUsage.length">{{ "insightboards_where_available" | localize }}</div>
  <ul class="options">
    <li class="option" *ngFor="let board of modalData.boardUsage; trackBy: trackById" (click)="goToBoard(board, $event)" data-test-id="go-to-board">
      <i class="fs-16px sp-mr-11" [uiTooltipTitle]="'insightboard' | localize" ui-icon ui-tooltip uiTheme="fill" uiType="insightboard"></i>
      <div class="action">
        {{ board.name }}<span> <i ui-icon uiType="chevron-right-big"></i></span>
      </div>
    </li>
  </ul>
</div>
