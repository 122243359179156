import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { UiTSType } from "@quantive/ui-kit/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { NzTextCopyComponent } from "ng-zorro-antd/typography";

@Component({
  selector: "ui-text-copy",
  exportAs: "uiTextCopy",
  templateUrl: "text-copy.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: false,
  standalone: true,
  imports: [UiTooltipModule, UiIconModule],
})
export class UiTextCopyComponent extends NzTextCopyComponent {
  @Input("text") public text!: string;
  @Input("tooltips") public tooltips?: [UiTSType, UiTSType] | null;
  @Input("icons") public icons: [UiTSType, UiTSType] = ["copy", "check"];

  @Output("textCopy") public readonly textCopy = new EventEmitter<string>();
}
