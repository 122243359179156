<p [innerHTML]="description"></p>
<p id="to_delete_item">{{ toDeleteItemText }}</p>
<p class="item-name">{{ itemName }}</p>
<p>
  <ui-input-text-field
    [focusMe]="true"
    [a11yRequired]="true"
    [debounceTime]="200"
    [uiPlaceholder]="inputPlaceholder"
    (valueChange)="changeEnteredItemName($event)"
    a11yLabelledby="to_delete_item"
    updateOn="change" />
</p>
