import { StateService } from "@uirouter/angular";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { Observable, first } from "rxjs";
import { TracingService } from "@webapp/core/tracing/services/tracing.service";
import { InsightsFacade } from "@webapp/data-story/services/insights-facade/insights-facade.service";
import { FeatureTogglesModule } from "@webapp/feature-toggles/feature-toggles.module";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { InsightRedirectComponent, InsightRedirectComponentBindings } from "@webapp/search/components/insight-redirect/insight-redirect.component";
import { SearchItemComponent } from "@webapp/search/components/search-item/search-item.component";
import {
  FocusParams,
  GlobalSearchItem,
  GlobalSearchResult,
  ITEMS_LIMIT_IF_EVERYWHERE,
  ITEMS_LIMIT_IF_NOT_EVERYWHERE,
  NextPrevItem,
  RecentFacetLocalisationMap,
  RecentSearchFacetsOptions,
  SearchCollection,
  SearchFacetsOptions,
  SearchFacetsOptionsEnum,
  SearchItemsLimit,
  SearchMode,
} from "@webapp/search/models/search.models";
import { GlobalSearchTrackingService } from "@webapp/search/services/global-search-tracking.service";
import { GlobalSearchMediatorService } from "@webapp/search/services/global-search.mediator.service";
import { getItemReturnState, getSearchFacetsOptionsLocalizationMap, moveFocusTo } from "@webapp/search/utils/global-search.utils";
import { SortByPipe } from "@webapp/shared/pipes/sort-by/sort-by.pipe";
import { UiDividerModule } from "@webapp/ui/divider/divider.module";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";

@UntilDestroy()
@Component({
  selector: "search-result-items",
  templateUrl: "./search-result-items.component.html",
  styleUrls: ["./search-result-items.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, LocalizationModule, UiDividerModule, UiButtonModule, SearchItemComponent, SortByPipe, FeatureTogglesModule],
})
export class SearchResultItemsComponent implements OnInit {
  @Input()
  public searchMode: SearchMode;

  @Input()
  public maxItems: SearchItemsLimit;
  public maxItemsPerGroup: number;

  /*
   * Custom handler for item click
   * */
  @Input()
  public isCustomClickHandlerAvailable: boolean;

  protected currentFacet: SearchFacetsOptions;
  protected searchResults: GlobalSearchResult[];
  public itemsLimit$: Observable<SearchItemsLimit> = this.globalSearchMediatorService.itemsLimit$;

  protected isRecent: boolean = false;
  private readonly searchFacetsOptionsLocalization: RecentFacetLocalisationMap = getSearchFacetsOptionsLocalizationMap();

  constructor(
    private globalSearchMediatorService: GlobalSearchMediatorService,
    private stateService: StateService,
    private modalService: UiModalService,
    private tracingService: TracingService,
    private globalSearchTrackingService: GlobalSearchTrackingService,
    private cdr: ChangeDetectorRef,
    private insightsFacade: InsightsFacade
  ) {}

  public ngOnInit(): void {
    this.globalSearchMediatorService.searchTerm$.pipe(untilDestroyed(this)).subscribe((searchTerm) => {
      this.isRecent = !searchTerm;
    });

    this.globalSearchMediatorService.currentFacet$.pipe(untilDestroyed(this)).subscribe((currentFacet) => {
      this.currentFacet = currentFacet;
    });

    this.globalSearchMediatorService.searchResults$.pipe(untilDestroyed(this)).subscribe((searchResults) => {
      this.searchResults = searchResults;
      this.maxItemsPerGroup = this.getMaxItemsPerGroup(searchResults);
      this.cdr.markForCheck();
    });
  }

  public changeFacetToEverywhere(): void {
    this.globalSearchMediatorService.setCurrentFacet(SearchFacetsOptionsEnum.Everywhere);
  }

  public getTotalCount(totalCount: number): number {
    let result = totalCount;
    if (this.isRecent) {
      // Recent items
      result = Math.min(totalCount, ITEMS_LIMIT_IF_NOT_EVERYWHERE);
    }
    return result - ITEMS_LIMIT_IF_EVERYWHERE;
  }

  protected viewAllResults(
    resultCount: number,
    additionalInfo: {
      collectionName: RecentSearchFacetsOptions;
      isSearchPage: boolean;
    }
  ): void {
    const { collectionName, isSearchPage } = additionalInfo;

    if (isSearchPage) {
      this.globalSearchMediatorService.setCurrentFacet(collectionName);
    } else {
      this.globalSearchMediatorService.searchTerm$.pipe(first()).subscribe((searchTerm) => {
        this.stateService.go("gtmhub.globalSearch", { facet: this.currentFacet, searchTerm });
      });
    }

    this.globalSearchTrackingService.trackSearch("Additional Results Shown", this.currentFacet, {
      location: "Global Search View All Results",
      result_count: resultCount,
    });
  }

  protected viewMoreResults(collectionName: RecentSearchFacetsOptions, resultCount: number): void {
    if (this.isRecent) {
      this.globalSearchTrackingService.trackRecent("Additional Results Shown", this.currentFacet, {
        collectionName,
        location: "Global Search View More Recent Items",
        result_count: resultCount,
      });
      this.tracingService.traceAction("gs__view_more_recent_results", () => {});
    } else {
      this.globalSearchTrackingService.trackSearch("Additional Results Shown", this.currentFacet, {
        collectionName,
        location: "Global Search View More Results",
        result_count: resultCount,
      });
      this.tracingService.traceAction("gs__view_more_results", () => {});
    }
    this.globalSearchMediatorService.setCurrentFacet(collectionName);
  }

  public getLocalization(collectionName: RecentSearchFacetsOptions): string {
    return this.searchFacetsOptionsLocalization[collectionName];
  }

  public onBtnFocusout(evt: FocusEvent): void {
    if (!evt.relatedTarget) {
      this.globalSearchMediatorService.hideResults();
    }
  }

  public onItemFocusout(evt: FocusEvent, param: { last: boolean; first: boolean }): void {
    const actionButtons = [...document.querySelectorAll("#result-actions > button")];
    // Prevent focusing search-input when
    if (actionButtons.find((b) => b === evt.relatedTarget)) return;

    if (param.last) {
      this.onBtnFocusout(evt);
    }
    if (param.first && (evt.relatedTarget as HTMLElement)?.children[0]?.localName !== "search-item") {
      document.getElementById("search-input").focus();
    }
  }

  public onSearchItemClick(item: GlobalSearchItem<SearchCollection>): void {
    if (this.isCustomClickHandlerAvailable) {
      this.globalSearchMediatorService.onItemClicked(item);
      return;
    }

    this.globalSearchMediatorService.addItemToRecentItems(item.id, item.collectionName);

    if (this.isRecent) {
      this.globalSearchTrackingService.trackRecent("Recent Item Clicked", this.currentFacet);
    } else {
      this.globalSearchTrackingService.trackSearch("Item clicked", this.currentFacet);
    }
    this.navigateToItem(item);
  }

  public moveFocusTo(to: NextPrevItem): void {
    const params: FocusParams = {
      siblingSelectors: "#result-actions > button, li.section-list.item-row",
      parentElementId: "search-input",
      to,
    };
    moveFocusTo(params);
  }

  private navigateToItem(item: GlobalSearchItem<SearchCollection>): void {
    if (item.collectionName === "insights") {
      this.openModal(item);
    } else {
      const itemReturnState = getItemReturnState(item.collectionName, item);
      this.stateService.go(itemReturnState.state, itemReturnState.stateParams);
      this.globalSearchMediatorService.hideResults();
    }
  }

  private openModal(item: GlobalSearchItem<SearchCollection>): void {
    this.insightsFacade
      .getInsightSafe$(item.fields["name"])
      .pipe(untilDestroyed(this))
      .subscribe((insight) => {
        this.insightsFacade
          .getInsightUsages$(insight.id, "boards")
          .pipe(untilDestroyed(this))
          .subscribe((boardUsage) => {
            this.modalService.create<InsightRedirectComponent, InsightRedirectComponentBindings>({
              uiContent: InsightRedirectComponent,
              uiData: { insight, boardUsage },
              uiNoScrollableContent: true,
              uiFooter: null,
              uiClosable: false,
              uiWidth: "fit-content",
              uiMaskClosable: true,
            });
          });
      });
  }

  private getMaxItemsPerGroup(searchResults: GlobalSearchResult[]): number {
    if (this.currentFacet === SearchFacetsOptionsEnum.Everywhere) {
      return ITEMS_LIMIT_IF_NOT_EVERYWHERE;
    }

    return this.maxItems ? this.maxItems / searchResults?.filter((collection) => collection.totalCount).length : ITEMS_LIMIT_IF_NOT_EVERYWHERE;
  }
}
