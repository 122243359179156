interface IQueryStorageKeyMapping {
  queryKey: string;
  storageKey: string;
}

export function persistQueryStringParametersInStorage(...args: (string | IQueryStorageKeyMapping)[]): void {
  // check for query string in the hash or regular query string
  // /some-path/#something?key=value
  // /some-path?key=value
  const { hash, search } = window.location;
  const query = hash.indexOf("?") >= 0 ? hash.substring(hash.indexOf("?")) : search;
  const searchParams = new URLSearchParams(query);

  for (const arg of args) {
    let mapping: IQueryStorageKeyMapping;
    if (typeof arg === "string") {
      mapping = { queryKey: arg, storageKey: arg };
    } else {
      mapping = arg as IQueryStorageKeyMapping;
    }

    const value = searchParams.get(mapping.queryKey);
    if (value) {
      localStorage.setItem(mapping.storageKey, value);
    }
  }
}
