<div class="icon-container item-icon icon-container--next" [ngSwitch]="true">
  <div *ngSwitchCase="itemsType === 'people'">
    <ui-avatar *ngIf="!avatarIsIcon()" [uiPicture]="item.icon"></ui-avatar>
    <ui-avatar *ngIf="avatarIsIcon()" [uiBgColor]="item.color" [uiIcon]="item.icon" uiTheme="outline" uiSize="xs"></ui-avatar>
  </div>
  <div class="gh-icon gh-icon-goal tiny" *ngSwitchCase="itemsType === 'okrs'"></div>
  <div class="gh-icon gh-icon-kpi tiny" *ngSwitchCase="itemsType === 'kpis'"></div>
  <div class="gh-icon gh-icon-whiteboard tiny" *ngSwitchCase="itemsType === 'whiteboards'"></div>
  <div class="gh-icon gh-icon-insightboard tiny" *ngSwitchCase="itemsType === 'insightboards'"></div>
  <div class="gh-icon gh-icon-list tiny" *ngSwitchCase="itemsType === 'tasks'"></div>
  <div class="gh-icon gh-icon-okr-view tiny" *ngSwitchCase="itemsType === 'sessions' && item.uxcType === 'filter'"></div>
  <div class="gh-icon gh-icon-report tiny" *ngSwitchCase="itemsType === 'lists'"></div>
  <div class="icon-container__circle icon-container__circle--next" *ngSwitchDefault [ngStyle]="{ 'background-color': item.color }"></div>
</div>
