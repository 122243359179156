import { getLanguageFromLocalStorage } from "@gtmhub/localization";
import { locale } from "@gtmhub/localization/locale/en-us";
import { LocaleBundle } from "../models/localization.models";

export const resolveLocales = (): [string, () => Promise<LocaleBundle>] => {
  const language = getLanguageFromLocalStorage();
  switch (language) {
    case "french":
      return ["fr", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/fr" */ "@gtmhub/localization/locale/fr").then(({ locale }) => locale)];
    case "portuguese":
      return ["pt", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/pt" */ "@gtmhub/localization/locale/pt").then(({ locale }) => locale)];
    case "spanish":
      return ["es", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/es" */ "@gtmhub/localization/locale/es").then(({ locale }) => locale)];
    case "german":
      return ["de", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/de" */ "@gtmhub/localization/locale/de").then(({ locale }) => locale)];
    case "italian":
      return ["it", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/it" */ "@gtmhub/localization/locale/it").then(({ locale }) => locale)];
    case "chinese":
      return ["zh-cn", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/zh-cn" */ "@gtmhub/localization/locale/zh-cn").then(({ locale }) => locale)];
    case "bulgarian":
      return ["bg", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/bg" */ "@gtmhub/localization/locale/bg").then(({ locale }) => locale)];
    case "arabic":
      return ["ar", (): Promise<LocaleBundle> => import(/* webpackChunkName: "locale/ar" */ "@gtmhub/localization/locale/ar").then(({ locale }) => locale)];
    case "english":
      // English is included by default
      break;
    default:
      console.warn(`Unknown language: '${language}'. Defaulting to English.`);
      break;
  }

  return ["en", (): Promise<LocaleBundle> => Promise.resolve(locale)];
};
