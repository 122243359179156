<div class="onboarding" *ngIf="shouldSeeOnboarding" [ngClass]="showTour ? 'step-' + step : ''" [ngStyle]="customStyleAppliedToStep === step ? customStyle : null">
  <div class="guide" ui-row>
    <div *ngIf="!showTour" ui-col>
      <div ui-row>
        <div class="title sp-mb-12" ui-col uiFlex="1">{{ popoverTitle | localize }}</div>
        <i class="fs-16px" (click)="close()" ui-icon ui-col uiType="close"></i>
      </div>

      <div class="description" [innerHTML]="description | localize"></div>

      <div>
        <div class="bottom" ui-row uiAlign="middle" uiJustify="space-between">
          <button class="outlined-button" [tabindex]="-1" (click)="close()" ui-button uiShape="round" uiType="primary">{{ "not_now" | localize }}</button>
          <button class="filled-button" [tabindex]="-1" (click)="takeTheTour()" ui-button uiShape="round">{{ "take_the_tour" | localize }}</button>
        </div>
      </div>
    </div>

    <div *ngIf="showTour" ui-col>
      <div ui-row>
        <div class="title sp-mb-12" ui-col uiFlex="1">{{ contentHeading }}</div>
        <i class="fs-16px" (click)="close()" ui-icon ui-col uiType="close"></i>
      </div>

      <div class="description" [innerHTML]="contentDescription"></div>

      <div>
        <div class="bottom" ui-row uiAlign="middle">
          <div class="onboarding-primary" ui-col uiFlex="3">
            <ng-container *ngIf="!hidePrimaryButton">
              <ng-container *ngIf="!primaryCTAHandler">
                <button class="outlined-button" *ngIf="step === 1" [tabindex]="-1" (click)="close()" ui-button uiShape="round" uiType="primary">{{ "close" | localize }}</button>
                <button class="outlined-button" *ngIf="step !== 1" [tabindex]="-1" (click)="back()" ui-button uiShape="round" uiType="primary">{{ "back" | localize }}</button>
              </ng-container>
              <ng-container *ngIf="primaryCTAHandler">
                <button class="outlined-button" [tabindex]="-1" (click)="primaryCTAHandler()" ui-button uiShape="round" uiType="primary">{{ primaryCTA }}</button>
              </ng-container>
            </ng-container>
          </div>

          <div class="onboarding-steps" ui-col uiFlex="2">
            <ng-container *ngIf="!hideSteps">
              <div class="steps" *ngIf="steps < maxStepsDots && !numericSteps" ui-row>
                <div class="step" *ngFor="let st of range" [ngClass]="{ active: st === step }" ui-col></div>
              </div>

              <div class="steps-preview" *ngIf="steps >= maxStepsDots || numericSteps" ui-row>
                {{ (previewOverrides?.stepsMap && previewOverrides?.stepsMap[step]) || step }}/{{ previewOverrides?.totalSteps || steps }}
              </div>
            </ng-container>
          </div>

          <div class="onboarding-secondary" ui-col uiFlex="3">
            <ng-container *ngIf="!hideSecondaryButton">
              <ng-container *ngIf="!secondaryCTAHandler">
                <button class="filled-button" *ngIf="step !== range.length" [tabindex]="-1" (click)="next()" ui-button uiShape="round">{{ "next" | localize }}</button>
                <button class="filled-button" *ngIf="step === range.length" [tabindex]="-1" (click)="gotIt()" ui-button uiShape="round">{{ "got_it" | localize }}</button>
              </ng-container>
              <ng-container *ngIf="secondaryCTAHandler">
                <button class="filled-button" [tabindex]="-1" (click)="secondaryCTAHandler()" ui-button uiShape="round">{{ secondaryCTA }}</button>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
