import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { ErrorViewComponent } from "./error-view.component";

@NgModule({
  imports: [CommonModule, LocalizationModule, UiIconModule, UiButtonModule],
  declarations: [ErrorViewComponent],
  exports: [ErrorViewComponent],
})
export class ErrorViewModule {}
