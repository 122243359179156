import { IWindowService } from "angular";
import { StateService } from "@uirouter/angularjs";
import { ITeam } from "@gtmhub/teams";
import { IUser } from "@gtmhub/users";
import { getRandomColor } from "@gtmhub/util/color";
import { TeamAssignee, UserAssignee } from "@webapp/assignees/models/assignee.models";
import { ISearchField } from "@webapp/search/models/search-api.models";
import { ItemAction, ItemActionHandler, ItemActionHref, ItemActionState } from "../components/ui/models";
import { Intercom } from "../intercom";

export function isIEBrowser(): boolean {
  return navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident") > -1; // only IE property
}

export function isNumberRounded(number: number): boolean {
  return number % 1 === 0;
}

export function toFixedWithoutRounding(number: number | string, size = 3): number {
  const regex = new RegExp("^-?\\d+(?:\\.\\d{0," + size + "})?", "g");
  const stringifiedNum = number.toString().match(regex)[0];
  const dot = stringifiedNum.indexOf(".");
  if (dot === -1) {
    return +(stringifiedNum + "." + "0".repeat(size));
  }
  const num = size - (stringifiedNum.length - dot) + 1;
  return num > 0 ? +(stringifiedNum + "0".repeat(num)) : +stringifiedNum;
}

export function actionHandlerGenerator(action: ItemAction, dependancies: { $state: StateService; $window: IWindowService }): () => unknown {
  if (!action) {
    return angular.noop;
  }

  const { handler } = action as ItemActionHandler;
  if (handler) {
    return handler;
  }

  const { href, target } = action as ItemActionHref;
  if (href) {
    if (target) {
      return () => dependancies.$window.open(href, target);
    }

    return () => (dependancies.$window.location.href = href);
  }

  const { state, params, options } = action as ItemActionState;
  if (state) {
    return () => dependancies.$state.go(state, params, options);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toCsv = <Row extends Record<string, any>>(rows: Row[], header?: (keyof Row)[]): string => {
  if (!header && !rows.length) {
    return "";
  }

  if (!header) {
    header = Object.keys(rows[0]);
  }

  const jsonReplacer = (prop: string, value: string) => (typeof value === "undefined" || value == null ? "N/A" : value);
  return [header.join(","), ...rows.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], jsonReplacer)).join(","))].join("\r\n");
};

export function addItemAndReturnCollection<T>(items: Array<T>, item: T): T[] {
  items.push(item);
  return items;
}

export function utf8StringSize(str: string): number {
  return new Blob([str]).size;
}

export function utf8StringTruncateToSize(str: string, size: number): string {
  if (utf8StringSize(str) <= size) {
    return str;
  }

  let result = "";
  let resultSize = 0;
  const symbols = [...str];
  for (const symbol of symbols) {
    resultSize += utf8StringSize(symbol);
    if (resultSize > size) {
      break;
    }
    result += symbol;
  }
  return result;
}

/** copied from smartbanner.js user-agent-detector https://github.com/ain/smartbanner.js/blob/main/src/detector.js */
export function isMobile(): boolean {
  const maxTouchPoints = window.navigator.maxTouchPoints;
  const userAgent = window.navigator.userAgent;
  const isAndroid = /Android/i.test(userAgent);
  const isDesktop = /Windows NT|Intel Mac OS X/i.test(userAgent);

  // maxTouchPoints is the only effective method to detect iPad iOS 13+
  const isIos = (maxTouchPoints && maxTouchPoints > 0) || /iPhone|iPad|iPod/i.test(userAgent);

  if (isDesktop) {
    return false;
  }

  if (isAndroid || isIos) {
    return true;
  }

  return false;
}

export function generateSearchFields(searchFieldsNames: string[]): ISearchField[] {
  return searchFieldsNames.map((name) => ({ name: name }));
}

export function userToAssignee(user: IUser): UserAssignee {
  return {
    picture: user.picture,
    email: user.email || "",
    id: user.id,
    name: user.name,
    type: "user",
  };
}

export function teamToAssignee(team: ITeam): TeamAssignee {
  return {
    picture: team.picture,
    avatar: team.avatar,
    color: team.color || getRandomColor(),
    id: team.id,
    name: team.name,
    type: "team",
  };
}

export const isSafari = (): boolean => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isFirefox = (): boolean => navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

export const toggleIntercom = (opts: { hideLauncher: boolean }): void => {
  if (!Intercom) {
    return;
  }

  Intercom("update", {
    hide_default_launcher: opts.hideLauncher,
  });
};
