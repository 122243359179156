import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { APP_CONFIG, IAppConfig } from "@gtmhub/env";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiServiceV2 } from "@webapp/core/api/base-api-v2.service";
import { AiRecommendation } from "@webapp/notifications/models/ai-recommendations.models";

@Injectable({
  providedIn: "root",
})
export class AiRecommendationsApiService extends BaseApiServiceV2<AiRecommendation> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig) {
    super("assistant/suggestions", { ...new ApiVersionModel("v1") }, httpClient, appConfig);
  }
}
