import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { Parameter, ParameterDTO } from "@webapp/data-story/models/parameters-facade.models";
import { DataSourcesState } from "@webapp/data-story/services/data-sources-facade/data-sources-state.service";

@Injectable({
  providedIn: "root",
})
export class ParametersApiService extends BaseApiService<Parameter, ParameterDTO, DataSourcesState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: DataSourcesState) {
    super("dashboards/parameters", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getParametersEndpoint(): string {
    return `${this.getBasePath(HttpActions.get)}`;
  }
}
