import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject, skip } from "rxjs";
import { ParentType } from "@gtmhub/automation/controllers/action";
import { QunativePlusExplanation } from "@webapp/platform-intelligence/expandable-suggestions/shared/models/quantive-plus-explanation.models";
import { SuggestionDrawerSectionType } from "@webapp/platform-intelligence/expandable-suggestions/suggestion-drawer/shared/models/suggestion-drawer.models";
import { SuggestionDrawerOverviewTabStatePropagator } from "@webapp/platform-intelligence/expandable-suggestions/suggestion-drawer/shared/utils/state-propagator";
import { PiStateProcessorInstance } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.models";
import { PiStateProcessorService } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.service";
import { PiTrackingFlowNameEnum } from "@webapp/platform-intelligence/shared/utils/pi-tracking";

@UntilDestroy()
@Component({
  selector: "suggestion-drawer-overview-tab-v2[parentType][parent]",
  templateUrl: "./overview-tab-v2.component.html",
  styleUrls: ["./overview-tab-v2.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionDrawerOverviewTabV2Component implements OnChanges, OnInit, OnDestroy {
  @Input() public statePropagator: SuggestionDrawerOverviewTabStatePropagator;
  @Input() public explanationSection: SuggestionDrawerSectionType<{ flowId: string; explanation: QunativePlusExplanation; refreshExplanation?(): void }>;
  @Input() public whyMatterSectionTitle: string;
  @Input() public parentType: ParentType;
  @Input() public parent;

  @Output() public readonly retry = new EventEmitter<void>();

  public summaryInstance: PiStateProcessorInstance;
  public whyMatterInstance: PiStateProcessorInstance;
  public keyTermsInstance: PiStateProcessorInstance;
  public onDestroy$ = new Subject<void>();

  constructor(private piStateProcessorService: PiStateProcessorService) {}

  public ngOnInit(): void {
    const instanceProps = {
      type: this.parentType,
      isInline: false,
      skipFeedbackReplacement: true,
      parentId: this.parent.id,
      parentType: this.parentType,
      destroyOn$: this.onDestroy$,
    };

    this.summaryInstance = this.piStateProcessorService.createNewInstance({ ...instanceProps, subType: "summary" });
    this.whyMatterInstance = this.piStateProcessorService.createNewInstance({ ...instanceProps, subType: "whyMatter" });
    this.keyTermsInstance = this.piStateProcessorService.createNewInstance({ ...instanceProps, subType: "keyTerms" });

    this.subscribeToInstanceUpdates();
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  private subscribeToInstanceUpdates(): void {
    const instances = [this.summaryInstance, this.whyMatterInstance, this.keyTermsInstance];
    instances.forEach((instance) => {
      instance.feedbackSubject.pipe(skip(1), untilDestroyed(this)).subscribe({
        next: (data) => {
          if (data.feedbackType === "negative") {
            this.explanationSection.refreshExplanation();
          }
        },
      });
    });
  }

  public ngOnChanges(): void {
    if (this.statePropagator && this.explanationSection) {
      const isLoading = this.explanationSection.isLoading;

      this.statePropagator.emitStateChange({ isLoading });
    }

    if (this.explanationSection && this.explanationSection.flowId) {
      const instances = [this.summaryInstance, this.whyMatterInstance, this.keyTermsInstance];

      instances.forEach((instance) =>
        this.piStateProcessorService.assigneInstanceFlowProperties(instance.id, {
          flowId: this.explanationSection.flowId,
          flowName:
            this.parentType === "goal" ? PiTrackingFlowNameEnum.ObjectiveDetailsViewOverviewSidePanel : PiTrackingFlowNameEnum.KeyResultDetailsViewOverviewSidePanel,
        })
      );
    }
  }

  public handleRetry(): void {
    this.retry.emit();
  }
}
