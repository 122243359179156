import { StateDeclaration } from "@uirouter/angular";
import { Injectable } from "@angular/core";
import { IUserMetadata } from "@gtmhub/login/models";
import { IUser } from "@gtmhub/users";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { StorageService } from "@webapp/core/storage/services/storage.service";
import { IProfile } from "../models/user-profile.models";

@Injectable({ providedIn: "root" })
export class UserProfileService {
  constructor(
    private storageService: StorageService,
    private broadcastService: BroadcastService
  ) {}

  public getProfile(): IProfile {
    return this.storageService.get<IProfile>("profile");
  }

  public setProfile(profile: IProfile, options: { notify?: boolean } = {}): void {
    this.storageService.set("profile", profile);

    if (options.notify) {
      this.broadcastService.emit("userProfileUpdated", profile);
    }
  }

  public clear(): void {
    this.storageService.remove("profile");
  }

  public setProfileFromUser(user: IUser): void {
    const profile: IProfile = {
      email: user.email,
      isSSOBased: user.isSSOBased,
      isAzureIDPBased: user.isAzureIDPBased,
      identities: user.identities,
      firstName: user.firstName,
      lastName: user.lastName,
      avatar: user.avatar,
      picture: user.picture,
      created: user.dateCreated,
      language: user.language,
      isPrimary: user.isPrimary,
      pictureInfo: user.pictureInfo,
    };

    this.setProfile(profile);
  }

  public updateProfileNames(user: IUserMetadata): void {
    const profile = this.getProfile();

    if (user.firstName) {
      profile.firstName = user.firstName;
    }

    if (user.lastName) {
      profile.lastName = user.lastName;
    }

    if (user.picture) {
      profile.picture = user.picture;
    }

    this.setProfile(profile, { notify: true });
  }

  public demandsAreMet(toState: StateDeclaration): boolean {
    const isAccountRequired = toState?.data?.requiresAccount;
    const isAccountPresent = this.getProfile() != null;

    return !isAccountRequired || isAccountPresent;
  }
}
