import { Injectable } from "@angular/core";
import { marked } from "@webapp/shared/libs/marked";
import { EMOJI_PATTERN } from "@webapp/shared/rich-text-editor/marked-js/emoji-regex";
import { MarkedJsExtensionLevel, MarkedJsExtensionThis, MarkedJsToken } from "@webapp/shared/rich-text-editor/marked-js/marked-js.interfaces";
import { MENTION_PATTERN, OKR_LINK_SELECTOR_PATTERN } from "../rich-text-editor.utils";

@Injectable({
  providedIn: "root",
})
export class MarkedJsService {
  private hasLoadedExtensions = false;

  public loadCustomExtensionsForRichTextEditor(): void {
    if (!this.hasLoadedExtensions) {
      this.initMarkedExtensions();

      this.hasLoadedExtensions = true;
    }
  }

  private initMarkedExtensions(): void {
    const ghMentionName = "ghMention";
    const ghMentionRegexStart = new RegExp(MENTION_PATTERN, "u");
    const ghMentionRegex = new RegExp("^" + MENTION_PATTERN, "u");

    const ghMentionExtension = {
      name: ghMentionName,
      level: "inline" as MarkedJsExtensionLevel,
      start(src: string): number {
        return src.match(ghMentionRegexStart)?.index;
      },
      tokenizer(src: string): MarkedJsToken | void {
        const match = ghMentionRegex.exec(src);

        if (match) {
          return {
            type: ghMentionName,
            raw: match[0],
            text: match[0].trim(),
            tokens: [],
          };
        }
      },
      renderer({ raw }): string {
        const regex = new RegExp(MENTION_PATTERN, "g");
        const [, name, email, id] = regex.exec(raw);

        const node = document.createElement("span");
        node.classList.add("mention");
        node.setAttribute("data-test-id", "mention-in-rich-text-editor");
        node.setAttribute("data-id", id);
        node.setAttribute("data-name", name);
        node.setAttribute("data-email", email);

        const wrapper = document.createElement("span");
        wrapper.setAttribute("contenteditable", "false");
        node.appendChild(wrapper);

        const denotationChar = document.createElement("span");
        denotationChar.classList.add("ql-mention-denotation-char");
        denotationChar.textContent = "@";
        wrapper.appendChild(denotationChar);

        const nameEl = document.createElement("span");
        nameEl.textContent = name;
        wrapper.appendChild(nameEl);

        return node.outerHTML;
      },
    };

    // OKR/KPI link selector extension
    const ghOKRLinkRegexStart = new RegExp(OKR_LINK_SELECTOR_PATTERN, "u");
    const ghOKRLinkRegex = new RegExp("^" + OKR_LINK_SELECTOR_PATTERN, "u");

    const ghOKRLinkExtension = {
      name: "okrLink",
      level: "inline" as MarkedJsExtensionLevel,
      start(src: string): number {
        return src.match(ghOKRLinkRegexStart)?.index;
      },
      tokenizer(src: string): MarkedJsToken | void {
        const match = ghOKRLinkRegex.exec(src);

        if (match) {
          return {
            type: "okrLink",
            raw: match[0],
            text: match[0].trim(),
            tokens: [],
          };
        }
      },
      renderer({ raw }): string {
        const regex = new RegExp(OKR_LINK_SELECTOR_PATTERN, "g");
        const [, type, title, id] = regex.exec(raw);

        const node = document.createElement("span");
        node.classList.add("okr-link");
        node.setAttribute("data-test-id", "okr-link-selector-in-rich-text-editor");
        node.setAttribute("data-id", id);
        node.setAttribute("data-title", title);
        node.setAttribute("data-type", type);
        return node.outerHTML;
      },
    };

    // eslint-disable-next-line no-misleading-character-class
    const ghEmojiStartRegex = new RegExp(`(${EMOJI_PATTERN})`, "u");
    // eslint-disable-next-line no-misleading-character-class
    const ghEmojiRegex = new RegExp(`^(${EMOJI_PATTERN})`, "u");

    const ghEmojiName = "ghEmoji";

    const ghEmojiExtension = {
      name: ghEmojiName,
      level: "inline" as MarkedJsExtensionLevel,
      start(src: string): number {
        return src.match(ghEmojiStartRegex)?.index;
      },
      tokenizer(src: string): MarkedJsToken | void {
        const match = ghEmojiRegex.exec(src);

        if (match) {
          return {
            type: ghEmojiName,
            raw: match[0],
            text: match[0].trim(),
            tokens: [],
          };
        }
      },
      renderer({ raw }: MarkedJsToken): string {
        return `<span class="emoji">${raw}</span>`;
      },
    };

    const ghStrikeExtensionName = "strikethrough";
    const ghStrikeThroughStartRegex = /~~(.*?)~~/;
    const ghStrikeThroughRegex = /^~~(.*?)~~/;

    const ghStrikeThroughExtension = {
      name: ghStrikeExtensionName,
      level: "inline" as MarkedJsExtensionLevel,
      start(src: string): number {
        return src.match(ghStrikeThroughStartRegex)?.index;
      },
      tokenizer(this: MarkedJsExtensionThis, src: string): MarkedJsToken | void {
        const match = ghStrikeThroughRegex.exec(src);

        if (match) {
          const [, text] = match;

          const token = {
            type: ghStrikeExtensionName,
            raw: match[0],
            text,
            tokens: [],
          };
          this.lexer.inline(token.text, token.tokens);

          return token;
        }
      },
      renderer(this: MarkedJsExtensionThis, { tokens }: MarkedJsToken): string {
        return `<s>${this.parser.parseInline(tokens)}</s>`;
      },
    };

    marked.use({ extensions: [ghMentionExtension, ghEmojiExtension, ghStrikeThroughExtension, ghOKRLinkExtension] });
  }
}
