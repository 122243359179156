import { PopupOptions } from "@picmo/popup-picker";
import { PickerOptions } from "picmo";
import { appendStylesIfMissing, removeStylesIfPresent } from "@webapp/ui/utils/dom.utils";

export const defaultPickerOptions: Partial<PickerOptions> = {
  emojiSize: "32px",
  autoFocus: "auto",
  initialCategory: "recents",
};

export const defaultPopupOptions: Partial<PopupOptions> = {
  position: "auto",
  className: "picmo-emoji-picker-popup",
  showCloseButton: false,
};

const PICMO_STYLE_ELEMENT_ID = "picmo";

export function appendPicmoStylesIfMissing(styles: string): void {
  appendStylesIfMissing(styles, PICMO_STYLE_ELEMENT_ID);
}

export function removePicmoStylesIfPresent(): void {
  removeStylesIfPresent(PICMO_STYLE_ELEMENT_ID);
}

export function stopPropagationOnEscapeKeydown(): void {
  const popupElement: HTMLDivElement = document.querySelector(".picmo-emoji-picker-popup");
  if (popupElement) {
    popupElement.addEventListener("keydown", handleKeyDown);
  }
}

function handleKeyDown(event: KeyboardEvent): void {
  if (event.key === "Escape") {
    event.stopPropagation();
  }
}
