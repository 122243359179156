<ng-container>
  <ng-container *ngIf="item.isInfoTextOnly; else actionableItem">
    <p class="menu-item-info-text sp-p-8 sp-m-0" [attr.aria-describedby]="item.a11yDescription ? item.key + '-' + item.a11yDescription : null">
      <span>{{ item.key | localize }}</span>
    </p>
  </ng-container>

  <ng-template #actionableItem>
    <button
      class="menu-item-action sp-p-8"
      *ngIf="item.uiType === 'icon'"
      [class.with-gap]="withGap"
      [attr.aria-describedby]="item.a11yDescription ? item.key + '-' + item.a11yDescription : null"
      [attr.data-test-id]="'menu-item-action-' + item.key"
      [class.disabled]="item.disabled"
      (click)="invokeAction()">
      <span class="sp-mr-5" [ngClass]="getIconClass()"></span>
      <span>{{ item.key | localize }}</span>
    </button>

    <button
      class="menu-item-action sp-p-8"
      *ngIf="item.uiType === 'uiIcon' && !item.descriptionKey"
      [attr.aria-describedby]="item.a11yDescription ? item.key + '-' + item.a11yDescription : null"
      [attr.data-test-id]="'menu-item-action-' + item.key"
      [class.disabled]="item.disabled"
      (click)="invokeAction()">
      <i class="sp-mr-5" [class.icon]="showNewDesign" [uiType]="getIconType()" [uiTheme]="getIconTheme()" uiSize="sm" ui-icon></i>
      <span [class.selected]="item.selected">{{ item.key | localize }}</span>
      <i *ngIf="item.selected" [class.transparent]="showNewDesign" [uiSize]="getIconSize()" uiType="check" uiTheme="outline" ui-icon></i>
    </button>

    <ng-container *ngIf="showNewDesign; else oldDesign">
      <button
        class="menu-item-action-v2 sp-p-8 with-description-v2"
        *ngIf="item.uiType === 'uiIcon' && item.descriptionKey"
        [attr.data-test-id]="'menu-item-action-v2-' + item.key"
        [class.disabled]="item.disabled"
        [attr.aria-describedby]="item.a11yDescription ? item.key + '-' + item.a11yDescription : null"
        (click)="invokeAction()"
        ui-button
        uiSize="default"
        uiType="text">
        <span class="menu-item-action-v2__context">
          <span class="menu-item-action-v2__header">
            <i [uiType]="getIconType()" [uiTheme]="getIconTheme()" uiSize="sm" ui-icon></i>
            <span class="fs-14px menu-item-action-v2__title">{{ item.key | localize }}</span>
          </span>
          <span class="menu-item-action-v2__description fs-14px">{{ item.descriptionKey | localize }}</span>
        </span>
      </button>
    </ng-container>

    <ng-template #oldDesign>
      <button
        class="menu-item-action sp-p-8 with-description"
        *ngIf="item.uiType === 'uiIcon' && item.descriptionKey"
        [class.disabled]="item.disabled"
        [attr.data-test-id]="'menu-item-action-' + item.key"
        [attr.aria-describedby]="item.a11yDescription ? item.key + '-' + item.a11yDescription : null"
        (click)="invokeAction()">
        <i class="sp-mr-9 fs-32px" [uiType]="getIconType()" [uiTheme]="getIconTheme()" ui-icon uiSize="sm"></i>
        <div class="context" ui-col>
          <div class="headline">
            {{ item.key | localize }}
            <i *ngIf="item.externalLink" uiSize="xs" ui-icon uiType="external-link"></i>
          </div>
          <div class="description">{{ item.descriptionKey | localize }}</div>
        </div>
      </button>
    </ng-template>

    <button
      class="menu-item-action with-toggle sp-p-8"
      *ngIf="item.uiType === 'toggle'"
      [class.disabled]="item.disabled"
      [attr.aria-describedby]="item.a11yDescription ? item.key + '-' + item.a11yDescription : null">
      <span>{{ item.key | localize }}</span>
      <ui-toggle [value]="getToggleValue()" [uiType]="'toggle-text'" [attr.data-test-id]="'menu-item-action-' + item.key" (toggled)="invokeAction()"></ui-toggle>
    </button>

    <button
      class="menu-item-action sp-p-8"
      *ngIf="!item.uiType"
      [class.disabled]="item.disabled"
      [class.selected]="item.selected"
      [attr.aria-describedby]="item.a11yDescription ? item.key + '-' + item.a11yDescription : null"
      [attr.data-test-id]="'menu-item-action-' + item.key"
      (click)="handleButtonClick()">
      <span>{{ item.key | localize }}</span>
      <div class="description" *ngIf="item.descriptionKey">{{ item.descriptionKey | localize }}</div>
      <i class="sp-ml-8" *ngIf="item.selected" [class.transparent]="showNewDesign" uiType="check" uiTheme="outline" ui-icon uiSize="sm"></i>
    </button>

    <button
      class="menu-item-action sp-p-8"
      *ngIf="item.uiType === 'button'"
      [class.disabled]="item.disabled"
      [attr.aria-describedby]="item.a11yDescription ? item.key + '-' + item.a11yDescription : null"
      [attr.data-test-id]="'menu-item-action-' + item.key"
      [style.display]="'flex'"
      [style.align-items]="'center'"
      (click)="invokeAction()"
      ui-button
      uiSize="default"
      uiType="primary"
      uiShape="round">
      <span>{{ item.key | localize }}</span>
      <div class="description" *ngIf="item.descriptionKey">{{ item.descriptionKey | localize }}</div>
      <i *ngIf="item.selected" [class.transparent]="showNewDesign" uiType="check" uiTheme="outline" ui-icon></i>
    </button>
  </ng-template>

  <!-- since aria-description is not available for all browsers and we don't have visible element to se to aria-describedby we are hiding the description in the DOM -->
  <div class="a11y-description" *ngIf="item.a11yDescription" [id]="item.key + '-' + item.a11yDescription">{{ item.a11yDescription | localize }}</div>
</ng-container>
