import { Ng2StateDeclaration, UIRouter } from "@uirouter/angular";
import { UIRouterUpgradeModule } from "@uirouter/angular-hybrid";
import { NgModule } from "@angular/core";
import { states as automationStates } from "./automation/automation.future-states";
import { states as configurationStates } from "./configuration/configuration.future-states";
import { states as dataStoryStates } from "./data-story/data-story.future-states";
import { states as examplesStates } from "./examples/examples.future-states";
import { states as kpisStates } from "./kpis/kpis.future-states";
import { states as listsStates } from "./lists/lists.future-states";
import { states as okrsStates } from "./okrs/okrs.future-states";
import { states as peopleStates } from "./people/people.future-states";
import { states as searchStates } from "./search/search.future-states";
import { states as whiteboardsStates } from "./whiteboards/whiteboards.future-states";

const coreState = {
  name: "gh",
  abstract: true,
};

const states: Ng2StateDeclaration[] = [
  coreState,
  ...examplesStates,
  ...dataStoryStates,
  ...automationStates,
  ...listsStates,
  ...whiteboardsStates,
  ...peopleStates,
  ...configurationStates,
  ...kpisStates,
  ...okrsStates,
  ...searchStates,
];

@NgModule({
  imports: [UIRouterUpgradeModule.forRoot({ states })],
  exports: [UIRouterUpgradeModule],
})
export class AppRoutingModule {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor, @typescript-eslint/no-unused-vars
  constructor(router: UIRouter) {
    // UIRouter is initialized the first time when it is injected somewhere, so we do this here
    // even if we don't use it explicitly
  }
}
