import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, NgZone } from "@angular/core";
import { Observable, map, take } from "rxjs";
import { ShareableFilter, ShareableFilterPermanentPostDTO } from "@webapp/shared/unified-filters/models/shareable-filters.models";
import { ShareableFiltersFacade } from "@webapp/shared/unified-filters/services/shareable-filters/shareable-filters-facade.service";
import { UI_MODAL_DATA } from "@webapp/ui/modal/modal.models";

export type SaveFilterAsViewModalData = {
  shareableFilter: ShareableFilterPermanentPostDTO;
  placeholder?: string;
  hint?: string;
};

@Component({
  selector: "save-filter-as-view-modal",
  templateUrl: "./save-filter-as-view-modal.component.html",
  styleUrls: ["./save-filter-as-view-modal.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveFilterAsViewModalComponent {
  public inputValue = "";
  public isLoading = false;

  constructor(
    @Inject(UI_MODAL_DATA) public modalData: SaveFilterAsViewModalData,
    private shareableFiltersFacade: ShareableFiltersFacade,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    Object.assign(this, modalData);
  }

  public onInputValueChange(value: string): void {
    this.modalData.shareableFilter.name = value;
    this.ngZone.run(() => {
      this.cdr.markForCheck();
    });
  }

  public saveFilterAsView(): Observable<ShareableFilter> {
    this.isLoading = true;
    this.modalData.shareableFilter.name = this.modalData.shareableFilter.name.trim();

    return this.shareableFiltersFacade.postShareableFilter$(this.modalData.shareableFilter).pipe(
      take(1),
      map((shareableFilter) => {
        this.isLoading = false;
        return shareableFilter;
      })
    );
  }

  public get isSaveButtonDisabled(): boolean {
    return this.inputValue.trim().length === 0 || this.isLoading;
  }
}
