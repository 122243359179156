import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LocalizationModule } from "@webapp/localization/localization.module";

@Component({
  selector: "skip-link",
  styleUrls: ["skip-link.component.less"],
  templateUrl: "skip-link.component.html",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LocalizationModule],
})
export class SkipLinkComponent {
  private skipToMainContainerSelector = ".a11y-skip-to-main-container";

  public skipToContent($event, section: "nav" | "main"): void {
    if ($event.key !== "Enter" && $event.type !== "click") {
      return;
    }

    let elementFocusSelector;
    if (section === "main") {
      elementFocusSelector = document.querySelector<HTMLElement>(this.skipToMainContainerSelector) || document.querySelector<HTMLElement>("article");
    } else {
      elementFocusSelector = document.querySelector<HTMLElement>(".top-nav-bar") || document.querySelector<HTMLElement>("top-nav-bar.legacy");
    }

    elementFocusSelector.classList.add("skip-link-focus");
    elementFocusSelector.setAttribute("tabindex", "-1");
    elementFocusSelector.addEventListener(
      "blur",
      () => {
        elementFocusSelector.removeAttribute("tabindex");
        elementFocusSelector.classList.remove("skip-link-focus");
      },
      { once: true }
    );
    elementFocusSelector.focus();
  }
}
