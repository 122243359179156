import { IModalStackService } from "angular-ui-bootstrap";
import { StateDeclaration, StateService, TargetState } from "@uirouter/angular";
import { Inject, Injectable } from "@angular/core";
import Cookies from "js-cookie";
import { firstValueFrom } from "rxjs";
import { IAccount, IEdition } from "@gtmhub/core";
import { ISubscriptionConverted } from "@gtmhub/login/models";
import { getCurrentUserId } from "@gtmhub/users";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { StorageService } from "@webapp/core/storage/services/storage.service";
import { localize } from "@webapp/localization/utils/localization.utils";
import dayjs from "@webapp/shared/libs/dayjs";
import { currentAccountCache } from "./current-account-cache";
import { CurrentAccountRepository } from "./current-account-repository.service";

const FEATURE_MAPPINGS = {
  slack: "gtmhub.configuration.slack",
};

const TWENTY_FOUR_HOURS_IN_MS = 86400;
const FORTY_EIGHT_HOURS_IN_MS = 172800;
const TWO_HOURS_IN_MS = 7200;
const ONE_HOUR_IN_MS = 3600;

@Injectable({ providedIn: "root" })
export class AccountResolverService {
  private initPromise: Promise<void>;
  private account: IAccount;

  public constructor(
    private broadcastService: BroadcastService,
    private storageService: StorageService,
    private state: StateService,
    @Inject("$uibModalStack") private ng1ModalStack: IModalStackService,
    private currentAccountRepository: CurrentAccountRepository
  ) {
    this.broadcastService.on<ISubscriptionConverted>("subscriptionConverted").subscribe((args) => this.subscriptionConverted(args));

    currentAccountCache.get$().subscribe((account) => {
      if (account) {
        this.setAccountData(account);
      }
    });
  }

  public getAccountName(): string {
    return this.storageService.get("accountName");
  }

  private setAccountName(accountName: string): void {
    this.storageService.set("accountName", accountName);
  }

  private setEdition(edition: IEdition): void {
    // used by marketplace
    this.storageService.set("edition", edition);
  }

  private setAccountSettings(accountSettings: string): void {
    // Account settings come stringified and the set method stringifies them for second time
    // Bear that in mind if changing the storage methods logic
    // If improved, please add a task for changing the current double parsing in the marketplace
    this.storageService.set("accountSettings", accountSettings);
  }

  public getAccountId(): string {
    return this.storageService.get("accountId");
  }

  public setAccountId(accountId: string): void {
    this.storageService.set("accountId", accountId);
  }

  public getFeatureStateName(): string | undefined {
    const featureKey = this.storageService.get<string>("feature");

    if (featureKey) {
      return FEATURE_MAPPINGS[featureKey];
    }
  }

  public clear(): void {
    this.storageService.remove("accountName");
    this.storageService.remove("accountSettings");
    this.storageService.remove("accountId");
  }

  public prepareForResolveAccount(): TargetState {
    this.ng1ModalStack.dismissAll();

    return this.state.target("accountBootstrap.resolveAccount");
  }

  public shouldShowAccountDetailsForm(): Promise<boolean> {
    return new Promise((resolve) => {
      const accountData: IAccount | undefined = this.getAccountData();

      if (!accountData) {
        // dont try to fetch account data if we dont have account id in LS
        if (!this.getAccountId()) {
          return resolve(false);
        }

        this.initAccount().then(() => {
          const account: IAccount = this.getAccountData();
          const isUserAccOwner = account.ownerId === getCurrentUserId();

          if (!isUserAccOwner) {
            resolve(false); // only account owner can add account details
          } else {
            resolve(!account.accountMarketingInfo);
          }
        });
      } else {
        const isUserAccOwner = accountData.ownerId === getCurrentUserId();

        if (!isUserAccOwner) {
          resolve(false); // only account owner can add account details
        } else {
          resolve(!accountData.accountMarketingInfo);
        }
      }
    });
  }

  public demandsAreMet(toState: StateDeclaration): boolean {
    return !toState?.data?.requiresAccount || Boolean(this.getAccountId());
  }

  public initAccount(): Promise<void> {
    const accountData = this.getAccountData();

    if (accountData) {
      this.broadcastService.emit("accountDataInitialized", accountData);
      return Promise.resolve();
    }

    if (this.initPromise == null) {
      this.initPromise = firstValueFrom(this.currentAccountRepository.get$()).then((account: IAccount) => {
        Cookies.set("quantiveAccountDomain", account.domain, { expires: 30, sameSite: "None", secure: true });
        this.broadcastService.emit("accountDataInitialized", account);

        delete this.initPromise;
      });
    }
    return this.initPromise;
  }

  public setAccountData(account: IAccount): void {
    if (account.id !== "") {
      this.setAccountId(account.id);
      this.setAccountSettings(account.settings);
      this.setEdition(account.edition);
      this.setAccountName(account.name);
      this.setAccount(account);
    }
  }

  public getAccountData(): IAccount {
    return this.account;
  }

  public getTimezone(): string {
    return this.account?.notifications?.reportOptions?.okrUpdate?.timezone || "UTC+0";
  }

  private setAccount(account: IAccount): void {
    this.account = account;
  }

  public checkExpirationOfTrialAccount(): { secondsLeft: number; timeLeft: string; percentageTimeLeft: number } {
    const account: IAccount = this.getAccountData();

    const secondsLeft = dayjs(account.trialEnds).diff(dayjs(), "seconds");
    const trialLength = dayjs(account.trialEnds).diff(account.dateCreated, "seconds");
    const percentageTimeLeft = (dayjs().diff(account.dateCreated, "seconds") * 100) / trialLength;
    let timeLeft: string = null;

    if (secondsLeft > TWENTY_FOUR_HOURS_IN_MS && secondsLeft < FORTY_EIGHT_HOURS_IN_MS) {
      timeLeft = `1 ${localize("day")}`;
    }

    if (secondsLeft > FORTY_EIGHT_HOURS_IN_MS) {
      timeLeft = `${Math.ceil(secondsLeft / TWENTY_FOUR_HOURS_IN_MS)} ${localize("days")}`;
    }

    if (secondsLeft < TWO_HOURS_IN_MS && secondsLeft > ONE_HOUR_IN_MS) {
      timeLeft = `1 ${localize("hour")}`;
    }

    if (secondsLeft < TWENTY_FOUR_HOURS_IN_MS && secondsLeft > TWO_HOURS_IN_MS) {
      timeLeft = `${Math.ceil(secondsLeft / ONE_HOUR_IN_MS)} ${localize("hours")}`;
    }

    if (secondsLeft < ONE_HOUR_IN_MS) {
      timeLeft = `${Math.ceil(secondsLeft / 60)} ${localize("minutes")}`;
    }

    return {
      secondsLeft,
      timeLeft,
      percentageTimeLeft,
    };
  }

  private subscriptionConverted(subscriptionConverted: ISubscriptionConverted): void {
    if (this.getAccountId() === subscriptionConverted.accountId) {
      const accountData = { ...this.getAccountData() };

      if (accountData.subscriptions.length) {
        const subscriptionTypeRegular = subscriptionConverted.subscriptions.find((license) => license.type === "regular");

        accountData.subscriptions = accountData.subscriptions.map((license) => {
          if (license.type === "regular") {
            license.isConverted = subscriptionTypeRegular.isConverted;
          }

          return license;
        });
      } else {
        accountData.subscriptions = subscriptionConverted.subscriptions;
      }

      accountData.edition = subscriptionConverted.accountEdition;
      this.setAccount(accountData);
    }
  }
}
