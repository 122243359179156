import { ObservableCache } from "@webapp/core/state-management/observable-cache";
import { Account } from "../models/accounts.models";

// This is not an Injectable because we need it in Angular.js until both
// AccountsService and AccountResolveService are migrated
export class CurrentAccountCache extends ObservableCache<Account> {
  constructor() {
    super(undefined);
  }
}

export const currentAccountCache = new CurrentAccountCache();
