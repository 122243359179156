import { ILocationService, IModule } from "angular";
import { OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { Location } from "@angular/common";
import { UiDropDownDirective } from "@quantive/ui-kit/dropdown";
import { Observable, Subject, merge } from "rxjs";

const locationObservable = (location: Location) =>
  new Observable((observer) => {
    observer.add(
      location.subscribe(
        () => observer.next(),
        (error) => observer.error(error)
      )
    );
  });

/**
 * Angular and Angular.js use different location services. We use the hybrid ui-router which
 * relies on the Angular.js location services, which does not trigger change events
 * for Angular location services. A side-effect of this is that overlays (used by modals)
 * are not closed when navigating away. This patch monkey-patches the Overlay service
 * to provide a way to close the overlays when the location changes.
 */
export const patchOverlay = (mod: IModule): void => {
  const ng1LocationChanges$ = new Subject<void>();

  const attach = OverlayRef.prototype.attach;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  OverlayRef.prototype.attach = function (portal: any): any {
    const attachResult = attach.call(this, portal);

    const config: OverlayConfig = this["_config"];
    if (config.disposeOnNavigation) {
      const location: Location = this["_location"];
      const location$ = locationObservable(location);

      this["_locationChanges"].unsubscribe();
      this["_locationChanges"] = merge(location$, ng1LocationChanges$).subscribe(() => {
        this.dispose();
      });
    }
    return attachResult;
  };

  UiDropDownDirective.prototype["handleNoopAnimations"] = function (this: UiDropDownDirective) {
    const location: Location = this["location"];
    const location$ = locationObservable(location);

    this["locationChange"] = merge(location$, ng1LocationChanges$).subscribe(() => {
      this.nzDropdownMenu?.onAnimationEvent({
        toState: "void",
        fromState: undefined,
        disabled: undefined,
        element: undefined,
        phaseName: undefined,
        totalTime: undefined,
        triggerName: undefined,
      });
    });
  };

  mod.run([
    "$location",
    ($location: ILocationService) => {
      const url = $location.url;

      function newUrl(this: ILocationService): string;
      function newUrl(this: ILocationService, input: string): ILocationService;
      function newUrl(this: ILocationService, input?: string): string | ILocationService {
        const result = url.call(this, input);
        if (input) {
          ng1LocationChanges$.next();
        }
        return result;
      }
      $location.url = newUrl;
    },
  ]);
};
