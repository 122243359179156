import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Goal } from "@webapp/okrs/goals/models/goal.models";
import { IPIFeedbackSuggestion } from "../models";
import { PIFeedbackSuggestionSubType, PIFeedbackSuggestionType } from "../services/feedback/models";
import { PiStateProcessorInstance } from "../services/state-processor/pi-state-processor.models";

@Component({
  selector: "pi-card-action-bar",
  templateUrl: "./pi-card-action-bar.component.html",
  styleUrls: ["./pi-card-action-bar.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiCardActionBarComponent {
  @Input() public instance: PiStateProcessorInstance;
  @Input() public suggestion: IPIFeedbackSuggestion<string | string[]>;
  @Input() public goal: Pick<Goal, "name" | "description" | "ownerIds" | "sessionId">;
  @Input() public entityId: string;
  @Input() public suggestionFeedbackType: PIFeedbackSuggestionType;
  @Input() public suggestionFeedbackSubType: PIFeedbackSuggestionSubType;
  @Input() public isMainActionsSectionAvailable = true;
  @Input() public isFeedbackActionsSectionAvailable = true;
}
