import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { IBaseRepository } from "@webapp/core/state-management/models/base-repository.model";
import { TodoGroups } from "../todos-widget.models";
import { TodosApiV2Service } from "./todos-api-v2.service";

@Injectable({ providedIn: "root" })
export class TodosRepository implements Pick<IBaseRepository<TodoGroups>, "get$"> {
  constructor(private api: TodosApiV2Service) {}

  public get$(): Observable<TodoGroups> {
    return (
      this.api
        .getAll$<TodoGroups>()
        // removes the timezone from the response for check-ins
        // as the dueDate for check-ins is dynamically computed server-side, it gets the value of the upcoming Sunday 23:59 UTC time
        // removing the timezone marker (the trailing Z char) will make browsers world-wide to interpret it as 23:59 local time
        .pipe(map((todos: TodoGroups) => ({ ...todos, reflections: todos?.reflections?.map((checkIn) => ({ ...checkIn, dueDate: checkIn.dueDate?.replace("Z", "") })) })))
    );
  }
}
