import { IRootScopeService, IScope } from "angular";
import { IModalScope } from "angular-ui-bootstrap";
import { KpiUiGroup } from "@webapp/kpis/models/kpi-group.models";

export enum KpiGroupEventType {
  KPIGROUP_VISITED = "KPIGROUP_VISITED",
  KPIGROUP_DELETED = "KPIGROUP_DELETED",
  KPIGROUP_UPDATED = "KPIGROUP_UPDATED",
}

export enum KpiEventType {
  KPI_VISITED = "KPI_VISITED",
  KPI_DELETED = "KPI_DELETED",
  KPI_UPDATED = "KPI_UPDATED",
}

export class KpiGroupEvents {
  private $rootScope: IRootScopeService;
  constructor(private $scope: IModalScope | IScope) {
    this.$rootScope = this.$scope.$root;
  }

  public broadcastKpiGroupVisited(kpiGroup: KpiUiGroup): void {
    this.$rootScope.$broadcast(KpiGroupEventType.KPIGROUP_VISITED, {
      kpiGroup,
    });
  }

  public broadcastKpiGroupDeleted(kpiGroup: { id: string }): void {
    this.$rootScope.$broadcast(KpiGroupEventType.KPIGROUP_DELETED, kpiGroup);
  }

  public broadcastKpiGroupUpdated(kpiGroup: KpiUiGroup): void {
    this.$rootScope.$broadcast(KpiGroupEventType.KPIGROUP_UPDATED, { kpiGroup });
  }
}
