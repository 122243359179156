import { FocusMonitor } from "@angular/cdk/a11y";
import { Directionality } from "@angular/cdk/bidi";
import { NgIf, NgStyle, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, ElementRef, Input, Optional, QueryList, Renderer2, TemplateRef } from "@angular/core";
import { UiSizeLDSType } from "@quantive/ui-kit/core";
import { NzFormNoStatusService, NzFormStatusService } from "ng-zorro-antd/core/form";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzInputGroupComponent } from "ng-zorro-antd/input";
import { UiInputDirective } from "@webapp/ui/input/input.directive";
import { UiInputGroupSlotComponent } from "../input-group-slot/input-group-slot.component";

@Component({
  selector: "ui-input-group",
  exportAs: "uiInputGroup",
  templateUrl: "input-group.component.html",
  styleUrls: ["input-group.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "ui-input-group",
    "[class.ui-input-group-numeric]": `uiType === 'numeric'`,
    "[class.ui-with-addon-before]": `uiAddOnBeforeIcon || uiAddOnBefore`,
    "[class.ui-with-addon-after]": `uiAddOnAfterIcon || uiAddOnAfter`,
    "[class.ui-read-only]": `uiReadOnly`,
    "[class.ui-disabled]": `uiDisabled`,
  },
  standalone: true,
  imports: [NgIf, NgStyle, UiInputGroupSlotComponent, NgTemplateOutlet],
})
export class UiInputGroupComponent extends NzInputGroupComponent {
  @ContentChildren(UiInputDirective) public listOfNzInputDirective!: QueryList<UiInputDirective>;
  @Input("uiAddOnBeforeIcon") public nzAddOnBeforeIcon?: string | null = null;
  @Input("uiAddOnAfterIcon") public nzAddOnAfterIcon?: string | null = null;
  @Input("uiPrefixIcon") public nzPrefixIcon?: string | null = null;
  @Input("uiSuffixIcon") public nzSuffixIcon?: string | null = null;
  @Input("uiAddOnBefore") public nzAddOnBefore?: string | TemplateRef<void>;
  @Input("uiAddOnAfter") public nzAddOnAfter?: string | TemplateRef<void>;
  @Input("uiPrefix") public nzPrefix?: string | TemplateRef<void>;
  @Input("uiSuffix") public nzSuffix?: string | TemplateRef<void>;
  @Input("uiSize") public nzSize: UiSizeLDSType = "default";
  @Input("uiSearch") @InputBoolean() public nzSearch = false;
  @Input("uiCompact") @InputBoolean() public nzCompact = false;
  @Input() public uiType?: "numeric";
  @Input() public uiReadOnly = false;
  @Input() public uiDisabled = false;
  /**
   * Sets the maximum width of addon boxes. Due to ng-zorro's "display: table-cell;" style rule the width value cannot be percentage
   */
  @Input() public uiAddonStyle: { maxWidth: string } = { maxWidth: "unset" };

  constructor(
    focusMonitor: FocusMonitor,
    elementRef: ElementRef,
    renderer: Renderer2,
    cdr: ChangeDetectorRef,
    @Optional() directionality: Directionality,
    @Optional() nzFormStatusService?: NzFormStatusService,
    @Optional() nzFormNoStatusService?: NzFormNoStatusService
  ) {
    super(focusMonitor, elementRef, renderer, cdr, directionality, nzFormStatusService, nzFormNoStatusService);
  }
}
