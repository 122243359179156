import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, firstValueFrom, map, take } from "rxjs";
import { localize } from "@gtmhub/localization/localize";
import { cancelButton, primaryButton } from "@webapp/shared/modal/modal.utils";
import {
  SaveFilterAsViewModalComponent,
  SaveFilterAsViewModalData,
} from "@webapp/shared/unified-filters/components/save-filter-as-view-modal/save-filter-as-view-modal.component";
import { ShareableFilter, ShareableFilterBasePostDTO } from "@webapp/shared/unified-filters/models/shareable-filters.models";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";

@Injectable({
  providedIn: "any",
})
export class ShareableFiltersModalsService {
  private static notifyViewId = new BehaviorSubject<string>("");

  constructor(private modalService: UiModalService) {}

  public openSaveFilterAsViewModal(
    params: { shareableFilter: ShareableFilterBasePostDTO } & Pick<SaveFilterAsViewModalData, "placeholder" | "hint">
  ): UiModalRef<SaveFilterAsViewModalComponent, ShareableFilter> {
    return this.modalService.create<SaveFilterAsViewModalComponent, SaveFilterAsViewModalData, ShareableFilter>({
      uiTitle: localize("save_as"),
      uiContent: SaveFilterAsViewModalComponent,
      uiData: {
        shareableFilter: params.shareableFilter,
        placeholder: params.placeholder,
        hint: params.hint,
      },
      uiWidth: 670,
      uiMaskClosable: false,
      uiKeyboard: false,
      uiFooter: [
        primaryButton(localize("save"), {
          disabled: (component) => component.isSaveButtonDisabled,
          onClick: (component) =>
            firstValueFrom(component.saveFilterAsView().pipe(take(1))).then((shareableFilter) => {
              ShareableFiltersModalsService.notifyViewId.next(shareableFilter.id);

              return shareableFilter;
            }),
        }),
        cancelButton(),
      ],
    });
  }

  public subscribeToViewCreationSuccess$(): Observable<string> {
    return ShareableFiltersModalsService.notifyViewId.pipe(
      take(1),
      map((viewId) => {
        ShareableFiltersModalsService.notifyViewId.next("");

        return viewId;
      })
    );
  }
}
