import { UIRouterModule } from "@uirouter/angular";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiCardModule } from "@quantive/ui-kit/card";
import { ApmService } from "@gtmhub/core/tracing/apm.service";
import { localize } from "@gtmhub/localization";
import { VIEW_ALIGNMENT_WIDGET_ID } from "@webapp/home/models/home-widgets.models";
import { HomeWidgetConfigurator } from "@webapp/home/services/home-widget-configuration.service";
import { HomeWidgetService } from "@webapp/home/services/home-widget.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { BaseWidgetComponent } from "@webapp/ui/dashboard/components/base-widget.component";
import { UiDashboardWidget } from "@webapp/ui/dashboard/dashboard.models";
import { UiToastService } from "@webapp/ui/toast/services/toast.service";
import { WidgetActionsComponent } from "../widget-actions/widget-actions.component";

@UntilDestroy()
@Component({
  selector: "view-feed-widget",
  templateUrl: "./view-feed-widget.component.html",
  styleUrls: ["./view-feed-widget.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiButtonModule, UiCardModule, UIRouterModule, LocalizationModule, WidgetActionsComponent],
})
export class ViewFeedWidgetComponent extends BaseWidgetComponent implements OnInit {
  private widget: UiDashboardWidget;
  private viewAlignmentWidget: UiDashboardWidget;
  private widgetConfigurator: HomeWidgetConfigurator;

  public get title(): string {
    return localize("stay_in_the_loop");
  }

  public get a11yLabel(): string {
    return "Stay in the loop";
  }

  constructor(
    private homeWidgetService: HomeWidgetService,
    private toastService: UiToastService,
    private apmService: ApmService
  ) {
    super();
    this.apmService.startDataLoadSpan("view-feed-widget-init");
  }

  public ngOnInit(): void {
    this.homeWidgetService
      .getDashboardWidgets$()
      .pipe(untilDestroyed(this))
      .subscribe((widgets) => {
        this.widget = widgets.find((w) => w.id === this.widgetId);
        this.viewAlignmentWidget = widgets.find((w) => w.id === VIEW_ALIGNMENT_WIDGET_ID);
        this.widgetConfigurator = new HomeWidgetConfigurator(this.homeWidgetService, widgets);
        this.apmService.endDataLoadSpan("view-feed-widget-init");
      });
  }

  public remove(): void {
    if (!this.widget) {
      throw new Error(`Cannot find ${this.widgetId} widget`);
    }

    const viewFeedWidget = this.widget;
    this.homeWidgetService.removeWidget(this.widgetId);

    if (this.viewAlignmentWidget) {
      this.widgetConfigurator.toggleFullWidth(this.viewAlignmentWidget.id);
    }

    const message = localize("you_can_find_feed_in_navigation");
    this.toastService.info(message, {
      uiShouldTimeout: true,
      uiDuration: 5000,
      uiPrimaryCTA: localize("undo"),
      uiOnPrimaryAction: () => {
        if (this.viewAlignmentWidget) {
          this.widgetConfigurator.toggleFullWidth(this.viewAlignmentWidget.id);
        }

        this.homeWidgetService.addWidget(viewFeedWidget);
      },
    });
  }
}
