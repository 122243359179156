import { IModule } from "angular";
import { Ng1ViewDeclaration, StateObject, StateProvider, Transition } from "@uirouter/angularjs";
import { ViewDecorator } from "./models";

const stateParamsToComponentBindings: ViewDecorator = (state: StateObject): void => {
  const paramNames = Object.keys(state.params);
  if (!paramNames.length) {
    return;
  }

  if (!state.resolve) {
    state.resolve = {};
  }

  paramNames.forEach((param) => {
    if (!state.resolve[param]) {
      Object.assign(state.resolve, {
        [param]: ["$transition$", ($transition$: Transition) => $transition$.params()[param]],
      });
    }

    // check if it is not optional but also has no default value; if such is the case we assign a default value of null
    if (state.params[param].isOptional === false && typeof state.params[param].config.value === "undefined") {
      state.params[param].config.value = null;
      state.params[param].isOptional = true;
    }
  });
};

const VIEW_DECORATORS = [stateParamsToComponentBindings];

export const configureViewDecorators = (mod: IModule): void => {
  mod.config([
    "$stateProvider",
    ($stateProvider: StateProvider) => {
      $stateProvider.decorator("views", function (state, parent) {
        const views: { [name: string]: Ng1ViewDeclaration } = parent(state);
        for (const view of Object.values(views)) {
          for (const handle of VIEW_DECORATORS) {
            handle(state, view);
          }
        }
        return views;
      });
    },
  ]);
};
