import { HttpErrorResponse } from "@angular/common/http";
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SimpleChangesOf } from "@quantive/ui-kit/core";
import { Observable, Subject, finalize, map, of, take, zip } from "rxjs";
import { getCurrentUserId } from "@gtmhub/users";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { AssigneesRepository } from "@webapp/assignees/services/assignees-repository.service";
import { toIdMap } from "@webapp/okrs/components/create-okr-form/utils";
import { Goal } from "@webapp/okrs/goals/models/goal.models";
import { GoalsFacade } from "@webapp/okrs/goals/services/goals-facade.service";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { MetricsFacade } from "@webapp/okrs/metrics/services/metrics-facade.service";
import { IParentSelector } from "@webapp/okrs/models/parent-selector.models";
import { SuggestionDrawerIndicatorStatePropagator } from "@webapp/platform-intelligence/expandable-suggestions/suggestion-drawer/shared/utils/state-propagator";
import { IQuantivePlusObjective } from "@webapp/platform-intelligence/quantive-plus/models";
import { PIImproveSuggestionContext } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/improvement/models";
import { PiImprovementFacade } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/improvement/pi-improvement-facade.service";
import { generateImprovementPayload } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/improvement/utils";
import { PiStateProcessorInstance } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.models";
import { PiStateProcessorService } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.service";
import { StrategicGuidedOkrFacade } from "@webapp/platform-intelligence/shared/services/strategic-guided-okr/strategic-guided-okr-facade.service";
import { getPiErrorData } from "@webapp/platform-intelligence/shared/utils/pi-errors.utils";
import { PiTrackingEventsEnum, PiTrackingFlowNameEnum } from "@webapp/platform-intelligence/shared/utils/pi-tracking";
import { SessionsRepository } from "@webapp/sessions/services/sessions-repository.service";
import { TeamsRepository } from "@webapp/teams/services/teams-repository.service";
import { generateCreateObjectiveSuggestionDrawerTitlePayload, getCreateObjectiveSuggestionDrawerNoTitleSuggestionReason } from "./utils/utils";

@UntilDestroy()
@Component({
  selector: "pi-create-objective-suggestion-drawer",
  templateUrl: "./create-objective-suggestion-drawer.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateObjectiveSuggestionDrawerComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {
  @Input() public title: string;
  @Input() public sessionId: string;
  @Input() public ownerIds: string[];
  @Input() public parentItem: IParentSelector;

  @Input() public isOpen = false;
  @Input() public statePropagator: SuggestionDrawerIndicatorStatePropagator;

  @Input() public flowId: string;
  @Input() public flowName: PiTrackingFlowNameEnum;

  @Output() public readonly visibilityChange = new EventEmitter<boolean>();
  @Output() public readonly titleSuggest = new EventEmitter<{ id: string; text: string }>();

  public instance: PiStateProcessorInstance;

  public hasError = false;
  public errorData = getPiErrorData("title", null);
  public hasAllDependencies: boolean;
  public noSuggestionReasonKey: string;
  public suggestions: [{ id: string; suggestionText: string }] = [{ id: "", suggestionText: "" }];

  public get isLoading(): boolean {
    return this.loadingStateRaw;
  }
  public set isLoading(isLoading: boolean) {
    this.loadingStateRaw = isLoading;
    this.propagateStateChange();
  }
  public onDestroy$ = new Subject<void>();

  private get suggestionContext(): PIImproveSuggestionContext {
    return {
      entityId: null,
      parentId: this.parentItem?.id,
      parentType: this.parentItem && this.parentItem.type === "goal" ? "objective" : "KR",
    };
  }

  private currentObjectiveSuggestion: IQuantivePlusObjective;
  private piFlowInitiatedEventSent = false;

  private indicatorFakeLoaderTimeout: NodeJS.Timeout;
  private indicatorFakeLoaderTimeoutDuration = 2000;

  /**
   * Don't set new values to this prop directly, use the isLoading setter instead.
   */
  private loadingStateRaw = false;

  constructor(
    private piStateProcessorService: PiStateProcessorService,
    private piImprovementFacade: PiImprovementFacade,
    private changeDetector: ChangeDetectorRef,
    private zone: NgZone,
    private strategicGuidedOkrFacade: StrategicGuidedOkrFacade,
    private analyticsService: AnalyticsService,
    private sessionsRepository: SessionsRepository,
    private teamsRepository: TeamsRepository,
    private assigneesRepository: AssigneesRepository,
    private goalsFacade: GoalsFacade,
    private metricsFacade: MetricsFacade
  ) {}

  public ngAfterContentInit(): void {
    if (this.flowId && this.flowName) {
      this.piStateProcessorService.assigneInstanceFlowProperties(this.instance.id, { flowId: this.flowId, flowName: this.flowName });
    }
  }

  public ngOnInit(): void {
    this.instance = this.piStateProcessorService.createNewInstance({
      type: "goal",
      subType: "createObjectiveTitle",
      isInline: false,
      iconType: "goal",
      iconTheme: "fill",
      improveSuggestionContext: this.suggestionContext,
      destroyOn$: this.onDestroy$,
    });

    this.instance.subject.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data.suggestAnother) {
        this.getTitleSuggestion({ isRefresh: true });
      }

      if (data.refreshError) {
        this.getTitleSuggestion();
      }

      if (data.addSuggestion) {
        this.setAcceptedTitleSuggestion();
      }

      if (data.goBackToSuggestion) {
        this.goBackToLastSuggestion();
      }
    });

    this.subscribeToImprovementSuggestion();
    this.subscribeToReplacementSuggestion();
  }

  public ngOnChanges(changes: SimpleChangesOf<CreateObjectiveSuggestionDrawerComponent>): void {
    if (this.instance && changes.parentItem) {
      this.piStateProcessorService.updateInstanceImproveSuggestionContext(this.instance.id, this.suggestionContext);
    }

    if (this.statePropagator && (changes.title || changes.ownerIds || changes.sessionId || changes.parentItem || changes.isOpen?.currentValue)) {
      this.updateSuggestionDrawerState();
    }

    if (changes.isOpen?.currentValue && !this.piFlowInitiatedEventSent) {
      this.piFlowInitiatedEventSent = true;
      this.analyticsService.track(PiTrackingEventsEnum.PiFlowInitiated, {
        flowName: this.flowName,
        flowId: this.flowId,
        userId: getCurrentUserId(),
      });
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  private subscribeToReplacementSuggestion(): void {
    this.instance.replacementSuggestionSubject.pipe(untilDestroyed(this)).subscribe((data) => {
      this.suggestions = [
        {
          id: data.newSuggestion.id,
          suggestionText: data.newSuggestion.text,
        },
      ];
      this.changeDetector.markForCheck();
    });
  }

  private subscribeToImprovementSuggestion(): void {
    this.instance.improvementSubject.pipe(untilDestroyed(this)).subscribe((data) => {
      const suggestion = this.suggestions[0];

      const payload = generateImprovementPayload({
        subEntityType: this.instance.subType,
        instruction: data.improvement,
        suggestion: {
          title: suggestion.suggestionText,
        },
        isAskAI: true,
        improveSuggestionContext: this.instance.improveSuggestionContext,
      });

      this.isLoading = true;

      this.piImprovementFacade
        .postImprovement<{ id: string; title: string }>(payload)
        .pipe(
          finalize(() => this.changeDetector.markForCheck()),
          take(1),
          untilDestroyed(this)
        )
        .subscribe({
          next: (data) => {
            suggestion.id = data.suggestions.improvement.id;
            suggestion.suggestionText = data.suggestions.improvement.title;

            this.isLoading = false;
          },
          error: (error: HttpErrorResponse) => {
            this.handleRequestError(error);
          },
        });
    });
  }

  private getTitleSuggestion(args: { isRefresh?: boolean } = {}): void {
    this.isLoading = true;
    this.hasError = false;

    zip([this.sessionsRepository.getMap$(), this.teamsRepository.getMap$(), this.assigneesRepository.getMap$(), this.getParetItemDataRequest()])
      .pipe(
        finalize(() => this.changeDetector.markForCheck()),
        take(1),
        untilDestroyed(this)
      )
      .subscribe({
        next: ([sessions, teams, assignees, parent]) => {
          const payload = generateCreateObjectiveSuggestionDrawerTitlePayload({
            subEntityType: "createObjectiveTitle",
            session: sessions.get(this.sessionId),
            ownerIds: this.ownerIds || [],
            assigneesMap: toIdMap(assignees),
            teams: [...(teams || new Map()).values()],
            ...(parent && {
              parentOkr: parent,
            }),
            ...(args.isRefresh && {
              isRefresh: true,
              rejectedObjectives: [this.currentObjectiveSuggestion],
            }),
          });

          this.zone.run(() => {
            this.strategicGuidedOkrFacade
              .getObjectiveSuggestions(payload)
              .pipe(
                finalize(() => this.changeDetector.markForCheck()),
                take(1),
                untilDestroyed(this)
              )
              .subscribe({
                next: (suggestion) => {
                  this.isLoading = false;
                  this.hasError = false;
                  this.suggestions = [
                    {
                      id: suggestion.suggestions.objectives[0].id,
                      suggestionText: suggestion.suggestions.objectives[0].title,
                    },
                  ];
                  this.currentObjectiveSuggestion = suggestion.suggestions.objectives[0];

                  if (args?.isRefresh) {
                    this.analyticsService.track(PiTrackingEventsEnum.PiSuggestionRefreshed, {
                      suggestionId: this.currentObjectiveSuggestion?.id,
                      flowName: this.flowName,
                      flowId: this.flowId,
                      userId: getCurrentUserId(),
                    });
                  }
                },
                error: (error: HttpErrorResponse) => {
                  this.handleRequestError(error);
                },
              });
          });
        },
        error: (error: HttpErrorResponse) => {
          this.handleRequestError(error);
        },
      });
  }

  private setAcceptedTitleSuggestion(): void {
    this.titleSuggest.emit({
      id: this.suggestions[0].id,
      text: this.suggestions[0].suggestionText,
    });

    this.analyticsService.track(PiTrackingEventsEnum.PiSuggestionAccepted, {
      suggestionId: this.suggestions[0].id,
      flowName: this.flowName,
      flowId: this.flowId,
      userId: getCurrentUserId(),
    });
  }

  private updateSuggestionDrawerState(): void {
    const isTitleAvailable = !!this.title;
    const isSessionAvailable = !!this.sessionId;
    const isOwnerAvailable = (this.ownerIds || []).length > 0;

    this.hasAllDependencies = !isTitleAvailable && isSessionAvailable && isOwnerAvailable;

    if (this.hasAllDependencies) {
      if (this.isOpen) {
        this.getTitleSuggestion();
      } else {
        this.setSuggestionIndicatorFakeLoadingState();
      }
    } else {
      this.isLoading = false;
      this.noSuggestionReasonKey = getCreateObjectiveSuggestionDrawerNoTitleSuggestionReason({
        isTitleAvailable,
        isSessionAvailable,
        isOwnerAvailable,
      });
    }

    this.changeDetector.markForCheck();
  }

  private goBackToLastSuggestion(): void {
    this.hasError = false;
    this.changeDetector.markForCheck();
  }

  private setSuggestionIndicatorFakeLoadingState(): void {
    this.isLoading = true;

    clearTimeout(this.indicatorFakeLoaderTimeout);

    this.indicatorFakeLoaderTimeout = setTimeout(() => {
      if (!this.isOpen) {
        this.isLoading = false;
      }
    }, this.indicatorFakeLoaderTimeoutDuration);
  }

  private propagateStateChange(): void {
    if (this.statePropagator) {
      const isOwnerAvailable = (this.ownerIds || []).length > 0;
      const isSessionAvailable = !!this.sessionId;
      const isTitleAvailable = !!this.title;
      const hasRequiredDependencies = !isTitleAvailable && isOwnerAvailable && isSessionAvailable;

      this.statePropagator.emitStateChange({
        isLoading: this.isLoading,
        suggestionStateLevel: undefined,
        hasRequiredDependencies,
        isSuggestionAvailable: !isTitleAvailable,
      });
    }
  }

  private getParetItemDataRequest(): Observable<Goal | Metric> {
    if (!this.parentItem?.id) {
      return of(null);
    }

    const facade = this.parentItem.type === "goal" ? this.goalsFacade : this.metricsFacade;
    const fields = ["name", "description"];
    const filter = { _id: this.parentItem.id };
    const limit = 1;

    return facade.getAll$({ fields, filter, limit }).pipe(
      map(({ items }) => items[0]),
      take(1),
      untilDestroyed(this)
    );
  }

  private handleRequestError(error: HttpErrorResponse): void {
    this.errorData = getPiErrorData("title", error);
    this.hasError = true;
    this.isLoading = false;
    this.currentObjectiveSuggestion = null;
  }
}
