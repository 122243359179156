<comment-skeleton *ngIf="indicators.loading"></comment-skeleton>

<ng-container *ngIf="!indicators.loading">
  <div class="comments-list-section" *ngIf="comments?.length > 0">
    <comment
      #component
      *ngFor="let comment of comments; trackBy: commentsTrackBy"
      [comment]="comment"
      [attr.data-test-id]="'comment-container-' + comment.id"
      [hideMentions]="hideMentions"
      [target]="target"
      [metadata]="metadata"
      [isEdited]="editedCommentId === comment.id"
      [editorButtonsDisabled]="editorButtonsDisabled"
      [commentOptionsDropdownPosition]="commentOptionsDropdownPosition"
      [scrollToComment]="scrollToComment"
      (editClick)="openInlineEditor(comment.id)"
      (deleteClick)="openCommentDeleteConfirmation(comment, component)"
      (reactionsChange)="commentsChange.emit(comments)"
      (edit)="editComment($event)"
      (editorClose)="closeInlineEditor()"></comment>
  </div>
  <div class="create-comment-section" *ngIf="!isCurrentUserViewOnly">
    <rich-text-editor
      #editor
      [(text)]="editorInitialText"
      [editorButtonsDisabled]="editorButtonsDisabled"
      [placeholder]="inputPlaceholder"
      [additionalMentions]="mentions.removable"
      [hidePostButton]="externalActionButtons"
      [showExternalEditorButtons]="externalActionButtons"
      [autofocus]="skipFetchingComments || focusEditor"
      [postButtonA11yLabel]="'post_a_comment' | localize"
      (post)="createComment(editor, $event)"
      (mentionsChange)="onCommentMentionsChanged($event)"
      (textChange)="onTextChanged($event)"
      (cancel)="onCommentCreationCanceled(editor)"
      data-test-id="create-comment-editor">
    </rich-text-editor>
    <mentions-editor
      class="mentions-editor"
      *ngIf="!hideMentions && (mentions.removable?.length > 0 || mentions.nonRemovable?.length > 0)"
      [removable]="mentions.removable"
      [nonRemovable]="mentions.nonRemovable"
      [removeAllMentionsAriaLabel]="removeAllMentionsAriaLabel"
      (removeAll)="removeAllMentions()"
      (removeOne)="removeMention($event)"></mentions-editor>
  </div>
</ng-container>
