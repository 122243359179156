import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Subject } from "rxjs";
import { IAssigneesStoreState } from "@gtmhub/assignees";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { ITeamsStoreState } from "@gtmhub/teams";
import { Goal } from "@webapp/okrs/goals/models/goal.models";
import { IQuantivePlusTag } from "@webapp/platform-intelligence/quantive-plus/models";
import { generateQuantivePlusObjectiveTagsSuggestionPayload } from "@webapp/platform-intelligence/quantive-plus/utils/utils";
import { IPIFeedbackSuggestion } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/models";
import {
  generateTagImprovementPayload,
  tagsImprovementSplitOrJoinSuffix,
} from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/improvement/utils";
import { PiStateProcessorInstance } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.models";
import { PiStateProcessorService } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.service";
import { PiErrorData } from "@webapp/platform-intelligence/shared/models/pi-errors.models";
import { PIObjectiveTagsSuggestion } from "@webapp/platform-intelligence/shared/models/quantive-plus-suggestions.models";
import { PiSuggestionSharedGoalStateService } from "@webapp/platform-intelligence/shared/services/pi-suggestion-shared-goal-state.service";
import { ObjectiveFlowName, PiTrackingFlowNameEnum } from "@webapp/platform-intelligence/shared/utils/pi-tracking";
import { SuggestionDrawerAllSuggestionsTabStatePropagator } from "../../../../utils/state-propagator";

@UntilDestroy()
@Component({
  selector: "suggestion-drawer-tags[goal]",
  templateUrl: "./tags.component.html",
  styleUrls: ["./tags.component.less"],
})
export class SuggestionDrawerTagsComponent implements OnInit, OnDestroy {
  @Input() public goal: Goal;
  @Input() public statePropagator: SuggestionDrawerAllSuggestionsTabStatePropagator;

  public tags: IQuantivePlusTag[];
  public isLoading = true;
  public isError: boolean;
  public errorData: PiErrorData;
  public tagsInstance: PiStateProcessorInstance;
  public flowId: string;
  public flowName: ObjectiveFlowName = PiTrackingFlowNameEnum.ObjectiveDetailsViewAllSuggestionSidePanel;
  public feedbackSuggestion: IPIFeedbackSuggestion<string[]>;
  public onDestroy$ = new Subject<void>();

  private suggestion: PIObjectiveTagsSuggestion;
  private lastAvailableSuggestion: PIObjectiveTagsSuggestion;
  private tagsSuggestionId: string;

  constructor(
    private piSuggestionSharedStateService: PiSuggestionSharedGoalStateService,
    private piStateProcessorService: PiStateProcessorService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.tagsInstance = this.piStateProcessorService.createNewInstance({
      type: "goal",
      subType: "tags",
      isInline: false,
      iconType: "goal",
      iconTheme: "fill",
      improveSuggestionContext: {
        entityId: this.goal.id,
        parentId: this.goal.parentId,
        parentType: "objective",
      },
      destroyOn$: this.onDestroy$,
    });

    this.subscribeToTagsSubject();
    this.subscribeToTagsImprovement();

    this.piSuggestionSharedStateService
      .getTags$()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data) => {
          this.isLoading = data.isLoading;
          this.isError = data.isError;
          this.errorData = data.errorData;
          this.statePropagator.emitStateChange({ isLoading: this.isLoading });
          this.suggestion = data.suggestion;

          this.cdr.markForCheck();

          if (!data.suggestion) {
            return;
          }

          this.tags = data.suggestion.suggestions.tags;
          this.flowId = data.suggestion.flowId;

          this.feedbackSuggestion = {
            id: data.suggestion.suggestions.tags[0].id,
            text: data.suggestion.suggestions.tags.map((tag) => tag.title),
          };

          this.tagsSuggestionId = data.suggestion.suggestions.tags[0].id;
          this.lastAvailableSuggestion = data.suggestion;

          this.piStateProcessorService.assigneInstanceFlowProperties(this.tagsInstance.id, {
            flowId: this.flowId,
            flowName: this.flowName,
          });
        },
      });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  private subscribeToTagsSubject(): void {
    this.tagsInstance.subject.pipe(untilDestroyed(this)).subscribe((data) => {
      if (data.suggestAnother) {
        this.getQuantivePlusTagsSuggestion({ isSuggestAnother: true });
      }

      if (data.refreshError) {
        this.getQuantivePlusTagsSuggestion({ isSuggestAnother: false });
      }

      if (data.addSuggestion) {
        this.onTagsSuggestionAdded();
      }

      if (data.goBackToSuggestion) {
        this.piSuggestionSharedStateService.goBackToLastAvailableTagsSuggestion(this.lastAvailableSuggestion);
      }
    });
  }

  private subscribeToTagsImprovement(): void {
    this.tagsInstance.replacementSuggestionSubject.pipe(untilDestroyed(this)).subscribe((data) => {
      const tagsClone = angular.copy(this.tags);
      const tags = data.newSuggestion.text.split(tagsImprovementSplitOrJoinSuffix);
      tagsClone.forEach((tag, index) => (tag.title = tags[index]));
      this.piSuggestionSharedStateService.replaceTagSuggestion(tagsClone, this.suggestion);
    });

    this.tagsInstance.improvementSubject.pipe(untilDestroyed(this)).subscribe((data) => {
      const splitOrJoinSuffix = ",";

      const payload = generateTagImprovementPayload({
        subEntityType: this.tagsInstance.subType,
        instruction: data.improvement,
        tags: this.tags,
        isAskAI: true,
        improveSuggestionContext: this.tagsInstance.improveSuggestionContext,
      });

      this.piSuggestionSharedStateService.postTagsImprovement(payload, splitOrJoinSuffix, this.suggestion);
    });
  }

  public getQuantivePlusTagsSuggestion(args: { isSuggestAnother: boolean }): void {
    const state = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState & ITeamsStoreState>();

    const payload = generateQuantivePlusObjectiveTagsSuggestionPayload({
      goal: this.goal,
      assigneesMap: state.assignees.map,
      teams: state.teams.items,
      ...(args.isSuggestAnother && {
        isRefresh: true,
        rejectedTags: this.tags,
      }),
    });

    this.piSuggestionSharedStateService.getTags({
      suggestionId: this.tagsSuggestionId,
      payload,
      flowId: this.flowId,
      flowName: this.flowName,
      isRefresh: args.isSuggestAnother,
    });
  }

  public onTagsSuggestionAdded(): void {
    this.piSuggestionSharedStateService.acceptTAgs(this.tags);
  }
}
