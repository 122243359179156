import { StateService, UIRouterGlobals } from "@uirouter/angular";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable, catchError, throwError } from "rxjs";

const ACCOUNT_SUSPENDED_STATE = "accountSuspended";

@Injectable()
export class AccountSuspendedInterceptor implements HttpInterceptor {
  constructor(
    private stateService: StateService,
    private routerGlobals: UIRouterGlobals
  ) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === 402 && this.routerGlobals.current.name !== ACCOUNT_SUSPENDED_STATE) {
          this.stateService.go(ACCOUNT_SUSPENDED_STATE, { reload: true });
          return EMPTY;
        }

        return throwError(() => response);
      })
    );
  }
}
