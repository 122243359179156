import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { DataSourceBlueprint, DataSourceBlueprintDTO } from "@webapp/data-story/models/data-sources-facade.models";
import { DataSourceBlueprintsState } from "@webapp/data-story/services/data-source-blueprints-facade/data-source-blueprints-state.service";

@Injectable({
  providedIn: "root",
})
export class DataSourceBlueprintsApiService extends BaseApiService<DataSourceBlueprint, DataSourceBlueprintDTO, DataSourceBlueprintsState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: DataSourceBlueprintsState) {
    super("datasources", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getBlueprintsWithConnectionEndpoint(): string {
    return `${this.getBasePath(HttpActions.get)}/blueprints-with-connection`;
  }

  public getBlueprintByNameEndpoint(name: string): string {
    return `${this.getBasePath(HttpActions.get)}/blueprints/${name}`;
  }
}
