import { IHttpService, IPromise, IRequestShortcutConfig, noop } from "angular";
import { EnvironmentService } from "@gtmhub/env";
import { ICollection } from "@webapp/core/core.models";
import { Entity } from "@webapp/data-story/services/entities-facade/entities-facade.models";
import { IEntityDefinition, IEntityMapping, IEntityMappingRequest, IEntityQueryParams } from "../models/models";

export class EntityService {
  public static $inject = ["$http", "EnvironmentService"];

  constructor(
    private $http: IHttpService,
    private env: EnvironmentService
  ) {}

  public createEntity(yamlDefinition: string | IEntityDefinition): IPromise<Entity> {
    const url = this.env.getApiEndpoint("/entities");

    return this.$http.post<Entity>(url, yamlDefinition).then((response) => response.data);
  }

  public getEntities(categoryId?: string, status: string = null, dataSourceIds: string = null): IPromise<Entity[]> {
    let url = this.env.getApiEndpoint("/entities");
    if (categoryId) {
      url = url + "?categoryId=" + categoryId;
    }

    const query: IRequestShortcutConfig = {
      params: {
        status: status,
        dataSourceIds: dataSourceIds,
      },
    };

    return this.$http.get<ICollection<Entity>>(url, query).then((response) => response.data.items);
  }

  public getSomeEntities<E extends Partial<Entity> = Entity>(queryParams: IEntityQueryParams): IPromise<E[]> {
    const url = this.env.getApiEndpoint("/entities");
    const query: IRequestShortcutConfig = {
      params: queryParams,
    };

    return this.$http.get<ICollection<E>>(url, query).then((response) => response.data.items);
  }

  public requestEntityData(entityName: string, params: { search?: string; skip: number; take: number; fields?: string; distinct: boolean }): IPromise<void> {
    const url = this.env.getApiEndpoint("/entities/" + entityName + "/data");
    const query: IRequestShortcutConfig = {
      params,
    };

    return this.$http.get(url, query).then(noop);
  }

  public getEntity(name: string): IPromise<Entity> {
    const url = this.env.getApiEndpoint("/entities/" + name);

    return this.$http.get<Entity>(url).then((response) => response.data);
  }

  public deleteEntity(name: string): IPromise<void> {
    const url = this.env.getApiEndpoint("/entities/" + name);

    return this.$http.delete<void>(url).then(() => null);
  }

  public createMappings(entityId: string, mapping: IEntityMappingRequest): IPromise<IEntityMapping> {
    const url = this.env.getApiEndpoint("/entities/" + entityId + "/mappings");

    return this.$http.post<IEntityMapping>(url, mapping).then((response) => response.data);
  }

  public deleteEntityMapping(mappingId: string): IPromise<boolean> {
    const url = this.env.getApiEndpoint("/entities/mappings/" + mappingId);

    return this.$http.delete<boolean>(url).then((response) => response.data);
  }

  public getEntityMappings(name: string): IPromise<IEntityMappingRequest[]> {
    const url = this.env.getApiEndpoint("/entities/blueprints/" + name + "/mappings");

    return this.$http.get<IEntityMappingRequest[]>(url).then((response) => response.data);
  }
}
