import { module } from "angular";
import angularJwt from "angular-jwt";
import { authProviderFactory } from "./auth-provider.factory";
import { AuthenticationResolverService } from "./authentication-resolver.service";
import { AuthorizationService } from "./authorization-service";
import { TokenProvider } from "./token-provider";

const mod = module("auth", [angularJwt]);

mod.service("AuthenticationResolverService", AuthenticationResolverService);
mod.service("TokenProvider", TokenProvider);
mod.service("AuthorizationService", AuthorizationService);
mod.factory("AuthProvider", authProviderFactory);

export default mod.name;
