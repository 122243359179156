<ui-select
  [(ngModel)]="uiSelectedTagTitles"
  [uiDisabled]="disabled"
  [uiLoading]="tagsLoading"
  [uiOptions]="assigning ? uiTagOptionsOnAssigning : uiTagOptions"
  [uiShowEmptyDropdown]="false"
  [uiPlaceHolder]="placeholderKey | localize"
  [uiAllowClear]="false"
  [uiShowArrow]="!borderless"
  [uiOptionHeightPx]="34"
  [a11yRequired]="a11yRequired"
  [a11yLabel]="a11yLabel | localize"
  [uiId]="uiId"
  [uiAutoFocus]="autofocus"
  [uiOpen]="open"
  [renderSelectedListItemIcon]="false"
  (ngModelChange)="onChangeModel($event)"
  (uiOnSearch)="search($event)"
  (uiFocus)="onFocus()"
  (uiBlur)="handleBlur()"
  (uiOpenChange)="handleOpenChange($event)"
  uiMode="multiple"
  uiServerSearch
  data-test-id="tags-autocomplete-input"
  ui-col>
</ui-select>

<error-notification *ngIf="errorNotification" [errorNotification]="errorNotification" placement="bottom"></error-notification>

<ng-template #createTagTemplate>
  <a class="add-item" data-test-id="create-new-tag">
    <i ui-icon uiType="plus"></i>
    {{ "create" | localize }} "{{ currentInputTyping }}"
  </a>
</ng-template>
