import { Injectable } from "@angular/core";
import { EmployeeEventType } from "@gtmhub/employees/services/events";
import { DashboardEventType } from "@gtmhub/insightboards/services/events";
import { KpiEventType, KpiGroupEventType } from "@gtmhub/kpis/services/events";
import { ListEventType } from "@gtmhub/lists/services/events";
import { GoalsChangedReason, IGoalChangedEventArgs, IMetricChangedEventArgs, OKRsEventType } from "@gtmhub/okrs/events";
import { OkrViewEventType } from "@gtmhub/okrs/okr-views/events";
import { PlanningSessionEventType } from "@gtmhub/sessions/services/events";
import { ITaskDeletedArgs, ITaskUpdatedArgs, TaskEventType } from "@gtmhub/tasks/task.events";
import { ITeam } from "@gtmhub/teams";
import { ITeamDeletedArgs, ITeamEventArgs, TeamEventType } from "@gtmhub/teams/events";
import { UXCustomizationType } from "@gtmhub/uxcustomization/models";
import { IWhiteboard } from "@gtmhub/whiteboards";
import { WhiteboardEventType } from "@gtmhub/whiteboards/events";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { Employee } from "@webapp/employees";
import { Insightboard } from "@webapp/insightboards/models";
import { KpiUiGroup } from "@webapp/kpis/models/kpi-group.models";
import { Kpi } from "@webapp/kpis/models/kpis.models";
import { List } from "@webapp/lists/models";
import { SubNavItemsModel } from "@webapp/navigation/utils/nav-items.util";
import { Session } from "@webapp/sessions/models/sessions.model";
import { ShareableFilter } from "@webapp/shared/unified-filters/models/shareable-filters.models";
import { Task } from "@webapp/tasks/models/tasks.models";

export interface IItemsChangeListenerDelegate {
  updateNavItem(uxcType: UXCustomizationType, item: SubNavItemsModel): void;
  deleteNavItem(uxcType: UXCustomizationType, item: SubNavItemsModel): void;
  addItemToViewHistory(uxcType: UXCustomizationType, item: SubNavItemsModel): void;
}

@Injectable({
  providedIn: "any",
})
export default class NavigationItemsChangeListener {
  private delegate: IItemsChangeListenerDelegate | null = null;

  constructor(private broadcastService: BroadcastService) {}

  public registerListeners(): void {
    if (this.delegate === null) {
      throw new Error("Navigation items change listener delegate not set!");
    }
    // INSIGHTBOARDS
    this.broadcastService.on<{ insightboard: Insightboard }>(DashboardEventType.DASHBOARD_UPDATED).subscribe(({ insightboard }) => {
      this.delegate.updateNavItem("dashboard", insightboard);
    });
    this.broadcastService.on<{ id: string }>(DashboardEventType.DASHBOARD_DELETED).subscribe(({ id }) => {
      this.delegate.deleteNavItem("dashboard", { id });
    });
    this.broadcastService.on<{ insightboard: Insightboard }>(DashboardEventType.DASHBOARD_VISITED).subscribe(({ insightboard }) => {
      this.delegate.addItemToViewHistory("dashboard", insightboard);
    });
    // WHITEBOARD
    this.broadcastService.on<{ id: string; name: string }>(WhiteboardEventType.WHITEBOARD_UPDATED).subscribe((whiteboard) => {
      this.delegate.updateNavItem("whiteboard", whiteboard);
    });
    this.broadcastService.on<{ whiteboard: IWhiteboard }>(WhiteboardEventType.WHITEBOARD_DELETED).subscribe(({ whiteboard }) => {
      this.delegate.deleteNavItem("whiteboard", whiteboard);
    });
    this.broadcastService.on<{ whiteboard: IWhiteboard }>(WhiteboardEventType.WHITEBOARD_VISITED).subscribe(({ whiteboard }) => {
      this.delegate.addItemToViewHistory("whiteboard", whiteboard);
    });
    // LISTS/REPORTS
    this.broadcastService.on<{ list: List }>(ListEventType.LIST_UPDATED).subscribe(({ list }) => {
      this.delegate.updateNavItem("list", list);
    });
    this.broadcastService.on<{ id: string }>(ListEventType.LIST_DELETED).subscribe(({ id }) => {
      this.delegate.deleteNavItem("list", { id });
    });
    this.broadcastService.on<{ list: List }>(ListEventType.LIST_VISITED).subscribe(({ list }) => {
      this.delegate.addItemToViewHistory("list", list);
    });
    // TEAMS
    this.broadcastService.on<ITeamEventArgs>(TeamEventType.TEAM_UPDATED).subscribe((teamUpdatedArgs) => {
      this.delegate.updateNavItem("team", teamUpdatedArgs.team);
    });
    this.broadcastService.on<ITeamDeletedArgs>(TeamEventType.TEAM_DELETED).subscribe((teamDeletedArgs) => {
      this.delegate.deleteNavItem("team", { id: teamDeletedArgs.teamId });
    });
    this.broadcastService.on<{ team: ITeam }>(TeamEventType.TEAM_VISITED).subscribe(({ team }) => {
      this.delegate.addItemToViewHistory("team", team);
    });
    // EMPLOYEES
    this.broadcastService.on<{ employee: Employee }>(EmployeeEventType.EMPLOYEE_VISITED).subscribe(({ employee }) => {
      this.delegate.addItemToViewHistory("user", employee);
    });
    this.broadcastService.on<{ id: string }>(EmployeeEventType.EMPLOYEE_DELETED).subscribe((employee) => {
      this.delegate.deleteNavItem("user", employee);
    });
    // TASKS
    this.broadcastService.on<ITaskUpdatedArgs>(TaskEventType.TASK_PATCHED).subscribe((taskUpdatedArgs) => {
      this.delegate.updateNavItem("task", taskUpdatedArgs.task);
    });
    this.broadcastService.on<ITaskDeletedArgs>(TaskEventType.TASK_DELETED_REQUEST_SUCCEEDED).subscribe((taskDeletedArgs) => {
      this.delegate.deleteNavItem("task", { id: taskDeletedArgs.task.id });
    });
    this.broadcastService.on<{ task: Task }>(TaskEventType.TASK_VISITED).subscribe(({ task }) => {
      this.delegate.addItemToViewHistory("task", task);
    });
    // SESSIONS
    this.broadcastService.on<{ session: Session }>(PlanningSessionEventType.SESSION_UPDATED).subscribe(({ session }) => {
      this.delegate.updateNavItem("session", session);
    });
    this.broadcastService.on<{ id: string }>(PlanningSessionEventType.SESSION_DELETED).subscribe((session) => {
      this.delegate.deleteNavItem("session", session);
    });
    this.broadcastService.on<{ session: Session }>(PlanningSessionEventType.SESSION_VISITED).subscribe(({ session }) => {
      this.delegate.addItemToViewHistory("session", session);
    });
    // KPIGROUP
    this.broadcastService.on<{ kpiGroup: KpiUiGroup }>(KpiGroupEventType.KPIGROUP_VISITED).subscribe(({ kpiGroup }) => {
      this.delegate.addItemToViewHistory("kpigroup", kpiGroup);
    });
    this.broadcastService.on<{ id: string }>(KpiGroupEventType.KPIGROUP_DELETED).subscribe((kpiGroup) => {
      this.delegate.deleteNavItem("kpigroup", kpiGroup);
    });
    this.broadcastService.on<{ kpiGroup: KpiUiGroup }>(KpiGroupEventType.KPIGROUP_UPDATED).subscribe(({ kpiGroup }) => {
      this.delegate.updateNavItem("kpigroup", kpiGroup);
    });
    // KPIS
    this.broadcastService.on<{ kpi: Kpi }>(KpiEventType.KPI_VISITED).subscribe(({ kpi }) => {
      this.delegate.addItemToViewHistory("kpi", kpi);
    });
    this.broadcastService.on<{ id: string }>(KpiEventType.KPI_DELETED).subscribe((kpi) => {
      this.delegate.deleteNavItem("kpi", kpi);
    });
    this.broadcastService.on<{ kpi: Kpi }>(KpiEventType.KPI_UPDATED).subscribe(({ kpi }) => {
      this.delegate.updateNavItem("kpi", kpi);
    });
    // GOALS
    this.broadcastService
      .on<{ reason: GoalsChangedReason; detail: IGoalChangedEventArgs["detail"] }>(OKRsEventType.OKRS_GOALS_CHANGED)
      .subscribe(({ reason, detail }) => {
        if (reason === "goalVisited") {
          this.delegate.addItemToViewHistory("goal", { id: detail.goal.id });
        }
      });
    // METRICS
    this.broadcastService
      .on<{ reason: GoalsChangedReason; detail: IMetricChangedEventArgs["detail"] }>(OKRsEventType.OKRS_GOALS_CHANGED)
      .subscribe(({ reason, detail }) => {
        if (reason === "metricVisited") {
          this.delegate.addItemToViewHistory("metric", detail.metric);
        }
      });
    // OKR VIEWS
    this.broadcastService.on<{ okrView: ShareableFilter }>(OkrViewEventType.OKRVIEW_UPDATED).subscribe(({ okrView }) => {
      this.delegate.updateNavItem("filter", okrView);
    });
    this.broadcastService.on<{ id: string }>(OkrViewEventType.OKRVIEW_DELETED).subscribe((okrView) => {
      this.delegate.deleteNavItem("filter", okrView);
    });
    this.broadcastService.on<{ okrView: ShareableFilter }>(OkrViewEventType.OKRVIEW_VISITED).subscribe(({ okrView }) => {
      this.delegate.addItemToViewHistory("filter", okrView);
    });
  }

  public setDelegate(delegate: IItemsChangeListenerDelegate): void {
    this.delegate = delegate;
  }
}
