/**
 * Both ModuleKey and ModuleFlag are used for generating the featureModuleMap.
 *
 * When you add a new value you need to add it to both enums because we strongly depend on the order of the values when we generate the featureModuleMap.
 */

export enum ModuleKey {
  Whiteboards = "whiteboards",
  Tasks = "tasks",
  Kpis = "kpis",
  Badges = "badges",
  Gif = "gif",
  Feed = "feed",
  Tags = "tags",
  Reflections = "reflections",
  PlatformIntelligence = "platform-intelligence",
}

export enum ModuleFlag {
  Whiteboards = "module-whiteboards",
  Tasks = "module-tasks",
  Kpis = "module-kpis",
  Badges = "module-badges",
  Gifs = "module-gifs",
  Feed = "module-feed",
  Tags = "module-tags",
  Reflections = "module-reflections",
  PlatformIntelligence = "module-platform-intelligence",
}
