import { RawParams, StateOrName, StateService } from "@uirouter/angular";
import { Injectable } from "@angular/core";
import { Observable, map, take } from "rxjs";
import { TrackingMetadata } from "@webapp/analytics/models/analytics.model";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { QueryParams } from "@webapp/core/abstracts/models/query-params.model";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { ICollection } from "@webapp/core/core.models";
import { ClipboardService } from "@webapp/core/util/services/clipboard.service";
import { ShareableFilter, ShareableFilterPatchDTO, ShareableFilterPostDTO } from "@webapp/shared/unified-filters/models/shareable-filters.models";
import { UiToastService } from "@webapp/ui/toast/services/toast.service";
import { ShareableFiltersApiService } from "./shareable-filters-api.service";
import { ShareableFiltersState } from "./shareable-filters-state.service";

@Injectable({
  providedIn: "any",
})
export class ShareableFiltersFacade extends BaseFacade<ShareableFilter, ShareableFilterPostDTO, ShareableFiltersState, ShareableFiltersApiService> {
  constructor(
    state: ShareableFiltersState,
    api: ShareableFiltersApiService,
    private stateService: StateService,
    private clipboardService: ClipboardService,
    private toastService: UiToastService,
    private analyticsService: AnalyticsService
  ) {
    super(state, api);
  }

  public getShareableFilterById$(filterId: string): Observable<ShareableFilter> {
    return this.get$(filterId, { ...new RequestConfig(), url: this.apiService.getSingleEndpoint(filterId) });
  }

  public getShareableFilters$(filter?: RequestPaging, queryParams?: QueryParams): Observable<ICollection<ShareableFilter>> {
    return this.getAll$(filter, {
      ...new RequestConfig(),
      url: this.apiService.getMultipleEndpoint(),
      queryParams,
    });
  }

  public postShareableFilter$(shareableFilterPayload: ShareableFilterPostDTO): Observable<ShareableFilter> {
    return this.post$(shareableFilterPayload, { ...new RequestConfig(), url: this.apiService.postSingleEndpoint() });
  }

  public patchShareableFilter$(filterId: string, shareableFilterPayload: ShareableFilterPatchDTO): Observable<ShareableFilter> {
    return this.patch$(filterId, shareableFilterPayload, { ...new RequestConfig(), url: this.apiService.patchSingleEndpoint(filterId) });
  }

  public deleteShareableFilter$(filterId: string): Observable<void> {
    return this.delete$(filterId, { ...new RequestConfig(), url: this.apiService.deleteSingleEndpoint(filterId) });
  }

  public copyShareableLinkWithAppliedFilterIdQuery(
    shareableFilterPayload: ShareableFilterPostDTO,
    stateOrName: StateOrName,
    params?: RawParams,
    meta?: TrackingMetadata
  ): void {
    this.generateShareableLinkWithFilterIdQuery(shareableFilterPayload, stateOrName, params)
      .pipe(take(1))
      .subscribe((link) => {
        this.clipboardService.copyToClipboard(link);
        this.analyticsService.track("OKR view copied to clipboard", { screen: stateOrName, ...meta });
      });
  }

  public copyShareableOkrViewLink(stateOrName: StateOrName, params?: RawParams, meta?: TrackingMetadata): void {
    const link = this.stateService.href(stateOrName, params, { absolute: true });
    this.clipboardService.copyToClipboard(link);
    this.analyticsService.track("OKR view copied to clipboard", { screen: stateOrName, ...meta });
  }

  public notifyFilterAccessRestricted(message: string): void {
    this.toastService.info(message, {
      uiPlacement: "bottom",
      uiOffset: 100,
      uiCloseable: true,
      uiShouldTimeout: true,
      uiDuration: 3000,
    });
  }

  private generateShareableLinkWithFilterIdQuery(shareableFilterPayload: ShareableFilterPostDTO, stateOrName: StateOrName, params?: RawParams): Observable<string> {
    return this.postShareableFilter$(shareableFilterPayload).pipe(
      map((filterResponse) => {
        return this.stateService.href(stateOrName, { ...params, filterId: filterResponse.id }, { absolute: true });
      })
    );
  }
}
