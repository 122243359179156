import { module } from "angular";
import upgrade from "@gtmhub/core/upgrade/module";
import { DisabledEditNamesComponent } from "./components/disabled-edit-names/disabled-edit-names.component";
import { VerifyUserEmailModalCtrl } from "./components/verify-email/verify-email-modal-ctrl";
import { VerifyEmailComponent } from "./components/verify-email/verify-email.component";
import { SendChangePasswordRequestCtrl } from "./controllers/send-change-password-request.ctrl";
import { UserLanguageCtrl } from "./controllers/user-language.ctrl";
import { UserProfileCtrl } from "./controllers/user-profile.ctrl";

const mod = module("user-profile", [upgrade]);

mod.controller("UserProfileCtrl", UserProfileCtrl);
mod.controller("SendChangePasswordRequestCtrl", SendChangePasswordRequestCtrl);
mod.controller("UserLanguageCtrl", UserLanguageCtrl);

mod.controller("VerifyUserEmailModalCtrl", VerifyUserEmailModalCtrl);
mod.component("verifyEmail", VerifyEmailComponent);

mod.component("disabledEditUserNames", DisabledEditNamesComponent);

export default mod.name;
