import { NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewEncapsulation } from "@angular/core";
import { UiPaginationI18nInterface } from "@quantive/ui-kit/i18n";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzPaginationI18nInterface } from "ng-zorro-antd/i18n";
import { NzPaginationItemComponent } from "ng-zorro-antd/pagination";
import { PaginationItemRenderContext, PaginationItemType } from "../../pagination.models";

@Component({
  selector: "button[ui-pagination-item]",
  preserveWhitespaces: false,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "pagination-item.component.html",
  host: {
    "[attr.title]": `null`,
    "[attr.aria-label]": `ariaLabel`,
    "[attr.aria-current]": `active ? 'page' : null`,
    "[class.ant-pagination-item-link]": `type !== 'page'`,
    "[disabled]": `disabled`,
  },
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, NgSwitchDefault, UiIconModule, NgTemplateOutlet],
})
export class UiPaginationItemComponent extends NzPaginationItemComponent {
  @Input() public active = false;
  @Input() public locale!: NzPaginationI18nInterface;
  @Input() public index: number | null = null;
  @Input() public disabled = false;
  @Input() public direction = "ltr";
  @Input() public type: PaginationItemType | string | null | undefined = null;
  @Input() public itemRender: TemplateRef<PaginationItemRenderContext> | null = null;

  @Output() public readonly diffIndex = new EventEmitter<number>();
  @Output() public readonly gotoIndex = new EventEmitter<number>();

  public get ariaLabel(): string {
    if (this.type === "page") {
      const prefix = this.active ? `${this.customLocale.current}, ` : "";
      return `${prefix}${this.locale.page} ${this.index}`;
    }

    if (this.type === "next_5") {
      return this.locale.next_5;
    }

    if (this.type === "prev_5") {
      return this.locale.prev_5;
    }

    return null;
  }

  private get customLocale(): UiPaginationI18nInterface {
    return this.locale as UiPaginationI18nInterface;
  }

  constructor() {
    super();
  }
}
