import { IGoal } from "@gtmhub/goals/models";
import { ITeam } from "@gtmhub/teams";
import { IdMap } from "@gtmhub/util";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { IQuantivePlusObjective } from "@webapp/platform-intelligence/quantive-plus/models";
import { generateAssignees } from "@webapp/platform-intelligence/quantive-plus/utils/utils";
import { PIStateProcessorInstanceSubType } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.models";
import { PIObjectiveSuggestionPayload } from "@webapp/platform-intelligence/shared/models/strategic-guided-okr.models";
import { Session } from "@webapp/sessions/models/sessions.model";
import { CreateObjectiveSuggestionDrawerNoSuggestionReasonsEnum, createObjectiveSuggestionDrawerStrategy } from "../models/create-objective-suggestion-drawer.models";

export const getCreateObjectiveSuggestionDrawerNoTitleSuggestionReason = (args: {
  isTitleAvailable: boolean;
  isSessionAvailable: boolean;
  isOwnerAvailable: boolean;
}): CreateObjectiveSuggestionDrawerNoSuggestionReasonsEnum => {
  if (args.isTitleAvailable) {
    return CreateObjectiveSuggestionDrawerNoSuggestionReasonsEnum.YOUR_OBJECTIVE_ALREADY_HAS_A_TITLE;
  }

  if (!args.isSessionAvailable && !args.isOwnerAvailable) {
    return CreateObjectiveSuggestionDrawerNoSuggestionReasonsEnum.ENTER_SESSION_AND_OWNER_TO_VIEW_SUGGESTIONS;
  }

  if (!args.isSessionAvailable) {
    return CreateObjectiveSuggestionDrawerNoSuggestionReasonsEnum.ENTER_SESSION_TO_VIEW_SUGGESTIONS;
  }

  if (!args.isOwnerAvailable) {
    return CreateObjectiveSuggestionDrawerNoSuggestionReasonsEnum.ENTER_OWNER_TO_VIEW_SUGGESTIONS;
  }
};

export const generateCreateObjectiveSuggestionDrawerTitlePayload = (args: {
  session: Session;
  ownerIds: string[];
  assigneesMap: IdMap<Assignee>;
  teams: ITeam[];
  parentOkr?: IGoal | Metric;
  isRefresh?: boolean;
  rejectedObjectives?: IQuantivePlusObjective[];
  subEntityType: PIStateProcessorInstanceSubType;
}): PIObjectiveSuggestionPayload => {
  const assignees = generateAssignees(args.ownerIds, args.assigneesMap, args.teams);
  const nSuggestions = 1;

  /* 
    The strategy name ("help me write an okr") is in agreement with the backend team in order to
    separate the API calls as currently the Create OKR Suggestion Drawer uses a Guided OKRs endpoint.    
  */
  return {
    subEntityType: args.subEntityType,
    strategy: createObjectiveSuggestionDrawerStrategy,
    sessionId: args.session.id,
    assignees: assignees,
    nSuggestions: nSuggestions,
    ...(args.parentOkr && {
      parentTitle: args.parentOkr.name,
      parentDescription: args.parentOkr.description || "",
    }),
    ...(args.isRefresh && {
      refresh: true,
    }),
    ...(args.rejectedObjectives && {
      rejected: {
        objectives: args.rejectedObjectives,
      },
    }),
  };
};
