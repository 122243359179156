import { StateService } from "@uirouter/angular";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from "@angular/core";
import { Observable, Subscription, firstValueFrom, map, throwError } from "rxjs";
import { UIErrorHandlingService, createUIError } from "@gtmhub/error-handling";
import { IBulkActionOnTeamsResponse, ITeam } from "@gtmhub/teams";
import { IBulkActionOnUsersResponse, IUser } from "@gtmhub/users";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { AssigneeType } from "@webapp/assignees/models/assignee.models";
import { TracingService } from "@webapp/core/tracing/services/tracing.service";
import { localize } from "@webapp/localization/utils/localization.utils";
import { ActionType } from "@webapp/teams/models/status-actions.models";
import { TeamsFacade } from "@webapp/teams/services/teams-facade.service";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";
import { UiModalOptions } from "@webapp/ui/modal/modal.models";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { UiNotificationService } from "@webapp/ui/notification/services/notification.service";
import { UsersFacade } from "@webapp/users/services/users-facade.service";

type ActionKey = `${ActionType}_${AssigneeType}`;

const titleKeyMap: Record<ActionKey, string> = {
  activate_user: "r_u_sure_activate_named_acc",
  deactivate_user: "r_u_sure_deactivate_named_acc",
  delete_user: "r_u_sure_remove_named_acc",
  activate_team: "activate_team_question",
  deactivate_team: "deactivate_team_question",
  delete_team: "delete_team_question",
};

const descriptionKeyMap: Record<ActionKey, string> = {
  activate_user: null,
  deactivate_user: "once_deactivate_cant_recover_user_data",
  delete_user: "once_removed_cant_recover_user_data",
  activate_team: "activate_team_warning",
  deactivate_team: "deactivate_team_warning",
  delete_team: "delete_team_warning",
};

const statusChangedMap: Record<ActionKey, string> = {
  deactivate_team: "team_x_deactivated",
  activate_team: "team_x_activated",
  delete_team: "team_x_deleted",
  deactivate_user: "user_x_deactivated",
  activate_user: "user_x_activated",
  delete_user: "user_x_deleted",
};

@Component({
  selector: "status-action",
  templateUrl: "./status-action.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusActionComponent implements OnDestroy {
  @ViewChild("notificationTemplate")
  public notificationTemplate: TemplateRef<object>;
  @ViewChild("usersLimitTemplate")
  public usersLimitTemplate: TemplateRef<object>;

  @Input() public entity: IUser | ITeam;
  @Input() public entityType: AssigneeType;
  @Input() public actionType: ActionType;
  @Input() public openDetailsPath: {
    path: string;
    paramName: string;
  };

  @Output() public readonly actionSuccess: EventEmitter<object> = new EventEmitter();

  get actionKey(): ActionKey {
    return `${this.actionType}_${this.entityType}`;
  }

  get viewEntityKey(): string {
    return this.entityType === "team" ? "view_team" : "view_profile";
  }

  get statusChangedTextKey(): string {
    return statusChangedMap[`${this.actionType}_${this.entityType}`];
  }

  public confirmationModal: UiModalRef;
  public usersLimitReachedModal: UiModalRef<TemplateRef<object>>;
  public modalSubscriptions: Subscription;
  public notificationId: string;

  constructor(
    private stateService: StateService,
    private modalService: UiModalService,
    private teamsFacade: TeamsFacade,
    private usersFacade: UsersFacade,
    private notificationsService: UiNotificationService,
    private tracingService: TracingService,
    private analyticsService: AnalyticsService,
    private errorHandlingService: UIErrorHandlingService
  ) {}

  public ngOnDestroy(): void {
    this.destroyModals();
  }

  public confirmAction(): void {
    this.destroyModals();

    if (this.actionKey == "activate_user" && (this.entity as IUser).subscriptionType !== "restricted") {
      this.checkUsersLimitReached();
    } else {
      this.openConfirmationModal();
    }
  }

  private checkUsersLimitReached(): void {
    this.usersFacade.getUsersLimit$(1).subscribe({
      next: (payload) => {
        const maximumUsersLimitReached = payload.hasLimitSet && payload.isLimitReached;
        if (maximumUsersLimitReached) {
          this.openUsersLimitReachedWarningModal();
        } else {
          this.openConfirmationModal();
        }
      },
      error: (response) => {
        this.errorHandlingService.handleModal(response);
      },
    });
  }

  private openConfirmationModal(): void {
    const modalOptions: UiModalOptions = {
      uiTitle: localize(titleKeyMap[this.actionKey], { name: this.entity.name }),
      uiContent: localize(descriptionKeyMap[this.actionKey]),
      uiOkText: localize(this.actionType),
      uiOnOk: () => this.performAction(),
      uiCancelText: localize("cancel"),
      uiOnCancel: () => this.destroyModals(),
    };
    if (this.actionType === "delete") {
      modalOptions.uiIconType = null;
      modalOptions.uiOkDanger = true;
    }
    this.confirmationModal = this.modalService.warning(modalOptions);
  }

  private openUsersLimitReachedWarningModal(): void {
    this.usersLimitReachedModal = this.modalService.create({
      uiContent: this.usersLimitTemplate,
      uiFooter: null,
    });
  }

  public contactUs(): void {
    this.analyticsService.contactSales("contactSales");
    this.destroyModals();
  }

  public openEntityDetails(): void {
    const { path, paramName } = this.openDetailsPath;
    this.stateService.go(path, { [paramName]: this.entity.id });

    this.notificationsService.remove(this.notificationId);
  }

  private performAction(): Promise<unknown> {
    return this.tracingService.traceAction(this.actionKey, () =>
      firstValueFrom(this.perform()).then(
        (payload) => {
          if (payload.hasFailures) {
            const title = `An error occurred while trying to ${this.actionType} the ${this.entityType}.`;
            return Promise.reject(title);
          }

          this.destroyModals();
          this.actionSuccess.emit({ ...payload, action: this.actionType });
          this.showSuccessNotification();
        },
        (response) => Promise.reject(createUIError(response).title)
      )
    );
  }

  public destroyModals(): void {
    if (this.modalSubscriptions) {
      this.modalSubscriptions.unsubscribe();
      this.modalSubscriptions = null;
    }

    if (this.confirmationModal) {
      this.confirmationModal.close();
      this.confirmationModal = null;
    }

    if (this.usersLimitReachedModal) {
      this.usersLimitReachedModal.close();
      this.usersLimitReachedModal = null;
    }
  }

  private perform(): Observable<IBulkActionOnUsersResponse | IBulkActionOnTeamsResponse> {
    switch (this.actionKey) {
      case "activate_user":
        return this.usersFacade.activateUser$(this.entity);
      case "deactivate_user":
        return this.usersFacade.deactivateUser$(this.entity);
      case "delete_user":
        return this.usersFacade.deleteUser$(this.entity);
      case "activate_team":
        return this.teamsFacade.activateTeam$(this.entity);
      case "deactivate_team":
        return this.teamsFacade.deactivateTeam$(this.entity);
      case "delete_team":
        return this.teamsFacade.deleteTeam$(this.entity).pipe(map(() => ({ hasFailures: false })));
      default:
        return throwError(() => ({ title: `Unknown action type ${this.actionType} for entity ${this.entityType}` }));
    }
  }

  private showSuccessNotification(): void {
    const notification = this.notificationsService.template(this.notificationTemplate, {
      notificationPlacement: "bottomRight",
      // provide the current action-related texts as context variables as the notification is opened asynchronously
      // and a new value for this.actionType is already provided to this component when the notification is rendered,
      // resulting in a wrong value being displayed if this.actionType is read from the component
      notificationData: {
        actionType: this.actionType,
        statusChangedText: localize(this.statusChangedTextKey, { name: this.entity.name }),
      },
    });
    this.notificationId = notification.messageId;
  }
}
