import { StateService, UIRouterModule } from "@uirouter/angular";
import { AsyncPipe, CommonModule, NgForOf, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnChanges, ViewChild } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { SimpleChangesOf } from "@quantive/ui-kit/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTabComponent, UiTabSetComponent } from "@quantive/ui-kit/tab";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { DropdownModule } from "@webapp/shared/dropdown/dropdown.module";
import { Tab } from "@webapp/shared/models/tabset-builder.models";
import { actionHandlerGenerator } from "@webapp/top-nav-bar/utils/action-handler";

@Component({
  selector: "top-nav-bar-tabs",
  standalone: true,
  imports: [AsyncPipe, NgIf, UiTabSetComponent, UiTabComponent, NgForOf, LocalizationModule, UIRouterModule, DropdownModule, CommonModule, UiIconModule, UiButtonModule],
  templateUrl: "./top-nav-bar-tabs.component.html",
  styleUrl: "./top-nav-bar-tabs.component.less",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopNavBarTabsComponent implements OnChanges {
  @Input()
  public tabs: Tab[];

  @Input()
  public initialActiveTabKey: string;

  @Input()
  public isRelaxedMode: boolean = true;

  @ViewChild("currentTab") protected currentTab: ElementRef;
  @ViewChild("tabsMenu") protected tabsMenu: ElementRef;

  public activeTabKey: string;
  public activeTabIndex: number;

  constructor(
    private stateService: StateService,
    private readonly cdr: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    private zone: NgZone
  ) {}

  public ngOnChanges(changes: SimpleChangesOf<TopNavBarTabsComponent>): void {
    if (changes.initialActiveTabKey?.currentValue) {
      this.activeTabKey = changes.initialActiveTabKey.currentValue;
      this.setSelected();
    }
    if (changes.isRelaxedMode && !changes.isRelaxedMode?.currentValue && changes.isRelaxedMode.currentValue !== changes.isRelaxedMode.previousValue) {
      // this is needed so the overlay to be located correctly when the tabs are displayed in a dropdown
      this.zone.run(() => {
        this.cdr.markForCheck();
      });
    }

    // This is outside of the above if since there was an async problem
    // with us setting the tab index and ngzorro changing it but not emitting the first change.
    this.setActiveTabIndex();
  }

  public actionHandler(tab: Tab): void {
    if (tab.track) {
      this.analyticsService.track(tab.track, tab.trackMetaData);
    }

    actionHandlerGenerator(tab.action, { stateService: this.stateService, window })();
    this.activeTabKey = tab.key;
    this.setActiveTabIndex();
    this.setSelected();
  }

  public dropdownSelectedChange(item: DropdownMenuItem): void {
    if (item != null) {
      this.activeTabKey = item.key;
      this.setActiveTabIndex();
      this.setSelected();
    }
  }

  public get activeTab(): Tab {
    return this.tabs.find((tab) => tab.key === this.activeTabKey);
  }

  private setSelected(): void {
    this.tabs.forEach((item) => {
      item.selected = item.key === this.activeTabKey;
    });

    this.cdr.markForCheck();
  }

  private setActiveTabIndex(): void {
    this.activeTabIndex = this.tabs.findIndex((tab) => tab.key === this.activeTabKey) || 0;
  }
}
