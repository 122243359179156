import { Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "pi-suggestion-drawer-side-modal-wrapper",
  templateUrl: "./suggestion-drawer-side-modal-wrapper.component.html",
  styleUrls: ["./suggestion-drawer-side-modal-wrapper.component.less"],
})
export class SuggestionDrawerSideModalWrapperComponent {
  @Input() public isOpen = false;

  @HostBinding("class")
  public get hostClasses(): string[] {
    const visibilityState = this.isOpen ? "open" : "closed";
    return ["suggestion-drawer", `suggestion-drawer-${visibilityState}`];
  }
}
