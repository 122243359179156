<ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
<div class="title">{{ titleKey | localize: { names: notification.notificationData.actor.names } }}</div>
<div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
<div class="summary">
  <div class="message-icon">
    <i class="fs-20px" [uiTooltipTitle]="icon | localize" [uiType]="icon" ui-icon ui-tooltip uiTheme="fill"></i>
  </div>
  <span class="item">{{ notification.notificationData.item.title }}</span>
</div>
<div class="full">
  <div class="inbox-box">
    <div class="inbox-section" *ngIf="notification.notificationData.item.fieldValue">
      <div class="heading inline-notifications">{{ notification.notificationData.item.customFieldLabel || (headingKey | localize) }}</div>
      : <rich-text-viewer [text]="notification.notificationData.item.fieldValue"></rich-text-viewer>
    </div>
    <div class="inbox-section">
      <a class="link classic" [attr.href]="notification.notificationData.item.targetUrl">{{ "view" | localize }} {{ targetItemKey | localize }}</a>
    </div>
  </div>
</div>
