<ui-row class="sp-pt-20 sp-pb-12" uiAlign="middle" uiJustify="center">
  <ui-col class="favorite-icon-wrapper">
    <i class="fs-32px" ui-icon uiTheme="outline" uiType="favorite"></i>
  </ui-col>
</ui-row>
<ui-row uiAlign="middle" uiJustify="center">
  <ui-col class="sp-px-12">
    <h3>{{ "favorite_items" | localize }}</h3>
  </ui-col>
</ui-row>
<ui-row class="sp-pt-10 sp-pb-10" uiAlign="middle" uiJustify="center">
  <ui-col class="sp-pt-4 sp-px-10">
    <p>{{ "empty_home_text" | localize }}</p>
  </ui-col>
</ui-row>
<ui-row uiAlign="middle" uiJustify="center">
  <ui-col>
    <a tabindex="-1" href="http://help.quantive.com/en/articles/6808330-add-items-to-favorites-new-gtmhub-experience" target="_blank"
      ><span class="sp-px-2">{{ "learn_how" | localize }}</span
      ><i ui-icon uiType="external-link"></i
    ></a>
  </ui-col>
</ui-row>
