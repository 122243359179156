import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, map, take } from "rxjs";
import { INotification } from "@gtmhub/notifications/models/notifications";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { ICollection } from "@webapp/core/core.models";
import { NotificationDTO } from "@webapp/users/models/notifications.models";
import { NotificationsApiService } from "./notifications-api.service";
import { NotificationsState } from "./notifications-state.service";

@Injectable({
  providedIn: "root",
})
export class NotificationsFacade extends BaseFacade<INotification, NotificationDTO, NotificationsState, NotificationsApiService> {
  private unreadNotificationsCount$: BehaviorSubject<number>;

  constructor(state: NotificationsState, api: NotificationsApiService) {
    super(state, api);
  }

  public getUnreadNotificationsCount$(): Observable<number> {
    if (!this.unreadNotificationsCount$) {
      this.unreadNotificationsCount$ = new BehaviorSubject(null);
      this.getUserUnreadNotificationsCount();
    }

    return this.unreadNotificationsCount$.asObservable();
  }

  public updateUnreadNotificationsCount(count: number): void {
    if (!this.unreadNotificationsCount$) {
      this.unreadNotificationsCount$ = new BehaviorSubject(null);
    }

    this.unreadNotificationsCount$.next(count);
  }

  public getNotificationsV2$(filter?: RequestPaging): Observable<ICollection<INotification>> {
    return this.getAll$<ICollection<INotification>>(filter, { ...new RequestConfig(), url: this.apiService.getNotificationsV2Endpoint() });
  }

  private getUserUnreadNotificationsCount(): void {
    // lack of unread notifications count endpoint, workaround are these query parameters
    const filter = { skip: 0, take: 1 };
    const config = { ...new RequestConfig(), queryParams: { seen: false } };

    this.getAll$(filter, config)
      .pipe(
        map((notifications) => {
          return notifications.totalCount;
        }),
        take(1)
      )
      .subscribe((count) => this.unreadNotificationsCount$.next(count));
  }
}
