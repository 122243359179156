<ui-card [uiBordered]="false" [uiHeadBorderless]="true" [uiRounded]="true" [uiExtra]="extraHeaderContentTemplate" [uiTitle]="title">
  <ng-template #extraHeaderContentTemplate>
    <widget-actions [widgetId]="widgetId" [canRemove]="true" (remove)="remove()" />
  </ng-template>

  <ui-alert *ngIf="error" [uiMessage]="error" [uiCloseable]="true" uiType="error"></ui-alert>

  <div class="boxes-container">
    <div class="boxes">
      <div class="box box-blue" (click)="openSelectHowToStartModal()" (keydown.enter)="openSelectHowToStartModal()" tabindex="0">
        <span class="icon-container"><i uiType="goal" ui-icon></i></span>
        <div class="title">{{ "set_company_and_team_objectives" | localize }}</div>
        <button class="ui-link" tabindex="-1">{{ "create_okr" | localize }}</button>
      </div>
      <div
        class="box box-purple"
        *hasAllPermissions="'ManageUsers'"
        [ghTrackMeta]="{ location: 'OnboardingWidget' }"
        (click)="openInvitationModal()"
        (keydown.enter)="openInvitationModal()"
        tabindex="0"
        ghTrack="User Invited">
        <span class="icon-container"><i uiType="users-group" ui-icon></i></span>
        <div class="title">{{ "add_colleagues_to_execute" | localize }}</div>
        <button class="ui-link" tabindex="-1">{{ "invite_teammate" | localize }}</button>
      </div>
      <div
        class="box box-purple"
        *hasAllPermissions="'ManageUsers'; negate: true"
        [ghTrackMeta]="{ location: 'OnboardingWidget' }"
        (click)="visitMarketplace()"
        (keydown.enter)="visitMarketplace()"
        tabindex="0"
        ghTrack="Browse the Marketplace">
        <span class="icon-container"><i uiType="add-from-marketplace" ui-icon></i></span>
        <div class="title">{{ "add_okr_templates_from_marketplace" | localize }}</div>
        <button class="ui-link" tabindex="-1" ui-external-link>
          {{ "browse_the_Marketplace" | localize }}
        </button>
      </div>
      <div
        class="box box-yellow"
        [ghTrackMeta]="{ location: 'OnboardingWidget' }"
        (click)="createDemoAccount()"
        (keydown.enter)="createDemoAccount()"
        tabindex="0"
        ghTrack="Check out Demo Account Clicked">
        <span class="icon-container"><i uiType="live-demo" ui-icon></i></span>
        <div class="title">{{ "launch_live_demo" | localize }}</div>
        <button class="ui-link" tabindex="-1" ui-external-link>
          {{ "play_with_the_demo" | localize }}
        </button>
      </div>
    </div>
  </div>
</ui-card>
