import { Injectable } from "@angular/core";
import { Observable, switchMap, take } from "rxjs";
import { storage } from "@gtmhub/core/storage";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { localize } from "@webapp/localization/utils/localization.utils";
import { loadModule } from "@webapp/okrs/okrs.future-states";
import { LazyModalService } from "@webapp/shared/modal/lazy-modal.service";
import { cancelButton, primaryButton } from "@webapp/shared/modal/modal.utils";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";
import type { UpdateMetricComponent, UpdateMetricModalData } from "../components/update-metric/update-metric.component";

@Injectable({
  providedIn: "root",
})
export class UpdateMetricModalService {
  constructor(
    private readonly lazyModalService: LazyModalService,
    private analyticsService: AnalyticsService
  ) {}

  public openUpdateMetricModal(data?: UpdateMetricModalData): Observable<void> {
    const updateMetricModal$: Observable<UiModalRef<UpdateMetricComponent, UpdateMetricModalData>> = this.lazyModalService.create({
      lazyConfig: { loadModule, component: (mod) => mod.UpdateMetricComponent },
      uiTitle: localize("update_key_result"),
      uiData: data,
      uiFooter: [
        primaryButton(localize("update"), {
          disabled: (instance) => !!instance.indicators.saving || instance.loading || !instance.isUpdateAllowed(),
          show: (instance) => instance.isActionAllowed("update"),
          onClick: (instance) => {
            this.analyticsService.track("KR Updated", {
              metricId: data.metricId,
            });
            storage.remove("modalCloseReason");
            return instance.save();
          },
        }),
        cancelButton({
          onClick: (instance, modalRef) => {
            storage.remove("modalCloseReason");
            modalRef.close();
          },
        }),
      ],
      uiWidth: 657,
    });

    return updateMetricModal$.pipe(
      switchMap((modal) => {
        modal.containerInstance.containerClick.pipe(take(1)).subscribe(() => {
          storage.set("modalCloseReason", "backdrop click");
        });

        modal.containerInstance.cancelTriggered.pipe(take(1)).subscribe(() => {
          storage.remove("modalCloseReason");
        });

        return modal.afterOpen;
      })
    );
  }
}
