<a>
  <div class="search-item-container">
    <i class="fs-16px sp-mt-3" *ngIf="!showAvatar" [uiType]="icon" [uiTooltipTitle]="icon" [attr.aria-label]="getItemType(item.collectionName)" ui-icon uiTheme="fill" ui-tooltip></i>
    <ui-assignee-avatar *ngIf="showAvatar" [fromAssigneeId]="item.id" uiSize="xs"></ui-assignee-avatar>
    <!--   Different collections have different property which contains the name :( -->
    <div
      class="name"
      [innerHTML]="item.fields['title'] || item.fields['fullName'] || item.fields['name'] || item.fields['names'] | bold: (searchTerm$ | async)"
      [ngClass]="{ 'fs-15px': searchMode === 'search-page', 'name-truncation': searchMode === 'search-page' }"></div>
  </div>
  <ng-container class="avatars" *ngIf="searchMode !== 'simple' && getAssigneeIds(item.collectionName) as assigneeIds">
    <ui-assignee-avatar-group [uiAlignRight]="true" [uiMax]="2" uiSize="xs">
      <ui-assignee-avatar *ngFor="let aId of assigneeIds" [fromAssigneeId]="aId" />
    </ui-assignee-avatar-group>
  </ng-container>
</a>
