// We import flat-map manually because of a client using Edge 17.
// Normally, these polyfills are generated automatically based on
// browserslist, but since we do not want to import all missing
// features of Edge 17 (plus it creates some other compilation issues
// with RegExp), we do it manually.
import { HotkeyModule } from "angular2-hotkeys";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { DoBootstrap, ErrorHandler, NgModule } from "@angular/core";
// eslint-disable-next-line no-restricted-imports
import { BrowserModule } from "@angular/platform-browser";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { UpgradeModule } from "@angular/upgrade/static";
import { UiIconModule, defaultIcons } from "@quantive/ui-kit/icon";
import "core-js/modules/es.array.flat-map.js";
import { NzNoAnimationModule } from "ng-zorro-antd/core/no-animation";
import { NZ_ICONS_USED_BY_ZORRO } from "ng-zorro-antd/icon";
import { shouldUseQuantiveUrlBehavior } from "@gtmhub/env";
import "@gtmhub/shared/smartbanner";
import { resultsIcons } from "../icons";
import { AccountsModule } from "./accounts/accounts.module";
import { PricingEditionServiceProvider } from "./accounts/providers/pricing-edition.provider";
import { UibModalStackProvider } from "./accounts/providers/uib-modal-stack.provider";
import { AccountResolverService } from "./accounts/services/account-resolver.service";
import { AnalyticsModule } from "./analytics/analytics.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AssigneesModule } from "./assignees/assignees.module";
import { ConfigurationCoreModule } from "./configuration/configuration-core.module";
import { CoreModule } from "./core/core.module";
import { ApmErrorHandler } from "./core/tracing/error-handler";
import { ApmServiceProvider } from "./core/tracing/home.provider";
import { FavoritesModule } from "./favorites/favorites.module";
import { FeatureTogglesFacadeModule } from "./feature-toggles/feature-toggles-facade.module";
import { HomeModule } from "./home/home.module";
import { IntegrationsModule } from "./integrations/integrations.module";
import { NavigationModule } from "./navigation/navigation.module";
import { NotificationsModule } from "./notifications/notifications.module";
import { OkrsCoreModule } from "./okrs/okrs-core.module";
import { PermissionsModule } from "./permissions/permissions.module";
import { PrivacyNoticeModule } from "./shared/components/privacy-notice/privacy-notice.module";
import { DowngradedSharedModule } from "./shared/downgraded-shared.module";
import { kendoIconSettingsServiceProvider } from "./shared/kendo/icons/kendo-icons.service";
import { SmartBannerTrackingService } from "./shared/libs/smartbanner/smart-banner.service";
import { ShortNumberModule } from "./shared/pipes/short-number/short-number.module";
import { DocumentExporterProvider } from "./shared/providers/document-exporter.providers";
import { TopNavBarModule } from "./top-nav-bar/top-nav-bar.module";
import { UiNotificationService } from "./ui/notification/services/notification.service";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    NzNoAnimationModule,
    CoreModule,
    TopNavBarModule,
    UpgradeModule,
    NavigationModule,
    PermissionsModule,
    FavoritesModule,
    AccountsModule,
    AppRoutingModule,
    UiIconModule.forRoot([...NZ_ICONS_USED_BY_ZORRO, ...defaultIcons, ...resultsIcons]),
    NotificationsModule,
    AssigneesModule,
    ConfigurationCoreModule,
    DowngradedSharedModule,
    HomeModule,
    AnalyticsModule.forRoot(),
    OkrsCoreModule,
    ShortNumberModule.forRoot(),
    IntegrationsModule,
    FeatureTogglesFacadeModule,
    PrivacyNoticeModule,
    HotkeyModule.forRoot({ disableCheatSheet: true }),
  ],
  providers: [
    UiNotificationService,
    kendoIconSettingsServiceProvider(),
    AccountResolverService,
    UibModalStackProvider,
    PricingEditionServiceProvider,
    DocumentExporterProvider,
    ApmServiceProvider,
    SmartBannerTrackingService,
    { provide: ErrorHandler, useClass: ApmErrorHandler },
    ...(shouldUseQuantiveUrlBehavior() ? [{ provide: LocationStrategy, useClass: PathLocationStrategy }] : []),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule implements DoBootstrap {
  constructor(public smartBannerService: SmartBannerTrackingService) {
    this.smartBannerService.trackSmartBanner();
  }

  public ngDoBootstrap(): void {
    // eslint-disable-next-line no-console
    console.log(`
                               ////////
                        ///////////////
                     //////////////////
                   ////////////////////
                 //////////////////////
               /////////////
             /////////////
           /////////////
//////////////////////
////////////////////
//////////////////
///////////////

Find out why we care
-> https://quantive.com/careers


`);
  }
}
