import { IRootScopeService } from "angular";
import { NgZone } from "@angular/core";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";
import { UiConfirmType, UiModalOptions, UiOnClickCallback } from "@webapp/ui/modal/modal.models";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";

export class Ng1UiModalService {
  static $inject = ["$rootScope", "ngZone", "UiModalService"];

  constructor(
    private $rootScope: IRootScopeService,
    private ngZone: NgZone,
    private modalService: UiModalService
  ) {}

  /**
   * This service is a wrapper over the original modal service and fires a new digest cycle manually in ng2 when modal is opened
   * since modals can not properly evaluate ng2 digest cycles when being open from ng1 components, states or state-transitions
   */

  public create<T, D = NzSafeAny, R = NzSafeAny>(config: UiModalOptions<T, D, R>): UiModalRef<T, R> {
    this.patchConfig(config);
    return this.ngZone.run(() => this.modalService.create<T, D, R>(config));
  }

  public confirm<T, D>(options: UiModalOptions<T> = {}, confirmType: UiConfirmType = "confirm"): UiModalRef<T> {
    this.patchConfig(options);
    return this.ngZone.run(() => this.modalService.confirm<T, D>(options, confirmType));
  }

  public error<T>(options: UiModalOptions<T> = {}): UiModalRef<T> {
    this.patchConfig(options);
    return this.ngZone.run(() => this.modalService.error<T>(options));
  }

  public warning<T>(options: UiModalOptions<T> = {}): UiModalRef<T> {
    this.patchConfig(options);
    return this.ngZone.run(() => this.modalService.warning<T>(options));
  }

  public success<T>(options: UiModalOptions<T> = {}): UiModalRef<T> {
    this.patchConfig(options);
    return this.ngZone.run(() => this.modalService.success<T>(options));
  }

  public info<T>(options: UiModalOptions<T> = {}): UiModalRef<T> {
    this.patchConfig(options);
    return this.ngZone.run(() => this.modalService.info<T>(options));
  }

  private patchConfig<T, D = NzSafeAny, R = NzSafeAny>(config: UiModalOptions<T, D, R>): void {
    const { uiOnOk, uiOnCancel } = config;
    if (uiOnOk && typeof uiOnOk === "function") {
      config.uiOnOk = this.patchCallback(uiOnOk as UiOnClickCallback<T>);
    }
    if (uiOnCancel && typeof uiOnCancel === "function") {
      config.uiOnCancel = this.patchCallback(uiOnCancel as UiOnClickCallback<T>);
    }
    if (config.uiFooter && Array.isArray(config.uiFooter)) {
      for (const button of config.uiFooter) {
        if (button.onClick) {
          button.onClick = this.patchCallback(button.onClick);
        }
      }
    }
  }

  private patchCallback<T>(callback: UiOnClickCallback<T>): UiOnClickCallback<T> {
    const $rootScope = this.$rootScope;
    return function ng1PatchedCallback(this: unknown, ...args: unknown[]) {
      const result = callback.apply(this, args);
      $rootScope.$evalAsync();
      return result;
    };
  }
}
