import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnChanges, OnInit, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, EMPTY, Observable, catchError, finalize, map, noop, take } from "rxjs";
import { IndicatorMap } from "@gtmhub/error-handling/models";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { SimpleChangesTyped } from "@webapp/shared/models";
import { ItemAutomationSelectorData, ItemAutomationSelectorUsageType, ItemAutomationTargetType } from "../../models/item-automation-selector.models";
import { ItemAutomationSuggestion } from "../../models/item-automation-suggestions.models";
import { AutomatingItemBrowserModalManager } from "../../utils/automating-item-browser-modal-manager";
import { ItemAutomationSuggestionsManager } from "../../utils/item-automation-suggestions-manager";

export type ExtendWithWriteValueOriginCheck<T> = {
  data: T;
  hasWriteValueOrigin: boolean;
};

@UntilDestroy()
@Component({
  selector: "item-automation-selector",
  templateUrl: "./item-automation-selector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ItemAutomationSelectorComponent),
      multi: true,
    },
  ],
})
export class ItemAutomationSelectorComponent<TargetType extends ItemAutomationTargetType> implements ControlValueAccessor, OnInit, OnChanges {
  private automatingItemBrowserModalManager: AutomatingItemBrowserModalManager;
  private itemAutomationSuggestionsManager: ItemAutomationSuggestionsManager;

  @Input({ required: true }) public itemType: TargetType;
  @Input({ required: true }) public itemTitle: string;
  @Input({ required: true }) public isAutomationEditable: boolean;
  @Input({ required: true }) public isAutomationDeletable: boolean;
  @Input({ required: true }) public usedIn: ItemAutomationSelectorUsageType;

  public get showSuggestions(): boolean {
    return this.automationSuggestions.length > 0;
  }
  public automationSuggestions: ItemAutomationSuggestion[] = [];
  public indicators: IndicatorMap<"loadingSuggestions"> = { loadingSuggestions: null };
  public selectorData$: BehaviorSubject<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorData<"kr"> | ItemAutomationSelectorData<"kpi">>> = new BehaviorSubject<
    ExtendWithWriteValueOriginCheck<ItemAutomationSelectorData<"kr"> | ItemAutomationSelectorData<"kpi">>
  >(null);

  constructor(
    private cdr: ChangeDetectorRef,
    injector: Injector,
    private analyticsService: AnalyticsService
  ) {
    this.automatingItemBrowserModalManager = new AutomatingItemBrowserModalManager(injector, this.selectorData$);
    this.itemAutomationSuggestionsManager = new ItemAutomationSuggestionsManager(injector, this.selectorData$);
  }

  public writeValue(value: ItemAutomationSelectorData<"kr"> | ItemAutomationSelectorData<"kpi">): void {
    this.selectorData$.next({ data: value, hasWriteValueOrigin: true });
  }

  public notifyControlChange: (value: ItemAutomationSelectorData<"kr"> | ItemAutomationSelectorData<"kpi">) => void;

  public registerOnChange(
    fn: (value: ItemAutomationSelectorData<"kr"> | ItemAutomationSelectorData<"kpi">) => ItemAutomationSelectorData<"kr"> | ItemAutomationSelectorData<"kpi">
  ): void {
    this.notifyControlChange = fn;
  }

  public registerOnTouched = noop;

  public ngOnInit(): void {
    this.automatingItemBrowserModalManager.usedIn = this.usedIn;

    this.selectorData$.pipe(untilDestroyed(this)).subscribe((selectorData) => {
      this.notifyControlChange?.(selectorData?.data);
    });
  }

  public ngOnChanges({ itemTitle }: SimpleChangesTyped<ItemAutomationSelectorComponent<TargetType>>): void {
    if (itemTitle && itemTitle.currentValue !== itemTitle.previousValue) {
      this.automate({ openBrowseAutomationItemsModal: false });
    }
  }

  public onAutomate(): void {
    this.analyticsService.track("Automation Selector Automate Button Clicked", {
      location: this.usedIn,
    });

    this.automate({ openBrowseAutomationItemsModal: true });
  }

  public get isAutomated$(): Observable<boolean> {
    return this.selectorData$.pipe(
      untilDestroyed(this),
      map((selectorData) => {
        const data = selectorData?.data;
        return (data?.selectionType === "insight" && !!data?.insightName) || (data?.selectionType === "kpi" && !!data?.kpiId);
      })
    );
  }

  private automate({ openBrowseAutomationItemsModal }: { openBrowseAutomationItemsModal?: boolean }): void {
    if (this.itemTitle) {
      this.updateSuggestions({ openBrowseAutomationItemsModal });
    } else {
      this.setEmptySuggestions$().subscribe();

      if (openBrowseAutomationItemsModal) {
        this.openBrowseAutomationItemsModal();
      }
    }
  }

  private updateSuggestions({ openBrowseAutomationItemsModal }: { openBrowseAutomationItemsModal?: boolean } = {}): void {
    this.indicators.loadingSuggestions = { progress: true };

    this.itemAutomationSuggestionsManager
      .getAutomationSuggestionsForItemType$({ itemType: this.itemType, itemTitle: this.itemTitle })
      .pipe(
        take(1),
        untilDestroyed(this),
        catchError((error) => {
          this.setEmptySuggestions$().subscribe();

          console.warn(error);

          if (openBrowseAutomationItemsModal) {
            this.openBrowseAutomationItemsModal();
          }

          return EMPTY;
        }),
        finalize(() => {
          delete this.indicators.loadingSuggestions;
          this.cdr.markForCheck();
        })
      )
      .subscribe((automationSuggestions) => {
        this.automationSuggestions = automationSuggestions;

        if (openBrowseAutomationItemsModal && !this.automationSuggestions.length) {
          this.openBrowseAutomationItemsModal();
        }
      });
  }

  private openBrowseAutomationItemsModal(): void {
    this.automatingItemBrowserModalManager
      .openBrowseAutomatingItemsModalForTargetType$(this.itemType)
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: (selectorData) => {
          this.selectorData$.next(selectorData);
        },
      });
  }

  private setEmptySuggestions$(): Observable<void> {
    this.automationSuggestions = [];

    return this.itemAutomationSuggestionsManager.setSuggestionToEmptyListForItemType$({ itemType: this.itemType }).pipe(
      take(1),
      untilDestroyed(this),
      map((updatedSelectorData) => {
        this.selectorData$.next({
          ...this.selectorData$.value,
          ...updatedSelectorData,
        });
      })
    );
  }
}
