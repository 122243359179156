import { NgFor } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { NzNotificationContainerComponent } from "ng-zorro-antd/notification";
import { UiNotificationComponent } from "../../notification.component";
import { UiNotificationData } from "../../notification.models";

@Component({
  selector: "ui-notification-container",
  exportAs: "uiNotificationContainer",
  templateUrl: "notification-container.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiNotificationComponent, NgFor],
})
export class UiNotificationContainerComponent extends NzNotificationContainerComponent {
  public topLeftInstances: Array<Required<UiNotificationData>>;
  public topRightInstances: Array<Required<UiNotificationData>>;
  public bottomLeftInstances: Array<Required<UiNotificationData>>;
  public bottomRightInstances: Array<Required<UiNotificationData>>;
}
