import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, forwardRef } from "@angular/core";
import { NgModel } from "@angular/forms";
import { FormItemBaseComponent } from "../form-item-base.component";

@Component({
  selector: "template-form-item",
  templateUrl: "./template-form-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FormItemBaseComponent, useExisting: forwardRef(() => TemplateFormItemComponent) }],
})
export class TemplateFormItemComponent extends FormItemBaseComponent {
  /**
   * Allows for wiring an arbitrary ControlValueAccessor element to the form.
   * The provided ngModel element validation and pristine status will be synced with the form.
   * Form item control props like required, disabled and validators are assigned to the control.
   *
   * @example
   * <template-form-item controlName="customControl" labelText="Custom Control" [required]="true">
   *   <input ngModel />
   * </template-form-item>
   */
  @ContentChild(NgModel, { static: true })
  public set setFormControl(model: NgModel) {
    if (this.controlName && model) {
      this.form.formGroup.addControl(this.controlName, model.control);
    }
  }

  /**
   * Assings the default ID to the custom control.
   * The control ID is internally linked to the label.
   */
  @ContentChild(NgModel, { static: true, read: ElementRef })
  public set setControlId(control: ElementRef<HTMLElement>) {
    if (this.controlName && control) {
      control.nativeElement.setAttribute("aria-labelledby", this.labelId);
    }
  }
}
