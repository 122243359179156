import { IFilterService } from "angular";
import { formatUnit } from "@gtmhub/feed/utils";
import { IUnitFormatting } from "@gtmhub/shared/models";
import { toFixedWithoutRounding } from "@gtmhub/shared/utils";

export type MetricValueFilter = (value: number, format: IUnitFormatting, options?: { maxFractionSize?: number; withoutRounding?: boolean }) => string;

export const metricValue = function ($filter: IFilterService) {
  return (value: number, format: IUnitFormatting, options: { maxFractionSize?: number; withoutRounding?: boolean } = {}): string => {
    if (!value && value !== 0) {
      return null;
    }

    if (!options || (!options.maxFractionSize && options.maxFractionSize !== 0)) {
      options = {
        maxFractionSize: 2,
      };
    }

    let resultValue: string | number;

    if (Math.floor(value) === value) {
      resultValue = $filter("number")(value, 0);
    } else {
      const fixedWithoutRounding = toFixedWithoutRounding(value, options.maxFractionSize);
      const formattedValue = fixedWithoutRounding ? $filter("number")(fixedWithoutRounding, options.maxFractionSize) : fixedWithoutRounding;
      resultValue = options.withoutRounding ? formattedValue : $filter("number")(value, options.maxFractionSize);
    }

    const prefix: string = format && format.prefix ? formatUnit({ unit: format.prefix, isPrefix: true }) : "";
    const suffix: string = format && format.suffix ? formatUnit({ unit: format.suffix, isPrefix: false }) : "";

    return `${prefix}${resultValue}${suffix}`.trim();
  };
};

metricValue.$inject = ["$filter"];
