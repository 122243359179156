import { IHttpResponse, IHttpService, IPromise, IQService, IRequestShortcutConfig } from "angular";
import { EnvironmentService } from "@gtmhub/env";
import { IPermissionAction, IRole } from "@gtmhub/roles";
import { ICollection, ICollectionLike } from "@webapp/core/core.models";
import { Permission } from "@webapp/permissions/models/permissions.model";
import { IAccess, IUserItemAllowedActions, UserAction } from "@webapp/sessions/models/sessions.model";

type ItemType = "session" | "dashboard" | "kpi";

export class PermissionsService {
  public static $inject = ["$http", "EnvironmentService", "$q"];

  constructor(
    private $http: IHttpService,
    private env: EnvironmentService,
    private $q: IQService
  ) {}

  public getEffectivePermissions(): IPromise<Permission[]> {
    const url = this.env.getApiEndpoint("/permissions/effective");

    return this.$http.get<Permission[]>(url).then((response) => response.data);
  }

  public getPermissionsByPrincipal(id: string): IPromise<string[]> {
    const url = this.env.getApiEndpoint("/permissions/principal/" + id);

    return this.$http.get<string[]>(url).then((response) => response.data);
  }

  public getPermissionActions(): IPromise<IPermissionAction[]> {
    const url = this.env.getApiEndpoint("/permissions/actions");

    return this.$http.get<ICollection<IPermissionAction>>(url).then((response) => response.data.items);
  }

  public getPermissionActionsV2(): IPromise<IPermissionAction[]> {
    const url = this.env.getApiEndpointV2("/permissions/actions");

    return this.$http.get<ICollection<IPermissionAction>>(url).then((response) => response.data.items);
  }

  public updatePrincipalPermissions(id: string, allowedActions: string[]): IPromise<void> {
    const url = this.env.getApiEndpoint("/permissions/principal/" + id);

    return this.$http.put(url, allowedActions).then(() => null);
  }

  public getCurrentUserAllowedActionsPerItem(targetId: string, targetType: string): IPromise<IUserItemAllowedActions> {
    const url: string = this.env.getApiEndpoint(`/permissions/currentUser-allowedActions/${targetId}`);
    const query: IRequestShortcutConfig = {
      params: {
        targetType: targetType,
      },
    };

    return this.$http.get<IUserItemAllowedActions>(url, query).then((response) => response.data);
  }

  public getCurrentUserAllowedActionsForItems(targetIds: string[], targetType: string): IPromise<Record<string, UserAction[]>> {
    if (!targetIds || targetIds.length === 0) {
      return this.$q.resolve({});
    }

    const requests: IPromise<IHttpResponse<Record<string, UserAction[]>>>[] = [];
    const url: string = this.env.getApiEndpointV2(`/permissions/allowed-actions/${targetType}`);

    const maxSupportedTargetIdsPerRequest = 100;
    const batchesCount = Math.ceil(targetIds.length / maxSupportedTargetIdsPerRequest);

    // slice the request to multiple batches if necessary, as the target IDs are sent as query params
    // when too many query params are present, the query url becomes too long, it gets cut and the request fails
    for (let index = 0; index < batchesCount; index += 1) {
      const targetIdsBatch = targetIds.slice(index * maxSupportedTargetIdsPerRequest, (index + 1) * maxSupportedTargetIdsPerRequest);

      const query: IRequestShortcutConfig = {
        params: { targetIds: targetIdsBatch },
      };

      requests.push(this.$http.get<Record<string, UserAction[]>>(url, query));
    }

    return this.$q.all(requests).then((responses) => responses.reduce((merged, response) => ({ ...merged, ...response.data }), {}));
  }

  public getDefaultAccess(itemType: ItemType): IPromise<IAccess> {
    const url = this.env.getApiEndpoint(`/permissions/default-access/${itemType}`);

    return this.$http.get<IAccess>(url).then((response) => response.data);
  }

  public getDefaultAccessById(itemType: ItemType, itemId: string): IPromise<IAccess> {
    const url = this.env.getApiEndpoint(`/permissions/default-access/${itemType}/${itemId}`);

    return this.$http.get<IAccess>(url).then((response) => response.data);
  }

  public getDefaultAccessForReadOnlyAccount = (roles: ICollectionLike<IRole>): IAccess => {
    const adminRoleId = roles.items.find((role) => role.name === "admin").id;
    const userRoleId = roles.items.find((role) => role.name === "user").id;

    const access: IAccess = {
      inherits: false,
      permissions: [
        {
          principalKind: "account",
          principalId: JSON.parse(localStorage.getItem("accountId")),
          grant: {
            general: ["read"],
          },
        },
      ],
    };

    if (userRoleId) {
      access.permissions.push({
        principalKind: "role",
        principalId: userRoleId,
        grant: {
          general: ["read"],
        },
      });
    }

    if (adminRoleId) {
      access.permissions.push({
        principalKind: "role",
        principalId: adminRoleId,
        grant: {
          general: ["read", "create", "update", "delete", "modifyPermissions"],
        },
      });
    }

    return access;
  };
}
