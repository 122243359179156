import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { QuillEditorComponent, QuillModules } from "ngx-quill";
import Quill from "quill";
import "quill-mention";
import { fromEvent, take, zip } from "rxjs";
import { IAssigneesStoreState } from "@gtmhub/assignees";
import { UIErrorHandlingService } from "@gtmhub/error-handling";
import { SearchService } from "@gtmhub/search/services/search.service";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { ITeam, ITeamsIdMap } from "@gtmhub/teams";
import { getCurrentUserId } from "@gtmhub/users";
import { Assignee, UnknownAssignee, UserAssignee } from "@webapp/assignees/models/assignee.models";
import { assigneeFromRedux } from "@webapp/assignees/utils/assignee.utils";
import { LocalizePipe } from "@webapp/localization/pipes/localize.pipe";
import { localize } from "@webapp/localization/utils/localization.utils";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { ISearchCondition, ISearchParams, SearchOperatorsEnum } from "@webapp/search/models/search-api.models";
import { Search, SearchDTO } from "@webapp/search/models/search.models";
import { PlanningSessionStatus } from "@webapp/sessions/models/sessions.model";
import { MentionBlot } from "@webapp/shared/rich-text-editor/blots/mention.blot";
import { MentionAttachedEvent, MentionDeletedEvent } from "@webapp/shared/rich-text-editor/events/mention-deleted.event";
import { deltaToMarkdownConverters } from "@webapp/shared/rich-text-editor/markdown-converter/from-delta.converters";
import { MarkedJsService } from "@webapp/shared/rich-text-editor/marked-js/marked-js.service";
import { RichTextEditorIconEnum } from "@webapp/shared/rich-text-editor/rich-text-editor-icon/rich-text-editor-icon.enum";
import { Gif, SizeOfGif } from "../components/gifs/gif.models";
import { EmbeddedEmoji } from "./blots/embedded-emoji.blot";
import { OKRLinkBlot } from "./blots/okr-link.blot";
import { EmojiPickerComponent } from "./emoji-picker/emoji-picker.component";
import { OkrLinkClickEvent } from "./events/okr-link.event";
import { deltaToMarkdown } from "./markdown-converter/markdown-converter";
import { OKRLinkSelector } from "./modules/okr-link";
import { OKRLinkItem } from "./rich-text-editor.models";
import { WebappQuillTheme } from "./rich-text-editor.theme";
import {
  UNKNOWN_AVATAR_ICON_PATH,
  buildOKRLinkSelectorInitialRequest,
  buildOKRLinkSelectorSearchBody,
  convertMarkdownToRichText,
  convertResponseItemsToOKRLinkItems,
  getTeamIdsByUserId,
  getUniqueItems,
  groupOKRLinkItems,
  isPreventBlurClass,
  prioritizeAndGroupItems,
  searchItemToUserAssignee,
  stripHtmlElements,
} from "./rich-text-editor.utils";

Quill.register("themes/ghQuillTheme", WebappQuillTheme, true);
Quill.register(MentionBlot, true);
Quill.register(EmbeddedEmoji, true);
Quill.register(OKRLinkBlot, true);
Quill.register("modules/okrLink", OKRLinkSelector);

const Delta = Quill.import("delta");
const TEXT_PLACEHOLDER = "-";

@UntilDestroy()
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "rich-text-editor",
  templateUrl: "./rich-text-editor.component.html",
  styleUrls: ["./rich-text-editor.component.less"],
  providers: [LocalizePipe],
})
/**
 * @example <rich-text-editor [(text)]="externalVariable"></rich-text-editor>
 */
export class RichTextEditorComponent implements OnChanges, AfterViewInit, OnDestroy {
  /**
   * Hides the post content button, e.g. when used in inline mode.
   */
  @Input() public hidePostButton: boolean;
  @Input() public uiId: string;
  @Input() public editorButtonsDisabled: boolean;
  @Input() public showExternalEditorButtons: boolean;

  /**
   * Removes the GIF selector.
   */
  @Input() public withoutGifs: boolean;

  /**
   * Hide the toolbar and border unless focused.
   */
  @Input() public inline: boolean;

  // TODO: consider adding an input borderless instead
  @Input() public inlineWithBorder = false;

  /**
   * Hide the toolbar and border unless focused.
   */
  @Input() public hideToolbarWhenUnfocused: boolean;

  /**
   * Disables editing (the field is still focusable).
   */
  @Input() public readonly: boolean;

  /**
   * Disables editing, focusing, and submitting the input.
   */
  @Input() public disabled: boolean;

  /**
   * Only show the content without toolbar, editing or placeholder.
   */
  @Input() public contentOnly: boolean;

  /**
   * Should field be focused when contentOnly switches from true to false, e.g. when editing comments.
   * @default true
   */
  @Input() public focusOnEditable = true;

  /**
   * Text to be populated in the editor.
   */
  @Input() public text = "";

  /**
   * Placeholder for the editor when no text is input.
   */
  @Input() public placeholder: string;

  /**
   * Gif to be populated in the editor.
   */
  @Input() public gif: Gif;

  @Input() public additionalMentions: string[] = [];
  @Input() public autofocus = false;
  // TODO: review if redundant - there's an autofocus prop just above
  @Input() public focusMe = false;

  /**
   * Notifies assistive technologies the label of the field.
   */
  @Input() public a11yLabel: string;

  /**
   * Notifies assistive technologies the label of the field, referencing another element.
   */
  @Input() public a11yLabelledby: string;

  /**
   * Notifies assistive technologies whether field is required.
   */
  @Input() public a11yRequired = false;

  /**
   * Determines if the rich text editior's content should be truncated on blur.
   */
  @Input() public ellipsis = false;

  /**
   * Specifies the aria-label text rendered on the post button (both inline or external).
   */
  @Input() public postButtonA11yLabel: string;

  /**
   * Controls OKR/KPI link selector support.
   */
  @Input() public isOKRLinkSelectorEnabled: boolean;
  @Input() public team: ITeam;
  @Input() public teams: ITeamsIdMap;

  @Output() public readonly textChange = new EventEmitter<string>();
  @Output() public readonly gifChange = new EventEmitter<Gif>();
  @Output() public readonly mentionsChange = new EventEmitter<string[]>();
  @Output() public readonly editorBlur = new EventEmitter<void>();
  @Output() public readonly editorFocus = new EventEmitter<void>();
  @Output() public readonly cancel = new EventEmitter<void>();
  @Output() public readonly post = new EventEmitter<{
    text: string;
    gif: Gif;
    mentions: string[];
  }>();
  @Output() public readonly okrLinkClick = new EventEmitter<OkrLinkClickEvent>();

  @ViewChild(QuillEditorComponent, { static: true }) public editor: QuillEditorComponent;
  @ViewChild("richTextEditor") public element: ElementRef<HTMLElement>;
  @ViewChild(EmojiPickerComponent) private readonly emojiPickerComponent: EmojiPickerComponent;

  public modules: QuillModules;
  public sizeOfGif = SizeOfGif;
  public isFocused = false;
  public hasContentChangedPriorToBlur = false;
  public mentionPopoverUser: Assignee | UnknownAssignee;
  public mentionElRef: ElementRef;
  public mentions: string[] = [];
  public icons = RichTextEditorIconEnum;
  public richText = "";
  public markdownText = "";
  public placeholderToShow = localize("message");

  private textCursorPositionBeforeOpeningEmojiPopup: { index: number; length: number };
  private areMentionsOpened = false;
  private hasPermissionAccessGoals: boolean;
  private hasPermissionAccessKPIs: boolean;

  private removeMentionClickedListener: () => void;
  private removeMentionDeletedListener: () => void;
  private removeMentionAttachedListener: () => void;
  private removeOKRLinkAttachedListener: () => void;

  constructor(
    private searchService: SearchService,
    private markedJsService: MarkedJsService,
    private renderer: Renderer2,
    private zone: NgZone,
    private localizePipe: LocalizePipe,
    private uiErrorHandlingService: UIErrorHandlingService,
    private permissionsFacade: PermissionsFacade
  ) {
    this.markedJsService.loadCustomExtensionsForRichTextEditor();

    zip([this.permissionsFacade.hasPermission$("AccessGoals"), this.permissionsFacade.hasPermission$("AccessKPIs")])
      .pipe(take(1), untilDestroyed(this))
      .subscribe(([accessGoals, accessKPIs]) => {
        this.setModules();
        this.hasPermissionAccessGoals = accessGoals;
        this.hasPermissionAccessKPIs = accessKPIs;
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.placeholderToShow = changes.contentOnly?.currentValue ? "" : this.placeholder || localize("message");

    const isContentOnlyChangedOff = changes.contentOnly?.previousValue && !changes.contentOnly?.currentValue;

    if (this.focusOnEditable && isContentOnlyChangedOff) {
      this.focusInput({ withTimeout: true });
    }

    this.refocusOnChange(changes);

    const isTextChanged =
      (changes.text?.firstChange || changes.text?.previousValue !== changes.text?.currentValue) &&
      !!changes.text?.currentValue &&
      changes.text?.currentValue !== this.markdownText;
    if (isTextChanged) {
      this.richText = convertMarkdownToRichText(changes.text.currentValue);
    }
  }

  public ngAfterViewInit(): void {
    this.attachMentionListeners();
    this.attachOKRLinkListener();

    if (this.autofocus) {
      this.focusInput({ withTimeout: true });
    }
  }

  public ngOnDestroy(): void {
    this.removeMentionClickedListener?.();
    this.removeMentionDeletedListener?.();
    this.removeMentionAttachedListener?.();
    this.removeOKRLinkAttachedListener?.();
  }

  public postContent(): void {
    if (!this.canPostContent()) {
      return;
    }

    this.post.emit({
      text: this.markdownText || this.text || "",
      gif: this.gif ?? { id: "", searchQuery: "" },
      mentions: [...new Set(this.mentions.concat(this.additionalMentions))],
    });
  }

  public clearContent(): void {
    this.richText = "";
    this.markdownText = "";
    this.gif = null;
  }

  public canPostContent(): boolean {
    return !this.editorButtonsDisabled && (((!!this.markdownText || !!this.text) && this.richText !== null) || !!this.gif?.id);
  }

  public selectGif(gif: Gif): void {
    this.gif = gif;
    this.gifChange.emit(gif);
  }

  public removeGif(): void {
    this.gif = null;
    this.gifChange.emit(null);
  }

  public addEmoji(emoji: string): void {
    this.addEmojiToQuillEditor(emoji);
  }

  public showMentionMenu(): void {
    if (!this.editor) {
      return;
    }

    const { quillEditor } = this.editor;

    const mention = quillEditor.getModule("mention");
    mention.openMenu("@");

    // Fixes cursor reset bug on openMenu call
    const cursorPosition = quillEditor.getSelection(true)?.index;
    setTimeout(() => quillEditor.setSelection(cursorPosition, 0));
  }

  public showOKRLinkMenu(): void {
    if (!this.editor) {
      return;
    }

    const { quillEditor } = this.editor;

    const okrLink = quillEditor.getModule("okrLink");
    okrLink.openMenu("#");

    // Fixes cursor reset bug on openMenu call
    const cursorPosition = quillEditor.getSelection(true)?.index;
    setTimeout(() => quillEditor.setSelection(cursorPosition, 0));
  }

  public onBlurMenus(event: FocusEvent, menuButton: "mention" | "okr"): void {
    const isRTELosingFocus = !(event.relatedTarget as HTMLElement).classList.contains("ql-editor");

    if (!isRTELosingFocus) {
      return;
    }

    const isMentionMenuButtonLast = this.hidePostButton && !this.isOKRLinkSelectorEnabled;
    const isOKRLinkMenuButtonLast = this.hidePostButton;

    if ((menuButton === "mention" && isMentionMenuButtonLast) || (menuButton === "okr" && isOKRLinkMenuButtonLast)) {
      this.blurHandler();
    }
  }

  public popupMenuOpen(): void {
    if (!this.editor) {
      return;
    }

    const { quillEditor } = this.editor;
    this.textCursorPositionBeforeOpeningEmojiPopup = quillEditor.getSelection();

    // Fallback if currently there is no selection.
    // This is needed for example when editor is not focused and emoji picker is opened.
    // In this case if there is no selection and you select an emoji, it will not be inserted to the editor.
    if (!this.textCursorPositionBeforeOpeningEmojiPopup) {
      quillEditor.setSelection(quillEditor.getLength(), 0);
      this.textCursorPositionBeforeOpeningEmojiPopup = quillEditor.getSelection();
    }

    if (this.areMentionsOpened) {
      quillEditor.deleteText(this.textCursorPositionBeforeOpeningEmojiPopup.index - 1, 1, Quill.sources.USER);
    }
  }

  public focusHandler(): void {
    if (!this.readonly && !this.disabled && !this.contentOnly) {
      this.isFocused = true;
      this.editorFocus.emit();
    }
  }

  public toolBarHandler($event: MouseEvent): void {
    // (GVS-27436) excluding mousedown preventDefault for search fields otherwise they could't be focused
    const eventTargetElement = $event.target as HTMLElement;

    if (isPreventBlurClass(eventTargetElement)) {
      return;
    }

    // we are trapping the mousedown on the toolbar to prevent a blur event when clicked
    $event.preventDefault();
  }

  public blurHandler(): void {
    if (this.text === TEXT_PLACEHOLDER) {
      this.editor.quillEditor.deleteText(0, 1);
    }
    const activeElement = document.activeElement as HTMLElement;

    // On Safari the reader blur event is triggered when focus moves to the toolbar buttons
    if (this.element?.nativeElement?.contains(activeElement)) {
      return;
    }

    if (!this.isFocused || isPreventBlurClass(activeElement)) {
      return;
    }

    if (this.emojiPickerComponent?.emojiPicker.isOpen) {
      return;
    }

    if (!this.readonly && !this.disabled && !this.contentOnly) {
      const { quillEditor } = this.editor;
      quillEditor.blur();

      this.isFocused = false;
      this.editorBlur.emit();

      if (this.hasContentChangedPriorToBlur) {
        // Text gets recognized as link, when converted from MD to HTML
        this.richText = convertMarkdownToRichText(this.markdownText);
      }
    }
  }

  public mentionPopoverClosed(): void {
    this.mentionPopoverUser = null;
    this.mentionElRef = null;
  }

  public onContentChanged(): void {
    const { quillEditor } = this.editor;

    const markdown = deltaToMarkdown(quillEditor.getContents().ops, deltaToMarkdownConverters()).trim();
    const stripped = stripHtmlElements(markdown);

    this.markdownText = stripped;

    this.textChange.emit(stripped);
    this.hasContentChangedPriorToBlur = true;
  }

  public onEditorCreated(): void {
    const keyboard = this.editor.quillEditor.getModule("keyboard");
    delete keyboard.bindings[9];

    // we need this blur event instead of onBlur because quill editor by their implementation recognises
    // blur event that are outside editor and are not buttons or inputs https://github.com/KillerCodeMonkey/ngx-quill/issues/390
    // BUG https://quantive-inc.atlassian.net/browse/GVS-41652
    fromEvent(this.editor.quillEditor.root, "blur")
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        // blur must be delayed to prevent the editor from losing focus when clicking on the toolbar
        // For example when editing links
        // BUG - https://quantive-inc.atlassian.net/browse/GVS-37024
        window.setTimeoutOutsideAngular(() => this.blurHandler());
      });
  }

  public focusInput(options = { withTimeout: false }): void {
    const focusRTE = (): void => {
      const innerEditor = this.editor?.editorElem?.firstChild as HTMLElement;
      innerEditor?.focus();

      if (innerEditor) {
        this.setCursorPositionInTheEnd(innerEditor);
      }

      this.isFocused = true;
    };

    if (options.withTimeout === true) {
      // Small timeout is needed for the focus event to work
      window.setTimeoutOutsideAngular(() => {
        focusRTE();
      }, 200);

      return;
    }

    focusRTE();
  }

  private setCursorPositionInTheEnd = (innerEditor: HTMLElement): void => {
    const lastChildNode = this.getLastChildNode(innerEditor.lastChild);

    const range = document.createRange();
    const selection = window.getSelection();

    range.setStart(lastChildNode, lastChildNode.nodeValue?.length || 0);
    range.collapse(true);

    selection.removeAllRanges();
    selection.addRange(range);
  };

  private getLastChildNode = (node: ChildNode): ChildNode => {
    if (!node.lastChild) {
      return node;
    }

    return this.getLastChildNode(node.lastChild);
  };

  private onImgHandler(): void {
    return new Delta().insert("");
  }

  private setModules(): void {
    const dataAttributes: (keyof UserAssignee)[] = ["id", "name", "email", "picture"];
    const okrLinkItemDataAttributes: (keyof OKRLinkItem)[] = ["title", "type"];

    this.modules = {
      okrLink: {
        blotName: "okrLink",
        minChars: 0,
        maxChars: 31,
        offsetTop: 2,
        offsetLeft: 0,
        isolateCharacter: false,
        mentionDenotationChars: ["#"],
        dataAttributes: okrLinkItemDataAttributes,
        // default is /^[a-zA-Z0-9_]*$/
        allowedChars: /^(\s?[a-zA-Z0-9_:-]+(\s[a-zA-Z0-9_:-]+)?\s?)*$/,
        listItemClass: "link-list-item",
        mentionContainerClass: "link-list-container",
        mentionListClass: "link-list",
        spaceAfterInsert: true,
        onSelect: (item: OKRLinkItem, insertItem: (item: OKRLinkItem) => void): void => {
          if (item.type !== "empty") {
            insertItem(item);
          }

          // Necessary because quill-mention triggers changes as 'api' instead of 'user'
          this.addTextToQuillEditor("");
        },
        onBeforeClose(): boolean {
          return true;
        },
        renderLoading(): boolean {
          return false;
        },
        onOpen: (): boolean => {
          return true;
        },
        onClose: (): boolean => {
          return false;
        },
        source: this.debounce(async (searchTerm: string, renderList: (items: OKRLinkItem[], searchTerm: string) => void): Promise<void> => {
          if (!this.isOKRLinkSelectorEnabled || (!this.hasPermissionAccessGoals && !this.hasPermissionAccessKPIs)) {
            return;
          }

          try {
            // Take 100 results to increase probability of getting Goal type since it is a priority, but shows only up to 10 of them
            const queryParams: Partial<ISearchParams> = { take: 100, skip: 0 };
            const state: IAssigneesStoreState = reduxStoreContainer.reduxStore.getState();
            const currentUser = assigneeFromRedux(state, getCurrentUserId());
            const otherTeamsIds = getTeamIdsByUserId(Object.values(this.teams), currentUser.id);

            const allItemsRequestBody = buildOKRLinkSelectorInitialRequest({
              ownerIds: [currentUser.id, this.team.id, ...otherTeamsIds],
              sessionStatuses: [PlanningSessionStatus.OPEN, PlanningSessionStatus.INPROGRESS],
              accessGoals: this.hasPermissionAccessGoals,
              accessKPIs: this.hasPermissionAccessKPIs,
            });
            const allItemsRequest = this.searchService.getSomeSearchData<Search<"goals">>(allItemsRequestBody, queryParams);
            const allItemsResponse = await allItemsRequest;

            // Suggested OKR/KPI on initial load
            let allItems = convertResponseItemsToOKRLinkItems(allItemsResponse.items);
            allItems = prioritizeAndGroupItems(allItems, [currentUser.id], [this.team.id], otherTeamsIds);

            let renderListValues: OKRLinkItem[] = allItems;

            if (searchTerm) {
              const searchBody: SearchDTO = buildOKRLinkSelectorSearchBody(searchTerm, {
                accessGoals: this.hasPermissionAccessGoals,
                accessKPIs: this.hasPermissionAccessKPIs,
              });
              const response = await this.searchService.getSomeSearchData<Search<"goals">>(searchBody, queryParams);

              if (response.items.length) {
                renderListValues = groupOKRLinkItems(convertResponseItemsToOKRLinkItems(response.items));
              } else {
                renderListValues = [this.buildOKRLinkSelectorEmptyStateTemplate(this.localizePipe.transform("link_selector_no_items_found"))];
              }
            }

            // Handle case where user has no items or access to any OKR/KPI
            if (!renderListValues.length && !searchTerm) {
              renderListValues = [this.buildOKRLinkSelectorEmptyStateTemplate(this.localizePipe.transform("link_selector_empty_state"))];
            }

            // By design up to 10 items will be rendered after grouping and sorting
            const uniqueItems = getUniqueItems(renderListValues, 10);
            renderList(uniqueItems, searchTerm);
          } catch (error) {
            this.uiErrorHandlingService.handleModal(error);
          }
        }, 200),
      },
      mention: {
        blotName: "ghMention",
        positioningStrategy: "normal",
        dataAttributes,
        // default is /^[a-zA-Z0-9_]*$/
        allowedChars: /^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-ž\u1e00-\u1eff0-9_]*$/,
        renderItem: (item: UserAssignee): HTMLElement => {
          return this.renderMentionItem(item);
        },
        onSelect: (item: UserAssignee, insertItem: (item: UserAssignee) => void): void => {
          insertItem(item);

          this.addMention(item.id);

          // Necessary because quill-mention triggers changes as 'api' instead of 'user'
          this.addTextToQuillEditor("");
        },
        onOpen: (): void => {
          this.areMentionsOpened = true;
        },
        onClose: (): void => {
          this.areMentionsOpened = false;
        },
        source: async (searchTerm: string, renderList: (items: UserAssignee[], searchTerm: string) => void): Promise<void> => {
          const searchConditions: ISearchCondition[] = [
            {
              fieldName: "isActive",
              fieldCondition: {
                operator: "isNot",
                value: "false",
              },
            },
          ];

          const searchBody: SearchDTO = {
            searchRequests: [
              {
                collectionName: "users",
                ...(searchTerm && {
                  searchFields: [{ name: "firstName" }, { name: "lastName" }, { name: "fullName" }],
                  responseFields: ["_id", "firstName", "lastName", "auth0Cache.email", "auth0Cache.picture"],
                }),
                searchConditions,
              },
            ],
            ...(searchTerm && {
              searchTerm,
              operator: SearchOperatorsEnum.matchPrefix,
            }),
          };
          const queryParams: Partial<ISearchParams> = { take: 10, skip: 0 };
          const searchResults = await this.searchService.getSomeSearchData<Search<"users">>(searchBody, queryParams);

          const renderListValues: UserAssignee[] = [];
          searchResults.items.forEach((item) => {
            const user = searchItemToUserAssignee(item);
            renderListValues.push(user);
          });

          renderList(renderListValues, searchTerm);
        },
      },
      keyboard: {
        bindings: {
          enter: {
            key: 13,
            shortKey: true,
            handler: (): void => {
              if ((this.hidePostButton && !this.showExternalEditorButtons) || !this.canPostContent() || this.editorButtonsDisabled) {
                return;
              }

              this.postContent();
            },
          },
        },
      },
      clipboard: {
        matchers: [
          ["IMG", this.onImgHandler],
          ["PICTURE", this.onImgHandler],
        ],
      },
    };
  }

  private buildOKRLinkSelectorEmptyStateTemplate(title: string): OKRLinkItem<"empty"> {
    return {
      id: "1",
      type: "empty",
      owners: [],
      title,
    };
  }

  private addTextToQuillEditor(text: string): void {
    if (!this.editor) {
      return;
    }

    const { quillEditor } = this.editor;
    const idx = quillEditor.getLength() - 1;

    quillEditor.insertText(idx, text, Quill.sources.USER);
  }

  private addEmojiToQuillEditor(text: string): void {
    if (!this.editor || !this.textCursorPositionBeforeOpeningEmojiPopup) {
      return;
    }

    const { quillEditor } = this.editor;

    if (this.textCursorPositionBeforeOpeningEmojiPopup.length > 0) {
      quillEditor.deleteText(this.textCursorPositionBeforeOpeningEmojiPopup.index, this.textCursorPositionBeforeOpeningEmojiPopup.length);
    }

    quillEditor.insertEmbed(this.textCursorPositionBeforeOpeningEmojiPopup.index, EmbeddedEmoji.blotName, text.trim(), Quill.sources.USER);
    quillEditor.setSelection(this.textCursorPositionBeforeOpeningEmojiPopup.index + 1, 0, Quill.sources.USER);
  }

  private addMention(userId: string): void {
    if (userId === getCurrentUserId()) {
      return;
    }

    this.mentions.push(userId);
    this.mentions = [...new Set(this.mentions)];
    this.mentionsChange.emit(this.mentions);
  }

  private removeMention(userId: string): void {
    this.mentions = this.mentions.filter((it) => it !== userId);

    if (this.mentionsChange.observed) {
      this.zone.run(() => this.mentionsChange.emit(this.mentions));
    }
  }

  private renderMentionItem(item: UserAssignee): HTMLElement {
    const wrapper = document.createElement("div");
    wrapper.classList.add("mention-item-wrapper");

    const pictureEl = document.createElement("span");
    pictureEl.classList.add("mention-item-avatar");
    const imgEl = document.createElement("img");
    imgEl.src = item.picture || UNKNOWN_AVATAR_ICON_PATH;
    imgEl.alt = item.name;
    pictureEl.appendChild(imgEl);
    wrapper.appendChild(pictureEl);

    const nameEl = document.createElement("span");
    nameEl.classList.add("mention-item-content");
    nameEl.textContent = item.name;

    wrapper.appendChild(nameEl);

    return wrapper;
  }

  private setMentionPopoverUser(userId: string): void {
    const state: IAssigneesStoreState = reduxStoreContainer.reduxStore.getState();
    this.mentionPopoverUser = assigneeFromRedux(state, userId);
  }

  private attachMentionListeners(): void {
    this.zone.runOutsideAngular(() => {
      this.removeMentionClickedListener = this.renderer.listen(this.element.nativeElement, "click", (event) => {
        const mentionElement = event.target?.closest(".mention");

        if (mentionElement) {
          this.zone.run(() => {
            this.mentionElRef = new ElementRef(mentionElement);
            this.setMentionPopoverUser(mentionElement.getAttribute("data-id"));
          });
        }
      });

      this.removeMentionAttachedListener = this.renderer.listen(this.element.nativeElement, "mention-attached", (event: MentionAttachedEvent) => {
        this.addMention(event.userId);
      });

      this.removeMentionDeletedListener = this.renderer.listen(this.element.nativeElement, "mention-deleted", (event: MentionDeletedEvent) => {
        if (this.isFocused) {
          this.removeMention(event.userId);
        }
      });
    });
  }

  private attachOKRLinkListener(): void {
    this.zone.runOutsideAngular(() => {
      this.removeOKRLinkAttachedListener = this.renderer.listen(this.element.nativeElement, "okr-clicked", (event: OkrLinkClickEvent) => {
        this.okrLinkClick.emit(event);
      });
    });
  }

  private refocusOnChange(changes: SimpleChanges): void {
    if (changes?.focusMe?.currentValue) this.focusInput({ withTimeout: true });
  }

  private debounce(fn, delay): (...args: unknown[]) => void {
    let timer = null;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.zone.runOutsideAngular(() => {
          fn(...args);
        });
      }, delay);
    };
  }
}
