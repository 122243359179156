import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "input-search",
  templateUrl: "./input-search.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputSearchComponent implements OnChanges {
  @ViewChild("inputField", { static: true }) public inputField: ElementRef<HTMLInputElement>;

  @Output() public readonly valueChange = new EventEmitter<string>();

  @Input() public value = "";
  @Input() public uiPlaceholder: string;
  @Input() public debounceTime = 500;
  @Input() public focusMe = false;

  private debounceTimeout: ReturnType<typeof setTimeout>;

  constructor(
    private zone: NgZone,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.focusMe && changes.focusMe.currentValue) {
      setTimeout(() => {
        this.inputField.nativeElement.focus();
      });
    }
  }

  public onChange(evt: string): void {
    this.zone.runOutsideAngular(() => {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.valueChange.emit(evt);
      }, this.debounceTime);
    });
  }

  public clearSearch(): void {
    this.value = "";
    this.valueChange.emit(this.value);
    this.cdr.detectChanges();
  }
}
