import { IdMap } from "@gtmhub/util";
import {
  currentSession,
  currentSessionAndItsDirectParent,
  currentSessionDirectParent,
  getSecondLevelAndAboveParents,
} from "@webapp/okrs/utils/okr-selector-sections-builder.utils";
import { Session } from "@webapp/sessions/models/sessions.model";

/**
 * Creates a function which filters and returns okrs and metrics based on their session belonging,
 * spliting the content to different section for the parent-selector.
 */
export const createParentSelectorSectionsFilter = (args: { sessionId: string; isGrandParentAlignmentEnabled: boolean }) => {
  return (sessionsMap: IdMap<Session>): Map<string, Map<string, { expand: boolean }>> => {
    const sectionsMap = new Map();

    if (args.isGrandParentAlignmentEnabled) {
      const currentSessionAndDirectParentSession = currentSessionAndItsDirectParent(sessionsMap, args.sessionId);
      const currentSessionParentSessionId = sessionsMap[args.sessionId]?.parentId;
      const sectionSecondLevelAndAboveParents = getSecondLevelAndAboveParents(sessionsMap, currentSessionParentSessionId);

      sectionsMap.set("okrs_related_to_you", currentSessionAndDirectParentSession);

      if (sectionSecondLevelAndAboveParents.size) {
        sectionsMap.set("okrs_in_related_sessions", sectionSecondLevelAndAboveParents);
      }
    } else {
      const currentSessionAndDirectParentSession = currentSession(sessionsMap, args.sessionId);
      const sectionSecondLevelAndAboveParents = currentSessionDirectParent(sessionsMap, args.sessionId);

      sectionsMap.set("okrs_related_to_you", currentSessionAndDirectParentSession);
      sectionsMap.set("okrs_in_related_sessions", sectionSecondLevelAndAboveParents);
    }

    return sectionsMap;
  };
};

export const toIdMap = <T>(map: Map<string, T>): Record<string, T> => {
  return [...(map || new Map()).entries()].reduce((objectMap, [key, value]) => {
    objectMap[key] = value;
    return objectMap;
  }, {});
};
