import { IModalService } from "angular-ui-bootstrap";
import { localize } from "@gtmhub/localization";
import { HowToStartCardType } from "../components/how-to-start/how-to-start.component";

export class HowToStartModalOpenerService {
  public static $inject = ["$uibModal"];

  constructor(private $uibModal: IModalService) {}

  public openHowToStartModal(cards: HowToStartCardType[]): void {
    this.$uibModal.open({
      windowClass: "how-to-start",
      backdropClass: "dark-backdrop",
      component: "howToStart",
      resolve: {
        cards: () =>
          cards.map((card) => ({
            title: localize(card.title),
            text: localize(card.text),
            img: card.img,
            button: card.button,
          })),
      },
    });
  }
}
