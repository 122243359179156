import { ITeam } from "@gtmhub/teams";
import { UXCustomizationType } from "@gtmhub/uxcustomization/models";
import { IViewHistoryPayload, ViewHistoryItemSource } from "@gtmhub/view-history";
import { IWhiteboard } from "@gtmhub/whiteboards";
import { KpiGroup } from "@webapp/kpis/models/kpi-group.models";

type BasicViewHistoryEntity = { id: string };

export class ViewHistoryPayloadBuilder {
  private itemId: string;
  private type: UXCustomizationType;
  private source: ViewHistoryItemSource;

  public withItem(item: BasicViewHistoryEntity): ViewHistoryPayloadBuilder {
    this.itemId = item.id;
    return this;
  }

  public withWhiteboard(whiteboard: IWhiteboard): ViewHistoryPayloadBuilder {
    return this.withItem(whiteboard).forType("whiteboard");
  }

  public withKpiGroup(kpiGroup: KpiGroup): ViewHistoryPayloadBuilder {
    return this.withItem(kpiGroup).forType("kpigroup");
  }

  public withSession(session: BasicViewHistoryEntity): ViewHistoryPayloadBuilder {
    return this.withItem(session).forType("session");
  }

  public withInsightboard(insightboard: BasicViewHistoryEntity): ViewHistoryPayloadBuilder {
    return this.withItem(insightboard).forType("dashboard");
  }

  public withTeam(team: ITeam): ViewHistoryPayloadBuilder {
    return this.withItem(team).forType("team");
  }

  public withTask(task: BasicViewHistoryEntity): ViewHistoryPayloadBuilder {
    return this.withItem(task).forType("task");
  }

  public withList(list: BasicViewHistoryEntity): ViewHistoryPayloadBuilder {
    return this.withItem(list).forType("list");
  }

  public withEmployee(employee: BasicViewHistoryEntity): ViewHistoryPayloadBuilder {
    return this.withItem(employee).forType("user");
  }

  public withGoal(goal: BasicViewHistoryEntity): ViewHistoryPayloadBuilder {
    return this.withItem(goal).forType("goal");
  }

  public withMetric(metric: BasicViewHistoryEntity): ViewHistoryPayloadBuilder {
    return this.withItem(metric).forType("metric");
  }

  public withFilter(filter: BasicViewHistoryEntity): ViewHistoryPayloadBuilder {
    return this.withItem(filter).forType("filter");
  }

  public withKpi(kpi: BasicViewHistoryEntity): ViewHistoryPayloadBuilder {
    return this.withItem(kpi).forType("kpi");
  }

  public forType(type: UXCustomizationType): ViewHistoryPayloadBuilder {
    this.type = type;
    return this;
  }

  public forSource(source: ViewHistoryItemSource): ViewHistoryPayloadBuilder {
    this.source = source;
    return this;
  }

  public generate(): IViewHistoryPayload {
    const product = {
      itemId: this.itemId,
      type: this.type,
      source: this.source,
    };

    this.itemId = undefined;
    this.type = undefined;
    this.source = undefined;
    return product;
  }
}
