import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { localize } from "@gtmhub/localization";
import { PiCardBorderStyle } from "../../models/pi-card.models";
import { PiErrorData } from "../../models/pi-errors.models";
import { IPIFeedbackSuggestion } from "../pi-feedback-card/models";
import { PiStateProcessorInstance } from "../pi-feedback-card/services/state-processor/pi-state-processor.models";

@UntilDestroy()
@Component({
  selector: "pi-clickable-error-card",
  templateUrl: "./pi-clickable-error-card.component.html",
  styleUrls: ["./pi-clickable-error-card.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiClickableErrorCardComponent {
  @Input()
  public isToggleEnabled = true;
  @Input()
  public borderStyle: PiCardBorderStyle = "solid";
  @Input()
  public isRemoveBtnAvailable = true;
  @Input()
  public removeBtnTooltipTitle = localize("remove");
  @Input()
  public errorData: PiErrorData;
  @Input()
  public instance: PiStateProcessorInstance;
  @Input()
  public suggestion: IPIFeedbackSuggestion<string | string[]>;
  @Input()
  public isRemoveBtnAtHeader = false;

  @Output()
  public readonly goBackToSuggestion = new EventEmitter();
  @Output()
  public readonly errorRemove = new EventEmitter();
  @Output()
  public readonly errorRefresh = new EventEmitter();

  public onGoBackToSuggestionClicked = (): void => {
    if (this.goBackToSuggestion.observed) {
      return this.goBackToSuggestion.emit();
    }

    this.instance.subject.next({ goBackToSuggestion: true });
  };

  public onRefreshErrorClicked = (): void => {
    if (this.errorRefresh.observed) {
      return this.errorRefresh.emit();
    }

    this.instance.subject.next({ refreshError: true, ...(this.suggestion && { suggestion: this.suggestion }) });
  };
}
