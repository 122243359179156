import { ElementRef, Injectable } from "@angular/core";
import { ɵDatePickerService as DatePickerService } from "ng-zorro-antd/date-picker";

@Injectable()
export class UiDatePickerService extends DatePickerService {
  public moveTableCellFocusTo(target: { rowIndex: number; cellIndex: number }, tableElementRef: ElementRef<HTMLElement>): void {
    tableElementRef.nativeElement.querySelector<HTMLTableCellElement>(`td[data-row="${target.rowIndex}"][data-col="${target.cellIndex}"][tabindex="0"]`)?.focus();
  }

  public focusFirstEnabledCellInTable(innerPopupElementRef: ElementRef<HTMLElement>): void {
    innerPopupElementRef.nativeElement.querySelector<HTMLTableCellElement>("td.ant-picker-cell[tabindex='0']")?.focus();
  }
}
