import { Injectable } from "@angular/core";
import { Observable, mergeMap, switchMap, tap } from "rxjs";
import { ICollection } from "@webapp/core/core.models";
import { TeamBase } from "../models/teams.models";
import { TeamsApiV2Service } from "./teams-api-v2.service";
import { TeamsCache } from "./teams-cache";

@Injectable({ providedIn: "root" })
export class TeamsRepository {
  constructor(
    private api: TeamsApiV2Service,
    private cache: TeamsCache
  ) {}

  public getMap$(): Observable<Map<string, TeamBase>> {
    return this.cache.get$().pipe(
      switchMap((value) => {
        if (value) {
          return this.cache.get$();
        } else {
          return this.loadTeamsIfMissing();
        }
      })
    );
  }

  private loadTeamsIfMissing(): Observable<Map<string, TeamBase>> {
    return this.api
      .getAll$<ICollection<TeamBase>>({
        fields: ["id,manager,members,parentId,dateCreated"],
      })
      .pipe(
        tap((col) => {
          this.cache.set(new Map<string, TeamBase>(col.items.map((item) => [item.id, item])));
        }),
        mergeMap(() => this.cache.get$())
      );
  }
}
