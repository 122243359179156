import { StateDeclaration } from "@uirouter/angular";
import { Injectable } from "@angular/core";
import equal from "fast-deep-equal";
import { encode as base64encode } from "js-base64";
import Cookies from "js-cookie";
import { Observable, firstValueFrom } from "rxjs";
import { Language, removeLanguageFromLocalStorage } from "@gtmhub/localization";
import { ICurrentUserRole, ICurrentUserRolesStoreState } from "@gtmhub/roles";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { IUser, IUserSettings, getCurrentAccountId, getCurrentUserId } from "@gtmhub/users";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { StorageService } from "@webapp/core/storage/services/storage.service";
import { Snippet } from "@webapp/data-story/models/insights.models";
import { IAccess, PermissionsMap, UserAction } from "@webapp/sessions/models/sessions.model";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";
import { CurrentUserApiService } from "./current-user-api.service";

@Injectable({ providedIn: "root" })
export class CurrentUserRepository {
  constructor(
    private currentUserApiService: CurrentUserApiService,
    private userProfileService: UserProfileService,
    private storageService: StorageService
  ) {}

  public demandsAreMet(toState: StateDeclaration): boolean {
    return !toState?.data?.requiresAccount || Boolean(getCurrentUserId());
  }

  public verifyEmail$(): Observable<void> {
    const { email } = this.userProfileService.getProfile();

    return this.currentUserApiService.post$("", {
      ...new RequestConfig(),
      url: this.currentUserApiService.getVerifyEmailEndpoint(email),
    });
  }

  public validateEmailVerification$(emailVerificationCode: string): Observable<void> {
    return this.currentUserApiService.post$(
      { emailVerificationCode },
      {
        ...new RequestConfig(),
        url: this.currentUserApiService.getValidateVerifyEmailEndpoint(getCurrentUserId()),
      }
    );
  }

  public clear(): void {
    this.storageService.remove("userSettings");
    this.storageService.remove("userId");
    removeLanguageFromLocalStorage();
  }

  public setUserSettings(user: IUser): void {
    if (user.userSettings) {
      this.storageService.set("userSettings", user.userSettings);
    } else {
      // this will be deleted when we migrate all users to have new userSettings property
      this.storageService.set("userSettings", JSON.parse(user.settings || "{}"));
    }
  }

  public setMarketplaceRedirectCookie(): void {
    // Skip the first part of the subdomain
    // The cookie will be shared between subdomains
    const domain = RegExp(/[^.]*\.[^.]*$/).exec(window.location.hostname);
    const lastVisitedUrlEncoded = base64encode(window.location.href.split("?")[0]);
    // Expires in 24h
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 1);
    Cookies.set("marketplace_redirect", lastVisitedUrlEncoded, { domain: domain[0], expires: expireDate, sameSite: "None", secure: true });
  }

  public removeMarketplaceRedirectCookie(): void {
    // Skip the first part of the subdomain
    // The cookie will be shared between subdomains
    const domain = RegExp(/[^.]*\.[^.]*$/).exec(window.location.hostname);
    if (domain) {
      Cookies.remove("marketplace_redirect", { domain: domain[0] });
    }
  }

  public getUserSetting<T = unknown>(key: keyof IUserSettings): T {
    if (!key) {
      throw new Error("You must provide user setting key.");
    }

    const userSettings = this.getUserSettingsFromLocalStorage();

    return (userSettings?.[key] as T) ?? null;
  }

  public setUserSetting(userSetting: IUserSettings): Promise<unknown> {
    const currentUserSettings = this.getUserSettingsFromLocalStorage();

    const areSettingsUnchanged = Object.keys(userSetting || {}).every((settingKey) => equal(userSetting[settingKey], currentUserSettings?.[settingKey]));
    if (areSettingsUnchanged) {
      return Promise.resolve();
    }

    const userSettings: IUserSettings = {
      ...currentUserSettings,
      ...userSetting,
    };

    this.storageService.set("userSettings", userSettings);

    if (userSetting.snippets) {
      userSetting.snippets = userSetting.snippets.map((snippet: Snippet) => {
        const code = base64encode(snippet.code || "");
        return {
          ...snippet,
          code,
        };
      });
    }

    return firstValueFrom(
      this.currentUserApiService.patch$(null, userSetting, {
        ...new RequestConfig(),
        url: this.currentUserApiService.getUserSettingsEndpoint(),
      })
    );
  }

  public raiseProfileLanguageUpdated(language: Language): void {
    this.userProfileService.setProfile({ ...this.userProfileService.getProfile(), language }, { notify: true });
  }

  public allowedActionsSync(accessObject: IAccess): UserAction[] {
    if (!accessObject) {
      return [];
    }

    const accessIdsMap = this.getAccesIdMap(accessObject);
    const userPrincipalIds = [...this.getCurrentUserRolesIds({ withShadowRoles: true }), getCurrentUserId(), getCurrentAccountId()];

    return userPrincipalIds.reduce((userAllowedActions, principleId) => {
      const allowedActions: string[] = accessIdsMap[principleId];

      if (!allowedActions) {
        return userAllowedActions;
      }

      const currentPrincipleAllowedActions = allowedActions.filter((allowedAction) => !userAllowedActions.includes(allowedAction));

      return [...userAllowedActions, ...currentPrincipleAllowedActions];
    }, []);
  }

  public userHasRole(roleName: string): boolean {
    return this.getCurrentUserRolesNames().indexOf(roleName) !== -1;
  }

  public getCurrentUserRolesNames(filter?: { withShadowRoles: boolean }): string[] {
    const currentUserRolesFromStore: ICurrentUserRole[] = this.getCurrentUserRoles(filter?.withShadowRoles);

    return currentUserRolesFromStore.map((role) => role.name);
  }

  public getCurrentUserShadowRoles(): ICurrentUserRole[] {
    return reduxStoreContainer.reduxStore.getState<ICurrentUserRolesStoreState>().currentUserRoles.shadowRoles;
  }

  private getCurrentUserRolesIds(filter?: { withShadowRoles: boolean }): string[] {
    const currentUserRolesFromStore: ICurrentUserRole[] = this.getCurrentUserRoles(filter?.withShadowRoles);

    return currentUserRolesFromStore.map((role) => role.id);
  }

  private getCurrentUserRoles(withShadowRoles?: boolean): ICurrentUserRole[] {
    let roles = reduxStoreContainer.reduxStore.getState<ICurrentUserRolesStoreState>().currentUserRoles.nonShadowRoles;

    if (withShadowRoles) {
      roles = roles.concat(reduxStoreContainer.reduxStore.getState<ICurrentUserRolesStoreState>().currentUserRoles.shadowRoles);
    }

    return roles;
  }

  private getUserSettingsFromLocalStorage(): IUserSettings {
    return this.storageService.get("userSettings");
  }

  private getAccesIdMap(access: IAccess): PermissionsMap {
    return (access?.permissions || []).reduce((permissionsMap, permission) => {
      permissionsMap[permission.principalId] = permission.grant.general;

      return permissionsMap;
    }, {});
  }
}
