import { Injectable } from "@angular/core";
import { ObservableCache } from "@webapp/core/state-management/observable-cache";
import { CustomViews } from "./custom-views.repository";

@Injectable()
export class CustomViewsCache extends ObservableCache<CustomViews[]> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }
}
