import { CommonModule, NgClass, NgIf, NgStyle, NgSwitch, NgSwitchCase } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, Output } from "@angular/core";
import { UiAccessibilityModule } from "@quantive/ui-kit/accessibility";
import { UiCollapseModule } from "@quantive/ui-kit/collapse";
import { UiI18nModule, UiI18nService } from "@quantive/ui-kit/i18n";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzNotificationComponent } from "ng-zorro-antd/notification";
import { Subject, takeUntil } from "rxjs";
import { ESCAPE } from "@webapp/shared/utils/keys";
import { UiNotificationData, UiNotificationPlacement } from "@webapp/ui/notification/notification.models";

@Component({
  selector: "ui-notification",
  exportAs: "uiNotification",
  templateUrl: "notification.component.html",
  styleUrls: ["notification.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle, NgClass, NgIf, NgSwitch, NgSwitchCase, UiIconModule, UiAccessibilityModule, UiI18nModule, CommonModule, UiCollapseModule],
})
export class UiNotificationComponent extends NzNotificationComponent implements OnDestroy {
  @Input("uiInstance") public instance!: Required<UiNotificationData>;
  @Input("uiPlacement") public placement?: UiNotificationPlacement;
  @Input("uiIndex") public index!: number;
  @Input() public accordionText: string | null = null;

  @Output("uiDestroyed") public readonly destroyed = new EventEmitter<{ id: string; userAction: boolean }>();

  private destroy$ = new Subject<void>();
  private notificationAriaLabelMap: {
    [NotificationType in UiNotificationData["type"]]: string;
  } = {};

  constructor(cdr: ChangeDetectorRef, i18n: UiI18nService) {
    super(cdr);

    i18n.localeChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.notificationAriaLabelMap = {
        info: i18n.translate("Notification.info"),
        success: i18n.translate("Notification.success"),
        warning: i18n.translate("Notification.warning"),
        error: i18n.translate("Notification.error"),
      };
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener("window:keydown", ["$event"])
  public handleDismiss(event: KeyboardEvent): void {
    if (event.code === ESCAPE) {
      this.close();
    }
  }

  public generateNotificationAriaLabel(type: UiNotificationData["type"]): string {
    return this.notificationAriaLabelMap[type];
  }

  public close(): void {
    this.destroyed.emit({ id: this.instance.messageId, userAction: true });
  }
}
