import { equals } from "angular";

const contains = <T>(collection: T[], el: T): boolean => {
  return (
    collection.find((value: T) => {
      return equals(value, el);
    }) !== undefined
  );
};

export const symmetricDifference = <T>(arr1: T[] | null | undefined, arr2: T[] | null | undefined): T[] => {
  if (arr1 === null || arr1 === undefined) {
    if (arr2) {
      return arr2;
    }
    return [];
  }
  if (arr2 === null || arr2 === undefined) {
    return arr1;
  }
  if (arr1.length === 0) {
    return arr2;
  }
  if (arr2.length === 0) {
    return arr1;
  }
  // arr1 and arr2 are present and their length is not 0
  const result = [...arr1];
  const elementsToDelete = [];
  arr2.forEach((el) => {
    if (contains(arr1, el)) {
      elementsToDelete.push(el);
    } else {
      result.push(el);
    }
  });

  return result.filter((element) => {
    return !contains(elementsToDelete, element);
  });
};
