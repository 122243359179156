import { module } from "angular";
import { DateDifferenceFilter, DateFilter, DateRangeFilter, TimeAgoFilter } from "../components/ui/datepicker/date.filter";
import { GhLimitToFilter } from "../components/ui/gh-limit-to/gh-limit-to.filter";
import { absolutePercentage } from "./absolute-percentage.filter";
import { bolder } from "./bolder.filter";
import { cut } from "./cut.filter";
import { dateDiffFromNow, dateDiffFromNowConversational } from "./date-diff-from-now.filter";
import { thousandsSeparator } from "./format-number.filter";
import { ghIsNaN } from "./is-nan.filter";
import { mentioning } from "./mentioning.filter";
import { objLength } from "./obj-length.filter";
import { ordinal } from "./ordinal.filter";
import { range } from "./range.filter";
import { TitleCaseFilter } from "./title-case.filter";
import { trim } from "./trim.filter";

const mod = module("sharedFilters", []);
mod.filter("dateDiffFromNow", dateDiffFromNow);
mod.filter("range", range);
mod.filter("ordinal", ordinal);
mod.filter("thousandsSeparator", thousandsSeparator);
mod.filter("titleCase", TitleCaseFilter);
mod.filter("dateDiffFromNowConversational", dateDiffFromNowConversational);
mod.filter("bolder", bolder);
mod.filter("absolutePercentage", absolutePercentage);
mod.filter("isNaN", ghIsNaN);
mod.filter("trim", trim);
mod.filter("cut", cut);
mod.filter("objLength", objLength);
mod.filter("mentioning", mentioning);
mod.filter("ghDaterange", DateRangeFilter);
mod.filter("ghDate", DateFilter);
mod.filter("ghTimeAgo", TimeAgoFilter);
mod.filter("ghDateDifference", DateDifferenceFilter);
mod.filter("ghLimitTo", GhLimitToFilter);
export default mod.name;
