import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { take, zip } from "rxjs";
import { INotificationSettings } from "@gtmhub/notifications/models/notifications";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { hasPermissions } from "@gtmhub/users/redux";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { ModuleFlag } from "@webapp/feature-toggles/models/feature-module.models";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { INotificationSettingsKeys, NotificationClass } from "@webapp/notifications/models/notification-settings.model";
import { CurrentUserRepository } from "@webapp/users";

@UntilDestroy()
@Component({
  selector: "notification-layout",
  templateUrl: "./notification-layout.component.html",
  styleUrls: ["./notification-layout.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationLayoutComponent implements OnInit {
  @Input()
  public notificationSettings: INotificationSettings;
  @Input()
  public notificationClass: NotificationClass;
  public isReflectionEnabled = false;

  public notificationSettingsMap: INotificationSettingsKeys;
  public canManageCheckinNotifications: boolean;
  public hasViewOnlyRole: boolean;

  constructor(
    private currentUserRepository: CurrentUserRepository,
    private featureTogglesFacade: FeatureTogglesFacade,
    private editionFeatureService: EditionFeatureService
  ) {}

  public ngOnInit(): void {
    this.editionFeatureService
      .hasFeature$("reflections")
      .pipe(untilDestroyed(this))
      .subscribe((hasFeature) => {
        this.isReflectionEnabled = hasFeature;
      });

    this.hasViewOnlyRole = this.currentUserRepository.userHasRole("view-only");

    zip([this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.OkrViews), this.featureTogglesFacade.isFeatureAvailable$(ModuleFlag.Reflections)])
      .pipe(take(1))
      .subscribe(([okrViewsAvailable, isCheckinsModuleEnabled]) => {
        this.canManageCheckinNotifications = this.getCanManageCheckinNotifications({ isCheckinsModuleEnabled });

        this.notificationSettingsMap = {
          okrs: [
            {
              key: "okrsEditedByMe",
              titleKey: "updates_okrs_owned_by_me",
              isRestricted: this.hasViewOnlyRole,
            },
            {
              key: "okrsEditedByTeam",
              titleKey: "updates_okrs_owned_by_my_team",
            },
            {
              key: "okrsAndTasksAssignedMeMyTeam",
              titleKey: "okrs_assigned_me_or_team",
            },
            {
              key: "objectiveApprovals",
              titleKey: "approval_workflow",
              isRestricted: this.hasViewOnlyRole,
            },
          ],
          krs: [
            {
              key: "krProgressUpdateMe",
              titleKey: "key_results_owned_by_me",
              isRestricted: this.hasViewOnlyRole,
            },
            {
              key: "krProgressUpdateTeam",
              titleKey: "key_results_owned_by_my_team",
            },
          ],
          kpis: [
            {
              key: "kpiEditedOwnedByMe",
              titleKey: "kpis_edited_owned_by_me",
            },
            {
              key: "kpiEditedOwnedByMyTeam",
              titleKey: "kpis_edited_owned_by_my_team",
            },
            {
              key: "kpiAssignedMeMyTeam",
              titleKey: "kpis_assigned_to_me_or_my_team",
            },
            {
              key: "kpiProjectionsMissedOrBeaten",
              titleKey: "kpis_projections_missed_or_beaten",
            },
          ],
          tasks: [
            {
              key: "taskAssignedMeMyTeam",
              titleKey: "tasks_assigned_me",
            },
          ],
          badges: [
            {
              key: "announcements",
              titleKey: "badges_awarded_to_me_or_my_team",
            },
          ],
          mentionsInComments: [
            {
              key: "mentionsInComment",
              titleKey: "new_comments_and_mentions_and_reactions",
            },
          ],
          sharing: [
            {
              key: "sharedItems",
              titleKey: okrViewsAvailable ? "sharing_notification_settings" : "kpis_insightboards_whiteboards_reports_sessions_shared_with_me",
            },
          ],
          ...(this.canManageCheckinNotifications && {
            reflections: [
              {
                key: "reflectionReminders",
                titleKey: "check_in_reminders",
              },
              {
                key: "reflectionsPublishedByTeammates",
                titleKey: "check_ins_published_by_teammates",
              },
            ],
          }),
          teams: [
            {
              key: "teamsAssignedToMe",
              titleKey: "teams_assigned_to_me",
            },
          ],
        };
      });
  }

  public getCanManageCheckinNotifications(props: { isCheckinsModuleEnabled: boolean }): boolean {
    if (!this.isReflectionEnabled) {
      return false;
    }

    if (!props.isCheckinsModuleEnabled) {
      return false;
    }

    if (this.hasViewOnlyRole) {
      return false;
    }

    if (!hasPermissions(reduxStoreContainer.reduxStore.getState(), "AccessPeople")) {
      return false;
    }

    return true;
  }
}
