import { AfterContentInit, Directive, Input } from "@angular/core";
import { IndexableObject } from "ng-zorro-antd/core/types";
import { NzRowDirective } from "ng-zorro-antd/grid";
import { UiGridAlign, UiGridJustify } from "@webapp/ui/grid/grid.models";

@Directive({
  selector: "[ui-row],ui-row,ui-form-item",
  exportAs: "uiRow",
  standalone: true,
})
export class UiRowDirective extends NzRowDirective implements AfterContentInit {
  @Input("uiAlign") public nzAlign: UiGridAlign | null = null;
  @Input("uiJustify") public nzJustify: UiGridJustify | null = null;
  @Input("uiGutter") public nzGutter: string | number | IndexableObject | [number, number] | [IndexableObject, IndexableObject] | null = null;

  public ngAfterContentInit(): void {
    const rowElement = this.elementRef.nativeElement as HTMLElement;
    const colsAttr = rowElement.getAttribute("ui-columns");
    if (colsAttr) {
      const colsData = JSON.parse(colsAttr) as string[];
      const fixedSizeCols: string[] = [];
      colsData.forEach((data) => {
        if (data !== "auto") fixedSizeCols.push(data);
      });

      const fixedSizeString = fixedSizeCols.join(" - ");
      // we have 2 or more cols
      if (colsData.length >= 2) {
        const colElements = rowElement.children;
        for (let i = 0; i < colElements.length; i++) {
          if (colsData[i] === "auto") {
            const columnElement = colElements[i];
            columnElement.setAttribute("style", `max-width: calc(100% - ${fixedSizeString})`);
          }
        }
      }
    }
  }
}
