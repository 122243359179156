<div *ngIf="suggestionsStatus?.isLoading">
  <div class="sp-mt-4" *ngFor="let _ of [].constructor(maxTasksToSuggest - taskList?.length)">
    <pi-loading-card-v2 *ngIf="isInline" [isSelected]="false" borderStyle="dashed"></pi-loading-card-v2>
    <pi-loading-card-v2 *ngIf="!isInline" [isSelected]="false" [isCustomIcon]="true" iconType="task" iconTheme="fill"></pi-loading-card-v2>
  </div>
</div>

<div *ngIf="suggestionsStatus?.isError">
  <div class="sp-mt-4">
    <pi-clickable-error-card
      [instance]="tasksInstance"
      [isRemoveBtnAvailable]="isRemoveBtnAvailable"
      [isRemoveBtnAtHeader]="isRemoveBtnAtHeader"
      [errorData]="suggestionsStatus.errorData"></pi-clickable-error-card>
  </div>
</div>

<div *ngIf="tasks && tasks.length > 0 && !suggestionsStatus?.isLoading && !suggestionsStatus?.isError">
  <div *ngFor="let task of tasks; let i = index; let first = first" [class.sp-mt-4]="!first" data-test-id="okr-suggestion-item-tasks">
    <pi-feedback-card
      *ngIf="((isInline && !task.isHidden) || !isInline) && !task.isLoading && !task.isError"
      [borderStyle]="borderStyle"
      [goal]="item"
      [suggestion]="getTaskFeedbackSuggestion(task, i)"
      [instance]="tasksInstance"
      [isIconAvailable]="true"
      [isRemoveBtnAvailable]="isRemoveBtnAvailable"
      [isRemoveBtnAtHeader]="isRemoveBtnAtHeader"
      [isInline]="isInline"
      [iconType]="iconType"
      [iconTheme]="iconTheme"
      suggestionFeedbackType="task"
      suggestionFeedbackSubType="title">
    </pi-feedback-card>

    <pi-loading-card-v2
      *ngIf="((isInline && !task.isHidden) || !isInline) && task.isLoading && isInline"
      [suggestion]="getTaskFeedbackSuggestion(task, i)"
      [instance]="tasksInstance"
      borderStyle="dashed"></pi-loading-card-v2>

    <pi-loading-card-v2
      *ngIf="((isInline && !task.isHidden) || !isInline) && task.isLoading && !isInline"
      [suggestion]="getTaskFeedbackSuggestion(task, i)"
      [instance]="tasksInstance"
      [isCustomIcon]="true"
      iconType="task"
      iconTheme="fill"></pi-loading-card-v2>

    <pi-clickable-error-card
      *ngIf="((isInline && !task.isHidden) || !isInline) && task.isError"
      [suggestion]="getTaskFeedbackSuggestion(task, i)"
      [instance]="tasksInstance"
      [isRemoveBtnAvailable]="false"
      [errorData]="task.errorData"
      (errorRefresh)="onSuggestAnotherTask(task, i)"
      (goBackToSuggestion)="onGoBackToSuggestionClicked(i)">
    </pi-clickable-error-card>
  </div>
</div>
