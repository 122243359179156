import { IHttpResponse } from "angular";
import { EntityExistsResponseError, isEntityExistsResponse } from "@gtmhub/core/routing";
import { ITag } from "./models";

export type TagExistsResponseError = EntityExistsResponseError & { data: ITag; error: "tag with the same name already exists" };
export type DuplicateTagTitleResponseError = { status: 409; data: "tag with the same name already exists" };

export const isTagExistsResponseError = (response: IHttpResponse<unknown>): response is IHttpResponse<TagExistsResponseError> => {
  if (!isEntityExistsResponse(response)) {
    return false;
  }
  return response.data.error === "tag with the same name already exists";
};

export const isDuplicateTagTitleResponseError = (response: IHttpResponse<unknown>): response is IHttpResponse<DuplicateTagTitleResponseError> => {
  return response?.status === 409 && response.data === "tag with the same name already exists";
};
