<div *ngIf="stylesMap" [uiGutter]="[24, 24]" ui-row>
  <div ui-col uiXs="24" uiMd="24" uiLg="24">
    <div ui-row uiJustify="center">
      <div>
        <img [ngStyle]="{ 'width.px': stylesMap.iconSize.width, 'height.px': stylesMap.iconSize.height }" [alt]="titleKey | localize" src="/dist/img/nothing-found-grey.svg" />
      </div>
    </div>

    <div class="mt-20px" ui-row uiJustify="center">
      <div ui-col>
        <h2 [class]="'fw-500 text-color-gray-10 fs-' + stylesMap.fontSize.titleSize + 'px'" ui-row uiJustify="center">{{ titleKey | localize }}</h2>
        <p [class]="'fw-400 text-color-gray-10 fs-' + stylesMap.fontSize.infoSize + 'px'" ui-row uiJustify="center">{{ infoKey | localize }}</p>
      </div>
    </div>
  </div>
</div>
