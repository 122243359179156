import { IComponentOptions, IController } from "angular";

class NothingFoundCtrl implements IController {}

export const NothingFoundComponent: IComponentOptions = {
  template: require("./nothing-found.html"),
  controller: NothingFoundCtrl,
  bindings: {
    titleKey: "@",
    infoKey: "@",
    theme: "@",
  },
};
