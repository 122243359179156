import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseApiServiceV2 } from "@webapp/core/api/base-api-v2.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { Whiteboard } from "../models/whiteboard.models";

@Injectable({ providedIn: "root" })
export class WhiteboardsApiV2Service extends BaseApiServiceV2<Whiteboard, RequestPaging> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig) {
    super("whiteboards", new ApiVersionModel({ version: "v1", trailingSlash: true }), httpClient, appConfig);
  }
}
