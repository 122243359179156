import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { localize } from "@gtmhub/localization";
import { InsightboardInsightUsage, KpiInsightUsage, MetricInsightUsage } from "@webapp/data-story/models/insights.models";
import { Insightboard } from "@webapp/insightboards/models";
import { Kpi } from "@webapp/kpis/models/kpis.models";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { UI_MODAL_DATA } from "@webapp/ui/modal/modal.models";

export type EditInsightModalData = {
  relatedKPIs: KpiInsightUsage[];
  relatedKRs: MetricInsightUsage[];
  relatedInsightboards: InsightboardInsightUsage[];
  relatedItemsCount: number;
  insightTitle: string;
  itemsWithoutPermissionsCount: number;
};

@Component({
  selector: "edit-insight-modal",
  templateUrl: "./edit-insight-modal.component.html",
  styleUrls: ["./edit-insight-modal.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditInsightModalComponent implements OnInit {
  @ViewChild("inputElement", { static: false }) public inputElement?: ElementRef;

  public relatedKPIs: Kpi[];
  public relatedKRs: Metric[];
  public relatedInsightboards: Insightboard[];
  public relatedItemsCount: number;
  public insightTitle: string;
  public itemsWithoutPermissionsCount: number;
  public editInsightMethod = "existing";
  public newInsightTitle: string;
  public shouldHideInsightboards = true;
  public shouldHideKPIs = true;
  public shouldHideKRs = true;

  constructor(@Inject(UI_MODAL_DATA) modalData: EditInsightModalData) {
    Object.assign(this, modalData);
  }

  public ngOnInit(): void {
    this.newInsightTitle = localize("cloning_insight", { insightTitle: this.insightTitle });
  }

  public focusInput(): void {
    window.setTimeoutOutsideAngular(() => {
      this.inputElement?.nativeElement.focus();
    }, 10);
  }

  public getMoreRelatedItemsString(itemsCount: number): string {
    return `+${itemsCount - 3} more...`;
  }
}
