import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest } from "rxjs";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { Permission } from "../models/permissions.model";

@UntilDestroy()
@Directive({
  selector: "[hasAllPermissions]",
  standalone: true,
})
export class HasAllPermissionsDirective implements OnInit {
  @Input()
  public hasAllPermissions: Permission | Permission[];

  @Input()
  public hasAllPermissionsNegate?: boolean;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private container: ViewContainerRef,
    private permissionsFacade: PermissionsFacade
  ) {}

  public ngOnInit(): void {
    this.container.clear();

    const permissions = Array.isArray(this.hasAllPermissions) ? this.hasAllPermissions : [this.hasAllPermissions];
    combineLatest(permissions.map((permission) => this.permissionsFacade.hasPermission$(permission)))
      .pipe(untilDestroyed(this))
      .subscribe((hasPermissions) => {
        const show = hasPermissions.every((x) => x === !this.hasAllPermissionsNegate);
        if (show) {
          this.container.createEmbeddedView(this.templateRef);
        } else {
          this.container.clear();
        }
      });
  }
}
