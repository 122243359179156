import { IComponentOptions, IRootScopeService } from "angular";
import { IModalScope, IModalService } from "angular-ui-bootstrap";
import { ITraceRootScopeService } from "@gtmhub/core/tracing";
import { IIndicator } from "@gtmhub/error-handling";
import { CfMap } from "@webapp/custom-fields/models/custom-fields.models";
import { IPatchedUser, IUser } from "../../models";
import { UserService } from "../../user-service";

export interface IConfirmScope extends IModalScope {
  indicators: {
    saving?: IIndicator;
  };
  user: IUser;
  requiredCfNotEmpty: boolean;
  save(): void;
  updateCustomFields(customFields: CfMap): void;
  setRequiredCf(requiredCf: { areFilled: boolean }): void;
}

export class EditUserNamesCtrl {
  public static $inject = ["$rootScope", "$uibModal"];
  user: IUser;
  broadcastChange: () => void;

  constructor(
    private $rootScope: IRootScopeService & ITraceRootScopeService,
    private $uibModal: IModalService
  ) {}

  $onInit = (): void => {
    if (this.user) {
      this.user.customFields = this.user.customFields || {};
    }

    this.$rootScope.$broadcast("focusTextInput");
  };

  editUserName = (): void => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const ctrl = this;

    this.$uibModal.open({
      template: require("./edit-user-names.component.html"),
      windowClass: "modal medium edit-user-modal",
      controller: [
        "$scope",
        "$rootScope",
        "UserService",
        function ($scope: IConfirmScope, $rootScope: IRootScopeService & ITraceRootScopeService, userService: UserService) {
          $scope.user = angular.copy(ctrl.user);
          $scope.requiredCfNotEmpty = false;
          $scope.indicators = {};
          $scope.save = (): void => {
            $rootScope.traceAction("edit_user", () => {
              $scope.indicators.saving = { progress: true };

              const patchedUser: IPatchedUser = {
                firstName: $scope.user.firstName,
                lastName: $scope.user.lastName,
                customFields: $scope.user.customFields,
              };

              userService.patchUser($scope.user.id, patchedUser).then(
                () => {
                  delete $scope.indicators.saving;

                  if (ctrl.broadcastChange) {
                    ctrl.broadcastChange();
                  }

                  $scope.$close();
                },
                (error) => ($scope.indicators.saving = { error })
              );
            });
          };
          $scope.updateCustomFields = (customFields: CfMap): void => {
            $scope.user.customFields = customFields;
          };
          $scope.setRequiredCf = (requiredCf: { areFilled: boolean }): void => {
            $scope.requiredCfNotEmpty = requiredCf.areFilled;

            $scope.$evalAsync();
          };
        },
      ],
    });
  };
}

export const EditUserNamesComponent: IComponentOptions = {
  bindings: {
    user: "<",
    broadcastChange: "&",
  },
  controller: EditUserNamesCtrl,
  template: require("./edit-user-names-btn.component.html"),
};
