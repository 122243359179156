<ui-select
  [uiMode]="'multiple'"
  [ngModel]="selectedValues"
  [uiDisabled]="disabled"
  [readonly]="readonly"
  [a11yLabelledby]="a11yLabelledby"
  [a11yRequired]="a11yRequired"
  [uiShowArrow]="!borderless"
  [uiShowEmptyDropdown]="false"
  [uiDropdownMatchSelectWidth]="false"
  [uiId]="uiId"
  (ngModelChange)="onSelection($event)"
  uiSize="large"
  uiDropdownClassName="cf-multi-select-container"
  data-test-id="custom-field-multiselect"
  uiShowSearch>
  <ng-container>
    <ui-option *ngFor="let option of options" [uiLabel]="option.value" [uiValue]="option.value" [uiHide]="selectedValuesSet?.has(option.value)"></ui-option>
  </ng-container>
</ui-select>
