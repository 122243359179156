import { Pipe, PipeTransform } from "@angular/core";
import { TodoItem, TodoItemType } from "@webapp/home/components/widgets/todos-widget/todos-widget.models";
import { getTodoDueDate } from "./utils";

const todoTypesMap: Record<TodoItemType, string> = {
  goal: "Objective for approval",
  metric: "Key Result",
  "check-in": "Check-in",
  task: "Task",
};

@Pipe({
  name: "todoAriaLabel",
  standalone: true,
})
export class TodoAriaLabelPipe implements PipeTransform {
  public transform(todo: TodoItem, options: { type: TodoItemType; nameField: string }): string {
    if (!todo || !options) {
      return null;
    }

    const itemTypeLabel = todoTypesMap[options.type];
    const todoName = todo[options.nameField];
    const dueOn = getTodoDueDate(todo);

    // e.g. Key Result, some-key-result-name, due today
    // e.g. Objective for approval, some-okr-name
    return `${itemTypeLabel}, ${todoName}` + (dueOn ? `, ${dueOn}` : "");
  }
}
