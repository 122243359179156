import { IAssigneesStoreState } from "@gtmhub/assignees";
import { INgRedux } from "@gtmhub/state-management";
import { MENTION_PATTERN } from "@webapp/shared/rich-text-editor/rich-text-editor.utils";

const regex = new RegExp(MENTION_PATTERN, "g");

/**
 * Strips mentioned parts and leaves only the name
 */
export const mentioning = [
  "$ngRedux",
  function ($ngRedux: INgRedux): MentioningFilter {
    return function (text: string): string {
      if (!text) {
        return text;
      }

      const { assignees } = $ngRedux.getState<IAssigneesStoreState>();
      const matches = text.matchAll(regex);
      for (const match of matches) {
        // a match is an array of the following:
        // [whole matched string, name, email, id]
        const id = match[3];
        let name = match[1];
        if (assignees.isFetched && assignees.map[id]) {
          name = assignees.map[id].name;
        }

        text = text.replace(match[0], name);
      }

      return text;
    };
  },
];

export interface MentioningFilter {
  (text: string): string;
}
