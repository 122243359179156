import { Injectable } from "@angular/core";
import { Observable, concatMap, map, tap } from "rxjs";
import { DashboardEventType } from "@gtmhub/insightboards/services/events";
import { IReport } from "@gtmhub/reporting/models";
import { ITag } from "@gtmhub/tags";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { QueryParams } from "@webapp/core/abstracts/models/query-params.model";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { ICollection } from "@webapp/core/core.models";
import { Insight } from "@webapp/data-story/models/insights.models";
import {
  ICachedInsightResponse,
  IInsightBoardItemResponse,
  IInsightboardItem,
  IInsightboardItemSetting,
  IInsightboardTransactions,
  Insightboard,
} from "@webapp/insightboards/models";
import { getTeamsWithSubteamsPermissionsCount } from "@webapp/insightboards/utils/insightboards.utils";
import { InsightboardApiService } from "./insightboard-api.service";

@Injectable()
export class InsightboardRepositoryService {
  constructor(
    private insightboardApiService: InsightboardApiService,
    private broadcastService: BroadcastService
  ) {}

  public getInsightboard$(id: string, fields: string = ""): Observable<Insightboard> {
    return this.insightboardApiService.get$(id, { queryParams: { fields } });
  }

  public getInsightboardsV2$(params: QueryParams): Observable<ICollection<Insightboard>> {
    const url = this.insightboardApiService.getBasePath(HttpActions.getAll, { apiVersionOverwrite: "v2" });

    return this.insightboardApiService.getAll$(params, { url: url });
  }

  public getReports$(): Observable<IReport[]> {
    const url = this.insightboardApiService.getBasePath(HttpActions.get) + "/reports";

    return this.insightboardApiService.get$(null, { url });
  }

  public createInsightboard$(insightboard: Insightboard): Observable<Insightboard> {
    return this.insightboardApiService.post$(insightboard, {
      queryParams: {
        teamsWithSubteamsPermissionsCount: getTeamsWithSubteamsPermissionsCount(insightboard),
      },
    });
  }

  public addInsightToInsightboard$(dashboardId: string, insight: Insight): Observable<Insightboard> {
    const initialBoardFields =
      "id,insights{col,filters,id,insightId,insightName,insightTitle,row,sizeX,sizeY,insightState,insightSource,insightRequiredEntities,formatting,type,sectionHeaderId},name,sectionHeaders{col,id,row,sizeX,sizeY,sectionHeader,type,sectionHeaderId},parameters{defaultValue,entityName,entityTitleField,entityValueField,id,key,predefinedValues,selectorType,title},readOnly,settings{name,value,valueDisplayName,valueDisplayNameAsArray,dynamicValues},tags{name,title},isReport";

    return this.getInsightboard$(dashboardId, initialBoardFields).pipe(
      concatMap((insightboard) => {
        const newWidget: IInsightboardItem = {
          id: dashboardId,
          dashboardId: dashboardId,
          sizeX: insight.size.width,
          sizeY: insight.size.height,
          insightName: insight.name,
          insightId: insight.id,
          type: "insight",
          filters: angular.copy(insight.usedFilters),
        };

        insightboard.insights.unshift(newWidget);

        return this.saveInsightboardItems$(dashboardId, [...insightboard.insights, ...insightboard.sectionHeaders]).pipe(map(() => insightboard));
      })
    );
  }

  public saveInsightboardItem(item: IInsightboardItem): Observable<boolean> {
    const url = this.insightboardApiService.getBasePath(HttpActions.put) + `/items/${item.id}`;

    return this.insightboardApiService.put$(null, item, { url });
  }

  public updateInsightboard$(insightboard: Insightboard): Observable<Insightboard> {
    const body = {
      name: insightboard.name,
      access: insightboard.access,
      settings: insightboard.settings,
      archived: insightboard.archived,
    };

    return this.insightboardApiService
      .put$(insightboard.id, body, {
        queryParams: {
          teamsWithSubteamsPermissionsCount: getTeamsWithSubteamsPermissionsCount(insightboard),
        },
      })
      .pipe(tap(() => this.broadcastService.emit(DashboardEventType.DASHBOARD_UPDATED, { insightboard })));
  }

  public editInsightboardSettings$(id: string, name: string, settings?: IInsightboardItemSetting[]): Observable<Insightboard> {
    const body = {
      name: name,
      settings: settings,
    };

    return this.insightboardApiService.put$(id, body);
  }

  public saveInsightboardItems$(insightboardId: string, items: IInsightboardItem[]): Observable<IInsightBoardItemResponse> {
    const url = this.insightboardApiService.getBasePath(HttpActions.post) + `/${insightboardId}/items`;

    return this.insightboardApiService.post$(items as unknown, { url });
  }

  public addTag$(insightboardId: string, tag: ITag): Observable<unknown> {
    const url = this.insightboardApiService.getBasePath(HttpActions.post) + `/${insightboardId}/tags`;

    return this.insightboardApiService.post$(tag, { url });
  }

  public removeTagFromInsightboard$(tagTitle: string, insightboardId: string): Observable<unknown> {
    const url = this.insightboardApiService.getBasePath(HttpActions.delete) + `/${insightboardId}/tags`;

    return this.insightboardApiService.delete$(null, { url, body: { title: tagTitle } });
  }

  public getCachedInsightboardWidgets$(insightboardId: string, parameters: IInsightboardItemSetting[] = null): Observable<ICachedInsightResponse[]> {
    const url = this.insightboardApiService.getBasePath(HttpActions.post) + `/${insightboardId}/cached/calculate`;

    return this.insightboardApiService.post$(
      {
        parameters: parameters,
      } as unknown,
      { url }
    );
  }

  public cloneInsightboard$(insightboardId: string, newInsightboardTag: string): Observable<Insightboard> {
    const url = this.insightboardApiService.getBasePath(HttpActions.post) + `/${insightboardId}/clone`;

    return this.insightboardApiService.post$(
      {
        tag: newInsightboardTag,
      },
      { url, queryParams: { cloned: true } }
    );
  }

  public calculateInsightboardWidgets$(
    insightboardId: string,
    parameters: IInsightboardItemSetting[] = null,
    excludeInsightIds: string[] = null
  ): Observable<ICollection<IInsightboardTransactions>> {
    const url = this.insightboardApiService.getBasePath(HttpActions.post) + `/${insightboardId}/calculate`;

    return this.insightboardApiService.post$(
      {
        parameters: parameters,
        excludeInsightIds: excludeInsightIds,
      } as unknown,
      { url }
    );
  }

  public deleteInsightboard$(id: string): Observable<void> {
    return this.insightboardApiService.delete$<void>(id).pipe(tap(() => this.broadcastService.emit(DashboardEventType.DASHBOARD_DELETED, { id })));
  }
}
