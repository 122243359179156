import { IOkrGridContributorAdditionalParams } from "@gtmhub/goals/models";
import { IInsightSelectorData } from "@gtmhub/insights";
import { AutoFieldsService } from "@gtmhub/insights/services/auto-fields.service";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { CascadingLink, ILinksSpecDetail, Links } from "@webapp/links/models/links.models";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { UnitTypeLong } from "@webapp/shared/libs/dayjs";

export enum MetricDeadLineEnum {
  NONE = "none",
  HARD_DEADLINE = "hardDeadline",
  HARD_AND_SOFT_DEADLINE = "hardAndSoftDeadline",
}
export type MetricDeadLine = MetricDeadLineEnum.NONE | MetricDeadLineEnum.HARD_DEADLINE | MetricDeadLineEnum.HARD_AND_SOFT_DEADLINE;
export type MetricDeadLineType = "softDeadline" | "hardDeadline";

export const metricFormStrings = {
  metricCustomFields: "metricCustomFields",
  session: "session",
  allowedActions: "allowedActions",
  double: "double",
  boolean: "boolean",
  empty: "",
  day: "day" as UnitTypeLong,
  default: {
    date: "0001-01-01T00:00:00Z",
  },
  manageData: "ManageData",
  admin: "admin",
  planningSession: "planningSession",
};

export function calculateMetricDelta(metric?: Metric): number {
  if (!metric) {
    return 0;
  }

  const target = metric.target || 0;
  const initialValue = metric.initialValue || 0;

  return target - initialValue;
}

export interface IContributorMetricValues {
  value: number;
  percentage: number;
}

export function calculateContributorMetricValues(cascadingMetric?: Metric, contributorMetric?: Metric): IContributorMetricValues {
  const contributorMetricValues: IContributorMetricValues = {
    value: 0,
    percentage: 0,
  };

  if (!cascadingMetric || !contributorMetric) {
    return contributorMetricValues;
  }

  switch (cascadingMetric.cascadeType) {
    case "delta_value":
      contributorMetricValues.value = calculateMetricDelta(contributorMetric);
      contributorMetricValues.percentage = (contributorMetricValues.value / calculateMetricDelta(cascadingMetric)) * 100;

      return contributorMetricValues;
    case "absolute_value":
      contributorMetricValues.value = contributorMetric.target;
      contributorMetricValues.percentage = (contributorMetricValues.value / calculateMetricDelta(cascadingMetric)) * 100;

      return contributorMetricValues;
    default:
      return contributorMetricValues;
  }
}

export function isContributingMetric(metric: Metric | IOkrGridContributorAdditionalParams): boolean {
  return !!metric.links?.spec?.contributing;
}

export function generateMetricLinksSpec(linkType: CascadingLink, targetIds: string[], targetType: "metric" | "kpi" = "metric"): Links<"metric"> {
  return {
    spec: {
      [linkType]: generateMetricLinksSpecDetails(targetIds, targetType),
    },
  };
}

export function generateMetricLinksSpecDetails(metricIds: string[], targetType: "metric" | "kpi" = "metric"): ILinksSpecDetail<typeof targetType>[] {
  return metricIds.map<ILinksSpecDetail<typeof targetType>>((metricId) => ({ type: targetType, id: metricId }));
}

export function sendPredictionAnalyticsFeedback(autoFieldsService: AutoFieldsService, data: IInsightSelectorData): void {
  const { marketplaceBody, accountBody } = autoFieldsService.getRecommendedInsightAnalyticsData(data);

  autoFieldsService.sendInsightRecommendtationFeedback(data.marketplacePredictionId, marketplaceBody);
  autoFieldsService.sendInsightRecommendtationFeedback(data.accountPredictionId, accountBody);
}

export function sendTrackingAnalytics(analyticsService: AnalyticsService, data: IInsightSelectorData, eventTitle: string): void {
  analyticsService.track(eventTitle, {
    totalInsightsSuggested: data.suggestedInsights?.length || 0,
    ...(data.insightTemplate && {
      insightSuggestedId: data.insightTemplate.id,
    }),
    isAutomationUsed: !!data.insightTemplate,
    isSelectedInsightFromAccount: data.isInsightFromAccount,
    suggestedMarketplaceCount: data.suggestedMarketplaceCount,
    suggestedAccountCount: data.suggestedAccountCount,
  });
}

/**
 * Formats the goal/metric attainment.
 * By default it rounds the value as expected (.4 down and .5 up).
 * If the value is between 0.99 - 1 (99 - 100%) the value is rounded always down, because not to display 100% goal reached, when it is 99.5% reached.
 * @param progress - Attainment value (ex. 0.60)
 * @returns - The formatted attainment value
 */
export function formatAttainmentProgressValue(progress: number): number {
  if (progress > 0.99 && progress < 1) {
    // Double Bitwise NOT Operator does the same as Math.floor but much faster
    return ~~(progress * 100);
  }
  return +(progress * 100).toFixed(0);
}

export function getNumberSign(
  numberToCheck: number,
  signOptions: {
    lowSign: string;
    topSign: string;
  } = {
    lowSign: "-",
    topSign: "+",
  },
  limitOptions: {
    signedLowLimit: number;
    signedTopLimit: number;
  } = {
    signedLowLimit: 0.005,
    signedTopLimit: 10,
  }
): string {
  if (numberToCheck >= limitOptions.signedLowLimit && numberToCheck < limitOptions.signedTopLimit) {
    return signOptions.topSign;
  } else if (numberToCheck <= -limitOptions.signedLowLimit && numberToCheck > -limitOptions.signedTopLimit) {
    return signOptions.lowSign;
  }

  return "";
}
