import { Pipe, PipeTransform } from "@angular/core";
import { CustomFieldsFormItemComponent } from "../components/form-items/custom-fields-form-item/custom-fields-form-item.component";
import { FormItemBase } from "../models/form.models";

/**
 * Checks if the provided form item is a CustomFieldsFormItemComponent instance.
 */
@Pipe({
  name: "isCustomFieldsFormItem",
})
export class IsCustomFieldsFormItemPipe implements PipeTransform {
  public transform(item: FormItemBase): item is CustomFieldsFormItemComponent {
    return item instanceof CustomFieldsFormItemComponent;
  }
}
