import { IAugmentedJQuery, IComponentOptions, ITimeoutService } from "angular";
import { GtmhubController } from "@gtmhub/core";
import { ITraceRootScopeService } from "@gtmhub/core/tracing";

export interface IGHCellChangePayload<T> {
  item?: T;
  action?: string;
}
export interface IGHCellClickPayload<T> {
  item: T;
  column: IGHGridColumn;
}

export interface IGHGridColumn {
  fieldName: string;
  width: number;
  minWidth?: number;
  maxWidth?: number;
  label?: string;
  type?: ColumnType;
  isCustom?: boolean;
  widthModifiedByUser?: boolean;
  visible: boolean;
  trackingName?: string;
}

export interface IGridComponentBindings {
  gridItems: unknown[];
  columns: IGHGridColumn[];
  onChange<T>(payload?: IGHCellChangePayload<T>): void;
  onClick<T>(payload: IGHCellClickPayload<T>): void;
  userCanFreezeColumns: boolean;
  userCanResizeColumns: boolean;
  freezeLastColumn: boolean;
}

type ColumnType = "alphabetic" | "id" | "selectassignee" | "date" | "numeric" | "dropdown" | "boolean" | "multiselect" | "array" | "user" | "permissions" | "item";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface GridComponentCtrl extends IGridComponentBindings {}

const MAX_COLUMN_WIDTH_AVERAGE_SCREEN = 350;
const MAX_COLUMN_WIDTH_SMALL_SCREEN = 250;
export class GridComponentCtrl extends GtmhubController {
  lastFrozenColumnIndex: number;
  draggingDivider: boolean;
  frozenColumnsWidth: number;
  private columnResizeStartX: number;

  static $inject = ["$rootScope", "$timeout"];

  constructor(
    private $rootScope: ITraceRootScopeService,
    private $timeout: ITimeoutService
  ) {
    super();
  }

  $onInit(): void {
    if (this.userCanFreezeColumns) {
      this.$timeout(() => {
        this.lastFrozenColumnIndex = 1;
        this.enableFreezeColumns();
      }, 500);
    }
  }

  formatHandleVerticalLine = ($event: JQueryEventObject): void => {
    const handleVLHeight = 100 * this.gridItems.length;
    angular.element($event.target.querySelector(".vl")).css("height", `${handleVLHeight}%`);
  };

  onColumnResizeStart = ($event: Partial<DragEvent>): void => {
    this.columnResizeStartX = $event.screenX;
  };

  onColumnResizeEnd = ($event: Partial<DragEvent>, colIndex: number, column: IGHGridColumn): void => {
    const widthDifference = $event.screenX - this.columnResizeStartX;
    const newWidth = this.columns[colIndex].width + widthDifference;
    const minColumnWidth = column.minWidth || 40;
    let maxColumnWidth = column.maxWidth;

    if (maxColumnWidth && window.matchMedia("screen and (max-width: 768px)").matches) {
      maxColumnWidth = MAX_COLUMN_WIDTH_SMALL_SCREEN;
    } else if (maxColumnWidth && window.matchMedia("screen and (max-width: 1280px)").matches) {
      maxColumnWidth = MAX_COLUMN_WIDTH_AVERAGE_SCREEN;
    }

    this.columns[colIndex].width = newWidth < minColumnWidth ? minColumnWidth : maxColumnWidth && newWidth > maxColumnWidth ? maxColumnWidth : Math.round(newWidth);
    this.columns[colIndex].widthModifiedByUser = true;

    if (this.userCanFreezeColumns && colIndex <= this.lastFrozenColumnIndex) {
      this.snapDividerAtFrozenColEdge();
    }

    if (this.onChange) {
      this.onChange();
    }
  };

  showUnfrozenColumn = ($index: number): boolean => {
    let result = true;
    if (this.userCanFreezeColumns) {
      result = $index > this.lastFrozenColumnIndex;
    }

    if (this.freezeLastColumn) {
      result = result && $index < this.columns.length - 1;
    }

    return result;
  };

  onMouseover(index: number): void {
    this.getRowsAtIndex(index).forEach((row) => row.addClass("row-hovered"));
  }

  onMouseleave(index: number): void {
    this.getRowsAtIndex(index).forEach((row) => row.removeClass("row-hovered"));
  }

  private getRowsAtIndex(index: number): IAugmentedJQuery[] {
    const rows = [];
    rows.push(this.getNonFrozenRow(index));

    if (this.freezeLastColumn) {
      rows.push(this.getLastFrozenRow(index));
    }

    if (this.userCanFreezeColumns) {
      rows.push(this.geFrozenRow(index));
    }

    return rows;
  }

  private getNonFrozenRow(index: number): IAugmentedJQuery {
    const nonFrozenRows = document.querySelectorAll<HTMLElement>("#non-frozen-cols-wrapper .grid-row:not(.grid-row-header)");
    return angular.element(nonFrozenRows[index]);
  }

  private getLastFrozenRow(index: number): IAugmentedJQuery {
    const actionsRows = document.querySelectorAll<HTMLElement>(".frozen-cols-wrapper.last .grid-row:not(.grid-row-header)");
    return angular.element(actionsRows[index]);
  }

  private geFrozenRow(index: number): IAugmentedJQuery {
    const actionsRows = document.querySelectorAll<HTMLElement>(".frozen-cols-wrapper:not(.last) .grid-row:not(.grid-row-header)");
    return angular.element(actionsRows[index]);
  }

  private enableFreezeColumns = () => {
    const divider = document.getElementById("pane-divider");
    const snap = document.getElementById("divider-snap");

    let pos1 = 0;
    let pos2 = 0;
    let finalDividerPosition: number;
    let finalFrozenColIndex: number;

    const onDividerDragStart = (e: DragEvent) => {
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos2 = e.clientX;
      divider.style.position = "absolute";
      this.draggingDivider = true;
      document.onmouseup = onDividerDragEnd;
      // call a function whenever the cursor moves:
      document.onmousemove = onDividerDragging;
    };

    const onDividerDragging = (e: DragEvent) => {
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos2 - e.clientX;
      pos2 = e.clientX;

      const left = divider.offsetLeft - pos1;
      divider.style.left = left + "px";

      const allColsEdgeX: number[] = this.getAllColsEdgeX();
      const rightEdgeOfHoveredCol = allColsEdgeX.find((el) => el >= left);
      const indexOfHoveredCol = allColsEdgeX.indexOf(rightEdgeOfHoveredCol);
      const previousColIndex = indexOfHoveredCol - 1;
      const rightEdgeOfPreviousCol = allColsEdgeX[previousColIndex] || allColsEdgeX[0];

      const hoveredColWidth = this.getColWidth(indexOfHoveredCol);

      if (left < rightEdgeOfHoveredCol - hoveredColWidth / 2) {
        finalDividerPosition = rightEdgeOfPreviousCol;
        finalFrozenColIndex = previousColIndex;
      } else {
        finalDividerPosition = rightEdgeOfHoveredCol;
        finalFrozenColIndex = indexOfHoveredCol;
      }

      snap.style.left = finalDividerPosition + "px";
    };

    const onDividerDragEnd = () => {
      divider.style.left = finalDividerPosition + "px";
      this.lastFrozenColumnIndex = finalFrozenColIndex;
      document.onmouseup = null;
      document.onmousemove = null;
      divider.style.position = "sticky";
      this.draggingDivider = false;
      this.$rootScope.$digest();
    };

    if (divider) {
      divider.onmousedown = onDividerDragStart;
      this.snapDividerAtFrozenColEdge();
    } else {
      return;
    }
  };

  private getColWidth = (index: number): number => {
    return this.columns[index].width;
  };

  private getAllColsEdgeX = (): number[] => {
    const edgeXArray = [];
    let previousEdgeX = 0;

    for (let i = 0; i < this.columns.length; i++) {
      edgeXArray.push(this.columns[i].width + previousEdgeX);
      previousEdgeX += this.columns[i].width;
    }

    return edgeXArray;
  };

  private getFrozenColsWidth = (): number => {
    let totalWidth = 0;
    for (let i = 0; i <= this.lastFrozenColumnIndex; i++) {
      totalWidth = totalWidth + this.columns[i].width;
    }
    return totalWidth;
  };

  private snapDividerAtFrozenColEdge = () => {
    const divider = document.getElementById("pane-divider");
    const snap = document.getElementById("divider-snap");
    this.frozenColumnsWidth = this.getFrozenColsWidth();

    snap.style.left = this.frozenColumnsWidth + "px";
    divider.style.left = this.frozenColumnsWidth + "px";
  };
}

export const GridComponent: IComponentOptions = {
  template: require("./grid.html"),
  controller: GridComponentCtrl,
  bindings: {
    gridItems: "<",
    columns: "=",
    onChange: "&",
    onClick: "&",
    userCanFreezeColumns: "=",
    userCanResizeColumns: "=",
    freezeLastColumn: "=",
  },
  transclude: {
    ghRowCell: "?ghRowCell",
  },
};
