import { Overlay } from "@angular/cdk/overlay";
import { Injectable, OnDestroy, Optional, SkipSelf } from "@angular/core";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { Observable, Subject } from "rxjs";
import { UiDrawerRef } from "@webapp/ui/drawer/abstracts/drawer-ref";
import { UiDrawerOptions } from "@webapp/ui/drawer/drawer.models";
import { UiDrawerBuilderForService } from "@webapp/ui/drawer/services/drawer-builder.service";

@Injectable({
  providedIn: "root",
})
export class UiDrawerService implements OnDestroy {
  private openDrawers: UiDrawerRef[];
  private readonly afterAllClosedAtThisLevel = new Subject<UiDrawerRef>();

  private get afterCloseSubject(): Subject<UiDrawerRef> {
    const parent = this.parentDrawer;
    return parent ? parent.afterCloseSubject : this.afterAllClosedAtThisLevel;
  }

  constructor(
    private overlay: Overlay,
    @Optional() @SkipSelf() private parentDrawer: UiDrawerService
  ) {
    this.openDrawers = parentDrawer ? parentDrawer.openDrawers : [];
  }

  public create<T = NzSafeAny, D = NzSafeAny, R = NzSafeAny>(options: UiDrawerOptions<T, D>): UiDrawerRef<T, R> {
    const drawerRef = new UiDrawerBuilderForService<T, R>(this.overlay, options).getInstance();
    this.openDrawers.push(drawerRef);
    drawerRef.afterClose.subscribe(() => {
      const index = this.openDrawers.indexOf(drawerRef);
      this.openDrawers.splice(index, 1);
      this.afterCloseSubject.next(drawerRef);
    });
    return drawerRef;
  }

  public getOpenDrawers(): UiDrawerRef[] {
    return [...this.openDrawers];
  }

  public closeAll(): void {
    for (const drawerRef of this.openDrawers) {
      drawerRef.close();
    }
  }

  public afterClose$(): Observable<UiDrawerRef> {
    return this.afterCloseSubject.asObservable();
  }

  public ngOnDestroy(): void {
    this.closeAll();
    this.afterAllClosedAtThisLevel.complete();
  }
}
