import { StateService } from "@uirouter/angularjs";
import { AccountResolverService } from "@webapp/accounts";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";
import { AuthenticationResolverService } from "../../auth";
import { UIErrorHandlingService } from "../../error-handling";
import { MultiAccountService } from "../services/multi-account-service";

interface IMultiAccountInvitationStateParams {
  action: MultiAccountInvitationActionTypes;
  invitationId: string;
}

type MultiAccountInvitationActionTypes = "accept" | "reject";

export class MultiAccountInvitationResponseCtrl {
  public static $inject = [
    "$state",
    "MultiAccountService",
    "$stateParams",
    "UIErrorHandlingService",
    "AccountResolverService",
    "UserProfileService",
    "AuthenticationResolverService",
  ];

  constructor(
    private $state: StateService,
    private multiAccountService: MultiAccountService,
    private $stateParams: IMultiAccountInvitationStateParams,
    private uiErrorHandlingService: UIErrorHandlingService,
    private accountResolverService: AccountResolverService,
    private profileService: UserProfileService,
    private authenticationResolverService: AuthenticationResolverService
  ) {
    const invitationId = this.$stateParams.invitationId;
    const action = this.$stateParams.action;

    if (!invitationId || (action !== "accept" && action !== "reject")) {
      this.navigateToHomeState();
      return;
    }

    if (action === "accept") {
      this.acceptInvitation(invitationId);
    } else {
      this.rejectInvitation(invitationId);
    }
  }

  private acceptInvitation = (invitationId: string): void => {
    this.multiAccountService.acceptMultiAccountInvitation(invitationId).then(
      () => {
        this.navigateToSecondaryAccount();
      },
      (error) => this.uiErrorHandlingService.handleModal(error)
    );
  };

  private rejectInvitation = (invitationId: string): void => {
    this.multiAccountService.rejectMultiAccountInvitation(invitationId).then(
      () => {
        this.navigateToHomeState();
      },
      (error) => this.uiErrorHandlingService.handleModal(error)
    );
  };

  private navigateToSecondaryAccount = (): void => {
    const authUserId = this.authenticationResolverService.getAuthUserId();

    this.multiAccountService.getUserMultiAccounts(authUserId).then(
      (data) => {
        const targetAccount = data.find((account) => account.invitation && account.invitation.id === this.$stateParams.invitationId);

        this.multiAccountService.switchUserAccount(targetAccount, this.accountResolverService.getAccountId(), this.profileService.getProfile().email);
      },
      (error) => this.uiErrorHandlingService.handleModal(error)
    );
  };

  private navigateToHomeState = (): void => {
    this.$state.go("gtmhub.home", null, { reload: true });
  };
}
