import { Ng2StateDeclaration } from "@uirouter/angular";
import { LazyNgAndNg1ModuleRef, lazyLoadNgAndNg1Module } from "@webapp/core/routing/routing.utils";

const loadModule = (): Promise<LazyNgAndNg1ModuleRef> => import(/* webpackChunkName: "automation" */ "./automation.lazy");
const lazyLoad = lazyLoadNgAndNg1Module(loadModule);

export const states: Ng2StateDeclaration[] = [
  {
    name: "gtmhub.automationList.**",
    url: "/automation/all/",
    lazyLoad,
  },
  {
    name: "gtmhub.automation",
    abstract: true,
  },
  {
    name: "gtmhub.automation.history.**",
    url: "/automation/history/:automationId/",
    lazyLoad,
  },
  {
    name: "gtmhub.automation.rules.**",
    url: "/automation/rules/:automationId/",
    lazyLoad,
  },
  {
    name: "gtmhub.automationContactSales.**",
    url: "/automation/contact-sales/",
    lazyLoad,
  },
];
