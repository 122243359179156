import { IComponentOptions, IController } from "angular";
import { ITeam } from "@gtmhub/teams/models/models";

export interface ISubteamTree extends ITeam {
  subteams?: ISubteamTree[];
  showSubteams?: boolean;
}
export class SubteamsTreeCtrl implements IController {
  selectedTeam: ISubteamTree;
  teams: ISubteamTree[];

  $onInit(): void {
    if (this.teams.length > 1) {
      this.setSubteamsTreeOfTeam(this.teams, this.selectedTeam);
    }
  }

  private setSubteamsTreeOfTeam(teams: ISubteamTree[], parent: ISubteamTree): void {
    const children = teams.filter((t) => t.parentId === parent.id);

    parent.subteams = children || [];
    parent.showSubteams = false;
    for (const child of children) {
      this.setSubteamsTreeOfTeam(teams, child);
    }
  }
}

export const SubteamsTreeComponent: IComponentOptions = {
  bindings: {
    teams: "<",
    selectedTeam: "<",
  },
  controller: SubteamsTreeCtrl,
  template: require("./subteams-tree.html"),
};
