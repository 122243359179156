import { CfMap } from "@webapp/custom-fields/models/custom-fields.models";
import { FormValueChange } from "@webapp/shared/form/models/form.models";

export enum TaskStatus {
  toDo = "todo",
  inProgress = "inprogress",
  done = "done",
}

export interface NewKanbanTask {
  name?: string;
  dueDate?: string;
  status?: string | TaskStatus;
  parentId?: string;
  parentType?: TaskParentType;
  ownerId?: string;
}

export interface NewTask {
  name?: string;
  externalId?: string;
  externalSystem?: string;
  metadata?: Record<string, string>;
  description?: string;
  dueDate?: string;
  status?: string | TaskStatus;
  priority?: number;
  parentId?: string;
  parentName?: string;
  parentType?: TaskParentType;
  taskType?: string;
  ownerId?: string;
  customFields?: CfMap;
}

export interface Task extends NewTask {
  id: string;
  unset?: {
    dueDate: boolean;
  };
  accountId?: string;
  dateCreated?: string;
  createdById?: string;
  modifiedById?: string;
  isNew?: boolean;
  archived?: boolean;
  newTodoItem?: boolean;
  snapshots?: TaskSnapshot[];
}

export interface TaskSnapshot {
  id: string;
  modifiedAt: string;
  modifiedBy: string;
  newStatus: TaskStatus;
  oldStatus: TaskStatus;
  previouslyModifiedAt: string;
}

export interface ITaskFilter {
  parentId?: string | string[];
  taskType?: string;
  priority?: number;
  ownerId?: string;
  archived?: boolean;
}

export type TaskParentType = "goal" | "metric";

export type TasksViewMode = "list" | "kanban";

export interface ITaskParent {
  parentId: string;
  name: string;
  parentType: TaskParentType;
}

export interface ITaskParentsIdMap {
  [parentId: string]: ITaskParent;
}

export type TaskSort = "name" | "-name" | "dueDate" | "-dueDate" | "status" | "-status";

export class TaskDTO {}

export type TaskFormValue = FormValueChange<Task & { linkedTo?: { key: string; type: TaskParentType } }>;

export interface TaskError {
  title: string;
  message: string;
  showError: boolean;
}
