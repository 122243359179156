import { Injectable } from "@angular/core";
import { Observable, map, switchMap, take } from "rxjs";
import { localize } from "@gtmhub/localization";
import { toIdMap } from "@gtmhub/util";
import { ISelectorAssignee } from "@webapp/assignees/models/assignee.models";
import { UsersFacade } from "@webapp/users/services/users-facade.service";
import { IPeopleFilterChain, PeopleSelectorRequestInternal } from "../models/models";

@Injectable()
export class PermissionFilterChain implements IPeopleFilterChain {
  private nextChain: IPeopleFilterChain;

  public constructor(private usersFacade: UsersFacade) {}

  public setNextChain(chain: IPeopleFilterChain): void {
    this.nextChain = chain;
  }

  public handle(request: PeopleSelectorRequestInternal, assignees$: Observable<ISelectorAssignee[]>): Observable<ISelectorAssignee[]> {
    if (!request.permissionSetting || !request.permissionSetting.enabled) {
      return this.nextChain ? this.nextChain.handle(request, assignees$) : assignees$;
    }

    const filteredAssignees$ = assignees$.pipe(switchMap((assignees) => this.getUsers$(assignees, request.permissionSetting.permissions)));

    return this.nextChain ? this.nextChain.handle(request, filteredAssignees$) : filteredAssignees$;
  }

  private getUsers$(assignees: ISelectorAssignee[], permissions: string[]): Observable<ISelectorAssignee[]> {
    const assigneeIds = assignees.reduce((ids, a) => (a.type === "user" && ids.push(a.id), ids), []);
    return this.usersFacade.getUsersV2$({ filter: { _id: { $in: assigneeIds } }, fields: ["id", "permissions"] }).pipe(
      take(1),
      map((users) => {
        const usersIdMap = toIdMap(users.items);
        return assignees.map((a) => {
          if (a.type === "user") {
            const userPermissions = usersIdMap[a.id]?.permissions || [];
            if (!this.userHasRequiredPermissions(userPermissions, permissions) && !a.invalid) {
              // Not the best to mutate the assignee, needs to be revisited
              // This is done because ui-assignee accepts the whole asssignee and does not handle errors other than deactivated
              a.name = this.enrichNameWithError(a.name);
              a.invalid = true;
            }
          }

          return a;
        });
      })
    );
  }

  private userHasRequiredPermissions(userPermissions: string[], requiredPermissions: string[]): boolean {
    const requiredPermissionsCount = requiredPermissions.length;

    let matchedPermissionsCount = 0;
    for (let i = 0; i < userPermissions.length; i++) {
      if (requiredPermissions.includes(userPermissions[i])) {
        matchedPermissionsCount++;
      }

      if (matchedPermissionsCount === requiredPermissionsCount) {
        break;
      }
    }

    return matchedPermissionsCount === requiredPermissionsCount;
  }

  private enrichNameWithError(name: string): string {
    const viewOnly = localize("view_only");
    return `${name} (${viewOnly})`;
  }
}
