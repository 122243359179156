<pi-suggestion-drawer-side-modal-wrapper [isOpen]="isOpen">
  <pi-suggestion-drawer-base [isOpen]="isOpen" (visibilityChange)="visibilityChange($event)">
    <ui-tabset>
      <ui-tab *ngIf="tabsVisibility.overview | async" [uiTitle]="'overview' | localize">
        <suggestion-drawer-overview-tab-v2
          [parent]="goal"
          [explanationSection]="explanationSection"
          [whyMatterSectionTitle]="'why_objective_matter' | localize"
          [statePropagator]="overviewTabStatePropagator"
          (retry)="explanationSection.onRetry()"
          parentType="goal">
        </suggestion-drawer-overview-tab-v2>
      </ui-tab>
      <ui-tab
        *ngIf="tabsVisibility.effectiveness | async"
        [uiTitle]="'effectiveness_cap' | localize"
        [uiTabColor]="effectivenessSection?.effectiveness?.score | scoreToLowToHighScale | lowToHighScaleToColor">
        <suggestion-drawer-effectiveness-tab
          [parent]="goal"
          [flowId]="flowId"
          [flowName]="effectivenessFlowName"
          [effectivenessSection]="effectivenessSection"
          [titleSection]="titleSection"
          [improveWithSuggestionsSectionTitle]="'use_the_suggestions_below_improve_objective' | localize"
          [statePropagator]="effectivenessTabStatePropagator"
          (retry)="effectivenessSection.onRetry()"
          parentType="goal"
          parentDrawerContent="goal">
        </suggestion-drawer-effectiveness-tab>
      </ui-tab>

      <ui-tab *ngIf="isQuantivePlusEnabled && tabsVisibility.allSuggestions | async" [uiTitle]="'all_suggestions_cap' | localize">
        <suggestion-drawer-all-suggestions-tab
          [flowName]="allSuggestionsflowName"
          [statePropagator]="allSuggestionsTabStatePropagator"
          [shouldSuggestQuantivePlusDescription]="shouldSuggestQuantivePlusDescription"
          [shouldSuggestQuantivePlusTags]="shouldSuggestQuantivePlusTags"
          [shouldSuggestQuantivePlusMetrics]="shouldSuggestQuantivePlusMetrics"
          [shouldSuggestQuantivePlusTasks]="shouldSuggestQuantivePlusTasks"
          [metricsSuggestionStatus]="metricsSuggestionStatus"
          [tasksSuggestionStatus]="tasksSuggestionStatus"
          [goal]="goal"
          [suggestions]="suggestions"
          [taskList]="taskList"
          parentType="goal">
        </suggestion-drawer-all-suggestions-tab>
      </ui-tab>
    </ui-tabset>
  </pi-suggestion-drawer-base>
</pi-suggestion-drawer-side-modal-wrapper>
