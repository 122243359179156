import { IGoal } from "@gtmhub/goals/models";
import { RequireAtLeastOne } from "@gtmhub/models";
import { ITag } from "@gtmhub/tags/models";
import { IdMap } from "@gtmhub/util/utils";
import { AssigneeType } from "@webapp/assignees/models/assignee.models";
import { AssignPermissions } from "@webapp/configuration/models/configuration.model";
import { ICollection } from "@webapp/core/core.models";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";

export type CadenceType = "WEEKLY" | "BI-WEEKLY" | "MONTHLY";
type Weekdays = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday";
type CadenceMonthPeriod = "firstDay" | "lastDay" | "specificDay";

export interface IStratifiedPlanningSession {
  data: Session;
  id: string;
  title?: string;
  parent?: Session;
}

export interface ICloningSession {
  resetProgress?: boolean;
  sendNotifications?: boolean;
  cloneTasks?: boolean;
  cloneCustomFields?: boolean;
  cloneOnlyParentObjectives?: boolean;
  sessionId: string;
  sessionParentId: string;
  title: string;
  shouldAlignSession?: boolean;
}

interface ICadenceStart {
  weekday?: Weekdays;
  dayOfMonth?: number;
  periodOfMonth?: CadenceMonthPeriod;
}

export interface Session {
  accountId?: string;
  end?: string;
  id?: string;
  start?: string;
  title?: string;
  status?: PlanningSessionStatusType;
  timePassedPercentage?: number; // only used in the webapp
  access?: IAccess;
  color?: string;
  cadence?: IPlanningSessionCadence;
  parentId?: string;
  cadenceType?: CadenceType;
  cadenceStart?: RequireAtLeastOne<ICadenceStart>;
  children?: Session[];

  workflow?: IPlanningSessionWorkflow;

  daysLength?: number;
  lengthPercentage?: number;
  leftPercentage?: number;
  selected?: boolean;
  tags?: ITag[];
  cadenceTimeZone?: string;
  userHasPermissionToCreate?: boolean;
  settings?: ISessionSettings;
  isSelfApproveAllowed?: boolean;
  dateCreated?: string;
}

interface SessionDelta {
  current: number;
  delta: number;
  deltaPercentage: number;
  previous: number;
}

interface AssigneeStats {
  assigneeId: string;
  progress: number;
  attainmentDelta: SessionDelta;
}

export interface SessionProgress {
  assigneesStats: AssigneeStats[];
  sessionId: string;
  sessionProgress: number;
  sessionAttainmentDelta: SessionDelta;
}
export interface ISessionSettings {
  assignGoalPermissions: AssignPermissions;
  assignMetricPermissions: AssignPermissions;
  multipleOwners: {
    disabledForMetrics: boolean;
    disabledForGoals: boolean;
  };
  progressAggregationDisabled?: boolean;
  goalsPerOwnerLimit?: number;
  metricsPerGoalLimit?: number;
}

export type PlanningSessionStatusType = "open" | "closed" | "locked";

export type IPrincipalKind = "team" | "user" | "role" | "account" | "team_and_subteams";

export enum PlanningSessionStatus {
  OPEN = "open",
  ARCHIVED = "closed",
  INPROGRESS = "locked",
}

export interface IPlanningSessionWorkflow {
  reviewers: number;
  target: string[];
  editableOkrFields?: string[];
  editableKrFields?: string[];
  approvalSettings?: IWorkflowApprovalSettings;
}

interface IWorkflowApprovalSettings {
  usersAllowedToApprove?: string[];
  teamsAllowedToApprove?: string[];
  everyoneCanApprove: boolean;
}

export interface IApprovalWorkflowValidation {
  message: string;
  status: ValidationStatus;
  items: IApprovalWorkflowValidationItemIdMap;
}

export interface IApprovalWorkflowValidationItemIdMap {
  [id: string]: IApprovalWorkflowValidationItem;
}

interface IApprovalWorkflowValidationItem {
  type: AssigneeType;
  status: ValidationStatus;
}

type ValidationStatus = "error" | "warning" | "ok";

export interface IPlanningSessionParams {
  fields?: string;
  status?: string;
}

export type ISessionsIdMap = IdMap<Session>;

export interface IPlanningSessionStats {
  goalCount: number;
  metricsCount: number;
  progress: number;
  daysToDeadline: number;
  dynamicMetricCount: number;
  manualMetricCount: number;
  goalsMetCount: number;
  goodMetricsCount: number;
  badMetricsCount: number;
  metricsMetCount: number;
}

export interface IAccess {
  inherits?: boolean;
  permissions?: IApiPermission[];
  ownerPermissions?: IApiPermission;
}

export interface IApiPermission {
  grant: {
    general: (keyof IAllowedActionsMap)[];
  };
  principalId: PrincipalId;
  principalKind: IPrincipalKind;
  includeSubteams?: boolean;
}

type PrincipalId = string;

export type PermissionsMap = Record<PrincipalId, (keyof IAllowedActionsMap)[]>;

export interface IPlanningSessionConflict {
  severity: string;
  type: "dueDate" | "softDueDate" | "crossSessionLink";
  goals: ICollection<IGoal>;
  metrics: ICollection<Metric>;
  sessions: ICollection<Session>;
}

export interface IPlanningSessionCadence {
  type: CadenceType;
  totalCadences?: number;
  ranges?: IPlanningSessionCadenceRange[];
}

interface IPlanningSessionCadenceRange {
  cadenceOrder: number;
  end: string;
  start: string;
}

export interface IPlanningSessionsIdMap {
  [id: string]: Session;
}

export interface ISessionWorkflowStats {
  approvedGoalsPercentage: number;
  approvedGoalsCount: number;
  pendingGoals: number;
  draftGoals: number;
  topLevelGoalsPercentage: number;
  topLevelGoalsCount: number;
  crossSessionAligned: number;
  pendingReviewers: number;
  draftOwners: number;
  averageApprovalPeriod: number;
}

export interface IUserItemAllowedActions {
  currentUserActions: UserAction[];
}

export type UserAction = keyof IAllowedActionsMap;

export interface IAllowedActionsMap {
  read?: boolean;
  update?: boolean;
  create?: boolean;
  delete?: boolean;
  modifyPermissions?: boolean;
}

export const FULL_USER_ACTION_LIST = ["read", "update", "create", "delete", "modifyPermissions"] as const;

export interface IPlanningSessionStatsAssignee {
  assigneeId: string;
  planningSessionStats: IPlanningSessionStats;
}

export interface IWorkflowSelectionField {
  name: string;
  label: string;
  checked?: boolean;
}

export type SessionFilterFn = (session: Session[]) => Session[];
