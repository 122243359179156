import { FAVORITES_STEP, INITIAL_STEP } from "@gtmhub/onboarding/models/onboarding.models";
import { IOnboardingJourneyState } from "./onboarding.models";

export const createInitialOnboardingState = (totalSteps: number): IOnboardingJourneyState => {
  return {
    currentStep: INITIAL_STEP,
    stage: "INITIALIZING",
    isCompleted: false,
    totalSteps: totalSteps,
  };
};

export const disabledJourneyState = (totalSteps: number, currentStep = INITIAL_STEP): IOnboardingJourneyState => {
  return {
    currentStep,
    stage: "DISABLED",
    isCompleted: false,
    totalSteps,
  };
};

export const pendingStartJourneyState = (totalSteps: number, skipSteps: number[]): IOnboardingJourneyState => {
  return {
    currentStep: INITIAL_STEP,
    stage: "PENDING_START",
    isCompleted: false,
    totalSteps,
    skipSteps,
  };
};

export const journeyFirstStep = (totalSteps: number, skipSteps: number[]): IOnboardingJourneyState => {
  return {
    currentStep: FAVORITES_STEP,
    stage: "ONGOING",
    isCompleted: false,
    totalSteps,
    skipSteps,
  };
};

export const completeTransitionForState = (state: IOnboardingJourneyState): IOnboardingJourneyState => {
  return {
    ...state,
    stage: "ONGOING",
  };
};

export const getJourneyNextStep = (state: IOnboardingJourneyState, options: { inTransition: boolean; stepsDelta?: number }): IOnboardingJourneyState => {
  return {
    ...state,
    currentStep: state.currentStep + (options.stepsDelta || 1),
    stage: options.inTransition ? "IN_TRANSITION" : "ONGOING",
  };
};

export const getJourneyPreviousStep = (state: IOnboardingJourneyState, options: { inTransition: boolean; stepsDelta?: number }): IOnboardingJourneyState => {
  return {
    ...state,
    currentStep: state.currentStep - (options.stepsDelta || 1),
    stage: options.inTransition ? "IN_TRANSITION" : "ONGOING",
  };
};

export const closeJourneyState = (state: IOnboardingJourneyState): IOnboardingJourneyState => {
  return {
    ...state,
    stage: "ENDED_BY_USER",
  };
};

export const completeJourneyState = (state: IOnboardingJourneyState): IOnboardingJourneyState => {
  return {
    ...state,
    isCompleted: true,
  };
};
