import { StateService } from "@uirouter/angular";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { Insight, InsightboardInsightUsage } from "@webapp/data-story/models/insights.models";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { UiModalRef } from "@webapp/ui/modal/abstracts/modal-ref";
import { UI_MODAL_DATA } from "@webapp/ui/modal/modal.models";

export type InsightRedirectComponentBindings = {
  insight: Insight;
  boardUsage: InsightboardInsightUsage[];
};
@UntilDestroy()
@Component({
  selector: "insight-redirect",
  templateUrl: "./insight-redirect.component.html",
  styleUrls: ["./insight-redirect.component.less"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LocalizationModule, UiIconModule, UiTooltipModule, CommonModule],
})
export class InsightRedirectComponent {
  constructor(
    private modalRef: UiModalRef,
    private stateService: StateService,
    @Inject(UI_MODAL_DATA) public modalData: InsightRedirectComponentBindings
  ) {}

  public goToBoard(board: InsightboardInsightUsage, $event): void {
    const openInNewTab = $event.metaKey || $event.ctrlKey;

    const url = this.stateService.href("gtmhub.insightboard", { dashboardId: board.id });
    window.open(url, openInNewTab ? "_blank" : "_self");
    this.modalRef.destroy();
  }

  public goToInsightsEditor($event): void {
    const openInNewTab = $event.metaKey || $event.ctrlKey;

    if (this.modalData.insight.formatting && this.modalData.insight.formatting.isCodeless) {
      const url = this.stateService.href("gtmhub.insightCodelessEditor", { name: this.modalData.insight.name, dashboardId: null });
      window.open(url, openInNewTab ? "_blank" : "_self");
      this.modalRef.destroy();
      return;
    }

    const url = this.stateService.href("gtmhub.insight", { name: this.modalData.insight.name, dashboardId: null });
    window.open(url, openInNewTab ? "_blank" : "_self");
    this.modalRef.destroy();
  }

  public trackById(index: number, board: InsightboardInsightUsage): string {
    return board.id;
  }
}
