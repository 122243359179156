import { getExcludedIdsSearchCondition, isActiveSearchCondition } from "@gtmhub/shared/utils/search-utils";
import { Employee } from "@webapp/employees";
import { IOptionalSearchFilter, ISearchCondition, ISearchRequestsBody } from "@webapp/search/models/search-api.models";
import { Search } from "@webapp/search/models/search.models";
import { IUser } from "../models";

export type UsersSearchConditionSettings = {
  activeUsersOnly?: boolean;
  excludedUserIds?: string[];
  allowedUserIds?: string[];
  allowedTeamIds?: string[];
};

export function buildUsersSearchRequest(searchTerm: string, settings: UsersSearchConditionSettings = {}): ISearchRequestsBody {
  const searchSettings: UsersSearchConditionSettings = {
    activeUsersOnly: true,
    ...settings,
  };
  const searchConditions = buildUsersSearchConditions(searchSettings);

  const searchRequestBody: ISearchRequestsBody = {
    collectionName: "users",
    searchFields: searchTerm ? [{ name: "fullName" }, { name: "auth0Cache.email" }] : [],
    ...(searchConditions.length && { searchConditions }),
  };

  if (settings.allowedUserIds || settings.allowedTeamIds) {
    searchRequestBody.optionalSearchFilter = [...buildOptionalSearchFilter(searchSettings)];
  }

  return searchRequestBody;
}

export function buildUsersSearchConditions(settings: UsersSearchConditionSettings = {}): ISearchCondition[] {
  return [
    ...(settings.activeUsersOnly ? [isActiveSearchCondition()] : []),
    ...(settings.excludedUserIds ? [getExcludedIdsSearchCondition(settings.excludedUserIds)] : []),
  ];
}

export function buildOptionalSearchFilter(settings: UsersSearchConditionSettings = {}): IOptionalSearchFilter[] {
  return [
    ...(settings.allowedUserIds ? [getAllowedUsersSearchCondition(settings.allowedUserIds)] : []),
    ...(settings.allowedTeamIds ? [getAllowedTeamsSearchCondition(settings.allowedTeamIds), getManagedTeamsSearchCondition(settings.allowedTeamIds)] : []),
  ];
}

const getAllowedUsersSearchCondition = (userIds: string[]): IOptionalSearchFilter => {
  return {
    name: "_id",
    operator: "in",
    value: userIds,
    weight: 60,
  };
};

const getAllowedTeamsSearchCondition = (teamsIds: string[]): IOptionalSearchFilter => {
  return {
    name: "teamIds",
    operator: "in",
    value: teamsIds,
    weight: 50,
  };
};

const getManagedTeamsSearchCondition = (managedTeamsIds: string[]): IOptionalSearchFilter => {
  return {
    name: "managedTeamIds",
    operator: "in",
    value: managedTeamsIds,
    weight: 50,
  };
};

export function searchItemsToUsers(searchItems: Search<"users">[]): SearchUser[] {
  return searchItems.map((searchItem) => toSearchUser(searchItem));
}

type UserProps = "id" | "email" | "name" | "accountId" | "picture" | "firstName" | "lastName" | "dateCreated";
type EmployeeProps = "teamIds" | "managedTeamIds";

export type SearchUser = Pick<IUser, UserProps> & Pick<Employee, EmployeeProps>;

function toSearchUser(searchItem: Search<"users">): SearchUser {
  return {
    id: searchItem.id,
    email: searchItem.fields.auth0Cache.email,
    name: searchItem.fields.fullName,
    accountId: searchItem.fields.accountId || "",
    picture: searchItem.fields.picture || searchItem.fields.auth0Cache.picture,
    firstName: searchItem.fields.firstName,
    lastName: searchItem.fields.lastName,
    dateCreated: searchItem.fields.dateCreated,
    teamIds: searchItem.fields.teamIds || [],
    managedTeamIds: searchItem.fields.managedTeamIds || [],
  };
}
