import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "quantive-plus-objective-tags",
  templateUrl: "./quantive-plus-objective-tags.component.html",
  styleUrls: ["./quantive-plus-objective-tags.component.less"],
})
export class QuantivePlusObjectiveTagsComponent {
  @Output()
  public readonly tagsSuggestionEvent = new EventEmitter();

  public onButtonClick = (): void => {
    this.tagsSuggestionEvent.emit();
  };
}
