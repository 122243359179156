import { UiToastConfig, UiToastDataOptions } from "../toast.models";

const DEFAULT_UI_TOAST_OFFSET = 64;
const DEFAULT_UI_TOAST_MAX_STACK = 1;
const DEFAULT_UI_TOAST_DURATION = 7000;
const INFINITE_UI_TOAST_DURATION = 0;

export class ToastConfigBuilder {
  private toastConfig: UiToastConfig;

  constructor() {
    this.resetConfig();
  }

  public setNzMaxStack(): ToastConfigBuilder {
    this.toastConfig.nzMaxStack = DEFAULT_UI_TOAST_MAX_STACK;

    return this;
  }

  /** - Sets nzDuration to 0 if _uiShouldTimeout_ is not explicitly set to true - this
   *  is to prevent the toast from disappearing after the default timeout
   *  - Can be overridden by setting _uiShouldTimeout_ to true and passing _uiDuration_ in options
   *  when creating toast instance
   *  - The default timeout is 7000ms if not explicitly set
   **/
  public setNzDuration(options: UiToastDataOptions): ToastConfigBuilder {
    if (!options.uiShouldTimeout) {
      this.toastConfig.nzDuration = INFINITE_UI_TOAST_DURATION;
      return this;
    }

    const duration = options.uiDuration || DEFAULT_UI_TOAST_DURATION;
    this.toastConfig.nzDuration = duration;
    return this;
  }

  public setNzTop(options: UiToastDataOptions): ToastConfigBuilder {
    const offset = options.uiOffset || DEFAULT_UI_TOAST_OFFSET;

    if (options.uiPlacement === "bottom") {
      this.toastConfig.nzTop = window.innerHeight - offset;
      return this;
    }

    this.toastConfig.nzTop = offset;
    return this;
  }

  public build(): UiToastConfig {
    const result = this.toastConfig;
    this.resetConfig();

    return result;
  }

  private resetConfig(): void {
    this.toastConfig = {};
  }
}
