<ng-template #containerTemplate>
  <ul>
    <li class="ant-pagination-total-text" *ngIf="showTotal">
      <ng-template [ngTemplateOutlet]="showTotal" [ngTemplateOutletContext]="{ $implicit: total, range: ranges }"></ng-template>
    </li>
    <li *ngFor="let page of listOfPageItem; trackBy: trackByPageItem">
      <button
        [locale]="locale"
        [type]="page.type"
        [index]="page.index"
        [disabled]="!!page.disabled || disabled"
        [itemRender]="itemRender"
        [active]="pageIndex === page.index"
        [direction]="dir"
        (gotoIndex)="jumpPage($event)"
        (diffIndex)="jumpDiff($event)"
        ui-pagination-item></button>
    </li>
  </ul>
  <div
    *ngIf="showQuickJumper || showSizeChanger"
    [total]="total"
    [locale]="locale"
    [disabled]="disabled"
    [uiSize]="nzSize"
    [showSizeChanger]="showSizeChanger"
    [showQuickJumper]="showQuickJumper"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (pageIndexChange)="onPageIndexChange($event)"
    (pageSizeChange)="onPageSizeChange($event)"
    ui-pagination-options></div>
</ng-template>
