import { module } from "angular";
import badgesCore from "@gtmhub/badges/badges-core.module";
import employeesCore from "@gtmhub/employees/employees-core.module";
import env from "@gtmhub/env/env.module";
import teamsCore from "@gtmhub/teams/teams-core.module";
import { MessagingService } from "./services/messaging.service";

const mod = module("sockets", [badgesCore, employeesCore, teamsCore, env]);

mod.service("MessagingService", MessagingService);

export default mod.name;
