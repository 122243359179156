import { TransitionService, UIRouterGlobals } from "@uirouter/angular";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatest, forkJoin, take } from "rxjs";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { fromTransitionHook } from "@webapp/core/routing/rxjs";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { NavItemsSectionBroadcastConfig } from "@webapp/navigation/models/nav-items-list.models";
import { SubNavOption } from "@webapp/navigation/models/sub-nav-options.models";
import NavigationItemsMediator from "@webapp/navigation/services/uxcustomization/navigation-items.mediator.service";
import { SessionsSubNavigationOptions } from "@webapp/navigation/utils/sessions-sub-navigation-options";
import { BaseSubNav } from "../services/base-sub-nav";
import { NavItemsSectionBroadcastConfigFactory } from "../services/nav-items-section-broadcast-config.factory";

@UntilDestroy()
@Component({
  selector: "sessions-sub-navigation",
  templateUrl: "./sessions-sub-navigation.component.html",
})
export class SessionsSubNavigationComponent extends BaseSubNav implements OnInit {
  public options: SubNavOption[] = [];

  public broadcastConfig: NavItemsSectionBroadcastConfig = this.broadcastConfigFactory.buildDefaultNavItemsSectionBroadcastConfig();

  public constructor(
    private cdr: ChangeDetectorRef,
    protected navigationItemsMediator: NavigationItemsMediator,
    private transitionService: TransitionService,
    private routerGlobals: UIRouterGlobals,
    private featureTogglesFacade: FeatureTogglesFacade,
    private broadcastConfigFactory: NavItemsSectionBroadcastConfigFactory,
    private sessionsSubNavigationOptions: SessionsSubNavigationOptions,
    private editionFeatureService: EditionFeatureService
  ) {
    super();
    this.setupEntityTypes(["session", "filter"], "sessions");
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.setSubNavigationOptions();
    this.setBroadcastConfigBasedOnFeatureFlag();
  }

  private setSubNavigationOptions(): void {
    forkJoin([this.sessionsSubNavigationOptions.allSessions$(), this.sessionsSubNavigationOptions.okrViews$(), this.sessionsSubNavigationOptions.okrsForApproval$()])
      .pipe(take(1))
      .subscribe((options) => {
        this.options = options.filter((option) => !!option);
        this.cdr.detectChanges();
      });
  }

  private setBroadcastConfigBasedOnFeatureFlag(): void {
    combineLatest([this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.OkrViews), this.editionFeatureService.hasFeature$("okrs.okr-views")])
      .pipe(untilDestroyed(this))
      .subscribe(([isOkrViewsFeatureEnabled, isOkrViewsFeatureIncludedInEdition]) => {
        const isOkrViewsFeatureAvailable = isOkrViewsFeatureEnabled && isOkrViewsFeatureIncludedInEdition;
        this.setBroadcastConfig({ isOkrViewsFeatureAvailable });

        fromTransitionHook(this.transitionService, "onSuccess", {})
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.setBroadcastConfig({ isOkrViewsFeatureAvailable });
          });
      });
  }

  private setBroadcastConfig(params: { isOkrViewsFeatureAvailable: boolean }): void {
    this.broadcastConfig = this.broadcastConfigFactory.buildSessionNavItemsSectionBroadcastConfig({
      isOkrViewsFeatureAvailable: params.isOkrViewsFeatureAvailable,
      currentStateName: this.routerGlobals.current.name,
      currentSubNavItemIdOnDisplay: this.routerGlobals.params.planningSessionId || this.routerGlobals.params.okrViewId,
    });
  }
}
