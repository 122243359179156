import { AccountService, ISharedOkrsAccountSetting } from "@gtmhub/accounts/accounts.service";
import { IGoal } from "@gtmhub/goals/models";
import { AssigneeSelectorType } from "@webapp/assignees/models/assignee.models";
import { AssignPermissions } from "@webapp/configuration/models/configuration.model";
import { ISessionSettings, Session } from "@webapp/sessions/models/sessions.model";

export const DEFAULT_LIMIT = 50000;

type ISettingsEntity = "goal" | "metric";

export interface IGoalsPerOwnerSettingUI {
  sessionId: string;
  limit: number;
}

export interface IGoalMethodologySettingsUI {
  goalsPerOwner: IGoalsPerOwnerSettingUI;
  multipleOwnersPerGoalDisabled: boolean;
  assignGoalPermissions: AssigneeSelectorType;
}

export interface IMetricMethodologySettingsUI {
  multipleOwnersPerMetricDisabled: boolean;
  assignMetricPermissions: AssigneeSelectorType;
}

export class OkrMethodologyFacade {
  public static $inject = ["AccountService"];

  constructor(private accountService: AccountService) {}

  public getMethodologySettingsUIForGoalView(goal: IGoal, session: Session): IGoalMethodologySettingsUI {
    if (!session) session = {};
    const { id, settings } = session;

    return {
      goalsPerOwner: this.getGoalsPerOwnerLimitSetting(id, settings),
      multipleOwnersPerGoalDisabled: this.areMultipleOwnersDisabled("goal", settings),
      assignGoalPermissions: this.getAssignGoalPermissionsSetting(settings, goal),
    };
  }

  public getMethodologySettingsUIForMetricView(session: Session): IMetricMethodologySettingsUI {
    if (!session) session = {};
    const { settings } = session;

    return {
      multipleOwnersPerMetricDisabled: this.areMultipleOwnersDisabled("metric", settings),
      assignMetricPermissions: this.getAssignMetricPermissions(settings),
    };
  }

  public areMultipleOwnersDisabled = (entity: ISettingsEntity, settings: ISessionSettings): boolean => {
    const sessionSettingProp = entity === "goal" ? "disabledForGoals" : "disabledForMetrics";
    const accountSettingProp = entity === "goal" ? "objectives" : "krs";
    const sessionSetting = settings && settings.multipleOwners?.[sessionSettingProp];
    const accountSetting = this.accountService.getAccountSetting<ISharedOkrsAccountSetting>("sharedOkrs")?.[accountSettingProp]?.disabled;

    return sessionSetting ?? accountSetting;
  };

  public getGoalsPerOwnerLimitSetting = (sessionId: string, settings: ISessionSettings): IGoalsPerOwnerSettingUI => {
    return {
      sessionId,
      limit: this.getLimitSetting("goal", settings),
    };
  };

  public getLimitSetting = (entity: ISettingsEntity, settings?: ISessionSettings): number => {
    const sessionSettingProp = entity === "goal" ? "goalsPerOwnerLimit" : "metricsPerGoalLimit";
    const accountSettingProp = entity === "goal" ? "limitObjectives" : "limitKRs";
    const sessionLimitSetting = settings && settings[sessionSettingProp];
    const accountLimitSetting = this.accountService.getAccountSetting<number>(accountSettingProp);
    const sessionLimit = sessionLimitSetting || DEFAULT_LIMIT;
    const accountLimit = accountLimitSetting || DEFAULT_LIMIT;

    /*
      if session limit has never been set, return account setting or default limit
      if session limit has been set (which includes null, 0)
      if the value is null or 0 return default limit, else return setting
    */
    return settings === undefined ? accountLimit : sessionLimit;
  };

  public getAssignGoalPermissionsSetting = (settings: ISessionSettings, goal?: IGoal): AssigneeSelectorType => {
    if (goal && goal.private) {
      return "user";
    }

    return this.getAssignPermissions("goal", settings);
  };

  public getAssignMetricPermissions(settings: ISessionSettings): AssigneeSelectorType {
    return this.getAssignPermissions("metric", settings);
  }

  private getAssignPermissions = (entity: ISettingsEntity, settings: ISessionSettings): AssigneeSelectorType => {
    const sessionSettingProp = entity === "goal" ? "assignGoalPermissions" : "assignMetricPermissions";
    const accountSettingProp = entity === "goal" ? "assignObjectivePermissions" : "assignKRPermissions";
    const sessionSetting = settings && settings[sessionSettingProp];
    const accountSetting = this.accountService.getAccountSetting<AssignPermissions>(accountSettingProp);
    const assignPermissions = sessionSetting || accountSetting;

    if (!assignPermissions || assignPermissions === "user and team") {
      return "both";
    }

    return assignPermissions;
  };

  public isProgressAggregationDisabled(settings: ISessionSettings): boolean {
    const sessionSettingDisabled = settings && settings.progressAggregationDisabled;
    if (typeof sessionSettingDisabled === "boolean") {
      return sessionSettingDisabled;
    }
    const accountSettingEnabled = this.accountService.getAccountSetting<boolean>("aggregation");

    // Since up to this point we kept true for the setting in the account settings if it is enabled
    // it should be converted to the opposite since for the session settings we ture for disabled
    return typeof accountSettingEnabled === "boolean" ? !accountSettingEnabled : true;
  }
}
