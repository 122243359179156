import { ChangeDetectionStrategy, Component, Input, TemplateRef } from "@angular/core";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { NzOptionItemGroupComponent } from "ng-zorro-antd/select";

@Component({
  selector: "ui-option-item-group",
  templateUrl: "option-item-group.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NzOutletModule],
})
export class UiOptionItemGroupComponent extends NzOptionItemGroupComponent {
  @Input("uiLabel") public nzLabel: string | number | TemplateRef<NzSafeAny> | null = null;
}
