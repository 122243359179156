import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { Kpi, KpiDTO } from "../models/kpis.models";
import { KpisState } from "./kpis-state.service";

@Injectable({
  providedIn: "any",
})
export class KpisApiService extends BaseApiService<Kpi, KpiDTO, KpisState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: KpisState) {
    super("kpis", { ...new ApiVersionModel("v1"), getAll: "v2" }, httpClient, appConfig, state);
  }
}
