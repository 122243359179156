import { Directionality } from "@angular/cdk/bidi";
import { Directive, ElementRef, Host, Input, OnInit, Optional, Renderer2 } from "@angular/core";
import { NzColDirective } from "ng-zorro-antd/grid";
import { UiGridEmbeddedProperty } from "@webapp/ui/grid/grid.models";
import { UiRowDirective } from "@webapp/ui/grid/row.directive";

@Directive({
  selector: "[ui-col],ui-col,ui-form-control,ui-form-label",
  exportAs: "uiCol",
  host: {
    "[style.flex]": "hostFlexStyle",
  },
  standalone: true,
})
export class UiColDirective extends NzColDirective implements OnInit {
  @Input("uiFlex") public nzFlex: string | number | null = null;
  @Input("uiSpan") public nzSpan: string | number | null = null;
  @Input("uiOrder") public nzOrder: string | number | null = null;
  @Input("uiOffset") public nzOffset: string | number | null = null;
  @Input("uiPush") public nzPush: string | number | null = null;
  @Input("uiPull") public nzPull: string | number | null = null;
  @Input("uiXs") public nzXs: string | number | UiGridEmbeddedProperty | null = null;
  @Input("uiSm") public nzSm: string | number | UiGridEmbeddedProperty | null = null;
  @Input("uiMd") public nzMd: string | number | UiGridEmbeddedProperty | null = null;
  @Input("uiLg") public nzLg: string | number | UiGridEmbeddedProperty | null = null;
  @Input("uiXl") public nzXl: string | number | UiGridEmbeddedProperty | null = null;
  @Input("uiXXl") public nzXXl: string | number | UiGridEmbeddedProperty | null = null;

  constructor(
    private uiElementRef: ElementRef,
    @Optional() @Host() nzRowDirective: UiRowDirective,
    renderer: Renderer2,
    @Optional() directionality: Directionality
  ) {
    super(uiElementRef, nzRowDirective, renderer, directionality);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.fillColumnData();
  }

  private fillColumnData(): void {
    const nativeElement = this.uiElementRef.nativeElement as HTMLElement;
    const parentElement = nativeElement.parentElement as HTMLElement;

    const found = parentElement.getAttribute("ui-row");
    if (found === null || found === undefined) {
      console.warn("ui-col nested into element that is missing ui-row directive");
    }

    const hasCols = parentElement.getAttribute("ui-columns");
    let columnData = [];
    if (hasCols) {
      columnData = JSON.parse(hasCols);
    }

    if ((typeof this.nzFlex === "string" && /^\d+(\.\d+)?(px|em|rem|%)$/.test(this.nzFlex)) || this.nzFlex === "auto") {
      columnData.push(this.nzFlex);
    }

    parentElement.setAttribute("ui-columns", JSON.stringify(columnData));
  }
}
