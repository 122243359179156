import { APP_INITIALIZER, ModuleWithProviders, NgModule } from "@angular/core";
import { AccountResolverService } from "@webapp/accounts";
import { TrackDirective } from "./directives/track.directive";
import { AnalyticsFactoryService } from "./services/analytics.factory.service";
import { IdentifyUserService } from "./services/identify-user.service";

function registerIdentifyingUser(identifyUserService: IdentifyUserService) {
  return (): void => identifyUserService.registerIdentifyingUser();
}

@NgModule({
  providers: [AnalyticsFactoryService, AccountResolverService, IdentifyUserService],
  declarations: [TrackDirective],
  exports: [TrackDirective],
})
export class AnalyticsModule {
  public static forRoot(): ModuleWithProviders<AnalyticsModule> {
    return {
      ngModule: AnalyticsModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: registerIdentifyingUser,
          deps: [IdentifyUserService],
          multi: true,
        },
      ],
    };
  }
}
