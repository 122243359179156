import { module } from "angular";
import { PluginsActions } from "./redux/plugin-actions";
import { PluginService } from "./services/plugin.service";

const mod = module("pluginsCore", []);

mod.service("PluginActions", PluginsActions);
mod.service("PluginService", PluginService);

export default mod.name;
