import { IConfidenceMapping } from "@gtmhub/core";
import { IMandatoryOkrAlignmentSettings } from "@webapp/configuration/models/configuration.model";

export const filterConfidenceMapping = (mapping: IConfidenceMapping[], value: number): IConfidenceMapping[] => mapping.filter((mp) => mp.range.to !== value);

export const isOutOfRange = (number: number, min: number, max: number): boolean => number < min || number > max;

export const isInvalid = (range: number): boolean => {
  if (range === 0) {
    return false;
  }

  return !range || typeof range !== "number";
};

export const isAlreadyExisting = (range: number, mappings: IConfidenceMapping[]): boolean => {
  return mappings.some((mp) => mp.range.to === range);
};

export const determineChargeBeeDCTarget = (billingSystem: string): string => {
  if (billingSystem === "as") {
    return "eu";
  }
  if (billingSystem === "sa") {
    return "us";
  }
  return billingSystem;
};

export const generateDefaultMandatoryOkrAlignmentAccountSettings = (): IMandatoryOkrAlignmentSettings => {
  return {
    enabled: false,
    excludedUserIds: [],
  };
};
