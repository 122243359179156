import { NzNotificationDataOptions } from "ng-zorro-antd/notification";
import { UiNotificationDataOptions } from "@webapp/ui/notification/notification.models";

export const fillDataOptionsNzValues = (options: UiNotificationDataOptions): NzNotificationDataOptions => {
  const transformedOptions: NzNotificationDataOptions = {};

  if (options) {
    const keys = Object.keys(options);
    for (const index in keys) {
      transformedOptions[keys[index].replace(/^(notification)/, "nz")] = options[keys[index]];
    }
    return transformedOptions;
  } else {
    return options;
  }
};
