import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { FileUploadedData, UploadImageResponse } from "../models/fileserver.models";
import { FileserverApiService } from "./fileserver-api.service";
import { FileserverState } from "./fileserver-state.service";

@Injectable()
export class FileserverFacade extends BaseFacade<null, null, FileserverState, FileserverApiService> {
  constructor(state: FileserverState, api: FileserverApiService) {
    super(state, api);
  }

  public uploadFile$(file): Observable<FileUploadedData> {
    return this.post$(file, { ...new RequestConfig(), url: this.apiService.getMediaEndpoint() });
  }

  public downloadFile$(url: string): Observable<HttpResponse<Blob>> {
    return this.apiService.downloadFile$(url);
  }

  public generateFileUrl$(url: string): Observable<string> {
    return this.apiService.downloadFile$(url).pipe(map((data: HttpResponse<Blob>) => URL.createObjectURL(data.body)));
  }

  public uploadImage$(file: FormData): Observable<UploadImageResponse> {
    return this.upload$("image", file);
  }

  public uploadMediaV2$(data: FormData): Observable<FileUploadedData> {
    return this.uploadV2$("media", data);
  }

  public upload$(path: string, file: FormData): Observable<UploadImageResponse> {
    return this.post$(file, { ...new RequestConfig(), url: this.apiService.getUploadEndpoint(path) });
  }

  public uploadV2$(path: string, data: FormData): Observable<FileUploadedData> {
    return this.post$(data, { ...new RequestConfig(), url: this.apiService.getUploadV2Endpoint(path) });
  }
}
