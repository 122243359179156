<div class="redirect-container">
  <div class="card-wrapper">
    <ui-card [uiBordered]="false" [uiRounded]="true">
      <ui-loading-indicator [uiSize]="'large'" [uiSpinning]="loading"></ui-loading-indicator>
      <h3 class="title">{{ "redirected_to_platform" | localize }}</h3>
      <p class="description">{{ "redirected_to_platform_description" | localize }}</p>
    </ui-card>
  </div>

  <div class="more-details">
    <p class="discover-more">{{ "discover_more_about_change" | localize }}</p>
    <a class="ui-link ui-small" href="https://help.quantive.com/en/articles/9702748-unifying-the-platform-of-quantive-for-your-success" target="_blank">{{ "learn_more" | localize }}</a>
  </div>

  @if (error) {
    <ui-alert
      uiType="error"
      class="alert-error"
      [uiCloseable]="true"
      (uiOnSecondaryAction)="onRefresh()"
      [uiMessage]="error.message | localize"
      [uiDescription]="error.title | localize"
      [uiSecondaryCTA]="'refresh_page' | localize">
    </ui-alert>
  }
</div>
