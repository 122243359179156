import { IPermissionsState, PermissionsAction } from "./models";

export const permissionsReducer = (
  state: IPermissionsState = {
    isFetched: false,
    isFetching: false,
    items: new Set(),
    error: null,
  },
  action: PermissionsAction
): IPermissionsState => {
  switch (action.type) {
    case "REQUEST_PERMISSIONS":
      return { items: new Set(), isFetching: true, isFetched: false };
    case "RECEIVE_PERMISSIONS":
      return { items: new Set(action.items), isFetched: true, isFetching: false };
    case "RECEIVE_PERMISSIONS_ERROR":
      return { items: new Set(), error: action.error, isFetching: false, isFetched: false };
    default:
      return state;
  }
};
