import { NgClass, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { UiAccessibilityModule } from "@quantive/ui-kit/accessibility";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiI18nModule, UiI18nService } from "@quantive/ui-kit/i18n";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzMessageComponent } from "ng-zorro-antd/message";
import { Subject, takeUntil } from "rxjs";
import { ESCAPE } from "@webapp/shared/utils/keys";
import { UiToastData } from "@webapp/ui/toast/toast.models";

@Component({
  selector: "ui-toast",
  exportAs: "uiToast",
  templateUrl: "toast.component.html",
  styleUrls: ["toast.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "ui-toast",
  },
  standalone: true,
  imports: [NgClass, NgSwitchCase, NgSwitch, NgIf, UiAccessibilityModule, UiI18nModule, NzOutletModule, NgTemplateOutlet, UiIconModule, UiButtonModule],
})
export class UiToastComponent extends NzMessageComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild("dismissAction") private dismissAction: TemplateRef<unknown>;
  @ViewChild("singleAction") private singleAction: TemplateRef<unknown>;
  @ViewChild("multipleActions") private multipleActions: TemplateRef<unknown>;

  @Input("uiInstance") public instance!: Required<UiToastData>;
  @Input() @InputBoolean() public uiCloseable = true;
  @Input() @InputBoolean() public uiHideIcon?: boolean;
  @Input() public uiPrimaryCTA: string | null = null;
  @Input() public uiSecondaryCTA: string | null = null;

  @Output() public readonly uiOnPrimaryAction = new EventEmitter<void>();
  @Output() public readonly uiOnSecondaryAction = new EventEmitter<void>();
  @Output("uiDestroyed") public readonly destroyed = new EventEmitter<{ id: string; userAction: boolean }>();

  private destroy$ = new Subject<void>();
  private toastAriaLabelMap: {
    [ToastType in UiToastData["type"]]: string;
  } = {};

  constructor(cdr: ChangeDetectorRef, i18n: UiI18nService) {
    super(cdr);

    i18n.localeChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.toastAriaLabelMap = {
        info: i18n.translate("Toast.info"),
        success: i18n.translate("Toast.success"),
        warning: i18n.translate("Toast.warning"),
        error: i18n.translate("Toast.error"),
        loading: i18n.translate("Toast.loading"),
      };
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener("window:keydown", ["$event"])
  public handleDismiss(event: KeyboardEvent): void {
    if (this.uiCloseable && event.code === ESCAPE) {
      this.removeToast();
    }
  }

  public toastActionsTemplateRef: TemplateRef<unknown>;

  public ngAfterViewInit(): void {
    this.setToastActionsTemplateRef();
    this.cdr.detectChanges();
  }

  public ngOnChanges(): void {
    this.setToastActionsTemplateRef();
  }

  public onPrimaryAction(): void {
    this.uiOnPrimaryAction.emit();
    this.removeToast();
  }

  public onSecondaryAction(): void {
    this.uiOnSecondaryAction.emit();
    this.removeToast();
  }

  public removeToast(): void {
    this.destroyed.emit({ id: this.instance.messageId, userAction: true });
  }

  public generateToastAriaLabel(type: UiToastData["type"]): string {
    return this.toastAriaLabelMap[type];
  }

  private setToastActionsTemplateRef(): void {
    this.toastActionsTemplateRef = this.toastActionsTemplate;
  }

  private get toastActionsTemplate(): TemplateRef<unknown> {
    if (this.uiCloseable) {
      return this.dismissAction;
    }

    const shouldDisplayPrimaryAction = this.uiPrimaryCTA && !!this.uiOnPrimaryAction;
    const shouldDisplaySecondaryAction = this.uiSecondaryCTA && !!this.uiOnSecondaryAction;

    if (shouldDisplayPrimaryAction && shouldDisplaySecondaryAction) {
      return this.multipleActions;
    } else if (shouldDisplayPrimaryAction) {
      return this.singleAction;
    }
  }
}
