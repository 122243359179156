import Quill from "quill";
import { EMOJI_CLASS } from "@webapp/shared/rich-text-editor/blots/blot-classes";

const Embed = Quill.import("blots/embed");
export class EmbeddedEmoji extends Embed {
  public static create(value): unknown {
    const node = super.create();

    node.innerText = value;

    return node;
  }

  public static value(domNode): unknown {
    return domNode.innerText;
  }
}

EmbeddedEmoji.blotName = "embeddedEmoji";
EmbeddedEmoji.tagName = "span";
EmbeddedEmoji.className = EMOJI_CLASS;
