import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseApiServiceV2 } from "@webapp/core/api/base-api-v2.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { ICollection } from "@webapp/core/core.models";
import { OkrsSessionGroup } from "../assignee-active-okrs-list.models";

@Injectable({ providedIn: "root" })
export class AssigneeActiveOkrsApiService extends BaseApiServiceV2<OkrsSessionGroup> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig) {
    super("overview", new ApiVersionModel({ version: "v1", trailingSlash: true }), httpClient, appConfig);
  }

  public getMyOkrs$<ResponseType = ICollection<OkrsSessionGroup>>(config: RequestConfig = new RequestConfig()): Observable<ResponseType> {
    const url = this.getBasePath(HttpActions.get) + "myokrs";

    return this.httpClient.get<ResponseType>(url, {
      params: this.encodeQueryParams(config),
      headers: config.headers,
      context: config.context,
    });
  }

  public getTeamOkrs$<ResponseType = ICollection<OkrsSessionGroup>>(teamId: string, config: RequestConfig = new RequestConfig()): Observable<ResponseType> {
    const url = `${this.getBasePath(HttpActions.get)}team-okrs/${teamId}`;

    return this.httpClient.get<ResponseType>(url, {
      params: this.encodeQueryParams(config),
      headers: config.headers,
      context: config.context,
    });
  }
}
