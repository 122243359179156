import { IAugmentedJQuery, IComponentOptions, IOnChangesObjectOf } from "angular";
import { v4 as uuidv4 } from "uuid";
import { GtmhubController } from "@gtmhub/core";
import { IArrayGridFilter } from "../grid-filtering-bar";
import { getPersistedScreenSettings } from "../grid-filtering-bar/persisting/models";
import { ITreeSelectorItem } from "./models";

/**
 * Provides hierarchical selector functionality
 * @param tree specifies the data used to build the hierarchy
 * @param onSelection is fired when a node is selected and the node itself is passed as an argument
 * @param onDeselection is fired when a node is deselected and the node itself is passed as an argument
 * @param enableItemDisabledState defines whether session should not be clickable or not
 * @param isSingleSelector` checks if only one session can be selected
 * @param hostSessionId is the session from which the selector is opened f.e. create okr -> the session in which the okr will be created
 * @param isExpanded how to present the tree expanded or collapsed
 * @param selectedSession this is the selected session (f.e. in the okr create form), this is different from hostSessionId
 * @example
 * tree-selector(
    tree="$ctrl.tree"
    on-selection="$ctrl.onSelectionToggle(item)"
    on-deselection="$ctrl.onSelectionToggle(item)"
    enable-item-disabled-state="true"
    is-single-selector="false"
    host-session-id="id_of_the_session"
    is-expanded="true"
    selected-session="{*selected session object*}"
  )
 */

// 50 is roughly the number of sessions with which the filter is not lagging,
// also filter with 50 sessions is less than 2048 characters (max length for url in most browsers)
const MAX_SESSIONS_COUNT_IN_FILTER = 50;

interface ITreeSelectorComponentBindings {
  tree: ITreeSelectorItem[];
  selectedSession: ITreeSelectorItem;
  onlyAllowedSession: ITreeSelectorItem;
  singleAllowedSession: boolean;
  isExpanded?: boolean;
  isSingleSelector?: boolean;
  hostSessionId: string;
  enableItemDisabledState: boolean;
  selectedSessionsCount: number;
  onSelection?(args: { item: ITreeSelectorItem }): void;
  onDeselection?(args: { item: ITreeSelectorItem }): void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface TreeSelectorComponentCtrl extends ITreeSelectorComponentBindings {}
export class TreeSelectorComponentCtrl extends GtmhubController {
  sessionFilterLimitReached: boolean;
  ctrlId: string;
  static $inject = ["$element"];

  constructor(private $element: IAugmentedJQuery) {
    super();
    this.ctrlId = uuidv4();
  }

  $onInit(): void {
    this.onlyAllowedSession = null;
    this.singleAllowedSession = false;

    this.selectedSessionsCount = this.getPersistedSessionsCount();
  }

  $onChanges(onChangesObj: IOnChangesObjectOf<ITreeSelectorComponentBindings>): void {
    if (onChangesObj.tree && onChangesObj.tree.isFirstChange()) {
      this.$element.css("visibility", "visible"); // we make the element visible once the tree is build
    }
  }

  onSelectionToggle(item: ITreeSelectorItem): void {
    if (this.isSingleSelector) {
      this.onSelection({ item: item });
    } else {
      if (item.selected && this.onSelection) {
        this.selectedSessionsCount++;
        this.onSelection({ item: item });
      } else if (!item.selected && this.onDeselection) {
        this.selectedSessionsCount--;
        this.onDeselection({ item: item });
      }
      this.sessionFilterLimitReached = this.selectedSessionsCount >= MAX_SESSIONS_COUNT_IN_FILTER;
    }
  }

  onExpandToggle(item: ITreeSelectorItem): void {
    item.expanded = !item.expanded;
  }

  toggleAllSubsessions(item: ITreeSelectorItem): void {
    const toggleSession = !item.selected;
    this.selectionToggleSession([item], toggleSession);
  }

  // eslint-disable-next-line @foxglove/no-boolean-parameters
  private selectionToggleSession(sessionsToBeToggled: ITreeSelectorItem[], selectSession: boolean): void {
    if (sessionsToBeToggled.length === 0) {
      return;
    }

    if (selectSession && this.selectedSessionsCount >= MAX_SESSIONS_COUNT_IN_FILTER) {
      return;
    }

    const currentSession = sessionsToBeToggled.shift();
    if (currentSession.children) {
      sessionsToBeToggled = [...sessionsToBeToggled, ...currentSession.children];
    }

    if (currentSession.selected === !selectSession) {
      currentSession.selected = selectSession;
      this.onSelectionToggle(currentSession);
    }

    return this.selectionToggleSession(sessionsToBeToggled, selectSession);
  }

  private getPersistedSessionsCount(): number {
    this.sessionFilterLimitReached = false;
    const screenPath = ["goals", "grid", this.hostSessionId];

    const persistedFilters = getPersistedScreenSettings(screenPath, "filters") || [];

    let persistedSessionsCount = 0;
    persistedFilters.forEach((filtersGroup) => {
      filtersGroup.forEach((filter) => {
        if (filter.fieldName === "sessionId") {
          const sessionIdsCount = ((<IArrayGridFilter>filter).value || []).length;
          const dynamicValuesCount = ((<IArrayGridFilter>filter).dynamicValues || []).length;
          persistedSessionsCount += sessionIdsCount + dynamicValuesCount;
        }
      });
    });

    this.sessionFilterLimitReached = persistedSessionsCount >= MAX_SESSIONS_COUNT_IN_FILTER;

    return persistedSessionsCount;
  }
}

export const TreeSelectorComponent: IComponentOptions = {
  template: require("./tree-selector.html"),
  controller: TreeSelectorComponentCtrl,
  bindings: {
    tree: "<",
    onSelection: "&",
    onDeselection: "&",
    isExpanded: "<",
    selectedSession: "<",
    isSingleSelector: "<",
    hostSessionId: "<",
    enableItemDisabledState: "<?",
  },
};
