import { IPromise, IRootScopeService } from "angular";
import { ThunkAction } from "redux-thunk";
import { IUIError, createUIError } from "@gtmhub/error-handling";
import { Session } from "@webapp/sessions/models/sessions.model";
import { PlanningSessionService } from "../services/planning-session.service";
import { IAddSessionAction, IErrorReceiveSessionsAction, IReceiveSessionsAction, IRequestSessionsAction, SessionsAction } from "./models";
import { ISessionsStoreState } from "./session-reducer";

const requestSessions = (): IRequestSessionsAction => ({ type: "REQUEST_SESSIONS" });
export const receiveSessions = (items: Session[]): IReceiveSessionsAction => ({ type: "RECEIVE_SESSIONS", items });
const requestSessionsError = (error: IUIError): IErrorReceiveSessionsAction => ({ type: "ERROR_RECEIVE_SESSIONS", error });
const shouldFetchSessions = (state: ISessionsStoreState) => !state.sessions.isFetching && !state.sessions.isFetched;
const addSession = (item: Session): IAddSessionAction => ({ type: "ADD_SESSION", item });

export class PlanningSessionsActions {
  public static $inject = ["PlanningSessionService", "$rootScope"];

  constructor(
    private planningSessionService: PlanningSessionService,
    private $rootScope: IRootScopeService
  ) {}

  private initializationPromise = null;

  public getSessions(): ThunkAction<IPromise<Session[] | void>, ISessionsStoreState, null, SessionsAction> {
    return (dispatch) => {
      this.fetchSessions(dispatch);
      return this.initializationPromise;
    };
  }

  public getSessionsIfMissing(): ThunkAction<IPromise<Session[] | void>, ISessionsStoreState, null, SessionsAction> {
    return (dispatch, getState) => {
      if (shouldFetchSessions(getState())) {
        this.fetchSessions(dispatch);
      }
      return this.initializationPromise;
    };
  }

  public addSession(session: Session): (dispatch) => void {
    return (dispatch) => {
      dispatch(addSession(session));
    };
  }

  private fetchSessions(dispatch) {
    dispatch(requestSessions());

    this.initializationPromise = this.planningSessionService.getPlanningSessions().then(
      (sessions) => {
        this.$rootScope.$broadcast("accountSessionsUpdated", sessions);
        const sortedSessions = sessions.sort((a, b) => b.end.localeCompare(a.end));

        dispatch(receiveSessions(sortedSessions));
      },
      (rejection) => dispatch(requestSessionsError(createUIError(rejection)))
    );
  }
}
