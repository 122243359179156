import { IRootScopeService, IScope } from "angular";

export type FiltersChangeAction = "create" | "update" | "delete";

export type FilterChangeModel = { id: string; name: string };

export enum FiltersEventType {
  FILTER_CHANGED = "filtersChanged",
}

export class FilterEvents {
  private $rootScope: IRootScopeService;

  constructor(private $scope: IScope) {
    this.$rootScope = this.$scope.$root;
  }

  broadcastFiltersChanged(changeDefinition?: { filter: FilterChangeModel; action: FiltersChangeAction }): void {
    this.$rootScope.$broadcast(FiltersEventType.FILTER_CHANGED, changeDefinition);
  }

  onFiltersChanged(callback: (changeDefinition: { filter: FilterChangeModel; action: FiltersChangeAction }) => unknown): void {
    this.$scope.$on(FiltersEventType.FILTER_CHANGED, (event, args: { filter: FilterChangeModel; action: FiltersChangeAction }) => {
      callback(args);
    });
  }
}
