import { NgModule } from "@angular/core";
import { UiTreeIndentComponent } from "@webapp/ui/tree/components/tree-indent/tree-indent.component";
import { UiTreeNodeBuiltinComponent } from "@webapp/ui/tree/components/tree-node/tree-node.component";
import { UiTreeService } from "./services/tree.service";
import { UiTreeComponent } from "./tree.component";

@NgModule({
  providers: [UiTreeService],
  imports: [UiTreeComponent, UiTreeIndentComponent, UiTreeNodeBuiltinComponent],
  exports: [UiTreeComponent, UiTreeIndentComponent, UiTreeNodeBuiltinComponent],
})
export class UiTreeModule {}
