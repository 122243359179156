import { Directionality } from "@angular/cdk/bidi";
import { NgClass, NgStyle, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Optional } from "@angular/core";
import { UiSizeDSType } from "@quantive/ui-kit/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzConfigService, WithConfig } from "ng-zorro-antd/core/config";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { InputNumber } from "ng-zorro-antd/core/util";
import { NzProgressComponent } from "ng-zorro-antd/progress";
import {
  UiProgressFormatter,
  UiProgressGapPositionType,
  UiProgressStatusType,
  UiProgressStrokeColorType,
  UiProgressStrokeLinecapType,
  UiProgressTypeType,
} from "./progress.models";

@Component({
  selector: "ui-progress",
  exportAs: "uiProgress",
  templateUrl: "progress.component.html",
  styleUrls: ["progress.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, UiIconModule, NgStyle, NgTemplateOutlet, NzOutletModule],
})
export class UiProgressComponent extends NzProgressComponent {
  @Input("uiShowInfo")
  @WithConfig()
  public nzShowInfo = true;
  @Input("uiWidth")
  public nzWidth = 132;
  @Input("uiStrokeColor")
  @WithConfig()
  public nzStrokeColor?: UiProgressStrokeColorType = undefined;
  @Input("uiStrokeTrailColor")
  @WithConfig()
  public nzStrokeTrailColor?: UiProgressStrokeColorType = "#f5f5f5";
  @Input("uiSize")
  @WithConfig()
  public nzSize: UiSizeDSType = "default";
  @Input("uiFormat")
  public nzFormat?: UiProgressFormatter;
  @Input("uiSuccessPercent")
  @InputNumber()
  public nzSuccessPercent?: number;
  @Input("uiPercent")
  @InputNumber()
  public nzPercent = 0;
  @Input("uiStrokeWidth")
  @WithConfig()
  @InputNumber()
  public nzStrokeWidth?: number = undefined;
  @Input("uiStrokeTrailWidth")
  @InputNumber()
  public uiStrokeTrailWidth?: number = undefined;
  @Input("uiGapDegree")
  @WithConfig()
  @InputNumber()
  public nzGapDegree?: number = undefined;
  @Input("uiStatus")
  public nzStatus?: UiProgressStatusType;
  @Input("uiType")
  public nzType: UiProgressTypeType = "line";
  @Input("uiGapPosition")
  @WithConfig()
  public nzGapPosition: UiProgressGapPositionType = "top";
  @Input("uiStrokeLinecap")
  @WithConfig()
  public nzStrokeLinecap: UiProgressStrokeLinecapType = "round";

  @Input("uiSteps")
  @InputNumber()
  public nzSteps = 0;

  constructor(
    cdr: ChangeDetectorRef,
    public uiConfigService: NzConfigService,
    @Optional() directionality: Directionality
  ) {
    super(cdr, uiConfigService, directionality);
  }
}
