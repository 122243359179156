export const range = () => {
  return function (input: number[], min: number, max: number): number[] {
    if (!Array.isArray(input)) {
      return;
    }

    for (let i = min; i <= max; i++) {
      input.push(i);
    }

    return input;
  };
};
