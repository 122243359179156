import { HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, noop } from "rxjs";
import { InsightCalculationPayload } from "@gtmhub/insights";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { QueryParams } from "@webapp/core/abstracts/models/query-params.model";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { ICollection } from "@webapp/core/core.models";
import { GTMHUB_ADDITIONAL_PARAMS } from "@webapp/core/http/interceptors/track-data.interceptor";
import {
  DynamicItemSettings,
  IInsightTotalUsageCount,
  Insight,
  InsightCalculationTransaction,
  InsightChangeLog,
  InsightDTO,
  InsightFilters,
  InsightMetricUsageMap,
  InsightSqlConditionOperators,
  InsightboardInsightUsage,
  InsightsQueryParams,
  KpiInsightUsage,
  MetricInsightUsage,
  RestrictedInsightsStatus,
} from "@webapp/data-story/models/insights.models";
import { InsightsApiService } from "@webapp/data-story/services/insights-facade/insights-api.service";
import { InsightsState } from "@webapp/data-story/services/insights-facade/insights-state.service";

@Injectable({
  providedIn: "root",
})
export class InsightsFacade extends BaseFacade<Insight, InsightDTO, InsightsState, InsightsApiService> {
  constructor(state: InsightsState, api: InsightsApiService) {
    super(state, api);
  }

  public getInsights$(queryParams: InsightsQueryParams): Observable<ICollection<Insight>> {
    return this.get$<ICollection<Insight>>(null, {
      ...new RequestConfig(),
      url: this.apiService.getInsightsEndpoint(),
      queryParams: { ...queryParams },
    });
  }

  public requestInsightCalculationById$(insightId: string, filters: InsightFilters | DynamicItemSettings[] = null): Observable<InsightCalculationTransaction> {
    return this.post$<InsightCalculationTransaction>(
      { filters },
      {
        ...new RequestConfig(),
        url: this.apiService.requestInsightCalculationByIdEndpoint(insightId),
      }
    );
  }

  public requestInsightScriptCalculation$(payload: InsightCalculationPayload): Observable<InsightCalculationTransaction> {
    return this.post$<InsightCalculationTransaction>(payload, {
      ...new RequestConfig(),
      url: this.apiService.requestInsightScriptCalculationEndpoint(),
    });
  }

  public getInsightSqlConditionOperators$(): Observable<ICollection<InsightSqlConditionOperators>> {
    return this.get$<ICollection<InsightSqlConditionOperators>>(null, {
      ...new RequestConfig(),
      url: this.apiService.getInsightSqlConditionOperatorsEndpoint(),
    });
  }

  public createInsight$(insight: Insight, options: { cloned?: boolean } = {}): Observable<Insight> {
    const context = options.cloned ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, { cloned: options.cloned }) } : {};

    return this.post$<Insight>(insight, {
      ...new RequestConfig(),
      ...context,
      url: this.apiService.createInsightEndpoint(),
    });
  }

  public patchInsight$(insight: Partial<Insight>): Observable<Insight> {
    return this.patch$<Insight>("", insight, {
      ...new RequestConfig(),
      url: this.apiService.patchInsightEndpoint(insight.id),
    });
  }

  public getInsightSafe$(name: string): Observable<Insight> {
    return this.get$<Insight>(null, {
      ...new RequestConfig(),
      url: this.apiService.getInsightSafeEndpoint(name),
    });
  }

  public getInsight$(name: string): Observable<Insight> {
    return this.get$<Insight>(null, {
      ...new RequestConfig(),
      url: this.apiService.getInsightEndpoint(name),
    });
  }

  public getInsightsSafe$(insightIds: string, fields?: string): Observable<ICollection<Insight>> {
    const queryParams: QueryParams = {};

    if (insightIds) {
      queryParams.insightIds = insightIds;
    }

    if (fields) {
      queryParams.fields = fields;
    }

    return this.get$<ICollection<Insight>>(null, {
      ...new RequestConfig(),
      url: this.apiService.getInsightsSafeEndpoint(),
      queryParams,
    });
  }

  public deleteInsight$(name: string): Observable<void> {
    return this.apiService
      .delete$<boolean>(null, {
        ...new RequestConfig(),
        url: this.apiService.getInsightDeleteEndpoint(name),
      })
      .pipe(map(noop));
  }

  public getInsightUsages$(insightId: string, usageType: "metrics"): Observable<MetricInsightUsage[]>;
  public getInsightUsages$(insightId: string, usageType: "kpis"): Observable<KpiInsightUsage[]>;
  public getInsightUsages$(insightId: string, usageType: "boards"): Observable<InsightboardInsightUsage[]>;
  public getInsightUsages$(insightId: string, usageType: "count"): Observable<IInsightTotalUsageCount>;
  public getInsightUsages$<T>(insightId: string, usageType: string): Observable<T[] | T> {
    const url = `${this.apiService.getBasePath(HttpActions.get, { apiVersionOverwrite: "v1" })}/${insightId}/usages/${usageType}`;
    return this.get$<T[]>(null, {
      ...new RequestConfig(),
      url,
    });
  }

  public getRestrictedInsightsStatus$(): Observable<RestrictedInsightsStatus> {
    const url = `${this.apiService.getBasePath(HttpActions.get, { apiVersionOverwrite: "v1" })}/restricted/status`;

    return this.apiService.get$<RestrictedInsightsStatus>(null, {
      ...new RequestConfig(),
      url,
    });
  }

  public getInsightsMetrics$(insightIds: string): Observable<InsightMetricUsageMap> {
    const url: string = this.apiService.getInsightsMetricsEndpoint();
    const query: QueryParams = {
      insightIds,
    };

    return this.apiService.get$<InsightMetricUsageMap>(null, {
      ...new RequestConfig(),
      url,
      queryParams: query,
    });
  }

  public getInsightChangeLogs$(insightId: string, queryParams?: InsightsQueryParams): Observable<ICollection<InsightChangeLog>> {
    return this.get$<ICollection<InsightChangeLog>>(null, {
      ...new RequestConfig(),
      url: this.apiService.getInsightChangeLogsEndpoint(insightId),
      queryParams: { ...queryParams },
    });
  }
}
