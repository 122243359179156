import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { GifDataParams, GifResponse, TenorResponse } from "./gif.models";

export const tenorApiKey = "8S423V0QMWIB";

@Injectable({
  providedIn: "root",
})
export class TenorGifService {
  constructor(private httpClient: HttpClient) {}

  public getGifData(params: GifDataParams): Observable<GifResponse[]> {
    let url = `https://g.tenor.com/v1/${params.gifType}?key=${tenorApiKey}&media_filter=${params.gifSize}&limit=8`;
    if (params.searchQuery) {
      url += `&q=${params.searchQuery}`;
    }
    if (params.gifId) {
      url += `&ids=${params.gifId}`;
    }

    return this.httpClient.get<TenorResponse>(url).pipe(
      map((data) => {
        return data.results.map((obj) => {
          return {
            id: obj.id,
            media: obj.media[0]?.[params.gifSize],
          };
        });
      })
    );
  }
}
