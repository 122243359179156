import { ChangeDetectionStrategy, Component, Input, TemplateRef } from "@angular/core";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";

@Component({
  selector: "div[ui-options-button]",
  templateUrl: "modal-options-button.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NzOutletModule],
})
export class UiModalOptionsButtonComponent {
  @Input() public uiOptionsButtonContent: TemplateRef<unknown>;
}
