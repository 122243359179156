import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { UiAvatarModule } from "@quantive/ui-kit/avatar";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { TopNavBarAvatarsConfig, TopNavBarVisibleAvatarsCount } from "@webapp/top-nav-bar/models/top-nav-bar.models";
import { UiAssigneeModule } from "@webapp/ui/assignee/assignee.module";
import { UiPopoverDirective } from "@webapp/ui/popover/popover.directive";

@Component({
  selector: "top-nav-bar-avatars",
  templateUrl: "./top-nav-bar-avatars.html",
  styleUrls: ["./top-nav-bar-avatars.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UiAvatarModule, UiPopoverDirective, UiAssigneeModule, AssigneesModule],
  host: {
    "[attr.tabindex]": "0",
    "[attr.aria-label]": "config.a11yLabel",
  },
})
export class TopNavBarAvatarsComponent implements OnInit, OnChanges {
  @Input({ required: true }) public config: TopNavBarAvatarsConfig;
  @Input() public visibleAvatarsCount: TopNavBarVisibleAvatarsCount = TopNavBarVisibleAvatarsCount.LARGE_SCREEN;

  public visibleAssignees = new Map<string, string>();
  public hiddenAssignees = new Map<string, string>();

  public ngOnInit(): void {
    this.setHiddenAndVisibleAssignees();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const visibleAvatarsCountChanged = changes.visibleAvatarsCount && !changes.visibleAvatarsCount.firstChange;
    const configChanged = changes.config && !changes.config.firstChange;

    if (visibleAvatarsCountChanged || configChanged) {
      this.setHiddenAndVisibleAssignees();
    }
  }

  private setHiddenAndVisibleAssignees(): void {
    // the check below ensures we don't get in a scenario where we have e.g. 5 avatars rendered and the last one is +1 (render a standalone avatar instead)
    const standaloneAssigneeAvatarsCount = this.visibleAvatarsCount === this.config.assigneesIdColorMap.size ? this.visibleAvatarsCount : this.visibleAvatarsCount - 1;
    const assignees = Array.from(this.config.assigneesIdColorMap);
    this.visibleAssignees = new Map(assignees.slice(0, standaloneAssigneeAvatarsCount));
    this.hiddenAssignees = new Map(assignees.slice(standaloneAssigneeAvatarsCount));
  }
}
