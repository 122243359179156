import { CurrentEmployeeActions } from "@gtmhub/employees";
import { CurrentUserRolesActions } from "@gtmhub/roles";
import { providerUpgradeFactory } from "@webapp/core/provider-upgrade/factories/provider-upgrade.factory";

export const CurrentEmployeeActionsProvider = {
  provide: CurrentEmployeeActions,
  useFactory: providerUpgradeFactory("CurrentEmployeeActions"),
  deps: ["$injector"],
};

export const CurrentUserRolesActionsProvider = {
  provide: CurrentUserRolesActions,
  useFactory: providerUpgradeFactory("CurrentUserRolesActions"),
  deps: ["$injector"],
};
