import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { merge } from "rxjs";
import { FormItemBase } from "@webapp/shared/form/models/form.models";

@UntilDestroy()
@Component({
  selector: "form-item-validation-hint",
  templateUrl: "./form-item-validation-hint.component.html",
  styleUrls: ["./form-item-validation-hint.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormItemValidationHintComponent implements OnInit {
  @Input({ required: true }) public formItemDescriptor: FormItemBase;
  @Input({ required: true }) public form: FormGroup;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private zone: NgZone
  ) {}

  public ngOnInit(): void {
    const streams = [this.form?.statusChanges, this.formItemDescriptor.formControl?.statusChanges].filter(Boolean);

    merge(...streams)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (NgZone.isInAngularZone()) {
          this.changeDetector.markForCheck();
        } else {
          this.zone.run(() => this.changeDetector.markForCheck());
        }
      });
  }
}
