import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormControl, ValidatorFn } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { map, switchMap, take } from "rxjs";
import { FormTooltipIconComponent } from "@webapp/shared/form/components/form-tooltip-icon/form-tooltip-icon.component";
import { SimpleChangesTyped } from "@webapp/shared/models";
import { addAttributeValue, removeAttributeValue } from "@webapp/ui/utils/dom.utils";
import { FormGroupComponent } from "../../form-group.component";
import { FormHint, FormItemBase, FormPopoverIcon, FormTooltipIcon } from "../../models/form.models";
import { FormHintComponent } from "../form-hint/form-hint.component";
import { FormPopoverIconComponent } from "../form-popover-icon/form-popover-icon.component";
import { CustomContentSiblingDirective } from "./directives/custom-content-sibling.directive";

/**
 * A base class for each form item.
 * Newly created form items should extend this class and add a provider that points to them, when FormItemBaseComponent is requested.
 *
 * @example
 * @Component({
 *   selector: "gh-assignee-form-item",
 *   templateUrl: "./assignee-form-item.component.html",
 *   changeDetection: ChangeDetectionStrategy.OnPush,
 *   providers: [
 *     {
 *       provide: FormItemBaseComponent,
 *       useExisting: forwardRef(() => AssigneeFormItemComponent),
 *     },
 *   ],
 * })
 * export class AssigneeFormItemComponent extends FormItemBaseComponent {
 */
@UntilDestroy()
@Component({
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormItemBaseComponent<T = unknown> implements OnInit, OnChanges, FormItemBase<T> {
  /**
   * Specifies the form control name.
   */
  @Input() public controlName: string;

  /**
   * Marks the label as required with an asterisk and sets a required form validator to this control.
   */
  @Input() public required: boolean;

  /**
   * Specifies the label text content.
   * If not specified, the label column will still be rendered with no content.
   * To hide the column entirely, check `hideLabelColumn`.
   */
  @Input() public labelText: string;

  /**
   * Specifies the secondary label text content. Which will render below the 'labelText'.
   * If not specified, the secondary label container will not be rendered.
   */
  @Input() public secondaryLabelText?: string;

  /**
   * Specifies if the label column rendering should be emitted altoghether.
   */
  @Input() public hideLabelColumn = false;

  /**
   * Focuses the editor, when rendered.
   * Depends on specific component implementation - won't work by default.
   */
  @Input() public autofocus: boolean;

  /**
   * Provides a placeholder for the editor.
   */
  @Input() public placeholder = "";

  /**
   * Marks the component as disabled.
   * It's value, if provided, will not be included in the form,
   * nor will it be validated.
   */
  @Input() public disabled: boolean;

  /**
   * Marks the component as readonly.
   */
  @Input() public readonly: boolean;

  /**
   * Provides a map for
   */
  @Input() public errorMessages: { [key: string]: string };

  /**
   * Specifies an array of default or custom validators.
   * To specify a `required` validator, you can use the quicker `required` syntax ([required]="true").
   */
  @Input() public validators: ValidatorFn[];

  /**
   * Attaches a data-test-id attribute.
   */
  @Input() public e2eTestId: string;

  /**
   * Generic implementation - overwrite in extending components to ensure correct typing.
   */
  @Input() public value: T;

  /**
   * Specifies whether to show a `trash-bin` icon on the right of the editor.
   * When clicked, the icon will clear the item value and the FormComponent will emit `valueChange`.
   */
  @Input() public clearValueIcon: boolean | { showOnHover: boolean };

  /**
   * Specifies a tooltip text to be rendered on the left of the label.
   */
  @Input() public labelTooltip: string;

  /**
   * Specifies whether an inline loading indicator should be rendered
   * in the place of the form control editor column (including hints, errors, icons).
   */
  @Input() public loading: boolean;

  @Input() public handleValidationHints = false;

  /**
   * Emits each time the inner-lying form control value changes via user interaction.
   */
  @Output()
  public readonly valueChange: EventEmitter<T> = new EventEmitter();

  /**
   * Specifies a list of hints to be rendered beneath the editor.
   * If an error message is rendered, any provided hints will be temporarily hidden.
   */
  @ContentChildren(FormHintComponent)
  public hints: FormHint[];

  /**
   * Creates an icon with popover text on the right of the editor.
   */
  @ContentChild(FormPopoverIconComponent)
  public popoverIcon: FormPopoverIcon;

  /**
   * Creates an icon with tooltip text on the right of the editor.
   */
  @ContentChild(FormTooltipIconComponent)
  public tooltipIcon: FormTooltipIcon;

  @ContentChild(CustomContentSiblingDirective)
  public customContentSibling: CustomContentSiblingDirective;

  /**
   * Captures the current form item editor template.
   * Rendered by the FormComponent in the corresponding placeholder.
   */
  @ViewChild(TemplateRef, { static: true })
  public template: TemplateRef<object>;

  /**
   * Overwrite in the extending class if you want to use a custom required value validator.
   * E.g. with string values, the default required validator marks as valid value a sequence of empty white-spaces.
   * In such a scenario, provide a validaton that trims the value first.
   */
  public requiredValidator: ValidatorFn;

  /**
   * This value is populated by the FormComponent parent.
   */
  public formControl: FormControl;

  /**
   * Creates a default form control element ID based on the control name and form ID.
   * Use this for linking the control with the label and description elements.
   */
  public get controlId(): string {
    return this.controlName + "_control_" + this.form.formId;
  }

  /**
   * Creates a default description element block ID based on the control name and form ID.
   * Used for accessiblity purposes - renders a visually hidden element which is linked
   * to the main control element via aria-describedby.
   */
  public get descriptionId(): string {
    return this.controlName + "_description_" + this.form.formId;
  }

  /**
   * Creates a default label element ID based on the control name and form ID.
   * Use this for linking the label with the control.
   */
  public get labelId(): string {
    return this.controlName + "_label_" + this.form.formId;
  }

  /**
   * Controls whether the current control will be rendered without a border.
   */
  public get borderless(): boolean {
    return this.form.borderless;
  }

  private initialized = false;

  public constructor(
    protected form: FormGroupComponent,
    protected changeDetector: ChangeDetectorRef,
    private zone: NgZone
  ) {}

  public ngOnInit(): void {
    this.initialized = true;
    this.subscribeToControlDescriptionChanges();
  }

  public ngOnChanges(changes: SimpleChangesTyped<FormItemBaseComponent>): void {
    // the initial changes are captured by the FormComponent ContentChildren query
    // after form-item component ngOnInit, react to each update regardless of what props are updated or if it's their first time
    if (this.initialized) {
      const formControlSetupChange = changes.controlName || changes.value || changes.required || changes.validators || changes.disabled || changes.readonly;
      this.form.handleFormDescriptorsChange({ formGroupUpdate: Boolean(formControlSetupChange), controlName: this.controlName });
    }
  }

  private subscribeToControlDescriptionChanges(): void {
    this.form.a11yDescriptionsChange$
      .pipe(
        map((renderedDescriptionBlockIds) => renderedDescriptionBlockIds.includes(this.descriptionId)),
        switchMap((isCurrentControlDescriptionRendered) =>
          // wait for the rendering to complete
          this.zone.onStable.pipe(
            take(1),
            map(() => isCurrentControlDescriptionRendered)
          )
        ),
        untilDestroyed(this)
      )
      .subscribe((isCurrentControlDescriptionRendered) => {
        const addOrRemoveAttribute = isCurrentControlDescriptionRendered ? addAttributeValue : removeAttributeValue;

        addOrRemoveAttribute({
          element: document.getElementById(this.controlId),
          attribute: "aria-describedby",
          value: this.descriptionId,
        });
      });
  }
}
