import { module } from "angular";
import ngRedux from "ng-redux";
import { Action, Store, combineReducers } from "redux";
import ReduxThunk, { ThunkDispatch } from "redux-thunk";
import { INgRedux } from "./models";
import { INgReduxProvider } from "./ng-redux";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
    __REDUX_DEVTOOLS_EXTENSION__?(): () => any;
  }
}

interface CustomStore extends Store {
  getState<T = unknown>(): T;
  dispatch: ThunkDispatch<unknown, unknown, Action<string>>;
}

export const reduxStoreContainer: { reduxStore?: CustomStore } = {};

export function stateManagementModule(reducers: { [key: string]: (state: unknown, action: Action<string>) => unknown }): string {
  const mod = module("state-management", [ngRedux]);

  mod.config([
    "$ngReduxProvider",
    function ($ngReduxProvider: INgReduxProvider) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let storeEnhancers: [() => any];
      const storeMiddleware = [];

      // if redux chrome devtools extension is installed and enabled
      if (window && window.__REDUX_DEVTOOLS_EXTENSION__) {
        storeEnhancers = [window.__REDUX_DEVTOOLS_EXTENSION__()];
      }

      storeMiddleware.push(ReduxThunk);

      const rootReducer = combineReducers(reducers);
      $ngReduxProvider.createStoreWith(rootReducer, storeMiddleware, storeEnhancers);
    },
  ]);

  mod.run([
    "$ngRedux",
    ($ngRedux: INgRedux) => {
      reduxStoreContainer.reduxStore = $ngRedux;
    },
  ]);

  return mod.name;
}
