import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiChipModule } from "@quantive/ui-kit/chip";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { ChipAssigneeDirective } from "@webapp/shared/chip/chip-assignee.directive";
import { ViewOnlyUserChain } from "@webapp/shared/components/people-selector/chains/view-only-user.chain";
import { IndicatorModule } from "@webapp/shared/indicator/indicator.module";
import { UiGridModule } from "@webapp/ui/grid/grid.module";
import { UiSelectModule } from "@webapp/ui/select/select.module";
import { CustomFilterChain } from "./chains/custom-filter.chain";
import { GoalFilterChain } from "./chains/goal-filter.chain";
import { IdFieldFilterChain } from "./chains/id-filter.chain";
import { LimitFilterChain } from "./chains/limit-filter.chain";
import { NameFilterChain } from "./chains/name-filter.chain";
import { PermissionFilterChain } from "./chains/permission-filter.chain";
import { TeamFilterChain } from "./chains/team-filter.chain";
import { UserFilterChain } from "./chains/user-filter.chain";
import { PeopleSelectorComponent } from "./people-selector.component";
import { PeopleFilterBuilder } from "./services/people-filter.builder";

@NgModule({
  imports: [
    CommonModule,
    IndicatorModule,
    LocalizationModule,
    FormsModule,
    AssigneesModule,
    UiIconModule,
    UiButtonModule,
    UiSelectModule,
    UiGridModule,
    UiChipModule,
    ChipAssigneeDirective,
  ],
  declarations: [PeopleSelectorComponent],
  exports: [PeopleSelectorComponent],
  providers: [
    PeopleFilterBuilder,
    GoalFilterChain,
    PermissionFilterChain,
    ViewOnlyUserChain,
    UserFilterChain,
    TeamFilterChain,
    LimitFilterChain,
    NameFilterChain,
    IdFieldFilterChain,
    CustomFilterChain,
  ],
})
export class PeopleSelectorModule {}
