<i
  [uiTrigger]="trigger"
  [uiPlacement]="placement"
  [uiTitle]="title ? titleTemplate : null"
  [uiContent]="contentTemplate"
  [uiVisible]="isPopoverOpen"
  [attr.aria-label]="a11yLabel"
  [tabindex]="a11yTabIndex"
  [uiType]="iconName"
  (keydown.enter)="onIconKeydown()"
  ui-icon
  aria-haspopup="true"
  aria-keyshortcuts="Enter"
  ui-popover
  type="button"></i>

<ng-template #titleTemplate>
  <div class="title-container" id="icon-popup-title" #contentElements (keydown)="onKeydownContent($event)" role="note" tabindex="0" aria-keyshortcuts="Escape">{{ title }}</div>
</ng-template>

<ng-template #contentTemplate>
  <div class="content-container" #contentElements (keydown)="onKeydownContent($event)" role="note" tabindex="0" aria-keyshortcuts="Escape">{{ content }}</div>
  <a class="link-container" #contentElements *ngIf="linkText && linkLocation" (keydown)="onKeydownContent($event)" (click)="linkInteract()" role="link" tabindex="0" aria-keyshortcuts="Escape">
    {{ linkText }}</a
  >
</ng-template>
