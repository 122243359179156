import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "quantive-plus-objective-description",
  templateUrl: "./quantive-plus-objective-description.component.html",
  styleUrls: ["./quantive-plus-objective-description.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuantivePlusObjectiveDescriptionComponent {
  @Output()
  public readonly descriptionSuggestionEvent = new EventEmitter();

  public onButtonClick = (): void => {
    this.descriptionSuggestionEvent.emit();
  };
}
