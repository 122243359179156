import { Inject, Injectable, InjectionToken, StaticProvider } from "@angular/core";
import { IconDefinition } from "@ant-design/icons-angular";
import { IconSettingsService, SVGIcon } from "@progress/kendo-angular-icons";
import { UiIconService } from "@quantive/ui-kit/icon";
import { DEFAULT_KENDO_ICON_OVERRIDES } from "@webapp/shared/kendo/icons/utils/icon-utils";

const KENDO_ICON_OVERRIDES = new InjectionToken<Map<string, string>>("KENDO_ICON_OVERRIDES");

/**
 * Provides a service that maps Kendo icons to UI Kit icons.
 *
 * @param {Map<string, string>} [moduleSpecificIconOverrides=new Map<string, string>()] - `optional`, defaults to an empty map.
 * Can be set to use a different set of overrides per module/component.
 * E.G. `kendoIconSettingsServiceProvider(myModuleCustomIconOverrides)`
 * @return {*}  {StaticProvider}
 */
export function kendoIconSettingsServiceProvider(moduleSpecificIconOverrides: Map<string, string> = new Map<string, string>()): StaticProvider {
  const iconOverrides = new Map([...DEFAULT_KENDO_ICON_OVERRIDES, ...moduleSpecificIconOverrides]);

  return {
    provide: IconSettingsService,
    useFactory: (uiIconService: UiIconService): KendoIconSettingsService => new KendoIconSettingsService(uiIconService, iconOverrides),
    deps: [UiIconService],
  };
}

@Injectable()
class KendoIconSettingsService extends IconSettingsService {
  private uiIcons: Map<string, IconDefinition>;

  constructor(
    private uiIconService: UiIconService,
    @Inject(KENDO_ICON_OVERRIDES) private iconOverrides: Map<string, string>
  ) {
    super();
    this.uiIcons = this.uiIconService.getCachedIcons();
  }

  public getSvgIcon(kendoSvgIconName: string): SVGIcon {
    const uiKitIconName = this.iconOverrides.get(kendoSvgIconName);
    const uiIconDefinition = this.uiIcons.get(uiKitIconName);

    return uiIconDefinition ? { name: kendoSvgIconName, content: uiIconDefinition.icon, viewBox: "0 0 16 16" } : super.getSvgIcon(kendoSvgIconName);
  }
}
