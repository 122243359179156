<div *ngIf="notification.operationType === 'requested'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    {{ "user_wants_to_award_badge_to" | localize: { names: notification.notificationData.actor.names, badgeName: notification.notificationData.item.badgeName } }}
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i [uiType]="notification.targetType" [uiTooltipTitle]="notification.targetType | localize" ui-tooltip ui-icon uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.badgeName }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <div class="flex" *ngFor="let user of notification.notificationData.assignees; trackBy: trackByUser">
          <span>🏅</span>
          <ui-assignee [uiAssignee]="user | asAssignee" uiSize="sm"></ui-assignee>
        </div>
      </div>
      <div class="inbox-section">
        {{ "badge_waiting_your_approval_accept_or_reject" | localize }}
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'approved'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    {{ "user_approved_your_badge_award" | localize: { names: notification.notificationData.actor.names, badgeName: notification.notificationData.item.badgeName } }}
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i [uiType]="notification.targetType" [uiTooltipTitle]="notification.targetType | localize" ui-tooltip ui-icon uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.badgeName }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <div class="flex" *ngFor="let user of notification.notificationData.assignees; trackBy: trackByUser">
          <span>🏅</span>
          <ui-assignee [uiAssignee]="user | asAssignee" uiSize="sm"></ui-assignee>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'received'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    {{ "user_awarded_you_badge" | localize: { names: notification.notificationData.actor.names, badgeName: notification.notificationData.item.badgeName } }}
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i [uiType]="notification.targetType" [uiTooltipTitle]="notification.targetType | localize" ui-tooltip ui-icon uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.badgeName }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section" *ngIf="notification.notificationData.item.comment">
        <rich-text-viewer [text]="notification.notificationData.item.comment"></rich-text-viewer>
      </div>
    </div>
  </div>
</div>

<div *ngIf="notification.operationType === 'rejected'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">
    {{ "user_rejected_badge_award_to" | localize: { names: notification.notificationData.actor.names, badgeName: notification.notificationData.item.badgeName } }}
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i [uiType]="notification.targetType" [uiTooltipTitle]="notification.targetType | localize" ui-tooltip ui-icon uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.badgeName }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <div class="flex" *ngFor="let user of notification.notificationData.assignees; trackBy: trackByUser">
          <span>🏅</span>
          <ui-assignee [uiAssignee]="user | asAssignee" uiSize="sm"></ui-assignee>
        </div>
      </div>
      <div class="inbox-section">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "make_new_request" | localize }}</a>
      </div>
    </div>
  </div>
</div>
