import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UiAvatarModule, UiAvatarSize } from "@quantive/ui-kit/avatar";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { Badge } from "@webapp/badges/models/badges.models";

/**
 * Should only be used in Angular.js
 */
@Component({
  // eslint-disable-next-line webapp/no-component-selector-prefix
  selector: "gh-badge-avatar",
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ui-avatar
      *ngIf="badge"
      [uiSize]="size"
      [uiIcon]="badge.icon"
      [uiPattern]="badge.pattern"
      [uiBgColor]="badge.background"
      [uiAlt]="badge.name"
      [uiTooltipTitle]="showTooltip && badge.name"
      ui-tooltip></ui-avatar>
  `,
  standalone: true,
  imports: [CommonModule, UiAvatarModule, UiTooltipModule],
})
export class BadgeAvatarComponent {
  @Input()
  public badge: Badge;

  @Input()
  public size: UiAvatarSize = "md";

  @Input()
  @InputBoolean()
  public showTooltip?: boolean;
}
