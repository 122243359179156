import { IRootScopeService, IScope } from "angular";

export class UserSelectorEvents {
  private $rootScope: IRootScopeService;

  constructor(private $scope: IScope) {
    this.$rootScope = this.$scope.$root;
  }

  broadcastChangeSelectedUsers(selectedIds: string[]): void {
    this.$rootScope.$broadcast("changeSelectedUsers", selectedIds);
  }

  onSelectedUsersChange(callback: (args: string[]) => unknown): void {
    this.$scope.$on("changeSelectedUsers", (evt, args: string[]) => callback(args));
  }
}
