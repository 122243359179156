import { NgFor, NgIf } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, Input, OnChanges, QueryList } from "@angular/core";
import { UiAvatarModule, UiAvatarSize } from "@quantive/ui-kit/avatar";
import { SimpleChangesOf } from "@quantive/ui-kit/core";
import { UiTooltipModule, UiTooltipPlacement } from "@quantive/ui-kit/tooltip";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { startWith } from "rxjs";
import { UiAssigneeComponent } from "../../assignee.component";
import { UiAssigneeTooltipTitleFn } from "../../assignee.models";
import { UiAssigneeAvatarComponent } from "../assignee-avatar/assignee-avatar.component";

/**
 * Displays a group of assignee avatars.
 * You can limit long lists using `uiMax` to determine the visible count of avatars
 * and the rest will be replaced by a placeholder containing the number of hidden items.
 */
@Component({
  selector: "ui-assignee-avatar-group",
  exportAs: "uiAssigneeAvatarGroup",
  templateUrl: "assignee-avatar-group.component.html",
  styleUrls: ["./assignee-avatar-group.component.less"],
  host: {
    "[class.ui-assignee-avatar-group-wrapper]": `true`,
    "[class.ui-assignee-avatar-group-x-small]": `uiSize === 'xs'`,
    "[class.ui-assignee-avatar-group-small]": `uiSize === 'sm'`,
    "[class.ui-assignee-avatar-group-medium]": `uiSize === 'md'`,
    "[class.ui-assignee-avatar-group-large]": `uiSize === 'lg'`,
    "[class.ui-assignee-avatar-group-x-large]": `uiSize === 'xl'`,
    "[class.ui-assignee-avatar-group-xx-large]": `uiSize === 'xxl'`,
    "[class.ui-align-right]": "uiAlignRight",
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiAvatarModule, UiAssigneeAvatarComponent, UiAssigneeComponent, UiTooltipModule, NgFor, NgIf],
})
export class UiAssigneeAvatarGroupComponent implements AfterViewInit, OnChanges {
  @Input()
  public uiSize?: UiAvatarSize = "md";

  @Input()
  public uiMax = 5;

  @Input()
  @InputBoolean()
  public uiAlignRight?: boolean;

  @Input()
  public uiAssigneeTooltipPlacement?: UiTooltipPlacement;

  @Input()
  public uiAssigneeTooltipTitle?: UiAssigneeTooltipTitleFn;

  /**
   * @internal
   */
  public visibleComponents: UiAssigneeAvatarComponent[] = [];

  /**
   * @internal
   */
  public hiddenComponents: UiAssigneeAvatarComponent[] = [];

  @ContentChildren(UiAssigneeAvatarComponent)
  private assignees: QueryList<UiAssigneeAvatarComponent>;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChangesOf<UiAssigneeAvatarGroupComponent>): void {
    if (changes.uiMax && this.assignees) {
      this.updateVisibleAssignees();
    }
  }

  public ngAfterViewInit(): void {
    this.assignees.changes.pipe(startWith(0)).subscribe(() => {
      this.updateVisibleAssignees();
      this.cdr.detectChanges();
    });
  }

  private updateVisibleAssignees(): void {
    // In case that we have same number of assignees as max, +1 avatar is not displayed
    const max = Math.max(0, this.uiMax);
    const assignees = this.assignees.toArray();
    this.visibleComponents = assignees.slice(0, max);
    this.hiddenComponents = assignees.slice(max);
  }
}
