<div class="suggestion-drawer-container sp-m-0" [uiGutter]="[24, 24]" ui-row>
  <div class="sp-p-0" ui-col uiXs="24" data-test-id="suggestions-side-modal">
    <div ui-row>
      <div ui-col uiXs="24">
        <div class="sp-pb-8 suggestion-drawer-header" ui-row uiAlign="middle">
          <div class="suggestion-drawer-title-wraper" ui-col uiXs="22" uiFlex="1">
            <p class="suggestion-drawer-title sp-m-0 text-color-gray-13 fs-20px">{{ "suggestions_cap" | localize }}</p>
          </div>
          <div ui-col uiXs="2">
            <button
              class="close-suggestion-drawer-button"
              [uiTooltipTitle]="'hide_suggestions' | localize"
              (click)="closeSuggestionDrawer()"
              ui-button
              uiType="link"
              ui-tooltip
              uiTooltipPlacement="bottomRight">
              <i ui-icon uiType="close-menu"></i>
            </button>
          </div>
        </div>

        <div *ngIf="description" ui-row>
          <div ui-col uiXs="24">
            <p class="sp-mb-0 sp-pb-12">{{ description }}</p>
          </div>
        </div>
      </div>
    </div>

    <div ui-row>
      <div ui-col uiXs="24">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
