<div class="heading">
  <div class="heading-content">
    <p class="title">{{ "you_are_view_only_role" | localize }}</p>
    <p class="description">{{ "you_are_view_only_role_description" | localize }}</p>
  </div>

  <button *ngIf="!hideCloseButton" (click)="emitCloseAction()" ui-button uiShape="round" uiSize="small">
    <i ui-icon uiType="close-big"></i>
  </button>
</div>

<a *ngIf="!widgetView" href="https://help.quantive.com/en/articles/6478981-regular-vs-view-only-users" ui-button uiShape="round" target="_blank">{{ "learn_more" | localize }}</a>
<a class="ui-link" *ngIf="widgetView" href="https://help.quantive.com/en/articles/6478981-regular-vs-view-only-users" ui-external-link target="_blank">{{ "learn_more" | localize }}</a>
