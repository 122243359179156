import { Observable } from "rxjs";
import { AssigneeSelectorType, ISelectorAssignee } from "@webapp/assignees/models/assignee.models";

export interface IPeopleFilterChain {
  setNextChain(chain: IPeopleFilterChain): void;
  handle(request: PeopleSelectorRequest, assignees$: Observable<ISelectorAssignee[]>, selectedIds?: string[]): Observable<ISelectorAssignee[]>;
}

export interface IHideSelectedQuery {
  enabled: boolean;
  ids: string[];
}

export interface IGoalLimitSetting {
  sessionId: string;
  limit: number;
}

export interface IGroupedSelectedPeople {
  teamIds: string[];
  userIds: string[];
}

interface IPermissionSetting {
  enabled: boolean;
  permissions?: string[];
}

export class PeopleSelectorRequest {
  public type: AssigneeSelectorType = "both";
  public goalsLimitSetting: IGoalLimitSetting;
  public permissionSetting: IPermissionSetting = { enabled: false };
  public limit = 9;
  public hideViewOnlyUsers? = false;
}

export class PeopleSelectorRequestInternal extends PeopleSelectorRequest {
  public notInIdFieldQuery?: string[];
  public nameQuery?: string;
  /**
   * Predicate used in custom filter chain based on which assignees are not included in the final list
   */
  public shouldDisplayAssignee?: (assignee: ISelectorAssignee) => boolean;
}
