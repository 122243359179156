import { storage } from "@gtmhub/core/storage";
import { IError } from "../models";

export class ErrorService {
  public getStoredError(): IError {
    const authError = storage.get<Record<string, string>>("auth_error");
    if (authError) {
      storage.remove("auth_error");
      return {
        message: authError.error,
        details: authError.errorDescription,
      };
    }
    return null;
  }
}
