import { module } from "angular";
import { CheckInsDataService } from "@webapp/reflections/services/checkins-data.service";
import { CheckInsFacade } from "@webapp/reflections/services/checkins-facade.service";

const mod = module("reflections-core", []);

mod.downgradeInjectable("CheckInsFacade", CheckInsFacade);
mod.downgradeInjectable("CheckInsDataService", CheckInsDataService);

export default mod.name;
