import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, Input } from "@angular/core";
import { CandyDate } from "ng-zorro-antd/core/time";
import { ɵMonthTableComponent as MonthTableComponent } from "ng-zorro-antd/date-picker";
import { UiDateHelperService } from "@webapp/ui/date-picker/services/date-helper.service";
import { UiDateBodyRow, UiDateCell } from "../../date-picker.models";
import { UiDatePickerService } from "../../services/date-picker.service";

@Component({
  selector: "ui-month-table",
  exportAs: "uiMonthTable",
  templateUrl: "../abstract-table.html",
  styleUrls: ["month-table.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "ui-date-table",
  },
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, NgSwitchDefault, NgIf, NgClass, NgFor, NgTemplateOutlet],
})
export class UiMonthTableComponent extends MonthTableComponent {
  /** the id of the element holding month and year buttons and used to label the table */
  @Input()
  public uiTableLabelledBy: string;

  public bodyRows: UiDateBodyRow[];

  constructor(
    public datePickerService: UiDatePickerService,
    public elementRef: ElementRef<HTMLElement>,
    dateHelper: UiDateHelperService
  ) {
    super(dateHelper);
  }
}

// This is a private method, so we need to use [] to access it
const addCellPropertyOrig = UiMonthTableComponent.prototype["addCellProperty"];
UiMonthTableComponent.prototype["addCellProperty"] = function (cell: UiDateCell, month: CandyDate): void {
  addCellPropertyOrig.call(this, cell, month);
  cell.ariaLabel = this["dateHelper"].format(month.nativeDate, "MMMM");
};
