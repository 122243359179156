import { Injector } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, Observable, finalize, of, switchMap, take } from "rxjs";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { localize } from "@webapp/localization/utils/localization.utils";
import { SupportedItemAutomationTypeService } from "@webapp/shared/components/item-automation-selector/providers/supported-automation-type.service";
import { AutomatingInsightSelectionManager } from "@webapp/shared/components/item-automation-selector/utils/automating-insight-selection-manager";
import { AutomatingKpiSelectionManager } from "@webapp/shared/components/item-automation-selector/utils/automating-kpi-selection-manager";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { ChooseAutomationComponent } from "../components/choose-automation/choose-automation.component";
import { ExtendWithWriteValueOriginCheck } from "../components/item-automation-selector/item-automation-selector.component";
import {
  ItemAutomationSelectionType,
  ItemAutomationSelectorData,
  ItemAutomationSelectorDataType,
  ItemAutomationSelectorUsageType,
  ItemAutomationSupportedSelectionModeType,
  ItemAutomationTargetType,
} from "../models/item-automation-selector.models";

type SupportedAutomationSelectionTypeToAction = {
  insight: AutomatingItemBrowserModalManager["openInsightSelectorModal$"];
  kpi: AutomatingItemBrowserModalManager["openKpiSelectorModal$"];
  insightOrKpi: AutomatingItemBrowserModalManager["openInsightOrKpiSelectorModal$"];
};

@UntilDestroy()
export class AutomatingItemBrowserModalManager {
  public usedIn: ItemAutomationSelectorUsageType;

  private uiModalService: UiModalService;
  private supportedAutomationTypeService: SupportedItemAutomationTypeService;
  private analyticsService: AnalyticsService;

  constructor(
    private injector: Injector,
    private selectorData$: BehaviorSubject<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorData<ItemAutomationTargetType>>>
  ) {
    this.uiModalService = this.injector.get(UiModalService);
    this.supportedAutomationTypeService = this.injector.get(SupportedItemAutomationTypeService);
    this.analyticsService = this.injector.get(AnalyticsService);
  }

  public openBrowseAutomatingItemsModalForTargetType$(
    itemType: ItemAutomationTargetType
  ): Observable<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"insight"> | ItemAutomationSelectorDataType<"kpi">>> {
    return this.supportedAutomationTypeService.getSupportedAutomationTypeForItemType$(itemType).pipe(
      switchMap((supportedType) => {
        return this.openBrowseAutomatingItemsModalForSelectionType$(supportedType);
      })
    );
  }

  private openBrowseAutomatingItemsModalForSelectionType$(
    selectionType: ItemAutomationSupportedSelectionModeType
  ): Observable<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"insight"> | ItemAutomationSelectorDataType<"kpi">>> {
    const supportedSelectionTypeCheckMap: {
      [SelectionType in ItemAutomationSupportedSelectionModeType]: SupportedAutomationSelectionTypeToAction[SelectionType];
    } = {
      insight: this.openInsightSelectorModal$.bind(this),
      kpi: this.openKpiSelectorModal$.bind(this),
      insightOrKpi: this.openInsightOrKpiSelectorModal$.bind(this),
    };

    return supportedSelectionTypeCheckMap[selectionType]();
  }

  public openInsightSelectorModal$(): Observable<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"insight">>> {
    const automatingInsightSelectionManager = new AutomatingInsightSelectionManager(
      this.injector,
      this.selectorData$ as BehaviorSubject<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"insight">>>
    );

    this.analyticsService.track("Automation Selector Browse Connections Modal Opened");

    return automatingInsightSelectionManager.openBrowseConnectionsModal$().pipe(
      finalize(() => {
        this.trackAutomationSelectorBrowseCancelled();
      })
    );
  }

  public openKpiSelectorModal$(): Observable<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"kpi">>> {
    const automatingKpiSelectionManager = new AutomatingKpiSelectionManager(
      this.injector,
      this.selectorData$ as BehaviorSubject<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"kpi">>>
    );

    this.analyticsService.track("Automation Selector Browse KPIs Modal Opened");

    return automatingKpiSelectionManager.openKpiSelectorModal$().pipe(
      finalize(() => {
        this.trackAutomationSelectorBrowseCancelled();
      })
    );
  }

  public openInsightOrKpiSelectorModal$(): Observable<
    ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"insight"> | ItemAutomationSelectorDataType<"kpi">>
  > {
    this.analyticsService.track("Automation Selector Choose Between KPI and Insight Modal Opened");

    return this.uiModalService
      .create({
        uiTitle: localize("how_do_you_want_to_automate_this_key_result"),
        uiContent: ChooseAutomationComponent,
        uiFooter: null,
        uiWidth: "740px",
        uiCentered: true,
      })
      .afterClose.pipe(
        switchMap((selection: ItemAutomationSelectionType) => {
          if (selection === "insight") {
            return this.openInsightSelectorModal$();
          } else if (selection === "kpi") {
            return this.openKpiSelectorModal$();
          } else {
            this.trackAutomationSelectorBrowseCancelled();

            return of(null);
          }
        })
      );
  }

  private trackAutomationSelectorBrowseCancelled(): void {
    this.selectorData$.pipe(take(1), untilDestroyed(this)).subscribe({
      next: (selectorData) => {
        const data = selectorData?.data;
        const isInsightSelected = data?.selectionType === "insight" && !!data?.insightName;
        const isKPISelected = data?.selectionType === "kpi" && !!data?.kpiId;

        if (!isInsightSelected && !isKPISelected) {
          this.analyticsService.track("Automation Selector Modal Cancelled", {
            location: this.usedIn,
          });
        }
      },
    });
  }
}
