<form [formGroup]="sessionForm" ui-form>
  <ui-form-item>
    <ui-form-control [uiXs]="24">
      <ui-tree-select
        #treeSelect
        [uiNodes]="data || []"
        [uiShowExpand]="false"
        [uiShowIcon]="false"
        [uiDefaultExpandAll]="true"
        [uiHideUnMatched]="true"
        [uiAllowClear]="false"
        [uiTreeTemplate]="nzTreeTemplate"
        [uiSelectItemTemplate]="selectItemTemplate"
        [uiNotFoundContentReference]="notFound"
        [uiSearchFunc]="searchFunction"
        [formControlName]="'id'"
        [isTreeFlatten]="isTreeFlatten"
        [uiNodeExpansionEnabled]="false"
        [uiLoading]="isLoading$ | async"
        [uiLoadingContentReference]="loading"
        [uiDisabled]="uiDisabled"
        [readonly]="readonly"
        [uiId]="uiId"
        [focusMe]="focusMe"
        [a11yRequired]="a11yRequired"
        [a11yDescribedby]="a11yDescribedby"
        [a11yDescription]="a11yDescription"
        [a11yLabelledby]="a11yLabelledby"
        [autofocus]="autofocus"
        [uiPlaceHolder]="placeholderText"
        (uiSearchValueChange)="onInputValueChange($event)"
        (uiSelectedValueChange)="onSelectedValueChange($event)"
        (uiOpenChange)="onOpenChange()"
        data-test-id="session-selector-dropdown"
        uiDropdownMatchSelectWidth="true"
        uiShowSearch
        uiDropdownClassName="session-selector-global"></ui-tree-select>
    </ui-form-control>
  </ui-form-item>
</form>

<ng-template #loading>
  <gh-indicator *ngIf="isLoading$ | async" [ghValue]="{ progress: true }" [ghMessage]="'loading' | localize"></gh-indicator>
</ng-template>

<ng-template #notFound>
  <div class="no-sessions-found">{{ "no_sessions_found" | localize }}</div>
</ng-template>

<ng-template #selectItemTemplate let-node>
  <span class="selected-item-color" [ngStyle]="{ 'background-color': node.origin.color }"></span>
  <span>{{ node.title }}</span>
</ng-template>

<ng-template #nzTreeTemplate let-node>
  <div class="ant-tree-node-content-wrapper">
    <span class="node-icon" [ngStyle]="{ 'background-color': node.origin.color }"></span>
    <div class="node-title-wrapper">
      <span data-test-id="session-selector-single-session" ui-typography uiEllipsis>{{ node.title }}</span>
      <span class="selected-icon" *ngIf="node.isSelected">
        <i class="fs-14px" ui-icon uiTheme="fill" uiType="check-white"></i>
      </span>
    </div>
  </div>
</ng-template>
