import { Injectable } from "@angular/core";
import { Observable, map, of } from "rxjs";
import { IAssigneesStoreState } from "@gtmhub/assignees";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { IdMap } from "@gtmhub/util";
import { Assignee, AssigneeDTO, IAssigneeReduxState } from "@webapp/assignees/models/assignee.models";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { AssigneesApiService } from "./assignees-api.service";
import { AssigneesState } from "./assignees-state.service";

@Injectable({
  providedIn: "any",
})
export class AssigneesFacade extends BaseFacade<Assignee, AssigneeDTO, AssigneesState, AssigneesApiService> {
  private assigneesState: IAssigneeReduxState;

  public constructor(state: AssigneesState, api: AssigneesApiService) {
    super(state, api);
    this.assigneesState = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState>().assignees;
  }

  public getAssigneesIdMap(): IdMap<Assignee> {
    return this.assigneesState.map;
  }

  public getAllActiveAssignees$(): Observable<Assignee[]> {
    return of(this.assigneesState.items).pipe(map((assignees) => assignees.filter((a) => a.isActive)));
  }
}
