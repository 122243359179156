import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { TrackingMetadata } from "../models/analytics.model";
import { AnalyticsService } from "../services/analytics.service";

interface ITrackOptions {
  on?: string;
}

/**
 * Tracks events on DOM elements
 *
 * @example
 * <a ghTrack="Clicked a link">Track clicks on this element</a>
 * <a ghTrack="Clicked a link" [ghTrackMeta]="{ year: 2020 }">Track clicks on this element with additional metadata</a>
 * <a ghTrack="Clicked a link" [ghTrackMeta]="{ year: 2020, email: vm.email }">Track clicks on this element with additional metadata resolved from current scope</a>
 * <a ghTrack="Dropped on a link" [ghTrackOptions]="{ on: 'drop' }">Track drops on this element</a>
 */
@Directive({
  selector: "[ghTrack]",
})
export class TrackDirective implements OnInit {
  @Input("ghTrack") public trackEvent: string;
  @Input("ghTrackMeta") public meta: TrackingMetadata;
  @Input("ghTrackOptions") public options: ITrackOptions = {};

  constructor(
    private elementRef: ElementRef,
    private analyticsService: AnalyticsService
  ) {}

  public ngOnInit(): void {
    const listenToEvent = this.options.on || "click";
    this.elementRef.nativeElement.addEventListener(listenToEvent, () => {
      this.analyticsService.track(this.trackEvent, this.meta);
    });
  }
}
