import { ViewHistoryService } from "@gtmhub/view-history";
import { ViewHistoryFactory } from "@gtmhub/view-history/view-history-factory";
import { providerUpgradeFactory } from "@webapp/core/provider-upgrade/factories/provider-upgrade.factory";

export const ViewHistoryServiceProvider = {
  provide: ViewHistoryService,
  useFactory: providerUpgradeFactory("ViewHistoryService"),
  deps: ["$injector"],
};

export const ViewHistoryFactoryProvider = {
  provide: ViewHistoryFactory,
  useFactory: providerUpgradeFactory("ViewHistoryFactory"),
  deps: ["$injector"],
};
