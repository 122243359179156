import { IAccess } from "@webapp/sessions/models/sessions.model";
import { SimplePermissionsAccessType } from "../models/permissions.model";

export function determineAccessType(access: IAccess): SimplePermissionsAccessType {
  for (const permission of access.permissions) {
    if (permission.principalKind === "account" && permission.grant.general.indexOf("read") !== -1 && permission.grant.general.indexOf("update") === -1) {
      return SimplePermissionsAccessType.publicRead;
    }

    if (permission.principalKind === "account" && permission.grant.general.indexOf("read") !== -1 && permission.grant.general.indexOf("update") !== -1) {
      return SimplePermissionsAccessType.publicUpdate;
    }
  }

  if (access.permissions.length === 0) {
    return SimplePermissionsAccessType.privateAccess;
  }

  return SimplePermissionsAccessType.restricted;
}
