import { Pipe, PipeTransform } from "@angular/core";
import { LowToHighScaleType } from "../models/quantive-plus-effectiveness.models";
import { lowToHighScaleEffectivenessToLabel } from "../utils/quantive-plus-effectiveness-adapter.utils";

@Pipe({ name: "lowToHighScaleToLabel" })
export class LowToHighScaleEffectivenessToLabelPipe implements PipeTransform {
  public transform(effectivenessLevel: LowToHighScaleType): string {
    return lowToHighScaleEffectivenessToLabel(effectivenessLevel);
  }
}
