import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiDropdownModule } from "@quantive/ui-kit/dropdown";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { UiSelectModule } from "@webapp/ui/select/select.module";
import { UiTreeModule } from "@webapp/ui/tree/tree.module";
import { ButtonSelectComponent } from "./button-select.component";

@NgModule({
  imports: [CommonModule, LocalizationModule, UiSelectModule, UiIconModule, FormsModule, UiButtonModule, UiIconModule, UiTreeModule, UiDropdownModule],
  declarations: [ButtonSelectComponent],
  exports: [ButtonSelectComponent],
})
export class ButtonSelectModule {}
