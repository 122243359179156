import { Pipe, PipeTransform } from "@angular/core";
import { INotificationUser } from "@gtmhub/notifications/models/notifications";
import { Assignee } from "@webapp/assignees/models/assignee.models";

@Pipe({ name: "asAssignee" })
export class AsAssigneePipe implements PipeTransform {
  public transform(input: INotificationUser): Assignee {
    if (!input) {
      return;
    }

    return {
      id: input.id,
      type: "user",
      name: input.names,
      email: input.email,
      picture: input.avatar,
    };
  }
}
