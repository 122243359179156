import { IScope } from "angular";
import { AuthenticationResolverService } from "@gtmhub/auth";

interface IAccountSwitchedScope extends IScope {
  logout(): void;
}

export class AccountSwitchedCtrl {
  public static $inject = ["$scope", "AuthenticationResolverService"];

  constructor(
    private $scope: IAccountSwitchedScope,
    private authenticationResolverService: AuthenticationResolverService
  ) {
    this.$scope.logout = this.logout;
  }

  private logout = (): void => {
    this.authenticationResolverService.logoutToWebappLogoutUrl();
  };
}
