import { Injectable } from "@angular/core";
import { ISessionListItem, ISessionTreeNode } from "@webapp/sessions/models/session-tree.model";

@Injectable({
  providedIn: "root",
})
export class SessionListDataTransformerService {
  public buildList(tree: ISessionTreeNode[]): ISessionListItem[] {
    const list: ISessionListItem[] = [];
    this.populateList(tree, list);
    return list;
  }

  private populateList(tree: ISessionTreeNode[], list: ISessionListItem[]): void {
    for (const node of tree) {
      const listItem = this.createSessionListItem(node);
      list.push(listItem);

      if (node.children.length) {
        this.populateList(node.children, list);
      }
    }
  }

  private createSessionListItem(sessionNode: ISessionTreeNode): ISessionListItem {
    const sessionItem: ISessionListItem = {
      key: sessionNode.key,
      title: sessionNode.title,
      color: sessionNode.color,
      disabled: sessionNode.disabled,
      parentKey: sessionNode.parentKey || null,
    };

    return sessionItem;
  }
}
