<ui-card [uiBordered]="false" [uiHeadBorderless]="true" [uiRounded]="true" [uiTitle]="title" [uiExtra]="extraHeaderContentTemplate">
  <ui-card-tab *editionFeature="'whiteboards'">
    <ui-tabset [uiHideBody]="true" (uiSelectedIndexChange)="changeActiveTabIndex($event)" uiSize="large">
      <ui-tab [uiTitle]="'recently_viewed' | localize"></ui-tab>
      <ui-tab [uiTitle]="'shared_with_me' | localize"></ui-tab>
    </ui-tabset>
  </ui-card-tab>

  <ui-loading-indicator [uiSpinning]="loading" [uiTip]="'loading' | localize" [uiIndicator]="loadingTemplate">
    <div class="wb-cards-container" *ngIf="whiteboards.length">
      <div class="wb-cards">
        <div
          class="wb-card"
          *ngFor="let whiteboard of whiteboards"
          [uiParams]="{ whiteboardId: whiteboard.id }"
          [ghTrackMeta]="getWhiteboardClickedMetaData(whiteboard.id)"
          ghTrack="Whiteboard Clicked"
          uiSref="gtmhub.whiteboard"
          tabindex="0"
          role="link">
          <ui-visually-hidden>{{ "whiteboard_cap" | localize }}</ui-visually-hidden>
          <div class="title">
            <span>{{ whiteboard.name }}</span>
          </div>
          <ui-assignee [fromAssigneeId]="whiteboard.createdById" [deletedAssigneeType]="'user'" uiSize="xs" />
          <div class="picture"><img *ngIf="whiteboard.picture" [src]="whiteboard.picture" alt="" /></div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!loading && !error && !whiteboards.length">
      <container-empty-state *editionFeature="'whiteboards'" [message]="emptyStateMessage" pictureUrl="/dist/img/home/widgets/empty_whiteboards.svg" />

      <container-empty-state *editionFeature="'whiteboards'; negate: true" [message]="'shared_whiteboards_will_show_here' | localize" pictureUrl="/dist/img/whiteboards_preview.png">
        <a *ngIf="(showUpgradeButton$ | async) === true" uiSref="gtmhub.editionPlanChange" ui-button uiShape="round">{{ "upgrade" | localize }}</a>
      </container-empty-state>
    </ng-container>
  </ui-loading-indicator>

  <ui-alert *ngIf="error" [uiDescription]="error" [uiCloseable]="true" uiType="error" />
</ui-card>

<ng-template #extraHeaderContentTemplate>
  <widget-actions [widgetId]="widgetId"></widget-actions>
</ng-template>

<ng-template #loadingTemplate>
  <widget-skeleton />
</ng-template>
