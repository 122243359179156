<i
  [uiType]="uiType"
  [uiTheme]="uiTheme"
  [uiSpin]="uiSpin"
  [uiSize]="uiSize"
  [uiTwotoneColor]="uiTwotoneColor"
  [uiIconfont]="uiIconfont"
  [uiRotate]="uiRotate"
  [uiTooltipTrigger]="tooltipTitle ? 'hover' : null"
  [uiTooltipTitle]="tooltipTitle"
  [uiTooltipPlacement]="tooltipPosition"
  [uiTooltipOverlayClassName]="uiTooltipOverlayClassName"
  [uiTooltipOrigin]="tooltipOrigin"
  [attr.data-test-id]="'icon-' + uiType"
  ui-icon
  ui-tooltip></i>
<span class="visually-hidden" *ngIf="alt">{{ alt }}</span>
