import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from "@angular/core";
import { NzTreeNodeOptions } from "ng-zorro-antd/tree";
import { Observable } from "rxjs";
import { FormItemBaseComponent } from "@webapp/shared/form/components/form-items/form-item-base.component";
import { FormModule } from "@webapp/shared/form/form.module";
import { IMultiSelectorGoal, IMultiSelectorMetric } from "../multi-selector/multi-selector.models";
import { MultiSelectorModule } from "../multi-selector/multi-selector.module";

@Component({
  selector: "multi-selector-form-item",
  templateUrl: "./multi-selector-form-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FormItemBaseComponent, useExisting: forwardRef(() => MultiSelectorFormItemComponent) }],
  standalone: true,
  imports: [FormModule, MultiSelectorModule],
})
export class MultiSelectorFormItemComponent extends FormItemBaseComponent<NzTreeNodeOptions> {
  @Input() public nodes: NzTreeNodeOptions[];
  @Input() public nodesLoading: boolean;
  @Input() public sessionIds: string[];
  @Input() public searchFn: (searchTerm: string) => Observable<NzTreeNodeOptions[]>;
  @Input() public sortFn: (itemA: IMultiSelectorGoal | IMultiSelectorMetric, itemB: IMultiSelectorGoal | IMultiSelectorMetric) => number;

  @Output()
  public readonly openChange: EventEmitter<boolean> = new EventEmitter();
  @Output()
  public readonly selectedParentItemClicked: EventEmitter<void> = new EventEmitter();
}
