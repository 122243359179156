import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MultiAccount, MultiAccountDTO } from "@webapp/accounts/models/multi-account/multi-account.models";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { DomainService } from "../domain/domain.service";
import { MultiAccountsApiService } from "./multi-account-api.service";
import { MultiAccountsState } from "./multi-account-state.service";

@Injectable({
  providedIn: "any",
})
export class MultiAccountsFacade extends BaseFacade<MultiAccount, MultiAccountDTO, MultiAccountsState, MultiAccountsApiService> {
  constructor(
    state: MultiAccountsState,
    api: MultiAccountsApiService,
    private domainService: DomainService,
    @Inject(APP_CONFIG) private appConfig: IAppConfig
  ) {
    super(state, api);
  }

  public getMultiAccounts$(clientId: string, filter?: RequestPaging): Observable<MultiAccount[]> {
    return this.getAll$<MultiAccount[]>(filter, { ...new RequestConfig(), url: this.apiService.getMultiAccountsEndpoint(clientId) });
  }

  public switchUserAccount(data: { account: MultiAccount; currentAccountId: string; currentUserEmail: string }): void {
    const { account, currentAccountId, currentUserEmail } = data;
    if (account.accountId === currentAccountId) {
      return;
    }

    let queryString: string;
    if (account.userIsPrimary) {
      queryString = `email=${currentUserEmail}`;
    } else {
      queryString = "isSecondary=true";
    }

    if (this.appConfig.env.bypassDomainCheck) {
      const gtmhubDomain = this.domainService.constructGtmhubDomain(account.accountDomain, { preserveDomainForLocalhost: true });
      queryString += `&originalDomain=${gtmhubDomain}`;
    }

    const domain = this.domainService.constructGtmhubDomain(account.accountDomain);

    // go to login => auto-resolve user using the auth0 cookie
    window.open(`${location.protocol}//${domain}/login?${queryString}`, "_self");
  }
}
