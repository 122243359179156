import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { IPIImprovementDTO, IPIImprovementItemModel } from "./models";
import { PiImprovementServiceState } from "./pi-improvement-state.service";

@Injectable({
  providedIn: "root",
})
export class PiImprovementApiService extends BaseApiService<IPIImprovementItemModel, IPIImprovementDTO, PiImprovementServiceState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: PiImprovementServiceState) {
    super("improvement", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getRoot(): string {
    return `${this.getQuantivePlusBasePath(HttpActions.post)}/`;
  }
}
