import { IModalProvider } from "angular-ui-bootstrap";
import dropdown from "angular-ui-bootstrap/src/dropdown/index-nocss";
import modal from "angular-ui-bootstrap/src/modal/index-nocss";
import popover from "angular-ui-bootstrap/src/popover/index-nocss";
import tabs from "angular-ui-bootstrap/src/tabs";
import tooltip from "angular-ui-bootstrap/src/tooltip/index-nocss";
import typeahead from "angular-ui-bootstrap/src/typeahead/index-nocss";

const mod = angular.module("gtmhub.ui.bootstrap", [dropdown, modal, popover, tabs, tooltip, typeahead]);

mod.config([
  "$uibModalProvider",
  function ($uibModalProvider: IModalProvider) {
    $uibModalProvider.options.animation = false;
  },
]);

export default mod.name;
