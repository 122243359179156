import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UiAvatarSize } from "@quantive/ui-kit/avatar";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { Assignee, MissingAssignee, UnknownAssignee } from "@webapp/assignees/models/assignee.models";
import { UiAssigneeNameAlignment } from "./assignee.models";
import { UiAssigneeAvatarComponent } from "./components/assignee-avatar/assignee-avatar.component";
import { UiAssigneeNameComponent } from "./components/assignee-name/assignee-name.component";

@Component({
  selector: "ui-assignee",
  exportAs: "uiAssignee",
  templateUrl: "assignee.component.html",
  styleUrls: ["./assignee.component.less"],
  host: {
    class: "ui-assignee-wrapper",
    "[class.ui-assignee-x-small]": `uiSize === 'xs'`,
    "[class.ui-assignee-small]": `uiSize === 'sm'`,
    "[class.ui-assignee-medium]": `uiSize === 'md'`,
    "[class.ui-assignee-large]": `uiSize === 'lg'`,
    "[class.ui-assignee-x-large]": `uiSize === 'xl'`,
    "[class.ui-assignee-xx-large]": `uiSize === 'xxl'`,
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiAssigneeAvatarComponent, UiAssigneeNameComponent],
})
export class UiAssigneeComponent {
  @Input()
  public uiAssignee: Assignee | UnknownAssignee | MissingAssignee;

  @Input()
  public uiSize: UiAvatarSize = "md";

  @Input()
  @InputBoolean()
  public uiNameEllipsis?: boolean;

  @Input()
  @InputBoolean()
  public uiNameMultiline?: boolean;

  @Input()
  public uiNameAlign: UiAssigneeNameAlignment;

  @Input()
  public uiNamePattern?: string;
}
