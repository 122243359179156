import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { take } from "rxjs";
import { IQuantivePlusTag } from "@webapp/platform-intelligence/quantive-plus/models";
import { UI_MODAL_DATA } from "@webapp/ui/modal/modal.models";
import { PiErrorData } from "../../models/pi-errors.models";
import { getPiErrorData } from "../../utils/pi-errors.utils";
import { IPIFeedbackSuggestion } from "../pi-feedback-card/models";
import { IPostImprovementPayload } from "../pi-feedback-card/services/improvement/models";
import { PiImprovementFacade } from "../pi-feedback-card/services/improvement/pi-improvement-facade.service";
import { generateImprovementPayload, generateTagImprovementPayload, tagsImprovementSplitOrJoinSuffix } from "../pi-feedback-card/services/improvement/utils";
import { PiStateProcessorInstance } from "../pi-feedback-card/services/state-processor/pi-state-processor.models";
import { PiStateProcessorService } from "../pi-feedback-card/services/state-processor/pi-state-processor.service";

export type CompareSuggestionsFeedbackOptions = "original" | "updated" | "none";

export type FeedbackSuggestionResult = {
  compareSuggestionsFeedback: CompareSuggestionsFeedbackOptions;
  newSuggestion: IPIFeedbackSuggestion;
};

export type PiFeedbackSuggestionsModalData = {
  suggestion: IPIFeedbackSuggestion;
  instance: PiStateProcessorInstance;
  userFeedback: string;
};

@Component({
  selector: "pi-feedback-suggestions",
  templateUrl: "./pi-feedback-suggestions.component.html",
  styleUrls: ["./pi-feedback-suggestions.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiFeedbackSuggestionsComponent implements OnInit {
  public selectedOption: CompareSuggestionsFeedbackOptions;
  public suggestion: IPIFeedbackSuggestion;
  public instance: PiStateProcessorInstance;
  public errorData: PiErrorData;
  public userFeedback: string;
  public isLoading = false;
  public isError = false;
  public improvedSuggestion: string | string[];
  public isCardsIconAvailable: boolean;
  private newSuggestion: IPIFeedbackSuggestion;

  constructor(
    private piImprovementFacade: PiImprovementFacade,
    private cd: ChangeDetectorRef,
    private piStateProcessorService: PiStateProcessorService,
    @Inject(UI_MODAL_DATA) modalData: PiFeedbackSuggestionsModalData
  ) {
    Object.assign(this, modalData);
  }

  public get canSubmitFeedback(): boolean {
    return !!this.selectedOption && !this.isError && !this.isLoading;
  }

  public ngOnInit(): void {
    this.isCardsIconAvailable = this.shouldDisplayCardsIcon();

    this.getImprovedSuggestion();
  }

  public getImprovedSuggestion(): void {
    this.isLoading = true;
    this.isError = false;
    this.cd.detectChanges();

    if (this.instance.subType === "tags") {
      return this.getTagsImprovedSuggestion();
    }

    const payload = generateImprovementPayload({
      subEntityType: this.instance.subType,
      instruction: this.userFeedback,
      suggestion: {
        title: this.suggestion.text,
      },
      isAskAI: false,
      improveSuggestionContext: this.instance.improveSuggestionContext,
    });

    this.postImprovement(payload);
  }

  private postImprovement(payload: IPostImprovementPayload): void {
    this.piImprovementFacade
      .postImprovement<{ title: string; id: string } | { tags: string[]; id: string }>(payload)
      .pipe(take(1))
      .subscribe({
        next: (improvement) => {
          this.isLoading = false;

          if ("title" in improvement.suggestions.improvement) {
            this.improvedSuggestion = improvement.suggestions.improvement.title;
          }

          if ("tags" in improvement.suggestions.improvement) {
            this.improvedSuggestion = improvement.suggestions.improvement.tags;
          }

          const replacementSuggestion =
            typeof this.improvedSuggestion === "string" ? this.improvedSuggestion : this.improvedSuggestion.join(tagsImprovementSplitOrJoinSuffix);

          this.newSuggestion = {
            id: improvement.suggestions.improvement.id,
            text: replacementSuggestion,
          };

          this.cd.detectChanges();
        },
        error: (err: HttpErrorResponse) => {
          this.errorData = getPiErrorData(this.instance.subType, err);
          this.isLoading = false;
          this.isError = true;
          this.cd.detectChanges();
        },
      });
  }

  private getTagsImprovedSuggestion(): void {
    const payload = generateTagImprovementPayload({
      subEntityType: this.instance.subType,
      instruction: this.userFeedback,
      tags: this.suggestion.text as unknown as IQuantivePlusTag[],
      isAskAI: false,
      improveSuggestionContext: this.instance.improveSuggestionContext,
    });

    this.postImprovement(payload);
  }

  private shouldDisplayCardsIcon(): boolean {
    return this.instance.isInline || (this.instance.subType !== "description" && this.instance.subType !== "tags");
  }

  private replaceSuggestion(): FeedbackSuggestionResult {
    this.piStateProcessorService.notifyInstancesOnSuggestionReplacement(this.instance.subType, { newSuggestion: this.newSuggestion, suggestion: this.suggestion });
    return { compareSuggestionsFeedback: this.selectedOption, newSuggestion: this.newSuggestion };
  }

  public submitFeedback(): FeedbackSuggestionResult {
    if (this.selectedOption === "updated") {
      return this.replaceSuggestion();
    }

    return { compareSuggestionsFeedback: this.selectedOption, newSuggestion: this.newSuggestion };
  }
}
