import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ICollection } from "@webapp/core/core.models";
import { INavItem, NavItemsSectionConfig } from "@webapp/navigation/models/nav-items-list.models";
import NavigationItemsMediator from "@webapp/navigation/services/uxcustomization/navigation-items.mediator.service";

@Component({
  selector: "nav-items-list",
  templateUrl: "./nav-items-list.component.html",
  styleUrls: ["./nav-items-list.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavItemsListComponent implements OnInit, OnChanges {
  @Input()
  public config: NavItemsSectionConfig;
  @Input()
  public collection: ICollection<INavItem>;
  @Output()
  public readonly markItemAsFavorite = new EventEmitter<INavItem>();
  @Output()
  public readonly unmarkItemAsFavorite = new EventEmitter<INavItem>();
  @Output()
  public readonly removeFromSuggested = new EventEmitter<INavItem>();
  public expanded = true;
  public seeMore: boolean;
  public limitedCollection: ICollection<INavItem>;

  public constructor(protected navigationitemsMediator: NavigationItemsMediator) {}

  private setLimitedCollection(collection: ICollection<INavItem>): void {
    if (!collection) {
      return;
    }

    this.updateInMemoryItemOrder();

    const maxAllowedItemsCount = this.config.maxItemsCount || collection.totalCount;
    const visibleItemsCount = Math.min(this.config.visibleItemsCount, maxAllowedItemsCount);
    const take = this.seeMore ? maxAllowedItemsCount : visibleItemsCount;

    this.limitedCollection = {
      items: collection.items.slice(0, take),
      totalCount: Math.min(maxAllowedItemsCount, collection.totalCount),
    };
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["collection"]) {
      this.setLimitedCollection(changes["collection"].currentValue);
    }
  }

  public ngOnInit(): void {
    this.setLimitedCollection(this.collection);
  }

  public toggleSeeMore(): void {
    this.seeMore = !this.seeMore;
    this.setLimitedCollection(this.collection);
  }

  public drop(event: Partial<CdkDragDrop<string[]>>): void {
    if (event.previousIndex === event.currentIndex || this.config.itemsGroupType !== "favorites") {
      return;
    }

    const item = this.collection.items[event.previousIndex];
    this.reorderItems(item, event.previousIndex, event.currentIndex);
  }

  public moveItem(itemId: string, direction: -1 | 1): void {
    const item = this.collection.items.find((item) => item.id === itemId);
    const previousIndex = item.order - 1; // order starts from 1, it's not 0-based
    const newIndex = previousIndex + direction;

    this.reorderItems(item, previousIndex, newIndex);
  }

  private reorderItems(item: INavItem, previousIndex: number, newIndex: number): void {
    moveItemInArray(this.collection.items, previousIndex, newIndex);

    // indices are 0-based, item order starts from 1
    const newOrder = newIndex + 1;
    this.navigationitemsMediator.reorder(item.favoriteId, newOrder);

    this.setLimitedCollection(this.collection);
  }

  private updateInMemoryItemOrder(): void {
    this.collection.items.forEach((item, index) => (item.order = index + 1));
  }
}
