import { Inject, Injectable, NgZone } from "@angular/core";
import { Subject } from "rxjs";
import { IGtmhubRootScopeService } from "@gtmhub/models";
import { BroadcastQueue } from "@webapp/core/broadcast/models/broadcast.models";

@Injectable({
  providedIn: "root",
})
export class BroadcastService {
  private messageQueue: BroadcastQueue = new BroadcastQueue();

  constructor(
    @Inject("$rootScope") private rootScope: IGtmhubRootScopeService,
    private ngZone: NgZone
  ) {}

  public emit<BroadcastMessageType extends unknown[]>(name: string, ...args: BroadcastMessageType): void {
    // creating the subject and $rootScope subscription if not present
    this.setupDataStream(name);

    // preserve the original Root Scope for Angular.js until we do not need it anymore
    // use the $rootScope as data stream until it's removed to avoid duplicate emissions (if we both $broadcast() and use this.messageQueue[name].next() - this will incorrectly result in two triggers)
    this.rootScope.$broadcast(name, ...args);
  }

  public on<BroadcastMessageType>(name: string): Subject<BroadcastMessageType> {
    // creating the subject and $rootScope subscription if not present (usually, as you subscribe to something which later gets emitted)
    this.setupDataStream(name);

    // emissions will be triggered via the $rootScope
    return this.messageQueue[name] as Subject<BroadcastMessageType>;
  }

  private setupDataStream(name: string): void {
    if (!this.messageQueue[name]) {
      this.messageQueue[name] = new Subject();

      // subscribe to the $rootScope only on subject creation to avoid incorrect multiple subscriptions to $rootScope.on(eventName, ...)
      this.rootScope.$on(name, (event, args: object) => {
        this.ngZone.run(() => this.messageQueue[name].next(args));
      });
    }
  }
}
