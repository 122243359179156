import { IPromise } from "angular";
import { firstValueFrom } from "rxjs";
import { IRestLayerRequest } from "@gtmhub/core";
import { GoalsFacade, IResponseProcessing } from "@webapp/okrs/goals/services/goals-facade.service";

export class UserOwnershipService {
  public static $inject = ["GoalsFacade"];

  constructor(private goalsFacade: GoalsFacade) {}

  public isGoalOwner = (userId: string): IPromise<boolean> => {
    const ownedGoalsParams: IRestLayerRequest = {
      fields: "id",
      limit: 1,
      skip: 0,
      filter: {
        ownerIds: {
          $in: [userId],
        },
      },
    };
    const ownedGoalsResponseProcessing: IResponseProcessing = { applyColoring: false };

    return firstValueFrom(
      this.goalsFacade.getGoalsV2$(
        {
          ...ownedGoalsParams,
          ...(ownedGoalsParams.sort && { sort: ownedGoalsParams.sort.split(",") }),
          ...(ownedGoalsParams.fields && { fields: ownedGoalsParams.fields.split(",") }),
        },
        null,
        ownedGoalsResponseProcessing
      )
    ).then((response) => response.items.length >= 1);
  };
}
