import { Directionality } from "@angular/cdk/bidi";
import { ChangeDetectorRef, Component, ContentChildren, HostListener, Input, Optional, QueryList, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { UiSizeLDSType } from "@quantive/ui-kit/core";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzRadioGroupComponent } from "ng-zorro-antd/radio";
import { UiRadioButtonStyle } from "../../models/radio-button.models";
import { UiRadioComponent } from "../../radio.component";
import { UiRadioService } from "../../services/radio.service";

/* Passing the nzName property to all input[type="radio"] that are in the same RadioGroup.
 * It is usually used to let the browser see your ui-radio-group as a real "group" and keep the default behavior.
 * For example, using left/right keyboard arrow to change your selection that in the same ui-radio-group.
 */

/**
 * This component extends the base nz-radio-group component of NG-Zorro.
 * Example usage : https://ng.ant.design/components/radio/en.
 * DS epic : https://gtmhub.atlassian.net/browse/GVS-14886.
 * Confluence page : https://gtmhub.atlassian.net/l/cp/bQqW2mSe
 */

@Component({
  selector: "ui-radio-group",
  exportAs: "uiRadioGroup",
  templateUrl: "./radio-group.component.html",
  styleUrls: ["./radio-group.component.less"],
  providers: [
    UiRadioService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiRadioGroupComponent),
      multi: true,
    },
  ],
  host: {
    "[class.horizontal]": `horizontal`,
  },
  standalone: true,
})
export class UiRadioGroupComponent extends NzRadioGroupComponent {
  @Input() @InputBoolean() public horizontal = false;
  @Input("uiDisabled") @InputBoolean() public nzDisabled = false;
  @Input("uiButtonStyle") public nzButtonStyle: UiRadioButtonStyle = "outline";
  @Input("uiSize") public nzSize: UiSizeLDSType = "default";
  @Input("uiName") public nzName: string | null = null;

  @ContentChildren(UiRadioComponent, { descendants: true }) public radioButtons: QueryList<UiRadioComponent>;

  private radioService: UiRadioService;
  private selectedIndex = -1;

  constructor(cdr: ChangeDetectorRef, uiRadioService: UiRadioService, @Optional() directionality: Directionality) {
    super(cdr, uiRadioService, directionality);
    this.radioService = uiRadioService;
  }

  // This entire handler is set in place because firefox doesn't have arrow keys support for radio buttons
  @HostListener("keydown", ["$event"])
  public onKeydown(evt: KeyboardEvent): void {
    const target = evt.target as HTMLElement;
    if (!target.classList.contains("ant-radio-input") && !target.classList.contains("ant-radio-button-input")) {
      return;
    }

    const buttonsCount = this.radioButtons.length;

    switch (evt.key) {
      case "ArrowDown":
      case "ArrowRight": {
        evt.preventDefault(); // the scroll in firefox is triggered as well if we don't prevent the default behaviour
        if (this.selectedIndex < 0) {
          this.selectedIndex = this.radioButtons.toArray().findIndex((button) => button.isChecked);
        }

        const nextIndex = (this.selectedIndex + buttonsCount + 1) % buttonsCount;
        const nextValue = this.radioButtons.toArray()[nextIndex].nzValue;

        this.radioService.select(nextValue);
        this.radioButtons.toArray()[nextIndex].inputElement.nativeElement.focus();
        this.selectedIndex = nextIndex;
        break;
      }
      case "ArrowUp":
      case "ArrowLeft": {
        evt.preventDefault(); // the scroll in firefox is triggered as well if we don't prevent the default behaviour
        if (this.selectedIndex < 0) {
          this.selectedIndex = this.radioButtons.toArray().findIndex((button) => button.isChecked);
        }

        const prevIndex = (this.selectedIndex + buttonsCount - 1) % buttonsCount;
        const prevValue = this.radioButtons.toArray()[prevIndex].nzValue;

        this.radioService.select(prevValue);
        this.radioButtons.toArray()[prevIndex].inputElement.nativeElement.focus();
        this.selectedIndex = prevIndex;
        break;
      }
    }
  }
}
