import { Injectable } from "@angular/core";
import { Observable, map, mergeMap, switchMap, take, tap } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { ICollection } from "@webapp/core/core.models";
import { IBaseRepository } from "@webapp/core/state-management/models/base-repository.model";
import { IPlanningSessionConflict, Session, SessionProgress, UserAction } from "../models/sessions.model";
import { SessionsApiService } from "./sessions-api.service";
import { SessionsCache } from "./sessions-cache";

@Injectable({ providedIn: "root" })
export class SessionsRepository implements Pick<IBaseRepository<Session>, "getAll$" | "get$" | "post$" | "put$" | "delete$"> {
  constructor(
    private api: SessionsApiService,
    private cache: SessionsCache
  ) {}

  public getAll$<RT = ICollection<Session>>(params?: { filter?: RequestPaging; config?: RequestConfig }): Observable<RT | ICollection<Session>> {
    return this.api.getAll$<RT>(params.filter, params.config);
  }

  public get$(id: string, config?: RequestConfig): Observable<Session> {
    return this.api.get$<Session>(id, config);
  }

  public post$(params: { postModel: Partial<Session>; config?: RequestConfig }): Observable<Session> {
    return this.api.post$<Session>(params.postModel, params.config).pipe(
      switchMap((session) =>
        this.cache.get$().pipe(
          take(1),
          map((map) => {
            if (map) {
              map.set(session.id, session);
              this.cache.set(map);
            }
            return session;
          })
        )
      )
    );
  }

  public put$(params: { id: string; putModel: Partial<Session>; config?: RequestConfig }): Observable<Session> {
    return this.api.put$<Session>(params.id, params.putModel, params.config);
  }

  public delete$(params: { id: string; config?: RequestConfig }): Observable<void> {
    return this.api.delete$<Session>(params.id, params.config).pipe(
      switchMap(() => {
        return this.cache.get$().pipe(
          take(1),
          map((map) => {
            if (map) {
              map.delete(params.id);
              this.cache.set(map);
            }
          })
        );
      })
    );
  }

  public checkForConflictsInSession$(session: Session, action: UserAction): Observable<ICollection<IPlanningSessionConflict>> {
    const sessionCopy = structuredClone(session);
    let inherits = false;
    for (let i = 0; i < sessionCopy.access.permissions.length; i++) {
      if (sessionCopy.access.permissions[i].principalKind === "account") {
        inherits = true;
      }
    }

    sessionCopy.access.inherits = inherits;

    return this.api.checkForConflictsInSession$(sessionCopy, action);
  }

  public getMap$(): Observable<Map<string, Session>> {
    return this.cache.get$().pipe(
      switchMap((value) => {
        if (value) {
          return this.cache.get$();
        } else {
          return this.loadSessionsIfMissing();
        }
      })
    );
  }

  public getSessionsProgress$(sessionIds: string, employeeIdAndTeamIds: string): Observable<ICollection<SessionProgress>> {
    return this.api.getSessionsProgress$(sessionIds, employeeIdAndTeamIds);
  }

  private loadSessionsIfMissing(): Observable<Map<string, Session>> {
    return this.api.getAll$().pipe(
      tap((sessions) => {
        const sortedSessions = sessions.items.sort((a, b) => b.end.localeCompare(a.end));
        const map = new Map();
        for (const s of sortedSessions) {
          map.set(s.id, s);
        }
        this.cache.set(map);
      }),
      mergeMap(() => this.cache.get$())
    );
  }
}
