import { Hotkey, HotkeysService } from "angular2-hotkeys";
import { Directive, ElementRef, Input, OnChanges, OnDestroy } from "@angular/core";
import { SimpleChangesOf } from "@quantive/ui-kit/core";

@Directive({
  selector: "[hotkey]",
})
export class HotkeyDirective implements OnChanges, OnDestroy {
  @Input() public hotkey: string;

  private hotkeyInternal: Hotkey;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private hotkeysService: HotkeysService
  ) {}

  public ngOnChanges(changes: SimpleChangesOf<HotkeyDirective>): void {
    if (changes.hotkey) {
      this.unregisterHotkey();

      if (changes.hotkey.currentValue) {
        this.hotkeyInternal = new Hotkey(changes.hotkey.currentValue, () => {
          const el = this.elementRef.nativeElement;
          const disabled = el.hasAttribute("disabled") || el.getAttribute("aria-disabled") === "true";
          if (!disabled) {
            this.elementRef.nativeElement.click();
          }
          return false;
        });
        this.hotkeysService.add(this.hotkeyInternal);
      }
    }
  }

  public ngOnDestroy(): void {
    this.unregisterHotkey();
  }

  private unregisterHotkey(): void {
    if (this.hotkeyInternal) {
      this.hotkeysService.remove(this.hotkeyInternal);
      this.hotkeyInternal = null;
    }
  }
}
