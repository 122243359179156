import { localize } from "@gtmhub/localization";
import { Task } from "@webapp/tasks/models/tasks.models";

export enum GtmhubTaskStatus {
  toDo = "todo",
  inProgress = "inprogress",
  done = "done",
}

const toDoKey = "to_do";
const inProgressKey = "in_progress";
const doneKey = "done";

const gtmhubStatusKeys = {
  [GtmhubTaskStatus.toDo]: toDoKey,
  [GtmhubTaskStatus.inProgress]: inProgressKey,
  [GtmhubTaskStatus.done]: doneKey,
};

export const jiraStatusKeys = {
  "to do": toDoKey,
  "in progress": inProgressKey,
  done: doneKey,
};

export const statusOfTask = (task: Task): string => {
  if (task) {
    if (task.externalSystem === "jira") {
      const status = task.status.toLocaleLowerCase();
      return localize(jiraStatusKeys[status]) || task.status;
    } else if (!task.externalSystem) {
      return localize(gtmhubStatusKeys[task.status]) || "...";
    }
  }
  return "";
};
