import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiTooltipDirective } from "@quantive/ui-kit/tooltip";
import { NgStyleInterface } from "ng-zorro-antd/core/types";
import { localize } from "@gtmhub/localization";
import { SuggestionDrawerIndicatorStatePropagator } from "@webapp/platform-intelligence/expandable-suggestions/suggestion-drawer/shared/utils/state-propagator";
import { PIContextualSuggestionButtonParentType } from "@webapp/platform-intelligence/shared/components/pi-contextual-suggestion-button/models/pi-contextual-suggestion-button.models";
import { PiContextualSuggestionsOnboardingService } from "@webapp/platform-intelligence/shared/components/pi-contextual-suggestion-button/services/pi-contextual-suggestions-onboarding.service";
import {
  PI_CONTEXTUAL_SUGGESTIONS_ONBOARDING_TOTAL_STEPS,
  PiContextualSuggestionsOnboardingStepsEnum,
} from "@webapp/platform-intelligence/shared/components/pi-contextual-suggestion-button/utils/pi-contextual-suggestions-onboarding.utils";
import { PiStateProcessorService } from "@webapp/platform-intelligence/shared/components/pi-feedback-card/services/state-processor/pi-state-processor.service";
import { IOnboardingDefinition } from "@webapp/shared/components/onboarding/onboarding.models";
import { LowToHighScaleType } from "../shared/models/quantive-plus-effectiveness.models";
import {
  SUGGESTION_STATE_INDICATOR_ONBOARDING_STARTING_STEP,
  SUGGESTION_STATE_INDICATOR_ONBOARDING_TOTAL_STEPS,
  SuggestionStateIndicatorOnboardingType,
} from "./models/suggestion-state-indicator-onboarding.models";
import {
  SUGGESTION_STATE_INDICATOR_BACKGROUND_CLASSES,
  SUGGESTION_STATE_INDICATOR_ICONS,
  SUGGESTION_STATE_INDICATOR_TOOLTIP_CUSTOM_STYLE,
  SUGGESTION_STATE_INDICATOR_TOOLTIP_MSG,
} from "./models/suggestion-state-indicator.models";
import { SuggestionStateIndicatorOnboardingService } from "./services/suggestion-state-indicator-onboarding.service";

@UntilDestroy()
@Component({
  selector: "pi-suggestion-state-indicator",
  templateUrl: "./suggestion-state-indicator.component.html",
  styleUrls: ["./suggestion-state-indicator.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionStateIndicatorComponent implements OnInit {
  @Input()
  public statePropagator: SuggestionDrawerIndicatorStatePropagator;
  @Input()
  public isSelected = false;
  @Input()
  public onboardingType: SuggestionStateIndicatorOnboardingType = "suggestionStateIndicatorDefault";

  @Output()
  public readonly suggestionStateIndicatorToggle = new EventEmitter<boolean>();
  @Output()
  public readonly suggestionStateIndicatorError = new EventEmitter();

  public onboarding: IOnboardingDefinition;
  public contextualSuggestionsOnboarding: IOnboardingDefinition;
  public isLoading = false;
  public suggestionStateLevel: LowToHighScaleType;
  public hasRequiredDependencies = true;
  public isSuggestionAvailable = true;
  public suggestionStateIndicatorHeight = 32;

  public indicatorTooltipCustomStyle: NgStyleInterface = {
    right: SUGGESTION_STATE_INDICATOR_TOOLTIP_CUSTOM_STYLE.xPosCorrection,
  };

  constructor(
    private suggestionStateIndicatorOnboardingService: SuggestionStateIndicatorOnboardingService,
    private piContextualSuggestionsOnboardingService: PiContextualSuggestionsOnboardingService,
    private piStateProcessorService: PiStateProcessorService,
    private cd: ChangeDetectorRef
  ) {}

  public get backgroundClass(): string {
    if (this.isLoading) return SUGGESTION_STATE_INDICATOR_BACKGROUND_CLASSES.loading;

    if (!this.isSuggestionAvailable) return SUGGESTION_STATE_INDICATOR_BACKGROUND_CLASSES.inapplicable;

    if (!this.hasRequiredDependencies) return SUGGESTION_STATE_INDICATOR_BACKGROUND_CLASSES.default;

    if (!this.suggestionStateLevel) return SUGGESTION_STATE_INDICATOR_BACKGROUND_CLASSES.available;

    return SUGGESTION_STATE_INDICATOR_BACKGROUND_CLASSES[this.suggestionStateLevel];
  }

  public get tooltip(): string {
    if (this.isLoading) return SUGGESTION_STATE_INDICATOR_TOOLTIP_MSG.loading;

    if (this.isSelected) return SUGGESTION_STATE_INDICATOR_TOOLTIP_MSG.selected;

    if (!this.isSuggestionAvailable && !this.isSelected) return SUGGESTION_STATE_INDICATOR_TOOLTIP_MSG.inapplicable;

    if (!this.hasRequiredDependencies) return SUGGESTION_STATE_INDICATOR_TOOLTIP_MSG.default;

    if (!this.suggestionStateLevel) return SUGGESTION_STATE_INDICATOR_TOOLTIP_MSG.available;

    return SUGGESTION_STATE_INDICATOR_TOOLTIP_MSG[this.suggestionStateLevel];
  }

  public get icon(): string {
    if (this.isLoading) {
      return SUGGESTION_STATE_INDICATOR_ICONS.loading;
    }

    return SUGGESTION_STATE_INDICATOR_ICONS.default;
  }

  public ngOnInit(): void {
    if (this.onboardingType === "suggestionStateIndicatorDefault") {
      this.initializeOnboarding();
    } else {
      this.initializeContextualSuggestionsOnboarding();
    }

    this.subscribeToStateChanges();
  }

  private subscribeToStateChanges(): void {
    if (!this.statePropagator) return;

    this.statePropagator.subscribeToStateChanges$().subscribe(({ isLoading, suggestionStateLevel, hasRequiredDependencies, isSuggestionAvailable }) => {
      this.isLoading = isLoading;
      this.suggestionStateLevel = suggestionStateLevel;
      this.hasRequiredDependencies = hasRequiredDependencies;
      this.isSuggestionAvailable = isSuggestionAvailable;

      this.cd.detectChanges();
    });
  }

  private initializeOnboarding(): void {
    this.suggestionStateIndicatorOnboardingService
      .getState$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.onboarding = {
          shouldShow: false,
          startingStep: SUGGESTION_STATE_INDICATOR_ONBOARDING_STARTING_STEP,
          totalSteps: SUGGESTION_STATE_INDICATOR_ONBOARDING_TOTAL_STEPS,
          delegate: {
            shouldSeeOnboarding: () => this.onboarding.shouldShow,
            getRedirectStateForStep: () => null,
            journeyCompleted: () => this.suggestionStateIndicatorOnboardingService.completeJourney(),
            getPrimaryActionForStep: (step, baseActions) => ({
              primaryCTAHandler: (): void => {
                baseActions.gotIt();
                this.handleSuggestionStateIndicatorToggle();
              },
              primaryCTA: localize("try_it_out_cap"),
            }),
          },
        };

        window.setTimeoutOutsideAngular(() => {
          this.onboarding.shouldShow = this.suggestionStateIndicatorOnboardingService.shouldSeeJourneyAccordingToMetadata();

          if (this.onboarding.shouldShow) {
            this.suggestionStateIndicatorOnboardingService.markAsShown();
          }

          this.cd.detectChanges();
        }, 1600);
      });
  }

  public onButtonClick(tooltipDirective: UiTooltipDirective): void {
    tooltipDirective.hide();
    this.handleSuggestionStateIndicatorToggle();
  }

  private handleSuggestionStateIndicatorToggle(): void {
    if (this.onboarding?.shouldShow) {
      this.suggestionStateIndicatorOnboardingService.completeJourney();
      this.onboarding.shouldShow = false;
    }

    if (this.contextualSuggestionsOnboarding?.shouldShow) {
      this.piContextualSuggestionsOnboardingService.completeJourney();
      this.contextualSuggestionsOnboarding.shouldShow = false;
    }

    this.isSelected = !this.isSelected;

    if (!this.isSelected) {
      this.piStateProcessorService.collapseAllInstances();
    }

    this.suggestionStateIndicatorToggle.emit(this.isSelected);
  }

  private initializeContextualSuggestionsOnboarding(): void {
    this.piContextualSuggestionsOnboardingService
      .getJourneyState$()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (onboardingState) => {
          const suggestionStateIndicatorStep = PiContextualSuggestionsOnboardingStepsEnum.suggestionStateIndicator;

          this.contextualSuggestionsOnboarding = {
            shouldShow: false,
            totalSteps: PI_CONTEXTUAL_SUGGESTIONS_ONBOARDING_TOTAL_STEPS,
            startingStep: suggestionStateIndicatorStep,
            delegate: {
              shouldSeeOnboarding: () => this.contextualSuggestionsOnboarding.shouldShow,
              getPrimaryActionForStep: () => this.piContextualSuggestionsOnboardingService.getPrimaryActionForStep(suggestionStateIndicatorStep),
              getSecondaryActionForStep: () => this.piContextualSuggestionsOnboardingService.getSecondaryActionForStep(suggestionStateIndicatorStep),
              getRedirectStateForStep: () => null,
              journeyClosed: () => this.piContextualSuggestionsOnboardingService.closeJourney(onboardingState),
            },
          };

          const isSuggestionStateIndicatorStep = onboardingState.currentStep === suggestionStateIndicatorStep;
          const parentType: PIContextualSuggestionButtonParentType = this.onboardingType === "piGoalContextualSuggestions" ? "goal" : "metric";
          const isParentContextMatching = onboardingState.parentContext === parentType;
          const isEndedByUser = onboardingState.stage === "ENDED_BY_USER";
          const isCompleted = onboardingState.isCompleted;

          if (!isSuggestionStateIndicatorStep || !isParentContextMatching || isEndedByUser || isCompleted) {
            return;
          }

          this.contextualSuggestionsOnboarding = {
            ...this.contextualSuggestionsOnboarding,
            shouldShow: true,
          };

          this.cd.markForCheck();
        },
      });
  }

  public ensureContextualSuggestionsOnboardingIsInViewport(args: { isVisible: boolean; suggestionStateIndicatorBtn: HTMLButtonElement }): void {
    if (!args.isVisible) {
      this.piContextualSuggestionsOnboardingService.setCurrentElShowingOnboarding(args.suggestionStateIndicatorBtn);
    }
  }
}
