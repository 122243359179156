import { Pipe, PipeTransform } from "@angular/core";
import { formatDateForPipes } from "@webapp/shared/utils/date";

@Pipe({
  name: "dayjsDate",
})
export class DayjsDatePipe implements PipeTransform {
  // eslint-disable-next-line @foxglove/no-boolean-parameters
  public transform(input: string, format?: string, local?: boolean): string {
    if (!input) {
      return;
    }

    const date = local ? input.replace(".000Z", "") : input;

    return formatDateForPipes(date, format);
  }
}
