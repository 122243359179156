import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "pi-feedback",
  templateUrl: "./pi-feedback.component.html",
  styleUrls: ["./pi-feedback.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiFeedbackComponent {
  public userFeedback: string;
}
