import { Injectable } from "@angular/core";
import { getCurrentUserId } from "@gtmhub/users";
import { AssigneesFacade } from "@webapp/assignees/services/assignees/assignees-facade.service";
import { Comment } from "@webapp/comments";
import { MENTION_PATTERN } from "@webapp/shared/rich-text-editor/rich-text-editor.utils";
import { TeamsFacade } from "@webapp/teams/services/teams-facade.service";

interface RemovableMentions {
  initialMentions?: string[];
  comments?: Comment[];
}

const regex = new RegExp(MENTION_PATTERN, "g");

@Injectable()
export class InitialMentionsService {
  constructor(
    private assigneesFacade: AssigneesFacade,
    private teamsFacade: TeamsFacade
  ) {}

  public getRemovableMentions({ initialMentions = [], comments = [] }: RemovableMentions): string[] {
    const assigneeMentions = this.getAssigneeMentions(initialMentions) || [];
    const commentMentions = this.getCommentMentions(comments) || [];
    const uniqueMentions = this.getUniqueMentions(assigneeMentions, commentMentions);
    const currentUserId = getCurrentUserId();

    return uniqueMentions.filter((mention) => mention !== currentUserId);
  }

  private getUniqueMentions(assigneeMentions: string[], commentMentions: string[]): string[] {
    return Array.from(new Set<string>([...assigneeMentions, ...commentMentions].filter((mention) => mention)));
  }

  private getAssigneeMentions(initialMentions: string[]): string[] {
    const assignees = this.assigneesFacade.getAssigneesIdMap();
    const teams = this.teamsFacade.getTeamsIdMap();

    return initialMentions?.reduce((ids, id) => {
      const assignee = assignees[id];

      if (assignee?.isActive) {
        const isTeam = assignee.type === "team";
        const team = isTeam ? teams[id] : null;

        if (isTeam && team) {
          ids.push(...(team.members ? team.members : []));
          ids.push(...(team.manager ? [team.manager] : []));
        } else {
          ids.push(id);
        }
      }

      return ids;
    }, []);
  }

  private getCommentMentions(comments: Comment[]): string[] {
    return comments?.reduce((ids, comment) => {
      const assignees = this.assigneesFacade.getAssigneesIdMap();
      const matches = comment.text?.matchAll(regex) || [];
      for (const match of matches) {
        // a match is an array of the following:
        // [whole matched string, name, email, id]
        const id = match[3];
        if (comment.mentioned && comment.mentioned.includes(id) && assignees[id]?.isActive) {
          ids.push(id);
        }
      }

      ids.push(comment.createdBy);

      return ids;
    }, []);
  }
}
