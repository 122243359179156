import { IAttributes, IAugmentedJQuery, ICompileService, IDirective, IScope } from "angular";
import { IIndicatorMap } from "@gtmhub/error-handling";

interface GlobalIndicatorScope extends IScope {
  indicators: IIndicatorMap;
  resetIndicatorByName(indicatorName: string): void;
  $ctrl: {
    indicators: IIndicatorMap;
  };
}

const getIndicatorName = (element: HTMLElement): string => {
  const oneWayBindingString = "[gh-value]",
    twoWayBindingString = "[(gh-value)]";
  if (element.attributes[oneWayBindingString]) {
    return element.attributes[oneWayBindingString]?.value;
  } else if (element.attributes[twoWayBindingString]?.value) {
    return element.attributes[twoWayBindingString]?.value;
  } else {
    return "";
  }
};

/*
 * This Directive will trigger $digest() when indicator error is cleared
 *
 * Add subscription '(errorReset)="resetIndicatorByName(indicatorName)"' for these indicators, that are used in Ajs and not subscribed already for onReset.
 */
export class IndicatorCD implements IDirective {
  public readonly restricted = "E";
  public readonly priority = 1001;

  constructor(
    private $compile: ICompileService,
    private $parse
  ) {}

  public compile(element: IAugmentedJQuery, attributes: IAttributes) {
    if (!attributes["onErrorReset"] && !attributes["(errorReset)"]) {
      element.attr("on-error-reset", `resetIndicatorByName('${getIndicatorName(element[0])}')`);
      return ($scope: GlobalIndicatorScope, $elem: IAugmentedJQuery) => {
        $scope.resetIndicatorByName = (indicatorName: string): void => {
          const getter = this.$parse(indicatorName);
          const setter = getter.assign;

          setter($scope, undefined);

          $scope.$evalAsync();
        };

        this.$compile($elem)($scope);
      };
    }
  }

  public static factory() {
    const factory = ($compile: ICompileService, $parse) => new IndicatorCD($compile, $parse);
    factory.$inject = ["$compile", "$parse"];
    return factory;
  }
}
