import { Component, Input } from "@angular/core";
import { DropdownComponent } from "@webapp/shared/dropdown/dropdown.component";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";

/**
 * @example <navigation-dropdown [menuItems]="this.contextMenuItems"><span gh-dropdown-toggle class="gh-font-icon-menu"></span></gh-dropdown>
 */
@Component({
  selector: "navigation-dropdown",
  templateUrl: "./navigation-dropdown.component.html",
  styleUrls: ["./navigation-dropdown.component.less"],
})
export class NavigationDropdownComponent extends DropdownComponent {
  @Input()
  public dropdownTitle: string;

  @Input() public isNavigationDropdownButtonClassNeeded = true;

  constructor() {
    super();
  }

  public handleItemClick(item: DropdownMenuItem): void {
    if (!item.disabled) {
      this.visible = false;
      this.onVisibleChange(this.visible);
    }
  }
}
