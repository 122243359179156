import { toIdMap } from "@gtmhub/util";
import { IRolesState, RolesAction, RolesActionsEnum } from "./models";

export const rolesReducer = (
  state: IRolesState = {
    isFetched: false,
    isFetching: false,
    items: [],
    map: {},
    error: null,
  },
  action: RolesAction
): IRolesState => {
  switch (action.type) {
    case RolesActionsEnum.REQUEST_ROLES:
      return { ...state, isFetching: true };
    case RolesActionsEnum.RECEIVE_ROLES:
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        items: action.items,
        map: toIdMap(action.items),
      };
    case RolesActionsEnum.RECEIVE_ROLES_ERROR:
      return { ...state, isFetching: false, error: action.error };
    case RolesActionsEnum.ADD_ROLE: {
      const updatedRoles = [...state.items, action.role];

      return {
        ...state,
        isFetched: true,
        items: updatedRoles,
        map: toIdMap(updatedRoles),
      };
    }
    case RolesActionsEnum.UPDATE_ROLE: {
      if (!state.isFetched) {
        return state;
      }

      state.items = state.items.map((item) => Object.assign(item, item.id === action.role.id ? action.role : {}));
      state.map[action.role.id] = Object.assign(state.map[action.role.id] || {}, action.role);
      return { isFetched: true, isFetching: false, items: state.items, map: state.map, error: null };
    }
    case RolesActionsEnum.REMOVE_ROLE: {
      const filteredRoles = state.items.filter((role) => role.id !== action.roleId);

      return {
        ...state,
        isFetched: true,
        items: filteredRoles,
        map: toIdMap(filteredRoles),
      };
    }
    default:
      return state;
  }
};
