import { NgModule } from "@angular/core";
import { MaxStringLengthDirective } from "@webapp/ui/input/directives/max-string-length.directive";
import { UiInputGroupSlotComponent } from "./components/input-group-slot/input-group-slot.component";
import { UiInputGroupComponent } from "./components/input-group/input-group.component";
import { UiInputTextAreaComponent } from "./components/input-text-area/input-text-area.component";
import { UiInputTextFieldComponent } from "./components/input-text-field/input-text-field.component";
import { UiValidationHintComponent } from "./components/validation-hint/validation-hint.component";
import { UiAutosizeDirective } from "./directives/autosize.directive";
import { UiInputGroupWhitSuffixOrPrefixDirective } from "./directives/input-group-with-suffix-or-prefix.directive";
import { UiInputDirective } from "./input.directive";
import { TextareaValidatorFactory } from "./utils/textarea-validator.factory";

@NgModule({
  imports: [
    UiAutosizeDirective,
    UiInputDirective,
    UiInputGroupWhitSuffixOrPrefixDirective,
    UiInputGroupComponent,
    UiInputGroupSlotComponent,
    UiInputTextFieldComponent,
    UiInputTextAreaComponent,
    UiValidationHintComponent,
    MaxStringLengthDirective,
  ],
  providers: [TextareaValidatorFactory],
  exports: [
    UiAutosizeDirective,
    UiInputDirective,
    UiInputGroupWhitSuffixOrPrefixDirective,
    UiInputGroupComponent,
    UiInputTextFieldComponent,
    UiInputTextAreaComponent,
    UiValidationHintComponent,
  ],
})
export class UiInputModule {}
