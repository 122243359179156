import { NgModule } from "@angular/core";
import { UiDateHeaderComponent } from "./components/date-header/date-header.component";
import { UiDateTableComponent } from "./components/date-table/date-table.component";
import { UiDecadeHeaderComponent } from "./components/decade-header/decade-header.component";
import { UiDecadeTableComponent } from "./components/decade-table/decade-table.component";
import { UiMonthHeaderComponent } from "./components/month-header/month-header.component";
import { UiMonthTableComponent } from "./components/month-table/month-table.component";
import { UiYearHeaderComponent } from "./components/year-header/year-header.component";
import { UiYearTableComponent } from "./components/year-table/year-table.component";

@NgModule({
  imports: [
    UiDateHeaderComponent,
    UiDateTableComponent,
    UiDecadeHeaderComponent,
    UiDecadeTableComponent,
    UiMonthHeaderComponent,
    UiMonthTableComponent,
    UiYearHeaderComponent,
    UiYearTableComponent,
  ],
  exports: [
    UiDateHeaderComponent,
    UiDateTableComponent,
    UiDecadeHeaderComponent,
    UiDecadeTableComponent,
    UiMonthHeaderComponent,
    UiMonthTableComponent,
    UiYearHeaderComponent,
    UiYearTableComponent,
  ],
})
export class UiLibPackerModule {}
