import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { IAccount } from "@gtmhub/core";
import { IPlan, PricingEditionService } from "@gtmhub/edition-plan-change";
import { ISubscriptionConverted } from "@gtmhub/login/models";
import { AccountResolverService } from "@webapp/accounts";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";

export enum UpgradeButtonState {
  STATIC = "static",
  TRIAL = "trial",
}

const gtmhubScaleV2 = "gtmhub-scale-v2";

@Injectable()
export class BillingInfoService {
  private secondsLeft: number;
  private timeLeft: string;
  private account: IAccount;
  private isAccountTrial: boolean;
  private isDemoAccount: boolean;
  private isInternalAccount$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private shouldShowUpgrade$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private availableUpgradePlans: IPlan[];
  private accountTypes: {
    trial: number;
    client: number;
    internal: number;
    demo: number;
  };
  private upgradeButtonState: UpgradeButtonState;
  private isAccountConverted: boolean;

  constructor(
    private broadcastService: BroadcastService,
    private accountResolverService: AccountResolverService,
    private priceEditionService: PricingEditionService
  ) {
    this.accountTypes = {
      trial: 0,
      client: 1,
      internal: 2,
      demo: 3,
    };

    this.account = this.accountResolverService.getAccountData();

    if (this.account.subscriptions.length) {
      this.isAccountConverted = this.account.subscriptions.find((license) => license.type === "regular").isConverted;
    }

    this.isAccountTrial = this.account.type === this.accountTypes.trial && !this.isAccountConverted;
    this.isDemoAccount = this.account.type === this.accountTypes.demo;
    this.isInternalAccount$.next(this.account.type === this.accountTypes.internal);

    this.checkExpiration();
    this.upgradeButtonState = this.setUpgradeButtonState();

    this.broadcastService.on("subscriptionConverted").subscribe((subscriptionConverted: ISubscriptionConverted) => this.subscriptionConverted(subscriptionConverted));

    this.priceEditionService.getAllowedTransitions(this.account.type).then((plans) => {
      this.availableUpgradePlans = plans;
      this.shouldShowUpgrade$.next(this.showUpgradePlanButton());
    });
  }

  public getAccountIsInternal$(): BehaviorSubject<boolean> {
    return this.isInternalAccount$;
  }

  public getUpgradeShouldBeShown$(): Observable<boolean> {
    return this.shouldShowUpgrade$.asObservable();
  }

  public getUpgradeButtonState(): UpgradeButtonState {
    return this.upgradeButtonState;
  }

  public getTimeLeft(): string {
    return this.timeLeft;
  }

  private showUpgradePlanButton(): boolean {
    if (!this.availableUpgradePlans?.length) {
      return false;
    }

    if (this.account.saasSubscriptionId && this.account.subscriptionProvider === "azure") {
      return false;
    }

    return !this.isDemoAccount && !this.isInternalAccount$.value && (this.account.edition.name !== gtmhubScaleV2 || this.isAccountTrial);
  }

  private setUpgradeButtonState(): UpgradeButtonState {
    if (this.account.edition.name === "gtmhub-essential") {
      return UpgradeButtonState.STATIC;
    }

    return this.secondsLeft > 0 ? UpgradeButtonState.TRIAL : UpgradeButtonState.STATIC;
  }

  private checkExpiration(): void {
    const trialExpiration = this.accountResolverService.checkExpirationOfTrialAccount();
    this.secondsLeft = trialExpiration.secondsLeft;
    this.timeLeft = trialExpiration.timeLeft;
  }

  private subscriptionConverted(subscriptionConverted: ISubscriptionConverted): void {
    if (this.account.id === subscriptionConverted.accountId) {
      const subscriptionTypeRegular = subscriptionConverted.subscriptions.find((license) => license.type === "regular");
      this.isAccountTrial = !subscriptionTypeRegular.isConverted;
      this.shouldShowUpgrade$.next(this.showUpgradePlanButton());
    }
  }
}
