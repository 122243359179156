import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { APP_CONFIG, IAppConfig } from "@gtmhub/env";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { KpiSnapshot, KpiSnapshotDTO } from "@webapp/kpis/models/kpi-snapshot.models";
import { KpisState } from "../kpis-state.service";
import { KpiSnapshotsState } from "./kpi-snapshots-state.service";

@Injectable({
  providedIn: "any",
})
export class KpiSnapshotsApiService extends BaseApiService<KpiSnapshot, KpiSnapshotDTO, KpiSnapshotsState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: KpisState) {
    super("kpis", { ...new ApiVersionModel("v1"), getAll: "v2" }, httpClient, appConfig, state);
  }

  public getCreateKpiSnapshotEndpoint(kpiId: string): string {
    return `${this.getBasePath(HttpActions.post)}/${kpiId}/snapshots`;
  }

  public getGetKpisSnapshotsEndpoint(): string {
    return `${this.getBasePath(HttpActions.getAll)}/snapshots`;
  }

  public getDeleteSnapshotEndpoint(kpiId: string, snapshotId: string): string {
    return `${this.getBasePath(HttpActions.delete)}/${kpiId}/snapshots/${snapshotId}`;
  }

  public getGetKpisSnapshotsEndpointV1(): string {
    return `${this.getBasePath(HttpActions.getAll, { apiVersionOverwrite: "v1" })}/snapshots`;
  }
}
