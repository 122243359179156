import { IScope } from "angular";
import { IModalStackService } from "angular-ui-bootstrap";
import { StateService } from "@uirouter/angularjs";
import { firstValueFrom } from "rxjs";
import { AccountService } from "@gtmhub/accounts/accounts.service";
import { AuthenticationResolverService } from "@gtmhub/auth";
import { IAccount } from "@gtmhub/core";
import { storage } from "@gtmhub/core/storage/storage";
import { EnvironmentService } from "@gtmhub/env";
import { IIndicator, IUIError, createUIError } from "@gtmhub/error-handling";
import { IGtmhubRootScopeService } from "@gtmhub/models";
import { IUser, setCurrentUserId } from "@gtmhub/users";
import { AccountResolverService } from "@webapp/accounts";
import dayjs from "@webapp/shared/libs/dayjs";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";
import { CurrentUserRepository } from "@webapp/users";
import { UsersFacade } from "@webapp/users/services/users-facade.service";
import { IUserAccountRequest } from "../models";

interface ILoginAccountScope extends IScope {
  lastName: string;
  firstName: string;
  userDisplayName: string;
  accountDomain: string;
  errMessage: string;
  errors: {
    account?: IUIError;
  };
  accountName: string;
  isDomainManuallyChanged: boolean;
  submitted: boolean;
  indicators: {
    addingAccount?: IIndicator;
  };
  dcPrefix: string;
  addAccount(form): void;
  domainChanged(): void;
  cancel(): void;
}

export class CreateAccountController {
  public static $inject = [
    "$rootScope",
    "$scope",
    "CurrentUserRepository",
    "$state",
    "$uibModalStack",
    "AccountService",
    "AuthenticationResolverService",
    "AccountResolverService",
    "UserProfileService",
    "EnvironmentService",
    "UsersFacade",
  ];

  constructor(
    private $rootScope: IGtmhubRootScopeService,
    private $scope: ILoginAccountScope,
    private currentUserRepository: CurrentUserRepository,
    private $state: StateService,
    private $uibModalStack: IModalStackService,
    private accountService: AccountService,
    private authenticationResolverService: AuthenticationResolverService,
    private accountResolverService: AccountResolverService,
    private profileService: UserProfileService,
    private env: EnvironmentService,
    private usersFacade: UsersFacade
  ) {
    this.$scope.submitted = false;
    this.$scope.indicators = {};
    this.$scope.isDomainManuallyChanged = false;
    this.$scope.accountName = "";
    this.$scope.firstName = "";
    this.$scope.lastName = "";
    this.$scope.accountDomain = "";
    this.$scope.errMessage = "";
    this.$scope.addAccount = this.addAccount;
    this.$scope.domainChanged = this.domainChanged;
    this.$scope.cancel = this.cancel;
    this.$scope.errors = {};

    this.$scope.dcPrefix = this.env.getDC();

    this.$scope.userDisplayName = this.authenticationResolverService.getUserDisplayNameFromIdToken();

    this.$scope.$watch("accountName", (value: string) => {
      if (!this.$scope.isDomainManuallyChanged && value !== "") {
        this.$scope.accountDomain = value === undefined ? "" : this.sanitizeUrl(value);
      }
    });

    this.$scope.$watch("accountDomain", (value: string) => {
      if (this.$scope.isDomainManuallyChanged) {
        this.$scope.accountDomain = this.sanitizeUrl(value);
      }
    });
  }

  private domainChanged = () => {
    if (!this.$scope.isDomainManuallyChanged) {
      this.$scope.isDomainManuallyChanged = true;
    }
  };

  private sanitizeUrl = (url: string) => {
    const maxDomainLength = 20;
    const cleanUrl = url
      .replace(/(^\\-+|[^a-zA-Z0-9\\/_| -]+|\\-+$)/g, "")
      .toLowerCase()
      .replace(/[\\/_| -]+/g, "-");
    return cleanUrl.substring(0, Math.min(cleanUrl.length, maxDomainLength));
  };

  private cancel = () => {
    this.authenticationResolverService.logout({ ssoLogoutIfEnabled: false });
  };

  private addAccount = (form) => {
    this.$scope.indicators.addingAccount = { progress: true };
    this.$scope.submitted = true;
    if (!form.$valid) {
      delete this.$scope.indicators.addingAccount;
      return;
    }

    const userNames = {
      firstName: form.firstName.$viewValue,
      lastName: form.lastName.$viewValue,
    };

    this.$scope.errMessage = "";
    this.$scope.errors = {};

    this.accountService.checkAccountDomain({ accountName: this.$scope.accountName, domain: this.$scope.accountDomain }).then(
      () => {
        this.addUserToAccount(userNames);
      },
      (rejection) => {
        const err = createUIError(rejection);
        if (err.body.split("Account").length > 1) {
          this.$scope.errors.account = err;
        } else {
          this.$scope.errMessage = err.body;
        }

        delete this.$scope.indicators.addingAccount;
      }
    );
  };

  private addUserToAccount = (userNames: { firstName: string; lastName: string }) => {
    const edition = localStorage.getItem("chosenEdition");
    const planId = localStorage.getItem("chosenPlanId");
    const timezone = `UTC${dayjs().format("Z")}`.replace(":00", "").replace("+0", "+").replace("-0", "-");
    const userAccountData: IUserAccountRequest = {
      accountName: this.$scope.accountName,
      accountDomain: this.$scope.accountDomain,
      createAccount: true,
      clientId: this.authenticationResolverService.getAuthUserId(),
      edition: edition,
      planId: planId,
      givenName: userNames.firstName,
      familyName: userNames.lastName,
      timezone: timezone,
    };

    firstValueFrom(this.usersFacade.ensureUserAccount$(userAccountData)).then(
      (user: IUser) => {
        this.profileService.setProfileFromUser(user);
        this.currentUserRepository.setUserSettings(user);
        this.accountResolverService.setAccountId(user.accountId);
        this.accountService
          .getAccount(user.accountId)
          .then(
            (account: IAccount) => {
              setCurrentUserId(user.id);
              this.accountResolverService.setAccountData(account);
              this.$rootScope.$broadcast("accountCreated", { account });
            },
            () => {
              storage.remove("accountId");
            }
          )
          .finally(() => {
            const featureState = this.accountResolverService.getFeatureStateName();
            if (featureState) {
              this.$state.go(featureState, null, { reload: true });
            } else {
              this.$state.go("gtmhub.home", null, { reload: true });
            }
            this.$uibModalStack.dismissAll();
          });
      },
      (err) => {
        this.$scope.errMessage = err.message;
      }
    );
  };
}
