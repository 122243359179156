import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { identity } from "rxjs";
import { IAssigneesStoreState } from "@gtmhub/assignees";
import { localize } from "@gtmhub/localization";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { sortAssigneeIdsByActiveStatus } from "@webapp/assignees/utils/assignee.utils";
import { AggregatedMentions } from "@webapp/comments";
import { Gif } from "../components/gifs/gif.models";

export interface NoteWithGifAndMentions {
  note?: string;
  gif?: Gif;
  mentions?: AggregatedMentions;
}

@Component({
  selector: "rich-text-editor-with-mentions",
  templateUrl: "./rich-text-editor-with-mentions.component.html",
  styleUrls: ["./rich-text-editor-with-mentions.component.less"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RichTextEditorWithMentionsComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextEditorWithMentionsComponent implements ControlValueAccessor {
  public onChange = identity;
  public value: NoteWithGifAndMentions;

  @Input() public uiId: string;
  @Input() public useGifs = true;
  @Input() public useMentions = true;
  @Input() public useSubmitButton = false;
  @Input() public readonly = false;
  @Input() public disabled = false;
  @Input() public a11yLabelledby: string;
  @Input() public placeholder: string;
  @Input() public removeAllMentionsAriaLabel = `${localize("remove_all")} ${localize("mentions")}`;
  @Input() public focusMe = false;
  /**
   * Makes the input borderless and collapses the input when out of focus
   */
  @Input() public inline: boolean;
  @Input() public postButtonA11yLabel: string;

  @Output() public readonly editorBlur = new EventEmitter<void>();
  @Output() public readonly editorFocus = new EventEmitter<void>();

  constructor(private cdr: ChangeDetectorRef) {
    this.value = {
      note: null,
    };

    if (this.useGifs) {
      this.value.gif = null;
    }

    if (this.useMentions) {
      this.value.mentions = {
        removable: [],
        nonRemovable: [],
      };
    }
  }

  public writeValue(value: NoteWithGifAndMentions): void {
    this.value = value;
    const assigneesItems: Record<string, Assignee> = reduxStoreContainer.reduxStore.getState<IAssigneesStoreState>().assignees.map;
    if (this.value?.mentions && this.value?.mentions?.removable?.length > 0) {
      this.value.mentions.removable = sortAssigneeIdsByActiveStatus(this.value.mentions.removable, assigneesItems);
    }
    if (this.value?.mentions && this.value?.mentions?.nonRemovable?.length > 0) {
      this.value.mentions.nonRemovable = sortAssigneeIdsByActiveStatus(this.value.mentions.nonRemovable, assigneesItems);
    }
    this.cdr.markForCheck();
  }

  public registerOnChange(fn: typeof identity): void {
    this.onChange = fn;
  }

  public registerOnTouched(): void {
    // required by ControlValueAccessor
  }

  public setNote(note: string): void {
    this.value.note = note;
    this.onChange(this.value);
  }

  public setGif(gif: Gif): void {
    this.value.gif = gif;
    this.onChange(this.value);
  }

  public removeMention(mentionId: string): void {
    this.value.mentions.removable = this.value.mentions.removable.filter((mention) => mention !== mentionId);
    this.onChange(this.value);
  }

  public removeAllMentions(): void {
    this.value.mentions.removable = [];
    this.onChange(this.value);
  }

  public onCommentMentionsChanged(mentions: string[]): void {
    if (!this.useMentions) {
      return;
    }

    this.value.mentions.nonRemovable = mentions;

    // removes duplicated mention IDs from the removable mentions array
    const nonRemovable = new Set(this.value.mentions.nonRemovable);
    this.value.mentions.removable = (this.value.mentions.removable || []).filter((mentionId) => !nonRemovable.has(mentionId));

    this.onChange(this.value);
  }
}
