export type TrackingMetadata = Record<string, unknown>;

export type AnalyticsIntegration = { [key: string]: boolean };

export interface IAnalyticsPageOptions {
  path?: string;
  controller?: string;
}

export interface IAnalyticsPagePayload {
  controller: string;
  ["location_hash"]: string;
  path: string;
  ["gtmhub_application_name"]: string;
  ["gtmhub_path"]: string;
  title: "";
}

export interface IRecommendationArgs {
  isUseful: boolean;
}

export enum Flavor {
  PASSIVE = "Passive",
  ACTIVE = "Active",
  SMART_DEFAULT = "Smart Default",
}

export const recommendation = "Recommendation Given";

export enum AnalyticsKeyboardEvent {
  KEYPRESS = "keypress",
  KEYDOWN = "keydown",
  KEYUP = "keyup",
}

export enum AnalyticsAction {
  ENABLE_DRAGGABLE = "Enable draggable mode",
  ENABLE_POINTER = "Enable pointer mode",
}
