import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { localize } from "@gtmhub/localization";
import { InsightboardsActions } from "./toast-error-utils-insightboards";

enum CustomHttpStatusCode {
  DeniedError = -1,
  ConnectionError = 0,
}

export interface ErrorObject {
  title: string;
  message: string;
}

const statusMessageObjectMap: { [key: string]: ErrorObject } = {
  [CustomHttpStatusCode.DeniedError]: {
    title: "denied_error_title",
    message: "denied_error_message",
  },
  [CustomHttpStatusCode.ConnectionError]: {
    title: "connection_error_title_1",
    message: "connection_error_message_1",
  },
  [HttpStatusCode.BadRequest]: {
    title: "bad_request_error_title",
    message: "bad_request_error_message",
  },
  [HttpStatusCode.Unauthorized]: {
    title: "unauthorized_error_title",
    message: "unauthorized_error_message",
  },
  [HttpStatusCode.PaymentRequired]: null, // when account is suspended, it is handled by another interceptor
  [HttpStatusCode.Forbidden]: {
    title: "forbidden_error_title",
    message: "forbidden_error_message",
  },
  [HttpStatusCode.NotFound]: {
    title: "not_found_error_title",
    message: "not_found_error_message",
  },
  [HttpStatusCode.Conflict]: {
    title: "conflict_error_title",
    message: "conflict_error_message",
  },
  [HttpStatusCode.PayloadTooLarge]: {
    title: "bad_request_error_title",
    message: "file_too_large",
  },
  [HttpStatusCode.Locked]: {
    title: "locked_error_title",
    message: "locked_error_message",
  },
  [HttpStatusCode.InternalServerError]: {
    title: "server_error_title",
    message: "server_error_message",
  },
};

const unexpectedErrorObject: ErrorObject = {
  title: "unexpected_error_title",
  message: "unexpected_error_message",
};

export const createErrorObject = (error: HttpErrorResponse): ErrorObject => {
  const errorObject = statusMessageObjectMap[error?.status] || unexpectedErrorObject;

  return {
    ...errorObject,
    title: `${localize(errorObject.title)}`,
    message: `${localize(errorObject.message)} ${error?.error}`,
  };
};

export type ErrorAction = InsightboardsActions;
