import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IOkrTemplate } from "@gtmhub/goals/models";
import { QueryParams } from "@webapp/core/abstracts/models/query-params.model";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { ICollection } from "@webapp/core/core.models";
import { MarketplaceInsightTemplateInstallRequest, MarketplaceInsightTemplateInstallResponse } from "@webapp/data-story/models/insights.models";
import { MarketplaceInsightTemplate, MarketplaceInsightTemplateDTO } from "@webapp/data-story/models/marketplace-facade.models";
import { MarketplaceApiService } from "@webapp/data-story/services/marketplace-facade/marketplace-api.service";
import { MarketplaceState } from "@webapp/data-story/services/marketplace-facade/marketplace-state.service";
import { IDirectSubmitInsightboard, IInstalledInsightboardTemplatesResponse, IMarketplaceInsightboardTemplate } from "@webapp/insightboards/models";
import { Goal } from "@webapp/okrs/goals/models/goal.models";

@Injectable({
  providedIn: "root",
})
export class MarketplaceFacade extends BaseFacade<MarketplaceInsightTemplate, MarketplaceInsightTemplateDTO, MarketplaceState, MarketplaceApiService> {
  constructor(state: MarketplaceState, api: MarketplaceApiService) {
    super(state, api);
  }

  public installMarketplaceInsightTemplates$(templates: MarketplaceInsightTemplateInstallRequest[]): Observable<MarketplaceInsightTemplateInstallResponse> {
    return this.post$<MarketplaceInsightTemplateInstallResponse>(templates, {
      ...new RequestConfig(),
      url: this.apiService.getMarketplaceInsightTemplateInstallEndpoint(),
    });
  }

  public submitToMarketplace$(
    goalId: string,
    options: {
      includeSubmitter: boolean;
      goalFieldsToUpdate: { name: string; metricsFieldsToUpdate?: { [id: string]: { name: string } } };
    }
  ): Observable<Goal> {
    const data = {
      includeSubmitter: options.includeSubmitter,
      goalFieldsToUpdate: options.goalFieldsToUpdate.metricsFieldsToUpdate ? options.goalFieldsToUpdate : { name: options.goalFieldsToUpdate.name },
    };

    return this.post$<Goal>(
      { ...data },
      {
        ...new RequestConfig(),
        url: this.apiService.getSubmitOkrToMarketplaceEndpoint(goalId),
      }
    );
  }

  public getFeaturedInsightboards$(): Observable<ICollection<IMarketplaceInsightboardTemplate>> {
    return this.getAll$<ICollection<IMarketplaceInsightboardTemplate>>(null, {
      ...new RequestConfig(),
      queryParams: { featured: true },
      url: this.apiService.getFeaturedInsightboardsEndpoint(),
    });
  }

  public installMarketplaceInsightboards$(insightboardIds: string[]): Observable<IInstalledInsightboardTemplatesResponse> {
    return this.post$({ ids: insightboardIds }, { url: this.apiService.getInstallMarketplaceInsightboardsEndpoint() });
  }

  public directSubmitInsightboard$(boardData: IDirectSubmitInsightboard): Observable<IMarketplaceInsightboardTemplate> {
    return this.post$<IMarketplaceInsightboardTemplate>(boardData, {
      ...new RequestConfig(),
      url: this.apiService.getDirectSubmitInsightboardEndpoint(),
    });
  }

  public getGoalTemplatesFromMarketplace$(params: {
    skip?: number;
    take?: number;
    text?: string;
    country?: string;
    tags?: string;
    partners?: string;
  }): Observable<ICollection<IOkrTemplate>> {
    return this.getAll$<ICollection<IOkrTemplate>>(null, {
      ...new RequestConfig(),
      queryParams: params,
      url: this.apiService.getMarketplaceEndpoint(),
    });
  }

  public submitInsightToMarketplace$(insightData: MarketplaceInsightTemplateDTO): Observable<MarketplaceInsightTemplate> {
    return this.post$<MarketplaceInsightTemplate>(insightData, {
      url: this.apiService.getSubmitMarketplaceInsightTemplateEndpoint(),
    });
  }

  public getMarketplaceInsightTemplates$(queryParams: QueryParams): Observable<ICollection<MarketplaceInsightTemplate>> {
    return this.getAll$<ICollection<MarketplaceInsightTemplate>>(null, {
      ...new RequestConfig(),
      queryParams: queryParams,
      url: this.apiService.getMarketplaceInsightTemplateEndpoint(),
    });
  }
}
