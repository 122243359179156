import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { IRole } from "@gtmhub/roles";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseApiServiceV2 } from "@webapp/core/api/base-api-v2.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";

@Injectable({
  providedIn: "root",
})
export class RolesApiService extends BaseApiServiceV2<IRole, RequestPaging> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig) {
    super("roles", { ...new ApiVersionModel("v1") }, httpClient, appConfig);
  }
  public getUpdateRoleEndpoint(roleId: string): string {
    return `${this.getBasePath(HttpActions.put)}/account/${roleId}`;
  }

  public createRoleEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}/account`;
  }

  public deleteRoleEndpoint(roleId: string): string {
    return `${this.getBasePath(HttpActions.delete)}/account/${roleId}`;
  }
}
