import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { ApmService } from "@gtmhub/core/tracing/apm.service";
import { getCurrentUserId } from "@gtmhub/users";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { KICKSTART_OKR_WIDGET_ID, WIDGET_MARGIN } from "@webapp/home/models/home-widgets.models";
import { getWidgetPosition } from "@webapp/home/services/home-widget-configuration.service";
import { HomeWidgetService } from "@webapp/home/services/home-widget.service";
import { UiDashboardElementEvent, UiDashboardNodesEvent, UiDashboardOptions, UiDashboardWidget } from "@webapp/ui/dashboard/dashboard.models";
import { nodeToWidget } from "./home-dashboard.utils";

@UntilDestroy()
@Component({
  selector: "home-dashboard",
  templateUrl: "./home-dashboard.component.html",
  styleUrls: ["./home-dashboard.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "new-layout-page",
  },
})
export class HomeDashboardComponent implements OnInit {
  public dashboardOptions: UiDashboardOptions = {
    margin: WIDGET_MARGIN,
    column: 2,
    cellHeight: 1,
    resizable: { handles: " " }, // disallow resizing using the mouse
  };

  public dashboardWidgets: UiDashboardWidget[] = [];
  private draggedWidgetId: string | null = null;
  public isLoading = true;

  public get showNoPermissions(): boolean {
    return !this.dashboardWidgets.length || (this.dashboardWidgets.length === 1 && this.dashboardWidgets[0].id === KICKSTART_OKR_WIDGET_ID);
  }

  constructor(
    private homeWidgetService: HomeWidgetService,
    private analyticsService: AnalyticsService,
    private cdr: ChangeDetectorRef,
    private apmService: ApmService
  ) {}

  public ngOnInit(): void {
    this.apmService.addLabelsToCurrentTransactions({ home: "new" });
    this.homeWidgetService
      .getDashboardWidgets$()
      .pipe(untilDestroyed(this))
      .subscribe((dashboardWidgets) => {
        if (equal(this.dashboardWidgets, dashboardWidgets)) {
          return;
        }
        this.dashboardWidgets = dashboardWidgets;
        this.isLoading = false;
        this.cdr.detectChanges();
      });
  }

  public onChange($event: UiDashboardNodesEvent): void {
    if (this.draggedWidgetId) {
      this.trackWidgetMoved($event.nodes.map(nodeToWidget));
      this.draggedWidgetId = null;
      this.homeWidgetService.handleWidgetsChange($event.nodes.map(nodeToWidget), { checkForY: true, persistChanges: true });
      return;
    }
    this.homeWidgetService.handleWidgetsChange($event.nodes.map(nodeToWidget), { checkForY: true });
  }

  public onDragStop($event: UiDashboardElementEvent): void {
    this.draggedWidgetId = $event.el.gridstackNode.id;
  }

  private trackWidgetMoved(changedWidgets: UiDashboardWidget[]): void {
    const oldPos = getWidgetPosition(this.draggedWidgetId, this.dashboardWidgets);
    const newPos = getWidgetPosition(this.draggedWidgetId, this.dashboardWidgets, changedWidgets);
    const widget = this.dashboardWidgets.find((widget) => widget.id === this.draggedWidgetId);
    const movedWidget = changedWidgets.find((widget) => widget.id === this.draggedWidgetId);

    if (oldPos !== newPos) {
      this.analyticsService.track("Widget Moved", {
        action: this.getWidgetMoveAction(widget, movedWidget),
        name: "home_customizable",
        entity: this.draggedWidgetId,
        starting_widget_position: oldPos,
        ending_widget_position: newPos,
        drag_and_drop: true,
        number_widgets: this.dashboardWidgets.length,
        user_id: getCurrentUserId(),
      });
    }
  }

  private getWidgetMoveAction(widget: UiDashboardWidget, movedWidget: UiDashboardWidget): string {
    let action = "";
    if (!movedWidget) {
      action = "move_down";
    } else {
      if (widget.y !== movedWidget.y) {
        action = movedWidget.y > widget.y ? "move_down" : "move_up";
      }

      if (widget.x !== movedWidget.x) {
        const horizontalMovement = movedWidget.x > widget.x ? "move_right" : "move_left";
        action = action ? `${action},${horizontalMovement}` : horizontalMovement;
      }
    }

    return action;
  }
}
