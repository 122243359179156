import { IModalScope, IModalService } from "angular-ui-bootstrap";
import { StateService } from "@uirouter/angularjs";
import { IAppConfig } from "@gtmhub/env";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { IErrorOptions } from "@webapp/shared/error-view/error-view.models";
import { IModalErrorOptions, IUIError } from "../models";
import { createUIError, logCaughtJsError } from "../util";

interface IErrorScope extends IModalScope {
  error: IUIError;
  errorOptions: IErrorOptions;
}

export class UIErrorHandlingService {
  public static $inject = ["$state", "$uibModal", "appConfig", "AnalyticsService"];

  constructor(
    private $state: StateService,
    private $uibModal: IModalService,
    private appConfig: IAppConfig,
    private analyticsService: AnalyticsService
  ) {}

  public handleModal<T>(what: T, modalOptions: IModalErrorOptions = {}): void {
    logCaughtJsError(what, this.appConfig);

    const error = createUIError(what);
    if (!error) {
      return;
    }

    this.trackError("modal");

    this.$uibModal.open({
      template: `<error-view [error]="error" [error-options]="errorOptions"></error-view>`,
      windowClass: modalOptions.windowClass || "medium-small",
      backdrop: typeof modalOptions.backdrop === "undefined" ? true : modalOptions.backdrop,
      controller: [
        "$scope",
        function ($scope: IErrorScope) {
          $scope.error = error;
          $scope.errorOptions = { hideBody: modalOptions.hideBody };
        },
      ],
    });
  }

  public trackError(handleWith: "modal" | "page"): void {
    const stateName = this.$state.current.name;
    const url = this.$state.href(this.$state.current, this.$state.params);
    this.analyticsService.track("Error Shown", { handle_with: handleWith, state_name: stateName, url });
  }
}
