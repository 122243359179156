<div
  class="ant-notification-notice ant-notification-notice-closable"
  [ngStyle]="instance.options?.nzStyle || null"
  [ngClass]="instance.options?.nzClass || ''"
  [attr.role]="'alert'"
  [attr.aria-live]="instance.type === 'error' ? 'assertive' : 'polite'"
  [attr.aria-label]="generateNotificationAriaLabel(instance.type)"
  [attr.aria-describedby]="'ui-notification-content-' + instance.messageId"
  (click)="onClick($event)"
  (mouseenter)="onEnter()"
  (mouseleave)="onLeave()">
  <div class="ant-notification-notice-content" *ngIf="!instance.template">
    <div class="ant-notification-notice-content" [class.ant-notification-notice-with-icon]="instance.type !== 'blank'">
      <div [class.ant-notification-notice-with-icon]="instance.type !== 'blank'">
        <ng-container [ngSwitch]="instance.type">
          <i
            class="ant-notification-notice-icon ant-notification-notice-icon-success"
            *ngSwitchCase="'success'"
            [a11yTitle]="generateNotificationAriaLabel('success')"
            role="img"
            ui-icon
            uiType="check-circle"></i>
          <i
            class="ant-notification-notice-icon ant-notification-notice-icon-info"
            *ngSwitchCase="'info'"
            [a11yTitle]="generateNotificationAriaLabel('info')"
            role="img"
            ui-icon
            uiType="info-circle"></i>
          <i
            class="ant-notification-notice-icon ant-notification-notice-icon-warning"
            *ngSwitchCase="'warning'"
            [a11yTitle]="generateNotificationAriaLabel('warning')"
            role="img"
            ui-icon
            uiType="exclamation-circle"></i>
          <i
            class="ant-notification-notice-icon ant-notification-notice-icon-error"
            *ngSwitchCase="'error'"
            [a11yTitle]="generateNotificationAriaLabel('error')"
            role="img"
            ui-icon
            uiType="close-circle"></i>
        </ng-container>
        <div class="ant-notification-notice-message" [innerHTML]="instance.title"></div>
        <div class="ant-notification-notice-description" [id]="'ui-notification-content-' + instance.messageId" [innerHTML]="instance.content"></div>
        <ng-container *ngIf="accordionText?.length > 0">
          <ui-collapse>
            <ui-collapse-panel [uiIsExpandable]="true" [uiBorderless]="true" uiSize="small" collapsePanelHeader="Details">
              <p class="accordion-text">{{ accordionText }}</p>
            </ui-collapse-panel>
          </ui-collapse>
        </ng-container>
      </div>
    </div>
  </div>
  <ng-template [ngIf]="instance.template" [ngTemplateOutlet]="instance.template!" [ngTemplateOutletContext]="{ $implicit: this, data: instance.options?.nzData }"></ng-template>
  <a class="ant-notification-notice-close" [a11yTitle]="'Notification.dismiss' | uiI18n" (click)="close()" tabindex="0" role="button">
    <span class="ant-notification-notice-close-x">
      <ng-container *ngIf="instance.options?.nzCloseIcon; else iconTpl">
        <ng-container *nzStringTemplateOutlet="instance.options?.nzCloseIcon; let closeIcon">
          <i [uiType]="closeIcon" ui-icon></i>
        </ng-container>
      </ng-container>
      <ng-template #iconTpl>
        <i class="ant-notification-close-icon" ui-icon uiType="close"></i>
      </ng-template>
    </span>
  </a>
</div>
