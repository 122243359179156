import { StateService } from "@uirouter/angular";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { PdfViewerComponent } from "ng2-pdf-viewer";
import { Subscription } from "rxjs";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { PrivacyNoticeService } from "./privacy-notice.service";

@UntilDestroy()
@Component({
  selector: "privacy-notice",
  templateUrl: "./privacy-notice.component.html",
  styleUrls: ["./privacy-notice.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyNoticeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("privacyMain") public privacyMain: ElementRef;
  @ViewChild("pdfViewer") public pdfViewer: PdfViewerComponent;

  public acceptButtonDisabled = true;
  public logoSrc = "/dist/img/msd-privacy/msd-logo.svg";
  public fileUrl: string;

  private fileSrc = "/notice?path=www.msdprivacy.com/wp-content/uploads/sites/4/2021/03/Notice-of-Data-Practices-for-Employment-Related-Purposes.pdf";
  private scrollTimeout: NodeJS.Timeout;
  private defaultState = "default";

  // Subscriptions
  private httpSubscription: Subscription;
  private updatingUserSettingsSubscription: Subscription;

  constructor(
    private privacyNoticeService: PrivacyNoticeService,
    private cdr: ChangeDetectorRef,
    private featureTogglesFacade: FeatureTogglesFacade,
    private state: StateService
  ) {}

  public ngOnInit(): void {
    this.featureTogglesFacade
      .isFeatureAvailable$(FeatureFlag.PrivacyNotice)
      .pipe(untilDestroyed(this))
      .subscribe((isAvailable) => {
        if (!isAvailable || this.privacyNoticeService.getHasAcceptedPrivacyNotice()) {
          this.state.go(this.defaultState, null, { location: "replace" });
          return;
        }
        this.fetchPdf();
      });
  }

  public ngAfterViewInit(): void {
    const pdfViewerElement = this.pdfViewer.pdfViewerContainer.nativeElement as HTMLIFrameElement;
    pdfViewerElement.addEventListener("scroll", this.onScroll.bind(this));

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes && mutation.addedNodes.length > 0) {
          const addedNodes = Array.from(mutation.addedNodes);
          const lastPage = addedNodes.find((node) => {
            return node instanceof HTMLElement && node.matches('.page[data-page-number="10"]');
          }) as HTMLElement;

          if (lastPage) {
            lastPage.style.marginBottom = "0";
            pdfViewerElement.style.overflowX = "hidden";

            observer.disconnect();
          }
        }
      });
    });

    observer.observe(pdfViewerElement, { childList: true, subtree: true });
  }

  public ngOnDestroy(): void {
    const pdfViewerElement = this.pdfViewer.pdfViewerContainer.nativeElement as HTMLIFrameElement;
    pdfViewerElement.removeEventListener("scroll", this.onScroll.bind(this));

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    if (this.httpSubscription) {
      this.httpSubscription.unsubscribe();
    }

    if (this.updatingUserSettingsSubscription) {
      this.updatingUserSettingsSubscription.unsubscribe();
    }
  }

  public onScroll(): void {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    if (!this.acceptButtonDisabled) {
      return;
    }

    this.scrollTimeout = setTimeout(() => {
      if (
        this.pdfViewer.pdfViewerContainer.nativeElement.offsetHeight + this.pdfViewer.pdfViewerContainer.nativeElement.scrollTop + 2 >=
        this.pdfViewer.pdfViewerContainer.nativeElement.scrollHeight
      ) {
        this.acceptButtonDisabled = false;
        this.cdr.detectChanges();
      }
    }, 200);
  }

  public onAccept(): void {
    this.updatingUserSettingsSubscription = this.privacyNoticeService.acceptPrivacyNotice$().subscribe(() => {
      this.state.go(this.defaultState, null, { location: "replace" });
    });
  }

  private fetchPdf(): void {
    this.httpSubscription = this.privacyNoticeService
      .getPrivacyNotice$(this.fileSrc)
      .pipe(untilDestroyed(this))
      .subscribe((blob) => {
        this.fileUrl = URL.createObjectURL(blob);
        this.cdr.markForCheck();
      });
  }
}
