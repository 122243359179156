<div class="metric-automation-options-wrapper">
  @for (card of cards; track card.type) {
    <div class="metric-automation-options-card" (click)="openCard(card)">
      <div class="metric-automation-option-heading-with-icon-wrapper">
        <i class="fs-16px sp-mr-8" [uiType]="card.icon" ui-icon ui-tooltip uiTheme="fill"></i>
        <h4 tabindex="0">{{ card.title }}</h4>
      </div>
      <p class="sp-ml-24 sp-mt-8" tabindex="0">{{ card.description }}</p>
      <button [attr.data-test-id]="'automate-with-' + card.type" (click)="openCard(card)" ui-button uiShape="round" uiType="primary">{{ card.buttonText }}</button>
    </div>
  }
</div>
