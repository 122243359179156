import { CommonModule } from "@angular/common";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UiAvatarModule } from "@quantive/ui-kit/avatar";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiSkeletonModule } from "@quantive/ui-kit/skeleton";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { FileserverModule } from "@webapp/fileserver/fileserver.module";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { SessionSelectorComponent } from "@webapp/sessions/shared/components/session-selector/session-selector.component";
import { PeopleSelectorModule } from "@webapp/shared/components/people-selector/people-selector.module";
import { UiFormModule } from "@webapp/ui/form/form.module";
import { UiGridModule } from "@webapp/ui/grid/grid.module";
import { UiTextAreaModule } from "@webapp/ui/input/components/textarea-count/ui-textarea.module";
import { UiInputModule } from "@webapp/ui/input/input.module";
import { PlatformIntelligenceSharedModule } from "../shared/pi-shared.module";
import { QuantiveKeyResultComponent } from "./components/key-result/key-result.component";
import { QuantiveObjectiveComponent } from "./components/objective/objective.component";
import { QuantivePlusWizardCardErrorInlineComponent } from "./components/quantive-plus-wizard-card-error-inline/quantive-plus-wizard-card-error-inline.component";
import { GuidedOKRsComponent } from "./guided-okrs.component";

@NgModule({
  declarations: [GuidedOKRsComponent, QuantiveObjectiveComponent, QuantiveKeyResultComponent, QuantivePlusWizardCardErrorInlineComponent],
  exports: [GuidedOKRsComponent],
  imports: [
    CommonModule,
    UiButtonModule,
    UiGridModule,
    UiIconModule,
    LocalizationModule,
    UiTextAreaModule,
    UiInputModule,
    PeopleSelectorModule,
    UiSkeletonModule,
    FormsModule,
    ReactiveFormsModule,
    UiFormModule,
    UiAvatarModule,
    AssigneesModule,
    UiTooltipModule,
    PlatformIntelligenceSharedModule,
    SessionSelectorComponent,
    FileserverModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class GuidedOKRsModule {}
