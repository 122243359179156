<sub-nav-options [options]="options"></sub-nav-options>
<nav-items-list
  *ngIf="showNavItems$ | async"
  [collection]="navItems$ | async"
  [config]="{
    sectionLabelKey: 'custom views',
    visibleItemsCount: 5,
    itemsGroupType: 'taskgroups',
    itemsType: 'tasks',
    broadcastConfig,
  }"></nav-items-list>
<tasks-empty-sub-nav *ngIf="(showNavItems$ | async) === false"></tasks-empty-sub-nav>
