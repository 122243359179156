import { ChangeDetectionStrategy, Component } from "@angular/core";
import { NzListItemExtraComponent } from "ng-zorro-antd/list";

@Component({
  selector: "ui-list-item-extra, [ui-list-item-extra]",
  exportAs: "uiListItemExtra",
  templateUrl: "./list-item-extra.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class UiListItemExtraComponent extends NzListItemExtraComponent {}
