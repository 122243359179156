@if (showSizeChanger) {
  <ui-select class="ant-pagination-options-size-changer" [renderSelectedListItemIcon]="false" [uiDisabled]="disabled" [uiSize]="nzSize" [ngModel]="pageSize" (ngModelChange)="onPageSizeChange($event)">
    @for (option of listOfPageSizeOption; track option.value) {
      <ui-option [uiLabel]="option.label" [uiValue]="option.value" />
    }
  </ui-select>
}
@if (showQuickJumper) {
  <div class="ant-pagination-options-quick-jumper">
    {{ locale.jump_to }}
    <input [disabled]="disabled" (keydown.enter)="jumpToPageViaInput($event)" />
    {{ locale.page }}
  </div>
}
