import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { PI_CARD_ACTION_BUTTON_TOOLTIP_CUSTOM_STYLE } from "../../../models/pi-card.models";

@Component({
  selector: "pi-error-action-bar-main-actions",
  templateUrl: "./pi-error-action-bar-main-actions.component.html",
  styleUrls: ["./pi-error-action-bar-main-actions.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiErrorActionBarMainActionsComponent {
  public actionBtnBottomLeftTooltipCustomStyle = PI_CARD_ACTION_BUTTON_TOOLTIP_CUSTOM_STYLE.bottomLeftTooltip;

  @Input()
  public isBackBtnAvailable = false;
  @Input()
  public isRefreshBtnAvailable = true;
  @Input()
  public isRemoveBtnAvailable = true;
  @Input()
  public removeBtnTooltipTitle: string;

  @Output()
  public readonly goBackToSuggestion = new EventEmitter();
  @Output()
  public readonly errorRefresh = new EventEmitter();
  @Output()
  public readonly errorRemove = new EventEmitter();

  public onGoBackToSuggestionClicked = (event: MouseEvent): void => {
    event.stopPropagation();
    this.goBackToSuggestion.emit();
  };

  public onRefreshErrorClicked = (event: MouseEvent): void => {
    event.stopPropagation();
    this.errorRefresh.emit();
  };

  public onRemoveErrorClicked = (event: MouseEvent): void => {
    event.stopPropagation();
    this.errorRemove.emit();
  };
}
