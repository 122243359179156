<button class="ant-typography-edit" *ngIf="!editing" [uiTooltipTitle]="tooltip === null ? null : tooltip || locale?.edit" (click)="onClick()" ui-tooltip ui-trans-button>
  <ng-container *nzStringTemplateOutlet="icon; let icon">
    <i [uiType]="icon" ui-icon></i>
  </ng-container>
</button>
<ng-container *ngIf="editing">
  <textarea #textarea (blur)="confirm()" (keydown.esc)="onCancel()" (keydown.enter)="onEnter($event)" ui-input nzAutosize></textarea>
  <button class="ant-typography-edit-content-confirm" (click)="confirm()" ui-trans-button>
    <i ui-icon uiType="check"></i>
  </button>
</ng-container>
