import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { localize, localizeToCase } from "@gtmhub/localization";
import { ICfSelectorMentionNotification } from "@gtmhub/notifications/models/notifications";
import { capitalize } from "@gtmhub/util";
import { InboxTemplateComponent } from "../inbox-template.component";

@Component({
  templateUrl: "./cf-selector-mention-inbox-template.component.html",
  styleUrls: ["../inbox-template.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CfSelectorMentionInboxTemplateComponent extends InboxTemplateComponent<ICfSelectorMentionNotification> implements OnInit {
  public mentionIds: string[] = [];

  public get titleText(): string {
    const target = this.notification.notificationData.item.singular ? "you" : "your_team";
    const key = {
      metric: `name_added_${target}_to_key_result`,
      metric_snapshot: `name_added_${target}_to_key_result_update`,
      goal: `name_added_${target}_to_objective`,
      task: `name_added_${target}_to_task`,
      user: `name_added_${target}_to_user_profile`,
      team: `name_added_${target}_to_team_profile`,
    }[this.notification.targetType];

    return localize(key, { name: this.notification.notificationData.actor.names });
  }

  public get buttonText(): string {
    const key = {
      metric: "view_key_result",
      metric_snapshot: "view_key_result",
      goal: "view_objective",
      task: "view_task",
      user: "view_profile",
      team: "view_team",
    }[this.notification.targetType];

    return localize(key);
  }

  public get iconType(): string {
    return this.notification.targetType === "metric_snapshot" ? "metric" : this.notification.targetType;
  }

  public get iconTooltipText(): string {
    const key = {
      metric: "metric",
      metric_snapshot: "metric",
      goal: "objective",
      task: "task",
    }[this.notification.targetType];

    return localize(key);
  }

  public get assigneeProfileTitleText(): string {
    return `${capitalize(localize(this.notification?.targetType))} ${localizeToCase("profile")}`;
  }

  public get isUserOrTeamTarget(): boolean {
    return this.notification.targetType === "user" || this.notification.targetType === "team";
  }

  public ngOnInit(): void {
    this.mentionIds = [
      // notificationData.item.singular indicates the targeted user has been explicitly added in a user-selector custom field
      ...(this.notification.notificationData.item.singular ? [this.notification.notificationData.target.id] : []),
      // teamIds indicate whether he's been added in the same user-selector as part of teams he's managing or is a part of
      ...(this.notification.notificationData.item.teamIds || []),
    ];
  }
}
