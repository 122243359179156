import { ComponentRef, EnvironmentInjector, Injectable } from "@angular/core";
import { CustomFieldMultiSelectComponent } from "@webapp/custom-fields/components/multi-select/multi-select.component";
import { CustomFieldUpdate, EditableCustomFieldsInput, ICustomField } from "../../models/custom-fields.models";
import { AbstractCustomFieldFactory } from "./abstract-custom-field.factory";

@Injectable()
export class MultiSelectCustomFieldComponentFactory extends AbstractCustomFieldFactory<CustomFieldMultiSelectComponent> {
  constructor(injector: EnvironmentInjector) {
    super(injector, CustomFieldMultiSelectComponent);
  }

  protected setComponentInputs(
    component: ComponentRef<CustomFieldMultiSelectComponent>,
    customField: ICustomField,
    editableCustomFieldInputs: EditableCustomFieldsInput
  ): void {
    component.setInput("uiId", customField.name);
    component.setInput("borderless", editableCustomFieldInputs.borderless ?? true);
    component.setInput("a11yLabelledby", customField._id);
    component.setInput("fieldName", customField.name);
    component.setInput("options", customField.settings?.predefinedValues);
    component.setInput("selectedValues", editableCustomFieldInputs.values[customField.name] || []);
    component.setInput("a11yRequired", !editableCustomFieldInputs.disableRequired && customField.settings.validations.required);
    component.setInput("disabled", editableCustomFieldInputs.disabled);
    component.setInput("readonly", editableCustomFieldInputs.readonly);
    component.instance.optionSelect.subscribe((updatedCustomField: CustomFieldUpdate) => {
      component.setInput("selectedValues", updatedCustomField.customField);
      editableCustomFieldInputs.onChange(updatedCustomField);
    });
  }
}
