import { Ng1StateDeclaration } from "@uirouter/angularjs";
import { IEditionPlanChangeResolveParams } from "./models";

export const states: Ng1StateDeclaration[] = [
  {
    name: "gtmhub.editionPlanChange",
    data: {
      requiresLogin: true,
      trackingPage: "Settings Edition Plan Change",
      titleKey: "choose_your_plan",
      pageBackgroundColor: "#fff",
    },
    url: "/edition-plan-change/",
    template: require("./views/edition-plan-change.html"),
    controller: "EditionPlanChangeCtrl",
    resolve: {
      resolveParams: (): IEditionPlanChangeResolveParams => ({
        isAccountActive: true,
        currentlySelectedPlanId: "",
      }),
    },
  },
];
