import { ComponentRef, EnvironmentInjector, Injectable } from "@angular/core";
import { CustomFieldLabelComponent } from "@webapp/custom-fields/components/label/label.component";
import { EditableCustomFieldsInput, ICustomField } from "@webapp/custom-fields/models/custom-fields.models";
import { AbstractCustomFieldFactory } from "./abstract-custom-field.factory";

@Injectable()
export class CustomFieldLabelComponentFactory extends AbstractCustomFieldFactory<CustomFieldLabelComponent> {
  constructor(injector: EnvironmentInjector) {
    super(injector, CustomFieldLabelComponent);
  }

  protected setComponentInputs(
    component: ComponentRef<CustomFieldLabelComponent>,
    customField: ICustomField,
    editableCustomFieldInputs: EditableCustomFieldsInput
  ): void {
    component.setInput("label", customField.label);
    component.setInput("customFieldName", customField.name);
    component.setInput("formName", editableCustomFieldInputs.formName);
    component.setInput("tooltipText", customField.tooltip);
    component.setInput("required", !editableCustomFieldInputs.disableRequired && customField.settings.validations.required);
    component.setInput("fieldId", customField._id);
  }
}
