import Quill, { StringMap } from "quill";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { OKRLinkItem } from "../rich-text-editor.models";
import { UNKNOWN_AVATAR_ICON_PATH, generateOKRIconByType, okrMappingRecord } from "../rich-text-editor.utils";
import { QuillContainer } from "./okr-link.models";

const Mention = Quill.import("modules/mention");

export class OKRLinkSelector extends Mention {
  constructor(quill: QuillContainer, options: StringMap) {
    super(quill, options);
    this.mentionList.id = "link-list";
    this.mentionList.setAttribute("aria-label", "Search for Objectives, Key Results and KPIs to insert");
  }

  public renderList(linkChar: string, data: StringMap[]): void {
    if (data && data.length > 0) {
      this.values = data;
      this.mentionList.innerHTML = "";
      let initialSelection = -1;

      for (let i = 0; i < data.length; i += 1) {
        const itemData = data[i];
        const linkListItem = this.createLinkListItem({
          item: itemData as OKRLinkItem,
          index: i,
          disabled: itemData.disabled,
          linkChar,
        });

        if (!itemData.disabled && initialSelection === -1) {
          initialSelection = i;
        }

        this.mentionList.appendChild(this.attachDataValues(linkListItem, itemData, this.options.dataAttributes));
      }

      this.itemIndex = initialSelection;
      this.highlightItem(true);
      this.showMentionList();
    } else {
      this.hideMentionList();
    }
  }

  private renderItem(item: OKRLinkItem): HTMLDivElement {
    const wrapper = document.createElement("div");
    wrapper.classList.add("link-list-item-content");

    if (item.type === "empty") {
      window.setTimeoutOutsideAngular(() => {
        wrapper.textContent = item.title;
        document.querySelector<HTMLElement>(".link-list")?.classList.add("empty-state");
      });
      return wrapper;
    }

    window.setTimeoutOutsideAngular(() => {
      document.querySelector<HTMLElement>(".link-list")?.classList.remove("empty-state");
    }, 100);
    const pictureEl = document.createElement("span");
    pictureEl.classList.add("okr-link-item-icon");

    pictureEl.appendChild(generateOKRIconByType(item.type));
    wrapper.appendChild(pictureEl);

    const textContentWrapper = document.createElement("div");
    textContentWrapper.classList.add("checkin-link-item-content");

    const paragraphEl = document.createElement("p");
    paragraphEl.classList.add("okr-link-text");
    textContentWrapper.appendChild(paragraphEl);

    const nameEl = document.createElement("span");
    nameEl.classList.add("okr-link-text");
    nameEl.textContent = decodeURIComponent(item.title);
    paragraphEl.appendChild(nameEl);

    const hasSessionTitle = (item.type === "goals" || item.type === "metrics") && item.sessionTitle;
    let sessionTitleEl: HTMLElementTagNameMap["span"];
    if (hasSessionTitle) {
      sessionTitleEl = document.createElement("span");
      sessionTitleEl.classList.add("okr-link-text", "okr-link-item-session-title");
      sessionTitleEl.textContent = ` (${item.sessionTitle})`;
      paragraphEl.appendChild(sessionTitleEl);
    }

    wrapper.appendChild(textContentWrapper);

    setTimeout(() => {
      const debounce = 2;
      const calculatedWidth = nameEl.offsetWidth + (hasSessionTitle ? sessionTitleEl.offsetWidth : 0);
      if (paragraphEl.offsetWidth + debounce < calculatedWidth) {
        const tooltipContent = document.createElement("p");
        tooltipContent.textContent = decodeURIComponent(item.title) + (hasSessionTitle ? ` (${item.sessionTitle})` : "");

        this.appendTooltipWithCustomContentToItem(paragraphEl, tooltipContent);
      }
    });

    const avatars = this.constructItemAvatars(item);
    wrapper.appendChild(avatars);

    return wrapper;
  }

  private constructItemAvatars(item: OKRLinkItem): HTMLDivElement {
    const avatars = document.createElement("div");
    avatars.classList.add("okr-link-item-avatars");

    // Visible owners
    const visibleOwnerCount = item.type === "kpis" ? 4 : 2;
    for (const owner of item.owners.slice(0, visibleOwnerCount)) {
      avatars.appendChild(this.createVisibleOwner(owner));
    }

    // Hidden owners
    if (item.owners.length > visibleOwnerCount) {
      const counterEl = document.createElement("div");
      counterEl.classList.add("okr-link-item-counter");

      const counter = document.createElement("span");
      counter.classList.add("okr-link-item-counter-icon");
      counter.textContent = `+${item.owners.length - visibleOwnerCount}`;
      counterEl.appendChild(counter);

      const hiddenOwnerTooltip = document.createElement("div");
      for (const owner of item.owners.slice(visibleOwnerCount)) {
        hiddenOwnerTooltip.appendChild(this.createHiddenOwner(owner));
      }

      this.appendTooltipWithCustomContentToItem(counterEl, hiddenOwnerTooltip);

      avatars.appendChild(counterEl);
    }

    return avatars;
  }

  private appendTooltipWithCustomContentToItem(triggerItem: HTMLElement, tooltipContent: HTMLElement): void {
    const tooltipContainer = this.generateTooltipContainerWithContent(tooltipContent);

    triggerItem.classList.add("checkin-link-item-tooltip-target");
    triggerItem.appendChild(tooltipContainer);

    this.handleMouseEnterForTooltipTrigger(triggerItem, tooltipContainer);
  }

  private handleMouseEnterForTooltipTrigger(triggerItem: HTMLElement, tooltip: HTMLElement): void {
    const repositionCb = (): void => {
      this.adjustTooltipRelativeToTarget(triggerItem, tooltip);
    };

    triggerItem.addEventListener("mouseenter", () => {
      this.adjustTooltipRelativeToTarget(triggerItem, tooltip);
      window.addEventListener("mousewheel", repositionCb);
    });

    triggerItem.addEventListener("mouseleave", () => {
      window.removeEventListener("mousewheel", repositionCb);
    });
  }

  private adjustTooltipRelativeToTarget(triggerItem: HTMLElement, tooltip: HTMLElement): void {
    const { x: triggerItemX, y: triggerItemY } = triggerItem.getBoundingClientRect();
    const { width: triggerItemWidth, height: triggerItemHeight } = triggerItem.getBoundingClientRect();
    const { height: tooltipHeight, width: tooltipWidth } = tooltip.getBoundingClientRect();

    const debounceTop = tooltipHeight + triggerItemHeight / 2;
    const debounceLeft = tooltipWidth / 2 - triggerItemWidth / 2;

    tooltip.style.left = `${triggerItemX - debounceLeft}px`;
    tooltip.style.top = `${triggerItemY - debounceTop}px`;
  }

  private generateTooltipContainerWithContent(customContent: HTMLElement): HTMLDivElement {
    const tooltipContainer = document.createElement("div");
    tooltipContainer.classList.add("checkin-link-item-tooltip");
    const tooltipContent = document.createElement("div");
    tooltipContent.classList.add("checkin-link-item-tooltip-content");
    tooltipContent.appendChild(customContent);
    tooltipContainer.appendChild(tooltipContent);

    return tooltipContainer;
  }

  private createVisibleOwner(owner: Assignee): HTMLSpanElement {
    const ownerAvatarEl = document.createElement("span");
    ownerAvatarEl.classList.add("okr-link-item-avatar");

    let ownerAvatarImg;
    if (owner.type === "user") {
      ownerAvatarImg = document.createElement("img");
      ownerAvatarImg.src = owner.picture || UNKNOWN_AVATAR_ICON_PATH;
      ownerAvatarImg.alt = owner.name;
    } else {
      let isImage = false;

      if (owner.picture || !owner.avatar) {
        ownerAvatarImg = document.createElement("img");
        ownerAvatarImg.src = owner.picture || "/dist/img/icons/travel_world.svg";
        ownerAvatarImg.alt = owner.name;
        isImage = true;
      } else {
        ownerAvatarImg = document.createElement("span");
        ownerAvatarImg.classList.add("okr-link-item-avatar-icon", "nc-icon-outline", "icon", owner.avatar);
        ownerAvatarImg.style.backgroundColor = owner.color;
      }

      if (isImage) {
        ownerAvatarEl.classList.add("team");
      }
    }

    const ownerAvatarTooltip = document.createElement("span");

    const ownerAvatarTooltipText = document.createElement("span");
    ownerAvatarTooltipText.classList.add("okr-link-item-avatar-tooltip-text");

    if (owner.name.length > 30) {
      ownerAvatarTooltipText.textContent = owner.name.slice(0, 28) + " ...";
    } else {
      ownerAvatarTooltipText.textContent = owner.name;
    }

    ownerAvatarEl.appendChild(ownerAvatarImg);
    ownerAvatarTooltip.appendChild(ownerAvatarTooltipText);
    ownerAvatarEl.appendChild(ownerAvatarTooltip);

    this.appendTooltipWithCustomContentToItem(ownerAvatarEl, ownerAvatarTooltip);

    return ownerAvatarEl;
  }

  private createHiddenOwner(owner: Assignee): HTMLDivElement {
    const counterTooltipRow = document.createElement("div");
    counterTooltipRow.classList.add("tooltip-row");

    const counterAvatarEl = document.createElement("span");
    counterAvatarEl.classList.add("counter-tooltip-avatar");

    let tooltipAvatar;
    if (owner.type === "user") {
      tooltipAvatar = document.createElement("img");
      tooltipAvatar.src = owner.picture || UNKNOWN_AVATAR_ICON_PATH;
      tooltipAvatar.alt = owner.name;
    } else {
      let isImage = false;

      if (owner.picture || !owner.avatar) {
        tooltipAvatar = document.createElement("img");
        tooltipAvatar.src = owner.picture || "/dist/img/icons/travel_world.svg";
        tooltipAvatar.alt = owner.name;
        isImage = true;
      } else {
        tooltipAvatar = document.createElement("span");
        tooltipAvatar.classList.add("okr-link-item-avatar-icon", "nc-icon-outline", "icon", owner.avatar);
        tooltipAvatar.style.backgroundColor = owner.color;
      }

      if (isImage) {
        tooltipAvatar.classList.add("team");
      }
    }

    const tooltipName = document.createElement("span");
    tooltipName.classList.add("tooltip-name");

    if (owner.name.length > 30) {
      tooltipName.textContent = owner.name.slice(0, 28) + " ...";
    } else {
      tooltipName.textContent = owner.name;
    }

    counterAvatarEl.appendChild(tooltipAvatar);
    counterTooltipRow.appendChild(counterAvatarEl);
    counterTooltipRow.appendChild(tooltipName);

    return counterTooltipRow;
  }

  private createLinkListItem({ item, index, disabled, linkChar }: { item: OKRLinkItem; index: number; disabled: boolean; linkChar: string }): HTMLLIElement {
    const li = document.createElement("li");
    li.id = "link-list-item-" + index;
    li.className = this.options.listItemClass ? this.options.listItemClass : "";

    if (disabled) {
      li.className += " disabled";
      li.setAttribute("aria-hidden", "true");
    }

    const owners = item.owners.map((obj) => obj.name).join(", ");
    li.setAttribute("aria-label", `${okrMappingRecord[item.type]}, ${decodeURIComponent(item.title)}, ${item.owners.length <= 1 ? "owner" : "owners"} ${owners}`);

    li.dataset.index = String(index);
    li.appendChild(this.renderItem(item));

    if (!disabled) {
      li.onmouseenter = this.onItemMouseEnter.bind(this);
      li.onmouseup = this.onItemClick.bind(this);
      li.onmousedown = this.onItemMouseDown.bind(this);
    } else {
      li.onmouseenter = this.onDisabledItemMouseEnter.bind(this);
    }
    li.dataset.denotationChar = linkChar;

    return li;
  }

  private attachDataValues(element: HTMLElement, data: StringMap, dataAttributes: string[]): HTMLElement {
    const link = element;
    Object.keys(data).forEach((key) => {
      if (dataAttributes.indexOf(key) > -1) {
        link.dataset[key] = data[key];
      } else {
        delete link.dataset[key];
      }
    });
    return link;
  }
}
