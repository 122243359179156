import { ChangeDetectionStrategy, Component } from "@angular/core";
import { IGoalNotification } from "@gtmhub/notifications/models/notifications";
import { InboxTemplateComponent } from "../inbox-template.component";

@Component({
  selector: "goal-inbox-template",
  templateUrl: "./goal-inbox-template.component.html",
  styleUrls: ["../inbox-template.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoalInboxTemplateComponent extends InboxTemplateComponent<IGoalNotification> {}
