import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { EmojiPickerController } from "@webapp/shared/emoji-picker";
import { RichTextEditorIconEnum } from "@webapp/shared/rich-text-editor/rich-text-editor-icon/rich-text-editor-icon.enum";

@UntilDestroy()
@Component({
  selector: "emoji-picker",
  templateUrl: "./emoji-picker.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiPickerComponent implements OnDestroy, OnInit {
  @Input() public outOfFocus: boolean;

  @Output() public readonly selectedEmoji = new EventEmitter<string>();
  @Output() public readonly pickerOpen: EventEmitter<MouseEvent>;
  @Output() public readonly pickerClose: EventEmitter<MouseEvent>;

  public emojiPicker: EmojiPickerController;

  public get emojiIcon(): RichTextEditorIconEnum {
    return this.emojiPicker.isLoading ? RichTextEditorIconEnum.LOADING : RichTextEditorIconEnum.EMOJI;
  }

  constructor(elementRef: ElementRef<HTMLElement>) {
    this.emojiPicker = new EmojiPickerController({
      triggerElement: (): HTMLElement => elementRef.nativeElement.querySelector("button"),
      referenceElement: (): HTMLElement => elementRef.nativeElement,
    });
    this.pickerOpen = this.emojiPicker.pickerOpen;
    this.pickerClose = this.emojiPicker.pickerClose;
  }

  public ngOnInit(): void {
    this.setUpPickerSubscriptions();
  }

  public ngOnDestroy(): void {
    this.emojiPicker?.destroy();
  }

  private setUpPickerSubscriptions(): void {
    this.emojiPicker.selectedReaction$.pipe(untilDestroyed(this)).subscribe((reaction) => {
      this.selectedEmoji.emit(reaction.emoji);
    });
  }
}
