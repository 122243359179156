<ng-container *ngIf="notification.operationType !== 'reflection_reaction' && notification.operationType !== 'announcement_reaction'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">{{ title }}</div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon" *ngIf="notification.notificationData.item.targetType === 'metric_snapshot'">
      <i class="fs-20px" [uiTooltipTitle]="'metric' | localize" ui-icon ui-tooltip uiTheme="fill" uiType="metric"></i>
    </div>
    <div class="message-icon" *ngIf="notification.notificationData.item.targetType !== 'metric_snapshot' && notification.notificationData.item.targetType !== 'checkin'">
      <i class="fs-20px" [uiType]="icon" [uiTooltipTitle]="notification.notificationData.item.targetType | localize" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.targetItemName }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <div class="heading">{{ "the_update" | localize }}:</div>
        <div *ngIf="notification.notificationData.item.targetType !== 'comment'">
          <div class="list-item">{{ "new_value" | localize }}</div>
          <span *ngIf="notification.notificationData.item.targetItem.targetInfo.manualType === 'boolean'"
            >: {{ notification.notificationData.item.targetItem.updatedValue.target | metricValue: notification.notificationData.item.targetItem.updatedValue.format }}</span
          >
          <span *ngIf="notification.notificationData.item.targetItem.targetInfo.manualType !== 'boolean'"
            >: {{ notification.notificationData.item.targetItem.updatedValue.target | metricValue: notification.notificationData.item.targetItem.updatedValue.format }} (
            <div class="list-item">{{ "target" | localize }}</div>
            :&nbsp;<span *ngIf="notification.notificationData.item.targetItem.targetOperator === 'should_stay_above'"
              >{{ "should_stay_above_target_item_target_info_value" | localize }}
              {{ notification.notificationData.item.targetItem.targetInfo.target | metricValue: notification.notificationData.item.targetItem.targetInfo.format
              }}<span *ngIf="notification.notificationData.item.targetItem.targetInfo.critical"
                >{{ "and_absolutely_must_be_above_target_item_critical_value" | localize }}
                {{ notification.notificationData.item.targetItem.targetInfo.critical | metricValue: notification.notificationData.item.targetItem.targetInfo.format }}</span
              ></span
            ><span *ngIf="notification.notificationData.item.targetItem.targetOperator === 'should_stay_below'"
              >{{ "should_stay_below_target_item_target_info_value" | localize }}
              {{ notification.notificationData.item.targetItem.targetInfo.target | metricValue: notification.notificationData.item.targetItem.targetInfo.format
              }}<span *ngIf="notification.notificationData.item.targetItem.targetInfo.critical"
                >{{ "and_absolutely_must_be_below_target_item_critical_value" | localize }}
                {{ notification.notificationData.item.targetItem.targetInfo.critical | metricValue: notification.notificationData.item.targetItem.targetInfo.format }}</span
              ></span
            ><span *ngIf="notification.notificationData.item.targetItem.targetOperator === 'at_least'"
              >{{ "should_increase_to_target_item_target_info_value" | localize }}
              {{ notification.notificationData.item.targetItem.targetInfo.target | metricValue: notification.notificationData.item.targetItem.targetInfo.format
              }}<span *ngIf="notification.notificationData.item.targetItem.targetInfo.critical"
                >{{ "and_absolutely_must_be_above_target_item_critical_value" | localize }}
                {{ notification.notificationData.item.targetItem.targetInfo.critical | metricValue: notification.notificationData.item.targetItem.targetInfo.format }}</span
              ></span
            ><span *ngIf="notification.notificationData.item.targetItem.targetOperator === 'at_most'"
              >{{ "should_decrease_to_target_item_target_info_value" | localize }}
              {{ notification.notificationData.item.targetItem.targetInfo.target | metricValue: notification.notificationData.item.targetItem.targetInfo.format
              }}<span *ngIf="notification.notificationData.item.targetItem.targetInfo.critical"
                >{{ "and_absolutely_must_be_below_target_item_critical_value" | localize }}
                {{ notification.notificationData.item.targetItem.targetInfo.critical | metricValue: notification.notificationData.item.targetItem.targetInfo.format }}</span
              ></span
            >)
          </span>
        </div>
        <div>
          <div class="list-item">
            {{ "note" | localize }}
            <span *ngIf="notification.notificationData.item.targetType !== 'comment'">: <rich-text-viewer [text]="notification.notificationData.item.targetItem.updateNote"></rich-text-viewer></span>
            <span *ngIf="notification.notificationData.item.targetType === 'comment'">: <rich-text-viewer [text]="notification.notificationData.item.content"></rich-text-viewer></span>
          </div>
        </div>
      </div>
      <div class="inbox-section with-reactions">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_update" | localize }}</a>
        <div class="reacted-with">
          <span>{{ notification.notificationData.item.reactions[0].emoji }}</span>
          <span *ngIf="notification.notificationData.item.reactions.length > 1">{{ notification.notificationData.item.reactions[1].emoji }}</span>
          <span *ngIf="notification.notificationData.item.reactions.length > 2">{{ notification.notificationData.item.reactions[2].emoji }}</span>
          <span *ngIf="notification.notificationData.item.reactions.length > 3">...</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="notification.operationType === 'announcement_reaction'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title">{{ badgeTitle }}</div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiTooltipTitle]="'announcement' | localize" ui-tooltip ui-icon uiTheme="fill" uiType="announcement"></i>
    </div>
    <span class="item">{{ notification.notificationData.item.targetItemName }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <div class="heading">{{ "note" | localize }}:</div>
        <span class="item"><rich-text-viewer [text]="notification.notificationData.item.content"></rich-text-viewer></span>
      </div>
      <div class="inbox-section with-reactions">
        <a class="link classic" [attr.href]="notification.notificationData.item.url">{{ "view_update" | localize }}</a>
        <div class="reacted-with">
          <span>{{ notification.notificationData.item.reactions[0].emoji }}</span>
          <span *ngIf="notification.notificationData.item.reactions.length > 1">{{ notification.notificationData.item.reactions[1].emoji }}</span>
          <span *ngIf="notification.notificationData.item.reactions.length > 2">{{ notification.notificationData.item.reactions[2].emoji }}</span>
          <span *ngIf="notification.notificationData.item.reactions.length > 3">...</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="checkin-reaction" *ngIf="notification.operationType === 'reflection_reaction'">
  <ui-assignee-avatar class="avatar" [uiAssignee]="notification.notificationData.actor | asAssignee" uiSize="sm"></ui-assignee-avatar>
  <div class="title" *ngIf="notification.notificationData.item.targetType === 'check-in-reaction-note'">
    {{ notification.notificationData.item.targetItem.type | lowercase | checkinNoteType: notification.targetType | localize: { user: notification.notificationData.actor.names } }}
  </div>
  <div class="title" *ngIf="notification.notificationData.item.targetType === 'check-in-reaction-answer' || notification.notificationData.item.targetType === 'checkin_answer'">
    {{ "checkins_reaction_on_question" | localize: { user: notification.notificationData.actor.names } }}
  </div>
  <div class="title" *ngIf="notification.notificationData.item.targetType === 'comment'">
    {{ "checkins_reaction_on_comment" | localize: { user: notification.notificationData.actor.names } }}
  </div>
  <div class="date">{{ notification.createdAt | date: "dd MMM, yyyy" : "UTC" }}</div>
  <div class="summary">
    <div class="message-icon">
      <i class="fs-20px" [uiType]="'check-in'" [uiTooltipTitle]="'Check-in' | localize" ui-icon ui-tooltip uiTheme="fill"></i>
    </div>
    <span class="item" *ngIf="notification.notificationData.item.targetItem?.teamName">{{
      "reflection_summary" | localize: { teamName: notification.notificationData.item.targetItem?.teamName }
    }}</span>
  </div>
  <div class="full">
    <div class="inbox-box">
      <div class="inbox-section">
        <div class="inline-notifications cadence">
          {{ notification.notificationData.item.targetItem.cadenceStart | date: "dd MMM, yyyy" : "UTC" }} -
          {{ notification.notificationData.item.targetItem.cadenceEnd | date: "dd MMM, yyyy" : "UTC" }}
        </div>
      </div>
      <ng-container *ngIf="checkInReactionLegacy.includes(notification.notificationData.item.targetType)">
        <ng-container *ngTemplateOutlet="notificationContent; context: { notification }"> </ng-container>
      </ng-container>
      <!-- Reaction on comment template -->
      <div class="inbox-section" *ngIf="notification.notificationData.item.targetType === 'comment'">
        <div class="heading inline-notifications comment">{{ "comment" | localize }}:</div>
        <rich-text-viewer [text]="notification.notificationData.item.content"></rich-text-viewer>
        <gif-view *ngIf="notification.notificationData.item.targetItem?.gif?.id" [gifSize]="sizeOfGif.Small" [gif]="notification.notificationData.item.targetItem.gif"></gif-view>
      </div>

      <div class="inbox-section with-reactions">
        <a
          class="link classic"
          *ngIf="checkInReactionLegacy.includes(notification.notificationData.item.targetType)"
          [attr.href]="notification.notificationData.item.targetItem.url"
          (click)="goToCheckinOrComment($event, notification.notificationData.item.targetItem.url, notification.notificationData.item.targetType)"
          >{{ "view_check_in" | localize }}</a
        >
        <a
          class="link classic"
          *ngIf="notification.notificationData.item.targetType === 'comment'"
          [attr.href]="notification.notificationData.item.url"
          (click)="goToCheckinOrComment($event, notification.notificationData.item.url, notification.notificationData.item.targetType)"
          >{{ "view_comment" | localize }}</a
        >
        <span class="emoji">{{ notification.notificationData.item.emoji }}</span>
      </div>
    </div>
  </div>
</div>

<!-- Reaction on note or question template -->
<ng-template #notificationContent let-notification="notification">
  <div class="inbox-section" [ngSwitch]="notification.notificationData.item.targetItem.type | lowercase">
    <div class="inline-notifications" *ngSwitchCase="'default_question'">
      <div class="heading inline-notifications" *ngIf="notification.notificationData.item.targetItem.name === 'question_one'">
        {{ "checkins_default_question_one" | localize }}
      </div>
      <div class="heading inline-notifications" *ngIf="notification.notificationData.item.targetItem.name === 'question_two'">
        {{ "checkins_default_question_two" | localize }}
      </div>
      <div class="heading inline-notifications question-three" *ngIf="notification.notificationData.item.targetItem.name === 'question_three'">
        {{ "checkins_default_question_three" | localize }}
      </div>
    </div>
    <div class="heading inline-notifications blockers" *ngSwitchCase="'blockers'">
      {{ "reflections_overview_blockers" | localize }}
    </div>
    <div class="heading inline-notifications requests" *ngSwitchCase="'requests'">
      {{ "reflections_overview_requests" | localize }}
    </div>
    <div class="heading inline-notifications wins" *ngSwitchCase="'wins'">
      {{ "reflections_overview_wins" | localize }}
    </div>
    <div class="heading inline-notifications kudos" *ngSwitchCase="'kudos'">
      {{ "reflections_overview_kudos" | localize }}
    </div>
    <div class="heading inline-notifications takeaways" *ngSwitchCase="'takeaways'">
      {{ "reflections_overview_takeaways" | localize }}
    </div>
    <div class="heading inline-notifications custom-question" *ngSwitchDefault>
      {{ notification.notificationData.item.targetItem.name }}
    </div>
    <rich-text-viewer [text]="notification.notificationData.item.targetItem.text" (okrLinkClick)="okrLinkClicked($event)"></rich-text-viewer>
    <gif-view *ngIf="notification.notificationData.item.targetItem?.gif?.id" [gifSize]="sizeOfGif.Small" [gif]="notification.notificationData.item.targetItem.gif"></gif-view>
  </div>
</ng-template>
