import { IModalService } from "angular-ui-bootstrap";

export class UserInvitationModalService {
  static $inject = ["$uibModal"];
  constructor(private $uibModal: IModalService) {}

  open(): void {
    this.$uibModal.open({
      component: "userInvitation",
      windowClass: "medium-large",
      backdropClass: "dark-backdrop",
    });
  }
}
