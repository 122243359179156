import { Ng2StateDeclaration } from "@uirouter/angular";
import { ExamplesModule } from "./examples.module";

export const states: Ng2StateDeclaration[] = [
  {
    name: "gh.examples.**",
    url: "/examples",
    loadChildren: (): Promise<typeof ExamplesModule> => import(/* webpackChunkName: "examples" */ "./examples.module").then((m) => m.ExamplesModule),
  },
];
