import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SimplePermissionsMediator {
  private showPermissions = new Subject<boolean>();

  public get showPermissions$(): Observable<boolean> {
    return this.showPermissions.asObservable();
  }

  public setShowPermissions(value: boolean): void {
    this.showPermissions.next(value);
  }
}
