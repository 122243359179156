/* eslint-disable @typescript-eslint/no-unsafe-function-type */

/* eslint-disable prefer-rest-params */
import { IModule, IPromise, IQService, IServiceProvider } from "angular";
import { isPromiseLike } from "@gtmhub/util/promise";
import { Ng1Zone } from "../zone";
import { constructChain, splitChain } from "./util";

export function $qPatch(mod: IModule) {
  mod.config([
    "$qProvider",
    function ($qProvider: IServiceProvider) {
      function patchCallback(zone: Ng1Zone, callback: Function): Function {
        let callbackPatch: Function;
        if (callback) {
          callbackPatch = function zoneAwareCallback(this: IQService) {
            let r = zone.run(callback, this, [...arguments]);
            if (isPromiseLike(r)) {
              r = patchPromise(zone, r);
            }
            return r;
          };
        }
        return callbackPatch;
      }

      function patchPromise<T>(zone: Ng1Zone, promise: IPromise<T>): IPromise<T> {
        const oldThen = promise.then;
        promise.then = function zoneAwareThen<TResult>(
          successCallback: (promiseValue: T) => IPromise<TResult> | TResult,
          errorCallback?: (reason: unknown) => unknown,
          notifyCallback?: (state: unknown) => unknown
        ): IPromise<TResult> {
          const successCallbackPatch = patchCallback(zone, successCallback);
          const errorCallbackPatch = patchCallback(zone, errorCallback);
          const notifyCallbackPatch = patchCallback(zone, notifyCallback);

          const r = oldThen.call(this, successCallbackPatch, errorCallbackPatch, notifyCallbackPatch);
          return patchPromise(zone, r);
        };
        return promise;
      }

      const [dependencies, oldGet] = splitChain($qProvider.$get);
      $qProvider.$get = constructChain(dependencies, newGet);

      function newGet(this: IQService) {
        const oldQ: IQService = oldGet.apply(this, arguments);

        const newQ = function zoneAwareQ(this: IQService) {
          return patchPromise(Ng1Zone.current, oldQ.apply(this, arguments));
        };
        newQ.defer = function zoneAwareDefer(this: IQService) {
          const deferred = oldQ.defer.apply(this, arguments);
          deferred.promise = patchPromise(Ng1Zone.current, deferred.promise);
          return deferred;
        };
        newQ.when = function zoneAwareWhen(this: IQService) {
          return patchPromise(Ng1Zone.current, oldQ.when.apply(this, arguments));
        };
        newQ.reject = function zoneAwareReject(this: IQService) {
          return patchPromise(Ng1Zone.current, oldQ.reject.apply(this, arguments));
        };
        newQ.resolve = function zoneAwareResolve(this: IQService) {
          return patchPromise(Ng1Zone.current, oldQ.resolve.apply(this, arguments));
        };
        newQ.all = function zoneAwareAll(this: IQService) {
          return patchPromise(Ng1Zone.current, oldQ.all.apply(this, arguments));
        };
        return newQ;
      }
    },
  ]);
}
