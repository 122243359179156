import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, forwardRef } from "@angular/core";
import { FormItemBaseComponent } from "../form-item-base.component";

@Component({
  selector: "numeric-form-item",
  templateUrl: "./numeric-form-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FormItemBaseComponent, useExisting: forwardRef(() => NumericFormItemComponent) }],
})
export class NumericFormItemComponent extends FormItemBaseComponent<number> {
  @Input() public inputPrefix?: string;
  @Input() public inputSuffix?: string;
  @Input() public useThousandsFormatter: boolean = false;
  @Input() public clickable: boolean = false;
  @Input() public valueControlsHide: boolean = false;

  @Output()
  public readonly uiBlur: EventEmitter<number> = new EventEmitter();

  @Output()
  public readonly uiClick: EventEmitter<void> = new EventEmitter();

  @Output()
  public readonly uiKeydown: EventEmitter<KeyboardEvent> = new EventEmitter();
}
