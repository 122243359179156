import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { ComponentRef } from "@angular/core";
import { Subject, takeUntil } from "rxjs";
import { UiDrawerComponent } from "@webapp/ui/drawer/drawer.component";
import { UiDrawerOptions, UiDrawerOptionsOfComponent } from "@webapp/ui/drawer/drawer.models";
import { UiDrawerRef } from "../abstracts/drawer-ref";

export class UiDrawerBuilderForService<T, R> {
  private drawerComponentRef: ComponentRef<UiDrawerComponent<T, R>> | null;
  private overlayRef: OverlayRef;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private overlay: Overlay,
    private options: UiDrawerOptions
  ) {
    /** pick {@link UiDrawerOptions.uiOnCancel} and omit this option */
    const { uiOnCancel, ...componentOption } = this.options;
    this.overlayRef = this.overlay.create();

    this.drawerComponentRef = this.overlayRef.attach(new ComponentPortal(UiDrawerComponent));
    const drawerComponent = this.drawerComponentRef.instance;
    this.updateOptions(componentOption);
    // Prevent repeatedly open drawer when tap focus element.
    drawerComponent.savePreviouslyFocusedElement();
    drawerComponent.nzOnViewInit.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      drawerComponent.open();
    });
    drawerComponent.nzOnClose.subscribe(() => {
      if (uiOnCancel) {
        uiOnCancel().then((canClose) => {
          if (canClose !== false) {
            drawerComponent.close();
          }
        });
      } else {
        drawerComponent.close();
      }
    });

    drawerComponent.afterClose.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.overlayRef.dispose();
      this.drawerComponentRef = null;
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    });
  }

  public getInstance(): UiDrawerRef<T, R> {
    return this.drawerComponentRef.instance;
  }

  public updateOptions(options: UiDrawerOptionsOfComponent): void {
    for (const [key, value] of Object.entries(options)) {
      let componentKey = key;
      if (key.startsWith("ui")) {
        componentKey = `nz${key.substring(2)}`;
      }
      this.drawerComponentRef.instance[componentKey] = value;
    }
  }
}
