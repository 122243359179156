import { UXCustomizationType } from "@gtmhub/uxcustomization/models";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";

export class FavoritesFactory {
  public buildNavigationFavoritesRequestPaging(types: UXCustomizationType[]): RequestPaging {
    const requestPaging: RequestPaging = {
      ...new RequestPaging(),
      filter: {
        type: {
          $in: types,
        },
      },
    };
    return requestPaging;
  }
}
