import { IAngularEvent, IRootScopeService, IScope } from "angular";
import { IModalScope } from "angular-ui-bootstrap";
import { Session } from "@webapp/sessions/models/sessions.model";

export enum PlanningSessionEventType {
  SESSION_VISITED = "SESSION_VISITED",
  SESSION_UPDATED = "SESSION_UPDATED",
  SESSION_DELETED = "SESSION_DELETED",
}

export class PlanningSessionEvents {
  private $rootScope: IRootScopeService;
  constructor(private $scope: IModalScope | IScope) {
    this.$rootScope = this.$scope.$root;
  }

  public broadcastSessionVisited(session: Session): void {
    this.$rootScope.$broadcast(PlanningSessionEventType.SESSION_VISITED, {
      session,
    });
  }

  public broadcastSessionUpdated(session: Session): void {
    this.$rootScope.$broadcast(PlanningSessionEventType.SESSION_UPDATED, {
      session,
    });
  }

  public broadcastSessionDeleted(id: string): void {
    this.$rootScope.$broadcast(PlanningSessionEventType.SESSION_DELETED, {
      id,
    });
  }

  public onSessionUpdated(...callbacks: ((event: IAngularEvent, session: Session) => void)[]) {
    return this.$rootScope.$on(PlanningSessionEventType.SESSION_UPDATED, (event, { session }: { session: Session }) => {
      callbacks.forEach((cb) => cb(event, session));
    });
  }
}
