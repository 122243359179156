import { DOWN_ARROW, ENTER, ESCAPE, TAB, UP_ARROW } from "@angular/cdk/keycodes";
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiPlacementType } from "@quantive/ui-kit/dropdown";
import { Subject, map, of, take, zip } from "rxjs";
import { DropdownMenuItem } from "@webapp/shared/dropdown/dropdown.models";
import { FocusTarget, getFocusTargetIndex } from "./util/navigation.util";

/**
 * @example <gh-dropdown [menuItems]="this.contextMenuItems"><span gh-dropdown-toggle class="gh-font-icon-menu"></span></gh-dropdown>
 */
@UntilDestroy()
@Component({
  // This needs to be refactored, but since it is widely used I will leave its selector as-is
  // eslint-disable-next-line webapp/no-component-selector-prefix
  selector: "gh-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.less"],
})
export class DropdownComponent implements OnChanges {
  private static dropdownId = 1;

  @Output()
  public readonly dropdownToggled = new EventEmitter<boolean>();

  @Output()
  public readonly selectedItem = new EventEmitter<DropdownMenuItem>();

  /**
   *  The suffix which will serve in the construction of the data-test-id
   */
  @Input()
  public testIdSuffix: string;

  /**
   *  The items to be displayed in the dropdown
   */
  @Input()
  public menuItems: DropdownMenuItem[];

  /**
   *  @param dropdownMenuClass The css class to be applied to the ul of items
   */
  @Input()
  public dropdownMenuClass: string;

  /**
   *  @param uiOverlayClassName Optional, class name of the ui-dropdown directive root element
   */
  @Input()
  public uiOverlayClassName?: string;

  /**
   * @param iconName Optional, used to render the icon in the dropdown button
   */
  @Input()
  public iconName?: string | undefined;

  /**
   * @param hasCustomButtonContent Optional, used to flag whether we should render the 'gh-dropdown-custom-button-content' instead of the default ui-button.
   */
  @Input()
  public hasCustomButtonContent?: boolean;

  /**
   * @param placement Used to indicate the dropdown placement, if not provided the default placement is "bottomLeft".
   */
  @Input()
  public placement: UiPlacementType = "bottomLeft";

  /**
   * @param disabled Optional, if true - disables the dropdown menu.
   */
  @Input()
  public disabled?: boolean;

  @Input()
  public tabIndex?: number | string | null = 0;

  @Input()
  public disableItemAutoFocusOnDropdownOpen = false;

  @Input()
  public showNewDesign = false;

  @Input()
  public a11yLabel: string;

  @Input()
  public e2eTestId: string;

  @ViewChild("buttonContainer") public buttonContainer: ElementRef<HTMLDivElement>;

  public currentActiveItem: DropdownMenuItem;
  public currentActiveItemIndex: number | null = null;
  public activeDropdownItemChange$: Subject<string> = new Subject<string>();
  public visible: boolean;
  public dropdownButtonId = DropdownComponent.dropdownId++;

  /**
   * An array map of the focusabilty state of the `menuItems`. Each boolean value of this array represents the focus-availability state of the corresponding menu item.
   * E.g. [true, true, false, true] would indicate the first, second and fourth items from `menuItems` can be focused, while the third is not focusable.
   */
  private itemsFocusAvailabilityMap: boolean[] = [];

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.menuItems) {
      this.setMenuItemsFocusAvailabilityMap();
    }
  }

  // TODO: https://quantive-inc.atlassian.net/browse/GVS-26903 This method is not fired - replace it with onVisibleChange and verify new navigation behaviour
  public onDropdownToggled(value: boolean): void {
    this.dropdownToggled.emit(value);
  }

  public onVisibleChange(isVisible: boolean): void {
    this.onDropdownToggled(isVisible);

    if (isVisible && !this.disableItemAutoFocusOnDropdownOpen) {
      this.setActiveIndex(FocusTarget.First);
    } else {
      this.currentActiveItem = null;
      this.currentActiveItemIndex = null;
    }
  }

  public onKeyDown(event: KeyboardEvent): void {
    switch (event.keyCode || event.code) {
      case DOWN_ARROW:
        this.setActiveIndex(FocusTarget.Next);
        event.preventDefault();
        break;
      case UP_ARROW:
        this.setActiveIndex(FocusTarget.Previous);
        event.preventDefault();
        break;
      case ENTER:
        // otherwise the popup menu won't close sometimes
        event.preventDefault();

        this.activeDropdownItemChange$.next(this.currentActiveItem.key);
        this.closeMenuAndEmitSelected(this.menuItems.find((item) => item.key === this.currentActiveItem.key));
        this.setFocusToDropdownButton();

        if (this.visible) {
          window.setTimeoutOutsideAngular(() => {
            this.closeMenuAndEmitSelected(null);
            this.setFocusToDropdownButton();
          });
        }
        break;
      case ESCAPE:
      case TAB:
        this.closeMenuAndEmitSelected(null);
        this.setFocusToDropdownButton();
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  public setFocusToDropdownButton(): void {
    // We need this in ordered to return the focus to the dropdown button after an item is selected, a modal is opened and then closed
    // Since we can't put a selector on the `ng-content` tag, the `buttonContainer` is on the container element, so we have to select the child button element
    if (this.buttonContainer) {
      const uiButton: HTMLElement = this.buttonContainer.nativeElement.querySelector("ui-button");

      if (uiButton) {
        uiButton.focus();
      } else {
        const button = this.buttonContainer.nativeElement.querySelector("button");
        button?.focus();
      }
    }
  }

  public isItemActive(item: DropdownMenuItem): boolean {
    return item.key === this.currentActiveItem?.key;
  }

  public closeMenuAndEmitSelected(item: DropdownMenuItem): void {
    this.visible = false;
    this.onVisibleChange(false);
    this.selectedItem.emit(item);
  }

  private setActiveIndex(focusTarget: FocusTarget): void {
    const focusTargetIndex = getFocusTargetIndex(this.currentActiveItemIndex, focusTarget, this.itemsFocusAvailabilityMap);
    if (focusTargetIndex !== -1) {
      this.currentActiveItemIndex = focusTargetIndex;
      this.currentActiveItem = this.menuItems[focusTargetIndex];
    }
  }

  private setMenuItemsFocusAvailabilityMap(): void {
    zip(
      (this.menuItems || []).map((item) =>
        (item.isVisible$ || of(true)).pipe(
          map((isVisible) => isVisible && !item.skipFromTabOrder && !item.disabled),
          take(1),
          untilDestroyed(this)
        )
      )
    ).subscribe((itemsFocusAvailabilityMap: boolean[]) => (this.itemsFocusAvailabilityMap = itemsFocusAvailabilityMap));
  }
}
