import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { RichTextEditorIconEnum } from "@webapp/shared/rich-text-editor/rich-text-editor-icon/rich-text-editor-icon.enum";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "rich-text-editor-icon",
  templateUrl: "./rich-text-editor-icon.component.html",
  styleUrls: ["./rich-text-editor-icon.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextEditorIconComponent {
  @Input() public icon: RichTextEditorIconEnum;
  @Input() public outOfFocus = false;
  @Input() public disabled = false;

  public types = RichTextEditorIconEnum;
}
