import { storage } from "@gtmhub/core/storage";
import { IOkrGridColumn } from "@gtmhub/goals/models";
import { IdMap } from "@gtmhub/util";
import { PersistedAlignmentDataPathParams } from "@webapp/okrs/alignment-tree/models";
import { IGHGridColumn } from "../../grid/grid.component";
import { IGridFilter, OrFiltersGroup } from "../grid-filters/models";
import { hasFilterValue } from "../grid-filters/util";
import { IGridSortingRule } from "../grid-sorting/models";

export const screensSettingsStorageKey = "screensSettings";

export interface IGoalsSharedViewOptions {
  includeKeyResults?: boolean;
  includeRelatedItems?: boolean;
  isNestedView?: boolean;
  useScrollbars?: boolean;
  useDragMode?: boolean;
  okrGridColumns?: IOkrGridColumn[];
}

interface IScreenSettings {
  filters?: OrFiltersGroup[];
  sorting?: IGridSortingRule[];
  viewOptions?: IGoalsSharedViewOptions; // old implementation - to be deleted https://gtmhub.atlassian.net/browse/GVS-6367
  sharedViewOptions?: IGoalsSharedViewOptions;
  expandedGoals?: IdMap<boolean>;
  expandedSelfIds?: IdMap<boolean>;
  collapsedIds?: IdMap<boolean>;
  customFieldsGridColumns?: IGHGridColumn[];
  activityHistoryGridColumns?: IGHGridColumn[];
  okrViewsListGridColumns?: IGHGridColumn[];
  okrViewsListFilters?: OrFiltersGroup[];
  okrViewsListSortingRules?: IGridSortingRule[];
  persistedSettings?: { version: number };
  tagsGridColumns?: IGHGridColumn[];
}

type ScreenSettings = Record<string, IScreenSettings>;
type PersistedSettings = ScreenSettings | Record<string, ScreenSettings>;

export const getPersistedScreenSettings = <S extends keyof IScreenSettings>(
  screenPath: PersistedAlignmentDataPathParams["screenPath"],
  settingKey: S
): IScreenSettings[S] | undefined => {
  const allSettings = getAllSettingsOrEmpty();
  return tryGetScreenSettings(allSettings, screenPath, settingKey);
};

export const setPersistedScreenSettings = <S extends keyof IScreenSettings>(
  screenPath: PersistedAlignmentDataPathParams["screenPath"],
  settingKey: S,
  settings: IScreenSettings[S]
): void => {
  if (settingKey === "filters") {
    persistFiltersScreenSettings(settings as OrFiltersGroup[], screenPath);
  } else {
    persistObjectScreenSettings(settings, screenPath, settingKey);
  }
};

export const resetPersistedScreenSettings = (screenPath: PersistedAlignmentDataPathParams["screenPath"], settingKey: keyof IScreenSettings) =>
  setPersistedScreenSettings(screenPath, settingKey, null);

const persistFiltersScreenSettings = (filters: OrFiltersGroup[], screenPath: PersistedAlignmentDataPathParams["screenPath"]): void => {
  const allSettings = getAllSettingsOrEmpty();
  const screenSettings = getOrCreateScreenSettings(allSettings, screenPath);

  const filtersToPersist: OrFiltersGroup[] = [];

  for (const filterGroup of filters || []) {
    const strippedFiltersGroup = angular
      .copy(filterGroup || [])
      .map((filter) => stripEvaluatedFromDynamicValue(filter))
      .filter((filter) => hasFilterValue(filter));

    if (strippedFiltersGroup.length) {
      filtersToPersist.push(strippedFiltersGroup);
    }
  }

  if (filtersToPersist && filtersToPersist.length) {
    screenSettings.filters = filtersToPersist;
  } else {
    delete screenSettings.filters;
    removeEmptySettingsEntries(allSettings, screenPath);
  }

  setSettingsInStorage(allSettings);
};

const persistObjectScreenSettings = <S extends keyof IScreenSettings>(
  settings: IScreenSettings[S],
  screenPath: PersistedAlignmentDataPathParams["screenPath"],
  settingKey: S
): void => {
  const allSettings = getAllSettingsOrEmpty();
  const screenSettings = getOrCreateScreenSettings(allSettings, screenPath);
  const settingsToPersist = angular.copy(settings);

  if (settingKey === "sorting") {
    // clean old implementation that was using the same screenPath
    delete screenSettings.viewOptions;
  }

  if (settingsToPersist && Object.values(settingsToPersist).length) {
    screenSettings[settingKey] = settingsToPersist;
  } else {
    delete screenSettings[settingKey];
    removeEmptySettingsEntries(allSettings, screenPath);
  }

  setSettingsInStorage(allSettings);
};

const getAllSettingsOrEmpty = (): PersistedSettings => storage.get<PersistedSettings>(screensSettingsStorageKey) || {};

const tryGetScreenSettings = <S extends keyof IScreenSettings>(
  settings: PersistedSettings,
  screenPath: PersistedAlignmentDataPathParams["screenPath"],
  settingKey: S
): IScreenSettings[S] | undefined => {
  for (const name of screenPath) {
    if (!settings[name]) {
      return;
    }

    settings = settings[name] as PersistedSettings;
  }

  // if there are filters persisted using the old model(OrFiltersGroup = IGridFilter[]) ->
  // wrap them in an array to match the new model(OrFiltersGroup[]),
  // persist them and update the settings to be returned below
  if (settingKey === "filters" && shouldTransformFiltersIntoTwoDimensionalArray(settings)) {
    const oldModelFilters: OrFiltersGroup = (<unknown>(settings as IScreenSettings)[settingKey]) as OrFiltersGroup;
    const newModelFilters: OrFiltersGroup[] = [oldModelFilters];
    (settings.filters as OrFiltersGroup[]) = newModelFilters;
    persistFiltersScreenSettings(newModelFilters, screenPath);
  }

  return (settings as IScreenSettings)[settingKey];
};

/**
 * Checks if the filters are persisted as OrFiltersGroup(=IGridFilter[]) using the old model of persisting
 * @returns boolean whether the filters are persisted as OrFiltersGroup(=IGridFilter[])
 */
function shouldTransformFiltersIntoTwoDimensionalArray(settings: PersistedSettings): boolean {
  return Array.isArray(settings.filters) && !Array.isArray(settings.filters[0]);
}

const getOrCreateScreenSettings = (settings: PersistedSettings, screenPath: PersistedAlignmentDataPathParams["screenPath"]): IScreenSettings => {
  for (const name of screenPath) {
    if (!settings[name]) {
      settings[name] = {};
    }

    settings = settings[name] as PersistedSettings;
  }

  return settings;
};

const stripEvaluatedFromDynamicValue = (filter: IGridFilter): IGridFilter => {
  if ("dynamicValues" in filter && Array.isArray(filter.dynamicValues)) {
    for (const dynamicValue of filter.dynamicValues) {
      delete dynamicValue.evaluated;
    }
  }

  return filter;
};

const removeEmptySettingsEntries = (settings: PersistedSettings, screenPath: PersistedAlignmentDataPathParams["screenPath"]): void => {
  const pathsWithContainers: Array<{ path: string; container: PersistedSettings }> = [];
  let nextPathContainer: ScreenSettings | Record<string, ScreenSettings> = settings;
  for (const path of screenPath) {
    pathsWithContainers.push({ path, container: nextPathContainer });
    nextPathContainer = nextPathContainer[path] as ScreenSettings | Record<string, ScreenSettings>;
  }

  for (const { path, container } of pathsWithContainers.reverse()) {
    if (!Object.keys(container[path]).length) {
      delete container[path];
    } else {
      break;
    }
  }
};

const setSettingsInStorage = (settings: PersistedSettings): void => {
  if (Object.keys(settings).length) {
    storage.set<PersistedSettings>(screensSettingsStorageKey, settings);
  } else {
    storage.remove(screensSettingsStorageKey);
  }
};
