import { Directive, Input } from "@angular/core";
import { NgModel } from "@angular/forms";
import { NzSafeAny } from "ng-zorro-antd/core/types";

/**
 * This directive is used to delay the ngModel change event.
 * It extends the NgModel directive and overrides the viewToModelUpdate method, which is called when the input value changes.
 *
 * @example - <input [(ngModel)]="model" [modelChangeDebounce]="500" (ngModelChange)="modelChangeHandler($event)" />
 *
 * modelChangeDebounce - the time to delay the change event in milliseconds
 */

@Directive({
  selector: "[ngModel][modelChangeDebounce]",
  standalone: true,
})
export class ModelChangeDebounceDirective {
  @Input() public modelChangeDebounce: number | null = null;

  private debounceTimeoutTimer: NodeJS.Timeout = null;

  constructor(ngModel: NgModel) {
    const originalViewToModelUpdate = ngModel.viewToModelUpdate.bind(ngModel);
    ngModel.viewToModelUpdate = (newValue: NzSafeAny): void => {
      if (!this.modelChangeDebounce) {
        originalViewToModelUpdate(newValue);
        return;
      }

      clearTimeout(this.debounceTimeoutTimer);
      this.debounceTimeoutTimer = setTimeout(() => {
        originalViewToModelUpdate(newValue);
      }, this.modelChangeDebounce);
    };
  }
}
