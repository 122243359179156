import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { calculateSuggestionCardActionButtonsContainerWidth } from "@webapp/platform-intelligence/quantive-plus/utils/utils";

@Component({
  selector: "quantive-plus-wizard-card-error-inline",
  templateUrl: "./quantive-plus-wizard-card-error-inline.component.html",
  styleUrls: ["./quantive-plus-wizard-card-error-inline.component.less"],
})
export class QuantivePlusWizardCardErrorInlineComponent implements OnChanges {
  public areActionButtonsAvailable = true;
  public actionButtonsContainerWidth: number;

  @Input()
  public showActionButtons: boolean;
  @Input()
  public isRefreshBtnAvailable = true;
  @Input()
  public isRemoveBtnAvailable = true;
  @Input()
  public errorMessage: string;

  @Output()
  public readonly errorRefresh = new EventEmitter();
  @Output()
  public readonly errorRemove = new EventEmitter();

  public ngOnChanges(changes: SimpleChanges): void {
    this.isRefreshBtnAvailable = changes.isRefreshBtnAvailable ? changes.isRefreshBtnAvailable.currentValue : this.isRefreshBtnAvailable;
    this.isRemoveBtnAvailable = changes.isRemoveBtnAvailable ? changes.isRemoveBtnAvailable.currentValue : this.isRemoveBtnAvailable;

    const availableActionButtons = [this.isRefreshBtnAvailable, this.isRemoveBtnAvailable].filter(Boolean).length;

    if (!availableActionButtons) {
      this.areActionButtonsAvailable = false;

      return;
    }

    this.actionButtonsContainerWidth = calculateSuggestionCardActionButtonsContainerWidth({ availableActionButtons });
  }

  public onRefreshErrorClicked = (): void => {
    this.errorRefresh.emit();
  };

  public onRemoveErrorClicked = (): void => {
    this.errorRemove.emit();
  };
}
