import { StateService, UIRouterGlobals } from "@uirouter/angular";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable, catchError, throwError } from "rxjs";

const USER_DEACTIVATED_STATE = "userDeactivated";

@Injectable()
export class UserDeactivatedInterceptor implements HttpInterceptor {
  constructor(
    private stateService: StateService,
    private routerGlobals: UIRouterGlobals
  ) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        const error = response.error?.error;
        const isUserDeactivatedOrDeletedResponse =
          response.status === 403 && (error === "user deactivated" || error === "could not resolve the user with the provided claims");

        if (isUserDeactivatedOrDeletedResponse && !this.stateService.is(USER_DEACTIVATED_STATE) && !this.routerGlobals.transition) {
          const transactionID = request.headers["transaction-id"];
          this.stateService.go(USER_DEACTIVATED_STATE, { transactionID }, { reload: true });
          return EMPTY;
        }

        return throwError(() => response);
      })
    );
  }
}
