import { NgModule } from "@angular/core";
import { LocalizePipe } from "./pipes/localize.pipe";

@NgModule({
  imports: [],
  providers: [],
  declarations: [LocalizePipe],
  exports: [LocalizePipe],
})
export class LocalizationModule {}
