import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { ICollection } from "@webapp/core/core.models";
import {
  CheckInQuestionEndpointParams,
  CheckInSettingsEndpointParams,
  CheckInTemplateEndpointParams,
  PatchCheckInEndpointParams,
  TeamCheckInEndpointParams,
} from "../models/checkins-api.models";
import { CheckInDTO, CheckInQuestionDTO, CheckInSettingsDTO, CheckInTemplateDTO } from "../models/checkins-facade.models";
import { CheckIn, CheckInQuestion, CheckInSettings, CheckInTemplate } from "../models/checkins-models";
import { CheckInsApiService } from "./checkins-api.service";
import { CheckInsState } from "./checkins-state.service";

@Injectable({
  providedIn: "any",
})
export class CheckInsFacade extends BaseFacade<CheckIn, CheckInDTO, CheckInsState, CheckInsApiService> {
  constructor(state: CheckInsState, api: CheckInsApiService) {
    super(state, api);
  }

  public getCheckIns$(filter: RequestPaging): Observable<ICollection<CheckInDTO>> {
    return this.getAll$<ICollection<CheckInDTO>>(filter, { ...new RequestConfig(), url: this.apiService.getTeamCheckInsEndpoint() });
  }

  public getCheckInTemplate$(params: TeamCheckInEndpointParams): Observable<CheckInTemplateDTO> {
    return this.get$<CheckInTemplateDTO>("", { ...new RequestConfig(), url: this.apiService.getCheckInTemplateEndpoint(params) });
  }

  public getCheckInSettings$(filter: RequestPaging): Observable<ICollection<CheckInSettingsDTO>> {
    return this.getAll$<ICollection<CheckInSettingsDTO>>(filter, { ...new RequestConfig(), url: this.apiService.getCheckInSettingsEndpoint() });
  }

  public postCheckIn$(payload: Partial<CheckIn>): Observable<CheckInDTO> {
    return this.post$<CheckInDTO>(payload, { ...new RequestConfig(), url: this.apiService.getCheckInsEndpoint() });
  }

  public putCheckIn$(payload: Partial<CheckIn>, params: PatchCheckInEndpointParams): Observable<CheckInDTO> {
    return this.put$<CheckInDTO>(params.reflectionId, payload, { ...new RequestConfig(), url: this.apiService.putCheckInEndpoint(params) });
  }

  public patchCheckInSettings$({ payload, ...params }: CheckInSettingsEndpointParams & { payload: Partial<CheckInSettings> }): Observable<CheckInSettingsDTO> {
    return this.patch$<CheckInSettingsDTO>("", payload, { ...new RequestConfig(), url: this.apiService.patchCheckInSettingEndpoint(params) });
  }

  public patchCheckInTemplate$({ payload, ...params }: CheckInTemplateEndpointParams & { payload: Partial<CheckInTemplate> }): Observable<CheckInTemplateDTO> {
    return this.patch$<CheckInTemplateDTO>("", payload, { ...new RequestConfig(), url: this.apiService.patchCheckInTemplateEndpoint(params) });
  }

  public patchCheckInQuesion$({ payload, ...params }: CheckInQuestionEndpointParams & { payload: Partial<CheckInQuestion> }): Observable<CheckInQuestionDTO> {
    return this.patch$<CheckInQuestionDTO>("", payload, { ...new RequestConfig(), url: this.apiService.patchCheckInQuestionEndpoint(params) });
  }

  public postCheckInQuestion$({ payload, ...params }: CheckInTemplateEndpointParams & { payload: Partial<CheckInQuestion> }): Observable<CheckInQuestionDTO> {
    return this.post$<CheckInQuestionDTO>(payload, { ...new RequestConfig(), url: this.apiService.postCheckInQuestionEndpoint(params) });
  }

  public deleteCheckInQuestion$(params: CheckInQuestionEndpointParams): Observable<void> {
    return this.delete$<void>("", { ...new RequestConfig(), url: this.apiService.patchCheckInQuestionEndpoint(params) });
  }
}
