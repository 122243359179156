import { TeamsSearchConditionSettings, buildTeamsSearchRequest } from "@gtmhub/teams/utils/search-utils";
import { UsersSearchConditionSettings, buildUsersSearchRequest } from "@gtmhub/users/utils/search-utils";
import { Assignee, AssigneeSelectorType } from "@webapp/assignees/models/assignee.models";
import { ISearchRequestsBody } from "@webapp/search/models/search-api.models";
import { Search } from "@webapp/search/models/search.models";

type AssigneeSearchConditionSettings = UsersSearchConditionSettings & TeamsSearchConditionSettings;

export function buildAssigneesSearchRequests(assigneeType: AssigneeSelectorType, searchTerm: string, settings?: AssigneeSearchConditionSettings): ISearchRequestsBody[] {
  if (!assigneeType || assigneeType === "both") {
    return [buildTeamsSearchRequest(searchTerm, settings), buildUsersSearchRequest(searchTerm, settings)];
  }

  if (assigneeType === "user") {
    return [buildUsersSearchRequest(searchTerm, settings)];
  }

  if (assigneeType === "team") {
    return [buildTeamsSearchRequest(searchTerm, settings)];
  }

  return [];
}

export const searchItemsToAssignees = (searchItems: (Search<"teams"> | Search<"users">)[]): Assignee[] => {
  return searchItems.map<Assignee>((item) => {
    if (item.collectionName === "users") {
      return {
        ...item.fields,
        id: item.id,
        type: "user",
        name: item.fields.fullName,
        email: item.fields.auth0Cache.email,
        picture: item.fields.picture || (item.fields.auth0Cache && item.fields.auth0Cache.picture),
      };
    }

    return {
      ...item.fields,
      id: item.id,
      type: "team",
      name: item.fields.name,
      email: "",
      picture: item.fields.picture,
    };
  });
};
