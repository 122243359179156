import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { User, UsersDTO } from "../models/users.models";
import { UsersState } from "./users-state.service";

@Injectable({
  providedIn: "root",
})
export class UsersApiService extends BaseApiService<User, UsersDTO, UsersState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: UsersState) {
    super("users", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getActivateUsersEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}/activate`;
  }

  public getDeactivateUsersEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}/deactivate`;
  }

  public getDeleteUsersEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}/delete`;
  }

  public getUsersLimitEndpoint(): string {
    return `${this.getBasePath(HttpActions.get)}/limit`;
  }

  public getUsersV2Endpoint(): string {
    return `${this.getBasePath(HttpActions.get, { apiVersionOverwrite: "v2" })}`;
  }

  public getUserEndpoint(userId: string): string {
    return `${this.getBasePath(HttpActions.get)}/${userId}`;
  }

  public getUserPatchEndpoint(userId: string): string {
    return `${this.getBasePath(HttpActions.patch)}/${userId}`;
  }

  public getUserDeleteEndpoint(userId: string): string {
    return `/accounts/user/${userId}`;
  }

  public getUserSettingsEndpoint(): string {
    return `${this.getBasePath(HttpActions.get)}/settings`;
  }

  public getUsersAccountEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}/account`;
  }
}
