import { Directive, TemplateRef } from "@angular/core";

@Directive({
  selector: "[uiModalContent]",
  exportAs: "uiModalContent",
  standalone: true,
})
export class UiModalContentDirective {
  constructor(public templateRef: TemplateRef<object>) {}
}
