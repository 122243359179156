import { RawParams, StateOrName, TransitionOptions } from "@uirouter/angular";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { Observable, Subject, delay, of } from "rxjs";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { IOnboardingDefinition } from "@webapp/shared/components/onboarding/onboarding.models";
import { OnboardingModule } from "@webapp/shared/components/onboarding/onboarding.module";
import { SimpleChangesTyped } from "@webapp/shared/models";
import { IsInViewportDirective } from "../../../../shared/directives/is-in-viewport/is-in-viewport.directive";
import { PIContextualSuggestionButtonE2ETestType, PIContextualSuggestionButtonParentType } from "./models/pi-contextual-suggestion-button.models";
import { PiContextualSuggestionsOnboardingService, PiContextualSuggestionsOnboardingState } from "./services/pi-contextual-suggestions-onboarding.service";
import { PI_CONTEXTUAL_SUGGESTIONS_ONBOARDING_TOTAL_STEPS, PiContextualSuggestionsOnboardingStepsEnum } from "./utils/pi-contextual-suggestions-onboarding.utils";

@UntilDestroy()
@Component({
  selector: "pi-contextual-suggestion-button",
  templateUrl: "./pi-contextual-suggestion-button.component.html",
  styleUrls: ["./pi-contextual-suggestion-button.component.less"],
  standalone: true,
  imports: [CommonModule, UiIconModule, UiTooltipModule, LocalizationModule, OnboardingModule, IsInViewportDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PiContextualSuggestionButtonComponent implements OnChanges, OnInit, OnDestroy {
  @Input({ required: true }) public parentType: PIContextualSuggestionButtonParentType;
  @Input({ required: true }) public e2eTestButtonType: PIContextualSuggestionButtonE2ETestType;
  @Input() public isLoading: boolean;
  @Input() public showDummyLoading: boolean;
  @Input() public dummyLoadingDurationMs: number;
  @Input() public checkForActiveOnboarding: boolean;

  @Output() public readonly dummyLoadingCompleted = new EventEmitter<void>();
  @Output() public readonly toggle = new EventEmitter<void>();

  @HostBinding("attr.data-test-id") public get hostDataTestIdAttr(): string {
    return `pi-${this.e2eTestButtonType}-contextual-suggestion-button`;
  }

  public onboarding: IOnboardingDefinition;
  public approximateOnboardingBoxHeight = 180;
  private contextualButtonOnboardingStep = PiContextualSuggestionsOnboardingStepsEnum.contextualSuggestionButton;
  private isContextualButtonInViewport$ = new Subject<boolean>();
  private isContextualButtonInitiallyVisible: boolean;

  constructor(
    private piContextualSuggestionsOnboardingService: PiContextualSuggestionsOnboardingService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnChanges(changes: Partial<SimpleChangesTyped<PiContextualSuggestionButtonComponent>>): void {
    if (changes.showDummyLoading?.currentValue) {
      window.setTimeoutOutsideAngular(() => {
        this.showDummyLoading = false;
        this.dummyLoadingCompleted.emit();
        this.cdr.detectChanges();
      }, this.dummyLoadingDurationMs);
    }
  }

  public ngOnInit(): void {
    if (this.checkForActiveOnboarding) {
      this.handleOnboarding();
    }
  }

  public ngOnDestroy(): void {
    if (this.showDummyLoading) {
      this.dummyLoadingCompleted.emit();
    }
  }

  public getIsButtonInViewport$ = (): Observable<boolean> => {
    return this.isContextualButtonInViewport$.asObservable();
  };

  public onContextualButtonViewportVisibilityChange = (isVisible: boolean): void => {
    // set isContextualButtonInitiallyVisible only once
    if (typeof this.isContextualButtonInitiallyVisible === "undefined") {
      this.isContextualButtonInitiallyVisible = isVisible;
    }

    this.isContextualButtonInViewport$.next(isVisible);
  };

  public ensureOnboardingIsInViewport = (args: { isVisible: boolean; contextualSuggestionBtn: HTMLDivElement }): void => {
    if (!args.isVisible) {
      this.piContextualSuggestionsOnboardingService.setCurrentElShowingOnboarding(args.contextualSuggestionBtn);
    }
  };

  private handleOnboarding = (): void => {
    const shouldSeeOnboarding = this.piContextualSuggestionsOnboardingService.shouldSeeJourneyAccordingToMetadata();

    if (!shouldSeeOnboarding) {
      return;
    }

    this.initIsButtonInViewportSubscription();
  };

  private initIsButtonInViewportSubscription = (): void => {
    this.getIsButtonInViewport$()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (isVisible) => {
          if (!isVisible) {
            return;
          }

          this.initOnboardingSubscription();
        },
      });
  };

  private initOnboardingSubscription = (): void => {
    const onboardingSubscription = this.piContextualSuggestionsOnboardingService
      .getJourneyState$()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (onboardingState) => {
          if (onboardingState.stage === "PENDING_START") {
            this.piContextualSuggestionsOnboardingService.setOnboardingParentContextAndInitialize(this.parentType);

            return;
          }

          if (onboardingState.stage === "INITIALIZING") {
            this.initOnboarding(onboardingState);
          }

          this.setOnboardingVisibility(onboardingState);
        },
      });

    onboardingSubscription.add(() => this.piContextualSuggestionsOnboardingService.resetOnboardingState());
  };

  private initOnboarding = (onboardingState: PiContextualSuggestionsOnboardingState): void => {
    this.onboarding = {
      shouldShow: false,
      totalSteps: PI_CONTEXTUAL_SUGGESTIONS_ONBOARDING_TOTAL_STEPS,
      startingStep: this.contextualButtonOnboardingStep,
      delegate: {
        shouldSeeOnboarding: (): boolean => this.onboarding.shouldShow,
        getPrimaryActionForStep: (): {
          primaryCTA: string;
          primaryCTAHandler(): void;
        } => this.piContextualSuggestionsOnboardingService.getPrimaryActionForStep(this.contextualButtonOnboardingStep),
        getSecondaryActionForStep: (): {
          secondaryCTA: string;
          secondaryCTAHandler(): void;
        } => this.piContextualSuggestionsOnboardingService.getSecondaryActionForStep(this.contextualButtonOnboardingStep),
        getRedirectStateForStep: (): null | {
          to: StateOrName;
          params?: RawParams;
          options?: TransitionOptions;
        } => null,
        journeyClosed: (): unknown => this.piContextualSuggestionsOnboardingService.closeJourney(onboardingState),
      },
    };
  };

  private setOnboardingVisibility = (onboardingState: PiContextualSuggestionsOnboardingState): void => {
    const isContextualButtonStep = onboardingState.currentStep === this.contextualButtonOnboardingStep;
    const isParentContextMatching = onboardingState.parentContext === this.parentType;
    const isEndedByUser = onboardingState.stage === "ENDED_BY_USER";

    if (!isContextualButtonStep || !isParentContextMatching || isEndedByUser) {
      this.onboarding = {
        ...this.onboarding,
        shouldShow: false,
      };

      return;
    }

    const isInitializing = onboardingState.stage === "INITIALIZING";

    if (isInitializing) {
      this.piContextualSuggestionsOnboardingService.markAsShown();
    }

    const makeOnboardingVisible$ = isInitializing && this.isContextualButtonInitiallyVisible ? of(true).pipe(delay(1600)) : of(true);

    makeOnboardingVisible$.subscribe({
      next: () => {
        this.onboarding = {
          ...this.onboarding,
          shouldShow: true,
        };

        this.cdr.detectChanges();
      },
    });
  };
}
