import { Injector, NgZone } from "@angular/core";
import { BehaviorSubject, Observable, of, switchMap, take } from "rxjs";
import { localize } from "@webapp/localization/utils/localization.utils";
import { KrWithKPIAutomationComponent } from "@webapp/shared/components/item-automation-selector/components/kr-with-kpi-automation/kr-with-kpi-automation.component";
import { AutomatingKpiSelectionData, ItemAutomationSelectorDataType } from "@webapp/shared/components/item-automation-selector/models/item-automation-selector.models";
import { cancelButton, primaryButton } from "@webapp/shared/modal/modal.utils";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { ExtendWithWriteValueOriginCheck } from "../components/item-automation-selector/item-automation-selector.component";

export class AutomatingKpiSelectionManager {
  private ngZone: NgZone;
  private uiModalService: UiModalService;

  constructor(
    injector: Injector,
    private selectorData$: BehaviorSubject<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"kpi">>>
  ) {
    this.ngZone = injector.get(NgZone);
    this.uiModalService = injector.get(UiModalService);
  }

  public openKpiSelectorModal$(): Observable<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"kpi">>> {
    return new Observable<AutomatingKpiSelectionData>((observer) => {
      this.ngZone.run(() => {
        observer.add(
          this.uiModalService
            .create({
              uiTitle: localize("select_kpi_to_automate_with"),
              uiContent: KrWithKPIAutomationComponent,
              uiCentered: true,
              uiFooter: [
                primaryButton(localize("confirm"), {
                  disabled: (component: KrWithKPIAutomationComponent) => {
                    return !component.isKpiSelected;
                  },
                  onClick: (component: KrWithKPIAutomationComponent) => {
                    component.onConfirm();
                  },
                }),
                cancelButton(),
              ],
            })
            .afterClose.pipe(take(1))
            .subscribe({
              next: (selectedKpi: AutomatingKpiSelectionData) => {
                observer.next(selectedKpi);
              },
              error: (error) => {
                observer.error(error);
              },
              complete: () => {
                observer.complete();
              },
            })
        );
      });
    }).pipe(
      take(1),
      switchMap((selectedKpi) => this.mapActionParamsToKpiSelectorData(selectedKpi))
    );
  }

  private mapActionParamsToKpiSelectorData = (
    selectedKpi: AutomatingKpiSelectionData
  ): Observable<ExtendWithWriteValueOriginCheck<ItemAutomationSelectorDataType<"kpi">>> => {
    return of({
      data: {
        ...this.selectorData$.value.data,
        ...selectedKpi,
        selectionType: "kpi",
      },
      hasWriteValueOrigin: false,
    });
  };
}
