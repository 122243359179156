import { ComponentRef, EnvironmentInjector, Injectable } from "@angular/core";
import { CustomFieldSelectComponent } from "../../components/select/select.component";
import { CfDropdownUI, DropdownCustomFieldPredefinedValue, EditableCustomFieldsInput, ICustomField } from "../../models/custom-fields.models";
import { AbstractCustomFieldFactory } from "./abstract-custom-field.factory";

@Injectable()
export class SelectCustomFieldComponentFactory extends AbstractCustomFieldFactory<CustomFieldSelectComponent> {
  constructor(injector: EnvironmentInjector) {
    super(injector, CustomFieldSelectComponent);
  }

  protected setComponentInputs(
    component: ComponentRef<CustomFieldSelectComponent>,
    customField: ICustomField,
    editableCustomFieldInputs: EditableCustomFieldsInput
  ): void {
    const selectedValue = (customField.settings.predefinedValues as DropdownCustomFieldPredefinedValue[]).find((e) => {
      return e.value === editableCustomFieldInputs.values[customField.name];
    });

    component.setInput("a11yLabelledby", customField._id);
    component.setInput("options", customField.settings?.predefinedValues);
    component.setInput("fieldName", customField.name);
    component.setInput("uiId", customField.name);
    component.setInput("deselectOption", true);
    component.setInput("targetCustomFieldsMap", { [customField.name]: selectedValue });
    component.setInput("a11yRequired", !editableCustomFieldInputs.disableRequired && customField.settings.validations.required);
    component.setInput("disabled", editableCustomFieldInputs.disabled);
    component.setInput("readonly", editableCustomFieldInputs.readonly);
    component.instance.optionSelect.subscribe((updatedCustomField: { fieldName: string; cf: CfDropdownUI }) =>
      editableCustomFieldInputs.onChange({ fieldName: updatedCustomField.fieldName, customField: updatedCustomField.cf?.value })
    );
  }
}
