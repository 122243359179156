import { UIRouterGlobals } from "@uirouter/angular";
import { Injectable } from "@angular/core";
import { Observable, catchError, combineLatest, from, map, of, take } from "rxjs";
import { PlanningSessionService } from "@gtmhub/sessions/services/planning-session.service";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { HubWorkflowActions } from "@webapp/filters/models/hub-filter.models";
import { SubNavOption } from "../models/sub-nav-options.models";

@Injectable()
export class SessionsSubNavigationOptions {
  constructor(
    private featureTogglesFacade: FeatureTogglesFacade,
    private planningSessionService: PlanningSessionService,
    private routerGlobals: UIRouterGlobals,
    private editionFeatureService: EditionFeatureService
  ) {}

  public allSessions$(): Observable<SubNavOption> {
    return of({ label: "all_sessions", icon: "sessions", state: "gtmhub.sessions" });
  }

  public okrViews$(): Observable<SubNavOption> {
    return combineLatest([this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.OkrViews), this.editionFeatureService.hasFeature$("okrs.okr-views")]).pipe(
      take(1),
      map(([isOkrViewsFeatureEnabled, isOkrViewsFeatureIncludedInEdition]) => {
        if (!(isOkrViewsFeatureEnabled && isOkrViewsFeatureIncludedInEdition)) {
          return;
        }

        return { label: "okr_views", icon: "filter-view", state: "gtmhub.okrViews" };
      }),
      catchError(() => {
        return of(undefined);
      })
    );
  }

  public okrsForApproval$(): Observable<SubNavOption> {
    return from(this.planningSessionService.getCurrentUserActionsCount()).pipe(
      take(1),
      map((workflowActions: HubWorkflowActions) => {
        const state = this.routerGlobals.current.name;
        const isMsTeamsState = state === "msteams" || state === "msTeamsHome";

        if (!workflowActions.enabled || !workflowActions.totalActionsNeeded || isMsTeamsState) return;
        return { label: "okrs_for_approval", icon: "done", state: "gtmhub.workflow" };
      }),
      catchError(() => {
        return of(undefined);
      })
    );
  }
}
