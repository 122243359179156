import { Injectable } from "@angular/core";
import { Observable, map, zip } from "rxjs";
import { AccountOptionsVisibilityService } from "./account-options-visibility.service";
import { IntegrationsOptionsVisibilityService } from "./integrations-options-visibility.service";
import { SystemOptionsVisibilityService } from "./system-options-visibility.service";
import { UserOptionsVisibilityService } from "./user-options-visibility.service";

@Injectable()
export class SettingsVisibilityService {
  constructor(
    public systemOptions: SystemOptionsVisibilityService,
    public userOptions: UserOptionsVisibilityService,
    public integrationsOptions: IntegrationsOptionsVisibilityService,
    public accountOptions: AccountOptionsVisibilityService
  ) {}

  get showSettings$(): Observable<boolean> {
    return zip(this.accountOptions.anyOption$, this.userOptions.anyOption$, this.integrationsOptions.anyOption$, this.systemOptions.anyOption$).pipe(
      map(([accountOptions, userOptions, integrationsOptions, systemOptions]) => {
        return accountOptions || userOptions || integrationsOptions || systemOptions;
      })
    );
  }
}
