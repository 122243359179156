import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { Insight, InsightDTO } from "@webapp/data-story/models/insights.models";
import { InsightsState } from "@webapp/data-story/services/insights-facade/insights-state.service";

@Injectable({
  providedIn: "root",
})
export class InsightsApiService extends BaseApiService<Insight, InsightDTO, InsightsState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: InsightsState) {
    super("insights", { ...new ApiVersionModel("v2"), post: "v1", patch: "v1", delete: "v1" }, httpClient, appConfig, state);
  }

  public getInsightsEndpoint(): string {
    return this.getBasePath(HttpActions.get);
  }

  public requestInsightCalculationByIdEndpoint(insightId: string): string {
    return `${this.getBasePath(HttpActions.post)}/${insightId}/calculate`;
  }

  public requestInsightScriptCalculationEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}/execute`;
  }

  public getInsightSqlConditionOperatorsEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}/query/properties`;
  }

  public createInsightEndpoint(): string {
    return `${this.getBasePath(HttpActions.post)}`;
  }

  public patchInsightEndpoint(insightId: string): string {
    return `${this.getBasePath(HttpActions.patch)}/${insightId}`;
  }

  public getInsightEndpoint(name: string): string {
    return `${this.getBasePath(HttpActions.get)}/${name}`;
  }

  public getInsightSafeEndpoint(name: string): string {
    return `${this.getBasePath(HttpActions.get, { apiVersionOverwrite: "v1" })}/safe/${name}`;
  }

  public getInsightsSafeEndpoint(): string {
    return `${this.getBasePath(HttpActions.get, { apiVersionOverwrite: "v1" })}/safe`;
  }

  public getInsightDeleteEndpoint(name: string): string {
    return `${this.getBasePath(HttpActions.delete)}/${name}`;
  }

  public getInsightsMetricsEndpoint(): string {
    return `${this.getBasePath(HttpActions.get, { apiVersionOverwrite: "v1" })}/metrics`;
  }

  public getInsightChangeLogsEndpoint(insightId: string): string {
    return `${this.getBasePath(HttpActions.get, { apiVersionOverwrite: "v1" })}/${insightId}/changelogs`;
  }
}
