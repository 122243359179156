<ng-template>
  <ui-date-picker
    [uiId]="controlId"
    [a11yLabelledby]="labelId"
    [uiOpen]="open"
    [uiDisabledDate]="isDateDisabled"
    [uiBackdrop]="backdrop"
    [formControl]="formControl"
    [uiBorderless]="borderless"
    [uiAllowClear]="allowClear"
    [attr.data-test-id]="e2eTestId"></ui-date-picker>
</ng-template>
