import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  forwardRef,
} from "@angular/core";
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzTreeNodeOptions } from "ng-zorro-antd/tree";
import { Observable, take, tap } from "rxjs";
import { localize } from "@gtmhub/localization";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { ISessionListItem, ISessionTreeNode, TreeOptions } from "@webapp/sessions/models/session-tree.model";
import { SessionFilterFn } from "@webapp/sessions/models/sessions.model";
import { SessionSelectorFacade } from "@webapp/sessions/services/session-selector-facade.service";
import { SessionSelectorUtilsService } from "@webapp/sessions/services/session-selector-utils.service";
import { IndicatorModule } from "@webapp/shared/indicator/indicator.module";
import { UiFormModule } from "@webapp/ui/form/form.module";
import { UiGridModule } from "@webapp/ui/grid/grid.module";
import { UiTreeSelectModule } from "@webapp/ui/tree-select/tree-select.module";

const SESSION_SELECTOR_CONTROL_VALUE_ACCESSOR = { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SessionSelectorComponent), multi: true };

/**
 * @example
 *  <gh-session-selector
 *   [value]="currentSessionId"
 *   [treeOptions]="{ alignmentType: 'parent-to-child', sourceSessionId: sourceGoalOrMetricSessionId}"
 *   (valueChange)="onSelect($event)"
 *  ></gh-session-selector>
 */
@UntilDestroy()
@Component({
  // There is an Angular.js component 'session-selector' and in session-selector.global.less we defined
  // global styles for this one, so we need to distinguish between the two components
  // eslint-disable-next-line webapp/no-component-selector-prefix
  selector: "gh-session-selector",
  templateUrl: "session-selector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SESSION_SELECTOR_CONTROL_VALUE_ACCESSOR, SessionSelectorFacade, SessionSelectorUtilsService],
  standalone: true,
  imports: [CommonModule, LocalizationModule, IndicatorModule, UiTreeSelectModule, ReactiveFormsModule, UiGridModule, UiFormModule],
})
export class SessionSelectorComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() public a11yLabelledby: string;
  @Input() public a11yRequired = false;
  @Input() public a11yDescribedby: string;
  @Input() public a11yDescription: string;
  @Input() public treeOptions: TreeOptions = { type: "available-sessions", sourceSessionId: null };
  @Input() public autofocus = false;
  @Input() public placeholderText = localize("search_for_session");
  @Input() public borderless: boolean;
  @Input() public uiId: string;
  @Input() public focusMe: boolean;
  /**
   * Disables editing, focusing, and submitting the input.
   */
  @Input() @InputBoolean() public uiDisabled = false;
  /**
   * Disables editing (the field is still focusable).
   */
  @Input() @InputBoolean() public readonly = false;

  /**
   * Specifies the selected session ID.
   */
  @Input() public value: string;

  @Input() public sessionsFilter: SessionFilterFn;

  /**
   * Emits when a new session ID is selected.
   */
  @Output() public readonly valueChange = new EventEmitter<string>();

  @HostBinding("class.borderless") public get borderlessClass(): boolean {
    return this.borderless;
  }

  public data: NzTreeNodeOptions[];
  public inputValue: string;
  public sessionForm: FormGroup;
  public isLoading$: Observable<boolean> = this.sessionSelectorFacade.isLoading$();
  public isTreeFlatten: boolean;
  public hasGrandParentAlignmentFeature: boolean;
  private selectedIds: string[];
  private tree: ISessionTreeNode[] = [];
  private list: ISessionListItem[] = [];
  private notifyControlChange: (value: string) => void;

  constructor(
    private sessionSelectorFacade: SessionSelectorFacade,
    private formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private editionFeatureService: EditionFeatureService,
    private sessionSelectorUtilsService: SessionSelectorUtilsService
  ) {
    // form used in create okr form so allowing only single select for now
    this.sessionForm = this.formBuilder.group({
      id: this.formBuilder.control(""),
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      const value = this.value ?? "";
      this.sessionForm.controls.id.setValue(value, { emitEvent: false });
    }
    this.getData();
  }

  public ngOnInit(): void {
    this.initAlignmentType$().subscribe(() => {
      this.initSessionForm();
      this.getData();
    });
  }

  private initSessionForm(): void {
    this.sessionForm.controls.id.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value !== this.value) {
        this.value = value;
        this.valueChange.emit(value);
        this.notifyControlChange?.(value);
      }
    });
  }

  private initAlignmentType$(): Observable<boolean> {
    return this.editionFeatureService.hasFeature$("grandparent-session-alignment").pipe(
      untilDestroyed(this),
      take(1),
      tap((hasFeature) => {
        this.hasGrandParentAlignmentFeature = hasFeature;
      })
    );
  }

  public getData(): void {
    if (!this.sessionsFilter) {
      this.sessionsFilter = this.sessionSelectorUtilsService.filterOpenSessionsInWhichUserHasRightToCreate;
    }

    this.sessionSelectorFacade
      .getSessionData$({
        treeOptions: this.treeOptions,
        hasGrandParentAlignmentFeature: this.hasGrandParentAlignmentFeature,
        sessionsFilter: this.sessionsFilter,
      })
      .pipe(untilDestroyed(this))
      .subscribe((results) => {
        this.tree = results.tree;
        this.list = results.list;
        this.data = this.tree;
        this.isTreeFlatten = false;

        this.changeDetector.markForCheck();
      });
  }

  public onOpenChange(): void {
    this.data = this.tree;
    this.isTreeFlatten = false;
  }

  public onInputValueChange(value: string): void {
    this.inputValue = value;
    this.setNodesStructure();
  }

  public searchFunction = (node: NzTreeNodeOptions): boolean => {
    return node.title.toLowerCase().includes(this.inputValue.toLowerCase());
  };

  public onSelectedValueChange(value: string[]): void {
    this.selectedIds = value;
    this.list.forEach((item) => {
      item.selected = this.selectedIds.includes(item.key);
    });

    this.tree.forEach((parentNode) => {
      this.setIsNodeSelected(parentNode);
    });
  }

  public writeValue(value: string): void {
    const normalizedValue = value ?? "";
    if (normalizedValue !== this.sessionForm.controls.id.value) {
      this.value = normalizedValue;
      this.sessionForm.controls.id.setValue(normalizedValue, { emitEvent: false });
      // ngOnChanges in not called here, so we need to call getData() manually
      this.getData();

      this.changeDetector.markForCheck();
    }
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.notifyControlChange = fn;
  }

  public registerOnTouched(): void {
    // required by the ControlValueAccessor interface
  }

  private setIsNodeSelected(parentNode: ISessionTreeNode): void {
    parentNode.selected = this.selectedIds.includes(parentNode.key);
    if (!parentNode.children.length) {
      return;
    }

    parentNode.children.forEach((childNode) => {
      this.setIsNodeSelected(childNode);
    });
  }

  private setNodesStructure(): void {
    if (this.inputValue.length) {
      this.data = this.list;
      this.isTreeFlatten = true;
    } else {
      this.data = this.tree;
      this.isTreeFlatten = false;
    }
  }
}
