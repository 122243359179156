import { FocusMonitor } from "@angular/cdk/a11y";
import { Directionality } from "@angular/cdk/bidi";
import { BACKSPACE, DOWN_ARROW, END, ENTER, ESCAPE, HOME, LEFT_ARROW, RIGHT_ARROW, SPACE, TAB, UP_ARROW } from "@angular/cdk/keycodes";
import { CdkConnectedOverlay, CdkOverlayOrigin, OverlayModule } from "@angular/cdk/overlay";
import { Platform } from "@angular/cdk/platform";
import { NgIf, NgStyle } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  DoCheck,
  ElementRef,
  EventEmitter,
  Host,
  Input,
  NgZone,
  OnInit,
  Optional,
  Output,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  forwardRef,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { UiI18nService } from "@quantive/ui-kit/i18n";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzConfigService, WithConfig } from "ng-zorro-antd/core/config";
import { NzFormNoStatusService, NzFormStatusService } from "ng-zorro-antd/core/form";
import { NzNoAnimationDirective } from "ng-zorro-antd/core/no-animation";
import { NzDestroyService } from "ng-zorro-antd/core/services";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { InputBoolean, isNotNil } from "ng-zorro-antd/core/util";
import { NzSelectComponent, NzSelectItemInterface } from "ng-zorro-antd/select";
import { UiOptionGroupComponent } from "@webapp/ui/select/components/option-group/option-group.component";
import { UiOptionComponent } from "@webapp/ui/select/components/option/option.component";
import { UiSelectTopControlComponent } from "@webapp/ui/select/components/select-top-control/select-top-control.component";
import {
  UiFilterOptionType,
  UiSelectItemInterface,
  UiSelectModeType,
  UiSelectOptionInterface,
  UiSelectPlacementType,
  UiSelectSizeType,
} from "@webapp/ui/select/select.models";
import { uiSelectDefaultFilterOption } from "@webapp/ui/select/utils/select.utils";
import { UiOptionContainerComponent } from "./components/option-container/option-container.component";
import { UiSelectArrowComponent } from "./components/select-arrow/select-arrow.component";
import { UiSelectClearComponent } from "./components/select-clear/select-clear.component";
import { UiSelectErrorComponent } from "./components/select-error/select-error.component";

let nextUniqueId = 0;
@Component({
  selector: "ui-select",
  exportAs: "uiSelect",
  preserveWhitespaces: false,
  templateUrl: "select.component.html",
  styleUrls: ["./select.component.less"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiSelectComponent),
      multi: true,
    },
    NzDestroyService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    "[class.select-readonly]": "readonly",
  },
  standalone: true,
  imports: [
    UiSelectTopControlComponent,
    OverlayModule,
    UiSelectErrorComponent,
    UiSelectArrowComponent,
    UiSelectClearComponent,
    UiOptionContainerComponent,
    NgStyle,
    NgIf,
    UiIconModule,
  ],
})
export class UiSelectComponent extends NzSelectComponent implements OnInit, DoCheck {
  @Input() public a11yRequired = false;
  /** Aria label of the select. */
  @Input() public a11yLabel = "";
  @Input() public a11yDescription: string;
  @Input() public a11yDescribedby: string;
  /** Input that can be used to specify the `aria-labelledby` attribute. */
  @Input() public a11yLabelledby: string;
  @Input("uiId") public nzId: string | null = null;
  @Input("uiSize") public nzSize: UiSelectSizeType = "default";
  @Input("uiOptionHeightPx") public nzOptionHeightPx = 36;
  @Input("uiOptionOverflowSize") public nzOptionOverflowSize = 8;
  @Input("uiDropdownClassName") public nzDropdownClassName: string | null = null;
  @Input("uiDropdownMatchSelectWidth") public nzDropdownMatchSelectWidth = true;
  @Input("uiDropdownStyle") public nzDropdownStyle: { [key: string]: string } | null = null;
  @Input("uiNotFoundContent") public nzNotFoundContent: string | TemplateRef<NzSafeAny> | undefined = undefined;
  @Input("uiPlaceHolder") public nzPlaceHolder: string | TemplateRef<NzSafeAny> | null = null;
  @Input("uiPlacement") public nzPlacement: UiSelectPlacementType | null = null;
  @Input("uiMaxTagCount") public nzMaxTagCount = Infinity;
  @Input("uiDropdownRender") public nzDropdownRender: TemplateRef<NzSafeAny> | null = null;
  @Input() public uiPlaceHolderTemplate: TemplateRef<{ $implicit: UiSelectItemInterface }> | null = null;
  @Input("uiCustomTemplate") public nzCustomTemplate: TemplateRef<{ $implicit: UiSelectItemInterface }> | null = null;
  @Input("uiSuffixIcon")
  @WithConfig<TemplateRef<NzSafeAny> | string | null>()
  public nzSuffixIcon: TemplateRef<NzSafeAny> | string | null = null;
  @Input("uiClearIcon") public nzClearIcon: TemplateRef<NzSafeAny> | null = null;
  @Input("uiRemoveIcon") public nzRemoveIcon: TemplateRef<NzSafeAny> | null = null;
  @Input("uiMenuItemSelectedIcon") public nzMenuItemSelectedIcon: TemplateRef<NzSafeAny> | null = null;
  @Input("uiTokenSeparators") public nzTokenSeparators: string[] = [];
  @Input("uiMaxTagPlaceholder") public nzMaxTagPlaceholder: TemplateRef<{ $implicit: NzSafeAny[] }> | null = null;
  @Input("uiMaxMultipleCount") public nzMaxMultipleCount = Infinity;
  /**
   * default - Single selection mode
   * multiple - Multiple selection mode
   * tags - Multiple selection mode with the ability to create new options when typing on enter press
   */
  @Input("uiMode") public nzMode: UiSelectModeType = "default";
  @Input("uiFilterOption") public nzFilterOption: UiFilterOptionType = uiSelectDefaultFilterOption;
  @Input("uiCompareWith") public compareWith: (o1: NzSafeAny, o2: NzSafeAny) => boolean = (o1: NzSafeAny, o2: NzSafeAny) => o1 === o2;
  @Input("uiAllowClear") @InputBoolean() public nzAllowClear = false;
  @Input("uiBorderless") @WithConfig<boolean>() @InputBoolean() public nzBorderless = false;
  @Input("hideSelectedTags") @InputBoolean() public hideSelectedTags = false;
  @Input("uiShowSearch") @InputBoolean() public nzShowSearch = true;
  @Input("uiLoading") @InputBoolean() public nzLoading = false;
  @Input("uiAutoFocus") @InputBoolean() public nzAutoFocus = false;
  @Input("uiAutoClearSearchValue") @InputBoolean() public nzAutoClearSearchValue = true;
  @Input("uiServerSearch") @InputBoolean() public nzServerSearch = false;
  /**
   * Disables editing, focusing, and submitting the input.
   */
  @Input("uiDisabled") @InputBoolean() public nzDisabled = false;
  /**
   * Disables editing (the field is still focusable).
   */
  @Input() @InputBoolean() public readonly = false;
  @Input("uiOpen") @InputBoolean() public nzOpen = false;
  @Input("uiBackdrop") @WithConfig<boolean>() @InputBoolean() public nzBackdrop = false;
  @Input("uiOptions") public nzOptions: UiSelectOptionInterface[] = [];
  @Input("uiShowEmptyDropdown") public showEmptyDropdown = true;
  @Input("uiSelectSearchInputTabIndex") public selectSearchInputTabIndex: number;
  @Input("uiDeselectOption") public deselectOption: boolean;
  @Input() public reserveScrollbarSpace: boolean;
  @Input() public problematicSelectItems: string[] = [];
  @Input() public errorOcurred: boolean;

  /**
   * Controls whether the a selected tick icon will be rendered at the end of each list item.
   */
  @Input() public renderSelectedListItemIcon = true;

  /**
   * Controls whether the default remove chip X icon at the end of each selected item when in multiple selection mode will be rendered.
   * Can be used to render complete custom template for selected items.
   */
  @Input() public renderRemoveChipIcon = true;

  /**
   * Specifies whether the default paddings and margins will be rendered around the selected value content.
   */
  @Input() public contentOnly = false;

  /**
   * Specifies whether the provided value and placeholder templates overflows should be cut by the upper container.
   * In some cases you want visible overflow - e.g. outline on buttons.
   */
  @Input() public visibleTemplateOverflow = false;
  /**
   * Disables options in cases when we use other componens for the dropdown.
   */
  @Input("uiShowOptions") public showOptions = true;

  @Input("uiShowArrow")
  set selectShowArrow(value: boolean) {
    this.nzShowArrow = value;
  }
  get selectShowArrow(): boolean {
    return this.nzShowArrow;
  }

  @Output("uiOnSearch") public readonly nzOnSearch = new EventEmitter<string>();
  @Output("uiScrollToBottom") public readonly nzScrollToBottom = new EventEmitter<void>();
  @Output("uiOpenChange") public readonly nzOpenChange = new EventEmitter<boolean>();
  @Output("uiBlur") public readonly nzBlur = new EventEmitter<void>();
  @Output("uiFocus") public readonly nzFocus = new EventEmitter<void>();
  @Output("customEvent") public readonly customEvent = new EventEmitter<void>();

  @ViewChild(CdkOverlayOrigin, { static: true, read: ElementRef }) public originElement!: ElementRef;
  @ViewChild(CdkConnectedOverlay, { static: true }) public cdkConnectedOverlay!: CdkConnectedOverlay;
  @ViewChild(UiSelectTopControlComponent, { static: true }) public nzSelectTopControlComponent!: UiSelectTopControlComponent;
  @ContentChildren(UiOptionComponent, { descendants: true }) public listOfNzOptionComponent!: QueryList<UiOptionComponent>;
  @ContentChildren(UiOptionGroupComponent, { descendants: true })
  public listOfNzOptionGroupComponent!: QueryList<UiOptionGroupComponent>;

  @ViewChild("defaultSelectedItemIconTemplate", { static: true })
  public defaultSelectedItemIconTemplate: TemplateRef<unknown>;

  @ViewChild(UiOptionGroupComponent, { static: true, read: ElementRef })
  public nzOptionGroupComponentElement!: ElementRef;

  @ViewChild(UiSelectTopControlComponent, { static: true, read: ElementRef })
  public nzSelectTopControlComponentElement!: ElementRef;

  public listboxId = `ui-select-listboxId-${nextUniqueId++}`;
  public topControlFocus = {
    currentIndex: -1,
    currentValue: "",
  };
  public searchInputAriaDescription = "";
  public keyboardActivatedValue: NzSafeAny;

  public get selectedItemIcon(): TemplateRef<unknown> | null {
    if (!this.renderSelectedListItemIcon) {
      return null;
    }

    return this.nzMenuItemSelectedIcon ?? this.defaultSelectedItemIconTemplate;
  }

  private oldSelectedItems: NzSelectItemInterface[];
  private oldHasNoSearchResults: boolean;
  private oldTopControlFocus: string;

  constructor(
    ngZone: NgZone,
    destroy$: NzDestroyService,
    nzConfigService: NzConfigService,
    cdr: ChangeDetectorRef,
    host: ElementRef<HTMLElement>,
    renderer: Renderer2,
    platform: Platform,
    focusMonitor: FocusMonitor,
    private i18n: UiI18nService,
    @Optional() directionality: Directionality,
    @Host() @Optional() noAnimation?: NzNoAnimationDirective,
    @Optional() nzFormStatusService?: NzFormStatusService,
    @Optional() nzFormNoStatusService?: NzFormNoStatusService
  ) {
    super(ngZone, destroy$, nzConfigService, cdr, host, renderer, platform, focusMonitor, directionality, noAnimation, nzFormStatusService, nzFormNoStatusService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.updateOverlayPosition = this.updateOverlayPosition.bind(this);
  }

  public ngDoCheck(): void {
    const selectedItemsChanged = this.listOfTopItem !== this.oldSelectedItems;
    const hasNoSearchResultsChanged = this.oldHasNoSearchResults !== this.hasNoSearchResults();
    const topControlFocusChanged = this.oldTopControlFocus !== this.topControlFocus.currentValue;

    if (selectedItemsChanged || hasNoSearchResultsChanged || topControlFocusChanged) {
      this.oldSelectedItems = this.listOfTopItem;
      this.oldHasNoSearchResults = this.hasNoSearchResults();
      this.oldTopControlFocus = this.topControlFocus.currentValue;

      this.setSearchInputAriaDescription();
    }
  }

  private hasNoSearchResults(): boolean {
    return this.listOfContainerItem.length === 0 && this.nzShowSearch;
  }

  private setSearchInputAriaDescription(): void {
    const description = [];

    if (this.hasNoSearchResults()) {
      description.push(this.i18n.translate("Select.noSearchResultsFound"));
    }

    if (this.listOfTopItem.length === 0) {
      description.push(this.i18n.translate("Select.noValueSelected"));
    }

    if (this.listOfTopItem.length > 0) {
      const selectedItemsStr =
        this.listOfTopItem.length === 1
          ? this.i18n.translate("Select.selectedItem", { item: this.listOfTopItem[0].nzLabel })
          : this.i18n.translate("Select.selectedItems", { itemsCount: this.listOfTopItem.length, items: this.listOfTopItem.map((v) => v.nzLabel).join(", ") });

      description.push(selectedItemsStr);
    }

    if (this.a11yDescription) {
      description.push(this.a11yDescription);
    }

    this.searchInputAriaDescription = description.join(" ");
  }

  public onItemDelete(item: UiSelectItemInterface): void {
    if (!item) return;

    const listOfSelectedValue = this.listOfValue.filter((v) => !this.compareWith(v, item.nzValue));
    this.updateListOfValue(listOfSelectedValue);
    this.clearInput();

    const { currentIndex } = this.topControlFocus;
    const shouldAssignFirstElement = currentIndex === 1 && listOfSelectedValue.length === 1;
    this.topControlFocus.currentValue = shouldAssignFirstElement ? listOfSelectedValue[0] : listOfSelectedValue[currentIndex];
  }

  private blurTopControl(): void {
    this.topControlFocus = {
      currentIndex: -1,
      currentValue: "",
    };
  }

  private setFocusToChip(): void {
    setTimeout(() => {
      const activeChip = document.getElementsByClassName("gh-select-item-active")[0] as HTMLElement;
      if (activeChip) {
        activeChip.tabIndex = -1;
        activeChip.focus();
      } else {
        return;
      }
    }, 100);
  }

  private registerUpdateOverlayPostitionHandler(): void {
    if (this.nzOpen) {
      window.addEventListener("scroll", this.updateOverlayPosition, true);
    } else {
      window.removeEventListener("scroll", this.updateOverlayPosition, true);
    }
  }

  private updateOverlayPosition(): void {
    super.updateCdkConnectedOverlayPositions();
  }

  public setOpenState(value: boolean): void {
    if (this.nzDisabled || this.readonly) {
      return;
    }

    super.setOpenState(value);
  }

  public onOpenChange(): void {
    super.onOpenChange();
    this.blurTopControl();
    this.registerUpdateOverlayPostitionHandler();
  }

  private toggleItemSelection(option: { color: string; value: string }): void {
    if (this.deselectOption && !!this?.oldSelectedItems?.find((item) => item.key.value === option.value)) {
      this.onClearSelection();
      this.setOpenState(false);
    } else {
      this.onItemClick(option);
    }
  }

  public onItemSelect(e: { color: string; value: string }): void {
    this.toggleItemSelection(e);
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public onKeyDown(e: KeyboardEvent): void {
    if (this.nzDisabled || this.readonly) {
      return;
    }
    const listOfFilteredOptionNotDisabled = this.listOfContainerItem.filter((item) => item.type === "item").filter((item) => !item.nzDisabled);
    const activatedIndex = listOfFilteredOptionNotDisabled.findIndex((item) => this.compareWith(item.nzValue, this.keyboardActivatedValue));

    switch (e.keyCode) {
      case UP_ARROW:
        e.preventDefault();
        this.blurTopControl();

        if (this.nzOpen && listOfFilteredOptionNotDisabled.length > 0) {
          const preIndex = activatedIndex > 0 ? activatedIndex - 1 : listOfFilteredOptionNotDisabled.length - 1;
          this.activatedValue = listOfFilteredOptionNotDisabled[preIndex].nzValue;
          this.keyboardActivatedValue = listOfFilteredOptionNotDisabled[preIndex].nzValue;
        }
        break;
      case DOWN_ARROW:
        e.preventDefault();
        this.blurTopControl();

        if (this.nzOpen && listOfFilteredOptionNotDisabled.length > 0) {
          const nextIndex = activatedIndex < listOfFilteredOptionNotDisabled.length - 1 ? activatedIndex + 1 : 0;
          this.activatedValue = listOfFilteredOptionNotDisabled[nextIndex].nzValue;
          this.keyboardActivatedValue = listOfFilteredOptionNotDisabled[nextIndex].nzValue;
        } else {
          this.setOpenState(true);
          this.keyboardActivatedValue = listOfFilteredOptionNotDisabled[0]?.nzValue;
        }
        break;
      case ENTER:
        e.preventDefault();
        if (this.nzOpen && !this.nzLoading) {
          if (listOfFilteredOptionNotDisabled.find((item) => item.template !== null && !item.nzCustomContent)) {
            this.toggleItemSelection(listOfFilteredOptionNotDisabled[listOfFilteredOptionNotDisabled.length - 1].nzValue);
          } else if (isNotNil(this.activatedValue)) {
            this.toggleItemSelection(this.activatedValue);
          } else {
            this.customEvent.emit();
          }
        }
        break;
      case SPACE:
        if (!this.nzOpen) {
          e.preventDefault();
        }
        break;
      case TAB:
        this.setOpenState(false);
        this.topControlFocus = {
          currentIndex: -1,
          currentValue: "",
        };
        break;
      case ESCAPE:
        /**
         * Skip the ESCAPE processing, it will be handled in {@link onOverlayKeyDown}.
         */
        break;
      case LEFT_ARROW: {
        e.preventDefault();
        this.activatedValue = null;
        this.keyboardActivatedValue = null;

        const nextValue = this.topControlFocus.currentIndex - 1;
        const currentIndex = nextValue < 0 ? this.listOfValue.length - 1 : nextValue;

        this.topControlFocus = {
          currentIndex,
          currentValue: this.listOfValue[currentIndex],
        };

        this.setFocusToChip();

        break;
      }
      case RIGHT_ARROW: {
        e.preventDefault();
        this.activatedValue = null;
        this.keyboardActivatedValue = null;

        const nextValue = this.topControlFocus.currentIndex + 1;
        const currentIndex = nextValue > this.listOfValue.length - 1 ? 0 : nextValue;

        this.topControlFocus = {
          currentIndex,
          currentValue: this.listOfValue[currentIndex],
        };

        this.setFocusToChip();

        break;
      }
      case BACKSPACE: {
        const value = this.listOfValue[this.topControlFocus.currentIndex];
        if (!value) {
          return;
        }

        e.preventDefault();

        this.onItemDelete({ nzValue: value } as NzSelectItemInterface);
        this.originElement.nativeElement.click();
        this.setFocusToChip();

        break;
      }
      case HOME: {
        e.preventDefault();

        if (this.topControlFocus.currentIndex === 0) {
          return;
        }

        this.activatedValue = null;
        this.keyboardActivatedValue = null;

        const currentIndex = 0;

        this.topControlFocus = {
          currentIndex,
          currentValue: this.listOfValue[currentIndex],
        };

        this.setFocusToChip();

        break;
      }
      case END: {
        e.preventDefault();

        if (this.topControlFocus.currentIndex === this.listOfValue.length - 1) {
          return;
        }

        this.activatedValue = null;
        this.keyboardActivatedValue = null;

        const currentIndex = this.listOfValue.length - 1;

        this.topControlFocus = {
          currentIndex,
          currentValue: this.listOfValue[currentIndex],
        };

        this.setFocusToChip();

        break;
      }
      default: {
        if (this.nzOpen || e.metaKey || e.shiftKey || e.ctrlKey || e.altKey || !this.nzShowSearch) {
          return;
        }

        this.setOpenState(true);
      }
    }
  }
}
