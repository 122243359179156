import { IUIError } from "@gtmhub/error-handling";
import { applySocketUpdatesToCollection, applySocketUpdatesToIdMap } from "@gtmhub/sockets/util";
import { IdMap, toIdMap } from "@gtmhub/util";
import { IReduxTeam } from "../models/models";
import { TeamsAction } from "./models";

export interface ITeamsStoreState {
  teams: ITeamsState;
}

export interface ITeamsState {
  isFetched: boolean;
  isFetching: boolean;
  items: IReduxTeam[];
  map: IdMap<IReduxTeam>;
  error: IUIError;
}

export function teamsReducer(state: ITeamsState = { isFetched: false, isFetching: false, items: [], map: {}, error: null }, action: TeamsAction): ITeamsState {
  switch (action.type) {
    case "REQUEST_TEAMS":
      return { ...state, isFetching: true, isFetched: false, error: null };
    case "RECEIVE_TEAMS": {
      return { ...state, isFetched: true, isFetching: false, items: action.items, map: toIdMap(action.items) };
    }
    case "UPDATE_TEAMS": {
      return state.isFetched
        ? {
            isFetched: true,
            isFetching: false,
            error: null,
            items: applySocketUpdatesToCollection(state.items, action.update),
            map: applySocketUpdatesToIdMap(state.map, action.update),
          }
        : state;
    }
    case "UPDATE_TEAM": {
      state.map[action.team.id] = Object.assign(state.map[action.team.id] || {}, action.team);
      state.items = state.items.map((item) => Object.assign(item, item.id === action.team.id ? action.team : {}));
      return state.isFetched ? { isFetched: true, isFetching: false, error: null, items: state.items, map: state.map } : state;
    }
    case "ERROR_RECEIVE_TEAMS":
      return { ...state, error: action.error, isFetching: false, isFetched: false };
    default: {
      return state;
    }
  }
}
