import { SuggestedInsight } from "@webapp/data-story/models/insights.models";
import { SuggestedMarketplaceInsightTemplate } from "@webapp/data-story/models/marketplace-facade.models";
import { ItemAutomationSuggestion } from "@webapp/shared/components/item-automation-selector/models/item-automation-suggestions.models";

export function filterSuggestions(accountInsights: SuggestedInsight[], marketplaceInsights: SuggestedMarketplaceInsightTemplate[]): void {
  const totalInsightsLength = marketplaceInsights.length + accountInsights.length;
  if (totalInsightsLength > 4) {
    accountInsights.splice(2, 1);
    marketplaceInsights.splice(2, 1);
  }
}

export function suggestedMarketplaceInsightTemplateToItemAutomationSuggestion(
  suggestedMarketplaceInsightTemplate: SuggestedMarketplaceInsightTemplate
): ItemAutomationSuggestion {
  return {
    ...suggestedMarketplaceInsightTemplate,
    type: "insightTemplate",
    source: "marketplace",
  };
}

export function suggestedInsightToItemAutomationSuggestion(suggestedInsight: SuggestedInsight): ItemAutomationSuggestion {
  return {
    ...suggestedInsight,
    type: "insight",
    source: "account",
  };
}
