import { IAccountRolesResponse } from "./models";

export function generateGetRolesQuery(options: { includeTeamRoles?: boolean; includeUserCount?: boolean }): string {
  const includeTeamRolesQuery = options.includeTeamRoles != null ? "includeTeamRoles=" + options.includeTeamRoles : "";
  const includeUserCountQuery = "excludeUserCount=" + !options.includeUserCount;
  return `?${includeTeamRolesQuery}&${includeUserCountQuery}`;
}

export function filterOutSysAdminRole(roles: IAccountRolesResponse): IAccountRolesResponse {
  // remove the SysAdmin role, as this is not to be shown
  // on the client web app
  for (let i = 0; i < roles.items.length; i++) {
    if (roles.items[i].name === "SysAdmin") {
      roles.items.splice(i, 1);

      break;
    }
  }
  return roles;
}
