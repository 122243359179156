import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";

@Component({
  selector: "ui-select-error",
  templateUrl: "select-error.component.html",
  styleUrls: ["./select-error.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UiIconModule],
})
export class UiSelectErrorComponent {}
