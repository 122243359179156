import { ILogService, IScope, IWindowService } from "angular";
import { IModalStackService } from "angular-ui-bootstrap";
import { StateParams } from "@uirouter/angularjs";
import { AuthenticationResolverService } from "@gtmhub/auth";
import { Intercom } from "../../shared/intercom";
import { IError } from "../models";
import { UIErrorHandlingService } from "../services/error-handling-service";
import { ErrorService } from "../services/error.service";

interface IErrorScope extends IScope {
  error: IError;
  userDisplayName: string;
  showIntercom(): void;
  goHome(): void;
  logout(): void;
}

export class ErrorCtrl {
  public static $inject = ["$scope", "$uibModalStack", "$stateParams", "ErrorService", "$log", "AuthenticationResolverService", "$window", "UIErrorHandlingService"];

  constructor(
    private $scope: IErrorScope,
    private $uibModalStack: IModalStackService,
    private $stateParams: StateParams,
    private errorService: ErrorService,
    private $log: ILogService,
    private authenticationResolverService: AuthenticationResolverService,
    private $window: IWindowService,
    private uiErrorHandlingService: UIErrorHandlingService
  ) {
    this.$uibModalStack.dismissAll();

    this.$scope.goHome = this.goHome;
    this.$scope.showIntercom = this.showIntercom;
    this.$scope.logout = this.logout;
    this.$scope.userDisplayName = this.authenticationResolverService.getUserDisplayNameFromIdToken();

    if (this.$stateParams.error) {
      this.$scope.error = this.$stateParams.error;

      if (this.$scope.error.data && this.$scope.error.data.config && this.$scope.error.data.config.headers && this.$scope.error.data.config.headers.Authorization) {
        delete this.$scope.error.data.config.headers.Authorization;
      }
    } else {
      this.$scope.error = this.errorService.getStoredError();
    }

    // we should redirect away from the login screen if there is no error to report like when you navigate back to the error page.
    if (!this.$scope.error) {
      this.goHome();
    }

    this.$log.error(this.$scope.error);
    this.uiErrorHandlingService.trackError("page");
  }

  private showIntercom = () => {
    Intercom("show");
  };

  // go big or
  private goHome = () => {
    this.$window.open("/login", "_self");
  };

  private logout = (): void => {
    this.authenticationResolverService.logout({ ssoLogoutIfEnabled: true });
  };
}
