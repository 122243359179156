<ui-input-number-group
  [uiBorderless]="uiBorderless"
  [uiAddOnBefore]="formatting?.prefix"
  [uiAddOnAfter]="formatting?.suffix"
  [uiAddonStyle]="{ maxWidth: '150px' }"
  [uiDisabled]="disabled"
  [uiReadOnly]="readonly"
  uiSize="large">
  <ui-input-number
    [(ngModel)]="selected"
    [uiBorderless]="uiBorderless"
    [uiDisabled]="disabled"
    [uiReadOnly]="readonly"
    [uiPrecision]="formatting?.precision"
    [uiId]="uiId"
    [uiPlaceHolder]="placeholder"
    [uiMax]="max"
    [errorMessageId]="errorMessageId"
    [a11yDisabled]="disabled"
    [a11yRequired]="a11yRequired"
    [a11yLabel]="a11yLabel"
    [a11yLabelledby]="a11yLabelledby"
    [a11yDescribedby]="uiId + '-description'"
    [e2eTestId]="e2eTestId"
    [valueControlsHide]="true"
    (ngModelChange)="selectedChange.emit($event)"
    (uiBlur)="inputBlur.emit()"
    uiSize="large"></ui-input-number>
</ui-input-number-group>

<!-- The div below plays the role of aria-description since it still doesn't have full support for macOs and safari -->
<!-- Once we have full support we can simply remove it and attach back [attr.aria-description]="a11yDescription" to the input element -->
<div class="select-a11y-description" [attr.id]="uiId + '-description'">{{ a11yDescription }}</div>
