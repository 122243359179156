import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { IQuantivePlusTag } from "../../models";

@Component({
  selector: "quantive-plus-suggestion-card-tags",
  templateUrl: "./quantive-plus-suggestion-card-tags.component.html",
  styleUrls: ["./quantive-plus-suggestion-card-tags.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuantivePlusSuggestionCardTagsComponent {
  public showActionButtons: boolean;

  @Input()
  public isRemoveButtonAvailable = true;

  @Input()
  public isSuggestionIconAvailable = true;

  @Input()
  public suggestion: IQuantivePlusTag[];

  @Output()
  public readonly suggest = new EventEmitter();
  @Output()
  public readonly suggestionRemove = new EventEmitter();
  @Output()
  public readonly suggestionAdd = new EventEmitter();

  public onSuggestAnotherClicked = (): void => {
    this.suggest.emit();
  };

  public onRemoveSuggestionClicked = (): void => {
    this.suggestionRemove.emit();
  };

  public onAddSuggestionClicked = (): void => {
    this.suggestionAdd.emit();
  };
}
