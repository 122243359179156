import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";

@Component({
  standalone: true,
  selector: "form-control-error",
  templateUrl: "form-control-error.component.html",
  styleUrls: ["./form-control-error.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [UiIconModule],
})
export class FormControlErrorComponent {}
