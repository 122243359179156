import { NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { CandyDate } from "ng-zorro-antd/core/time";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { ɵCalendarFooterComponent as CalendarFooterComponent } from "ng-zorro-antd/date-picker";
import { UiCalendarInterface } from "@webapp/ui/date-picker/date-picker.models";
import { UiDateHelperService } from "@webapp/ui/date-picker/services/date-helper.service";

@Component({
  selector: "ui-calendar-footer",
  exportAs: "uiCalendarFooter",
  templateUrl: "calendar-footer.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet, UiButtonModule, NgIf, NgSwitch, NgSwitchCase],
})
export class UiCalendarFooterComponent extends CalendarFooterComponent {
  @Input("uiLocale")
  public locale!: UiCalendarInterface;
  @Input("uiShowToday")
  public showToday = false;
  @Input("uiShowNow")
  public showNow = false;
  @Input("uiHasTimePicker")
  public hasTimePicker = false;
  @Input("uiIsRange")
  public isRange = false;

  @Input("uiOkDisabled")
  public okDisabled = false;
  @Input("uiDisableDate")
  public disabledDate?: (d: Date) => boolean;
  @Input("uiExtraFooter")
  public extraFooter?: TemplateRef<void> | string;
  @Input("uiRangeQuickSelector")
  public rangeQuickSelector: TemplateRef<NzSafeAny> | null = null;

  @Output("uiClickOk")
  public readonly clickOk = new EventEmitter<void>();
  @Output("uiClickToday")
  public readonly clickToday = new EventEmitter<CandyDate>();

  constructor(uiDateHelper: UiDateHelperService) {
    super(uiDateHelper);
  }
}
