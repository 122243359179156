import { StateService } from "@uirouter/angular";
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { Assignee, UnknownAssignee } from "@webapp/assignees/models/assignee.models";
import { isUnknownAssignee } from "@webapp/assignees/utils/assignee.utils";

@Component({
  selector: "ui-mention-popover",
  templateUrl: "./mention-popover.component.html",
  styleUrls: ["./mention-popover.component.less"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MentionPopoverComponent {
  @Input() public user: Assignee | UnknownAssignee;
  @Input() public sourceMentionEl: ElementRef;
  @Output() public readonly closed = new EventEmitter<boolean>();

  constructor(private stateService: StateService) {}

  public changePopoverState(visible: boolean): void {
    if (!visible) {
      this.close();
    }
  }

  public get email(): string {
    return this.user && !isUnknownAssignee(this.user) && this.user.type === "user" && this.user.email;
  }

  public close(): void {
    this.user = null;
    this.sourceMentionEl = null;
    this.closed.emit(true);
  }

  public isVisible(): boolean {
    return this.user != null && this.sourceMentionEl != null;
  }

  public openProfile(): void {
    this.stateService.go("gtmhub.employees.employee", { employeeId: this.user.id });
  }
}
