import { ComponentRef, EnvironmentInjector, Injectable } from "@angular/core";
import { RichTextEditorComponent } from "@webapp/shared/rich-text-editor/rich-text-editor.component";
import { EditableCustomFieldsInput, ICustomField } from "../../models/custom-fields.models";
import { AbstractCustomFieldFactory } from "./abstract-custom-field.factory";

@Injectable()
export class RichTextEditorCustomFieldComponentFactory extends AbstractCustomFieldFactory<RichTextEditorComponent> {
  constructor(injector: EnvironmentInjector) {
    super(injector, RichTextEditorComponent);
  }

  protected setComponentInputs(component: ComponentRef<RichTextEditorComponent>, customField: ICustomField, editableCustomFieldInputs: EditableCustomFieldsInput): void {
    component.setInput("withoutGifs", true);
    component.setInput("hidePostButton", true);
    component.setInput("inline", true);
    component.setInput("inlineWithBorder", !editableCustomFieldInputs.borderless);
    component.setInput("placeholder", "-");
    component.setInput("a11yLabelledby", customField._id);
    component.setInput("uiId", customField.name);
    component.setInput("a11yRequired", !editableCustomFieldInputs.disableRequired && customField.settings.validations.required);
    component.setInput("text", editableCustomFieldInputs.values[customField.name]);
    component.setInput("readonly", editableCustomFieldInputs.readonly);
    component.setInput("disabled", editableCustomFieldInputs.disabled);
    let update: string;
    component.instance.textChange.subscribe((updatedText: string) => {
      update = updatedText;
      if (!editableCustomFieldInputs.updateOnBlur) {
        editableCustomFieldInputs.onChange({ fieldName: customField.name, customField: update });
      }
    });

    if (editableCustomFieldInputs.updateOnBlur) {
      component.instance.editorBlur.subscribe(() => {
        if (typeof update !== "undefined") {
          editableCustomFieldInputs.onChange({ fieldName: customField.name, customField: update });
        }
      });
    }
  }
}
