import { IUIError } from "@gtmhub/error-handling";
import { IdMap } from "@gtmhub/util";
import { IRole } from "../models";

export interface ICurrentUserRole {
  name: string;
  id: string;
}

export interface ICurrentUserRoles {
  nonShadowRoles: ICurrentUserRole[];
  shadowRoles: ICurrentUserRole[];
}

export interface ICurrentUserRolesStoreState {
  currentUserRoles: ICurrentUserRolesState;
}

export interface ICurrentUserRolesState extends ICurrentUserRoles {
  isFetched: boolean;
  fetching?: boolean;
  error?: IUIError;
}

export interface IRequestCurrentUserRolesAction {
  type: "REQUEST_CURRENT_USER_ROLES";
}

export interface IReceiveCurrentUserRolesAction extends ICurrentUserRoles {
  type: "RECEIVE_CURRENT_USER_ROLES";
}

export interface IReceiveCurrentUserRolesErrorAction {
  type: "RECEIVE_CURRENT_USER_ROLES_ERROR";
  error: IUIError;
}

export type CurrentUserRolesAction = IRequestCurrentUserRolesAction | IReceiveCurrentUserRolesAction | IReceiveCurrentUserRolesErrorAction;

export interface IRolesStoreState {
  roles: IRolesState;
}

export interface IRolesState {
  isFetched: boolean;
  isFetching?: boolean;
  items: IRole[];
  map: IdMap<IRole>;
  error?: IUIError;
}

export enum RolesActionsEnum {
  REQUEST_ROLES = "REQUEST_ROLES",
  RECEIVE_ROLES = "RECEIVE_ROLES",
  RECEIVE_ROLES_ERROR = "RECEIVE_ROLES_ERROR",
  ADD_ROLE = "ADD_ROLE",
  UPDATE_ROLE = "UPDATE_ROLE",
  REMOVE_ROLE = "REMOVE_ROLE",
}

export interface IRequestRolesAction {
  type: RolesActionsEnum.REQUEST_ROLES;
}

export interface IReceiveRolesAction {
  type: RolesActionsEnum.RECEIVE_ROLES;
  items: IRole[];
}

export interface IReceiveRolesErrorAction {
  type: RolesActionsEnum.RECEIVE_ROLES_ERROR;
  error: IUIError;
}

export interface IAddRoleAction {
  type: RolesActionsEnum.ADD_ROLE;
  role: IRole;
}

export interface IUpdateRoleAction {
  type: RolesActionsEnum.UPDATE_ROLE;
  role: IRole;
}

export interface IRemoveRoleAction {
  type: RolesActionsEnum.REMOVE_ROLE;
  roleId: string;
}

export type RolesAction = IRequestRolesAction | IReceiveRolesAction | IReceiveRolesErrorAction | IAddRoleAction | IUpdateRoleAction | IRemoveRoleAction;
