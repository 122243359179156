import { UiThemeType } from "@quantive/ui-kit/icon";
import { NzTSType } from "ng-zorro-antd/core/types";
import { NzFormLayoutType } from "ng-zorro-antd/form";

export type UiFormLayoutType = NzFormLayoutType;

export const uiDefaultTooltipIcon = {
  type: "question-circle",
  theme: "outline",
} as const;

export interface UiFormTooltipIcon {
  type: NzTSType;
  theme: UiThemeType;
}
