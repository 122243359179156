<multi-selector
  [nodes]="nodes$ | async"
  [nodesLoading]="nodesLoading"
  [triggerOpen]="triggerOpen"
  [searchFunctionPlaceholder]="searchFn"
  [nodesType]="multiSelectorNodesType"
  [treeOffset]="1"
  (openChange)="onOpenChange($event)"
  (expandChange)="onExpandChange($event)"
  (valueUpdate)="onUpdatedValue($event)">
  <ng-template #nodeTitleTemplate let-node>
    <div class="node-title" ui-typography uiEllipsis>
      {{ node.title }}
      <span class="node-archived-session" *ngIf="node.origin.isFromArchivedSession">({{ "archived_session" | localize }})</span>
    </div>
  </ng-template>
</multi-selector>
