import { getExcludedIdsSearchCondition, isActiveSearchCondition } from "@gtmhub/shared/utils/search-utils";
import { ISearchCondition, ISearchRequestsBody } from "@webapp/search/models/search-api.models";

export type TeamsSearchConditionSettings = {
  activeTeamsOnly?: boolean;
  excludedTeamIds?: string[];
};

export function buildTeamsSearchRequest(searchTerm: string, settings: TeamsSearchConditionSettings = {}): ISearchRequestsBody {
  const searchSettings: TeamsSearchConditionSettings = {
    activeTeamsOnly: true,
    ...settings,
  };
  const searchConditions = buildTeamsSearchConditions(searchSettings);

  return {
    collectionName: "teams",
    searchFields: searchTerm ? [{ name: "name" }, { name: "description" }] : [],
    ...(searchConditions.length && { searchConditions }),
  };
}

export function buildTeamsSearchConditions(settings: TeamsSearchConditionSettings = {}): ISearchCondition[] {
  return [
    ...(settings.activeTeamsOnly ? [isActiveSearchCondition()] : []),
    ...(settings.excludedTeamIds ? [getExcludedIdsSearchCondition(settings.excludedTeamIds)] : []),
  ];
}

export function getSearchTermFilter(employeeSearch): Record<string, unknown> {
  // map of all special regex characters which might be included in a user's name
  const regexToChar = { " ": "|", "+": "\\+", "?": "\\?", "!": "\\!", ":": "\\:", ".": "\\.", "^": "\\^", $: "\\$", "-": "\\-" };
  // replace all occurences of special regex characters in a user's name from the above map
  const employeeRegexxed = `${employeeSearch.replace(/[ |+?!:.^$-]/, (m) => regexToChar[m])}`;
  const paramToSearch = `^(${employeeRegexxed})|(${employeeRegexxed})$`;
  return { $or: [{ firstName: { $regex: paramToSearch } }, { lastName: { $regex: paramToSearch } }, { email: { $regex: paramToSearch } }] };
}
