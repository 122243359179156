import { Injectable } from "@angular/core";
import { Observable, combineLatest, map, of, switchMap, take } from "rxjs";
import { getLanguageFromLocalStorage, localize } from "@gtmhub/localization";
import { getCurrentUserId } from "@gtmhub/users";
import { AssigneesRepository } from "@webapp/assignees/services/assignees-repository.service";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { EmployeeDetails, EmployeesRepository } from "@webapp/employees";
import { checkInComparer } from "@webapp/home/utils/check-ins-widget/check-ins-widget.utils";
import { CheckInDTO } from "@webapp/reflections/models/checkins-facade.models";
import { CheckIn, CheckInNotes } from "@webapp/reflections/models/checkins-models";
import { CheckInsFacade } from "@webapp/reflections/services/checkins-facade.service";
import { getCurrentWeeksSundayAsDate, getLastMondayAsDate } from "@webapp/reflections/utils";
import { transformLocalToUtcString } from "@webapp/reflections/utils/transform-local-to-utc-string";
import dayjs from "@webapp/shared/libs/dayjs";
import { TeamBase } from "@webapp/teams/models/teams.models";
import { TeamsRepository } from "@webapp/teams/services/teams-repository.service";

type CheckInNotesMap = Partial<Record<keyof CheckInNotes, { count: number; expandCheckIns: boolean }>>;

export interface CheckInOverview {
  id: string;
  notes: CheckInNotesMap;
  membersCount: number;
  filledOutCheckInsCount: number;
  hasCurrentUserFilledOut: boolean;
  isUserManagerOfTeam: boolean;
  name: string;
  dueOn: string;
}

@Injectable()
export class CheckInsWidgetService {
  private checkIns: CheckIn[];
  private teamsMap: Map<string, TeamBase>;

  get languageFromLocalStorage(): string {
    return getLanguageFromLocalStorage();
  }

  constructor(
    private employeesRepository: EmployeesRepository,
    private checkInsFacade: CheckInsFacade,
    private teamsRepository: TeamsRepository,
    private assigneesRepository: AssigneesRepository
  ) {}

  public getCheckInsOverviews$(): Observable<CheckInOverview[]> {
    return combineLatest([this.loadCurrentUserTeamsInfo$(), this.teamsRepository.getMap$(), this.assigneesRepository.getMap$()]).pipe(
      take(1),
      map(([{ teamIds, checkIns }, teamsMap, assignees]) => {
        this.teamsMap = teamsMap;
        this.checkIns = checkIns;
        const unsortedTeams: CheckInOverview[] = [];
        teamIds.forEach((teamId) => {
          if (!teamsMap.get(teamId) || !assignees.get(teamId)?.isActive) {
            return;
          }

          const team: CheckInOverview = {
            id: teamId,
            notes: this.aggregateCheckInsNotesCount(teamId),
            membersCount: this.calculateTeamMembersCount(teamId),
            filledOutCheckInsCount: this.calculatePublishedCheckInsPerTeam(teamId),
            hasCurrentUserFilledOut: this.isCheckInFilledOutFromCurrentUser(teamId),
            isUserManagerOfTeam: this.isUserManagerOfTeam(teamId),
            name: assignees.get(teamId)?.name,
            dueOn: this.getDueOn(),
          };
          unsortedTeams.push(team);
        });

        return unsortedTeams.sort(checkInComparer);
      })
    );
  }

  public calculateTeamMembersCount(teamId: string): number {
    const currentTeam = this.teamsMap.get(teamId);
    const members = [...(currentTeam.manager ? [currentTeam.manager] : []), ...(currentTeam.members || [])];

    return new Set(members).size;
  }

  public isUserManagerOfTeam(teamId: string): boolean {
    return this.teamsMap.get(teamId).manager === getCurrentUserId();
  }

  public aggregateCheckInsNotesCount(teamId: string): CheckInNotesMap {
    const notesMap: CheckInNotesMap = {};
    const checkIns = this.checkIns.filter((checkIn) => checkIn.teamId === teamId);

    checkIns.forEach((item) => {
      Object.keys(item.notesV2).forEach((key) => {
        if (item.notesV2[key]?.text || item.notesV2[key]?.gif?.id) {
          notesMap[key] = notesMap[key] || { count: 0 };
          notesMap[key].count += 1;

          if (!notesMap[key].expandCheckIns) {
            notesMap[key].expandCheckIns = true;
          }
        }
      });
    });
    return notesMap;
  }

  public isCheckInFilledOutFromCurrentUser(teamId: string): boolean {
    const checkInsPerTeam = this.getCheckInsForTeam(teamId);

    return checkInsPerTeam.some((item) => {
      return item.userId === getCurrentUserId();
    });
  }

  private getCheckInsForTeam(teamId: string): CheckInDTO[] {
    return this.checkIns.filter((item) => item.teamId === teamId);
  }

  private calculatePublishedCheckInsPerTeam(teamId: string): number {
    return this.getCheckInsForTeam(teamId).length;
  }

  private loadCurrentUserTeamsInfo$(): Observable<{ teamIds: string[]; checkIns: CheckIn[] }> {
    return this.employeesRepository.getEmployeeDetails$(getCurrentUserId()).pipe(
      map((profile: EmployeeDetails) => {
        const teamIdsWithDuplicates = [...(profile.info.managedTeamIds || []), ...(profile.info.teamIds || [])];
        return [...new Set(teamIdsWithDuplicates)];
      }),
      switchMap((teamIds) => {
        if (!teamIds.length) {
          return of<string[]>([]);
        }

        return this.filterTeamsWithCheckInsEnabled$(teamIds);
      }),
      switchMap((teamIds) => {
        if (!teamIds.length) {
          return of({
            teamIds: [],
            checkIns: [],
          });
        }

        const reqFilter = {
          filter: {
            $and: [
              { createdAt: { $gte: transformLocalToUtcString(getLastMondayAsDate()) } },
              { createdAt: { $lt: transformLocalToUtcString(getCurrentWeeksSundayAsDate()) } },
              { teamId: { $in: teamIds } },
            ],
          },
        };
        return this.checkInsFacade.getCheckIns$(reqFilter).pipe(map((checkIns) => ({ teamIds: teamIds, checkIns: checkIns.items })));
      })
    );
  }

  private getDueOn(): string {
    const today = dayjs().day();
    const saturday = dayjs().day(6);
    const sunday = dayjs().day(7);

    if (today === saturday.day()) {
      return localize("due_tomorrow");
    } else if (today === sunday.day()) {
      return localize("due_today");
    } else {
      return localize("due_sunday");
    }
  }

  private filterTeamsWithCheckInsEnabled$(teamIds: string[]): Observable<string[]> {
    const reqFilter: RequestPaging = {
      filter: { teamId: { $in: teamIds } },
      fields: ["reflectionsEnabled", "teamId"],
    };

    return this.checkInsFacade.getCheckInSettings$(reqFilter).pipe(
      map((response) => response.items.reduce((map, item) => ({ ...map, [item.teamId]: item.reflectionsEnabled }), {})),
      map((reflectionsEnabledMap) => teamIds.filter((teamId) => reflectionsEnabledMap[teamId]))
    );
  }
}
