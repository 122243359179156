<div class="sp-py-4 action-bar" ui-row uiAlign="middle" uiJustify="end">
  <div *ngIf="isMainActionsSectionAvailable" ui-col uiFlex="1">
    <ng-content select="[pi-action-bar-main-actions]"></ng-content>
  </div>
  <ng-container *ngIf="isFeedbackActionsSectionAvailable">
    <pi-action-bar-feedback-actions
      [goal]="goal"
      [entityId]="entityId"
      [suggestionFeedbackType]="suggestionFeedbackType"
      [suggestionFeedbackSubType]="suggestionFeedbackSubType"
      [suggestion]="suggestion"
      [instance]="instance"></pi-action-bar-feedback-actions>
  </ng-container>
</div>
