import { Inject, NgModule } from "@angular/core";
// eslint-disable-next-line no-restricted-imports
import { PdfViewerModule as Ng2PdfViewerModule, PDFViewerOptions, PdfViewerComponent, RenderTextMode } from "ng2-pdf-viewer";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";

declare global {
  interface Window {
    pdfWorkerSrc: string;
  }
}

/**
 * In ng2-pdf-viewer version 10.0.0 they explicitly populate `l10n`, which prevent using the built-in localization.
 * https://github.com/VadimDez/ng2-pdf-viewer/blob/master/src/app/pdf-viewer/pdf-viewer.component.ts#L431
 */
PdfViewerComponent.prototype["getPDFOptions"] = function (this: PdfViewerComponent): PDFViewerOptions {
  return {
    eventBus: this.eventBus,
    container: this["element"].nativeElement.querySelector("div")!,
    removePageBorders: !this["_showBorders"],
    linkService: this.pdfLinkService,
    textLayerMode: this["_renderText"] ? this["_renderTextMode"] : RenderTextMode.DISABLED,
    findController: this.pdfFindController,
    imageResourcesPath: this["_imageResourcesPath"],
    l10n: undefined,
  };
};

@NgModule({
  imports: [Ng2PdfViewerModule],
  exports: [Ng2PdfViewerModule],
})
export class PdfViewerModule {
  constructor(@Inject(APP_CONFIG) appConfig: IAppConfig) {
    const cdnUrl = appConfig.cdnStorage.enabled ? appConfig.cdnStorage.url || "https://cdnstaging.quantivestatic.com" : "";
    window.pdfWorkerSrc = `${cdnUrl}/dist/lib/pdfjs/pdf.worker.min.js`;
  }
}
