import { Component, Input } from "@angular/core";
import { SubNavOption } from "@webapp/navigation/models/sub-nav-options.models";

@Component({
  selector: "titled-sub-nav-options",
  templateUrl: "./titled-sub-nav-options.component.html",
  styleUrls: ["./titled-sub-nav-options.component.less"],
})
export class TitledSubNavOptionsComponent {
  @Input() public options: SubNavOption[];
  @Input() public label: string;
  public expanded = true;
}
