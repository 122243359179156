import Quill from "quill";
import { UserAssignee } from "@webapp/assignees/models/assignee.models";
import { MENTION_CLASS } from "@webapp/shared/rich-text-editor/blots/blot-classes";
import { MentionAttachedEvent, MentionDeletedEvent } from "@webapp/shared/rich-text-editor/events/mention-deleted.event";

const Embed = Quill.import("blots/embed");

export class MentionBlot extends Embed {
  private container: Element;

  constructor(scroll, node) {
    super(scroll, node);

    this.mounted = false;
  }

  public static create(data: UserAssignee): unknown {
    const node: HTMLElement = super.create();

    const denotationCharEl = document.createElement("span");
    denotationCharEl.className = "ql-mention-denotation-char";
    denotationCharEl.textContent = "@";
    node.appendChild(denotationCharEl);

    const nameEl = document.createElement("span");
    nameEl.textContent = data.name;
    node.appendChild(nameEl);

    return MentionBlot.setDataValues(node, data);
  }

  public static setDataValues(element: HTMLElement, data: UserAssignee): HTMLElement {
    const domNode = element;
    Object.keys(data).forEach((key) => {
      domNode.dataset[key] = data[key];
    });
    return domNode;
  }

  public static value(domNode: HTMLElement): DOMStringMap {
    return domNode.dataset;
  }

  public attach(): void {
    super.attach();

    if (!this.mounted) {
      this.mounted = true;
      this.container = this.domNode.closest(".rich-text-editor-container");
    }

    if (this.domNode?.dataset?.id) {
      const event = new MentionAttachedEvent("mention-attached", {
        bubbles: true,
        cancelable: true,
      });
      event.userId = this.domNode.dataset.id;
      this.container?.dispatchEvent(event);
    }
  }

  public detach(): void {
    super.detach();
    this.mounted = false;

    const event = new MentionDeletedEvent("mention-deleted", {
      bubbles: true,
      cancelable: true,
    });
    event.userId = this.domNode?.dataset?.id;

    this.container?.dispatchEvent(event);
    this.container = null;
  }
}

MentionBlot.blotName = "ghMention";
MentionBlot.tagName = "span";
MentionBlot.className = MENTION_CLASS;
