<div class="backdrop"></div>
<div class="privacy">
  <div class="privacy__header">
    <img class="privacy__logo" [src]="logoSrc" alt="Logo" />
    <h2>Privacy Notice</h2>
    <p class="privacy__paragraph">Read and accept our privacy notice.</p>
  </div>
  <div class="privacy__pdf-viewer-container">
    <pdf-viewer class="privacy-viewer-component" #pdfViewer [src]="fileUrl" [show-all]="true" [original-size]="false" [fit-to-page]="true"></pdf-viewer>
  </div>
  <button [disabled]="acceptButtonDisabled" (click)="onAccept()" ui-button uiShape="round" uiType="primary" uiSize="large">Accept</button>
</div>
