import { MetricService } from "@gtmhub/okrs/metrics/services/metric.service";
import { providerUpgradeFactory } from "@webapp/core/provider-upgrade/factories/provider-upgrade.factory";
import { PiSuggestionSharedGoalStateService } from "@webapp/platform-intelligence/shared/services/pi-suggestion-shared-goal-state.service";
import { PiSuggestionSharedMetricStateService } from "@webapp/platform-intelligence/shared/services/pi-suggestion-shared-metric-state.service";

export const MetricServiceProvider = {
  provide: MetricService,
  useFactory: providerUpgradeFactory("MetricService"),
  deps: ["$injector"],
};

export const PiSuggestionSharedGoalStateServiceProvider = {
  provide: PiSuggestionSharedGoalStateService,
  useFactory: providerUpgradeFactory("PiSuggestionSharedGoalStateService"),
  deps: ["$injector"],
};

export const PiSuggestionSharedMetricStateServiceProvider = {
  provide: PiSuggestionSharedMetricStateService,
  useFactory: providerUpgradeFactory("PiSuggestionSharedMetricStateService"),
  deps: ["$injector"],
};
