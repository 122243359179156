<div class="assignee-details-popover-trigger" [(uiVisible)]="isOpen" [uiContent]="contentTemplate" uiTrigger="click" uiPlacement="bottomLeft" uiOverlayClassName="assignee-details-popover" ui-popover>
  <ng-content></ng-content>
</div>

<ng-template #contentTemplate>
  <div class="assignee-details-popover-content" [attr.data-test-id]="'assignee-details-' + assigneeId">
    <div class="assignee-details-header">
      <ui-assignee-avatar [fromAssigneeId]="assignee.id" uiSize="lg"></ui-assignee-avatar>
      <div class="assignee-details-header-title">
        <ui-assignee-name class="assignee-display-name" [uiEllipsis]="true" [fromAssigneeId]="assignee.id" [uiMultiline]="true" [uiDeactivatedLabel]="true"></ui-assignee-name>
        <div class="assignee-info">
          <div *ngIf="assigneeType === 'user'">{{ assignee.email }}</div>
          <div *ngIf="assigneeType === 'team'" class="assignee-created-on">{{ "created_on" | localize }} {{ assignee.dateCreated | date: "MMMM DD, YYYY" }}</div>
          <div *ngIf="assigneeType === 'team' && !assignee.isActive" class="assignee-deactivated-on">{{ "deactivated_on" | localize }} {{ assignee.dateDeactivated | date: "MMMM dd, yyyy" }}</div>
        </div>
      </div>
      <span class="assignee-details-close-icon" (click)="close()"><i ui-icon uiType="close-big"></i></span>
    </div>
    <div class="border sp-mt-8"></div>
    <div class="assignee-details-body">
      <ng-container *ngIf="assigneeType === 'team'">
        <div class="assignee-details-section-title">{{ "manager" | localize }}:</div>
        <div class="assignee-details-list-item" *ngIf="assignee.manager">
          <ui-assignee [fromAssigneeId]="assignee.manager" uiSize="xs"></ui-assignee>
        </div>
        <div class="assignee-details-list-item" *ngIf="!assignee.manager">
          <ui-avatar uiIcon="unknown" uiTheme="outline" uiSize="xs"></ui-avatar>
          <div class="assignee-details-list-item-name no-manager-text">{{ "no_manager_assigned" | localize }}</div>
        </div>
      </ng-container>
      <div class="assignee-details-section-title" *ngIf="list?.length > 0">
        <div *ngIf="assigneeType === 'user'">{{ "member_of" | localize }}:</div>
        <div class="capitalize" *ngIf="assigneeType === 'team'">{{ "members" | localize }}:</div>
      </div>
      @for (item of list; track item.id) {
        <div class="assignee-details-list-item">
          <ui-assignee [fromAssigneeId]="item.id" uiSize="xs"></ui-assignee>
        </div>
      }
      <div class="assignee-details-list-more-available" *ngIf="listTotalCount === limit + 1">{{ "and_x_other" | localize: { "team.members.length": 1 } }}</div>
      <div class="assignee-details-list-more-available" *ngIf="listTotalCount > limit + 1">{{ "and_x_others" | localize: { "team.members.length": listTotalCount - limit } }}</div>
    </div>
    <div class="assignee-details-actions">
      <button (click)="navigateToProfile()" ui-button uiShape="round" uiType="default" uiSize="default">{{ "view_profile" | localize }}</button>
    </div>
  </div>
</ng-template>
