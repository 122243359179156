import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { APP_CONFIG, IAppConfig } from "@gtmhub/env";
import { Assignee, AssigneeDTO } from "@webapp/assignees/models/assignee.models";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { AssigneesState } from "./assignees-state.service";

@Injectable({
  providedIn: "root",
})
export class AssigneesApiService extends BaseApiService<Assignee, AssigneeDTO, AssigneesState> {
  public constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, assigneesState: AssigneesState) {
    super("assignees", { ...new ApiVersionModel("v2") }, httpClient, appConfig, assigneesState);
  }
}
