import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { UiCheckboxModule } from "@quantive/ui-kit/checkbox";
import { NzTreeNodeBuiltinCheckboxComponent } from "ng-zorro-antd/tree";
import { TreeSize } from "../../tree.models";

@Component({
  selector: "ui-tree-node-checkbox[builtin]",
  templateUrl: "tree-node-checkbox.component.html",
  styleUrl: "./tree-node-checkbox.component.less",
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    // medium size styles can be added if requiered
    "[class.tree-node-checkbox-large]": `!uiSelectMode && treeSize === 'large'`,
  },
  standalone: true,
  imports: [UiCheckboxModule],
})
export class UiTreeNodeBuiltinCheckboxComponent extends NzTreeNodeBuiltinCheckboxComponent {
  @Input("uiSelectMode") public nzSelectMode = false;
  @Input() public isChecked?: boolean;
  @Input() public isHalfChecked?: boolean;
  @Input() public isDisabled?: boolean;
  @Input() public isDisableCheckbox?: boolean;
  @Input() public treeSize?: TreeSize;
  /**
   * `checkChange` propagates the MouseEvent
   * this is currently needed when using the `ui-checkbox`
   * to replace the `tree-node-checkbox` internal implementation
   */
  @Output() public readonly checkChange = new EventEmitter<MouseEvent>();
}
