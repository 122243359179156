import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, Input } from "@angular/core";
import { UiI18nService } from "@quantive/ui-kit/i18n";
import { ɵDateTableComponent as DateTableComponent } from "ng-zorro-antd/date-picker";
import { UiCalendarInterface, UiDateBodyRow } from "@webapp/ui/date-picker/date-picker.models";
import { UiDateHelperService } from "@webapp/ui/date-picker/services/date-helper.service";
import { UiDatePickerService } from "../../services/date-picker.service";

@Component({
  selector: "ui-date-table",
  exportAs: "uiDateTable",
  templateUrl: "../abstract-table.html",
  styleUrls: ["date-table.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "ui-date-table",
  },
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, NgSwitchDefault, NgIf, NgClass, NgFor, NgTemplateOutlet],
})
export class UiDateTableComponent extends DateTableComponent {
  /** the id of the element holding month and year buttons and used to label the table */
  @Input()
  public uiTableLabelledBy: string;
  @Input("uiLocale")
  public locale!: UiCalendarInterface;

  public bodyRows: UiDateBodyRow[];

  constructor(
    public datePickerService: UiDatePickerService,
    public elementRef: ElementRef<HTMLElement>,
    i18n: UiI18nService,
    dateHelper: UiDateHelperService
  ) {
    super(i18n, dateHelper);
  }
}
