import { IScimSettings } from "@gtmhub/core";

export const checkIfSetOfPropertiesSame = (objectA: Partial<IScimSettings>, objectB: Partial<IScimSettings>): boolean => {
  const aKeys = Object.keys(objectA).sort();
  const bKeys = Object.keys(objectB).sort();

  return JSON.stringify(aKeys) === JSON.stringify(bKeys);
};

export const generateDefaultScimAccountSettings = (args: { hideExternalTeams: boolean }): IScimSettings => {
  const defaultConfiguration: IScimSettings = {
    teamNamingConvention: "manager",
    autoRenameTeams: false,
    deactivateEmptyTeams: false,
    rolesProvisioningSetupType: "sso",
  };

  const defaultConfigurationHideExternalTeams: IScimSettings = {
    teamNamingConvention: "none",
    autoRenameTeams: false,
    deactivateEmptyTeams: false,
    rolesProvisioningSetupType: "sso",
  };

  return args.hideExternalTeams ? defaultConfigurationHideExternalTeams : defaultConfiguration;
};
