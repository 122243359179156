import { ChangeDetectionStrategy, Component, Input, OnChanges, forwardRef } from "@angular/core";
import { SimpleChangesTyped } from "@webapp/shared/models";
import { UiSelectOptionInterface } from "@webapp/ui/select/select.models";
import { FormItemBaseComponent } from "../form-item-base.component";

@Component({
  selector: "select-form-item",
  templateUrl: "./select-form-item.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FormItemBaseComponent,
      useExisting: forwardRef(() => SelectFormItemComponent),
    },
  ],
})
export class SelectFormItemComponent extends FormItemBaseComponent<string> implements OnChanges {
  @Input() public options: UiSelectOptionInterface[] | string[] = [];

  public uiOptions: UiSelectOptionInterface[] = [];

  public ngOnChanges(changes: SimpleChangesTyped<SelectFormItemComponent>): void {
    if (changes.options) {
      this.uiOptions = this.getUIOptions(changes.options.currentValue);
    }
  }

  /**
   * Converts the provided string options to a UiSelectOptionInterface array.
   */
  private getUIOptions(options: UiSelectOptionInterface[] | string[]): UiSelectOptionInterface[] {
    return options.map((option) => {
      if (typeof option === "string") {
        return {
          value: option,
          label: option,
        };
      }

      return option;
    });
  }
}
