<gh-indicator [(ghValue)]="indicators.loadingWorkspaces" [align]="'center'" [ghMessage]="'loading' | localize"></gh-indicator>
<form *ngIf="!indicators.loadingWorkspaces" [formGroup]="form" (ngSubmit)="onFormSubmit()" ui-form>
  <ui-row *ngIf="formErrors.upperFormError">
    <ui-alert class="sp-mb-24" [uiDescription]="formErrors.upperFormError" uiCloseable uiType="error"></ui-alert>
  </ui-row>
  <ui-form-item ui-row>
    <ui-form-label [uiSm]="8" [uiXs]="24" [uiNoColon]="true">
      <div class="label-content">Workspace</div>
    </ui-form-label>
    <ui-form-control [uiSm]="16" [uiXs]="24" uiValidateStatus="error">
      <ui-select class="full-width" [uiPlaceHolder]="'power_bi_workspace_dropdown_placeholder' | localize" formControlName="workspace" uiDropdownClassName="workspace-dropdown-select">
        <ui-option *ngFor="let workspace of workspaces" [uiValue]="workspace" [uiLabel]="workspace.name"></ui-option>
      </ui-select>
    </ui-form-control>
  </ui-form-item>

  <ui-form-item ui-row>
    <ui-form-label [uiSm]="8" [uiXs]="24" [uiNoColon]="true">
      <div class="label-content">Dataset</div>
    </ui-form-label>
    <ui-form-control [uiSm]="16" [uiXs]="24">
      <ui-select
        class="full-width"
        [uiLoading]="!!indicators.loadingDatasets"
        [uiPlaceHolder]="'power_bi_dataset_dropdown_placeholder' | localize"
        formControlName="dataset"
        uiDropdownClassName="dataset-dropdown-select">
        <ui-option *ngFor="let dataset of datasets" [uiValue]="dataset" [uiLabel]="dataset?.settings?.datasetName || dataset.name"></ui-option>
      </ui-select>
    </ui-form-control>
  </ui-form-item>

  <ui-form-item ui-row>
    <ui-form-label [uiSm]="8" [uiXs]="24" [uiNoColon]="true">
      <div class="label-content">DAX Query</div>
    </ui-form-label>
    <ui-form-control [uiSm]="16" [uiXs]="24" [formValidateStatus]="formErrors.inlineDAXQueryError ? 'error' : undefined" [uiErrorTip]="formErrors.inlineDAXQueryError">
      <textarea [attr.placeholder]="'power_bi_dax_query_input_placeholder' | localize" formControlName="daxQuery" rows="4" ui-input></textarea>
      <form-control-error *ngIf="formErrors.inlineDAXQueryError"></form-control-error>
    </ui-form-control>
  </ui-form-item>
  <ui-row *ngIf="formErrors.lowerFormError">
    <ui-alert class="sp-mb-8" [uiDescription]="formErrors.lowerFormError" uiCloseable uiType="error" uiIconType="failed"></ui-alert>
  </ui-row>
  <div class="form-actions">
    <gh-indicator [(ghValue)]="indicators.submittingForm" [ghMessage]="'fetching_data' | localize"></gh-indicator>
    <div class="actions-holder" *ngIf="!indicators.submittingForm">
      <button class="submit-btn sp-mr-10" [disabled]="!form.valid || formErrors.inlineDAXQueryError" ui-button type="submit" uiSize="large" uiType="primary" uiShape="round">
        {{ "automate" | localize }}
      </button>
      <button class="cancel-btn sp-mr-10" (click)="isEdit ? closeModal() : onBackButtonClick()" ui-button type="button" uiSize="large" uiType="default" uiShape="round">
        {{ "cancel" | localize }}
      </button>
    </div>
  </div>
</form>
