import { Ng2StateDeclaration, UIRouterModule } from "@uirouter/angular";
import { NgModule } from "@angular/core";
import { PersonalNotificationComponent } from "./components/personal-notification/personal-notification.component";

const states: Ng2StateDeclaration[] = [
  {
    name: "personal",
    url: "/user-notification-settings",
    data: { requiresAuth: true },
    component: PersonalNotificationComponent,
  },
];

@NgModule({
  imports: [UIRouterModule.forChild({ states })],
})
export class NotificationsRoutingModule {}
