import { NgFor } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { NzTreeIndentComponent } from "ng-zorro-antd/tree";
import { TreeSize } from "../../tree.models";

@Component({
  selector: "ui-tree-indent",
  exportAs: "uiTreeIndent",
  templateUrl: "tree-indent.component.html",
  styleUrls: ["tree-indent.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    "[class.tree-indent-medium]": `!uiSelectMode && treeSize === 'medium'`,
    "[class.is-checkable]": `uiCheckable`,
  },
  standalone: true,
  imports: [NgFor],
})
export class UiTreeIndentComponent extends NzTreeIndentComponent {
  @Input("uiTreeLevel") public nzTreeLevel = 0;
  @Input("uiIsStart") public nzIsStart: boolean[] = [];
  @Input("uiIsEnd") public nzIsEnd: boolean[] = [];
  @Input("uiSelectMode") public nzSelectMode = false;
  @Input() public uiCheckable: boolean;
  @Input() public treeSize?: TreeSize;
}
