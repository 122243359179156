import { Injectable } from "@angular/core";
import { UiThemeType } from "@quantive/ui-kit/icon";
import { BehaviorSubject, Observable, Subject, take } from "rxjs";
import { v4 as uuidv4 } from "uuid";
import { PiTrackingFlowNameEnum } from "@webapp/platform-intelligence/shared/utils/pi-tracking";
import { IPIFeedbackSuggestion } from "../../models";
import { PIImproveSuggestionContext } from "../improvement/models";
import {
  PIStateProcessorInstanceSubType,
  PIStateProcessorInstanceType,
  PiFeedbackSubjectProps,
  PiImprovementSubjectProps,
  PiReplacementSuggestionSubjectProps,
  PiStateProcessorInstance,
  PiStateProcessorSubjectProps,
} from "./pi-state-processor.models";

const subjects: PiStateProcessorInstance[] = [];

class PiStateProcessorManager {
  private static instance: PiStateProcessorManager;

  constructor() {
    if (PiStateProcessorManager.instance) {
      return PiStateProcessorManager.instance;
    }

    PiStateProcessorManager.instance = this;
  }

  private instanceIdsPendingFocus: Record<string, number> = {};

  public getInstance(id: string): PiStateProcessorInstance {
    return subjects.find((s) => s.id === id);
  }

  public createNewInstance(opts: {
    type: PIStateProcessorInstanceType;
    subType: PIStateProcessorInstanceSubType;
    isInline: boolean;
    improveSuggestionContext?: PIImproveSuggestionContext;
    iconType?: string;
    iconTheme?: UiThemeType;
    flowName?: PiTrackingFlowNameEnum;
    flowId?: string;
    skipFeedbackReplacement?: boolean;
    parentType?: PIStateProcessorInstanceType;
    parentId?: string;
    focusPriorityOrder?: number;
    destroyOn$: Observable<void>;
  }): PiStateProcessorInstance {
    const subject = new Subject<PiStateProcessorSubjectProps>();
    const feedbackSubject = new BehaviorSubject<PiFeedbackSubjectProps>(null);
    const improvementSubject = new Subject<PiImprovementSubjectProps>();
    const replacementSuggestionSubject = new Subject<PiReplacementSuggestionSubjectProps>();
    const isFeedbackCardFocused = new BehaviorSubject<{ index?: number; isFocused: boolean }>({ index: undefined, isFocused: false });

    const instance: PiStateProcessorInstance = {
      id: uuidv4(),
      subject,
      feedbackSubject,
      improvementSubject,
      isFeedbackCardFocused,
      replacementSuggestionSubject,
      subType: opts.subType,
      type: opts.type,
      isInline: opts.isInline,
      ...(opts.iconType && {
        iconType: opts.iconType,
      }),
      ...(opts.iconTheme && {
        iconTheme: opts.iconTheme,
      }),
      flowId: opts.flowId,
      flowName: opts.flowName,
      improveSuggestionContext: opts.improveSuggestionContext,
      skipFeedbackSuggestion: opts.skipFeedbackReplacement,
      parentId: opts.parentId,
      parentType: opts.parentType,
      focusPriorityOrder: opts.focusPriorityOrder || 100,
    };
    subjects.push(instance);

    opts.destroyOn$.pipe(take(1)).subscribe(() => {
      subjects.splice(
        subjects.findIndex((s) => s.id === instance.id),
        1
      );
    });

    return instance;
  }

  public assigneInstanceFlowProperties(instanceId: string, props: { flowId: string; flowName: PiTrackingFlowNameEnum }): void {
    const instance = this.getInstance(instanceId);

    instance.flowId = props.flowId;
    instance.flowName = props.flowName;
  }

  public updateInstanceImproveSuggestionContext(instanceId: string, improveSuggestionContext: PIImproveSuggestionContext): void {
    const instance = this.getInstance(instanceId);

    instance.improveSuggestionContext = improveSuggestionContext;
  }

  public tryToFocusInstanceAfter(props: { instanceId: string; priorityOrder: number; focusAfter(): Observable<unknown> }): void {
    this.instanceIdsPendingFocus[props.instanceId] = props.priorityOrder;

    props
      .focusAfter()
      .pipe(take(1))
      .subscribe(() => {
        if (!this.instanceIdsPendingFocus[props.instanceId]) return;

        const instance = this.getInstance(props.instanceId);
        if (!instance) {
          delete this.instanceIdsPendingFocus[props.instanceId];
          return;
        }

        if (
          subjects.some((s) => {
            const isFocused = s.isFeedbackCardFocused.value.isFocused;
            const isOfSameType = s.type === instance.type;

            return isFocused && isOfSameType;
          })
        ) {
          this.instanceIdsPendingFocus = {};
          return;
        }

        const instanceIsNotOfTheHighestPriority = Object.keys(this.instanceIdsPendingFocus).some((id) => {
          const isNotSameInstance = id !== props.instanceId;
          const isOfHigherPriority = this.instanceIdsPendingFocus[id] < this.instanceIdsPendingFocus[props.instanceId];
          const isOfSameType = instance.type === this.getInstance(id).type;

          return isNotSameInstance && isOfHigherPriority && isOfSameType;
        });
        if (instanceIsNotOfTheHighestPriority) {
          return;
        }

        this.instanceIdsPendingFocus = {};
        instance.isFeedbackCardFocused.next({ index: 0, isFocused: true });
      });
  }

  public focusCurrentInstanceAndCloseOthers(opts: { instanceId: string; suggestionIndex: number; isFocused: boolean }): void {
    if (opts.isFocused) {
      this.instanceIdsPendingFocus = {};
    }

    const instance = this.getInstance(opts.instanceId);
    instance.isFeedbackCardFocused.next({ index: opts.suggestionIndex, isFocused: opts.isFocused });

    subjects
      .filter((s) => s.id !== opts.instanceId && s.isFeedbackCardFocused.value.isFocused)
      .forEach((s) => s.isFeedbackCardFocused.next({ index: s.isFeedbackCardFocused.value.index, isFocused: false }));
  }

  public notifyInstancesOnFeedbackSent(type: PIStateProcessorInstanceSubType, payload: PiFeedbackSubjectProps): void {
    const instances = subjects.filter((s) => s.subType === type);

    instances.forEach((instance) => instance.feedbackSubject.next(payload));
  }

  public notifyInstancesOnSuggestionReplacement(
    type: PIStateProcessorInstanceSubType,
    payload: { newSuggestion: IPIFeedbackSuggestion; suggestion: IPIFeedbackSuggestion }
  ): void {
    const instances = subjects.filter((s) => s.subType === type);

    instances.forEach((instance) => instance.replacementSuggestionSubject.next(payload));
  }

  public collapseAllInstances(): void {
    subjects.forEach((s) => s.isFeedbackCardFocused.next({ index: s.isFeedbackCardFocused.value.index, isFocused: false }));
  }
}

@Injectable({
  providedIn: "root",
  useValue: new PiStateProcessorManager(),
})
export class PiStateProcessorService extends PiStateProcessorManager {}
