import { StateService, TransitionPromise, TransitionService, UIRouterModule } from "@uirouter/angular";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiAlertModule } from "@quantive/ui-kit/alert";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiCardModule } from "@quantive/ui-kit/card";
import { finalize, take } from "rxjs";
import { ApmService } from "@gtmhub/core/tracing/apm.service";
import { humanError } from "@gtmhub/error-handling/util";
import { localize } from "@gtmhub/localization";
import { getCurrentUserId } from "@gtmhub/users";
import { AnalyticsModule } from "@webapp/analytics/analytics.module";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { fromTransitionHook } from "@webapp/core/routing/rxjs";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { TaskStatusModule } from "@webapp/okrs/components/okr-tasks/components/task-status/task-status.module";
import { OkrWorkflowService } from "@webapp/okrs/components/okr-workflow/okr-workflow.service";
import { UpdateMetricModalService } from "@webapp/okrs/metrics/services/update-metric-modal.service";
import { TaskStatus } from "@webapp/tasks/models/tasks.models";
import { TasksFacade } from "@webapp/tasks/services/task-facade.service";
import { BaseWidgetComponent } from "@webapp/ui/dashboard/components/base-widget.component";
import { UiLoadingIndicatorModule } from "@webapp/ui/loading-indicator/loading-indicator.module";
import { UiToastService } from "@webapp/ui/toast/services/toast.service";
import { UiToastModule } from "@webapp/ui/toast/toast.module";
import { ContainerEmptyStateComponent } from "../../../../shared/components/container-empty-state/container-empty-state.component";
import { WidgetActionsComponent } from "../widget-actions/widget-actions.component";
import { WidgetSkeletonComponent } from "../widget-skeleton/widget-skeleton.component";
import { TodosApiV2Service } from "./repository/todos-api-v2.service";
import { TodosRepository } from "./repository/todos-repository.service";
import { TodosWidgetSectionComponent } from "./todos-widget-section/todos-widget-section.component";
import { TaskTodoItem, TodoGroups } from "./todos-widget.models";
import { emulateColumnWidthToElements } from "./todos-widget.utils";

const TOAST_DURATION = 5000;

@UntilDestroy()
@Component({
  selector: "todos-widget",
  templateUrl: "./todos-widget.component.html",
  styleUrls: ["./todos-widget.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    UiCardModule,
    LocalizationModule,
    WidgetActionsComponent,
    UiButtonModule,
    TaskStatusModule,
    TodosWidgetSectionComponent,
    ContainerEmptyStateComponent,
    UIRouterModule,
    UiToastModule,
    WidgetSkeletonComponent,
    UiLoadingIndicatorModule,
    UiAlertModule,
    AnalyticsModule,
  ],
  providers: [TodosRepository, TodosApiV2Service, OkrWorkflowService, UpdateMetricModalService],
})
export class TodosWidgetComponent extends BaseWidgetComponent {
  public get title(): string {
    return localize("to_dos");
  }

  public get a11yLabel(): string {
    return "To-dos";
  }

  public get hasPendingTodos(): boolean {
    return this.todos?.keyResults?.length > 0 || this.todos?.okrsForApproval?.length > 0 || this.todos?.reflections?.length > 0 || this.todos?.tasks?.length > 0;
  }

  public error: string = null;
  public loading = true;
  public todos: TodoGroups;
  public hadTodos = false;
  public hasResolvedLastTodo = false;
  public loadingItemId: string;

  public constructor(
    private todosRepository: TodosRepository,
    private changeDetector: ChangeDetectorRef,
    private state: StateService,
    private okrWorkflowService: OkrWorkflowService,
    private zone: NgZone,
    private hostElement: ElementRef<HTMLElement>,
    private updateMetricModalService: UpdateMetricModalService,
    private tasksFacade: TasksFacade,
    private toastService: UiToastService,
    private transitionService: TransitionService,
    private analyticsService: AnalyticsService,
    private apmService: ApmService
  ) {
    super();
    this.apmService.startDataLoadSpan("todos-widget-init");
    this.loadTodos();

    // https://quantive-inc.atlassian.net/browse/GVS-48454
    // reload the data when sidebar panels get closed and you are back on the dashboard
    fromTransitionHook(this.transitionService, "onSuccess", { to: "gtmhub.home.dashboard" })
      .pipe(untilDestroyed(this))
      .subscribe(() => this.loadTodos());
  }

  public openApproveOkrModal(objectiveId: string): void {
    this.openOkr(objectiveId).then(() => {
      // the delay is required in order to open the modals in the correct order
      this.zone.onStable.pipe(take(1), untilDestroyed(this)).subscribe(() => {
        this.okrWorkflowService.openWorkflowApprovalModal({ goalId: objectiveId, mode: "approve" });
      });
    });
  }

  public handleOkrLinkClick(objectiveId: string): void {
    this.analyticsService.track("Link Clicked", this.getTrackingMeta({ link: "objective_details", id: objectiveId }));
    this.openOkr(objectiveId);
  }

  public openOkr(objectiveId: string): TransitionPromise {
    return this.state.go("._goals.goal", { id: objectiveId });
  }

  public handleCheckInLinkClick(teamId: string): void {
    this.analyticsService.track("Link Clicked", this.getTrackingMeta({ link: "team_profile", id: teamId }));
    this.openCheckInPage(teamId);
  }

  public openCheckInPage(teamId: string, options?: { expandCurrentUserCheckIn: boolean }): void {
    this.state.go(".team", {
      teamId: teamId,
      activeTab: "check-ins",
      ...(options?.expandCurrentUserCheckIn && { memberId: getCurrentUserId() }),
    });
  }

  public handleTaskLinkClick(taskId: string): void {
    this.analyticsService.track("Link Clicked", this.getTrackingMeta({ link: "task_details", id: taskId }));
    this.openTask(taskId);
  }

  public openTask(taskId: string): void {
    this.state.go(".task", { taskId });
  }

  public updateTaskStatus(taskId: string, status: TaskStatus): void {
    if (status === "done") {
      this.loadingItemId = taskId;
    }

    this.tasksFacade
      .update$(taskId, { status }, this.getTrackingMeta({ button_name: "task_status" }))
      .pipe(
        take(1),
        untilDestroyed(this),
        finalize(() => {
          this.loadingItemId = null;
          this.changeDetector.markForCheck();
        })
      )
      .subscribe(() => {
        const initialTaskIndex = this.todos.tasks.findIndex((task) => task.id === taskId);
        const initailTaskState = this.todos.tasks[initialTaskIndex];

        if (status === "done") {
          this.toastService.info(localize("task_moved_to_done"), {
            uiPrimaryCTA: localize("undo"),
            uiDuration: TOAST_DURATION,
            uiOnPrimaryAction: () => this.restoreTaskStatus(initailTaskState, initialTaskIndex),
          });

          this.todos.tasks = this.todos.tasks.filter((task) => task.id !== taskId);
          this.hasResolvedLastTodo = !this.hasPendingTodos;
        } else {
          initailTaskState.status = status;
        }
      });
  }

  public handleKrLinkClick(keyResultId: string): void {
    this.analyticsService.track("Link Clicked", this.getTrackingMeta({ link: "key_result_details", id: keyResultId }));
    this.openKr(keyResultId);
  }

  public openKr(keyResultId: string): TransitionPromise {
    return this.state.go(".metric", { metricId: keyResultId });
  }

  public openUpdateKrModal(keyResultId: string): void {
    this.openKr(keyResultId).then(() => {
      window.setTimeoutOutsideAngular(() => {
        this.updateMetricModalService
          .openUpdateMetricModal({
            metricId: keyResultId,
          })
          .pipe(take(1))
          .subscribe();
      }, 200);
    });
  }

  public handleErrorDismiss(): void {
    this.error = null;
    this.loading = true;
    this.changeDetector.markForCheck();

    this.loadTodos();
  }

  public getTrackingMeta(additionalMeta: Record<string, string>): Record<string, string> {
    return {
      user_id: getCurrentUserId(),
      widget: "to-do",
      name: "home_customizable",
      ...additionalMeta,
    };
  }

  private restoreTaskStatus(initialTask: TaskTodoItem, initialIndex: number): void {
    this.tasksFacade
      .update$(initialTask.id, { status: initialTask.status }, this.getTrackingMeta({ button_name: "task_status" }))
      .pipe(take(1), untilDestroyed(this))
      .subscribe(() => {
        // recreate the array instead of array.splice to trigger change detection in the child component
        this.todos.tasks = [...this.todos.tasks.slice(0, initialIndex), initialTask, ...this.todos.tasks.slice(initialIndex)];

        // force update the view before resizing the columns
        this.changeDetector.detectChanges();
        this.setColumnSizes();
      });
  }

  private setColumnSizes(): void {
    emulateColumnWidthToElements(this.hostElement.nativeElement, ".todos-widget-row-due-date");
    emulateColumnWidthToElements(this.hostElement.nativeElement, ".todos-widget-row-cta-container");
  }

  private loadTodos(): void {
    this.todosRepository
      .get$()
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: (todos) => {
          this.todos = todos;
          this.loading = false;

          if (this.hasPendingTodos) {
            this.hadTodos = true;
          }

          // show the todos-resolved svg, controlled by `hasResolvedLastTodo`, only if the widget had todos and now they are all resolved
          this.hasResolvedLastTodo = this.hadTodos && !this.hasPendingTodos;

          // force render changes, then set column sizes
          this.changeDetector.detectChanges();
          this.setColumnSizes();
          this.apmService.endDataLoadSpan("todos-widget-init");
        },
        error: (error) => {
          this.error = humanError(error) || localize("unexpected_error_message");
          this.loading = false;
          this.changeDetector.markForCheck();
          this.apmService.endDataLoadSpan("todos-widget-init");
        },
      });
  }
}
