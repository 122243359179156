import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { QunativePlusExplanation, QunativePlusExplanationPostDTO } from "../../models/quantive-plus-explanation.models";
import { QuantivePlusExplanationState } from "./quantive-plus-explanation-state.service";

@Injectable({
  providedIn: "root",
})
export class QuantivePlusExplanationApiService extends BaseApiService<QunativePlusExplanation, QunativePlusExplanationPostDTO, QuantivePlusExplanationState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: QuantivePlusExplanationState) {
    super("explanation", { ...new ApiVersionModel("v1") }, httpClient, appConfig, state);
  }

  public getObjectivesExplanation(): string {
    return `${this.getQuantivePlusBasePath(HttpActions.post)}/objectives`;
  }

  public getKeyResultsExplanation(): string {
    return `${this.getQuantivePlusBasePath(HttpActions.post)}/krs`;
  }
}
