import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { APP_CONFIG, IAppConfig } from "@gtmhub/env";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseApiServiceV2 } from "@webapp/core/api/base-api-v2.service";

@Injectable({ providedIn: "root" })
export class CurrentUserApiService extends BaseApiServiceV2<unknown, RequestPaging> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig) {
    super("users", { ...new ApiVersionModel("v1") }, httpClient, appConfig);
  }

  public getVerifyEmailEndpoint(email: string): string {
    return `${this.getBasePath(HttpActions.post)}/send-verification-email/${email}`;
  }

  public getValidateVerifyEmailEndpoint(userId: string): string {
    return `${this.getBasePath(HttpActions.post)}/${userId}/validate-verify-email`;
  }

  public getUserSettingsEndpoint(): string {
    return `${this.getBasePath(HttpActions.get)}/settings`;
  }
}
