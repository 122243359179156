import { IHttpService, IPromise, IRequestShortcutConfig, IRootScopeService } from "angular";
import { IRestLayerRequest } from "@gtmhub/core";
import { EnvironmentService } from "@gtmhub/env";
import { ICollection } from "@webapp/core/core.models";
import { PluginGtmhubAdditionalParams } from "@webapp/plugins/plugins.models";
import { StatusActionGtmhubAdditionalParams } from "@webapp/teams/models/status-actions.models";
import { TeamEvents } from "./events";
import { ITeam } from "./models/models";

export class TeamService {
  public static $inject = ["$http", "EnvironmentService", "$rootScope"];
  private events: TeamEvents;

  constructor(
    private $http: IHttpService,
    private env: EnvironmentService,
    private $rootScope: IRootScopeService
  ) {
    this.events = new TeamEvents(this.$rootScope);
  }

  public createTeam(team: ITeam, params?: PluginGtmhubAdditionalParams): IPromise<ITeam> {
    const url = this.env.getApiEndpoint("/teams");

    const config: IRequestShortcutConfig = {
      params,
    };

    return this.$http.post<ITeam>(url, team, config).then((response) => response.data);
  }

  public setTeamManager(teamId: string, managerId: string): IPromise<string> {
    const url = this.env.getApiEndpoint("/teams/" + teamId + "/manager");
    const manager = { manager: managerId };
    return this.$http.put<string>(url, manager).then((response) => response.data);
  }

  public addTeamMember(teamId: string, members: string[]): IPromise<string> {
    const url = this.env.getApiEndpoint("/teams/" + teamId + "/members");
    const newMembers = { members: members };
    return this.$http.put<string>(url, newMembers).then((response) => response.data);
  }

  public removeTeamManager(teamId: string): IPromise<void> {
    const url = this.env.getApiEndpoint("/teams/" + teamId + "/manager");

    return this.$http.delete(url).then(() => null);
  }

  public removeTeamMember(teamId: string, memberId: string): IPromise<void> {
    const url = this.env.getApiEndpoint("/teams/" + teamId + "/members/" + memberId);

    return this.$http.delete(url).then(() => null);
  }

  public getTeams(params: IRestLayerRequest, additionalGtmhubParams?: PluginGtmhubAdditionalParams): IPromise<ICollection<ITeam>> {
    const url = this.env.getApiEndpointV2("/teams");

    const config: IRequestShortcutConfig = {
      params: { ...params, ...additionalGtmhubParams },
    };

    return this.$http.get<ICollection<ITeam>>(url, config).then((response) => response.data);
  }

  public updateTeam(team: Partial<ITeam>, additionalGtmhubParams?: PluginGtmhubAdditionalParams): IPromise<ITeam> {
    const url = this.env.getApiEndpoint("/teams/" + team.id);

    const config: IRequestShortcutConfig = {
      params: additionalGtmhubParams,
    };

    return this.$http.patch<ITeam>(url, team, config).then((response) => response.data);
  }

  public getTeam(id: string, additionalGtmhubParams?: PluginGtmhubAdditionalParams): IPromise<ITeam> {
    const url = this.env.getApiEndpoint("/teams/" + id);

    const config: IRequestShortcutConfig = {
      params: additionalGtmhubParams,
    };

    return this.$http.get<ITeam>(url, config).then((response) => response.data);
  }

  public deleteTeam(id: string, additionalGtmhubParams?: PluginGtmhubAdditionalParams | StatusActionGtmhubAdditionalParams): IPromise<void> {
    const url = this.env.getApiEndpoint("/teams/" + id);

    const config: IRequestShortcutConfig = {
      params: additionalGtmhubParams,
    };

    return this.$http.delete(url, config).then(() => {
      this.events.broadcastTeamDeleted({ teamId: id });
    });
  }
}
